export const UPDATE_LOGIN = 'UPDATE_LOGIN';
export const UPDATE_USER_PERMISSIONS = 'UPDATE_USER_PERMISSIONS';
export const UPDATE_USER = 'UPDATE_USER';
export const SET_ROLE = 'SET_ROLE';
export const GET_LISTINGS = 'GET_LISTINGS';
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
export const UPDATE_ORGANIZATION_TITLE = 'UPDATE_ORGANIZATION_TITLE';
export const UPDATE_ORGANIZATION_ID = 'UPDATE_ORGANIZATION_ID';
export const ORG_MODAL = 'ORG_MODAL';
export const ENABLE_COLLECTIONS = 'ENABLE_COLLECTIONS';
export const CURRENT_ITEM_TITLE = "CURRENT_ITEM_TITLE";
export const INTERNAL_PROPERTY_ADMIN = 'INTERNAL_PROPERTY_ADMIN';
export const UPDATE_MOBILE_STATE = 'UPDATE_MOBILE_STATE';
export const UPDATE_UNSAVED_DATA_STATE = 'UPDATE_UNSAVED_DATA_STATE';
/**
 * Update login status 
 * @param {*} isLoggedIn user information
 * @returns logged in user information 
 */
export const updateLoginStatus = isLoggedIn => {
    return {
        type: UPDATE_LOGIN,
        isLoggedIn
    }
}
/**
 * Update mobile state
 * @param {boolean} mobileMode mobileMode state
 * @returns mobileMode boolean
 */
export const updateMobileState = mobileMode => {
    return {
        type: UPDATE_MOBILE_STATE,
        mobileMode,
    }
}
/**
 * Update user permissions
 * @param {*} perms user permissions
 * @returns updated user permission
 */
export const updateUserPermissions = perms => {
    return {
        type: UPDATE_USER_PERMISSIONS,
        perms
    }
}
/**
 * Update current user
 * @param {*} user updated user information
 * @returns updated user information
 */
export const updateUser = user => {
    return {
        type: UPDATE_USER,
        user
    }
}
/** Set role of current user 
 * @param {*} role user roles
 * @returns User roles
 */
export const setRole = role => {
    return {
        type: SET_ROLE,
        role
    }
}
/** Update organization
 * @param {string} org organization name.
 * @returns new organization name 
 */
export const updateOrganization = org => {
    return {
        type: UPDATE_ORGANIZATION,
        org
    }
}
/** Update organization title
 * @param {string} orgTitle organization title.
 * @returns new organization title 
 */
export const updateOrganizationTitle = orgTitle => {
    return {
        type: UPDATE_ORGANIZATION_TITLE,
        orgTitle
    }
}
/** Update organization ID
 * @param {string} orgId organization ID.
 * @returns new organization ID 
 */
export const updateOrganizationId = orgId => {
    return {
        type: UPDATE_ORGANIZATION_ID,
        orgId
    }
}
/** Open organization modal 
 * @returns organization state open 
*/
export const openOrganizationModal = name => {
    return {
        type: ORG_MODAL,
        show: name
    }
}
/** Close organization modal 
 * @returns organization state close 
*/
export const closeOrganizationModal = name =>{
    return {
        type: ORG_MODAL,
        show: name
    }
}
export const updateInternalPropertyAdmin = (boolean) =>{
    return {
        type: INTERNAL_PROPERTY_ADMIN,
        boolean
    }
}
/** For set random delay  */
export const delay = async(ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}
export const updateUnsavedData = unsavedData => {
    return {
        type: UPDATE_UNSAVED_DATA_STATE,
        unsavedData,
    }
}
export default updateLoginStatus;
