import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { updateLoginStatus, updateUnsavedData } from '../../js/actions';
import { connect } from 'react-redux';
import { PrimaryButton } from '../global/forms/FormElements';

import Modal from 'react-bootstrap/lib/Modal';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';

class NavigationPrompt extends Component {
    state = {
        nextLocation: null, 
        openModal: false,
    };
    
    componentDidMount() {
        this.unblock = this.props.history.block((nextLocation) => {
        if (this.props.when) {
            this.setState({
            openModal: true,
            nextLocation: nextLocation
            });
        }
        return !this.props.when;
        });
    }

    componentWillUnmount() {
        this.unblock();
    }

    onCancel = () => {
        this.setState({nextLocation: null, openModal: false});
    }

    onConfirm = () => {
        this.navigateToNextLocation();
    }

    onSave = () => {
        this.onCancel();

        this.props.onSave();
    }

    //changed from this.state.nextLocation.pathanme to this.state.nextLocation for cloneData property task
    navigateToNextLocation = async () => {
        this.unblock();
        
        if(this.state.nextLocation.pathname === "/") {
            await Auth.signOut();
            this.props.updateLoginStatus();

            this.props.history.push("/");
        }
        else {
            this.props.history.push(this.state.nextLocation);
        };

        this.props.updateUnsavedData(false);
    }

    render () {

        return (
            <Fragment>
                <Modal 
                    show={this.state.openModal}
                    className="navigation-prompt-modal"
                    onHide={this.onCancel}
                >
                    <Modal.Body>
                        <Row className="text-center bottom-margin-30">
                            <Col xs={12}>
                                <h2>There are unsaved changes, do you want to continue?</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <PrimaryButton
                                    cssClass='white-btn pull-right right-margin'
                                    fullWidth={false}
                                    onClick={this.onCancel}
                                >
                                    Cancel
                                </PrimaryButton>
                                <PrimaryButton 
                                    cssClass='white-btn pull-right right-margin'
                                    fullWidth={false} 
                                    onClick={this.onConfirm}
                                >
                                    Continue without Saving
                                </PrimaryButton>
                                <PrimaryButton 
                                    cssClass='pull-right right-margin'
                                    fullWidth={false} 
                                    onClick={this.onSave}
                                >
                                    Save
                                </PrimaryButton>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </Fragment>
        )
    } 
}

const mapDispatchToProps = dispatch => {
    return {
        updateLoginStatus: (isLoggedIn) => {
            dispatch(updateLoginStatus(isLoggedIn));
        },
        updateUnsavedData: (isLoggedIn) => {
            dispatch(updateUnsavedData(isLoggedIn));
        }
    }
}

export default withRouter(connect(
    null,
    mapDispatchToProps
)(NavigationPrompt));