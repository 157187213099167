import React, { Component, Fragment } from 'react';
import Link from 'react-router-dom/Link';
import { API } from 'aws-amplify';
import Picky from 'react-picky';
import 'react-picky/dist/picky.css';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Form from 'react-bootstrap/lib/Form';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import PanelGroup from 'react-bootstrap/lib/PanelGroup';
import Panel from 'react-bootstrap/lib/Panel';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import Modal from 'react-bootstrap/lib/Modal';

import { Title } from '../global/Title';
import TableComponent from '../global/TableComponent';
import { InputField, PrimaryButton, FieldWrap, InputLabel } from '../global/forms/FormElements';
import Loader from '../global/Loader';
import PageLayout from '../global/PageLayout';
import GlobalSubmitModal from '../global/forms/GlobalSubmitModal';
import constants from '../../js/constants';

class EditUser extends Component {
    state = {
        org: "",
        roles: "",
        isLoading: false,
        error: false,
        activeKey: "1",
        user: {},
        allOrganization: [],
        selectedOrganization: [],
        currentUserData: {},
        submitStatus: 0,
        progressMessage: "",
        modalMsg: "",
        modalError: {
            show: false,
            message: "",
            detail: "",
        },
        formValidation: {
            first_name: false,
            last_name: false,
        },
        modalValidation: {
            email: false,
            roles: false,
            selectedOrganization: false,
            validRedawningEmail: false,
        },
        data: {
            id: "",
            uid: "",
            contact_info: {
                first_name: "",
                last_name: "",
                email: "",
                home_phone: "",
                mobile_phone: "",
                work_phone: "",
                street_address: "",
                city: "",
                province: "",
                country: "",
                postal_code: "",
            }
        },
        modalData: {
            email: "",
            roles: [],
        },
        showModal: "",
        columns:[
            {
                'Header':'Organization(s)',
                'accessor': 'org_name',
                'minWidth': 390,
                'sortable': false,
                'Cell': row=>{
                    return (
                        <Fragment>
                            <div>{row &&row.original && row.original.title ? 
                                row.original.title : 
                                row.original.organization_name}</div>
                            {row && row.original && row.original.roles.map((role, i) => {
                                return <br key={i}/>
                            })}
                        </Fragment>)

                    }
            },
            {
                'Header':'Role(s)',
                'accessor': 'roles',
                'minWidth': 390,
                'sortable': false,
                'Cell': row=>{
                    return (
                        row && row.original && row.original.roles.map((roles, i) => {
                            return (
                                <Fragment key={i}>
                                    <div>{roles}</div>
                                </Fragment>)
                            }))
                    }
            },
            {
                'Header':'Actions',
                'minWidth': 90,
                'sortable': false,
                'Cell': row=>{
                    return (
                        <div>
                            <OverlayTrigger 
                                placement='bottom' 
                                overlay={this.getTooltip('edit',`Update User`)}
                                onClick={() => this.openEditUserModal(row.original)}
                            >
                                <span className='properties__options' style={{cursor: 'pointer'}}>
                                    <i className='icon-edit' />
                                </span>
                            </OverlayTrigger>
                            <OverlayTrigger 
                                placement='bottom' 
                                overlay={this.getTooltip('delete', 'Delete User')}
                                onClick ={() => this.openDeleteUserFromOrgModal(row.original)}
                            >
                                <span className='properties__options' style={{cursor: 'pointer'}}>
                                    <i className='icon-Delete' />
                                </span>
                            </OverlayTrigger>
                        </div>
                    )
                }
            }
        ],
    }

    setDataValue = (data) => {
        let user = data;
        let constactInfo = user && user.contact_info;
        
        this.setState({
            modalData: {
                ...this.state.modalData,
                email: 
                    constactInfo &&
                    constactInfo.email || "",
            },
            data: {
                ...this.state.data,
                id: 
                    user && 
                    user.id || "",
                uid: 
                    user && 
                    user.uid || "",
                contact_info: {
                    ...this.state.data.contact_info,
                    first_name: 
                        constactInfo && 
                        constactInfo.first_name || "",
                    last_name: 
                        constactInfo &&
                        constactInfo.last_name || "",
                    email: 
                        constactInfo &&
                        constactInfo.email || "",
                    home_phone:
                        constactInfo &&
                        constactInfo.home_phone || "",
                    mobile_phone:
                        constactInfo &&
                        constactInfo.mobile_phone || "",
                    work_phone:
                        constactInfo &&
                        constactInfo.work_phone || "",
                    street_address:
                        constactInfo &&
                        constactInfo.street_address || "",
                    city:
                        constactInfo &&
                        constactInfo.city || "",
                    province:
                        constactInfo &&
                        constactInfo.province || "",
                    country:
                        constactInfo &&
                        constactInfo.country || "",
                    postal_code:
                        constactInfo &&
                        constactInfo.postal_code || "",
                }
            },
        })
    }
    
    async componentDidMount() {
        let { match } = this.props;
        this.state.org = this.props.roleManager.org;
        this.state.roles = this.props.roleManager.roles;
        this.state.allOrganization = this.props.organizations;

        this.setState({
            isLoading: true,
        })
        if(match && match.params && match.params.uid) {
            this.setState({ 
                mode: "edit",
            });
            await this.getUser();

            this.setDataValue(this.state.user);

        } else {
            this.setState({ 
                isLoading: false
            })
        }
    }

    getUser = async () => {
        try {
            let data = await API.get("accapi", `/users/${this.props.match.params.uid}`);
            this.setState({ 
                user: data,
                isLoading: false,
            })
        }catch(e) {
            this.setState({
                isLoading: false,
                error: true,
            })
        }
    }

    handleFormSubmit = async () => {
        let formValidation = this.state.formValidation;
        let validationNames = ["first_name", "last_name"];

        validationNames.map((name) => {
            this.requiredValidationHandler(name);
        });
   
        if(!formValidation.first_name && !formValidation.last_name) {
        
        // Reset Submit Status
        this.setState({ submitStatus: 0 })

        this.showModal("submit-modal");
        this.setState({
            progressMessage: "Updating..."
        })
        let data = this.state.data;

        // Edit User
        try {
            let response = await API.put("accapi", `/users/${data.uid}`, { "body": data});
            this.setState({ 
                submitStatus: this.state.submitStatus + 1,
                progressMessage: "User Saved.",
            });
        } catch(e) {
            this.setModalError(e.response)
            console.log(e)
            }
        } else {
            this.setState({ 
                activeKey: "1"
            });
        }
    }

    handleModalSubmit = async (edit) => {
        let modalData = this.state.modalData;
        let modalValidation = this.state.modalValidation;
        let selectedOrganization = this.state.selectedOrganization;

        // check validation
        if(edit) {
            this.validationHandler(null, "roles", null, "internalAdmin");
        } else {
            this.validationHandler("email", "roles", "org", "internalAdmin");
        }
        
        if(!modalValidation.roles && 
            !modalValidation.selectedOrganization && 
            !modalValidation.validRedawningEmail) {

            this.closeModal();
            this.setState({ isLoading: true})
            
            if(edit) {
                // edit organization user roles
                let currentUserData = this.state.currentUserData;
                try {
                    await API.post(constants.ACCAPI.NAME,  constants.ACCAPI.EDIT_USER(currentUserData.organization_name, encodeURIComponent(this.state.user.uid)), { body: modalData.roles, response: true });
                    await this.getUser();
    
                    this.setState({ 
                        isLoading: false,
                        isSaved: true,
                        showModal: "Notification Modal",
                        modalMsg: `Successfully Edited ${currentUserData.title}`,
                    })
                } catch(err) {
                    let errMessage = err.response.data.Message;
                    if(errMessage) {
                        this.setState({ 
                            isLoading: false,
                            showModal: "Notification Modal",
                            modalMsg: errMessage,
                        })
                    }
                }
            } else {
                // add organization to user
                try {
                    await API.post(constants.ACCAPI.NAME,  constants.ACCAPI.GET_USER(selectedOrganization.organization_name), { body: modalData, response: true });
                    await this.getUser();
    
                    this.setState({ 
                        isLoading: false,
                        isSaved: true,
                        showModal: "Notification Modal",
                        modalMsg: `Successfully Added User to ${selectedOrganization.title ? selectedOrganization.title: selectedOrganization.organization_name}`,
                    })
                } catch(err) {
                    let errMessage = err.response.data.Message;
                    if(errMessage && errMessage.includes("Duplicate")) {
                        this.setState({ 
                            isLoading: false,
                            showModal: "Notification Modal",
                            modalMsg: "User Already Exists",
                        })
                    }
                }
            }
        } 
    }

    handleDeleteUserFromOrg = async () => {
        let data = this.state.data;
        let currentUserData = this.state.currentUserData;

        this.closeModal();
        this.setState({ isLoading: true });

        try {
            await API.del(constants.ACCAPI.NAME, constants.ACCAPI.DELETE_USER(currentUserData.organization_name, encodeURIComponent(data.uid)));
            await this.getUser();
            this.setState({ 
                isLoading: false,
                isSaved: true,
                showModal: "Notification Modal",
                modalMsg: `Successfully Deleted User From ${currentUserData.title ? currentUserData.title : currentUserData.organization_name}`,
            });
        } catch(err) {
            console.log(err)
            this.setState({
                isLoading: false,
                isSaved: false,
                showModal: "Notification Modal",
                modalMsg: 'Error Deleting User',
            })
        }
    }
    
    handleChange = (event) => {
        let newData = this.state.data;
		let name = event.target.getAttribute("name");
        let eventValue = event.target.value;
        let data = ["uid"]
    
        if(data.includes(name)) {
            this.setState({ 
                data: {...newData, [name]: eventValue}
            });
        } else {
            this.setState({ 
                data: 
                    {...newData, contact_info: 
                        {...this.state.data.contact_info, 
                            [name]: eventValue}}
            }, () => this.requiredValidationHandler(name));
        }
    }

    toggleGlyph = (e, selectedKey) => {
		e.preventDefault();
   		const { activeKey } = this.state;

		if (activeKey !== selectedKey) {     
            this.setState({
                activeKey: selectedKey
            })	
		} else {
            this.setState({activeKey: null})
		}
    }

    getTooltip = (id,text) => {
        return <Tooltip id={id} className='properties__tooltip'>{text}</Tooltip>
    }

    closeModal = () => {
        this.setState({
            showModal: false,
        });
    }

    showModal(modalName) {
		this.setState({
			showModal: modalName,
		});
    }

    setModalError = (error) => {
        this.setState({ 
            submitStatus: this.state.submitStatus + 1,
            modalError: {
                show: true,
                message: "Error Saving User. Please contact Customer Support.",
                detail: error && error.data && error.data.Code,
            }
        });
    }

    openEditUserModal = (data) => {
        this.setState({
            showModal: "EditUserModal",
            currentUserData: data,
            editCurrentOrganizationName: data.organization_name,
            modalData: 
                {...this.state.modalData,
                    roles: [...data.roles],
            },
            modalValidation: 
                {...this.state.modalValidation,
                    roles: false,
                    selectedOrganization: false,
                    validRedawningEmail: false,
                },

        });
    }

    openDeleteUserFromOrgModal = (data) => {
        this.setState({
            showModal: "Delete User From Org",
            currentUserData: data,
        });
    }

    openAddUserModal = () => {
        this.setState({
            showModal: "AddUserModal",
            selectedOrganization: [],
            modalData: 
                {...this.state.modalData,
                    roles: [],
            },
            modalValidation: 
                {...this.state.modalValidation,
                    roles: false,
                    selectedOrganization: false,
                    validRedawningEmail: false,
                },

        });
    }

    closeModal = () => {
        this.setState({
            showModal: false,
            selectedOrganization: "",
            currentUserData: "",
            modalData: 
                {...this.state.modalData,
                    roles: [],
            },
            modalValidation: 
                {...this.state.modalValidation,
                    roles: false,
                    selectedOrganization: false},

        });
    }

    toggleCheck = (e) => {
        let roles = this.state.modalData.roles;
        let name = e.target.name;
     
         if (roles.indexOf(name) > -1) {
             roles.splice(roles.indexOf(name), 1);
         } else {
             roles.push(name);
         }
         this.setState({ 
             data: {...this.state.data, roles: roles},
             modalValidation: {...this.state.modalValidation, roles: false}},
             () => this.validationHandler(null, "roles", null, "internalAdmin"));
     }

    onBlurChange = (e) => {
        let name = e.target.name;
        let formValidation = this.state.formValidation;

        if(!this.state.data.contact_info[name].length) {
            formValidation[name] = true;
        } else {
            formValidation[name] = false;
        }

        this.setState({ 
            formValidation: this.state.formValidation
        });
    }

    requiredValidationHandler = (name) => {
        let firstNameData = this.state.data.contact_info.first_name;
        let lastNameData = this.state.data.contact_info.last_name;
        let formValidation = this.state.formValidation;

        if(name === "first_name") {
            if(!firstNameData.length) {
                formValidation.first_name = true;
            } else {
                formValidation.first_name = false;
            }
        }
        if(name === "last_name") {
            if(!lastNameData.length) {
                formValidation.last_name = true;
            } else {
                formValidation.last_name = false;
            }
        }

        this.setState({ 
            formValidation: formValidation
        });
    }

    validationHandler = (email, roles, org, internalAdmin) => {
        let emailData = this.state.modalData.email;
        let rolesData = this.state.modalData.roles;
        let modalValidation = this.state.modalValidation;
        let orgData = this.state.selectedOrganization;

        if(roles) {
            if(!rolesData.length) {
                modalValidation.roles = true;
            } else {
                modalValidation.roles = false;
            }
        }
        if(org) {
            if(orgData.length === 0) {
                modalValidation.selectedOrganization = true;
            } else {
                modalValidation.selectedOrganization = false;
            }
        }
        if(internalAdmin) {
            if(emailData.length > 0 && 
                emailData.split("@")[1] !== "redawning.com" && 
                rolesData.indexOf(constants.USER_TYPES.INTERNAL_ACCOUNT_ADMIN) > -1) {
                modalValidation.validRedawningEmail = true;
            } else {
                modalValidation.validRedawningEmail = false;
            }
        }

        this.setState({ 
            modalValidation: this.state.modalValidation
        });
    }

    generateUserTypeArray = () => {
        let data = [];
        for (let key in constants.USER_TYPES) {
            data.push(
                <InputField 
                    key={key}
                    type="checkbox" 
                    value={this.state.modalData.roles.indexOf(constants.USER_TYPES[key]) > -1} 
                    name={constants.USER_TYPES[key]} 
                    cbChange={this.toggleCheck}>
                    {(constants.USER_TYPES[key]).replace(/([A-Z])/g, ' $1').trim()}
                </InputField>)
        }
        return (
            <Col md={10} className="checkbox-group padding-box panel-heading">
                {data}
            </Col>
        )
    }

    render() {
        let { isLoading, data, error } = this.state,
        breadcrumbItems = [{
            title: "Users",
            link: "/account-management/users"
        },
        {
            title: this.props.match.params.uid
        }]

        let countries = 
            [<option key="Select" value="">Select Country</option>].concat(constants.PROPERTY_VIEW.COUNTRIES.map(country=>{
			return(<option key={country.Code} value={country.Code}>{country.Name}</option>);
        }));

        let organizationNameAndTitleList = [];
        this.state.allOrganization.map((org) => {
            let organizationList = {};
            organizationList['title'] = org.title ? org.title : org.organization_name;
            organizationList['organization_name'] = org.organization_name;
            organizationNameAndTitleList.push(organizationList);
        });

        return (
            <PageLayout isCentered={true}>

                {isLoading && <Loader />}

                {error && <div className="container-fluid">
                    <Title 
                        title="Edit User"
                        isCentered={true} 
                        breadcrumbItems={this.breadcrumbItems}
                    >
                        <Link 
                            to={'/account-management/users'} 
                            className="primary-btn black-btn pull-left left-margin">
                            Back to Users
                        </Link>
                    </Title>
                    <div id="content" style={{ height: '70vh' }}>
                        <Col>
                            <div className="no-collection">User not found.</div>
                        </Col>
                    </div>
                </div>}
                {Object.entries(this.state.user).length > 0 && !error && <div className="container-fluid">
                    <Title 
                        title={
                            <Fragment>
                                Edit User {data.contact_info.email ? data.contact_info.email : ""}
                            </Fragment>
                            } 
                        isCentered={true} 
                        removeOrganizationDropdown={true}
                        addDefaultTitle="Account Management"
                        breadcrumbItems={breadcrumbItems}
                    >
                        <Link 
                            to={'/account-management/users'} 
                            className="primary-btn black-btn pull-left left-margin">
                            Cancel
                        </Link>
                        <PrimaryButton 
                            cssClass="pull-left left-margin" 
                            fullWidth={false} 
                            type="button" 
                            onClick={this.handleFormSubmit}>
                            Save
                        </PrimaryButton>
                    </Title>

                    <div id="content">
                        <div className="property-item row">
                            <FieldWrap>
                                <Form horizontal>
                                    <Row>
                                        <Col md={12} xs={12} sm={12} lg={12}>
                                            <PanelGroup 
                                                accordion id="accordion-controlled-example" 
                                                activeKey={this.state.activeKey}
                                                onSelect={
                                                    (activeKey) => {
                                                        this.setState({ activeKey })
                                                    }
                                                }
                                            >
                                                <Panel eventKey='1'>
                                                    <Panel.Heading>
                                                        <Panel.Title 
                                                            toggle
                                                            onClick={e => {this.toggleGlyph(e, "1")}}
                                                        > 
                                                            User Details
                                                                <span 
                                                                    className={`pull-right 
                                                                        ${(this.state.activeKey === '1' ?
                                                                        'glyphicon glyphicon-chevron-up' :
                                                                        'glyphicon glyphicon-chevron-down')}`}
                                                                >
                                                                </span>
                                                        </Panel.Title>
                                                    </Panel.Heading>
                                                    <Panel.Body collapsible>
                                                        <Row>
                                                            <Col md={4} className="prop-type-col">
                                                                <InputLabel 
                                                                    htmlFor="title"
                                                                    className={this.state.formValidation.first_name && 'form-validation'}
                                                                >
                                                                    First Name
                                                                </InputLabel>
                                                                <InputField 
                                                                    type="text"
                                                                    className={this.state.formValidation.first_name && 'form-validation'}
                                                                    placeholder="First Name"
                                                                    name="first_name" 
                                                                    value={data.contact_info.first_name}
                                                                    onBlur={this.onBlurChange} 
                                                                    onChange={this.handleChange}
                                                                />
                                                                {this.state.formValidation.first_name && <span className="required-error-text">First Name is required</span>}
                                                            </Col>
                                                            <Col md={4} className="prop-type-col">
                                                                <InputLabel 
                                                                    htmlFor="title"
                                                                    className={this.state.formValidation.last_name && 'form-validation'}
                                                                >
                                                                    Last Name
                                                                </InputLabel>
                                                                <InputField 
                                                                    type="text"
                                                                    className={this.state.formValidation.last_name && 'form-validation'}
                                                                    placeholder="Last Name"
                                                                    name="last_name" 
                                                                    value={data.contact_info.last_name}
                                                                    onBlur={this.onBlurChange} 
                                                                    onChange={this.handleChange}
                                                                />
                                                                {this.state.formValidation.last_name && <span className="required-error-text">Last Name is required</span>}
                                                            </Col>
                                                            <Col md={4} className="prop-type-col">
                                                                <InputLabel htmlFor="title">
                                                                    Email Address
                                                                </InputLabel>
                                                                    <span className="readOnly-text">{data.contact_info.email}</span>
                                                            </Col>
                                                        </Row>
                                                        <br />
                                                        <Row>
                                                            <Col md={4} className="prop-type-col">
                                                                <InputLabel htmlFor="title">
                                                                    Home Phone
                                                                </InputLabel>
                                                                <InputField 
                                                                    type="text"
                                                                    placeholder="Home Phone"
                                                                    name="home_phone" 
                                                                    value={data.contact_info.home_phone} 
                                                                    onChange={this.handleChange}
                                                                />
                                                            </Col>
                                                            <Col md={4} className="prop-type-col">
                                                                <InputLabel htmlFor="title">
                                                                    Office Phone
                                                                </InputLabel>
                                                                <InputField 
                                                                    type="text"
                                                                    placeholder="Office Phone"
                                                                    name="work_phone" 
                                                                    value={data.contact_info.work_phone} 
                                                                    onChange={this.handleChange}
                                                                />
                                                            </Col>
                                                            <Col md={4} className="prop-type-col">
                                                                <InputLabel htmlFor="title">
                                                                    Mobile Phone
                                                                </InputLabel>
                                                                <InputField 
                                                                    type="text"
                                                                    placeholder="Mobile Phone"
                                                                    name="mobile_phone" 
                                                                    value={data.contact_info.mobile_phone} 
                                                                    onChange={this.handleChange}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <br />
                                                        <Row>
                                                        <Col md={4} className="prop-type-col">
                                                                <InputLabel htmlFor="title">
                                                                    Address
                                                                </InputLabel>
                                                                <InputField 
                                                                    type="text"
                                                                    placeholder="Address"
                                                                    name="street_address" 
                                                                    value={data.contact_info.street_address} 
                                                                    onChange={this.handleChange}
                                                                />
                                                            </Col>
                                                            <Col md={4} className="prop-type-col">
                                                                <InputLabel htmlFor="title">
                                                                    City
                                                                </InputLabel>
                                                                <InputField 
                                                                    type="text"
                                                                    placeholder="City"
                                                                    name="city" 
                                                                    value={data.contact_info.city} 
                                                                    onChange={this.handleChange}
                                                                />
                                                            </Col>
                                                            <Col md={4} className="prop-type-col">
                                                                <InputLabel htmlFor="title">
                                                                    State / Province
                                                                </InputLabel>
                                                                <InputField 
                                                                    type="text" 
                                                                    placeholder="State / Province" 
                                                                    name="province" 
                                                                    value={data.contact_info.province} 
                                                                    onChange={this.handleChange}
                                                                >
                                                                </InputField>
                                                            </Col>
                                                        </Row>
                                                        <br />
                                                        <Row>
                                                            <Col md={4} className="prop-type-col">
                                                                <InputLabel htmlFor="title">
                                                                    ZIP / Postal Code
                                                                </InputLabel>
                                                                <InputField 
                                                                    type="text"
                                                                    placeholder="ZIP / Postal Code"
                                                                    name="postal_code" 
                                                                    value={data.contact_info.postal_code} 
                                                                    onChange={this.handleChange}
                                                                />
                                                            </Col>
                                                            <Col md={4} className="prop-type-col">
                                                                <InputLabel htmlFor="title">
                                                                    Country
                                                                </InputLabel>
                                                                <InputField 
                                                                    type="select" 
                                                                    placeholder="Country" 
                                                                    name="country" 
                                                                    value={data.contact_info.country} 
                                                                    onChange={this.handleChange}
                                                                >
                                                                    {countries}
                                                                </InputField>
                                                            </Col>
                                                        </Row>
                                                        <br />
                                                    </Panel.Body>
                                                </Panel>
                                                <Panel eventKey='2'>
                                                    <Panel.Heading>
                                                        <Panel.Title 
                                                                toggle
                                                                onClick={e => {this.toggleGlyph(e, "2")}}
                                                            > 
                                                                Organizations & Roles
                                                                <span 
                                                                    className={`pull-right
                                                                        ${(this.state.activeKey === '2' ?
                                                                        'glyphicon glyphicon-chevron-up' :
                                                                        'glyphicon glyphicon-chevron-down')}`}
                                                                >
                                                                </span>
                                                        </Panel.Title>
                                                    </Panel.Heading>
                                                    <Panel.Body collapsible>
                                                        <Col xs={12}>
                                                            <TableComponent className='-highlight'
                                                                resizable={false} minRows={0}
                                                                loading={this.state.isLoading}
                                                                data={this.state.user.organization_roles}
                                                                columns={this.state.columns}
                                                                showPagination={false}
                                                                manual
                                                                >
                                                            </TableComponent>
                                                            <br/>
                                                            <PrimaryButton 
                                                                cssClass="primary-btn" 
                                                                fullWidth={false}
                                                                onClick={this.openAddUserModal}
                                                            >
                                                                Add User to Organization
                                                            </PrimaryButton>
                                                            <br/>
                                                        </Col>
                                                    </Panel.Body>
                                                </Panel>
                                            </PanelGroup>
                                        </Col>
                                    </Row>
                                    <Modal
                                        className="submit-modal" 
                                        show={this.state.showModal === "AddUserModal"} 
                                        onHide={this.closeModal}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>
                                                Add User to Organization
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className='edit-user-modal'>
                                                <Row>
                                                    <Col sm={12} xs={12} md={12} className="model-margin">
                                                        <InputLabel 
                                                            htmlFor="email"
                                                        >
                                                            Email
                                                        </InputLabel>
                                                        <span 
                                                            className="readOnly-text">
                                                            {this.state.data.contact_info.email}
                                                        </span>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={12} xs={12} md={12} className="model-margin">
                                                        <InputLabel 
                                                            htmlFor="organizations"
                                                            className={this.state.modalValidation.selectedOrganization && 'form-validation'}
                                                        >
                                                            Organization
                                                        </InputLabel>
                                                        <Picky
                                                            className={this.state.modalValidation.selectedOrganization && 'form-validation'}
                                                            placeholder=""
                                                            labelKey="title"
                                                            valueKey="organization_name"
                                                            filterPlaceholder="Search by Organization Name"
                                                            options={organizationNameAndTitleList}
                                                            value={this.state.selectedOrganization.title ? 
                                                                this.state.selectedOrganization.title : 
                                                                this.state.selectedOrganization.organization_name}
                                                            multiple={false}
                                                            includeSelectAll={false}
                                                            defaultFocusFilter={true}
                                                            includeFilter={true}
                                                            keepOpen={false}
                                                            onChange={value => {
                                                                this.setState({ selectedOrganization: value }, 
                                                                () => this.validationHandler(null, null, "org"))}}
                                                            dropdownHeight={600}
                                                            render={({
                                                                style,
                                                                item,
                                                                isSelected,
                                                                selectValue,
                                                                labelKey,
                                                                valueKey,
                                                            }) => {
                                                                return (
                                                                <li
                                                                    style={{ ...style }} 
                                                                    className={isSelected ? "selected" : ""} 
                                                                    key={item[valueKey]}
                                                                    onClick={() => selectValue(item)}
                                                                >
                                                                    <span
                                                                    style={{
                                                                        fontWeight: isSelected ? "bold" : "normal"
                                                                    }}
                                                                    >
                                                                    {item[labelKey] !== null && item[labelKey] !== "" ? item[labelKey] : item[valueKey]}
                                                                    </span>
                                                                </li>
                                                                );
                                                            }}
                                                        />
                                                        {this.state.modalValidation.selectedOrganization && 
                                                            <span className="required-error-text">
                                                                Organization is required
                                                            </span>}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={12} xs={12} md={12} className="checkbox-group padding-box panel-heading">
                                                        <InputLabel
                                                            className={this.state.modalValidation.roles && 'form-validation'}
                                                            cssClass="col-md-12 float-left padding-box" 
                                                            htmlFor="roles"
                                                        >
                                                            Roles
                                                        </InputLabel>
                                                        <Col sm={12} xs={12} md={12}>
                                                            {this.generateUserTypeArray()}
                                                        </Col>
                                                        {this.state.modalValidation.roles && 
                                                            <Col sm={12} xs={12} md={12}>
                                                                <span className="col-md-12 error-text">
                                                                    At least one role is required
                                                                </span>
                                                            </Col>}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={12} xs={12} md={12}>
                                                        <PrimaryButton
                                                            onClick={() => this.handleModalSubmit()} 
                                                            cssClass="primary-btn pull-left margin-btn top-margin" 
                                                            fullWidth={false} 
                                                            type="button">
                                                            Save
                                                        </PrimaryButton>
                                                    </Col>
                                                    <Col sm={12} xs={12} md={12}>
                                                        {this.state.modalValidation.validRedawningEmail && 
                                                        <span className="col-md-12 error-text">
                                                            Internal Account Admin can only be assigned to users with a RedAwning email address.
                                                        </span>}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                    <Modal
                                        className="submit-modal" 
                                        show={this.state.showModal === "EditUserModal"} 
                                        onHide={this.closeModal}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>
                                                Edit User Organization
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className='edit-user-modal'>
                                                <Row>
                                                    <Col sm={12} xs={12} md={12} className="model-margin">
                                                        <InputLabel 
                                                            htmlFor="email"
                                                        >
                                                            Email
                                                        </InputLabel>
                                                        <span 
                                                            className="readOnly-text">
                                                            {this.state.data.contact_info.email}
                                                        </span>
                                                        <InputLabel 
                                                            htmlFor="organizations"
                                                        >
                                                            Organization
                                                        </InputLabel>
                                                        <span 
                                                            className="readOnly-text">
                                                            {this.state.currentUserData.title ? 
                                                                this.state.currentUserData.title :
                                                                this.state.currentUserData.organization_name}
                                                        </span>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={12} xs={12} md={12} className="checkbox-group padding-box panel-heading">
                                                        <InputLabel
                                                            className={this.state.modalValidation.roles && 'form-validation'}
                                                            cssClass="col-md-12 float-left padding-box" 
                                                            htmlFor="roles"
                                                        >
                                                            Roles
                                                        </InputLabel>
                                                        <Col sm={12} xs={12} md={12}>
                                                            {this.generateUserTypeArray()}
                                                        </Col>
                                                        {this.state.modalValidation.roles && 
                                                            <Col sm={12} xs={12} md={12}>
                                                                <span className="col-md-12 error-text">
                                                                    At least one role is required
                                                                </span>
                                                            </Col>}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={12} xs={12} md={12}>
                                                        <PrimaryButton
                                                            onClick={() => this.handleModalSubmit("edit")} 
                                                            cssClass="primary-btn pull-left margin-btn top-margin" 
                                                            fullWidth={false} 
                                                            type="button">
                                                            Save
                                                        </PrimaryButton>
                                                    </Col>
                                                    <Col sm={12} xs={12} md={12}>
                                                        {this.state.modalValidation.validRedawningEmail && 
                                                        <span className="col-md-12 error-text">
                                                            Internal Account Admin can only be assigned to users with a RedAwning email address.
                                                        </span>}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                    <Modal 
                                        className="submit-modal" 
                                        show={this.state.showModal === 'Notification Modal'} 
                                        onHide={this.closeModal}
                                    >
                                        <Modal.Header closeButton>
                                            <Modal.Title>
                                                {this.state.isSaved === true ? (
                                                    <div>
                                                        <span className='status-icon status-icon--success'></span>
                                                        <span className="submit-heading">{this.state.modalMsg}</span>
                                                    </div>) :
                                                    (<div>
                                                        <span className='status-icon status-icon--error'></span>
                                                        <span className="submit-heading">{this.state.modalMsg}</span>
                                                    </div>)}
                                            </Modal.Title>
                                        </Modal.Header>
                                    </Modal>
                                    <Modal 
                                            className="delete-room-modal xs-modal" 
                                            show={this.state.showModal === "Delete User From Org"} 
                                            onHide={this.closeModal}
                                        >
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    Confirm
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <Row>
                                                    <Col xs={12} className="bottom-margin">
                                                        {this.state.currentUserData ? 
                                                            <p>Are you sure you want to delete this user from this organization?</p> :(<div/>)}
                                                    </Col>
                                                    <div className="modal-btns">
                                                        <PrimaryButton 
                                                            cssClass="transparent-btn pull-left" 
                                                            fullWidth={false} 
                                                            onClick={this.closeModal}
                                                        >
                                                            No
                                                        </PrimaryButton>
                                                        <PrimaryButton 
                                                            cssClass="pull-left left-margin" 
                                                            fullWidth={false} 
                                                            onClick={e => this.handleDeleteUserFromOrg()}
                                                        >
                                                            Yes
                                                        </PrimaryButton>
                                                    </div>
                                                </Row>
                                            </Modal.Body>
                                        </Modal>
                                    <GlobalSubmitModal
                                        showViewAll={true}
                                        mode={this.state.mode}
                                        activeModal={this.state.showModal}
                                        submitModalTotal={1}
                                        closeModal={this.closeModal}
                                        submitStatus={this.state.submitStatus}
                                        progressMessage={this.state.progressMessage}
                                        error={this.state.modalError}
                                        continueUrl={`/account-management/users/${this.props.match.params.uid}/edit`}
                                        viewAllLabel={"View All Users"}
                                        viewAllUrl={"/account-management/users"}
								    />
                                </Form>
                            </FieldWrap>
                        </div>
                    </div>
                </div>}
            </PageLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.authState.user,
        organizations: state.authState.user.expanded_organization_roles,
        roleManager: state.roleManager
    }
}

export default withRouter(connect(
    mapStateToProps,
    null
)(EditUser));