const USER_TYPES = {
  INTERNAL_ACCOUNT_ADMIN: 'InternalAccountAdmin',
  PROPERTY_MANAGER_ADMIN: 'PropertyManagerAdmin',
  PROPERTY_MANAGER_VIEWER: 'PropertyManagerViewer',
  WEBSITE_ADMIN: 'WebsiteAdmin',
  ACCOUNT_MANAGER_ADMIN: 'AccountManagerAdmin',
  ACCOUNT_MANAGER_VIEWER: 'AccountManagerViewer',
  SMART_CONCIERGE_ADMIN: 'SmartConciergeAdmin',
  SMART_CONCIERGE_VIEWER: 'SmartConciergeViewer',
  MESSAGING_ADMIN: 'MessagingAdmin',
  MESSAGING_TEMPLATE_ADMIN: 'MessagingTemplateAdmin',
  MESSAGING_EDITOR: 'MessagingEditor',
  MESSAGING_VIEWER: 'MessagingViewer',
  GUEST_SERVICES: 'GuestServices',
  HOST_SUPPORT: 'Host Support',
  FULL_SERVICE_HOST: 'FullServiceHost',
  FINANCE: 'Finance',
  REVIEWS_ADMIN: 'ReviewsAdmin',
  REVIEWS_VIEWER: 'ReviewsViewer'
}

export const STRING_CONSTANTS = {
  PROPERTY_NOT_FOUND: 'Property not found. Please contact Customer Support.',
  NO_ACCESS_PROPERTY: 'You don\'t have permissions to access this property',
}
const Constants = {
    STATUS_CHECK_MAX: 100,
    FEATURE_FLAG: 'feature_flag',
    FEATURE_COLLECTIONS: 'collections',
    RAPAPI: {
        NAME: 'rapapi',
        PROPERTY_RESERVATIONS: '/properties/reservations',
        PROPERTY_RESERVATIONS_BY_ID: id=>`/properties/reservations/${id}`,
        PROPERTY_RESERVATIONS_INVOICES_BY_ID: id=>`/properties/reservations/${id}/invoices`,
        PROPERTY_RESERVATIONS_BY_ID_PROMOTIONS: id=>`/properties/reservations/${id}/promotions`,
        PROPERTY_RESERVATIONS_BY_ID_POLICIES: id => `/properties/reservations/${id}/policies`,
        LISTINGS: '/listings',
        PROPERTIES: '/properties',
        PERMISSIONS: '/permissions',
        PROPERTY_PERFORMANCE: '/reports/booking_payments_by_property_id',
        REVENUE: '/reports/total_payments',
        EARNINGS_BY_DATE: '/reports/booking_payments_by_date',
        AVAILABILITY_FEED: id=>`/properties/${id}/availabilityfeed`,
        PROMOTION_BY_ID: promotionId=> { return `/promotion/${promotionId}`},
        PROMOTION_TRANSACTION_BY_RESERVATION_ID: id=>{ return `/promotion/transaction/${id}`},  
        PROMOTIONS_LIST: (tid,pageSize,offset,sort,sortDesc,keyword,startDate,endDate,status)=>{ return `/promotions?tid=${tid}&limit=${pageSize}&offset=${offset}&keyword=${encodeURIComponent(keyword)}&end_date=${startDate ? `${startDate}` : ""}${endDate ? `:${endDate}` : ""}&status=${status}`},
        LIMIT: 50
    },
    ACCAPI: {
      NAME: "accapi",
      CURRENT_USER: '/currentuser',
      USER_REGISTRATION: (id)=> { return `/userregistration/${id}`},
      GET_USER: (organization_name)=> { return`/organizations/${organization_name}/users`},
      EDIT_USER:(organization_name,uid)=>{ return`/organizations/${organization_name}/users/${uid}`},
      DELETE_USER:(organization_name,uid)=>{ return`/organizations/${organization_name}/users/${uid}`},
      GET_PAYACC: (organization_name)=> { return`/organizations/${organization_name}/payacc`},
      CONFIRM_PAYACC: (organization_name)=> { return`/organizations/${organization_name}/payacc`},
    },
    NUM_FORMAT: (num) => { return num.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})},
    generateUUID: () => {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },
    EMAIL_VALIDATION: /^([a-zA-Z0-9_\.\-+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
    COGNITO: APIConfig.COGNITO,
    ROLES_ERROR_MESSAGE:"Please select at least one role for the user",
    EMAIL_INVALID_MESSAGE:"Invalid email format",
    USER_ROLES: {
      [USER_TYPES.INTERNAL_ACCOUNT_ADMIN]: {
        '/dashboard/:tabs?/:scroll_to?' : 'Dashboard',
        '/threads/:id' : 'Dashboard',
        '/account-management/organizations': 'Organizations',
        '/account-management/organizations/create': 'AddOrganization',
        '/account-management/organizations/:organizationName/edit': 'AddOrganization',
        '/account-management/users': 'Users',
        '/account-management/users/create': 'EditUser',
        '/account-management/users/:uid/edit': 'EditUser',
        '/account-management/promotions': 'Promotions',
        '/account-management/promotions/create': 'AddPromotion', 
        '/account-management/promotions/:promotionId/edit': 'EditPromotion',
        '/account-management/support-tickets': 'SupportTicketsSearch',
        '/properties': 'Properties',
        '/reservations': 'PropertyReservationListing',
        '/reservations/:reservation_id/details': 'PropertyReservationDetail',
        '/reservations/:reservation_id/report-a-problem': 'ReportProblemReservation',
        '/reservations/create': 'CreateReservation',
        '/properties/create': 'AddProperty',
        '/properties/:property_id/edit': 'EditProperty',
        '/properties/:property_id/fees/:id': 'AddFeeSchedule',
        '/calendar': 'Pricing',
        '/calendar/:property_id/edit': 'Pricing',
        '/collections': 'Collection',
        '/collections/create': 'AddCollection',
        '/collections/:collectionId/edit': 'AddCollection',
        '/collections/:collectionId': 'Collection',
        '/collections/:collectionId/rooms/create': 'AddRoom',
        '/collections/:collectionId/rooms/:roomId': 'Rooms',
        '/collections/:collectionId/rooms/:roomId/edit': 'EditRoom',
        '/collections/:collectionId/rooms/:roomId/fees/:id': 'AddFeeSchedule',
        '/cleaning': 'Cleaning',
        '/fees': 'FeesSchedule',
        '/fees/global/:id' : 'AddFeeSchedule',
        '/account/faq' : 'Faq',
        '/account/faq/:id' : 'Faq',
        '/account/organization-details': 'OrganizationDetails',
      },
      [USER_TYPES.PROPERTY_MANAGER_ADMIN]: {
        '/dashboard/:tabs?/:scroll_to?' : 'Dashboard',
        '/threads/:id' : 'Dashboard',
        '/properties': 'Properties',
        '/account-management/support-tickets': 'SupportTicketsSearch',
        '/reservations': 'PropertyReservationListing',
        '/reservations/:reservation_id/details': 'PropertyReservationDetail',
        '/reservations/:reservation_id/report-a-problem': 'ReportProblemReservation',
        '/reservations/create': 'CreateReservation',
        '/properties/create': 'AddProperty',
        '/properties/:property_id/edit': 'EditProperty',
        '/properties/:property_id/fees/:id': 'AddFeeSchedule',
        '/calendar': 'Pricing',
        '/calendar/:property_id/edit': 'Pricing',
        '/collections': 'Collection',
        '/collections/create': 'AddCollection',
        '/collections/:collectionId/edit': 'AddCollection',
        '/collections/:collectionId': 'Collection',
        '/collections/:collectionId/rooms/create': 'AddRoom',
        '/collections/:collectionId/rooms/:roomId': 'Rooms',
        '/collections/:collectionId/rooms/:roomId/edit': 'EditRoom',
        '/collections/:collectionId/rooms/:roomId/fees/:id': 'AddFeeSchedule',
        '/cleaning': 'Cleaning',
        '/fees': 'FeesSchedule',
        '/fees/global/:id' : 'AddFeeSchedule',
        '/account/faq' : 'Faq',
        '/account/faq/:id' : 'Faq',
      },
      [USER_TYPES.PROPERTY_MANAGER_VIEWER]: {
        '/dashboard/:tabs?/:scroll_to?' : 'Dashboard',
        '/threads/:id' : 'Dashboard',
        '/properties': 'Properties',
        '/properties/:property_id/edit': 'EditProperty',
        '/properties/:property_id/fees/:id': 'AddFeeSchedule',
        '/reservations': 'PropertyReservationListing',
        '/reservations/:reservation_id/details': 'PropertyReservationDetail',
        '/reservations/:reservation_id/report-a-problem': 'ReportProblemReservation',
        '/calendar': 'Pricing',
        '/calendar/:property_id/edit': 'Pricing',
        '/collections': 'Collection',
        '/collections/:collectionId/edit': 'AddCollection',
        '/collections/:collectionId': 'Collection',
        '/collections/:collectionId/rooms/:roomId': 'Rooms',
        '/collections/:collectionId/rooms/:roomId/edit': 'EditRoom',
        '/collections/:collectionId/rooms/:roomId/fees/:id': 'AddFeeSchedule',
        '/cleaning': 'Cleaning',
        '/fees': 'FeesSchedule',
        '/fees/global/:id' : 'AddFeeSchedule',
        '/account/faq' : 'Faq',
        '/account/faq/:id' : 'Faq',
      },
      [USER_TYPES.SMART_CONCIERGE_ADMIN]: {
        '/smart-concierge': 'SmartConcierge',
        '/smart-concierge/:level/:propertyId': 'SmartConcierge',
        '/smart-concierge/:level/:propertyId/rooms/:roomId': 'SmartConcierge',
        '/smart-concierge/:level/:collectionId': 'SmartConcierge',
        '/smart-concierge/:level/:collectionId/properties/:propertyId': 'SmartConcierge',
        '/smart-concierge/:level/:collectionId/rooms/:roomId': 'SmartConcierge',
      },
      [USER_TYPES.SMART_CONCIERGE_VIEWER]: {
        '/smart-concierge': 'SmartConcierge',
        '/smart-concierge/:level/:propertyId': 'SmartConcierge',
        '/smart-concierge/:level/:propertyId/rooms/:roomId': 'SmartConcierge',
        '/smart-concierge/:level/:collectionId': 'SmartConcierge',
        '/smart-concierge/:level/:collectionId/properties/:propertyId': 'SmartConcierge',
        '/smart-concierge/:level/:collectionId/rooms/:roomId': 'SmartConcierge',
      },
      [USER_TYPES.ACCOUNT_MANAGER_ADMIN]: {
        '/account/organization-details': 'OrganizationDetails',
        '/account/users': 'User',
      },
      [USER_TYPES.ACCOUNT_MANAGER_VIEWER]: {
        '/account/users': 'User',
      },
      [USER_TYPES.WEBSITE_ADMIN]: {
        '/manage-website': 'ManageWebsite',
      },
      [USER_TYPES.HOST_SUPPORT]: {
        '/account-management/promotions': 'Promotions',
        '/account-management/promotions/create': 'AddPromotion', 
        '/account-management/promotions/:promotionId/edit': 'EditPromotion',
        '/account-management/chat': 'KnowledgeBot',
        '/account-management/support-tickets': 'SupportTicketsSearch',
      },
      [USER_TYPES.FULL_SERVICE_HOST]: {
        '/dashboard/:tabs?/:scroll_to?' : 'Dashboard',
        '/threads/:id' : 'Dashboard',
      },
      [USER_TYPES.FINANCE]: {
        '/dashboard/:tabs?/:scroll_to?' : 'Dashboard',
        '/threads/:id' : 'Dashboard',
        '/account-management/promotions': 'Promotions',
        '/account-management/promotions/create': 'AddPromotion', 
        '/account-management/promotions/:promotionId/edit': 'EditPromotion',
      },
      [USER_TYPES.GUEST_SERVICES]: {
        '/dashboard/:tabs?/:scroll_to?' : 'Dashboard',
        '/threads/:id' : 'Dashboard',
        '/properties': 'Properties',
        '/properties/:property_id/edit': 'EditProperty',
        '/properties/:property_id/fees/:id': 'AddFeeSchedule',
        '/reservations': 'PropertyReservationListing',
        '/reservations/:reservation_id/details': 'PropertyReservationDetail',
        '/reservations/:reservation_id/report-a-problem': 'ReportProblemReservation',
        '/reservations/create': 'CreateReservation',
        '/calendar': 'Pricing',
        '/calendar/:property_id/edit': 'Pricing',
        '/collections': 'Collection',
        '/collections/:collectionId/edit': 'AddCollection',
        '/collections/:collectionId': 'Collection',
        '/collections/:collectionId/rooms/:roomId': 'Rooms',
        '/collections/:collectionId/rooms/:roomId/edit': 'EditRoom',
        '/collections/:collectionId/rooms/:roomId/fees/:id': 'AddFeeSchedule',
        '/cleaning': 'Cleaning',
        '/fees': 'FeesSchedule',
        '/fees/global/:id' : 'AddFeeSchedule',
        '/account/faq' : 'Faq',
        '/account/faq/:id' : 'Faq',
        '/account-management/chat': 'KnowledgeBot',
        '/account-management/support-tickets': 'SupportTicketsSearch',
      },
    },
    CURRENCY: {
      "USD": "$",
      "CAD": "CAD",
      "MXN": "MXN",
      "EUR": "€",
      "GBP": "£",
      "IDR": "IDR",
      "AUD": "AU$",
      "PHP": "₱",
    },
    TIME_ZONE: [
      { label: "Eastern Time (ET)", value: "US/Eastern" },
      { label: "Central Time (CT)", value: "US/Central" },
      { label: "Mountain Time (MT)", value: "US/Mountain" },
      { label: "Pacific Time (PT)", value: "US/Pacific" },
      { label: "Alaska Time (AKT)", value: "America/Anchorage" },
      { label: "Hawaii-Aleutian Time (HST)", value: "Pacific/Honolulu" },
      { label: "Central European Time (CET)", value: "CET" },
      { label: "Eastern European Time (EET)", value: "EET" },
      { label: "Indian Std Time (IST)", value: "Asia/Kolkata" },
      { label: "China Std Time (CST)", value: "Asia/Shanghai" },
      { label: "Japan Std Time (JST)", value: "Asia/Tokyo" },
      { label: "Australian Eastern Std (AEST)", value: "Australia/Sydney" },
      { label: "New Zealand Std Time (NZST)", value: "Pacific/Auckland" },
      { label: "Brasília Time (BRT)", value: "America/Sao_Paulo" },
      { label: "Moscow Std Time (MSK)", value: "Europe/Moscow" },
      { label: "South Africa Std Time (SAST)", value: "Africa/Johannesburg" },
      { label: "Atlantic Std Time (AST)", value: "America/Halifax" },
      { label: "Argentina Time (ART)", value: "America/Argentina/Buenos_Aires" },
      { label: "Central Africa Time (CAT)", value: "Africa/Maputo" },
      { label: "Eastern Africa Time (EAT)", value: "Africa/Nairobi" },
      { label: "Singapore Std Time (SGT)", value: "Asia/Singapore" },
      { label: "Hong Kong Time (HKT)", value: "Asia/Hong_Kong" },
      { label: "Korea Std Time (KST)", value: "Asia/Seoul" },
      { label: "Iran Std Time (IRST)", value: "Asia/Tehran" },
      { label: "Arabian Std Time (AST)", value: "Asia/Dubai" },
      { label: "Israel Std Time (IST)", value: "Asia/Jerusalem" },
      { label: "Brazil Summer Time (BRST)", value: "America/Sao_Paulo" }
    ],
    TIME_15:[ 
      {
        label: "12:00 AM",
        value: "00:00:00Z"
      },
      {
        label: "12:30 AM",
        value: "00:30:00Z"
      },
      {
        label: "1:00 AM",
        value: "01:00:00Z"
      },
      {
        label: "1:30 AM",
        value: "01:30:00Z"
      },
      {
        label: "2:00 AM",
        value: "02:00:00Z"
      },
      {
        label: "2:30 AM",
        value: "02:30:00Z"
      },
      {
        label: "3:00 AM",
        value: "03:00:00Z"
      },
      {
        label: "3:30 AM",
        value: "03:30:00Z"
      },
      {
        label: "4:00 AM",
        value: "04:00:00Z"
      },
      {
        label: "4:30 AM",
        value: "04:30:00Z"
      },
      {
        label: "5:00 AM",
        value: "05:00:00Z"
      },
      {
        label: "5:30 AM",
        value: "05:30:00Z"
      },
      {
        label: "6:00 AM",
        value: "06:00:00Z"
      },
      {
        label: "6:30 AM",
        value: "06:30:00Z"
      },
      {
        label: "7:00 AM",
        value: "07:00:00Z"
      },
      {
        label: "7:30 AM",
        value: "07:30:00Z"
      },
      {
        label: "8:00 AM",
        value: "08:00:00Z"
      },
      {
        label: "8:30 AM",
        value: "08:30:00Z"
      },
      {
        label: "9:00 AM",
        value: "09:00:00Z"
      },
      {
        label: "9:30 AM",
        value: "09:30:00Z"
      },
      {
        label: "10:00 AM",
        value: "10:00:00Z"
      },
      {
        label: "10:30 AM",
        value: "10:30:00Z"
      },
      {
        label: "11:00 AM",
        value: "11:00:00Z"
      },
      {
        label: "11:30 AM",
        value: "11:30:00Z"
      },

      {
        label: "12:00 PM",
        value: "12:00:00Z"
      },
      {
        label: "12:30 PM",
        value: "12:30:00Z"
      },
      {
        label: "1:00 PM",
        value: "13:00:00Z"
      },
      {
        label: "1:30 PM",
        value: "13:30:00Z"
      },
      {
        label: "2:00 PM",
        value: "14:00:00Z"
      },
      {
        label: "2:30 PM",
        value: "14:30:00Z"
      },
      {
        label: "3:00 PM",
        value: "15:00:00Z"
      },
      {
        label: "3:30 PM",
        value: "15:30:00Z"
      },
      {
        label: "4:00 PM",
        value: "16:00:00Z"
      },
      {
        label: "4:30 PM",
        value: "16:30:00Z"
      },
      {
        label: "5:00 PM",
        value: "17:00:00Z"
      },
      {
        label: "5:30 PM",
        value: "17:30:00Z"
      },
      {
        label: "6:00 PM",
        value: "18:00:00Z"
      },
      {
        label: "6:30 PM",
        value: "18:30:00Z"
      },
      {
        label: "7:00 PM",
        value: "19:00:00Z"
      },
      {
        label: "7:30 PM",
        value: "19:30:00Z"
      },
      {
        label: "8:00 PM",
        value: "20:00:00Z"
      },
      {
        label: "8:30 PM",
        value: "20:30:00Z"
      },
      {
        label: "9:00 PM",
        value: "21:00:00Z"
      },
      {
        label: "9:30 PM",
        value: "21:30:00Z"
      },
      {
        label: "10:00 PM",
        value: "22:00:00Z"
      },
      {
        label: "10:30 PM",
        value: "22:30:00Z"
      },
      {
        label: "11:00 PM",
        value: "23:00:00Z"
      },
      {
        label: "11:30 PM",
        value: "23:30:00Z"
      },
    ],
    GUEST_INVOLVEMENT: [
      {
        label: "Local Partner",
        value: "local_partner"
      },
      {
        label: "RedAwning",
        value: "redawning"
      },
      {
        label: "Host",
        value: "host"
      },
    ],
    BLOCK_FUTURE_CALENDAR: [
      {
        label: "6 Months from Present",
        value: 182
      },
      {
        label: "9 Months from Present",
        value: 273
      },
      {
        label: "1 Year from Present",
        value: 365
      },
      {
        label: "2 Years from Present",
        value: 730
      },
      {
        label: "3 Years from Present",
        value: 1095
      },
      {
        label: "4 Years from Present",
        value: 1460
      },
      {
        label: "5 Years from Present",
        value: 1825
      }
    ],
    EXEMPTION_REASONS: [
      {
        label: "(Japan) Hotels and Inns Act",
        value: "HOTELS_AND_INNS_ACT",
      },
      {
        label: "(Japan]) Economic Zoning Act",
        value: "ECONOMIC_ZONING_ACT",
      },
      {
        label: "(Catalonia) Listing is not full building",
        value: "listing_not_full_building",
      },
      {
        label: "(Catalonia) Listing is not a shared room",
        value: "listing_is_shared_room",
      },
      {
        label: "(Catalonia) Other exemption reason",
        value: "other_exemption_reason",
      },
      {
        label: "(Andalucia) Listing Type is not included",
        value: "LISTING_TYPE_NOT_INCLUDED",
      },
      {
        label: "(Andalucia) Listing Type not a tourist accommodation ",
        value: "LISTING_NOT_TOURIST_ACCOMMODATION",
      },
      {
        label: "(Portugal) Listing is not a full building",
        value: "listing is not a full building",
      },
      {
        label: "(Portugal) Listing has a registered urban lease contract",
        value: "listing has a registered urban lease contract",
      },
      {
        label: "(Vancouver / Santa Monica) Licensed Hotel or B&B",
        value: "LICENSED_HOTEL_OR_BB",
      },
      {
        label: "(Vancouver) Event Space",
        value: "EVENT_SPACE",
      },
      {
        label: "(Vancouver) Location exempt",
        value: "LOCATION_EXEMPT",
      },
    ],
    UPDATE_FREQUENCY : [
      {
        label: "Every 1 Hour",
        value: 60,
      },
      {
        label: "Every 2 Hours",
        value: 120,
      },
      {
        label: "Every 4 Hours",
        value: 240,
      },
      {
        label: "Every 8 Hours",
        value: 480,
      },
      {
        label: "Every 12 Hours",
        value: 1440,
      },
      {
        label: "Every Day",
        value: 2880,
      },
    ],
    FREE_CANCEL_POLICY: [
      {
        label: "Free Cancel 60",
        value: "yes_60",
      },
      {
        label: "Free Cancel 42",
        value: "yes_42",
      },
      {
        label: "Free Cancel 30",
        value: "yes_30",
      },
      {
        label: "Free Cancel 14",
        value: "yes_14",
      },
      {
        label: "Free Cancel 7",
        value: "yes_7",
      },
      {
        label: "Free Cancel 3",
        value: "yes_3",
      },
      {
        label: "RedAwning Strict",
        value: "no",
      },
    ],
    FREE_CANCEL_POLICY_STEPDOWN: {
      'no': 'yes_60',
      'yes_60': 'yes_42',
      'yes_42': 'yes_30',
      'yes_30': 'yes_14',
      'yes_14': 'yes_7',
      'yes_7': 'yes_3'
    },
    PROPERTY_VIEW: {
        OPT_MAX_VALUE: 50,
        PROPERTY_TYPE: [
            {
              "label": "Apartment Hotel",
              "value": "apartment hotel"
            },
            {
                "label": "Apartment",
                "value": "apts"
            },
            {
              "label": "Bed and Breakfast",
              "value": "Bed and Breakfast"
            },
            {
                "label": "Bungalow",
                "value": "bungalow"
            },
            {
                "label": "Cabin",
                "value": "cabin"
            },
            {
                "label": "Chalet",
                "value": "chalet"
            },
            {
                "label": "Condo",
                "value": "condo"
            },
            {
                "label": "Cottage",
                "value": "cottage"
            },
            {
                "label": "Divers",
                "value": "divers"
            },
            {
                "label": "Duplex",
                "value": "Duplex"
            },
            {
                "label": "Estate",
                "value": "estate"
            },
            {
                "label": "Farmhouse",
                "value": "farmhouse"
            },
            {
                "label": "Holiday Village",
                "value": "holiday village"
            },
            {
                "label": "Home",
                "value": "home"
            },
            {
                "label": "Hotel Room",
                "value": "Hotel Room"
            },
            {
                "label": "Houseboat",
                "value": "houseboat"
            },
            {
                "label": "Residence",
                "value": "residence"
            },
            {
                "label": "Retreat",
                "value": "retreat"
            },
            {
                "label": "Townhouse",
                "value": "townhouse"
            },
            {
                "label": "Villa",
                "value": "villa"
            },
            {
                "label": "Tent Cabin",
                "value": "tent cabin"
            },
            {
                "label": "Yurt",
                "value": "yurt"
            },
            {
                "label": "Resort",
                "value": "resort"
            },
            {
                "label": "Inn",
                "value": "inn"
            },
            {
                "label": "Lodge",
                "value": "lodge"
            },
            {
                "label": "Motel",
                "value": "motel"
            },
        ],
        AMENITIES: {
          important: [
            {
              "label": "Pets",
              "icon": "pet",
              "value": "Pets OK"
            },
            {
              "label": "Beach",
              "icon": "Beach",
              "value": "Beach Access"
            },
            {
              "label": "Beach",
              "icon": "Beach",
              "value": "Beachfront "
            },
            {
              "label": "Ocean",
              "icon": "Beach",
              "value": "Oceanfront"
            },
            {
              "label": "Parking",
              "icon": "Parking",
              "value": "Parking"
            },
            {
              "label": "Parking",
              "icon": "Parking",
              "value": "Parking -- Covered"
            },
            {
              "label": "Parking",
              "icon": "Parking",
              "value": "Parking -- Free"
            },
            {
              "label": "Parking",
              "icon": "Parking",
              "value": "Parking -- Off Street"
            },
            {
              "label": "Parking",
              "icon": "Parking",
              "value": "Parking -- RV"
            },
            {
              "label": "Ski",
              "icon": "ski",
              "value": "Ski in/Ski out"
            },
            {
              "label": "Pool",
              "icon": "pool",
              "value": "Pool"
            },
            {
              "label": "Pool",
              "icon": "pool",
              "value": "Pool -- Heated"
            },
            {
              "label": "Pool",
              "icon": "pool",
              "value": "Pool -- Indoor"
            },
            {
              "label": "Pool",
              "icon": "pool",
              "value": "Pool -- Privatee"
            },
            {
              "label": "Pool",
              "icon": "pool",
              "value": "Pool -- Shared"
            },
            {
              "label": "Pool",
              "icon": "pool",
              "value": "Pool -- Unheated"
            },
            {
              "label": "Hot Tub",
              "icon": "HotTub",
              "value": "Hot Tub"
            },
            {
              "label": "Hot Tub",
              "icon": "HotTub",
              "value": "Hot Tub -- Private"
            },
            {
              "label": "Hot Tub",
              "icon": "HotTub",
              "value": "Hot Tub -- Shared"
            },
            {
              "label": "Linens",
              "icon": "Linens",
              "value": "Linens Provided"
            },
            {
              "label": "No Smoking",
              "icon": "no_smoking",
              "value": "Non Smoking"
            },
            {
              "label": "Internet",
              "icon": "wifi",
              "value": "Non-Smoking"
            },
            {
              "label": "Internet",
              "icon": "wifi",
              "value": "Internet"
            },
          ],
            amenities: [
              {
                  "label": "Air Conditioning",
                  "value": "Air Conditioning"
              },
              {
                  "label": "Baby Crib",
                  "value": "Baby Crib"
              },
              {
                  "label": "Balcony",
                  "value": "Balcony"
              },
              {
                  "label": "Barbecue",
                  "value": "Barbecue"
              },
              {
                  "label": "Bathtub",
                  "value": "Bathtub"
              },
              {
                  "label": "Beach Access",
                  "value": "Beach Access"
              },
              {
                  "label": "Beachfront",
                  "value": "Beachfront"
              },
              {
                  "label": "Bidet",
                  "value": "Bidet"
              },
              {
                  "label": "Blender",
                  "value": "Blender"
              },
              {
                  "label": "Boat Dock",
                  "value": "Boat Dock"
              },
              {
                  "label": "Breakfast",
                  "value": "Breakfast"
              },
              {
                  "label": "Central Heating",
                  "value": "Central Heating"
              },
              {
                  "label": "Chef",
                  "value": "Chef"
              },
              {
                  "label": "Coffee Maker",
                  "value": "Coffee Maker"
              },
              {
                  "label": "Croquet Set",
                  "value": "Croquet Set"
              },
              {
                  "label": "Daily Housekeeping",
                  "value": "Daily Housekeeping"
              },
              {
                  "label": "Daily Housekeeping -- Free",
                  "value": "Daily Housekeeping -- Free"
              },
              {
                  "label": "Deck/Patio",
                  "value": "Deck/Patio"
              },
              {
                  "label": "Dining Area",
                  "value": "Dining Area"
              },
              {
                  "label": "Dishwasher",
                  "value": "Dishwasher"
              },
              {
                  "label": "Dryer",
                  "value": "Dryer"
              },
              {
                  "label": "Electric Kettle",
                  "value": "Electric Kettle"
              },
              {
                  "label": "Elevator",
                  "value": "Elevator"
              },
              {
                  "label": "Fan",
                  "value": "Fan"
              },
              {
                  "label": "Fireplace",
                  "value": "Fireplace"
              },
              {
                  "label": "Freezer",
                  "value": "Freezer"
              },
              {
                  "label": "Garage",
                  "value": "Garage"
              },
              {
                  "label": "Garden",
                  "value": "Garden"
              },
              {
                  "label": "Gym",
                  "value": "Gym"
              },
              {
                  "label": "Hair Dryer",
                  "value": "Hair Dryer"
              },
              {
                  "label": "Heater",
                  "value": "Heater"
              },
              {
                  "label": "Hot Tub -- Private",
                  "value": "Hot Tub -- Private"
              },
              {
                  "label": "Hot Tub -- Shared",
                  "value": "Hot Tub -- Shared"
              },
              {
                  "label": "Ice Maker",
                  "value": "Ice Maker"
              },
              {
                  "label": "Internet",
                  "value": "Internet"
              },
              {
                  "label": "Internet -- Wireless",
                  "value": "Internet -- Wireless"
              },
              {
                  "label": "iPod Dock",
                  "value": "iPod Dock"
              },
              {
                  "label": "Ironing Board",
                  "value": "Ironing Board"
              },
              {
                  "label": "Jacuzzi Tub",
                  "value": "Jacuzzi Tub"
              },
              {
                  "label": "Kitchen Cookware and Utensils",
                  "value": "Kitchen Cookware and Utensils"
              },
              {
                  "label": "Lanai",
                  "value": "Lanai"
              },
              {
                  "label": "Linens Provided",
                  "value": "Linens Provided"
              },
              {
                  "label": "Loft",
                  "value": "Loft"
              },
              {
                  "label": "Microwave",
                  "value": "Microwave"
              },
              {
                  "label": "Mini-Bar",
                  "value": "Mini-Bar"
              },
              {
                  "label": "Oceanfront",
                  "value": "Oceanfront"
              },
              {
                  "label": "Outdoor Fireplace",
                  "value": "Outdoor Fireplace"
              },
              {
                  "label": "Oven",
                  "value": "Oven"
              },
              {
                  "label": "Parking",
                  "value": "Parking"
              },
              {
                  "label": "Parking -- Covered",
                  "value": "Parking -- Covered"
              },
              {
                  "label": "Parking -- Free",
                  "value": "Parking -- Free"
              },
              {
                  "label": "Parking -- Off Street",
                  "value": "Parking -- Off Street"
              },
              {
                  "label": "Parking -- RV",
                  "value": "Parking -- RV"
              },
              {
                  "label": "Pool -- Heated",
                  "value": "Pool -- Heated"
              },
              {
                  "label": "Pool -- Indoor",
                  "value": "Pool -- Indoor"
              },
              {
                  "label": "Pool -- Private",
                  "value": "Pool -- Private"
              },
              {
                  "label": "Pool -- Shared",
                  "value": "Pool -- Shared"
              },
              {
                  "label": "Pool -- Unheated",
                  "value": "Pool -- Unheated"
              },
              {
                  "label": "Safe",
                  "value": "Safe"
              },
              {
                  "label": "Sauna",
                  "value": "Sauna"
              },
              {
                  "label": "Security Alarm",
                  "value": "Security Alarm"
              },
              {
                  "label": "Shower",
                  "value": "Shower"
              },
              {
                  "label": "Solarium",
                  "value": "Solarium"
              },
              {
                  "label": "Steam Room",
                  "value": "Steam Room"
              },
              {
                  "label": "Steam Shower",
                  "value": "Steam Shower"
              },
              {
                  "label": "Terrace",
                  "value": "Terrace"
              },
              {
                  "label": "Toaster",
                  "value": "Toaster"
              },
              {
                  "label": "Toiletries Provided",
                  "value": "Toiletries Provided"
              },
              {
                  "label": "Walk-in Closet",
                  "value": "Walk-in Closet"
              },
              {
                  "label": "Washing Machine",
                  "value": "Washing Machine"
              },
              {
                  "label": "Wood-Burning Fireplace",
                  "value": "Wood-Burning Fireplace"
              },
              {
                  "label": "Wood-Burning Stove",
                  "value": "Wood-Burning Stove"
              },
              {
                "label": "Ski Cross Country",
                "value": "Ski Cross Country"
              },
              {
                "label": "Ski Storage",
                "value": "Ski Storage"
              },
              {
                "label": "Ski in/Ski out",
                "value": "Ski in/Ski out"
              }
          ],
            entertainment: [
              {
                  "label": "Air Hockey Table",
                  "value": "Air Hockey Table"
              },
              {
                  "label": "Badminton Set",
                  "value": "Badminton Set"
              },
              {
                  "label": "Basketball",
                  "value": "Basketball"
              },
              {
                  "label": "Blu-ray Player",
                  "value": "Blu-ray Playerr"
              },
              {
                  "label": "Board Games",
                  "value": "Board Games"
              },
              {
                  "label": "Bocce Ball",
                  "value": "Bocce Ball"
              },
              {
                  "label": "Book Collection",
                  "value": "Book Collection"
              },
              {
                  "label": "CD Player",
                  "value": "CD Player"
              },
              {
                  "label": "Computer",
                  "value": "Computer"
              },
              {
                  "label": "Cycling",
                  "value": "Cycling"
              },
              {
                  "label": "DVD Player",
                  "value": "DVD Player"
              },
              {
                  "label": "Darts",
                  "value": "Darts"
              },
              {
                  "label": "Fishing",
                  "value": "Fishing"
              },
              {
                  "label": "Foosball Table",
                  "value": "Foosball Table"
              },
              {
                  "label": "Golf",
                  "value": "Golf"
              },
              {
                  "label": "Golf Course on Site",
                  "value": "Golf Course on Site"
              },
              {
                  "label": "Hiking",
                  "value": "Hiking"
              },
              {
                  "label": "Ice Skating",
                  "value": "Ice Skating"
              },
              {
                  "label": "Jet Skiing",
                  "value": "Jet Skiing"
              },
              {
                  "label": "Karaoke",
                  "value": "Karaoke"
              },
              {
                  "label": "Sailing",
                  "value": "Sailing"
              },
              {
                  "label": "Mini Golf",
                  "value": "Mini Golf"
              },
              {
                  "label": "Mountain Climbing",
                  "value": "Mountain Climbing"
              },
              {
                  "label": "Movie Collection",
                  "value": "Movie Collection"
              },
              {
                  "label": "Piano",
                  "value": "Piano"
              },
              {
                  "label": "Ping Pong Table",
                  "value": "Ping Pong Table"
              },
              {
                  "label": "Playground Set",
                  "value": "Playground Set"
              },
              {
                  "label": "Poker Table",
                  "value": "Poker Table"
              },
              {
                  "label": "Pool Table",
                  "value": "Pool Table"
              },
              {
                  "label": "Ski Area",
                  "value": "Ski Area"
              },
              {
                "label": "Stereo",
                "value": "Stereo"
              },
              {
                  "label": "Surround Sound/Home Theater",
                  "value": "Surround Sound/Home Theater"
              },
              {
                  "label": "Swing",
                  "value": "Swing"
              },
              {
                  "label": "Telephone",
                  "value": "Telephone"
              },
              {
                  "label": "Television",
                  "value": "Television"
              },
              {
                  "label": "Television -- Cable/Satellite",
                  "value": "Television -- Cable/Satellite"
              },
              {
                  "label": "Television -- Widescreen",
                  "value": "Television -- Widescreen"
              },
              {
                  "label": "Tennis Court",
                  "value": "Tennis Court"
              },
              {
                "label": "Video Games",
                "value": "Video Games"
              },
              {
                  "label": "Volleyball Set",
                  "value": "Volleyball Set"
              }
            ]
        },
        CURRENCY: [
            /*{
                text: 'Australia Dollar',
                value: 'AUD'
            },
            {
              text: 'China Yuan',
                value: 'CNT'
            },
            {
              text: 'India Rupees',
                value: 'INR'
              },
            {
              text: 'South Africa Rand',
              value: 'ZAR'
            },
            {
              text: 'Thailand Baht',
              value: 'THB'
            }, */
            {
              text: 'United States Dollar',
              value: 'USD'
            },
            {
              text: 'Canada Dollar',
                value: 'CAD'
            },
            {
              text: 'Mexican Peso',
              value: 'MXN'
            },
            {
              text: 'Euros',
              value: 'EUR'
            },
            {
              text: 'United Kingdom Pound',
              value: 'GBP'
            }
        ],
        TYPE_OF_PLACE: [
            'Living Room',
            'Loft Room'
        ],
        COUNTRIES: [
            {
              "Code": "AF",
              "Name": "Afghanistan"
            },
            {
              "Code": "AX",
              "Name": "Åland Islands"
            },
            {
              "Code": "AL",
              "Name": "Albania"
            },
            {
              "Code": "DZ",
              "Name": "Algeria"
            },
            {
              "Code": "AS",
              "Name": "American Samoa"
            },
            {
              "Code": "AD",
              "Name": "Andorra"
            },
            {
              "Code": "AO",
              "Name": "Angola"
            },
            {
              "Code": "AI",
              "Name": "Anguilla"
            },
            {
              "Code": "AQ",
              "Name": "Antarctica"
            },
            {
              "Code": "AG",
              "Name": "Antigua and Barbuda"
            },
            {
              "Code": "AR",
              "Name": "Argentina"
            },
            {
              "Code": "AM",
              "Name": "Armenia"
            },
            {
              "Code": "AW",
              "Name": "Aruba"
            },
            {
              "Code": "AU",
              "Name": "Australia"
            },
            {
              "Code": "AT",
              "Name": "Austria"
            },
            {
              "Code": "AZ",
              "Name": "Azerbaijan"
            },
            {
              "Code": "BS",
              "Name": "Bahamas"
            },
            {
              "Code": "BH",
              "Name": "Bahrain"
            },
            {
              "Code": "BD",
              "Name": "Bangladesh"
            },
            {
              "Code": "BB",
              "Name": "Barbados"
            },
            {
              "Code": "BY",
              "Name": "Belarus"
            },
            {
              "Code": "BE",
              "Name": "Belgium"
            },
            {
              "Code": "BZ",
              "Name": "Belize"
            },
            {
              "Code": "BJ",
              "Name": "Benin"
            },
            {
              "Code": "BM",
              "Name": "Bermuda"
            },
            {
              "Code": "BT",
              "Name": "Bhutan"
            },
            {
              "Code": "BO",
              "Name": "Bolivia, Plurinational State of"
            },
            {
              "Code": "BQ",
              "Name": "Bonaire, Sint Eustatius and Saba"
            },
            {
              "Code": "BA",
              "Name": "Bosnia and Herzegovina"
            },
            {
              "Code": "BW",
              "Name": "Botswana"
            },
            {
              "Code": "BV",
              "Name": "Bouvet Island"
            },
            {
              "Code": "BR",
              "Name": "Brazil"
            },
            {
              "Code": "IO",
              "Name": "British Indian Ocean Territory"
            },
            {
              "Code": "BN",
              "Name": "Brunei Darussalam"
            },
            {
              "Code": "BG",
              "Name": "Bulgaria"
            },
            {
              "Code": "BF",
              "Name": "Burkina Faso"
            },
            {
              "Code": "BI",
              "Name": "Burundi"
            },
            {
              "Code": "KH",
              "Name": "Cambodia"
            },
            {
              "Code": "CM",
              "Name": "Cameroon"
            },
            {
              "Code": "CA",
              "Name": "Canada"
            },
            {
              "Code": "CV",
              "Name": "Cape Verde"
            },
            {
              "Code": "KY",
              "Name": "Cayman Islands"
            },
            {
              "Code": "CF",
              "Name": "Central African Republic"
            },
            {
              "Code": "TD",
              "Name": "Chad"
            },
            {
              "Code": "CL",
              "Name": "Chile"
            },
            {
              "Code": "CN",
              "Name": "China"
            },
            {
              "Code": "CX",
              "Name": "Christmas Island"
            },
            {
              "Code": "CC",
              "Name": "Cocos (Keeling) Islands"
            },
            {
              "Code": "CO",
              "Name": "Colombia"
            },
            {
              "Code": "KM",
              "Name": "Comoros"
            },
            {
              "Code": "CG",
              "Name": "Congo"
            },
            {
              "Code": "CD",
              "Name": "Congo, the Democratic Republic of the"
            },
            {
              "Code": "CK",
              "Name": "Cook Islands"
            },
            {
              "Code": "CR",
              "Name": "Costa Rica"
            },
            {
              "Code": "CI",
              "Name": "Côte d'Ivoire"
            },
            {
              "Code": "HR",
              "Name": "Croatia"
            },
            {
              "Code": "CU",
              "Name": "Cuba"
            },
            {
              "Code": "CW",
              "Name": "Curaçao"
            },
            {
              "Code": "CY",
              "Name": "Cyprus"
            },
            {
              "Code": "CZ",
              "Name": "Czech Republic"
            },
            {
              "Code": "DK",
              "Name": "Denmark"
            },
            {
              "Code": "DJ",
              "Name": "Djibouti"
            },
            {
              "Code": "DM",
              "Name": "Dominica"
            },
            {
              "Code": "DO",
              "Name": "Dominican Republic"
            },
            {
              "Code": "EC",
              "Name": "Ecuador"
            },
            {
              "Code": "EG",
              "Name": "Egypt"
            },
            {
              "Code": "SV",
              "Name": "El Salvador"
            },
            {
              "Code": "GQ",
              "Name": "Equatorial Guinea"
            },
            {
              "Code": "ER",
              "Name": "Eritrea"
            },
            {
              "Code": "EE",
              "Name": "Estonia"
            },
            {
              "Code": "ET",
              "Name": "Ethiopia"
            },
            {
              "Code": "FK",
              "Name": "Falkland Islands (Malvinas)"
            },
            {
              "Code": "FO",
              "Name": "Faroe Islands"
            },
            {
              "Code": "FJ",
              "Name": "Fiji"
            },
            {
              "Code": "FI",
              "Name": "Finland"
            },
            {
              "Code": "FR",
              "Name": "France"
            },
            {
              "Code": "GF",
              "Name": "French Guiana"
            },
            {
              "Code": "PF",
              "Name": "French Polynesia"
            },
            {
              "Code": "TF",
              "Name": "French Southern Territories"
            },
            {
              "Code": "GA",
              "Name": "Gabon"
            },
            {
              "Code": "GM",
              "Name": "Gambia"
            },
            {
              "Code": "GE",
              "Name": "Georgia"
            },
            {
              "Code": "DE",
              "Name": "Germany"
            },
            {
              "Code": "GH",
              "Name": "Ghana"
            },
            {
              "Code": "GI",
              "Name": "Gibraltar"
            },
            {
              "Code": "GR",
              "Name": "Greece"
            },
            {
              "Code": "GL",
              "Name": "Greenland"
            },
            {
              "Code": "GD",
              "Name": "Grenada"
            },
            {
              "Code": "GP",
              "Name": "Guadeloupe"
            },
            {
              "Code": "GU",
              "Name": "Guam"
            },
            {
              "Code": "GT",
              "Name": "Guatemala"
            },
            {
              "Code": "GG",
              "Name": "Guernsey"
            },
            {
              "Code": "GN",
              "Name": "Guinea"
            },
            {
              "Code": "GW",
              "Name": "Guinea-Bissau"
            },
            {
              "Code": "GY",
              "Name": "Guyana"
            },
            {
              "Code": "HT",
              "Name": "Haiti"
            },
            {
              "Code": "HM",
              "Name": "Heard Island and McDonald Islands"
            },
            {
              "Code": "VA",
              "Name": "Holy See (Vatican City State)"
            },
            {
              "Code": "HN",
              "Name": "Honduras"
            },
            {
              "Code": "HK",
              "Name": "Hong Kong"
            },
            {
              "Code": "HU",
              "Name": "Hungary"
            },
            {
              "Code": "IS",
              "Name": "Iceland"
            },
            {
              "Code": "IN",
              "Name": "India"
            },
            {
              "Code": "ID",
              "Name": "Indonesia"
            },
            {
              "Code": "IR",
              "Name": "Iran, Islamic Republic of"
            },
            {
              "Code": "IQ",
              "Name": "Iraq"
            },
            {
              "Code": "IE",
              "Name": "Ireland"
            },
            {
              "Code": "IM",
              "Name": "Isle of Man"
            },
            {
              "Code": "IL",
              "Name": "Israel"
            },
            {
              "Code": "IT",
              "Name": "Italy"
            },
            {
              "Code": "JM",
              "Name": "Jamaica"
            },
            {
              "Code": "JP",
              "Name": "Japan"
            },
            {
              "Code": "JE",
              "Name": "Jersey"
            },
            {
              "Code": "JO",
              "Name": "Jordan"
            },
            {
              "Code": "KZ",
              "Name": "Kazakhstan"
            },
            {
              "Code": "KE",
              "Name": "Kenya"
            },
            {
              "Code": "KI",
              "Name": "Kiribati"
            },
            {
              "Code": "KP",
              "Name": "Korea, Democratic People's Republic of"
            },
            {
              "Code": "KR",
              "Name": "Korea, Republic of"
            },
            {
              "Code": "KW",
              "Name": "Kuwait"
            },
            {
              "Code": "KG",
              "Name": "Kyrgyzstan"
            },
            {
              "Code": "LA",
              "Name": "Lao People's Democratic Republic"
            },
            {
              "Code": "LV",
              "Name": "Latvia"
            },
            {
              "Code": "LB",
              "Name": "Lebanon"
            },
            {
              "Code": "LS",
              "Name": "Lesotho"
            },
            {
              "Code": "LR",
              "Name": "Liberia"
            },
            {
              "Code": "LY",
              "Name": "Libya"
            },
            {
              "Code": "LI",
              "Name": "Liechtenstein"
            },
            {
              "Code": "LT",
              "Name": "Lithuania"
            },
            {
              "Code": "LU",
              "Name": "Luxembourg"
            },
            {
              "Code": "MO",
              "Name": "Macao"
            },
            {
              "Code": "MK",
              "Name": "Macedonia, the Former Yugoslav Republic of"
            },
            {
              "Code": "MG",
              "Name": "Madagascar"
            },
            {
              "Code": "MW",
              "Name": "Malawi"
            },
            {
              "Code": "MY",
              "Name": "Malaysia"
            },
            {
              "Code": "MV",
              "Name": "Maldives"
            },
            {
              "Code": "ML",
              "Name": "Mali"
            },
            {
              "Code": "MT",
              "Name": "Malta"
            },
            {
              "Code": "MH",
              "Name": "Marshall Islands"
            },
            {
              "Code": "MQ",
              "Name": "Martinique"
            },
            {
              "Code": "MR",
              "Name": "Mauritania"
            },
            {
              "Code": "MU",
              "Name": "Mauritius"
            },
            {
              "Code": "YT",
              "Name": "Mayotte"
            },
            {
              "Code": "MX",
              "Name": "Mexico"
            },
            {
              "Code": "FM",
              "Name": "Micronesia, Federated States of"
            },
            {
              "Code": "MD",
              "Name": "Moldova, Republic of"
            },
            {
              "Code": "MC",
              "Name": "Monaco"
            },
            {
              "Code": "MN",
              "Name": "Mongolia"
            },
            {
              "Code": "ME",
              "Name": "Montenegro"
            },
            {
              "Code": "MS",
              "Name": "Montserrat"
            },
            {
              "Code": "MA",
              "Name": "Morocco"
            },
            {
              "Code": "MZ",
              "Name": "Mozambique"
            },
            {
              "Code": "MM",
              "Name": "Myanmar"
            },
            {
              "Code": "NA",
              "Name": "Namibia"
            },
            {
              "Code": "NR",
              "Name": "Nauru"
            },
            {
              "Code": "NP",
              "Name": "Nepal"
            },
            {
              "Code": "NL",
              "Name": "Netherlands"
            },
            {
              "Code": "NC",
              "Name": "New Caledonia"
            },
            {
              "Code": "NZ",
              "Name": "New Zealand"
            },
            {
              "Code": "NI",
              "Name": "Nicaragua"
            },
            {
              "Code": "NE",
              "Name": "Niger"
            },
            {
              "Code": "NG",
              "Name": "Nigeria"
            },
            {
              "Code": "NU",
              "Name": "Niue"
            },
            {
              "Code": "NF",
              "Name": "Norfolk Island"
            },
            {
              "Code": "MP",
              "Name": "Northern Mariana Islands"
            },
            {
              "Code": "NO",
              "Name": "Norway"
            },
            {
              "Code": "OM",
              "Name": "Oman"
            },
            {
              "Code": "PK",
              "Name": "Pakistan"
            },
            {
              "Code": "PW",
              "Name": "Palau"
            },
            {
              "Code": "PS",
              "Name": "Palestine, State of"
            },
            {
              "Code": "PA",
              "Name": "Panama"
            },
            {
              "Code": "PG",
              "Name": "Papua New Guinea"
            },
            {
              "Code": "PY",
              "Name": "Paraguay"
            },
            {
              "Code": "PE",
              "Name": "Peru"
            },
            {
              "Code": "PH",
              "Name": "Philippines"
            },
            {
              "Code": "PN",
              "Name": "Pitcairn"
            },
            {
              "Code": "PL",
              "Name": "Poland"
            },
            {
              "Code": "PT",
              "Name": "Portugal"
            },
            {
              "Code": "PR",
              "Name": "Puerto Rico"
            },
            {
              "Code": "QA",
              "Name": "Qatar"
            },
            {
              "Code": "RE",
              "Name": "Réunion"
            },
            {
              "Code": "RO",
              "Name": "Romania"
            },
            {
              "Code": "RU",
              "Name": "Russian Federation"
            },
            {
              "Code": "RW",
              "Name": "Rwanda"
            },
            {
              "Code": "BL",
              "Name": "Saint Barthélemy"
            },
            {
              "Code": "SH",
              "Name": "Saint Helena, Ascension and Tristan da Cunha"
            },
            {
              "Code": "KN",
              "Name": "Saint Kitts and Nevis"
            },
            {
              "Code": "LC",
              "Name": "Saint Lucia"
            },
            {
              "Code": "MF",
              "Name": "Saint Martin (French part)"
            },
            {
              "Code": "PM",
              "Name": "Saint Pierre and Miquelon"
            },
            {
              "Code": "VC",
              "Name": "Saint Vincent and the Grenadines"
            },
            {
              "Code": "WS",
              "Name": "Samoa"
            },
            {
              "Code": "SM",
              "Name": "San Marino"
            },
            {
              "Code": "ST",
              "Name": "Sao Tome and Principe"
            },
            {
              "Code": "SA",
              "Name": "Saudi Arabia"
            },
            {
              "Code": "SN",
              "Name": "Senegal"
            },
            {
              "Code": "RS",
              "Name": "Serbia"
            },
            {
              "Code": "SC",
              "Name": "Seychelles"
            },
            {
              "Code": "SL",
              "Name": "Sierra Leone"
            },
            {
              "Code": "SG",
              "Name": "Singapore"
            },
            {
              "Code": "SX",
              "Name": "Sint Maarten (Dutch part)"
            },
            {
              "Code": "SK",
              "Name": "Slovakia"
            },
            {
              "Code": "SI",
              "Name": "Slovenia"
            },
            {
              "Code": "SB",
              "Name": "Solomon Islands"
            },
            {
              "Code": "SO",
              "Name": "Somalia"
            },
            {
              "Code": "ZA",
              "Name": "South Africa"
            },
            {
              "Code": "GS",
              "Name": "South Georgia and the South Sandwich Islands"
            },
            {
              "Code": "SS",
              "Name": "South Sudan"
            },
            {
              "Code": "ES",
              "Name": "Spain"
            },
            {
              "Code": "LK",
              "Name": "Sri Lanka"
            },
            {
              "Code": "SD",
              "Name": "Sudan"
            },
            {
              "Code": "SR",
              "Name": "Suriname"
            },
            {
              "Code": "SJ",
              "Name": "Svalbard and Jan Mayen"
            },
            {
              "Code": "SZ",
              "Name": "Swaziland"
            },
            {
              "Code": "SE",
              "Name": "Sweden"
            },
            {
              "Code": "CH",
              "Name": "Switzerland"
            },
            {
              "Code": "SY",
              "Name": "Syrian Arab Republic"
            },
            {
              "Code": "TW",
              "Name": "Taiwan, Province of China"
            },
            {
              "Code": "TJ",
              "Name": "Tajikistan"
            },
            {
              "Code": "TZ",
              "Name": "Tanzania, United Republic of"
            },
            {
              "Code": "TH",
              "Name": "Thailand"
            },
            {
              "Code": "TL",
              "Name": "Timor-Leste"
            },
            {
              "Code": "TG",
              "Name": "Togo"
            },
            {
              "Code": "TK",
              "Name": "Tokelau"
            },
            {
              "Code": "TO",
              "Name": "Tonga"
            },
            {
              "Code": "TT",
              "Name": "Trinidad and Tobago"
            },
            {
              "Code": "TN",
              "Name": "Tunisia"
            },
            {
              "Code": "TR",
              "Name": "Turkey"
            },
            {
              "Code": "TM",
              "Name": "Turkmenistan"
            },
            {
              "Code": "TC",
              "Name": "Turks and Caicos Islands"
            },
            {
              "Code": "TV",
              "Name": "Tuvalu"
            },
            {
              "Code": "UG",
              "Name": "Uganda"
            },
            {
              "Code": "UA",
              "Name": "Ukraine"
            },
            {
              "Code": "AE",
              "Name": "United Arab Emirates"
            },
            {
              "Code": "GB",
              "Name": "United Kingdom"
            },
            {
              "Code": "US",
              "Name": "United States"
            },
            {
              "Code": "UM",
              "Name": "United States Minor Outlying Islands"
            },
            {
              "Code": "UY",
              "Name": "Uruguay"
            },
            {
              "Code": "UZ",
              "Name": "Uzbekistan"
            },
            {
              "Code": "VU",
              "Name": "Vanuatu"
            },
            {
              "Code": "VE",
              "Name": "Venezuela, Bolivarian Republic of"
            },
            {
              "Code": "VN",
              "Name": "Viet Nam"
            },
            {
              "Code": "VG",
              "Name": "Virgin Islands, British"
            },
            {
              "Code": "VI",
              "Name": "Virgin Islands, U.S."
            },
            {
              "Code": "WF",
              "Name": "Wallis and Futuna"
            },
            {
              "Code": "EH",
              "Name": "Western Sahara"
            },
            {
              "Code": "YE",
              "Name": "Yemen"
            },
            {
              "Code": "ZM",
              "Name": "Zambia"
            },
            {
              "Code": "ZW",
              "Name": "Zimbabwe"
            }
          ]
    },
    STATES_PROVINCES: [
      {
        "country_code": "US",
        "name": "Alabama",
        "abbrev": "AL",
        "code": "US-AL"
      },
      {
        "country_code": "US",
        "name": "Alaska",
        "abbrev": "AK",
        "code": "US-AK"
      },
      {
        "country_code": "US",
        "name": "Arizona",
        "abbrev": "AZ",
        "code": "US-AZ"
      },
      {
        "country_code": "US",
        "name": "Arkansas",
        "abbrev": "AR",
        "code": "US-AR"
      },
      {
        "country_code": "US",
        "name": "California",
        "abbrev": "CA",
        "code": "US-CA"
      },
      {
        "country_code": "US",
        "name": "Colorado",
        "abbrev": "CO",
        "code": "US-CO"
      },
      {
        "country_code": "US",
        "name": "Connecticut",
        "abbrev": "CT",
        "code": "US-CT"
      },
      {
        "country_code": "US",
        "name": "Delaware",
        "abbrev": "DE",
        "code": "US-DE"
      },
      {
        "country_code": "US",
        "name": "Florida",
        "abbrev": "FL",
        "code": "US-FL"
      },
      {
        "country_code": "US",
        "name": "Georgia",
        "abbrev": "GA",
        "code": "US-GA"
      },
      {
        "country_code": "US",
        "name": "Hawaii",
        "abbrev": "HI",
        "code": "US-HI"
      },
      {
        "country_code": "US",
        "name": "Idaho",
        "abbrev": "ID",
        "code": "US-ID"
      },
      {
        "country_code": "US",
        "name": "Illinois",
        "abbrev": "IL",
        "code": "US-IL"
      },
      {
        "country_code": "US",
        "name": "Iowa",
        "abbrev": "IA",
        "code": "US-IA"
      },
      {
        "country_code": "US",
        "name": "Kansas",
        "abbrev": "KS",
        "code": "US-KS"
      },
      {
        "country_code": "US",
        "name": "Kentucky",
        "abbrev": "KY",
        "code": "US-KY"
      },
      {
        "country_code": "US",
        "name": "Louisiana",
        "abbrev": "LA",
        "code": "US-LA"
      },
      {
        "country_code": "US",
        "name": "Maine",
        "abbrev": "ME",
        "code": "US-ME"
      },
      {
        "country_code": "US",
        "name": "Maryland",
        "abbrev": "MD",
        "code": "US-MD"
      },
      {
        "country_code": "US",
        "name": "Massachusetts",
        "abbrev": "MA",
        "code": "US-MA"
      },
      {
        "country_code": "US",
        "name": "Michigan",
        "abbrev": "MI",
        "code": "US-MI"
      },
      {
        "country_code": "US",
        "name": "Minnesota",
        "abbrev": "MN",
        "code": "US-MN"
      },
      {
        "country_code": "US",
        "name": "Mississippi",
        "abbrev": "MS",
        "code": "US-MS"
      },
      {
        "country_code": "US",
        "name": "Missouri",
        "abbrev": "MO",
        "code": "US-MO"
      },
      {
        "country_code": "US",
        "name": "Montana",
        "abbrev": "MT",
        "code": "US-MT"
      },
      {
        "country_code": "US",
        "name": "Nebraska",
        "abbrev": "NE",
        "code": "US-NE"
      },
      {
        "country_code": "US",
        "name": "Nevada",
        "abbrev": "NV",
        "code": "US-NV"
      },
      {
        "country_code": "US",
        "name": "New Hampshire",
        "abbrev": "NH",
        "code": "US-NH"
      },
      {
        "country_code": "US",
        "name": "New Jersey",
        "abbrev": "NJ",
        "code": "US-NJ"
      },
      {
        "country_code": "US",
        "name": "New Mexico",
        "abbrev": "NM",
        "code": "US-NM"
      },
      {
        "country_code": "US",
        "name": "New York",
        "abbrev": "NY",
        "code": "US-NY"
      },
      {
        "country_code": "US",
        "name": "North Carolina",
        "abbrev": "NC",
        "code": "US-NC"
      },
      {
        "country_code": "US",
        "name": "North Dakota",
        "abbrev": "ND",
        "code": "US-ND"
      },
      {
        "country_code": "US",
        "name": "Ohio",
        "abbrev": "OH",
        "code": "US-OH"
      },
      {
        "country_code": "US",
        "name": "Oklahoma",
        "abbrev": "OK",
        "code": "US-OK"
      },
      {
        "country_code": "US",
        "name": "Oregon",
        "abbrev": "OR",
        "code": "US-OR"
      },
      {
        "country_code": "US",
        "name": "Pennsylvania",
        "abbrev": "PA",
        "code": "US-PA"
      },
      {
        "country_code": "US",
        "name": "Rhode Island",
        "abbrev": "RI",
        "code": "US-RI"
      },
      {
        "country_code": "US",
        "name": "South Carolina",
        "abbrev": "SC",
        "code": "US-SC"
      },
      {
        "country_code": "US",
        "name": "South Dakota",
        "abbrev": "SD",
        "code": "US-SD"
      },
      {
        "country_code": "US",
        "name": "Tennessee",
        "abbrev": "TN",
        "code": "US-TN"
      },
      {
        "country_code": "US",
        "name": "Texas",
        "abbrev": "TX",
        "code": "US-TX"
      },
      {
        "country_code": "US",
        "name": "Utah",
        "abbrev": "UT",
        "code": "US-UT"
      },
      {
        "country_code": "US",
        "name": "Vermont",
        "abbrev": "VT",
        "code": "US-VT"
      },
      {
        "country_code": "US",
        "name": "Virginia",
        "abbrev": "VA",
        "code": "US-VA"
      },
      {
        "country_code": "US",
        "name": "Washington",
        "abbrev": "WA",
        "code": "US-WA"
      },
      {
        "country_code": "US",
        "name": "West Virginia",
        "abbrev": "WV",
        "code": "US-WV"
      },
      {
        "country_code": "US",
        "name": "Wisconsin",
        "abbrev": "WI",
        "code": "US-WI"
      },
      {
        "country_code": "US",
        "name": "Wyoming",
        "abbrev": "WY",
        "code": "US-WY"
      },
      {
        "country_code": "US",
        "name": "District of Columbia",
        "abbrev": "D.C.",
        "code": "US-DC"
      },
      {
        "country_code": "US",
        "name": "AS",
        "abbrev": "IA",
        "code": "US-AS"
      },
      {
        "country_code": "US",
        "name": "Guam",
        "abbrev": "GU",
        "code": "US-GU"
      },
      {
        "country_code": "US",
        "name": "Northern Mariana Islands",
        "abbrev": "MP",
        "code": "US-MP"
      },
      {
        "country_code": "US",
        "name": "Puerto Rico",
        "abbrev": "PR",
        "code": "US-PR"
      },
      {
        "country_code": "US",
        "name": "United States Minor Outlying Islands",
        "abbrev": "UM",
        "code": "US-UM"
      },
      {
        "country_code": "US",
        "name": "U.S. Virgin Islands",
        "abbrev": "VI",
        "code": "US-VI"
      },
    ],
    CALENDAR: {
      AVAILABILITY_TITLE: {
        "Temporary": "Blocked - Temporary / No Pricing",
        "BlockedByBooking": "Blocked - Booking",
        "TurnoverBlock": 'Blocked - Turnover',
        "BlockedByManager": "Blocked - Manager",
        "Manual": "Blocked - Manual",
        "Bulk": "Blocked - Bulk",
        "InquiryOnly": "Blocked - Inquiry",
        "PaymentBlock": "Blocked - Payment",
        'NotAvailable': 'Not Available',
        "Default": "Blocked"
      },
      BLOCKED_BY_MANAGER: 'Blocked',
      TEMPORARY: 'No Pricing',
      VIEW: {
        MONTH: 'month',
        MULTI_PROPERTY: 'custom'
      },
      LISTVIEW: {
        AVAILABILITY_TYPE: {
          'Temporary': 'Temporary',
          'BlockedByManager': 'Blocked By Manager',
          'BlockedByBooking': 'Blocked By Booking',
          'PaymentBlock': 'Payment Block'
        }
      }
    },
    CHANNELS: {
      "airbnb": "Airbnb",
      "bookingcom": "Booking.com",
      "expedia": "Expedia",
      "homeaway": "Homeaway",
      "redawning": "RedAwning"
    },
    IMAGE_FORMATS: [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/x-icon"
    ],
    CRITERIA_NAME: [
      { label: "Length of Stay", value: "los"},
      { label: "Number of Guests", value: "guests"},
      { label: "Number of Pets", value: "pets"},
      { label: "Charge Name", value: "charge_name"},
      { label: "Check in Date(s)", value: "check_in_date"},
      { label: "Night(s) Booked in Advance", value: "nights_booked_in_advance"},
      { label: "Booking date(s)", value: "booking_date"},
      { label: "Day(s) of Week of the Stay", value: "stay_dow"},
      { label: "Date(s) of Stay", value: "stay_date"},
      { label: "Number of Night Stay", value: "stay_night_number"},
      { label: "Guest Number", value: "guest_number"},
      { label: "Pet Number", value: "pet_number"},
    ],
    CRITERIA_OPERATOR: [
      { label: "Equal to", value: "eq"},
      { label: "Greater than", value: "gt"},
      { label: "Less than", value: "lt"},
      { label: "Greater than or Equal to", value: "ge"},
      { label: "Less than or Equal to", value: "le"},
      { label: "In", value: "in"},
      { label: "Between", value: "between"},
    ],
    FEE_APPLIES_TYPE: [
      { label: "Per Stay", value: "per_stay"},
      { label: "Per Stay Per Guest", value: "per_stay_per_guest"},
      { label: "Per Stay Per Pet", value: "per_stay_per_pet"},
      { label: "Per Night", value: "per_night"},
      { label: "Per Night Per Pet", value: "per_night_per_pet"},
      { label: "Per Night Per Guest", value: "per_night_per_guest"},
    ],
    RENTAL_POLICIES: [
      { label: "0% Commission, 0% Credit Card Fees", value: "0% | 0%"},
      { label: "0% Commission, 3% Credit Card Fees", value: "0% | 3%"},
      { label: "10% Commission, 3% Credit Card Fees", value: "10% | 3%"},
      { label: "10% Commission, 0% Credit Card Fees", value: "10% | 0%"},
      { label: "15% Commission, 0% Credit Card Fees", value: "15% | 0%"},
      { label: "18% Commission, 0% Credit Card Fees", value: "18% | 0%"},
      { label: "20% Commission, 0% Credit Card Fees", value: "20% | 0%"},
      { label: "25% Commission, 0% Credit Card Fees", value: "25% | 0%"},
      { label: "30% Commission, 0% Credit Card Fees", value: "30% | 0%"},
      { label: "35% Commission, 0% Credit Card Fees", value: "35% | 0%"},
    ],
    FREE_CANCEL_INFO: [
      { label: "Manager/Owner Offered", value: 'offered'},
      { label: "Manager/Owner Unreached", value: 'unreached'},
    ],
    ACCEPTED_FILE_TYPES: [
      'application/pdf',
      'image/*',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      '.xls',
      '.xlsx',
      '.doc',
      '.docx',
      '.csv',
      '.txt', 
      '.log',
    ],
    CHURN_REASONS: [
      { label: "Exclusivity", value: "exclusivity"},
      { label: "Guest Services", value: "guest_services"},
      { label: "Markup/DW", value: "markup_dw"},
      { label: "Onboarding - Unresponsive", value: "onboarding_unresponsive"},
      { label: "Onboarding - Reputation", value: "onboarding_reputation"},
      { label: "Payment Issues", value: "payment_issues"},
      { label: "Product - portal", value: "product_portal"},
      { label: "Product - pricing", value: "product_pricing"},
      { label: "Performance", value: "performance"},
      { label: "Property Sold", value: "property_sold"},
      { label: "Rejected Bookings", value: "rejected_bookings"},
    ],
    TEMPLATE_SEND_MESSAGE: [
      { label: "1 Hour", value: '3600' },
      { label: "2 Hours", value: '7200' },
      { label: "3 Hours", value: '10800' },
      { label: "4 Hours", value: '14400' },
      { label: "5 Hours", value: '18000' },
      { label: "6 Hours", value: '21600' },
      { label: "7 Hours", value: '25200' },
      { label: "8 Hours", value: '28800' },
      { label: "1 Day", value: '86400' },
      { label: "2 Days", value: '172800' },
      { label: "3 Days", value: '259200' },
      { label: "4 Days", value: '345600' },
      { label: "5 Days", value: '432000' },
      { label: "6 Days", value: '518400' },
      { label: "7 Days", value: '604800' },
      { label: "8 Days", value: '691200' },
      { label: "9 Days", value: '777600' },
      { label: "10 Days", value: '864000' },
      { label: "11 Days", value: '950400' },
      { label: "12 Days", value: '1036800' },
      { label: "13 Days", value: '1123200' },
      { label: "14 Days", value: '1209600' },
      { label: "15 Days", value: '1296000' },
      { label: "16 Days", value: '1382400' },
      { label: "17 Days", value: '1468800' },
      { label: "18 Days", value: '1555200' },
      { label: "19 Days", value: '1641600' },
      { label: "20 Days", value: '1728000' },
      { label: "21 Days", value: '1814400' },
      { label: "22 Days", value: '1900800' },
      { label: "23 Days", value: '1987200' },
      { label: "24 Days", value: '2073600' },
      { label: "25 Days", value: '2160000' },
      { label: "26 Days", value: '2246400' },
      { label: "27 Days", value: '2332800' },
      { label: "28 Days", value: '2419200' },
      { label: "29 Days", value: '2505600' },
      { label: "30 Days", value: '2592000' },
    ],
  FEE_RECIPIENT: {
    REDAWNING: "redawning",
    HOST: "host",
  }
}
const APP_CONSTANTS = Object.assign(Constants, {USER_TYPES})
export default APP_CONSTANTS
