import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { updateOrganization, updateOrganizationTitle, updateOrganizationId, setRole } from '../../js/actions/index';
import constants from '../../js/constants';

// taken from Underscore.js
// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce(func, wait, immediate) {

	let timeout
	return function() {
		let context = this, args = arguments
		let later = function() {
		timeout = null
		if (!immediate) func.apply(context, args)
		}
		let callNow = immediate && !timeout
		clearTimeout(timeout)
		timeout = setTimeout(later, wait)
		if (callNow) func.apply(context, args)
	}
}

/**
 * @class The modal for change an organization.  
 */
class OrganzationDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.filteredTreeIds = []
        this.state = {
            open: false,
            orgStructure: props.user.organization_role_tree,
            nested: {},
            defaultExpanded: true,
            query: ''
        }
        this.handleSearch = this.handleSearch.bind(this)
        this.renderOrgList = this.renderOrgList.bind(this)
    }

    /**
     * Toggle organization modal.
     */
    toggleDropdown = () => {
        this.setState({open:!this.state.open});
    }

    /**
     * Handle organization search event. 
     * @param {*} e 
     */
    handleSearch = e => {
        let query = e.target.value.toLowerCase();
        let _self = this;

        let setQuery = () => {
            _self.setState({
                query,
            })
        }

        debounce(setQuery(), 250);
              
    }

    renderOrgList = async () => {
        let boldEl = document.getElementsByClassName('bold')
        let els = document.querySelectorAll('.showListItem')
        let _removeClasses = () => {
            for (var i = 0; i < els.length; i++) {
                // Check here to see if .querySelector('.org-dropdown-list') li.isOpen and don't remove that one
                els[i].classList.remove('showListItem')
            }
            return
        }
        await _removeClasses()
        
        for (let item of boldEl) {
            let itemParentIsOpen = [],
                nestedShowBlock = []
            
            if (item) {
                item.classList.add("showListItem")
            }

            itemParentIsOpen[0] = item.parentNode
            if (itemParentIsOpen[0]) {
                itemParentIsOpen[0].classList.add("showListItem")
                nestedShowBlock[0] = itemParentIsOpen[0].parentNode
                if (nestedShowBlock[0]) {
                    nestedShowBlock[0].classList.add("showListItem")
                }                    
            }

            if (nestedShowBlock && nestedShowBlock.length) {
                for (let i = 1; i < 20; i++) {
                    itemParentIsOpen[i] = nestedShowBlock[i-1]
                    if (itemParentIsOpen[i]) {
                        nestedShowBlock[i] = itemParentIsOpen[i].parentNode
                        if (nestedShowBlock[i]) {
                            if (nestedShowBlock[i].querySelector('.org-dropdown-list')) {
                                break
                            }
                            nestedShowBlock[i].classList.add("showListItem")
                        }                     
                    }
                }
            }
            
        }
    }

    /**
     * Invoke when select any organization.
     * @param {*} e 
     */
    selectOrg = (e) => {
        e.preventDefault();
        let orgName = e.target.getAttribute('name');
        let orgTitle = e.target.textContent;
        let orgId = e.target.getAttribute('orgid');
        let allUserRoles = this.props.isInternalPropertyAdmin ? Object.values(constants.USER_TYPES).filter((role) => {
            return role;
        }) : [];

        let organization = this.props.user.expanded_organization_roles.filter(org => {
            if(org.organization_name === orgName) {
                return true
            };
        });

        if(organization && organization.length) {
            this.props.updateOrganization(orgName);
            this.props.updateOrganizationTitle(orgTitle);
            this.props.updateOrganizationId(orgId);
            this.props.setRole(this.props.isInternalPropertyAdmin ? allUserRoles: organization[0].roles);

            sessionStorage.setItem("org", orgName);
            sessionStorage.setItem("orgTitle", orgTitle);
            sessionStorage.setItem("orgId", orgId);
        };

        this.props.history.push("/dashboard", {org:orgName, roles: this.props.isInternalPropertyAdmin ? allUserRoles: organization[0].roles});
        this.props.closeModal();
    }

    /**
     * Function use for toggle a nested organization. 
     * @param {*} e 
     */
    toggleNested = (e) => {
        let orgName = e.target.getAttribute('data-org'),
            { nested } = this.state;

        if(nested[orgName]) {
            nested[orgName] = null
        } else {
            nested[orgName] = orgName;
        }
        this.setState({
            nested
        })
    }

    /**
     * Generate organization list.
     */
    generateOrgList(list,query) {
        // Filter out TPRO agency orgs 'isNotTpro'.
        return list.map((item,i)=>{
            let hasChildren = item.child_organizations && (item.child_organizations.length > 0),
            isNotTpro = (!item.roles.includes('TPAgencyAgent') && !item.roles.includes('TPAgencyAdmin') && !item.roles.includes('TPInternalAdmin') && !item.roles.includes('TPAgencyClient')),
            orgName = item.organization_name,
            orgTitle = 
                !item.title ? 
                orgName : 
                item.title,
            orgId = item.organization_id,
            managerUid = item.manager_uid,
            highLight = false;

            if (query && query.length && (orgTitle.toLowerCase().indexOf(query.toLowerCase()) > -1 || orgName.toLowerCase().indexOf(query.toLowerCase()) > -1 || String(managerUid).toLowerCase() === query.toLowerCase())) {
                highLight = true
            }

            if (isNotTpro && (highLight || hasChildren)) {
                let boldOrNormal = (highLight) ? 'bold' : 'normal'
                let aClassName = (this.props.org === orgName) ? 'active bold' : boldOrNormal
                let liClassName = ((highLight || orgName==this.state.nested[orgName] || this.state.defaultExpanded || arguments[1]) ? 'isOpen' : 'isClosed')
                return (
                    <li key={i} className={liClassName}>
                        <a  href="#"
                            name={orgName}
                            orgid={orgId}
                            title={managerUid}
                            className={aClassName} 
                            onClick={(this.props.org !==orgName ? 
                                this.selectOrg : null)}>
                            {orgTitle}
                        </a>
                        { hasChildren ?
                            <span data-org={orgName} onClick={this.toggleNested} className="org-child-toggle"></span> :
                            <span data-org={orgName} className="org-child-notoggle"></span> }
                        { hasChildren ? 
                            <ul className="org-nested-list">
                                {this.generateOrgList(item.child_organizations,query)}
                            </ul> :  
                            <ul className="org-nested-list">
                            </ul>
                        }
                    </li>)
            } else {
                return this.generateOrgList(item.child_organizations,query)
            }
            
        })
    }

    componentDidMount() {
        this.renderOrgList()
    }

    componentDidUpdate() {
        this.renderOrgList()        
    }

    render() {
        let { query } = this.state
        return (
            <div className="org-dropdown open">
                <input type="text" placeholder="Search by Organization Name or Id" onChange={this.handleSearch} autoFocus className="form-field" />
                {(this.props.user.organization_role_tree && this.props.user.organization_role_tree.length && query && query.length) && 
                    <ul className="org-dropdown-list">
                        {this.generateOrgList(this.props.user.organization_role_tree,query)}
                    </ul>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        isInternalPropertyAdmin: state.roleManager.isInternalPropertyAdmin,
        org: state.roleManager.org,
        user: state.authState.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setRole: (role) => {
            dispatch(setRole(role));
        },
        updateOrganization: (org) => {
            dispatch(updateOrganization(org))
        },
        updateOrganizationTitle: (org) => {
            dispatch(updateOrganizationTitle(org))
        },
        updateOrganizationId: (orgId) => {
            dispatch(updateOrganizationId(orgId))
        }
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(OrganzationDropdown));