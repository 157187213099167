import React, { Component } from 'react';
import { API } from 'aws-amplify';
import Link from 'react-router-dom/Link';
import { updateOrganization, updateOrganizationTitle, updateOrganizationId } from '../../js/actions/index';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';

import TableComponent from '../global/TableComponent';
import PageLayout from '../global/PageLayout';
import { Title } from '../global/Title';
import { InputField } from '../global/forms/FormElements';
import ReactTable from 'react-table/react-table.min.js';
import { tableSort, getSessionId } from '../../helpers/helpers';

class Organizations extends Component {
    breadcrumbItems = [
        { title: "Organizations" }
    ];
    org = this.props.roleManager.org;
    tid = getSessionId();
    state = {
        organizationsList: [],
        showAll: false,
        isLoading: false,
        pageSize: 10,
        activePage: 0,
        totalOrgCount: 0,
        showPagination: true,
        keyword: '',
        sorted: {
            id: "manager_uid",
            desc: true,
        },
        columns:[]
    }

    updateColumns = () => {
        let columns = [
        {
            Header:() => tableSort("UID"),
            'className': 'table_uid',
            'accessor': 'manager_uid',
            'width': 150,
            'Cell': row=>(
                <div>
                    <strong>
                        {row.original.manager_uid}
                    </strong>
                </div>
            )
        },
        {
            Header:() => tableSort("Organization Name"),
            'className': 'table_organization_name',
            'accessor': 'title',
            'minWidth': 250,
            'Cell': row=>(
                <div>
                    <strong>
                        {row && 
                        row.original.title ? 
                        row && row.original.title : 
                        row && row.original.organization_name}
                    </strong>
                </div>
            )
        },
        {
            Header:() => tableSort("Integration Calendar"),
            'className': 'table_intergration_calendar',
            'accessor': 'calendar_format',
            'minWidth': 250,
            'Cell': row=>(
                <div>
                    <strong>
                        {row && 
                        row.original && 
                        row.original.property_manager_details && 
                        row.original.property_manager_details.calendar_format}
                    </strong>
                </div>
            )
        },
        {
            Header:() => tableSort("Status"),
            'className': 'table_status',
            'accessor': 'account_status',
            'width': 120,
            'Cell': row=>{
                let status = "notLive";
                const propertyManagerDetails = row && row.original && row.original.property_manager_details;
                const rowOriginal = row && row.original;

                if(propertyManagerDetails) {
                    if((propertyManagerDetails && 
                        propertyManagerDetails.account_status && 
                        propertyManagerDetails.account_status.toLowerCase() === "canceled")
                    ) {
                        status = "canceled";
                    }
                    else if((
                        propertyManagerDetails && 
                        propertyManagerDetails.account_status && 
                        propertyManagerDetails.account_status.toLowerCase() !== "delisted")
                    ) {
                        status = "live";
                    };
                }
                else {
                    if((rowOriginal && 
                        rowOriginal.account_status &&
                        rowOriginal.account_status.toLowerCase() === "canceled")
                    ) {
                        status = "canceled";
                    }
                    else if((rowOriginal && 
                        rowOriginal.account_status &&
                        rowOriginal.account_status.toLowerCase() !== "delisted")
                    ) {
                        status = "live";
                    };
                };
                
                return(
                <div>
                    {   
                        status === "canceled" ?
                        <strong className='text-neutral'>Canceled</strong> :
                        (status === "live" ?
                        <strong className='text-success'>Live</strong> :
                        <strong className='text-danger'>Not Live</strong>)
                    }
                </div>
            )}
        },
        {
            'Header':'Actions',
            'width': 180,
            'sortable': false,
            'Cell': row=>{
                return (
                    <div>
                        {
                            _.includes(this.props.permissions, 'create_organization') &&
                            <OverlayTrigger 
                                placement='bottom' 
                                overlay={this.getTooltip('edit',`Update Organization`)}>
                                <Link 
                                    to={`/account-management/organizations/${row.original.organization_name}/edit`} 
                                    className='properties__options'>
                                        <i className='icon-edit'/>
                                </Link>
                            </OverlayTrigger>
                        }
                        <OverlayTrigger 
                            placement='bottom' 
                            overlay={this.getTooltip('edit',`View Users`)}>
                                <span className='properties__options' onClick={e => this.routeToUserOrProperties(e, row, "user")} style={{cursor: "pointer"}}>
                                    <i className="icon-users"/>
                                </span>       
                        </OverlayTrigger>
                        <OverlayTrigger 
                            placement='bottom' 
                            overlay={this.getTooltip('edit',`View Properties`)}>
                                <span className='properties__options' onClick={e => this.routeToUserOrProperties(e, row, "properties")} style={{cursor: "pointer"}}>
                                    <i className="icon-house-icon" style={{fontSize: "18px"}}/>
                                </span>  
                        </OverlayTrigger>
                    </div>
                )
            }
        }];

        return columns;
    }

    componentDidMount() {
        const params = JSON.parse(sessionStorage.getItem('acc-mgmt-orgs'));

        if(params) {
            if(params.showAll || params.keyword.length) {
                const { 
                    showAll,
                    pageSize,
                    activePage,
                    keyword,
                    sorted
                } = this.state;
    
                this.setState({
                    showAll: params.showAll || showAll,
                    pageSize: params.pageSize || pageSize,
                    keyword: params.keyword || keyword,
                    sorted: params.sorted || sorted,
                }, async () => {
                    await this.fetchOrganizations(params.activePage != 0 ? (params.activePage - 1) : activePage);
                });
            };
        };
    }

    componentWillUnmount() {
        this.setSessionStorage();
    }

    fetchOrganizations = async (active_page, sort, page_size) => {
        const { 
            pageSize,
            keyword,
            sorted,
        } = this.state;

        const newPageSize = page_size ? page_size : pageSize;
        let newActivePage = ((active_page === 0) ? 0 : active_page);
        const newSorted = sort ? sort : sorted;
        const offset = newPageSize * newActivePage;
        const sortDesc = newSorted.desc ? "-" : "+";

        this.setState({ isLoading: true });

        try {
            let orgData = await API.get("accapi", `/organizations?tid=${this.tid}&limit=${pageSize}&offset=${offset}&sort=${newSorted.id}${encodeURIComponent(sortDesc)}&keyword=${encodeURIComponent(keyword)}`, { response: true });
            this.setState({
                activePage: (newActivePage + 1),
                pageSize: pageSize,
                sorted: {
                    ...sorted,
                    id: newSorted.id,
                    desc: newSorted.desc,
                },
                organizationsList: [...orgData.data],
                totalOrgCount: orgData.headers["x-total-count"],
                columns: (orgData && orgData.data && orgData.data.length) ? this.updateColumns() : [],
                isLoading: false,
            }, () => {
                this.setSessionStorage();
            });
        }
        catch (e) {
            console.log(e);
            this.setState({
                columns: [],
                isLoading: false
            });
        }
    }

    setSessionStorage = () => {
        const { 
            showAll,
            pageSize,
            activePage,
            keyword,
            sorted
        } = this.state;

        const pagination = {
            showAll: showAll,
            pageSize: pageSize,
            activePage: activePage,
            keyword: keyword,
            sorted: sorted
        };

        sessionStorage.setItem('acc-mgmt-orgs', JSON.stringify(pagination));
    }

    handlePageSizeChange = (newPageSize) => {
        const { pageSize } = this.state;

        if(!isNaN(newPageSize) && newPageSize.trim() !== "" && newPageSize !== pageSize) {
            this.setState({
                pageSize: newPageSize
            }, () => {
                this.fetchOrganizations(0, '', newPageSize);
            });
        };
    }

    handlePageChange = async (page) => {
        await this.fetchOrganizations(page);
    }

    onSortedChange = async (newSorted) => {
        await this.fetchOrganizations(0, newSorted[0]);
    }

    handleSearchSubmit = (e) => {
        e.preventDefault();

        this.setState({
            showAll: false
        }, () => {
            const { 
                keyword,
            } = this.state;

            if(keyword.length) {
                this.fetchOrganizations(0);
            }
            else {
                this.resetData();
            };
        });
    }

    handleClearSearch = () => {
        this.resetData();
    }

    handleSearchChange = (e) => {
        const name = e.target.name;
        const val = e.target.value;

        this.setState({
            [name] : val
        });
    }

    getTooltip = (id,text) => {
        return <Tooltip id={id} className='properties__tooltip'>{text}</Tooltip>
    }

    routeToUserOrProperties = (e, row, name) => {
        e.preventDefault();

        this.props.updateOrganization(row.original.organization_name);
        this.props.updateOrganizationTitle(row.original.title ? row.original.title : row.original.organization_name);
        this.props.updateOrganizationId(row.original.organization_id)

        sessionStorage.setItem('org', row.original.organization_name);
        sessionStorage.setItem('orgTitle', row.original.title ? row.original.title : row.original.organization_name);
        sessionStorage.setItem('orgId', row.original.organization_id);

        if(name === "user") {
            this.props.history.push("/account/users");
        }
        else if (name === "properties") {
            this.props.history.push("/properties");
        }     
    }

    handleShowAll = () => {
        this.setState({ 
            showAll: !this.state.showAll
        }, () => {
            const { 
                showAll,
            } = this.state;

            if(showAll == true) {
                this.setState({ 
                    keyword: ''
                }, () => {
                    this.fetchOrganizations(0);
                }); 
            }
            else {
                this.resetData();
            };
        });
    }

    resetData = () => {
        this.setState({
            columns: [],
            organizationsList: [],
            pageSize: 10,
            totalOrgCount: 0,
            activePage: 0,
            keyword: "",
            sorted: {
                id: "manager_uid",
                desc: true,
            },
        }, () => {
            this.setSessionStorage();
        });
    }

    renderSubComponent = (row) => {
        return (
            <ReactTable
                TheadComponent={props => null}
                resizable={false} minRows={0}
                data={row && row._original && row._original.children}
                columns={this.state.columns}
                showPagination={false}
                getTrProps = {(state, rowInfo, column) => {
                    if (rowInfo === undefined) {
                        return {};
                    };
                    return {
                        'data-qnt': 
                            rowInfo && 
                            rowInfo.original && 
                            rowInfo.original.children && 
                            rowInfo.original.children.length ? "1" : "0",
                    }
                }}
                getTdProps={(state, rowInfo, column) => {
                    if (
                        column.className === "table_uid" ||
                        column.className === "table_organization_name" ||
                        column.className === "table_intergration_calendar" || 
                        column.className === "table_status")
                    {
                        return {
                            onClick: () => {
                                this.props.history.push(`/account-management/organizations/${rowInfo.original.organization_name}/edit`)
                            },
                            style: {
                                cursor: 'pointer'
                            }
                        };
                    }
                    else {
                        return {};
                    };
                }}
                SubComponent={({ row }) => {
                    if(row && row._original && row._original.children) {
                        return this.renderSubComponent(row);
                    };
                }}
            />
        )
    }

    render() {
        const { 
            showAll,
            isLoading,
            keyword,
            pageSize,
            organizationsList,
            columns,
            totalOrgCount,
            showPagination,
            activePage,
            sorted
        } = this.state;

        return (
            <PageLayout isCentered={true}>
                <Title 
                    title='Organizations'
                    isCentered={true}
                    removeOrganizationDropdown={true}
                    addDefaultTitle="Account Management"
                    breadcrumbItems={this.breadcrumbItems}
                >
                    <div className="filters-wrap">
                        <InputField 
                            type="checkbox" 
                            value={showAll} 
                            cbChange={this.handleShowAll}
                            disabled={isLoading}
                        >
                            Show All Organizations
                        </InputField>
                    </div>
                    <form 
                        className='search-inp' 
                        onSubmit={this.handleSearchSubmit}
                    >
                        <InputField
                            autoFocus
                            type='searchbox'
                            placeholder='Search' 
                            name='keyword' 
                            value={keyword} 
                            onChange={this.handleSearchChange}
                            clearSearch={this.handleClearSearch} 
                        />
                    </form>
                    {
                        _.includes(this.props.permissions, 'create_organization') &&
                        <Link 
                            className='primary-btn' 
                            to='/account-management/organizations/create'
                        >
                            <i className='icon-Plus create-icon'/>
                            Create
                        </Link>
                    }
                </Title>
                <div id='content' className='properties'>
                    <Grid fluid={true}>
                        <Row>
                            <Col xs={12}>
                                <TableComponent 
                                    className='-highlight'
                                    resizable={false} 
                                    minRows={0}
                                    pageSize={parseInt(pageSize)}
                                    loading={isLoading}
                                    data={organizationsList}
                                    columns={columns}
                                    noDataText={isLoading ? "Loading..." : "No organizations found."}
                                    totalItemsCount={totalOrgCount}
                                    showPagination={showPagination}
                                    activePage={
                                        activePage !== 0 ? 
                                        activePage : 1}
                                    onPageSizeChange={this.handlePageSizeChange}
                                    handlePageChange={this.handlePageChange}
                                    defaultSortDesc={false}
                                    defaultSorted={[sorted]}
                                    onSortedChange={this.onSortedChange}
                                    itemType="Organizations"
                                    getTrProps = {(state, rowInfo, column) => {
                                        if (rowInfo === undefined) {
                                            return {};
                                        };

                                        return {
                                            'data-qnt': 
                                                rowInfo && 
                                                rowInfo.original && 
                                                rowInfo.original.children && 
                                                rowInfo.original.children.length ? "1" : "0",
                                        };
                                    }}
                                    getTdProps={(state, rowInfo, column) => {
                                        if (
                                            column.className === "table_uid" ||
                                            column.className === "table_organization_name" ||
                                            column.className === "table_intergration_calendar" || 
                                            column.className === "table_status"){
                                            return {
                                                onClick: () => {
                                                    this.props.history.push(`/account-management/organizations/${rowInfo.original.organization_name}/edit`)
                                                },
                                                style: {
                                                    cursor: 'pointer'
                                                }
                                            };
                                        }
                                        else {
                                            return {};
                                        };
                                    }}
                                    SubComponent={this.state.organizationsList.length ? ({ row }) => {
                                        if(row && row._original && row._original.children) {
                                            return this.renderSubComponent(row);
                                        };
                                    } : null}
                                    manual
                                    Pag
                                >
                                </TableComponent>
                            </Col>
                        </Row>
                    </Grid>
                </div>
            </PageLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        permissions: state.authState.permissions,
        roleManager: state.roleManager
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateOrganization: (org) => {
            dispatch(updateOrganization(org))
        },
        updateOrganizationTitle: (org) => {
            dispatch(updateOrganizationTitle(org))
        },
        updateOrganizationId: (orgId) => {
            dispatch(updateOrganizationId(orgId))
        },
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Organizations));