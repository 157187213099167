import React, { Component } from 'react';
import Row from 'react-bootstrap/lib/Row';
import Modal from 'react-bootstrap/lib/Modal';

export default class CustomDomainInstructionModal extends Component {

    render () {
        const {
            modal
        } = this.props;

        return(
            <Modal
                show={modal == 'cd-instruction-modal'} 
                onHide={(e) => this.props.handleModal(e, '')}
                className="cd-instruction-modal"
            >
            <Modal.Body>
                <Row>
                    <Modal.Header closeButton>
                        <Modal.Body>
                            <Row>
                                <div>
                                    <h1>Step 1 - Change Custom Domain</h1>
                                        <li>Enter your custom domain in manage website then click save.</li>
                                    <div className="img-container-1">
                                        <div className="img-1"></div>
                                    </div>
                                        <li>After the save process is complete, you should recieve a verification CName and Value.</li>
                                    <div className="img-container-2">
                                        <div className="img-2"></div>
                                    </div>
                                </div>
                                <br/>
                                <div>
                                    <h1>Step 2 - Update Custom DNS Config</h1>
                                        <li>This will need to be done using your hosting service. The screen shots below show what this looks like for Google domain hosting, but all hosting services will have similar setups.</li>
                                        <li>Navigate to your DNS records/custom records section.</li>
                                        <li>Take the "verification" values and create a CNAME record for them. Specifically, the verification cname domain needs to go in the host name/domain name field and the verification value should go in the data field. It should look something like this:</li>
                                    <div className="img-container-3">
                                        <div className="img-3"></div>
                                    </div>
                                        <li>Note that it's recommended that the TTL be set to something fairly small to make sure it takes effect fairly qulckly.</li>
                                        <li>In addition to this CNAME record, you will need to add another CNAME record that will represent the final hostname pointing to the default website we provide (something like*.vacationsbyredawning.com).</li>
                                    <div className="img-container-4">
                                        <div className="img-4"></div>
                                    </div>
                                        <li>You should end up with 2 CNAME records: one for the AWS verification and one for your desired domain.</li>
                                </div>
                                <br/>
                                <div>
                                    <h1>Step 3 - Wait</h1>
                                    <span>
                                        At this point, we need to wait for AWS to verify the certificate and the DNS changes to take effect. This can take anywhere from a few minutes to a day. If the status shows failed, please contact customer service.
                                    </span>
                                </div>
                            </Row>
                        </Modal.Body>
                    </Modal.Header>
                </Row>
            </Modal.Body>
        </Modal>
        )
    }
}