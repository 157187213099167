import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import BackgroundCells from 'react-big-calendar/lib/BackgroundCells';
import { inRange } from 'react-big-calendar/lib/utils/eventLevels';
import moment from 'moment';
import Constant from '../../../js/constants';

/**
 * Big calendar date cells.
 */
class CellComponent extends BackgroundCells {
    constructor(props) {
        super(props);
        this.today=moment(props.now).startOf('day');
        this.setRefs = this.setRefs.bind(this);
        this.smart_pricing_enabled = false;
    }
    hasCico(date) {
        let exist = false;
        this.cico.forEach(e => {
            if(inRange(e,date,date,this.props)) {
                exist=true
            }
        });
        return exist
    }
    cicoData(date) {
        let data = {};
        const dateFormat = moment(date).format('YYYY-MM-DD');

        this.cico.forEach(e => {
            const start_date = moment(e.start_date).format('YYYY-MM-DD');
            const end_date = moment(e.end_date).format('YYYY-MM-DD');

            if(moment(dateFormat).isBetween(start_date, end_date) || moment(dateFormat).isSame(start_date) || moment(dateFormat).isSame(end_date)) {
                data=e
            };
        });
        return data;
    }
    /**
     * Check if a particular date is blocked or not.
     */
    isBlocked(date) {
        let exist=false
        this.blocked.forEach(e => {
            if(e && e.period && e.period.type === 'Rooms Available') 
                return exist
            if(inRange(e,date,date,this.props)) {
                exist=true
            }
        });
        return exist
    }
    hasKeyBlock(date) {
        let hasBlock = false,
        fDate = moment(date).format('YYYY-MM-DD')
        this.keyBlocks.forEach(({ period }) => {
            if (period.type !== "Temporary" && period.type !== "BlockedByBooking") {
                if (moment(fDate).isSameOrAfter(moment(period.period_start).format('YYYY-MM-DD')) && moment(fDate).isSameOrBefore(moment(period.period_end).format('YYYY-MM-DD'))) {
                    hasBlock = true
                }
            }
        })
        return hasBlock
    }
    hasReservation(date) {
        let hasReservation = false,
        fDate = moment(date).format('YYYY-MM-DD')
        this.blocked.forEach(({ checkin_date, checkout_date, reservation_id }) => {
            if (reservation_id) {
                if (moment(fDate).isSameOrAfter(moment(checkin_date).format('YYYY-MM-DD')) && moment(fDate).isSameOrBefore(moment(checkout_date).format('YYYY-MM-DD'))) {
                    hasReservation = true
                }
            }
        })
        return hasReservation
    }
    getEventWithPropertyIdByDate(mDate, propertyId, propertLevel) {
        let evtWithPropertyId,
        fDate = mDate.format('YYYY-MM-DD')
        if (propertLevel === "rep") {
            this.props.multiRepFinalAvail[propertyId].forEach(evt => {
                if (moment(evt.period.period_start).format('YYYY-MM-DD') === fDate) {
                    evtWithPropertyId = evt
                }
            })
        }
        return evtWithPropertyId
    }
    /**
     * check event accessor to show block type from it.
     * @param {*} acc accessor will give you access to filter view of calendar.
     */
    isEnabled(acc) {
        if(this.props.eventAccessor.indexOf(acc)>-1)
            return true;
        return false;
    }
    setRefs(ref,date) {
        this.props.handleRefs(ref,date,this.props.propId)
    }
    returnCurrency = (data) => {
        let currency = '';

        if(Constant.CURRENCY[data]) {
            currency = `${Constant.CURRENCY[data]}`;
        }
        else {
            currency = <span className='currency-undefined'>
                <i className='icon-question-mark-unknown'></i>
            </span>
        };

        return currency;
    }
    render() {
        let _this=this,isPrice,priceAccessor,propId=this.props.propId,dragEvents=this.props.dragEvents;
        let {
            admin,
            guestServices,
            propertyData,
            currentProperty
        } = this.props;

        if 
        (
            (propertyData &&
            propertyData.smart_pricing_enabled) ||
            (currentProperty &&
            currentProperty.smart_pricing_enabled)
        ) {
            this.smart_pricing_enabled = true;
        };

        this.periods=this.props.filteredEvts.filter(evt=>{
            if(evt.price_period_id)
                return evt
        });
        this.blocked = this.props.filteredEvts.filter(evt=>{
            if(evt.period || evt.reservation_id)
                return evt
        });
        this.cico = this.props.filteredEvts.filter(evt=>{
            if(evt.cico_id)
                return evt
        });
        if (this.props.mobileMode) {
            this.keyBlocks = this.props.availability.filter(evt => {
                if (this.props.propertyLevel !== "rep"){
                    if (evt.period && evt.period.availability_id) {
                        return evt
                    }
                }
            })
        };
        return (
            this.props.range.map((date,index)=>{ 
                let mDate=moment(date),
                    sMonth = moment(this.props.date).format("MMMM"),
                    month = moment(date).format('MMMM'),
                    day=mDate.weekday(),
                    enableDate=mDate.isSameOrAfter(this.today),
                    isFirst=mDate.format('D')==1,
                    showAvailabilty=this.isEnabled('availability'),
                    showPricing=this.isEnabled('price_periods'),
                    highlightPricing=false,
                    highlightBlocked=false;

                if(day==5 || day==6) {
                    priceAccessor='weekend_price';
                }
                else {
                    priceAccessor='weekday_price';
                }
                dragEvents.pricingHighlightedDates.forEach(hDate=>{
                    if(moment(hDate).isSame(mDate) && dragEvents.pricingPropId==propId) {
                        highlightPricing=true;
                    }
                })
                dragEvents.blockedHighlightedDates.forEach(hDate=>{
                    if(moment(hDate).isSame(mDate) && dragEvents.blockedPropId==propId) {
                        highlightBlocked=true;
                    }
                });
                return this.props.isMobileCalendarModalView === false && (
                    <div 
                        key={index} 
                        className='rbc-day-bg' 
                        style={this.props.cellStyle} 
                        ref={ref=>this.setRefs(ref,date)}
                    >
                        {this.props.monthView &&
                            <span
                                className={`rbc-date
                                    ${mDate.isSame(this.today) ? ' today' : ''}
                                    ${mDate.isBefore(this.today) ? ' past-day' : ''}
                                    ${this.props.mobileMode && (((this.props.propertyLevel === 'rep' && mDate.isSameOrAfter(this.today) &&this.props.multiAvailTable[this.props.propId][mDate.format('YYYY-MM-DD')] === 0) || this.props.propertyLevel !== 'rep' && this.hasKeyBlock(date) && this.hasReservation(date) === false)) ? ' new-white' : ''}
                                    ${sMonth !== month ? ' off-range' : ''}
                                `}
                            >
                                {mDate.format('D')} 
                                <span 
                                    className={this.props.mobileMode ? 'subscript' : ''}
                                >
                                    {isFirst?mDate.format('MMM'):''}
                                </span>
                            </span>
                        }
                        {/* { Handles Block Dates / Availability } */}
                        {(
                            (admin || guestServices) && 
                            enableDate && 
                            showAvailabilty && 
                            !this.props.mobileMode) && 
                            ((this.props.propertyData !== undefined && !((this.props.propertyData && this.props.propertyData.level === "rep") && this.props.integrationName.integration_name === "racalendar")) || 
                            (this.props.currentProperty !== undefined && !((this.props.currentProperty && this.props.currentProperty.level === "rep") && this.props.integrationName.integration_name === "racalendar")))  ? 
                            (this.isBlocked(date) ? '' :
                            <a href="#!" 
                                onMouseDown={e=>dragEvents.handleBlockedDragStart(e,date,propId)} 
                                onMouseMove={e=>dragEvents.handleBlockedDrag(e,date,propId)} 
                                onMouseUp={e=>dragEvents.handleBlockedDragEnd(e,date,propId)} 
                                onClick={e=>this.props.addAvailability(date,date,propId)} 
                                title={`Block ${this.props.propertyLevel === "rep" ? "Rooms" : 'Dates'}`} 
                                className={'block-calendar ' + (highlightBlocked ?'highlight':'')}>
                            </a>) : '' 
                        }
                        {(admin && enableDate && this.props.mobileMode && this.props.propertyLevel === "repchild" && !this.hasKeyBlock(date) && !this.hasReservation(date)) && (
                            <a href="#!" className='block-calendar' onClick={e=>this.props.addAvailability(date,date,propId)}/>
                        )}
                        {isPrice=false}
                        {
                            !this.props.repChild ? 
                            this.periods.map((e,id)=>{
                                if(inRange(e,date,date,this.props) || moment(date).format('YYYY-MM-DD') === moment().format()) {
                                    const price_period_overrides = 
                                        e && 
                                        e.price_period_overrides ?
                                        e.price_period_overrides.filter((override) => moment(moment(override.date).format('YYYY-MM-DD')).isSame(moment(date).format('YYYY-MM-DD'))) 
                                        :
                                        [];
                                    const priceOvr =  
                                        price_period_overrides &&
                                        price_period_overrides[0] &&
                                        price_period_overrides[0].price || "";
                                    const minPriceOvr =  
                                        price_period_overrides &&
                                        price_period_overrides[0] &&
                                        price_period_overrides[0].min_price || "";
                                    const minStayOvr =  
                                        price_period_overrides &&
                                        price_period_overrides[0] &&
                                        price_period_overrides[0].min_stay || "";


                                    isPrice=true;

                                    return this.props.mobileMode ? 
                                    (
                                        <div
                                            key={id}
                                            className={`${!this.props.activeProperty ? 'price-cont ' : ''}mobile-price-cont`}
                                            style={{
                                                zIndex: ((this.props.propertyLevel === "rep" && this.props.multiAvailTable[this.props.propId][mDate.format('YYYY-MM-DD')] === this.props.totalRooms) || 
                                                (this.props.propertyLevel === "key" && this.hasReservation(date) === false && this.hasKeyBlock(date) === false)) && "99"
                                            }}
                                            onClick={event=>this.props.editPricing(event,e,propId,this.cicoData(date),mDate,price_period_overrides)}
                                        >
                                        {
                                            this.hasCico(date) && !this.props.monthView ?
                                            <span
                                                className='day-price cico'
                                            >
                                                <i className="icon-week"></i>
                                            </span>
                                            :
                                            ""
                                        }
                                        {
                                            !this.props.activeProperty && (
                                                <span
                                                    className='day-price'
                                                    onClick={event=>this.props.editPricing(event,e,propId,this.cicoData(date),mDate,price_period_overrides)}
                                                >
                                                    {
                                                        price_period_overrides && price_period_overrides[0] &&
                                                        <span>
                                                            {this.returnCurrency(this.props.currency)}{' ' + price_period_overrides[0].price + " " + "|"}
                                                        </span>
                                                    }
                                                    <span className={price_period_overrides && price_period_overrides[0] ? "price-ovr" : ""}>
                                                        {this.returnCurrency(this.props.currency)}{' ' + e[priceAccessor]}
                                                    </span>
                                                </span>
                                            )
                                        }
                                        </div>
                                    ) : (
                                        <div
                                            key={id}
                                            className="price-cont"
                                        >
                                            {
                                                this.hasCico(date) ?
                                                <span
                                                    className='day-price cico'
                                                    onClick={e=>this.props.editCico(e,this.cicoData(date),propId,mDate)}
                                                >
                                                    <i className="icon-week"></i>
                                                </span>
                                                :
                                                <span 
                                                    className='day-price cico'
                                                    onClick={e=>this.props.addCico(date,date,propId)}
                                                >
                                                    <i className="icon-cico"></i>
                                                </span>
                                            }
                                            
                                            <span 
                                                className='day-price'
                                                onClick={event=>this.props.editPricing(event,e,propId,this.cicoData(date),mDate,price_period_overrides)}
                                            >
                                                {
                                                    (minPriceOvr || priceOvr) &&
                                                    <Fragment>
                                                        {
                                                            minPriceOvr ?
                                                            <span>MIN {this.returnCurrency(this.props.currency)}{price_period_overrides[0].min_price}&nbsp;|&nbsp;</span>
                                                            :
                                                            <span>{this.returnCurrency(this.props.currency)}{price_period_overrides[0].price}&nbsp;|&nbsp;</span>
                                                        }
                                                    </Fragment>
                                                }
                                                <span className={(minPriceOvr || priceOvr) ? "price-ovr" : ""}>
                                                    {this.returnCurrency(this.props.currency)}{e[priceAccessor]}
                                                </span>
                                            </span>
                                            {
                                                this.smart_pricing_enabled ?
                                                <Fragment>
                                                    <span className={`minimum-stay-weekly-price ${(!minPriceOvr && !priceOvr) && 'price-override'}`}>
                                                        <ul>
                                                            <li>
                                                                {
                                                                    minPriceOvr ?
                                                                    <span>Minimum Price {this.returnCurrency(this.props.currency)}{price_period_overrides[0].min_price}&nbsp;|&nbsp;</span>
                                                                    :
                                                                    ""
                                                                }
                                                                {
                                                                    priceOvr ?
                                                                    <span>Price OVR {this.returnCurrency(this.props.currency)}{price_period_overrides[0].price}</span>
                                                                    :
                                                                    ""
                                                                }
                                                            </li>
                                                            <li> 
                                                                {
                                                                    minStayOvr ? 
                                                                    `Minimum Stay OVR ${price_period_overrides[0].min_stay} days`
                                                                    : 
                                                                    `Minimum Stay ${e.minstay} days`
                                                                }
                                                            </li>
                                                        </ul>
                                                    </span>
                                                </Fragment>
                                                :
                                                <Fragment>
                                                    <span className='minimum-stay-weekly-price'>
                                                        <ul>
                                                            <li>Minimum Stay {e.minstay} day{e.minstay > 1 ? 's' : ''}</li>
                                                            {
                                                                e.maxstay &&
                                                                <li>Maximum Stay {e.maxstay} day{e.maxstay > 1 ? 's' : ''}</li>
                                                            }
                                                            <li>{this.returnCurrency(this.props.currency)}{e.weekly_price}/Day Weekly Price</li>
                                                        </ul>
                                                    </span>
                                                </Fragment>
                                            }
                                        </div>
                                    )
                                }
                            }) : 
                            ''
                        }
                        {((!this.periods.length || !isPrice) && enableDate && showPricing && admin && !this.props.repChild) &&
                            <Fragment>
                                {
                                    !this.props.mobileMode ? 
                                    <div className='cell-gutter'>
                                        <div className='no-price-cont'>
                                            {
                                                this.hasCico(date) ?
                                                <span
                                                    className='add-cico' 
                                                    onClick={e=>this.props.editCico(e,this.cicoData(date),propId,mDate)}
                                                >
                                                    <i className="icon-week"></i>
                                                </span>
                                                :
                                                <span 
                                                    className='add-cico' 
                                                    onClick={e=>this.props.addCico(date,date,propId)}
                                                >
                                                    <i className="icon-cico"></i>
                                                </span>
                                            }
                                            {
                                                !this.smart_pricing_enabled && 
                                                <a 
                                                    title='Add Pricing' 
                                                    href="#!"
                                                    onClick={e=>this.props.addPricing(date,date,propId,mDate,this.cicoData(date))} 
                                                    onMouseDown={e=>dragEvents.handlePricingDragStart(e,date,propId)} 
                                                    onMouseMove={e=>dragEvents.handlePricingDrag(e,date,propId)} 
                                                    onMouseUp={e=>dragEvents.handlePricingDragEnd(e,date,propId)} 
                                                    className={'add-price ' + (highlightPricing ?'highlight':'')}
                                                />
                                            }
                                        </div>
                                    </div>
                                    :
                                    <Fragment>
                                        {
                                            this.hasCico(date) && 
                                            !this.props.monthView ?
                                            <span
                                                className='mobile-cico-cont'
                                                onClick={e=>this.props.addPricing(date,date,propId,mDate,this.cicoData(date))}
                                            >
                                                <i className="icon-week"></i>
                                            </span>
                                            :
                                            <span
                                                className='mobile-cico-cont'
                                                onClick={e=>this.props.addPricing(date,date,propId,mDate,this.cicoData(date))}
                                            >
                                            </span>
                                        }
                                        {
                                            !this.smart_pricing_enabled ?
                                            <div
                                                title='Add Pricing'
                                                onClick={e=>this.props.addPricing(date,date,propId,mDate,this.cicoData(date))}
                                                className="no-pricing-segment"
                                            />
                                            :
                                            ""
                                        }
                                    </Fragment>
                                }
                            </Fragment>
                        }
                        {
                            this.props.mobileMode && 
                            this.props.propertyLevel === "rep" && 
                            mDate.isSameOrAfter(this.today) && 
                            (
                                <div
                                    onClick={() => 
                                        this.props.showAvailableRooms(this.getEventWithPropertyIdByDate(mDate, propId, this.props.propertyLevel), propId, mDate.format('YYYY-MM-DD'))
                                    } 
                                    className="available-rooms-cont" 
                                >
                                    {this.props.activeProperty === "" &&
                                        <span 
                                            className={`room-count${this.props.multiAvailTable[propId][mDate.format('YYYY-MM-DD')] === 0 ? ' zero-rooms' : ''}`}
                                        >
                                            {this.isBlocked(date) ? "" : (this.props.multiAvailTable[propId][mDate.format('YYYY-MM-DD')] !== undefined ? 
                                            this.props.multiAvailTable[propId][mDate.format('YYYY-MM-DD')] : this.props.units)}
                                        </span>
                                    }
                                </div>
                            )
                        }
                    </div>
                )
            })
        )
    }
}

const mapStateToProps = state => {
    return {
        mobileMode: state.updateMobileState.mobileMode,
    }
}

export const Cell = connect(
    mapStateToProps
)(CellComponent)