import React, { Component, Fragment } from 'react';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import { CSVLink } from "react-csv";
import Link from 'react-router-dom/Link';

import { connect } from 'react-redux';
import { Title } from '../global/Title';
import { API } from 'aws-amplify';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import PageLayout from '../global/PageLayout';
import { InputField } from '../global/forms/FormElements';
import Constants from '../../js/constants';
import moment from 'moment';
import TableComponent from '../global/TableComponent';
import DateRangePicker from 'react-dates/lib/components/DateRangePicker';
import { tableSort } from '../../helpers/helpers';
import Picky from 'react-picky';
import { getSessionId } from '../../helpers/helpers';

class PropertyReservationListing extends Component {
    isAdmin = this.props.roles.indexOf(Constants.USER_TYPES.PROPERTY_MANAGER_ADMIN) > -1;
    guestServices = this.props.roles.indexOf(Constants.USER_TYPES.GUEST_SERVICES) > -1;
    dateTypeLabels = [
        { label: 'Check-In', key: 'checkin'},
        { label: 'Check-Out', key: "checkout"},
        { label: 'Booked', key: 'booked'},
    ];
    CSVReportsCopy = [];
    recordsCopy = [];
    orgsString = "";
    breadcrumbItems = [{
        title: "Reservations",
    }];
    dateTypeInit = "Check-Out";
    tid = getSessionId();
    org = this.props.roleManager.org;
    state = {
        records: [],
        keyword: "",
        isLoading: false,
        pageSize: 20,
        orgsTreeData: {},
        totalReserveCount: 0,
        activePage: 0,
        dateType: {},
        dateTypeValue: "Check-Out",
        showCancellations: false,
        CSVReports: [],
        externalReserveId: 0,
        showOrgSubs: true,
        sorted: {
            id: "checkin_date",
            desc: false,
        },
        reservationsDates: {
            startDate: moment(),
            endDate: null,
        },
        columns:[],
        mobileColumns: [
            {
                "Header":"Res ID",
                "accessor": "reservation_id",
                "minWidth": 35,
                "Cell": row=>(
                    <div>
                        {row.value}
                    </div>
                )
            },
            {
                "Header":"Check-In",
                "accessor": "checkin_date",
                "minWidth": 65,
                "Cell": row=>(
                    <div>
                        {moment(row.value).format('MM/DD/YYYY')}
                    </div>
                )
            },
            {
                "Header":"Check-Out",
                "accessor": "checkout_date",
                "minWidth": 60,
                "Cell": row=>(
                    <div>
                        {moment(row.value).format('MM/DD/YYYY')}
                    </div>
                )
            },
            {
                "Header":"Property Name",
                "accessor": "property_title",
                "minWidth": 75,
                "Cell": row=>(
                    <div className="propertyId">
                        {row.original.property_title}
                    </div> 
                )
            },
            {
                "Header":"Book Date",
                "accessor": "book_date",
                "minWidth": 50,
                "Cell": row=>(
                    <div>
                        {moment(row.value).format('MM/DD/YYYY')}
                    </div>
                )
            }
        ],
    }

    updateCSVHeaders = () => {
        const { records } = this.state;
        let CSVHeaders = [
            { label: 'Reservation ID', key: 'reservation_id'},
            { label: 'Property ID', key: 'property_id'},
            { label: 'External Reservation ID', key: 'property_manger_reservation_id'},
            { label: 'Date Booked', key: 'book_date'},
            { label: 'Check-In', key: 'checkin_date'},
            { label: 'Check-Out', key: 'checkout_date'},
            { label: 'Nights Booked', key: 'nights_booked'},
            { label: 'Organization', key: 'organization_name'},
            { label: 'Property Name', key: 'property_title'},
            { label: 'Guest Name', key: 'guest_name'},
            { label: 'Guest Phone Number', key: 'phone'},
            { label: 'Rent', key: 'rent'},
            { label: 'Fee', key: 'fee'},
            { label: 'Tax', key: 'tax'},
            { label: 'Total', key: 'total'},
            { label: 'Status', key: 'status'},
        ];

        let exReservID = records.filter((record) => {
            return record.property_manger_reservation_id;
        });

        if(exReservID.length) {
            CSVHeaders.splice(1, 0, { label: 'External Reservation ID', key: 'property_manger_reservation_id'});
        };

        let channel = records.filter((record) => {
            return record.channel;
        });

        if (channel.length) {
            CSVHeaders.splice(3, 0, { label: 'Channel', key: 'channel'});
        };

        return CSVHeaders;
    }

    updateColumns = () => {
        let columns = [];

        columns = [
            ...columns,
            {
                "Header":row => tableSort("Reservation ID"),
                "accessor": "reservation_id",
                "minWidth": 120,
                "Cell": row=>(
                    <div>
                        <strong>{row.value}</strong>
                    </div>
                )
            },
            {
                "Header": "Property ID",
                "accessor": "property_id",
                'sortable': false,
                "minWidth": 85,
                "Cell": row=>(
                    <div>
                        <strong>{row.value}</strong>
                    </div>
                )
            },
            {
                "Header": "Reference ID",
                "accessor": "external_property_id",
                'sortable': false,
                "minWidth": 100,
                "Cell": row=>{
                    const referenceID = row.value;

                    return (
                        <OverlayTrigger
                            placement='top'
                            overlay={
                                <Tooltip>
                                    {referenceID}
                                </Tooltip>
                            }
                        >
                            <div>
                                <strong>{referenceID}</strong>
                            </div>
                        </OverlayTrigger>
                    )
                }
            },
            {
                "Header":row => tableSort("Date Booked"),
                "accessor": "book_date",
                "minWidth": 120,
                "Cell": row=>(
                    <div>
                        {moment(row.value).format('MM/DD/YYYY')}
                    </div>
                )
            },
            {
                "Header":row => tableSort("Check-In"),
                "accessor": "checkin_date",
                "minWidth": 90,
                "Cell": row=>(
                    <div>
                        {moment(row.value).format('MM/DD/YYYY')}
                    </div>
                )
            },
            {
                "Header":row => tableSort("Check-Out"),
                "accessor": "checkout_date",
                "minWidth": 100,
                "Cell": row=>(
                    <div>
                        {moment(row.value).format('MM/DD/YYYY')}
                    </div>
                )
            },
            {
                "Header":"Nights Booked",
                "minWidth": 105,
                'sortable': false,
                "Cell": row=>{
                    const checkin_date = row.original.checkin_date;
                    const checkout_date = row.original.checkout_date;

                    return (
                        <div>
                            {moment(checkout_date).diff(checkin_date,'days')}
                        </div>
                    )
                }
            },
            {
                "Header":row => tableSort("Property Name"),
                "accessor": "property_title",
                "minWidth": 130,
                "Cell": row=>(
                    <OverlayTrigger
                        placement='top'
                        overlay={
                            <Tooltip
                                id="property_id"
                            >
                                {`${row.original.property_id} - ${row.original.property_title}`}
                            </Tooltip>
                        }
                    >
                        <div>
                            {row.original.property_title}
                        </div> 
                    </OverlayTrigger>
                )
            },
            {
                "Header": "Guest Name",
                "accessor": "first_name",
                'sortable': false,
                "minWidth": 100,
                "Cell": row=>
                {
                    const {
                        first_name,
                        last_name
                    } = row.original
                    return (
                        <div>
                            {`${first_name ? first_name : ''} ${last_name ? last_name : ''}`}
                        </div>
                    )
                }

            },
            {
                "Header": "Guest Phone Number",
                "accessor": "phone",
                'sortable': false,
                "minWidth": 140,
                "Cell": row=>
                {
                    const {
                        phone
                    } = row.original
                    return (
                        <div>
                            {this.formatPhoneNumber(phone)}
                        </div>
                    )
                }

            },
            {
                "Header":row => tableSort("Rent/Fee/Tax"),
                "accessor": "rental_price",
                "minWidth": 150,
                "Cell": row => {
                    let { 
                        rental_price, 
                        cleaning_fee, 
                        tax,
                        currency
                    } = row.original.price
                    const currencySymbol = currency && Constants.CURRENCY[currency];
    
                    return `${currencySymbol}${rental_price}/${currencySymbol}${cleaning_fee}/${currencySymbol}${tax}`;
                }
            },
            {
                "Header":row => tableSort("Total"),
                "accessor": "total",
                "minWidth": 90,
                "Cell": row => {
                    const {
                        currency
                    } = row.original.price
                    const currencySymbol = currency && Constants.CURRENCY[currency];
                    const totalPaymentsData = {
                        tax: _.get(row, 'original.price.tax') || 0,
                        rental_price: _.get(row, 'original.price.rental_price') || 0,
                        cleaning_fee: _.get(row, 'original.price.cleaning_fee') || 0,
                        ra_commission: _.get(row, 'original.price.ra_commission') || 0,
                        cc_processing: _.get(row, 'original.price.cc_processing') || 0,
                        adjustments: _.get(row, 'original.price.adjustments') || 0,
                        other_fees: _.get(row, 'original.price.other_fees') || 0,
                        channel_collected_tax: _.get(row, 'original.price.channel_collected_tax') || 0,
                    };
    
                    return `${currencySymbol}${this.calculateTotal(totalPaymentsData).toFixed(2)}`;
                },
            },
            {
                "Header": "Status",
                "accessor": "status",
                "sortable": false,
                "minWidth": 80,
                "Cell": row=>(
                    <div>
                        {row.value}
                    </div>
                )
            },
        ];

        let externalReserveId = 
            this.state.externalReserveId > 0 ?
            {
                "Header": "External Reservation ID",
                "accessor": "property_manger_reservation_id",
                'sortable': false,
                "minWidth": 155,
                "Cell": row=>(
                    <div>
                        <strong>{row.value}</strong>
                    </div>
                )
            }
            :
            {};

        let orgName = 
            this.state.orgsTreeData && 
            this.state.orgsTreeData[0] && 
            this.state.orgsTreeData[0].child_organizations && 
            this.state.orgsTreeData[0].child_organizations.length && 
            this.state.showOrgSubs ?
            {
                'Header':'Organization',
                'accessor': 'organization_name',
                "minWidth": 100,
                'sortable': false,
                'Cell': row=>(
                    <div className='organization_name'>
                        <strong>{row.value}</strong>
                    </div>
                )
            }
            :
            {};
        
        let channel = 
            (this.state.records && 
            this.state.records[0] && 
            this.state.records[0].channel) ?
            {
                'Header':'Channel',
                'accessor': 'channel',
                "minWidth": 120,
                'sortable': false,
                'Cell': row=>(
                    <div>
                        {row.value}
                    </div>
                )
            }
            :
            {};

        if(Object.keys(externalReserveId).length > 0) {
            columns.splice(1, 0, externalReserveId);
        };

        if(Object.keys(channel).length > 0) {
            columns.splice((Object.keys(externalReserveId).length > 0 ? 3 : 2), 0, channel);
        };

        if(Object.keys(orgName).length > 0) {
            let index = 2;

            if(Object.keys(externalReserveId).length > 0 && Object.keys(channel).length > 0) {
                index = 4;
            }
            else if(Object.keys(externalReserveId).length > 0 || Object.keys(channel).length > 0) {
                index = 3;
            };

            columns.splice(index, 0, orgName);
        };

        return columns;
    }
    
    calculateTotal = (data) => {
        let total = 0;

        Object.values(data).forEach((value) => {
            if(_.isNumber(value)) {
                total = total + value;
            };
        });

        return total;
    }
    
    componentDidMount() {
        const params = JSON.parse(sessionStorage.getItem('reservations-listing'));

        if(params) {
            const { 
                showOrgSubs,
                pageSize,
                activePage,
                keyword,
                sorted,
                showCancellations,
                reservationsDates,
                dateTypeValue
            } = this.state;

            this.setState({
                showOrgSubs: params.showOrgSubs || showOrgSubs,
                pageSize: params.pageSize || pageSize,
                keyword: params.keyword || keyword,
                sorted: params.sorted || sorted,
                showCancellations: params.showCancellations || showCancellations,
                dateTypeValue: params.dateTypeValue || dateTypeValue,
                reservationsDates: {
                    startDate: 
                        (params && params.reservationsDates && params.reservationsDates.startDate) ? 
                        moment(params.reservationsDates.startDate) :
                        reservationsDates.startDate,
                    endDate: 
                        (params && params.reservationsDates && params.reservationsDates.endDate) ?
                        moment(params.reservationsDates.endDate) :
                        reservationsDates.endDate,
                }
            }, async () => {
                this.fetchReservations(params.activePage != 0 ? (params.activePage - 1) : activePage);
            });
        }
        else {
            this.fetchReservations(0);
        };
    }

    setSessionStorage = () => {
        const { 
            showOrgSubs,
            pageSize,
            activePage,
            keyword,
            sorted,
            reservationsDates,
            showCancellations,
            dateTypeValue
        } = this.state;

        const pagination = {
            showOrgSubs: showOrgSubs,
            pageSize: pageSize,
            activePage: activePage,
            keyword: keyword,
            sorted: sorted,
            reservationsDates: reservationsDates,
            showCancellations: showCancellations,
            dateTypeValue: dateTypeValue
        };

        sessionStorage.setItem('reservations-listing', JSON.stringify(pagination));
    }

    getOrgsTreeData = async () => {
        const { 
            keyword,
            showOrgSubs
        } = this.state;
        let parentOrg = encodeURIComponent(this.org);
        let orgs = []
        let resp = await API.get("accapi", `/organizationtree?parent=${parentOrg}`);
        
        if(resp) {
            this.setState({
                orgsTreeData: resp
            }, () => {
                const { 
                    orgsTreeData
                } = this.state;
                let orgsString = '';
                let orgsTreeParent = orgsTreeData[0];
                let searchKeyword = keyword;
                let parentOrgName = encodeURIComponent(orgsTreeParent.organization_name);
        
                orgs = [parentOrgName];
        
                function walk(node) {
                    let children = node.child_organizations;
                    // Children are siblings to each other
                    if (children && children.length) {
                        for (let i = 0; i < children.length; i++) { 
                            if (children && children[i] && children[i].organization_name) {
                                let child = children[i];
                                let childOrgName = encodeURIComponent(child.organization_name);
        
                                if (searchKeyword) {
                                    if (String(searchKeyword).toLowerCase() === String(childOrgName).toLowerCase()) {
                                        orgs = [childOrgName];
                                        return;
                                    } 
                                    else if (String(searchKeyword).toLowerCase() === String(child.title).toLowerCase()) {
                                        orgs = [childOrgName];
                                        return;
                                    } 
                                    else if (String(searchKeyword).toLowerCase() === String(child.manager_uid).toLowerCase()) {
                                        orgs = [childOrgName];
                                        return;
                                    } 
                                    else {
                                        orgs.push(childOrgName);
                                    };
                                }
                                else {
                                    orgs.push(childOrgName);
                                };
                                walk(child);
                            };
                        };
                    };
                };
        
                if (showOrgSubs && 
                    orgsTreeParent && 
                    orgsTreeParent.child_organizations && 
                    orgsTreeParent.child_organizations.length && 
                    parentOrg !== '_global_' && 
                    parentOrg !== 'travelagencies' && 
                    parentOrg !== 'propertymanagers' && 
                    parentOrg !== 'propertyowners'
                ) {
                    walk(orgsTreeParent);
                };

                if (orgs.length == 1) {
                    orgsString = `organization=${orgs[0]}`;
                } 
                else {
                    orgsString = `organizations=${orgs.join()}`;
                };
        
                this.orgsString = orgsString;
            });
        };
    }

    fetchReservations = async (active_page, sort, page_size) => {
        const { 
            pageSize,
            keyword,
            sorted,
            reservationsDates,
            dateTypeValue,
            showCancellations,
            compareRecords,
        } = this.state;

        this.setState({ 
            isLoading: true 
        });

        await this.getOrgsTreeData();
        
        const startDate = 
            moment(reservationsDates.startDate).isValid() ? 
            moment(reservationsDates.startDate).format("YYYY-MM-DD") :
            "";
        const endDate = 
            moment(reservationsDates.endDate).isValid() ? 
            moment(reservationsDates.endDate).format("YYYY-MM-DD") :
            "";
        const dateType = {
            "Check-In" : "checkin",
            "Check-Out" : "checkout",
            "Booked" : "book",
        };

        let searchKeyword = '';
        if (keyword) {
            searchKeyword = `&keyword=${encodeURIComponent(keyword)}`
        };

        const newPageSize = page_size ? page_size : pageSize;
        let newActivePage = ((active_page === 0) ? 0 : active_page);
        const newSorted = sort ? sort : sorted;
        const compare = keyword === "compare";
        const sortDesc = newSorted.desc ? "-" : "+";
        const searchParam = compare ? "" : searchKeyword;
        const status = !showCancellations? '&status=current': '';
        const offset = newPageSize * newActivePage;
        
        if(this.dateTypeInit !== dateTypeValue) {
            this.tid = getSessionId();

            this.dateTypeInit = dateTypeValue;
        };
            
        let params = `?${this.orgsString}&limit=${pageSize}&tid=${this.tid}&offset=${offset}&sort=${compare ? sorted.id : encodeURIComponent(newSorted.id)}${compare ? "+" : encodeURIComponent(sortDesc)}${`&${dateType[dateTypeValue]}=`}${`${startDate ? startDate : ""}${startDate && endDate ? ":" : ""}${endDate ? endDate : ""}`}${status}`

        try {
            let reservationsData = await API.get(Constants.RAPAPI.NAME, Constants.RAPAPI.PROPERTY_RESERVATIONS + params + searchParam, { response: true });
            let checkExternalReserveId = reservationsData.data.filter((data) => {
                return data.property_manger_reservation_id;
            });

            this.setState({
                records: reservationsData.data,
                externalReserveId: checkExternalReserveId.length,
                compareRecords: compare ? reservationsData.data : compareRecords,
                totalReserveCount: reservationsData && reservationsData.headers && reservationsData.headers["x-total-count"],
                sorted: {
                    ...sorted,
                    id: newSorted.id,
                    desc: newSorted.desc,
                },
                activePage: (newActivePage + 1),
                pageSize: pageSize,
                isLoading: false,
            }, () => {
                this.setState({
                    columns: this.updateColumns()
                });
                this.setSessionStorage();
            });
        }
        catch(e) {
            console.log(e);
            this.setState({
                isLoading: false
            });
        };
    }

    handleSearch = (e) => {
        e.preventDefault();
        this.fetchReservations(0);
    }

    handleClearSearch = () => {
        this.setState({
            keyword: ''
        }, () => {
            this.fetchReservations(0);
        });
    }
    
    handlePageChange = (page) => {
        this.fetchReservations(page);
    }

    onSortedChange = (newSorted) => {
        this.fetchReservations(0, newSorted[0]);
    }

    getRowProps = (state, rowInfo, column) => {
        return {
            onClick: (e, handleOriginal) => {
                this.props.history.push({
                    pathname: `/reservations/${rowInfo.original.reservation_id}/details`,
                    state: {
                        details: rowInfo.original
                    }
                })
            },
            style: {
                cursor: 'pointer'
            }
        };
    }

    /** Handle cancellation a reservation. */
    handleCancellations = () => {
        this.setState({ 
            showCancellations: !this.state.showCancellations
        }, () => {
            this.fetchReservations(0);
        });
    }

    /** Handle past reservation to search reservation made in past will call the API */
    handleShowOrgSubs = () => {
        const { showOrgSubs} = this.state;
        this.setState({ 
            showOrgSubs: !showOrgSubs
        }, () => {
            this.fetchReservations(0);
        });
    }

    resetFilter = async () => {
        this.setState({
            pageSize: 20,
            keyword: "",
            dateTypeValue: "Check-Out",
            showOrgSubs: true,
            showCancellations: false,
            sorted: {
                id: "checkin_date",
                desc: false,
            },
            reservationsDates: {
                startDate: moment(),
                endDate: null,
            },
        }, () => {
            this.fetchReservations(0);
        });
    }

    handlePageSizeChange = (newPageSize) => {
        const { pageSize } = this.state;

        if(!isNaN(newPageSize) && newPageSize.trim() !== "" && newPageSize !== pageSize) {
            this.setState({
                pageSize: newPageSize
            }, () => {
                this.fetchReservations(0, '', newPageSize);
            });
        };
    }

    isOutsideRange = () => {
        return false;
    }

    handleDatesChange = (newDates) => {
        this.setState({
            reservationsDates: {
                ...newDates
            }
        }, () => {
            const { 
                reservationsDates
            } = this.state;
            let startElement = document.getElementById('start');
            let startElementValue = startElement.value;
            let endElement = document.getElementById('end');
            let endElementValue = endElement.value;

            if(moment(reservationsDates && reservationsDates.startDate).isValid() && (endElementValue == "") || moment(reservationsDates && reservationsDates.endDate).isValid() && (startElementValue == "")) {
                this.fetchReservations(0);
            }
            else if (moment(reservationsDates && reservationsDates.startDate).isValid() && moment(reservationsDates && reservationsDates.endDate).isValid()) {
                this.fetchReservations(0);
            };
        });
    }

    handleDateType = (value) => {
        this.setState({ 
            dateType: value 
        }, async () => {

            this.setState({
                dateTypeValue: value.label,
            }, () => {

                const { dateTypeValue } = this.state;
                let defaultDates = {
                    'Check-In': {
                        startDate: moment(),
                        endDate: null,
                    },
                    'Check-Out': {
                        startDate: moment(),
                        endDate: null,
                    },
                    'Booked': {
                        startDate: moment().startOf('month'),
                        endDate: null,
                    },
                };

                this.setState({
                    reservationsDates: defaultDates[dateTypeValue]
                }, () => {
                    this.fetchReservations(0);
                });
            });
        });
    }

    downloadCSV = async () => {
        const { 
            CSVReports,
            records
        } = this.state;

        if(CSVReports !== this.CSVReportsCopy || records !== this.recordsCopy) {
            await this.getReservationsCSV();

            const CSVcomponent = document.getElementById("download-csv");
            CSVcomponent.click();
        };
    }

    getReservationsCSV = async () => {
        const {
            showCancellations,
            reservationsDates,
            dateTypeValue,
            showOrgSubs,
        } = this.state;
        const startDate = 
            moment(reservationsDates.startDate).isValid() ? 
            moment(reservationsDates.startDate).format("YYYY-MM-DD") :
            "";
        const endDate = 
            moment(reservationsDates.endDate).isValid() ? 
            moment(reservationsDates.endDate).format("YYYY-MM-DD") :
            "";
        const dateType = {
            "Check-In" : "checkin",
            "Check-Out" : "checkout",
            "Booked" : "book",
        };
        const tid = getSessionId();
        let response = [];
        let reports = [];
        let offset = 0;
        let limit = 100;
        let count = 0;
        let totalCount = 0;

        this.setState({
            isLoading: true,
        });

        try {
            response = await API.get(Constants.RAPAPI.NAME, Constants.RAPAPI.PROPERTY_RESERVATIONS + `${showOrgSubs ? ("?" + this.orgsString) : `?organization=${this.org}`}&limit=${limit}&offset=${offset}&tid=${tid}${`&${dateType[dateTypeValue]}=`}${`${startDate ? startDate : ""}${startDate && endDate ? ":" : ""}${endDate ? endDate : ""}`}${!showCancellations ? '&status=current' : ''}`, { response: true, isCognito: true });

            if(response && response.data) {
                
                response.data.forEach((x) => {
                    reports = [...reports, x];
                });

                count = count + response.data.length;

                totalCount = response.headers["x-total-count"];

                while(count < totalCount) {
                    try {
                        offset = offset + 100;

                        response = await API.get(Constants.RAPAPI.NAME, Constants.RAPAPI.PROPERTY_RESERVATIONS + `${showOrgSubs ? ("?" + this.orgsString) : `?organization=${this.org}`}&limit=${limit}&offset=${offset}&tid=${tid}${`&${dateType[dateTypeValue]}=`}${`${startDate ? startDate : ""}${startDate && endDate ? ":" : ""}${endDate ? endDate : ""}`}${!showCancellations ? '&status=current' : ''}`, { response: true, isCognito: true });

                        response.data.forEach((x) => {
                            reports = [...reports, x];
                        });
        
                        count = count + response.data.length;
                    }
                    catch(e) {
                        console.log(e)
                    };
                };

                if(count >= totalCount) {
                    let newReports = [];

                    reports.forEach((report, i) => {
                        let total = 0;
                        let rent = 0;
                        let fee = 0;
                        let tax = 0;
                        const totalPaymentsData = {
                            tax: _.get(report, 'price.tax') || 0,
                            rental_price: _.get(report, 'price.rental_price') || 0,
                            cleaning_fee: _.get(report, 'price.cleaning_fee') || 0,
                            ra_commission: _.get(report, 'price.ra_commission') || 0,
                            cc_processing: _.get(report, 'price.cc_processing') || 0,
                            adjustments: _.get(report, 'price.adjustments') || 0,
                            other_fees: _.get(report, 'price.other_fees') || 0,
                            channel_collected_tax: _.get(report, 'price.channel_collected_tax') || 0,
                        };

                        newReports.push({
                            reservation_id: report.reservation_id,
                            property_id: report.property_id,
                            property_manger_reservation_id: report.property_manger_reservation_id,
                            checkin_date: report.checkin_date,
                            checkout_date: report.checkout_date,
                            nights_booked: moment(report.checkout_date).diff(report.checkin_date,'days'),
                            channel: report.channel,
                            organization_name: report.organization_name,
                            property_title: report.property_title,
                            guest_name: `${report && report.first_name} ${report && report.last_name}`,
                            book_date: report.book_date,
                            rent: totalPaymentsData.rental_price,
                            fee: totalPaymentsData.cleaning_fee,
                            tax: totalPaymentsData.tax,
                            total: this.calculateTotal(totalPaymentsData).toFixed(2),
                            status: report.status,
                            phone: report.phone ? this.formatPhoneNumber(report.phone ) : "",
                        });
                    });

                    this.CSVReportsCopy = newReports;

                    this.recordsCopy = this.state.records;
                    
                    this.setState({
                        CSVReports: newReports,
                        isLoading: false,
                    });
                }
            };
        }
        catch(e) {
            console.log(e)
            this.setState({
                isLoading: false,
            });
        }
    }

    renderDateRange = () => {
        const { 
            dateTypeValue,
            reservationsDates,
            focusedInput
        } = this.state;

        return(
            <Fragment>
                <DateRangePicker
                    orientation={this.props.mobileMode ? "vertical" : "horizontal"}
                    noBorder
                    showClearDates={true}
                    minimumNights={0}
                    startDateId='start'
                    endDateId='end'
                    isOutsideRange={this.isOutsideRange}
                    startDatePlaceholderText="MM/DD/YYY"
                    endDatePlaceholderText="MM/DD/YYY"
                    startDate={reservationsDates && reservationsDates.startDate}
                    endDate={reservationsDates && reservationsDates.endDate}
                    focusedInput={focusedInput}
                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                    onDatesChange={this.handleDatesChange}
                />
            </Fragment>
        )
    }

    formatPhoneNumber = (phoneNumber) => {
        const cleaned = phoneNumber.replace(/\D/g, '');
        
        const pattern = /^(\d{3})(\d{3})(\d{4})$/;
        
        const formatted = cleaned.replace(pattern, '($1) $2-$3');
        
        return formatted;
    }

    render() {
        const {
            CSVReports,
            records,
            keyword,
            dateTypeValue,
            orgsTreeData,
            showOrgSubs,
            showCancellations,
            isLoading,
            pageSize,
            totalReserveCount,
            mobileColumns,
            columns,
            activePage,
            sorted,
        } = this.state;

        return (
            <PageLayout isCentered={true}>
                <div className={`reservations-listing ${this.props.mobileMode && 'mobile-view'}`}>
                    <Title 
                        title="Reservations" 
                        isCentered={true} 
                        breadcrumbItems={this.breadcrumbItems}
                    >
                        <div className="content-right">
                            <div className="csv-folder-download">
                                <OverlayTrigger 
                                    placement='bottom' 
                                    overlay={
                                        <Tooltip
                                            id="csv-download"
                                        >
                                            Download CSV
                                        </Tooltip>}
                                >
                                    <span 
                                        onClick={records.length ? this.downloadCSV : null}
                                    >
                                        <i className={`icon-folder-download ${!records.length ? 'disabled': ''}`} />
                                        <CSVLink
                                            asyncOnClick={true}
                                            headers={this.updateCSVHeaders()}
                                            data={CSVReports}
                                            filename={`${this.org}-reservations-reports.csv`}
                                            target="_blank"
                                            id="download-csv"
                                        >
                                        </CSVLink>
                                    </span>
                                </OverlayTrigger>
                            </div>
                            <form 
                                className='search-inp' 
                                onSubmit={this.handleSearch}
                            >
                                <InputField 
                                    type='searchbox' 
                                    placeholder='Search' 
                                    value={keyword} 
                                    onChange={e=>
                                        this.setState({
                                            keyword: e.target.value
                                        })
                                    }
                                    clearSearch={this.handleClearSearch}   
                                />
                            </form>
                            {
                                (this.isAdmin || this.guestServices) &&
                                <Link className='primary-btn' to='/reservations/create'>
                                    <i className='icon-Plus create-icon' />
                                    Create Reservation
                                </Link>
                            }
                        </div>
                    </Title>
                    <div id="content" className="reservations">
                        <Row>
                            <Col xs={12} sm={12}>
                                <div className='fs-warning left-padding-5'>For more details on <Link className="clickable-text" to={`/account/faq/getting_paid`}>Payout Timing</Link>.</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} className="display-flex bottom-margin">
                                {
                                    orgsTreeData && 
                                    orgsTreeData[0] && 
                                    orgsTreeData[0].child_organizations && 
                                    orgsTreeData[0].child_organizations.length && 
                                    orgsTreeData[0].organization_name !== '_global_' && 
                                    orgsTreeData[0].organization_name !== 'travelagencies' && 
                                    orgsTreeData[0].organization_name !== 'propertymanagers' && 
                                    orgsTreeData[0].organization_name !== 'propertyowners' ? 
                                    <div className="filters-wrap">
                                        <InputField 
                                            type="checkbox" 
                                            value={showOrgSubs} 
                                            cbChange={this.handleShowOrgSubs}>
                                            All Organizations
                                        </InputField>
                                    </div> : 
                                    ''
                                }
                                <div className="filters-wrap">
                                    <InputField 
                                        type="checkbox" 
                                        value={showCancellations} 
                                        cbChange={this.handleCancellations}>
                                        Show Cancellations
                                    </InputField>
                                </div>
                                <Picky
                                    placeholder="filter by"
                                    labelKey="label"
                                    valueKey="value"
                                    filterPlaceholder="Filter"
                                    options={this.dateTypeLabels}
                                    value={dateTypeValue}
                                    multiple={false}
                                    includeSelectAll={false}
                                    defaultFocusFilter={true}
                                    keepOpen={false}
                                    onChange={value => this.handleDateType(value)}
                                    dropdownHeight={600}
                                    render={({
                                        style,
                                        item,
                                        isSelected,
                                        selectValue,
                                        labelKey,
                                        valueKey,
                                        }) => {
                                            return (
                                                <li
                                                    style={{ ...style }} 
                                                    className={isSelected ? "selected" : ""} 
                                                    key={item[labelKey]}
                                                    onClick={() => selectValue(item)}
                                                >
                                                    <span
                                                        style={{fontWeight: isSelected ? "bold" : "normal"}}
                                                    >
                                                        {
                                                            (item[labelKey] !== null && item[labelKey] !== "") ? 
                                                            item[labelKey] : 
                                                            item[valueKey]
                                                        }
                                                    </span>
                                                </li>
                                            );
                                        }}
                                />
                                {this.renderDateRange()}
                                <OverlayTrigger
                                    placement='top'
                                    overlay={
                                        <Tooltip
                                            id="reset-filter"
                                        >
                                            Reset Filter
                                        </Tooltip>
                                    }
                                >
                                    <div
                                        className='clickable-text reset-filter'
                                        onClick={this.resetFilter}
                                    >
                                        Reset
                                    </div> 
                                </OverlayTrigger>
                            </Col>
                        </Row>
                        <TableComponent 
                            className={this.props.mobileMode ? 
                                'mobile-styled-table' : 
                                '-highlight'
                            }
                            loading={isLoading}
                            resizable={false} minRows={0}
                            pageSize={parseInt(pageSize)}
                            totalItemsCount={parseInt(totalReserveCount)}
                            data={records}
                            columns={this.props.mobileMode ? mobileColumns : columns}
                            showPagination
                            activePage={
                                activePage !== 0 ? 
                                activePage : 1
                            }
                            defaultSorted={[
                                sorted
                            ]}
                            onSortedChange={this.onSortedChange}
                            handlePageChange={this.handlePageChange}
                            onPageSizeChange={this.handlePageSizeChange}
                            noDataText={isLoading?"Loading...":"No reservations found."}
                            getTrProps={this.getRowProps}
                            itemType={totalReserveCount == 1 ? "Reservation" : "Reservations"}
                            mobileMode={this.props.mobileMode}
                        />
                    </div>
                </div>
            </PageLayout>
        )
    }
}
const mapStateToProps = state => {
    return {
        roleManager: state.roleManager,
        mobileMode: state.updateMobileState.mobileMode,
        roles: state.roleManager.roles,
    }
}
export default connect(
    mapStateToProps,
    null
)(PropertyReservationListing);
