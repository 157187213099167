import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { API } from 'aws-amplify';

import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import PageLayout from '../global/PageLayout';
import { Title } from '../global/Title';
import { FieldWrap } from '../global/forms/FormElements';

import ReactMarkdown from 'react-markdown';


class KnowledgeBotComponent extends Component {

    constructor() {
        super();
        this.onKeyUp = this.onKeyUp.bind(this);
    }

    
    breadcrumbItems = [
        { title: "ChatAI" }
    ];
    
    state = {
        threadId: null,
        userThreads: null,
        userQuestion: '',
        isLoadingThreads: false,
        isLoadingResponse: false,
        loadingStatusMessage: '',
        currentThread: [],
        errorMessage: null
    }

    setIsResponseLoading = (boolean) => {
        if (this.state.isLoadingResponse !== boolean) {
            this.setState({isLoadingResponse: boolean});
        }
    }

    setErrorMessage = (message) => {
        this.setState({
            errorMessage: message
        });
    }

    getThreads = async () => {
        this.setState({
            isLoadingThreads: true
        });
        let threadsResponse = await API.get('cbapi', `/chatbots/gskb/threads`);
        if (threadsResponse.length) {
            this.setState({
                userThreads: (threadsResponse.length) ? threadsResponse : null,
                isLoadingThreads: false
            });
        }
    }

    loadThread = async (threadId) => {
        try {
            const threadEndpoint = '/chatbots/gskb/threads/' + threadId + '/messages';
            let threadResponse = await API.get('cbapi', threadEndpoint);
            this.setState({
                threadId: threadId,
                currentThread: threadResponse.messages.reverse(),
                loadingStatusMessage: '',
                userQuestion: ''
            }, () => {
                const lastMessageId = (this.state.currentThread.length >= 2) ? this.state.currentThread.length - 2: 0;
                const lastMessageElement = document.getElementById(`message${lastMessageId}`);
                lastMessageElement.scrollIntoView({behavior: 'smooth'});
            });
        } catch(errorResponse) {
            const errorMessage = (errorResponse.message) ? errorResponse.message : 'We had a problem loading the thread';
            this.setErrorMessage(errorMessage);
        };
    }

    getThreadResponse = (threadId, statusEndpoint) => {
        this.setIsResponseLoading(true);
        let statusPollingCounter = 0;
        const statusCallback = async () => {
            statusPollingCounter += 1;
            if (statusPollingCounter === 6) {
                this.setState({
                    loadingStatusMessage: "Taking a bit longer than expected, but we're still working on it!"
                });
            }
            if (statusPollingCounter === 14) {
                this.setState({
                    loadingStatusMessage: "Seriously still here and working away, hang tight!"
                });
            }
            try {
                let statusResponse = await API.get('cbapi', statusEndpoint);
                if (statusResponse.status == 'success') {
                    if (!this.state.threadId) {
                        this.getThreads();
                    }
                    this.loadThread(threadId);
                    this.setIsResponseLoading(false);
                    clearInterval(threadLoader);
                } else if (statusResponse.status == 'expired') {
                    this.setIsResponseLoading(false);
                    clearInterval(threadLoader);
                }
            } catch(errorResponse) {
                const errorMessage = (errorResponse.message) ? errorResponse.message : 'We had a problem loading the response';
                this.setErrorMessage(errorMessage);
                this.setIsResponseLoading(false);
                clearInterval(threadLoader);
            };
        };
        let threadLoader = setInterval(statusCallback, 1000);
    }

    addToThread = async () => {
        if (this.state.userQuestion.length) {
            const questionObject = {
                "messages": [
                    {
                        "role": "user",
                        "content": this.state.userQuestion
                    }
                ]
            };
            const questionEndpoint = (this.state.threadId) ? `/chatbots/gskb/threads/${this.state.threadId}/messages` : '/chatbots/gskb/threads';
            let messageResponse;
            try {
                const currentQuestion = {
                    "role": "user",
                    "text_content": [
                        {
                            "text": this.state.userQuestion
                        }
                    ]
                };
                this.state.currentThread.push(currentQuestion);
                this.setState({
                    userQuestion: ''
                });
                this.setIsResponseLoading(true);
                messageResponse = await API.post('cbapi', questionEndpoint, { response: true, body: questionObject });
                setTimeout(() => {
                    if (messageResponse && messageResponse.data) {
                        this.getThreadResponse(messageResponse.data.thread_id, messageResponse.data.run_status_url);
                    }
                }, 4000);
            } catch(errorResponse) {
                const errorMessage = (errorResponse.message) ? errorResponse.message : 'We had a problem sending your question';
                this.setErrorMessage(errorMessage);
                this.setIsResponseLoading(false);
            };
        }
    }

    createNewThread = () => {
        this.setState({
            userQuestion: '',
            threadId: null,
            currentThread: []
        });
    }

    updateInputValue = (event) => {
        this.setState({
            userQuestion: event.target.value
        });
    }

    setThreadId = (threadId) => {
        this.setState({
            threadId: threadId
        });
        this.loadThread(threadId);
    }

    onKeyUp(event) {
        if (event.key === "Enter" && !event.shiftKey && this.state.userQuestion.length) {
            this.addToThread();
        }
    }

    async componentDidMount() {
        this.getThreads();
    }
    
    render() {
        const {
            threadId,
            userThreads,
            currentThread,
            userQuestion,
            isLoadingThreads,
            isLoadingResponse,
            loadingStatusMessage,
            errorMessage
        } = this.state;

        return (
            <PageLayout isCentered={true}>
                <Title 
                    title="Guest Services Knowledge Bot"
                    isCentered={true}
                    breadcrumbItems={this.breadcrumbItems}
                >
                </Title>
                <div id='content' className='knowledge-bot-component'>
                    <div className="container-fluid">
                        <FieldWrap>
                            <Row>
                                <Col sm={12} md={12}>
                                    <h1 className="bland content-container">
                                        Hello! You can ask me anything, like: find properties available during certain dates, list the details for a specific reservation, show the modification guidelines from the Guest Services knowledge base, find things to do around a certain property, generate a quote in real-time, or generate an answer for any guest question!
                                    </h1>
                                    {(isLoadingThreads || userThreads) &&
                                        <select value={(threadId) ? threadId : ''} onChange={e => this.setThreadId(e.target.value)} className='bubble-container fluid'>
                                            <option value="" disabled>{isLoadingThreads ? 'Loading saved threads... ' : 'Select a Previous Thread'}</option>
                                            {userThreads &&
                                                userThreads.map(function(thread, idx) {
                                                    return(
                                                        <option value={thread.thread_id} key={idx}>{thread.subject}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    }
                                    {threadId &&
                                        <button className='action-button' onClick={this.createNewThread}>Create a New Thread <i className="icon-Plus"></i></button>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={12}>
                                    {currentThread ? currentThread.map(function(message, idx) {
                                            const participantClassName = message.role == 'user' ? 'user-style' : 'bot-style';
                                            return (
                                                <div className={`bubble-container ${participantClassName}`} key={idx} id={`message${idx}`}>
                                                    <ReactMarkdown linkTarget="_blank" source={message.text_content[0].text}></ReactMarkdown>
                                                </div>
                                            );
                                        }) :
                                        <div className='bubble-container user-style'>
                                            <div className="typing">
                                                <div className="dot"></div>
                                                <div className="dot"></div>
                                                <div className="dot"></div>
                                            </div>
                                        </div>
                                    }
                                    {isLoadingResponse &&
                                        <div className='bubble-container bot-style'>
                                            <div className="typing">
                                                <div className="dot"></div>
                                                <div className="dot"></div>
                                                <div className="dot"></div>
                                            </div>
                                            { loadingStatusMessage &&
                                                <span className='bland'>
                                                    {loadingStatusMessage}
                                                </span>
                                            }
                                        </div>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={12}>
                                    <textarea type='text' placeholder={(isLoadingResponse) ? '' : ((threadId) ? 'Ask another question' : 'Ask a new question!')} className='question-input bubble-container' value={userQuestion} onChange={this.updateInputValue} onKeyPress={this.onKeyUp} disabled = {(isLoadingResponse)? "disabled" : ""}/><button className='action-button' onClick={this.addToThread}>&#9166;</button>
                                </Col>
                            </Row>
                            {errorMessage &&
                                <Row>
                                    <Col sm={12} md={12}>
                                        <span className='error-msg'>{errorMessage}</span>
                                    </Col>
                                </Row>
                            }
                        </FieldWrap>
                    </div>
                </div>
            </PageLayout>
        )
    }
}

export default withRouter(KnowledgeBotComponent);