import React, { Component, Fragment } from 'react';
import { API } from 'aws-amplify';
import Link from 'react-router-dom/Link';
import Picky from 'react-picky';
import 'react-picky/dist/picky.css';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import Modal from 'react-bootstrap/lib/Modal';

import TableComponent from '../global/TableComponent';
import PageLayout from '../global/PageLayout';
import { Title } from '../global/Title';
import { InputLabel, InputField, PrimaryButton } from '../global/forms/FormElements';
import constants from '../../js/constants'
import moment from 'moment';
import { tableSort, getSessionId } from '../../helpers/helpers';

class Users extends Component {
    breadcrumbItems = [
        { title: "Users" }
    ];
    org = this.props.roleManager.org;
    roles = this.props.roleManager.roles;
    orgTitles = [];
    tid = getSessionId();
    state = {
        showModal: "",
        usersList: [],
        isLoading: false,
        showPagination: true,
        showAll: false,
        pageSize: 10,
        totalUsersCount: 0,
        activePage: 0,
        keyword: "",
        sorted: {
            id: "email",
            desc: false,
        },
        validation: {
            email: false,
            validEmailFormat: false,
            roles: false,
            selectedOrganization: false,
            validRedawningEmail: false,
        },
        allOrganization: [],
        selectedOrganization: [],
        data: {
            email: "",
            roles: [],
        },
        columns:[]
    }

    updateColumns = () => {
        let columns = [
        {
            Header:() => tableSort("First Name"),
            'accessor': 'first_name',
            'width': 140,
            'Cell': row=>(
                <div>
                    <strong>{row.original.contact_info.first_name}</strong>
                </div>
            )
        },
        {
            Header:() => tableSort("Last Name"),
            'accessor': 'last_name',
            'width': 140,
            'Cell': row=>(
                <div>
                    <strong>{row.original.contact_info.last_name}</strong>
                </div>
            )
        },
        {
            Header:() => tableSort("Email Address"),
            'accessor': 'email',
            'minWidth': 190,
            'Cell': row=>(
                <div>
                    <div>{row.original.contact_info.email}</div>
                </div>
            )
        },
        {
            'Header':'Organization(s)',
            'accessor': 'org_name',
            'minWidth': 170,
            'sortable': false,
            'Cell': row=>{
                let combinedRoles = [];

                row && row.original && row.original.organization_roles && row.original.organization_roles.map((org) =>{
                    combinedRoles.push(org.roles);
                });

                return row && row.original && row.original.organization_roles && row.original.organization_roles.map((org, i) =>{
                    return (
                        <Fragment key={i}>
                            <div>{org.title ? org.title : org.organization_name}</div>
                            {org.roles.map((role, i) => {
                                return <br key={i}/>
                            })}
                        </Fragment>)
                }
            )}
        },
        {
            'Header':'Role(s)',
            'accessor': 'roles',
            'minWidth': 140,
            'sortable': false,
            'Cell': row=>{
                let combinedRoles = [];

                row && row.original && row.original.organization_roles && row.original.organization_roles.map((org) =>{
                    combinedRoles.push(org.roles);
                });

                return (combinedRoles.map((roles, i) => {

                    return (
                        <Fragment key={i}>
                            {roles.map((role, i) => {
                                return (
                                <div key={i}>
                                    <div>{roles[i]}</div>
                                </div>)
                            })}
                            <br/>
                        </Fragment>)
                    }))
                }
        },
        {
            Header:() => tableSort("Created"),
            'accessor': 'created',
            'minWidth': 90,
            'Cell': row=>{
                return (
                    <div>
                        <div>{row.original.created ? moment(row.original.created * 1000).format('MM/DD/YYYY') : 'No Date'}</div>
                    </div>
                )
            }
        },
        {
            'Header':'Actions',
            'width': 90,
            'sortable': false,
            'Cell': row=>{
                return (
                    <div>
                        <OverlayTrigger 
                            placement='bottom' 
                            overlay={this.getTooltip('edit',`Update User`)}
                        >
                            <Link 
                                to={`/account-management/users/${row.original.uid}/edit`} 
                                className='properties__options'>
                                    <i className='icon-edit' />
                            </Link>
                        </OverlayTrigger>
                    </div>
                )
            }
        }];

        return columns;
    }

    componentDidMount() {
        const params = JSON.parse(sessionStorage.getItem('acc-mgmt-users'));


        this.orgTitles = this.orgNametoTitle();

        if(params) {
            if(params.showAll || params.keyword.length) {
                const { 
                    showAll,
                    pageSize,
                    keyword,
                    sorted
                } = this.state;
    
                this.setState({
                    showAll: params.showAll || showAll,
                    pageSize: params.pageSize || pageSize,
                    keyword: params.keyword || keyword,
                    sorted: params.sorted || sorted,
                }, async () => {
                    await this.fetchUsers(params.activePage != 0 ? (params.activePage - 1) : activePage);
                });
            };
        };
    }

    componentWillUnmount() {
        this.setSessionStorage();
    }

    fetchUsers = async (active_page, sort, page_size) => {
        const { 
            pageSize,
            keyword,
            sorted,
        } = this.state;

        const newPageSize = page_size ? page_size : pageSize;
        let newActivePage = ((active_page === 0) ? 0 : active_page);
        const newSorted = sort ? sort : sorted;
        const offset = newPageSize * newActivePage;
        const sortDesc = newSorted.desc ? "-" : "+";

        this.setState({ isLoading: true });

        try {
            let userData = await API.get("accapi", `/users?tid=${this.tid}&limit=${pageSize}&offset=${offset}&sort=${sorted.id}${encodeURIComponent(sortDesc)}&keyword=${encodeURIComponent(keyword)}`, { response: true });
            this.setState({
                activePage: (newActivePage + 1),
                pageSize: pageSize,
                sorted: {
                    ...sorted,
                    id: newSorted.id,
                    desc: newSorted.desc,
                },
                usersList: [...userData.data],
                totalUsersCount: userData.headers["x-total-count"],
                columns: (userData && userData.data && userData.data.length) ? this.updateColumns() : [],
                isLoading: false,
            }, () => {
                this.setSessionStorage();
            });
        }
        catch (e) {
            console.log(e);
            this.setState({
                columns: [],
                isLoading: false
            });
        }
    }

    setSessionStorage = () => {
        const { 
            showAll,
            pageSize,
            activePage,
            keyword,
            sorted
        } = this.state;

        const pagination = {
            showAll: showAll,
            pageSize: pageSize,
            activePage: activePage,
            keyword: keyword,
            sorted: sorted
        };

        sessionStorage.setItem('acc-mgmt-users', JSON.stringify(pagination));
    }

    handleSubmitAddUser = async () => {
        let data = this.state.data;
        let selectedOrganization = this.state.selectedOrganization.organization_name;
        let validation = this.state.validation;

        this.validationHandler("email", "roles", "org", "internalAdmin");

        if(!validation.email && 
            !validation.validEmailFormat && 
            !validation.roles && 
            !validation.selectedOrganization && 
            !validation.validRedawningEmail) {

            this.closeModal();
            this.setState({ isLoading: true})
            
            try {
                await API.post(constants.ACCAPI.NAME,  constants.ACCAPI.GET_USER(selectedOrganization), { body: data, response: true })
                this.setState({ 
                    isLoading: false,
                    isSaved: true,
                    showModal: "Notification Modal",
                    msg: "Successfully Added User",
                })
            } catch(err) {
                let errMessage = err.response.data.Message;
    
                if(errMessage.includes("Duplicate")) {
                    this.setState({ 
                        isLoading: false,
                        showModal: "Notification Modal",
                        msg: "User Already Exists",
                    })
                }
            }
        }
    }

    handlePageSizeChange = (newPageSize) => {
        const { pageSize } = this.state;

        if(!isNaN(newPageSize) && newPageSize.trim() !== "" && newPageSize !== pageSize) {
            this.setState({
                pageSize: newPageSize
            }, () => {
                this.fetchUsers(0, '', newPageSize);
            });
        };
    }

    handlePageChange = (page) => {
        this.fetchUsers(page);   
    }

    onSortedChange = (newSorted) => {
        this.fetchUsers(0, newSorted[0]);   
    }

    handleSearchSubmit = (e) => {
        e.preventDefault();

        this.setState({
            showAll: false
        }, () => {
            const { 
                keyword
            } = this.state;

            if(keyword.length) {
                this.fetchUsers(0);
            }
            else {
                this.resetData();
            };
        });
    }

    handleSearchChange = (e) => {
        const name = e.target.name;
        const val = e.target.value;

        this.setState({
            [name] : val
        });
    }

    getTooltip = (id,text) => {
        return <Tooltip id={id} className='properties__tooltip'>{text}</Tooltip>
    }

    handleClearSearch = () => {
        this.resetData();
    }

    handleModalInputChange = (e) => {
        const name = e.target.name,
            value = e.target.value;

        this.setState({ 
            data: {
                ...this.state.data, 
                [name]: value.toLowerCase()
        }}, () => this.validationHandler("email", null, null, "internalAdmin"));
    }

    toggleCheck = (e) => {
        const { 
            data,
            validation
        } = this.state;
        const name = e.target.name;
    
        if (data.roles.indexOf(name) > -1) {
            data.roles.splice(data.roles.indexOf(name), 1);
        }
        else {
            data.roles.push(name);
        }
        this.setState({ 
            data: {
                ...data, 
                roles: data.roles},
            validation: {
                ...validation, 
                roles: false
            }
        }, () => this.validationHandler(null, "roles", null, "internalAdmin"));
    }

    openAddUserModal = () => {
        const { 
            data,
            validation
        } = this.state;

        this.setState({
            showModal: "AddUserModal",
            selectedOrganization: [],
            data: {
                ...data,
                email: "",
                roles: [],
            },
            validation: {
                ...validation, 
                email: false,
                roles: false,
                validEmailFormat: false,
                selectedOrganization: false,
                validRedawningEmail: false,
            },
        });
    }

    closeModal = () => {
        const { 
            data,
            validation
        } = this.state;

        this.setState({
            showModal: false,
            selectedOrganization: "",
            data: {
                ...data,
                email: "",
                roles: [],
            },
            validation: {
                ...validation, 
                email: false,
                roles: false,
                validEmailFormat: false,
                selectedOrganization: false
            },
        });
    }

    onBlurChange = () => {
        const { 
            data,
            validation
        } = this.state;

        if(!data.email.length) {
            validation.email = true;
        }
        else {
            validation.email = false;
        };

        if(data.email.length > 0 && !data.email.match(constants.EMAIL_VALIDATION)) {
            validation.validEmailFormat = true;
        }
        else {
            validation.validEmailFormat = false;
        };

        this.setState({ 
            validation: validation
        });
    }

    validationHandler = (email, roles, org, internalAdmin) => {
        const { 
            data,
            validation,
            selectedOrganization
        } = this.state;

        if(email) {
            if(!data.email.length) {
                validation.email = true;
            }
            else {
                validation.email = false;
            };
        };

        if(roles) {
            if(!data.roles.length) {
                validation.roles = true;
            }
            else {
                validation.roles = false;
            };
        };

        if(org) {
            if(selectedOrganization.length === 0) {
                validation.selectedOrganization = true;
            }
            else {
                validation.selectedOrganization = false;
            };
        };

        if(internalAdmin) {
            if(
                data.email.length > 0 && 
                data.email.split("@")[1] !== "redawning.com" && 
                data.roles.indexOf(constants.USER_TYPES.INTERNAL_ACCOUNT_ADMIN) > -1) 
            {
                validation.validRedawningEmail = true;
            }
            else {
                validation.validRedawningEmail = false;
            };
        };

        this.setState({ 
            validation: validation
        });
    }

    generateUserTypeArray = () => {
        let data = [];

        for (let key in constants.USER_TYPES) {
            data.push(
                <InputField 
                    key={key}
                    type="checkbox" 
                    value={this.state.data.roles.indexOf(constants.USER_TYPES[key]) > -1} 
                    name={constants.USER_TYPES[key]} 
                    cbChange={this.toggleCheck}>
                    {(constants.USER_TYPES[key]).replace(/([A-Z])/g, ' $1').trim()}
                </InputField>
            )
        };
        
        return (
            <Col md={10} className="checkbox-group padding-box panel-heading">
                {data}
            </Col>
        )
    }

    handleShowAll = () => {
        this.setState({ 
            showAll: !this.state.showAll
        }, () => {
            const { 
                showAll
            } = this.state;

            if(showAll == true) {
                this.setState({ 
                    keyword: ''
                }, () => {
                    this.fetchUsers(0);
                }); 
            }
            else {
                this.resetData();
            };
        });
    }

    resetData = () => {
        this.setState({
            columns: [],
            usersList: [],
            pageSize: 10,
            totalUsersCount: 0,
            activePage: 0,
            keyword: "",
            sorted: {
                id: "email",
                desc: false,
            },
        }, () => {
            this.setSessionStorage();
        });
    }

    orgNametoTitle = () => {
        let orgTitles = [];

        this.props.organizations.map((org) => {
            let newOrg = {};

            newOrg['title'] = org.title ? org.title : org.organization_name;
            newOrg['organization_name'] = org.organization_name;

            orgTitles.push(newOrg);
        });

        return orgTitles;
    }

    render() {
        const { 
            showAll,
            isLoading,
            keyword,
            pageSize,
            usersList,
            columns,
            totalUsersCount,
            showPagination,
            activePage,
            sorted,
            showModal,
            isSaved,
            validation,
            msg,
            data,
            selectedOrganization
        } = this.state;

        return (
            <PageLayout isCentered={true}>
                <Title 
                    title='Users' 
                    isCentered={true}
                    removeOrganizationDropdown={true}
                    addDefaultTitle="Account Management"
                    breadcrumbItems={this.breadcrumbItems}
                >
                    <div className="filters-wrap">
                        <InputField 
                            type="checkbox" 
                            value={showAll} 
                            cbChange={this.handleShowAll}
                            disabled={isLoading}
                        >
                            Show All Users
                        </InputField>
                    </div>
                    <form 
                        className='search-inp' 
                        onSubmit={this.handleSearchSubmit}
                    >
                        <InputField
                            autoFocus
                            type='searchbox'
                            placeholder='Search' 
                            name='keyword'
                            value={keyword} 
                            onChange={this.handleSearchChange}
                            clearSearch={this.handleClearSearch} 
                        />
                    </form>
                    <PrimaryButton 
                        cssClass="primary-btn pull-right" 
                        onClick={this.openAddUserModal} 
                        fullWidth={false}
                    >
                        <i className='icon-Plus create-icon'/>
                        Create
                    </PrimaryButton>
                </Title>
                <div id='content' className='properties'>
                    <Grid fluid={true}>
                        <Row>
                            <Col xs={12}>
                                <TableComponent 
                                    className='-highlight'
                                    resizable={false} 
                                    minRows={0}
                                    pageSize={parseInt(pageSize)}
                                    loading={isLoading}
                                    data={usersList}
                                    columns={columns}
                                    noDataText={isLoading ? "Loading..." : "No users found."}
                                    totalItemsCount={Number(totalUsersCount)}
                                    showPagination={showPagination}
                                    activePage={
                                        activePage !== 0 ? 
                                        activePage : 1}
                                    onPageSizeChange={this.handlePageSizeChange}
                                    handlePageChange={this.handlePageChange}
                                    manual
                                    defaultSortDesc={false}
                                    defaultSorted={[sorted]}
                                    onSortedChange={this.onSortedChange}
                                    itemType="Users"
                                    getTrProps = {(state, rowInfo, column) => {
                                        let data = rowInfo.original,
                                            editLink = {
                                                pathname: `/account-management/users/${data.uid}/edit`,
                                                state: {
                                                    data:JSON.stringify(data)
                                                }
                                            }
                                        return {
                                            onClick: () => {
                                                this.props.history.push(editLink)
                                            },
                                            style: {
                                                cursor: 'pointer'
                                            }
                                        }
                                    }}
                                    Pag
                                    >
                                </TableComponent>
                            </Col>
                        </Row>
                    </Grid>
                </div>
                <Modal 
                    className="submit-modal" 
                    show={showModal === 'Notification Modal'} 
                    onHide={this.closeModal}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {
                                isSaved === true ? (
                                <div>
                                    <span className='status-icon status-icon--success'></span>
                                    <span className="submit-heading">{msg}</span>
                                </div>) :
                                (<div>
                                    <span className='status-icon status-icon--error'></span>
                                    <span className="submit-heading">{msg}</span>
                                </div>)
                            }
                        </Modal.Title>
                    </Modal.Header>
                </Modal>
                <Modal
                    className="submit-modal" 
                    show={showModal === "AddUserModal"} 
                    onHide={this.closeModal}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Create User
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='edit-user-modal'>
                        <Row>
                            <Col sm={12} xs={12} md={12} className="model-margin">
                                <InputLabel 
                                    htmlFor="email"
                                    className={validation.email && 'form-validation'}
                                >
                                    Email
                                </InputLabel>
                                <InputField
                                    type="text"
                                    className={validation.email && 'form-validation'}
                                    name="email" 
                                    onChange={this.handleModalInputChange}
                                    onBlur={this.onBlurChange}
                                    value={data.email}
                                    autoFocus
                                />
                                {
                                    validation.email && 
                                    <span className="required-error-text">
                                        Email is required
                                    </span>
                                }
                                {
                                    validation.validEmailFormat &&
                                    <span className="required-error-text">
                                        {constants.EMAIL_INVALID_MESSAGE}
                                    </span>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} xs={12} md={12} className="model-margin">
                                <InputLabel 
                                    htmlFor="organizations"
                                    className={validation.selectedOrganization && 'form-validation'}
                                >
                                    Organization
                                </InputLabel>
                                <Picky
                                    className={validation.selectedOrganization && 'form-validation'}
                                    placeholder=""
                                    labelKey="title"
                                    valueKey="organization_name"
                                    filterPlaceholder="Search by Organization Name"
                                    options={this.orgTitles}
                                    value={
                                        selectedOrganization.title ? 
                                        selectedOrganization.title : 
                                        selectedOrganization.organization_name
                                    }
                                    multiple={false}
                                    includeSelectAll={false}
                                    defaultFocusFilter={true}
                                    includeFilter={true}
                                    keepOpen={false}
                                    onChange={value => {
                                        this.setState({ selectedOrganization: value }, 
                                        () => this.validationHandler(null, null, "org"))}}
                                    dropdownHeight={600}
                                    render={({
                                        style,
                                        item,
                                        isSelected,
                                        selectValue,
                                        labelKey,
                                        valueKey,
                                        }) => {
                                            return (
                                                <li
                                                style={{ ...style }}
                                                className={isSelected ? "selected" : ""}
                                                key={item[valueKey]}
                                                onClick={() => selectValue(item)}
                                                >
                                                    <span
                                                        style={{
                                                        fontWeight: isSelected ? "bold" : "normal"
                                                        }}
                                                    >
                                                        {item[labelKey] !== null && item[labelKey] !== "" ? item[labelKey] : item[valueKey]}
                                                    </span>
                                                </li>
                                            );
                                    }}
                                />
                                {
                                    validation.selectedOrganization && 
                                    <span className="required-error-text">
                                        Organization is required
                                    </span>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className="checkbox-group padding-box panel-heading">
                                <InputLabel
                                    className={validation.roles && 'form-validation'}
                                    cssClass="col-md-12 float-left padding-box" 
                                    htmlFor="roles"
                                >
                                    Roles
                                </InputLabel>
                                <Col sm={12} xs={12} md={12}>
                                    {this.generateUserTypeArray()}
                                </Col>
                                {
                                    validation.roles && 
                                    <Col sm={12} xs={12} md={12}>
                                        <span className="col-md-12 error-text">
                                            At least one role is required
                                        </span>
                                    </Col>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} xs={12} md={12}>
                                <PrimaryButton
                                    onClick={this.handleSubmitAddUser} 
                                    cssClass="primary-btn pull-left margin-btn top-margin" 
                                    fullWidth={false} 
                                    type="button">
                                    Save
                                </PrimaryButton>
                            </Col>
                            <Col sm={12} xs={12} md={12}>
                                {
                                    validation.validRedawningEmail && 
                                    <span className="col-md-12 error-text">
                                        Internal Account Admin can only be assigned to users with a RedAwning email address.
                                    </span>
                                }
                            </Col>
                        </Row>
                        </div>
                    </Modal.Body>
                </Modal>
            </PageLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.authState.user,
        organizations: state.authState.user.expanded_organization_roles,
        roleManager: state.roleManager
    }
}

export default withRouter(connect(
    mapStateToProps,
    null
)(Users));