import React, { Component } from 'react';
import { connect } from 'react-redux';

import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import Loader from '../global/Loader';
import { InputField } from '../global/forms/FormElements';
import { infoPopover } from '../../helpers/helpers';
import Constant from '../../js/constants';

class ReportsRevenue extends Component {
    last30DaysInfo = 'Reservations created within the last 30 days.';

    render() {
        const {
            org,
            allOrganizations,
            revenueIsLoading, 
            totalRevenue,
            showAllOrgRevenue,
            getRevenue,
            handleShowAllOrg
        } = this.props;
        const currency = Constant.CURRENCY[totalRevenue.currency] || '$';

        return(
            <div className="revenue">

                {revenueIsLoading && <Loader />}

                <Row>
                    <Col md={12} sm={12}>
                        <Col md={6} sm={6} xs={6} className="left">
                            <h2>
                                Revenue
                            </h2>
                        </Col>
                        <Col md={6} sm={6} xs={6} className="right">
                            {
                                allOrganizations.length > 1 && 
                                org !== '_global_' && 
                                org !== 'travelagencies' ? 
                                <div className="filters-wrap">
                                    <InputField 
                                        type="checkbox" 
                                        value={showAllOrgRevenue} 
                                        cbChange={() => handleShowAllOrg("showAllOrgRevenue", getRevenue)}>
                                        All Organizations
                                    </InputField>
                                </div> : 
                                ''
                            }
                        </Col>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div className="revenue-container">
                            <h4>Last 30 Days: {infoPopover(this.last30DaysInfo, null, 'top', 'icon-question-mark')}</h4>
                            <p className="reports__price"><span className='currency'>{currency}</span>{totalRevenue.days || 0}</p>
                        </div>
                    </Col>
                    <Col md={12}>
                        <div className="revenue-container">
                            <h4>Year to Date:</h4>
                            <p className="reports__price"><span className='currency'>{currency}</span>{totalRevenue.year || 0}</p>
                        </div>
                    </Col>  
                    <Col md={12}>
                        <div className="revenue-container">
                            <h4>All Time:</h4>
                            <p className="reports__price"><span className='currency'>{currency}</span>{totalRevenue.total || 0}</p>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        org: state.roleManager.org
    }
}

export default connect(
    mapStateToProps,
    null
)(ReportsRevenue);