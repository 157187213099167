import React, { Component } from 'react';
import { connect } from 'react-redux';
import { API } from 'aws-amplify';
import queryString from 'query-string';
import Link from 'react-router-dom/Link';
import DateRangePicker from 'react-dates/lib/components/DateRangePicker';
import moment from 'moment';
import { CSVLink } from "react-csv";

import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import Modal from 'react-bootstrap/lib/Modal';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';

import { InputField, PrimaryButton } from '../global/forms/FormElements';
import { Title } from '../global/Title';
import constants from '../../js/constants';
import PageLayout from '../global/PageLayout';
import Loader from '../global/Loader';
import ReactTableComponent from '../global/ReactTableComponent';
import { infoPopover } from '../../helpers/helpers';
import { 
    tableSort,
    getSessionId,
    getOrgData
} from '../../helpers/helpers';

class ReportsTaxes extends Component {
    constructor(props) {
        super(props);
        this.reactTable = null;
        this.CSVLink = null;
        this.org = this.props.org;
        this.tid = getSessionId();
        this.compareTaxDates = {};
        this.allOrganizations = [];
        this.taxPaidInfoMessage = "This is the portion of the Total Tax paid directly to your tax authority by a partner";
        this.totalTaxInfoMessage = "This is the total tax collected for each stay including sales, tourism or occupancy tax";
        this.breadcrumbItems = [
            { title: "Reports" },
            { title: "Taxes" },
        ];
        this.CSVHeaders = [
            { label: 'Reservation ID', key: 'rental_id'},
            { label: 'Property ID', key: 'nid'},
            { label: 'Guest Name', key: 'guest_name'},
            { label: 'Arrival Date', key: 'arrival_date'},
            { label: 'Departure Date', key: 'departure_date'},
            { label: 'Nights', key: 'nights'},
            { label: 'Currency', key: 'currency'},
            { label: 'Rental Amount', key: 'rental_amount'},
            { label: 'Cleaning Fees', key: 'cleaning_fees'},
            { label: 'Other Fees', key: 'other_fees'},
            { label: 'Total Tax', key: 'tax_amount'},
            { label: 'Tax Paid', key: 'tax_paid'},
            { label: 'Credit Amount', key: 'credit_amount'},
            { label: 'Processing Fees', key: 'cc_fees'},
            { label: 'RedAwning Commission', key: 'redawning_commission'},
            { label: 'Total', key: 'total_amount'},
        ];
        this.CSVReportsCopy = [];
        this.totalSummaryCSVincluded = false;
        this.state = {
            error: false,
            isLoading: false,
            dropdownIsOpen: false,
            modal: "",
            activePage: 1,
            pageSize: 10,
            totalCount: 0,
            keyword: "",
            taxReports: [],
            taxDates: {
                startDate: moment().startOf('year'),
                endDate: moment(),
            },
            initStateTaxReports: [],
            filteredTaxReports: [],
            CSVReports: [],
            showAllOrg: true,
            totalSummary: {
                rentalAmount: "0",
                cleaningFees: "0",
                otherFees: "0",
                totalTax: "0",
                totalTaxPaid: "0",
                totalNights: "0",
            },
            sorted: {
                id: "departure_date",
                desc: false
            },
            columns: [],
        }
    }
    
    updateColumns = () => {
        const { 
            showAllOrg,
            taxReports
        } = this.state;
        let columns = [];

        columns = [
            ...columns,
            {
                Header:() => tableSort("Reservation ID"),
                accessor: 'rental_id',
                'Cell': row=>{
                    const rentalID = row && row.original && row.original.rental_id;

                    return(
                        <div>
                            <Link 
                                className="clickable-text" 
                                to={`/reservations/${rentalID}/details`}
                            >
                                {rentalID}
                            </Link>
                        </div>
                    )
                }
            },
            {
                Header:() => tableSort("Property ID"),
                accessor: 'nid',
                'Cell': row=>{
                    const propertyId = row && row.original && row.original.nid;

                    return(
                        <div>
                            <Link 
                                className="clickable-text" 
                                to={`/properties/${propertyId}/edit`}
                            >
                                {propertyId}
                            </Link>
                        </div>
                    )
                }
            },
            {
                Header:() => tableSort("Guest Name"),
                accessor: 'guest_name',
                'Cell': row=>{
                    const guest_name = row && row.original && row.original.guest_name;

                    return(
                        <div>
                            <OverlayTrigger 
                                placement='top' 
                                overlay={
                                    <Tooltip
                                        id="guest_name"
                                    >
                                        {guest_name}
                                    </Tooltip>
                                }
                            >
                                <div>
                                    {_.truncate(guest_name, {length: 20, omission: '...'})}
                                </div>
                            </OverlayTrigger>
                        </div>
                    )
                }
            },
            {
                Header:() => tableSort("Departure Date"),
                accessor: 'departure_date',
                'minWidth': 115,
            },
            {
                Header: <div className="center-text">Nights</div>,
                'minWidth': 90,
                'sortable': false,
                'Cell': row=>{
                    const arrival_date = row && row.original && row.original.arrival_date;
                    const departure_date = row && row.original && row.original.departure_date;

                    return (
                        <div className="center-text">
                            {moment(departure_date).diff(moment(arrival_date), 'days')}
                        </div>
                    )
                }
            },
            {
                Header: 'Rental Amount',
                accessor: 'rental_amount',
                'sortable': false,
                'Cell': row=>{
                    const rentalAmount = row && row.original && row.original.rental_amount;
                    const currency = row && row.original && row.original.currency;

                    return this.cell(rentalAmount, currency);
                }
            },
            {
                Header: 'Cleaning Fees',
                accessor: 'cleaning_fees',
                'sortable': false,
                'Cell': row=>{
                    const cleaningFees = row && row.original && row.original.cleaning_fees;
                    const currency = row && row.original && row.original.currency;

                    return this.cell(cleaningFees, currency);
                }
            },
            {
                Header: 'Other Fees',
                accessor: 'other_fees',
                'sortable': false,
                'Cell': row=>{
                    const otherFees = row && row.original && row.original.other_fees;
                    const currency = row && row.original && row.original.currency;

                    return this.cell(otherFees, currency);
                }
            },
            {
                Header: row=>{
                    return <div>Total Tax {infoPopover(this.totalTaxInfoMessage, null, "top", "icon-info")}</div>;
                },
                accessor: 'tax_amount',
                'sortable': false,
                'Cell': row=>{
                    const taxAmount = row && row.original && row.original.tax_amount;
                    const currency = row && row.original && row.original.currency;

                    return this.cell(taxAmount, currency);
                }
            },
            {
                Header: row=>{
                    return <div>Tax Paid {infoPopover(this.taxPaidInfoMessage, null, "top", "icon-info")}</div>;
                },
                accessor: 'tax_paid',
                'sortable': false,
                'Cell': row=>{
                    const taxPaid = row && row.original && row.original.tax_paid;
                    const currency = row && row.original && row.original.currency;

                    return (<div>{this.cell(taxPaid, currency)}</div>)
                }
            },
            {
                Header: 'Credit Amount',
                accessor: 'credit_amount',
                'sortable': false,
                'Cell': row=>{
                    const creditAmount = row && row.original && row.original.credit_amount;
                    const currency = row && row.original && row.original.currency;

                    return this.cell(creditAmount, currency);
                }
            },
            {
                Header: 'Processing Fees',
                accessor: 'cc_fees',
                'sortable': false,
                'Cell': row=>{
                    const ccFees = row && row.original && row.original.cc_fees;
                    const currency = row && row.original && row.original.currency;

                    return this.cell(ccFees, currency);
                }
            },
            {
                Header: 'RA Commission',
                accessor: 'redawning_commission',
                'sortable': false,
                'Cell': row=>{
                    const RACommision = row && row.original && row.original.redawning_commission;
                    const currency = row && row.original && row.original.currency;

                    return this.cell(RACommision, currency);
                }
            },
            {
                Header: 'Total',
                accessor: 'total_amount',
                'sortable': false,
                'Cell': row=>{
                    const totalAmount = row && row.original && row.original.total_amount;
                    const currency = row && row.original && row.original.currency;

                    return this.cell(totalAmount, currency);
                }
            }
        ];

        if(showAllOrg && (taxReports && taxReports[0] && taxReports[0].organization_name)) {
            let orgName = 
                {
                    Header:() => tableSort("Organization"),
                    'accessor': 'organization_name',
                    'sortable': false,
                    'Cell': row=>(
                        <div className='organization_name'>
                            <OverlayTrigger 
                                placement='bottom' 
                                overlay={
                                    <Tooltip
                                        id="organization_name"
                                    >
                                        {row.value}
                                    </Tooltip>
                                }
                            >
                                <strong>
                                    {row.value}
                                </strong>
                            </OverlayTrigger>
                        </div>
                    )
                };

            columns.splice(2, 0, orgName);
        }

        return columns;
    }

    async componentDidMount() {
        const { user } = this.props;
        let queryStringActivePage = 
            this.props.location.search ? 
            parseInt(queryString.parse(this.props.location.search).page) : 
            1;

        this.setState({ 
            isLoading: true
        });

        let orgData = getOrgData(user, this.org);

        this.allOrganizations = orgData.allOrganizations;

        this.compareTaxDates = this.state.taxDates;

        await this.getTaxReports(queryStringActivePage);

        this.setState({
            isLoading: false
        });
    }

    async componentWillReceiveProps(newProps) {
        let queryStringActivePage = 
            this.props.location.search ? 
            parseInt(queryString.parse(this.props.location.search).page) : 
            1;
        let newQueryStringActivePage = 
            newProps.location.search ? 
            parseInt(queryString.parse(newProps.location.search).page) : 
            1;

        if(queryStringActivePage !== newQueryStringActivePage) {
            newQueryStringActivePage = 
                !((newQueryStringActivePage - 1) < 0) ? 
                newQueryStringActivePage - 1 : 
                0;

            await this.fetchTaxReports(parseInt(newQueryStringActivePage), this.state.sorted, false);
        }
    }
    
    getTaxReports = async (activePage) => {
        const { 
            taxDates,
            showAllOrg,
            pageSize,
            sorted
        } = this.state;
        let offset = (activePage - 1) * pageSize;
        let sortDesc = sorted.desc ? "-" : "+";
        let sortValue = {
            "nid": "property_id",
            "rental_id": "reservation_id",
            "departure_date" : "departure_date"
        };
        const startDate = moment(taxDates.startDate).format("YYYY-MM-DD");
        const endDate = moment(taxDates.endDate).format("YYYY-MM-DD");

        try {
            let response = await API.get('rptapi',`/reports/acctg_tax_reporting?organization=${showAllOrg ? encodeURIComponent(this.allOrganizations.join()) : this.org}&limit=${encodeURIComponent(pageSize)}&offset=${encodeURIComponent(offset)}&tid=${encodeURIComponent(this.tid)}&sort=${encodeURIComponent(sortValue[sorted.id])}${encodeURIComponent(sortDesc)}&departure_start_date=${startDate}&departure_end_date=${endDate}`, { response: true, isCognito: true });

            if(response && response.data && response.data.items && response.data.items.length) {
                this.setState({
                    activePage: activePage,
                    taxReports: response.data.items,
                    initStateTaxReports: response.data.items,
                    totalCount: response.data.pagination.total_results
                }, () => {
                    this.setState({
                        columns: this.updateColumns(),
                    })
                });
            }
            else {
                if(this.props.location.search) {
                    this.props.history.push("/reports/taxes");
                } 
                else {
                    this.fetchTaxReports(0, sorted);
                };
            };
        } 
        catch(e) {
            console.log(e)
            this.setState({
                error: true
            });
        };
    }

    fetchTaxReports = async (activePage, newSorted, compare) => {
        this.setState({
            isLoading: true
        });

        const { 
            pageSize, 
            sorted, 
            keyword,
            taxDates,
            initStateTaxReports,
            showAllOrg,
        } = this.state;
        let sortValue = {
            "nid": "property_id",
            "rental_id": "reservation_id",
            "departure_date" : "departure_date"
        };
        const startDate = encodeURIComponent(moment(taxDates && taxDates.startDate).format("YYYY-MM-DD"));
        const endDate = encodeURIComponent(moment(taxDates && taxDates.endDate).format("YYYY-MM-DD"));
        let offset = pageSize * activePage;
        let sortDesc = newSorted.desc ? "-" : "+";

        try {
            let response = await API.get('rptapi',`/reports/acctg_tax_reporting?organization=${showAllOrg ? encodeURIComponent(this.allOrganizations.join()) : this.org}&limit=${encodeURIComponent(pageSize)}&limit=${encodeURIComponent(pageSize)}&offset=${encodeURIComponent(offset)}&tid=${encodeURIComponent(this.tid)}&sort=${encodeURIComponent(sortValue[newSorted.id])}${encodeURIComponent(sortDesc)}&departure_start_date=${startDate}&departure_end_date=${endDate}&keyword=${encodeURIComponent(keyword)}`, { response: true, isCognito: true });

            if(response && response.data && response.data.items) {
                this.setState({
                    activePage: activePage + 1,
                    taxReports: response.data.items,
                    initStateTaxReports: compare ? response.data.items : initStateTaxReports,
                    pageSize: pageSize,
                    totalCount: response.data.pagination.total_results,
                    sorted: {
                        ...sorted,
                        id: newSorted ? newSorted.id : sorted.id,
                        desc: newSorted ? newSorted.desc : sorted.desc,
                    },
                    isLoading: false
                }, () => {
                    this.setState({
                        columns: this.updateColumns(),
                    })
                });
            };
        } 
        catch(e) {
            this.setState({
                error: true,
                isLoading: false
            });
        };
    }

    getTaxReportsCSV = async (bool) => {
        const { 
            taxDates,
            keyword,
            showAllOrg
         } = this.state;
        const tid = getSessionId();
        const startDate = moment(taxDates && taxDates.startDate).format("YYYY-MM-DD");
        const endDate = moment(taxDates && taxDates.endDate).format("YYYY-MM-DD");
        let response = [];
        let reports = [];
        let offset = 0;
        let limit = 100;
        let count = 0;
        let totalCount = 0;

        this.setState({
            isLoading: true,
            dropdownIsOpen: false
        });

        try {
            response = await API.get("rptapi", `/reports/acctg_tax_reporting?organization=${showAllOrg ? encodeURIComponent(this.allOrganizations.join()) : this.org}&limit=${limit}&offset=${offset}&tid=${tid}&departure_start_date=${startDate}&departure_end_date=${endDate}&keyword=${encodeURIComponent(keyword)}`, { response: true, isCognito: true });
            if(response && response.data && response.data.items) {
                
                response.data.items.forEach((x) => {
                    reports = [...reports, x];
                });

                count = count + response.data.items.length;

                totalCount = response.data.pagination.total_results;


                while(count < totalCount) {
                    try {
                        offset = offset + 100;

                        response = await API.get("rptapi", `/reports/acctg_tax_reporting?organization=${showAllOrg ? encodeURIComponent(this.allOrganizations.join()) : this.org}&limit=${limit}&offset=${offset}&tid=${tid}&departure_start_date=${startDate}&departure_end_date=${endDate}&keyword=${encodeURIComponent(keyword)}`, { response: true, isCognito: true });

                        response.data.items.forEach((x) => {
                            reports = [...reports, x];
                        });
        
                        count = count + response.data.items.length;
                    }
                    catch(e) {
                        console.log(e)
                    };
                };
                if(count == totalCount) {
                    this.CSVReportsCopy = reports;
                    
                    this.compareTaxDates = this.state.taxDates;
                    
                    this.setState({
                        CSVReports: reports,
                        isLoading: false,
                    }, () => {
                        let CSVReportsCopy = [...this.state.CSVReports]
                        let rentalAmount = 0;
                        let cleaningFees = 0;
                        let otherFees = 0;
                        let totalTax = 0;
                        let totalTaxPaid = 0;
                        let creditAmount = 0;
                        let creditFees = 0;
                        let RACommision = 0;
                        let totalAmount = 0;
                        let totalNights = 0;
                        let totalSummaryCSV;

                        CSVReportsCopy.forEach((report) => {
                            rentalAmount = rentalAmount + (!isNaN(parseInt(report.rental_amount)) ? report.rental_amount : 0);
                            cleaningFees = cleaningFees + (!isNaN(parseInt(report.cleaning_fees)) ? report.cleaning_fees : 0);
                            otherFees = otherFees + (!isNaN(parseInt(report.other_fees)) ? report.other_fees : 0);
                            totalTax = totalTax + (!isNaN(parseInt(report.tax_amount)) ? report.tax_amount : 0);
                            totalTaxPaid = totalTaxPaid + (!isNaN(parseInt(report.tax_paid)) ? report.tax_paid : 0);
                            creditAmount = creditAmount + (!isNaN(parseInt(report.credit_amount)) ? report.credit_amount : 0);
                            creditFees = creditFees + (!isNaN(parseInt(report.cc_fees)) ? report.cc_fees : 0);
                            RACommision = RACommision + (!isNaN(parseInt(report.redawning_commission)) ? report.redawning_commission : 0);
                            totalNights = totalNights + moment(report.departure_date).diff(moment(report.arrival_date), 'days');
                            totalAmount = totalAmount + (!isNaN(parseInt(report.total_amount)) ? report.total_amount : 0);
                            // Add nights to CSV reports
                            report.nights = moment(report.departure_date).diff(moment(report.arrival_date), 'days');
                        });

                        totalSummaryCSV = {
                            rental_amount: constants.NUM_FORMAT(rentalAmount),
                            cleaning_fees: constants.NUM_FORMAT(cleaningFees),
                            other_fees: constants.NUM_FORMAT(otherFees),
                            tax_amount: constants.NUM_FORMAT(totalTax),
                            tax_paid: constants.NUM_FORMAT(totalTaxPaid),
                            credit_amount: constants.NUM_FORMAT(creditAmount),
                            cc_fees: constants.NUM_FORMAT(creditFees),
                            redawning_commission: constants.NUM_FORMAT(RACommision),
                            nights: totalNights,
                            total_amount: constants.NUM_FORMAT(totalAmount),
                        }

                        if(bool) {
                            this.totalSummaryCSVincluded = true;
                            CSVReportsCopy.push(totalSummaryCSV);
                        };

                        this.setState({
                            CSVReports: CSVReportsCopy,
                            totalSummaryCSV: totalSummaryCSV,
                            totalSummary: {
                                ...this.state.totalSummary,
                                rentalAmount: rentalAmount,
                                cleaningFees: cleaningFees,
                                otherFees: otherFees,
                                totalTax: totalTax,
                                totalTaxPaid: totalTaxPaid,
                                creditAmount: creditAmount,
                                creditFees: creditFees,
                                RACommision: RACommision,
                                totalNights: totalNights,
                                totalAmount: totalAmount,
                            },
                        });
                    });
                }
            };
        }
        catch(e) {
            console.log(e)
            this.setState({
                isLoading: false,
            });
        }
    }

    pageOnChange = (page) => {
        this.props.history.push(`/reports/taxes?page=${page}`);
    }

    pageSizeOnChange = (newPageSize) => {
        const { sorted, pageSize } = this.state;

        if(!isNaN(newPageSize) && newPageSize.trim() !== "" && newPageSize !== pageSize) {
            this.setState({
                pageSize: parseInt(newPageSize),
            }, () => {
                this.fetchTaxReports(0, sorted, false); 
            });
        };
    }

    onSortedChange = (newSorted) => {
        const { activePage } = this.state;

        this.fetchTaxReports(activePage - 1, newSorted[0], false);
    }

    handleSearch = (e) => {
        e.preventDefault();
        const { sorted } = this.state;

        this.fetchTaxReports(0, sorted, false);
    }

    handleSearchChange = (e) => {
        const name = e.target.name;
        const val = e.target.value;

        this.setState({
            [name] : val
        });
    }

    handleDatesChange = (newDates) => {
        this.setState({
            taxDates: {
                ...newDates
            }
        }, () => {
            const { taxDates, sorted } = this.state;

            if(moment(taxDates && taxDates.startDate).isValid() && moment(taxDates && taxDates.endDate).isValid()) {

                this.fetchTaxReports(0, sorted, false, true);
            }
        });
    }

    clearSearch = () => {
        const { taxReports, initStateTaxReports, sorted } = this.state;
        const compareTaxData = taxReports === initStateTaxReports;

        this.setState({
            keyword: ''
        }, !compareTaxData ? () => this.fetchTaxReports(0, sorted, true) : null);
    }

    getRef = (r) => {
        this.reactTable = r;
    }

    cell = (amount, currency) => {
        return(
            <span 
                className={Math.sign(amount) == -1 ? "negative-amount" : ""}
            >
                {constants.CURRENCY[currency.toUpperCase()]} {constants.NUM_FORMAT(Math.abs(amount))}
            </span>
        )
    }

    closeModal = () => this.setState({ modal: "" })

    isOutsideRange = () => {
        return false;
    }

    showTotalSummary = async () => {
        const { 
            CSVReports
        } = this.state;

        if(CSVReports !== this.CSVReportsCopy || this.compareTaxDates !== this.state.taxDates) {
            await this.getTaxReportsCSV();
            this.setState({
                modal: "total-tax-modal"
            });
        }
        else {
            this.setState({
                modal: "total-tax-modal"
            });
        };
    }

    downloadCSV = async (e, bool) => {
        e.preventDefault();
        const { 
            CSVReports,
            totalSummaryCSV,
            taxDates
        } = this.state;
        let CSVReportsCopy = [...this.state.CSVReports];
        const CSVcomponent = document.getElementById("download-csv");

        if(CSVReports !== this.CSVReportsCopy || this.compareTaxDates !== taxDates) {
            await this.getTaxReportsCSV(bool);

            CSVcomponent.click();
        }
        else {
            if(this.totalSummaryCSVincluded && bool === false) {
                this.totalSummaryCSVincluded = false;
                
                CSVReportsCopy.pop();

                this.CSVReportsCopy = CSVReportsCopy;
            }
            else if(this.totalSummaryCSVincluded === false && bool === true) {
                this.totalSummaryCSVincluded = true;

                CSVReportsCopy.push(totalSummaryCSV);

                this.CSVReportsCopy = CSVReportsCopy;
            };

            this.setState({
                CSVReports: CSVReportsCopy
            }, () => {
                setTimeout(() => {
                    CSVcomponent.click();
                },0);
            });
        };

        this.closeModal();
    }

    closeModal = () => {
        this.setState({
            modal: "",
        });
    }

    openModal = (e, name) => {
        e.preventDefault();

        this.setState({
            modal: name
        });
    }

    toggleDropdown = () => this.setState({ dropdownIsOpen: !this.state.dropdownIsOpen })

    handleShowAllOrg = (name) => {
        this.setState({ 
            [name]: !this.state[name]
        }, () => {
            this.fetchTaxReports(0, this.state.sorted, false); 
        });
    }
    
    render() {
        const { 
            isLoading,
            taxReports, 
            activePage, 
            pageSize,
            sorted, 
            columns,
            totalCount,
            keyword,
            taxDates,
            filteredTaxReports,
            dropdownIsOpen,
            CSVReports,
            focusedInput,
            modal,
            totalSummary,
            showAllOrg,
        } = this.state;

        return(
            <PageLayout isCentered={true}>
                
                {isLoading && <Loader />}

                <Title 
                    title="Taxes" 
                    isCentered={true} 
                    breadcrumbItems={this.breadcrumbItems}
                >
                    {
                        this.allOrganizations.length > 1 &&
                        this.org !== '_global_' && 
                        this.org !== 'travelagencies' &&
                        <div className="filters-wrap">
                            <InputField 
                                type="checkbox" 
                                value={showAllOrg} 
                                cbChange={() => this.handleShowAllOrg('showAllOrg')}
                            >
                                All Organizations
                            </InputField>
                        </div>
                    }
                    <DropdownButton  
                        title="Actions"
                        className="primary-btn white-btn"
                        id="property-actions"
                        onToggle={this.toggleDropdown}
                        open={dropdownIsOpen}
                    >
                        <MenuItem 
                            onClick={taxReports.length ? e => this.openModal(e, "include-total-summary-modal") : null}
                            disabled={!taxReports.length}
                        >
                            Download CSV
                        </MenuItem>
                        <CSVLink
                            asyncOnClick={true}
                            headers={this.CSVHeaders}
                            data={CSVReports}
                            filename={`${this.org}-tax-reports.csv`}
                            target="_blank"
                            id="download-csv"
                        >
                        </CSVLink>
                        <MenuItem 
                            onClick={taxReports.length ? this.showTotalSummary : null}
                            disabled={!taxReports.length}
                        >
                            Total Summary
                        </MenuItem>
                    </DropdownButton>
                    <div className="tax-date-picker">
                        <DateRangePicker
                            noBorder
                            showClearDates={true}
                            minimumNights={0}
                            startDateId='start'
                            endDateId='end'
                            isOutsideRange={this.isOutsideRange}
                            startDatePlaceholderText="Start-Date"
                            endDatePlaceholderText="End-Date"
                            startDate={taxDates && taxDates.startDate}
                            endDate={taxDates && taxDates.endDate}
                            focusedInput={focusedInput}
                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                            onDatesChange={this.handleDatesChange}
                        />
                    </div>
                    <form 
                        className='search-inp'
                        onSubmit={e => this.handleSearch(e)}
                    >
                        <InputField 
                            type='searchbox' 
                            placeholder='Search' 
                            name='keyword' 
                            value={keyword}
                            onChange={this.handleSearchChange}
                            clearSearch={this.clearSearch}
                        />
                    </form>
                </Title>
                {
                    <div id="content">
                        <div className="container-fluid reports-taxes">
                            <div className="reports_performance">
                                <ReactTableComponent
                                    apiBase={true}
                                    getRef={this.getRef}
                                    reactTable={this.reactTable}
                                    defaultFiltered={filteredTaxReports}
                                    className='-highlight'
                                    noDataText={isLoading ? "Loading..." : "No Data"}
                                    minRows={0}
                                    showPagination={true}
                                    data={taxReports}
                                    resizable={false}
                                    pageSize={pageSize}
                                    activePage={activePage}
                                    columns={columns}
                                    defaultSorted={[sorted]}
                                    totalCount={Number(totalCount)}
                                    pageSizeOnChange={(pageSize) => this.pageSizeOnChange(pageSize)}
                                    pageOnChange={(page) => this.pageOnChange(page)}
                                    onSortedChange={this.onSortedChange}
                                />
                            </div>
                        </div>
                    </div>
                }
                <Modal
                    backdrop={false}
                    className="tax-total-modal"
                    show={modal === "total-tax-modal"} 
                    onHide={this.closeModal}
                >
                    <Modal.Header closeButton>
                        <h1>Total Summary</h1>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="tax-total-container">
                            <div>
                                <h4>
                                    Rental Amount:
                                </h4>
                                <h4>
                                    Cleaning Fees:
                                </h4>
                                <h4>
                                    Other Fees:
                                </h4>
                                <h4>
                                    Total Tax:
                                </h4>
                                <h4>
                                    Tax Paid:
                                </h4>
                                <h4>
                                    Number of Nights:
                                </h4>
                                <div className="total-summary-modal-info total-tax">
                                        {infoPopover(this.totalTaxInfoMessage, null, "top", "icon-info")}
                                    </div>
                                <div className="total-summary-modal-info tax-paid">
                                        {infoPopover(this.taxPaidInfoMessage, null, "top", "icon-info")}
                                </div>
                            </div>
                            <div className="tax-total-values">
                                <div className="tax-total-currency">
                                    <h4>
                                        <strong>$</strong>
                                    </h4>
                                    <h4>
                                        <strong>$</strong>
                                    </h4>
                                    <h4>
                                        <strong>$</strong>
                                    </h4>
                                    <h4>
                                        <strong>$</strong>
                                    </h4>
                                    <h4>
                                        <strong>$</strong>
                                    </h4>
                                </div>
                                <div>
                                    <h4>
                                        <strong>{constants.NUM_FORMAT(totalSummary.rentalAmount)}</strong>
                                    </h4>
                                    <h4>
                                        <strong>{constants.NUM_FORMAT(totalSummary.cleaningFees)}</strong>
                                    </h4>
                                    <h4>
                                        <strong>{constants.NUM_FORMAT(totalSummary.otherFees)}</strong>
                                    </h4>
                                    <h4>
                                        <strong>{constants.NUM_FORMAT(totalSummary.totalTax)}</strong>
                                    </h4>
                                    <h4>
                                        <strong>{constants.NUM_FORMAT(totalSummary.totalTaxPaid)}</strong>
                                    </h4>
                                    <h4>
                                        <strong>{totalSummary.totalNights}</strong>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal
                    className="submit-modal"
                    show={modal === "include-total-summary-modal"} 
                    onHide={this.closeModal}
                >
                    <Modal.Body>
                        <Row className="text-center">
                            <Col md={12}>
                                <h1>Include Total Summary?</h1>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col md={6}>
                                <PrimaryButton 
                                    cssClass="pull-left white-btn col-md-12 " 
                                    fullWidth={true} 
                                    type="button" 
                                    onClick={(e) => this.downloadCSV(e, true)}
                                >
                                    Yes
                                </PrimaryButton>
                            </Col>
                            <Col md={6}>
                                <PrimaryButton 
                                    cssClass="pull-right col-md-12 " 
                                    fullWidth={true} 
                                    type="button" 
                                    onClick={(e) => this.downloadCSV(e, false)}
                                >
                                    No
                                </PrimaryButton>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </PageLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        org: state.roleManager.org,
        user: state.authState.user,
    }
}

export default connect(
    mapStateToProps,
    null
)(ReportsTaxes);