import React, { Component } from 'react';
import { API } from 'aws-amplify';
import Picky from 'react-picky';
import 'react-picky/dist/picky.css';

import { connect } from 'react-redux';
import Col from 'react-bootstrap/lib/Col';
import { Title } from '../global/Title';
import { InputField, PrimaryButton, FieldWrap, InputLabel } from '../global/forms/FormElements';
import { Button } from 'react-bootstrap';
import Loader from '../global/Loader';
import PageLayout from '../global/PageLayout';
import { getSessionId } from '../../helpers/helpers';
import SupportTickets from './SupportTickets';

class SupportTicketsSearch extends Component {
    breadcrumbItems = [
        { title: "Support Tickets" }
    ];
    TYPE_OPTIONS = [
        { value: 'freshdesk', label: 'Freshdesk Tickets' },
        { value: 'case', label: 'CRM Cases' },
    ]
    orgList = [];
    state = {
        init: true,
        errMsg: '',
        isLoading: true,
        searchIsLoading: false,
        getPropertiesIsLoading: false,
        orgPickyValue: '',
        propPickyValue: '',
        ticketType: 'freshdesk',
        ticketId: '',
        reservationId: '',
        propertyId: '',
        reviewId: '',
        threadId: '',
        propertyList: [],
        ticketsList: [],
    }

    searchValid = () => {
        const stateVal = [
            'ticketId',
            'reservationId',
            'propertyId',
            'reviewId',
            'threadId',
        ];

        const hasValue = stateVal.some((key) => this.state[key]);

        return !hasValue;
    }

    async componentDidMount() {
        this.setOrgPickyOptions();

        this.setState({
            isLoading: false
        });
    }

    getProperties = async (orgName) => {
        let resp;
        let limit = 100;
        let offset = 0;
        const tid = getSessionId();
        let propertyList = [];

        this.setState({
            getPropertiesIsLoading: true
        });

        try {
            resp = await API.get("rapapi", `/promotion/properties?organizations=${orgName}&offset=${offset}&tid=${tid}&limit=${limit}`, { response: true, isCognito: true });

            if(_.get(resp, 'data.list.length')) {
                resp.data.list.forEach((prop) => {
                    propertyList.push({
                        ...prop,
                        custom_label: `${prop.property_id ? `${prop.property_id} -` : ''} ${prop.title ? prop.title : ''}`,
                        custom_value: prop.property_id
                    })
                });
            };

            this.setState({
                propertyList: propertyList,
                getPropertiesIsLoading: false
            });
        }
        catch(e) {
            this.setState({
                getPropertiesIsLoading: false
            });
        };
    }

    submitSearch = async () => {
        const { 
            ticketType,
            propertyId,
            ticketId,
            reservationId,
            reviewId,
            threadId,
            propPickyValue,
            orgPickyValue
        } = this.state;
        let resp;
        let url = '';
        let qs = '';
        let qsArr = [];

        switch(ticketType) {
            case 'freshdesk':
                url = `/freshdesk/tickets`;
                if(ticketId) {
                    qs = qs + `/${ticketId}`;
                };
                if(threadId) {
                    qsArr.push(`thread_id=${threadId}`);
                 };
                break;
            case 'case':
                url = `/organizations/${orgPickyValue.value}/cases`;
                if(ticketId) {
                    qsArr.push(`case_id=${ticketId}`);
                };
                if(reviewId) {
                    qsArr.push(`review_id=${reviewId}`);
                 };
                break;
        };

        if(propertyId || _.get(propPickyValue, 'property_id')) {
           qsArr.push(`property_id=${propertyId || _.get(propPickyValue, 'property_id')}`);
        };
        if(reservationId) {
           qsArr.push(`reservation_id=${reservationId}`);
        };


        if(qsArr.length) {
            qs = qs + `?${qsArr.join('&')}`;
        };

        this.setState({
            init: false,
            errMsg: '',
            searchIsLoading: true
        });

        try {
            resp = await API.get("pomapi", `${url}${qs}`);

            let arr = _.isArray(resp) ? [...resp] : [resp];

            this.setState({
                ticketsList: arr,
                searchIsLoading: false
            });
        }
        catch(err) {
            let msg = _.get(err, 'response.data.Message', '') || 'Oops! We encountered an error.';
            const match = (_.get(err, 'response.data.Message', '') || '').match(/'([^']+)'/);

            if(_.get(match, '[1]')) {
                msg = match[1];
            };

            this.setState({
                errMsg: msg,
                ticketsList: [],
                searchIsLoading: false
            });
        };
    }

    setOrgPickyOptions = () => {
        this.props.organizations.forEach((org) => {
            let orgConfig = {};
            
            orgConfig['label'] = org.title ? org.title : org.organization_name;
            orgConfig['value'] = org.organization_name;
    
            this.orgList.push(orgConfig);
        });
    }

    handleStateOnChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        }, () => {
            if(name == 'ticketType') {
                this.setState({
                    init: true,
                    errMsg: '',
                    ticketsList: []
                });
            }
        });
    }

    handleOrgPickyOnChange = (org) => {
        this.setState({
            orgPickyValue: org,
            propPickyValue: '',
            propertyList: [],
        }, async () => {
            if(org.value) {
                await this.getProperties(org.value);
            };
        });
    }

    handlePropPickyOnChange = (data) => {
        this.setState({
            propPickyValue: data
        });
    }

    clearSearch = () => {
        this.setState({
            init: true,
            errMsg: '',
            orgPickyValue: '',
            propPickyValue: '',
            ticketType: 'freshdesk',
            ticketId: '',
            reservationId: '',
            propertyId: '',
            reviewId: '',
            threadId: '',
            propertyList: [],
            ticketsList: [],
        });
    }

    render() {
        const {
            init,
            isLoading,
            searchIsLoading,
            ticketId,
            orgPickyValue,
            propPickyValue,
            ticketType,
            propertyList,
            reservationId,
            reviewId,
            threadId,
            getPropertiesIsLoading,
            ticketsList,
            errMsg
        } = this.state;

        let typeOptions = [];
        for (let i = 0; i < this.TYPE_OPTIONS.length; i++) {
			typeOptions.push(<option key={i + 1} value={this.TYPE_OPTIONS[i].value}>{this.TYPE_OPTIONS[i].label}</option>);
		};

        return(
                <PageLayout isCentered={true}>
                    
                    {isLoading && <Loader />}

                    <Title
                        title="Support Tickets"
                        isCentered={true}
                        breadcrumbItems={this.breadcrumbItems}
                        removeOrganizationDropdown={true}
                        addDefaultTitle="Account Management"
                    >
                    </Title>

                    <div id="content" className='support-tickets-search'>
                        <div className="property-item container-fluid">
                            <FieldWrap>
                                <Col md={12} className="support-tickets-search-container">

                                    {searchIsLoading && <Loader />}
                                    <Col md={12} sm={12} xs={12}>
                                        <strong><h2>Search for Tickets & Cases</h2></strong>
                                    </Col>
                                    <Col md={12} sm={12} xs={12}>
                                        <span
                                            className="clickable-text pull-right"
                                            onClick={!getPropertiesIsLoading ? this.clearSearch : null}
                                        >
                                            Clear Search
                                        </span>
                                    </Col>
                                    <Col md={4} sm={12}>
                                        <InputLabel>
                                            Type 
                                        </InputLabel>
                                        <InputField 
                                            type="select" 
                                            name="ticketType"
                                            value={ticketType} 
                                            onChange={this.handleStateOnChange}
                                        >
                                            {typeOptions}
                                        </InputField>
                                    </Col>
                                    <Col md={4} sm={12}>
                                        <InputLabel>
                                            Organization
                                        </InputLabel>
                                        <div className='custom-picky'>
                                            {
                                                orgPickyValue &&
                                                <div className='picky-clear-search'>
                                                    <Button 
                                                        onClick={()=> {
                                                            this.setState({
                                                                filterValue: '',
                                                                orgPickyValue: '',
                                                                propPickyValue: '',
                                                                propertyList: [],
                                                            })
                                                        }}
                                                    >
                                                        <i className='icon-Cross'></i>
                                                    </Button>
                                                </div>
                                            }
                                            <Picky
                                                labelKey="label"
                                                valueKey="value"
                                                filterPlaceholder="Search by Organization Name"
                                                options={this.orgList}
                                                value={orgPickyValue}
                                                multiple={false}
                                                includeSelectAll={false}
                                                includeFilter={true}
                                                keepOpen={false}
                                                onChange={value => this.handleOrgPickyOnChange(value)}
                                                dropdownHeight={600}
                                                defaultFocusFilter={true}
                                                disabled={getPropertiesIsLoading}
                                                clearFilterOnClose={true}
                                                render={({
                                                    style,
                                                    item,
                                                    isSelected,
                                                    selectValue,
                                                    labelKey,
                                                    valueKey,
                                                    }) => {
                                                    return (
                                                        <li
                                                            style={{ ...style }} 
                                                            className={isSelected ? "selected" : ""} 
                                                            key={item[valueKey]}
                                                            onClick={() => selectValue(item)}
                                                        >
                                                            <span
                                                                style={{
                                                                    fontWeight: isSelected ? "bold" : "normal"
                                                                }}
                                                            >
                                                                {item[labelKey] !== null && item[labelKey] !== "" ? item[labelKey] : item[valueKey]}
                                                            </span>
                                                        </li>
                                                    );
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    {
                                        orgPickyValue &&
                                        <Col md={4} sm={12}>
                                            <InputLabel>
                                                Property ID
                                            </InputLabel>
                                            <div className='input-loader-container'>
                                                {
                                                    getPropertiesIsLoading && <Loader/>
                                                }
                                                <div className='custom-picky'>
                                                    {
                                                        propPickyValue &&
                                                        <div className='picky-clear-search'>
                                                            <Button 
                                                                onClick={()=> {
                                                                    this.setState({
                                                                        propPickyValue: ''
                                                                    })
                                                                }}
                                                            >
                                                                <i className='icon-Cross'></i>
                                                            </Button>
                                                        </div>
                                                    }
                                                    <Picky
                                                        labelKey="custom_label"
                                                        valueKey="custom_value"
                                                        options={propertyList}
                                                        value={propPickyValue}
                                                        multiple={false}
                                                        includeSelectAll={false}
                                                        includeFilter={true}
                                                        onChange={value => this.handlePropPickyOnChange(value)}
                                                        dropdownHeight={600} 
                                                        filterDebounce={100}
                                                        keepOpen={false}
                                                        defaultFocusFilter={true}
                                                        disabled={getPropertiesIsLoading}
                                                        clearFilterOnClose={true}
                                                        render={({
                                                            style,
                                                            item,
                                                            isSelected,
                                                            selectValue,
                                                            labelKey,
                                                            valueKey,
                                                            }) => {
                                                                return (
                                                                    <li
                                                                        style={{ ...style }} 
                                                                        className={isSelected ? "selected" : ""} 
                                                                        key={item.custom_value}
                                                                        onClick={() => selectValue(item)}
                                                                    >
                                                                        <span
                                                                            style={{fontWeight: isSelected ? "bold" : "normal"}}
                                                                        >
                                                                            {<span>{item[labelKey]}</span>}
                                                                        </span>
                                                                    </li>
                                                                );
                                                            }}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    }
                                    <Col md={4} sm={12}>
                                        <InputLabel>
                                            Reservation ID
                                        </InputLabel>
                                        <div className='search-box'>
                                            <InputField 
                                                type="search" 
                                                value={reservationId} 
                                                name="reservationId"
                                                onChange={this.handleStateOnChange}
                                                handleClear={()=> {
                                                    this.setState({
                                                        reservationId: ''
                                                    })
                                                }} 
                                            >
                                            </InputField>
                                        </div>
                                    </Col>
                                    <Col md={4} sm={12}>
                                        <InputLabel>
                                            {ticketType == 'freshdesk' ? 'Ticket' : 'Case'} ID
                                        </InputLabel>
                                        <div className='search-box'>
                                            <InputField 
                                                type='search'
                                                value={ticketId} 
                                                name="ticketId"
                                                onChange={this.handleStateOnChange}
                                                handleClear={()=> {
                                                    this.setState({
                                                        ticketId: ''
                                                    })
                                                }} 
                                            >
                                            </InputField>
                                        </div>
                                    </Col>
                                    <Col md={4} sm={12}>
                                        <InputLabel>
                                            {ticketType == 'freshdesk' ? 'Thread' : 'Review'} ID
                                        </InputLabel>
                                        <div className='search-box'>
                                            <InputField 
                                                type="search" 
                                                value={ticketType == 'freshdesk' ? threadId : reviewId}
                                                name={ticketType == 'freshdesk' ? 'threadId' : 'reviewId'}
                                                onChange={this.handleStateOnChange}
                                                handleClear={()=> {
                                                    this.setState({
                                                        [ticketType == 'freshdesk' ? 'threadId' : 'reviewId']: ''
                                                    })
                                                }} 
                                            >
                                            </InputField>
                                        </div>
                                    </Col>
                                    <Col md={12} sm={12} className='top-margin flex-align-right'>
                                        <PrimaryButton 
                                            fullWidth={false} 
                                            type="button" 
                                            onClick={this.submitSearch}
                                            disabled={
                                                ticketType == 'freshdesk' ? 
                                                (this.searchValid() && 
                                                !propPickyValue || getPropertiesIsLoading)
                                                :
                                                (!orgPickyValue || (!reservationId && !ticketId && !propPickyValue) || getPropertiesIsLoading)
                                            }
                                        >
                                            Search
                                        </PrimaryButton>                            
                                    </Col>
                                    <Col md={12} sm={12} className='top-margin-30'>
                                        {
                                            (!searchIsLoading && !errMsg && !init) &&
                                            <SupportTickets
                                                type={ticketType}
                                                level={'search'}
                                                ticketsList={ticketsList}
                                            />
                                        }
                                        {
                                            errMsg &&
                                            <div className="custom-error-msg-container">
                                                {errMsg}
                                            </div>
                                        }
                                    </Col>
                                </Col>
                            </FieldWrap>
                        </div>
                    </div>
                </PageLayout>

        )
    }
}

const mapStateToProps = state => {
    return {
        organizations: state.authState.user.expanded_organization_roles,
    }
}

export default connect(
    mapStateToProps,
    null
)(SupportTicketsSearch);