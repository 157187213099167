import React, { Component, Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import { InputLabel, InputField, PrimaryButton } from '../../global/forms/FormElements';

export default class Accomodation extends Component {

    shouldComponentUpdate(nextProps) {
        return (
            nextProps.formValidation.property_type !== this.props.formValidation.property_type ||
            nextProps.formValidation.sleep_max !== this.props.formValidation.sleep_max ||
            nextProps.formValidation.numberOfBedrooms !== this.props.formValidation.numberOfBedrooms ||
            nextProps.formValidation.bathroomInt !== this.props.formValidation.bathroomInt ||
            nextProps.formValidation.kitchen !== this.props.formValidation.kitchen ||
            nextProps.formValidation.handicap_accessible !== this.props.formValidation.handicap_accessible ||
            nextProps.formValidation.rooms !== this.props.formValidation.rooms ||
            nextProps.formValidation.duplicateCommonRooms !== this.props.formValidation.duplicateCommonRooms ||
            nextProps.formValidation.commonRooms !== this.props.formValidation.commonRooms ||

            nextProps.data.property_type !== this.props.data.property_type ||
            nextProps.data.square_feet !== this.props.data.square_feet ||
            nextProps.data.sleep_max !== this.props.data.sleep_max ||
            nextProps.rooms !== this.props.rooms ||
            nextProps.studioRoom !== this.props.studioRoom ||
            nextProps.commonRooms !== this.props.commonRooms ||
            nextProps.numberOfBedrooms !== this.props.numberOfBedrooms ||
            nextProps.bathroomInt !== this.props.bathroomInt ||
            nextProps.bathroomFloat !== this.props.bathroomFloat
        );
    }
    
    render() {
        const {
            type,
            data,
            isAdmin,
            formValidation,
            propertyTypeOption,
            numberOfBedrooms,
            bathroomInt,
            bathroomFloat,
            maxSleepsOptions,
            numberOfBedroomsValues,
            kitchenList,
            handicapList,
            numberOfBathrooms,
            commonRooms,
            rooms,
            studioRoom,

            handleDataChange,
            handleBedoomChange,
            createCommonRoom,
            createRoomConfig,
            createCommonAreaConfig,
            handleInputStateChange,
            createDefaultRadioButton,

        } = this.props;
        
        return (
            <Fragment>
                <Row>
                    <Col md={6} sm={12} className="prop-type-col">
                        <InputLabel
                            id="property_type"
                            htmlFor="property_type"
                            className={formValidation.property_type && 'form-validation'}
                        >
                            {type} Type {type === "Room" ? "Category" : ""}
                        </InputLabel>
                        <InputField 
                            key={`property_type_input_ ${propertyTypeOption.length}`} 
                            type="select" 
                            name="property_type" 
                            value={data.property_type} 
                            onChange={handleDataChange}
                            className={formValidation.property_type && 'form-validation'}
                        >
                            {propertyTypeOption}
                        </InputField>
                        {formValidation.property_type && <span className="required-error-text">{type} Type is required</span>}
                    </Col>
                </Row>
                <Row>
                    <Col md={6} sm={12} className="prop-type-col">
                        <InputLabel 
                            htmlFor="square_feet"
                        >
                            Square Feet <em className="optional">(Optional)</em>
                        </InputLabel>
                        <InputField 
                            type="number"
                            name="square_feet" 
                            value={data.square_feet} 
                            onChange={(e) => handleDataChange(e, "text")}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6} sm={12} className="prop-type-col">
                        <InputLabel
                            id="sleep_max"
                            htmlFor="sleep_max"
                            className={formValidation.sleep_max && 'form-validation'}
                        >
                            Maximum Sleeps
                        </InputLabel>
                        <InputField 
                            type="select" 
                            name="sleep_max" 
                            value={data.sleep_max} 
                            onChange={handleDataChange}
                            className={formValidation.sleep_max && 'form-validation'}
                        >
                            {maxSleepsOptions}
                        </InputField>
                        {formValidation.sleep_max && <span className="required-error-text">Maximum Sleep is required</span>}
                    </Col>
                </Row>
                <Row>
                    <hr/>
                    <Col md={6} sm={12} className="prop-type-col">
                        <InputLabel
                            htmlFor="bedrooms"
                            className={formValidation.numberOfBedrooms && 'form-validation'}
                        >
                            Number of Bedrooms
                        </InputLabel>
                        <InputField 
                            type="select" 
                            name="numberOfBedrooms" 
                            value={numberOfBedrooms} 
                            onChange={handleBedoomChange}
                            className={formValidation.numberOfBedrooms && 'form-validation'}
                        >
                            {numberOfBedroomsValues}
                        </InputField>
                        {formValidation.numberOfBedrooms && <span className="required-error-text">Number of Bedrooms is required</span>}
                    </Col>
                </Row>
                {createRoomConfig(studioRoom, "studioRoom")}
                {createRoomConfig(rooms, "rooms")}
                {createCommonAreaConfig("commonRooms")}
                {
                    (isAdmin && numberOfBedrooms != 0 && numberOfBedrooms !== "studio") &&
                    <Row>
                        <Col
                            id="commonRooms"
                            md={3} 
                            sm={12} 
                            className="top-margin pull-left"
                        >
                            <PrimaryButton
                                cssClass="blue-btn "
                                fullWidth={true} 
                                onClick={createCommonRoom}
                                disabled={commonRooms.length === 2}
                            >
                                Add Common Area <i className="icon icon-Next"></i>
                            </PrimaryButton>
                        </Col>
                    </Row>
                }
                <br/>
                <Row>
                    <hr/>
                    <Col md={6} sm={12} className="prop-type-col">
                        <InputLabel
                            id="bathroomInt"
                            htmlFor="bathroomInt"
                            className={formValidation.bathroomInt && 'form-validation'}
                        >
                            Number of Bathrooms
                        </InputLabel>
                        <Row>
                            <Col md={8} sm={6}>		
                                <InputField 
                                    type="select" 
                                    name="bathroomInt" 
                                    value={bathroomInt} 
                                    onChange={handleInputStateChange}
                                    className={formValidation.bathroomInt && 'form-validation'}
                                >
                                    {numberOfBathrooms}
                                </InputField>
                                {formValidation.bathroomInt && <span className="required-error-text">Number of Bathrooms is required</span>}
                            </Col>
                            <Col md={4} sm={6}>
                                <InputField 
                                    type="select" 
                                    name="bathroomFloat" 
                                    value={bathroomFloat} 
                                    onChange={handleInputStateChange}
                                >
                                    <option>.0</option>
                                    <option>.5</option>
                                    <option>.75</option>
                                </InputField>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <InputLabel
                            id="kitchen"
                            className={formValidation.kitchen && 'form-validation'}
                        >
                            Kitchen
                        </InputLabel>
                        {createDefaultRadioButton(kitchenList, data.kitchen)}
                        {formValidation.kitchen && <span className="required-error-text">Kitchen is required</span>}
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col sm={12}>
                        <InputLabel
                            id="handicap_accessible"
                            className={formValidation.handicap_accessible && 'form-validation'}
                        >
                            Handicap Accessible
                        </InputLabel>
                        {createDefaultRadioButton(handicapList, data.handicap_accessible.toString())}
                        {formValidation.handicap_accessible && <span className="required-error-text">Handicap Accessible is required</span>}
                    </Col>
                </Row>
            </Fragment>
        )
    }
}