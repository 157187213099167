import React from "react";
// import { Row, Col, ProgressBar, Modal } from 'react-bootstrap';
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import ProgressBar from "react-bootstrap/lib/ProgressBar";
import Modal from "react-bootstrap/lib/Modal";
import Loader from "../../global/Loader";
/**
 * @class Website progress modal.
 */
export default class WebsiteSubmitModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			progress: 0,
			taskCount: 1,
			completedTask: 0
		};
		this.message = ["Updating Website Data"];
		this.onHide = this.onHide.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (
			(_.get(nextProps, "data.logo_image.data") &&
				!_.get(this.props.data, "logo_image.data")) ||
			(_.get(nextProps, "data.favicon_image.data") &&
				!_.get(this.props.data, "favicon_image.data")) ||
			(_.get(nextProps, "data.hero_image.data") &&
				!_.get(this.props.data, "hero_image.data"))
		) {
			_.get(nextProps, "data.logo_image.data") &&
			!_.get(this.props.data, "logo_image.data")
				? this.message.push("Uploading Logo")
				: null;
			_.get(nextProps, "data.favicon_image.data") &&
			!_.get(this.props.data, "favicon_image.data")
				? this.message.push("Uploading Favicon")
				: null;
			_.get(nextProps, "data.hero_image.data") &&
			!_.get(this.props.data, "hero_image.data")
				? this.message.push("Uploading Hero")
				: null;
		}

		let completedTask = nextProps.completedTask;

		this.setState({
			data: nextProps.data,
			taskCount: nextProps.taskCount,
			completedTask
		});
	}

	/**
	 * Hide submit modal and pass props to parent component.
	 */
	onHide() {
		this.setState(
			{
				taskToDone: 1
			},
			() => {
				this.props.onHide();
			}
		);
	}

	render() {
		const { completedTask, taskCount } = this.state;
		let progress = (completedTask * 100) / taskCount;
		let status;
		let style =
			this.props.error && this.props.error.status ? "danger" : "success";

		if (progress !== 100) {
			status = (
				<span className="submit-heading">{this.props.submitStatus}</span>
			);
		} else if (this.props.submitStatus === "Saving") {
			status = <span className="submit-heading">Saved</span>;
		} else if (this.props.submitStatus === "Creating Website") {
			status = <span className="submit-heading">Website Created</span>;
		} else {
			status = <span className="submit-heading">Error</span>;
		}

		return (
			<Modal
				backdrop="static"
				className="submit-modal"
				show={this.props.show}
				onHide={() => this.props.onHide()}
			>
				<Modal.Header>
					<Modal.Title>
						{progress !== 100 && (
							<div className="submit-loader">
								<Loader />
							</div>
						)}
						{progress === 100 &&
							this.props.error &&
							this.props.error.status && (
								<span className="status-icon status-icon--error"></span>
							)}
						{progress === 100 &&
							this.props.error &&
							!this.props.error.status && (
								<span className="status-icon status-icon--success"></span>
							)}
						{status}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col xs={12} className="bottom-margin">
							<div className="upload-status">
								<ProgressBar bsStyle={style} now={progress} />
								{progress !== 100 ? (
									<h5>{this.message[completedTask]}</h5>
								) : null}
								<h5 className="progress-percent">{parseInt(progress)}%</h5>
							</div>
						</Col>
					</Row>
					{progress === 100 ? (
						<Row>
							<Col xs={12} className="text-center">
								<button
									className="primary-btn pull-none col-md-6"
									onClick={this.onHide}
								>
									{" "}
									Done{" "}
								</button>
							</Col>
						</Row>
					) : null}
				</Modal.Body>
			</Modal>
		);
	}
}
