import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { 
    updateOrganization, 
    updateOrganizationTitle, 
    updateOrganizationId,
    closeOrganizationModal,
    setRole, 
} from '../../js/actions/index';
import {
    getOrgData
} from '../../helpers/helpers';
import constants from '../../js/constants';

class OrganizationTree extends Component {
    state = {
        parentOrg: {}
    };

    componentWillMount() {
        const { user, org } = this.props;
        let parent = {};

        parent = this.getParent(user.organization_role_tree, org);

        if((parent && parent.organization_name === 'propertyowners') || (parent && parent.organization_name === 'propertymanagers' )) {
            const orgData = getOrgData(user, org);
            
            parent = orgData.currOrg;
        };

        this.setState({
            parentOrg: parent ? parent : {}
        });
    }

    async componentWillReceiveProps(newProps) {
        const { org } = this.props;

		if(newProps.org !== org) {

            const parent = this.getParent(newProps.user.organization_role_tree, org);

            this.setState({
                parentOrg: parent ? parent : {}
            });
        };
    }

    getParent = (orgs, currOrg) => {
        const organization_role_tree = 
            orgs[0].organization_name === '_global_' ? 
            orgs[0].child_organizations :
            orgs;
        let parent = {};

        const filterOrg = (x) => {
            for(let i = 0; x.length > i; i++) {
                if(x[i].organization_name === currOrg) {

                    parent = x[i];
                    break;
                }
                else if(x[i].child_organizations.length) {
                    
                    for(let y = 0; x[i].child_organizations.length > y; y++) {

                        if(x[i].child_organizations[y].organization_name === currOrg) {

                            parent = x[i]
                            break;
                        };
                    };

                    if(!Object.keys(parent).length) {

                        filterOrg(x[i].child_organizations);
                    };
                };
            }
        };

        filterOrg(organization_role_tree);

        return parent;
    }

    switchOrg = (orgData) => {
        const orgName = orgData.organization_name;
        const orgTitle = orgData.title;
        const orgId = orgData.organization_id;
        const allUserRoles = this.props.isInternalPropertyAdmin ? Object.values(constants.USER_TYPES).filter((role) => {
            return role;
        }) : [];
        const organization = this.props.user.expanded_organization_roles.filter(org=>{
            if(org.organization_name == orgName) {
                return true;
            };
        });

        if(organization && organization.length) {
            this.props.updateOrganization(orgName);
            this.props.updateOrganizationTitle(orgTitle);
            this.props.updateOrganizationId(orgId);
            this.props.setRole(this.props.isInternalPropertyAdmin ? allUserRoles : organization[0].roles);
        };

        sessionStorage.removeItem('reservations-listing');
        sessionStorage.removeItem('properties-listing');
        sessionStorage.setItem("org", orgName);
        sessionStorage.setItem("orgTitle", orgTitle);
        sessionStorage.setItem("orgId", orgId);

        this.setState({
            parentOrg: orgData
        });

        this.props.history.push("/dashboard", {org:orgName, roles: this.props.isInternalPropertyAdmin ? allUserRoles: organization[0].roles});
        this.props.closeOrganizationModal('')
    }

    generateOrgTree = (list) => {
        const { org } = this.props;

        return list.map((item,i)=>{
            let hasChildren = item.child_organizations && (item.child_organizations.length > 0),
            isNotTpro = 
                (item && item.roles && !item.roles.includes('TPAgencyAgent')) && 
                (item && item.roles && !item.roles.includes('TPAgencyAdmin')) && 
                (item && item.roles && !item.roles.includes('TPInternalAdmin')) && 
                (item && item.roles && !item.roles.includes('TPAgencyClient')),
            orgName = item.organization_name,
            orgTitle = !item.title ? orgName : item.title,
            currentOrg = org === item.organization_name;

            if(isNotTpro) {
                return (
                    <div key={i}>
                        <li 
                            onClick={!currentOrg ? () => this.switchOrg(item) : null}
                            className={currentOrg ? 'highlight' : ""}
                        >
                            <a  href="#">
                                {orgTitle}
                            </a>
                        </li>
                        { 
                            hasChildren ? 
                            <ul className="child">
                                {this.generateOrgTree(item.child_organizations)}
                            </ul> 
                            :  
                            <ul className="child">
                            </ul>
                        }
                    </div>
                );
            }
        })
    }

    render() {
        const { parentOrg } = this.state;

        return(
            <ul className='org-tree'>
                {this.generateOrgTree([parentOrg])}
            </ul>
        )
    }
}

const mapStateToProps = state => {
    return {
        isInternalPropertyAdmin: state.roleManager.isInternalPropertyAdmin,
        org: state.roleManager.org,
        user: state.authState.user,
        roleManager: state.roleManager,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setRole: (role) => {
            dispatch(setRole(role));
        },
        updateOrganization: (org) => {
            dispatch(updateOrganization(org))
        },
        updateOrganizationTitle: (org) => {
            dispatch(updateOrganizationTitle(org))
        },
        updateOrganizationId: (orgId) => {
            dispatch(updateOrganizationId(orgId))
        },
        closeOrganizationModal: (name) => {
            dispatch(closeOrganizationModal(name))
        }
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(OrganizationTree));