import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PageLayout from '../global/PageLayout';
import PromotionsForm from '../content/promotions/PromotionsForm';
import _ from "lodash";

class AddPromotion extends Component {

    constructor(props) {
        super(props);
        this.mode = 'add';
        this.org = this.props.roleManager.org;
        this.roles = this.props.roleManager.roles;
        this.title = "Add Promotion";
        this.cloneData = {
            "channels":[],
            "details":"",
            "discount":"percent",
            "id":0,
            "name":"",
            "organizations":[],
            "properties":[],
            "rule_set":{
                "rules":[
                    {"attribute": "MINIMUMNIGHTS", "operation":"GREATERTHANEQUAL", "value": "1"},
                    {"attribute": "CHECKINALLOWED", "operation":"IN", "value": "0,1,2,3,4,5,6"},
                    {"attribute": "CHECKOUTALLOWED", "operation":"IN", "value": "0,1,2,3,4,5,6"}
                ]
            },
            "segments":[],
            "status":"NOTLIVE",
            "type":"DD",
            "value":"",
            "evaluate_organization": 0,
            "qualify_all_properties": 0,
            "accounting_adjustment": "PM"
        };
        this.breadcrumbItems = [
            {
                title: "Account Management"
            },   
            {
                title: "Promotions",
                link:  "/account-management/promotions"
            }   
            ,    
            {
                title: "Create Promotion"
            }
        ];
        this.init();
    }

    init = async () => { 
        let cloneDataCopy = _.get(this.props, 'location.state.cloneData');

        if(_.isObject(cloneDataCopy) && !_.isEmpty(cloneDataCopy)) {
            cloneDataCopy.id = 0;
            cloneDataCopy.rule_set;

            delete cloneDataCopy.id;
            delete cloneDataCopy.created_at;
            delete cloneDataCopy.rule_set.id;
            delete cloneDataCopy.rule_set.promotion_id;

            if(_.get(cloneDataCopy, 'segments.length')) {
                cloneDataCopy.segments.forEach((segment, i) => {
                    delete cloneDataCopy.segments[i].title;
                    delete cloneDataCopy.segments[i].promotion_id;
                    delete cloneDataCopy.segments[i].description;
                });
            };

            this.cloneData = {...this.cloneData, ...cloneDataCopy};
            this.title = cloneDataCopy.name || "Add Promotion";
        };
    }

	render() {
		return ( 
            <PageLayout isCentered={true}>
                <PromotionsForm 
                    mode="add" 
                    org={this.org}
                    roles={this.roles}
                    breadcrumbItems={this.breadcrumbItems} 
                    promotionData={this.cloneData}
                    title='Edit'
                />
            </PageLayout>
        )
	}
}

const mapStateToProps = state => {
    return {
        roleManager: state.roleManager
    }
}

export default withRouter(connect(
    mapStateToProps,
    null
)(AddPromotion));

