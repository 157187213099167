import React, { Component, Fragment } from 'react';
import { API, Storage } from 'aws-amplify';
import { updateUnsavedData } from '../../js/actions';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Dropzone from 'react-dropzone';
import isEqual from "lodash/isEqual";

import Form from 'react-bootstrap/lib/Form';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import PanelGroup from 'react-bootstrap/lib/PanelGroup';
import Panel from 'react-bootstrap/lib/Panel';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Popover from 'react-bootstrap/lib/Popover';
import moment from 'moment';

import { Title } from '../global/Title';
import { delay } from '../../js/actions';
import { InputField, PrimaryButton, FieldWrap, InputLabel } from '../global/forms/FormElements';
import Loader from '../global/Loader';
import PageLayout from '../global/PageLayout';
import GlobalSubmitModal from '../global/forms/GlobalSubmitModal';
import NavigationPrompt from "../global/NavigationPrompt";
import Constants from '../../js/constants';
import DateRangePicker from "react-dates/lib/components/DateRangePicker";
import Switch from "react-switch";

class OrganizationDetails extends Component {
    constructor(props) {
        super(props);
        this.seasonalPoliciesTooltip = "Cancellation policies that are less strict often generate more reservations. To overwrite your default cancellation policy for a certain date range, you can do so here. Please note this isn't supported on all channels currently. Channels other than Booking.com, Expedia, VRBO and RedAwning will continue to use your default cancellation policy."
        this.org = this.props.org;
        this.isViewer = (this.props.roleManager.indexOf(Constants.USER_TYPES.ACCOUNT_MANAGER_VIEWER) > -1);
        this.imageFormats = Constants.IMAGE_FORMATS.toString();
        this.state = {
            isLoading: false,
            image: {},
            activeKey: 1,
            orgData: {},
            data:{},
            image: {},
            newImage: {},
            imageDimension: "",
            activeModal: '',
            submitModalTotal: 0,
            submitStatus: 0,
            seasonalPolicies: [],
            modalError: {
                show: false,
                message: "",
                detail: [],
            },
            initialStateModel: {
                data: {},
                image: {},
                newImage: {},
                seasonalPolicies: [],
            },
            validations: {
                title: false,
                contact_email: false,
                validEmailFormat: false,
                seasonalPolicies: [],
            },
        }
    }

    async componentDidMount() {

        this.setState({ isLoading: true });

        await this.getSeasonalPolicies();
        await this.getOrganization();

        this.updateInitialStateModel();

        this.setState({ isLoading: false });
    }

    getSeasonalPolicies = async () => {
        try {
            let data = await API.get("rapapi", `/organizations/${this.org}/seasonalcancellationpolicy`);

            let seasonalPolicies = [];
            let seasonalPoliciesValidation = [];

            if (data && data.length) {
                data.forEach((policy) => {
                    seasonalPolicies.push({
                        start_date: moment(policy.start_date, 'YYYY-MM-DD'),
                        end_date: moment(policy.end_date, 'YYYY-MM-DD'),
                        policy: policy.policy,
                        active: policy.active,
                        focusedInput: null
                    });
                    seasonalPoliciesValidation.push({
                        dates: false, policy: false
                    });
                });
                this.setState({
                    seasonalPolicies: seasonalPolicies,
                    validations: {
                        ...this.state.validations,
                        seasonalPolicies: seasonalPoliciesValidation
                    }

                });
            }
        } catch (e) {
            console.log(e, "Error GET Seasonal Policy");
            this.setState({
                seasonalPolicies: []
            });
        }
    }

    getOrganization = async () => {
        try {
            let data = await API.get("accapi", `/organizations/${this.org}`, { headers: {"Cache-Control": "must-revalidate"}});
            if(Object.keys(data).length) {
                if(data.organization_name !== '_global_' || data.organization_name !== 'propertyowners' || data.organization_name !== 'propertymanagers') {
                    this.setState({ 
                        orgData: data,
                        data: data,
                        image: (data && data.logo_image && data.logo_image.url) ? data.logo_image.url : '',
                        newImage: {},
                    });
                }
                else {
                    this.setState({
                        orgData: {},
                        data: {},
                        image: {},
                        newImage: {},
                    });
                };
            };
        }
        catch(e) {
            console.log(e, "Error GET Organization");
        }
    }

    submitSeasonalPolicies = async () => {
        const {seasonalPolicies} = this.state;
        let seasonalPoliciesBody = [];

        seasonalPolicies.forEach((policy) => {
            seasonalPoliciesBody.push({
                start_date: moment(policy.start_date).format('YYYY-MM-DD'),
                end_date: moment(policy.end_date).format('YYYY-MM-DD'),
                policy: policy.policy,
                active: policy.active
            })
        });

        await API.put("rapapi", `/organizations/${this.org}/seasonalcancellationpolicy`, {body: seasonalPoliciesBody})
            .then(resp => {
                this.setState({
                    submitStatus: this.state.submitStatus + 1
                });
            })
            .catch(err => {
                this.setModalError(err && err.response && err.response.data);
            });
    }

    handleFormSubmit = async () => {
        const validations = this.handleValidations();
        
        this.setState({
            validations: validations
        }, () => {
            const { 
                validations
            } = this.state;

            function getTrueValues(obj) {
                let trueValues = [];
            
                function findTrueValues(item) {
                    if (item === true) {
                        trueValues.push(item);
                    } else if (Array.isArray(item)) {
                        item.forEach(findTrueValues);
                    } else if (typeof item === 'object' && item !== null) {
                        Object.values(item).forEach(findTrueValues);
                    }
                }
            
                findTrueValues(obj);
                return trueValues;
            }

            const checkValidations = getTrueValues(validations);

            if(!checkValidations.length) {
                const {
                    data,
                    newImage,
                    seasonalPolicies,
                    initialStateModel,
                } = this.state;
        
                let submitModalTotal = 0;
        
                // Organization
                if(!isEqual(initialStateModel.data, data)) {
                    submitModalTotal ++;
                };
                // Image
                if(!isEqual(initialStateModel.newImage, newImage)) {
                    submitModalTotal ++;
                };
                if(!isEqual(initialStateModel.seasonalPolicies, seasonalPolicies)) {
                    submitModalTotal ++;
                };
        
                this.setState({
                    modalError: {
                        show: false,
                        message: "",
                        detail: [],
                    },
                    activeModal: 'submit-modal',
                    submitStatus: 0,
                    submitModalTotal: submitModalTotal,
                    progressMessage: 'Saving Organization...'
                }, async () => {
                    const {
                        data,
                        submitStatus,
                        initialStateModel,
                        newImage
                    } = this.state;
        
                    // Update Organization
                    if(!isEqual(initialStateModel.data, data)) {
                        try {
                            await API.patch("accapi", `/organizations/${this.org}`, { "body": data });
                
                            this.setState({ 
                                submitStatus: submitStatus + 1,
                            });
                        }
                        catch(e) {
                            this.setModalError(err && err.response && err.response.data);
                        }
                    }
        
                    // Upload Image
                    if(!isEqual(initialStateModel.newImage, newImage)) {
                        this.setState({
                            progressMessage: 'Saving Logo...'
                        })
                        await this.saveImage();
                    };

                    // Update Seasonal Cancellation Policy
                    if (!isEqual(initialStateModel.seasonalPolicies, seasonalPolicies)) {
                        await this.submitSeasonalPolicies();
                    }
        
                    this.updateInitialStateModel();
        
                    this.setState({
                        progressMessage: "Organization Saved"
                    });
                });
            };
        })
    }

    saveImage = async () => {
        const { newImage } = this.state;
        let tokenList = [];

        try {
            if(newImage) {
                let dataType = newImage.data_type;
                let token = await API.post('accapi',`/organizations/${this.org}/imagetokens`,{
                    body: { image_type : "logo"}
                });
                if(token && token.token_id) {
                    let upload_url = token.upload_url.split("s3://")[1];
                    let bucket = upload_url.substr(0, upload_url.indexOf("/"));
                    let id = upload_url.substring(upload_url.indexOf("/") + 1, upload_url.lastIndexOf("/"));

                    Storage.configure({
                        bucket: bucket,
                        identityPoolId: Constants.COGNITO.IdentityPoolId,
                        region: Constants.COGNITO.Region
                    });

                    try {
                        await Storage.put(`${token.token_id}`, newImage.file, {
                            contentType: dataType,
                            customPrefix: {
                                public: `${id}/public/`,
                            }
                        });

                        tokenList.push({ dataType, id: token.token_id });
                    }
                    catch(e) {
                        const reader = new FileReader();

                        reader.addEventListener("loadend", () => {
                            let result = reader.result;
                            let errorMessage = result.includes("RequestTimeTooSkewed") ?
                                "Image upload failed. The difference between the request time and the current time is too large." :
                                result;
                            let detail = [...this.state.modalError.detail, errorMessage];
                            
                            if(result) {
                                this.setState({
                                    submitStatus: this.state.submitStatus + 1,
                                    modalError: {
                                        ...this.state.modalError,
                                        show: true,
                                        detail: detail
                                    }
                                });
                            };
                        });
                        reader.readAsText(e && e.response && e.response.data);
                    };
                } 
                else {
                    throw new Error();
                }
            }
        }
        catch(e) {
            console.log(e, "Error Uploading Logo Image");
        }

        for(let i=0;i<tokenList.length;i++) {
            let uploadComplete = false;

            while(!uploadComplete) {
                try {
                    let bucketInfo = await API.get("accapi",`/organizations/${this.org}/imagetokens/${tokenList[i].id}`);
                    if(bucketInfo && bucketInfo[0] && bucketInfo[0].status == "completed" || bucketInfo && bucketInfo[0] && bucketInfo[0].status == "error") {
                        uploadComplete = true;
                        this.setState({
                            submitStatus: this.state.submitStatus + 1 
                        });
                    };
                } 
                catch(e) {
                    console.log(e);
                    uploadComplete = true;
                    this.setState({
                        submitStatus: this.state.submitStatus + 1 
                    });
                }
                if(!uploadComplete) {
                    await delay(2000);
                };
            };
        };
    }

    handleValidations = () => {
        const { 
            data,
            seasonalPolicies
        } = this.state;

        let validations = {
            title: false,
            contact_email: false,
            validEmailFormat: false,
            seasonalPolicies: [],
        };

        // Title
        if(!data.title.length) {
            validations.title = true;
        }
        else {
            validations.title = false;
        };

        // Contact Email
        if(!data.contact_email.length) {
            validations.contact_email = true;
        }
        else {
            validations.contact_email = false;
        };

        // Contact Email - Valid email
        if(data.contact_email.length && !data.contact_email.match(Constants.EMAIL_VALIDATION)) {
            validations.validEmailFormat = true;
        }
        else {
            validations.validEmailFormat = false;
        }

        if(seasonalPolicies.length) {
            validations.seasonalPolicies = seasonalPolicies.map(item => {
                const hasDates = item.start_date !== null && item.end_date !== null;
                return {
                    "dates": !hasDates
                };
            });
        };

        return validations;
    }

    updateInitialStateModel = () => {
        const {
            data,
            image,
            newImage,
            initialStateModel,
            seasonalPolicies
        } = this.state;

        this.setState({
            initialStateModel: {
                ...initialStateModel,
                data: {...data},
                image: {...image},
                newImage: {...newImage},
                seasonalPolicies: [...seasonalPolicies],
            }
        });
    }

    compareState = () => {
        const {
            data,
            image,
            newImage,
            initialStateModel,
            seasonalPolicies
        } = this.state;

        let unsavedData = false;

        const stateModel = {
            data: {...data},
            image: {...image},
            newImage: {...newImage},
            seasonalPolicies: [...seasonalPolicies],
        };

        unsavedData = !isEqual(initialStateModel, stateModel);

		this.props.updateUnsavedData(unsavedData);

        return !isEqual(initialStateModel, stateModel);
    }

    setModalError = (error) => {
        let detailError = [...this.state.modalError.detail];

        if(error && error.Message) {
            detailError.push(error.Message);
        };

        this.setState({ 
            submitStatus: this.state.submitStatus + 1,
            modalError: {
                show: true,
                message: this.handleSubmitErrorMessage(error && error.Message),
                detail: detailError,
            }
        });
    }

    handleDataChange = (e) => {
        const { data } = this.state;
        const name = e.target.name;
        const value = e.target.value;
        let stateData = {...data};

        if(name == 'phone_office') {
            stateData = {
                ...data,
                property_manager_details: {
                    ...data.property_manager_details,
                    [name]: value
                }
            };
        }
        else {
            stateData = {
                ...data,
                [name]: value
            };
        };

        this.setState({
            data: stateData
        }, () => {
            const { validations } = this.state;

            if(validations[name] === true || validations.validEmailFormat === true) {
                const checkValidations = this.handleValidations();

                this.setState({
                    validations: checkValidations
                });
            };
        })
    }

    updateImage = async (file) => {
		if(!file.length) return;

        const { newImage } = this.state;

        const reader = new FileReader();
		reader.onload = ((e) => {
			this.setState({
                newImage: {
                    ...newImage,
                    data: e.target.result.split(',')[1],
                    data_type: file[0].type,
                    file: file[0]
                }
            });
		});
        reader.readAsDataURL(file[0]);
	}

    getDimensions = (e) => {
		const target = e.target;
          
		this.setState({
			imageDimension: `${target.naturalWidth} x ${target.naturalHeight}`
		});
    }

    closeModal = () => {
        this.setState({
            activeModal: '',
            progressMessage: '',
        });
    }

    seasonalPoliciesPopover = (title, message) => {
        return (
            <OverlayTrigger
                placement='top'
                overlay={<Popover
                    id="popover-basic"
                    placement="top"
                    title={title}
                >
                    <p>{message}</p>
                </Popover>}
            >
                <span className="input-question-mark">
                    <i className="icon-question-mark"></i>
                </span>
            </OverlayTrigger>
        )
    }

    addSeasonalPolicy = (e) => {
        e.preventDefault();
        let freeCancel = {
            'no': 'yes_60',
            'yes_60': 'yes_42',
            'yes_42': 'yes_30',
            'yes_30': 'yes_14',
            'yes_14': 'yes_7',
            'yes_7': 'yes_3'
        };
        
        this.setState((prevState) => ({
            seasonalPolicies: [
                ...prevState.seasonalPolicies,
                {
                    start_date: null,
                    end_date: null,
                    policy: freeCancel[this.state.data.property_manager_details.free_cancel_policy],
                    active: true,
                    focusedInput: null
                },
            ],
            validations: {
                ...this.state.validations,
                seasonalPolicies: [
                    ...prevState.validations.seasonalPolicies,
                    {
                        dates: false
                    }
                ]
            }
        }));
    }

    createSeasonalPolicies = () => {
        const standardPolicy = Number(((_.get(this.state.data, 'property_manager_details.free_cancel_policy') || '').match(/\d+/) || [99])[0]);
        let freeCancelPolicy = [];
        Constants.FREE_CANCEL_POLICY.map((policy, i) => {
            if (policy.value !== 'no') {
                const freeCancel = Number((policy.value.match(/\d+/) || [null])[0]);
                const exclude = standardPolicy && freeCancel >= standardPolicy;
                if(!exclude) {
                    freeCancelPolicy.push(<option key={i} data-value={policy.value} value={policy.value}>{policy.label}</option>)
                }; 
            };
        });

        return this.state.seasonalPolicies.map((policy, index) => {

            return (
                <Fragment key={index}>
                    <Row className="sespol bottom-margin">
                        <Col className="sespol-form" lg={10} md={10} xs={10} sm={10}>
                            <Col className="sespol-dtpc" lg={6} md={6} xs={12} sm={12}>
                                <Col md={6} xs={6} sm={6} lg={6}>
                                    <InputLabel
                                        htmlFor='seasonalstart'
                                    >
                                        Start Date
                                    </InputLabel>
                                </Col>
                                <Col md={6} xs={6} sm={6} lg={6}>
                                    <InputLabel
                                        htmlFor='seasonalend'
                                    >
                                        End Date
                                    </InputLabel>
                                </Col>
                                <Col md={12} xs={12} sm={12} lg={12}>
                                    <div className={this.state.validations.seasonalPolicies[index].dates ? 'form-validation' : ''}>
                                        <DateRangePicker
                                            noBorder
                                            key={index}
                                            startDate={policy.start_date}
                                            startDateId={`sespol-startdate-${index}`}
                                            startDatePlaceholderText="mm/dd/yyyy"
                                            endDate={policy.end_date}
                                            endDateId={`sespol-enddate-${index}`}
                                            endDatePlaceholderText="mm/dd/yyyy"
                                            onDatesChange={(dates) => this.handleSeasonalDatesChange(index, dates)}
                                            focusedInput={policy.focusedInput}
                                            onFocusChange={(focused) => this.handleSeasonalDatesFocusChange(index, focused)}
                                        />
                                    </div>
                                    {this.state.validations.seasonalPolicies[index].dates && <span className="required-error-text">Seasonal date range should be filled in.</span>}
                                </Col>
                            </Col>
                            <Col lg={3} md={3} xs={6} sm={6}>
                                <InputLabel
                                    htmlFor="seasonal_cancel_policy"
                                >
                                    Free Cancel Policy
                                </InputLabel>
                                <InputField
                                    type="select"
                                    name="seasonal_cancel_policy"
                                    value={policy.policy}
                                    onChange={(e) => this.handleSeasonalPolicyChange(index, e)}
                                    className={this.state.validations.seasonalPolicies[index].policy ? 'form-validation' : ''}
                                >
                                    {freeCancelPolicy}
                                </InputField>
                                {this.state.validations.seasonalPolicies[index].policy && <span className="required-error-text">Free Cancel Policy is required.</span>}
                            </Col>
                            <Col lg={3} md={3} xs={6} sm={6}>
                                <InputLabel
                                    htmlFor="seasonal_active"
                                >
                                    Active
                                </InputLabel>
                                <div className={"switch-active"}>
                                    <Switch
                                        onColor={"#5AD888"}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        height={30}
                                        width={45}
                                        checked={policy.active}
                                        onChange={() => this.handleSeasonalActiveChange(index)}
                                    />
                                </div>
                            </Col>
                        </Col>
                        <Col md={2} xs={2} sm={2} lg={2}>
                            <a
                                href="#"
                                className="left-margin"
                                onClick={(e) => this.removeSeasonalPolicy(e, index)}
                            >
                                <i
                                    className="icon icon-Delete"
                                />
                            </a>
                        </Col>
                    </Row>
                </Fragment>
            );
        });
    }

    removeSeasonalPolicy = (e, index) => {
        e.preventDefault();

        let seasonalPolicies = [...this.state.seasonalPolicies];
        let seasonalPoliciesValidations = [...this.state.validations.seasonalPolicies];

        seasonalPolicies.splice(index, 1);
        seasonalPoliciesValidations.splice(index, 1);

        this.setState({
            seasonalPolicies: seasonalPolicies,
            validations: {
                ...this.state.validations,
                seasonalPolicies: seasonalPoliciesValidations
            }
        })
    }

    handleSeasonalDatesFocusChange = (index, focusedInput) => {
        this.setState((prevState) => ({
            seasonalPolicies: prevState.seasonalPolicies.map((policy, i) =>
                i === index ? {...policy, focusedInput} : policy
            ),
        }), () => {
            const startDateInput = document.getElementById(`sespol-startdate-${index}`);
            const endDateInput = document.getElementById(`sespol-enddate-${index}`);
            if (startDateInput) startDateInput.readOnly = true;
            if (endDateInput) endDateInput.readOnly = true;
        });
    };

    handleSeasonalDatesChange = (index, {startDate, endDate}) => {
        this.setState((prevState) => ({
            seasonalPolicies: prevState.seasonalPolicies.map((policy, i) =>
                i === index ? {...policy, start_date: startDate, end_date: endDate} : policy
            ),
        }), () => {
            const checkValidations = this.handleValidations();

            this.setState({
                validations: checkValidations
            });
        });
    };

    handleSeasonalActiveChange = (index) => {
        this.setState((prevState) => ({
            seasonalPolicies: prevState.seasonalPolicies.map((policy, i) =>
                i === index ? {...policy, active: !policy.active} : policy
            ),
        }));
    };

    handleSeasonalPolicyChange = (index, e) => {
        const newPolicy = e.target.value
        this.setState((prevState) => ({
            seasonalPolicies: prevState.seasonalPolicies.map((policy, i) =>
                i === index ? {...policy, policy: newPolicy} : policy
            ),
            validations: {
                ...this.state.validations,
                seasonalPolicies: this.state.validations.seasonalPolicies.map((validation, i) =>
                    i === index ? {...validation, policy: false} : validation
                )
            }
        }));
    };

    render() {
        const { 
            data,
            isLoading,
            activeKey,
            newImage,
            image,
            activeModal,
            submitModalTotal,
            imageDimension,
            validations
        } = this.state;

        return (
            <PageLayout isCentered={true}>

                {
                    isLoading === true && <Loader />
                }
                {
                    (Object.keys(data).length === 0 && !isLoading) &&
                    <Fragment>
                        <Title 
                            title='Organization' 
                            isCentered={true} 
                        >
                        </Title>
                        <div id="content">
                            <Col xs={12}>
                                <div className='no-collection'>No Organization Found.</div>
                            </Col>
                        </div>
                    </Fragment>
                }
                {
                    (Object.keys(data).length > 0 && !isLoading) &&
                    <Fragment>
                        <Title 
                            title='Organization' 
                            isCentered={true} 
                        >
                            <PrimaryButton 
                                onClick={this.handleFormSubmit} 
                                cssClass="primary-btn pull-left panal-button" 
                                fullWidth={false} 
                                type="button"
                                disabled={!this.compareState()}
                            >
                                Save
                            </PrimaryButton>
                        </Title>
                        <div id="content">
                            <div className={`property-item row ${!_.includes(this.props.permissions, 'update_organization') ? "readonly" : ""}`}>
                                <FieldWrap>
                                    <Form horizontal>
                                        <Row className="row-padding">
                                            <Col xs={12}>
                                                <PanelGroup 
                                                    accordion id="account-settings" 
                                                    defaultActiveKey={1} 
                                                    activeKey={activeKey} 
                                                    onSelect={(activeKey) => {this.setState({ activeKey })}}
                                                >
                                                    <Panel eventKey={1}>
                                                        <Panel.Heading>
                                                            <Panel.Title toggle>
                                                                <strong>Organization Details<span className={'pull-right '+(activeKey === 1 ? 'glyphicon glyphicon-chevron-up': 'glyphicon glyphicon-chevron-down')}></span></strong>
                                                            </Panel.Title>
                                                        </Panel.Heading>
                                                        <Panel.Body collapsible>
                                                            <Row>
                                                                <Col md={6} className="prop-type-col">
                                                                    <InputLabel
                                                                        htmlFor="title"
                                                                        className={validations.title && 'form-validation'}
                                                                    >
                                                                        Organization Name
                                                                    </InputLabel>
                                                                    <InputField 
                                                                        type="text"
                                                                        name="title"
                                                                        value={data.title || ''}
                                                                        className={validations.title && 'form-validation'}
                                                                        onChange={this.handleDataChange}
                                                                    />
                                                                    {validations.title && <span className="required-error-text">Organization Name is required</span>}
                                                                </Col>
                                                            </Row>
                                                            <br/>
                                                            <Row>
                                                                <Col md={6} className="prop-type-col">
                                                                    <InputLabel 
                                                                        htmlFor="contact_email"
                                                                        className={(validations.contact_email || validations.validEmailFormat) && 'form-validation'}
                                                                    >
                                                                        Organization Email Address
                                                                    </InputLabel>
                                                                    <InputField 
                                                                        type="text"
                                                                        name="contact_email" 
                                                                        value={data.contact_email || ''}
                                                                        className={(validations.contact_email || validations.validEmailFormat) && 'form-validation'}
                                                                        onChange={this.handleDataChange}
                                                                    />
                                                                    {
                                                                        validations.contact_email && 
                                                                        <span className="required-error-text">
                                                                            Organization Email Address is required
                                                                        </span>
                                                                    }
                                                                    {
                                                                        (validations.validEmailFormat) &&
                                                                        <span className="col-md-12 error-text">
                                                                            {Constants.EMAIL_INVALID_MESSAGE}
                                                                        </span>
                                                                    }
                                                                </Col>
                                                            </Row>
                                                            <br/>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <InputLabel 
                                                                        htmlFor="phone_office"
                                                                    >
                                                                        Organization Primary Phone <em className="optional">(Optional)</em>
                                                                    </InputLabel>
                                                                    <InputField 
                                                                        type="text"
                                                                        name="phone_office" 
                                                                        value={(data && data.property_manager_details && data.property_manager_details.phone_office) || ''} 
                                                                        onChange={this.handleDataChange}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <br/>
                                                            <Row>
                                                                <Col md={3} className='bottom-margin top-margin'>
                                                                    <InputLabel>
                                                                        Seasonal Cancellation Policy {this.seasonalPoliciesPopover('', this.seasonalPoliciesTooltip)}
                                                                    </InputLabel>
                                                                </Col>
                                                                <Col md={3} className='bottom-margin top-margin'>
                                                                    <PrimaryButton
                                                                        cssClass="blue-btn "
                                                                        fullWidth={true}
                                                                        onClick={(e) => this.addSeasonalPolicy(e)}
                                                                        disabled={this.state.data.property_manager_details.free_cancel_policy === 'yes_3'}
                                                                    >
                                                                        Add Seasonal Policy
                                                                    </PrimaryButton>
                                                                </Col>
                                                            </Row>
                                                            {this.createSeasonalPolicies()}
                                                            <br/>
                                                            <Row>
                                                                <Col xs={12} md={6} className="field-wrapper">
                                                                    <InputLabel 
                                                                        htmlFor="organization_logo" 
                                                                        cssClass="custom-label"
                                                                    >
                                                                        Organization Logo <em className="optional">(Optional)</em>
                                                                    </InputLabel>
                                                                    <div className="file-input-wrap">
                                                                        <Dropzone
                                                                            inputProps={{name: "organization_logo"}}
                                                                            style={{height: "32px"}}
                                                                            multiple={false}
                                                                            accept={this.imageFormats}
                                                                            onDrop={this.updateImage}>

                                                                            <div className="file-input">
                                                                                <span className="file-name">
                                                                                    {(newImage && newImage.file && newImage.file.name) || ""}
                                                                                </span>
                                                                                <span className="browse-btn">Browse</span>
                                                                            </div>

                                                                        </Dropzone>
                                                                    </div>
                                                                    <div 
                                                                        className={`image-preview ${(newImage && newImage.file && newImage.file.preview) || (Object.keys(image)).length ? "image-preview-border" : ""}`}
                                                                    >
                                                                        {(newImage && newImage.file && newImage.file.preview) || (Object.keys(image)).length ? 
                                                                            <img
                                                                                onLoad={this.getDimensions}
                                                                                id="organization_logo" 
                                                                                src={(newImage && newImage.file && newImage.file.preview) || image} 
                                                                                alt="Error Loading Image"
                                                                            />
                                                                                : 
                                                                            <span><Glyphicon glyph="picture"/> Image Preview</span>}
                                                                    </div>
                                                                    <div 
                                                                        className="image-dimensions"
                                                                    >
                                                                        {(newImage && newImage.file && newImage.file.preview) ? imageDimension : ""}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Panel.Body>
                                                    </Panel>
                                                </PanelGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                </FieldWrap>
                            </div>
                        </div>
                    </Fragment>
                }
                <NavigationPrompt 
                    when={this.compareState()}
                    onSave={this.handleFormSubmit}
                />
                <GlobalSubmitModal
                    mode={'edit'}
                    submitModalTotal={submitModalTotal}
                    activeModal={activeModal}
                    closeModal={this.closeModal}
                    submitStatus={this.state.submitStatus}
                    showViewAll={false}
                    progressMessage={this.state.progressMessage}
                    error={this.state.modalError}
                    className={'addOrganizations'}
                    continueUrl={'/account/organization-details'}
                    viewAllLabel={'View All Organizations'}
                    viewAllUrl={"/account-management/organizations"}
                />
            </PageLayout>
        )
    }
}


const mapStateToProps = state => {
    return {
        permissions: state.authState.permissions,
        roleManager: state.roleManager.roles,
        User:state.authState.user,
        org: state.roleManager.org,
        unsavedData: state.updateUnsavedData.unsavedData
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateUnsavedData: (bool) => {
            dispatch(updateUnsavedData(bool));
        }
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(OrganizationDetails));
