import React, { Component, Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import { InputLabel } from '../../global/forms/FormElements';
import { CheckBoxGroup } from '../../global/forms/CheckBoxGroup';

export default class AmenitiesEntertainment extends Component {

    shouldComponentUpdate(nextProps) {
        return (
            nextProps.formValidation.property_type !== this.props.formValidation.property_type ||
            nextProps.formValidation.amenities !== this.props.formValidation.amenities ||
            nextProps.formValidation.petsAmenityRequired !== this.props.formValidation.petsAmenityRequired ||
            nextProps.formValidation.internetAmenityRequired !== this.props.formValidation.internetAmenityRequired ||
            nextProps.formValidation.parkingAmenityRequired !== this.props.formValidation.parkingAmenityRequired ||
            
            nextProps.amenities.amenities !== this.props.amenities.amenities ||
            nextProps.amenities.entertainment !== this.props.amenities.entertainment ||
            nextProps.data.amenities !== this.props.data.amenities
        );
    }
    
    render() {
        const {
            amenities,
            data,
            petsAllowed,
            internetIncluded,
            parkingIncluded,
            formValidation,
            
            toggleAmenities,
        } = this.props;
        
        return (
            <Fragment>
                <Row>
                    <Col md={12} sm={12} xs={12}>
                        <InputLabel
                            id="amenities"
                        >
                            Amenities
                        </InputLabel>
                    </Col>
                    <Col md={12} sm={12} xs={12}>
                        <CheckBoxGroup 
                            defaultExpanded={true} 
                            cbClick={toggleAmenities} 
                            items={amenities.amenities} 
                            values={data.amenities}
                            formValidationAmenities={formValidation.amenities}
                            formValidationPetsAmenityRequired={
                                { 
                                    validation: formValidation.petsAmenityRequired || "",
                                    petsAllowed: petsAllowed || ""
                                }
                            }
                            formValidationInternetAmenityRequired={
                                { 
                                    validation: formValidation.internetAmenityRequired || "",
                                    internetIncluded: internetIncluded || ""
                                }
                            }
                            formValidationParkingAmenityRequired={
                                { 
                                    validation: formValidation.parkingAmenityRequired || "",
                                    parkingIncluded: parkingIncluded || ""
                                }
                            }
                            name="amenities" 
                            cbClass='badge-style' 
                            label='Amenities'
                        />
                    </Col>
                    {
                        formValidation.amenities &&
                        <Col md={12} sm={12} xs={12}>
                            <span className="required-error-text">At least one Amenity is required</span>
                        </Col>
                    }
                    {
                        formValidation.petsAmenityRequired &&
                        <Col md={12} sm={12} xs={12}>
                            <span className="required-error-text">Pet Amenity must match Pet Policy</span>
                        </Col>
                    }
                    {
                        formValidation.internetAmenityRequired &&
                        <Col md={12} sm={12} xs={12}>
                            <span className="required-error-text">Internet Amenity must match Internet Policy</span>
                        </Col>
                    }														
                    {
                        formValidation.parkingAmenityRequired &&
                        <Col md={12} sm={12} xs={12}>
                            <span className="required-error-text">Parking Amenity must match Parking Policy</span>
                        </Col>
                    }
                    <Col md={12} sm={12} xs={12}>
                        <InputLabel 
                            cssClass="custom-label top-margin"
                        >
                            Entertainment
                        </InputLabel>
                    </Col>
                    <Col md={12} sm={12} xs={12}>
                        <CheckBoxGroup 
                            defaultExpanded={true} 
                            cbClick={toggleAmenities} 
                            items={amenities.entertainment} 
                            values={data.amenities}
                            name="entertainment" 
                            cbClass='badge-style' 
                            label='Entertainment'
                        />
                    </Col>
                </Row>
            </Fragment>
        )
    }
}