import React from 'react';

const Loader = (props) => (
    <div className={"loader-div" + (props.hide?' hide':'') }>
        <svg className="circular" viewBox="25 25 50 50">
            <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="4" strokeMiterlimit="10"/>
        </svg>
    </div>
)

export default Loader;
