import React from 'react';
import { Auth } from 'aws-amplify';
import Form from 'react-bootstrap/lib/Form';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Col from 'react-bootstrap/lib/Col';
import FormControl from 'react-bootstrap/lib/FormControl';
import Button from 'react-bootstrap/lib/Button';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import { checkPasswordRule } from '../../helpers/helpers';
/** Newest user login screen to set new password */
export default class NewLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSigningIn: false,
            errorMsg: '',
            password:'',
            confirmPassword: '',
            showNewPassword: false,
            showConfirmPassword: false,
            checkCapsLock: false,
            passwordRule: {
                lowercase: false,
                uppercase: false,
                specialChar: false,
                minimum: false,
                number: false,
                match: false,
            }
        }
        this.newPasswordInput;
        this.handleSubmit = this.handleSubmit.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.isValid = this.isValid.bind(this);
    }
    componentDidMount() {
        ['keydown', 'keyup', 'click'].forEach((event) => {
            window.addEventListener(event, (e) => {
                if(e && e.keyCode === 20) {
                    const capslock = e && e.getModifierState("CapsLock");
    
                    this.setState({
                        checkCapsLock: capslock
                    });
                };
            });
        });
    }
    handleSubmit(e) {
        e.preventDefault();
        this.changePassword();
    }
    /** Change password API call. */
    async changePassword() {
        this.setState({ 
            isSigningIn: true,
        });
        if(this.props.tokenId) {
            try {
                await Auth.completeNewPassword(this.props.user, this.state.password, {});
                await this.props.login(this.state.password, "newLogin");
            } catch(e) {
                this.setState({
                    isSigningIn: false,
                    errorMsg: e.message
                });
            }
        } else {
            this.setState({
                isSigningIn: false,
                errorMsg: "Missing token_id. Please use the link in the RedAwning Registration email to proceed."
            })
        };
    }
    /** Handle change of text box. */
    handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => {
            const { password, confirmPassword } = this.state;
            let passwordRule = checkPasswordRule(this.state.password, this.state.passwordRule);

            passwordRule.match = password.length && confirmPassword.length && (password === confirmPassword) ? true : false;
            
            this.setState({
                passwordRule: passwordRule
            });
        });
    }
    showPassword = (e, name) => {
        e.preventDefault();
        this.setState({
            [name]: !this.state[name]
        });
    }
    getTooltip = (id,text) => {
        return <Tooltip id={id}>{text}</Tooltip>
    }
    /** Validation of user's password  */
    isValid() {
        const { password, confirmPassword, passwordRule } = this.state;

        return (
            password !== confirmPassword ||
            password.length < 8 ||
            confirmPassword.length < 8 ||
            (Object.values(passwordRule).filter((rule) => {return rule == false}).length ? true : false)
        )
    }
    render() {
        return (
            <div className="main-container">
                <div className="page-container form-wrap">
                    <Form horizontal onSubmit={this.handleSubmit}>
                        <FormGroup>
                            <Col xs={6}>
                                <img 
                                    src='/app/assets/logo-2x.png' 
                                    alt='RedAwning' 
                                    className='img-responsive'
                                />
                            </Col>
                        </FormGroup>
                        <p 
                            className='login-welcome'>
                            Enter a New Password
                        </p>
                        <FormGroup controlId="newPassword">
                            <Col xs={12}>
                                <FormControl 
                                    className="show-password-padding"
                                    type={!this.state.showNewPassword ? "password" : "text"}
                                    name="password" 
                                    value={this.state.password} 
                                    onChange={this.handleChange}
                                    placeholder="New Password"
                                />
                                {
                                    this.state.checkCapsLock &&
                                    <OverlayTrigger 
                                        placement='bottom' 
                                        overlay={this.getTooltip("password", "Caps-lock is on")}
                                    >
                                        <span 
                                            className="show-caps-lock-on-container"
                                        >
                                            <i className="icon-caps-lock-on" />
                                        </span>
                                    </OverlayTrigger>
                                }
                                <OverlayTrigger 
                                    placement='bottom' 
                                    overlay={this.getTooltip("password", (!this.state.showNewPassword ? "Show Password": "Hide Password"))}
                                >
                                    <span 
                                        className="show-password-container"
                                        onClick={(e) => this.showPassword(e, "showNewPassword")}
                                    >
                                        <i className={!this.state.showNewPassword ? "icon-eye-open" : "icon-eye-close"} />
                                    </span>
                                </OverlayTrigger>
                            </Col>
                        </FormGroup>
                        <FormGroup controlId="confirmNewPassword">
                            <Col xs={12}>
                                <FormControl 
                                    className="show-password-padding"
                                    type={!this.state.showConfirmPassword ? "password" : "text"}
                                    name="confirmPassword" 
                                    value={this.state.confirmPassword} 
                                    onChange={this.handleChange}
                                    placeholder="Confirm New Password"
                                />
                                {
                                    this.state.checkCapsLock &&
                                    <OverlayTrigger 
                                        placement='bottom' 
                                        overlay={this.getTooltip("confirmPassword", "Caps-lock is on")}
                                    >
                                        <span 
                                            className="show-caps-lock-on-container"
                                        >
                                            <i className="icon-caps-lock-on" />
                                        </span>
                                    </OverlayTrigger>
                                }
                                <OverlayTrigger 
                                    placement='bottom' 
                                    overlay={this.getTooltip("confirmPassword", (!this.state.showConfirmPassword ? "Show Password": "Hide Password"))}
                                >
                                    <span 
                                        className="show-password-container"
                                        onClick={(e) => this.showPassword(e, "showConfirmPassword")}
                                    >
                                        <i className={!this.state.showConfirmPassword ? "icon-eye-open" : "icon-eye-close"} />
                                </span>
                                </OverlayTrigger>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col xs={12}>
                                <Button
                                    type="submit"
                                    disabled={this.isValid()}
                                >
                                    Change Password <span className={'glyphicon glyphicon-refresh'+(this.state.isSigningIn ? ' spinning':' hide')}></span>
                                </Button>
                            </Col>
                        </FormGroup>
                            <Col xs={6}>
                                <ul>
                                    <li className={this.state.passwordRule.lowercase ? "password-rule" : ""}>One lowercase character</li>
                                    <li className={this.state.passwordRule.uppercase ? "password-rule" : ""}>One uppercase character</li>
                                    <li className={this.state.passwordRule.number ? "password-rule" : ""}>One number</li>
                                </ul>              
                            </Col>
                            <Col xs={6}>
                                <ul>
                                    <li className={this.state.passwordRule.specialChar ? "password-rule" : ""}>One special character</li>
                                    <li className={this.state.passwordRule.minimum ? "password-rule" : ""}>8 characters minimum</li>
                                    <li className={this.state.passwordRule.match ? "password-rule" : ""}>Passwords must match</li>
                                </ul>              
                            </Col>
                            {
                                this.state.errorMsg &&
                                <FormGroup>
                                    <Col xs={12}>
                                        <span className={'text-center '+(this.state.errorMsg ? 'error-msg':'hide')}>{this.state.errorMsg}</span>
                                    </Col>
                                </FormGroup>
                            }
                    </Form>
                </div>
            </div>
        )
    }
}

