import React, { Fragment } from "react";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { InputField } from "../../global/forms/FormElements";
import "react-dates/initialize";
import Loader from "../../global/Loader";
import { API } from "aws-amplify";
import constants from "../../../js/constants";

/**
 * Add optional(add-on) fee to the reservation
 */
export class AddonForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showAddonForm: false,
			availableAddons: null,
			filledAddons: {}
		};
	}

	showFormToggle(show) {
		this.setState({
			showAddonForm: show
		});

		if (!show) {
			this.props.handleReservationQuote();
			this.setState({
				filledAddons: {}
			});
		}
	}

	refreshAmounts = quoteData => {
		Object.keys(this.state.filledAddons).forEach(key => {
			this.state.filledAddons[key].amount = 0;
		});
		if (quoteData.fee_details) {
			quoteData.fee_details.forEach(fee => {
				if (fee.fee_name in this.state.filledAddons) {
					this.state.filledAddons[fee.fee_name].amount = fee.amount;
				}
			});
		}
	};

	createAddonFeeRow = () => {
		const { quoteIsLoading, quoteData } = this.props;
		this.refreshAmounts(quoteData);

		return this.state.availableAddons.map((addon, i) => {
			const fee_name = addon.fee_schedule_name;
			return (
				<Fragment key={i}>
					<Row>
						<Col xs={12} sm={12} md={12}>
							<Col xs={6} sm={6} md={7} className="start-date">
								<h4>{fee_name}</h4>
							</Col>
							<Col xs={4} sm={4} md={2}>
								<div className="field-wrapper">
									<InputField
										type="number"
										name="quantity"
										className="form-field"
										value={
											this.state.filledAddons[fee_name]
												? this.state.filledAddons[fee_name].quantity
												: 0
										}
										onChange={e => this.handleAddonQuantityChange(e, fee_name)}
									/>
								</div>
							</Col>
							<Col xs={4} sm={4} md={3} className="pull-right no-pad-right">
								<div
									className={`pull-right no-pad-right ${quoteIsLoading &&
										this.state.filledAddons[fee_name] &&
										"isLoading"}`}
								>
									{quoteIsLoading && <Loader />}
									<h4>
										<span>
											${" "}
											{this.state.filledAddons[fee_name] &&
											this.state.filledAddons[fee_name].amount &&
											this.state.filledAddons[fee_name].quantity
												? this.state.filledAddons[fee_name].amount.toFixed(2)
												: "0.00"}
										</span>
									</h4>
								</div>
							</Col>
						</Col>
					</Row>
				</Fragment>
			);
		});
	};

	getAddonFees() {
		let response = API.get(
			constants.RAPAPI.NAME,
			`/properties/${this.props.propertyId}/feeschedules?required=0`,
			{ response: true, isCognito: true }
		);

		return response.then(resp => {
			if (!resp.data) {
				return [];
			}
			return resp.data.manager_fee_schedules.fee_schedules.concat(
				resp.data.property_fee_schedules.fee_schedules
			);
		});
	}

	componentDidMount() {
		if (this.props.availableAddons && this.props.availableAddons.length > 0) {
			this.setState({
				availableAddons: this.props.availableAddons
			});
		} else if (this.props.propertyId) {
			const $this = this;
			this.getAddonFees().then(function(addons) {
				if (!$this.props.isInternalPropertyAdmin) {
					// non-admin can not see other fee beside that has recipient = 'host'
					addons = addons.filter(addon => addon.recipient === "host");
				}
				$this.setState({
					availableAddons: addons
				});
			});
		}
	}

	handleAddonQuantityChange = (e, addon_name) => {
		const { filledAddons } = this.state;
		const newState = {
			filledAddons
		};
		let inputValue = e.target.value;
		inputValue = parseInt(inputValue);
		if (inputValue < 0 || isNaN(inputValue)) {
			inputValue = 0;
		}
		e.target.value = inputValue;
		newState.filledAddons[addon_name] = newState.filledAddons[addon_name] || {};
		newState.filledAddons[addon_name]["quantity"] = inputValue;

		this.setState(newState, () => {
			const addons = Object.keys(filledAddons).map(key => ({
				fee_name: key,
				quantity: parseInt(filledAddons[key].quantity)
			}));
			this.props.handleReservationQuote(
				addons.filter(addon => addon.quantity > 0)
			);
		});
	};

	render() {
		const { showAddonForm } = this.state;

		if (!this.state.availableAddons) {
			return null;
		}
		if (!showAddonForm) {
			return (
				<Col xs={12} className="center-content">
					<span
						onClick={() => this.showFormToggle(true)}
						className="clickable-text"
					>
						<i className="icon-Plus create-icon" />
						<span>Add add-on fee(s)</span>
					</span>
				</Col>
			);
		}
		return (
			<Col className="rates-summary">
				<h2 className="detail-label">
					<strong>Optional(Add-on) Fees</strong>
					<i
						className="icon-Delete pull-right"
						onClick={() => this.showFormToggle(false)}
					/>
				</h2>
				<div>
					<div className="row">
						<div className="col-md-12 col-sm-12 col-xs-12">
							<div className="start-date col-md-7 col-sm-6 col-xs-6">
								<h4>Name</h4>
							</div>
							<div className="col-md-2 col-sm-4 col-xs-4">
								<div className="field-wrapper">
									<label htmlFor="minstay" className="form-label">
										Quantity
									</label>
								</div>
							</div>
							<div className="col-md-3 col-sm-4 col-xs-4 pull-right no-pad-right">
								<div className="pull-right no-pad-right">
									<label className="form-label">Amount</label>
								</div>
							</div>
						</div>
					</div>
					{this.createAddonFeeRow()}
				</div>
			</Col>
		);
	}
}
