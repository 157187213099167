import React, { Component, Fragment } from 'react';
import { Editor } from 'slate-react';
import Html from 'slate-html-serializer'
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import Col from 'react-bootstrap/lib/Col';
import Plain from 'slate-plain-serializer'

const BLOCK_TAGS = {
  p: 'p',
}

// Add a dictionary of mark tags.
const MARK_TAGS = {
	"sub": "sub",
	"say-as": "say-as",
	"prosody": "prosody",
	"wifi_pw" : "wifi_pw",
}

const rules = [
  	// rules that handle block
  	{
		deserialize(el, next) {
		const type = BLOCK_TAGS[el.tagName.toLowerCase()]
		if (type) {
			return {
				object: 'block',
				type: type,
				data: {
					className: el.getAttribute('class'),
				},
				nodes: next(el.childNodes),
			}
		}
    	},
		serialize(obj, children) {
			if (obj.object == 'block') {
				return <p>{children} </p>
			}
		},
  	},
  	// rules that handles marks...
  	{
		deserialize(el, next) {
			const type = MARK_TAGS[el.tagName.toLowerCase()];
			
			let attributeData = undefined;
			
			if(type === "say-as") {
				attributeData = el.getAttribute('interpret-as') ? el.getAttribute('interpret-as') : "";
			} else if (type === "sub") {
				attributeData = el.getAttribute('alias') ? el.getAttribute('alias') : "";
			} else if (type === "prosody") {
				if(el.getAttribute('rate')) {
					attributeData = el.getAttribute('rate');
				} else if(el.getAttribute('volume')) {
					attributeData = el.getAttribute('volume');
				} else {
					attributeData = "";
				}
			}
			if (type) {
				return {
				object: 'mark',
				type: type,
				data: {
					attribute: attributeData,
				},
				nodes: next(el.childNodes),
				}
			}
		},
		serialize(obj, children) {
			if(
				obj.object == 'mark' && 
				obj && obj.data && obj.data.toJSON().attribute === "digits") {
					return <say-as interpret-as="digits">{children}</say-as>
			} else if(
				obj.object == 'mark' && 
				obj && obj.data && obj.data.toJSON().attribute === "characters") {
					return <say-as interpret-as="characters">{children}</say-as>
			} else if(
				obj.object == 'mark' && 
				obj && obj.data && obj.data.toJSON().attribute === "date") {
					return <say-as interpret-as="date" format="yyyymmdd">{children}</say-as>
			} else if(
				obj.object == 'mark' &&
				obj.type === "sub" &&
				obj && obj.data && obj.data.toJSON().attribute) {
					return <sub alias={obj && obj.data && obj.data.toJSON().attribute}>{children}</sub>
			} else if(
				obj.object == 'mark' &&
				obj.type === "prosody" &&
				obj && obj.data && obj.data.toJSON().attribute === "slow") {
					return <prosody rate="slow">{children}</prosody>
			} else if(
				obj.object == 'mark' &&
				obj.type === "prosody" &&
				obj && obj.data && obj.data.toJSON().attribute === "fast") {
					return <prosody rate="fast">{children}</prosody>
			} else if(
				obj.object == 'mark' &&
				obj.type === "prosody" &&
				obj && obj.data && obj.data.toJSON().attribute === "soft") {
					return <prosody volume="soft">{children}</prosody>
			} else if(
				obj.object == 'mark' &&
				obj.type === "prosody" &&
				obj && obj.data && obj.data.toJSON().attribute === "loud") {
					return <prosody volume="loud">{children}</prosody>
			} else if(
				obj.object == 'mark' && 
				obj.type === "wifi_pw") {
					return <wifi_pw>{children}</wifi_pw>
			}
		},
  	},
]

// Create a new serializer instance with our `rules` from above.
const html = new Html({ rules })

export default class SSMLRTE extends Component {
	state = {
		value: html.deserialize(this.props.value ? this.props.value : "" ),
		inputValue: "",
	};

	ref = editor => {
		this.editor = editor;
	}
	
	componentDidMount() {
		const htmlSerialize = html.serialize(this.state.value);
		const plainTextSerialize = Plain.serialize(this.state.value);
		this.props.ssmlrteOnChange(htmlSerialize, plainTextSerialize);
	}

	onChange = ({ value }) => {
		// converts value to html
		const htmlSerialize = html.serialize(value);
		// converts value to plain text
		const plainTextSerialize = Plain.serialize(value);
		// sends the html and plain value as props
		this.props.ssmlrteOnChange(htmlSerialize, plainTextSerialize);

		// console.log(htmlSerialize);
		// console.log(plainTextSerialize);
		this.setState({ value });
	}
	
	onClickMark = (e, type, data) => {
		e.preventDefault();
		// e.stopPropagation()
		this.editor.toggleMark({
			data: data,
			type: type,
		});
	}


	renderMark = (props) => {
		const { children, mark, attributes } = props
		let TYPE_DATA = mark.data.toJSON().attribute ? (mark.type === "sub" ? mark.type : mark.data.toJSON().attribute) : "";
		
		switch (TYPE_DATA ? TYPE_DATA : mark.type) {
		case 'digits':
			return <say-as interpret-as="digits" style={{ fontWeight: "bold" }}><i className="icon-digits" />{props.children}<i className="icon-digits" /></say-as>;
		case 'characters':
			return <say-as interpret-as="characters" style={{ fontWeight: "bold" }}><i className="icon-characters" />{props.children}<i className="icon-characters" /></say-as>;
		case 'date':
			return <say-as interpret-as="date" format="yyyymmdd" style={{ fontWeight: "bold" }}><i className="icon-Calender" />{props.children}<i className="icon-Calender" /></say-as>;
		case 'sub':
			return <sub alias={mark.data.toJSON().attribute} style={{ fontSize: "14px", fontWeight: "bold", position: "unset" }}><i className="icon-replace" />{props.children}<i className="icon-replace" /></sub>;
		case 'slow':
			return <span rate="slow" style={{ fontWeight: "bold"}}><i className="icon-turtle" />{props.children}<i className="icon-turtle" /></span>;
		case 'fast':
			return <span rate="fast" style={{ fontWeight: "bold"}}><i className="icon-rabbit" />{props.children}<i className="icon-rabbit" /></span>;
		case 'soft':
			return <span volume="soft" style={{ fontWeight: "bold"}}><i className="icon-soft" />{props.children}<i className="icon-soft" /></span>;
		case 'loud':
			return <span volume="loud" style={{ fontWeight: "bold"}}><i className="icon-loud" />{props.children}<i className="icon-loud" /></span>;
		case 'wifi_pw':
			return <span style={{ fontWeight: "bold"}}><i className="icon-wifi" />{props.children}<i className="icon-wifi" /></span>;
		default: {
			return;
			}
		}
	};


	hasMark = (type, data) => {
		const { value } = this.state;

		return value.activeMarks.some((mark) => {
			if(
				typeof mark && mark.data && mark.data.toJSON().attribute !== undefined && 
				mark && mark.data && mark.data.toJSON().attribute === (data && data.attribute)) {
				return true;
			} else if(
				mark.type === type && typeof mark && mark.data && mark.data.toJSON().attribute === undefined) {
				return true;
			} else {
				return false;
			}
		});
	}

	handleSubInput = (e) => {
		this.setState({ inputValue: e.target.value})
	}

	removeAllMarks = () => {
		const value = this.state.value;
			if (value.marks) {
				// remove all marks
				value.marks.forEach(mark => {
					this.editor.removeMark(mark);
				});
			}
	}

	render() {
		return (
		<Fragment>
			<Col xs={12} style={{ border: "1px solid #dedede", padding: "10px" }}>
				<Col xs={12} style={{ borderBottom: "1px solid #dedede", padding: "0px 0px 10px 0px" }}>
				<DropdownButton  
					title={'Say-as'}
					className="primary-btn white-btn"
					id="property-actions"
				>
					<MenuItem onMouseDown={(e) => this.onClickMark(e, "say-as", { "attribute": "digits"})}><i className="icon-digits"/>  Digits</MenuItem>
					<MenuItem onMouseDown={(e) => this.onClickMark(e, "say-as", { "attribute": "characters"})}><i className="icon-characters"/>  Characters </MenuItem>
					<MenuItem onMouseDown={(e) => this.onClickMark(e, "say-as", { "attribute": "date"})}><i className="icon-Calender"/>  date </MenuItem>
				</DropdownButton>
			<DropdownButton  
				title={'Prosody'}
				className="primary-btn white-btn"
				id="property-actions"
			>
				<MenuItem onMouseDown={(e) => this.onClickMark(e, "prosody", { "attribute": "slow"})}><i className="icon-turtle"/> Slow </MenuItem>
				<MenuItem onMouseDown={(e) => this.onClickMark(e, "prosody", { "attribute": "fast"})}><i className="icon-rabbit"/> Fast </MenuItem>
				<MenuItem onMouseDown={(e) => this.onClickMark(e, "prosody", { "attribute": "soft"})}><i className="icon-soft"/> Soft </MenuItem>
				<MenuItem onMouseDown={(e) => this.onClickMark(e, "prosody", { "attribute": "loud"})}><i className="icon-loud"/> Loud </MenuItem>
			</DropdownButton>
			<button
				type="button"
				onMouseDown={(e) => this.onClickMark(e, "wifi_pw")}
				style={{
					position: "relative",
					height: "33px",
					top: "2px",
					backgroundColor: "white",
					padding: "5px 10px",
					border: "1px solid #D5D5D5",
					minWidth: "108px",
				}}
			>
				<i className="icon-wifi" />  Wifi 
			</button>
			<button
					type="button"
					onMouseDown={(e) => this.onClickMark(e, 'sub', { "attribute": this.state.inputValue })}
					disabled={this.state.value.selection.isExpanded && this.state.inputValue === ''}
					style={{
						position: "relative",
						height: "33px",
						top: "2px",
						backgroundColor: "white",
						padding: "5px 10px",
						border: "1px solid #D5D5D5",
						minWidth: "125px",
					}}
				>
					<i className="icon-replace" /> Substitute
				</button>
			<Col xs={12} style={{ padding: "0px" }}>
				<input 
					onChange={this.handleSubInput}
					placeholder="Add Substitute"
					style={{
						position: "relative",
						height: "33px",
						top: "2px",
						border: "1px solid #D5D5D5",
						paddingLeft: "5px",
						width: "453px"
					}}
				/>
			</Col>
				</Col>
				<Col xs={12} style={{ padding: "5px" }}>
				<Editor
					ref={this.ref}
					value={this.state.value}
					onChange={this.onChange}
					renderMark={this.renderMark}
				/>
				<button
					type="button"
					onClick={this.removeAllMarks}
					// add disabled state when state.value is not focused.
					disabled={!this.state.value.selection.isExpanded}
					className="primary-btn left-margin"
					style={{
						margin: "0px",
						position: "relative",
						right: "16px",
						top: "51px",
					}}
					>
					Clear Tags
				</button>
				</Col>
			</Col>
		</Fragment>
		);
	}
}