import React from 'react';
import Page from './pages/Page';
import LoginPage from './pages/LoginPage';
import styles from '../styles/bundle.less';
import { compose } from 'redux';
import { API } from 'aws-amplify';
import { DateUtils } from '@aws-amplify/core';

import { updateLoginStatus, 
        updateUserPermissions, 
        updateUser,
        setRole, 
        updateOrganization, 
        allowCollections, 
        updateOrganizationTitle,
        updateOrganizationId,
        updateMobileState, 
        updateInternalPropertyAdmin } from '../js/actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import apiHandler from '../js/apiHandler';
import constants from '../js/constants';
import { Auth } from 'aws-amplify';
import Loader from './global/Loader';
import _ from "lodash";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            properties: null,
            tokenId: null,
            isLoading: false,
            permissionsResponse: '',
        }
        this.initApp = this.initApp.bind(this);
        this.toggleAuth = this.toggleAuth.bind(this);
        this.getPermissions = this.getPermissions.bind(this);
    }

    componentWillMount() {
        this.initApp();
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowResize)
        this.handleWindowResize()
    }
    
    handleWindowResize = () => {
        let mobile = window.innerWidth < 768
        this.props.updateMobileState(mobile)
    }
    clearTokenId = () => {
        this.setState({
            tokenId: null
        });
    }
    checkTokenId = async (tokenId) => {
        let status;

        try
        {
            await API.head("accapi", `/userregistration_token/${encodeURIComponent(tokenId)}`);
            status = 200;
        }
        catch(e)
        {
            status = e && e.response && e.response.status;
        };

        return status;
    }
    async initApp() {
        // search = full token id in the url including /?token_id=.
        let { search } = this.props.location;

        // tokenId = parses search value to grab the /?token_id=.
        let tokenId = this.getParameterByName('token_id', search);

        let featureFlag = sessionStorage.getItem(constants.FEATURE_FLAG) || this.getParameterByName(constants.FEATURE_FLAG);

        // if tokenId exist.
        if(tokenId) 
        {
            await Auth.signOut();

            let tokenResponse = await this.checkTokenId(tokenId);

            // Token Id valid.
            if(tokenResponse == 200)
            {
                this.checkSession(tokenId);
            }
            // Token ID invalid.
            else if(tokenResponse == 400)
            {
                this.setState({
                    tokenIdErrorMessage: "Your Token ID is invalid. Please contact Customer Support."
                });
            }
            // Token ID does not exist.
            else if(tokenResponse == 404)
            {
                this.setState({
                    tokenIdErrorMessage: "Your Token ID doesn't exist. Please contact Customer Support."
                });
            };
        } 
        else 
        {
            this.checkSession();
        }
        if(featureFlag && featureFlag.toLowerCase() === constants.FEATURE_COLLECTIONS) 
        {
            this.props.allowCollections(true);
            sessionStorage.setItem(constants.FEATURE_FLAG, constants.FEATURE_COLLECTIONS)
        };
    }

    /** Fetch authenticated user if already logged-in **/
    async checkSession(tokenId) {
        this.setState({
            tokenId,
            isLoading:true,
        });

        let user;
        try {
            user = await apiHandler.getAuthenticatedUser();
            await this.toggleAuth(true);
        }
        catch(err) {
            this.toggleAuth(false);
        };

        this.setState({
            isLoading:false
        });
    }

    async toggleAuth(val) {
        this.setState({ 
            tokenIdErrorMessage: ""
        });

        if(val) {
            await this.getPermissions();

            if(!_.get(this.props.roleManager, 'roles.length')) {
                val = false
            };
        } 
        else {
            await Auth.signOut();
        };

        this.props.updateLoginStatus(val);
    }

    /** Fetch user's role and his account_type **/
    async getPermissions() {
        try {
            let user = await apiHandler.getAuthenticatedUser();
        
            if(user && user.signInUserSession && user.signInUserSession.clockDrift !== 0) {
                DateUtils.setClockOffset(user.signInUserSession.clockDrift * 1000 * -1);
            }; 

            const getUserResponse = await apiHandler.get(constants.ACCAPI.CURRENT_USER, { apiName: constants.ACCAPI.NAME });

            const { data } = getUserResponse;

            if(data && data.organization_roles.length) {
                // Filter "ranative" and "rawebsite" from org tree.
                let filteredOrgRoleTree = [];
                if(_.get(data, 'organization_role_tree.length')) {
                    filteredOrgRoleTree = data.organization_role_tree.filter(function (item) {
                        if(!item.organization_name.includes('ranative') && !item.organization_name.includes('rawebsite')) {
                            return item;
                        };
                    });

                    if(filteredOrgRoleTree.length) {
                        data.organization_role_tree = filteredOrgRoleTree;
                    };
                };
                
                let filteredExpandedOrgRoleTree = [];
                if(_.get(data, 'expanded_organization_roles.length')) {
                    filteredExpandedOrgRoleTree = data.expanded_organization_roles.filter(function (item) {
                        if(!item.organization_name.includes('ranative') && !item.organization_name.includes('rawebsite')) {
                            return item;
                        };
                    });
                    if(filteredExpandedOrgRoleTree.length) {
                        data.expanded_organization_roles = filteredExpandedOrgRoleTree;
                    };
                };

                // Do not auto select TPRO agency organization on load of page.
                const excludeRoleList = [
                    'TPAgencyAgent',
                    'TPAgencyAdmin',
                    'TPInternalAdmin',
                    'TPAgencyClient'
                ];
                let initOrg = {};
                let viableOrg = [];
                for (let i = 0; i < data.expanded_organization_roles.length; i++) {
                    const hasExcludedRole = _.get(data, `expanded_organization_roles[${i}].roles`, []).some((role) => excludeRoleList.includes(role));
                    const isOnlyGuest = _.get(data, `expanded_organization_roles[${i}].roles`, []).length === 1 && _.get(data, `expanded_organization_roles[${i}].roles[0]`) === 'Guest';

                    // Check if session storage org exist in expanded_organization_roles.
                    if((sessionStorage.getItem('org') === _.get(data, `expanded_organization_roles[${i}].organization_name`, '') && !hasExcludedRole && !isOnlyGuest)) {
                        initOrg = data.expanded_organization_roles[i];
                        break; 
                    };

                    if(!hasExcludedRole && !isOnlyGuest) {
                        viableOrg.push(data.expanded_organization_roles[i]);
                    };
                };

                // Set initOrg to the first viable organization if the session storage organization does not exist.
                if(_.isEmpty(initOrg)) {
                    if(viableOrg.length) {
                        initOrg = viableOrg[0];
                    }
                    else {
                        initOrg = data.organization_roles[0];
                    };
                };

                sessionStorage.setItem('org', initOrg.organization_name);
                sessionStorage.setItem('orgTitle', initOrg.title); 
                sessionStorage.setItem('orgId', initOrg.organization_id); 

                // Check if the user has internal account admin role.
                let internalAdminRole = [];
                data.organization_roles.map((org) => {
                    org.roles.map((roles) => {
                        if(roles === 'InternalAccountAdmin') {
                            internalAdminRole.push(org);
                        };
                    });
                });

                let allUserRoles = Object.values(constants.USER_TYPES).filter((role) => {
                    return role;
                });
                this.props.updateInternalPropertyAdmin(internalAdminRole.length ? true : false);

                let permissionResp = [];
                try {
                    permissionResp = await API.get("accapi", `/currentuser/${initOrg.organization_name}/permissions`);
                }
                catch(e) {
                    console.log(e);
                };

                this.props.updateUser(data);
                this.props.updateUserPermissions(_.get(permissionResp, 'permissions') || []);
                this.props.setRole(internalAdminRole.length ? allUserRoles: initOrg.roles);
                this.props.updateOrganization(initOrg.organization_name);
                this.props.updateOrganizationTitle(initOrg.title);
                this.props.updateOrganizationId(initOrg.organization_id);
            }
            else {
                this.props.setRole([]);
                if (getUserResponse) {
                    this.setState({
                        permissionsResponse: 'ACCAPI ' + String(getUserResponse)
                    });
                }
            }
        }
        catch(e) {
            this.props.setRole([]);
            this.setState({
                permissionsResponse: 'PERM EXP: '+ String(e)
            });
        }       
    }

    getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    render() {
        const {
            isLoading,
            tokenIdErrorMessage,
            permissionsResponse,
            tokenId,
        } = this.state;

        if(isLoading) 
        {
            return <Loader />
        }
        if(this.props.isLoggedIn) 
        {
            return (
                <Page onLogout={this.toggleAuth}/>
            )
        } 
        else 
        {
            return (
                <LoginPage
                    tokenId={tokenId}
                    tokenIdErrorMessage={tokenIdErrorMessage}
                    permissionsResponse={permissionsResponse}
                    history={this.props.history}
                    clearTokenId={this.clearTokenId}
                    onLogin={this.toggleAuth}
                    getPermissions={this.getPermissions}
                />
            )
        };
    }
}

const mapStateToProps = state => {
    return {
        authState: state.authState,
        isLoggedIn: state.authState ? state.authState.isLoggedIn : false,
        roleManager: state.roleManager,
        mobileMode: state.updateMobileState.mobileMode,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateLoginStatus: (isLoggedIn) => {
            dispatch(updateLoginStatus(isLoggedIn));
        },
        updateInternalPropertyAdmin: (boolean) => {
            dispatch(updateInternalPropertyAdmin(boolean));
        },
        updateUserPermissions: (perms) => {
            dispatch(updateUserPermissions(perms));
        },
        updateUser: (user) => {
            dispatch(updateUser(user));
        },
        setRole: (role) => {
            dispatch(setRole(role));
        },
        updateOrganization: (org) => {
            dispatch(updateOrganization(org))
        },
        updateMobileState: (mobileMode) => {
            dispatch(updateMobileState(mobileMode))
        },
        allowCollections: (enable) => {
            dispatch(allowCollections(enable))
        },
        updateOrganizationTitle: (org) => {
            dispatch(updateOrganizationTitle(org))
        },
        updateOrganizationId: (orgId) => {
            dispatch(updateOrganizationId(orgId))
        }
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(App));
