import React, {	Component } from 'react';
import styles from '../../styles/maps.less';

import AuxComp from '../hoc/AuxComp';

class GoogleMap extends Component {
	constructor(props) {
		super(props);

		this.defaultMapOptions = {
			center: { lat: 32.705901, lng: 74.873033 },
			zoom: 13,
			mapTypeId: 'roadmap'
		}

		this.state={
			zoom: 13,
			place_formatted: '',
			place_id: '',
			place_location: '',
			show: false,
			mapOptions: this.defaultMapOptions,
			baseLocation: null,
			prevLocation: null
		}
		this.createMap = this.createMap.bind(this);
		this.createMarker = this.createMarker.bind(this);
		this.bindEvents = this.bindEvents.bind(this);
		this.mapEvents = this.mapEvents.bind(this);
		this.autoComplete = this.autoComplete.bind(this);
		this.setMarkerPosition = this.setMarkerPosition.bind(this);
	}
	/**
	 * For create a map. 
	 * @returns object of map
	 */
	createMap() {
		let mapOptions, map, mapId,_this=this;
		
		if(this.props.mapOptions) {
			mapOptions = Object.assign(this.defaultMapOptions, this.props.mapOptions);
		} else {
			mapOptions = this.defaultMapOptions;
		}

		mapId = this.props.mapId || 'map';

		map = new window.google.maps.Map(document.getElementById(mapId), mapOptions);
		map.addListener('zoom_changed',()=>{
			_this.mapEvents('zoom_changed', map.getZoom())
		})
		this.setState({
			baseLocation: this.props.baseLocation || map.getCenter()
		});
		return map;
	}

	/**
	 * For create marker on map.
	 * @param {*} map object of map
	 * @param {*} coords object of coordinates with lng, lat.
	 * @returns object of marker. 
	 */
	createMarker(map, coords) {
		let _this = this, circle = null;
		coords = coords || map.getCenter();
		let marker = new window.google.maps.Marker({
			map: map,
			position: coords,
			draggable: true
		});
		if (this.props.baseLocation) {
			circle = new google.maps.Circle({
				map: map,
				center: { lat: +this.props.baseLocation.lat, lng: +this.props.baseLocation.lng },
				radius: 160934,    // 100 miles in metres
				fillColor: '#fff',
				strokeColor: '#00ff00',
				strokeOpacity: 0.8,
				strokeWeight: 1
			});
		}
		
		let location = {
			lat: coords && coords.lat(),
			lng: coords && coords.lng()
		}

		_this.setState({
			place_location: location
		});

		marker.addListener('dragend', () => {
			var latlng = marker.getPosition();
			let location = _this.state.prevLocation || _this.state.baseLocation;
			location.lat = location.lat
			location.lng = location.lng 
			let containsMarker = true;
			var bounds = null;
			if(circle) {
				try {
					bounds = circle.getBounds();
					containsMarker = bounds.contains(latlng) && google.maps.geometry.spherical.computeDistanceBetween(circle.getCenter(), latlng) <= circle.getRadius();
				} catch(e) {
					console.log('Error while checking if marker is in range: ',e);
				}
			}
			if(containsMarker) {
				location = {
					lat: latlng.lat(),
					lng: latlng.lng()
				}
			}
			_this.setState({
				place_location: location,
				prevLocation: location
			});
			marker.setPosition(location)
			_this.mapEvents('dragend',location)	
		});
		return marker;
	}

	/**
	 * Auto complete of entered manual address on property forms.
	 * @param {*} map map object
	 * @param {*} marker marker object
	 */
	autoComplete(map, marker) {
		let _this = this;
		let autoCompleteInputId = this.props.autoCompleteInput || 'pac-input';
		let inputNode = document.getElementById(autoCompleteInputId);
		// let cityNode = document.getElementById(this.props.autoCompleteCity);
		// let provinceNode = document.getElementById(this.props.autoCompleteProvince);

		let autoComplete = new window.google.maps.places.Autocomplete(inputNode);
		autoComplete.addListener('place_changed', () => {
			let place = autoComplete.getPlace();
			_this.setMarkerPosition(map,marker,place);
			_this.mapEvents('place_changed',place);
		});

		// let autoCompleteCity = new window.google.maps.places.Autocomplete(cityNode,{
		// 	types: ['(cities)']
		// })
		// autoCompleteCity.addListener('place_changed', () => {
		// 	let place = autoCompleteCity.getPlace();
		// 	if(this.props.address.street_address=="") {
		// 		_this.setMarkerPosition(map,marker,place);
		// 	}
		// 	_this.mapEvents('city_changed',place);
		// });

		// let autoCompleteProvince = new window.google.maps.places.Autocomplete(provinceNode)
		// autoCompleteProvince.addListener('place_changed', () => {
		// 	let place = autoCompleteProvince.getPlace();
		// 	if(this.props.address.city=="") {
		// 		_this.setMarkerPosition(map,marker,place);
		// 	}
		// 	_this.mapEvents('province_changed',place);
		// });
	}

	/**
	 * Set marker position on map.
	 * @param {*} map map object
	 * @param {*} marker marker object
	 * @param {*} place place object. 
	 */
	setMarkerPosition(map,marker,place) {
		let location = place.geometry.location;
		this.setState({
			place_formatted: place.formatted_address,
			place_id: place.place_id,
			place_location: location.toString()
		});
		map.fitBounds(place.geometry.viewport);
		map.setCenter(location);
		if(marker) {
			marker.setPlace({
				placeId: place.place_id,
				location: location,
			});
		}
	}

	/**
	 * bind events with listeners and invoke mapEvents function.
	 * @param {*} map object of map.
	 */
	bindEvents(map){
		let _this = this;
		map.addListener('zoom_changed', () => {
			this.setState({
				zoom: map.getZoom()
			});
			_this.mapEvents('zoom_changed', map.getZoom())
		});

		map.addListener('maptypeid_changed', () => {
			this.setState({
				mapType: map.getMapTypeId()
			});
			_this.mapEvents('maptypeid_changed', map.getMapTypeId())
		});
	}

	/**
	 * Pass data to parent when detect any map event.
	 */
	mapEvents(res,data) {
		this.props.mapEvents(res,data);
	}

	/**
	 * Initialization function.
	 */
	init() {
		let _this = this;
		let map = this.createMap();
		this.bindEvents(map);
		let marker = this.createMarker(map);
		// Auto Complete Functionality
		if(this.props.autoCompleteInput) {
			this.autoComplete(map, marker);
		}
	}

	componentDidMount() {
		this.init();
	}

	render() {
		return(
			<AuxComp>
				<div id={this.props.mapId}></div>
			</AuxComp>
		);
	}
};

export default GoogleMap;
