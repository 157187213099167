import React from 'react';
import { SideNav } from '../global/SideNav';
import { Main } from '../Main';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

/** Page will render side navigation menu and react router component {Main.js} */
class Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: true
        }
    }

    componentWillMount() {
        const email = (this.props.user && this.props.user.contact_info && this.props.user.contact_info.email) || "";

        if(email) {
            SnapEngage.setUserEmail(email);
        }; 
    }

    render() {
        return (
            <div className="page-container">
                <SideNav 
                    user={this.props.user} 
                    onLogout={this.props.onLogout} 
                />
                <Main 
                    role={this.props.roleManager} 
                    mobileMode={this.props.mobileMode} 
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        roleManager: state.roleManager,
        user: state.authState.user,
        mobileMode: state.updateMobileState.mobileMode,
    }
}

export default withRouter(connect(
    mapStateToProps
)(Page));