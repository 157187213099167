import React, { Component } from 'react';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Modal from 'react-bootstrap/lib/Modal';
import { withRouter } from 'react-router';

class VideoModal extends Component {

    render() {

        return (
            <Modal 
                backdrop='static' 
                className='video-modal'
                show={this.props.modal === 'video-modal'} 
                onHide={this.props.closeModal}
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12} sm={12} className="flex-align-center">
                            <iframe
                                src={this.props.url}
                                width="853" 
                                height="505"
                                frameBorder="0"
                                allowFullScreen
                            >
                            </iframe>                                      
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        )
    }
}

export default withRouter(VideoModal);