import React, { Component, Fragment } from 'react';
import { API } from 'aws-amplify';
import Link from 'react-router-dom/Link';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import PanelGroup from 'react-bootstrap/lib/PanelGroup';
import Panel from 'react-bootstrap/lib/Panel';
import Modal from 'react-bootstrap/lib/Modal';

import ReactTableComponent from '../global/ReactTableComponent';
import PageLayout from '../global/PageLayout';
import { Title } from '../global/Title';
import constants from '../../js/constants';
import { InputField, FieldWrap, PrimaryButton } from '../global/forms/FormElements';

class FeesSchedule extends Component {
    reactTable = null;
    FEE_TYPE = [];
    org = this.props.roleManager.org || "";
    orgTitle= this.props.roleManager.orgTitle || "";
    isAdmin = this.props.roleManager.roles.indexOf(constants.USER_TYPES.PROPERTY_MANAGER_ADMIN)> -1 || false;
    breadcrumbItems = [{
        title: "Fees Schedule",
    }];
    state = {
        isLoading: false,
        keyword: "",
        pageSize: 10,
        activePage: 1,
        activeModal: "",
        activeFee: {},
        feesData: [],
        filteredFees: [],
        sorted: {
            id: "fee_schedule_name",
            desc: false,
        },
        columns: []
    }

    updateColumns = () => {
        let columns = [
            {
                'Header':'Fee Name',
                'accessor': 'fee_schedule_name',
                'Cell': row=>(
                    <div>
                        <strong>
                            {row && row.original && row.original.fee_schedule_name}
                        </strong>
                    </div>
                )
            },
            {
                'Header':'Fee Type',
                'accessor': 'fee_type',
                'Cell': row=> {
                    let feeType = "";

                    this.FEE_TYPE.map((fee) => {
                        if(fee.value == (row && row.original && row.original.fee_type)) {
                            feeType = fee.title;
                        }
                    });
                    return (
                        <div>
                            {feeType}
                        </div>
                    );
                }
            },
            {
                'Header':'Fee Applies',
                'accessor': 'applies',
                'Cell': row=>{
                    const applies = (row && row.original && row.original.applies);
                    const feeApplies = constants.FEE_APPLIES_TYPE.filter((app) => {
                        return app.value === applies;
                    });

                    return (  
                        <div>
                            <span>{feeApplies && feeApplies[0] && feeApplies[0].label}</span>
                        </div>
                    )
                    
                }
            },
            {
                'Header':'Fee Criteria',
                'accessor': 'criterion',
                'Cell': row=>{
                    const criterion = (row && row.original && row.original.criterion);

                    return criterion.map((criteria, i) => {
                        const criteriaName = constants.CRITERIA_NAME.filter((name) => {
                            return name.value === criteria.criteria_name;
                        });
                        const criteriaOperator = constants.CRITERIA_OPERATOR.filter((op) => {
                            return op.value === criteria.operator;
                        });
                        return (
                            <Fragment key={i}>
                                <div className='fs-list-view-criteria'>
                                    <span><strong>{criteriaName && criteriaName[0] && criteriaName[0].label} {criteria.criteria_name === 'charge_name' ? (criteriaOperator && criteriaOperator[0] && criteriaOperator[0].label) : ''}</strong> </span>
                                    {
                                        criteria.criteria_name !== 'charge_name' ?
                                        <span>{`${criteriaOperator && criteriaOperator[0] && criteriaOperator[0].label} ${criteria.criteria_name !== 'charge_name' ? criteria.values : ''}`}</span> :
                                        ''
                                    }
                                    {
                                        (criteria.values.length > 0 && criteria.criteria_name === 'charge_name') &&
                                        <ul>
                                            {
                                                criteria.values.split(',').map((value, x) => {
                                                    return (
                                                        <li key={x}>
                                                            {value}
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    }
                                </div>
                                <span className='hr'/>
                            </Fragment>
                        )
                    });
                    
                }
            },
            {
                'Header':'Fee Value',
                'accessor': 'value',
                'Cell': row=>{
                    const VALUE_TYPE = [
                        {value: "amount", label: "$"},
                        {value: "percent", label: "%"},
                    ];
                    const original = (row && row.original);
                    const valueType = VALUE_TYPE.filter((type) => {
                        return type.value === original.value_type;
                    });
                    
                    return(
                    <div>
                        {`${(valueType && valueType[0] && valueType[0].value) === 'amount' ? valueType[0].label : ''}${original.value}${(valueType && valueType[0] && valueType[0].value) === 'percent' ? valueType[0].label : ''}`}
                    </div>
                )}
            },
            {
                'Header':'Required',
                'accessor': 'required',
                'Cell': row=>{
                    return(
                    <div>
                        {row && row.original && row.original.required ? 'Yes' : 'No'}
                    </div>
                )}
            },
            {
                'Header':'Status',
                'accessor': 'active',
                'Cell': row=>{
                    return(
                    <div>
                        {
                            (row && row.original && row.original.active) == true ? 
                            <strong className='text-success'>Live</strong> : 
                            <strong className='text-danger'>Not Live</strong>
                        }
                    </div>
                )}
            },
            {
                'Header':'Actions',
                'width': 150,
                'sortable': false,
                'Cell': row=>{
                    return (
                        <div>
                            <OverlayTrigger 
                                placement='bottom'
                                overlay={this.getTooltip('edit',`${this.isAdmin ? 'Update' : 'View'} Fee`)}
                            >
                                <Link 
                                    to={`/fees/global/${row && row.original && row.original.fee_schedule_id}`} 
                                    className='properties__options'>
                                        <i className='icon-edit'/>
                                </Link>
                            </OverlayTrigger>
                            {
                                this.isAdmin &&
                                <OverlayTrigger 
                                    placement='bottom' 
                                    overlay={this.getTooltip('edit',`Delete Fee`)}>
                                        <span 
                                            className='properties__options pointer'
                                            onClick={e => this.openModal(e, "delete-fee-modal", (row && row.original))}
                                        >
                                            <i className='icon icon-Delete'/>
                                        </span>
                                </OverlayTrigger>
                            }
                        </div>
                    )
                }
            }
        ];

        return columns;
    }
    
    async componentWillMount() {
        this.setState({
            isLoading: true
        });

        await this.getFees();

        this.setState({
            isLoading: false,
        });
    }

    getFeesMetaData = async () => {
        let data = {};
        try {
            data = await API.get("rapapi", "/feeschedules/enums");
            if(data && data.fee_type) {
                Object.entries(data.fee_type).forEach((feeType) => {
                    this.FEE_TYPE.push({ name: "fee_type", value: feeType[0], title: feeType[1]})
                });
            };
        }
        catch(e) {
            this.setState({
                error: true,
                isLoading: false,
            });
        }
    }

    getFees = async () => {
        let data = [];
        
        try {
            data = await API.get("rapapi", `/organizations/${this.org}/feeschedules`);

            if(data && data.manager_fee_schedules && data.manager_fee_schedules.fee_schedules && data.manager_fee_schedules.fee_schedules.length) {
                let managerFeesCopy = [...data.manager_fee_schedules.fee_schedules];

				if(!this.props.isInternalPropertyAdmin) {
                    managerFeesCopy = managerFeesCopy.filter((x) => {
                        return x.recipient !== 'redawning';
                    });
				};

                this.setState({
                    feesData: managerFeesCopy,
                    columns: this.updateColumns()
                });

                await this.getFeesMetaData();
            }
        }
        catch(e) {
            console.log(e, 'error getting fees')
        }
    }

    deleteFeeHandler = async (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true,
            activeModal: "",
        });

        try {
            await API.del("rapapi", `/organizations/${this.org}/feeschedules/${this.state.activeFee.fee_schedule_id}`);

            this.getFees();

            this.setState({
                isLoading: false,
            });
        }
        catch(e) {
            console.log(e, "error deleting fee");

            this.setState({
                activeModal: "delete-error-modal",
                isLoading: false,
            });
        };
    }

    closeModal = () => {
        this.setState({
            activeModal: "",
        });
    }

    openModal = (e, modalName, feeData) => {
        e.stopPropagation();
        this.setState({
            activeModal: modalName,
            activeFee: feeData,
        });
    }

    pageSizeOnChange = (pageSize) => {
        this.setState({
            activePage: 1,
            pageSize: parseInt(pageSize),
        });
    }

    pageOnChange = (page) => {
        this.setState({
            activePage: parseInt(page)
        })
    }

    filterFees = (e) => {
        const value = e.target.value;
        let filteredFees = [...this.state.filteredFees];

        filteredFees.splice(0, 1, 
            {...this.state.filteredFees[0], id: "fee_schedule_name", value: value.toString().toLowerCase() });

        this.setState({
            filteredFees: filteredFees,
            keyword: value,
            activePage: 1,
        });
    }

    getTooltip = (id,text) => {
        return <Tooltip id={id} className='properties__tooltip'>{text}</Tooltip>
    }

    clearSearch = () => {
        this.setState({
            keyword: "",
            filteredFees: {}
        });
    }

    getRef = (r) => {
        this.reactTable = r;
    }

    render() {
        const { 
            keyword,
            feesData,
            columns,
            pageSize,
            activePage,
            filteredFees,
            isLoading,
            sorted,
            activeModal,
            activeFee,
        } = this.state;

        return (
            <PageLayout isCentered={true}>
            <Title 
                title={`${this.orgTitle} Fees`}
                isCentered={true}
                breadcrumbItems={this.breadcrumbItems}
            >
                <div className='search-inp'>
                    <InputField 
                        type='searchbox'
                        placeholder='Search' 
                        name='keyword' 
                        value={keyword} 
                        onChange={this.filterFees}
                        clearSearch={this.clearSearch} 
                    />
                </div>
                {
                    this.props.isInternalPropertyAdmin && 
                    <Link 
                        className='primary-btn' 
                        to='/fees/global/create'
                    >
                        <i className='icon-Plus create-icon' />
                        Create Global Fee
                    </Link>
                }
            </Title>
            <div id='content' className='fees'>
                <div className="container-fluid property-item">
                    <FieldWrap>
                        <Row>
                            <Col md={12} xs={12} sm={12} lg={12}>
                                <PanelGroup 
                                    accordion id="accordion-controlled-example" 
                                    defaultActiveKey="1"
                                >
                                    <Panel eventKey='1'>
                                        <Panel.Heading>
                                            <Panel.Title> 
                                                Global Fees
                                            </Panel.Title>
                                        </Panel.Heading>
                                        <Panel.Body collapsible>
                                            <div className="fs-table-component-cntr">
                                                <br/>
                                                <Row>
                                                    <Col md={12} xs={12} sm={12} lg={12}>
                                                        <ReactTableComponent
                                                            className='-highlight'
                                                            getRef={this.getRef}
                                                            reactTable={this.reactTable}
                                                            minRows={0}
                                                            resizable={false}
                                                            showPagination={true}
                                                            data={feesData}
                                                            columns={columns}
                                                            pageSize={pageSize}
                                                            activePage={activePage}
                                                            defaultFiltered={filteredFees}
                                                            loading={isLoading}
                                                            itemType={'Fees'}
                                                            noDataText={isLoading ? "Loading..." : "No Global Fees found."}
                                                            defaultFilterMethod={(filter, row, column) => {
                                                                return filter.value.length ? 
                                                                String(row.fee_schedule_name.toLowerCase()).startsWith(filter.value) || 
                                                                String(row.fee_type.toLowerCase()).startsWith(filter.value) : 
                                                                true
                                                            }}
                                                            defaultSorted={[sorted]}
                                                            pageSizeOnChange={this.pageSizeOnChange}
                                                            pageOnChange={this.pageOnChange}
                                                            getTrProps = {(state, rowInfo, column) => {
                                                                return {
                                                                    onClick: (e, handleOriginal) => {
                                                                        this.props.history.push(`/fees/global/${rowInfo.original.fee_schedule_id}`);
                                                                    },
                                                                    style: {
                                                                        cursor: 'pointer'
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Panel.Body>
                                    </Panel>
                                </PanelGroup>
                            </Col>
                        </Row>
                    </FieldWrap>
                </div>
            </div>
            <Modal
                className="submit-modal"
                show={activeModal === "delete-fee-modal"} 
                onHide={this.closeModal}
            >
                <Modal.Body>
                    <Row className="text-center">
                        <Col md={12}>
                            <h1>Are you sure you want to delete</h1>
                            <h1>"{activeFee.fee_schedule_name}"?</h1>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col md={6}>
                            <PrimaryButton 
                                cssClass="pull-left white-btn col-md-12 " 
                                fullWidth={true} 
                                type="button" 
                                onClick={this.closeModal}
                            >
                                Cancel
                            </PrimaryButton>
                        </Col>
                        <Col md={6}>
                            <PrimaryButton 
                                cssClass="pull-right col-md-12 " 
                                fullWidth={true} 
                                type="button" 
                                onClick={e =>this.deleteFeeHandler(e)}
                            >
                                Delete
                            </PrimaryButton>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <Modal
                className="submit-modal"
                show={activeModal === "delete-error-modal"} 
                onHide={this.closeModal}
            >
                <Modal.Body>
                    <Row className="fs-del-modal">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <div>
                                    <span className='status-icon status-icon--error'></span>
                                    <span className="submit-heading">Error Deleting Fee.</span>
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                    </Row>
                </Modal.Body>
            </Modal>
        </PageLayout>)
    }
}

const mapStateToProps = state => {
    return {
        isInternalPropertyAdmin: state.roleManager.isInternalPropertyAdmin,
        roleManager: state.roleManager
    }
}

export default withRouter(connect(
    mapStateToProps,
    null
)(FeesSchedule));
