import { combineReducers } from 'redux'
import { UPDATE_LOGIN } from '../actions'
import authState from './authState'
import roleManager from './roleManager'
import orgModal from './orgModal'
import updateMobileState from './updateMobileState';
import updateUnsavedData from './updateUnsavedData';

const commonBus = combineReducers({
  authState,
  roleManager,
  orgModal,
  updateMobileState,
  updateUnsavedData
})

export default commonBus
