import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import Constants from '../../../js/constants';

import pet from '../../../assets/icons/pet.svg';
import pool from '../../../assets/icons/pool.svg';
import ski from '../../../assets/icons/ski.svg';
import wifi from '../../../assets/icons/wifi.svg';
import bedroomsImg from '../../../assets/icons/bedrooms.svg'
import bathtubImg from '../../../assets/icons/bathtub.svg'
import sleepsImg from '../../../assets/icons/sleeps.svg'
import Beach from '../../../assets/icons/Beach.svg';
import HotTub from '../../../assets/icons/HotTub.svg';
import Linens from '../../../assets/icons/Linens.svg';
import Parking from '../../../assets/icons/Parking.svg';
import air_conditioner from '../../../assets/icons/air_conditioner.svg';
import no_smoking from '../../../assets/icons/no_smoking.svg';
import constants from '../../../js/constants';

let Features = {
    'Beach': Beach,
    'HotTub': HotTub,
    'Linens': Linens,
    'Parking': Parking,
    'air_conditioner': air_conditioner,
    'no_smoking': no_smoking,
    'pet': pet,
    'pool': pool,
    'ski': ski,
    'wifi': wifi
}

export default class PropertyRoomInfo extends Component {
    /** Calculate check in check out time
    * @param {number} time current time millisecond
    */
    calculateTime = (time) => {
        let H, M;
        H = time / 3600;
        M = (time % 3600) / 60;
        if (H >= 12) {
            if(Math.floor(H) != 12) {
                H = (H - 12);
            }
            return Math.floor(H) + ':' + (M === 0 ? '00' : M) + ' PM';
        } else {
            if(Math.floor(H) == 0) {
                H = 12;
            }
            return Math.floor(H) + ':' + (M === 0 ? '00' : M) + ' AM';
        }
    }

    /** Get amenities and show amenities of current room type. */
    getAmenities = () => {
        let important = Constants.PROPERTY_VIEW.AMENITIES.important;
        let usedIcon = [];
        let ame = [];
        let amenities = this.props.propertyData.content.amenities;

        important.forEach((obj, i) => {
            if (amenities.indexOf(obj.value) > -1 && ame.length < 6 && usedIcon.indexOf(obj.icon) === -1) {
                usedIcon.push(obj.icon)
                ame.push(
                    <Col md={4} key={i}>
                        <div className="feature-box clearfix">
                            <img src={Features[obj.icon]} className="svg-img" />
                            <span className="feature-text">{obj.label}</span>
                        </div>
                    </Col>
                );
            }
        });
        return ame;
    }

    render() {
        const {
            view,
            propertyData,
        } = this.props;
        const photosUrl = _.get(propertyData, 'photos[0].url') || '';
        const location = {
            street_address: _.get(propertyData, (view === 'property' ? 'content.location.address.street_address' : 'street_address')) || '',
            street_address2: _.get(propertyData, (view === 'property' ? 'content.location.address.street_address2' : 'street_address2')) || '',
            city: _.get(propertyData, (view === 'property' ? 'content.location.address.city' : 'city')) || '',
            province: _.get(propertyData, (view === 'property' ? 'content.location.address.province' : 'province')) || '',
            country: _.get(propertyData, (view === 'property' ? 'content.location.address.country': 'country')) || '',
            postal_code: _.get(propertyData, (view === 'property' ? 'content.location.address.postal_code' : 'postal_code')) || '',
        };
        const bedrooms = _.get(propertyData, 'content.bedrooms') || '';
        const bathrooms = _.get(propertyData, 'content.bathrooms') || '';
        const sleeps = _.get(propertyData, 'content.sleep_max') || '';
        const cico = {
            check_in_start: _.get(propertyData, (view === 'property' ? 'cico_times.check_in_start' : 'check_in_start_time')) || 0,
            check_out: _.get(propertyData, (view === 'property' ? 'cico_times.check_out' : 'check_out_time')) || 0
        };

        let stateProvince = [];
        stateProvince = constants.STATES_PROVINCES.filter((value) => {
            return (
                value.code == location.province ||
                value.abbrev == location.province
            );
        });

        return (
            <Row>
                <div id="content" className="property-room-info">
                    <Col md={12}>
                        <div className={`property-info ${view == 'property' ? 'property-view' : ''}`}>
                            <Col md={2} className="img-wrapper">
                                <img
                                    src={photosUrl}
                                    className="property-img pull-left"
                                />
                            </Col>
                            <Col md={view == 'property' ? 5 : 10} sm={10} xs={10} className="property-details">
                                <div className="property-address">
                                    <i className="icon-map_locator_icon"></i>
                                    {
                                        `${location.street_address || ''}
                                        ${location.street_address2 || ''}
                                        ${location.city || ''}
                                        ${stateProvince.length ? (stateProvince[0].name + ", ") : (location.province || '')}
                                        ${location.country || ''}
                                        ${location.postal_code || ''}`
                                    }
                                </div>
                                <div className="cico-container">
                                    <div className='cico-time'>
                                        <i className="icon-Calender"></i>
                                        Check-In Time: {view == 'property' ? this.calculateTime(cico.check_in_start) : cico.check_in_start}
                                    </div>
                                    <div className='cico-time'>
                                        <i className="icon-Calender"></i>
                                        Check-Out Time: {view == 'property' ? this.calculateTime(cico.check_out) : cico.check_out}
                                    </div>
                                </div>
                                <div className="property-features-container">
                                    <Col md={4} className='property-features'>
                                        <div className='feature-img'>
                                            <img src={bedroomsImg}/>
                                        </div>
                                        <div className='feature-text'>
                                            <div>{bedrooms}</div>
                                            <div>Bedrooms</div>
                                        </div>
                                    </Col>
                                    <Col md={4} className='property-features'>
                                        <div className='feature-img'>
                                            <img src={bathtubImg}/>
                                        </div>
                                        <div className='feature-text'>
                                            <div>{bathrooms}</div>
                                            <div>Bathrooms</div>
                                        </div>
                                    </Col>
                                    <Col md={4} className='property-features'>
                                        <div className='feature-img'>
                                            <img src={sleepsImg}/>
                                        </div>
                                        <div className='feature-text'>
                                            <div>{sleeps}</div>
                                            <div>Max sleeps</div>
                                        </div>
                                    </Col>
                                </div>
                                {
                                    this.getAmenities() &&
                                    <div className='feature-container'>
                                        {this.getAmenities()}
                                    </div>
                                }
                            </Col>

                        </div>
                    </Col>
                </div>
            </Row>
        )
    }
}