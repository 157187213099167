import React, { Component, Fragment } from 'react';
import { API } from 'aws-amplify';
import Link from 'react-router-dom/Link';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import TableComponent from '../global/TableComponent';
import apiHandler from '../../js/apiHandler';
import { KebabStyleDropdown } from '../hoc/KebabStyleDropdown';
import PageLayout from '../global/PageLayout';
import { Title } from '../global/Title';
import { InputField } from '../global/forms/FormElements';
import { ValidationModal } from '../content/properties/ValidationModal';
import constants from '../../js/constants';
import { tableSort, getSessionId } from '../../helpers/helpers';

class Properties extends Component {
    breadcrumbItems = [
        { title: "Properties" }
    ];
    org = this.props.roleManager.org;
    admin = this.props.roleManager.roles.indexOf(constants.USER_TYPES.PROPERTY_MANAGER_ADMIN)> -1;
    isSmartConciergeViewer = this.props.roleManager.roles.indexOf(constants.USER_TYPES.SMART_CONCIERGE_VIEWER)> -1;
    isSmartConciergeAdmin = this.props.roleManager.roles.indexOf(constants.USER_TYPES.SMART_CONCIERGE_ADMIN)> -1;
    tid = getSessionId();
    orgsString = "";
    state = {
        propertyList: [],
        isLoading: true,
        pageSize: 10,
        orgsTreeData: {},
        showOrgSubs: true,
        totalPropCount: 0,
        activePage: 0,
        keyword: '',
        sorted: {
            id: "property_id",
            desc: true,
        },
        showValidations: false,
        selectedProperty: null,
        validationChannels: [],
        mobileColumns: [
        {
            Header:() => tableSort("ID"),
            'accessor': 'property_id',
            'minWidth': 50,
            'Cell': row=>(
                <div className='propertyId'>
                    {row.value}
                </div>
            )
        },
        {
            Header:() => tableSort("Ref ID"),
            'accessor': 'external_property_id ',
            'minWidth': 60,
            'Cell': row=>(
                <div className='referenceId'>
                    {
                        row && 
                        row.original && 
                        row.original.external_property_id && 
                        row.original.external_property_id.length > 24 ? 
                        row.original.external_property_id.substring(0, 23) + "..." : 
                        row.original.external_property_id
                    }
                </div>
            )
        },
        {
            Header:() => tableSort("Property Name"),
            'accessor': 'title',
            'minWidth': 100,
            'Cell': row=> (
                row.original.content && row.original.content.title
            )
        },

        {
            Header:() => tableSort("Status"),
            'accessor': 'status',
            'minWidth': 50,
            'Cell': row=> (
                <div>
                    {row.value.published? <p className='text-success'>Live</p> : <div className="status not-published"/>}
                </div>
            )
        },
        {
            'minWidth': 5,
            'sortable': false,
            'Cell': row=> {
                let data = row.original, 
                status = data.status.published;

                return (
                    <Fragment>
                        <div className="kebab-menu small-kebab-menu">
                            {[1,2,3].map(el => (
                                    <div key={el} className="kebab-item" />
                                )
                            )}
                        </div>
                        <KebabStyleDropdown>
                            <DropdownButton
                                title='Mobile-Actions'
                                className="primary-btn secondary-btn"
                                onClick={e => {e.stopPropagation()}}
                                id="property-actions"
                            >   
                                <MenuItem>
                                    <Link 
                                        onClick={(e)=>{e.stopPropagation()}} 
                                        to={{pathname:'/calendar/' + data.property_id + '/edit'}}
                                    >
                                        <i className='icon-Calender' />
                                    </Link>
                                </MenuItem>
                                <MenuItem>
                                    <a
                                        onClick={(e)=>{e.stopPropagation()}} 
                                        target='_blank' 
                                        href={'http://www.redawning.com/node/' + data.property_id} 
                                        className={status ? '' : 'properties__options--disabled'}
                                    >
                                        <i className='icon-property_details' />
                                    </a>
                                </MenuItem>
                                <MenuItem
                                    onClick={e=>{e.stopPropagation(); this.showValidationModal(e, data)}} 
                                >
                                    <img
                                        style={{margin: '-5px 0px 0px'}} 
                                        className='validation-icon' 
                                        src='/app/assets/icons/validation_error.svg'
                                    />
                                </MenuItem>
                                {(this.isSmartConciergeViewer || this.isSmartConciergeAdmin) &&
                                    <MenuItem>
                                        <Link
                                            onClick={(e)=> {e.stopPropagation();}} 
                                            to={`/smart-concierge/${row.original.repconfig.property_collection_id ? `collections/${row.original.repconfig.property_collection_id}/` : ''}properties/${row.original.property_id}`}
                                        >
                                            <i className="icon-concierge"/>
                                        </Link>
                                    </MenuItem>
                                }
                            </DropdownButton>
                        </KebabStyleDropdown>
                    </Fragment>
                )
            }
        }],
        columns: []
    }

    updateColumns = () => {
        let orgName = this.state.orgsTreeData && this.state.orgsTreeData[0] && this.state.orgsTreeData[0].child_organizations && this.state.orgsTreeData[0].child_organizations.length && this.state.showOrgSubs ? {
            'Header':'Organization',
            'accessor': 'organization_name',
            'sortable': false,
            'width': 170,
            'Cell': row=>(
                <div className='organization_name'>
                    <strong>{row.value}</strong>
                </div>
            )
        } : {
                'width': 0
            }

        return [
            {
                Header:() => tableSort("Property ID"),
                'accessor': 'property_id',
                'width': 120,
                'Cell': row=>(
                    <div className='propertyId'>
                        <strong>{row.value}</strong>
                    </div>
                )
            },
            {
                Header:() => tableSort("Reference ID"),
                'accessor': 'external_property_id ',
                'width': 140,
                'Cell': row=>(
                    <div className='referenceId'>
                        <OverlayTrigger 
                            placement='bottom' 
                            overlay={
                                <Tooltip 
                                    className="org-modal__tooltip" 
                                    id="external_property_id"
                                >
                                    {row.original.external_property_id}
                                </Tooltip>}>
                            <span>
                                <strong>
                                    {row && row.original && row.original.external_property_id && row.original.external_property_id.length > 24 ? 
                                        row.original.external_property_id.substring(0, 23) + "..." : 
                                        row.original.external_property_id}
                                </strong>
                            </span>
                        </OverlayTrigger>
                    </div>
                )
            },
            {
                'Header':'',
                'accessor': 'repconfig',
                'width': 60,
                'sortable': false,
                'Cell': row=>(
                    <div>
                        {row.original.level === 'rep'? <i style={{fontSize: 20}}className="icon-Properties_icon"/> : null}
                    </div>
                )
            },
            orgName,
            {
                Header:() => tableSort("Property Details"),
                'accessor': 'title',
                'minWidth': 200,
                'Cell': row=>{
                    let location = row.original.content.location.address,
                        address='';
                    if(location) {
                        let stateProvince = [];
                        stateProvince = constants.STATES_PROVINCES.filter((value) => {
                            return (
                                value.code == location['province'] ||
                                value.abbrev == location['province']
                            );
                        });
                        address = `${location['street_address']}
                                    ${location['street_address2']}
                                    ${location['city']}
                                    ${stateProvince.length ? stateProvince[0].name : location['province']}
                                    ${location['postal_code']}`
                    }
                    return(
                        <div>
                            <strong>
                                {(!row.original || !row.original.content || !row.original.content.title || !row.original.content.title.length) ? '-' : row.original.content.title}
                            </strong> {address.trim() == '' ? '' : `- ${address}` }
                        </div>
                    )
                }
            },
            {
                Header:() => tableSort("Status"),
                'accessor': 'status',
                'width': 120,
                'Cell': row=>(
                    <div>
                        {row.value.published? <strong className='text-success'>Live</strong>:<strong className='text-danger'>Not Live</strong>}
                    </div>
                )
            },
            {
                'Header':'Actions',
                'width': 260,
                'sortable': false,
                'Cell': row=>{
                    let data = row.original,
                        status = data.status.published,
                        editLink = {
                            pathname: 
                                (
                                    (data && 
                                    data.level === 'rep' && 
                                    data && data.repconfig.collection_name) || 
                                    (data && data.level === 'key' && 
                                    data && data.repconfig.collection_name)
                                ) ? 
                                (`collections/${data && data.repconfig && data.repconfig.property_collection_id}/rooms/${data.property_id}/edit`) : 
                                ('/properties/'+data.property_id+'/edit'),
                        },
                        href = `https://${APIConfig.ENV == 'DEV' ? 'stage.' : ''}redawning.com/node/${data.property_id}`;

                    return (
                        <div>
                            <OverlayTrigger 
                                placement='bottom' 
                                overlay={this.getTooltip('edit',`${this.admin? "Update" : "View"} ${data.level === 'rep' ? 'Room Type' :'Property'}`)}>
                                    <Link 
                                        onClick={e => e.stopPropagation()} 
                                        to={editLink} 
                                        className='properties__options'>
                                            <i className='icon-edit' />
                                    </Link>
                            </OverlayTrigger>
                            <OverlayTrigger 
                                placement='bottom' 
                                overlay={this.getTooltip('pricing','View Calendar')}>
                                    <Link 
                                        onClick={(e)=>{e.stopPropagation()}} 
                                        to={{pathname:'/calendar/' + data.property_id + '/edit'}} 
                                        className='properties__options'>
                                            <i className='icon-Calender' />
                                    </Link>
                            </OverlayTrigger>
                            <OverlayTrigger 
                                placement='bottom' 
                                overlay={this.getTooltip('preview','Preview')}>
                                    <a 
                                        onClick={(e)=>{e.stopPropagation()}} 
                                        target='_blank' 
                                        href={href} 
                                        className={`properties__options ${status ?'' : 'properties__options--disabled'}`}>
                                            <i className='icon-property_details' />
                                    </a>
                            </OverlayTrigger>
                            <OverlayTrigger 
                                placement='bottom' 
                                overlay={this.getTooltip('validation','View Validations')}>
                                    <span 
                                        onClick={e=>{
                                            e.stopPropagation();
                                            this.showValidationModal(e, data)}} 
                                        className='properties__options'>
                                            <img 
                                                className='validation-icon' 
                                                src='/app/assets/icons/validation_error.svg'/>
                                    </span>
                            </OverlayTrigger>
                            {(this.isSmartConciergeViewer || this.isSmartConciergeAdmin) &&
                                <OverlayTrigger 
                                    placement='bottom' 
                                    overlay={this.getTooltip('smart-concierge', 'Smart Concierge')}
                                >
                                    <Link 
                                        onClick={(e)=> {e.stopPropagation();}} 
                                        to={`/smart-concierge/${row.original.repconfig.property_collection_id ? `collections/${row.original.repconfig.property_collection_id}/` : ''}properties/${row.original.property_id}`}
                                    >
                                        <i className="icon-concierge"/>
                                    </Link>
                                </OverlayTrigger>
                            }
                        </div>
                    )
                }
            }
        ]
    }

    componentDidMount() {
        const params = JSON.parse(sessionStorage.getItem('properties-listing'));

        if(params) {
            const { 
                pageSize,
                keyword,
                sorted,
                showOrgSubs,
                activePage,
            } = this.state;

            this.setState({
                pageSize: params.pageSize || pageSize,
                keyword: params.keyword || keyword,
                sorted: params.sorted || sorted,
                showOrgSubs: params.showOrgSubs || showOrgSubs
            }, async () => {
                await this.fetchProperties(params.activePage != 0 ? (params.activePage - 1) : activePage);
            });
        }
        else {
            this.fetchProperties(0);
        };
    }

    getOrgsTreeData = async () => {
        const { 
            keyword,
            showOrgSubs
        } = this.state;
        let parentOrg = encodeURIComponent(this.org);
        let orgs = [];

        try {
            let resp = await API.get("accapi", `/organizationtree?parent=${parentOrg}`);
            if(resp) {
                this.setState({
                    orgsTreeData: resp
                }, () => {
                    const { 
                        orgsTreeData
                    } = this.state;
                    let orgsString = '';
                    let orgsTreeParent = orgsTreeData && orgsTreeData[0];
                    let searchKeyword = keyword;
                    let parentOrgName = encodeURIComponent(orgsTreeParent && orgsTreeParent.organization_name);
            
                    orgs = [parentOrgName];
            
                    function walk(node) {
                        let children = node.child_organizations;
                        // Children are siblings to each other
                        if (children && children.length) {
                            for (let i = 0; i < children.length; i++) { 
                                if (children && children[i] && children[i].organization_name) {
                                    let child = children[i];
                                    let childOrgName = encodeURIComponent(child.organization_name);
            
                                    if (searchKeyword) {
                                        if (String(searchKeyword).toLowerCase() === String(childOrgName).toLowerCase()) {
                                            orgs = [childOrgName];
                                            return;
                                        } 
                                        else if (String(searchKeyword).toLowerCase() === String(child.title).toLowerCase()) {
                                            orgs = [childOrgName];
                                            return;
                                        } 
                                        else if (String(searchKeyword).toLowerCase() === String(child.manager_uid).toLowerCase()) {
                                            orgs = [childOrgName];
                                            return;
                                        } 
                                        else {
                                            orgs.push(childOrgName);
                                        };
                                    }
                                    else {
                                        orgs.push(childOrgName);
                                    };
                                    walk(child);
                                };
                            };
                        };
                    };
            
                    if (showOrgSubs && 
                        orgsTreeParent && 
                        orgsTreeParent.child_organizations && 
                        orgsTreeParent.child_organizations.length && 
                        parentOrg !== '_global_' && 
                        parentOrg !== 'travelagencies' && 
                        parentOrg !== 'propertymanagers' && 
                        parentOrg !== 'propertyowners'
                    ) {
                        walk(orgsTreeParent);
                    };
    
                    if (orgs.length == 1) {
                        orgsString = `&organization=${orgs[0]}`;
                    } 
                    else {
                        orgsString = `&organizations=${orgs.join()}`;
                    };
            
                    this.orgsString = orgsString;
                });
            };
        }
        catch(err) {
        };
    }

    fetchProperties = async (active_page, sort, page_size) => {
        const { 
            pageSize,
            keyword,
            sorted,
        } = this.state;
        const newPageSize = page_size ? page_size : pageSize;
        let newActivePage = ((active_page === 0) ? 0 : active_page);
        const offset = newPageSize * newActivePage;
        const newSorted = sort ? sort : sorted;
        const sortDesc = newSorted.desc ? "-" : "+";
        const filter = '&fl=property_id,status,level,content,repconfig,organization_name,external_property_id';

        this.setState({ 
            isLoading: true
        });

        await this.getOrgsTreeData();

        let searchQuery = '';
        if (keyword) {
            searchQuery = `&keyword=${encodeURIComponent(keyword)}`;
        };

        try {
            let resp = await API.get("rapapi", `/properties?limit=${encodeURIComponent(pageSize)}&offset=${encodeURIComponent(offset)}&tid=${encodeURIComponent(this.tid)}&sort=${encodeURIComponent(sorted.id)}${encodeURIComponent(sortDesc)}${searchQuery}${filter}${this.orgsString}`, { response: true, isCognito: true });

            this.setState({
                activePage: (newActivePage + 1),
                pageSize: pageSize,
                sorted: {
                    ...sorted,
                    id: newSorted.id,
                    desc: newSorted.desc,
                },
                propertyList: resp.data,
                totalPropCount: resp.headers["x-total-count"],
                isLoading: false,
            },  () => {
                this.setState({
                    columns: this.updateColumns()
                });
                this.setSessionStorage();
            });
        }
        catch (e) {
            console.log(e);
            this.setState({
                isLoading: false
            });
        };
    }

    setSessionStorage = () => {
        const { 
            pageSize,
            keyword,
            sorted,
            showOrgSubs,
            activePage
        } = this.state;

        const pagination = {
            pageSize: pageSize,
            keyword: keyword,
            sorted: sorted,
            showOrgSubs: showOrgSubs,
            activePage: activePage,
        };

        sessionStorage.setItem('properties-listing', JSON.stringify(pagination));
    }

    handleShowOrgSubs = () => {
        const { showOrgSubs} = this.state;
        this.setState({ 
            showOrgSubs: !showOrgSubs
        }, () => {
            this.fetchProperties(0);
        });
    }

    handlePageSizeChange = (newPageSize) => {
        const { pageSize } = this.state;

        if(!isNaN(newPageSize) && newPageSize.trim() !== "" && newPageSize !== pageSize) {
            this.setState({
                pageSize: newPageSize
            }, () => {
                this.fetchProperties(0, '', newPageSize);
            });
        };
    }

    handlePageChange = (page) => {
        this.fetchProperties(page);
    }

    onSortedChange = (newSorted) => {
        this.fetchProperties(0, newSorted[0]);
    }

    handleSearch = (e) => {
        e.preventDefault();
        this.fetchProperties(0);
    }

    handleSearchChange = (e) => {
        const name = e.target.name;
        const val = e.target.value;

        this.setState({
            [name] : val
        });
    }

    handleClearSearch = () => {
        this.setState({
            keyword: ''
        }, () => {
            this.fetchProperties(0);
        });
    }

    getTooltip = (id,text) => {
        return <Tooltip id={id} className='properties__tooltip'>{text}</Tooltip>
    }

    /**
     * Show validation modal of property.
     * @param {object} e element of button. 
     * @param {object} data element pass data of properties. 
     */
    showValidationModal = (e,data) => {
        e.preventDefault();
        let propId = data.property_id,
            property = this.state.selectedProperty;
        if(!property) {
            property={}
        }
        if(propId != property.property_id) {
            this.setState({
                isLoading: true
            })
            apiHandler.get(`/properties/${propId}/validations`,{iscognito:true}).then(validations=>{
                if(validations.data) {
                    const defaultChannels = constants.CHANNELS;
                    let redAwningFatalError = false;
                    let channels = {};

                    validations.data.forEach(item => {
                        if(item.channel === "redawning" && item.severity_property === "fatal") {
                            redAwningFatalError = true;
                        };
                        if(defaultChannels[item.channel]) {
                            let channelName = defaultChannels[item.channel];
            
                            if(channels[channelName] && item.severity_property === "fatal") {
                                channels[channelName].push(item);
                            } 
                            else if(item.severity_property === "fatal") {
                                channels[channelName] = new Array(item)
                            };
                        };
                    });

                    this.setState({
                        redAwningFatalError: redAwningFatalError,
                        showValidations: true,
                        selectedProperty: data,
                        validationChannels: channels,
                        isLoading: false
                    })
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            })
        } else {
            this.setState({
                showValidations: true
            })
        }
    }

    closeValidations = () => {
        this.setState({
            showValidations: false
        });
    }

    render() {
        const { 
            orgsTreeData,
            showOrgSubs,
            keyword,
            propertyList,
            pageSize,
            isLoading,
            mobileColumns,
            columns,
            totalPropCount,
            total,
            sorted,
            showValidations,
            selectedProperty,
            validationChannels,
            redAwningFatalError,
            activePage,
        } = this.state;

        return (
            <PageLayout 
                isCentered={true} 
                plClass={this.props.mobileMode ? 'main-container properties-page-container' : ''}
            >
                <Title 
                    title='Properties' 
                    isCentered={true} 
                    breadcrumbItems={this.breadcrumbItems}
                >
                    {
                        orgsTreeData && 
                        orgsTreeData[0] && 
                        orgsTreeData[0].child_organizations && 
                        orgsTreeData[0].child_organizations.length && 
                        orgsTreeData[0].organization_name !== '_global_' && 
                        orgsTreeData[0].organization_name !== 'travelagencies' && 
                        orgsTreeData[0].organization_name !== 'propertymanagers' && 
                        orgsTreeData[0].organization_name !== 'propertyowners' ? 
                        <div className="filters-wrap">
                            <InputField 
                                type="checkbox" 
                                value={showOrgSubs} 
                                cbChange={this.handleShowOrgSubs}>
                                All Organizations
                            </InputField>
                        </div> : 
                        ''
                    }
                    <div className={`${this.props.mobileMode ? 'mobile-' : ''}search-wrapper`}>
                        <form 
                            className='search-inp' 
                            onSubmit={this.handleSearch}
                        >
                            <InputField 
                                type='searchbox' 
                                placeholder='Search' 
                                name='keyword' 
                                value={keyword} 
                                onChange={this.handleSearchChange}
                                clearSearch={this.handleClearSearch} 
                            />
                        </form>
                    </div>
                    {
                        (this.admin && !this.props.mobileMode) &&
                        <Link className='primary-btn' to='/properties/create'>
                            <i className='icon-Plus create-icon' />
                            Create Property
                        </Link>
                    }
                </Title>
                <div id='content' className='properties'>
                    <Grid 
                        fluid={true}
                        className={this.props.mobileMode ? 'mobile-col' : ''}
                    >
                        <Row>
                            <Col xs={12}>
                                <TableComponent className='-highlight'
                                    customMaxPageSize={100}
                                    resizable={false}
                                    minRows={0}
                                    pageSize={parseInt(pageSize)}
                                    loading={isLoading}
                                    data={propertyList}
                                    columns={this.props.mobileMode ? mobileColumns : columns}
                                    noDataText={isLoading ? "Loading..." : "No properties found. Please create a property."}
                                    totalItemsCount={parseInt(totalPropCount)}
                                    showPagination
                                    activePage={
                                        activePage !== 0 ? 
                                        activePage : 1}
                                    onPageSizeChange={this.handlePageSizeChange}
                                    handlePageChange={this.handlePageChange}
                                    itemType={total == 1 ? "Property" : "Properties"}
                                    manual
                                    defaultSortDesc={false}
                                    defaultSorted={[
                                        sorted
                                    ]}
                                    getTrProps = {(state, rowInfo, column) => {
                                        const data = rowInfo.original,
                                            editLink = {
                                                pathname: 
                                                    (
                                                        (data && 
                                                        data.level === 'rep' && 
                                                        data && data.repconfig.collection_name) || 
                                                        (data && data.level === 'key' && 
                                                        data && data.repconfig.collection_name)
                                                    ) ? 
                                                    (`collections/${data && data.repconfig && data.repconfig.property_collection_id}/rooms/${data.property_id}/edit`) : 
                                                    ('/properties/'+data.property_id+'/edit'),
                                            };
                                        return {
                                            onClick: (e, handleOriginal) => {
                                                this.props.history.push(editLink);
                                            },
                                            style: {
                                                cursor: 'pointer'
                                            }
                                        }
                                    }}
                                    mobileMode={this.props.mobileMode}
                                    onSortedChange={this.onSortedChange}
                                >
                                </TableComponent>
                                {
                                    (this.props.mobileMode && !isLoading) &&
                                   <Link 
                                        className='primary-btn mobile-create-btn'
                                        to='/properties/create'
                                        style={propertyList.length === 0 ? {top: '0px'} : {}}
                                    >
                                        <i 
                                            className='icon-Plus create-icon'
                                        />
                                        Create
                                    </Link>
                                }
                                {
                                    (selectedProperty != null) && 
                                    <ValidationModal 
                                        history={this.props.history} 
                                        show={showValidations} 
                                        property={selectedProperty} 
                                        onHide={this.closeValidations} 
                                        validationChannels={validationChannels}
                                        redAwningFatalError={redAwningFatalError}
                                    />
                                }
                            </Col>
                        </Row>
                    </Grid>
                </div>
            </PageLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        roleManager: state.roleManager,
        mobileMode: state.updateMobileState.mobileMode,
    }
}

export default withRouter(connect(
    mapStateToProps,
    null
)(Properties));