import React, { Component, Fragment } from 'react';
import { API } from 'aws-amplify';
import axios from 'axios';
import { delay } from '../../js/actions';
import Link from 'react-router-dom/Link';
import moment from 'moment';
import Dropzone from 'react-dropzone';
import Tabs from 'react-bootstrap/lib/Tabs';
import Tab from 'react-bootstrap/lib/Tab';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import Loader from '../global/Loader';
import DateRangePicker from 'react-dates/lib/components/DateRangePicker';
import _ from "lodash";
import Picky from 'react-picky';
import 'react-picky/dist/picky.css';

import PageLayout from '../global/PageLayout';
import { Title } from '../global/Title';
import { InputLabel, FieldWrap, InputField, PrimaryButton } from '../global/forms/FormElements';
import ReactTableComponent from '../global/ReactTableComponent';
import { 
    tableSort, 
    getSessionId,
    getOrgData,
    suggestReplyBtn
} from '../../helpers/helpers';
import { updateOrganization, updateOrganizationTitle, updateOrganizationId, setRole } from '../../js/actions/index';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Modal from 'react-bootstrap/lib/Modal';

import DashboardEarnings from './DashboardEarnings';
import DashboardRevenue from './DashboardRevenue';
import constants from '../../js/constants';

import Pdf from '../../assets/icons/icon-pdf.png';
import File from '../../assets/icons/icon-file.png';
import ImageFile from '../../assets/icons/icon-image-file.png';
import Docs from '../../assets/icons/icon-docs.png';
import Sheets from '../../assets/icons/icon-sheets.png'
import CommunicationManagement from './CommunicationManagement';
import AdjustmentsPayouts from './AdjustmentsPayouts';
import Reviews from './Reviews';
import PropertyRoomInfo from '../content/properties/PropertyRoomInfo';

class Dashboard extends Component {
    baseUrl = `${window.location.hostname}`;
    view = 'db';
    pingInterval = null;
    reservationsTable = null;
    threadsTable = null;
    org = this.props.org || "";
    isAdmin = this.props.roles.indexOf(constants.USER_TYPES.PROPERTY_MANAGER_ADMIN) > -1;
    viewReviews = false;
    viewMessages = false;
    viewSubscriptions = false;
    viewTemplates = false;
    viewPayments = false;
    allOrganizations = [];
    breadcrumbItems = [
        { title: "Dashboard" }
    ];
    tidReservations = "";
    tidProperties = "";
    tidThreads = "";
    markStatusOptions = [
        {label: 'In Progress', value: 'in_progress'},
        {label: 'Complete', value: 'complete'},
    ];
    markPriorityOptions = [
        {label: 'Critical', value: 'critical'},
        {label: 'High', value: 'high'},
        {label: 'Medium', value: 'medium'},
        {label: 'Low', value: 'low'},
    ];
    last30Days = function() {
        let last30Days = {};

        for(let i=0;i<=31;i++) {
            let day = moment().subtract(i,'days').format('YYYY-MM-DD');
            last30Days[day]={
                x: 31-i+1,
                y: 0
            }
        };
        return last30Days;
    };
    initState = {
        commCenterAccess: false,
        reservationsIsLoading: false,
        earningsIsLoading: false,
        revenueIsLoading: false,
        threadIsLoading: false,
        messageIsLoading: false,
        propertiesIsLoading: false,
        showAllOrgReservation: true,
        showAllOrgEarnings: true,
        showAllOrgRevenue: true,
        showAllOrgThread: true,
        showOnlyUnread: false,
        markAsUnread: false,
        markStatus: [],
        markPriority: [],
        pageSize: 5,
        propertiesData: [],
        totalRevenue: {},
        modalData: {},
        organizationData: {},
        dashboardTabs: 'dashboard',
        reservationsData: [],
        reservationsActivePage: 1,
        reservationsTotalCount: 0,
        threadDates: {
            startDate: null,
            endDate: null,
        },
        allThreads: [],
        threadData: [],
        threadPageSize: 5,
        threadActivePage: 1,
        threadMessage: "",
        threadKeyword: "",
        threadSorted: {
            id: "priority_update_dt",
            desc: false
        },
        priorityFilters: {
            critical: true,
            high: true,
            medium: true,
            low: false,
        },
        statusFilters: {
            new: true,
            complete: true,
            in_progress: true,
            responded: true,
            reopened: true,
        },
        replyTo: '',
        attachments: [],
        tooltip: null,
        modal: '',
        pendingReviews: '',
        last30Days: this.last30Days(),
        graphData: Object.keys(this.last30Days()).map(date=>this.last30Days()[date]),
        containerHeight: 0,
        suggestedReplies: [],
        suggestedRepliesCurrentIndex: 0,
        isSuggestedReplyLoading: false,
        reservationCommData: {},
        propertyCommData: {},
        editHostNotes: false,
        hostNotesErr: '',
        hostNotes: '',
        guestVerificationModalIsLoading: false,
        guestVerificationError: '',
        guestVerificationModal: false,
        errorMsg: '',
    };
    state = {
        ...this.initState,
        reservations_columns: [
            {
                Header: 'Reference ID',
                accessor: 'external_property_id',
                sortable: false,
                'Cell': row=>{
                    const referenceID = _.get(row, 'original.external_property_id');

                    return(
                        <div>
                            <strong>{referenceID}</strong>
                        </div>
                    )
                }
            },
            {
                Header: 'Reservation ID',
                accessor: 'reservation_id',
                sortable: false,
                'Cell': row=>{
                    const { allThreads } = this.state;
                    let msgCount = 0;
                    const reservationId = _.get(row, 'original.reservation_id');
                    const reservationThread = allThreads.filter((thread) => {
                        return thread.internal_id == reservationId;
                    });
                    
                    if(reservationThread.length) {
                        reservationThread.map((thread) => {
                            if(thread.messages.length) {
                                thread.messages.map((msg) => {
                                    if(msg.status == 'new') {
                                        msgCount = msgCount + 1;
                                    }
                                });
                            };
                        });
                    };

                    const numberMsg = (
                        <OverlayTrigger 
                            placement='top' 
                            overlay={this.getTooltip('newMessage', `${msgCount} New Message`)} 
                        >
                            <div className="msg-icon">
                                <span className="msg-count">{msgCount > 10 ? '9+' : msgCount}</span>
                            </div>
                        </OverlayTrigger>
                    );

                    return(
                        <div>
                            <Link 
                                className="clickable-text" 
                                to={`/reservations/${reservationId}/details`}
                            >
                                {reservationId}
                            </Link>
                            {(msgCount > 0) && numberMsg}
                        </div>
                    )
                }
            },
            {
                Header: 'Property ID',
                accessor: 'property_id',
                sortable: false,
                'Cell': row=>{
                    const propertyId = _.get(row, 'original.property_id');

                    return(
                        <div>
                            {
                                propertyId &&
                                <Link 
                                    className="clickable-text" 
                                    to={`/properties/${propertyId}/edit`}
                                >
                                    {propertyId}
                                </Link>
                            }
                        </div>
                    )
                }
            },
            {
                Header: 'Arrival Date',
                accessor: 'checkin_date',
                sortable: false,
                'Cell': row=>{
                    const checkin_date = _.get(row, 'original.checkin_date');

                    return(
                        <div>
                            <strong>{checkin_date}</strong>
                        </div>
                    )
                }
            },
            {
                Header: 'Departure Date',
                accessor: 'checkout_date',
                sortable: false,
                'Cell': row=>{
                    const checkout_date = _.get(row, 'original.checkout_date');

                    return(
                        <div>
                            <strong>{checkout_date}</strong>
                        </div>
                    )
                }
            },
        ],
        threadColumns: [
            {
                Header:() => tableSort("Last Message"),
                accessor: 'msg_dt',
                sortable: true,
                width: 200,
                'Cell': row => {
                    const messages = _.get(row, 'original.messages');
                    const subject = _.get(row, 'original.subject');

                    if(messages.length) {
                        const createdDate = _.get(messages[messages.length - 1], 'created_dt');
                        return(
                            <OverlayTrigger 
                                placement='top' 
                                overlay={subject ? this.getTooltip('subject', subject): null} 
                            >
                                <div>
                                    {createdDate && moment(createdDate).format('llll')}
                                </div>
                            </OverlayTrigger>
                        )
                    }
                    else {
                        <div></div>
                    };
                }
            },
            {
                sortable: false,
                maxWidth: 75,
                'Cell': row=>{
                    const messages = _.get(row, 'original.messages');
                    const newMessage = messages.filter((msg) => msg.status == 'new');
                    const numberMsg = (
                        <OverlayTrigger 
                            placement='top' 
                            overlay={this.getTooltip('newMessage', `${newMessage.length} New Message`)} 
                        >
                            <div className="msg-icon">
                                <span className="msg-count">{newMessage.length > 10 ? '9+' : newMessage.length}</span>
                            </div>
                        </OverlayTrigger>
                    );

                    return(
                        <div>
                            <div>
                                {(newMessage.length > 0) && numberMsg}
                            </div>
                        </div>
                    )
                }
            },
            {
                Header:() => tableSort("Priority"),
                accessor: 'priority_update_dt',
                sortable: true,
                width: 100,
                'Cell': row=>{
                    const priority = _.get(row, 'original.priority', '');
                    const capitalizePriority = priority.length ? `${priority.charAt(0).toUpperCase()}${priority.slice(1)}` : '';
                    const priorityIcons = {
                        critical: 'icon-critical',
                        high: 'icon-triangle',
                        medium: 'icon-circle',
                        low: 'icon-upside-down-triangle'
                    };

                    return(
                        <div>
                            {
                                priority.length &&
                                <OverlayTrigger 
                                    placement='top' 
                                    overlay={this.getTooltip('priority', `${capitalizePriority} Priorty`)} 
                                >
                                    <div className={`thread-msg-priority-icon ${priority ? priority : ''}`}>
                                        <i className={priorityIcons[priority]}></i>
                                    </div>
                                </OverlayTrigger>
                            }
                        </div>
                    )
                }
            },
            {
                Header:() => tableSort("Status"),
                accessor: 'status',
                sortable: true,
                width: 100,
                'Cell': row=>{
                    const status = _.get(row, 'original.status');

                    return(
                        <div className='capitalize'>
                            {status}
                        </div>
                    )
                }
            },
            {
                Header:() => tableSort("Guest"),
                accessor: 'guest_name',
                sortable: true,
                minWidth: 100,
                'Cell': row=>{
                    let displayName = '';
                    const guest_name = _.get(row, 'original.guest_name');
                    const messages = _.get(row, 'original.messages');
                    const internalIdType = _.get(row, 'original.internal_id_type');

                    if(!guest_name && messages.length) {
                        const filterGuest = messages.filter((msg) => _.get(msg, 'context.origin_context') == 'guest');

                        displayName = filterGuest.length ? filterGuest[0].posted_by_subscriber_display_name : '';
                    }
                    else {
                        displayName = guest_name;
                    };

                    return(
                        <div>
                            {internalIdType == 'nid' ? 'Inquiry: ' : ''}{displayName}
                        </div>
                    )
                }
            },
            {
                Header:() => tableSort("Arrival Date"),
                accessor: 'checkin_date',
                sortable: true,
                width: 120,
                'Cell': row=>{
                    const checkin_date = moment.utc(_.get(row, 'original.checkin_date'));
                    
                    return(
                        <div>
                            {moment(checkin_date).isValid() ?  moment(checkin_date).format('YYYY-MM-DD') : ''}
                        </div>
                    )
                }
            },
            {
                Header:() => tableSort("Departure Date"),
                accessor: 'checkout_date',
                sortable: true,
                width: 140,
                'Cell': row=>{
                    const checkout_date = moment.utc(_.get(row, 'original.checkout_date'));

                    return(
                        <div>
                            {moment(checkout_date).isValid() ?  moment(checkout_date).format('YYYY-MM-DD') : ''}
                        </div>
                    )
                }
            },
            {
                Header:() => tableSort("Property"),
                accessor: 'guest_name',
                sortable: true,
                minWidth: 110,
                'Cell': row=>{
                    const property_title = _.get(row, 'original.property_title');

                    return(
                        <div>
                            <OverlayTrigger
                                placement='top'
                                overlay={
                                    <Tooltip id='property_title'>
                                        {property_title}
                                    </Tooltip>
                                }
                            >
                                <span>
                                    {property_title}
                                </span>
                            </OverlayTrigger>
                        </div>
                    )
                }
            },
            {
                Header:() => tableSort("Reference ID"),
                accessor: 'external_property_id',
                sortable: true,
                minWidth: 135,
                'Cell': row=>{
                    const referenceID = _.get(row, 'original.external_property_id');
                    const propertyId = _.get(row, 'original.property_id');
                    const url = `/properties/${propertyId}/edit`;

                    const openNewTab = (
                        <OverlayTrigger
                            placement='top'
                            overlay={
                                <Tooltip id='reference_id'>
                                    {referenceID}
                                </Tooltip>
                            }
                        >
                            <span
                                className='clickable-text'
                                onClick={(e) => {
                                    this.navigate(e, url);
                                }}
                            >
                                {referenceID}
                            </span>
                        </OverlayTrigger>
                    )

                    return(
                        <div>
                            {openNewTab}
                        </div>
                    )
                }
            },
            {
                Header: 'Reservation ID',
                accessor: 'checkout_date',
                sortable: false,
                width: 135,
                'Cell': row=>{
                    const internalId = _.get(row, 'original.internal_id');
                    const internalIdType = _.get(row, 'original.internal_id_type');
                    let url = '';
                    let msg = '';

                    if(internalIdType == 'rid') {
                        url = `/reservations/${internalId}/details`;
                        msg = `Navigate to Reservation ${internalId}`;
                    }
                    else if(internalIdType == 'nid') {
                        url = `/properties/${internalId}/edit`;
                        msg = `Navigate to Property ${internalId}`;
                    };

                    const openNewTab = (
                        <OverlayTrigger 
                            placement='top' 
                            overlay={this.getTooltip('goTo', msg)} 
                        >
                            <span 
                                onClick={(e) => {
                                    this.navigate(e, url);
                                }}
                            >
                                <i className='icon-open-new-tab'></i>
                            </span>
                        </OverlayTrigger>
                    );

                    return(
                        <div className='position-relative'>
                            {
                                internalIdType == 'rid' &&
                                <span 
                                    className='clickable-text'
                                    onClick={(e) => {
                                        this.navigate(e, `/reservations/${internalId}/details`);
                                    }}
                                >
                                    {internalId}
                                </span>
                            }
                            <div className='thread-nav-link'>
                                {
                                    (url.length && msg.length) &&
                                    openNewTab
                                }
                            </div>
                        </div>
                    )
                }
            },
            {
                maxWidth: 70,
                sortable: false,
                'Cell': row=>{
                    const threadId = _.get(row, 'original.thread_id');

                    const reply = (
                        <OverlayTrigger 
                            placement='top' 
                            overlay={this.getTooltip('reply', 'Reply')} 
                        >
                            <span 
                                onClick={(e) => {
                                    e.stopPropagation();
                                    this.handleOpenMsg(threadId)}
                                }
                            >
                                <i className='icon-reply'></i>
                            </span>
                        </OverlayTrigger>
                    );
                    return(
                        <div className='comm-center-actions'>
                            {reply}
                        </div>
                    )
                }
            },
        ]
    }

    componentDidMount() {
        this.init();
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            websocket,
            threadActivePage,
            websocketSend,
            threadMessage,
            attachments,
            modalData
        } = this.state;

        this.isAdmin = this.props.roles.indexOf(constants.USER_TYPES.PROPERTY_MANAGER_ADMIN) > -1;

		if(_.get(prevProps, 'org') != _.get(this.props, 'org')) {
            this.org = _.get(this.props, 'org');

            this.allOrganizations = [];
            
            this.setState({
                ...this.initState,
            }, () => {
                this.init();
            });
        };

        if(websocket) {
            websocket.onmessage = async (event) => {
                if(_.get(event, 'data')) {
                    const parseData = JSON.parse(event.data);

                    if(parseData.thread_id == modalData.thread_id) {
                        const threadResp = await this.getThread(parseData.thread_id);

                        if(this.view == 'threads') {
                            await this.changeMsgStatusToRead(_.get(threadResp, 'data') || {});
                        };

                        this.setState({
                            threadMessage: !websocketSend ? threadMessage : "",
                            attachments: !websocketSend ? attachments : [],
                            modalData: _.get(threadResp, 'data') || {},
                            suggestedReplies: [],
                            suggestedRepliesCurrentIndex: 0,
                            threadLoading: false,
                            messageIsLoading: false,
                        });
                    };

                    if(this.view == 'db' && threadActivePage == 1) {
                        this.getThreads(threadActivePage);
                    };
                };
            };

            websocket.onclose = (event) => {
                if(event) {
                    this.connectWebsocket();
                };
            };
        }
    }

    componentWillUnmount() {
        const {
            websocket
        } = this.state;

        clearInterval(this.pingInterval);

        if(websocket) {
            websocket.close();
        };

        this.setLocalStorage();
    }

    init = async () => {
        const { 
            user,
            match,
            location,
        } = this.props;

        const pathName = _.get(location, 'pathname', '');
        const params = _.get(match, 'params');

        this.tidReservations = getSessionId();
        this.tidProperties = getSessionId();
        this.tidThreads = getSessionId();

        this.checkPermissions();

        if(this.org !== 'propertyowners' && this.org !== 'propertymanagers') {
            if(pathName.includes('threads')) {
                this.setState({
                    messageIsLoading: true
                });

                this.view = 'threads';

                await this.getCurrentSub();
                await this.handleOpenMsg(_.get(params, 'id', ''));

                this.pingInterval = setInterval(() => {
                    this.sendPing();
                }, 300000);

                this.setState({
                    messageIsLoading: false
                });
            }
            else {
                const { 
                    threadPageSize,
                    showOnlyUnread,
                    priorityFilters,
                    statusFilters,
                    threadSorted
                } = this.state;

                this.view = 'db';
    
                const params = JSON.parse(localStorage.getItem('threads'));
    
                let orgData = getOrgData(user, this.org);
    
                this.allOrganizations = orgData.allOrganizations;
    
                if(params) {
                    this.setState({
                        threadPageSize: params.threadPageSize || threadPageSize,
                        showOnlyUnread: params.showOnlyUnread || showOnlyUnread,
                        priorityFilters: params.priorityFilters || priorityFilters,
                        statusFilters: params.statusFilters || statusFilters,
                        threadSorted: params.threadSorted || threadSorted
                    }, () => {
                        this.getThreads(0, '', '', true);
                    });
                }
                else {
                    this.getThreads(0, '', '', true);
                };
    
                if(pathName.includes('comm-management')) {
                    this.setState({
                        dashboardTabs: 'comm-management'
                    });
                }
                else if(pathName.includes('earnings')) {
                    this.setState({
                        dashboardTabs: 'earnings'
                    });
                }
                else if(pathName.includes('reviews')) {
                    this.setState({
                        dashboardTabs: 'reviews'
                    });
                };

                if(this.org !== "_global_") {
                    this.getOrganization();
                    this.getProperties();
                    this.getReservations();
                    this.getEarnings();
                    this.getRevenue();
                };
            };
        };
    }

    checkPermissions = () => {
        if(_.isArray(this.props.permissions)) {
            if(this.props.permissions.includes('view_reviews') || this.props.permissions.includes('view_reviews_unrestricted')) {
                this.viewReviews = true;
                this.getPendingReviews();
            };

            if(this.props.permissions.includes('view_messages')) {
                this.viewMessages = true;
            };

            if(this.props.permissions.includes('view_subscriptions')) {
                this.viewSubscriptions = true;
            };

            if(this.props.permissions.includes('view_templates')) {
                this.viewTemplates = true;
            };

            if(this.props.permissions.includes('view_devices')) {
                this.viewDevices = true;
            };

            if(this.props.permissions.includes('view_payments')) {
                this.viewPayments = true;
            };
        };
    }

    setLocalStorage = () => {
        const { 
            showOnlyUnread,
            threadPageSize,
            priorityFilters,
            statusFilters,
            threadSorted,
        } = this.state;

        const pagination = {
            showOnlyUnread: showOnlyUnread,
            threadPageSize: threadPageSize,
            priorityFilters: priorityFilters,
            statusFilters: statusFilters,
            threadSorted: threadSorted
        };

        localStorage.setItem('threads', JSON.stringify(pagination));
    }

    getReservationCommData = async (id, type) => {
        let resp;

        try {
            resp = await API.get('gbapi', `/reservations/${id}/commdata?view=preview`, { response: true });
            if(resp) {
                let state = {}
                if(type == 'guestVerification') {
                    state = {
                        reservationCommData: resp.data,
                    };
                }
                else {
                    state = {
                        reservationCommData: resp.data,
                        hostNotes: _.get(resp, 'data.host_notes') || '',
                    }
                };

                this.setState(state);
            };
        }
        catch(err) {
        };
    }

    getPropertyCommData = async (id) => {
        let resp;

        try {
            resp = await API.get('gbapi', `/properties/${id}/commdata?view=preview`, { response: true });
            if(resp) {
                this.setState({
                    propertyCommData: resp.data
                })
            };
        }
        catch(err) {
        };
    }

    getCurrentSub = async () => {
        let resp;

        try {
            resp = await API.get('ramapi', `/commhub/currentsubscriber`, { response: true });
            if(resp) {
                this.setState({
                    currentSub: resp.data
                })
            };
        }
        catch(err) {
        };
    }

    sendPing = () => {
        const { websocket } = this.state;

        if(_.get(websocket, 'readyState') == 1) {
            websocket.send(JSON.stringify('ping'));
        };
    }

    connectWebsocket = async (category, subcategory) => {
        const body = {
            websocket_type: "all",
            organization_id: this.props.orgId,
            category: category ? category : "reservation",
            subcategory: subcategory ? subcategory : "inquiry",
            role_type: "guest"
        };
        let resp;

        try {
            resp = await API.post('ramapi', `/commhub/websocket-token`, { response: true, body: body });
            if(_.get(resp, 'data.token_id')) {

                const socket = await new WebSocket(`wss://${APIConfig.ENV == "DEV" ? 'dev.wss.redawning.com' : 'wss.redawning.com'}/ramapi-v1?ra-ouid-token=${resp.data.token_id}`);
                
                this.setState({
                    websocket: socket
                });
            };
        }
        catch(err) {
        };
        return resp;
    }

    getThreads = async (active_page, sort, page_size, init, hideLoader, tid) => {
        const { 
            threadPageSize,
            threadSorted,
            showAllOrgThread,
            showOnlyUnread,
            threadDates,
            threadKeyword,
            currentSub,
            priorityFilters,
            statusFilters,
        } = this.state;
        let resp;
        const newPageSize = page_size ? page_size : threadPageSize;
        let newActivePage = ((active_page === 0) ? 0 : (active_page - 1));
        const startDate = 
            moment(threadDates.startDate).isValid() ? 
            moment(threadDates.startDate).format("YYYY-MM-DD") :
            "";
        const endDate = 
            moment(threadDates.endDate).isValid() ? 
            moment(threadDates.endDate).format("YYYY-MM-DD") :
            "";
        const offset = newPageSize * newActivePage;
        const newSorted = sort ? sort : threadSorted;
        const sortDesc = newSorted.desc ? "-" : "+";

        const priorityKeys = _.keys(_.pickBy(priorityFilters, Boolean));
        const statusKeys = _.keys(_.pickBy(statusFilters, Boolean));
        const priorityQueryString = priorityKeys.length > 0 ? `&priority=${priorityKeys.join(',')}` : '';
        const statusQueryString = statusKeys.length > 0 ? `&thread_statuses=${statusKeys.join(',')}` : '';

        if(!tid) {
            this.tidThreads = getSessionId();
        };

        if(!hideLoader) {
            this.setState({
                threadIsLoading: true
            });
        };

        if(init || !(currentSub && currentSub.subscriber_id)) {
            this.getCurrentSub();
        };

        const checkInDate = moment(threadDates.startDate).isValid() ? `&checkin_date=${startDate ? encodeURIComponent(startDate) : ""}${endDate ? `:${endDate}`: ''}` : '';

        try {
            resp = await API.get('ramapi', `/commhub/threads?organization=${this.org}&tsid=${this.tidThreads}&limit=${threadPageSize}&offset=${offset}&sort=${threadSorted.id}${encodeURIComponent(sortDesc)}${checkInDate}${showAllOrgThread ? `&include_child_orgs=true` : ''}${showOnlyUnread ? '&status=new' : ''}${threadKeyword ? `&keyword=${encodeURIComponent(threadKeyword)}` : ''}${statusQueryString}${priorityQueryString}`, { response: true });

            if(resp && resp.data) {
                this.setState({
                    threadData: resp.data,
                    threadActivePage: (newActivePage + 1),
                    threadPageSize: newPageSize,
                    threadSorted: {
                        ...threadSorted,
                        id: newSorted.id,
                        desc: newSorted.desc,
                    },
                    threadIsLoading: false
                }, () => {
                    if(init) {
                        this.setState({
                            commCenterAccess: true
                        }, () => {
                            this.connectWebsocket(_.get(this.state.threadData, '[0].category'), _.get(this.state.threadData, '[0].subcategory'));

                            this.pingInterval = setInterval(() => {
                                this.sendPing();
                            }, 300000);
                        });
                    };

                    this.setLocalStorage();
                });
            };
        }
        catch(err) {
            if(err && err.response && err.response.status == 401) {
                this.setState({
                    commCenterAccess: false
                });
            };
            this.setState({
                threadIsLoading: false,
            });
        };
    }

    getPendingReviews = async () => {
        let resp;

		try { 
			resp = await API.get("rapapi", `/organizations/${this.org}/reviews?include_suborgs=true&limit=1`, { response: true });
            if(_.get(resp, 'headers.pending_response_count')) {
                this.setState({
                    pendingReviews: resp.headers.pending_response_count
                });
            }
            else {
                this.setState({
                    pendingReviews: ''
                });
            }
		}
        catch(e) {
            console.log(e)
		};
    }

    getRevenue = async () => {
        const {
            showAllOrgRevenue,
        } = this.state;

        let revenueData, totalRevenue = {};
        let param = `?organization=${showAllOrgRevenue ? encodeURIComponent(this.allOrganizations.join()) : this.org}`;

        this.setState({ 
            revenueIsLoading: true
        });

        try {
            revenueData = await API.get('rptapi', constants.RAPAPI.REVENUE+param,null);

            if(revenueData && revenueData.data) {
                let year = moment().format("YYYY");
                totalRevenue.days = revenueData.data["30"] ? constants.NUM_FORMAT(revenueData.data["30"].amount) : 0;
                totalRevenue.year = revenueData.data[year] ? constants.NUM_FORMAT(revenueData.data[year].amount) : 0;
                totalRevenue.total = constants.NUM_FORMAT(revenueData.total);
                totalRevenue.currency = revenueData.currency;
            } 
            else {
                totalRevenue= {};
            };

            this.setState({
                totalRevenue: totalRevenue,
                revenueIsLoading: false
            });
        }
        catch(err) {
            this.setState({
                revenueIsLoading: false
            });
        };
    }

    getOrganization = async () => {
        let resp;

        try {
            resp = await API.get("accapi", `/organizations/${this.org}`, {headers: {"Cache-Control": "must-revalidate"}});

            if(_.isPlainObject(resp)) {
                this.setState({
                    organizationData: resp
                });
            };
        }
        catch(err) {
        };
    }

    getProperties = async () => {
        let response = [],
            limit = 1,
            offset = 0;

        this.setState({
            propertiesData: [],
            propertiesIsLoading: true,
        });
        
        try {
            response = await API.get("rapapi", `/properties?organization=${this.org}&limit=${limit}&offset=${offset}&tid=${this.tidProperties}`, { response: true, isCognito: true });
            if(response && response.data) {
                this.setState({
                    propertiesData: response.data,
                    propertiesIsLoading: false,
                });
            }
        }
        catch(err) {
            this.setState({
                propertiesData: [],
                propertiesIsLoading: false,
            });
        };
    }

    getReservations = async (page, pageChange, orgChange) => {
        const { 
            reservationsActivePage,
            reservationsData,
            showAllOrgReservation
        } = this.state;
        let response = [],
            limit = 5,
            sort = "checkin_date",
            checkinDate = moment().format("YYYY-MM-DD"),
            offset = page ? (!((page - 1) < 0) ? ((page - 1) * limit) : 0) : 0;
        const orgCopy = this.org;

        this.setState({
            reservationsData: pageChange ? reservationsData : [],
            reservationsIsLoading: true,
            reservationsActivePage: !orgChange ? reservationsActivePage : 1
        });

        try {
            response = await API.get("rapapi", `/properties/reservations?organization${showAllOrgReservation ? 's' : ''}=${showAllOrgReservation ? encodeURIComponent(this.allOrganizations.join()) : this.org}&limit=${limit}&tid=${this.tidReservations}&offset=${offset}&sort=${sort}&checkin=${checkinDate}&status=current`, { response: true, isCognito: true });
            if(response && response.data && (orgCopy === this.org)) {
                this.setState({
                    reservationsData: response.data,
                    reservationsTotalCount: response.headers["x-total-count"],
                    reservationsActivePage: page ? page : reservationsActivePage,
                    reservationsIsLoading: false,
                });
            };
        }
        catch(err) {
            if((orgCopy === this.org)) {
                this.setState({
                    reservationsData: [],
                    reservationsTotalCount: 0,
                    reservationsActivePage: 1,
                    reservationsIsLoading: false
                });
            };
        };
    }

    getEarnings = async () => {
        const {
            showAllOrgEarnings,
        } = this.state;

        let last30Days = this.last30Days();
        let graphData = [];
        let earningsByDate;
        let dates = Object.keys(last30Days);
        let param = `?organization=${showAllOrgEarnings ? encodeURIComponent(this.allOrganizations.join()) : this.org}`;

        this.setState({ 
            earningsIsLoading: true
        });

        try {
            let filter = '&book='+ encodeURIComponent(dates[30]+':'+dates[1]);

            earningsByDate = await API.get('rptapi', constants.RAPAPI.EARNINGS_BY_DATE+param+filter,null);

            if(earningsByDate) {
                earningsByDate.data.forEach((date)=>{
                    if(last30Days[date.book_date]) {
                        last30Days[date.book_date].y = date.amount;
                    };
                });
            };
    
            dates.map((date, i)=>{
                graphData.splice(i, 0, last30Days[date]);
            });
    
            this.setState({
                graphData: graphData,
                last30Days: last30Days,
                earningsIsLoading: false
            });
        } 
        catch(err) {
            this.setState({
                last30Days: this.last30Days(),
                graphData: Object.keys(this.last30Days()).map(date=>this.last30Days()[date]),
                earningsIsLoading: false
            });
        }
    }

    getThread = async (id) => {
        let resp;
        let errMsg = `Oops! We encountered an error. Please contact customer support.`;
        try {
            resp = await API.get('ramapi', `/commhub/threads/${id}`, { response: true });
        }
        catch(err) {
            if(_.get(err, 'response.data.Message.length')) {
                if(err.response.data.Message.match(/'(.*?)'/)) {
                    errMsg = err.response.data.Message.match(/'(.*?)'/)[1];
                };
            };
        };
        return resp || errMsg;
    }

    setPropertyOrg = (orgName) => {
        let organization = this.props.user.expanded_organization_roles.filter(org => {
            if(org.organization_name === orgName) {
                return true
            }
        });

        if(this.org !== orgName) {
            if(organization && organization.length) {
                this.props.updateOrganization(organization[0].organization_name);
                this.props.updateOrganizationTitle(organization[0].title);
                this.props.updateOrganizationId(organization[0].organization_id);
                this.props.setRole(organization[0].roles);
    
                sessionStorage.setItem('org', organization[0].organization_name);
                sessionStorage.setItem('orgTitle', organization[0].title);
                sessionStorage.setItem('orgId', organization[0].organization_id);

                sessionStorage.removeItem('reservations-listing');
                sessionStorage.removeItem('properties-listing');
            };
        };
    }

    handleShowAllOrg = (name, apiCall) => {
        this.setState({ 
            [name]: !this.state[name]
        }, () => {
            apiCall(0);
        });
    }

    getTooltip = (id, text) => {
        return <Tooltip id={id}>{text}</Tooltip>
    }

    getRef = (r, type) => {
        this[type] = r;
    }

    pageOnChange = (page, type) => {
    if(type === "reservations"){
            this.getReservations(page, "pageChange");
        };
    }

    updateMessageStatus = async (thread_id, body) => {
        await API.patch('ramapi', `/commhub/threads/${thread_id}/messages`, { "body": body });
    }

    updateThreadAttributes = async (type) => {
        const {
            modalData,
            markStatus,
        } = this.state;
        const id = _.get(modalData, 'thread_id');
        const body = {
            [type]: markStatus.value
        };

        await API.patch('ramapi', `/commhub/threads/${id}`, { "body": body });
    }

    changeMsgStatusToRead = async (data) => {
        let filterNewMsg = [];

        if(_.isArray(data.messages) && !_.isEmpty(data.messages)) {
            filterNewMsg = data.messages.filter((msg) => msg.status == 'new');
        };

        if(filterNewMsg.length) {
            let body = [];

            filterNewMsg.map((msg) => {
                body.push({ message_id: msg.message_id, status: "read" });
            });

            await this.updateMessageStatus(_.get(data, 'thread_id'), body);
        };
        return filterNewMsg;
    }

    changeMsgStatusToUnread = async (data) => {
        let filterLastMsg = [];

        if(data && data.messages && data.messages.length) {
            filterLastMsg = data.messages.filter((msg, i) => i == (data.messages.length - 1) && data.messages.status !== 'new');
        };

        if(filterLastMsg.length) {
            let body = [];

            filterLastMsg.map((msg) => {
                body.push({ message_id: msg.message_id, status: "new" });
            });

            await this.updateMessageStatus((data && data.thread_id), body);
        };
        return filterLastMsg;
    }

    renderMessages = () => {
        const { 
            modalData,
            currentSub
        } = this.state;

        if(modalData && modalData.messages && modalData.messages.length) {
            const reverseArr = modalData.messages.slice().reverse();

            const renderMessage = (checkSubscriber, renderType, message, msg, firstOriginContext) => {
                const displayName = (!_.get(msg, 'posted_by_subscriber_display_name') || _.get(msg, 'posted_by_subscriber_display_name').toLowerCase().includes('none')) ? 
                    'Guest' : msg.posted_by_subscriber_display_name;
                const checkTextPosition = (checkSubscriber || firstOriginContext == _.get(msg, 'context.origin_context'));
                const hasHtml = (msg && msg.renders).filter(render => render.content_type === 'html');
                
                return (
                    <div className="thread-msg-container">
                        <Col xs={12} sm={12} className={`sender ${(checkTextPosition) ? 'right' : 'left'} ${(msg.status == 'new') ? 'new-msg' : ''}`}>
                            <span>{displayName} &nbsp; {moment(msg.created_dt).format('llll')}</span>
                        </Col>
                        <Col xs={12} sm={12}>
                            <div className={`bubbles ${checkTextPosition ? 'right' : 'left'}`}>
                                <div className={`msg ${checkTextPosition ? 'right current-sub ' : ''}${renderType == 'text' ? 'break-space ': ''}${hasHtml.length ? 'hasHtml' : ''}`}>
                                    {message}
                                </div>
                            </div>
                        </Col>
                        {
                            (msg && msg.attachments && msg.attachments.length > 0) &&
                            <Col xs={12} sm={12} className='attachments top-margin'>
                                <div className='attach-title'>
                                    {msg.attachments.length}{' '}Attachments:
                                </div>
                                {
                                    (msg && msg.attachments).map((attachment, i) => {

                                        let imgSrc = File;

                                        if(attachment.mime_type.includes('pdf')) {
                                            imgSrc = Pdf;
                                        }
                                        else if(attachment.mime_type.includes('xls') || attachment.mime_type.includes('xlm')) {
                                            imgSrc = Sheets;
                                        }
                                        else if(attachment.mime_type.includes('txt') || attachment.mime_type.includes('doc')) {
                                            imgSrc = Docs;
                                        }
                                        else if(attachment.mime_type.includes('image') || attachment.mime_type.includes('png') || attachment.mime_type.includes('jpeg')) {
                                            imgSrc = ImageFile;
                                        };

                                        return (
                                            <Col xs={4} sm={4} key={i}>
                                                <OverlayTrigger 
                                                    placement='top' 
                                                    overlay={this.getTooltip('filename', `${attachment.filename}`)} 
                                                >
                                                    <div className="attach-border">
                                                        <a
                                                            className="attach-container"
                                                            href={attachment.uri}
                                                            target="_blank"
                                                        >
                                                            <div className="attach-icon-container">
                                                                <img src={imgSrc}></img>
                                                            </div>
                                                            <div className='attach-file-name'>
                                                                {attachment && attachment.filename && attachment.filename.length > 15 ? attachment.filename.substring(0, 15) + "...": attachment.filename}
                                                            </div>
                                                        </a>
                                                    </div>
                                                </OverlayTrigger>
                                            </Col>
                                        )
                                    })
                                }
                            </Col>
                        }
                    </div>
                )
            };

            return reverseArr.map((msg, i) => {
                const hasHtml = (msg && msg.renders).filter(render => render.content_type === 'html');
                const hasEmailHtml = (msg && msg.renders).filter(render => render.content_type === 'email_html');
                const hasEmailText = (msg && msg.renders).filter(render => render.content_type === 'email_text');
                const hasText = (msg && msg.renders).filter(render => render.content_type === 'text');
                const hasSms = (msg && msg.renders).filter(render => render.content_type === 'sms_text');
                const firstOriginContext = _.get(modalData.messages, '[0].context.origin_context', '');

                if(msg.renders.length) {
                    let message = "";
                    let renderType = "";
                    const checkSubscriber = ((currentSub && currentSub.subscriber_id) == msg.posted_by_subscriber_id);

                    if(hasHtml.length) {
                        message = <div dangerouslySetInnerHTML={{ __html: hasHtml[0].rendered_body }}></div>;
                        renderType = 'html';
                    }
                    else if(hasEmailText.length) {
                        const newEmailText = hasEmailText[0].rendered_body.replace(/\n +/g, '\n').replace(/ +/g, ' ').trim();

                        message = <div className='left-text'>{newEmailText}</div>
                        renderType = 'text';
                    }
                    else if(hasEmailHtml.length) {
                        message = <div dangerouslySetInnerHTML={{ __html: hasEmailHtml[0].rendered_body }}></div>;
                        renderType = 'html';
                    }
                    else if(hasText.length) {
                        message = <div className='left-text'>{hasText[0].rendered_body}</div>
                        renderType = 'text';
                    }
                    else if(hasSms.length) {
                        message = <div className='left-text'>{hasSms[0].rendered_body}</div>
                    };

                    return (
                        <div key={i}>
                            {renderMessage(checkSubscriber, renderType, message, msg, firstOriginContext)}
                        </div>
                    )
                };
            });
        };
    }
    
    handleOpenMsg = async (id) => {
        this.setState({ 
            messageIsLoading: true
        });

        const threadResp = await this.getThread(id);

        if(!_.isEmpty(threadResp) && _.get(threadResp, 'data')) {

            if(this.view == 'threads') {
                this.setPropertyOrg(_.get(threadResp, 'data.organization_name'));
            };
            
            const data = _.get(threadResp, 'data');
            let replyTo = '';
            let markStatus = [];
            let markPriority = [];
            
            if(_.get(data, 'internal_id') && _.get(data, 'internal_id_type') === 'rid') {
                await this.getReservationCommData(data.internal_id);
            };
    
            if(_.get(data, 'property_id')) {
                await this.getPropertyCommData(data.property_id);
            };
            
            if(!_.isEmpty(data)) {
                const { currentSub } = this.state;
    
                if(_.get(data, 'messages.length')) {
                    const msgLength = data.messages.length;
    
                    for(let i = (msgLength - 1); i >= 0 ; i--) {
                        if(data.messages[i].posted_by_subscriber_id !== currentSub.subscriber_id) {
                            replyTo = data.messages[i].posted_by_subscriber_display_name;
                            break;
                        };
                    };
                };
    
                if(data.status) {
                    markStatus = this.markStatusOptions.find(option => option.value === data.status);
                };
    
                if(data.priority) {
                    markPriority = this.markPriorityOptions.find(option => option.value === data.priority);
                };
            };
    
            this.setState({
                replyTo: replyTo,
                modalData: data,
                markStatus: markStatus,
                markPriority: markPriority,
                messageIsLoading: false,
            }, () => {
                if(this.view == 'db') {
                    this.setState({
                        modal: 'ask-question'
                    });
                }
                else if(this.view == 'threads') {
                    this.connectWebsocket(_.get(data, 'category'), _.get(data, 'subcategory'));
                };
            });
        }
        else {
            this.setState({
                modal: 'error-modal',
                errorMsg: threadResp,
                threadIsLoading: false,
            });
        }
    }

    handleCloseMsgModal = () => {
        this.setState({
            modal: '',
        }, async () => {
            const {
                modalData,
                threadActivePage,
                markAsUnread,
            } = this.state;

            this.setState({ threadIsLoading: true });

            if(markAsUnread) {
                await this.changeMsgStatusToUnread(modalData);
            }
            else {
                const newThreads = await this.getThread(modalData.thread_id);

                await this.changeMsgStatusToRead(newThreads.data);
            };

            await this.getThreads(threadActivePage, '', '', '', true);

            this.setState({
                modalData: {},
                threadMessage: "",
                markAsUnread: false,
                markStatus: [],
                markPriority: [],
                threadIsLoading: false,
                attachments: [],
                suggestedReplies: [],
                suggestedRepliesCurrentIndex: 0,
                reservationCommData: {},
                propertyCommData: {},
                editHostNotes: false,
                hostNotes: '',
                hostNotesErr: '',
            });
        });
    }

    submitMessage = async () => {
        const {
            threadMessage,
            modalData,
            websocket,
            attachments,
            markPriority
        } = this.state;
        const body = {
            "thread_id": modalData.thread_id,
            "priority": markPriority.value,
            "renders": [
                {
                    "content_type": "text",
                    "rendered_body": threadMessage
                }
            ],
        };
        let resp;
        const loader = this.view == 'threads' ? 'messageIsLoading' : 'threadLoading';

        this.setState({
            [loader]: true,
            websocketSend: true,
        });

        if(attachments.length) {
            resp = await this.uploadAttachments();

            if(resp.length) {
                body.attachments = resp;
            };
        };

        websocket.send(JSON.stringify(body));
    }

    handleMessageOnChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        });
    }

    navigate = (e, url) => {
        if(e) {
            e.stopPropagation();
        };
        const data = { dashboard: true };
        const newWindow = window.open(url, '_blank');

        newWindow.data = data;
    };

    onSortedChange = (newSorted) => {
        const { 
            threadActivePage,
        } = this.state;

        this.setState({
            threadSorted: newSorted[0]
        }, () => {
            this.getThreads(threadActivePage- 1, newSorted[0]);
        });
    }

    toggleState = (e, stateName) => {
        e.preventDefault();

        this.setState({
            [stateName]: !this.state[stateName]
        });
    }

    handleMarkPriority = (value) => {
        this.setState({
            markPriority: value,
        });
    }

    handleMarkStatus = (value) => {
        this.setState({
            markStatus: value,
        }, async () => {
            const loader = this.view == 'threads' ? 'messageIsLoading' : 'threadLoading';
            this.setState({ [loader]: true });

            await this.updateThreadAttributes('status');

            this.setState({ [loader]: false });
        });
    }

    isOutsideRange = () => {
        return false;
    }

    handleDatesChange = (newDates) => {
        this.setState({
            threadDates: {
                ...newDates
            }
        }, () => {
            const { 
                threadDates
            } = this.state;
            let startElement = document.getElementById('start');
            let startElementValue = startElement.value;
            let endElement = document.getElementById('end');
            let endElementValue = endElement.value;

            if(moment(threadDates && threadDates.startDate).isValid() && (endElementValue == "") || moment(threadDates && threadDates.endDate).isValid() && (startElementValue == "")) {
                this.getThreads(0);
            }
            else if (moment(threadDates && threadDates.startDate).isValid() && moment(threadDates && threadDates.endDate).isValid()) {
                this.getThreads(0);
            }
            else if(!(threadDates && threadDates.startDate) && !(threadDates && threadDates.endDate)) {
                this.getThreads(0);
            };
        });
    }

    handleSearchChange = (e) => {
        const name = e.target.name;
        const val = e.target.value;

        this.setState({
            [name] : val
        });
    }

    handleSearch = (e) => {
        e.preventDefault();

        this.getThreads(0);
    }

    addAttachment = (file) => {
        const { attachments } = this.state;
        let newAttachments = [...attachments];

        newAttachments.push(file[0]);

        this.setState({
            attachments: newAttachments
        });
    }

    removeAttachment = (e, index) => {
        e.preventDefault();
        const { attachments } = this.state;
        let copyAttachments = [...attachments];

        copyAttachments.splice(index, 1);

        this.setState({
            attachments: copyAttachments
        });
    }

    formatFileSize = (fileSize) => {
        const units = ['B', 'KB', 'MB', 'GB', 'TB'];
        let size = fileSize;
        let unitIndex = 0;
      
        while (size >= 1024 && unitIndex < units.length - 1) {
          size /= 1024;
          unitIndex++;
        }
      
        return Math.round(size) + units[unitIndex];
    }

    renderNewAttachments = () => {
        const { attachments } = this.state;

        return (
            <div className='new-attachments'>
                {
                    attachments.map((attach, i) => {
                        return (
                            <div className='new-attach-container' key={i}>
                                <span className='new-attach'>
                                    <span>
                                        <span className='new-attach-name'>{attach && attach.name && attach.name.length > 20 ? attach.name.substring(0, 20) + "...": attach.name}</span>
                                        <span className='attach-file-size'>{`(${this.formatFileSize(attach.size)})`}</span>
                                    </span>
                                    <OverlayTrigger 
                                        placement='top' 
                                        overlay={this.getTooltip('remove-attachment', 'Remove Attachment')} 
                                    >
                                        <span 
                                            className='new-attach-icon'
                                            onClick={e => this.removeAttachment(e, i)}
                                        >
                                            <i className='icon-Cross'></i>
                                        </span>
                                    </OverlayTrigger>
                                </span>
                            </div>
                        )
                    })
                }
            </div>
        );
    }

    uploadAttachments = async () => {
        const { attachments } = this.state;
        let resp;
        let body = [];

        attachments.forEach((attachment) => {
            body.push(
                {
                    "filename": attachment.name,
                    "mime_type": attachment.type
                }
            );
        });

        try {
            resp = await API.post('ramapi', `/commhub/attachments`, { body: body });

            if(resp && resp.length) {

                let newResp = [...resp];

                resp.forEach((x, i) => {
                    attachments.forEach((y) => {
                        if(x.filename == y.name) {
                            newResp[i] = {
                                ...newResp[i],
                                file: y
                            };
                        };
                    });
                });

                for(let i = 0; i < newResp.length; i++) {
                    try {
                        axios.put(newResp[i].uri, newResp[i].file, {
                            headers: {
                              'filename': newResp[i].file.name,
                              'content-type': newResp[i].file.type
                            }})
                            .catch(error => {
                                console.error(error);
                            });
                    }
                    catch(err) {
                    };
                    await delay(1500);
                };
            };
        }
        catch(err) {
        };

        return resp;
    }

    toggleTabs = (value) => {
        if(value == 'calendar' || value == 'reservations') {
            this.props.history.push(`/${value}`)
        }
        else {
            const url = value == 'dashboard' ? '/dashboard' : `/dashboard${`/${value}`}`;

            this.props.history.push(url);
    
            this.setState({
                dashboardTabs: value
            });
        };
    }

    threadPageSizeOnChange = (pageSize) => {
        this.setState({
            threadPageSize: pageSize
        }, async () => {
            const { 
                threadSorted
            } = this.state;

            this.getThreads(0, threadSorted, pageSize);
        });
    }

    threadPageOnChange = (direct) => {
        const { threadActivePage } = this.state;
        let page = 1;

        switch(direct) {
            case 'prev':
                page = threadActivePage - 1;
                break;
            case 'next':
                page = threadActivePage + 1;
                break;
            default:
                page = 1;
        };

        this.getThreads(page, '', '', '', '', true)
    }

    handlePriorityFilter = (name) => {
        const { priorityFilters } = this.state;

        this.setState({
            priorityFilters: {
                ...priorityFilters,
                [name]: !priorityFilters[name]
            }
        }, () => {
            const { 
                threadSorted,
                threadPageSize
            } = this.state;

            this.getThreads(0, threadSorted, threadPageSize)
        })
    }

    handleStatusFilters = (name) => {
        const { statusFilters } = this.state;

        this.setState({
            statusFilters: {
                ...statusFilters,
                [name]: !statusFilters[name]
            },
        }, () => {
            const { 
                threadSorted,
                threadPageSize
            } = this.state;

            this.getThreads(0, threadSorted, threadPageSize)
        })
    }

    renderPicky = (label, options, value, func, disabled) => {
        return (
            <Picky
                className='left-margin picky-dropdown-up'
                placeholder={label}
                labelKey="label"
                valueKey="value"
                options={options}
                value={value}
                onChange={value => func(value)}
                keepOpen={false}
                dropdownHeight={600}
                disabled={disabled}
                render={({
                    style,
                    item,
                    isSelected,
                    selectValue,
                    labelKey,
                    valueKey,
                    }) => {
                    return (
                        <li
                            style={{ ...style }} 
                            className={isSelected ? "selected" : ""} 
                            key={item[valueKey]}
                            onClick={() => selectValue(item)}
                        >
                            <span
                                style={{fontWeight: isSelected ? "bold" : "normal"}}
                            >
                                {item[labelKey] !== null && item[labelKey] !== "" ? item[labelKey] : item[valueKey]}
                            </span>
                        </li>
                    );
                }}
            />
        )
    }

    getSuggestedReply = async (e, init) => {
        e && e.preventDefault();
        const { 
            modalData,
        } = this.state;

        this.setState({
            isSuggestedReplyLoading: true
        });

        try {
            const resp = await API.get('ramapi', `/commhub/threads/${modalData.thread_id}/suggestions?num_suggestions=3`);

            if(_.get(resp, 'replies')) {
                if(init) {
                    this.setState({
                        suggestedReplies: resp.replies,
                        threadMessage: resp.replies[0],
                        isSuggestedReplyLoading: false
                    });
                }
                else {
                    const { suggestedReplies } = this.state;

                    this.setState({
                        suggestedReplies: [...suggestedReplies ,...resp.replies],
                        isSuggestedReplyLoading: false
                    });
                }
            };
        }
        catch(err) {
            console.log(err);
        };
    }

    responseSelector = async (e, direction) => {
        e.preventDefault();
        const {
            suggestedRepliesCurrentIndex
        } = this.state;
        let index = suggestedRepliesCurrentIndex;

        if(direction == 'foward') {
            index++;
        }
        else if(direction == 'back') {
            index--;
        };

        if(index > (this.state.suggestedReplies.length - 1)) {
            await this.getSuggestedReply();
        };

        this.setState({
            suggestedRepliesCurrentIndex: index,
            threadMessage: this.state.suggestedReplies[index],
        })
    }

    hostNotesOnChange = (e) => {
        e.preventDefault();
        const value = e.target.value;

        this.setState({
            hostNotes: value
        });
    }

    submitHostNotes = async () => {
        const { 
            hostNotes,
            reservationCommData
        } = this.state;
        let body = {
            attribute_name: 'host_notes',
            attribute_value: hostNotes,
        };
        let resp;
        const loader = this.view == 'threads' ? 'messageIsLoading' : 'threadLoading';

        this.setState({
            hostNotesErr: '',
            [loader]: true,
        });

        try {
            resp = await API.post(constants.RAPAPI.NAME, `/properties/reservations/${reservationCommData.reservation_id}/attributes`, { body: [body] });
            if(resp) {

                await this.getReservationCommData(reservationCommData.reservation_id, 'hostNotes');

                this.setState({
                    messageIsLoading: false,
                    editHostNotes: false,
                    threadLoading: false
                });
            }
        }
        catch(err) {
            let msg = `Oops! We encountered an error. Please contact customer support.`;
        
            if(_.get(err, 'response.data.Message.length')) {
                if(err.response.data.Message.match(/'(.*?)'/)) {
                    msg = err.response.data.Message.match(/'(.*?)'/)[1];
                };
            };

            this.setState({
                hostNotesErr: msg,
                messageIsLoading: false,
                editHostNotes: false,
                threadLoading: false
            });
        };
    }

    submitGuestVerification = async () => {
        const { reservationCommData } = this.state;
        let data = {
            reservation_id: reservationCommData.reservation_id,
            vendor: 'stripe'
        };

        this.setState({
            guestVerificationModalIsLoading: true
        });

        try {
            await API.put('gbapi', `/reservations/${reservationCommData.reservation_id}/commdata/guest_verified`, { body: data });

            await this.getReservationCommData(reservationCommData.reservation_id, 'guestVerification');

            this.setState({
                guestVerificationModalIsLoading: false,
                guestVerificationModal: false,
            });
        }
        catch(e) {
            let msg = `Oops! We encountered an error. Please contact customer support.`;
        
            if(_.get(e, 'response.data.Message.length')) {
                if(e.response.data.Message.match(/'(.*?)'/)) {
                    msg = e.response.data.Message.match(/'(.*?)'/)[1];
                };
            };

            this.setState({
                guestVerificationError: msg,
                guestVerificationModalIsLoading: false
            });
        }
    }

    handleGuestVerificationModal = (bool) => {
        this.setState({
            guestVerificationError: '',
            guestVerificationModal: bool
        });
    }

    renderRequestIdentification = () => {
        const { 
            reservationCommData,
        } = this.state;
        const verifiedStatus = _.get(reservationCommData.guest_verified, 'verified', '');

        // Verified / Failed
        if(!_.isNull(reservationCommData.guest_verified) && !_.isNull(verifiedStatus)) {
            return (
                <span>
                    <div 
                        className={`identification-status ${verifiedStatus ? 'verified' : 'unverified'}`}
                    >
                        {verifiedStatus ? `Verified as ${_.get(reservationCommData.guest_verified, 'verified_first_name', '')} ${_.get(reservationCommData.guest_verified, 'verified_last_name', '')}` : 'Unverified'}
                        <span className='left-padding-5'>
                            <i className={verifiedStatus ? 'icon-gree_check' : 'icon-wrong'}></i>
                        </span>
                    </div>
                </span>
            )
        }
        // Pending
        else if(!_.isNull(reservationCommData.guest_verified) && _.isNull(verifiedStatus)) {
            return (
                <span className={`identification-status pending`}>Indentity Verification Requested</span>
            )
        }
        // Request Identity
        else if(_.isNull(reservationCommData.guest_verified)) {
            return (
                <span 
                    className='clickable-text right-margin-5'
                    onClick={() => this.handleGuestVerificationModal(true)}
                >
                    Request Verification
                </span>
            )
        }
    }

    renderMsg = (msg, type) => {
        return (
            <Col xs={12} sm={12} className='top-margin'>
                <div className={`msg-container ${type}`}>
                    {msg}
                </div>
            </Col>
        )
    }

    renderThreadMsg = () => {
        const {
            modalData,
            threadMessage,
            markAsUnread,
            replyTo,
            markStatus,
            markPriority,
            isSuggestedReplyLoading,
            suggestedReplies,
            suggestedRepliesCurrentIndex,
            propertyCommData,
            reservationCommData,
            hostNotes,
            editHostNotes,
            hostNotesErr
        } = this.state;

        return (
            <Fragment>
                <Row>
                    <Col xs={12} sm={6}>
                        <div className='thread-msg'>
                            <Row>
                                <Col xs={12} sm={12}>
                                    <div className='property-title'>{_.get(modalData, 'property_title')} &nbsp; 
                                        <span className='subtext'>
                                            <Link
                                                target='_blank' 
                                                to={`/properties/${_.get(modalData, 'property_id')}/edit`}>
                                                    {_.get(modalData, 'property_id')}
                                            </Link>
                                        </span>
                                    </div>
                                    {
                                        moment(_.get(modalData, 'checkin_date')).isValid() &&
                                        <div className="reservation-details">
                                            <span>Check-In Date:</span> <strong>{moment(_.get(modalData, 'checkin_date')).utc().format('MM/DD/YYYY')}</strong>,&nbsp;
                                            <span>Check-Out Date:</span> <strong>{moment(_.get(modalData, 'checkout_date')).utc().format('MM/DD/YYYY')}</strong>
                                        </div>
                                    }
                                    <div className="thread-subject top-margin">{_.get(modalData, 'subject')}</div>
                                </Col>
                            </Row>
                            {
                                (_.get(modalData, 'messages', []).length) && 
                                <Row>
                                    <Col xs={12} sm={12}>
                                        <div className='thread-container'>
                                            {this.renderMessages()}
                                        </div>
                                    </Col>
                                </Row>
                            }
                            <Row style={{marginTop: '20px'}}>
                                <Col xs={12} sm={12}>
                                    {this.renderNewAttachments()}
                                </Col>
                                <Col xs={12} sm={12}>
                                    <InputLabel 
                                        htmlFor="messageResponse"
                                        style={{margin: '8px 0'}}
                                    >
                                        {
                                            (_.get(modalData, 'messages.length') && replyTo) ?
                                            `Reply to ${replyTo}:` :
                                            'Message:'
                                        }
                                    </InputLabel>
                                    {
                                        suggestReplyBtn(suggestedReplies, suggestedRepliesCurrentIndex, isSuggestedReplyLoading, this.getSuggestedReply, this.responseSelector)
                                    }
                                    <InputField
                                        id="messageResponse"
                                        type="textarea"
                                        name="threadMessage"
                                        value={threadMessage}
                                        onChange={e =>this.handleMessageOnChange(e)}
                                    />
                                </Col>
                                <Col xs={12} sm={12} className='thread-button-container'>
                                    {
                                        this.view == 'threads' &&
                                        <Fragment>
                                            {this.renderPicky('Status', this.markStatusOptions, markStatus, this.handleMarkStatus)}
                                            {this.renderPicky('Priority', this.markPriorityOptions, markPriority, this.handleMarkPriority)}
                                        </Fragment>
                                    }
                                    <span className='add-attachment'>
                                        <OverlayTrigger 
                                            placement='top' 
                                            overlay={this.getTooltip('add-attachment', 'Attach Files')} 
                                        >
                                            <div>
                                                <Dropzone
                                                    multiple={true}
                                                    accept={constants.ACCEPTED_FILE_TYPES.join(',')}
                                                    onDrop={this.addAttachment}
                                                    className='attach-dropzone'
                                                >
                                                </Dropzone>
                                                <i className='icon-attachment'></i>
                                            </div>
                                        </OverlayTrigger>
                                    </span>
                                    <PrimaryButton 
                                        fullWidth={false} 
                                        type="button" 
                                        onClick={this.submitMessage}
                                        disabled={
                                            (!threadMessage.length)
                                        }
                                    >
                                        Send Message
                                    </PrimaryButton>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={12} sm={6}>
                        <div className='thread-info'>
                            <Row>
                                <Col xs={12} sm={12} className='flex-space-between'>
                                    <div>
                                        <div><strong>{_.get(propertyCommData, 'title') || ''}</strong></div>
                                    </div>
                                    <div>
                                        <div>
                                            <span className='right-margin-5'>
                                                <span className='right-margin-5'>
                                                    Property ID:
                                                </span>
                                                <span
                                                    className='clickable-text'
                                                    onClick={e => this.navigate(e, `/properties/${_.get(propertyCommData, 'property_id')}/edit`)}
                                                >
                                                    {_.get(propertyCommData, 'property_id') || ''}
                                                </span>
                                            </span>
                                            <span><i className='icon-house-icon'></i></span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <PropertyRoomInfo
                                propertyData={propertyCommData}
                            />
                            <Row>
                                <Col xs={12} sm={12} className='top-margin flex-align-right'>
                                    <PrimaryButton
                                        cssClass="blue-btn right-margin"
                                        fullWidth={false} 
                                        type="button" 
                                        onClick={(e) => this.navigate(e, `/properties/${_.get(propertyCommData, 'property_id')}/edit`)}
                                    >
                                        <span className='right-margin-5'>View Property</span> 
                                        <span><i className='icon-open-new-tab'></i></span>
                                    </PrimaryButton>
                                    <PrimaryButton
                                        cssClass="blue-btn "
                                        fullWidth={false} 
                                        type="button" 
                                        onClick={(e) => this.navigate(e, `/calendar/${_.get(propertyCommData, 'property_id')}/edit`)}
                                    >
                                        <span className='right-margin-5'>View Calendar</span>
                                        <span><i className='icon-open-new-tab'></i></span> 
                                    </PrimaryButton>
                                </Col>
                            </Row>
                            {
                                !_.isEmpty(reservationCommData) &&
                                <Fragment>
                                    <Row>
                                        <Col xs={12} sm={12}>
                                            <hr/>
                                            <div>
                                                <span>
                                                    <span className='right-margin-5'>Reservation</span>
                                                </span>
                                            </div>
                                            <div className='display-flex'>
                                                <div className='right-margin-5'>
                                                    <strong>{`${_.get(reservationCommData, 'first_name') || ''} ${_.get(reservationCommData, 'last_name') || ''}`}</strong>
                                                </div>
                                                <div>
                                                    | <a 
                                                        className='clickable-text' 
                                                        href={`mailto:${_.get(reservationCommData, 'to_guest_email') || ''}`}
                                                    >
                                                        {_.get(reservationCommData, 'to_guest_email') || ''}
                                                    </a>
                                                </div>
                                            </div>
                                            <div className='flex-space-between'>
                                                <div>
                                                    Reservation ID: <strong>{_.get(reservationCommData, 'reservation_id') || ''}</strong>
                                                </div>
                                                <div>
                                                    Guest Cancellation Policy: 100% Refundable Until {moment(_.get(reservationCommData, 'cancellation_policy.last_refundable_datetime')).format('M/D/YY @ hA') || ''}
                                                </div>
                                            </div>
                                            <div className='flex-space-between'>
                                                <div>
                                                    Booked On: <strong>{_.get(reservationCommData, 'channel_name') || ''}</strong>
                                                </div>
                                                <div>
                                                    Host Cancellation Policy: 100% Refundable Until {moment(_.get(reservationCommData, 'channel_cancellation_policy.last_refundable_datetime')).format('M/D/YY @ hA') || ''}
                                                </div>
                                            </div>
                                            <div className='flex-space-between'>
                                                <div>
                                                    Door Code: <strong>{_.get(reservationCommData, 'door_code') || ''}</strong>
                                                </div>
                                                <div>
                                                    <span className='right-margin-5'>Identity:</span>
                                                    {this.renderRequestIdentification()}
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    WiFi Name: <strong>{_.get(reservationCommData, 'wifi_name') || ''}</strong>
                                                </div>
                                                <div>
                                                    WiFi Password: <strong>{_.get(reservationCommData, 'wifi_password') || ''}</strong>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={12} className='top-margin-30'>
                                            <div className='thread-reservation-box-container'>
                                                <Col xs={6} sm={4}>
                                                    <div>Checked-In:</div>
                                                    <div><strong>{_.get(reservationCommData, 'checkin_date') || ''}</strong></div>
                                                </Col>
                                                <Col xs={6} sm={4}>
                                                    <div>Check-Out:</div>
                                                    <div><strong>{_.get(reservationCommData, 'departure_date') || ''}</strong></div>
                                                </Col>
                                                <Col xs={6} sm={4}>
                                                    <div>Nights:</div>
                                                    <div><strong>{_.get(reservationCommData, 'number_of_nights') || ''}</strong></div>
                                                </Col>
                                                <Col xs={6} sm={4}>
                                                    <div>Guests:</div>
                                                    <div><strong>{_.get(reservationCommData, 'number_of_guests') || ''}</strong></div>
                                                </Col>
                                                <Col xs={6} sm={4}>
                                                    <div>Date Booked:</div>
                                                    <div><strong>{_.get(reservationCommData, 'book_date') || ''}</strong></div>
                                                </Col>
                                                <Col xs={6} sm={4}>
                                                    <div>Property:</div>
                                                    <div 
                                                        className='clickable-text'
                                                        onClick={e => this.navigate(e, `/properties/${_.get(reservationCommData, 'property_id')}/edit`)}
                                                    >
                                                        {_.get(reservationCommData, 'property_id') || ''}
                                                    </div>
                                                </Col>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={12} className='top-margin-30 flex-align-right'>
                                            <PrimaryButton
                                                cssClass="blue-btn "
                                                fullWidth={false} 
                                                type="button" 
                                                onClick={(e) => this.navigate(e, `/reservations/${_.get(reservationCommData, 'reservation_id')}/details`)}
                                            >
                                                <span className='right-margin-5'>View Reservation</span>
                                                <span><i className='icon-open-new-tab'></i></span>
                                            </PrimaryButton>
                                        </Col>
                                    </Row>
                                </Fragment>
                            }
                            {
                                (_.isObject(reservationCommData) && 
                                !_.isEmpty(reservationCommData)) &&
                                <Fragment>
                                    <Row>
                                        <Col xs={12} sm={12}>
                                        <hr/>
                                        <div className='host-notes-title'>
                                            <div>Host Notes:</div>
                                            {
                                                (!editHostNotes &&
                                                _.get(reservationCommData, 'host_notes')) &&
                                                <i onClick={(e) => this.toggleState(e, 'editHostNotes')} className='icon-edit'></i>
                                            }
                                        </div>
                                        </Col>
                                        <Col xs={12} sm={12} className="host-notes-container">
                                            {
                                                (editHostNotes ||
                                                (!editHostNotes &&
                                                !_.get(reservationCommData, 'host_notes'))) ?
                                                <InputField
                                                    cssClass="text-area"
                                                    type="textarea"
                                                    name="attribute_value"
                                                    value={hostNotes}
                                                    onChange={(e) => this.hostNotesOnChange(e)}
                                                />
                                                :
                                                <pre>{ _.get(reservationCommData, 'host_notes')}</pre>

                                            }
                                        </Col>
                                    </Row>
                                    {
                                        (editHostNotes ||
                                        !_.get(reservationCommData, 'host_notes')) &&
                                        <Row>
                                            <Col xs={12} sm={12} className='top-margin-5 flex-align-right'>
                                                {
                                                    (editHostNotes ||
                                                    (!editHostNotes &&
                                                    _.get(reservationCommData, 'host_notes'))) &&
                                                    <PrimaryButton
                                                        cssClass="white-btn right-margin"
                                                        fullWidth={false} 
                                                        type="button" 
                                                        onClick={(e) => this.toggleState(e, 'editHostNotes')}
                                                    >
                                                        Cancel
                                                    </PrimaryButton>
                                                }
                                                <PrimaryButton
                                                    cssClass="blue-btn "
                                                    fullWidth={false} 
                                                    type="button" 
                                                    onClick={this.submitHostNotes}
                                                    disabled={!hostNotes.length}
                                                >
                                                    {`${(!editHostNotes && !_.get(reservationCommData, 'host_notes')) ? 'Add' : 'Edit'}`} Note
                                                </PrimaryButton>
                                            </Col>
                                        </Row>
                                    }
                                    <Row>
                                        {
                                            (hostNotesErr.length > 0) &&
                                            this.renderMsg(hostNotesErr, '_error')
                                        }
                                    </Row>
                                </Fragment>
                            }
                        </div>
                    </Col>
                </Row>
                {
                    this.view == 'db' &&
                    <Row>
                        <Col xs={12} sm={12} className='thread-modal-btn-container'>
                            <Col xs={12} sm={12} md={6} className='thread-modal-btn-left'>
                                <button
                                    className={`primary-btn white-btn full-width ${markAsUnread ? 'brand-btn' : ''}`}
                                    type="button" 
                                    onClick={(e) => this.toggleState(e, 'markAsUnread')}
                                >
                                    Mark As Unread
                                </button>
                                {this.renderPicky('Status', this.markStatusOptions, markStatus, this.handleMarkStatus)}
                                {this.renderPicky('Priority', this.markPriorityOptions, markPriority, this.handleMarkPriority)}
                            </Col>
                            <Col xs={12} sm={12} md={6} className='thread-modal-btn-right'>
                                <button 
                                    type="button"
                                    className="primary-btn white-btn right-margin"
                                    onClick={this.handleCloseMsgModal}
                                >
                                    Close Window
                                </button>
                                <PrimaryButton
                                    cssClass={`white-btn right-margin`}
                                    fullWidth={false} 
                                    type="button" 
                                    onClick={(e) => this.navigate(e, `/threads/${_.get(modalData, 'thread_id')}`)}
                                >
                                    <i className='icon-open-new-tab'></i>
                                </PrimaryButton>
                                <PrimaryButton
                                    cssClass={`white-btn right-margin`}
                                    fullWidth={false} 
                                    type="button" 
                                    onClick={() => navigator.clipboard.writeText(`https://${this.baseUrl}/threads/${_.get(modalData, 'thread_id')}`)}
                                >
                                    <i className='icon-link'></i>
                                </PrimaryButton>
                            </Col>
                        </Col>
                    </Row>
                }
            </Fragment>
        )
    }

    render() {
        const { 
            modal,
            reservations_columns,
            reservationsData,
            reservationsTotalCount,
            reservationsActivePage,
            reservationsIsLoading,
            propertiesData,
            propertiesIsLoading,
            pageSize,
            showAllOrgReservation,
            showAllOrgEarnings,
            showAllOrgRevenue,
            last30Days,
            graphData,
            earningsIsLoading,
            revenueIsLoading,
            totalRevenue,
            threadData,
            threadPageSize,
            threadActivePage,
            threadColumns,
            threadSorted,
            threadIsLoading,
            showAllOrgThread,
            threadLoading,
            messageIsLoading,
            showOnlyUnread,
            threadDates,
            focusedInput,
            threadKeyword,
            dashboardTabs,
            commCenterAccess,
            organizationData,
            priorityFilters,
            statusFilters,
            pendingReviews,
            reservationCommData,
            guestVerificationModalIsLoading,
            guestVerificationError,
            guestVerificationModal,
            propertyCommData,
            errorMsg
        } = this.state;

        return (
            <PageLayout isCentered={true}>

            {messageIsLoading && <Loader/>}
            
            <Title 
                title="Dashboard"
                isCentered={true}
                breadcrumbItems={this.breadcrumbItems}
            >
                {
                    (this.isAdmin) &&
                    (propertiesData.length > 0) &&
                    <Link className='primary-btn' to='/properties/create'>
                        <i className='icon-Plus create-icon' />
                        Create Property
                    </Link>
                }
            </Title>
            {
                this.view == 'db' ?
                <div id='content' className='dashboard'>
                    <div className="container-fluid">
                        <FieldWrap>
                            <Row>
                                <Col xs={12} className='tab-container'>
                                    <Tabs 
                                        activeKey={dashboardTabs} 
                                        id="uncontrolled-tab-example" 
                                        className="mb-3"
                                        onSelect={(value) => this.toggleTabs(value)}
                                    >
                                        <Tab 
                                            eventKey="dashboard" 
                                            title={<i className="icon-dashboard"></i>}
                                        >
                                        </Tab>
                                        {
                                            (this.viewReviews ||
                                            this.viewSubscriptions ||
                                            this.viewDevices) &&
                                            <Tab 
                                                eventKey="comm-management" 
                                                title="Communication & Management"
                                            >
                                            </Tab>
                                        }
                                        {
                                            (_.get(organizationData, 'property_manager_details.payout_period_details.periodic_payouts') &&
                                            this.viewPayments) &&
                                            <Tab 
                                                eventKey="earnings" 
                                                title="Adjustment & Payouts"
                                            >
                                            </Tab>
                                        }
                                        {
                                            this.viewReviews &&
                                            <Tab 
                                                eventKey="reviews" 
                                                title={
                                                    <div className='reviews-title-tab'>
                                                        <div>Reviews</div>
                                                        {
                                                            (pendingReviews > 0) &&
                                                            <div className='pending-count'>
                                                                {pendingReviews}
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                            ></Tab>
                                        }
                                        <Tab 
                                            eventKey="calendar" 
                                            title="Calendar"
                                        >
                                        </Tab>
                                        <Tab 
                                            eventKey="reservations" 
                                            title="Reservations"
                                        >
                                        </Tab>
                                    </Tabs>
                                </Col>
                            </Row>
                            <Row>
                                <CommunicationManagement
                                    dashboardTabs={dashboardTabs}
                                    org={this.org}
                                    isInternalPropertyAdmin={this.props.isInternalPropertyAdmin}
                                />
                            </Row>
                            <Row>
                                <AdjustmentsPayouts
                                    dashboardTabs={dashboardTabs}
                                />
                            </Row>
                            <Row>
                                <Reviews
                                    view={'dashboard'}
                                    dashboardTabs={dashboardTabs}
                                    pendingReviews={pendingReviews}
                                    getPendingReviews={this.getPendingReviews}
                                />
                            </Row>
                            {
                                !propertiesIsLoading &&
                                this.org !== "_global_" &&
                                (dashboardTabs == 'dashboard') &&
                                (propertiesData.length == 0) &&
                                (this.allOrganizations.length <= 1) &&
                                <Row>
                                    <Col md={12} sm={12}> 
                                        <Col md={12} sm={12} className="getting-started custom-container">
                                            <Row>
                                                <Col md={12} sm={12}>
                                                    <div className="title-container ">
                                                        <div className="ra-logo"></div> 
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12} sm={12} className="flex-align-center">
                                                    <iframe
                                                        src="https://www.youtube.com/embed?si=AUKdMEbbWnmZQBje&rel=0&playlist=uQYrQbMAOsg&loop=1"
                                                        width="560"
                                                        height="315"
                                                        title="RedAwning Portal Overview"
                                                        allow="autoplay; fullscreen;"
                                                        className="no-border"
                                                    >
                                                    </iframe>                         
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12} sm={12} className="flex-align-center top-margin">
                                                    <h2>Need help getting started?</h2>
                                                    <Link className="clickable-text" to={"/account/faq"}>FAQ</Link>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <hr/>
                                            </Row>
                                            {
                                                (this.isAdmin) &&
                                                <Row>
                                                    <Col md={12} sm={12} className="flex-align-center">
                                                        <Link
                                                            className="primary-btn "
                                                            to={"/properties/create"}
                                                        >
                                                            <i className="icon-Plus create-icon"/>
                                                            Create Property
                                                        </Link>
                                                    </Col>
                                                </Row>
                                            }
                                        </Col>
                                    </Col>
                                </Row>
                            }
                            {
                                <div className={dashboardTabs != 'dashboard' ? 'hide-view' : ''}>
                                {
                                    (commCenterAccess && this.viewMessages) &&
                                    <Row>
                                        <Col>
                                            <Col md={12} sm={12}>
                                                <Col md={12} sm={12} className="db-comm-center custom-container">

                                                    {((dashboardTabs == 'dashboard') && threadIsLoading) && <Loader/>}

                                                    <Row>
                                                        {
                                                            (dashboardTabs == 'dashboard') &&
                                                            <Col md={12} sm={12} className='comm-center-filter'>
                                                                <Col lg={12} md={12} sm={12} className='filter-row'>
                                                                    <div className='priority-label'>Status:</div>
                                                                    <div className="filters-wrap">
                                                                        <InputField 
                                                                            type="checkbox" 
                                                                            value={statusFilters.new} 
                                                                            cbChange={() => this.handleStatusFilters("new")}>
                                                                            New
                                                                        </InputField>
                                                                    </div>
                                                                    <div className="filters-wrap">
                                                                        <InputField 
                                                                            type="checkbox" 
                                                                            value={statusFilters.complete} 
                                                                            cbChange={() => this.handleStatusFilters("complete")}>
                                                                            Complete
                                                                        </InputField>
                                                                    </div>
                                                                    <div className="filters-wrap">
                                                                        <InputField 
                                                                            type="checkbox" 
                                                                            value={statusFilters.in_progress} 
                                                                            cbChange={() => this.handleStatusFilters("in_progress")}>
                                                                            In Progress
                                                                        </InputField>
                                                                    </div>
                                                                    <div className="filters-wrap">
                                                                        <InputField 
                                                                            type="checkbox" 
                                                                            value={statusFilters.responded} 
                                                                            cbChange={() => this.handleStatusFilters("responded")}>
                                                                            Responded
                                                                        </InputField>
                                                                    </div>
                                                                    <div className="filters-wrap">
                                                                        <InputField 
                                                                            type="checkbox" 
                                                                            value={statusFilters.reopened} 
                                                                            cbChange={() => this.handleStatusFilters("reopened")}>
                                                                            Reopened
                                                                        </InputField>
                                                                    </div>
                                                                    <div>
                                                                        <span className='splitter'></span>
                                                                    </div>
                                                                    <div className='priority-label'>Priority:</div>
                                                                    <div className="filters-wrap">
                                                                        <InputField 
                                                                            type="checkbox" 
                                                                            value={priorityFilters.critical} 
                                                                            cbChange={() => this.handlePriorityFilter("critical")}>
                                                                            Critical
                                                                        </InputField>
                                                                    </div>
                                                                    <div className="filters-wrap">
                                                                        <InputField 
                                                                            type="checkbox" 
                                                                            value={priorityFilters.high} 
                                                                            cbChange={() => this.handlePriorityFilter("high")}>
                                                                            High
                                                                        </InputField>
                                                                    </div>
                                                                    <div className="filters-wrap">
                                                                        <InputField 
                                                                            type="checkbox" 
                                                                            value={priorityFilters.medium} 
                                                                            cbChange={() => this.handlePriorityFilter("medium")}>
                                                                            Medium
                                                                        </InputField>
                                                                    </div>
                                                                    <div className="filters-wrap">
                                                                        <InputField 
                                                                            type="checkbox" 
                                                                            value={priorityFilters.low} 
                                                                            cbChange={() => this.handlePriorityFilter("low")}>
                                                                            Low
                                                                        </InputField>
                                                                    </div>
                                                                    <div>
                                                                        <span className='splitter'></span>
                                                                    </div>
                                                                    <div className="filters-wrap">
                                                                        <InputField 
                                                                            type="checkbox" 
                                                                            value={showOnlyUnread} 
                                                                            cbChange={() => this.handleShowAllOrg("showOnlyUnread", this.getThreads)}>
                                                                            Show only Unread
                                                                        </InputField>
                                                                    </div>
                                                                    {
                                                                        this.allOrganizations.length > 1 && 
                                                                        this.org !== '_global_' && 
                                                                        this.org !== 'travelagencies' ?
                                                                        <Fragment>
                                                                            <div>
                                                                                <span className='splitter'></span>
                                                                            </div>
                                                                            <div className="filters-wrap">
                                                                                <InputField 
                                                                                    type="checkbox" 
                                                                                    value={showAllOrgThread} 
                                                                                    cbChange={() => this.handleShowAllOrg("showAllOrgThread", this.getThreads)}>
                                                                                    All Organizations
                                                                                </InputField>
                                                                            </div>
                                                                        </Fragment>
                                                                        : 
                                                                        ''
                                                                    }
                                                                </Col>
                                                                <Col lg={12} md={12} sm={12} className='filter-row no-wrap'>
                                                                    <DateRangePicker
                                                                        orientation={this.props.mobileMode ? "vertical" : "horizontal"}
                                                                        noBorder
                                                                        showClearDates={true}
                                                                        minimumNights={0}
                                                                        startDateId='start'
                                                                        endDateId='end'
                                                                        isOutsideRange={this.isOutsideRange}
                                                                        startDatePlaceholderText="MM/DD/YYYY"
                                                                        endDatePlaceholderText="MM/DD/YYYY"
                                                                        startDate={threadDates && threadDates.startDate}
                                                                        endDate={threadDates && threadDates.endDate}
                                                                        focusedInput={focusedInput}
                                                                        onFocusChange={focusedInput => this.setState({ focusedInput })}
                                                                        onDatesChange={this.handleDatesChange}
                                                                    />
                                                                    <form 
                                                                        className='search-box'
                                                                        onSubmit={e => this.handleSearch(e)}
                                                                    >
                                                                        <InputField 
                                                                            type='search' 
                                                                            placeholder='Search' 
                                                                            name='threadKeyword'
                                                                            value={threadKeyword}
                                                                            onChange={e => this.handleSearchChange(e)}
                                                                            handleClear={()=> {
                                                                                this.setState({
                                                                                    threadKeyword: ''
                                                                                }, () => this.getThreads(0));
                                                                            }} 
                                                                        />
                                                                    </form>
                                                                </Col>
                                                            </Col>
                                                        }
                                                    </Row>
                                                    <Row>
                                                        <Col md={12} sm={12}>
                                                            <ReactTableComponent
                                                                manual
                                                                minRows={0}
                                                                className='-highlight'
                                                                getRef={(r) => this.getRef(r, "threadsTable")}
                                                                reactTable={this.threadsTable}
                                                                noDataText={threadIsLoading ? "Loading..." : (!(commCenterAccess && this.viewMessages) ? 'Your are not Authorized to view messages for this Organization' : "No Message")}
                                                                showPagination={false}
                                                                data={threadData}
                                                                resizable={false}
                                                                columns={threadColumns}
                                                                pageSize={Number(threadPageSize)}
                                                                defaultSortDesc={true}
                                                                defaultSorted={[threadSorted]}
                                                                onSortedChange={this.onSortedChange}
                                                                getTrProps = {(state, rowInfo, column) => {
                                                                    const threadId = rowInfo && rowInfo.original && rowInfo.original.thread_id;
                                                                
                                                                    return {
                                                                        onClick: () => {
                                                                            this.handleOpenMsg(threadId)
                                                                        },
                                                                        style: {
                                                                            cursor: 'pointer'
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col 
                                                            md={12} sm={12} 
                                                            className="pagination-container top-margin-20"
                                                        >
                                                            <div className="page_size-input-container">
                                                                <div 
                                                                    className='page-size' 
                                                                >
                                                                    <span className='right-margin-5'>
                                                                        Rows per page:
                                                                    </span>
                                                                    <InputField
                                                                        className="pageSize"
                                                                        type='number' 
                                                                        name='pageSize'
                                                                        autoComplete="off"
                                                                        defaultValue={Number(threadPageSize)}
                                                                        onKeyUp={(e) => {
                                                                            let value = e.target.value;
    
                                                                            if(value > 100) {
                                                                                value = 100;
                                                                            }
                                                                            else if(value < 0) {
                                                                                value = 1;
                                                                            };
    
                                                                            if(value.length && e.keyCode === 13 && value !== 0 && Number.isInteger(Number(value))) {
                                                                                this.threadPageSizeOnChange(value)
                                                                            };
                                                                        }}
                                                                    /> 
                                                                    <span className='left-margin'>Page &nbsp; {threadActivePage}</span>
                                                                </div>
                                                                <button
                                                                    name="prev"
                                                                    className="transaction-pagination-btn"
                                                                    type="button"
                                                                    onClick={() => this.threadPageOnChange("firstPage")}
                                                                    disabled={threadActivePage == 1}
                                                                >
                                                                    <i className='icon-first-page'></i>
                                                                </button>
                                                                <button
                                                                    name="prev"
                                                                    className="transaction-pagination-btn"
                                                                    type="button"
                                                                    onClick={() => this.threadPageOnChange("prev")}
                                                                    disabled={threadActivePage == 1}
                                                                >
                                                                    Prev
                                                                </button>
                                                                <button
                                                                    name="next"
                                                                    className="transaction-pagination-btn"
                                                                    type="button"
                                                                    onClick={() => this.threadPageOnChange("next")}
                                                                    disabled={_.get(threadData, 'length') <= 0}
                                                                >
                                                                    Next
                                                                </button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Col>
                                        </Col>
                                    </Row>
                                }
                                    <Row>
                                        <Col>
                                            <Col md={12} sm={12}>
                                                <Col md={12} sm={12} className="db-reservations custom-container">
                                                    <Row>
                                                        <Col md={12} sm={12}>
                                                            <Col md={6} sm={6} xs={6} className="left">
                                                                <h2>
                                                                    Upcoming Reservations
                                                                </h2>
                                                            </Col>
                                                            <Col md={6} sm={6} xs={6} className="right">
                                                                {
                                                                    this.allOrganizations.length > 1 && 
                                                                    this.org !== '_global_' && 
                                                                    this.org !== 'travelagencies' ? 
                                                                    <div className="filters-wrap">
                                                                        <InputField 
                                                                            type="checkbox" 
                                                                            value={showAllOrgReservation} 
                                                                            cbChange={() => this.handleShowAllOrg("showAllOrgReservation", this.getReservations)}>
                                                                            All Organizations
                                                                        </InputField>
                                                                    </div> : 
                                                                    ''
                                                                }
                                                                <Link  
                                                                    to={{ pathname: "/reservations"}}
                                                                    className="db-reserve-link"
                                                                >
                                                                    View Reservations
                                                                </Link>
                                                            </Col>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12} sm={12}>
                                                            <ReactTableComponent
                                                                apiBase={true}
                                                                className='-highlight'
                                                                getRef={(r) => this.getRef(r, "reservationsTable")}
                                                                reactTable={this.reservationsTable}
                                                                noDataText={reservationsIsLoading ? "Loading..." : "No Upcoming Reservations"}
                                                                minRows={0}
                                                                data={reservationsData}
                                                                resizable={false}
                                                                activePage={reservationsActivePage}
                                                                columns={reservations_columns}
                                                                showPagination={true}
                                                                pageSize={pageSize}
                                                                totalCount={Number(reservationsTotalCount)}
                                                                disabledPageSize={true}
                                                                pageOnChange={(page) => this.pageOnChange(page, "reservations")}
                                                                loading={reservationsIsLoading}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Col>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} sm={12}>
                                            <Col md={12} sm={12} className="container earnings-revenue">
                                                <DashboardEarnings
                                                    allOrganizations={this.allOrganizations}
                                                    showAllOrgEarnings={showAllOrgEarnings}
                                                    last30Days={last30Days}
                                                    graphData={graphData}
                                                    earningsIsLoading={earningsIsLoading}
                                                    handleShowAllOrg={this.handleShowAllOrg}
                                                    getEarnings={this.getEarnings}
                                                />
                                            </Col>
                                        </Col>
                                        <Col md={6} sm={12}>
                                            <Col md={12} sm={12} className="container earnings-revenue">
                                                <DashboardRevenue
                                                    allOrganizations={this.allOrganizations}
                                                    revenueIsLoading={revenueIsLoading}
                                                    totalRevenue={totalRevenue}
                                                    showAllOrgRevenue={showAllOrgRevenue}
                                                    handleShowAllOrg={this.handleShowAllOrg}
                                                    getRevenue={this.getRevenue}
                                                />
                                            </Col>
                                        </Col>
                                    </Row>
                                </div>
                            }
                            <Modal 
                                show={modal == 'ask-question'} 
                                className='ask-question-modal' 
                                onHide={this.handleCloseMsgModal}
                                backdrop={threadLoading ? 'static' : true}
                                animation
                            >

                                {threadLoading && <Loader/>}

                                <Modal.Header closeButton>
                                </Modal.Header>
                                <Modal.Body>
                                    {this.renderThreadMsg()}
                                </Modal.Body>
                            </Modal>
                        </FieldWrap>
                    </div>
                </div>
                :
                <div id='content' className='dashboard'>
                    <div className="container-fluid">
                        <FieldWrap>
                            <div className='static-thread-info'>
                                {
                                    _.get(propertyCommData, 'property_id') ? 
                                    this.renderThreadMsg()
                                    :
                                    <div>
                                        {messageIsLoading ? 'Loading...' : 'No thread found.'}
                                    </div>
                                }
                            </div>
                        </FieldWrap>
                    </div>
                </div>
            }
            <Modal 
                show={modal == "error-modal"}
            >
                <Modal.Body>
                    <Row className="text-center bottom-margin">
                        <Col xs={12} className='msg-container _error'>
                            {errorMsg}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <PrimaryButton
                                cssClass="pull-right"
                                fullWidth={false}
                                onClick={() => this.setState({ modal: '' })}
                            >
                                Close
                            </PrimaryButton>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <Modal 
                show={guestVerificationModal}
                className="guest-verification-modal"
                onHide={() => this.handleGuestVerificationModal(false)}
                backdrop={'static'}
            >
                {guestVerificationModalIsLoading && <Loader/>}

                <Modal.Body>
                    <Row className="text-center bottom-margin">
                        <Col xs={12}>
                            <h2>Verify "<strong>{`${_.get(reservationCommData, 'first_name') || ''} ${_.get(reservationCommData, 'last_name') || ''}`}</strong>"?</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <PrimaryButton
                                cssClass="pull-right"
                                fullWidth={false}
                                onClick={this.submitGuestVerification}
                            >
                                Yes
                            </PrimaryButton>
                            <PrimaryButton 
                                cssClass='white-btn pull-right right-margin'
                                fullWidth={false} 
                                onClick={() => this.handleGuestVerificationModal(false)}
                            >
                                Cancel
                            </PrimaryButton>
                        </Col>
                    </Row>
                    <Row>
                        {
                            (guestVerificationError.length > 0) &&
                            this.renderMsg(guestVerificationError, '_error')
                        }
                    </Row>
                </Modal.Body>
            </Modal>
        </PageLayout>)
    }
}

const mapStateToProps = state => {
    return {
        permissions: state.authState.permissions,
        orgId: state.roleManager.orgId,
        org: state.roleManager.org,
        user: state.authState.user,
        roles: state.roleManager.roles,
        isInternalPropertyAdmin: state.roleManager.isInternalPropertyAdmin,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setRole: (role) => {
            dispatch(setRole(role));
        },
        updateOrganization: (org) => {
            dispatch(updateOrganization(org))
        },
        updateOrganizationTitle: (org) => {
            dispatch(updateOrganizationTitle(org))
        },
        updateOrganizationId: (orgId) => {
            dispatch(updateOrganizationId(orgId))
        },
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard));