import 'babel-polyfill/dist/polyfill.min.js';
import React from 'react';
import ReactDOM from 'react-dom';
import "isomorphic-fetch";
import BrowserRouter from 'react-router-dom/BrowserRouter'
import App from './components/App';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import commonBus from './js/reducers';

let store = createStore(commonBus);

ReactDOM.render((
    <Provider store={store}>
       	<BrowserRouter>
		    <div>	
            	<App />
		    </div>
  		</BrowserRouter>
    </Provider>
), document.getElementById('app'));