import React, { Component } from 'react';
import { API } from 'aws-amplify';
import Link from 'react-router-dom/Link';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import PanelGroup from 'react-bootstrap/lib/PanelGroup';
import Panel from 'react-bootstrap/lib/Panel';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Modal from 'react-bootstrap/lib/Modal';

import PageLayout from '../global/PageLayout';
import { Title } from '../global/Title';
import { FieldWrap, PrimaryButton } from '../global/forms/FormElements';
import Loader from '../global/Loader';
import ReactTableComponent from '../global/ReactTableComponent';
import { getSessionId } from '../../helpers/helpers';

class Cleaning extends Component {
    org = this.props.roleManager.org || "";
    email = this.props.user.contact_info.email || "";
    firstName = this.props.user.contact_info.first_name || "";
    lastName = this.props.user.contact_info.last_name || "";
    registered = false;
    propertyNids = [];
    breadcrumbItems = [
        { title: "Cleaning" }
    ];
    state = {
        isLoading: false,
        activePage: 1,
        activeKey: 1,
        cleaningData: [],
        modal: '',
        columns: [
            {
                Header: 'Reservation ID',
                accessor: 'rid',
                'sortable': false,
                'Cell': row=>{
                    const reservationId = row && row.original && row.original.rid;

                    return(
                        <div>
                            <Link 
                                className="clickable-text" 
                                to={`/reservations/${reservationId}/details`}
                            >
                                {reservationId}
                            </Link>
                        </div>
                    )
                }
            },
            {
                Header: 'Arrival Date',
                accessor: 'arrival_date',
                'sortable': false,
            },
            {
                Header: 'Departure Date',
                accessor: 'departure_date',
                'sortable': false,
            },
            {
                Header: 'URL',
                accessor: 'url',
                'sortable': false,
                'Cell': row=>{
                    let url = row && row.original && row.original.url;

                    return(
                        <div className="channels-link">
                            <OverlayTrigger 
                                placement='bottom' 
                                overlay={this.getTooltip('url', url)}>
                                    <a 
                                        target='_blank'
                                        className="clickable-text" 
                                        href={url}
                                    >
                                        {url}
                                    </a>
                            </OverlayTrigger>
                        </div>
                    )
                }
            },
            {
                Header: 'Cleaner Name',
                accessor: 'cleanerName',
                'sortable': false,
            },
            {
                Header: 'Status',
                accessor: 'status',
                'sortable': false,
            }
        ]
    }
    
    async componentWillMount() {
        this.setState({
            isLoading: true
        });

        await this.getCleaningData();

        await this.getProperties();

        this.setState({
            isLoading: false,
        });
    }

    getCleaningData = async () => {
        let response;

        try {
            response = await API.get("rptapi", `/reports/cleaning_status?organization=${this.org}`, { response: true, isCognito: true });
            if(response && response.data && response.data.items && response.data.items.length) {
                this.registered = true;
                this.setState({
                    cleaningData: response.data.items,
                })
            }
        }
        catch(e) {
            console.log(e)
            this.registered = false;
        }
    }

    getProperties = async () => {
        let data = {};
        let nids = [];
        let offset = 0;
        let limit = 200;
        const tid = getSessionId();

        try {
            data = await API.get("rapapi", `/promotion/properties?organizations=${this.org}&limit=${limit}&offset=${offset}&tid=${tid}`, { response: true, isCognito: true });
            
            if(data && data.data && data.data.list && data.data.list.length) {
                data.data.list.forEach((x) => {
                    nids.push(x.property_id);
                });

                if(data && data.headers && data.headers["x-total-count"] > 200) {
                    while(nids.length < data.headers["x-total-count"]) {
                        try {
                            offset = offset + 200;

                            data = await API.get("rapapi", `/promotion/properties?organizations=${this.org}&limit=${limit}&offset=${offset}&tid=${tid}`, { response: true, isCognito: true });
                            data.data.list.forEach((x) => {
                                nids.push(x.property_id);
                            });
                        }
                        catch(e) {
                            console.log(e)
                        };
                    };
                };
                
                this.propertyNids = nids;
            };
        }
        catch(e) {
            console.log(e)
        }
    }

    getStartedNow = () => {
        let url = APIConfig.ENV === "DEV" ?
            "https://rondi.turno.com/redawning" 
            :
            "https://app.turno.com/redawning";
        
        window.open(`${url}?name=${encodeURIComponent(this.org)}&fname=${encodeURIComponent(this.firstName)}&lname=${encodeURIComponent(this.lastName)}&email=${encodeURIComponent(this.email)}&listing_ids=${encodeURIComponent(this.propertyNids.toString())}`, '_blank');
    }

    addProperty = () => {
        this.getStartedNow();

        this.handleModal('');
    }

    getTid = () => {
        const maximum = 99999
        const minimum = 10000
        return (Math.floor(Math.random() * ((maximum - minimum) + 1)) + minimum)
    }

    panelOnSelect = (activekey, e, customKey) => {
        if(e) e.preventDefault();
        
        this.setState({ 
            activeKey: customKey ? customKey : activekey,
        });
    }

    getTooltip = (id, text) => {
        return <Tooltip id={id}>{text}</Tooltip>
    }

    handlePropertyOnChange = (value) => {
        this.setState({
            selectedProperty: value,
        });
    }

    handleModal = (name) => {
        this.setState({
            modal: name
        });
    }

    createCleaningPanels = () => {
        const { 
            activePage,
            columns,
            isLoading,
            activeKey,
            cleaningData
        } = this.state;

        return cleaningData.map((data, i) => {
            let cleaningSchedule = [];
            
            if((data && data.previous !== null)) {
                cleaningSchedule.push(data && data.previous);
            };

            if((data && data.next !== null)) {
                cleaningSchedule.push(data && data.next);
            };

            return (
                <Panel eventKey={i + 1} key={i}>
                    <Panel.Heading>
                        <Panel.Title
                            onClick={e => this.panelOnSelect(null, e, (i + 1))}
                            toggle
                        >
                            {data.listing_id} &nbsp; | &nbsp; <span>{data.alias}</span>
                            <span className={'pull-right '+(activeKey === (i + 1) ? 'glyphicon glyphicon-chevron-up': 'glyphicon glyphicon-chevron-down')}></span>
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible>
                        <Col sm={12} md={12}>
                            <ReactTableComponent 
                                className='-highlight'
                                noDataText={isLoading ? "Loading..." : "No Data"}
                                minRows={0}
                                data={cleaningSchedule}
                                resizable={false}
                                activePage={activePage}
                                columns={columns}
                            />
                        </Col>
                    </Panel.Body>
                </Panel>
            )
        });
    }

    render() {
        const { 
            activeKey,
            isLoading,
            cleaningData,
            modal
        } = this.state;

        return (
            <PageLayout isCentered={true}>
            
            <Title 
                title="Cleaning"
                isCentered={true}
                breadcrumbItems={this.breadcrumbItems}
            >
                {
                    this.registered &&
                    <PrimaryButton 
                        className='primary-btn'
                        fullWidth={false}
                        onClick={() => this.handleModal('addProperty')}
                    >
                        <i className='icon-Plus create-icon' />
                        Add Properties
                    </PrimaryButton>
                }
            </Title>
            {
                isLoading && <Loader/>
            }
            {
                !isLoading &&
                <div id='content' className='cleaning'>
                    <div className="container-fluid">
                        <FieldWrap>
                            {
                                !this.registered ?
                                <Col md={12} sm={12} className="cleaning-container">
                                    <Row className="bottom-margin-30">
                                        <Col md={12} sm={12}>
                                            <h1>FIND AND SCHEDULE YOUR HOUSECLEANERS</h1>
                                        </Col>
                                        <Col md={12} sm={12}>
                                            <div className="title-container">
                                                <div className="redawning-tbnb-logo"></div>
                                            </div>
                                        </Col>
                                        <Col md={12} sm={12} className="flex-align-center">
                                            <h2>FREE SOLUTION FOR EXCLUSIVE REDAWNING PROPERTIES.</h2>
                                        </Col>
                                        <Col md={12} sm={12} className="flex-align-center">
                                            <h2 style={{ "color": "#AC2431" }}>($10/month/property for non-exclusive properties)</h2>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4} sm={12} className="bottom-margin-30">
                                            <Col md={12} sm={12}>
                                                <div className="image-container">
                                                    <div className="tbnb-image tbnb-scheduling"></div>
                                                </div>
                                            </Col>
                                            <Col md={12} sm={12}>
                                                <h2>Automatic cleaner scheduling! No more calling, texting, emailing, or reminders.</h2>
                                            </Col>
                                            <Col md={12} sm={12}>
                                                <p>RedAwning is seamlessly integrated with TurnoverBnb to create cleaning projects for every guest stay.  Your approved cleaners are automatically notified and confirm every project so you don't have to worry.</p>
                                            </Col>
                                        </Col>
                                        <Col md={4} sm={12} className="bottom-margin-30">
                                            <Col md={12} sm={12}>
                                                <div className="image-container">
                                                    <div className="tbnb-image how-marketplace-works"></div>
                                                </div>
                                            </Col>
                                            <Col md={12} sm={12}>
                                                <h2>Bring Your Own or Choose from 25,000+ Trusted Cleaners in Our Network.</h2>
                                            </Col>
                                            <Col md={12} sm={12}>
                                                <p>You are free to register and work with your own cleaners, or you can get bids from other highly reviewed cleaners in our network.  You can even set up automatic payments to network cleaners!</p>
                                            </Col>
                                        </Col>
                                        <Col md={4} sm={12} className="bottom-margin-30">
                                            <Col md={12} sm={12}>
                                                <div className="image-container">
                                                    <div className="tbnb-image tbnb-badage"></div>
                                                </div>
                                            </Col>
                                            <Col md={12} sm={12}>
                                            <h2>Covid 19 Ready.</h2>
                                            </Col>
                                            <Col md={12} sm={12}>
                                                <p>All cleaners in the Network have completed Covid 19 Training.  You can ask your own cleaners to complete the same training based on CDC Guidelines.</p>
                                            </Col>
                                        </Col>
                                    </Row>
                                    <hr/>
                                    <Row className="flex-align-center">
                                        <Col>
                                            <PrimaryButton 
                                                cssClass="col-md-12 " 
                                                type="button"
                                                onClick={this.getStartedNow}
                                            >
                                                Get Started Now
                                            </PrimaryButton>
                                        </Col>
                                    </Row>
                                </Col>
                                :
                                <Col sm={12} md={12}>
                                    <Row>
                                        <Col md={12} sm={12} className="cleaning-container">
                                            <div className="title-container">
                                                <div className="redawning-tbnb-logo"></div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {
                                            cleaningData.length > 0 ?
                                            <PanelGroup 
                                                accordion id="accordion-controlled-example"
                                                activeKey={activeKey}
                                                onSelect={activeKey => this.panelOnSelect(activeKey)}
                                            >
                                                {this.createCleaningPanels()}
                                            </PanelGroup>
                                            :
                                            <div className="no-collection">
                                                No Cleaning Data
                                            </div>
                                        }
                                    </Row>
                                </Col>
                            }
                        </FieldWrap>
                    </div>
                    <Modal
                        className='cleaning-modal'
                        show={modal === 'addProperty'}
                        onHide={() => this.handleModal('')}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Add Properties</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col xs={12}>
                                    <span>Add new properties to TurnoverBnb.</span>
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col xs={12}>
                                    <PrimaryButton 
                                        onClick={this.addProperty}
                                        fullWidth={false}
                                        cssClass="pull-right" 
                                    >
                                        Add
                                    </PrimaryButton>   
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                </div>
            }
        </PageLayout>)
    }
}

const mapStateToProps = state => {
    return {
        roleManager: state.roleManager,
        user: state.authState.user
    }
}

export default withRouter(connect(
    mapStateToProps,
    null
)(Cleaning));