import React from 'react';
import Col from 'react-bootstrap/lib/Col';
import Dropzone from 'react-dropzone';
import PhotoCaption from './PhotoCaption';
import BrokenImg from '../../../assets/icons/broken-img.svg';
import ImageIcon from '../../../assets/icons/image-icon.svg';
import Loader from '../../global/Loader';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';

export default class PhotoGallery extends React.Component {

    constructor(props) {
        super(props);
        this.pictures = [];
        this.dragEffect = {
            'hover': null,
            'is-dragging': null
        }
        this.state = {
            editCaption: false,
            dragState: {},
            dimensions: {},
            dropZoneOver: false,
            propertyContentLocks: props.propertyContentLocks,
            integrationsDataByOrgName: (props.integrationsDataByOrgName && props.integrationsDataByOrgName.integration_name) ? props.integrationsDataByOrgName : {"integration_name": "racalendar"},
        }

        this.changePhoto = this.changePhoto.bind(this);
        this.updatePhotos = this.updatePhotos.bind(this);
        this.updateImage = this.updateImage.bind(this);
        this.onDragStart = this.onDragStart.bind(this);
        this.onDragOver = this.onDragOver.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.dblClickHandler = this.dblClickHandler.bind(this);
        this.toggleDialog = this.toggleDialog.bind(this);
        this.resetDragEffect = this.resetDragEffect.bind(this);
        this.updateDragEffect = this.updateDragEffect.bind(this);
    }

    toggleImagesLockState(lockName, lockValue) {
        let {propertyContentLocks} = this.state

        let strLockValue = lockValue.toString()
        let lockedIndex = propertyContentLocks.images.LOCKED.indexOf(strLockValue)
        let unLockedIndex = propertyContentLocks.images.UNLOCKED.indexOf(strLockValue)

        if (lockedIndex > -1) {
            propertyContentLocks.images.LOCKED.splice(lockedIndex, 1)
        }
        if (unLockedIndex > -1) {
            propertyContentLocks.images.UNLOCKED.splice(unLockedIndex, 1)
        }

        if (lockName === 'LOCKED') {
            propertyContentLocks.images.LOCKED.push(strLockValue)
        } else {
            propertyContentLocks.images.UNLOCKED.push(strLockValue)
        }

        this.setState({
            propertyContentLocks,
        })

        this.props.updateLocks('images', propertyContentLocks.images)
    }

    /**
     * For reset drag effect of photos. 
     */
    resetDragEffect() {
        this.dragEffect = {
            'drag-hover': null,
            'is-dragging': null
        }
        this.setState({
            dropZoneOver: false
        })
        this.updateDragEffect();
    }

    /**
     * Toggle caption modal
     * @param {boolean} open for open or close modal. 
     */
    toggleDialog(open) {
        let editCaption = open ? true : false;
        if(!open) {
            this.setState({
                activeImage: null
            })
        }
        this.setState({
            editCaption
        });
    }

    updateImage(caption, undefined, newImage, blob, updateImage) {
        this.props.updateImage(caption, this.state.activeIndex, newImage, blob, updateImage);
    }

    /**
     * Double click on image handler to open caption dialog.  
     * @param {*} ev Image data.
     */
    dblClickHandler(ev) {
        let activeImage = this.props.photos[ev.target.dataset.index];
        this.setState({
            activeImage,
            activeIndex: ev.target.dataset.index
        });
        this.toggleDialog(true);
    }

    /**
     * Select image will send index of image to parent component
     * @param {number} index 
     */
    selectImage(index) {
        this.props.selectImage(index);
    }

    /**
     * For change photo 
     * @param {*} files new file which have to add 
     */
    async changePhoto(files) {
        this.props.changePhoto(files);
        this.resetDragEffect();
    }

    /**
     * Invoke when image drag start.
     * @param {*} ev 
     */
    onDragStart(ev) {
        this.dragEffect['is-dragging'] = ev.target.dataset.index;
        this.updateDragEffect();
        ev.dataTransfer.setData('sourceIndex',ev.target.dataset.index)
    }

    /**
     * Invoke when drag over event.
     * @param {*} ev 
     */
    onDragOver(ev) {
        let dragState = this.state.dragState;
        this.dragEffect['drag-hover'] = ev.target.dataset.index;
        this.updateDragEffect();
    }

    /**
     * Invoke when drop event occur in image for swap images.
     * @param {*} ev 
     */
    onDrop(ev) {
        this.props.swapImages(+ev.dataTransfer.getData('sourceIndex'), +ev.target.dataset.index);
        this.resetDragEffect();
    }

    /**
     * Invoke function when drag event end.
     * @param {*} ev 
     */
    onDragEnd(ev) {
        this.resetDragEffect();
    }

    /**
     * Update drag effect call after drag and drop event over.
     * @param {number} index 
     */
    updateDragEffect(index) {
        let dragState = this.state.dragState;
        let dragEffect = this.dragEffect;
        dragState = {};
        if(dragEffect && Object.keys(dragEffect).length !== 0) {
            for(let effect in dragEffect) {
                if( dragEffect[effect] != null ) {
                    dragState[dragEffect[effect]] = effect;
                }
            }
        }
        this.setState({
            dragState
        });
    }

    /**
     * For set image dimensions
     * @param {*} ev 
     */
    imageLoadHandler(ev) {
        try {
            let image = ev.target;
            let index = ev.target.dataset.index;
            let dimensions = this.state.dimensions;
            dimensions[index] = image.naturalWidth + 'x' + image.naturalHeight;
            this.setState({
                dimensions
            });
        } catch(e) {
            console.log(e);
        }
    }

    /**
     * Invoke if Image drop out of bound.
     * @param {*} ev 
     */
    dropZoneOver(ev){
        if(this.dragEffect['is-dragging'] === null) {
            this.setState({
                dropZoneOver: true
            });
        }
    }

    /**
     * Drop zone end 
     * @param {*} ev 
     */
    dropZoneEnd(ev){
        this.setState({
            dropZoneOver: false
        });
    }

    /**
     * For update image after add or delete image events.
     */
    updatePhotos() {
        let pictures = [];
        let photosData = this.props.photos;
        let dragState = this.state.dragState || {};
        let priorityPhotos = {0: true, 1: true, 2: true, 3: true, 4: true, 5: true};

        if(photosData.length) {
            let selectedPictures = this.props.selectedPictures;
            for (let i = 0; i < photosData.length; i++) {         
                let image = photosData[i];
                let isSelectedClass = selectedPictures.indexOf(i) >= 0 ? ' is-selected' : '';
                let dragEffect = dragState[i] ? ' '+dragState[i] : '';
                let photoId = image.photo_id ? image.photo_id.toString() : ''; // Must cast to String type for includes check with value we get back in this.state.propertyContentLocks.images arrays
                let lockStatus = (() => {
                    if(this.props.isAdmin && this.state.propertyContentLocks && this.state.propertyContentLocks.images && photoId && this.state.integrationsDataByOrgName && this.state.integrationsDataByOrgName.integration_name && this.state.integrationsDataByOrgName.integration_name !== 'racalendar') {
                         if (this.state.propertyContentLocks.images.LOCKED.includes(photoId)) {
                            return 'LOCKED'
                         } else {
                            return 'UNLOCKED'
                         }
                    } else {
                        return ''
                    }                    
                })()
                let imagesrc = image.url ? 
                    image.url.replace("/sites/default/files/rental_property/","/sites/default/files/styles/850x567/public/rental_property/") : 
                    (image.status === "failure" ?  
                        BrokenImg : 
                        (photosData[i].status === "processing" ? ImageIcon : 'data:image/'+image.data_type+';base64,'+image.data));
                let containerOptions = {};

                if(this.props.isAdmin) {
                    containerOptions = {
                        draggable:true,
                        onDragStart: this.onDragStart,
                        onDragEnd: this.onDragEnd.bind(this),
                        onDrop: this.onDrop,
                        onDragEnter: this.onDragOver,
                        onDoubleClick: this.dblClickHandler
                    }
                }

                pictures.push(
                    <Col key={i} md={2} sm={6} xs={12}
                        className={"top-margin bottom-margin image-col"+isSelectedClass+dragEffect}
                        {...containerOptions}
                    >
                        <div className={`${priorityPhotos[i] ? "priority-border" : "no-priority-border"}`}>
                            <div 
                                className="upload-img-wrap" 
                                style={{cursor: !this.props.isAdmin && 'default'}}
                                
                            > 
                            {
                                photosData[i].status === "processing" ?
                                <div className="photo-loader">
                                    <Loader />
                                </div>
                                :
                                ""
                            } 
                                {
                                    (this.props.isAdmin && !this.props.photoIsProcessing) ? 
                                    <img 
                                        className={`upload-img ${photosData[i].status === "processing" ? "loading" : ""}`}
                                        src={imagesrc} 
                                        data-index={i} 
                                        onClick={()=> this.selectImage(i)}
                                        onLoad={this.imageLoadHandler.bind(this)}
                                    /> : 
                                    <img 
                                        className="upload-img" 
                                        src={imagesrc} 
                                    />
                                }
                            </div>
                        </div>
                        {
                            i !== 0 ? 
                                this.props.isAdmin ? 
                                <div 
                                    className="count" 
                                    onClick={()=> this.selectImage(i)}
                                >
                                    {i + 1}
                                </div> 
                                : 
                                <div 
                                    className="count" 
                                    style={{cursor: "default"}}
                                >
                                    <strong>{i + 1}</strong>
                                </div>
                            :
                            ""
                        }

                        {
                            i === 0 ? 
                            <span className="photo-star-container">
                                <i className="icon-star-full"><span className="photo-count">1</span></i>
                            </span> 
                            : 
                            ""
                        }

                        {(lockStatus === 'LOCKED' || lockStatus === 'UNLOCKED') && photoId ?
                        <div className="locks">                                
                            <OverlayTrigger placement='top' overlay={<Tooltip id={`tip-photos${photoId}`} className='properties_photos__tooltip'>																																							
                                {(lockStatus === 'LOCKED') ? 
                                    "Unlock image to enable PMS integration to overwrite this image." : 
                                    "Lock image to prevent PMS integration from overwriting this image."}
                                </Tooltip>}>
                                <a href="#" onClick={(e) => {
                                        this.toggleImagesLockState((lockStatus === 'LOCKED' ? 'UNLOCKED ': 'LOCKED'), photoId)
                                    }}>
                                    <i className={(lockStatus === 'LOCKED') ? 
                                        "icon-lock" : 
                                        "icon-unlock"} />
                                </a>
                            </OverlayTrigger>
                        </div> : ''}

                        {
                            (!image.url && image.status !== "failure") && 
                            (photosData[i].status !== "processing") &&
                            <div 
                                className="dimensions">
                                    {this.state.dimensions[i]}&nbsp;
                            </div>
                        }
                    </Col>
                );
            }
        }
        
        this.pictures = pictures;
    }

    render() {
        this.updatePhotos();
        return (
            <div className="dropzone-wrap">
                {
                    this.props.isAdmin &&
                    <Dropzone
                        className={`dropzone-box ${this.state.dropZoneOver ? "dropzone-active" : ""} ${this.props.photoIsProcessing ? "dropzone-image-processing" : ""}`}
                        disabled={this.props.photoIsProcessing ? true : false}
                        multiple={true}
                        accept="image/*"
                        onDrop={this.changePhoto.bind(this)}
                        onDragOver={this.dropZoneOver.bind(this)}
                        onDragLeave={this.dropZoneEnd.bind(this)}
                    >
                    </Dropzone>
                }
                {
                    this.pictures
                }
                {
                    (this.props.isAdmin && !this.props.photoIsProcessing) &&
                    <Col md={2} sm={6} xs={12} className="top-margin bottom-margin image-col add-btn-wrap">
                        <div className="no-priority-border">
                            <div className="img-add-box col-md-2 col-sm-6 col-xs-12">
                                <p className="plus-sign">+</p>
                            </div>
                        </div>
                    </Col>
                }
                { 
                    this.state.activeImage &&
                    <PhotoCaption
                        image={this.state.activeImage}
                        toggleDialog={this.toggleDialog}
                        updateImage={this.updateImage}
                        editCaption={this.state.editCaption}
                    />
                }
            </div>
        )
    }
}