import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Auth, API } from "aws-amplify";
import Form from "react-bootstrap/lib/Form";
import FormGroup from "react-bootstrap/lib/FormGroup";
import Col from "react-bootstrap/lib/Col";
import FormControl from "react-bootstrap/lib/FormControl";
import Button from "react-bootstrap/lib/Button";
import { updateLoginStatus } from "../../js/actions";
import { connect } from "react-redux";
import NewLogin from "./NewLogin";
import constants from "../../js/constants";
import OverlayTrigger from "react-bootstrap/lib/OverlayTrigger";
import Tooltip from "react-bootstrap/lib/Tooltip";
import { checkPasswordRule } from "../../helpers/helpers";
import _ from "lodash";

/** Login component. */
class LoginPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			password: "",
			code: "",
			isSigningIn: false,
			errorMsg: "",
			reqPassword: false,
			forgotPassword: false,
			showPassword: false,
			newPassword: false,
			user: null,
			errorCaptureCognitoId: "",
			errorCaptureemailAddress: "",
			errorCaptureroles: "",
			checkCapsLock: false,
			passwordRule: {
				lowercase: false,
				uppercase: false,
				specialChar: false,
				minimum: false,
				number: false
			}
		};
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.login = this.login.bind(this);
		this.showForgotPassword = this.showForgotPassword.bind(this);
		this.showLogin = this.showLogin.bind(this);
		this.handleForgotPassword = this.handleForgotPassword.bind(this);
		this.forgotPassword = this.forgotPassword.bind(this);
		this.handleSubmitPassword = this.handleSubmitPassword.bind(this);
		this.submitNewPassword = this.submitNewPassword.bind(this);
		this.userRegistration = this.userRegistration.bind(this);
	}
	componentDidMount() {
		["keydown", "keyup", "click"].forEach(event => {
			window.addEventListener(event, e => {
				if (e && e.keyCode === 20) {
					const capslock = e && e.getModifierState("CapsLock");

					this.setState({
						checkCapsLock: capslock
					});
				}
			});
		});
	}
	/** handle email change */
	handleEmailChange(e) {
		let emailValue = e.target.value;
		let emailRemoveSpaces = emailValue.replace(" ", "");
		this.setState({ email: emailRemoveSpaces });
	}
	/** Handle password change things here  */
	handlePasswordChange(e) {
		const value = e.target.value;
		const name = e.target.name;

		this.setState(
			{
				password: value
			},
			() => {
				if (name === "newPassword") {
					let passwordRule = checkPasswordRule(
						this.state.password,
						this.state.passwordRule
					);

					this.setState({
						passwordRule: passwordRule
					});
				}
			}
		);
	}
	/** Handle new password changes */
	handleCodeChange(e) {
		this.setState({ code: e.target.value });
	}
	/** Handle form submit click event */
	handleFormSubmit(e) {
		e.preventDefault();
		this.login();
	}
	/** Invoke when fail to login
	 * @param {*} err error information
	 */
	failed(err) {
		this.setState({
			isSigningIn: false,
			errorMsg: err.message
		});
	}
	/** Click on forgot password will show forgot password information */
	showForgotPassword() {
		this.setState({
			forgotPassword: true,
			newPassword: false,
			errorMsg: ""
		});
	}
	showPassword = () => {
		this.setState({
			showPassword: !this.state.showPassword
		});
	};
	getTooltip = (id, text) => {
		return <Tooltip id={id}>{text}</Tooltip>;
	};
	/** Show login page */
	showLogin(e) {
		if (e) e.preventDefault();
		this.setState({
			forgotPassword: false,
			newPassword: false,
			errorMsg: ""
		});
	}
	/** Handle forgot password button click event */
	handleForgotPassword(e) {
		e.preventDefault();
		this.setState({
			errorMsg: ""
		});
		this.forgotPassword();
	}
	/** Forgot password API call to send mail on user mail */
	async forgotPassword() {
		let sent;
		try {
			sent = await Auth.forgotPassword(this.state.email.toLowerCase());
		} catch (err) {
			this.failed(err);
		}
		if (sent) {
			this.setState({
				newPassword: true,
				password: ""
			});
		}
	}
	/** Handle submit new password */
	handleSubmitPassword(e) {
		e.preventDefault();
		this.submitNewPassword();
	}
	/** Submit new password after first login */
	async submitNewPassword() {
		try {
			await Auth.forgotPasswordSubmit(
				this.state.email.toLowerCase(),
				this.state.code,
				this.state.password
			);
			this.showLogin();
		} catch (err) {
			this.failed(err);
		}
	}

	loginErrorMessage = error => {
		let errMessage = {
			message: `We've encountered an error, please contact your account manager and give them the following information: 
                [ response:: ${JSON.stringify(this.props.permissionsResponse)}
                id:: ${JSON.stringify(this.state.errorCaptureCognitoId)}
                uid:: ${_.get(this.props.user, "uid", "")}
                roles:: ${JSON.stringify(this.state.errorCaptureroles)}
                email:: ${JSON.stringify(this.state.errorCaptureemailAddress)}
                error:: ${JSON.stringify(error.message)} ]
            }`
		};
		return errMessage;
	};

	isValid = () => {
		const { password, newPassword, email, passwordRule, code } = this.state;

		return newPassword
			? !email.length ||
					!code.length ||
					password.length < 8 ||
					(Object.values(passwordRule).filter(rule => {
						return rule == false;
					}).length
						? true
						: false)
			: false;
	};

	/** Login API call when click on login */
	async login(newUserPassword, newLogin) {
		const { email, password } = this.state;
		let user;

		this.setState({
			isSigningIn: true,
			errorMsg: "",
			newUserPassword: newUserPassword ? newUserPassword : "",
			errorCaptureemailAddress: email,
			errorCaptureroles: _.get(this.props.roleManager, "roles.length")
				? _.get(this.props.roleManager, "roles")
				: ""
		});

		try {
			await this.props.onLogin(false);

			// user returns cognitoUser - if the user is 'new' - it will return the value challengeName="NEW_PASSWORD_REQUIRED"
			user = await Auth.signIn(
				email.toLowerCase(),
				newUserPassword ? newUserPassword : password
			);
		} catch (err) {
			if (
				(err && err.code === "NotAuthorizedException") ||
				(err && err.code === "UserNotFoundException")
			) {
				if (err && err.message === "User does not exist.") {
					err.message = "Incorrect username or password";
				}
				this.failed(err);
			} else {
				this.failed(this.loginErrorMessage(err));
			}

			if (newLogin) {
				this.setState({
					user: null,
					isSigningIn: false
				});
				this.props.clearTokenId();
				this.props.history.push("/");
			}
		}

		// successful user sign In
		if (user) {
			let userResponseStringify = JSON.stringify(user).toLowerCase();
			let reg = /"aws.cognito.identity-id.us-west-2:[^"]+":"us-west-2:([^"]+)"/gm;
			let cognitoId = reg.exec(userResponseStringify);
			if (cognitoId && cognitoId.length && cognitoId[1]) {
				this.setState({
					errorCaptureCognitoId: cognitoId[1]
				});
			}

			await this.props.getPermissions();

			if (user.challengeName == "NEW_PASSWORD_REQUIRED") {
				if (!this.props.tokenId && this.props.tokenIdErrorMessage) {
					this.failed({ message: this.props.tokenIdErrorMessage });
				} else if (!this.props.tokenId) {
					this.failed({
						message:
							"Missing token_id. Please use the link in the RedAwning Registration email to proceed."
					});
				} else {
					this.setState({
						user
					});
				}
			} else {
				if (
					!this.props.tokenId &&
					!_.get(this.props.roleManager, "roles.length")
				) {
					let errMessage = {
						message: `It appears you don't have a role assigned to your account. Please contact your account manager and give them the following information: 
                            [ response:: ${JSON.stringify(
															this.props.permissionsResponse
														)}
                            id:: ${JSON.stringify(
															this.state.errorCaptureCognitoId
														)}
                            uid:: ${_.get(this.props.user, "uid", "")}
                            roles:: ${JSON.stringify(
															this.state.errorCaptureroles
														)}
                            email:: ${JSON.stringify(
															this.state.errorCaptureemailAddress
														)}
                            error:: no roles assigned ]
                        }`
					};
					this.failed(errMessage);
				} else if (_.get(this.props.roleManager, "roles.length")) {
					this.props.updateLoginStatus(true);
				} else {
					await this.userRegistration(this.props.tokenId);
				}
			}
		}

		this.setState({
			errorCaptureCognitoId: "",
			errorCaptureemailAddress: "",
			errorCaptureroles: ""
		});
	}
	/** User registration process */
	async userRegistration(tokenId) {
		if (tokenId) {
			try {
				await API.post(
					constants.ACCAPI.NAME,
					constants.ACCAPI.USER_REGISTRATION(tokenId),
					{ body: {} }
				);
				await this.login();
			} catch (e) {
				this.failed({ message: "Invalid TokenID" });
			}
		} else {
			this.failed({
				message:
					"Please follow the link sent in your email to finish the registration process"
			});
		}
	}
	/** Render forgot password form */
	renderForgotPassword() {
		const {
			email,
			code,
			password,
			showPassword,
			passwordRule,
			newPassword,
			checkCapsLock,
			isSigningIn,
			errorMsg
		} = this.state;

		return (
			<Form
				horizontal
				onSubmit={
					newPassword ? this.handleSubmitPassword : this.handleForgotPassword
				}
			>
				<FormGroup>
					<Col xs={6}>
						<img
							src="/app/assets/logo-2x.png"
							alt="RedAwning"
							className="img-responsive"
						/>
					</Col>
				</FormGroup>
				<div className="login-welcome">
					<span>Forgot Password</span>
					{newPassword && (
						<p>
							Enter the verification code you received via email, then create a
							new password for your account.
						</p>
					)}
				</div>
				<FormGroup>
					<Col xs={12}>
						<FormControl
							type="text"
							placeholder="Username"
							value={email}
							onChange={this.handleEmailChange.bind(this)}
						/>
					</Col>
				</FormGroup>
				{newPassword && (
					<Fragment>
						<FormGroup>
							<Col xs={12}>
								<FormControl
									type="text"
									placeholder="Verification Code"
									value={code}
									onChange={this.handleCodeChange.bind(this)}
								/>
							</Col>
						</FormGroup>
						<FormGroup controlId="formHorizontalPassword">
							<Col xs={12}>
								<FormControl
									name="newPassword"
									className="show-password-padding"
									type={!showPassword ? "password" : "text"}
									autoComplete="new-password"
									placeholder="New Password"
									value={password}
									onChange={this.handlePasswordChange.bind(this)}
								/>
								{checkCapsLock && (
									<OverlayTrigger
										placement="bottom"
										overlay={this.getTooltip("newPassword", "Caps-lock is on")}
									>
										<span className="show-caps-lock-on-container">
											<i className="icon-caps-lock-on" />
										</span>
									</OverlayTrigger>
								)}
								<OverlayTrigger
									placement="bottom"
									overlay={this.getTooltip(
										"showNewPassword",
										!showPassword ? "Show Password" : "Hide Password"
									)}
								>
									<span
										className="show-password-container"
										onClick={this.showPassword}
									>
										<i
											className={
												!showPassword ? "icon-eye-open" : "icon-eye-close"
											}
										/>
									</span>
								</OverlayTrigger>
							</Col>
						</FormGroup>
					</Fragment>
				)}
				<FormGroup>
					<Col xs={12}>
						<Button type="submit" disabled={this.isValid()}>
							{newPassword ? "Save Password" : "Forgot Password"}{" "}
							<span
								className={
									"glyphicon glyphicon-refresh" +
									(isSigningIn ? " spinning" : " hide")
								}
							></span>
						</Button>
					</Col>
				</FormGroup>
				{newPassword && (
					<Fragment>
						<Col xs={6}>
							<ul>
								<li className={passwordRule.lowercase ? "password-rule" : ""}>
									One lowercase character
								</li>
								<li className={passwordRule.uppercase ? "password-rule" : ""}>
									One uppercase character
								</li>
								<li className={passwordRule.number ? "password-rule" : ""}>
									One number
								</li>
							</ul>
						</Col>
						<Col xs={6}>
							<ul>
								<li className={passwordRule.specialChar ? "password-rule" : ""}>
									One special character
								</li>
								<li className={passwordRule.minimum ? "password-rule" : ""}>
									8 characters minimum
								</li>
							</ul>
						</Col>
					</Fragment>
				)}
				<FormGroup className="login-utils ">
					<Col xs={12} className="top-margin-20">
						<a
							style={{ cursor: "pointer" }}
							className="forgot-password"
							onClick={this.showLogin}
						>
							Return to Login
						</a>
					</Col>
				</FormGroup>
				{errorMsg && (
					<FormGroup>
						<Col xs={12}>
							<span
								className={"text-center " + (errorMsg ? "error-msg" : "hide")}
							>
								{errorMsg}
							</span>
						</Col>
					</FormGroup>
				)}
			</Form>
		);
	}
	/** Render login form  */
	renderLoginForm() {
		const {
			email,
			password,
			showPassword,
			checkCapsLock,
			isSigningIn,
			errorMsg
		} = this.state;

		return (
			<Form horizontal onSubmit={this.handleFormSubmit}>
				<FormGroup>
					<Col xs={6}>
						<img
							src="/app/assets/logo-2x.png"
							alt="RedAwning"
							className="img-responsive"
						/>
					</Col>
				</FormGroup>
				<p className="login-welcome">
					{!this.props.tokenId
						? "Welcome! Please login to your account."
						: "Welcome! Please login with your temporary password."}
				</p>
				<FormGroup>
					<Col xs={12}>
						<FormControl
							type="text"
							placeholder="Username"
							value={email}
							onChange={this.handleEmailChange.bind(this)}
						/>
					</Col>
				</FormGroup>
				<FormGroup controlId="formHorizontalPassword">
					<Col xs={12}>
						<FormControl
							name="password"
							className="show-password-padding"
							type={!showPassword ? "password" : "text"}
							placeholder={
								!this.props.tokenId ? "Password" : "Temporary Password"
							}
							value={password}
							onChange={this.handlePasswordChange.bind(this)}
						/>
						{checkCapsLock && (
							<OverlayTrigger
								placement="bottom"
								overlay={this.getTooltip("password", "Caps-lock is on")}
							>
								<span className="show-caps-lock-on-container">
									<i className="icon-caps-lock-on" />
								</span>
							</OverlayTrigger>
						)}
						<OverlayTrigger
							placement="bottom"
							overlay={this.getTooltip(
								"showPassword",
								!showPassword ? "Show Password" : "Hide Password"
							)}
						>
							<span
								className="show-password-container"
								onClick={this.showPassword}
							>
								<i
									className={!showPassword ? "icon-eye-open" : "icon-eye-close"}
								/>
							</span>
						</OverlayTrigger>
					</Col>
				</FormGroup>
				<FormGroup>
					<Col xs={12}>
						<Button
							type="submit"
							disabled={password.length < 8 || !email.length || isSigningIn}
						>
							Login{" "}
							<span
								className={
									"glyphicon glyphicon-refresh" +
									(isSigningIn ? " spinning" : " hide")
								}
							></span>
						</Button>
					</Col>
				</FormGroup>
				<FormGroup className="login-utils">
					{!this.props.tokenId ? (
						<Fragment>
							<Col xs={12}>
								<input type="checkbox" id="rememberMe" />
								<label htmlFor="rememberMe">Remember me</label>
							</Col>
							<Col xs={12} className="top-margin-10">
								<a
									style={{ cursor: "pointer" }}
									className="forgot-password"
									onClick={this.showForgotPassword}
								>
									Forgot Password
								</a>
							</Col>
						</Fragment>
					) : (
						<Col xs={12}>
							<Link
								onClick={this.props.clearTokenId}
								to={{ pathname: "/" }}
								className="clickable-text"
							>
								Already Registered?
							</Link>
						</Col>
					)}
				</FormGroup>
				{this.props.tokenIdErrorMessage && (
					<FormGroup>
						<Col xs={12}>
							<span
								className={
									"text-center " +
									(this.props.tokenIdErrorMessage ? "error-msg" : "hide")
								}
							>
								{this.props.tokenIdErrorMessage}
							</span>
						</Col>
					</FormGroup>
				)}
				{errorMsg && (
					<FormGroup>
						<Col xs={12}>
							<span
								className={"text-center " + (errorMsg ? "error-msg" : "hide")}
							>
								{errorMsg}
							</span>
						</Col>
					</FormGroup>
				)}
			</Form>
		);
	}

	render() {
		const { user, forgotPassword } = this.state;

		if (user) {
			return (
				<NewLogin user={user} tokenId={this.props.tokenId} login={this.login} />
			);
		}

		return (
			<div className="main-container">
				<div className="page-container form-wrap">
					{forgotPassword
						? this.renderForgotPassword()
						: this.renderLoginForm()}
				</div>
			</div>
		);
	}
}
const mapStateToProps = state => {
	return {
		user: state.authState.user,
		roleManager: state.roleManager
	};
};
const mapDispatchToProps = dispatch => {
	return {
		updateLoginStatus: isLoggedIn => {
			dispatch(updateLoginStatus(isLoggedIn));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
