import React, { Component, Fragment } from 'react';
import { API } from 'aws-amplify';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Modal from 'react-bootstrap/lib/Modal';
import ToggleButtonGroup from 'react-bootstrap/lib/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/lib/ToggleButton';
import PanelGroup from 'react-bootstrap/lib/PanelGroup';
import Panel from 'react-bootstrap/lib/Panel';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';

import { InputLabel, InputField, PrimaryButton } from '../global/forms/FormElements';
import Loader from '../global/Loader';
import ReactTableComponent from '../global/ReactTableComponent';
import Picky from 'react-picky';
import { delay } from '../../js/actions';
import { getSessionId } from '../../helpers/helpers';
import Pagination from 'react-js-pagination';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import _ from "lodash";

import Wifi_0 from '../../assets/icons/wifi-0-bar.svg'
import Wifi_1 from '../../assets/icons/wifi-1-bar.svg'
import Wifi_2 from '../../assets/icons/wifi-2-bar.svg'
import Wifi_3 from '../../assets/icons/wifi-3-bar.svg'
import Wifi_4 from '../../assets/icons/wifi-4-bar.svg'

class EditDevices extends Component {
    fahrenheitSymbol = '\u2109';
    celsiusSymbol = '\u2103';
    deviceErrorMsg = 'We encountered an issue while updating your device. Please contact customer support.';
    doorCodeErrorMsg = 'We encountered an issue while updating your door code. Please contact customer support.';
    LOCATION_TYPE = [
        { name: "location_type", value: 'door_code', label: 'Main Door' },
        { name: "location_type", value: 'lobby_door_code', label: 'Lobby Door' },
        { name: "location_type", value: 'side_door_code', label: 'Side Door' },
        { name: "location_type", value: 'back_door_code', label: 'Back Door' },
        { name: "location_type", value: 'gate_code', label: 'Gate' },
        { name: "location_type", value: 'garage_code', label: 'Garage' },
        { name: "location_type", value: 'hallway_code', label: 'Hallway' },
        { name: "location_type", value: 'dining_room_code', label: 'Dining Room' },
        { name: "location_type", value: 'kitchen_code', label: 'Kitchen' },
        { name: "location_type", value: 'bedroom_code', label: 'Bedroom' },
        { name: "location_type", value: 'game_room_code', label: 'Game Room' },
        { name: "location_type", value: 'attic_code', label: 'Attic' },
        { name: "location_type", value: 'upstairs_code', label: 'Upstairs' },
        { name: "location_type", value: 'downstairs_code', label: 'Downstairs' },
        { name: "location_type", value: 'upper_deck_code', label: 'Upper Deck' },
        { name: "location_type", value: 'lower_deck_code', label: 'Lower Deck' },
        { name: "location_type", value: 'cleaners_closet_code', label: 'Cleaners Closet' },
        { name: "location_type", value: 'closet_code', label: 'Closet' },
        { name: "location_type", value: 'utility_room_code', label: 'Utility Room' },
        { name: "location_type", value: 'garden_code', label: 'Garden' },
        { name: "location_type", value: 'lock_box_code', label: 'Lock Box' },
        { name: "location_type", value: 'pool_gate_code', label: 'Pool Gate' },
        { name: "location_type", value: 'fitness_center_code', label: 'Fitness Center' },
    ];
    SET_CODE = [
        { name: "doorCodeType", value: 'last_phone_number_digits', label: "Guest's Phone Number (Last 4 Digits)" },
        { name: "doorCodeType", value: 'random_numeric', label: "Random Numeric Code" },
        { name: "doorCodeType", value: 'random_alphanumeric', label: "Random Alphanumeric Code" },
        { name: "doorCodeType", value: 'static', label: "Set Code Manually" },
    ]
    LEAD_TIME = [
        { name: "leadTime", value: '60', label: "1 Hour" },
        { name: "leadTime", value: '120', label: "2 Hours" },
        { name: "leadTime", value: '180', label: "3 Hours" },
        { name: "leadTime", value: '240', label: "4 Hours" },
        { name: "leadTime", value: '300', label: "5 Hours" },
        { name: "leadTime", value: '360', label: "6 Hours" },
    ]
    newDate = new Date();
    copySelectedDevice = '';
    doorCodeCopy = '';
    copyDevicePropertyValue = [];
    copyDeviceLocationType = '';
    copyDeviceCodeType = '';
    copyCurrentThermostatSettings = '';
    copyCurrentThermostatTemp = '';
    copyScheduledThermostatSettings = '';
    copyScheduledThermostatTemp = '';
    copyBeforeCheckIn = '';
    copyAfterCheckout = '';
    isLeadTimeEmpty = true;
    copyAdditionalCodeData = '';
    copyDeviceCodeValue = '';
    createOrEditConnectors = false;
    createOrEditDevice = false;
    initState = {
        propertyId: '',
        isLoading: true,
        mode: '',
        deviceActiveKey: '',
        modal: '',
        showPassword: {},
        deviceList: [],
        devicePropertyValue: [],
        beforeCheckIn: '120',
        afterCheckout: '60',
        additionalDoorCodeOptions: [],
        additionalDoorCodeValue: [],
        additionalCodeData: {
            teamMemberData: {
                name: '',
                id: '',
            },
            name: '',
            starts: '',
            expires: '',
            code: '',
        },
        additionalDeviceConfigs: [],
        deviceLocationType: '',
        selectedDevice: '',
        removeDeviceIsLoading: false,
        deleteAdditionalDeviceConfigIsLoading: false,
        updateDevicePropertyError: '',
        unassociatedDeviceIsLoading: false,
        submitDeviceDataIsLoading: false,
        submitDeviceDataError: '',
        deleteDeviceError: '',
        selectedGuestCode: '',
        doorCode: {},
        doorCodeValue: '',
        deviceConfig: {},
        deviceCodeValue: '',
        devicePageSize: 10,
        deviceActivePage: 1,
        deviceTotalCount: 0,
        guestCodeData: {},
        guestCodeType: '',
        doorCodeType: '',
        deviceCodeType: '',
        currentThermostatSettings: '',
        currentThermostatTemp: {
            heat: 68,
            cool: 78,
        },
        scheduledThermostatSettings: '',
        scheduledThermostatTemp: {
            heat: 68,
            cool: 78,
        },
        submitAdditionalCodeSuccess: '',
        submitAdditionalCodeError: '',
        updateDoorCodeSuccess: '',
        submitDeviceDataSuccess: '',
        connectorsData: {},
        assignPropertyIsLoading: false,
        unassociatedDevices: [],
        unassociatedDeviceValue: [],
        allDevices: [],
        manufacturers: [],
        errorKey: 1,
    };
    state = {
        ...this.initState,
        additionalDeviceColumns: [
            {
                maxWidth: 200,
                'Cell': row=>{
                    const name = row && row.original && row.original.config_name;
                    const reservationId = row && row.original && row.original.metadata && row.original.metadata.reservation_id;         
                    return(
                        <div>
                            <span className="team-member-tag">{reservationId ? 'Reservation ID' : 'Name'}</span>
                            <div>
                                {
                                    reservationId ?
                                    <Link 
                                        className="clickable-text" 
                                        to={`/reservations/${reservationId}/details`}
                                    >
                                        {reservationId}
                                    </Link>
                                    :
                                    <span className='sbold-text'>
                                        {name}
                                    </span>
                                }
                            </div>
                        </div>
                    )
                }
            },
            {
                'Cell': row=>{
                    const teamMeberName = row && row.original && row.original.subscriber_display_name;
                    const guestName = row && row.original && row.original.metadata && row.original.metadata.guest_name;
                    return(
                        <div>
                            <span className="team-member-tag">{guestName ? 'Guest Name' : 'Team Member'}</span>
                            <div>
                                <span className='sbold-text'>
                                    {guestName ? guestName : teamMeberName}
                                </span>
                            </div>
                        </div>
                    )
                }
            },
            {
                'Cell': row=>{
                    const start = row && row.original && row.original.config_settings && row.original.config_settings.start_dt;
                    return(
                        <div>
                            {
                                start &&
                                <Fragment>
                                    <span className="team-member-tag">Starts</span>
                                    <div>
                                        <span className='sbold-text'>
                                            {moment.parseZone(start).utc().format("MM/DD/YY hh:mm A z")}{" "}
                                        </span>
                                    </div>
                                </Fragment>
                            }
                        </div>
                    )
                }
            },
            {
                'Cell': row=>{
                    const expires = row && row.original && row.original.config_settings && row.original.config_settings.end_dt;
                    return(
                        <div>
                            <span className="team-member-tag">Expires</span>
                            <div>
                                <span className='sbold-text'>
                                    {
                                        expires ?
                                        <Fragment>
                                            {moment.parseZone(expires).utc().format("MM/DD/YY hh:mm A z")}{" "}
                                        </Fragment>
                                        :
                                        <span>Never</span>
                                    }
                                </span>
                            </div>
                        </div>
                    )
                }
            },
            {
                'Cell': row=>{
                    const code = row && row.original && row.original.config_settings && row.original.config_settings.code;
                    return(
                        <div>
                            <span className="team-member-tag">Code</span>
                            <div>
                                <span className='sbold-text'>
                                    {code}
                                </span>
                            </div>
                        </div>
                    )
                }
            },
            {
                'Cell': row=>{
                    const data = row && row.original;

                    return(
                        <div>
                            <span 
                                className="clickable-text sbold-text" 
                                onClick={(e) => this.editAdditonalCodeData(e, data)}
                            >
                                Edit
                            </span>
                        </div>
                    )
                }
            },
            {
                'Cell': row=>{
                    const data = row && row.original;

                    return(
                        <div>
                            <span 
                                className="clickable-text sbold-text" 
                                onClick={e => this.handleDeleteAdditionalConfigModal(e, data)}
                            >
                                Delete
                            </span>
                        </div>
                    )
                }
            },
        ]
    }

    async componentDidMount() {
        await this.init();

        this.setState({
            isLoading: false,
        });
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if((prevProps.propertyId !== this.props.propertyId) || _.get(prevProps, 'org') != _.get(this.props, 'org')) {
            this.setState({
                ...this.initState
            });

            await this.init();

            this.setState({
                isLoading: false
            });
        };
    }

    init = async () => {
        if( _.get(location, 'pathname').includes('devices')) {
            setTimeout(() => {
				document.getElementById('devices').scrollIntoView({
					block: "center",
					behavior: "smooth"
				});
			}, 600);
        };

        this.checkPermissions();

        if(this.props.propertyId !== 'global') {
            await this.getGuestCodes();
            await this.getAllDevices();
        };

        await this.getConnectors();
        await this.getDevices();
        await this.getManufacturers();
    }

    checkPermissions = () => {
        if(_.isArray(this.props.permissions)) {
            if(this.props.permissions.includes('create_edit_device_connectors')) {
                this.createOrEditConnectors = true;
            };
            if(this.props.permissions.includes('create_edit_devices')) {
                this.createOrEditDevice = true;
            };
        };
    }

    getManufacturers = async () => {
        let resp;

        try {
            resp = await API.get("gbapi", `/manufacturers`);
            if(resp && resp.length) {
                let copyResp = resp;

                copyResp.push({
                    connector_type: 'remotelock',
                    image_url: '/app/assets/remotelock.png',
                    title: 'Remote Lock'
                });

                this.setState({
                    manufacturers: copyResp
                });
            };
        }
        catch(e) {
            console.log(e)
        };
    }

    getConnectors = async () => {
        let resp;

        try {
            resp = await API.get("gbapi", `/organizations/${this.props.org}/connectors`);

            if(Object.keys(resp).length) {
                this.setState({
                    connectorsData: resp
                });
            };
        }
        catch(e) {
            console.log(e)
        };
    }

    submitConnectors = async (e, mfr) => {
        e.preventDefault();
        let resp;
        let returnUrl = window.location.href + '/devices';

        let data = {
            connector_type: mfr.connector_type,
            return_url: returnUrl,
        };

        if(mfr && mfr.name) {
            data.manufacturer_name = mfr.name;
        };

        this.setState({
            isLoading: true
        });

        try {
            resp = await API.post("gbapi", `/organizations/${this.props.org}/connectors`, { body: data })
            if(resp) {  
                this.setState({
                    modal: '',
                    connectorUrl: resp.connector_url,
                    isLoading: false
                }, () => {
                    if(this.state.connectorUrl.length) {
                        window.location.href = this.state.connectorUrl;
                    };
                });
            };
        }
        catch(e) {
            this.setState({
                isLoading: false
            });
        };
    }

    getGuestCodes = async () => {
        let resp;

        try {
            resp = await API.get("gbapi", `/properties/${this.props.propertyId}/commdata/door_code_configs/door_code`, { isCognito: true })
            if(resp !== null && typeof resp === 'object') {
                this.setState({
                    doorCode: resp,
                    doorCodeType: resp.type,
                    doorCodeValue: resp.type == 'static' ? resp.value : ''
                });
            };
        }
        catch(e) {
            console.log(e)
        }
    }
    
    getAllDevices = async () => {
        let resp;
        let unassociatedDevices = [];

        try {
            resp = await API.get("gbapi", `/organizations/${this.props.org}/devices?full_details=false`, { response: true, isCognito: true });

            if(resp && resp.data) {
                resp.data.forEach((device) => {
                    if(!device.property_id) {
                        unassociatedDevices.push({
                            ...device,
                            label: device.name
                        })
                    }
                });
            };

            this.setState({
                allDevices: resp.data,
                unassociatedDevices: unassociatedDevices
            });
        }
        catch(e) {
            console.log(e)
        }
    }

    getDevices = async (reload) => {
        const { 
            deviceActivePage,
            devicePageSize,
            selectedDevice
        } = this.state;
        const reloadQuery = reload ? `&refresh=true` : '';
        const propertyId = this.props.propertyId !== 'global' ? `&property_id=${encodeURIComponent(this.props.propertyId)}` : '';
        let newActivePage = ((deviceActivePage < 0) ? 0 : (deviceActivePage - 1));
        const offset = devicePageSize * newActivePage;
        const tid = getSessionId();
        let resp;
        let newState = {};

        try {
            resp = await API.get("gbapi", `/organizations/${this.props.org}/devices?full_details=true${propertyId}&limit=${encodeURIComponent(devicePageSize)}&offset=${encodeURIComponent(offset)}&tid=${encodeURIComponent(tid)}${reloadQuery}`, { response: true, isCognito: true })
            
            if(resp && resp.data) {
                newState = {
                    ...newState,
                    deviceList: resp.data,
                    deviceTotalCount: resp.headers["x-total-count"],
                };

                if(_.get(selectedDevice, 'organization_device_id')) {
                    let newSelectedDevice = resp.data.filter((device) => device.organization_device_id == selectedDevice.organization_device_id)
                    
                    if(newSelectedDevice.length) {
                        newState = {
                            ...newState,
                            selectedDevice: newSelectedDevice[0]
                        };
                    };
                };

                this.setState(newState);
            };
        }
        catch(e) {
            console.log(e)
        }
    }

    submitDeviceLockStatus = async (e) => {
        const { selectedDevice } = this.state;
        e.preventDefault();
        let data = {
            ...this.copySelectedDevice,
            locked: !selectedDevice.locked
        }
        let resp;

        this.setState({
            selectedDevice: {
                ...selectedDevice,
                locked: !selectedDevice.locked
            },
            submitAdditionalCodeError: '',
            submitDeviceDataIsLoading: true
        });

        try {
            resp = await API.put("gbapi", `/organizations/${this.props.org}/devices/${selectedDevice.organization_device_id}`, { body: data });
            if(resp) {
                await this.getDevices();

                this.copySelectedDevice = this.state.selectedDevice;

                this.setState({
                    submitAdditionalCodeSuccess: 'Device Successfully Updated.',
                    submitDeviceDataIsLoading: false
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            submitAdditionalCodeSuccess: '',
                        })
                    }, 3000)
                })
            };
        }
        catch(e) {
            const { selectedDevice } = this.state;
            let msg = this.deviceErrorMsg;

            this.setState({
                selectedDevice: {
                    ...selectedDevice,
                    locked: !selectedDevice.locked
                },
                submitAdditionalCodeError: msg,
                submitDeviceDataIsLoading: false
            });
        };
    }

    cancelAdditionalCode = () => {
        this.setState({
            mode: '',
            additionalDoorCodeValue: [],
            additionalCodeData: {
                teamMemberData: {
                    name: '',
                    id: '',
                },
                name: '',
                starts: new Date(),
                expires: new Date(),
                code: '',
            },
            currentAdditionalCodeData: ''
        })
    }

    cancelDeleteAdditionalConfig = () => {
        this.setState({
            modal: '',
            currentAdditionalCodeData: ''
        })
    }

    handleDeleteAdditionalConfigModal = (e, data) => {
        e.preventDefault();

        this.setState({
            modal: 'delete-additional-config-modal',
            currentAdditionalCodeData: data,
        })
    }

    handleDeleteAdditionalConfigOnClick = async () => {
        const { 
            currentAdditionalCodeData,
        } = this.state;
        let additionalDoorCodeOptions = [...this.state.additionalDoorCodeOptions];

        this.setState({
            mode: '',
            deleteAdditionalDeviceConfigIsLoading: true
        });

        await this.deleteAdditionalDeviceConfig();

        await delay(1500);
        
        const additionalDeviceConfigs = await this.getAdditionalDeviceConfigs(currentAdditionalCodeData.organization_device_id);

        additionalDoorCodeOptions = this.handleAdditionDoorCodeOptions(additionalDeviceConfigs);

        this.setState({
            additionalDeviceConfigs: additionalDeviceConfigs || [],
            deleteAdditionalDeviceConfigIsLoading: false,
            additionalDoorCodeOptions: additionalDoorCodeOptions,
            modal: '',
        });
    }

    deleteAdditionalDeviceConfig = async () => {
        const { 
            currentAdditionalCodeData,
        } = this.state;
        try {
            await API.del("gbapi", `/devices/${currentAdditionalCodeData.organization_device_id}/configs/${currentAdditionalCodeData.config_id}`)
        }
        catch(e) {
            console.log(e)
        };
    }

    submitAdditionalCode = async (e, device) => {
        e.preventDefault();
        const { 
            mode,
            additionalCodeData,
            currentAdditionalCodeData
        } = this.state;
        let data = {};
        let additionalDoorCodeOptions = [...this.state.additionalDoorCodeOptions];

        if(mode == 'create') {
            data.config_type = "access_code";
            data.config_settings = {
                code: additionalCodeData.code,
            };
            data.config_name = additionalCodeData.name;
            data.subscriber_id = additionalCodeData.teamMemberData.id;
        }
        else {
            data = {
                ...currentAdditionalCodeData,
                config_settings: {
                    ...currentAdditionalCodeData.config_settings,
                    code: additionalCodeData.code,
                    start_dt: moment(additionalCodeData.starts).format('YYYY-MM-DDTHH:mm:ssZ'),
                    end_dt: moment(additionalCodeData.expires).format('YYYY-MM-DDTHH:mm:ssZ')
                },
                config_name: additionalCodeData.name
            };

            if(currentAdditionalCodeData.subscriber_id) {
                data.subscriber_id;
            };
        };

        this.setState({
            submitDeviceDataIsLoading: true,
            submitAdditionalCodeError: '',
        });

        try {
            await API[mode == 'create' ? 'post': 'put']("gbapi", `/devices/${device.organization_device_id}/configs${mode == 'edit' ? `/${currentAdditionalCodeData.config_id}` : ''}`, { body: data })

            const additionalDeviceConfigs = await this.getAdditionalDeviceConfigs(device.organization_device_id);

            additionalDoorCodeOptions = this.handleAdditionDoorCodeOptions(additionalDeviceConfigs);

            this.setState({
                mode: '',
                additionalDoorCodeValue: [],
                additionalDoorCodeOptions: additionalDoorCodeOptions,
                additionalDeviceConfigs: additionalDeviceConfigs || [],
                submitAdditionalCodeSuccess: `Sucessfully ${mode == 'create' ? 'Added' : 'Edit'} Additional Code.`,
                submitDeviceDataIsLoading: false
            }, () => {
                setTimeout(() => {
                    this.setState({
                        submitAdditionalCodeSuccess: '',
                    })
                }, 3000)
            })
        }
        catch(err) {
            const errorMsg = _.get(err.response, 'data.message', '');
            let msg = this.deviceErrorMsg;

            if(errorMsg) {
                msg = errorMsg.replace(/^\('\s*|\',\s*-1\)$/g, '');
            };

            this.setState({
                submitAdditionalCodeError: msg,
                submitDeviceDataIsLoading: false
            });
        };
    }

    editAdditonalCodeData = (e, data) => {
        e.preventDefault();
        let name = (data && data.config_name) ? data.config_name : '';
        let teamMemberId = (data && data.subscriber_id) ? data.subscriber_id : '';
        let teamMemberName = (data && data.subscriber_display_name) ? data.subscriber_display_name : '';
        let starts = (data && data.config_settings && data.config_settings.start_dt) ? data.config_settings.start_dt : new Date();
        let expires = (data && data.config_settings && data.config_settings.end_dt) ? data.config_settings.end_dt : new Date();
        let code = (data && data.config_settings && data.config_settings.code) ? data.config_settings.code : ""; 
        let additionalCodeData = {
            teamMemberData: {
                name: teamMemberName,
                id: teamMemberId,
            },
            name: name,
            starts: starts,
            expires: expires,
            code: code,
        };

        this.copyAdditionalCodeData = additionalCodeData;

        this.setState({
            mode: 'edit',
            currentAdditionalCodeData: data,
            additionalCodeData: additionalCodeData
        }, () => {
            this.scrollToForm();
        });
    }

    loadAdditionalCodeData = (data) => {
        this.setState({
            additionalDoorCodeValue: data
        }, () => {
            let additionalCodeData = {
                teamMemberData: {
                    name: '',
                    id: '',
                },
                name: '',
                starts: new Date(),
                expires: new Date(),
                code: '',
            };

            if(data.value !== 'createCode') {
                additionalCodeData.teamMemberData.name = data.label;
                additionalCodeData.teamMemberData.id = data.value;
            };

            this.setState({
                mode: 'create',
                additionalCodeData: additionalCodeData
            }, () => {
                this.scrollToForm();
            });
        });
    }

    handleAdditionalCodeDateTime = (date, name) => {
        const { 
            additionalCodeData
        } = this.state;

        this.setState({
            additionalCodeData: {
                ...additionalCodeData,
                [name]: date
            }
        })
    }

    handleAdditionalCodeStartExpireNow = (e, name) => {
        e.preventDefault();
        const { 
            additionalCodeData
        } = this.state;
        let date = new Date();

        this.setState({
            additionalCodeData: {
                ...additionalCodeData,
                [name]: date
            }
        })
    }

    handleAdditionalCodeData = (e) => {
        const { 
            additionalCodeData,
            selectedDevice
        } = this.state;
        const name = e.target.name;
        let value = e.target.value;
        let copyAdditionalCode = additionalCodeData.code;
        const accessCodeLength = selectedDevice && selectedDevice.access_code_lengths && selectedDevice.access_code_lengths.length;

        if(name == 'code') {
            if(accessCodeLength) {
                if(additionalCodeData.code.length >= selectedDevice.access_code_lengths[accessCodeLength - 1]) {
                    value = copyAdditionalCode.slice(0, selectedDevice.access_code_lengths[accessCodeLength - 1])
                };
            };
        };

        this.setState({
            additionalCodeData: {
                ...additionalCodeData,
                [name]: value
            }
        })
    }

    renderDropdownStatus = () => {
        const { selectedDevice } = this.state;
        let icon = '';
        let status = '';

        if(_.isNil(selectedDevice.locked)) {
            icon = 'icon-lock-unknown';
            status = 'Unknown';
        }
        else if(_.get(selectedDevice, 'locked')) {
            icon = 'icon-lock';
            status = 'Locked';
        }
        else {
            icon = 'icon-unlock';
            status = 'Unlocked';
        };

        return (
            <Fragment>
                <i className={icon}></i>
                <div className='device-status'>{status}</div>
            </Fragment>
        );
    }

    renderDevicePanels = () => {
        const {
            mode,
            deviceActiveKey,
            deviceCodeValue,
            deviceCodeType,
            devicePropertyValue,
            scheduledThermostatSettings,
            scheduledThermostatTemp,
            submitDeviceDataError,
            submitDeviceDataSuccess,
            deviceLocationType,
            submitDeviceDataIsLoading,
            selectedDevice,
            beforeCheckIn,
            afterCheckout,
            additionalDoorCodeOptions,
            additionalDoorCodeValue,
            additionalCodeData,
            additionalDeviceConfigs,
            additionalDeviceColumns,
            currentAdditionalCodeData,
            submitAdditionalCodeSuccess,
            submitAdditionalCodeError,
            deviceList,
            deviceTotalCount,
            deviceActivePage,
            devicePageSize,
        } = this.state;

        let locationTypeOptions = [<option key={'key'} value={""} disabled></option>];
        this.LOCATION_TYPE.forEach((type) => {
            let locationType = [];

            deviceList.forEach((device) => {
                if(device.location_type) {
                    locationType.push(device.location_type);
                };
            });
            
            locationTypeOptions.push(<option key={type.value} value={type.value}>{type.label}</option>);
        });

        let leadTimeOptions = [];
        this.LEAD_TIME.forEach((type) => {
            leadTimeOptions.push(<option key={type.value} value={type.value}>{type.label}</option>);
        });

        return (
            <Col md={12} className='manage-devices'>
                {
                    submitDeviceDataIsLoading && <Loader/>
                }
                <PanelGroup 
                    accordion id="accordion-controlled-example"
                    activeKey={deviceActiveKey}
                    onSelect={() => null}
                >
                    {
                        deviceList.map((device, index) => {
                            let locationType = '';

                            if(device.location_type && device.location_type.length) {
                                let filterLocationType = this.LOCATION_TYPE.filter((type) => type.value == device.location_type);
                                if(filterLocationType.length) {
                                    locationType = filterLocationType[0].label
                                }
                            };
                            return(
                                <Panel key={index} eventKey={index + 1}>
                                    <Panel.Heading>
                                        <Panel.Title
                                            onClick={e => this.handleManageDevice(e, device, index + 1)}
                                            toggle
                                        >
                                            <div className='connected-devices'>
                                                <div className='device-name'>
                                                    <div>
                                                        <strong>{device.name}</strong>
                                                        <div className='grey-font'>{device.location_description}</div>
                                                    </div>
                                                </div>
                                                <div className='device-status'>
                                                    {this.renderDeviceStatus(device)}
                                                </div>
                                                <div className='device-assign'>
                                                    <div>
                                                        <span className="team-member-tag capitalize"><strong>{device.property_organization_name}</strong></span>
                                                        <div>
                                                            <span className='sbold-text'>
                                                                {device.property_id ? `${device.property_id}` : 'Not Assigned'}
                                                                &nbsp;
                                                            </span>
                                                            {
                                                                (locationType.length > 0) &&
                                                                <span>
                                                                    <span className='sbold-text'>|</span> {locationType}
                                                                </span>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='device-manage'>
                                                    <div className='manage-device-text-container'>
                                                        {
                                                            this.createOrEditDevice &&
                                                            (!device.property_id || (_.get(device, 'errors.length') > 0)) &&
                                                            <OverlayTrigger 
                                                                placement='top' 
                                                                overlay={
                                                                    <Tooltip id="manage-device-text">
                                                                        {
                                                                            !device.property_id && 
                                                                            <div>The device is not assigned to a property.</div>
                                                                        }
                                                                        {
                                                                            (_.get(device, 'errors', []) || []).map((error, i) => {
                                                                                return(
                                                                                    <div key={i} className='top-margin-10'>
                                                                                        {_.get(error, 'message')}
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Tooltip>
                                                                }>
                                                                <span className='manage-device-icon-container'>
                                                                    <i className="icon-exclamation-mark"></i>
                                                                </span>
                                                            </OverlayTrigger>

                                                        }
                                                        {
                                                            this.createOrEditDevice &&
                                                            <span 
                                                                className="clickable-text" 
                                                                onClick={e => this.handleManageDevice(e, device, index + 1)}
                                                            >
                                                                Manage Device
                                                            </span>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='device-remove'>
                                                    {
                                                        this.createOrEditDevice &&
                                                        <span 
                                                            className="clickable-text sbold-text" 
                                                            onClick={e => this.handleRemoveDevice(e, device)}
                                                        >
                                                            {`Remove ${this.props.propertyId !== 'global' ? 'from Property' : ''}`}
                                                        </span>
                                                    }
                                                </div>
                                                <div 
                                                    className={'device-chevron ' + (deviceActiveKey === (index + 1) ? 'glyphicon glyphicon-chevron-up': 'glyphicon glyphicon-chevron-down')}
                                                >
                                                </div>
                                            </div>
                                        </Panel.Title>
                                    </Panel.Heading>
                                    <Panel.Body collapsible>
                                        <Col md={12}>
                                            <Col xs={12}>
                                                <strong>{this.capitizeFirstLetter(device && device.manufacturer)}</strong>
                                                {', '}
                                                {this.capitizeFirstLetter(device.description)}
                                            </Col>
                                            <Col md={device.device_type == 'connected_lock' ? 4 : 6} className='top-margin'>
                                                <InputLabel>
                                                    <strong>Device Name:</strong>
                                                </InputLabel>
                                                <InputField 
                                                    type="text" 
                                                    value={selectedDevice.name || ''} 
                                                    name="name"
                                                    onChange={e => this.handleDeviceData(e)}
                                                >
                                                </InputField>
                                            </Col>
                                            <Col md={device.device_type == 'connected_lock' ? 4 : 6} className='top-margin'>
                                                <InputLabel>
                                                    <strong>Assign Property:</strong>
                                                </InputLabel>
                                                <Picky
                                                    placeholder="Select Property"
                                                    labelKey="label"
                                                    valueKey="property_id"
                                                    options={
                                                        [
                                                            {label: '', property_id: ''},
                                                            ...this.props.teamMemberPropertiesList
                                                        ]
                                                    }
                                                    value={devicePropertyValue}
                                                    multiple={false}
                                                    includeSelectAll={true}
                                                    includeFilter={true}
                                                    onChange={prop => this.handleDevicePropertyValue(prop)}
                                                    dropdownHeight={600} 
                                                    filterDebounce={100}   
                                                    keepOpen={false}
                                                    numberDisplayed={1}
                                                    clearFilterOnClose={true}
                                                    render={({
                                                        style,
                                                        item,
                                                        isSelected,
                                                        selectValue,
                                                        labelKey,
                                                        valueKey,
                                                        index
                                                        }) => {
                                                            return (
                                                                <li
                                                                    style={{ ...style }} 
                                                                    className={isSelected ? "selected" : ""} 
                                                                    key={index}
                                                                    onClick={() => selectValue(item)}
                                                                >
                                                                    <span
                                                                        style={{fontWeight: isSelected ? "bold" : "normal"}}
                                                                    >
                                                                        {
                                                                            <span>{item[labelKey]}</span>                                                    
                                                                        }
                                                                    </span>
                                                                </li>
                                                            );
                                                        }}
                                                />
                                            </Col>
                                            {
                                                device.device_type == 'connected_lock' &&
                                                <Fragment>
                                                    <Col md={4} className='top-margin'>
                                                        <InputLabel>
                                                            <strong>Select Location:</strong>
                                                        </InputLabel>
                                                        <InputField 
                                                            type="select" 
                                                            value={deviceLocationType} 
                                                            name="deviceLocationType"
                                                            onChange={e => this.handleOnChange(e)}
                                                        >
                                                            {locationTypeOptions}
                                                        </InputField>
                                                    </Col>
                                                    <Col xs={12} className='top-margin'>
                                                        <InputLabel>
                                                            <strong>Set Code:</strong>
                                                        </InputLabel>
                                                    </Col>
                                                    <Col md={12}>
                                                        <Col md={12} className='no-padding'>
                                                            <Col md={12} className='no-padding'>
                                                                {
                                                                    this.createRadioButton(this.SET_CODE, deviceCodeType, this.handleDeviceCodeTypeOnChange, index + 1)
                                                                }
                                                            </Col>
                                                            <Col md={8} className='door-code-lead-time no-padding'>
                                                                <div className='top-margin'>
                                                                    <span>
                                                                        Add door code 
                                                                    </span>
                                                                    <InputField 
                                                                        type="select" 
                                                                        value={beforeCheckIn} 
                                                                        name="beforeCheckIn"
                                                                        onChange={e => this.handleOnChange(e)}
                                                                    >
                                                                        {leadTimeOptions}
                                                                    </InputField>
                                                                    <span>
                                                                        before Check-In
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span>
                                                                        Remove door code 
                                                                    </span>
                                                                    <InputField 
                                                                        type="select" 
                                                                        value={afterCheckout} 
                                                                        name="afterCheckout"
                                                                        onChange={e => this.handleOnChange(e)}
                                                                    >
                                                                        {leadTimeOptions}
                                                                    </InputField>
                                                                    <span>
                                                                        after Checkout
                                                                    </span>
                                                                </div>
                                                            </Col>
                                                            {
                                                                deviceCodeType == 'static' &&
                                                                <Col md={4}>
                                                                    <InputLabel>
                                                                        <strong>Manual Code:</strong>
                                                                    </InputLabel>
                                                                    <InputField 
                                                                        type="number"
                                                                        value={deviceCodeValue} 
                                                                        name="deviceCodeValue"
                                                                        onChange={e => this.handleOnChange(e)}
                                                                    >
                                                                    </InputField>
                                                                </Col>
                                                            }
                                                            {
                                                                (!deviceLocationType.length ||
                                                                !(Object.keys(devicePropertyValue).length)) &&
                                                                <OverlayTrigger 
                                                                    placement='top' 
                                                                    overlay={
                                                                        <Tooltip id="locked">
                                                                        Assign Property and Select Location are required before proceeding to set up the device code.
                                                                        </Tooltip>
                                                                    }>
                                                                        <div className='validation-overlay'></div>
                                                                </OverlayTrigger>
                                                            }
                                                        </Col>
                                                    </Col>
                                                </Fragment>
                                            }
                                            {
                                                device.device_type == 'connected_thermostat' &&
                                                <Fragment>
                                                    <Col xs={12} className='top-margin'>
                                                    <   Col md={12} className='center-text'>
                                                            <div>
                                                                <strong>
                                                                    Scheduled Temperature:
                                                                </strong>
                                                            </div>
                                                        </Col>
                                                        <Col md={12} className='flex-align-center bottom-margin top-margin'>
                                                            <ToggleButtonGroup 
                                                                type="radio" 
                                                                name="thermos-settings-options" 
                                                                value={scheduledThermostatSettings}
                                                                onChange={(value) => this.thermostatSettingsOnChange(value, 'scheduled')}
                                                            >
                                                                {
                                                                ((device && device.cooling_available) ||
                                                                ((device && device.cooling_available) &&
                                                                (device && device.heating_available))) &&
                                                                    <ToggleButton 
                                                                        id="thermos-settings-radio-1" 
                                                                        value='cool'
                                                                        disabled={this.disableToggleBtn()}
                                                                    >
                                                                        Cool
                                                                    </ToggleButton>
                                                                }
                                                                {
                                                                    ((device && device.cooling_available) &&
                                                                    (device && device.heating_available)) &&
                                                                    <ToggleButton 
                                                                        id="thermos-settings-radio-2" 
                                                                        value='auto'
                                                                        disabled={this.disableToggleBtn()}
                                                                    >
                                                                        Auto
                                                                    </ToggleButton>
                                                                }
                                                                {
                                                                    ((device && device.heating_available) ||
                                                                    ((device && device.cooling_available) &&
                                                                    (device && device.heating_available))) &&
                                                                    <ToggleButton 
                                                                        id="thermos-settings-radio-2" 
                                                                        value='heat'
                                                                        disabled={this.disableToggleBtn()}
                                                                    >
                                                                        Heat
                                                                    </ToggleButton>
                                                                }                                  
                                                            </ToggleButtonGroup>
                                                            {
                                                                !(Object.keys(devicePropertyValue).length) &&
                                                                <OverlayTrigger 
                                                                    placement='top' 
                                                                    overlay={
                                                                        <Tooltip id="locked">
                                                                        Assign Property is required before proceeding to set up the scheduled Temperature.
                                                                        </Tooltip>
                                                                    }>
                                                                        <div className='validation-overlay'></div>
                                                                </OverlayTrigger>
                                                            }
                                                        </Col>
                                                        <Row className='flex-align-center'>
                                                            {
                                                                (scheduledThermostatSettings.length > 0) &&
                                                                <Fragment>
                                                                    {
                                                                        scheduledThermostatSettings !== 'auto' ?
                                                                        <Col md={2}>
                                                                            <InputLabel>
                                                                                <strong>{this.capitizeFirstLetter(scheduledThermostatSettings)} to:</strong>
                                                                            </InputLabel>
                                                                            <InputField 
                                                                                type="number"
                                                                                value={scheduledThermostatTemp && scheduledThermostatTemp[scheduledThermostatSettings]} 
                                                                                name={scheduledThermostatSettings || ''}
                                                                                onChange={e => this.thermostatValueOnChange(e, 'scheduled')}
                                                                            >
                                                                            </InputField>
                                                                        </Col>
                                                                        :
                                                                        <Fragment>
                                                                            <Col md={2}>
                                                                                <InputLabel>
                                                                                    <strong>Heat to:</strong>
                                                                                </InputLabel>
                                                                                <InputField 
                                                                                    type="number"
                                                                                    name="heat"
                                                                                    value={scheduledThermostatTemp.heat || ''} 
                                                                                    onChange={e => this.thermostatValueOnChange(e, 'scheduled')}
                                                                                >
                                                                                </InputField>
                                                                            </Col>
                                                                            <Col md={2}>
                                                                                <InputLabel>
                                                                                    <strong>Cool to:</strong>
                                                                                </InputLabel>
                                                                                <InputField 
                                                                                    type="number"
                                                                                    name="cool"
                                                                                    value={scheduledThermostatTemp.cool || ''} 
                                                                                    onChange={e => this.thermostatValueOnChange(e, 'scheduled')}
                                                                                >
                                                                                </InputField>
                                                                            </Col>
                                                                        </Fragment>
                                                                    }
                                                                </Fragment>
                                                            }
                                                        </Row>
                                                        <Col md={8} className='door-code-lead-time no-padding'>
                                                            <div className='top-margin'>
                                                                <span>
                                                                    Change temperature
                                                                </span>
                                                                <InputField 
                                                                    type="select" 
                                                                    value={beforeCheckIn} 
                                                                    name="beforeCheckIn"
                                                                    onChange={e => this.handleOnChange(e)}
                                                                >
                                                                    {leadTimeOptions}
                                                                </InputField>
                                                                <span>
                                                                    before Check-In
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <span>
                                                                    Return to previous settings
                                                                </span>
                                                                <InputField 
                                                                    type="select" 
                                                                    value={afterCheckout} 
                                                                    name="afterCheckout"
                                                                    onChange={e => this.handleOnChange(e)}
                                                                >
                                                                    {leadTimeOptions}
                                                                </InputField>
                                                                <span>
                                                                    after Checkout
                                                                </span>
                                                            </div>
                                                        </Col>
                                                    </Col>
                                                </Fragment>
                                            }
                                            <Col md={12} className='top-margin-10 flex-row-reverse'>
                                                <PrimaryButton
                                                    fullWidth={false} 
                                                    type="button"
                                                    onClick={e =>this.submitDeviceData(e)}
                                                    disabled={this.manageDeviceValidation()}
                                                >
                                                    Update
                                                </PrimaryButton>
                                            </Col>
                                            {
                                                (submitDeviceDataError.length > 0) && this.renderMsg(submitDeviceDataError, '_error')
                                            }
                                            {
                                                (submitDeviceDataSuccess.length > 0) && this.renderMsg(submitDeviceDataSuccess, '_success')
                                            }
                                        </Col>
                                        {
                                            ((this.copyDeviceLocationType.length > 0) && 
                                            (device.device_type == 'connected_lock') &&
                                            (this.copyDevicePropertyValue.length > 0 || Object.keys(this.copyDevicePropertyValue).length > 0)) && 
                                            <Col md={12}>
                                                <Col md={12} className='top-margin'>
                                                    <Col md={6} className='no-padding'>
                                                        <InputLabel>
                                                            <strong>Additional Door Codes:</strong>
                                                        </InputLabel>
                                                        <Picky
                                                            placeholder="Select Team Member or Create Code"
                                                            labelKey="label"
                                                            valueKey="value"
                                                            options={additionalDoorCodeOptions}
                                                            value={additionalDoorCodeValue}
                                                            multiple={false}
                                                            includeSelectAll={true}
                                                            includeFilter={true}
                                                            onChange={prop => this.loadAdditionalCodeData(prop, 'create')}
                                                            dropdownHeight={600} 
                                                            filterDebounce={100}   
                                                            keepOpen={false}
                                                            numberDisplayed={1}
                                                            clearFilterOnClose={true}
                                                            render={({
                                                                style,
                                                                item,
                                                                isSelected,
                                                                selectValue,
                                                                labelKey,
                                                                valueKey,
                                                                }) => {
                                                                    return (
                                                                        <li
                                                                            style={{ ...style }} 
                                                                            className={isSelected ? "selected" : ""} 
                                                                            key={item.value}
                                                                            onClick={() => selectValue(item)}
                                                                        >
                                                                            <span
                                                                                style={{fontWeight: isSelected ? "bold" : "normal"}}
                                                                            >
                                                                                {
                                                                                    <span>{item[labelKey]}</span>                                                    
                                                                                }
                                                                            </span>
                                                                        </li>
                                                                    );
                                                                }}
                                                        />
                                                    </Col>
                                                </Col>
                                                <Col md={12} className="top-margin">
                                                    <ReactTableComponent
                                                        manual
                                                        className='-highlight'
                                                        noDataText="No Additional Door Codes found."
                                                        minRows={0}
                                                        data={additionalDeviceConfigs}
                                                        resizable={false}
                                                        activePage={1}
                                                        columns={additionalDeviceColumns}
                                                        showPagination={true}
                                                        pageSize={5}
                                                        totalCount={5}
                                                    />
                                                </Col>
                                                {
                                                    mode.length > 0 &&
                                                    <Fragment>
                                                        {
                                                            additionalCodeData.teamMemberData.name &&
                                                            <Col md={3} className='top-margin'>
                                                                <InputLabel>
                                                                    <strong>Team Member:</strong>
                                                                </InputLabel>
                                                                <div className='tm-name'>
                                                                    {additionalCodeData.teamMemberData.name}
                                                                </div>
                                                            </Col>
                                                        }
                                                        <Col md={3} className='top-margin' id='additional-code-form'>
                                                            {
                                                                mode !== 'create' &&
                                                                (currentAdditionalCodeData && 
                                                                currentAdditionalCodeData.config_type == 'access_code_schedule') ?
                                                                <Fragment>
                                                                    <InputLabel>
                                                                        <strong>Guest Name:</strong>
                                                                    </InputLabel>
                                                                    <div className='tm-name'>
                                                                        {
                                                                            currentAdditionalCodeData && 
                                                                            currentAdditionalCodeData.metadata &&
                                                                            currentAdditionalCodeData.metadata.guest_name
                                                                        }
                                                                    </div>
                                                                </Fragment>
                                                                :
                                                                <Fragment>
                                                                    <InputLabel>
                                                                        <strong>Name:</strong>
                                                                    </InputLabel>
                                                                    <InputField 
                                                                        type="text" 
                                                                        value={additionalCodeData.name} 
                                                                        name="name"
                                                                        onChange={e => this.handleAdditionalCodeData(e)}
                                                                    >
                                                                    </InputField>
                                                                </Fragment>
                                                            }
                                                        </Col>
                                                        <Col md={3} className='top-margin'>
                                                            <InputLabel>
                                                                <strong>Code:</strong>
                                                            </InputLabel>
                                                            <InputField 
                                                                type="number" 
                                                                value={additionalCodeData.code} 
                                                                name="code"
                                                                onChange={e => this.handleAdditionalCodeData(e)}
                                                            >
                                                            </InputField>
                                                        </Col>
                                                        {
                                                            mode !== 'create' &&
                                                            (currentAdditionalCodeData && 
                                                            currentAdditionalCodeData.config_type == 'access_code_schedule') &&
                                                            <Fragment>
                                                                <Col md={3} className='top-margin'>
                                                                    <InputLabel>
                                                                        <strong>Starts:</strong>
                                                                        <span 
                                                                            className='sbold-text clickable-text left-padding-5'
                                                                            onClick={(e) => this.handleAdditionalCodeStartExpireNow(e, 'starts')}
                                                                        >
                                                                            Set to start now
                                                                        </span>
                                                                    </InputLabel>
                                                                    <DateTimePicker 
                                                                        onChange={(date) => this.handleAdditionalCodeDateTime(date, 'starts')} 
                                                                        value={additionalCodeData.starts}
                                                                    />
                                                                </Col>
                                                                <Col md={3} className='top-margin'>
                                                                    <InputLabel>
                                                                        <strong>Expires:</strong>
                                                                        <span 
                                                                            className='sbold-text clickable-text left-padding-5'
                                                                            onClick={(e) => this.handleAdditionalCodeStartExpireNow(e, 'expires')}
                                                                        >
                                                                            Set to start now
                                                                        </span>
                                                                    </InputLabel>
                                                                    <DateTimePicker 
                                                                        onChange={(date) => this.handleAdditionalCodeDateTime(date, 'expires')} 
                                                                        value={additionalCodeData.expires}
                                                                    />
                                                                </Col>
                                                            </Fragment>
                                                        }
                                                        <Col md={12} className='top-margin flex-end'>
                                                            <div className="flex-row-reverse">
                                                                <PrimaryButton
                                                                    fullWidth={false} 
                                                                    type="button"
                                                                    onClick={e =>this.submitAdditionalCode(e, device)}
                                                                    disabled={this.additionalDoorCodeValidation()}
                                                                >
                                                                    {mode == 'create' ? 'Add' : 'Update'}
                                                                </PrimaryButton>
                                                                <PrimaryButton
                                                                    cssClass='white-btn right-margin'
                                                                    fullWidth={false} 
                                                                    type="button"
                                                                    onClick={this.cancelAdditionalCode}
                                                                >
                                                                    Cancel
                                                                </PrimaryButton>
                                                            </div>
                                                        </Col>
                                                    </Fragment>
                                                }
                                            </Col>
                                        }
                                        <Col xs={12} className="top-margin manage-device-actions">
                                            <Col xs={12} className='display-flex'>
                                                {
                                                    selectedDevice.device_type == 'connected_lock' &&
                                                    <Fragment>
                                                        {
                                                            !_.isNil(selectedDevice.locked) ?
                                                            <OverlayTrigger 
                                                                placement='top' 
                                                                overlay={
                                                                    <Tooltip 
                                                                        id="locked"
                                                                    >
                                                                        {selectedDevice.locked ? 'Unlock Device' : 'Lock Device'}
                                                                    </Tooltip>}>
                                                                    <div
                                                                        onClick={e => this.submitDeviceLockStatus(e)}
                                                                        className='manage-device-icon border'
                                                                        style={{cursor: 'pointer'}}
                                                                    >
                                                                        {this.renderDropdownStatus()}
                                                                    </div>
                                                            </OverlayTrigger>
                                                            :
                                                            <div className='manage-device-icon'>
                                                                {this.renderDropdownStatus()}
                                                            </div>
                                                        }
                                                    </Fragment>
                                                }
                                                {
                                                    (device.temperature &&
                                                    device.device_type == 'connected_thermostat') &&
                                                    <div className='manage-device-icon'>
                                                        <i className="icon-thermostat"></i>
                                                        <div className='device-status'>
                                                            {device.temperature}
                                                            {device.temperature_units == 'fahrenheit' ? this.fahrenheitSymbol : this.celsiusSymbol}
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    (device.online_status && device.online_status != 'unknown') &&
                                                    <div className='manage-device-icon'>
                                                        {this.renderWifi(device.wifi_signal_strength)}
                                                    </div>
                                                }
                                                {
                                                    device.battery_level &&
                                                    <div className='manage-device-icon'>
                                                        {this.renderBattery(device.battery_level, device.battery_status)}
                                                    </div>
                                                }
                                                {
                                                     _.get(device, 'errors.length') > 0 &&
                                                    <OverlayTrigger 
                                                        placement='top' 
                                                        overlay={
                                                            <Tooltip 
                                                                id="error"
                                                            >
                                                                Show Error{_.get(device, 'errors.length') > 0 ? 's' : ''}
                                                            </Tooltip>}>
                                                            <div
                                                                onClick={(e) => this.handleModal(e, 'device-error-modal')}
                                                                className='manage-device-icon border'
                                                                style={{cursor: 'pointer'}}
                                                            >
                                                                <i className='icon-exclamation-triangle'></i>
                                                                <div className="device-status">Error{_.get(device, 'errors.length') > 0 ? 's' : ''}</div>
                                                            </div>
                                                    </OverlayTrigger>
                                                }
                                            </Col>
                                        </Col>
                                        <Col xs={12}>
                                            {
                                                (submitAdditionalCodeError.length > 0) && this.renderMsg(submitAdditionalCodeError, '_error')
                                            }
                                            {
                                                (submitAdditionalCodeSuccess.length > 0) && this.renderMsg(submitAdditionalCodeSuccess, '_success')
                                            }
                                        </Col>
                                    </Panel.Body>
                                </Panel>
                            )
                        })
                    }
                </PanelGroup>
                {
                    <div className="pagination-container">
                        <div className="units-info-container">
                            {deviceTotalCount} &nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;
                        </div>
                        <div className="page_size-input-container">
                            <div 
                                className='page-size' 
                            >
                                <InputField
                                    className="pageSize"
                                    type='number' 
                                    name='pageSize'
                                    autoComplete="off"
                                    defaultValue={devicePageSize}
                                    onKeyUp={(e) => {
                                        let value = e.target.value;

                                        if(value > 100) {
                                            value = 100;
                                        }
                                        else if(value < 0) {
                                            value = 1;
                                        };
                                        if(value.length && e.keyCode === 13 && value !== 0 && Number.isInteger(Number(value))) {
                                            this.devicePageSizeOnChange(value)
                                        };
                                    }}
                                /> 
                                / page
                            </div>
                        </div>
                        <Pagination
                            activePage={deviceActivePage}
                            hideFirstLastPages={false}
                            prevPageText={"Prev"}
                            nextPageText={"Next"}
                            itemsCountPerPage={parseInt(devicePageSize)}
                            totalItemsCount={parseInt(deviceTotalCount)}
                            onChange={(page) => this.payoutsPageOnChange(page)}
                        />
                    </div>
                }
            </Col>
        )
    }

    devicePageSizeOnChange = (pageSize) => {
        this.setState({
            devicePageSize: pageSize,
            deviceActivePage: 1,
            deviceActiveKey: '',
        }, async () => {
            this.setState({
                isLoading: true
            });

            await this.getDevices();

            this.setState({
                isLoading: false
            });
        });
    }

    payoutsPageOnChange = (page) => {
        this.setState({
            deviceActivePage: page
        }, async () => {
            this.setState({
                isLoading: true
            });

            await this.getDevices();

            this.setState({
                isLoading: false
            });
        });
    }

    updateDoorCode = async () => {
        const { 
            doorCode,
            doorCodeType,
            doorCodeValue,
        } = this.state;
        let resp;
        let data = {
            ...doorCode,
            type: doorCodeType,
            value: null
        }

        if(doorCodeType == 'static') {
            data.value = doorCodeValue;
        };

        this.setState({
            updateDoorCodeError: '',
            isLoading: true,
        });

        try {
            resp = await API.put("gbapi", `/properties/${this.props.propertyId}/commdata/door_code_configs/door_code`, { body: data });

            if(resp && resp.door_code_configs && resp.door_code_configs.door_code) {
                this.doorCodeCopy = resp.door_code_configs.door_code;
            };
            
            this.setState({
                updateDoorCodeSuccess: "Guest Door Code Updated Successfully.",
                isLoading: false
            }, () => {
                setTimeout(() => {
                    this.setState({
                        updateDoorCodeSuccess: ""
                    });
                }, 3000);
            });
   
        }
        catch(e) {
            console.log(e)
            this.setState({
                doorCode: this.doorCodeCopy,
                updateDoorCodeError: this.doorCodeErrorMsg,
                isLoading: false
            });
        }
    }

    attachDeviceProperty = async () => {
        const { 
            unassociatedDeviceValue,
            deviceLocationType
        } = this.state;
        const thermostatLocationType = `thermostat_${unassociatedDeviceValue.organization_device_id}`;
        let resp;
        let data = {
            ...unassociatedDeviceValue,
            property_id: this.props.propertyId,
        };

        if(unassociatedDeviceValue.device_type == 'connected_lock') {
            data.location_type = deviceLocationType;
        }
        else if (unassociatedDeviceValue.device_type == 'connected_thermostat') {
            data.location_type = thermostatLocationType;
        };

        delete data.label;

        this.setState({
            unassociatedDeviceIsLoading: true
        });

        try {
            resp = await API.put("gbapi", `/organizations/${this.props.org}/devices/${unassociatedDeviceValue.organization_device_id}`, { body: data })
            if(resp) {  
                this.setState({
                    modal: '',
                    unassociatedDeviceValue: [],
                    deviceLocationType: '',
                    unassociatedDeviceIsLoading: false,
                    isLoading: true
                });

                await this.getAllDevices();
                await this.getDevices();

                this.setState({
                    isLoading: false
                });
            };
        }
        catch(err) {
            let msg = this.deviceErrorMsg;
            const errorMsg = _.get(err.response, 'data.Message', '');

            if(errorMsg) {
                if(errorMsg.toLowerCase().includes('unable to find')) {
                    msg = 'Device Not Found.'
                }
                else {
                    msg = errorMsg.replace(/^\('\s*|\',\s*-1\)$/g, '');
                };
            };

            this.setState({
                updateDevicePropertyError: msg,
                unassociatedDeviceIsLoading: false
            });
        };
    }

    removeDeviceProperty = (e, data) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            selectedDevice: data,
        }, () => {
            this.setState({
                modal: 'remove-device-modal',
            });
        })
    }

    handleRemoveDeviceProperty = async() => {
        const { 
            selectedDevice,
        } = this.state;
        let resp;
        let data = {
            ...selectedDevice,
            property_id: '',
            location_type: ''
        };

        this.setState({
            removeDeviceIsLoading: true,
        });

        try {
            resp = await API.put("gbapi", `/organizations/${this.props.org}/devices/${selectedDevice.organization_device_id}`, { body: data })
            if(resp) {  
                this.setState({
                    modal: '',
                    unassociatedDeviceValue: [],
                    deviceLocationType: '',
                    removeDeviceIsLoading: false,
                    isLoading: true
                });

                await this.getAllDevices();
                await this.getDevices();

                this.setState({
                    isLoading: false
                });
            };
        }
        catch(e) {
            let msg = this.deviceErrorMsg;

            if(e && e.response && e.response.data && e.response.data.Message && e.response.data.Message.toLowerCase().includes('unable to find')) {
                msg = 'Device Not Found.'
            };
            this.setState({
                updateDevicePropertyError: msg,
                removeDeviceIsLoading: false,
                isLoading: false
            });
        };
    }

    getDeviceConfig = async (propertyId, type) => {
        const newType = type == 'connected_lock' ? 'door_code_configs' : 'thermostat_configs';
        let resp;

        try {
            resp = await API.get("gbapi", `/properties/${propertyId}/commdata/${newType}`, { isCognito: true });
        }
        catch(e) {
            console.log(e)
        };

        return resp;
    }

    getAdditionalDeviceConfigs = async (deviceId) => {
        let resp;

        try {
            resp = await API.get("gbapi", `/devices/${deviceId}/configs`, { isCognito: true });
        }
        catch(e) {
            console.log(e)
        };

        return resp;
    }

    scrollToForm = () => {
        setTimeout(() => {
            document.getElementById('additional-code-form').scrollIntoView({
                block: "center",
                behavior: "smooth"
            });
        }, 500);
    }

    updateDeviceConfig = async (propertyId, type, locationType) => {
        const { 
            deviceCodeType,
            deviceCodeValue,
            scheduledThermostatSettings,
            scheduledThermostatTemp,
            beforeCheckIn,
            afterCheckout,
        } = this.state;
        const newType = type == 'connected_lock' ? 'door_code_configs' : 'thermostat_configs';
        let resp;
        let data = {};

        if(type == 'connected_lock') {
            data = {
                type: deviceCodeType,
                value: null,
                minutes_before_checkin: parseInt(beforeCheckIn),
                minutes_after_checkout: parseInt(afterCheckout),
            };

            if(deviceCodeType == 'static') {
                data.value = deviceCodeValue;
            };
        };

        if(type == 'connected_thermostat') {
            data = {
                location_type: locationType,
                minutes_before_checkin: parseInt(beforeCheckIn),
                minutes_after_checkout: parseInt(afterCheckout),
            };

            if(scheduledThermostatSettings == 'auto') {
                data.cooling_set_point = scheduledThermostatTemp.cool;
                data.heating_set_point = scheduledThermostatTemp.heat;
            }
            else if(scheduledThermostatSettings == 'cool') {
                data.cooling_set_point = scheduledThermostatTemp.cool;
            }
            else if(scheduledThermostatSettings == 'heat') {
                data.heating_set_point = scheduledThermostatTemp.heat;
            };
        };

        try {
            resp = await API.put("gbapi", `/properties/${propertyId}/commdata/${newType}/${locationType}`, { body: data });
        }
        catch(e) {
            console.log(e)
        };
    }

    submitDeviceData = async (e) => {
        e && e.preventDefault();

        const { 
            selectedDevice,
            devicePropertyValue,
            deviceLocationType,
            currentThermostatSettings,
            currentThermostatTemp,
            scheduledThermostatSettings,
            deviceCodeType
        } = this.state;
        let resp;
        let data = {
            ...this.copySelectedDevice,
            name: selectedDevice.name,
            property_id: devicePropertyValue.property_id,
        };
        const thermostatLocationType = `thermostat_${this.copySelectedDevice.organization_device_id}`;

        if(selectedDevice.device_type == 'connected_lock') {
            data.locked = selectedDevice.locked;
            data.location_type = deviceLocationType;
        };

        if(selectedDevice.device_type == 'connected_thermostat') {
            data.location_type = thermostatLocationType;

            if(currentThermostatSettings == 'auto') {
                data.cooling_set_point = Number(currentThermostatTemp.cool);
                data.heating_set_point = Number(currentThermostatTemp.heat);
            }
            else if(currentThermostatSettings == 'cool') {
                data.cooling_set_point = Number(currentThermostatTemp.cool);
                data.heating_set_point = null;
                
            }
            else if(currentThermostatSettings == 'heat') {
                data.cooling_set_point = null;
                data.heating_set_point = Number(currentThermostatTemp.heat);
            };
        };

        this.setState({
            submitDeviceDataError: '',
            submitDeviceDataIsLoading: true
        });
        
        if(deviceCodeType.length || scheduledThermostatSettings.length) {
            await this.updateDeviceConfig(devicePropertyValue.property_id, selectedDevice.device_type, (selectedDevice.device_type == 'connected_lock' ? deviceLocationType : thermostatLocationType));
        };

        await delay(1500);

        try {
            resp = await API.put("gbapi", `/organizations/${this.props.org}/devices/${selectedDevice.organization_device_id}`, { body: data });
            if(resp) {

                if(this.props.propertyId  !== 'global' && deviceLocationType == 'door_code') {
                    this.getGuestCodes();
                };
                
                this.copyDeviceCodeType = this.state.deviceCodeType;
                this.copySelectedDevice = this.state.selectedDevice;
                this.copyDevicePropertyValue = this.state.devicePropertyValue;
                this.copyDeviceLocationType = this.state.deviceLocationType;
                this.copyCurrentThermostatSettings = this.state.currentThermostatSettings;
                this.copyCurrentThermostatTemp = this.state.currentThermostatTemp;
                this.copyScheduledThermostatSettings = this.state.scheduledThermostatSettings;
                this.copyScheduledThermostatTemp = this.state.scheduledThermostatTemp;
                this.isLeadTimeEmpty = false;
                this.copyBeforeCheckIn = this.state.beforeCheckIn;
                this.copyAfterCheckout =  this.state.afterCheckout;
                this.copyDeviceCodeValue = this.state.deviceCodeValue;

                await this.getDevices();

                this.setState({
                    submitDeviceDataSuccess: 'Device Successfully Updated.',
                    submitDeviceDataIsLoading: false
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            submitDeviceDataSuccess: '',
                        })
                    }, 3000)
                });
            };
        }
        catch(err) {
            let msg = this.deviceErrorMsg;
            const errorMsg = _.get(err.response, 'data.Message', '');

            if(errorMsg) {
                if(errorMsg.toLowerCase().includes('unable to find')) {
                    msg = 'Device Not Found.'
                }
                else {
                    msg = errorMsg.replace(/^\('\s*|\',\s*-1\)$/g, '');
                };
            };

            this.setState({
                submitDeviceDataError: msg,
                submitDeviceDataIsLoading: false
            });
        };
    }

    removeDevice = async () => {
        const { 
            selectedDevice,
        } = this.state;

        this.setState({
            deleteDeviceError: '',
            removeDeviceIsLoading: true,
        });

        try {
            await API.del("gbapi", `/organizations/${this.props.org}/devices/${selectedDevice.organization_device_id}`)
            this.setState({
                modal: '',
                selectedDevice: {},
                removeDeviceIsLoading: false,
                isLoading: true,
            });

            await this.getAllDevices();
            await this.getDevices();

            this.setState({
                isLoading: false
            });
        }
        catch(e) {
            let msg = this.deviceErrorMsg;

            if(e && e.response && e.response.data && e.response.data.Message && e.response.data.Message.toLowerCase().includes('unable to find')) {
                msg = 'Device Not Found.'
            };
            this.setState({
                deleteDeviceError: msg,
                removeDeviceIsLoading: false,
                isLoading: false
            });
        };
    }

    toggleShowPassword = (e, name) => {
        const { showPassword } = this.state;
        e.preventDefault();
        let value = true;

        if(typeof showPassword[name]== 'boolean') {
            value = !showPassword[name];
        };

        this.setState({
            showPassword: {
                ...showPassword,
                [name]: value
            }
        });
    }

    renderDeviceStatus = (data) => {
        const device_type = _.get(data, 'device_type');
        const temperature = _.get(data, 'temperature');
        const tempUnits = _.get(data, 'temperature_units');

        let icon = '';
        let deviceStatus = '';
        let tempSymbol = '';

        if(temperature) {
            icon = 'icon-thermostat';
            deviceStatus = temperature;

            if(tempUnits == 'fahrenheit') {
                tempSymbol = this.fahrenheitSymbol;
            }
            else {
                tempSymbol = this.celsiusSymbol;
            };
        }
        else if(device_type == 'connected_lock') {
            if(_.isNil(data.locked)) {
                icon = 'icon-lock-unknown';
                deviceStatus = 'Unknown';
            }
            else if(_.get(data, 'locked')) {
                icon = 'icon-lock';
                deviceStatus = 'Locked';
            }
            else {
                icon = 'icon-unlock';
                deviceStatus = 'Unlocked';
            };
        }

        return(
            <div className="flex-align-center">
                <div className='device-icon-container'>
                    <i className={icon}></i>
                    <div className="device-status">{deviceStatus}{tempUnits && tempSymbol}</div>
                </div>
            </div>
        )
    }

    handleModal = (e, name) => {
        e && e.preventDefault();

        this.setState({
            modal: name ? name : ''
        });
    }

    handleDeviceData = (e) => {
        e.preventDefault();
        const { 
            selectedDevice
        } = this.state;
        let name = e.target.name;
        let value = e.target.value;

        this.setState({
            submitDeviceDataError: '',
            selectedDevice: {
                ...selectedDevice,
                [name]: value
            }
        });
    }
    
    handleAdditionDoorCodeOptions = (configs) => {
        let options = [];
        const globalTeamMembers = this.props.globalTeamMembers;

        if(globalTeamMembers.length) {
            let subIds = [];

            if(configs) {
                configs.forEach((config) => {
                    if(config.subscriber_id) {
                        subIds.push(config.subscriber_id)
                    };
                });
            };

            globalTeamMembers.forEach((tm) => {
                if(!subIds.includes(tm.subscriber_id)) {
                    let tmName = (tm && tm.subscriber && tm.subscriber.display_name) || '';
                    let tmId = (tm && tm.subscriber && tm.subscriber.subscriber_id) || '';
                    options.push(
                        {
                            label: tmName,
                            value: tmId,
                        }
                    );
                };
            });

            options.push(
                {
                    label: 'Create Code +',
                    value: 'createCode',
                }
            );
        };

        return options;
    }

    handleManageDevice = async (e, data, key) => {
        const { deviceActiveKey } = this.state;
        e.preventDefault();
        e.stopPropagation();
        let deviceProperty = [];
        let deviceConfig = {};
        let additionalDeviceConfigs = [];
        let additionalDoorCodeOptions = [];
        let isLeadTimeEmpty = true;

        if(key !== deviceActiveKey) {
            if(data && data.property_id) {
                deviceProperty = this.props.teamMemberPropertiesList.filter((property) => data.property_id == property.property_id);
    
                this.setState({
                    isLoading: true,
                });
    
                let respDeviceConfigs = await this.getDeviceConfig(data.property_id, data.device_type);
                additionalDeviceConfigs = await this.getAdditionalDeviceConfigs(data.organization_device_id);
    
                if(respDeviceConfigs && respDeviceConfigs !== null && (typeof respDeviceConfigs === 'object')) {
                    const filteredDeviceConfig = Object.entries(respDeviceConfigs).filter((entry) => entry[0] == data.location_type);
    
                    if(filteredDeviceConfig.length) {
                        deviceConfig = filteredDeviceConfig[0][1];

                        if(deviceConfig.minutes_before_checkin || deviceConfig.minutes_after_checkout) {
                            isLeadTimeEmpty = false;
                        };
                    };
    
                    this.copyDeviceCodeType = deviceConfig.type || '';
                };
            };

            additionalDoorCodeOptions = this.handleAdditionDoorCodeOptions(additionalDeviceConfigs);
    
            this.copyDevicePropertyValue = deviceProperty.length ? deviceProperty[0] : [];
            this.copyDeviceLocationType = data.location_type || '';
            this.copySelectedDevice = data;
            this.copyBeforeCheckIn = (deviceConfig && deviceConfig.minutes_before_checkin) ? deviceConfig.minutes_before_checkin.toString() : '120';
            this.copyAfterCheckout = (deviceConfig && deviceConfig.minutes_after_checkout) ? deviceConfig.minutes_after_checkout.toString() : '60';
            this.isLeadTimeEmpty = isLeadTimeEmpty;
            this.copyDeviceCodeValue = deviceConfig.value || '';
    
            this.setState({
                mode: '',
                submitAdditionalCodeError: '',
                submitDeviceDataError: '',
                selectedDevice: data,
                selectedDeviceConfig: deviceConfig,
                deviceCodeType: deviceConfig.type || '',
                deviceCodeValue: deviceConfig.value || '',
                deviceLocationType: data.location_type || '',
                devicePropertyValue: deviceProperty.length ? deviceProperty[0] : [],
                beforeCheckIn: (deviceConfig && deviceConfig.minutes_before_checkin) ? deviceConfig.minutes_before_checkin.toString() : '120',
                afterCheckout: (deviceConfig && deviceConfig.minutes_after_checkout) ? deviceConfig.minutes_after_checkout.toString() : '60',
                additionalDeviceConfigs: additionalDeviceConfigs || [],
                additionalDoorCodeValue: [],
                additionalDoorCodeOptions: additionalDoorCodeOptions,
                additionalCodeData: {
                    teamMemberData: {
                        name: '',
                        id: '',
                    },
                    name: '',
                    starts: new Date(),
                    expires: new Date(),
                    code: '',
                },
                isLoading: false,
            }, () => {
                if(data.device_type == 'connected_thermostat') {
                    let currentHeat = 60;
                    let currentCool = 70;
                    let currentSettings = '';
                    let scheduledHeat = 60;
                    let scheduledCool = 70;
                    let scheduledSettings = '';
        
                    if(data.cooling_set_point && data.heating_set_point) {
                        currentSettings = 'auto';
                        currentHeat = data.heating_set_point;
                        currentCool = data.cooling_set_point;
                    }
                    else if(data.cooling_set_point !== null) {
                        currentSettings = 'cool';
                        currentCool = data.cooling_set_point;
                    }
                    else if(data.heating_set_point !== null) {
                        currentSettings = 'heat';
                        currentHeat = data.heating_set_point;
                    };
    
                    if(Object.keys(deviceConfig).length) {
                        if(deviceConfig.cooling_set_point && deviceConfig.heating_set_point) {
                            scheduledSettings = 'auto';
                            scheduledHeat = deviceConfig.heating_set_point;
                            scheduledCool = deviceConfig.cooling_set_point;
                        }
                        else if(deviceConfig.cooling_set_point !== null) {
                            scheduledSettings = 'cool';
                            scheduledCool = deviceConfig.cooling_set_point;
                        }
                        else if(deviceConfig.heating_set_point !== null) {
                            scheduledSettings = 'heat';
                            scheduledHeat = deviceConfig.heating_set_point;
                        };
                    };
    
                    this.copyCurrentThermostatSettings = currentSettings;
                    this.copyCurrentThermostatTemp = {
                        heat: currentHeat,
                        cool: currentCool
                    };
    
                    this.copyScheduledThermostatSettings = scheduledSettings;
                    this.copyScheduledThermostatTemp = {
                        heat: scheduledHeat,
                            cool: scheduledCool
                    };
        
                    this.setState({
                        currentThermostatSettings: currentSettings,
                        currentThermostatTemp: {
                            heat: currentHeat,
                            cool: currentCool
                        },
                        scheduledThermostatSettings: scheduledSettings,
                        scheduledThermostatTemp: {
                            heat: scheduledHeat,
                            cool: scheduledCool
                        }
                    });
                };
    
                this.setState({
                    deviceActiveKey: key
                });
            });
        }
        else {
            this.setState({
                deviceActiveKey: ""
            });
        };
    }

    handleRemoveDevice = (e, data) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            selectedDevice: data,
        }, () => {
            this.handleModal('', 'remove-device-modal')
        });
    }

    handleDevicePropertyValue = (value) => {
        this.setState({
            updateDevicePropertyError: '',
            devicePropertyValue: value
        });
    }

    renderBattery = (batteryLevel, batteryStatus) => {
        let style = {
            height: `${batteryLevel * 100}%`
        };

        if(((batteryLevel * 100) < 20)) {
            style.backgroundColor = "red";
        };

        return (
            <Fragment>
                <div className='device-icon-container'>
                    <i className="icon-battery">
                        <div className="battery-level" style={style}/>
                    </i>
                </div>
                {
                    batteryStatus &&
                    <div className="device-status">{batteryStatus.charAt(0).toUpperCase() + batteryStatus.slice(1)}</div>
                }
            </Fragment>
        )
    }

    renderWifi = (wifiLevel) => {
        let convWifiLevel = wifiLevel * 100;
        let wifiIcon = '';
        let wifiStatus = ''

        if(_.isNil(wifiLevel)) {
            wifiStatus = 'Unknown';
        }
        else if(convWifiLevel == 100) {
            wifiIcon = Wifi_4;
            wifiStatus = 'Great';
        }
        else if(convWifiLevel < 99 && convWifiLevel > 75) {
            wifiIcon = Wifi_3;
            wifiStatus = 'Excellent';
        }
        else if(convWifiLevel < 75 && convWifiLevel > 50) {
            wifiIcon = Wifi_2;
            wifiStatus = 'Good';
        }
        else if(convWifiLevel < 50 && convWifiLevel > 10) {
            wifiIcon = Wifi_1;
            wifiStatus = 'Fair';
        }
        else if(convWifiLevel < 10) {
            wifiIcon = Wifi_0;
            wifiStatus = 'Poor';
        }

        return (
            <Fragment>
                <div className='device-icon-container'>
                    {
                        _.isNil(wifiLevel) ?
                        <i className='icon-wifi-unknown'></i> :
                        <img src={wifiIcon}></img>
                    }
                    <div className='device-status'>{wifiStatus}</div>
                </div>
            </Fragment>

        )
    }

    capitizeFirstLetter = (str) => {
        if(str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        };
    }


    renderMsg = (msg, type) => {
        return (
            <Col xs={12} sm={12} className='top-margin'>
                <div className={`msg-container ${type}`}>
                    {msg}
                </div>
            </Col>
        )
    }

    handleDoorCodeType = (value) => {
        this.setState({
            doorCodeValue: '',
            doorCodeType: value
        }, () => {
            if(value !== 'static') {
                this.updateDoorCode();
            };
        });
    }

    handleOnChange = (e) => {
        const { 
            selectedDevice,
            deviceCodeValue
        } = this.state;
        let name = e.target.name;
        let value = e.target.value;
        let copyDeviceCodeValue = deviceCodeValue;
        const accessCodeLength = selectedDevice && selectedDevice.access_code_lengths && selectedDevice.access_code_lengths.length;
        
        if(name == 'deviceCodeValue') {
            if(accessCodeLength) {
                if(deviceCodeValue.length >= selectedDevice.access_code_lengths[accessCodeLength - 1]) {
                    value = copyDeviceCodeValue.slice(0, selectedDevice.access_code_lengths[accessCodeLength - 1])
                };
            };
        }

        this.setState({
            [name]: value
        });
    }

    handleDeviceCodeTypeOnChange = (e) => {
        let value = e.target.value;

        this.setState({
            deviceCodeType: value
        });
    }

    reloadDevices = async () => {
        this.setState({
            isLoading: true
        });

        await this.getDevices('reload');

        this.setState({
            isLoading: false
        });
    }

    thermostatValueOnChange = (e, type) => {
        e.preventDefault();
        const tempName = type == 'current' ? 'currentThermostatTemp' : 'scheduledThermostatTemp';
        let name = e.target.name;
        let value = e.target.value;

        this.setState({
            [tempName]: {
                ...this.state[tempName],
                [name]: value
            }
        });
    }

    thermostatSettingsOnChange = (value, type) => {
        const settingsName = type == 'current' ? 'currentThermostatSettings' : 'scheduledThermostatSettings';

        this.setState({
            [settingsName]: value
        }, () => {
            const tempName = type == 'current' ? 'currentThermostatTemp' : 'scheduledThermostatTemp';
            let heat = 68;
            let cool = 78;

            if(value == 'auto') {
                heat = 68;
                cool = 78;
            }
            else if(value == 'cool') {
                cool = 78;
            };

            this.setState({
                [tempName]: {
                    heat: heat,
                    cool: cool
                }
            });
        });
    }

    assignDevicePropertyLevel = () => {
        this.setState({
            deviceLocationType: '',
            unassociatedDeviceValue: [],
            modal: 'assign-device-modal'
        });
    }

    handleUnassociatedDevice = (value) => {
        this.setState({
            unassociatedDeviceValue: value
        });
    }

    createRadioButton = (radioOptions, state, func, index) => {
		return (
			radioOptions.map((option, i) => (
			<Col md={3} sm={6} xs={12} className='no-padding' key={i}>
				<label
					id={option.name}
					className="radio-wrap radio-input"
				>
					<input
						type="radio" 
						className="form-radio-label radio-label"
						name={option.name + index}  
						onChange={e => func(e)}
						checked={state == option.value ? option.value : ""}
						value={option.value}
					/>
				<div
					className="radio-label"
				>
					{option.label}
				</div>
				</label>
			</Col>
		))
    )}

    additionalDoorCodeValidation = () => {
        const { additionalCodeData } = this.state;
        let disable = false;

        if(!additionalCodeData.name.length || !additionalCodeData.code.length || additionalCodeData.starts == null || additionalCodeData.expires == null) {
            disable = true;
        }
        else if(_.isEqual(this.copyAdditionalCodeData, additionalCodeData)){
            disable = true;
        }

        return disable;
    }

    manageDeviceValidation = () => {
        const {
            submitDeviceDataIsLoading,
            selectedDevice,
            devicePropertyValue,
            deviceLocationType,
            deviceCodeType,
            scheduledThermostatTemp,
            scheduledThermostatSettings,
            currentThermostatTemp,
            currentThermostatSettings,
            beforeCheckIn,
            afterCheckout,
            deviceCodeValue
        } = this.state;
        let disable = true;

        if(selectedDevice.device_type == 'connected_lock') {
            if(
                submitDeviceDataIsLoading ||
                ((!devicePropertyValue.length && typeof devicePropertyValue !== 'object') ||
                (!deviceLocationType.length || typeof deviceLocationType === null || typeof deviceLocationType === undefined))
            ) {
                disable = true;
            }
            else if(
                !_.isEqual(this.copySelectedDevice, selectedDevice) ||
                !_.isEqual(this.copyDevicePropertyValue, devicePropertyValue) ||
                !_.isEqual(this.copyDeviceLocationType, deviceLocationType) ||
                !_.isEqual(this.copyDeviceCodeType, deviceCodeType) ||
                !_.isEqual(this.copyDeviceCodeValue, deviceCodeValue) ||
                !_.isEqual(this.copyBeforeCheckIn, beforeCheckIn) ||
                !_.isEqual(this.copyAfterCheckout, afterCheckout) ||
                this.isLeadTimeEmpty
            ) {
                disable = false;
            };
        }
        else if(selectedDevice.device_type == 'connected_thermostat') {
            if(
                submitDeviceDataIsLoading ||
                (!devicePropertyValue.length && typeof devicePropertyValue !== 'object')
            ) {
                disable = true;
            }
            else if(
                !_.isEqual(this.copySelectedDevice, selectedDevice) ||
                !_.isEqual(this.copyDevicePropertyValue, devicePropertyValue) ||
                !_.isEqual(this.copyCurrentThermostatSettings, currentThermostatSettings) ||
                !_.isEqual(this.copyCurrentThermostatTemp, currentThermostatTemp) ||
                !_.isEqual(this.copyScheduledThermostatSettings, scheduledThermostatSettings) ||
                !_.isEqual(this.copyScheduledThermostatTemp, scheduledThermostatTemp) ||
                this.isLeadTimeEmpty
            ) {
                disable = false;
            };
        }

        return disable;
    }
    
    disableToggleBtn = () => {
        const { devicePropertyValue } = this.state;
        let disable = false;

        if(!Object.keys(devicePropertyValue).length) {
            disable = true;
        };

        return disable;
    }
    
    unassociatedDeviceValidation = () => {
        const { 
            unassociatedDeviceValue,
            deviceLocationType
        } = this.state;
        let notValid = true;

        if(unassociatedDeviceValue.device_type == 'connected_lock') {
            if(
                Object.keys(unassociatedDeviceValue).length &&
                deviceLocationType.length
            ) {
                notValid = false;
            };
        }
        else if(unassociatedDeviceValue.device_type == 'connected_thermostat') {

            if(Object.keys(unassociatedDeviceValue).length) {
                notValid = false;
            };
        };

        return notValid;
    }

    mfrScroll = (e, direction) => {
        e.preventDefault();

        let ele = document.getElementById('mfr-card-container');

        if(direction == 'left') {
            ele.scrollLeft -= 200;
        }
        else {
            ele.scrollLeft += 200;
        };
    }

    render() {
        const {
            modal,
            errorKey,
            isLoading,
            deviceList,
            allDevices,
            manufacturers,
            doorCodeType,
            selectedDevice,
            selectedGuestCode,
            deleteDeviceError,
            removeDeviceIsLoading,
            doorCodeValue,
            updateDoorCodeError,
            updateDoorCodeSuccess,
            deviceLocationType,
            unassociatedDeviceIsLoading,
            unassociatedDevices,
            unassociatedDeviceValue,
            currentAdditionalCodeData,
            deleteAdditionalDeviceConfigIsLoading,
        } = this.state;

        let locationTypeOptions = [<option key={'key'} value={""} disabled></option>];
        this.LOCATION_TYPE.forEach((type) => {
            let locationType = [];

            allDevices.forEach((device) => {
                if(device.location_type) {
                    locationType.push(device.location_type);
                };
            });

            if(!locationType.includes(type.value)) {
                locationTypeOptions.push(<option key={type.value} value={type.value}>{type.label}</option>);
            };
        });

        return (
            <Col md={12} sm={12} className={`edit-devices ${!this.createOrEditDevice ? 'readonly' : ''}`}>
                {
                    this.props.view !== 'property' &&
                    <h2>{this.props.propertyId  == 'global' ? 'Remote Devices' : 'Property Access'}</h2>
                }   
                <Col md={12} sm={12} className="container">
                
                    {isLoading && <Loader/>}
                
                    {
                        this.props.propertyId == 'global' ?
                        <Fragment>
                            {
                                (this.createOrEditConnectors &&
                                manufacturers.length > 0) &&
                                <Row>
                                    <Col md={12} className='bottom-margin'>
                                        <InputLabel>
                                            Connect Your Smart Lock, Thermostat, or Noise Sensor
                                        </InputLabel>
                                    </Col>
                                    <Col md={12} className='mfr-container bottom-margin'>
                                        <i 
                                            className='icon-left_arrow' 
                                            id='mfr-scroll-left' 
                                            onClick={(e) => this.mfrScroll(e, 'left')}
                                        ></i>
                                        <div id='mfr-card-container'>
                                            {
                                                manufacturers.map((mfr, i) => {
                                                    return (
                                                        <div
                                                            key={i}
                                                            className='mfr-card' 
                                                            onClick={(e) => this.submitConnectors(e, mfr)}
                                                        >
                                                            <img src={mfr.image_url} alt={mfr.title}></img>
                                                            <div className='mfr-title'>{mfr.title}</div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <i 
                                            className='icon-right_arrow' 
                                            id='mfr-scroll-right' 
                                            onClick={(e) => this.mfrScroll(e, 'right')}
                                        ></i>
                                    </Col>
                                </Row>
                            }
                            <Row>
                                <Col md={12} className='flex-space-between'>
                                    <div>
                                        <h4 id='devices'>Connected Devices</h4>
                                    </div>
                                    {
                                        this.createOrEditDevice &&
                                        <div className="reload-devices-container" >
                                            <div onClick={this.reloadDevices}>
                                                <span className='clickable-text'>Reload Devices</span>
                                            </div>
                                        </div>
                                    }
                                </Col>
                                {
                                    deviceList.length > 0 ?
                                    this.renderDevicePanels() 
                                    :
                                    <Col md={12} sm={12} className='no-devices-found'>
                                        <div className='no-devices-found'>No Devices found.</div>
                                    </Col>
                                }
                            </Row>
                        </Fragment>
                        :
                        <Fragment>
                            <Row>
                                <Col md={12} className='top-margin'>
                                    <h4>Guest Door Code</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} className='top-margin'>
                                    <ToggleButtonGroup 
                                        type="radio" 
                                        name="options" 
                                        onChange={this.handleDoorCodeType}
                                        value={doorCodeType}
                                    >
                                        <ToggleButton 
                                            id="tbg-radio-1" 
                                            value={'last_phone_number_digits'}
                                        >
                                            Guest's Phone Number (Last 4 Digits)
                                        </ToggleButton>
                                        <ToggleButton 
                                            id="tbg-radio-2" 
                                            value={'random_numeric'}
                                        >
                                            Random Numeric Code
                                        </ToggleButton>
                                        <ToggleButton 
                                            id="tbg-radio-2" 
                                            value={'random_alphanumeric'}
                                        >
                                            Random Alphanumeric Code
                                        </ToggleButton>
                                        <ToggleButton 
                                            id="tbg-radio-3" 
                                            value={'static'}
                                        >
                                            Set Code Manually
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Col>
                                {
                                    doorCodeType == 'static' &&
                                    <Col md={12} className='top-margin no-padding'>
                                        <Col md={5} sm={12} className='display-flex'>
                                            <InputField 
                                                type="number"
                                                value={doorCodeValue} 
                                                name="doorCodeValue"
                                                onChange={e => this.handleOnChange(e)}
                                            >
                                            </InputField>
                                            <PrimaryButton
                                                cssClass="pull-right"
                                                fullWidth={false} 
                                                type="button"
                                                onClick={this.updateDoorCode}
                                                disabled={!doorCodeValue.length}
                                            >
                                                Submit
                                            </PrimaryButton>
                                        </Col>
                                    </Col>
                                }
                                </Row>
                                <Row>
                                    {
                                        (updateDoorCodeError || updateDoorCodeSuccess) &&
                                        <Col md={12} className='no-padding'>
                                            <Col md={12}>
                                                {
                                                    updateDoorCodeError.length > 0 && this.renderMsg(updateDoorCodeError, '_error')
                                                }
                                                {
                                                    updateDoorCodeSuccess.length > 0 && this.renderMsg(updateDoorCodeSuccess, '_success')
                                                }
                                            </Col>
                                        </Col>
                                    }
                                </Row>
                                <Row>
                                    <Col md={12} className='top-margin'>
                                        <h4>Connected Devices</h4>
                                    </Col>
                                    {
                                        unassociatedDevices.length > 0 &&
                                        <Col md={12} sm={12} className="bottom-margin">
                                            <span 
                                                className='clickable-text'
                                                onClick={this.assignDevicePropertyLevel}
                                            >
                                                Attach a connected Device to this property
                                            </span>
                                        </Col>
                                    }
                                    {
                                        deviceList.length > 0 ?
                                        this.renderDevicePanels(deviceList) 
                                        :
                                        <Fragment>
                                            <Col md={12} sm={12} className='bottom-margin'>
                                                <div className='no-devices-found'>No Devices found.</div>
                                            </Col>
                                            {
                                                this.props.view !== 'property' &&
                                                <Col md={12} sm={12}>
                                                    <span 
                                                        className='clickable-text'
                                                        onClick={() => this.props.handlePropertyOptions({property_id: "global", title: "Global", label: "Global"})}
                                                    >
                                                        Add or assign devices in global settings
                                                    </span>
                                                </Col>
                                            }
                                        </Fragment>
                                    }
                                </Row>
                                
                        </Fragment>
                    }
                </Col>
                <Modal 
                    show={modal == 'remove-device-modal'}
                    className='remove-device-modal'
                    onHide={(e) => this.handleModal(e, '')}
                    backdrop='static'
                >
                    <Modal.Header>
                    </Modal.Header>
                    <Modal.Body>
                        {
                           removeDeviceIsLoading && <Loader/>
                        }
                        <Row className="text-center bottom-margin">
                            <Col xs={12}>
                                <h2>Removing Device {" "}
                                    <strong>
                                        {selectedDevice && selectedDevice.name && `"${selectedDevice.name}"`}
                                        {selectedGuestCode && selectedGuestCode.name && `"${selectedGuestCode.name}"`}
                                    </strong>
                                    {this.props.propertyId !== 'global' ? ` from ${this.props.propertyTitle}` : ''}.
                                </h2>
                                <h2>Do you want to continue?</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <PrimaryButton
                                    cssClass="pull-right"
                                    fullWidth={false} 
                                    type="button"
                                    onClick={
                                        this.props.propertyId !== 'global' ?
                                        this.handleRemoveDeviceProperty :
                                        this.removeDevice
                                    }
                                >
                                    Remove
                                </PrimaryButton>
                                <PrimaryButton
                                    cssClass='white-btn pull-right right-margin'
                                    fullWidth={false} 
                                    type="button"
                                    onClick={(e) => this.handleModal(e, '')}
                                >
                                    Cancel
                                </PrimaryButton>
                            </Col>
                        </Row>
                        {
                            (deleteDeviceError.length > 0) && this.renderMsg(deleteDeviceError, '_error')
                        }
                    </Modal.Body>
                </Modal>
                <Modal 
                    show={modal == 'delete-additional-config-modal'}
                    className='delete-additional-config-modal'
                    onHide={this.cancelDeleteAdditionalConfig}
                    backdrop='static'
                >
                    <Modal.Header>
                    </Modal.Header>
                    <Modal.Body>
                        {
                           deleteAdditionalDeviceConfigIsLoading && <Loader/>
                        }
                        <Row className="text-center bottom-margin">
                            <Col xs={12}>
                                <h2>Deleting {" "}
                                    <strong>
                                        {(currentAdditionalCodeData && currentAdditionalCodeData.config_name) ? `"${currentAdditionalCodeData.config_name}"` : 'Door Code'}
                                    </strong>.
                                </h2>
                                <h2>Do you want to continue?</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <PrimaryButton
                                    cssClass="pull-right"
                                    fullWidth={false} 
                                    type="button"
                                    onClick={this.handleDeleteAdditionalConfigOnClick}
                                >
                                    Remove
                                </PrimaryButton>
                                <PrimaryButton
                                    cssClass='white-btn pull-right right-margin'
                                    fullWidth={false} 
                                    type="button"
                                    onClick={this.cancelDeleteAdditionalConfig}
                                >
                                    Cancel
                                </PrimaryButton>
                            </Col>
                        </Row>
                        {
                            (deleteDeviceError.length > 0) && this.renderMsg(deleteDeviceError, '_error')
                        }
                    </Modal.Body>
                </Modal>
                <Modal 
                    show={modal == 'assign-device-modal'} 
                    className='comm-properties-modal' 
                    onHide={this.handleModal}
                    backdrop='static'
                >

                    {unassociatedDeviceIsLoading && <Loader/>}

                    <Modal.Header>
                        <h1>Select Device</h1>
                    </Modal.Header>
                    <Modal.Body>
                        <Picky
                            placeholder="Select Device"
                            labelKey="name"
                            valueKey="organization_device_id"
                            options={unassociatedDevices}
                            value={unassociatedDeviceValue}
                            multiple={false}
                            includeSelectAll={true}
                            includeFilter={true}
                            onChange={prop => this.handleUnassociatedDevice(prop)}
                            dropdownHeight={600} 
                            filterDebounce={100}   
                            keepOpen={false}
                            numberDisplayed={1}
                            clearFilterOnClose={true}
                            render={({
                                style,
                                item,
                                isSelected,
                                selectValue,
                                labelKey,
                                valueKey,
                                }) => {
                                    return (
                                        <li
                                            style={{ ...style }} 
                                            className={isSelected ? "selected" : ""} 
                                            key={item.organization_device_id}
                                            onClick={() => selectValue(item)}
                                        >
                                            <span
                                                style={{fontWeight: isSelected ? "bold" : "normal"}}
                                            >
                                                {
                                                    <span>{item[labelKey]}</span>                                                    
                                                }
                                            </span>
                                        </li>
                                    );
                                }}
                        />
                        <Row>
                            <Col xs={6} className='top-margin'>
                                {
                                    (unassociatedDeviceValue && 
                                    unassociatedDeviceValue.device_type == 'connected_lock') &&
                                    <InputField 
                                        type="select" 
                                        value={deviceLocationType} 
                                        name="deviceLocationType"
                                        onChange={e => this.handleOnChange(e)}
                                        required
                                    >
                                        {locationTypeOptions}
                                    </InputField>
                                }                     
                            </Col>
                            <Col xs={6} className='top-margin'>
                                <PrimaryButton
                                    cssClass="pull-right"
                                    fullWidth={false} 
                                    type="button"
                                    onClick={this.attachDeviceProperty}
                                    disabled={this.unassociatedDeviceValidation()}
                                >
                                    {selectedDevice && selectedDevice.property_id ? 'Update' : 'Save'}
                                </PrimaryButton>
                                <PrimaryButton
                                    cssClass='white-btn pull-right right-margin'
                                    fullWidth={false} 
                                    type="button"
                                    onClick={(e) => this.handleModal(e, '')}
                                >
                                    Cancel
                                </PrimaryButton>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
                <Modal 
                    show={modal == 'device-error-modal'}
                    onHide={(e) => this.handleModal(e, '')}
                    className='device-error-modal'
                >
                    <Modal.Header closeButton>
                        <h1><i className='icon-exclamation-triangle'></i>{" "}{_.get(selectedDevice, 'name')}{" "}Error{_.get(selectedDevice, 'errors.length') > 1 ? 's' : ''}</h1>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={12}>
                                <PanelGroup 
                                    accordion id="accordion-controlled-example"
                                    activeKey={errorKey}
                                    onSelect={(key) => this.setState({ errorKey: key })}
                                >
                                    {
                                        (_.get(selectedDevice, 'errors', []) || []).map((errorData, index) => {
                                        const url = /^(https?:\/\/)/.test(_.get(errorData, 'resolution_link', '')) ? new URL(_.get(errorData, 'resolution_link', '')) : '';
                                        const baseUrl = url.origin;
                                        const title = _.get(errorData, 'error_type','').replace(/_/g, ' ') || '';

                                            return(
                                                <Panel key={index} eventKey={index + 1}>
                                                    <Panel.Heading>
                                                        <Panel.Title
                                                            toggle
                                                        >
                                                            <div className='flex-space-between'>
                                                                <div className='capitalize'>{title}</div>
                                                                <div 
                                                                    className={
                                                                        'device-chevron ' + (errorKey === (index + 1) ? 
                                                                        'glyphicon glyphicon-chevron-up': 
                                                                        'glyphicon glyphicon-chevron-down')
                                                                    }
                                                                >
                                                                </div>
                                                            </div>
                                                        </Panel.Title>
                                                    </Panel.Heading>
                                                    <Panel.Body collapsible>
                                                        <Col xs={12}>
                                                            {_.get(errorData, 'message')}
                                                        </Col>
                                                        {
                                                            _.get(errorData, 'resolution_link') &&
                                                            <Col xs={12} className='top-margin'>
                                                                <div className='clickable-text'>
                                                                    <a className='clickable-text' target="_blank" href={`${url}`}>{baseUrl}</a>
                                                                </div>
                                                            </Col>
                                                        }
                                                    </Panel.Body>
                                                </Panel>
                                            )
                                        })
                                    }
                                </PanelGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </Col>
        )
    }
}

const mapStateToProps = state => {
    return {
        permissions: state.authState.permissions,
        org: state.roleManager.org,
    }
}

export default withRouter(connect(
    mapStateToProps,
    null
)(EditDevices));