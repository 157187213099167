import React, { Component, Fragment } from 'react';
import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import { 
    InputField, 
    PrimaryButton, 
    FieldWrap, 
    InputLabel,
} from '../../global/forms/FormElements';
import Panel from 'react-bootstrap/lib/Panel';
import PanelGroup from 'react-bootstrap/lib/PanelGroup';
import Modal from 'react-bootstrap/lib/Modal';
import moment from 'moment';
import TableComponent from '../../global/TableComponent';
import 'react-dates/initialize';
import DateRangePicker from 'react-dates/lib/components/DateRangePicker';
import Col from 'react-bootstrap/lib/Col';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import { DatePicker } from '../../global/forms/SingleDatePicker';
import Constants from '../../../js/constants';
import Tabs from 'react-bootstrap/lib/Tabs';
import Tab from 'react-bootstrap/lib/Tab';


/**
 * Mobile view for add or edit pricing and availability used on /calendar.
 */
export class MobileCalendarModal extends Component {
    expiresTimeLabel = Constants.TIME_15.map((time, i) => {
        return (
            <option key={i} value={time.value}>{time.label}</option>
        )
    });
    ovrLabel = [
        {name: "priceOvrSource", value: "min_price", label: "Minimum Price"},
        {name: "priceOvrSource", value: "price_ovr", label: "Modify Price"},
    ];
    state = {
        pricing: {
            focusedInput: "",
            pricingCalendar: [],
        },
        availability: {
            focusedInput: "",
            checkoutDateArray: [],
            availabilityArray: [],
            startDate: undefined,
            endDate: undefined,
            maxDisableDates: undefined,
            units: null,
            mCheckIn: "",
            mCheckOut: "",
            /* available rooms */
            pagination: {
                activePage: 1,
            },
        },
        reservationFocusedInput: null,
        roomsColumns: [
            {
                Header: ()=> (
                    <strong>Room</strong>
                ),
                accessor: 'title',
                Cell: row => (row.value),
                sortable: false,
            },
            {
                Header: ()=> (
                    <strong>ID</strong>
                ),
                accessor: 'id',
                minWidth: 60,
                Cell: row => (row.value),
                sortable: false,
            },
        ],
        reservationColumns: [
            {
                Header: ()=> (
                    <strong>Res Id</strong>
                ),
                accessor: 'reservation_id',
                Cell: row => (row.value),
                sortable: false,
            },
            {
                Header: ()=> (
                    <strong>Check-In</strong>
                ),
                accessor: 'checkin_date',
                Cell: row => moment(row.value).format('MM/DD/YYYY'),
                sortable: false,
            },
            {
                Header: ()=> (
                    <strong>Check-Out</strong>
                ),
                accessor: 'checkout_date',
                Cell: row => row.value && (row.value._i ? 
                moment(row.value._i).format('MM/DD/YYYY')  : 
                moment(row.value).format('MM/DD/YYYY')),
                sortable: false,
            },
        ],
    }

    componentWillReceiveProps(nextProps) {
        let currentPropsShow = 
            this.props.mobileCalendarModal.availabilityPanel.show || 
            this.props.mobileCalendarModal.pricingPanel.show,

            nextPropsShow = 
            nextProps.mobileCalendarModal.availabilityPanel.show || 
            nextProps.mobileCalendarModal.pricingPanel.show,
            touchable

        if (nextPropsShow) {
            setTimeout(()=> {
                [ touchable ] = document.getElementsByClassName('modal-title')
                if (touchable) {
                    touchable.addEventListener('touchstart', this.props.handleTouchStart, false);
                    touchable.addEventListener('touchmove', this.props.handleTouchMove, false);
                }
            }, 10)
        }
        else {
            if (touchable) {
                touchable.removeEventListener('touchstart', this.props.handleTouchStart, false);
                touchable.removeEventListener('touchmove', this.props.handleTouchMove, false);
            }
            this.setState({
                activeSubKey: "",
            })
        }

        if (currentPropsShow !== nextPropsShow) {
            this.setState({
                activeKey: nextProps.mobileCalendarModal.pricingPanel.show ? 1 : 2,
            })
        }
        
        /* pricing panel */
        this.setState({
            pricing: { 
                ...this.state.pricing,
                pricingCalendar: nextProps.propertyPricePeriods,
            }
        })
        /* availability panel */
        this.getAvailabilityDates(nextProps.mobileCalendarModal.availabilityPanel.property_id)
        if (nextPropsShow) {
            this.setState({
                availability: {
                    ...this.state.availability,
                    units: nextProps.children && nextProps.children.length || 0
                }
            })
        }
    }
    
    /* PRICING PANEL METHODS */

    /**
     * Handle date change on pricing panel datepicker.
     * @param {*} startDate
     * @param {*} endDate
     */
    handleDatesChange = ({startDate,endDate}, type) => {
        this.setState({
            isDisabled: false
        });
        if(type === 'price') {
            if (startDate) {
                this.props.handlePeriodDateChange(startDate,'period_start', 'price', {startDate,endDate})
            }
            if (endDate) {
                this.props.handlePeriodDateChange(endDate,'period_end', 'price', {startDate,endDate})
            };
        }
        else {
            if (startDate) {
                this.props.handlePeriodDateChange(startDate,'start_date', 'cico', {startDate,endDate})
            }
            if (endDate) {
                this.props.handlePeriodDateChange(endDate,'end_date', 'cico', {startDate,endDate})
            };
        };
    }

    propertyPricePeriods = (day) => {
        const { 
            pricing: {
                pricingCalendar,
            }
        } = this.state
        if (day && pricingCalendar) {
            for (let i = 0; i < pricingCalendar.length; i+=1) {
                let period_start = moment(pricingCalendar[i].period_start)
                let period_end = moment(pricingCalendar[i].period_end).add(1, 'd')
                //refactor line of code to use inBetween with []
                if (moment(day).isBetween(period_start, period_end) || moment(day).isSame(period_start) || moment(day).isSame(period_end)) {
                    return true;
                } 
            }
        }        
    }

    cicoPeriods = (day) => {
        const modal = this.props.mobileCalendarModal.cicoPanel;

        if (day){
            if (moment(day).isBetween(modal.data.start_date, modal.data.end_date) || moment(day).isSame(modal.data.start_date) || moment(day).isSame(modal.data.end_date)) {
                return true;
            };
        };
    }

    /**
     * updates focused input of DateRangePicker
     * @param {String} focusedInput string startDate or endDate
     * @param {String} inputType string panel type
    */
    handleFocusChange = (focusedInput, inputType) => {
        this.setState({
            [inputType]: {
                ...this.state[inputType],
                focusedInput,
            }
        })
    }

    /**
     * updates activePage for availability subpanel tables
     * @param {String} page string page number
    */
    handlePageChange = page => {
        this.setState({
            availability: {
                ...this.state.availability,
                pagination: {
                    ...this.state.availability.pagination,
                    activePage: page + 1,
                }
            }
        })
    }

    /**
     * Handle availability panel's subpanel change
     * @param {*} key
     */
    handleSubPanelChange = key => {
        this.setState({
            activeSubKey: key,
            availability: {
                ...this.state.availability,
                pagination: {
                    ...this.state.availability.pagination,
                    activePage: 1,
                }
            }
        })
    }

    /* AVAILABILITY PANEL METHODS */

    /**
     * Handle date change of availability panel datepicker
     * @param {*} param
     */
    handleAvailabilityDatesChange = async ({startDate, endDate}) => {
        if (startDate) {
            this.props.handleBlockedDateChange(startDate,'period_start', {startDate, endDate})
        };
        if (endDate) {
            this.props.handleBlockedDateChange(endDate,'period_end', {startDate, endDate})
        };
        if (startDate && this.state.availability.availabilityArray && this.state.availability.availabilityArray.length > 0) {
            for (var i = 0; i < this.state.availability.availabilityArray.length; i++) {
                if (!(this.state.availability.maxDisableDates) && startDate < moment(this.state.availability.availabilityArray[i])) {
                    await this.setState({
                        availability: {
                            ...this.state.availability,
                            maxDisableDates: moment(this.state.availability.availabilityArray[i]).add(1, 'days')
                        }
                    })
                }
            }
        }
        if (startDate && endDate && this.state.availability.endDate) {
            this.setState({
                availability: {
                    ...this.state.availability,
                    maxDisableDates: undefined,
                }
            })
        }
    }

    submitAvailability = async() => {
        this.setState({
            isAvailabilityDisabled: true,
        })
        let level = this.currentProperty.level,
        units = this.props.availableChildren.length - this.state.availability.units

        await this.props.submitAvailability(level, units)

        setTimeout(() => {
            this.setState({
                isAvailabilityDisabled: false,
            })
        }, 2500)
    }

    /**
     * Navigate to reservation details page
     * @param {*} data reservation
     */
    viewReservationDetails = data => {
        this.props.history.push(`/reservations/${data.reservation_id}/details`)
    }

    /**
     * For recognize is specific date is already blocked or not.
     * @param {*} day specific date.
     */
    isDayBlocked = (day) => {
        let { 
            availability: { 
                checkoutDateArray 
            } 
        } = this.state
        if (!checkoutDateArray.includes(moment(day).format("YYYY-MM-DD"))) {
            return (moment(day) < moment(new Date()))
        }
    }

    /**
     * For fetching date from availability.
     * @param {*} property_id 
     */
    getAvailabilityDates = property_id => {
        let checkoutDateArray = []
        let bookDates = [];
        let property = this.props.getPropertyById(property_id)[0];
        if(property && property.availability && property.availability.length){
            property.availability.forEach((avail) => {
                let take2YearData = new Date();
                take2YearData.setUTCHours(0,0,0,0)
                take2YearData.setUTCFullYear(take2YearData.getUTCFullYear() + 2)
                let date = avail.period && new Date(avail.period.period_start);

                if (date) {
                    date.setUTCHours(0, 0, 0, 0)
                    if(0 < date.getTimezoneOffset()){
                            date = date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
                    }
                }
                
                let endDate = avail.period && new Date(avail.period.period_end);

                if (endDate) {
                    endDate.setUTCHours(0, 0, 0, 0)
                    if(0 < endDate.getTimezoneOffset()){
                        endDate = endDate.setMinutes(endDate.getMinutes() + endDate.getTimezoneOffset());
                    }
                }
                
                while (new Date(date).getTime() <= new Date(endDate).getTime() && new Date(date).getTime() <= take2YearData.getTime()) {
                    bookDates.push(new Date(date).toISOString().substr(0, 10))
                    date = new Date(date).setDate(new Date(date).getDate() + 1);
                }
            })
            bookDates.forEach(date=>{
                if(!bookDates.includes(moment(date).subtract(1,"days").format("YYYY-MM-DD"))) {
                    checkoutDateArray.push(date);
                }
            })
            bookDates.sort()
            this.setState({
                availability: {
                    ...this.state.availability,
                    availabilityArray: bookDates,
                    checkoutDateArray: checkoutDateArray,
                }
            });
        }
    }

    /**
     * Handle number of units change
     * @param {*} units 
     */
    handleUnitsChange = units => {
        units = units.target.value
        this.setState({
            availability: {
                ...this.state.availability,
                units: units,
            }
        })
    }

    /**
     * Update panel key
     * @param {*} key active key
     * @param {*} event
     */
    updateActiveKey = (key, event) => {
        this.setState({ 
            activeKey: key,
        })
        /* update panel to show in parent state */
        this.props.updateShowActivePanel(key, event, this.currentProperty)
    }

    render() {
        let { 
            pricingPanel, 
            availabilityPanel,
            cicoPanel
        } = this.props.mobileCalendarModal;

        const { 
            pricing, 
            availability,
            reservationFocusedInput,
        } = this.state

        let show = pricingPanel.show || availabilityPanel.show
        let id = pricingPanel.property_id || availabilityPanel.property_id
        let fDate = moment(this.props.dateClicked).format('YYYY-MM-DD')
        let panel = pricingPanel.show === true ? 'pricing' : 'availability'

        this.currentProperty = this.props.getPropertyById(id)[0]

        const smartPricingEnabled = 
            this.currentProperty && 
            this.currentProperty.smart_pricing_enabled ?
            true : 
            false;
        const smartPricingdata = pricingPanel.priceOvr;

        this.property_id = id

        let isPricingDisabled, isAvailabilityDisabled = false
        let errors = this.props.errors
        let errorMessage = ''

        let expiresTime = "00:00:00Z"
        if(availabilityPanel.data.expires) {
            expiresTime = availabilityPanel.data.expires.split("T")[1];
        };
        
        /* pricing variables */
        
        if (pricingPanel.show) {
            Object.keys(errors).map(error => {
                if(errors[error].show) {
                    isPricingDisabled = true
                    if (errors[error].type === '2') {
                        errorMessage = errors[error].message.replace(/"/g,'')
                    }   
                }
            })
        }

        if (availabilityPanel.show) {
            Object.keys(errors).map(error => {
                if(errors[error].show) {
                    isAvailabilityDisabled = true
                }
            })
        }
        
        /* available room variables */

        let availableRooms = [],
        unavailableRooms = [], 
        selectedDateReservations = []


        if (panel === 'availability') {
            if (this.currentProperty && this.currentProperty.level === "rep") {
                unavailableRooms = this.props.unavailability.blockTypes && 
                this.props.unavailability.blockTypes.length ? 
                this.props.unavailability.blockTypes.map(block => {
                    let children = []
                    block.availability_ids.forEach(availability_id => {
                        this.props.children.map(child => {
                            child.availability.map(avail => {
                                if (avail.period.availability_id === availability_id) {
                                    children.push({title: child.content.title, id: child.property_id})
                                }
                            })
                        })
                    })
                    return (
                        {
                            children: children,
                            type: block.type,
                            quantity: block.quantity,
                        }
                    )
                }) : []
                
                let unavilableRoomPropertyIds = []
                unavailableRooms.forEach(unavail => {
                    let availIds = unavail.children.map(child => child.id)
                    unavilableRoomPropertyIds = [
                        ...unavilableRoomPropertyIds, 
                        ...availIds
                    ]
                })
                this.props.children && this.props.children.map(child=> {
                    if (unavilableRoomPropertyIds.indexOf(child.property_id) === -1) {
                        availableRooms.push({
                            title: child.content.title,
                            id: child.property_id,
                        })
                    }
                })
                if (unavailableRooms.length === 0) {
                    availableRooms = this.props.children.map(child => {
                        return ({
                            title: child.content.title,
                            id: child.property_id,
                        })
                    })
                }
                /* return repchild reservations that correspond to selected date */
                selectedDateReservations = this.props.reservations.filter(reservation => {
                    if (moment(fDate).isSameOrAfter(moment(reservation.checkin_date).format('YYYY-MM-DD')) && moment(fDate).isSameOrBefore(moment(reservation.checkout_date).format('YYYY-MM-DD'))) {
                        return true
                    }
                }).filter(res => {
                    let isProperty = false
                    this.currentProperty.repconfig.units_active.forEach(unit => {
                        if (unit === res.property_id) {
                            isProperty = true
                        }
                    })
                    return isProperty
                })
            }
        }

        let promotions = [<option key={0} value={''}>None</option>];
        if(availabilityPanel && availabilityPanel.reservations && availabilityPanel.reservations.promotionCodeData && availabilityPanel.reservations.promotionCodeData.length) {

            for (let i = 0; i < availabilityPanel.reservations.promotionCodeData.length; i++) {
                const org_promo_id = availabilityPanel.reservations.promotionCodeData[i].org_promo_id;

                promotions.push(<option key={i + 1} value={org_promo_id}>{org_promo_id}</option>);
            };
        };

        return this.currentProperty ? (
            <Modal 
                className='calendar-modal mobile'
                bsClass='modal' 
                show={show}
                onHide={()=>this.props.hideMobileCalendarModal()}
                animation={false}
                centered="true"    
            >
                <Modal.Header closeButton>
                    {`${this.currentProperty.property_id} - ${this.currentProperty.content.title}`}
                </Modal.Header>
                <Modal.Title>
                    <div className="modal-nav-wrapper">
                        {moment(fDate).isSame(this.props.today, 'day') ? (
                            <a className="icon-left_arrow disabled"/>
                        ) : (
                            <a 
                                className="icon-left_arrow"
                                href="#!" 
                                onClick={(e)=>this.props.handleUpdateModalEventsByDate(e, moment(fDate).subtract(1, 'days').format('YYYY-MM-DD'), panel, this.currentProperty)} 
                                title="Previous"
                            />
                        )}
                        <p 
                            className="selected-date"
                        >
                            {moment(fDate).format("dddd, MMMM D, YYYY")}
                        </p>
                        <a 
                            className="icon-right_arrow"
                            href="#!"
                            onClick={(e)=>this.props.handleUpdateModalEventsByDate(e, moment(fDate).add(1, 'days').format('YYYY-MM-DD'), panel, this.currentProperty)} 
                            title="Next"
                        />
                    </div>
                </Modal.Title>
                <Modal.Body>
                    <PanelGroup
                        accordion 
                        defaultActiveKey={this.state.activeKey}
                        id="mobile-panel-group"
                        onSelect={(activeKey, event) => {
                            this.updateActiveKey(activeKey, event)
                        }}
                    >
                        {this.currentProperty.level !== 'repchild' &&
                            <Panel 
                                eventKey={1} 
                                className='pricing'
                            >
                                <Panel.Heading className='mobile'>
                                    <Panel.Title
                                        className="center-content"
                                        toggle
                                    >
                                        Pricing <span className={'pull-right '+(this.state.activeKey === 1 ? 'glyphicon glyphicon-chevron-up': 'glyphicon glyphicon-chevron-down')}></span>
                                    </Panel.Title>
                                </Panel.Heading>
                                <Panel.Collapse>
                                    <Panel.Body>
                                        <Grid fluid={true}>
                                            <Row>
                                                {
                                                    smartPricingEnabled &&
                                                    <Col md={12} className="center-content bottom-margin-10">
                                                        <InputField
                                                            cssClass="form-check-label checkbox-label"
                                                            type="checkbox"
                                                            value={pricingPanel.minPriceOnly}
                                                            cbChange={this.props.toggleMinPriceOnly}
                                                        >
                                                            Only Modify Minimum Stay
                                                        </InputField>
                                                    </Col>
                                                }
                                            </Row>
                                            <Row>
                                                <Col 
                                                    xs={12}
                                                    className="center-content"
                                                >
                                                    Start (Night of Check-In)
                                                </Col>
                                                <Col xs={12}>
                                                    {
                                                        this.currentProperty && 
                                                        <div className="date-picker-calendar-group">
                                                            <DateRangePicker 
                                                                startDate={pricingPanel.pricingDates.startDate}
                                                                startDateId='priceStartDate'
                                                                endDate={pricingPanel.pricingDates.endDate}
                                                                endDateId='priceEndDate'
                                                                focusedInput={this.state.focusedPricingInput}
                                                                onFocusChange={focusedInput => this.setState({focusedPricingInput: focusedInput})}
                                                                onDatesChange={e => this.handleDatesChange(e, "price")}
                                                                noBorder
                                                                numberOfMonths={1}
                                                                startDatePlaceholderText="Check-In"
                                                                endDatePlaceholderText="Check-Out"
                                                                minimumNights={0}
                                                                isDayHighlighted={this.propertyPricePeriods}
                                                            />
                                                        </div>
                                                    }
                                                </Col>
                                                <Col 
                                                    xs={12}
                                                    className="center-content"
                                                    style={{marginTop: '-70px'}}
                                                >
                                                    End (Last Night of Stay)
                                                </Col>
                                            </Row>
                                            {
                                                !smartPricingEnabled ? 
                                                <Fragment>                                                
                                                <Row>
                                                    <Col 
                                                        xs={12}
                                                        className="center-content"
                                                    >
                                                        <FieldWrap>
                                                            <InputLabel htmlFor='weekendPrice'>Weekend Price</InputLabel>
                                                            <InputField 
                                                                disabled={!this.props.admin} 
                                                                onChange={e=>this.props.handlePricingPanelAttrChange(e,'weekend_price')} 
                                                                value={pricingPanel.data.weekend_price} 
                                                                type='number' 
                                                                id='weekendPrice'
                                                                placeholder="0.00" 
                                                            />
                                                            <span 
                                                                className={`error-msg ${errors.weekend_price.show?'':'hide'}`}
                                                            >
                                                                {errors.weekend_price.message}
                                                            </span>
                                                        </FieldWrap>
                                                    </Col>
                                                    <Col 
                                                        xs={12}
                                                        className="center-content"
                                                    >
                                                        <FieldWrap>
                                                            <InputLabel htmlFor='weekdayPrice'>Weekday Price</InputLabel>
                                                            <InputField 
                                                                disabled={!this.props.admin} 
                                                                onChange={e=>this.props.handlePricingPanelAttrChange(e,'weekday_price')}
                                                                value={pricingPanel.data.weekday_price}
                                                                type='number' 
                                                                id='weekdayPrice' 
                                                                placeholder="0.00"
                                                            />
                                                            <span 
                                                                className={`error-msg ${errors.weekday_price.show?'':'hide'}`}
                                                            >
                                                            {errors.weekday_price.message}
                                                            </span>
                                                        </FieldWrap>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col 
                                                        xs={12}
                                                        className="center-content"
                                                    >
                                                        <FieldWrap>
                                                            <InputLabel htmlFor='weeklyPrice'>$/Day Weekly Price</InputLabel>
                                                            <InputField 
                                                                disabled={!this.props.admin} 
                                                                onChange={e=>this.props.handlePricingPanelAttrChange(e,'weekly_price')}
                                                                value={pricingPanel.data.weekly_price}
                                                                type='number' 
                                                                id='weeklyPrice'
                                                                placeholder="0.00"
                                                            />
                                                            <span 
                                                                className={`error-msg ${errors.weekly_price.show || errors.avg_price.show ? '' : 'hide'}`}
                                                            >
                                                                {errors.weekly_price.show ? errors.weekly_price.message : errors.avg_price.show ? errors.avg_price.message : ""}
                                                            </span>
                                                        </FieldWrap>
                                                    </Col>
                                                    <Col 
                                                        xs={12}
                                                        className="center-content"
                                                    >
                                                        <FieldWrap>
                                                            <InputLabel htmlFor='minstay'>Minimum # of Nights</InputLabel>
                                                            <InputField 
                                                                disabled={!this.props.admin} 
                                                                onChange={e=>this.props.handlePricingPanelAttrChange(e,'minstay')} 
                                                                value={pricingPanel.data.minstay}
                                                                type='number' 
                                                                id='minstay'
                                                                placeholder="0"
                                                            />
                                                            <span 
                                                                className={`error-msg ${errors.minstay.show?'':'hide'}`}
                                                            >
                                                                {errors.minstay.message}
                                                            </span>
                                                        </FieldWrap>
                                                    </Col>
                                                    <Col 
                                                        xs={12}
                                                        className="center-content"
                                                    >
                                                        <FieldWrap>
                                                            <InputLabel htmlFor='minstay'>Maximum # of Nights</InputLabel>
                                                            <InputField 
                                                                disabled={!this.props.admin} 
                                                                onChange={e=>this.props.handlePricingPanelAttrChange(e,'maxstay')} 
                                                                value={pricingPanel.data.maxstay}
                                                                type='number' 
                                                                id='maxstay'
                                                                placeholder="0"
                                                            />
                                                        </FieldWrap>
                                                    </Col>
                                                </Row>
                                                </Fragment>
                                                :
                                                <Fragment>
                                                    <Row className="center-content">
                                                        {
                                                            !pricingPanel.minPriceOnly &&
                                                            <Fragment>
                                                                <Col xs={12}>
                                                                    <div className="parallel-radio-container bottom-margin-10">
                                                                        {this.props.createDefaultRadioButton(this.ovrLabel, pricingPanel.priceOvrSource, this.props.handleSmartPricingSource)}
                                                                    </div>
                                                                </Col>
                                                                <Col xs={12} sm={12} className="bottom-margin-20">
                                                                    Price: $ {pricingPanel && pricingPanel.data && pricingPanel.data.weekday_price}
                                                                </Col>
                                                                <Col xs={12} sm={12} className="bottom-margin-10">
                                                                    <FieldWrap>
                                                                        {
                                                                            pricingPanel.priceOvrSource == "price_ovr" ?
                                                                            <Fragment>
                                                                                <InputLabel htmlFor='weekendPrice'>Price Override</InputLabel>
                                                                                <InputField 
                                                                                    disabled={!this.props.admin} 
                                                                                    onChange={e=>this.props.handleSmartPricingChange(e)} 
                                                                                    value={smartPricingdata.price || ""} 
                                                                                    type='number'
                                                                                    name='price'
                                                                                    placeholder="0.00"
                                                                                />
                                                                            </Fragment>
                                                                            :
                                                                            <Fragment>
                                                                                <InputLabel htmlFor='weekendPrice'>Minimum Price</InputLabel>
                                                                                <InputField 
                                                                                    disabled={!this.props.admin} 
                                                                                    onChange={e=>this.props.handleSmartPricingChange(e)} 
                                                                                    value={smartPricingdata.min_price ||""} 
                                                                                    type='number'
                                                                                    name='min_price'
                                                                                    placeholder="0.00"
                                                                                />
                                                                            </Fragment>
                                                                        }
                                                                    </FieldWrap>
                                                                </Col>
                                                            </Fragment>
                                                        }
                                                        <Col xs={12} sm={12} className="bottom-margin-20">
                                                            Min # of Nights: {pricingPanel && pricingPanel.data && pricingPanel.data.minstay}
                                                        </Col>
                                                        <Col xs={12} sm={12}>
                                                            <FieldWrap>
                                                                <InputLabel htmlFor='minstay'>Min # of Nights Override</InputLabel>
                                                                <InputField 
                                                                    disabled={!this.props.admin} 
                                                                    onChange={e=>this.props.handleSmartPricingChange(e)}  
                                                                    value={smartPricingdata.min_stay}
                                                                    type='number'
                                                                    name='min_stay'
                                                                    placeholder="0"
                                                                />
                                                            </FieldWrap>
                                                        </Col>
                                                    </Row>
                                                </Fragment>
                                            }
                                            {this.props.admin && 
                                                <Row>
                                                    {
                                                        pricingPanel.mode ?
                                                        <Col 
                                                            xs={12}
                                                            className="center-content top-margin"
                                                        >
                                                            <button 
                                                                onClick={
                                                                    smartPricingEnabled ?
                                                                    e => this.props.deletePricingOvr(e)
                                                                    :
                                                                    e => this.props.deletePricing(e)
                                                                } 
                                                                className='btn-delete'
                                                            >
                                                                Delete {smartPricingEnabled ? "Override" : "Pricing"}<span className={'glyphicon glyphicon-refresh'+(this.props.isDeleting ? ' spinning':' hide')}></span>
                                                            </button>
                                                        </Col> :''
                                                    }
                                                    <Col 
                                                        xs={12}
                                                        className="center-content top-margin"
                                                    >
                                                        <PrimaryButton 
                                                            disabled={isPricingDisabled} 
                                                            cssClass={pricingPanel.mode? 'black-btn ':''} 
                                                            type='button' 
                                                            onClick={
                                                                smartPricingEnabled ?
                                                                e => this.props.submitPricingOvr(e)
                                                                :
                                                                e => this.props.submitPricing(e)
                                                            }
                                                            fullWidth={false}
                                                        >
                                                            {pricingPanel.mode?'Update':'Add'} Pricing<span className={'glyphicon glyphicon-refresh'+(this.props.loading ? ' spinning':' hide')}></span>
                                                        </PrimaryButton>
                                                    </Col>
                                                </Row>
                                            }
                                            <Row>
                                                <Col 
                                                    xs={12}
                                                    className="center-content"
                                                >
                                                    <span className={errorMessage ?'error-msg':'hide'}>{errorMessage}</span>
                                                </Col>
                                            </Row>
                                        </Grid>
                                    </Panel.Body>
                                </Panel.Collapse>
                            </Panel>
                        }
                        {
                            !((this.currentProperty && this.currentProperty.level === "rep") && this.props.integrationName.integration_name === "racalendar") &&
                            <Panel 
                                eventKey={2}
                                className='availability'
                            >
                                <Panel.Heading className="mobile">
                                    <Panel.Title 
                                        toggle
                                        className="center-content"
                                    >
                                        Availability <span className={'pull-right '+(this.state.activeKey === 2 ? 'glyphicon glyphicon-chevron-up': 'glyphicon glyphicon-chevron-down')}></span>
                                    </Panel.Title>
                                </Panel.Heading>
                                <Panel.Collapse>
                                    <Panel.Body>
                                        <Grid fluid={true}>
                                            {this.currentProperty.level === "rep" &&
                                                <Fragment>
                                                    {(availableRooms.length > 0 || unavailableRooms.length > 0) &&
                                                        <Row>
                                                            <Col xs={12}>
                                                                <PanelGroup
                                                                    accordion  
                                                                    className="sub-panel-group"
                                                                    id="sub-panel-group"
                                                                    activeKey={this.state.activeSubKey} 
                                                                    onSelect={(activeSubKey) => {
                                                                        this.handleSubPanelChange(activeSubKey)
                                                                    }}
                                                                >
                                                                    <Panel
                                                                        eventKey="sub-1"
                                                                    >
                                                                        <Panel.Heading className="mobile">
                                                                            <Panel.Title
                                                                                toggle
                                                                                className="center-content"
                                                                            >
                                                                                {`${availableRooms.length} Room${availableRooms.length === 1 ? '' : 's'} Available `}<span className="expand-table pull-right">{this.state.activeSubKey === 'sub-1' ? '-' : '+'}</span>
                                                                            </Panel.Title>
                                                                        </Panel.Heading>
                                                                        <Panel.Collapse>
                                                                            <Panel.Body>
                                                                                <TableComponent
                                                                                    className='-highlight'
                                                                                    data={availableRooms}
                                                                                    resizable={false}
                                                                                    minRows={0}
                                                                                    totalItemsCount={availableRooms.length}
                                                                                    pageSize={5}
                                                                                    onPageChange={this.handlePageChange}
                                                                                    tableComponentPage={availability.pagination.activePage}
                                                                                    mobileMode
                                                                                    columns={this.state.roomsColumns}
                                                                                    noDataText="No property children found"
                                                                                />
                                                                            </Panel.Body>
                                                                        </Panel.Collapse>
                                                                    </Panel>
                                                                    <Panel
                                                                        eventKey="sub-2"
                                                                    >
                                                                        <Panel.Heading className="mobile">
                                                                            <Panel.Title
                                                                                toggle
                                                                                className="center-content"
                                                                            >
                                                                                {`${selectedDateReservations.length} Reservation${selectedDateReservations.length === 1 ? '' : 's'}`}
                                                                                <span className="expand-table pull-right">{this.state.activeSubKey === 'sub-2' ? '-' : '+'}</span>
                                                                            </Panel.Title>
                                                                        </Panel.Heading>
                                                                        <Panel.Collapse>
                                                                            <Panel.Body>
                                                                                <TableComponent
                                                                                    className='-highlight'
                                                                                    data={selectedDateReservations}
                                                                                    resizable={false}
                                                                                    minRows={0}
                                                                                    mobileMode
                                                                                    tableComponentPage={availability.pagination.activePage}
                                                                                    totalItemsCount={selectedDateReservations.length}
                                                                                    pageSize={5}
                                                                                    onPageChange={this.handlePageChange}
                                                                                    columns={this.state.reservationColumns}
                                                                                    getTrProps = {(state, rowInfo) => {
                                                                                        return {
                                                                                            onClick: () => {
                                                                                                this.props.history.push(`/reservations/${rowInfo.original.reservation_id}/details`)
                                                                                            },
                                                                                            style: {
                                                                                                cursor: 'pointer'
                                                                                            }
                                                                                        }
                                                                                    }}
                                                                                    noDataText="No reservations found on selected date"
                                                                                />
                                                                            </Panel.Body>
                                                                        </Panel.Collapse>
                                                                    </Panel>
                                                                    {unavailableRooms && unavailableRooms.map((data, idx) => {
                                                                        return data.type !== "BlockedByBooking" && (
                                                                            <Panel
                                                                                key={idx}
                                                                                eventKey={`sub-${idx+3}`}
                                                                            >
                                                                                <Panel.Heading className="mobile">
                                                                                    <Panel.Title
                                                                                        toggle
                                                                                        className="center-content"
                                                                                    >
                                                                                        {data.quantity} Blocked By {data.type.split('By')[1]} <span className="expand-table pull-right">{this.state.activeSubKey === `sub-${idx+3}` ? '-' : '+'}</span>
                                                                                    </Panel.Title>
                                                                                </Panel.Heading>
                                                                                <Panel.Collapse>
                                                                                    <Panel.Body>
                                                                                        <TableComponent
                                                                                            className='-highlight'
                                                                                            data={data.children}
                                                                                            resizable={false}
                                                                                            minRows={0}
                                                                                            mobileMode
                                                                                            totalItemsCount={data.children.length}
                                                                                            pageSize={10}
                                                                                            onPageChange={this.handlePageChange}
                                                                                            tableComponentPage={availability.pagination.activePage}
                                                                                            columns={this.state.roomsColumns}
                                                                                        />
                                                                                    </Panel.Body>
                                                                                </Panel.Collapse>
                                                                            </Panel>
                                                                        )
                                                                    })}
                                                                </PanelGroup>
                                                            </Col>
                                                        </Row>
                                                    }
                                                </Fragment> 
                                            }
                                            {this.currentProperty.level === "key" && (
                                                <Row>
                                                    <Col 
                                                        xs={12}
                                                        className="center-content bottom-margin availability-label"
                                                    >
                                                        {availabilityPanel.data && 
                                                        availabilityPanel.data.availability_id ? 
                                                        `Blocked By ${availabilityPanel.data.type.split('By')[1]}` : 
                                                        (availabilityPanel.reservationData ? '' : 'Available')}
                                                    </Col>
                                                </Row>
                                            )}
                                            {   
                                                availabilityPanel.reservationData &&
                                                <Row>
                                                    <Col 
                                                        xs={12}
                                                        className="center-content bottom-margin availability-label"
                                                    >
                                                        Reservation {availabilityPanel.reservationData.reservation_id}
                                                    </Col>
                                                    <Col
                                                        xs={12}
                                                        className="center-content bottom-margin"
                                                    >
                                                        Check-In
                                                    </Col>
                                                    <Col
                                                        xs={12}
                                                        className="center-content bottom-margin"
                                                    >
                                                        <strong>
                                                            {moment(availabilityPanel.reservationData.checkin_date).format('MM/DD/YYYY')}
                                                        </strong>
                                                    </Col>
                                                    <Col
                                                        xs={12}
                                                        className="center-content bottom-margin"
                                                    >
                                                        Check-Out
                                                    </Col>
                                                    <Col
                                                        xs={12}
                                                        className="center-content bottom-margin"
                                                    >
                                                        <strong>
                                                            {moment(availabilityPanel.reservationData.checkout_date._i ? availabilityPanel.reservationData.checkout_date._i : availabilityPanel.reservationData.checkout_date).format('MM/DD/YYYY')}
                                                        </strong>
                                                    </Col>
                                                    <Col
                                                        xs={12}
                                                        className="center-content"
                                                    >
                                                        <PrimaryButton 
                                                            cssClass="black-btn"
                                                            type='button' 
                                                            onClick={()=> this.viewReservationDetails(availabilityPanel.reservationData)} 
                                                            fullWidth={false}
                                                        >
                                                            View Details
                                                        </PrimaryButton>
                                                    </Col>
                                                </Row>
                                            }
                                            {
                                                (this.currentProperty && 
                                                this.currentProperty.availabilityFeed && 
                                                this.currentProperty.availabilityFeed.feed_type === "ical") && 
                                                (this.currentProperty && 
                                                this.currentProperty.availabilityFeed && 
                                                this.currentProperty.availabilityFeed.frequency) ?
                                                (<Row className="bottom-margin top-margin">
                                                    <Col 
                                                        xs={12} 
                                                        className="availability-heading center-content bottom-margin"
                                                    >
                                                        Update Availability
                                                    </Col>
                                                    <Col xs={12}>
                                                        <p 
                                                            className="justified-text"
                                                        >
                                                            Blocks cannot be modified on this property's calendar as iCal is set as the source of truth. To modify blocks manually, update the property's iCal settings.
                                                        </p>
                                                    </Col>
                                                    <Col 
                                                        xs={12} 
                                                        className="top-margin center-text"
                                                    >
                                                        <span
                                                            className="clickable-text"
                                                            onClick={this.props.viewSettingsAvailabilityModal}
                                                        >
                                                            View Settings
                                                        </span>
                                                    </Col>
                                                </Row>
                                                ) : (
                                                <Fragment>
                                                    {
                                                        (!availabilityPanel.reservationData) &&
                                                        <Row className="bottom-margin top-margin">
                                                            {
                                                                (availabilityPanel.mode == 1) ?
                                                                <Modal.Title 
                                                                    componentClass='h3'
                                                                >
                                                                    <Col md={12} sm={12} xs={12} className="availability-heading center-content bottom-margin">
                                                                        Update Availability
                                                                    </Col>
                                                                </Modal.Title> 
                                                                :
                                                                <Fragment>
                                                                    <Col xs={12} className='tab-container bottom-margin-30'>
                                                                        <Tabs
                                                                            activeKey={availabilityPanel.type} 
                                                                            id="uncontrolled-tab-example" 
                                                                            className="mb-3"
                                                                            onSelect={(value) => this.props.toggleAvailModalType(value, true)}
                                                                        >
                                                                            <Tab eventKey="block" title="Block Property">
                                                                            </Tab>
                                                                            <Tab eventKey="create" title="Create Reservation">
                                                                            </Tab>
                                                                        </Tabs>
                                                                    </Col>
                                                                </Fragment>
                                                            }
                                                            {
                                                                (availabilityPanel.mode == 1) ||
                                                                (availabilityPanel.type === 'block') ?
                                                                <Fragment>
                                                                    <Col xs={12} className="center-content">
                                                                        Start (Night of Check-In)
                                                                    </Col>
                                                                    <Col xs={12}>
                                                                        {this.currentProperty && <div className="date-picker-calendar-group">
                                                                            <DateRangePicker
                                                                                startDate={availabilityPanel.availabilityDates.startDate}
                                                                                startDateId='blockStartDate'
                                                                                endDate={availabilityPanel.availabilityDates.endDate}
                                                                                endDateId='blockEndDate'
                                                                                focusedInput={this.state.focusedAvailabilityInput}
                                                                                onFocusChange={focusedInput => this.setState({ focusedAvailabilityInput: focusedInput })}
                                                                                onDatesChange={this.handleAvailabilityDatesChange}
                                                                                noBorder
                                                                                numberOfMonths={1}
                                                                                startDatePlaceholderText="From"
                                                                                endDatePlaceholderText="To"
                                                                                isDayBlocked={this.isDayBlocked}
                                                                                isOutsideRange={(day) => {
                                                                                    if (this.currentProperty !== 'rep') {
                                                                                        return false    
                                                                                    }
                                                                                    let dayIsBlocked = false;
                                                                                    if ((day.isBefore(moment().startOf('day')))) {
                                                                                        return true;
                                                                                    }
                                                                                    if (this.state.availability.maxDisableDates && this.state.availability.maxDisableDates.diff(day) < 0) {
                                                                                        dayIsBlocked = true;
                                                                                    }
                                                                                    return dayIsBlocked;
                                                                                }}
                                                                                renderDayContents={day => {
                                                                                    if (this.currentProperty !== 'rep') {
                                                                                        return <div>{day.format("D")}</div>    
                                                                                    }
                                                                                    let className = "";
                                                                                    if (this.state.availability.availabilityArray && this.state.availability.availabilityArray.length > 0 && day.startOf("day").isSameOrAfter(moment().startOf("day"))) {
                                                                                        if (this.state.availability.availabilityArray.includes(moment(day).subtract(1, "days").format("YYYY-MM-DD"))) {
                                                                                                className = "checkInOnly";
                                                                                            }
                                                                                            if (this.state.availability.checkoutDateArray.includes(day.format("YYYY-MM-DD"))) {
                                                                                                className = "checkOutOnly";
                                                                                            }
                                                                                        }
                                                                                        return <div className={className}>{day.format("D")}</div>
                                                                                    }
                                                                                }
                                                                            />
                                                                        </div>}
                                                                    </Col>
                                                                    <Col 
                                                                        xs={12}
                                                                        className="center-content"
                                                                        style={{marginTop: '-70px'}}
                                                                    >
                                                                        End (Last Night of Stay)
                                                                    </Col>
                                                                    <Col 
                                                                        xs={12}
                                                                        className="center-content"
                                                                    >
                                                                        Expires (Date)
                                                                    </Col>
                                                                    <Col 
                                                                        xs={12}
                                                                        className="calendar-mobile-expires"
                                                                    >
                                                                        <DatePicker
                                                                            showClearDate={true}
                                                                            placeholder="MM/DD/YYYY"
                                                                            initialVisibleMonth={() => moment(availabilityPanel.data.period_start)}
                                                                            date={
                                                                                !availabilityPanel.data.expires ?
                                                                                null :
                                                                                moment(availabilityPanel.data.expires).utc()
                                                                            }
                                                                            onDateChange={(date)=> {
                                                                                this.props.handleAvailExpiresDate(date, "mobile");
                                                                            }}
                                                                            numberOfMonths={1}
                                                                            isOutsideRange={(day) => {
                                                                                const todaysDate = moment().add(1, 'days');
                                                                                const period_end = moment(availabilityPanel.data.period_end).add(1, 'days');

                                                                                if(moment(day).isBetween(todaysDate, period_end)) {
                                                                                    return false;
                                                                                }
                                                                                else {
                                                                                    return true;
                                                                                };
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                    <Col md={12} sm={12} xs={12} className="center-content">
                                                                        Expires (Time)
                                                                    </Col>
                                                                    <Col md={12} sm={12} xs={12} className="center-content">
                                                                        <InputField 
                                                                            type="select"
                                                                            name="expiresTime"
                                                                            value={availabilityPanel.expiresTime || expiresTime}
                                                                            onChange={e => this.props.handleExpiresTime(e, "mobile")}
                                                                            disabled={!availabilityPanel.data.expires ? true : false}
                                                                        >
                                                                            {this.expiresTimeLabel}
                                                                        </InputField>
                                                                    </Col>
                                                                    {
                                                                        this.currentProperty && 
                                                                        this.currentProperty.level === 'rep' &&
                                                                        <Col xs={12} className="prop-type-col center-content">
                                                                            <InputLabel htmlFor="title">Total # Rooms to <strong>Make Available</strong></InputLabel>
                                                                            <InputField type="select" value={this.state.availability.units} onChange={this.handleUnitsChange.bind(this)}>
                                                                                <option value="0">0</option>
                                                                                {this.props.children.map((e, index) => {
                                                                                    return <option value={index + 1} key={index}>{index + 1}</option>
                                                                                })}
                                                                            </InputField>
                                                                        </Col>
                                                                    }
                                                                    {
                                                                        (this.currentProperty && this.currentProperty.level !== 'rep') &&
                                                                        (!availabilityPanel.reservationData) &&
                                                                        <Fragment>
                                                                            <Col md={12} sm={12} xs={12} className="mobile-avail-notes-container top-margin">
                                                                                <Col md={12} sm={12} xs={12}>Note(s):</Col>
                                                                                {
                                                                                    availabilityPanel.notes.map((note, i) => {
                                                                                        const { duplicateNotes, typeValidation } = this.props;
                                                                                        let hasDuplicates = "";
                                                                                        let duplicateLabel = "";
                                                                                        let typeValid = "";

                                                                                        if(typeValidation.includes(i)) {
                                                                                            typeValid = true;
                                                                                        }

                                                                                        if(note.type === "Owner Stay") {
                                                                                            if(duplicateNotes && duplicateNotes["Owner Stay"] && duplicateNotes["Owner Stay"].includes(i)) {
                                                                                                duplicateLabel = "Owner Stay";
                                                                                                hasDuplicates = true;
                                                                                            } 
                                                                                            else {
                                                                                                duplicateLabel = "";
                                                                                                hasDuplicates = false;
                                                                                            };
                                                                                        } 
                                                                                        else if(note.type === "Direct Guest") {
                                                                                            if(duplicateNotes && duplicateNotes["Direct Guest"] && duplicateNotes["Direct Guest"].includes(i)) {
                                                                                                duplicateLabel = "Direct Guest";
                                                                                                hasDuplicates = true;
                                                                                            } 
                                                                                            else {
                                                                                                duplicateLabel = "";
                                                                                                hasDuplicates = false;
                                                                                            };
                                                                                        } 
                                                                                        else if(note.type === "Maintenance") {
                                                                                            if(duplicateNotes && duplicateNotes["Maintenance"] && duplicateNotes["Maintenance"].includes(i)) {
                                                                                                duplicateLabel = "Maintenance";
                                                                                                hasDuplicates = true;
                                                                                            } 
                                                                                            else {
                                                                                                duplicateLabel = "";
                                                                                                hasDuplicates = false;
                                                                                            };
                                                                                        }
                                                                                        else if(note.type === "Hold") {
                                                                                            if(duplicateNotes && duplicateNotes["Hold"] && duplicateNotes["Hold"].includes(i)) {
                                                                                                duplicateLabel = "Hold";
                                                                                                hasDuplicates = true;
                                                                                            } 
                                                                                            else {
                                                                                                duplicateLabel = "";
                                                                                                hasDuplicates = false;
                                                                                            };
                                                                                        }
                                                                                        else if(note.type === "Other") {
                                                                                            if(duplicateNotes && duplicateNotes["Other"] && duplicateNotes["Other"].includes(i)) {
                                                                                                duplicateLabel = "Other";
                                                                                                hasDuplicates = true;
                                                                                            } 
                                                                                            else {
                                                                                                duplicateLabel = "";
                                                                                                hasDuplicates = false;
                                                                                            };
                                                                                        };

                                                                                        return (
                                                                                            <Fragment key={i}>
                                                                                                <Col xs={12} className="avail-notes-main-container">
                                                                                                <Col xs={12}>
                                                                                                    <Col xs={12} className="avail-notes-header-container">
                                                                                                        <Col xs={11}>
                                                                                                            <InputField
                                                                                                                type="select"
                                                                                                                name="type"
                                                                                                                className={`${hasDuplicates || typeValid ? "form-validation" : ""}`}
                                                                                                                onChange={(e) => this.props.handleAvailNoteOnChange(e, i, true)}
                                                                                                                value={note.type}
                                                                                                            >
                                                                                                                <option value="" disabled hidden>Type</option>
                                                                                                                {
                                                                                                                    this.props.availNotesTypes.map((type, i) => {
                                                                                                                        return(
                                                                                                                            <option value={type} key={i}>{type}</option>
                                                                                                                        );
                                                                                                                    })
                                                                                                                }  
                                                                                                            </InputField>
                                                                                                        </Col>
                                                                                                        <Col xs={1}>
                                                                                                            <i 
                                                                                                                className="icon-Delete" 
                                                                                                                onClick={() => this.props.deleteAvailNote(i, true)}
                                                                                                            ></i>
                                                                                                        </Col> 
                                                                                                    </Col>
                                                                                                </Col>
                                                                                                <Col xs={12} className={`${(note.type === "Other" && this.props.noteValidation === true) || hasDuplicates || typeValid ? "" : "bottom-margin-10" }`}>
                                                                                                    <InputField
                                                                                                        cssClass="text-area"
                                                                                                        className={`${note.type === "Other" && this.props.noteValidation === true ? "form-validation" : ""}`}
                                                                                                        type="textarea"
                                                                                                        name="note"
                                                                                                        value={note.note}
                                                                                                        onChange={(e) => this.props.handleAvailNoteOnChange(e, i, true)}
                                                                                                    />
                                                                                                </Col>
                                                                                                {
                                                                                                    note.type === "Other" && 
                                                                                                    this.props.noteValidation === true &&
                                                                                                    <Col xs={12}>
                                                                                                        <span className="required-error-text">Type Other requires more info.</span>
                                                                                                    </Col> 
                                                                                                }
                                                                                                {
                                                                                                    hasDuplicates && 
                                                                                                    <Col xs={12}>
                                                                                                        <span className="required-error-text">Cannot have duplicate {duplicateLabel}.</span>
                                                                                                    </Col> 
                                                                                                }
                                                                                                {
                                                                                                    typeValid && 
                                                                                                    <Col xs={12}>
                                                                                                        <span className="required-error-text">Type is required.</span>
                                                                                                    </Col> 
                                                                                                }
                                                                                                </Col>
                                                                                            </Fragment>
                                                                                        )
                                                                                    })

                                                                                }
                                                                                {
                                                                                    availabilityPanel.notes.length < 5 &&
                                                                                    <Col xs={12} className="center-content bottom-margin-10">
                                                                                        <span
                                                                                            onClick={(e) => this.props.addAvailNote(e, true)}
                                                                                            className="clickable-text"
                                                                                        >
                                                                                            <i className="icon-Plus create-icon"/>
                                                                                            <span>
                                                                                                Add additional notes
                                                                                            </span>
                                                                                        </span>
                                                                                    </Col>
                                                                                }
                                                                            </Col>
                                                                        </Fragment>
                                                                    }                                                 
                                                                    {
                                                                        !availabilityPanel.reservationData &&
                                                                        <Fragment>
                                                                            {this.props.admin && 
                                                                                <Row>
                                                                                    {
                                                                                        availabilityPanel.mode ?
                                                                                        <Col md={12} sm={12} xs={12} className="center-content top-margin">
                                                                                            <button 
                                                                                                onClick={this.props.deleteAvailability} 
                                                                                                className='btn-delete'
                                                                                            >
                                                                                                Delete this Block<span className={'glyphicon glyphicon-refresh'+(this.props.isDeleting ? ' spinning':' hide')}></span>
                                                                                            </button>
                                                                                        </Col> :''
                                                                                    }
                                                                                    <Col md={12} sm={12} xs={12} className="center-content top-margin">
                                                                                        <PrimaryButton 
                                                                                            cssClass={availabilityPanel.mode? 'black-btn ':''} 
                                                                                            disabled={isAvailabilityDisabled}
                                                                                            style={{"fontSize" : "12px"}}
                                                                                            type='button' 
                                                                                            onClick={this.submitAvailability} 
                                                                                            fullWidth={false}
                                                                                        >
                                                                                            {this.currentProperty && this.currentProperty.level === "rep" ? 'Update Availability' : 'Block Property'}<span className={'glyphicon glyphicon-refresh'+(this.props.loading ? ' spinning':' hide')}></span>
                                                                                        </PrimaryButton>
                                                                                    </Col>
                                                                                </Row>
                                                                            }
                                                                        </Fragment>
                                                                    }
                                                                    <Col md={12} sm={12} xs={12} className="center-content" style={{margin: '5px 0px'}}>
                                                                        <span className={`error-msg ${errors.period_start.show?'':'hide'}`}>{errors.period_start.message}</span>
                                                                        <span className={`error-msg ${errors.period_end.show?'':'hide'}`}>{errors.period_end.message}</span>
                                                                        <span className={errors.server_error.show ?'error-msg':'hide'}>{errors.server_error.message}</span>
                                                                    </Col>
                                                                </Fragment>
                                                                :
                                                                <Fragment>
                                                                    <Col md={12} sm={12} xs={12} className="center-content">
                                                                        Check-In
                                                                    </Col>
                                                                    <Col md={12} sm={12} xs={12}>
                                                                        <DateRangePicker
                                                                            noBorder
                                                                            startDate={availabilityPanel.reservations.reservationDates.startDate}
                                                                            startDateId="startexcluded" 
                                                                            startDatePlaceholderText="MM/DD/YYYY"
                                                                            endDate={availabilityPanel.reservations.reservationDates.endDate} 
                                                                            endDateId="endexcluded" 
                                                                            endDatePlaceholderText="MM/DD/YYYY"
                                                                            focusedInput={reservationFocusedInput}
                                                                            onFocusChange={focusedInput => this.setState({ reservationFocusedInput: focusedInput })}
                                                                            onDatesChange={({ startDate, endDate }) => this.props.handleOnDateChange(startDate, endDate, true)} 
                                                                        />
                                                                    </Col>
                                                                    <Col md={12} sm={12} xs={12} className="center-content" style={{marginTop: '-70px'}}>
                                                                        Check-Out
                                                                    </Col>
                                                                    <Col md={12} sm={12} xs={12} className="center-content">
                                                                        Adults
                                                                    </Col>
                                                                    <Col md={12} sm={12} xs={12} className="center-content bottom-margin-10">
                                                                        <InputField 
                                                                            type="select" 
                                                                            value={availabilityPanel.reservations.adults} 
                                                                            name="adults"
                                                                            onChange={(e) => this.props.handleAvailReserveOnChange(e, true, this.currentProperty.content.max_sleep)}
                                                                        >
                                                                            {availabilityPanel.reservations.adultOptions}
                                                                        </InputField>
                                                                    </Col>
                                                                    <Col md={12} sm={12} xs={12} className="center-content">
                                                                        Children
                                                                    </Col>
                                                                    <Col md={12} sm={12} xs={12} className="center-content bottom-margin-10">
                                                                        <InputField 
                                                                            type="select" 
                                                                            value={availabilityPanel.reservations.children} 
                                                                            name="children"
                                                                            onChange={(e) => this.props.handleAvailReserveOnChange(e, true, this.currentProperty.content.max_sleep)}
                                                                        >
                                                                            {availabilityPanel.reservations.childOptions}
                                                                        </InputField>
                                                                    </Col>
                                                                    {
                                                                        availabilityPanel.reservations.promotionCodeData.length > 0 &&
                                                                        <Fragment>
                                                                            <Col md={12} sm={12} xs={12} className="center-content">
                                                                                <InputLabel>
                                                                                    Promotion
                                                                                </InputLabel>
                                                                            </Col>
                                                                            <Col md={12} sm={12} xs={12} className="center-content">
                                                                                <InputField 
                                                                                    type="select" 
                                                                                    value={availabilityPanel.reservations.promotionCode.org_promo_id} 
                                                                                    name="promotionCode"
                                                                                    onChange={(e) => this.props.handleAvailReserveOnChange(e, true)}
                                                                                >
                                                                                    {promotions}
                                                                                </InputField>
                                                                            </Col>
                                                                        </Fragment>
                                                                    }
                                                                    <Col md={12} sm={12} xs={12} className="top-margin">
                                                                        {this.props.ratesSummary('', true)}
                                                                    </Col>
                                                                    <Col md={12} sm={12} xs={12}>
                                                                        {this.props.errMsg(false, availabilityPanel.reservations.quoteErrMsg)}
                                                                    </Col>
                                                                    <Col xs={12} sm={12} className="center-content">
                                                                        <PrimaryButton
                                                                            fullWidth={false}
                                                                            type="button" 
                                                                            onClick={(e) => this.props.handleGuestInfoModal(e, true, true)}
                                                                            disabled={this.props.disableCheckOutbtn(true) || availabilityPanel.reservations.quoteIsLoading || availabilityPanel.reservations.quoteErrMsg.length}
                                                                        >
                                                                            Checkout
                                                                        </PrimaryButton>
                                                                    </Col>
                                                                </Fragment>
                                                            }
                                                        </Row>
                                                    }
                                                </Fragment>
                                            )}
                                        </Grid>
                                    </Panel.Body>
                                </Panel.Collapse>
                            </Panel>
                        }
                        <Panel 
                            eventKey={3}
                            className='availability'
                        >
                            <Panel.Heading className="mobile">
                                <Panel.Title 
                                    toggle
                                    className="center-content"
                                >
                                    Check-in / Check-out <span className={'pull-right '+(this.state.activeKey === 3 ? 'glyphicon glyphicon-chevron-up': 'glyphicon glyphicon-chevron-down')}></span>
                                </Panel.Title>
                            </Panel.Heading>
                            <Panel.Collapse>
                                <Panel.Body>
                                    <Grid fluid={true}>
                                    <Row>
                                        <Col 
                                            xs={12}
                                            className="center-content"
                                        >
                                            Start Date
                                        </Col>
                                        <Col xs={12}>
                                            {
                                                this.currentProperty && 
                                                <div className="date-picker-calendar-group">
                                                    <DateRangePicker 
                                                        startDate={cicoPanel.cicoDates.startDate}
                                                        startDateId='cicoStartDate'
                                                        endDate={cicoPanel.cicoDates.endDate}
                                                        endDateId='cicoEndDate'
                                                        focusedInput={this.state.focusedCicoInput}
                                                        onFocusChange={focusedInput => this.setState({focusedCicoInput: focusedInput})}
                                                        onDatesChange={e => this.handleDatesChange(e, "cico")}
                                                        noBorder
                                                        numberOfMonths={1}
                                                        startDatePlaceholderText="Check-In"
                                                        endDatePlaceholderText="Check-Out"
                                                        minimumNights={0}
                                                        isDayHighlighted={this.cicoPeriods}
                                                    />
                                                </div>
                                            }
                                        </Col>
                                        <Col 
                                            xs={12}
                                            className="center-content"
                                            style={{marginTop: '-70px'}}
                                        >
                                            End Date
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col 
                                            xs={12}
                                            className="center-content top-margin-20"
                                        >
                                            <FieldWrap>
                                                <InputLabel htmlFor='checkin'>Check-In Allowed &nbsp;
                                                    {(cicoPanel && cicoPanel.cicoLocked) ?
                                                    <OverlayTrigger placement='top' overlay={<Tooltip id="tip-cicolocked-ci" className='cico_locked__tooltip'>																																							
                                                        {(cicoPanel.cicoLocked === 'LOCKED') ? 
                                                            "PMS integration is not overwriting CICO periods. This setting can be changed in Property Policies." : 
                                                            "PMS integration is overwriting CICO periods. This setting can be changed in Property Policies."}
                                                        </Tooltip>}>
                                                        <a href="#!">
                                                            <i className={(cicoPanel.cicoLocked === 'LOCKED') ? 
                                                                "icon-lock dim-gray" : 
                                                                "icon-unlock dim-gray"} />
                                                        </a>
                                                    </OverlayTrigger> : ''}
                                                </InputLabel>
                                                <Row className='seven-cols'>
                                                    {
                                                        Object.entries(cicoPanel.data.check_in_allowed).map((day,id)=>{
                                                            return (
                                                                <Col xs={4} sm={1} key={id}>
                                                                    <div className='badge-checkbox'>
                                                                        <input 
                                                                            onChange={e=>this.props.handleBadgeChange(e)} 
                                                                            disabled={!this.props.admin} 
                                                                            checked={day[1]} 
                                                                            id={'checkin_'+day[0]} 
                                                                            type='checkbox' 
                                                                            name='check_in_allowed' 
                                                                            value={day[0]}
                                                                        />
                                                                        <label 
                                                                            htmlFor={'checkin_'+day[0]}>
                                                                                {day[0].substr(0,3)}
                                                                            </label>
                                                                    </div>
                                                                </Col>
                                                            )
                                                        })
                                                    }
                                                </Row>
                                            </FieldWrap>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col 
                                            xs={12}
                                            className="center-content"
                                        >
                                            <FieldWrap>
                                                <InputLabel htmlFor='checkout'>Check-Out Allowed &nbsp;
                                                    {(cicoPanel && cicoPanel.cicoLocked) ?
                                                    <OverlayTrigger placement='top' overlay={<Tooltip id="tip-cicolocked-co" className='cico_locked__tooltip'>																																							
                                                        {(cicoPanel.cicoLocked === 'LOCKED') ? 
                                                            "PMS integration is not overwriting CICO periods. This setting can be changed in Property Policies." : 
                                                            "PMS integration is overwriting CICO periods. This setting can be changed in Property Policies."}
                                                        </Tooltip>}>
                                                        <a href="#!">
                                                            <i className={(cicoPanel.cicoLocked === 'LOCKED') ? 
                                                                "icon-lock dim-gray" : 
                                                                "icon-unlock dim-gray"} />
                                                        </a>
                                                    </OverlayTrigger> : ''}
                                                </InputLabel>
                                                <Row className='seven-cols'>
                                                    {
                                                        Object.entries(cicoPanel.data.check_out_allowed).map((day,id)=> (
                                                                <Col xs={4} sm={1} key={id}>
                                                                    <div className='badge-checkbox'>
                                                                        <input 
                                                                            onChange={e=>this.props.handleBadgeChange(e)} 
                                                                            disabled={!this.props.admin} 
                                                                            checked={day[1]} 
                                                                            id={'checkout_'+day[0]} 
                                                                            type='checkbox' 
                                                                            name='check_out_allowed' 
                                                                            value={day[0]}
                                                                        />
                                                                        <label htmlFor={'checkout_'+day[0]}>{day[0].substr(0,3)}</label>
                                                                    </div>
                                                                </Col>
                                                            )
                                                        )
                                                    }
                                                </Row>
                                                {
                                                    this.props.admin && 
                                                    <Row>
                                                        {
                                                            cicoPanel.data.cico_id ?
                                                            <Col 
                                                                xs={12}
                                                                className="center-content top-margin"
                                                            >
                                                                <button 
                                                                    onClick={this.props.deleteCico} 
                                                                    className='btn-delete'
                                                                >
                                                                    Delete <span className={'glyphicon glyphicon-refresh'+(this.props.isDeleting ? ' spinning':' hide')}></span>
                                                                </button>
                                                            </Col> :''
                                                        }
                                                        <Col 
                                                            xs={12}
                                                            className="center-content top-margin"
                                                        >
                                                            <PrimaryButton 
                                                                disabled={isPricingDisabled} 
                                                                cssClass={cicoPanel.data.cico_id? 'black-btn ':''} 
                                                                type='button' 
                                                                onClick={e =>this.props.submitCico(e, this.props.activeProperty ? this.props.activeProperty.cico : [])}
                                                                fullWidth={false}
                                                            >
                                                                {cicoPanel.data.cico_id ? 'Update' : 'Add'} <span className={'glyphicon glyphicon-refresh'+(this.props.loading ? ' spinning':' hide')}></span>
                                                            </PrimaryButton>
                                                        </Col>
                                                    </Row>
                                                }
                                                <Row>
                                                    <Col 
                                                        xs={12}
                                                        className="center-content top-margin"
                                                    >
                                                        <span className={errorMessage ?'error-msg':'hide'}>{errorMessage}</span>
                                                    </Col>
                                                </Row>
                                            </FieldWrap>
                                        </Col>
                                    </Row>
                                    </Grid>
                                </Panel.Body>
                            </Panel.Collapse>
                        </Panel>
                    </PanelGroup>
                </Modal.Body>
            </Modal>
        ) : ""
    }
}
