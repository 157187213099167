import React, { Fragment } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { InputField } from '../../global/forms/FormElements';
import { Cell } from './Cell';
import chunk from 'lodash/chunk';
import Month from 'react-big-calendar/lib/Month';
import { DatePicker } from '../forms/SingleDatePicker';
import EventRow from 'react-big-calendar/lib/EventRow';
import dates from 'react-big-calendar/lib/utils/dates';
import { ShowMoreRow } from './ShowMoreRow';
import Aux from '../../hoc/AuxComp';
import PanelGroup from 'react-bootstrap/lib/PanelGroup';
import Panel from 'react-bootstrap/lib/Panel';
import {segStyle, inRange, eventSegments, endOfRange, eventLevels} from 'react-big-calendar/lib/utils/eventLevels';
import { PrimaryButton } from '../forms/FormElements';
import constants from '../../../js/constants';
import TableComponent from '../TableComponent';
import { StyledWrapper } from './CalendarView';
import { tableSort } from '../../../helpers/helpers';

/**
 * Month view is for show single property calendar.
 */
class MonthView extends Month {
    constructor(props) {
        super(props);
        this.state = {
            rowLimit: 5,
            needLimitMeasure: true,
            pricePeriodsPageSize: 10,
            cicoPageSize: 10,
            blocksPageSize: 10,
            reservationsPageSize: 10,
            manualDate: moment(this.props.date).format('MM/DD/YYYY'),
          }
        this.level = this.props.getCalendarTitle().level
        this.cellStyle = segStyle(1,7);
        this.handleShowMore=this.handleShowMore.bind(this);
        this.setRefs = this.setRefs.bind(this);
        this.blockedTypes = constants.CALENDAR.LISTVIEW.AVAILABILITY_TYPE;
        this.cellRefs = [],
        this.mobilePricingColumns = [
            {
                Header:() => tableSort("Start"),
                accessor: 'price_period_start',
                minWidth: 80,
                Cell: row => moment(row.value).format("MM/DD/YYYY"),
                sortMethod: this.sortByDate
            },
            {
                Header:() => tableSort("End"),
                accessor: 'period_end',
                minWidth: 80,
                Cell: row => moment(row.value).format("MM/DD/YYYY"),
                sortMethod: this.sortByDate
            },
            {
                Header:() => tableSort("Price"),
                accessor: 'row.original',
                minWidth: 100,
                Cell: row => `${constants.CURRENCY[this.props.currency]}${row.original.weekend_price} / ${constants.CURRENCY[this.props.currency]}${row.original.weekday_price} / ${constants.CURRENCY[this.props.currency]}${row.original.weekly_price}`
            }
        ],
        this.mobileAvailabilityColumns = [
            {
                Header:() => tableSort("Start"),
                accessor: 'period.period_start',
                minWidth: 80,
                Cell: row=>moment(row.value).format("MM/DD/YYYY"),
                sortMethod: this.sortByDate
            },
            {
                Header:() => tableSort("End"),
                accessor: 'period.period_end',
                minWidth: 80,
                Cell: row=>moment(row.value).format("MM/DD/YYYY"),
                sortMethod: this.sortByDate
            },
            {
                Header:() => tableSort("Type"),
                accessor: this.level === 'rep' ? 'blockTypes' : 'period.type',
                Cell: row=> {
                    let vals = [] 
                    if (this.level === 'rep') {
                        if (row.value && row.value.length) {
                            row.value.forEach((val, i) => {
                                let blockType = ''
                                if (val.type === "PaymentBlock") {
                                    blockType = 'Payment'
                                }
                                if (val.type === "InquiryOnly") {
                                    blockType = 'Inquiry Only'
                                }
                                if (val.type === "Temporary") {
                                    blockType = "No Pricing"
                                }
                                else {
                                    blockType = val.type.split('By')[1]
                                }
                                vals.push(`${i > 0 ? ', ' : ''}${blockType}`)
                            })
                        }
                        else {
                            vals = 'Misc'
                        }
                    }
                    else {
                        vals = row.value.split('By')[1] ? 
                        row.value.split('By')[1] : 
                        row.value === 'InquiryOnly' ? 
                        'Inquiry Only' : 
                        row.value === 'PaymentBlock' ?
                        'Payment Block' :
                        row.value === "Blocked by Booking" ?
                        "Booking" :
                        row.value === "Temporary" ?
                        'No Pricing' :
                        row.value
                    }
                    return vals
                }
            }
        ],
        this.pricingColumns = [
            {
                Header:() => tableSort("Start (Night of Check-In)"),
                accessor: 'price_period_start',
                Cell: row => moment(row.value).format("MM/DD/YYYY"),
                sortMethod: this.sortByDate
            },
            {
                Header:() => tableSort("End (Last Night of Stay)"),
                accessor: 'period_end',
                Cell: row => moment(row.value).format("MM/DD/YYYY"),
                sortMethod: this.sortByDate
            },
            {
                Header:() => tableSort("Weekend Price"),
                accessor: 'weekend_price',
                Cell: row => `${constants.CURRENCY[this.props.currency]}${row.value}`
            },
            {
                Header:() => tableSort("Weekday Price"),
                accessor: 'weekday_price',
                Cell: row => `${constants.CURRENCY[this.props.currency]}${row.value}`
            },
            {
                Header:() => tableSort("$/Day Weekly Price"),
                accessor: 'weekly_price',
                Cell: row => `${constants.CURRENCY[this.props.currency]}${row.value}`
            },
            {
                Header:() => tableSort("Monthly Price"),
                accessor: 'monthly_price',
                Cell: row => `${constants.CURRENCY[this.props.currency]}${row.value}`
            },
            {
                Header:() => tableSort("Minimum # of Nights"),
                accessor: 'minstay',
                Cell: row => `${row.value}`
            },
            {
                Header:() => tableSort("Maximum # of Nights"),
                accessor: 'maxstay',
                Cell: row => `${row.value ? row.value : 'N/S'}`
            },
        ],
        this.cicoColumns = [
            {
                Header:() => tableSort("Start Date"),
                accessor: 'start_date',
                Cell: row => moment(row.value).format("MM/DD/YYYY"),
                sortMethod: this.sortByDate
            },
            {
                Header:() => tableSort("End Date"),
                accessor: 'end_date',
                Cell: row => moment(row.value).format("MM/DD/YYYY"),
                sortMethod: this.sortByDate
            },
            {
                Header:() => tableSort("Check-In Allowed"),
                Cell: row => {
                    if(row.original) {
                        let checkIn = row.original.check_in_allowed;
                        let checkInAllowed = [];
                        Object.keys(checkIn).forEach(val=>{
                            if(checkIn[val]) {
                                checkInAllowed.push(val.charAt(0).toUpperCase() + val.substr(1,2))
                            }
                        })
                        return checkInAllowed.join(', ')
                    }
                    return 'N/A'
                }
            },
            {
                Header:() => tableSort("Check-Out Allowed"),
                Cell: row => {
                    if(row.original) {
                        let checkOut = row.original.check_out_allowed;
                        let checkOutAllowed = [];
                        Object.keys(checkOut).forEach(val=>{
                            if(checkOut[val]) {
                                checkOutAllowed.push(val.charAt(0).toUpperCase() + val.substr(1,2))
                            }
                        })
                        return checkOutAllowed.join(', ')
                    }
                    return 'N/A'
                }
            }
        ];
        this.availabilityColumns = [
            {
                Header:() => tableSort("Start (Night of Check-In)"),
                accessor: 'period.period_start',
                width: 200,
                Cell: row=>moment(row.value).format("MM/DD/YYYY"),
                sortMethod: this.sortByDate
            },
            {
                Header:() => tableSort("End (Last Night of Stay)"),
                accessor: 'period.period_end',
                width: 200,
                Cell: row=>moment(row.value).format("MM/DD/YYYY"),
                sortMethod: this.sortByDate
            },
            {
                Header:() => tableSort("Type"),
                accessor: this.level === 'rep' ? 'blockTypes' : 'period.type',
                Cell: row=> {
                    let vals = [];
                    if (this.level === 'rep') {
                        if (row.value && row.value.length) {
                            row.value.forEach((val, i) => {
                                let blockType = ''
                                if (val.type === "PaymentBlock") {
                                    blockType = 'Blocked by Payment'
                                }
                                else if (val.type === 'Manual') {
                                    blockType = 'Blocked Manually'
                                }
                                if (val.type === "Temporary") {
                                    blockType = "No Pricing"
                                }
                                else {
                                    blockType = val.type.split(/(?=[A-Z])/).join(' ').replace('By', 'by')
                                }
                                vals.push(`${i > 0 ? ', ' : ''}${val.quantity} ${blockType}`)
                            })
                        }
                        else {
                            vals = 'Misc'
                        }
                    }
                    else {
                        vals = row.value === "Temporary" ? 
                        "No Pricing" :
                        row.value.split(/(?=[A-Z])/).join(' ').replace('By', 'by')
                    }
                    return `${vals} ${row && row.original && row.original.period && row.original.period.notes && row.original.period.notes[0] ? `(${row.original.period.notes[0].type})` : ""}`
                }
            },
            {
                Header:() => tableSort("Availability"),
                accessor: 'roomsAvailable',
                Cell: row=> `${row.value !== undefined ? `${row.value} Room${row.value === 1 ? '' : 's'} Available` : 'Unavailable'}`
            }
        ],
        this.getRowProps = this.getRowProps.bind(this);
        this.addPricing = this.addPricing.bind(this);
        this.addAvailability = this.addAvailability.bind(this);
    }
    /**
     * Sort by date for set availability array in ascending order of dates.
     * @param {*} a 
     * @param {*} b 
     */
    sortByDate(a, b) {
        let mA = moment(a),
            mB = moment(b);
        if(mA.isSame(mB))
            return 0;
        return mA.isAfter(mB)? 1 : -1;
    }
    componentDidMount() {
        // Override Super function
    }
    componentDidUpdate() {
        // Override Super function
    }
    componentWillReceiveProps(nextProps){
        this.setState({
            manualDate: moment(nextProps.date).format('MM/DD/YYYY'),
        })
    }
    handleManualDateChange = e => {
        let newVal = e.target.value.replace(/[.-]/g, '/')
        if (moment(newVal, ['M/D/YYYY', 'MM/DD/YYYY'], true).isValid()) {
            if (moment(newVal, 'MM/DD/YYYY').isSameOrAfter(moment(this.props.today), 'day')) {
                document.getElementById('mobile-input-date').blur()
                this.props.onNavigate('DATE', moment(newVal, 'MM/DD/YYYY'))
            }
        }
        this.setState({
            manualDate: newVal,
        })
    }
    /**
     * For onclick event on row props element of rows.
     * @param {*} state 
     * @param {*} rowInfo 
     * @param {*} column 
     */
    getRowProps(state, rowInfo, column) {
        return rowInfo.original.period && rowInfo.original.period.type === "Booking" ? {
            onClick: (e, handleOriginal) => {
                this.props.history.push({
                    pathname: `/reservations/${rowInfo.original.period.reservation_id}/details`,
                })
            },
            style: {
                cursor: 'pointer'
            }
        } : 
        {
            onClick: (e, handleOriginal) => {
                if(rowInfo.original.period) {
                    if(rowInfo.original.period.type != "Temporary") {
                        this.props.onSelectEvent(rowInfo.original,e,this.props.activeProperty)
                    };
                }
                else if(rowInfo.original.cico_id) {
                    this.props.onSelectEvent(rowInfo.original,e,this.props.activeProperty, "list")
                }
                else {
                    let period = Object.assign({},rowInfo.original);
                    if(period.cico) {
                        delete period.cico
                    }
                    this.props.editPricing(e,period,this.props.activeProperty,rowInfo.original.cico, "", "", "list")
                };
            },
            style: {
                cursor: 'pointer'
            }
        };
    }
    /**
     * Get events by weeks it will filter current month event and add events according to month.
     * @param {*} week 
     * @param {*} currentMonthEvents 
     */
    getFilteredEvents(week, currentMonthEvents) {
        let refs=this.props,
            evts=currentMonthEvents,
            range=week,
            start=range[0],
            end=range[range.length-1],
            first=endOfRange(range).first,
            last=endOfRange(range).last,
            filteredEvts=[],
            segments=[],
            levels=[],
            minimumStayWeeklyPrice=[];

        minimumStayWeeklyPrice = evts.filter((events) => {
            return events.minstay;
        });

        filteredEvts= evts.filter(e=>{
            /* remove rep-availability from mobile view */
            if (this.props.mobileMode && e && e.propertyId)
                return false
            /* remove custom mobile rep blocks from desktop view */
            if (!this.props.mobileMode && e && e.mobile_block_id)
                return false
            return inRange(e,start,end,refs)
        });
        filteredEvts.forEach(evt=>{
            if(evt.period_start) {
                evt.period_start=moment(evt.period_start).hours(12)
                evt.period_end=moment(evt.period_end).hours(12)
            }
            if(evt.start_date) {
                evt.start_date=moment(evt.start_date).hours(12)
                evt.end_date=moment(evt.end_date).hours(12)
            }
            if(evt.period) {
                evt.period.period_start=moment(evt.period.period_start).hours(12)
                evt.period.period_end=moment(evt.period.period_end).hours(12)
            }
        })
        segments=filteredEvts.map(e=>{
            return eventSegments(e,first,last,refs,range);
        })
        
        let priceSegments = segments.filter(seg=>{
            return (seg.event.price_period_id || seg.event.cico_id ) ? true:false
        })
        let otherSegments = segments.filter(seg=>{
            return (!seg.event.price_period_id && !seg.event.cico_id) ? true:false
        })
        let priceLevels = eventLevels(priceSegments,2)
        let otherLevels = eventLevels(otherSegments,2)
        levels = {
            levels: priceLevels.levels.concat(otherLevels.levels),
            extra: priceLevels.extra.concat(otherLevels.extra)
        }
        return {
            levels: levels,
            first: first,
            last: last,
            filteredEvts: filteredEvts,
            minimumStayWeeklyPrice: minimumStayWeeklyPrice,
        }
    }

    /**
     * Invoke when cell have more event then it can contain. and after click it will show all events.
     * @param {*} slot 
     * @param {*} segments 
     * @param {*} levels 
     * @param {*} i 
     */
    onShowMore(slot, segments, levels, i) {
        let _props = Object.assign({},this.props);
        let levelSegments = segments;
        levels.forEach(level=>{
            level.forEach(seg=>{
                levelSegments.push(seg)
            })
        })
        let events = levelSegments.filter(seg=>{
            return (seg.event.period || seg.event.reservation_id) && (seg.left <= slot && seg.right >= slot);
        }).map(evt=>{
            return evt.event
        });
        let el = this.cellRefs[this.weeks[i][slot-1]];
        let bounds = el.getBoundingClientRect();
        _props = Object.assign(_props,{
                events: events,
                dayHeaderFormat: "D",
                position: {
                    top: bounds.top + window.scrollY,
                    left: bounds.left + (bounds.width/2),
                    width: 140
                },
            slotStart: this.weeks[i][slot-1],
            EventComponent: 'EventCell',
            eventWrapperComponent: this.props.components.eventWrapper,
            onSelect: (evt,e)=>this.props.onSelectEvent(evt,e,this.props.activeProperty)
        })
        this.props.handleShowMore(_props)
    }

    setRefs(ref,date) {
        this.cellRefs[date] = ref
    }

    /**
     * Invoke when click on add pricing button of cellOpen add pricing modal.
     * @param {*} e 
     */
    addPricing(e) {
        this.props.addPricing(moment(),moment(),this.props.activeProperty)
    }

    /**
     * Invoke when try to add new block on calendar and open a block modal. 
     * @param {*} e 
     */
    addAvailability(e) {
        if (this.props.mobileMode) {
            /* supply mobile calendar modal with available room data needed */
            this.props.onUpdateModalEventsByDate(e, moment().format('YYYY-MM-DD'), 'availability', this.props.currentProperty)
        }
        this.props.addAvailability(moment(),moment(),this.props.activeProperty)
    }

    /**
     * Render calendar 
     */
    renderCalendar() {
        let _this=this,
            _props=this.props,
            month=dates.visibleDays(_props.date,_props.culture),
            currentMonthEvents = _props.events.filter(e=>{
                return inRange(e, month[0], month[month.length - 1], _props)
            })
        this.weeks=chunk(month,7);
        if(_props.rootElement)
            _props.rootElement.style.height = (100 + (150 * this.weeks.length)) + 'px';
        
        return (
            <div className='rbc-month-view single-view' ref='monthNode'>
                <div className='rbc-row rbc-month-header'>
                    {this.renderHeaders(this.weeks[0],_props.weekdayFormat,_props.culture)}
                </div>
                {
                    this.weeks.map((week,i)=>{
                        let events=this.getFilteredEvents(week,currentMonthEvents);
                        return (
                            <div className='rbc-month-row' key={i}>
                                <div className='rbc-row-bg'>
                                    <Cell 
                                        {..._props} 
                                        handleRefs={this.setRefs}
                                        minimumStayWeeklyPrice={events.minimumStayWeeklyPrice}
                                        filteredEvts={events.filteredEvts} 
                                        propId={_props.activeProperty} 
                                        range={week} 
                                        propertyLevel={this.props.currentProperty.level}
                                        availability={this.props.currentProperty.availability}
                                        cellStyle={this.cellStyle} 
                                        monthView 
                                    />
                                </div>
                                <div className='rbc-row-content'>
                                    <div className='rbc-row'>
                                        <div className='rbc-date-cell' style={this.cellStyle}></div>
                                    </div>
                                    {
                                        events.levels.levels.map((segs,i)=>{
                                            segs = segs.map((data) => {
                                                if(data) 
                                                    data.event.currency = this.props.currency || 'USD';
                                                return data
                                            })
                                            let fSegs = segs.filter(seg => {
                                                return seg.left !== 0
                                            })
                                            return (
                                                <EventRow
                                                    {..._props}
                                                    onSelect={(evt,e)=>_props.onSelectEvent(evt,e,_props.activeProperty)}
                                                    key={i}
                                                    start={events.first}
                                                    end={events.last}
                                                    segments={_props.mobileMode ? fSegs : segs}
                                                    slots={week.length}
                                                    EventComponent='month'
                                                    eventWrapperComponent={_props.components.eventWrapper}
                                                />
                                            )
                                        })
                                    }                 
                                    { !!events.levels.extra.length &&
                                        <ShowMoreRow
                                            {..._this.props}
                                            start={events.first}
                                            end={events.last}
                                            segments={events.levels.extra}
                                            slots={week.length}
                                            EventComponent='month'
                                            eventWrapperComponent={_this.props.components.eventWrapper}
                                            onShowMore={slot=>_this.onShowMore(slot,events.levels.extra,events.levels.levels,i)}
                                        />
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    /**
     * Render List view of blocked dates.
     */
    renderListView() {
        let {
            reservations, 
            events, 
            currentProperty, 
        } = this.props;
        let pricePeriods=[],
            availability = [],
            cico=[];
        events.forEach(e => {
            if(e.price_period_id) {
                pricePeriods.push(e)
            } 
            else if(e.period && (e.roomsAvailable < this.props.totalRooms || this.level !== "rep")) {
                availability.push(e);
            } 
            else if(e.cico_id) {
                cico.push(e);
            }
        });
        let newResObjList = []
        if (currentProperty && currentProperty.level !== "rep") {
            reservations.forEach(res => {
                newResObjList.push({
                    period: {
                        period_start: res.checkin_date,
                        period_end: res.checkout_date,
                        type: "Blocked by Booking",
                        reservation_id: res.reservation_id,
                    }
                })
            })
            availability = availability.concat(newResObjList)
        }
        return (
            <div className={`listView${this.props.mobileMode ? '-mobile' : ''}`}>
                <PanelGroup 
                    accordion 
                    className="property-item reports" 
                    id="listView"
                    activeKey={this.props.listViewKey} 
                    onSelect={(activeKey) => { 
                            this.props.updateListViewPanelKey(activeKey)
                        }}
                    >
                    {!this.props.repChild ? 
                        <Panel eventKey={1}>
                            <Panel.Heading>
                                <Panel.Title toggle>
                                    <strong>Pricing <span className={'pull-right '+(this.props.listViewKey === 1 ? 'glyphicon glyphicon-chevron-up': 'glyphicon glyphicon-chevron-down')}></span></strong>
                                </Panel.Title>
                                {
                                    this.props.admin && 
                                    !this.props.currentProperty.smart_pricing_enabled &&
                                    <PrimaryButton 
                                        type="button" 
                                        cssClass={`listView${this.props.mobileMode ? '-add-mobile' : '-add'}`} 
                                        fullWidth={false} 
                                        onClick={this.addPricing}
                                    >
                                        + Add Pricing
                                    </PrimaryButton>
                                }
                            </Panel.Heading>
                            <Panel.Collapse>
                                <Panel.Body>
                                    <TableComponent className='-highlight'
                                        data={pricePeriods}
                                        resizable={false} 
                                        minRows={0}
                                        showPagination={true}
                                        columns={this.props.mobileMode ? this.mobilePricingColumns : this.pricingColumns}
                                        getTrProps={this.getRowProps}
                                        itemType={pricePeriods && pricePeriods.length == 1 ? 'Price Period' : 'Price Periods'}
                                        pageSize={
                                            this.state.pricePeriodsPageSize
                                        }
                                        onPageSizeChange={val => {
                                                this.setState({
                                                        pricePeriodsPageSize: Number(val),
                                                })
                                            }
                                        }
                                        mobileMode={this.props.mobileMode}
                                        cssClass="listview"
                                        totalItemsCount={pricePeriods && pricePeriods.length}
                                        noDataText="No Pricing found"
                                        defaultSorted={[
                                            {
                                                id: "price_period_start",
                                                desc: true
                                            }
                                        ]}
                                    />
                                </Panel.Body>
                            </Panel.Collapse>
                        </Panel> :
                        ''
                    }
                    {
                        !this.props.repChild ? 
                        <Panel eventKey={2}>
                            <Panel.Heading>
                                <Panel.Title toggle>
                                    <strong>Check-in/Check-out <span className={'pull-right '+(this.props.listViewKey === 1 ? 'glyphicon glyphicon-chevron-up': 'glyphicon glyphicon-chevron-down')}></span></strong>
                                </Panel.Title>
                                {
                                    this.props.admin && 
                                    <PrimaryButton 
                                        type="button" 
                                        cssClass={`listView${this.props.mobileMode ? '-add-mobile' : '-add'}`} 
                                        fullWidth={false} 
                                        onClick={e => this.props.addCico(moment(),moment(),this.props.activeProperty, "", e)}
                                    >
                                        + Add Check-in/Check-out
                                    </PrimaryButton>
                                }
                            </Panel.Heading>
                            <Panel.Collapse>
                                <Panel.Body>
                                    <TableComponent className='-highlight'
                                        data={cico}
                                        resizable={false} 
                                        minRows={0}
                                        showPagination={true}
                                        columns={
                                            this.cicoColumns
                                        }
                                        getTrProps={this.getRowProps}
                                        itemType={'Check-in/Check-out'}
                                        pageSize={
                                            this.state.cicoPageSize
                                        }
                                        onPageSizeChange={val => {
                                                this.setState({
                                                    cicoPageSize: Number(val),
                                                })
                                            }
                                        }
                                        mobileMode={this.props.mobileMode}
                                        cssClass="listview"
                                        totalItemsCount={cico && cico.length}
                                        noDataText="No Pricing found"
                                        defaultSorted={[
                                            {
                                                id: "start_date",
                                                desc: true
                                            }
                                        ]}
                                    />
                                </Panel.Body>
                            </Panel.Collapse>
                        </Panel> :
                        ''
                    }
                    {
                        !((this.props.currentProperty && this.props.currentProperty.level === "rep") && this.props.integrationName.integration_name === "racalendar") &&
                        <Panel eventKey={3}>
                            <Panel.Heading>
                                <Panel.Title toggle>
                                    <strong>Availability <span className={'pull-right '+(this.props.listViewKey === 2 ? 'glyphicon glyphicon-chevron-up': 'glyphicon glyphicon-chevron-down')}></span></strong>
                                </Panel.Title>
                                {this.props.admin && <PrimaryButton type="button" cssClass={`listView${this.props.mobileMode ? '-add-mobile' : '-add'} `} fullWidth={false} onClick={this.addAvailability}>+ Add Availability</PrimaryButton>}
                            </Panel.Heading>
                            <Panel.Collapse>
                                <Panel.Body>
                                    <TableComponent className='-highlight'
                                        data={availability}
                                        resizable={false} 
                                        minRows={0}
                                        showPagination={true}
                                        columns={this.props.mobileMode ? this.mobileAvailabilityColumns : this.availabilityColumns}
                                        getTrProps={this.getRowProps}
                                        pageSize={this.state.blocksPageSize}
                                        itemType={availability && availability.length == 1 ? 'Block' : 'Blocks'}
                                        onPageSizeChange={val => {
                                                this.setState({
                                                    blocksPageSize: Number(val),
                                                })
                                            }
                                        }
                                        mobileMode={this.props.mobileMode}
                                        cssClass="listview"
                                        totalItemsCount={availability && availability.length}
                                        noDataText="No Blocks found"
                                        defaultSorted={[
                                            {
                                                id: "period.period_start",
                                                desc: true
                                            }
                                        ]}
                                    />
                                </Panel.Body>
                            </Panel.Collapse>
                        </Panel>
                    }
                </PanelGroup>
            </div>
        )
    }
    render() {
        return (
            <Aux>
                {this.props.mobileMode ?
                    <Fragment>
                        <div className="mobile-top-items">
                            <div className="toggle-view-mobile">
                                <a 
                                    href="#!"
                                    onClick={this.props.toggleListView}
                                    className={`toggle-view-btn-mobile${!this.props.listView ? ' active' : ''}`}
                                >
                                    Calendar
                                </a>
                                    &nbsp;&nbsp;<strong>|</strong>&nbsp;&nbsp;
                                    <a 
                                    href="#!"
                                    className={`toggle-view-btn-mobile${this.props.listView ? ' active' : ''}`}
                                    onClick={this.props.toggleListView}
                                >
                                    List
                                </a>
                            </div>
                            {!this.props.listView &&
                                <div style={{position: 'relative'}}>
                                    <StyledWrapper>
                                        <DatePicker
                                            id="mobileMonthViewPicker"
                                            date={moment(this.props.date)}
                                            onDateChange={(date)=> {
                                                if (date) {
                                                    this.props.onNavigate('DATE',date.toDate())
                                                }
                                            }}
                                            readOnly
                                            numberOfMonths={1}
                                            isOutsideRange={() => false}
                                        />
                                    </StyledWrapper>
                                    <InputField
                                        type="text"
                                        maxLength="10"
                                        id="mobile-input-date"
                                        disabled={!this.props.admin}
                                        value={this.state.manualDate}
                                        onBlur={()=>{this.setState({
                                            manualDate: moment(this.props.date).format('MM/DD/YYYY'),
                                        })}}
                                        onChange={(e)=> {
                                            this.handleManualDateChange(e)
                                        }}
                                    />
                                </div>
                            }
                        </div>
                        {!this.props.listView &&
                            <div 
                                className="mobile-navigation-controller"
                            >
                                <div 
                                    className="arrow-container"
                                >
                                    <button
                                        className="plain-text-btn icon-left_arrow"
                                        onClick={()=>this.props.onNavigate('PREV')} 
                                        title="Previous"
                                    />
                                </div>
                                <div>
                                    <h3 
                                        className="month-year-date-heading"
                                    >
                                        {moment(this.props.date).format("MMMM YYYY")}
                                    </h3>
                                </div>
                                <div 
                                    className="arrow-container"
                                >
                                    <button
                                        className="plain-text-btn icon-right_arrow"
                                        onClick={()=>this.props.onNavigate('NEXT')} 
                                        title="Next"
                                    />
                                </div>
                            </div>
                        }
                    </Fragment> : 
                    <div className="toggle-view">
                        <a href="#!" className={`toggle-btn ${this.props.listView? "toggle-btn-active":""}`} onClick={this.props.toggleListView}><img src={this.props.listView? "/app/assets/icons/list-icon.svg":"/app/assets/icons/list-icon-black.svg"} className="listview-icon"/>List</a>
                        <a href="#!" className={`toggle-btn ${!this.props.listView? "toggle-btn-active":""}`} onClick={this.props.toggleListView}><span className="icon-Calender">&nbsp;</span>Calendar</a>
                    </div>
                }
                {
                    this.props.listView? this.renderListView() : this.renderCalendar()
                }
            </Aux>
        )
    }
}

export default connect(state => {
    return {
        mobileMode: state.updateMobileState.mobileMode,
    }
})(MonthView);

