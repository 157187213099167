import React, { Fragment } from 'react';
import { PrimaryButton } from '../../global/forms/FormElements';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Modal from 'react-bootstrap/lib/Modal';
import PanelGroup from 'react-bootstrap/lib/PanelGroup';
import Panel from 'react-bootstrap/lib/Panel';
import Link from 'react-router-dom/Link';

export class ValidationModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeKey: null,
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ 
                activeKey: this.props.redAwningFatalError ? Object.keys(this.props.validationChannels).length : null,
            });
        }, 500);
    }

    componentDidUpdate(nextProps) {
        if(nextProps.validationChannels !== this.props.validationChannels) {
            setTimeout(() => {
                this.setState({ 
                    activeKey: this.props.redAwningFatalError ? Object.keys(this.props.validationChannels).length : null,
                });
            }, 500);
        }
    }

    panelOnSelect = (activekey, e, customKey) => {
        if(e) e.preventDefault();
        
        this.setState({ 
            activeKey: customKey ? customKey : activekey,
        });
    }

    /**
     * Invoke function before modal hide start. 
     */
    onHide = () => {
        this.props.onHide();
        this.setState({
            activeKey: null
        });
    }

    /**
     * render validation data.
     * @param {*} channel 
     * @param {*} list 
     * @param {*} index 
     */
    renderValidationData = (channel,list,index) => {

        return (
            <Panel eventKey={(index + 1)} key={index}>
                <Panel.Heading>
                    <Panel.Title 
                        toggle
                        onClick={e => this.panelOnSelect(null, e, (index + 1))}
                    >
                        {channel+" ("+(list[channel].length)+")"}
                        <span 
                            className={
                                `pull-right ${this.state.activeKey === (index + 1) ? 'glyphicon glyphicon-chevron-up': 'glyphicon glyphicon-chevron-down'}`
                            }
                        >
                        </span>
                    </Panel.Title>
                    <Panel.Body collapsible>
                        <div className="messages-panel">
                            <div>
                                <h4 className="error-category"><img className="validation-icon" src="/app/assets/icons/fatal.svg"/>Fatal Errors</h4>
                                <ul>
                                    {
                                        list[channel].map((message, i)=> {
                                            return <li key={i}>{message.description}</li>
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </Panel.Body>
                </Panel.Heading> 
            </Panel>
        ) 
    }

    render() {
        let propertyData = this.props.property;

        return (
            <Modal className="validation-modal" show={this.props.show} onHide={this.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Validations</Modal.Title>
                    <h4>{propertyData.content.title} - #{propertyData.property_id}</h4>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            {
                                Object.keys(this.props.validationChannels).length > 0 ?
                                <Fragment>
                                    <PanelGroup 
                                        accordion 
                                        id="validations"
                                        activeKey={this.state.activeKey}
                                        onSelect={activeKey => this.panelOnSelect(activeKey)}
                                    >
                                        {
                                            Object.keys(this.props.validationChannels).map((channel, index)=>{
                                            return this.renderValidationData(channel, this.props.validationChannels, index)
                                        })}
                                    </PanelGroup>
                                    <div>What are <Link className="clickable-text" to={`/account/faq/validations`}>Fatal Validations</Link>?</div>
                                </Fragment>
                                :
                                <h4>Congratulations! This property meets all distribution requirements.</h4>
                            }
                        </Col>
                    </Row>
                    {
                        this.props.redAwningFatalError && this.props.showValidationModalError &&
                        <Row>
                            <Col xs={12}>
                                <span className="required-error-text">Changes not saved. Please fix all RedAwning validations to set this property live.</span>
                            </Col>
                        </Row>
                    }
                </Modal.Body>
                {
                    this.props.view !== "edit" &&
                    <Modal.Footer>
                        <Row>
                            <Col xs={6}>
                                <PrimaryButton 
                                    onClick={
                                        ()=> {
                                            this.props.history.push(propertyData.repconfig.property_collection_id ? 
                                            `/collections/${propertyData.repconfig.property_collection_id}/rooms/${propertyData.property_id}/edit` : 
                                            `/properties/${propertyData.property_id}/edit`)}
                                        }
                                >
                                    Edit Property
                                </PrimaryButton>   
                            </Col>
                            <Col xs={6}>
                                <PrimaryButton 
                                    onClick={() => {
                                        this.props.history.push(`/calendar/${propertyData.property_id}/edit`)}
                                    }
                                >
                                    Edit Calendar
                                </PrimaryButton>
                            </Col>
                        </Row>
                    </Modal.Footer>
                }
            </Modal>
        )
    }
}
