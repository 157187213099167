import React, { Component, Fragment } from "react";
import showdown from "showdown";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Storage, API } from "aws-amplify";
import { Title } from "../../global/Title";
import {
	FieldWrap,
	InputLabel,
	InputField,
	PrimaryButton
} from "../../global/forms/FormElements";
import { Link } from "react-router-dom";
import { ValidationModal } from "./ValidationModal";
import { RTE } from "../../global/forms/RTE";
import { delay } from "../../../js/actions";
import { DatePicker } from "../../global/forms/SingleDatePicker";
import { AddToCollection } from "../../content/properties/AddToCollection";
import moment from "moment";
import isEqual from "lodash/isEqual";
import _ from "lodash";
import { updateUnsavedData } from "../../../js/actions";
import Switch from "react-switch";

import Form from "react-bootstrap/lib/Form";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import PanelGroup from "react-bootstrap/lib/PanelGroup";
import Panel from "react-bootstrap/lib/Panel";
import Modal from "react-bootstrap/lib/Modal";
import DropdownButton from "react-bootstrap/lib/DropdownButton";
import MenuItem from "react-bootstrap/lib/MenuItem";
import OverlayTrigger from "react-bootstrap/lib/OverlayTrigger";
import Tooltip from "react-bootstrap/lib/Tooltip";
import Popover from "react-bootstrap/lib/Popover";
import DateRangePicker from "react-dates/lib/components/DateRangePicker";

import NavigationPrompt from "../../global/NavigationPrompt";
import GoogleMap from "../../global/GoogleMap.js";
import apiHandler from "../../../js/apiHandler";
import PhotoGallery from "./PhotoGallery";
import SubmitModal from "./SubmitModal";
import PropertyStatusButton from "./PropertyStatusButton";
import Loader from "../../global/Loader";
import constants from "../../../js/constants";
import Rooms from "../../views/Rooms.js";
import PropertyRoomInfo from "../../content/properties/PropertyRoomInfo";
import Detail from "../../content/properties/Detail";
import Accomodation from "../../content/properties/Accomodation";
import Policies from "../../content/properties/Policies";
import AmenitiesEntertainment from "../../content/properties/AmenitiesEntertainment";
import PropertiesFeesSchedule from "../../content/properties/PropertiesFeesSchedule";
import Aux from "../../hoc/AuxComp";
import { infoPopover } from "../../../helpers/helpers";

import FaqModal from "../../content/properties/FaqModal.js";
import VideoModal from "../../global/forms/VideoModal";
import Reviews from "../../views/Reviews";
import EditDevices from "../../views/EditDevices";
import GuestBook from "./GuestBook";
import SupportTickets from "../../views/SupportTickets.js";

const NotLiveDefaultNote = "Not Live reason left empty.";

class PropertiesForm extends Component {
	geocoder = new window.google.maps.Geocoder();
	typeCount = 0;
	_isMounted = false;
	viewDevices = false;
	viewReviews = false;
	viewFreshDeskTickets = false;
	viewCrmCases = false;
	isInternalPropertyAdmin = this.props.isInternalPropertyAdmin;
	propertyContentLocksModel = {
		title: "UNLOCKED",
		accommodations: "UNLOCKED",
		amenities: "UNLOCKED",
		description: "UNLOCKED",
		licenses: "UNLOCKED",
		cico: "LOCKED",
		policies: {
			internet: "UNLOCKED",
			parking: "UNLOCKED",
			pets: "UNLOCKED"
		},
		images: {
			LOCKED: [],
			UNLOCKED: []
		}
	};
	turnoverBlockTooltip =
		"Number of nights blocked before and after a reservation.";
	propertyTitleTooltip =
		"Changing the listing title in the portal will not update the title on our channel partners. It will require a request to hostsuccess@redawning.com.";
	checkinInstructionsTooltip =
		"Metadata will be inserted into the check-in instructions provided to guest. Editing the field below will set the default metadata for this property (this will override the default metadata that is set for your organization).";
	seasonalPoliciesTooltip =
		"Cancellation policies that are less strict often generate more reservations. To overwrite your default cancellation policy for a certain date range, you can do so here. Please note this isn't supported on all channels currently. Channels other than Booking.com, Expedia, VRBO and RedAwning will continue to use your default cancellation policy.";
	licenseInfo = {
		tax:
			"Please add any applicable tax license number used to identify yourself, your company or property to adhere to local short term rental tax laws in your area. An example would be a Transient Accomodation Tax (TAT) number. Enter only one number per field. You may add an additional tax license number field if needed.",
		permit_number:
			"If you are required to have a short term rental permit or registration number to rent out your property, please enter that here. Enter only one number per field. You may add an additional permit/registration number field if needed.",
		exemption:
			"Only use this checkbox if your property is located in a region that requires any permits or licenses but you have an exemption from your local government.",
		tax_map_key:
			"(Parcel Number) - Please add your 12-digit TMK (parcel number) here. This is only required for properties in Hawaii, and can be located on your local government website."
	};
	state = {
		init: true,
		isAdmin: false,
		isSmartConciergeAdmin: false,
		isSmartConciergeViewer: false,
		integrationsDataByOrgName:
			this.props.integrationsDataByOrgName &&
			this.props.integrationsDataByOrgName.integration_name
				? this.props.integrationsDataByOrgName
				: { integration_name: "racalendar" },
		mode: "create",
		propertyContentLocks: this.propertyContentLocksModel,
		type:
			this.props.propertyData && this.props.propertyData.level === "rep"
				? "Room"
				: "Property",
		propertyId: this.props.propertyData && this.props.propertyData.property_id,
		activeKey: _.get(window, "data.scrollTo")
			? _.get(window, "data.scrollTo")
			: this.props.routeFromCalendar
			? "9"
			: this.props.routeFromFeeSchedule
			? "7"
			: this.props.propertyData && this.props.propertyData.level !== "key"
			? "2"
			: "1",
		typesOfPlaces: [],
		newPhotos: [],
		amenities: {
			amenities: [],
			entertainment: []
		},
		success: false,
		editAddress: false,
		showModal: null,
		failedImagesCount: 0,
		showValidations: false,
		validationData: null,
		validationChannels: {},
		isLoading: false,
		validationIsLoading: false,
		showValidationModalError: false,
		photoIsLoading: false,
		photoIsProcessing: false,
		isFetching: false,
		fetchError: false,
		addToCollection: true,
		bathroomInt: "",
		bathroomFloat: ".0",
		petsAllowed: "",
		internetIncluded: "",
		parkingIncluded: "",
		photos: [],
		selectedPictures: [],
		notAcceptedFileType: [],
		notAcceptedFileSize: [],
		calendarSource: "racalendar",
		deletedPhotos: [],
		external_property_id: "",
		mapBaseLocation: null,
		selectAllImages: false,
		numberOfBedrooms: "",
		rooms: [],
		studioRoom: [],
		commonRooms: [],
		selectedDates: [],
		duplicateLicenses: {},
		duplicateCommonRooms: {},
		collectionSuggestionData: [],
		calendarData: {},
		uploadStatus: "",
		progressMessage: "",
		processingPhotosStatus: 0,
		processingPhotos: [],
		submitImageStatus: 0,
		submitStatus: 0,
		submitModalTotal: 0,
		submitModalDeletedPhotos: [],
		submitModalPhotos: [],
		propertyExemption: "",
		exemption_reason: "",
		RTE_description: "",
		RTE_special_terms: "",
		internet_policy: "0",
		parking_policy: "0",
		booking_lead_hours: 24,
		imageStatusMessage: "",
		redAwningFatalError: false,
		acceptTaxCleaning: false,
		address: "",
		videoModal: "",
		videoURL: "",
		feeScheduleEnabled: false,
		turnover_block: "",
		teamMemberPropertiesList: [],
		globalTeamMembers: [],

		data: {
			title: "",
			description: "",
			property_type: "",
			handicap_accessible: "",
			special_terms: "",
			view: null,
			kitchen: "",
			pool: false,
			bathrooms: "",
			bedrooms: "",
			beds_king: 0,
			beds_queen: 0,
			beds_double: 0,
			beds_twin: 0,
			beds_sofa: 0,
			room_configurations: [],
			sleep_max: "",
			square_feet: "",
			pets_allowed: "",
			children_ok: "",
			smoking_allowed: "",
			residency_category: "",
			amenities: ["Carbon Monoxide Detector", "Smoke Detector"],
			licenses: [],
			location: {
				address: {
					street_address: "",
					street_address2: "",
					city: "",
					province: "",
					postal_code: "",
					country: "US"
				},
				geo_code: {
					lat: 0,
					lng: 0
				}
			}
		},
		price: {
			block_calendar: 1095,
			base_weekday_price: 1,
			base_weekend_price: 1,
			base_weekly_price: 1,
			base_minstay: 1,
			damage_waiver: 1,
			security_deposit: 1,
			other: 0,
			currency: "",
			cleaning_fee: "",
			tax_percent: ""
		},
		showOtherField: false,
		status: {
			published: false,
			bookable: false,
			open: false,
			notes: NotLiveDefaultNote
		},
		cico_times: {
			check_in_start: 57600,
			check_in_end: 86340,
			check_out: 39600
		},
		policies: {
			pets: {
				item: "pets",
				property_policy: "",
				restrictions: [
					{
						adjustment: {
							amount: ""
						},
						duration: {
							duration_per: ""
						},
						meta: {
							pets_admission: "",
							pets_permit: ""
						},
						title: ""
					}
				]
			},
			internet: {
				item: "internet",
				property_policy: "",
				restrictions: [
					{
						adjustment: {
							amount: ""
						},
						duration: {
							duration_per: ""
						},
						meta: {
							admission: "",
							internet_type: "",
							internet_coverage: ""
						}
					}
				]
			},
			parking: {
				item: "parking",
				property_policy: "",
				restrictions: [
					{
						adjustment: {
							amount: ""
						},
						duration: {
							duration_per: ""
						},
						meta: {
							admission: "",
							parking_location: "",
							parking_reservation: "",
							parking_type: ""
						}
					}
				]
			}
		},
		customFields: [],
		propertyCommData: {
			alarm_code: "",
			wifi_name: "",
			wifi_password: ""
		},
		calendar: {
			feed_type: "",
			url: "",
			frequency: "",
			one_time_run_ts: "",
			use_checkout_date: ""
		},
		error: {
			show: false,
			message: "",
			detail: ""
		},
		mapOptions: {
			center: { lat: 37.1945192, lng: -95.106263 },
			zoom: 2,
			mapTypeId: "roadmap"
		},
		initialStateModel: {
			data: {
				amenities: []
			},
			price: {},
			cicoTimes: {},
			photos: [],
			status: {},
			rooms: [],
			commonRooms: [],
			policies: {
				pets: {},
				internet: {},
				parking: {}
			},
			calendar: {},
			externalPropertyId: "",
			bathroomInt: "0",
			bathroomFloat: ".0",
			booking_lead_hours: 24,
			turnover_block: "",
			propertyContentLocks: this.propertyContentLocksModel,
			freeCancelPolicy: "no",
			seasonalPolicies: []
		},
		formValidation: {
			title: false,
			address: false,
			longLatRequired: false,
			street_address: false,
			city: false,
			country: false,
			province: false,
			description: false,
			property_type: false,
			kitchen: false,
			sleep_max: false,
			bathroomInt: false,
			commonRooms: [],
			rooms: [],
			studioRoom: [],
			duplicateCommonRooms: false,
			amenities: false,
			petsAllowed: false,
			petsRequest: false,
			petsFee: false,
			petsFeeFrequency: false,
			petsAmenityRequired: false,
			internetIncluded: false,
			internet_type: false,
			internet_coverage: false,
			internetFee: false,
			internetFeeFrequency: false,
			internetAmenityRequired: false,
			parkingIncluded: false,
			parking_type: false,
			parking_location: false,
			parking_reservation: false,
			parkingFee: false,
			parkingFeeFrequency: false,
			parkingAmenityRequired: false,
			duplicateLicenses: false,
			licenses: [],
			propertyExemption: false,
			exemption_reason: false,
			residency_category: false,
			photos: false,
			photoFileType: false,
			photoFileSize: false,
			calendarUrl: false,
			use_checkout_date: false
		},
		licenseLocationRequired: { isRequired: false, message: "" },
		propertyContentLocks: this.propertyContentLocksModel,
		freeCancelPolicy: null,
		seasonalPolicies: []
	};

	parseCommData = async () => {
		let respCustomFields = await this.getCommDataCustomFields();
		let respCommData = await this.getCommData();

		let data = {
			alarm_code: _.get(respCommData, "alarm_code") || "",
			wifi_name: _.get(respCommData, "wifi_name") || "",
			wifi_password: _.get(respCommData, "wifi_password") || ""
		};

		if (respCustomFields && !_.isEmpty(respCustomFields)) {
			respCustomFields.forEach(field => {
				if (_.get(field, "name") && _.get(field, "custom_field")) {
					data[field.name] = _.get(respCommData, `${[field.name]}`) || "";
				}
			});
		}

		this.setState({
			customFields: respCustomFields,
			propertyCommData: data
		});
	};

	async componentDidMount() {
		this._isMounted = true;

		this.setState({
			isLoading: true
		});

		await this.parseCommData();
		await this.getAmenities();

		const currentOrgRoles = this.props.expanded_organization_roles.filter(
			org => {
				return org.organization_name == this.props.org;
			}
		);

		if (currentOrgRoles.length) {
			this.setState({
				isAdmin:
					currentOrgRoles[0].roles.indexOf(
						constants.USER_TYPES.PROPERTY_MANAGER_ADMIN
					) > -1,
				isSmartConciergeViewer:
					currentOrgRoles[0].roles.indexOf(
						constants.USER_TYPES.SMART_CONCIERGE_VIEWER
					) > -1,
				isSmartConciergeAdmin:
					currentOrgRoles[0].roles.indexOf(
						constants.USER_TYPES.SMART_CONCIERGE_ADMIN
					) > -1
			});
		}

		// Edit property
		if (this.props.propertyData) {
			this.checkPermissions();
			this.getChildOrgProperties();
			this.getProperties();
			this.getTeamMembers();

			await this.getFreeCancelPolicy();

			await this.getSeasonalPolicies();

			await this.getCollectionSuggestionData();

			await this.getFeesList();

			await this.getTurnoverBlock();

			if (
				this.state.integrationsDataByOrgName.integration_name === "racalendar"
			) {
				await this.getCalendarData();
			}

			if (
				this.state.isAdmin &&
				this.state.integrationsDataByOrgName &&
				this.state.integrationsDataByOrgName.integration_name &&
				this.state.integrationsDataByOrgName.integration_name !== "racalendar"
			) {
				await this.getContentData();
			}

			this.setState(
				{
					isLoading: false
				},
				() => {
					let eleId = "";
					if (_.get(window, "data.scrollTo")) {
						eleId = _.get(window, "data.scrollTo");
						window.data = {};
					} else if (
						this.props.routeFromCalendar ||
						this.props.routeFromFeeSchedule
					) {
						eleId = this.routeFromCalendar ? "calendarSource" : "feeSchedule";
					}

					if (eleId) {
						setTimeout(() => {
							document.getElementById(eleId).scrollIntoView({
								block: "end",
								behavior: "smooth"
							});
						}, 1000);
					}
				}
			);

			this.updatePropertyState(this.props.propertyData, "edit");
			this.updatePhotoState(this.props.propertyData, "edit");
			this.checkPhotoStatus(this.props.propertyData, "edit");
		}
		// Clone property
		else if (this.props.cloneData) {
			this.updatePropertyState(this.props.cloneData.property, "clone");
		}
		// Create Property
		else {
			this.updateInitialStateModel();
		}

		this.setState({
			isLoading: false
		});
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	checkPermissions = () => {
		if (_.isArray(this.props.permissions)) {
			if (
				this.props.permissions.includes("view_reviews") ||
				this.props.permissions.includes("view_reviews_unrestricted")
			) {
				this.viewReviews = true;
			}

			if (this.props.permissions.includes("view_devices")) {
				this.viewDevices = true;
			}

			if (this.props.permissions.includes("view_freshdesk_tickets")) {
				this.viewFreshDeskTickets = true;
			}

			if (this.props.permissions.includes("view_crm_cases")) {
				this.viewCrmCases = true;
			}
		}
	};

	getCommDataCustomFields = async () => {
		let resp;

		try {
			resp = await API.get(
				"gbapi",
				`/organizations/${this.props.org}/commdata-custom-fields`
			);
		} catch (e) {}

		return resp;
	};

	getCommData = async id => {
		const { propertyId } = this.state;
		let resp;

		try {
			resp = await API.get(
				"gbapi",
				`/properties/${id ? id : propertyId}/commdata`
			);
		} catch (e) {}

		return resp;
	};

	/**
	 * Get amenities from API call.
	 */
	getAmenities = async () => {
		try {
			let amenities = await apiHandler.get("/amenities");
			let amenitiesData = amenities.data;
			let amenitiesArray = [];
			let entertainmentArray = [];

			amenitiesData.forEach(amenity => {
				if (amenity.name == "Washer/Dryer") {
					return;
				} else if (amenity.type === "amenity") {
					amenitiesArray.push({ label: amenity.name, value: amenity.name });
				} else {
					entertainmentArray.push({ label: amenity.name, value: amenity.name });
				}
			});
			this.setState({
				amenities: {
					...this.state.amenities,
					amenities: amenitiesArray,
					entertainment: entertainmentArray
				}
			});
		} catch (e) {
			console.log("error in amenities api");
		}
	};

	getTeamMembers = async () => {
		let resp;
		let globalTeamMembers = [];

		try {
			resp = await API.get(
				"ramapi",
				`/commhub/organizations/${this.props.org}/subscribers`,
				{ response: true, isCognito: true }
			);

			if (resp && resp.data && resp.data.length) {
				resp.data.forEach(tm => {
					let globalOptins = tm.opt_ins.filter(optin => !optin.internal_id);
					if (globalOptins.length) {
						globalTeamMembers.push(tm);
					}
				});
			}

			this.setState({
				globalTeamMembers: globalTeamMembers
			});
		} catch (e) {
			console.log(e);
		}
	};

	getChildOrgs = async () => {
		let resp_childOrgs;
		let orgList = [this.props.org];

		function getOrganizationName(data) {
			let organizationNames = [];

			function extractOrgNames(organizations) {
				organizations.forEach(org => {
					organizationNames.push(org.organization_name);
					if (org.child_organizations && org.child_organizations.length > 0) {
						extractOrgNames(org.child_organizations);
					}
				});
			}

			extractOrgNames(data);
			return organizationNames;
		}

		try {
			resp_childOrgs = await API.get(
				"accapi",
				`/organizationtree?parent=${this.props.org}`
			);

			if (_.get(resp_childOrgs, "[0].child_organizations.length")) {
				let orgNames = getOrganizationName(
					resp_childOrgs[0].child_organizations
				);

				orgList = [...orgList, ...orgNames];
			}
		} catch (e) {}

		return orgList;
	};

	getChildOrgProperties = async () => {
		let orgNames = await this.getChildOrgs();

		let resp1;
		let resp2;
		let limit = 200;
		let offset = 0;
		let propertyList = [];
		let totalCount = 0;

		try {
			resp1 = await API.get(
				"rapapi",
				`/promotion/properties?organizations=${orgNames.join(
					","
				)}&offset=${offset}&tid=${this.tid}&limit=${limit}`,
				{ response: true, isCognito: true }
			);

			if (_.get(resp1, "data.list.length")) {
				totalCount = resp1.headers["x-total-count"];

				resp1.data.list.forEach(property => {
					propertyList.push({
						...property,
						label: `${
							property.organization_title
								? `${property.organization_title} -`
								: ""
						} ${property.property_id ? `${property.property_id} -` : ""} ${
							property.title ? property.title : ""
						}`
					});
				});

				while (propertyList.length < totalCount) {
					offset = offset + 200;
					resp2 = await API.get(
						"rapapi",
						`/promotion/properties?organizations=${orgNames.join(
							","
						)}&offset=${offset}&tid=${this.tid}&limit=${limit}`,
						{ response: true, isCognito: true }
					);

					resp2.data.list.forEach(property => {
						propertyList.push({
							...property,
							label: `${
								property.organization_title
									? `${property.organization_title} -`
									: ""
							} ${property.property_id ? `${property.property_id} -` : ""} ${
								property.title ? property.title : ""
							}`
						});
					});
				}
			}

			propertyList.sort((a, b) => {
				if (a.label < b.label) {
					return -1;
				}
				if (a.label > b.label) {
					return 1;
				}
				return 0;
			});

			this.setState({
				teamMemberPropertiesList: propertyList
			});
		} catch (e) {
			console.log(e);
		}
	};

	getProperties = async () => {
		let resp;
		let limit = 200;
		let offset = 0;
		let newPropertyList = [];

		try {
			resp = await API.get(
				"rapapi",
				`/promotion/properties?organizations=${this.props.org}&offset=${offset}&tid=${this.tid}&limit=${limit}`,
				{ response: true, isCognito: true }
			);

			if (resp && resp.data && resp.data.list && resp.data.list.length) {
				this.propertyCount = resp.data.list.length;

				resp.data.list.forEach(property => {
					newPropertyList.push({
						...property,
						label: `${
							property.property_id ? `${property.property_id} -` : ""
						} ${property.title ? property.title : ""}`
					});
				});
			}

			this.setState({
				propertyOptionsValue: {
					property_id: "global",
					title: "Global",
					label: "Global"
				},
				propertiesList: [
					{
						property_id: "global",
						title: "Global",
						label: "Global"
					},
					...newPropertyList
				],
				teamMemberPropertiesList: [...newPropertyList]
			});
		} catch (e) {
			console.log(e);
		}
	};

	getValidation = async obj => {
		let propertyId = this.state.propertyId;
		let checkValidationStatus = [];
		let validationData = [];
		let run_validations = (obj && obj.run_validations) || false;

		try {
			let validationStatus = false;

			if (run_validations) {
				try {
					let runValidationsResponse = await apiHandler.get(
						`/properties/${propertyId}/validations?run_validations=${run_validations}`,
						{ iscognito: true }
					);
					let runValidationsResponseData = runValidationsResponse.data;

					while (validationStatus === false) {
						checkValidationStatus = await API.get(
							"rapapi",
							`/properties/validationstatuses/${runValidationsResponseData.id}`
						);

						if (checkValidationStatus.status === "completed") {
							validationStatus = true;

							try {
								validationData = await apiHandler.get(
									`/properties/${propertyId}/validations`,
									{ iscognito: true }
								);
							} catch (e) {
								this.setState({
									validationIsLoading: false
								});
							}
						}
						await delay(1500);
					}
				} catch (e) {
					this.setState({
						validationIsLoading: false
					});
				}
			} else {
				try {
					validationData = await apiHandler.get(
						`/properties/${propertyId}/validations`,
						{ iscognito: true }
					);
				} catch (e) {
					this.setState({
						validationIsLoading: false
					});
				}
			}
		} catch (e) {
			this.setState({
				validationIsLoading: false
			});
		}

		let channels = {};
		let redAwningFatalError = false;

		if (validationData.data) {
			const defaultChannels = constants.CHANNELS;

			validationData.data.forEach(item => {
				if (
					item.channel === "redawning" &&
					item.severity_property === "fatal"
				) {
					redAwningFatalError = true;
				}
				if (defaultChannels[item.channel]) {
					let channelName = defaultChannels[item.channel];

					if (channels[channelName] && item.severity_property === "fatal") {
						channels[channelName].push(item);
					} else if (item.severity_property === "fatal") {
						channels[channelName] = new Array(item);
					}
				}
			});
		}

		return {
			validationData: validationData.data,
			validationChannels: channels,
			redAwningFatalError: redAwningFatalError
		};
	};

	getCollectionSuggestionData = async () => {
		try {
			let suggestion = await apiHandler.get(
				`/propertycollections?organization=${this.props.org}`
			);
			this.setState({
				collectionSuggestionData: suggestion.data
			});
		} catch (e) {
			console.log("error in collection suggestion api");
		}
	};

	getCalendarData = async () => {
		let { propertyId } = this.state;
		try {
			let calendarData = await apiHandler.get(
				`/properties/${propertyId}/availabilityfeed`
			);
			this.setState({
				calendarData: calendarData.data
			});
		} catch (e) {
			console.log("error in availabilityfeed api");
		}
	};

	getTurnoverBlock = async () => {
		const { propertyId } = this.state;
		let resp;
		try {
			resp = await apiHandler.get(
				`/properties/${propertyId}/turnoverblockconfig`
			);
			if (_.get(resp, "data.turnover_block")) {
				this.setState({
					turnover_block: resp.data.turnover_block
				});
			}
		} catch (e) {}
	};

	getContentData = async () => {
		let propertyContentLocksResponse = {};
		try {
			propertyContentLocksResponse = await API.get(
				"rapapi",
				`/properties/${this.state.propertyId}/contentlocks`,
				{}
			);
			this.setState({
				propertyContentLocks:
					propertyContentLocksResponse && propertyContentLocksResponse.title
						? propertyContentLocksResponse
						: this.propertyContentLocksModel
			});
		} catch (err) {
			this.setState({
				propertyContentLocks: this.propertyContentLocksModel
			});
			console.log("Error getting property content locks", err);
		}
	};

	getFeesList = async () => {
		let data = {};

		try {
			data = await API.get(
				"rapapi",
				`/properties/${this.state.propertyId}/feeschedules`
			);
			if (Object.keys(data).length) {
				let dataCopy = { ...data };
				let managerFees = [...dataCopy.manager_fee_schedules.fee_schedules];
				let propertyFees = [...dataCopy.property_fee_schedules.fee_schedules];

				if (!this.props.isInternalPropertyAdmin) {
					if (data.manager_fee_schedules) {
						managerFees = dataCopy.manager_fee_schedules.fee_schedules.filter(
							x => {
								return x.recipient !== "redawning";
							}
						);
					}
					if (data.property_fee_schedules) {
						propertyFees = dataCopy.property_fee_schedules.fee_schedules.filter(
							x => {
								return x.recipient !== "redawning";
							}
						);
					}
				}

				dataCopy = {
					...dataCopy,
					manager_fee_schedules: {
						...dataCopy.manager_fee_schedules,
						fee_schedules: managerFees
					},
					property_fee_schedules: {
						...dataCopy.property_fee_schedules,
						fee_schedules: propertyFees
					}
				};

				this.setState({
					feesList: dataCopy,
					feeScheduleEnabled: propertyFees.length ? true : false
				});
			}
		} catch (e) {
			console.log(e, "error getting fees");
		}
	};

	getFreeCancelPolicy = async () => {
		const { propertyId } = this.state;
		try {
			const { data: resp } = await apiHandler.get(
				`/properties/${propertyId}/cancellationpolicies`
			);
			if (!_.isEmpty(resp)) {
				this.setState({
					freeCancelPolicy: resp[0].free_cancel_policy
				});
			}
		} catch (e) {
			console.log(e, "error getting cancellation policy");
		}
	};

	getSeasonalPolicies = async () => {
		const { propertyId } = this.state;
		try {
			const resp = await apiHandler.get(
				`/properties/${propertyId}/seasonalcancellationpolicy`
			);

			if (!_.isEmpty(resp.data)) {
				this.setState({
					seasonalPolicies: resp.data.map(policy => {
						return {
							start_date: moment(policy.start_date, "YYYY-MM-DD"),
							end_date: moment(policy.end_date, "YYYY-MM-DD"),
							policy: policy.policy,
							active: policy.active,
							focusedInput: null,
							is_valid_dates: true,
							is_valid_policy: true
						};
					})
				});
			}
		} catch (e) {
			console.log(e, "error getting seasonal policy");
		}
	};

	seasonalPoliciesPopover = (title, message) => {
		return (
			<OverlayTrigger
				placement="top"
				overlay={
					<Popover id="popover-basic" placement="top" title={title}>
						<p>{message}</p>
					</Popover>
				}
			>
				<span className="input-question-mark">
					<i className="icon-question-mark"></i>
				</span>
			</OverlayTrigger>
		);
	};

	addSeasonalPolicy = e => {
		e.preventDefault();

		this.setState({
			seasonalPolicies: this.state.seasonalPolicies.concat({
				start_date: null,
				end_date: null,
				policy:
					constants.FREE_CANCEL_POLICY_STEPDOWN[this.state.freeCancelPolicy],
				active: true,
				focusedInput: null,
				is_valid_dates: false,
				is_valid_policy: true
			})
		});
	};

	handleSeasonalDatesChange = (index, { startDate, endDate }) => {
		this.setState(prevState => ({
			seasonalPolicies: prevState.seasonalPolicies.map((policy, i) =>
				i === index
					? { ...policy, start_date: startDate, end_date: endDate }
					: policy
			)
		}));
	};

	handleSeasonalDatesFocusChange = (index, focusedInput) => {
		this.setState(
			{
				seasonalPolicies: this.state.seasonalPolicies.map((policy, i) =>
					i === index ? { ...policy, focusedInput } : policy
				)
			},
			() => {
				const startDateInput = document.getElementById(
					`sespol-startdate-${index}`
				);
				const endDateInput = document.getElementById(`sespol-enddate-${index}`);
				if (startDateInput) startDateInput.readOnly = true;
				if (endDateInput) endDateInput.readOnly = true;
			}
		);
	};

	validateDateInput = (date, type, index) => {
		let datesValidation = true;
		const dateInput = document.getElementById(`sespol-${type}-${index}`);
		if (_.isEmpty(date)) {
			dateInput.classList.add("form-validation");
			datesValidation = false;
		} else {
			dateInput.classList.remove("form-validation");
		}

		return datesValidation;
	};

	handleSeasonalDatesClose = (index, { startDate, endDate }) => {
		const startDateValid = this.validateDateInput(
			startDate,
			"startdate",
			index
		);
		const endDateValid = this.validateDateInput(endDate, "enddate", index);

		this.setState({
			seasonalPolicies: this.state.seasonalPolicies.map((policy, i) =>
				i === index
					? { ...policy, is_valid_dates: startDateValid && endDateValid }
					: policy
			)
		});
	};

	handleSeasonalActiveChange = index => {
		this.setState({
			seasonalPolicies: this.state.seasonalPolicies.map((policy, i) =>
				i === index ? { ...policy, active: !policy.active } : policy
			)
		});
	};

	handleSeasonalPolicyChange = (index, e) => {
		const newPolicy = e.target.value;

		const standardPolicy = Number(
			(this.state.freeCancelPolicy.match(/\d+/) || [99])[0]
		);
		const seasonalPolicies = Number((newPolicy.match(/\d+/) || [null])[0]);
		let validPolicy = !(standardPolicy && seasonalPolicies >= standardPolicy);

		this.setState({
			seasonalPolicies: this.state.seasonalPolicies.map((policy, i) =>
				i === index
					? { ...policy, policy: newPolicy, is_valid_policy: validPolicy }
					: policy
			)
		});
	};

	removeSeasonalPolicy = (e, index) => {
		e.preventDefault();

		this.setState({
			seasonalPolicies: this.state.seasonalPolicies.filter(
				(_, i) => i !== index
			)
		});
	};

	createSeasonalPolicies = () => {
		const standardPolicy = Number(
			(this.state.freeCancelPolicy.match(/\d+/) || [99])[0]
		);
		let freeCancelPolicy = [];

		constants.FREE_CANCEL_POLICY.map((policy, i) => {
			if (policy.value === "no") {
				return;
			}

			const freeCancel = Number((policy.value.match(/\d+/) || [null])[0]);
			const isDisabled = standardPolicy && freeCancel >= standardPolicy;
			freeCancelPolicy.push(
				<option
					key={i}
					data-value={policy.value}
					value={policy.value}
					disabled={isDisabled}
				>
					{policy.label}
				</option>
			);
		});

		return this.state.seasonalPolicies.map((policy, index) => {
			return (
				<Fragment key={index}>
					<Row className="sespol bottom-margin" id="seasonalPolicies">
						<Col className="sespol-form" lg={10} md={10} xs={10} sm={10}>
							<Col className="sespol-dtpc" lg={6} md={6} xs={12} sm={12}>
								<Col md={6} xs={6} sm={6} lg={6}>
									<InputLabel htmlFor="seasonalstart">Start Date</InputLabel>
								</Col>
								<Col md={6} xs={6} sm={6} lg={6}>
									<InputLabel htmlFor="seasonalend">End Date</InputLabel>
								</Col>
								<Col md={12} xs={12} sm={12} lg={12}>
									<DateRangePicker
										/* need error state */
										noBorder
										key={index}
										startDate={policy.start_date}
										startDateId={`sespol-startdate-${index}`}
										startDatePlaceholderText="mm/dd/yyyy"
										endDate={policy.end_date}
										endDateId={`sespol-enddate-${index}`}
										endDatePlaceholderText="mm/dd/yyyy"
										onDatesChange={dates =>
											this.handleSeasonalDatesChange(index, dates)
										}
										focusedInput={policy.focusedInput}
										onFocusChange={focused =>
											this.handleSeasonalDatesFocusChange(index, focused)
										}
										onClose={dates =>
											this.handleSeasonalDatesClose(index, dates)
										}
									/>
									{!this.state.seasonalPolicies[index].is_valid_dates && (
										<span className="required-error-text">
											Seasonal date range should be filled in.
										</span>
									)}
								</Col>
							</Col>
							<Col lg={3} md={3} xs={6} sm={6}>
								<InputLabel htmlFor="seasonal_cancel_policy">
									Free Cancel Policy
								</InputLabel>
								<InputField
									type="select"
									name="seasonal_cancel_policy"
									value={policy.policy}
									onChange={e => this.handleSeasonalPolicyChange(index, e)}
									className={
										!this.state.seasonalPolicies[index].is_valid_policy &&
										"form-validation"
									}
								>
									{freeCancelPolicy}
								</InputField>
								{!this.state.seasonalPolicies[index].is_valid_policy && (
									<span className="required-error-text">
										Seasonal policy should be stricter than the default.
									</span>
								)}
							</Col>
							<Col lg={3} md={3} xs={6} sm={6}>
								<InputLabel htmlFor="seasonal_active">Active</InputLabel>
								<div className={"switch-active"}>
									<Switch
										onColor={"#5AD888"}
										uncheckedIcon={false}
										checkedIcon={false}
										height={30}
										width={45}
										checked={policy.active}
										onChange={() => this.handleSeasonalActiveChange(index)}
									/>
								</div>
							</Col>
						</Col>
						<Col md={2} xs={2} sm={2} lg={2}>
							<a
								href="#"
								className="left-margin"
								onClick={e => this.removeSeasonalPolicy(e, index)}
							>
								<i className="icon icon-Delete" />
							</a>
						</Col>
					</Row>
				</Fragment>
			);
		});
	};

	updatePropertyState = (data, mode) => {
		let { bathrooms } = data.content;
		const { calendarData } = this.state;
		let strNums = bathrooms.toString().split(".");

		// Update State/Province
		let stateProvince = [];
		stateProvince = constants.STATES_PROVINCES.filter(value => {
			return (
				value.code == data.content.location.address.province ||
				value.abbrev == data.content.location.address.province
			);
		});

		// Update Description
		if (data && data.content && data.content.description) {
			data.content.description = data.content.description
				.replace(/<br \/>/g, "<br>")
				.replace(/\r/g, "")
				.replace(/\n/g, "");
		}

		// Update Room Data
		let roomConfiguration = this.setRoomsConfiguration(
			data.content.room_configurations,
			data.content.bedrooms
		);
		let updateStudioRoom =
			roomConfiguration &&
			roomConfiguration.studioRoom &&
			roomConfiguration.studioRoom.length
				? roomConfiguration.studioRoom
				: roomConfiguration.bedrooms == 0
				? [{ roomName: "Studio Room" }]
				: [];

		// Update Pet Policy Data
		let petsData = data.policies.filter(policy => {
			return policy.item === "pets";
		});
		let updatePetsAllowed = "";
		let updatePetsFeeFrequency = "";

		if (petsData.length) {
			(petsData && petsData[0] && petsData[0].restrictions).map(data => {
				// Update pets allowed - pets_admission.
				if (!data.meta.pets_admission) {
					updatePetsAllowed = "no";
				} else if (data.meta.pets_admission === "pets can stay for free") {
					updatePetsAllowed = "yes";
				} else {
					updatePetsAllowed = data && data.meta && data.meta.pets_admission;
				}

				// Update Pets Fee Frequency - duration_per.
				if (data.title.includes("Per Pet Per Day")) {
					updatePetsFeeFrequency = "per pet per day";
				} else if (data.title.includes("Per Pet Per Week")) {
					updatePetsFeeFrequency = "per pet per week";
				} else if (data.title.includes("Per Pet Per Stay")) {
					updatePetsFeeFrequency = "per pet per stay";
				} else if (data && data.duration && data.duration.duration_per) {
					updatePetsFeeFrequency =
						data && data.duration && data.duration.duration_per;
				}
			});
		}

		// Update Internet Policy Data
		let internetData = data.policies.filter(policy => {
			return policy.item === "internet";
		});
		let updateInternetIncluded = "";

		if (internetData.length) {
			(internetData && internetData[0] && internetData[0].restrictions).map(
				data => {
					if (!data.meta.internet_type || !data.meta.internet_coverage) {
						updateInternetIncluded = "no";
					} else if (data && data.meta && data.meta.admission) {
						updateInternetIncluded = "charges may apply";
					} else if (data && data.adjustment && data.adjustment.amount) {
						updateInternetIncluded = "fee applies";
					} else {
						updateInternetIncluded = "yes";
					}
				}
			);
		}

		// Update Parking Policy Data
		let parkingData = data.policies.filter(policy => {
			return policy.item === "parking";
		});
		let updateParkingIncluded = "";

		if (parkingData.length) {
			(parkingData && parkingData[0] && parkingData[0].restrictions).map(
				data => {
					if (
						!data.meta.parking_location ||
						!data.meta.parking_reservation ||
						!data.meta.parking_type
					) {
						updateParkingIncluded = "no";
					} else if (data && data.meta && data.meta.admission) {
						updateParkingIncluded = "charges may apply";
					} else if (data && data.adjustment && data.adjustment.amount) {
						updateParkingIncluded = "fee applies";
					} else {
						updateParkingIncluded = "yes";
					}
				}
			);
		}

		// Update License Property Exemption
		let updateLicensePropertyExemption = "";
		let updateLicensePropertyExemptionReason = "";

		// Update License Data
		let updateLicenseData = [];

		if (data.content.licenses.length) {
			data.content.licenses.map(license => {
				switch (license.exempt) {
					case true:
						updateLicensePropertyExemption = "propertyExemption";
						break;
					default:
						updateLicensePropertyExemption = "licenseId";
				}
				updateLicenseData.push({
					type: license && license.type ? license.type : "",
					id: license && license.id ? license.id : "",
					exempt: license && license.exempt ? license.exempt : false,
					exemption_reason:
						license && license.exemption_reason ? license.exemption_reason : "",
					expires: license && license.expires ? license.expires : ""
				});
			});
			// Update Exemption Reason
			updateLicensePropertyExemptionReason =
				data.content.licenses[0].exemption_reason;
		}

		// Update Calendar Source
		let updateCalendarSource = "racalendar";
		let updateOnetimeRunTs = "";
		if (calendarData) {
			if (Object.keys(calendarData).length) {
				if (calendarData.feed_type === "ical") {
					if (!calendarData.frequency) {
						updateCalendarSource = "ical_one_time_import";
						updateOnetimeRunTs = "1574677833";
					} else {
						updateCalendarSource = "ical_source_of_truth";
					}
				}
			}
		}

		this.setState(
			{
				mode: mode,
				editAddress: false,
				init: mode === "clone" ? true : false,
				status: mode === "clone" ? this.state.status : data.status,
				propertyId: data.property_id,
				bathroomInt: bathrooms.toString().includes(".")
					? strNums[0]
					: bathrooms,
				bathroomFloat: bathrooms.toString().includes(".")
					? `.${strNums[1]}`
					: ".0",
				numberOfBedrooms: data && data.content && data.content.bedrooms,
				rooms: roomConfiguration.rooms,
				commonRooms: roomConfiguration.commonRooms,
				studioRoom: updateStudioRoom,
				external_property_id: data.external_property_id || "",
				price: data.price || this.state.price,
				cico_times: data.cico_times,
				selectedDates: [...new Array(data.content.licenses.length)].map(() => {
					"";
				}),
				RTE_description: data.content.description,
				RTE_special_terms: data.content.special_terms || "",
				propertyExemption: updateLicensePropertyExemption,
				exemption_reason: updateLicensePropertyExemptionReason,
				calendarSource: updateCalendarSource,
				propertyContentLocks: this.state.propertyContentLocks,
				mapOptions: {
					...this.state.mapOptions,
					center: data.content.location.geo_code,
					zoom: 13
				},
				data: {
					...data.content,
					view: null,
					bedrooms: roomConfiguration.bedrooms,
					kitchen: data.content.kitchen,
					licenses: updateLicenseData,
					residency_category:
						data.content.license_categories.residency_category || "",
					location: {
						...data.content.location,
						address: {
							...data.content.location.address,
							country: data.content.location.address.country.toUpperCase(),
							province: stateProvince.length
								? stateProvince[0].name
								: data.content.location.address.province
						}
					}
				},
				petsAllowed: updatePetsAllowed,
				internetIncluded: updateInternetIncluded,
				parkingIncluded: updateParkingIncluded,
				policies: {
					pets:
						petsData[0] && petsData[0].restrictions
							? {
									...petsData[0],
									restrictions: [
										{
											...petsData[0].restrictions[0],
											duration: {
												...petsData[0].restrictions[0].duration,
												duration_per: updatePetsFeeFrequency
											}
										}
									]
							  }
							: { ...this.state.policies.pets },
					internet: internetData[0] || { ...this.state.policies.internet },
					parking: parkingData[0] || { ...this.state.policies.parking }
				},
				calendar: {
					...calendarData,
					feed_type:
						calendarData && calendarData.feed_type
							? calendarData.feed_type
							: "racalendar",
					url: calendarData && calendarData.url ? calendarData.url : "",
					frequency:
						calendarData && calendarData.frequency
							? calendarData.frequency
							: "",
					one_time_run_ts: updateOnetimeRunTs,
					use_checkout_date:
						calendarData && calendarData.use_checkout_date == false
							? "false"
							: calendarData && calendarData.use_checkout_date == true
							? "true"
							: calendarData && calendarData.use_checkout_date
							? calendarData && calendarData.use_checkout_date
							: ""
				},
				deletedPhotos: [],
				booking_lead_hours: data.price.booking_lead_hours || "",
				licenseLocationRequired: this.state.licenseLocationRequired
			},
			() => {
				this.updateInitialStateModel();
			}
		);
	};

	updatePhotoState = (data, mode) => {
		this.setState(
			{
				photos: mode === "clone" ? [] : data.photos || [],
				newPhotos: data.photos ? JSON.parse(JSON.stringify(data.photos)) : []
			},
			() => {
				this.setState({
					initialStateModel: {
						...this.state.initialStateModel,
						photos: [...this.state.photos]
					}
				});
			}
		);
	};

	updateInitialStateModel = () => {
		this.setState({
			initialStateModel: {
				...this.state.initialStateModel,
				data: {
					...this.state.data,
					amenities: [...this.state.data.amenities]
				},
				price: { ...this.state.price },
				cicoTimes: { ...this.state.cico_times },
				photos: [...this.state.photos],
				status: { ...this.state.status },
				rooms: [...this.state.rooms],
				commonRooms: [...this.state.commonRooms],
				studioRoom: [...this.state.studioRoom],
				policies: {
					...this.state.policies,
					pets: {
						...this.state.policies.pets,
						restrictions: [...this.state.policies.pets.restrictions]
					},
					internet: {
						...this.state.policies.internet,
						restrictions: [...this.state.policies.internet.restrictions]
					},
					parking: {
						...this.state.policies.parking,
						restrictions: [...this.state.policies.parking.restrictions]
					}
				},
				calendar: {
					...this.state.calendar
				},
				petsAllowed: this.state.petsAllowed,
				internetIncluded: this.state.internetIncluded,
				parkingIncluded: this.state.parkingIncluded,
				externalPropertyId: this.state.external_property_id,
				bathroomInt: this.state.bathroomInt,
				bathroomFloat: this.state.bathroomFloat,
				booking_lead_hours: this.state.booking_lead_hours,
				turnover_block: this.state.turnover_block,
				propertyContentLocks: {
					...this.state.propertyContentLocks,
					images: {
						...this.state.propertyContentLocks.images,
						LOCKED: [...this.state.propertyContentLocks.images.LOCKED],
						UNLOCKED: [...this.state.propertyContentLocks.images.UNLOCKED]
					}
				},
				propertyCommData: this.state.propertyCommData,
				freeCancelPolicy: this.state.freeCancelPolicy,
				seasonalPolicies: this.state.seasonalPolicies
			}
		});
	};

	/**
	 * Show confirmation Box before move from property edit or add page.
	 * @param {*} e
	 */
	compareState = () => {
		let unsavedData = false;
		const stateModel = {
			data: { ...this.state.data },
			price: { ...this.state.price },
			cicoTimes: { ...this.state.cico_times },
			photos: [...this.state.photos],
			status: { ...this.state.status },
			rooms: [...this.state.rooms],
			commonRooms: [...this.state.commonRooms],
			studioRoom: [...this.state.studioRoom],
			policies: { ...this.state.policies },
			calendar: { ...this.state.calendar },
			petsAllowed: this.state.petsAllowed,
			internetIncluded: this.state.internetIncluded,
			parkingIncluded: this.state.parkingIncluded,
			externalPropertyId: this.state.external_property_id,
			bathroomInt: this.state.bathroomInt,
			bathroomFloat: this.state.bathroomFloat,
			booking_lead_hours: this.state.booking_lead_hours,
			turnover_block: this.state.turnover_block,
			propertyContentLocks: { ...this.state.propertyContentLocks },
			propertyCommData: { ...this.state.propertyCommData },
			freeCancelPolicy: this.state.freeCancelPolicy,
			seasonalPolicies: this.state.seasonalPolicies
		};

		unsavedData = !isEqual(this.state.initialStateModel, stateModel);

		this.props.updateUnsavedData(unsavedData);

		return unsavedData;
	};

	/**
	 * For set room configuration.
	 * @param {*} roomConfig
	 */
	setRoomsConfiguration = (roomConfig, bedrooms) => {
		let rooms = [];
		roomConfig.forEach(room => {
			let roomObj = {
				roomName: room.name
			};
			let beds = [].concat(room.beds);
			let i = 0,
				x,
				count,
				item;
			while (i < beds.length) {
				count = 1;
				item = beds[i];
				x = i + 1;

				while (x < beds.length && (x = beds.indexOf(item, x)) != -1) {
					count += 1;
					beds.splice(x, 1);
				}
				beds[i] = new Array(beds[i], count);
				++i;
			}
			beds.forEach(bed => {
				roomObj[bed[0]] = bed[1];
			});
			rooms.push(roomObj);
		});
		// Split rooms, studio room, and common rooms
		let parsedRoom = [];
		let studioRoom = [];
		let commonRooms = [];

		rooms.map(room => {
			if (room.roomName === "Studio Room") {
				studioRoom.push(room);
			} else if (
				room.roomName === "Living Room" ||
				room.roomName === "Loft Room"
			) {
				commonRooms.push(room);
			} else {
				parsedRoom.push(room);
			}
		});

		let newBedroomData = [];
		let newBedroomNumbers = [];
		let bedroomNumbers = [];

		// Remove all bedrooms that are not the right format e.g. "Bedroom 1"
		parsedRoom.forEach(room => {
			if (
				room.roomName.includes("Bedroom") &&
				room &&
				room.roomName &&
				!isNaN(parseInt(room.roomName.split(" ")[1]))
			) {
				newBedroomData.push(room);
			}
		});

		// Add missing bedrooms
		if (newBedroomData.length != bedrooms) {
			for (let i = 1; i < bedrooms + 1; i++) {
				bedroomNumbers.push(i);
			}

			newBedroomData.forEach(bedroom => {
				newBedroomNumbers.push(parseInt(bedroom.roomName.split(" ")[1]));
			});

			for (let i = 0; i < bedroomNumbers.length; i++) {
				if (!newBedroomNumbers.includes(parseInt(bedroomNumbers[i]))) {
					newBedroomData.push({ roomName: `Bedroom ${bedroomNumbers[i]}` });
				}
			}
		}

		// Sort bedrooms
		let sortedRooms = [...newBedroomData.sort(this.compareRooms)];

		return {
			bedrooms: newBedroomData.length,
			rooms: sortedRooms,
			commonRooms: commonRooms,
			studioRoom: studioRoom
		};
	};

	compareRooms = (a, b) => {
		const roomA = a.roomName.toLowerCase();
		const roomB = b.roomName.toLowerCase();

		let comparison = 0;
		if (roomA > roomB) {
			comparison = 1;
		} else if (roomA < roomB) {
			comparison = -1;
		}
		return comparison;
	};

	panelOnSelect = activekey => {
		this.setState({
			activeKey: activekey,
			init: false
		});
	};

	/**
	 * For fetch an address from from object received from map API call.
	 * @param {*} place object recived from google map API call.
	 * @returns {*} return an object with address filed.
	 */
	getAddressComponents = place => {
		let address = {
			street_address: "",
			street_address2: "",
			city: "",
			province: "",
			postal_code: "",
			country: ""
		};
		for (var i = 0; i < place.address_components.length; i++) {
			if (
				place.address_components[i].types[0] === "administrative_area_level_1"
			) {
				address["province"] = place.address_components[i].long_name;
			} else if (place.address_components[i].types[0] === "country") {
				address["country"] = place.address_components[i].short_name;
			} else if (place.address_components[i].types[0] === "postal_code") {
				address["postal_code"] = place.address_components[i].long_name;
			} else if (place.address_components[i].types[0] === "locality") {
				address["city"] = place.address_components[i].long_name;
			} else if (
				place.address_components[i].types.indexOf("street_number") > -1
			) {
				address["street_address"] +=
					place.address_components[i].long_name + " ";
			} else if (place.address_components[i].types.indexOf("route") > -1) {
				address["street_address"] += place.address_components[i].long_name;
			} else if (
				place.address_components[i].types.indexOf("neighborhood") > -1
			) {
				address["street_address2"] +=
					place.address_components[i].long_name + " ";
			} else if (
				place.address_components[i].types.indexOf("sublocality") > -1
			) {
				address["street_address2"] += place.address_components[i].long_name;
			}
		}
		return address;
	};

	/**
	 * Invoke while place change.
	 * @param {*} type pass type of of how place was changed eg. place_changed, dragend, city_changed, province_changed, zoom_changed.
	 * @param {*} data
	 */
	mapEvents = (type, data) => {
		try {
			let description = this.state.data.description;
			let newData = JSON.parse(JSON.stringify(this.state.data));
			let mapOptions = JSON.parse(JSON.stringify(this.state.mapOptions));
			let place = data || {};
			let isAddressPopulated = this.isAddressPopulated();
			newData.description = description;
			switch (type) {
				case "place_changed":
					let address = this.getAddressComponents(place);
					let currAddress = this.state.data.location.address;
					let newAddress = Object.assign({}, currAddress, address);
					let newLocation = {
						lat: parseFloat(data.geometry.location.lat().toFixed(4)),
						lng: parseFloat(data.geometry.location.lng().toFixed(4))
					};
					newData.location = {
						address: newAddress,
						geo_code: newLocation
					};
					mapOptions.center = data.geometry.location;
					mapOptions.zoom = 13;
					if (isAddressPopulated) {
						this.setState({
							showModal: "overwrite-modal"
						});
						this.newAddressInfo = {
							data: newData,
							mapOptions: mapOptions,
							mapBaseLocation: newLocation
						};
					} else {
						this.setState(
							{
								data: newData,
								mapOptions: mapOptions,
								mapBaseLocation: newLocation
							},
							() => {
								if (
									this.state.formValidation.longLatRequired ||
									this.state.formValidation.address ||
									this.state.formValidation.street_address ||
									this.state.formValidation.city ||
									this.state.formValidation.province ||
									this.state.formValidation.country
								) {
									let addressValidations = [
										"longLatRequired",
										"address",
										"street_address",
										"city",
										"province",
										"country"
									];
									let formValidation = this.formValidationHandler(
										addressValidations
									);

									this.setState({
										formValidation: formValidation
									});
								}
							}
						);
						this.newAddressInfo = null;
						document.getElementById("add-input").value = "";
					}
					break;
				case "dragend":
					newData.location.geo_code = {
						lat: data.lat,
						lng: data.lng
					};
					mapOptions.center = data;
					this.setState(
						{
							data: newData,
							mapOptions: mapOptions,
							mapBaseLocation: newLocation
						},
						() => {
							if (
								this.state.formValidation.longLatRequired ||
								this.state.formValidation.address ||
								this.state.formValidation.street_address ||
								this.state.formValidation.city ||
								this.state.formValidation.province ||
								this.state.formValidation.country
							) {
								let addressValidations = [
									"longLatRequired",
									"address",
									"street_address",
									"city",
									"province",
									"country"
								];
								let formValidation = this.formValidationHandler(
									addressValidations
								);

								this.setState({
									formValidation: formValidation
								});
							}
						}
					);
					this.handleDragEndAddressChange(mapOptions.center);
					break;
				case "city_changed":
					var addressData = Object.assign({}, this.state.data.location.address);
					for (var i = 0; i < place.address_components.length; i++) {
						if (place.address_components[i].types[0] === "locality") {
							addressData["city"] = place.address_components[i].long_name;
						} else if (
							place.address_components[i].types[0] ===
							"administrative_area_level_3"
						) {
							addressData["city"] = place.address_components[i].long_name;
						} else if (
							place.address_components[i].types[0] ===
							"administrative_area_level_1"
						) {
							addressData["province"] = place.address_components[i].long_name;
						} else if (place.address_components[i].types[0] === "country") {
							addressData["country"] = place.address_components[i].short_name;
						} else if (place.address_components[i].types[0] === "postal_code") {
							addressData["postal_code"] =
								place.address_components[i].long_name;
						}
					}
					var location = {};
					if (addressData.street_address == "") {
						mapOptions.center = data.geometry.location;
						mapOptions.zoom = 13;
						location = {
							lat: data.geometry.location.lat().toFixed(4),
							lng: data.geometry.location.lng().toFixed(4)
						};
					} else {
						location = Object.assign({}, newData.location.geo_code);
					}
					newData.location = {
						address: addressData,
						geo_code: location
					};
					this.setState(
						{
							data: newData,
							mapOptions: mapOptions,
							mapBaseLocation: {
								lat: data.geometry.location.lat().toFixed(4),
								lng: data.geometry.location.lng().toFixed(4)
							}
						},
						() => {
							if (
								this.state.formValidation.longLatRequired ||
								this.state.formValidation.address ||
								this.state.formValidation.street_address ||
								this.state.formValidation.city ||
								this.state.formValidation.province ||
								this.state.formValidation.country
							) {
								let addressValidations = [
									"longLatRequired",
									"address",
									"street_address",
									"city",
									"province",
									"country"
								];
								let formValidation = this.formValidationHandler(
									addressValidations
								);

								this.setState({
									formValidation: formValidation
								});
							}
						}
					);
					break;
				case "province_changed":
					var addressData = Object.assign({}, this.state.data.location.address);
					for (var i = 0; i < place.address_components.length; i++) {
						if (
							place.address_components[i].types[0] ===
							"administrative_area_level_1"
						) {
							addressData["province"] = place.address_components[i].long_name;
						} else if (place.address_components[i].types[0] === "country") {
							addressData["country"] = place.address_components[i].short_name;
						} else if (place.address_components[i].types[0] === "postal_code") {
							addressData["postal_code"] =
								place.address_components[i].long_name;
						}
					}
					var location = {};

					if (addressData.city == "") {
						mapOptions.center = data.geometry.location;
						mapOptions.zoom = 6;
						location = {
							lat: data.geometry.location.lat().toFixed(4),
							lng: data.geometry.location.lng().toFixed(4)
						};
					} else {
						location = Object.assign({}, newData.location.geo_code);
					}
					newData.location = {
						address: addressData,
						geo_code: location
					};
					this.setState(
						{
							data: newData,
							mapOptions: mapOptions,
							mapBaseLocation: location
						},
						() => {
							if (
								this.state.formValidation.longLatRequired ||
								this.state.formValidation.address ||
								this.state.formValidation.street_address ||
								this.state.formValidation.city ||
								this.state.formValidation.province ||
								this.state.formValidation.country
							) {
								let addressValidations = [
									"longLatRequired",
									"address",
									"street_address",
									"city",
									"province",
									"country"
								];
								let formValidation = this.formValidationHandler(
									addressValidations
								);

								this.setState({
									formValidation: formValidation
								});
							}
						}
					);
					break;
				case "zoom_changed":
					mapOptions.zoom = place;
					this.setState({
						mapOptions: mapOptions
					});
					break;
				default:
					console.log("default", type);
			}
		} catch (e) {
			console.log(e);
		}
	};

	/**
	 * Click on save button will call this function to handle submit form event.
	 * @param {*} e
	 */
	handleFormSubmit = () => {
		this.setState(
			{
				showModal: null,
				acceptTaxCleaning: true
			},
			() => {
				this.addProperty();
			}
		);
	};

	/**
	 * For overwrite an address
	 */
	overwriteAddress = () => {
		let newData = JSON.parse(JSON.stringify(this.state.data));
		let mapOptions = JSON.parse(JSON.stringify(this.state.mapOptions));
		if (this.newAddressInfo) {
			let { data, mapOptions, mapBaseLocation } = this.newAddressInfo;
			this.setState(
				{
					data,
					mapOptions,
					mapBaseLocation,
					showModal: null
				},
				() => {
					if (
						this.state.formValidation.longLatRequired ||
						this.state.formValidation.address ||
						this.state.formValidation.street_address ||
						this.state.formValidation.city ||
						this.state.formValidation.province ||
						this.state.formValidation.country
					) {
						let addressValidations = [
							"longLatRequired",
							"address",
							"street_address",
							"city",
							"province",
							"country"
						];
						let formValidation = this.formValidationHandler(addressValidations);

						this.setState({
							formValidation: formValidation
						});
					}
				}
			);
			this.newAddressInfo = null;
			document.getElementById("add-input").value = "";
		}
	};

	/**
	 * Handle manual address change on properties form.
	 * Function calls after user accepts address change.
	 * @param {*} event
	 */
	handleChangeAddress = event => {
		let newAddress = JSON.parse(
			JSON.stringify(this.state.data.location.address)
		);
		let name = event.target.name;
		let value = event.target.value;

		newAddress[name] = event.target.value;

		let newData = JSON.parse(JSON.stringify(this.state.data));
		newData.location.address = newAddress;

		let manualAddress;
		let hasAllInputNeededForGeocoder =
			newAddress.street_address !== "" &&
			newAddress.city !== "" &&
			newAddress.country !== "";

		if (this.typeCount) clearInterval(this.typeCount);

		this.typeCount = setInterval(() => {
			if (hasAllInputNeededForGeocoder) {
				this.setState({
					isFetching: true
				});
				manualAddress = `${newAddress.street_address} ${newAddress.city} ${newAddress.country} ${newAddress.postal_code}`;

				this.geocoder.geocode({ address: manualAddress }, (results, status) => {
					let data = this.state.data;
					let mapOptions = this.state.mapOptions;
					if (status === "OK") {
						data.location.geo_code = {
							lat: results[0].geometry.location.lat().toFixed(4),
							lng: results[0].geometry.location.lng().toFixed(4)
						};
						mapOptions.center = results[0].geometry.location;
						mapOptions.zoom = 13;
						this.setState(
							{
								data: data,
								mapOptions: mapOptions,
								hasSuccessfullyFetched: true,
								isFetching: false,
								fetchError: false
							},
							() => {
								if (this.state.formValidation.longLatRequired) {
									let formValidation = this.formValidationHandler([
										"longLatRequired"
									]);

									this.setState({
										formValidation: formValidation
									});
								}
							}
						);
					} else {
						this.setState({
							fetchError: true,
							isFetching: false
						});
					}
				});
			}
			clearInterval(this.typeCount);
		}, 2000);
		this.setState(
			{
				data: newData
			},
			() => {
				if (
					value.length <= 1 &&
					(this.state.formValidation.address ||
						this.state.formValidation.street_address ||
						this.state.formValidation.city ||
						this.state.formValidation.province ||
						this.state.formValidation.country)
				) {
					let addressValidations = [
						"address",
						"street_address",
						"city",
						"province",
						"country"
					];
					let formValidation = this.formValidationHandler(addressValidations);

					this.setState({
						formValidation: formValidation
					});
				}
			}
		);
	};

	handleDragEndAddressChange = coordinates => {
		this.setState({
			isFetching: true
		});
		let newAddress = {};
		this.geocoder.geocode({ location: coordinates }, (results, status) => {
			const { data, mapOptions } = this.state;
			if (status === "OK") {
				if (results) {
					newAddress = this.getAddressComponents(results[0]);
				}
				data.location.address = newAddress;
				mapOptions.center = coordinates;
				this.setState({
					data: data,
					mapOptions: mapOptions,
					hasSuccessfullyFetched: true,
					isFetching: false,
					fetchError: false
				});
			} else {
				this.setState({
					fetchError: true,
					isFetching: false
				});
			}
		});
	};

	/**
	 * Invoke when upload or change photo.
	 * @param {*} files
	 */
	changePhoto = async files => {
		const _this = this;
		let acceptedFileTypes = [];
		let rejectFileTypes = [];
		let rejectFileSize = [];

		files.forEach(file => {
			if (
				(file.type === "image/png" ||
					file.type === "image/jpg" ||
					file.type === "image/gif" ||
					file.type === "image/jpeg") &&
				file.size < 50000000
			) {
				acceptedFileTypes.push(file);
			} else if (
				file.type !== "image/png" &&
				file.type !== "image/jpg" &&
				file.type !== "image/gif" &&
				file.type !== "image/jpeg" &&
				file.size > 50000000
			) {
				rejectFileTypes.push(file);
				rejectFileSize.push(file);
			} else if (
				file.type !== "image/png" &&
				file.type !== "image/jpg" &&
				file.type !== "image/gif" &&
				file.type !== "image/jpeg"
			) {
				rejectFileTypes.push(file);
			} else if (file.size > 50000000) {
				rejectFileSize.push(file);
			}
		});

		// Photo filetype validation
		if (rejectFileTypes.length) {
			this.state.formValidation.photoFileType = true;
		} else {
			this.state.formValidation.photoFileType = false;
		}

		// Photo filesize validation
		if (rejectFileSize.length) {
			this.state.formValidation.photoFileSize = true;
		} else {
			this.state.formValidation.photoFileSize = false;
		}

		let photos = Object.assign([], this.state.photos),
			order = photos.length;
		if (typeof this.props.onChange === "function") {
			this.props.onChange(acceptedFileTypes);
		}

		// Iterate over all uploaded files
		this.setState({
			photoIsLoading: true
		});
		for (let i = 0, f; (f = acceptedFileTypes[i]); i++) {
			await new Promise((resolve, reject) => {
				const reader = new FileReader();
				// Read the image via FileReader API and save image result in state.
				reader.onload = (function() {
					return function(e) {
						if (_this.state.photos.indexOf(e.target.result) === -1) {
							photos.push({
								data: e.target.result.split(",")[1],
								data_type: f.type.replace("image/", ""),
								order: order++,
								file: acceptedFileTypes[i]
							});
							resolve();
						}
					};
				})(f);
				reader.readAsDataURL(f);
			});
		}
		this.setState(
			{
				photos
			},
			() => {
				this.setState({
					photoIsLoading: false
				});
				if (this.state.formValidation.photos) {
					let formValidation = this.formValidationHandler(["photos"]);

					this.setState({
						formValidation: formValidation
					});
				}
			}
		);
	};

	handleInputStateChange = e => {
		const name = e.target.name;
		let value = e.target.value;
		const requireFormValidation = ["bathroomInt"];

		if (name == "booking_lead_hours") {
			if (value % 1 != 0 || value < 0) {
				value = "0";
			}
			if (value > 380) {
				value = "380";
			}
		}

		if (
			name === "freeCancelPolicy" &&
			!_.isEmpty(this.state.seasonalPolicies)
		) {
			// Handling default cancellation policy change for seasonal policies
			const defaultPolicy = Number((value.match(/\d+/) || [null])[0]);

			this.setState({
				seasonalPolicies: this.state.seasonalPolicies.map(item => {
					const seasonalPolicies = Number(
						(item.policy.match(/\d+/) || [null])[0]
					);
					let validPolicy = !(
						defaultPolicy && seasonalPolicies >= defaultPolicy
					);
					return {
						...item,
						is_valid_policy: validPolicy
					};
				})
			});
		}

		this.setState(
			{
				[name]: value
			},
			() => {
				if (
					requireFormValidation.includes(name) &&
					this.state.formValidation.bathroomInt
				) {
					let formValidation = this.formValidationHandler([name]);

					this.setState({
						formValidation: formValidation
					});
				}
			}
		);
	};
	/**
	 * Call when filed value changed.
	 * @param {*} event
	 */
	handleDataChange = e => {
		const numValues = ["square_feet", "sleep_max"];
		let name = e.target.name;
		let value = numValues.includes(name)
			? e.target.value !== ""
				? parseInt(e.target.value)
				: e.target.value
			: e.target.value;

		this.setState(
			{
				data: {
					...this.state.data,
					[name]: value
				}
			},
			() => {
				const { formValidation } = this.state;

				if (name !== "square_feet") {
					if (formValidation[name] === true) {
						let formValidation = this.formValidationHandler([name]);

						this.setState({
							formValidation: formValidation
						});
					}
				}
			}
		);
	};

	apiLicenseValidationHandler = apiValidation => {
		let licenseLocationRequired = { isRequired: false, message: "" };
		let redawningFatalError = false;
		let requiredValidations = [];

		if (apiValidation && apiValidation.length) {
			const noneFormValidation = [102, 301, 310, 317, -409];
			const longLatRequired = [502, 503];
			const cityRequireTaxId = [
				"denver",
				"anaheim",
				"biarritz",
				"bordeaux",
				"cannes",
				"levallois-perret",
				"lyon",
				"menton",
				"nice",
				"paris",
				"versailles"
			];

			apiValidation.map(severity => {
				// FATAL VALIDATIONS NOT INCLUDED IN THE FORM
				if (noneFormValidation.includes(severity.error_code)) {
					console.log(severity.error_code, "None Form Validation Code");
					redawningFatalError = true;
				}

				// FATAL LINCENSE VALIDATION
				if (
					(this.state.data.location.address.province.toLowerCase() ===
						"hawaii" ||
						this.state.data.location.address.province.toLowerCase() === "hi") &&
					severity.error_code == 821
				) {
					if (this.state.data.licenses.length) {
						let licenseTypeTax = false;
						let licenseTypeTaxId = false;
						let licenseTypeExpire = false;

						this.state.data.licenses.map(license => {
							if (license.type.includes("tax")) {
								licenseTypeTax = true;
								if (license.id) {
									licenseTypeTaxId = true;
								}
								if (license.expires) {
									licenseTypeExpire = true;
								}
							}
						});

						if (licenseTypeTax && licenseTypeTaxId && licenseTypeExpire) {
							licenseLocationRequired = {
								isRequired: false,
								message: severity.description
							};
						} else {
							licenseLocationRequired = {
								isRequired: true,
								message: severity.description
							};
						}
					} else {
						licenseLocationRequired = {
							isRequired: true,
							message: severity.description
						};
					}
				} else if (
					cityRequireTaxId.includes(
						this.state.data.location.address.city.toLowerCase()
					) &&
					severity.error_code == 822
				) {
					const errorCodeCustomMessage =
						"Tax ID is required in the selected City";

					if (this.state.data.licenses.length) {
						let licenseTypeTax = false;
						let licenseTypeTaxId = false;

						this.state.data.licenses.map(license => {
							if (license.type.includes("tax")) {
								licenseTypeTax = true;
								if (license.id) {
									licenseTypeTaxId = true;
								}
							}
						});

						if (licenseTypeTax && licenseTypeTaxId) {
							licenseLocationRequired = {
								isRequired: false,
								message: errorCodeCustomMessage
							};
						} else {
							licenseLocationRequired = {
								isRequired: true,
								message: errorCodeCustomMessage
							};
						}
					} else {
						licenseLocationRequired = {
							isRequired: true,
							message: errorCodeCustomMessage
						};
					}
				} else if (
					(this.state.data.location.address.country.toLowerCase() === "japan" ||
						this.state.data.location.address.country.toLowerCase() === "jp") &&
					severity.error_code == 828
				) {
					if (this.state.data.licenses.length) {
						let licenseTypePermit = false;
						let licenseTypePermitId = false;
						let licenseExempt = false;

						this.state.data.licenses.map(license => {
							if (license.type.includes("permit_number")) {
								licenseTypePermit = true;
								if (license.id) {
									licenseTypePermitId = true;
								}
							}
							if (
								license.exempt &&
								(license.exemption_reason === "HOTELS_AND_INNS_ACT" ||
									license.exemption_reason === "ECONOMIC_ZONING_ACT")
							) {
								licenseExempt = true;
							}
						});

						if ((licenseTypePermit && licenseTypePermitId) || licenseExempt) {
							licenseLocationRequired = {
								isRequired: false,
								message: severity.description
							};
						} else {
							licenseLocationRequired = {
								isRequired: true,
								message: severity.description
							};
						}
					} else {
						licenseLocationRequired = {
							isRequired: true,
							message: severity.description
						};
					}
				} else if (
					this.state.data.location.address.province.toLowerCase() ===
						"catalunya" &&
					severity.error_code == 829
				) {
					if (this.state.data.licenses.length) {
						let licenseTypePermit = false;
						let licenseTypePermitId = false;
						let licenseTypeIdNumber = false;
						let licenseTypeIdNumberId = false;
						let licenseExempt = false;

						this.state.data.licenses.map(license => {
							if (license.type.includes("permit_number")) {
								licenseTypePermit = true;
								if (license.id) {
									licenseTypePermitId = true;
								}
							}
							if (license.type.includes("identification_number")) {
								licenseTypeIdNumber = true;
								if (license.id) {
									licenseTypeIdNumberId = true;
								}
							}
							if (
								license.exempt &&
								((license &&
									license.exemption_reason === "listing_not_full_building") ||
									(license &&
										license.exemption_reason === "listing_is_shared_room") ||
									(license &&
										license.exemption_reason === "other_exemption_reason"))
							) {
								licenseExempt = true;
							}
						});

						if (
							(licenseTypePermit &&
								licenseTypePermitId &&
								licenseTypeIdNumber &&
								licenseTypeIdNumberId) ||
							licenseExempt
						) {
							licenseLocationRequired = {
								isRequired: false,
								message: severity.description
							};
						} else {
							licenseLocationRequired = {
								isRequired: true,
								message: severity.description
							};
						}
					} else {
						licenseLocationRequired = {
							isRequired: true,
							message: severity.description
						};
					}
				} else if (
					this.state.data.location.address.province.toLowerCase() ===
						"andalucia" &&
					severity.error_code == 830
				) {
					if (this.state.data.licenses.length) {
						let licenseTypePermit = false;
						let licenseTypePermitId = false;
						let licenseTypeId = false;
						let licenseTypeIdNumberId = false;
						let licenseExempt = false;

						this.state.data.licenses.map(license => {
							if (license.type.includes("permit_number")) {
								licenseTypePermit = true;
								if (license.id) {
									licenseTypePermitId = true;
								}
							}
							if (license.type.includes("identification_number")) {
								licenseTypeId = true;
								if (license.id) {
									licenseTypeIdNumberId = true;
								}
							}
							if (
								license.exempt &&
								((license &&
									license.exemption_reason === "LISTING_TYPE_NOT_INCLUDED") ||
									(license &&
										license.exemption_reason ===
											"LISTING_NOT_TOURIST_ACCOMMODATION"))
							) {
								licenseExempt = true;
							}
						});

						if (
							(licenseTypePermit &&
								licenseTypePermitId &&
								licenseTypeId &&
								licenseTypeIdNumberId) ||
							licenseExempt
						) {
							licenseLocationRequired = {
								isRequired: false,
								message: severity.description
							};
						} else {
							licenseLocationRequired = {
								isRequired: true,
								message: severity.description
							};
						}
					} else {
						licenseLocationRequired = {
							isRequired: true,
							message: severity.description
						};
					}
				} else if (
					(this.state.data.location.address.country.toLowerCase() ===
						"portugal" ||
						this.state.data.location.address.country.toLowerCase() === "pt") &&
					severity.error_code == 831
				) {
					if (this.state.data.licenses.length) {
						let licenseTypeTax = false;
						let licenseTypeTaxId = false;
						let licenseTypePermit = false;
						let licenseTypePermitId = false;
						let licenseExempt = false;

						this.state.data.licenses.map(license => {
							if (license.type.includes("tax")) {
								licenseTypeTax = true;
								if (license.id) {
									licenseTypeTaxId = true;
								}
							}
							if (license.type.includes("permit_number")) {
								licenseTypePermit = true;
								if (license.id) {
									licenseTypePermitId = true;
								}
							}
							if (
								license.exempt &&
								((license &&
									license.exemption_reason ===
										"listing is not a full building") ||
									(license &&
										license.exemption_reason ===
											"listing has a registered urban lease contract"))
							) {
								licenseExempt = true;
							}
						});

						if (
							(licenseTypeTax &&
								licenseTypeTaxId &&
								licenseTypePermit &&
								licenseTypePermitId) ||
							licenseExempt
						) {
							licenseLocationRequired = {
								isRequired: false,
								message: severity.description
							};
						} else {
							licenseLocationRequired = {
								isRequired: true,
								message: severity.description
							};
						}
					} else {
						licenseLocationRequired = {
							isRequired: true,
							message: severity.description
						};
					}
				} else if (
					this.state.data.location.address.city.toLowerCase() === "vancouver" &&
					severity.error_code == 832
				) {
					if (this.state.data.licenses.length) {
						let licenseTypePermit = false;
						let licenseTypePermitId = false;
						let licenseExempt = false;

						this.state.data.licenses.map(license => {
							if (license.type.includes("permit_number")) {
								licenseTypePermit = true;
								if (license.id) {
									licenseTypePermitId = true;
								}
							}
							if (
								license.exempt &&
								(license.exemption_reason === "LICENSED_HOTEL_OR_BB" ||
									license.exemption_reason === "EVENT_SPACE" ||
									license.exemption_reason === "LOCATION_EXEMPT")
							) {
								licenseExempt = true;
							}
						});

						if ((licenseTypePermit && licenseTypePermitId) || licenseExempt) {
							licenseLocationRequired = {
								isRequired: false,
								message: severity.description
							};
						} else {
							licenseLocationRequired = {
								isRequired: true,
								message: severity.description
							};
						}
					} else {
						licenseLocationRequired = {
							isRequired: true,
							message: severity.description
						};
					}
				} else if (
					this.state.data.location.address.city.toLowerCase() ===
						"santa monica" &&
					severity.error_code == 833
				) {
					if (this.state.data.licenses.length) {
						let licenseTypePermit = false;
						let licenseTypePermitId = false;
						let licenseExempt = false;

						this.state.data.licenses.map(license => {
							if (license.type.includes("permit_number")) {
								licenseTypePermit = true;
								if (license.id) {
									licenseTypePermitId = true;
								}
							}
							if (
								license.exempt &&
								license.exemption_reason === "LICENSED_HOTEL_OR_BB"
							) {
								licenseExempt = true;
							}
						});

						if ((licenseTypePermit && licenseTypePermitId) || licenseExempt) {
							licenseLocationRequired = {
								isRequired: false,
								message: severity.description
							};
						} else {
							licenseLocationRequired = {
								isRequired: true,
								message: severity.description
							};
						}
					} else {
						licenseLocationRequired = {
							isRequired: true,
							message: severity.description
						};
					}
				} else if (
					this.state.data.location.address.city.toLowerCase() === "chicago" &&
					severity.error_code == 834
				) {
					const errorCodeCustomMessage =
						"Permit Number or Exemption Reason is required in the selected City";

					if (this.state.data.licenses.length) {
						let licenseTypePermit = false;
						let licenseTypePermitId = false;
						let licenseExempt = false;

						this.state.data.licenses.map(license => {
							if (license.type.includes("permit_number")) {
								licenseTypePermit = true;
								if (license.id) {
									licenseTypePermitId = true;
								}
							}
							if (license.exempt && license.exemption_reason) {
								licenseExempt = true;
							}
						});

						if ((licenseTypePermit && licenseTypePermitId) || licenseExempt) {
							licenseLocationRequired = {
								isRequired: false,
								message: errorCodeCustomMessage
							};
						} else {
							licenseLocationRequired = {
								isRequired: true,
								message: errorCodeCustomMessage
							};
						}
					} else {
						licenseLocationRequired = {
							isRequired: true,
							message: errorCodeCustomMessage
						};
					}
				} else if (
					this.state.data.location.address.city.toLowerCase() ===
						"san francisco" &&
					severity.error_code == 835
				) {
					const errorCodeCustomMessage =
						"Permit Number and Expiration Date is required in the selected City";

					if (this.state.data.licenses.length) {
						let licenseTypePermit = false;
						let licenseTypePermitId = false;
						let licenseTypePermitExpireDate = false;

						this.state.data.licenses.map(license => {
							if (license.type.includes("permit_number")) {
								licenseTypePermit = true;
								if (license.id) {
									licenseTypePermitId = true;
								}
								if (license.expires) {
									licenseTypePermitExpireDate = true;
								}
							}
						});

						if (
							licenseTypePermit &&
							licenseTypePermitId &&
							licenseTypePermitExpireDate
						) {
							licenseLocationRequired = {
								isRequired: false,
								message: errorCodeCustomMessage
							};
						} else {
							licenseLocationRequired = {
								isRequired: true,
								message: errorCodeCustomMessage
							};
						}
					} else {
						licenseLocationRequired = {
							isRequired: true,
							message: errorCodeCustomMessage
						};
					}
				}
				// FRANCE RESIDENCY VALIDATION
				if (
					(this.state.data.location.address.country.toLowerCase() ===
						"france" ||
						this.state.data.location.address.country.toLowerCase() === "fr") &&
					severity.error_code == 837
				) {
					const errorCodeCustomMessage =
						"Residency Category is required in the selected Country";

					if (!this.state.data.residency_category) {
						licenseLocationRequired = {
							isRequired: true,
							message: errorCodeCustomMessage
						};
						residency_category = true;
					} else {
						licenseLocationRequired = { isRequired: false, message: "" };
					}
				}
				// FORM VALIDATION
				if (
					longLatRequired.includes(severity.error_code) &&
					severity.channel === "redawning"
				) {
					if (!requiredValidations.includes("longLatRequired")) {
						requiredValidations.push("longLatRequired");
					}
				}
				if (severity.error_code == 500 && severity.channel === "redawning") {
					if (!requiredValidations.includes("city")) {
						requiredValidations.push("city");
					}
				}
				if (severity.error_code == 501 && severity.channel === "redawning") {
					if (!requiredValidations.includes("country")) {
						requiredValidations.push("country");
					}
				}
				if (severity.error_code == 504 && severity.channel === "redawning") {
					if (!requiredValidations.includes("province")) {
						requiredValidations.push("province");
					}
				}
				if (severity.error_code == 505 && severity.channel === "redawning") {
					if (!requiredValidations.includes("street_address")) {
						requiredValidations.push("street_address");
					}
				}
				if (severity.error_code == 815 && severity.channel === "redawning") {
					if (!requiredValidations.includes("description")) {
						requiredValidations.push("description");
					}
				}
				if (severity.error_code == 820 && severity.channel === "redawning") {
					if (!requiredValidations.includes("property_type")) {
						requiredValidations.push("property_type");
					}
				}
				if (
					severity.error_code == 602 &&
					severity.channel === "redawning" &&
					parseFloat(this.state.bathroomInt) == 0
				) {
					if (!requiredValidations.includes("bathroomInt")) {
						requiredValidations.push("bathroomInt");
					}
				}
				if (
					severity.error_code == 603 &&
					severity.channel === "redawning" &&
					this.state.data.sleep_max == 0
				) {
					if (!requiredValidations.includes("sleep_max")) {
						requiredValidations.push("sleep_max");
					}
				}
				if (severity.error_code == 700 && severity.channel === "redawning") {
					if (!requiredValidations.includes("amenities")) {
						requiredValidations.push("amenities");
					}
				}
				if (severity.error_code == 401 && severity.channel === "redawning") {
					if (!requiredValidations.includes("photos")) {
						requiredValidations.push("photos");
					}
				}
			});

			return {
				redawningFatalError,
				requiredValidations,
				licenseLocationRequired
			};
		}
	};

	formValidationHandler = validationNames => {
		const numberValues = ["bathroomInt"];
		const petPolicies = [
			"petsAllowed",
			"petsRequest",
			"petsFee",
			"petsFeeFrequency"
		];
		const internetPolicies = [
			"internetIncluded",
			"internet_coverage",
			"internet_type",
			"internetFee",
			"internetFeeFrequency"
		];
		const parkingPolicies = [
			"parkingIncluded",
			"parking_type",
			"parking_reservation",
			"parking_location",
			"parkingFee",
			"parkingFeeFrequency"
		];
		const { data } = this.state;
		let formValidation = JSON.parse(JSON.stringify(this.state.formValidation));

		validationNames.map(name => {
			if (numberValues.includes(name)) {
				if (this.state[name] <= 0) {
					formValidation[name] = true;
				} else {
					formValidation[name] = false;
				}
				// Remove validation for each bedroom that is deleted.
				if (
					this.state.rooms.length !== this.state.formValidation.rooms.length
				) {
					const validationRoomLenght = this.state.formValidation.rooms.length;
					const roomsLenght = this.state.rooms.length;
					const sumRoomValidationLenght = validationRoomLenght - roomsLenght;

					for (let i = 0; i < sumRoomValidationLenght; i++) {
						this.state.formValidation.rooms.pop();
					}
				}
			} else if (name === "address") {
				if (
					!data.location.address.street_address ||
					!data.location.address.city ||
					!data.location.address.province ||
					!data.location.address.country
				) {
					formValidation[name] = true;
				} else {
					formValidation[name] = false;
				}
			} else if (name === "longLatRequired") {
				if (!data.location.geo_code.lat || !data.location.geo_code.lng) {
					formValidation[name] = true;
				} else {
					formValidation[name] = false;
				}
			} else if (name === "street_address") {
				if (!data.location.address.street_address) {
					formValidation[name] = true;
				} else {
					formValidation[name] = false;
				}
			} else if (name === "city") {
				if (!data.location.address.city) {
					formValidation[name] = true;
				} else {
					formValidation[name] = false;
				}
			} else if (name === "country") {
				if (!data.location.address.country) {
					formValidation[name] = true;
				} else {
					formValidation[name] = false;
				}
			} else if (name === "province") {
				if (!data.location.address.province) {
					formValidation[name] = true;
				} else {
					formValidation[name] = false;
				}
			} else if (name === "description" || name === "special_terms") {
				if (!data[name].replace(/<[^>]*>?/gm, "").length) {
					formValidation[name] = true;
				} else {
					formValidation[name] = false;
				}
			} else if (name === "amenities") {
				if (!data[name].toString().length) {
					formValidation[name] = true;
				} else {
					formValidation[name] = false;
				}
			} else if (name === "photos") {
				if (!this.state.photos.length) {
					formValidation[name] = true;
				} else {
					formValidation[name] = false;
				}
			} else if (name === "photoFileType" || name === "photoFileSize") {
				formValidation[name] = false;
			} else if (name === "rooms" || name === "studioRoom") {
				if (this.state[name].length > 0 && this.state.status.published) {
					this.state[name].map((room, i) => {
						const roomType = [
							"King Bed",
							"Queen Bed",
							"Double Bed",
							"Twin Bed",
							"Sofa Bed",
							"Air Mattress",
							"Bunk Bed"
						];
						let count = 0;

						roomType.map(type => {
							if (room && room[`${type}`] && parseInt(room[`${type}`]) > 0) {
								count++;
							}
						});
						if (count > 0) {
							formValidation[name][i] = false;
						} else {
							formValidation[name][i] = true;
						}
					});
				} else {
					formValidation[name] = [];
				}
			} else if (name === "commonRooms") {
				if (this.state.commonRooms.length > 0 && this.state.status.published) {
					this.state.commonRooms.map((room, i) => {
						// CHECK FOR COMMON ROOM TYPE ERRORS
						if (!room.roomName.length) {
							formValidation[name][i] = {
								...formValidation[name][i],
								commonAreaTypeError: true
							};
						} else {
							formValidation[name][i] = {
								...formValidation[name][i],
								commonAreaTypeError: false
							};
						}
						// CHECK FOR COMMON ROOM BED ERRORS
						const roomType = [
							"King Bed",
							"Queen Bed",
							"Double Bed",
							"Twin Bed",
							"Sofa Bed",
							"Air Mattress",
							"Bunk Bed"
						];
						let count = 0;

						roomType.map(type => {
							if (room && room[`${type}`] && parseInt(room[`${type}`]) > 0) {
								count++;
							}
						});
						if (count > 0) {
							formValidation[name][i] = {
								...formValidation[name][i],
								commonAreaRoomError: false
							};
						} else {
							formValidation[name][i] = {
								...formValidation[name][i],
								commonAreaRoomError: true
							};
						}
					});
				} else {
					formValidation[name] = [];
				}
				// CHECK FOR DUPLICATE COMMON ROOM TYPE
				if (this.state.commonRooms.length > 1) {
					let duplicateCommonRooms = this.checkDuplicate(
						this.state.commonRooms,
						"roomName"
					);

					if (
						Object.keys(duplicateCommonRooms).length &&
						Object.keys(duplicateCommonRooms)[0] !== ""
					) {
						formValidation.duplicateCommonRooms = true;
					} else {
						formValidation.duplicateCommonRooms = false;
					}
				}
			}
			// LICENSE VALIDATIONS
			else if (name === "licenses") {
				if (this.state.data.licenses.length) {
					this.state.data.licenses.map((license, i) => {
						if (!license.type) {
							formValidation[name][i] = {
								...formValidation[name][i],
								licenseTypeError: true
							};
						} else {
							formValidation[name][i] = {
								...formValidation[name][i],
								licenseTypeError: false
							};

							if (!this.state.propertyExemption) {
								formValidation.propertyExemption = true;
							} else {
								formValidation.propertyExemption = false;

								if (this.state.propertyExemption === "licenseId") {
									if (!license.id) {
										formValidation[name][i] = {
											...formValidation[name][i],
											licenseIdError: true
										};
									} else {
										formValidation[name][i] = {
											...formValidation[name][i],
											licenseIdError: false
										};
									}
								} else {
									formValidation[name][i] = {
										...formValidation[name][i],
										licenseIdError: false
									};
								}
								// EXEMPTION REASON VALIDATION
								if (license.exempt === true) {
									if (!license.exemption_reason) {
										formValidation.exemption_reason = true;
									} else {
										formValidation.exemption_reason = false;
									}
								} else {
									formValidation.exemption_reason = false;
								}
							}
						}
					});
				} else {
					formValidation[name] = [];
					formValidation.exemption_reason = false;
				}
				// CHECK FOR DUPLICATE LICENSES
				if (this.state.data.licenses.length) {
					let duplicateLicenses = this.checkDuplicate(
						this.state.data.licenses,
						"type"
					);

					this.setState({
						duplicateLicenses: duplicateLicenses
					});

					if (Object.keys(duplicateLicenses).length) {
						Object.keys(duplicateLicenses).map(duplicate => {
							if (duplicate !== "") {
								formValidation.duplicateLicenses = true;
							} else {
								formValidation.duplicateLicenses = false;
							}
						});
					} else {
						formValidation.duplicateLicenses = false;
					}
				}
			}

			// PET POLICTY VALIDATION
			else if (petPolicies.includes(name)) {
				const petPolicies =
					this.state.policies &&
					this.state.policies.pets &&
					this.state.policies.pets.restrictions &&
					this.state.policies.pets.restrictions[0];

				if (name === "petsAllowed") {
					if (!this.state.petsAllowed) {
						formValidation[name] = true;
					} else {
						formValidation[name] = false;
					}
					if (
						this.state.petsAllowed === "yes" ||
						this.state.petsAllowed === "charges may apply" ||
						this.state.petsAllowed === "fee applies"
					) {
						if (!this.state.data.amenities.includes("Pets OK")) {
							formValidation.petsAmenityRequired = true;
						} else {
							formValidation.petsAmenityRequired = false;
						}
					} else if (this.state.petsAllowed === "no") {
						if (this.state.data.amenities.includes("Pets OK")) {
							formValidation.petsAmenityRequired = true;
						} else {
							formValidation.petsAmenityRequired = false;
						}
					}
				} else if (name === "petsRequest") {
					if (
						petPolicies &&
						this.state.policies.pets.restrictions[0].meta &&
						!this.state.policies.pets.restrictions[0].meta.pets_permit &&
						(this.state.petsAllowed === "yes" ||
							this.state.petsAllowed === "charges may apply" ||
							this.state.petsAllowed === "fee applies")
					) {
						formValidation[name] = true;
					} else {
						formValidation[name] = false;
					}
				} else if (name === "petsFee") {
					if (
						((petPolicies &&
							this.state.policies.pets.restrictions[0].adjustment ===
								undefined) ||
							(petPolicies &&
								this.state.policies.pets.restrictions[0].adjustment &&
								!this.state.policies.pets.restrictions[0].adjustment.amount.toString()
									.length)) &&
						this.state.petsAllowed === "fee applies"
					) {
						formValidation[name] = true;
					} else {
						formValidation[name] = false;
					}
				} else if (name === "petsFeeFrequency") {
					if (
						((petPolicies &&
							this.state.policies.pets.restrictions[0].duration ===
								undefined) ||
							(petPolicies &&
								this.state.policies.pets.restrictions[0].duration &&
								!this.state.policies.pets.restrictions[0].duration
									.duration_per)) &&
						this.state.petsAllowed === "fee applies"
					) {
						formValidation[name] = true;
					} else {
						formValidation[name] = false;
					}
				}
			}
			// Internet Policy Validation
			else if (internetPolicies.includes(name)) {
				const internetPolicies =
					this.state.policies &&
					this.state.policies.internet &&
					this.state.policies.internet.restrictions &&
					this.state.policies.internet.restrictions[0];

				if (name === "internetIncluded") {
					if (!this.state.internetIncluded) {
						formValidation[name] = true;
					} else {
						formValidation[name] = false;
					}
					if (
						this.state.internetIncluded === "yes" ||
						this.state.internetIncluded === "charges may apply" ||
						this.state.internetIncluded === "fee applies"
					) {
						if (
							!this.state.data.amenities.includes("Internet") &&
							!this.state.data.amenities.includes("Internet -- Wireless")
						) {
							formValidation.internetAmenityRequired = true;
						} else {
							formValidation.internetAmenityRequired = false;
						}
					} else if (this.state.internetIncluded === "no") {
						if (
							this.state.data.amenities.includes("Internet") ||
							this.state.data.amenities.includes("Internet -- Wireless")
						) {
							formValidation.internetAmenityRequired = true;
						} else {
							formValidation.internetAmenityRequired = false;
						}
					}
				} else if (name === "internet_type") {
					if (
						internetPolicies &&
						!this.state.policies.internet.restrictions[0].meta.internet_type &&
						(this.state.internetIncluded === "yes" ||
							this.state.internetIncluded === "charges may apply" ||
							this.state.internetIncluded === "fee applies")
					) {
						formValidation[name] = true;
					} else {
						formValidation[name] = false;
					}
				} else if (name === "internet_coverage") {
					if (
						internetPolicies &&
						!this.state.policies.internet.restrictions[0].meta
							.internet_coverage &&
						(this.state.internetIncluded === "yes" ||
							this.state.internetIncluded === "charges may apply" ||
							this.state.internetIncluded === "fee applies")
					) {
						formValidation[name] = true;
					} else {
						formValidation[name] = false;
					}
				} else if (name === "internetFee") {
					if (
						((internetPolicies &&
							this.state.policies.internet.restrictions[0].adjustment ===
								undefined) ||
							(internetPolicies &&
								this.state.policies.internet.restrictions[0].adjustment &&
								!this.state.policies.internet.restrictions[0].adjustment.amount.toString()
									.length)) &&
						this.state.internetIncluded === "fee applies"
					) {
						formValidation[name] = true;
					} else {
						formValidation[name] = false;
					}
				} else if (name === "internetFeeFrequency") {
					if (
						((internetPolicies &&
							this.state.policies.internet.restrictions[0].duration ===
								undefined) ||
							(internetPolicies &&
								this.state.policies.internet.restrictions[0].duration &&
								!this.state.policies.internet.restrictions[0].duration
									.duration_per)) &&
						this.state.internetIncluded === "fee applies"
					) {
						formValidation[name] = true;
					} else {
						formValidation[name] = false;
					}
				}
			}
			// Parking Policy Validation
			else if (parkingPolicies.includes(name)) {
				const parkingPolicies =
					this.state.policies &&
					this.state.policies.parking &&
					this.state.policies.parking.restrictions &&
					this.state.policies.parking.restrictions[0];

				if (name === "parkingIncluded") {
					if (!this.state.parkingIncluded) {
						formValidation[name] = true;
					} else {
						formValidation[name] = false;
					}
					if (this.state.parkingIncluded === "yes") {
						if (!this.state.data.amenities.includes("Parking -- Free")) {
							formValidation.parkingAmenityRequired = true;
						} else {
							formValidation.parkingAmenityRequired = false;
						}
					} else if (
						this.state.parkingIncluded === "charges may apply" ||
						this.state.parkingIncluded === "charges may apply" ||
						this.state.parkingIncluded === "fee applies"
					) {
						if (
							!this.state.data.amenities.includes("Parking") &&
							!this.state.data.amenities.includes("Parking -- Covered") &&
							!this.state.data.amenities.includes("Parking -- Off Street") &&
							!this.state.data.amenities.includes("Parking -- On Street") &&
							!this.state.data.amenities.includes("Parking -- RV")
						) {
							formValidation.parkingAmenityRequired = true;
						} else {
							formValidation.parkingAmenityRequired = false;
						}
					} else if (this.state.parkingIncluded === "no") {
						if (
							this.state.data.amenities.includes("Parking -- Free") ||
							this.state.data.amenities.includes("Parking") ||
							this.state.data.amenities.includes("Parking -- Covered") ||
							this.state.data.amenities.includes("Parking -- Off Street") ||
							this.state.data.amenities.includes("Parking -- On Street") ||
							this.state.data.amenities.includes("Parking -- RV")
						) {
							formValidation.parkingAmenityRequired = true;
						} else {
							formValidation.parkingAmenityRequired = false;
						}
					}
				} else if (name === "parking_type") {
					if (
						parkingPolicies &&
						!this.state.policies.parking.restrictions[0].meta.parking_type &&
						(this.state.parkingIncluded === "yes" ||
							this.state.parkingIncluded === "charges may apply" ||
							this.state.parkingIncluded === "fee applies")
					) {
						formValidation[name] = true;
					} else {
						formValidation[name] = false;
					}
				} else if (name === "parking_location") {
					if (
						parkingPolicies &&
						!this.state.policies.parking.restrictions[0].meta
							.parking_location &&
						(this.state.parkingIncluded === "yes" ||
							this.state.parkingIncluded === "charges may apply" ||
							this.state.parkingIncluded === "fee applies")
					) {
						formValidation[name] = true;
					} else {
						formValidation[name] = false;
					}
				} else if (name === "parking_reservation") {
					if (
						parkingPolicies &&
						!this.state.policies.parking.restrictions[0].meta
							.parking_reservation &&
						(this.state.parkingIncluded === "yes" ||
							this.state.parkingIncluded === "charges may apply" ||
							this.state.parkingIncluded === "fee applies")
					) {
						formValidation[name] = true;
					} else {
						formValidation[name] = false;
					}
				} else if (name === "parkingFee") {
					if (
						((parkingPolicies &&
							this.state.policies.parking.restrictions[0].adjustment ===
								undefined) ||
							(parkingPolicies &&
								this.state.policies.parking.restrictions[0].adjustment &&
								!this.state.policies.parking.restrictions[0].adjustment.amount.toString()
									.length)) &&
						this.state.parkingIncluded === "fee applies"
					) {
						formValidation[name] = true;
					} else {
						formValidation[name] = false;
					}
				} else if (name === "parkingFeeFrequency") {
					if (
						((parkingPolicies &&
							this.state.policies.parking.restrictions[0].duration ===
								undefined) ||
							(parkingPolicies &&
								this.state.policies.parking.restrictions[0].duration &&
								!this.state.policies.parking.restrictions[0].duration
									.duration_per)) &&
						this.state.parkingIncluded === "fee applies"
					) {
						formValidation[name] = true;
					} else {
						formValidation[name] = false;
					}
				}
			}
			// CALENDAR VALIDATION
			else if (name === "calendarUrl") {
				if (
					!this.state.calendar.url &&
					this.state.calendarSource !== "racalendar"
				) {
					formValidation.calendarUrl = true;
				} else {
					formValidation.calendarUrl = false;
				}
			} else if (name === "use_checkout_date") {
				if (
					!this.state.calendar.use_checkout_date &&
					this.state.calendarSource !== "racalendar"
				) {
					formValidation.use_checkout_date = true;
				} else {
					formValidation.use_checkout_date = false;
				}
			}
			// FRANCE VALIDATION
			else if (name === "residency_category") {
				if (!this.state.data.residency_category) {
					formValidation.residency_category = true;
				} else {
					formValidation.residency_category = false;
				}
			} else {
				if (!data[name].toString().length) {
					formValidation[name] = true;
				} else {
					formValidation[name] = false;
				}
			}
		});
		return formValidation;
	};

	handleBedoomChange = e => {
		let eventValue = e.target.value === "studio" ? 0 : e.target.value;
		let roomsLength = this.state.rooms.length;
		let roomValidations = [...this.state.formValidation.rooms];

		if (eventValue == 0) {
			// Add Studio Room
			this.state.studioRoom.push({
				roomName: "Studio Room"
			});
			// Remove Rooms
			let newEventAmount = roomsLength - eventValue;

			for (let i = 0; i < newEventAmount; i++) {
				this.state.rooms.pop();
				roomValidations.pop();
			}
			// Remove Common Area Rooms
			this.state.commonRooms = [];
		} else if (eventValue > roomsLength) {
			// Add Rooms
			let newEventAmount = eventValue - roomsLength;
			let newRoomsLength = this.state.rooms.length;

			for (let i = 0; i < newEventAmount; i++) {
				this.state.rooms.push({
					roomName: `Bedroom ${newRoomsLength + 1}`
				});
				newRoomsLength++;
			}
			// Remove Studio Room
			if (this.state.studioRoom.length) {
				this.state.studioRoom.pop();
			}
		} else {
			// Remove Rooms
			let newEventAmount = roomsLength - eventValue;

			for (let i = 0; i < newEventAmount; i++) {
				this.state.rooms.pop();
				roomValidations.pop();
			}
		}
		this.setState({
			numberOfBedrooms: eventValue,
			data: {
				...this.state.data,
				bedrooms: eventValue == 0 ? "studio" : eventValue
			},
			studioRoom: [...this.state.studioRoom],
			rooms: [...this.state.rooms],
			commonRooms: [...this.state.commonRooms],
			formValidation: {
				...this.state.formValidation,
				rooms: roomValidations
			}
		});
	};

	handleRoomChange = (e, index, roomType) => {
		let name = e.target.name;
		let value = parseInt(e.target.value);

		let rooms = JSON.parse(JSON.stringify(this.state[roomType]));

		rooms.splice(index, 1, { ...this.state[roomType][index], [name]: value });

		this.setState(
			{
				[roomType]: rooms
			},
			() => {
				const { formValidation } = this.state;
				let roomsValidations = [];

				if (roomType !== "commonRooms") {
					roomsValidations = formValidation[roomType].filter(room => {
						return room === true;
					});
				} else {
					formValidation["commonRooms"].map(rooms => {
						Object.values(rooms).map(room => {
							if (room === true) {
								roomsValidations.push(room);
							}
						});
					});
				}

				if (roomsValidations.length) {
					let formValidation = this.formValidationHandler([roomType]);

					this.setState({
						formValidation: formValidation
					});
				}
			}
		);
	};

	/**
	 * Handle pricing change on properties form.
	 * @param {*} event
	 */
	handlePricingChange = e => {
		const name = e.target.name;
		let value = e.target.value;

		if (value < 0) {
			value = 0;
		}

		this.setState({
			price: {
				...this.state.price,
				[name]: value
			}
		});
	};

	/**
	 * Handle RTE event changes.
	 */
	handleRTEChange = (value, name) => {
		if (name === "RTE_description") {
			this.setState(
				{
					[name]: value,
					data: {
						...this.state.data,
						description: value.toString("html")
					}
				},
				() => {
					if (
						this.state.data.description.replace(/<[^>]*>?/gm, "").length <= 1 &&
						this.state.formValidation.description
					) {
						let formValidation = this.formValidationHandler(["description"]);

						this.setState({
							formValidation: formValidation
						});
					}
				}
			);
		} else if (name === "RTE_special_terms") {
			this.setState({
				[name]: value,
				data: {
					...this.state.data,
					special_terms: value.toString("html")
				}
			});
		}
	};

	/**
	 * Call when you select any of amenities and change background color
	 * @param {*} type
	 * @param {string} value name of amenity.
	 * @param {boolean} isChecked value tru or false
	 */

	toggleAmenities = (type, value, isChecked) => {
		let copyAmenities = [...this.state.data.amenities];

		if (isChecked) {
			copyAmenities.push(value);
		} else {
			copyAmenities = copyAmenities.filter(amenitie => {
				return amenitie !== value;
			});
		}
		this.setState(
			{
				data: {
					...this.state.data,
					amenities: copyAmenities
				}
			},
			() => {
				// CHECK LICENSE AMENITIES VALIDATIONS
				if (
					this.state.formValidation.amenities ||
					this.state.formValidation.petsAmenityRequired ||
					this.state.formValidation.internetAmenityRequired ||
					this.state.formValidation.parkingAmenityRequired ||
					this.state.petsAllowed ||
					this.state.internetIncluded ||
					this.state.parkingIncluded
				) {
					let formValidationType = [];
					const licenseValidation = [
						"petsAllowed",
						"internetIncluded",
						"parkingIncluded"
					];

					if (this.state.formValidation.amenities) {
						formValidationType.push("amenities");
					}

					licenseValidation.map(license => {
						if (this.state[license].length) {
							formValidationType.push(license);
						}
					});

					let formValidation = this.formValidationHandler(formValidationType);

					this.setState({
						formValidation: formValidation
					});
				}
			}
		);
	};

	handleCiCoOnChange = (time, type) => {
		const { cico_times } = this.state;

		this.setState({
			cico_times: {
				...cico_times,
				[type]: time
			}
		});
	};

	/**
	 * Show large map modal on proeprties for page.
	 * @param {*} id
	 * @param {*} data
	 */
	showModal = (id, data) => {
		id = id || "map";
		data = data || {};
		this.setState({
			showModal: id,
			modalData: data
		});
	};

	/**
	 * Close a submit modal and reset values.
	 */
	closeSubmitModal = () => {
		this.setState(
			{
				showValidationModalError: false
			},
			() => {
				this.closeModal();
			}
		);
	};

	/**
	 * For close an any modal.
	 */
	closeModal = () => {
		this.setState({ showModal: null });
	};

	/**
	 * Invoke when select any image.
	 * @param {*} imgIndex
	 */
	selectImage = imgIndex => {
		let selectedPictures = this.state.selectedPictures;
		let pictures = this.state.photos || [];

		if (selectedPictures.length === 0) {
			selectedPictures = [];
		}

		if (selectedPictures.indexOf(imgIndex) < 0) {
			selectedPictures.push(imgIndex);
		} else {
			selectedPictures.splice(selectedPictures.indexOf(imgIndex), 1);
		}
		this.setState({
			selectedPictures: selectedPictures,
			selectAllImages:
				selectedPictures.length === this.state.photos.length ? true : false
		});
	};

	/***
	 * Function will invoke when click on delete selected images and it will delete images which are selected.
	 */
	deleteSelectedImages = () => {
		let selectedPictures = this.state.selectedPictures;
		let pictures = Object.assign([], this.state.photos);
		let deletedPhotos = Object.assign([], this.state.deletedPhotos);
		pictures = this.reorderImages(
			pictures.filter((pic, i) => {
				if (selectedPictures.indexOf(i) >= 0) {
					if (pic.photo_id || pic.status === "failure") {
						deletedPhotos.push(pic);
					}
					return false;
				}
				return true;
			})
		);
		this.setState(
			{
				photos: pictures,
				selectedPictures: [],
				selectAllImages: false,
				deletedPhotos: deletedPhotos
			},
			() => {
				if (this.state.formValidation.photos) {
					let formValidation = this.formValidationHandler(["photos"]);

					this.setState({
						formValidation: formValidation
					});
				}
			}
		);
	};

	/**
	 * Deselect single image which is selected.
	 */
	cancelSelectedImages = () => {
		this.setState({
			selectedPictures: [],
			selectAllImages: false
		});
	};

	/**
	 * Deselect all images which are selected.
	 */
	toggleSelectAllImages = e => {
		let temp = [];
		let isChecked = false;
		let selectedPictures = this.state.selectedPictures;
		let pictures = this.state.photos;
		if (pictures.length > 0) {
			if (selectedPictures.length !== pictures.length) {
				for (let i = 0; i < pictures.length; i++) {
					temp.push(i);
				}
				isChecked = true;
			}
			this.setState({
				selectedPictures: temp,
				selectAllImages: temp.length === pictures.length ? true : false
			});
		}
	};

	//Reorder array by swapping the items based on source and target
	swapArray = (arr, source, target) => {
		if (!arr || !arr.length) {
			return [];
		}
		try {
			arr.splice(target, 0, arr.splice(source, 1)[0]);
			return arr;
		} catch (e) {
			return arr;
		}
	};

	/**
	 * For Swap images invoke when any drag event occur in image box on properties form.
	 * @param {*} source
	 * @param {*} target
	 */
	swapImages = (source, target) => {
		let photos = this.state.photos;
		let selectedPictures = this.state.selectedPictures;

		if (
			selectedPictures.indexOf(source) >= 0 &&
			selectedPictures.indexOf(target) < 0
		) {
			selectedPictures[selectedPictures.indexOf(source)] = target;
		}
		let swappedPhotos = this.reorderImages(
			this.swapArray(photos, source, target)
		);
		this.setState({
			photos: swappedPhotos,
			selectedPictures
		});
	};

	/**
	 * Reassign order of the images
	 * @param {Array} imageList
	 * @returns {Array}
	 *
	 */
	reorderImages = imageList => {
		return imageList.map((img, i) => {
			img.order = i;
			return img;
		});
	};

	updateImage = (caption, index, newImage, blob, updateImage) => {
		let photos = [...this.state.photos];
		let deletedPhotosCopy = [...this.state.deletedPhotos];
		let newPhoto = {
			...photos[index],
			title: caption
		};

		if (updateImage && photos[index]) {
			newPhoto = {
				...newPhoto,
				data: newImage.split(",")[1],
				url: newImage,
				data_type: "png",
				file: blob,
				updated: true
			};

			deletedPhotosCopy.push(photos[index]);
		}

		photos.splice(index, 1, newPhoto);

		this.setState({
			photos: photos,
			deletedPhotos: deletedPhotosCopy
		});
	};

	/**
	 * Update types of places like bedrooms and living room and all.
	 * @param {*} typesOfPlaces
	 */
	updateTypesOfPlaces = typesOfPlaces => {
		this.state.typesOfPlaces = typesOfPlaces;
	};

	/**
	 * Update selected room configuration.
	 * @param {*} rooms room data
	 */
	updateRoomConfig = () => {
		let roomsConfig = [];
		let concatRooms = [
			...this.state.rooms,
			...this.state.commonRooms,
			...this.state.studioRoom
		];

		concatRooms.forEach(function(room) {
			let beds = [];
			let bedsList = Object.keys(room);
			bedsList.splice(bedsList.indexOf("roomName"), 1);

			bedsList.forEach(function(bed) {
				for (let i = 0; i < room[bed]; i++) {
					beds.push(bed);
				}
			});

			roomsConfig.push({
				name: room.roomName,
				beds: beds
			});
		}, this);

		return roomsConfig;
	};

	handleModal = (e, name) => {
		e.preventDefault();

		this.setState({
			modal: name
		});
	};

	/**
	 * Upload photos in edit or add property or room type.
	 * @param {*} id id of current property.
	 * @param {*} photos the array of photos.
	 */
	uploadPhotos = async (id, photos) => {
		let token = {};

		// Upload Photos
		if (photos.length) {
			this.setState({
				imageStatusMessage: "Uploading"
			});

			try {
				for (let i = 0; i < photos.length; i++) {
					let storeImageSuccess = false;

					token = await API.post("rapapi", `/properties/${id}/imagetokens`, {
						body: { title: photos[i].title || "" }
					});

					if (token && token.status) {
						let upload_url = token.upload_url.split("s3://")[1];
						let bucket = upload_url.substr(0, upload_url.indexOf("/"));

						Storage.configure({
							bucket: bucket,
							identityPoolId: constants.COGNITO.IdentityPoolId,
							region: constants.COGNITO.Region
						});

						while (!storeImageSuccess) {
							try {
								await Storage.put(`${token.token_id}`, photos[i].file, {
									contentType: `image/${photos[i].data_type}`,
									customPrefix: {
										public: `${id}/public/`
									}
								});

								storeImageSuccess = true;

								this.setState({
									submitImageStatus: this.state.submitImageStatus + 1,
									submitStatus: this.state.submitStatus + 1
								});
							} catch (e) {
								console.log(e, "Error Storing Image to S3");
								storeImageSuccess = true;
								const reader = new FileReader();

								reader.addEventListener("loadend", () => {
									let result = reader.result;
									let errorMessage = result.includes("RequestTimeTooSkewed")
										? "Image upload failed. The difference between the request time and the current time is too large."
										: result;

									if (result) {
										this.setState({
											submitImageStatus: this.state.submitImageStatus + 1,
											submitStatus: this.state.submitStatus + 1,
											error: {
												...this.state.error,
												show: true,
												detail: errorMessage
											}
										});
									}
								});
								reader.readAsText(e && e.response && e.response.data);
							}
						}
					}
					await delay(1500);
				}
			} catch (e) {
				this.setState({
					submitStatus: this.state.submitStatus + 1
				});
				e.errorType = "image";

				this.failed(e);
			}

			this.setState({
				imageStatusMessage: ""
			});
		}
	};

	checkPhotoStatus = async data => {
		let photoProcessing = [];
		let processing = false;
		let newPhotos = [...data.photos];

		photoProcessing = newPhotos.filter(photo => {
			return photo.status == "processing";
		});

		processing = photoProcessing.length ? true : false;

		this.setState(
			{
				photos: newPhotos,
				newPhotos: newPhotos ? JSON.parse(JSON.stringify(newPhotos)) : []
			},
			() => {
				this.setState({
					initialStateModel: {
						...this.state.initialStateModel,
						photos: [...this.state.photos]
					}
				});
			}
		);

		if (photoProcessing.length) {
			this.setState({
				photoIsProcessing: true
			});

			while (processing && this._isMounted) {
				await delay(5000);

				try {
					await this.props.fetchPropertyDetail();

					newPhotos = this.props.propertyData.photos;

					photoProcessing = this.props.propertyData.photos.filter(photo => {
						return photo.status == "processing";
					});

					processing = photoProcessing.length ? true : false;
				} catch (e) {
					console.log(e);
					processing = false;
				}
				this.setState(
					{
						photos: this.props.propertyData.photos,
						newPhotos: this.props.propertyData.photos
							? JSON.parse(JSON.stringify(this.props.propertyData.photos))
							: [],
						photoIsProcessing: processing
					},
					() => {
						this.setState({
							initialStateModel: {
								...this.state.initialStateModel,
								photos: [...this.state.photos]
							}
						});
					}
				);
			}
		} else {
			this.setState(
				{
					photos: newPhotos,
					newPhotos: newPhotos ? JSON.parse(JSON.stringify(newPhotos)) : [],
					photoIsProcessing: false,
					processingPhotosStatus: 0
				},
				() => {
					this.setState({
						initialStateModel: {
							...this.state.initialStateModel,
							photos: [...this.state.photos]
						}
					});
				}
			);
		}
	};

	/**
	 * Use for delete photos from server side.
	 * @param {*} id
	 * @param {*} photoIds
	 */
	deletePhotos = async (id, photoIds) => {
		if (photoIds && photoIds.length > 0) {
			this.setState({
				imageStatusMessage: "Deleting Images"
			});

			for (let i = 0; i < photoIds.length; i++) {
				if (photoIds[i].status === "failure") {
					try {
						await API.del(
							"rapapi",
							`/imagetokens/${photoIds[i].status_token_id}`
						);
						this.setState({
							submitStatus: this.state.submitStatus + 1
						});
					} catch (e) {
						console.log(e, "Error deleting failed photos");
						this.setState({
							submitStatus: this.state.submitStatus + 1
						});
					}
				} else {
					try {
						await API.del(
							"rapapi",
							"/properties/" + id + "/photos/" + photoIds[i].photo_id
						);
						this.setState({
							submitStatus: this.state.submitStatus + 1
						});
					} catch (e) {
						console.log(e, "Error deleting photos");
						this.setState({
							submitStatus: this.state.submitStatus + 1
						});
					}
				}
			}
		}
	};

	// Reason Notes change handler
	handleReasonNotesChange = async e => {
		let status = this.state.status.published;
		let note = "";

		this.setState({
			status: {
				...this.state.status,
				notes: note
			}
		});

		if (!status) {
			// If input field has value
			if (e.currentTarget.dataset.notLiveReason) {
				if (e.currentTarget.dataset.notLiveReason === "Other") {
					this.setState({
						showOtherField: true
					});
				} else {
					this.setState({
						showOtherField: false
					});
					// If input field has value
					note = e.currentTarget.dataset.notLiveReason;
				}
			} else if (e.target.value) {
				note = e.target.value;
			}
		}

		this.setState({
			status: {
				...this.state.status,
				notes: note !== "Other" && note.length ? note : NotLiveDefaultNote
			}
		});
	};

	/**
	 * Toggle status of property published or not.
	 */
	toggleStatus = async () => {
		let status = this.state.status.published;

		status = !status;

		this.setState(
			{
				status: {
					published: status,
					bookable: this.state.status.bookable,
					open: this.state.status.open
				}
			},
			() => {
				this.setState({
					formValidation: {
						...this.state.formValidation,
						address: false,
						longLatRequired: false,
						street_address: false,
						city: false,
						country: false,
						province: false,
						description: false,
						kitchen: false,
						sleep_max: false,
						photos: false,
						amenities: false,
						bathroomInt: false,
						residency_category: false
					},
					licenseLocationRequired: {
						...this.state.formValidation.licenseLocationRequired,
						isRequired: false,
						message: ""
					}
				});
			}
		);
	};

	/**
	 * Will fetch all uploaded photos.
	 * @param {*} photos
	 */
	getUploadedPhotos = photos => {
		let uploadedPhotos = [];
		let updatedPhotos = [];

		if (!photos || photos.length < 0) {
			return [];
		}

		photos.map((photo, index) => {
			if (photo.photo_id && photo.file) {
				updatedPhotos.push({ ...photo });
			}

			if (
				(!photo.photo_id && !photo.status) ||
				(photo.photo_id && photo.file)
			) {
				uploadedPhotos.push({ ...photo, index: index });
			}
		});

		return {
			uploadedPhotos,
			updatedPhotos
		};
	};

	/**
	 * Invoke function by clicking on save button photo submitting is one of process of submitting an picture.
	 * @param {*} property_id
	 */
	submitPhotos = async property_id => {
		let addedPhotos = this.getUploadedPhotos(this.state.photos);
		let deletedPhotosCopy = [...this.state.deletedPhotos];

		if (
			(addedPhotos &&
				addedPhotos.uploadedPhotos &&
				addedPhotos.uploadedPhotos.length) ||
			deletedPhotosCopy.length
		) {
			if (deletedPhotosCopy && deletedPhotosCopy.length > 0) {
				this.setState({
					progressMessage: "Deleting Photos..."
				});
				await this.deletePhotos(property_id, deletedPhotosCopy);
			}
			if (
				addedPhotos &&
				addedPhotos.uploadedPhotos &&
				addedPhotos.uploadedPhotos.length
			) {
				this.setState({
					progressMessage: "Saving Photos..."
				});
				await this.uploadPhotos(property_id, addedPhotos.uploadedPhotos);
			}
		}
	};

	/**
	 * For hide discard current data confirmation modal. Just after API call is successful once.
	 */
	triggerRedirect = () => {
		setTimeout(function() {
			this.setState({
				success: true
			});
		}, 2000);
	};

	/**
	 * Handle response error.
	 * @param {*} response response of API
	 * @param {*} id
	 * @param {*} status status of API call
	 */
	handleResponse = response => {
		this.setState({
			submitStatus: this.state.submitStatus + 1
		});
		if (response && response.error) {
			this.failed(response);
		}
	};

	/**
	 * reset progress status of progress modal.
	 */
	resetStatus = () => {
		this.setState({
			submitStatus: 0,
			submitImageStatus: 0,
			error: {
				show: false,
				message: "",
				detail: ""
			},
			progressMessage: "",
			imageStatusMessage: "",
			submitModalPhotos: [],
			submitModalDeletedPhotos: [],
			showValidationModalError: false
		});
	};

	/**
	 * For add new property and update a property.
	 */
	addProperty = async () => {
		this.resetStatus();

		if (
			(this.state.initialStateModel.status.published == false &&
				this.state.status.published == true &&
				this.state.price.tax_percent == 0 &&
				this.state.acceptTaxCleaning == false) ||
			(this.state.initialStateModel.status.published == false &&
				this.state.status.published == true &&
				this.state.price.cleaning_fee == 0 &&
				this.state.acceptTaxCleaning == false)
		) {
			this.setState({
				showModal: "tax-cleaning-prompt"
			});
		} else if (
			this.state.initialStateModel.status.published == true &&
			this.state.status.published == false &&
			!this.isInternalPropertyAdmin
		) {
			this.setState({
				showModal: "unpublish-prompt"
			});
		} else {
			const { price } = this.state;
			let data = JSON.parse(JSON.stringify(this.state.data));
			let validationsResponse = [];
			let requiredValidations = [
				"title",
				"commonRooms",
				"rooms",
				"studioRoom",
				"petsRequest",
				"petsFee",
				"petsFeeFrequency",
				"internet_type",
				"internet_coverage",
				"internetFee",
				"internetFeeFrequency",
				"parking_type",
				"parking_location",
				"parking_reservation",
				"parkingFee",
				"parkingFeeFrequency",
				"licenses",
				"calendarUrl",
				"use_checkout_date",
				"photoFileType",
				"photoFileSize"
			];
			let apiValidationError = {};
			let redawningFatalError = false;

			if (this.state.mode === "create") {
				requiredValidations = [...requiredValidations, "property_type"];
			}

			// ** SETTING PROPERTY LIVE VALIDATIONS **
			if (this.state.mode === "edit" && this.state.status.published === true) {
				this.setState({
					validationIsLoading: true
				});

				requiredValidations = [...requiredValidations, "property_type"];

				validationsResponse = await this.getValidation({
					run_validations: true
				});

				if (validationsResponse.validationData.length) {
					// CHECK BACK-END VALIDATION
					let severities = [];

					validationsResponse.validationData.map(validation => {
						if (validation.severity_property) {
							if (validation.description === "Organization is inactive") {
								validation.description =
									"This property cannot be set live because the organization is not live. Please contact Customer Support.";
							}
							severities.push(validation);
						}
					});

					apiValidationError = this.apiLicenseValidationHandler(severities);

					redawningFatalError = apiValidationError.redawningFatalError;

					// COMBINE REQUIRED VALIDATIONS AND LIVE REQUIRED VALIDATIONS
					requiredValidations = [
						...requiredValidations,
						...apiValidationError.requiredValidations
					];

					this.setState({
						showValidations:
							(this.state.initialStateModel.status.published == false &&
								this.state.status.published == true &&
								redawningFatalError) ||
							apiValidationError.requiredValidations.length
								? true
								: false,
						validationData: validationsResponse.validationData,
						validationChannels: validationsResponse.validationChannels,
						redAwningFatalError: validationsResponse.redAwningFatalError,
						showValidationModalError: true,
						validationIsLoading: false
					});
				} else {
					this.setState({
						validationIsLoading: false
					});
				}
			}

			// CHECK FRONT-END VALIDATION
			let checkAllFormValidation = this.formValidationHandler(
				requiredValidations
			);

			this.setState(
				{
					formValidation: checkAllFormValidation,
					licenseLocationRequired: apiValidationError.licenseLocationRequired
				},
				async () => {
					let formValidation = { ...this.state.formValidation };

					if (this.state.licenseLocationRequired) {
						formValidation = {
							...formValidation,
							licenseLocationRequired: this.state.licenseLocationRequired
						};
					}

					if (!_.isEmpty(this.state.seasonalPolicies)) {
						formValidation = {
							...formValidation,
							seasonalPolicies: this.state.seasonalPolicies
						};
					}

					let checkFormValidation = Object.entries(formValidation).filter(
						validation => {
							if (validation[0] === "commonRooms") {
								let commonRoomsError = false;
								for (let i = 0; i < validation[1].length; i++) {
									if (
										validation[1][i].commonAreaRoomError === true ||
										validation[1][i].commonAreaTypeError === true
									) {
										commonRoomsError = true;
									}
								}
								if (commonRoomsError) {
									return validation;
								}
							} else if (
								validation[0] === "rooms" ||
								validation[0] === "studioRoom"
							) {
								let roomsError = false;
								for (let i = 0; i < validation[1].length; i++) {
									if (validation[1].toString().includes("true")) {
										roomsError = true;
									}
								}
								if (roomsError) {
									return validation;
								}
							} else if (validation[0] === "licenses") {
								let licensesError = false;
								for (let i = 0; i < validation[1].length; i++) {
									if (
										validation[1][i].licenseTypeError === true ||
										validation[1][i].licenseIdError === true
									) {
										licensesError = true;
									}
								}
								if (licensesError) {
									return validation;
								}
							} else if (validation[0] === "licenseLocationRequired") {
								return validation[1].isRequired === true;
							} else if (validation[0] === "seasonalPolicies") {
								const checkSeasonalPolicies = [];
								validation[1].map(policy => {
									checkSeasonalPolicies.push(policy.is_valid_dates);
									checkSeasonalPolicies.push(policy.is_valid_policy);
								});

								if (checkSeasonalPolicies.includes(false)) {
									return validation;
								}
							} else {
								return validation[1] === true;
							}
						}
					);

					if (
						!checkFormValidation.length &&
						!this.state.formValidation.duplicateCommonRooms &&
						(this.state.initialStateModel.status.published == false &&
						this.state.status.published == true
							? !redawningFatalError
							: true) &&
						(!this.state.licenseLocationRequired ||
							this.state.licenseLocationRequired.isRequired === false)
					) {
						this.setState({
							showValidations: false
						});

						// STATE - PROVINCE CONFIG
						let stateProvince = [];
						stateProvince = constants.STATES_PROVINCES.filter(value => {
							return (
								value.name == data.location.address.province ||
								value.code == data.location.address.province
							);
						});
						data.location.address.province = stateProvince.length
							? stateProvince[0].abbrev
							: data.location.address.province;

						// SLEEP MAX CONFIGURATIONS
						data.sleep_max = data.sleep_max === 0 ? 1 : data.sleep_max;

						// POOL AMENITIES CONFIGURATIONS
						if (data.amenities) {
							let poolAmenity = data.amenities.filter(val => {
								return val === "Pool -- Heated" ||
									val === "Pool -- Indoor" ||
									val === "Pool -- Private" ||
									val === "Pool -- Shared" ||
									val === "Pool -- Unheated"
									? true
									: false;
							});

							data.pool = poolAmenity.length ? true : false;
						}

						// CHECK IF IMAGES HAS A NEW SEQUENCE
						let newImageSequence = [];

						if (!this.state.photoIsProcessing) {
							newImageSequence = this.state.photos.filter(img => {
								let oldImage = this.state.newPhotos.filter(photo => {
									return photo.photo_id == img.photo_id;
								});
								return (
									img.status !== "failure" &&
									oldImage[0] &&
									JSON.stringify(oldImage[0]) !== JSON.stringify(img)
								);
							});
						}

						// UPDATE ROOM CONFIGURATIONS
						const roomsConfig = this.updateRoomConfig();
						data.room_configurations = roomsConfig;

						let kingBed = 0;
						let queenBed = 0;
						let doubleBed = 0;
						let twinBed = 0;
						let sofaBed = 0;
						let airMattress = 0;
						let bunkBed = 0;

						const combineRooms = [
							...this.state.rooms,
							...this.state.commonRooms,
							...this.state.studioRoom
						];

						combineRooms.map(room => {
							Object.entries(room).map(bed => {
								if (bed[0] === "King Bed") {
									kingBed = kingBed + bed[1];
								}
								if (bed[0] === "Queen Bed") {
									queenBed = queenBed + bed[1];
								}
								if (bed[0] === "Double Bed") {
									doubleBed = doubleBed + bed[1];
								}
								if (bed[0] === "Twin Bed") {
									twinBed = twinBed + bed[1];
								}
								if (bed[0] === "Sofa Bed") {
									sofaBed = sofaBed + bed[1];
								}
								if (bed[0] === "Air Mattress") {
									airMattress = airMattress + bed[1];
								}
								if (bed[0] === "Bunk Bed") {
									bunkBed = bunkBed + bed[1];
								}
							});
						});

						data.beds_king = kingBed;
						data.beds_queen = queenBed;
						data.beds_double = doubleBed;
						data.beds_twin = twinBed;
						data.beds_sofa = sofaBed;
						data.beds_air_mattress = airMattress;
						data.beds_bunk = bunkBed;

						// UPDATE PET POLICIES CONFIGURATIONS
						let petPoliciesCopy = { ...this.state.policies.pets };
						if (
							this.state.petsAllowed !== "fee applies" &&
							typeof petPoliciesCopy.restrictions[0].duration != undefined
						) {
							delete petPoliciesCopy.restrictions[0].duration;
						}

						// BATHROOM CONFIGURATIONS
						if (
							(
								parseFloat(this.state.bathroomInt) +
								parseFloat(this.state.bathroomFloat)
							).toString() !== this.state.data.bathrooms.toString()
						) {
							data.bathrooms =
								parseFloat(this.state.bathroomInt) +
								parseFloat(this.state.bathroomFloat);
						}

						// GEO-CODE CONFIGURATIONS
						data.location.geo_code.lat = parseFloat(data.location.geo_code.lat);
						data.location.geo_code.lng = parseFloat(data.location.geo_code.lng);

						// TAX PERCENT / TOTAL NIGHTLY / TOTAL CLEANING CONFIGURATION
						price.cleaning_fee = !price.cleaning_fee ? 0 : price.cleaning_fee;
						price.tax_percent = !price.tax_percent ? 0 : price.tax_percent;

						// CALENDER SETTINGS
						!isNaN(this.state.booking_lead_hours) ||
						this.state.booking_lead_hours == ""
							? this.state.booking_lead_hours
							: "0";

						// CHECK WHICH DATA HAS BEEN CHANGED
						let submitModalTotal = 1;

						if (this.state.mode === "edit") {
							// ADD +1 FOR VALIDATION API CHECK
							submitModalTotal++;

							if (
								!isEqual(
									this.state.initialStateModel.externalPropertyId,
									this.state.external_property_id
								)
							) {
								submitModalTotal++;
							}
							if (
								!isEqual(this.state.initialStateModel.data, this.state.data) ||
								!isEqual(
									this.state.initialStateModel.studioRoom,
									this.state.data.studioRoom
								)
							) {
								submitModalTotal++;
							}
							if (
								!isEqual(
									this.state.initialStateModel.cicoTimes,
									this.state.cico_times
								)
							) {
								submitModalTotal++;
							}
							if (
								!isEqual(this.state.initialStateModel.price, this.state.price)
							) {
								submitModalTotal++;
							}
							if (
								!isEqual(this.state.initialStateModel.status, this.state.status)
							) {
								submitModalTotal++;
							}
							if (
								!isEqual(
									this.state.initialStateModel.policies.pets,
									this.state.policies.pets
								)
							) {
								submitModalTotal++;
							}
							if (
								!isEqual(
									this.state.initialStateModel.policies.internet,
									this.state.policies.internet
								)
							) {
								submitModalTotal++;
							}
							if (
								!isEqual(
									this.state.initialStateModel.policies.parking,
									this.state.policies.parking
								)
							) {
								submitModalTotal++;
							}
							if (
								!isEqual(
									this.state.initialStateModel.booking_lead_hours,
									this.state.booking_lead_hours
								)
							) {
								submitModalTotal++;
							}
							if (
								!isEqual(
									this.state.initialStateModel.turnover_block,
									this.state.turnover_block
								)
							) {
								submitModalTotal++;
							}
							if (
								!isEqual(
									this.state.initialStateModel.propertyCommData,
									this.state.propertyCommData
								)
							) {
								submitModalTotal++;
							}
							if (
								!isEqual(
									this.state.initialStateModel.calendar,
									this.state.calendar
								)
							) {
								submitModalTotal++;
								submitModalTotal++;
							}
							if (
								!isEqual(
									this.state.initialStateModel.freeCancelPolicy,
									this.state.freeCancelPolicy
								)
							) {
								submitModalTotal++;
							}
							if (
								!isEqual(
									this.state.initialStateModel.seasonalPolicies,
									this.state.seasonalPolicies
								)
							) {
								submitModalTotal++;
							}

							if (newImageSequence.length) {
								submitModalTotal++;
							}
						} else {
							if (
								!isEqual(
									this.state.initialStateModel.booking_lead_hours,
									this.state.booking_lead_hours
								) ||
								this.state.booking_lead_hours != 0
							) {
								submitModalTotal++;
							}
							if (
								!isEqual(
									this.state.initialStateModel.policies.pets,
									this.state.policies.pets
								) ||
								(this.state.policies.pets.id && this.state.mode === "clone")
							) {
								submitModalTotal++;
							}
							if (
								!isEqual(
									this.state.initialStateModel.policies.internet,
									this.state.policies.internet
								) ||
								(this.state.policies.internet.id && this.state.mode === "clone")
							) {
								submitModalTotal++;
							}
							if (
								!isEqual(
									this.state.initialStateModel.policies.parking,
									this.state.policies.parking
								) ||
								(this.state.policies.parking.id && this.state.mode === "clone")
							) {
								submitModalTotal++;
							}
							if (
								!isEqual(
									this.state.initialStateModel.propertyCommData,
									this.state.propertyCommData
								) ||
								(this.state.propertyCommData && this.state.mode === "clone")
							) {
								submitModalTotal++;
							}
							if (
								!isEqual(
									this.state.initialStateModel.turnover_block,
									this.state.turnover_block
								)
							) {
								submitModalTotal++;
							}
							submitModalTotal++;
						}

						// SEND A COPY OF PHOTOS AND DELETED PHOTOS TO SUBMIT MODAL.
						this.setState(
							{
								processingPhotos: !this.state.photoIsProcessing
									? this.state.photos
									: [],
								submitModalDeletedPhotos: !this.state.photoIsProcessing
									? this.state.deletedPhotos
									: [],
								submitModalPhotos: !this.state.photoIsProcessing
									? this.state.photos
									: [],
								submitModalTotal: submitModalTotal,
								progressMessage:
									this.state.mode === "edit" ? "Updating..." : "Saving..."
							},
							() => {
								this.showModal("submit-modal");
							}
						);

						try {
							let myInit = {
								body: {
									organization_name: this.props.org,
									external_property_id: this.state.external_property_id,
									content: data,
									cico_times: this.state.cico_times,
									status: this.state.status,
									price: this.state.price,
									pets: petPoliciesCopy,
									internet: this.state.policies.internet,
									parking: this.state.policies.parking,
									booking_lead_hours: this.state.booking_lead_hours,
									calendar: this.state.calendar
								}
							};
							let path = "/properties";

							// EDIT PROPERTY API CALL
							if (this.state.mode === "edit") {
								path += "/" + this.state.propertyId;
								if (
									!isEqual(
										this.state.initialStateModel.externalPropertyId,
										this.state.external_property_id
									)
								) {
									await apiHandler
										.patch(path, {
											body: {
												external_property_id: this.state.external_property_id
											}
										})
										.then(response => {
											this.handleResponse(response);
										});
								}
								if (
									!isEqual(
										this.state.initialStateModel.data,
										this.state.data
									) ||
									!isEqual(
										this.state.initialStateModel.studioRoom,
										this.state.data.studioRoom
									)
								) {
									await apiHandler
										.put(path + "/content", { body: myInit.body.content })
										.then(response => {
											this.handleResponse(response);
										});
								}
								if (
									!isEqual(
										this.state.initialStateModel.cicoTimes,
										this.state.cico_times
									)
								) {
									await apiHandler
										.put(path + "/cicotimes", { body: myInit.body.cico_times })
										.then(response => {
											this.handleResponse(response);
										});
								}
								if (
									!isEqual(
										this.state.initialStateModel.status,
										this.state.status
									)
								) {
									await apiHandler
										.put(path + "/status", { body: myInit.body.status })
										.then(response => {
											this.handleResponse(response);
										});
								}
								if (
									!isEqual(this.state.initialStateModel.price, this.state.price)
								) {
									await apiHandler
										.put(path + "/price", { body: myInit.body.price })
										.then(response => {
											this.handleResponse(response);
										});
								}
								// LICENSE PETS API CALL
								if (
									!isEqual(
										this.state.initialStateModel.policies.pets,
										this.state.policies.pets
									)
								) {
									if (!this.state.policies.pets.id) {
										await apiHandler
											.post(path + "/policies", { body: myInit.body.pets })
											.then(response => {
												this.handleResponse(response);
											});
									} else {
										await apiHandler
											.put(path + "/policies/" + myInit.body.pets.id, {
												body: myInit.body.pets
											})
											.then(response => {
												this.handleResponse(response);
											});
									}
								}
								// LICENSE INTERNET API CALL
								if (
									!isEqual(
										this.state.initialStateModel.policies.internet,
										this.state.policies.internet
									)
								) {
									if (!this.state.policies.internet.id) {
										await apiHandler
											.post(path + "/policies", { body: myInit.body.internet })
											.then(response => {
												this.handleResponse(response);
											});
									} else {
										await apiHandler
											.put(path + "/policies/" + myInit.body.internet.id, {
												body: myInit.body.internet
											})
											.then(response => {
												this.handleResponse(response);
											});
									}
								}
								// LICENSE PARKING API CALL
								if (
									!isEqual(
										this.state.initialStateModel.policies.parking,
										this.state.policies.parking
									)
								) {
									if (!this.state.policies.parking.id) {
										await apiHandler
											.post(path + "/policies", { body: myInit.body.parking })
											.then(response => {
												this.handleResponse(response);
											});
									} else {
										await apiHandler
											.put(path + "/policies/" + myInit.body.parking.id, {
												body: myInit.body.parking
											})
											.then(response => {
												this.handleResponse(response);
											});
									}
								}
								// CALENDAR - BOOKING TIME LEAD API CALL
								if (
									!isEqual(
										this.state.initialStateModel.booking_lead_hours,
										this.state.booking_lead_hours
									)
								) {
									await apiHandler
										.post(path + "/bookingleadhours", {
											body: {
												booking_lead_hours: myInit.body.booking_lead_hours
											}
										})
										.then(response => {
											this.handleResponse(response);
										});
								}
								// Turnover Block
								if (
									!isEqual(
										this.state.initialStateModel.turnover_block,
										this.state.turnover_block
									)
								) {
									await apiHandler
										.post(path + "/turnoverblockconfig", {
											body: { turnover_block: this.state.turnover_block }
										})
										.then(response => {
											this.handleResponse(response);
										});
								}
								// Comm Data Config
								if (
									!isEqual(
										this.state.initialStateModel.propertyCommData,
										this.state.propertyCommData
									)
								) {
									await API.put(
										"gbapi",
										`/properties/${this.state.propertyId}/commdata`,
										{ body: this.state.propertyCommData }
									).then(response => {
										this.handleResponse(response);
									});
								}
								// SYNC CALENDAR
								if (
									!isEqual(
										this.state.initialStateModel.calendar,
										this.state.calendar
									)
								) {
									if (this.state.calendar.url) {
										await apiHandler
											.put(path + "/availabilityfeed", {
												body: myInit.body.calendar
											})
											.then(response => {
												this.handleResponse(response);
											});
									} else {
										await API.del("rapapi", path + "/availabilityfeed").then(
											response => {
												this.handleResponse(response);
											}
										);
									}
								}
								// Standard Cancellation Policy
								if (
									!isEqual(
										this.state.initialStateModel.freeCancelPolicy,
										this.state.freeCancelPolicy
									)
								) {
									await apiHandler
										.put(path + "/cancellationpolicies", {
											body: { free_cancel_policy: this.state.freeCancelPolicy }
										})
										.then(response => {
											this.handleResponse(response);
										});
								}
								// Seasonal Cancellation Policy
								if (
									!isEqual(
										this.state.initialStateModel.seasonalPolicies,
										this.state.seasonalPolicies
									)
								) {
									const body = this.state.seasonalPolicies.map(policy => {
										return {
											start_date: moment(policy.start_date).format(
												"YYYY-MM-DD"
											),
											end_date: moment(policy.end_date).format("YYYY-MM-DD"),
											policy: policy.policy,
											active: policy.active
										};
									});

									await apiHandler
										.put(path + "/seasonalcancellationpolicy", { body: body })
										.then(response => {
											this.handleResponse(response);
										});
								}

								// Save propertyContentLocks
								if (
									this.state.isAdmin &&
									this.state.propertyContentLocks &&
									this.state.integrationsDataByOrgName &&
									this.state.integrationsDataByOrgName.integration_name &&
									this.state.integrationsDataByOrgName.integration_name !==
										"racalendar"
								) {
									try {
										await API.patch(
											"rapapi",
											`/properties/${this.state.propertyId}/contentlocks`,
											{ body: this.state.propertyContentLocks }
										);
									} catch (err) {
										console.log("updateLocks catch", err);
									}
								}

								// SUBMIT PHOTOS
								await this.submitPhotos(this.state.propertyId);

								// IMAGE SEQUENCE API CALL - will call SEQUENCE API if any images are in different order.
								if (newImageSequence.length) {
									let response = {};
									for (let i = 0; i < newImageSequence.length; i++) {
										try {
											response = await apiHandler.put(
												`${path}/photos/${newImageSequence[i].photo_id}`,
												{
													body: newImageSequence[i]
												}
											);
										} catch (e) {
											console.log("Error Image Sequence");
										}
										if (i == newImageSequence.length - 1) {
											this.setState({
												submitStatus: this.state.submitStatus + 1
											});
										}
									}
								}

								this.setState({
									progressMessage: "Checking Validations..."
								});

								const before = new Date().getTime();

								// CHECK VALIDATIONS
								let response = await this.getValidation({
									run_validations: true
								});

								this.setState({
									validationData: response.validationData || null,
									validationChannels: response.validationChannels,
									redAwningFatalError: response.redAwningFatalError,
									submitStatus: this.state.submitStatus + 1
								});

								// GET NEW CALENDAR SOURCE DATA
								if (
									!isEqual(
										this.state.initialStateModel.calendar,
										this.state.calendar
									)
								) {
									await this.getCalendarData();

									this.setState({
										submitStatus: this.state.submitStatus + 1
									});
								}

								// Get updated turnover block.
								if (
									!isEqual(
										this.state.initialStateModel.turnover_block,
										this.state.turnover_block
									)
								) {
									await this.getTurnoverBlock();
								}

								// Get updated standard cancellation policy
								if (
									!isEqual(
										this.state.initialStateModel.freeCancelPolicy,
										this.state.freeCancelPolicy
									)
								) {
									await this.getFreeCancelPolicy();
								}

								// Get updated seasonal cancellation policy
								if (
									!isEqual(
										this.state.initialStateModel.seasonalPolicies,
										this.state.seasonalPolicies
									)
								) {
									await this.getSeasonalPolicies();
								}

								const after = new Date().getTime();

								if (!this.state.photoIsProcessing) {
									let addedPhotos = this.getUploadedPhotos(this.state.photos);

									if (
										addedPhotos &&
										addedPhotos.uploadedPhotos &&
										addedPhotos.uploadedPhotos.length
									) {
										await delay(after - before < 5000 ? after - before : 0);
									}
								}

								// Get Comm Data Config
								await this.parseCommData();

								// GET NEW PROPERTY DATA
								await this.props.fetchPropertyDetail();

								// UPDATE STATE PROPERTY
								this.updatePropertyState(this.props.propertyData, "edit");

								// CHECK PHOTO STATUS
								if (!this.state.photoIsProcessing) {
									this.checkPhotoStatus(this.props.propertyData);
								}

								this.setState({
									submitStatus: this.state.submitStatus + 1,
									progressMessage: "Property Saved"
								});

								// CREATE PROPERTY API CALL
							} else {
								apiHandler.post(path, myInit).then(async response => {
									if (!response.error) {
										// ADD PROPERTY TO COLLECTIONS - create room type functionality from collection.js
										if (this.props.collection) {
											await apiHandler.put(
												`/propertycollections/${this.props.collectionId}/properties/${response.property_id}`,
												{ body: {} }
											);
										}
										this.handleResponse(response);

										// CALENDAR - BOOKING TIME LEAD API CALL
										if (
											!isEqual(
												this.state.initialStateModel.booking_lead_hours,
												this.state.booking_lead_hours
											) ||
											this.state.booking_lead_hours != 0
										) {
											await apiHandler
												.post(
													`/properties/${response.property_id}/bookingleadhours`,
													{
														body: {
															booking_lead_hours: myInit.body.booking_lead_hours
														}
													}
												)
												.then(response => {
													this.handleResponse(response);
												});
										}

										// Turnover Block
										if (
											!isEqual(
												this.state.initialStateModel.turnover_block,
												this.state.turnover_block
											)
										) {
											await apiHandler
												.post(
													`/properties/${response.property_id}/turnoverblockconfig `,
													{
														body: { turnover_block: this.state.turnover_block }
													}
												)
												.then(response => {
													this.handleResponse(response);
												});
										}

										// LICENSE PETS API CALL
										if (
											!isEqual(
												this.state.initialStateModel.policies.pets,
												this.state.policies.pets
											) ||
											(this.state.policies.pets.id &&
												this.state.mode === "clone")
										) {
											await apiHandler
												.post(`/properties/${response.property_id}/policies`, {
													body: myInit.body.pets
												})
												.then(response => {
													this.handleResponse(response);
												});
										}

										// LICENSE INTERNET API CALL
										if (
											!isEqual(
												this.state.initialStateModel.policies.internet,
												this.state.policies.internet
											) ||
											(this.state.policies.internet.id &&
												this.state.mode === "clone")
										) {
											await apiHandler
												.post(`/properties/${response.property_id}/policies`, {
													body: myInit.body.internet
												})
												.then(response => {
													this.handleResponse(response);
												});
										}

										// LICENSE PARKING API CALL
										if (
											!isEqual(
												this.state.initialStateModel.policies.parking,
												this.state.policies.parking
											) ||
											(this.state.policies.parking.id &&
												this.state.mode === "clone")
										) {
											await apiHandler
												.post(`/properties/${response.property_id}/policies`, {
													body: myInit.body.parking
												})
												.then(response => {
													this.handleResponse(response);
												});
										}

										// Comm Data Config
										if (
											!isEqual(
												this.state.initialStateModel.propertyCommData,
												this.state.propertyCommData
											)
										) {
											await this.getCommData(response.property_id);

											if (
												_.get(this.state.propertyCommData, "organization_uid")
											) {
												await API.put(
													"gbapi",
													`/properties/${response.property_id}/commdata`,
													{ body: this.state.propertyCommData }
												).then(response => {
													this.handleResponse(response);
												});
											}
										}

										// SUBMIT IMAGES
										await this.submitPhotos(response.property_id);

										this.updateInitialStateModel();

										this.setState({
											propertyId: response.property_id,
											progressMessage: "Property Saved",
											submitStatus: this.state.submitStatus + 1
										});
									} else {
										this.handleResponse(response);
									}
								});
							}
						} catch (err) {
							console.log("error while saving property: ", err);
						}
					} else {
						// SCROLL TO FORM VALIDATION
						if (checkFormValidation.length) {
							let validationName = checkFormValidation[0][0];
							console.log(validationName, "validation name for scroll effect");

							if (
								checkFormValidation[0][0] === "longLatRequired" ||
								checkFormValidation[0][0] === "street_address" ||
								checkFormValidation[0][0] === "province" ||
								checkFormValidation[0][0] === "city" ||
								checkFormValidation[0][0] === "country"
							) {
								validationName = "address";
							}

							if (
								checkFormValidation[0][0] === "petsAmenityRequired" ||
								checkFormValidation[0][0] === "internetAmenityRequired" ||
								checkFormValidation[0][0] === "parkingAmenityRequired"
							) {
								validationName = "amenities";
							}

							if (checkFormValidation[0][0] === "duplicateCommonRooms") {
								validationName = "commonRooms";
							}

							if (
								checkFormValidation[0][0] === "duplicateLicenses" ||
								checkFormValidation[0][0] === "propertyExemption"
							) {
								validationName = "licenses";
							}

							this.scrollToValidation(validationName);
						}
					}
				}
			);
		}
	};

	scrollToValidation = validation => {
		this.setState(
			{
				activeKey: "1",
				init: true,
				editAddress: true
			},
			async () => {
				setTimeout(() => {
					document.getElementById(validation).scrollIntoView({
						block: "center",
						behavior: "smooth"
					});
				}, 500);
			}
		);
	};

	scrollToFees = e => {
		e.stopPropagation();

		this.setState(
			{
				activeKey: "7"
			},
			() => {
				setTimeout(() => {
					document.getElementById("feeSchedule").scrollIntoView({
						block: "center",
						behavior: "smooth"
					});
				}, 500);
			}
		);
	};

	/**
	 * Handle an error and show on modal
	 * @param {*} err
	 */
	failed = err => {
		let msg =
			"An error occurred while Saving Property. Please contact Customer Support.";
		let failedImagesCount = this.state.failedImagesCount || 0;

		if (err && err.errorType === "image") {
			msg =
				"Property Saved. Error Saving Pictures. Please contact Customer Support";
			failedImagesCount++;
		}
		err = err.response;
		let errData = err ? (err.data ? err.data : err) : "Request timed out";
		let errorDetail = this.state.error.detail || [];
		errorDetail.push(errData);

		this.setState({
			error: {
				show: true,
				message: msg,
				detail: errorDetail,
				failedImagesCount: failedImagesCount
			}
		});
	};

	/**
	 * show validation modal
	 * @param {*} e
	 */
	showValidationModal = async e => {
		e && e.preventDefault();

		if (!this.state.validationData) {
			this.setState({
				validationIsLoading: true
			});

			let validationsResponse = await this.getValidation({
				run_validations: true
			});

			this.setState({
				showValidations: true,
				validationData: validationsResponse.validationData,
				validationChannels: validationsResponse.validationChannels || {},
				redAwningFatalError: validationsResponse.redAwningFatalError,
				validationIsLoading: false
			});
		} else {
			this.setState({
				showValidations: true
			});
		}
	};

	/**
	 * For close validation modal
	 */
	closeValidations = () => {
		this.setState({
			showValidationModalError: false,
			showValidations: false
		});
	};

	openPhotoTipModal = e => {
		e.preventDefault();
		this.setState({
			showModal: "photo-tips-modal"
		});
	};

	/**
	 * Handle policy change input
	 * @param {*} e
	 */

	handlePolicyChange = (e, policy) => {
		const numValues = ["amount"];
		const name = e.target.name;
		let value = numValues.includes(name)
			? parseInt(e.target.value)
			: e.target.value;
		const petRestrictions = this.state.policies[policy].restrictions;

		if (value < 0) {
			value = 0;
		}

		petRestrictions.splice(0, 1, {
			...this.state.policies[policy].restrictions[0],
			adjustment: {
				...this.state.policies[policy].restrictions[0].adjustment,
				amount: value
			}
		});

		this.setState(
			{
				policies: {
					...this.state.policies,
					[policy]: {
						...this.state.policies[policy],
						restrictions: petRestrictions
					}
				}
			},
			() => {
				if (value.length <= 1) {
					let formValidation = this.formValidationHandler([name]);

					this.setState({
						formValidation: formValidation
					});
				}
			}
		);
	};

	/**
	 * For populate address in part
	 * @returns boolean represent if only one address available
	 */
	isAddressPopulated = () => {
		let {
			street_address,
			street_address2,
			city,
			country,
			province,
			postal_code
		} = this.state.data.location.address;
		if (street_address || street_address2 || province || city || postal_code)
			return true;
		return false;
	};

	/**
	 * Add address manually handler.
	 */
	addManually = () => {
		this.setState({
			editAddress: !this.state.editAddress
		});
	};

	getTooltip = (id, text) => {
		return (
			<Tooltip id={id} className="properties__tooltip">
				{text}
			</Tooltip>
		);
	};

	createCommonRoom = () => {
		const commonRooms = [...this.state.commonRooms];

		commonRooms.push({
			roomName: "",
			"King Bed": 0,
			"Queen Bed": 0,
			"Double Bed": 0,
			"Twin Bed": 0,
			"Sofa Bed": 0,
			"Air Mattress": 0,
			"Bunk Bed": 0
		});

		this.setState({
			commonRooms: commonRooms
		});
	};

	deleteCommonRoom = index => {
		const commonRooms = [...this.state.commonRooms];

		commonRooms.splice(index, 1);

		this.setState(
			{
				commonRooms: commonRooms,
				formValidation: {
					...this.state.formValidation,
					duplicateCommonRooms: false
				}
			},
			() => {
				const { formValidation } = this.state;

				formValidation.commonRooms.splice(index, 1);
			}
		);
	};

	createDefaultRadioButton = (labels, state, type, disabled) => {
		let func = null;

		switch (type) {
			case "pets":
				func = e => this.togglePetPoliciesRadioBtn(e);
				break;
			default:
				func = e => this.toggleDefaultRadioButton(e);
		}
		return labels.map((label, i) => (
			<Row key={i}>
				<label
					id={label["name"]}
					className={`radio-wrap radio-input ${disabled && "text-disabled"}`}
				>
					<input
						type="radio"
						className="form-radio-label radio-label"
						name={label["name"]}
						onChange={func}
						// Change label value to a single space string if the label value is a empty string.
						defaultChecked={
							state === label.value
								? label.value === ""
									? " "
									: label.value
								: null
						}
						value={label["value"]}
						disabled={disabled}
					/>
					<div className="radio-label" style={{ marginLeft: "35px" }}>
						{label["title"]}
					</div>
				</label>
			</Row>
		));
	};

	togglePetPoliciesRadioBtn = e => {
		let petRestrictions = [...this.state.policies.pets.restrictions];
		let name = e.target.name,
			value =
				e.target.value === "false"
					? false
					: e.target.value === "true"
					? true
					: e.target.value,
			state = {};

		if (name === "petsAllowed" && value === "no") {
			petRestrictions.splice(0, 1, {
				meta: {
					...this.state.policies.pets.restrictions[0].meta,
					pets_admission: "",
					pets_permit: "",
					admission: ""
				}
			});

			state = {
				[name]: value,
				data: {
					...this.state.data,
					pets_allowed: false
				},
				policies: {
					...this.state.policies,
					pets: {
						...this.state.policies.pets,
						property_policy: "not available",
						restrictions: petRestrictions
					}
				},
				formValidation: {
					...this.state.formValidation,
					petsRequest: false,
					petsFee: false,
					petsFeeFrequency: false
				}
			};
		} else if (name === "petsAllowed" && value === "yes") {
			petRestrictions.splice(0, 1, {
				meta: {
					...this.state.policies.pets.restrictions[0].meta,
					pets_admission: "pets can stay for free",
					admission: ""
				}
			});

			state = {
				[name]: value,
				data: {
					...this.state.data,
					pets_allowed: true
				},
				policies: {
					...this.state.policies,
					pets: {
						...this.state.policies.pets,
						guest_policy: "contact",
						property_policy: "included",
						restrictions: petRestrictions
					}
				},
				formValidation: {
					...this.state.formValidation,
					petsFee: false,
					petsFeeFrequency: false
				}
			};
		} else if (name === "petsAllowed" && value === "charges may apply") {
			petRestrictions.splice(0, 1, {
				meta: {
					...this.state.policies.pets.restrictions[0].meta,
					admission: "charges may apply",
					pets_admission: value
				}
			});

			state = {
				[name]: value,
				data: {
					...this.state.data,
					pets_allowed: true
				},
				policies: {
					...this.state.policies,
					pets: {
						...this.state.policies.pets,
						property_policy: "contact",
						restrictions: petRestrictions
					}
				},
				formValidation: {
					...this.state.formValidation,
					petsFee: false,
					petsFeeFrequency: false
				}
			};
		} else if (name === "petsAllowed" && value === "fee applies") {
			petRestrictions.splice(0, 1, {
				meta: {
					...this.state.policies.pets.restrictions[0].meta,
					pets_admission: value,
					admission: ""
				}
			});

			state = {
				[name]: value,
				data: {
					...this.state.data,
					pets_allowed: true
				},
				policies: {
					...this.state.policies,
					pets: {
						...this.state.policies.pets,
						property_policy: "restricted",
						restrictions: petRestrictions
					}
				},
				formValidation: {
					...this.state.formValidation,
					petsFee: false,
					petsFeeFrequency: false
				}
			};
		} else if (name === "petsRequest") {
			petRestrictions.splice(0, 1, {
				...this.state.policies.pets.restrictions[0],
				meta: {
					...this.state.policies.pets.restrictions[0].meta,
					pets_permit: value,
					admission:
						this.state.policies.pets.restrictions[0].meta.pets_admission ===
						"charges may apply"
							? "charges may apply"
							: ""
				}
			});

			state = {
				data: {
					...this.state.data,
					pets_allowed: true
				},
				policies: {
					...this.state.policies,
					pets: {
						...this.state.policies.pets,
						restrictions: petRestrictions
					}
				}
			};
		} else if (name === "petsFeeFrequency") {
			petRestrictions.splice(0, 1, {
				...this.state.policies.pets.restrictions[0],
				duration: {
					...this.state.policies.pets.restrictions[0].duration,
					duration_per: value
				}
			});

			state = {
				data: {
					...this.state.data,
					pets_allowed: true
				},
				policies: {
					...this.state.policies,
					pets: {
						...this.state.policies.pets,
						restrictions: petRestrictions
					}
				}
			};
		}

		this.setState(state, () => {
			let formValidation = this.formValidationHandler([name]);

			this.setState({
				formValidation: formValidation
			});
		});
	};

	toggleDefaultRadioButton = e => {
		let name = e.target.name;
		// if the value is a string boolean - it will change to a regular boolean value
		let value =
			e.target.value === "false"
				? false
				: e.target.value === "true"
				? true
				: e.target.value;
		const singleStateValue = ["calendarSource"];
		const internetPolicies = [
			"internetIncluded",
			"internet_coverage",
			"internet_type",
			"internetFeeFrequency"
		];
		const parkingPolicies = [
			"parkingIncluded",
			"parking_type",
			"parking_reservation",
			"parking_location",
			"parkingFeeFrequency"
		];

		// TODO: look for a way to minimize logic for Internet, and Parking Policies.
		// Internet Configuration
		if (internetPolicies.includes(name)) {
			const internetRestrictions = this.state.policies.internet.restrictions;

			if (name === "internetIncluded" && value === "no") {
				internetRestrictions.splice(0, 1, {
					meta: {
						...this.state.policies.internet.restrictions[0].meta,
						admission: "",
						internet_coverage: "",
						internet_type: ""
					}
				});

				this.setState(
					{
						[name]: value,
						policies: {
							...this.state.policies,
							internet: {
								...this.state.policies.internet,
								property_policy: "not available",
								restrictions: internetRestrictions
							}
						},
						// Clear internet form validation.
						formValidation: {
							...this.state.formValidation,
							internet_type: false,
							internet_coverage: false,
							internetFee: false,
							internetFeeFrequency: false
						}
					},
					() => {
						let formValidation = this.formValidationHandler([name]);

						this.setState({
							formValidation: formValidation
						});
					}
				);
			}
			if (name === "internetIncluded" && value === "yes") {
				internetRestrictions.splice(0, 1, {
					meta: {
						...this.state.policies.internet.restrictions[0].meta,
						admission: ""
					}
				});

				this.setState(
					{
						[name]: value,
						policies: {
							...this.state.policies,
							internet: {
								...this.state.policies.internet,
								property_policy: "included",
								restrictions: internetRestrictions
							}
						},
						formValidation: {
							...this.state.formValidation,
							internetFee: false,
							internetFeeFrequency: false
						}
					},
					() => {
						let formValidation = this.formValidationHandler([name]);

						this.setState({
							formValidation: formValidation
						});
					}
				);
			} else if (
				(name === "internetIncluded" && value === "charges may apply") ||
				(name === "internetIncluded" && value === "fee applies")
			) {
				internetRestrictions.splice(0, 1, {
					meta: {
						...this.state.policies.internet.restrictions[0].meta,
						admission: value
					}
				});

				this.setState(
					{
						[name]: value,
						policies: {
							...this.state.policies,
							internet: {
								...this.state.policies.internet,
								property_policy:
									value === "charges may apply" ? "contact" : "restricted",
								restrictions: internetRestrictions
							}
						},
						formValidation: {
							...this.state.formValidation,
							internetFee: false,
							internetFeeFrequency: false
						}
					},
					() => {
						let formValidation = this.formValidationHandler([name]);

						this.setState({
							formValidation: formValidation
						});
					}
				);
			} else if (name === "internetIncluded") {
				this.setState(
					{
						[name]: value
					},
					() => {
						let formValidation = this.formValidationHandler([name]);

						this.setState({
							formValidation: formValidation
						});
					}
				);
			} else if (name === "internet_coverage" || name === "internet_type") {
				internetRestrictions.splice(0, 1, {
					...this.state.policies.internet.restrictions[0],
					meta: {
						...this.state.policies.internet.restrictions[0].meta,
						[name]: value
					}
				});

				this.setState(
					{
						policies: {
							...this.state.policies,
							internet: {
								...this.state.policies.internet,
								restrictions: internetRestrictions
							}
						}
					},
					() => {
						let formValidation = this.formValidationHandler([name]);

						this.setState({
							formValidation: formValidation
						});
					}
				);
			} else if (name === "internetFeeFrequency") {
				internetRestrictions.splice(0, 1, {
					...this.state.policies.internet.restrictions[0],
					duration: {
						...this.state.policies.internet.restrictions[0].duration,
						duration_per: value
					}
				});

				this.setState(
					{
						policies: {
							...this.state.policies,
							internet: {
								...this.state.policies.internet,
								restrictions: internetRestrictions
							}
						}
					},
					() => {
						let formValidation = this.formValidationHandler([name]);

						this.setState({
							formValidation: formValidation
						});
					}
				);
			}
		}
		// Parking Configuration
		else if (parkingPolicies.includes(name)) {
			const parkingRestrictions = this.state.policies.parking.restrictions;

			if (name === "parkingIncluded" && value === "no") {
				parkingRestrictions.splice(0, 1, {
					meta: {
						...this.state.policies.parking.restrictions[0].meta,
						admission: "",
						parking_type: "",
						parking_location: "",
						parking_reservation: ""
					}
				});

				this.setState(
					{
						[name]: value,
						policies: {
							...this.state.policies,
							parking: {
								...this.state.policies.parking,
								property_policy: "not available",
								restrictions: parkingRestrictions
							}
						},
						// Clear parking form validation.
						formValidation: {
							...this.state.formValidation,
							parking_type: false,
							parking_location: false,
							parking_reservation: false,
							parkingFee: false,
							parkingFeeFrequency: false
						}
					},
					() => {
						let formValidation = this.formValidationHandler([name]);

						this.setState({
							formValidation: formValidation
						});
					}
				);
			} else if (name === "parkingIncluded" && value === "yes") {
				parkingRestrictions.splice(0, 1, {
					meta: {
						...this.state.policies.parking.restrictions[0].meta,
						admission: ""
					}
				});

				this.setState(
					{
						[name]: value,
						policies: {
							...this.state.policies,
							parking: {
								...this.state.policies.parking,
								property_policy: "included",
								restrictions: parkingRestrictions
							}
						},
						formValidation: {
							...this.state.formValidation,
							parkingFee: false,
							parkingFeeFrequency: false
						}
					},
					() => {
						let formValidation = this.formValidationHandler([name]);

						this.setState({
							formValidation: formValidation
						});
					}
				);
			} else if (
				(name === "parkingIncluded" && value === "charges may apply") ||
				(name === "parkingIncluded" && value === "fee applies")
			) {
				parkingRestrictions.splice(0, 1, {
					meta: {
						...this.state.policies.parking.restrictions[0].meta,
						admission: value
					}
				});

				this.setState(
					{
						[name]: value,
						policies: {
							...this.state.policies,
							parking: {
								...this.state.policies.parking,
								property_policy:
									value === "charges may apply" ? "contact" : "restricted",
								restrictions: parkingRestrictions
							}
						},
						formValidation: {
							...this.state.formValidation,
							parkingFee: false,
							parkingFeeFrequency: false
						}
					},
					() => {
						let formValidation = this.formValidationHandler([name]);

						this.setState({
							formValidation: formValidation
						});
					}
				);
			} else if (name === "parkingIncluded") {
				this.setState(
					{
						[name]: value
					},
					() => {
						let formValidation = this.formValidationHandler([name]);

						this.setState({
							formValidation: formValidation
						});
					}
				);
			} else if (
				name === "parking_type" ||
				name === "parking_location" ||
				name === "parking_reservation"
			) {
				parkingRestrictions.splice(0, 1, {
					...this.state.policies.parking.restrictions[0],
					meta: {
						...this.state.policies.parking.restrictions[0].meta,
						[name]: value
					}
				});

				this.setState(
					{
						policies: {
							...this.state.policies,
							parking: {
								...this.state.policies.parking,
								restrictions: parkingRestrictions
							}
						}
					},
					() => {
						let formValidation = this.formValidationHandler([name]);

						this.setState({
							formValidation: formValidation
						});
					}
				);
			} else if (name === "parkingFeeFrequency") {
				parkingRestrictions.splice(0, 1, {
					...this.state.policies.parking.restrictions[0],
					duration: {
						...this.state.policies.parking.restrictions[0].duration,
						duration_per: value
					}
				});

				this.setState(
					{
						policies: {
							...this.state.policies,
							parking: {
								...this.state.policies.parking,
								restrictions: parkingRestrictions
							}
						}
					},
					() => {
						let formValidation = this.formValidationHandler([name]);

						this.setState({
							formValidation: formValidation
						});
					}
				);
			}
		} else if (name === "currency") {
			this.setState({
				price: {
					...this.state.price,
					[name]: value
				}
			});
		} else if (name.includes(singleStateValue)) {
			this.setState({
				[name]: value
			});
		} else {
			this.setState(
				{
					data: {
						...this.state.data,
						[name]: value
					}
				},
				() => {
					if (this.state.status.published || name === "title") {
						const { validationData } = this.state;

						let formValidation = this.formValidationHandler([name]);

						this.setState({
							formValidation: formValidation
						});
						if (
							this.state.status.published &&
							validationData &&
							name === "residency_category"
						) {
							let apiValidationError = this.apiLicenseValidationHandler(
								validationData
							);

							this.setState({
								licenseLocationRequired:
									apiValidationError.licenseLocationRequired
							});
						}
					}
				}
			);
		}
	};

	createRoomConfig = (rooms, roomType) => {
		const { formValidation } = this.state;

		let numberOptions = [];
		for (let i = 0; i <= constants.PROPERTY_VIEW.OPT_MAX_VALUE; i++) {
			numberOptions.push(
				<option key={i} value={i}>
					{i}
				</option>
			);
		}

		let scrollIntoViewRoomsId = [];
		formValidation[roomType].map((validation, i) => {
			if (validation === true) {
				scrollIntoViewRoomsId.push(i);
			}
		});

		return rooms.map((room, i) => (
			<Fragment key={i}>
				<Row>
					<Col md={6} sm={12} xs={12}>
						<div
							className={`bed-config-div pull-left ${
								this.state.formValidation[roomType][i] ? "form-validation" : ""
							}`}
						>
							<Col md={12} sm={12} xs={12}>
								<InputLabel id={i === scrollIntoViewRoomsId[0] ? roomType : ""}>
									{room.roomName}
								</InputLabel>
							</Col>
							<Col md={12}>
								<Row>
									<Col md={3}>
										<InputLabel>King Bed</InputLabel>
										<InputField
											type="select"
											value={room["King Bed"]}
											name={`King Bed`}
											onChange={e => this.handleRoomChange(e, i, roomType)}
										>
											{numberOptions}
										</InputField>
									</Col>
									<Col md={3}>
										<InputLabel>Queen Bed</InputLabel>
										<InputField
											type="select"
											value={room["Queen Bed"]}
											name={`Queen Bed`}
											onChange={e => this.handleRoomChange(e, i, roomType)}
										>
											{numberOptions}
										</InputField>
									</Col>
									<Col md={3}>
										<InputLabel>Double Bed</InputLabel>
										<InputField
											type="select"
											value={room["Double Bed"]}
											name={`Double Bed`}
											onChange={e => this.handleRoomChange(e, i, roomType)}
										>
											{numberOptions}
										</InputField>
									</Col>
									<Col md={3}>
										<InputLabel>Twin Bed</InputLabel>
										<InputField
											type="select"
											value={room["Twin Bed"]}
											name={`Twin Bed`}
											onChange={e => this.handleRoomChange(e, i, roomType)}
										>
											{numberOptions}
										</InputField>
									</Col>
								</Row>
								<br />
								<Row>
									<Col md={3}>
										<InputLabel>Sofa Bed</InputLabel>
										<InputField
											type="select"
											value={room["Sofa Bed"]}
											name={`Sofa Bed`}
											onChange={e => this.handleRoomChange(e, i, roomType)}
										>
											{numberOptions}
										</InputField>
									</Col>
									<Col md={3}>
										<InputLabel>Air Mattress</InputLabel>
										<InputField
											type="select"
											value={room["Air Mattress"]}
											name={`Air Mattress`}
											onChange={e => this.handleRoomChange(e, i, roomType)}
										>
											{numberOptions}
										</InputField>
									</Col>
									<Col md={3}>
										<InputLabel>Bunk Bed</InputLabel>
										<InputField
											type="select"
											value={room["Bunk Bed"]}
											name="Bunk Bed"
											onChange={e => this.handleRoomChange(e, i, roomType)}
										>
											{numberOptions}
										</InputField>
									</Col>
								</Row>
							</Col>
							<br />
						</div>
					</Col>
				</Row>
				{this.state.formValidation[roomType][i] && (
					<Row>
						<Col md={12}>
							<span className="required-error-text">
								At least one bed type is required per room
							</span>
						</Col>
					</Row>
				)}
				<br />
			</Fragment>
		));
	};

	createCommonAreaRadioButton = (index, state) => {
		const commonAreaList = [
			{ name: "commonArea", value: "Living Room", nameValue: "Living Room" },
			{ name: "commonArea", value: "Loft Room", nameValue: "Loft Room" }
		];

		return commonAreaList.map((label, i) => (
			<Row md={12} key={i}>
				<label className="radio-wrap radio-input">
					<input
						type="radio"
						className="form-radio-label radio-label"
						name={label["name"][index]}
						onChange={e => this.toggleCommonAreaRadioButton(e, index)}
						defaultChecked={state === label.value ? label.value : null}
						value={label["value"]}
					/>
					<div className="radio-label" style={{ marginLeft: "35px" }}>
						{label["nameValue"]}
					</div>
				</label>
			</Row>
		));
	};

	toggleCommonAreaRadioButton = (e, index) => {
		let value = e.target.value;

		let commonRooms = [...this.state.commonRooms];
		commonRooms.splice(index, 1, {
			...this.state.commonRooms[index],
			roomName: value
		});

		this.setState(
			{
				commonRooms: commonRooms
			},
			() => {
				const { formValidation } = this.state;
				let roomsValidations = [];

				formValidation["commonRooms"].map(rooms => {
					Object.values(rooms).map(room => {
						if (room === true) {
							roomsValidations.push(room);
						}
					});
				});

				if (
					roomsValidations.length ||
					formValidation.duplicateCommonRooms === true
				) {
					let formValidation = this.formValidationHandler(["commonRooms"]);

					this.setState({
						formValidation: formValidation
					});
				}
			}
		);
	};

	createCommonAreaConfig = roomType => {
		const { commonRooms, formValidation, isAdmin } = this.state;

		// default number for options
		let numberOptions = [];
		for (let i = 0; i <= constants.PROPERTY_VIEW.OPT_MAX_VALUE; i++) {
			numberOptions.push(
				<option key={i} value={i}>
					{i}
				</option>
			);
		}

		let scrollIntoViewCommonRoomsId = [];
		formValidation.commonRooms.map((validation, i) => {
			if (
				validation.commonAreaRoomError === true ||
				validation.commonAreaTypeError === true
			) {
				scrollIntoViewCommonRoomsId.push(i);
			}
		});

		return commonRooms.map((commonRoom, i) => {
			const commonAreaTypeError =
				formValidation &&
				formValidation.commonRooms &&
				formValidation.commonRooms[i] &&
				formValidation.commonRooms[i].commonAreaTypeError;
			const commonAreaRoomError =
				formValidation &&
				formValidation.commonRooms &&
				formValidation.commonRooms[i] &&
				formValidation.commonRooms[i].commonAreaRoomError;
			let commonAreaDuplicateType = "";

			Object.entries(this.state.duplicateCommonRooms).map(commonRoomType => {
				if (commonRoomType[1].includes(i)) {
					commonAreaDuplicateType = commonRoomType[0];
				}
			});

			return (
				<Fragment key={i}>
					<Row>
						<Col md={6} sm={12} xs={12}>
							<div
								className={`common-area-container ${
									commonAreaTypeError ||
									commonAreaRoomError ||
									formValidation.duplicateCommonRooms
										? "form-validation"
										: ""
								}`}
							>
								<Col md={12}>
									<InputLabel
										id={
											scrollIntoViewCommonRoomsId[0]
												? i === scrollIntoViewCommonRoomsId[0]
													? "commonRooms"
													: ""
												: i === 0
												? "commonRooms"
												: ""
										}
										className={
											formValidation.duplicateCommonRooms && "form-validation"
										}
									>
										Common Area
									</InputLabel>
									{isAdmin && (
										<OverlayTrigger
											placement="bottom"
											overlay={this.getTooltip("delete", "Delete Common Area")}
											onClick={() => this.deleteCommonRoom(i)}
										>
											<span
												className="common-area-delete-button pull-right"
												style={{ cursor: "pointer" }}
											>
												<i className="icon-Delete" />
											</span>
										</OverlayTrigger>
									)}
									{this.createCommonAreaRadioButton(i, commonRoom.roomName)}
									<hr />
								</Col>
								<Fragment>
									<Col md={12}>
										<Row>
											<Col md={3}>
												<InputLabel>King Bed</InputLabel>
												<InputField
													type="select"
													value={commonRoom["King Bed"]}
													name="King Bed"
													onChange={e => this.handleRoomChange(e, i, roomType)}
												>
													{numberOptions}
												</InputField>
											</Col>
											<Col md={3}>
												<InputLabel>Queen Bed</InputLabel>
												<InputField
													type="select"
													value={commonRoom["Queen Bed"]}
													name="Queen Bed"
													onChange={e => this.handleRoomChange(e, i, roomType)}
												>
													{numberOptions}
												</InputField>
											</Col>
											<Col md={3}>
												<InputLabel>Double Bed</InputLabel>
												<InputField
													type="select"
													value={commonRoom["Double Bed"]}
													name="Double Bed"
													onChange={e => this.handleRoomChange(e, i, roomType)}
												>
													{numberOptions}
												</InputField>
											</Col>
											<Col md={3}>
												<InputLabel>Twin Bed</InputLabel>
												<InputField
													type="select"
													value={commonRoom["Twin Bed"]}
													name="Twin Bed"
													onChange={e => this.handleRoomChange(e, i, roomType)}
												>
													{numberOptions}
												</InputField>
											</Col>
										</Row>
										<br />
										<Row>
											<Col md={3}>
												<InputLabel>Sofa Bed</InputLabel>
												<InputField
													type="select"
													value={commonRoom["Sofa Bed"]}
													name={`Sofa Bed`}
													onChange={e => this.handleRoomChange(e, i, roomType)}
												>
													{numberOptions}
												</InputField>
											</Col>
											<Col md={3}>
												<InputLabel>Air Mattress</InputLabel>
												<InputField
													type="select"
													value={commonRoom["Air Mattress"]}
													name={`Air Mattress`}
													onChange={e => this.handleRoomChange(e, i, roomType)}
												>
													{numberOptions}
												</InputField>
											</Col>
											<Col md={3}>
												<InputLabel>Bunk Bed</InputLabel>
												<InputField
													type="select"
													value={commonRoom["Bunk Bed"]}
													name="Bunk Bed"
													onChange={e => this.handleRoomChange(e, i, roomType)}
												>
													{numberOptions}
												</InputField>
											</Col>
										</Row>
									</Col>
								</Fragment>
							</div>
						</Col>
					</Row>
					{commonAreaTypeError && (
						<Row>
							<Col md={12}>
								<span className="required-error-text">
									Property Type is required
								</span>
							</Col>
						</Row>
					)}
					{commonAreaRoomError && (
						<Row>
							<Col md={12}>
								<span className="required-error-text">
									At least one bed type is required per room
								</span>
							</Col>
						</Row>
					)}
					{formValidation.duplicateCommonRooms && (
						<Row>
							<Col md={12}>
								<span className="required-error-text">
									Cannot have duplicate {commonAreaDuplicateType}{" "}
								</span>
							</Col>
						</Row>
					)}
					<br />
				</Fragment>
			);
		});
	};

	createLicense = () => {
		let licenses = [...this.state.data.licenses];
		let exempt = false;
		let exemptionReason = "";

		// PRE-POPULATE EXEMPT AND EXEMPTION_REASON
		licenses.map(license => {
			switch (license.exempt) {
				case true:
					exempt = true;
					break;
				default:
					exempt = false;
			}
			switch (license.exemption_reason) {
				case license.exemption_reason:
					exemptionReason = license.exemption_reason;
					break;
				default:
					exemptionReason = "";
			}
		});

		licenses.push({
			type: "",
			id: "",
			exempt: exempt,
			exemption_reason: exemptionReason
		});

		this.setState({
			data: { ...this.state.data, licenses }
		});
	};

	deleteLicense = index => {
		let licenses = [...this.state.data.licenses];
		let selectedDates = [...this.state.selectedDates];

		licenses.splice(index, 1);
		selectedDates.splice(index, 1);

		this.setState(
			{
				data: { ...this.state.data, licenses },
				selectedDates: selectedDates
			},
			() => {
				const { formValidation, validationData } = this.state;
				let licenseValidations = [];

				formValidation["licenses"].map(licenses => {
					Object.values(licenses).map(license => {
						if (license === true) {
							licenseValidations.push(license);
						}
					});
				});

				if (
					licenseValidations.length ||
					formValidation.duplicateLicenses === true
				) {
					formValidation.licenses.splice(index, 1);

					let checkFormValidation = this.formValidationHandler(["licenses"]);

					this.setState({
						formValidation: checkFormValidation
					});
				}

				if (this.state.status.published && validationData) {
					let apiValidationError = this.apiLicenseValidationHandler(
						validationData
					);

					this.setState({
						licenseLocationRequired: apiValidationError.licenseLocationRequired,
						formValidation: formValidation
					});
				}
				if (!licenses.length) {
					this.setState({
						propertyExemption: ""
					});
				}
			}
		);
	};

	licenseOnChange = (e, index) => {
		const name = e.target.name.replace(/[0-9]/g, "");
		const value = e.target.value;
		let licenses = [...this.state.data.licenses];

		licenses.splice(index, 1, {
			...this.state.data.licenses[index],
			[name]: value
		});

		this.setState(
			{
				data: {
					...this.state.data,
					licenses: licenses
				}
			},
			() => {
				const { validationData, formValidation } = this.state;
				let licenseValidations = [];

				formValidation["licenses"].map(licenses => {
					Object.values(licenses).map(license => {
						if (license === true) {
							licenseValidations.push(license);
						}
					});
				});

				if (
					licenseValidations.length ||
					formValidation.duplicateLicenses === true
				) {
					let formValidation = this.formValidationHandler(["licenses"]);

					this.setState({
						formValidation: formValidation
					});
				}
				if (
					this.state.status.published &&
					validationData &&
					(!licenses[index].id || licenses[index].id.length === 1)
				) {
					let apiValidationError = this.apiLicenseValidationHandler(
						validationData
					);

					this.setState({
						licenseLocationRequired: apiValidationError.licenseLocationRequired
					});
				}
			}
		);
	};

	licenseDateOnChange = (date, index) => {
		if (date) {
			let selectedDates = [...this.state.selectedDates];
			let licenses = [...this.state.data.licenses];

			selectedDates.splice(index, 1, moment(date));
			licenses.splice(index, 1, {
				...this.state.data.licenses[index],
				["expires"]: moment(date).format("YYYY-MM-DD")
			});

			this.setState(
				{
					selectedDates: selectedDates,
					data: {
						...this.state.data,
						licenses: licenses
					}
				},
				() => {
					const { validationData, formValidation } = this.state;
					let licenseValidations = [];

					formValidation["licenses"].map(licenses => {
						Object.values(licenses).map(license => {
							if (license === true) {
								licenseValidations.push(license);
							}
						});
					});

					if (licenseValidations.length) {
						let formValidation = this.formValidationHandler(["licenses"]);

						this.setState({
							formValidation: formValidation
						});
					}
					if (this.state.status.published && validationData) {
						let apiValidationError = this.apiLicenseValidationHandler(
							validationData
						);

						this.setState({
							licenseLocationRequired:
								apiValidationError.licenseLocationRequired
						});
					}
				}
			);
		}
	};

	exemptionOnChange = e => {
		const value = e.target.value;
		let licenses = [...this.state.data.licenses];

		this.setState(
			{
				propertyExemption: value
			},
			() => {
				if (this.state.propertyExemption === "propertyExemption") {
					licenses.map((license, i) => {
						licenses.splice(i, 1, { ...license, exempt: true, id: "" });
					});
				} else {
					licenses.map((license, i) => {
						licenses.splice(i, 1, {
							...license,
							exempt: false,
							exemption_reason: ""
						});
					});
					// REMOVE EXEMPTION REASON FROM VALIDATION
					this.state.formValidation.exemption_reason = false;
				}
				this.setState(
					{
						exemption_reason: "",
						data: {
							...this.state.data,
							licenses: licenses
						}
					},
					() => {
						const { validationData, formValidation } = this.state;
						let licenseValidations = [];

						formValidation["licenses"].map(licenses => {
							Object.values(licenses).map(license => {
								if (license === true) {
									licenseValidations.push(license);
								}
							});
						});

						if (
							licenseValidations.length ||
							formValidation.propertyExemption === true
						) {
							let formValidation = this.formValidationHandler(["licenses"]);

							this.setState({
								formValidation: formValidation
							});
						}

						if (this.state.status.published && validationData) {
							let apiValidationError = this.apiLicenseValidationHandler(
								validationData
							);

							this.setState({
								licenseLocationRequired:
									apiValidationError.licenseLocationRequired
							});
						}
					}
				);
			}
		);
	};

	exemptionReasonOnChange = e => {
		const name = e.target.name;
		const value = e.target.value;
		let licenses = [...this.state.data.licenses];

		licenses.map((license, i) => {
			licenses.splice(i, 1, { ...license, [name]: value, id: "" });
		});

		this.setState(
			{
				[name]: value,
				data: {
					...this.state.data,
					licenses: licenses
				}
			},
			() => {
				const { validationData, formValidation } = this.state;
				let licenseValidations = [];

				formValidation["licenses"].map(licenses => {
					Object.values(licenses).map(license => {
						if (license === true) {
							licenseValidations.push(license);
						}
					});
				});

				if (
					licenseValidations.length ||
					formValidation.exemption_reason === true
				) {
					let formValidation = this.formValidationHandler(["licenses"]);

					this.setState({
						formValidation: formValidation
					});
				}

				if (this.state.status.published && validationData) {
					let apiValidationError = this.apiLicenseValidationHandler(
						validationData
					);

					this.setState({
						licenseLocationRequired: apiValidationError.licenseLocationRequired
					});
				}
			}
		);
	};

	createLicenseRadioButton = (index, labels, state, type) => {
		return labels.map((label, i) => (
			<Row md={12} key={i}>
				<label className="radio-wrap radio-input">
					<input
						type="radio"
						className="form-radio-label radio-label"
						name={label["name"] + index}
						onChange={e => this.licenseOnChange(e, index, "radio")}
						defaultChecked={state === label.value ? label.value : null}
						value={label["value"]}
					/>
					<div className="radio-label" style={{ marginLeft: "35px" }}>
						{label["title"]}{" "}
						{label.value === "tax" ||
						label.value === "permit_number" ||
						label.value === "tax_map_key"
							? infoPopover(
									this.licenseInfo[label.value],
									null,
									"top",
									"icon-info"
							  )
							: ""}
					</div>
				</label>
			</Row>
		));
	};

	createPropertyExemptionRadioButton = (index, labels, state, licenseTitle) => {
		return labels.map((label, i) => (
			<Row md={12} key={i}>
				<label className="radio-wrap radio-input">
					<input
						type="radio"
						className="form-radio-label radio-label"
						name={label["name"] + index}
						onChange={e => this.exemptionOnChange(e, index, "radio")}
						value={label["value"]}
						checked={state === label.value ? true : false}
					/>
					<div className="radio-label" style={{ marginLeft: "35px" }}>
						{label["value"] === "licenseId" ? licenseTitle : label["title"]}{" "}
						{label.value === "propertyExemption"
							? infoPopover(
									this.licenseInfo.exemption,
									null,
									"top",
									"icon-info"
							  )
							: ""}
					</div>
				</label>
			</Row>
		));
	};

	checkinInstructionsPopover = (title, message) => {
		return (
			<OverlayTrigger
				placement="top"
				overlay={
					<Popover id="popover-basic" placement="top" title={title}>
						<p>{message}</p>
					</Popover>
				}
			>
				<span className="input-question-mark">
					<i className="icon-question-mark"></i>
				</span>
			</OverlayTrigger>
		);
	};

	checkDuplicate = (state, type) => {
		let data = [...state];
		let duplicateArr = [];
		let duplicateList = {};

		for (let i = 0; i < data.length; i++) {
			duplicateArr.splice(i, 0, data[i][type]);
		}
		for (let i = 0; i < duplicateArr.length; i++) {
			if (duplicateList.hasOwnProperty(duplicateArr[i])) {
				duplicateList[duplicateArr[i]].push(i);
			} else if (duplicateArr.lastIndexOf(duplicateArr[i]) !== i) {
				duplicateList[duplicateArr[i]] = [i];
			}
		}
		delete duplicateList[""];

		return duplicateList;
	};

	createLicenseConfig = () => {
		const { data, formValidation } = this.state;
		const licenseType = [
			{ name: "type", value: "tax", title: "Tax License Number" },
			{
				name: "type",
				value: "permit_number",
				title: "STR Permit/Registration Number"
			},
			{
				name: "type",
				value: "identification_number",
				title: "Identification Number"
			},
			{ name: "type", value: "tax_map_key", title: "Tax Map Key" }
		];
		const propertyExemption = [
			{ name: "propertyExemption", value: "licenseId", title: "License ID" },
			{
				name: "propertyExemption",
				value: "propertyExemption",
				title: "Property Exemption"
			}
		];

		let scrollIntoViewLicenseId = [];
		formValidation.licenses.map((license, i) => {
			if (
				license.licenseTypeError === true ||
				license.licenseIdError === true
			) {
				scrollIntoViewLicenseId.push(i);
			}
		});

		return data.licenses.map((license, i) => {
			const licenseTypeError =
				formValidation &&
				formValidation.licenses &&
				formValidation.licenses[i] &&
				formValidation.licenses[i].licenseTypeError;
			const licenseIdError =
				formValidation &&
				formValidation.licenses &&
				formValidation.licenses[i] &&
				formValidation.licenses[i].licenseIdError;

			// Dynamic Label Name
			let licenseLabel = "";
			for (let x = 0; x < licenseType.length; x++) {
				if (licenseType[x]["value"] === license.type) {
					licenseLabel = licenseType[x].title;
				}
			}

			// Check if license type has duplicates to show error message
			const { duplicateLicenses } = this.state;
			let hasDuplicates = "";
			let duplicateType = "";

			if (license.type === "tax") {
				if (
					duplicateLicenses &&
					duplicateLicenses["tax"] &&
					duplicateLicenses["tax"].includes(i)
				) {
					duplicateType = "Tax";
					hasDuplicates = true;
				} else {
					duplicateType = "";
					hasDuplicates = false;
				}
			} else if (license.type === "permit_number") {
				if (
					duplicateLicenses &&
					duplicateLicenses["permit_number"] &&
					duplicateLicenses["permit_number"].includes(i)
				) {
					duplicateType = "Permit Number";
					hasDuplicates = true;
				} else {
					duplicateType = "";
					hasDuplicates = false;
				}
			} else if (license.type === "identification_number") {
				if (
					duplicateLicenses &&
					duplicateLicenses["identification_number"] &&
					duplicateLicenses["identification_number"].includes(i)
				) {
					duplicateType = "Identification Number";
					hasDuplicates = true;
				} else {
					duplicateType = "";
					hasDuplicates = false;
				}
			} else if (license.type === "tax_map_key") {
				if (
					duplicateLicenses &&
					duplicateLicenses["tax_map_key"] &&
					duplicateLicenses["tax_map_key"].includes(i)
				) {
					duplicateType = "Tax Map Key";
					hasDuplicates = true;
				} else {
					duplicateType = "";
					hasDuplicates = false;
				}
			}

			return (
				<Fragment key={i}>
					<div
						className={`license-container ${
							hasDuplicates ||
							licenseTypeError ||
							licenseIdError ||
							formValidation.propertyExemption
								? "form-validation"
								: ""
						}`}
					>
						<Row>
							<Col md={12} sm={12} xs={12}>
								<InputLabel
									id={
										scrollIntoViewLicenseId[0]
											? i === scrollIntoViewLicenseId[0]
												? "licenses"
												: ""
											: i === 0
											? "licenses"
											: ""
									}
									className={licenseTypeError && "form-validation"}
								>
									License Type
								</InputLabel>
								{this.createLicenseRadioButton(i, licenseType, license.type)}
							</Col>
							{license && license.type && license.type.length && (
								<Col md={12} sm={12} xs={12} className="top-margin">
									<InputLabel
										className={
											formValidation.propertyExemption && "form-validation"
										}
									>
										License / Exemption
									</InputLabel>
									{this.createPropertyExemptionRadioButton(
										i,
										propertyExemption,
										this.state.propertyExemption,
										licenseLabel
									)}
								</Col>
							)}
							{this.state.propertyExemption &&
								this.state.propertyExemption !== "propertyExemption" &&
								license &&
								license.type &&
								license.type.length && (
									<Fragment>
										<Col md={12} sm={12} xs={12} className="top-margin">
											<InputLabel
												className={licenseIdError && "form-validation"}
											>
												{licenseLabel}
											</InputLabel>
											<InputField
												className={licenseIdError && "form-validation"}
												type="text"
												value={license.id}
												name={"id" + i}
												onChange={e => this.licenseOnChange(e, i, "text")}
											></InputField>
										</Col>
										{license.type !== "identification_number" &&
											license.type !== "tax_map_key" && (
												<Col md={12} sm={12} xs={12} className="top-margin">
													<InputLabel>Expiration Date</InputLabel>
													<div className="expirationDate">
														<DatePicker
															date={
																this.state.selectedDates[i]
																	? this.state.selectedDates[i]
																	: license.expires
																	? moment(license.expires)
																	: null
															}
															onDateChange={date => {
																this.licenseDateOnChange(date, i);
															}}
															numberOfMonths={1}
															isOutsideRange={() => false}
															placeholder="MM/DD/YYYY"
															displayFormat="MM/DD/YYYY"
														/>
													</div>
												</Col>
											)}
									</Fragment>
								)}
							<OverlayTrigger
								placement="bottom"
								overlay={this.getTooltip("delete", "Delete License")}
								onClick={() => this.deleteLicense(i)}
							>
								<span
									className="common-area-delete-button"
									style={{ cursor: "pointer" }}
								>
									<i className="icon-Delete" />
								</span>
							</OverlayTrigger>
						</Row>
					</div>
					{licenseTypeError && (
						<span className="required-error-text">
							License Type is required
						</span>
					)}
					{licenseIdError && (
						<span className="required-error-text">
							{licenseLabel} is required
						</span>
					)}
					{formValidation.propertyExemption && (
						<span className="required-error-text">
							License / Exemption input is required.
						</span>
					)}
					{hasDuplicates && (
						<span className="required-error-text">
							Cannot have duplicate {duplicateType}
						</span>
					)}
					{data.licenses.length - 1 !== i && <br />}
				</Fragment>
			);
		});
	};

	handleCalendarChange = e => {
		let calendar = { ...this.state.calendar };
		let name = e.target.name;
		const value = e.target.value;
		const calendarSource = [
			"racalendar",
			"ical_one_time_import",
			"ical_source_of_truth"
		];
		const changeCalendarData = ["url", "use_checkout_date", "frequency"];

		if (changeCalendarData.includes(name)) {
			this.setState(
				{
					calendar: {
						...calendar,
						[name]: value
					}
				},
				() => {
					if (name === "url") {
						name = "calendarUrl";
					}
					if (name !== "frequency" && this.state.formValidation[name] == true) {
						let formValidation = this.formValidationHandler([name]);

						this.setState({
							formValidation: formValidation
						});
					}
				}
			);
		} else {
			this.setState(
				{
					[name]: value
				},
				() => {
					if (calendarSource.includes(value)) {
						if (value === "racalendar") {
							this.setState({
								calendar: {
									...calendar,
									feed_type: "racalendar",
									frequency: "",
									use_checkout_date: "",
									one_time_run_ts: "",
									url: ""
								},
								formValidation: {
									...this.state.formValidation,
									url: false,
									use_checkout_date: false
								}
							});
						} else if (value === "ical_one_time_import") {
							this.setState({
								calendar: {
									...calendar,
									feed_type: "ical",
									one_time_run_ts: "1574677833",
									frequency: ""
								}
							});
						} else if (value === "ical_source_of_truth") {
							this.setState({
								calendar: {
									...calendar,
									feed_type: "ical",
									one_time_run_ts: "",
									frequency: 120
								}
							});
						}
					}
				}
			);
		}
	};

	createCalendarSourceRadioButton = (labels, state) => {
		return labels.map((label, i) => (
			<Row key={i}>
				<label id={label["name"]} className="radio-wrap radio-input">
					<input
						type="radio"
						className="form-radio-label radio-label"
						name={label["name"]}
						onChange={e => this.handleCalendarChange(e, "radio")}
						checked={
							state === label.value
								? label.value == "true"
									? "yes"
									: label.value == "false"
									? "no"
									: label.value
								: ""
						}
						value={label["value"]}
					/>
					<div className="radio-label" style={{ marginLeft: "35px" }}>
						{label["title"]}
					</div>
				</label>
			</Row>
		));
	};

	updateLocks = async (lockName, lockValue) => {
		let { propertyContentLocks } = this.state;

		this.setState({
			propertyContentLocks: {
				...propertyContentLocks,
				[lockName]: lockValue
			}
		});
	};

	handleVideoModal = () => {
		this.setState({
			videoModal: ""
		});
	};

	handleCommDataOnChange = e => {
		const { propertyCommData } = this.state;
		const name = e.target.name;
		const value = e.target.value;

		this.setState({
			propertyCommData: {
				...propertyCommData,
				[name]: value
			}
		});
	};

	renderChevronIcon = key => {
		const { init, activeKey } = this.state;

		return (
			<span
				className={`pull-right ${
					init || activeKey == key
						? "glyphicon glyphicon-chevron-up"
						: "glyphicon glyphicon-chevron-down"
				}`}
			></span>
		);
	};

	renderCustomFields = () => {
		const { customFields, propertyCommData } = this.state;

		if (!_.isEmpty(customFields)) {
			return customFields.map((field, i) => {
				return (
					<Row key={i}>
						<Col sm={12} md={6} className="bottom-margin-10">
							<InputLabel>{_.get(field, "title", "")}</InputLabel>
							<InputField
								type="text"
								value={propertyCommData[_.get(field, "name", "")]}
								name={_.get(field, "name", "")}
								onChange={this.handleCommDataOnChange}
							></InputField>
						</Col>
					</Row>
				);
			});
		}
	};

	render() {
		const {
			data,
			price,
			isAdmin,
			formValidation,
			videoModal,
			videoURL,
			feesList,
			integrationsDataByOrgName,
			isSmartConciergeAdmin,
			isSmartConciergeViewer,
			propertyCommData
		} = this.state;
		const petPolicies =
			this.state.policies &&
			this.state.policies.pets &&
			this.state.policies.pets.restrictions &&
			this.state.policies.pets.restrictions[0];
		const internetPolicies =
			this.state.policies &&
			this.state.policies.internet &&
			this.state.policies.internet.restrictions &&
			this.state.policies.internet.restrictions[0];
		const parkingPolicies =
			this.state.policies &&
			this.state.policies.parking &&
			this.state.policies.parking.restrictions &&
			this.state.policies.parking.restrictions[0];
		const kitchenList = [
			{ name: "kitchen", value: "kitchenette", title: "Kitchenette" },
			{ name: "kitchen", value: "full", title: "Full" },
			{ name: "kitchen", value: "none", title: "None" }
		];
		const handicapList = [
			{ name: "handicap_accessible", value: "true", title: "Yes" },
			{ name: "handicap_accessible", value: "false", title: "No" }
		];
		const currency = [
			{
				name: "currency",
				value: "USD",
				title: "United States: Dollar ($)",
				symbols: "$"
			},
			{
				name: "currency",
				value: "CAD",
				title: "Canada: Dollar (CAD)",
				symbols: "CAD"
			},
			{
				name: "currency",
				value: "MXN",
				title: "Mexico: Peso (MXN)",
				symbols: "MXN"
			},
			{
				name: "currency",
				value: "EUR",
				title: "Europe: Euro (€)",
				symbols: "€"
			},
			{
				name: "currency",
				value: "GBP",
				title: "United Kingdom: Pound (£)",
				symbols: "£"
			},
			{
				name: "currency",
				value: "IDR",
				title: "Indonesia: Rupiah (IDR)",
				symbols: "IDR"
			},
			{
				name: "currency",
				value: "AUD",
				title: "Australia: Dollar (AU$)",
				symbols: "AU$"
			}
		];
		const residencyCategory = [
			{
				name: "residency_category",
				value: "primary_residence",
				title: "Primary Residence"
			},
			{
				name: "residency_category",
				value: "secondary_residence",
				title: "Secondary Residence"
			},
			{
				name: "residency_category",
				value: "non_residential",
				title: "Non-Residential"
			}
		];
		const calendarSource = [
			{ name: "calendarSource", value: "racalendar", title: "Portal" },
			{
				name: "calendarSource",
				value: "ical_one_time_import",
				title: "iCal - One-Time Import"
			},
			{
				name: "calendarSource",
				value: "ical_source_of_truth",
				title: "iCal - Source of Truth"
			}
		];
		const checkOutDate = [
			{
				name: "use_checkout_date",
				value: "true",
				title: "Calendar uses Check-Out Date"
			},
			{
				name: "use_checkout_date",
				value: "false",
				title: "Calendar uses Last Night of Stay"
			}
		];

		// Currency Symbol
		let currencySymbol = "";
		for (let i = 0; i < currency.length; i++) {
			if (currency[i]["value"] === price.currency) {
				currencySymbol = currency[i].symbols;
			}
		}

		// Default Number for Options
		let numberOptions = [];
		for (let i = 0; i <= constants.PROPERTY_VIEW.OPT_MAX_VALUE; i++) {
			numberOptions.push(
				<option key={i} value={i}>
					{i}
				</option>
			);
		}

		// Number of Bedrooms
		let numberOfBedrooms = [<option key="" value="" disabled></option>];
		for (let i = 0; i <= constants.PROPERTY_VIEW.OPT_MAX_VALUE; i++) {
			numberOfBedrooms.push(
				<option key={i} value={i === 0 ? "studio" : i}>
					{i === 0 ? "Studio" : i}
				</option>
			);
		}

		// Number of Bathrooms
		let numberOfBathrooms = [<option key="" value="" disabled></option>];
		for (let i = 0; i <= constants.PROPERTY_VIEW.OPT_MAX_VALUE; i++) {
			numberOfBathrooms.push(
				<option key={i} value={i}>
					{i}
				</option>
			);
		}

		// Max Sleep
		let maxSleepsOptions = [<option key="" value="" disabled></option>];
		for (let i = 1; i <= constants.PROPERTY_VIEW.OPT_MAX_VALUE; i++) {
			maxSleepsOptions.push(
				<option key={i} value={i}>
					{i}
				</option>
			);
		}

		// Countries
		let countries = [];
		constants.PROPERTY_VIEW.COUNTRIES.map(country => {
			countries.push(
				<option
					key={country.Code}
					data-value={country.Name}
					value={country.Code}
				>
					{country.Name}
				</option>
			);
		});

		// Property Type
		let propertyTypeOption = [<option key="" value="" disabled></option>];
		let propertyTypeOptionValues = constants.PROPERTY_VIEW.PROPERTY_TYPE;
		propertyTypeOptionValues.forEach(function(type) {
			propertyTypeOption.push(
				<option key={type.value} value={type.value}>
					{type.label}
				</option>
			);
		});

		// Block Future Calendar
		let blockFeatureCalendar = [];
		constants.BLOCK_FUTURE_CALENDAR.map((days, i) => {
			blockFeatureCalendar.push(
				<option key={i} value={days.value}>
					{days.label}
				</option>
			);
		});

		// Turnover Block
		let turnoverBlock = [
			<option key="0" value={""}>
				{""}
			</option>
		];
		for (let i = 1; i <= 7; i++) {
			turnoverBlock.push(
				<option key={i} value={i}>
					{i}
				</option>
			);
		}

		// Exemption Reasons
		let exemptionReasons = [<option key="" value="" disabled></option>];
		constants.EXEMPTION_REASONS.map((reason, i) => {
			exemptionReasons.push(
				<option key={i} data-value={reason.value} value={reason.value}>
					{reason.label}
				</option>
			);
		});

		// Update Frequency
		let updateFrequency = [];
		constants.UPDATE_FREQUENCY.map((frequency, i) => {
			updateFrequency.push(
				<option key={i} data-value={frequency.value} value={frequency.value}>
					{frequency.label}
				</option>
			);
		});

		// Free Cancel Policy
		let freeCancelPolicy = [];
		constants.FREE_CANCEL_POLICY.map((policy, i) => {
			freeCancelPolicy.push(
				<option key={i} data-value={policy.value} value={policy.value}>
					{policy.label}
				</option>
			);
		});

		return (
			<div className="container-fluid">
				{this.state.isLoading && <Loader />}

				{(this.state.validationIsLoading || this.state.photoIsLoading) && (
					<Loader />
				)}

				{!this.state.isLoading && (
					<Fragment>
						{this.state.mode === "edit" && !this.props.mobileMode && (
							<PropertyRoomInfo
								view="property"
								propertyData={this.props.propertyData}
							/>
						)}
						<Title
							title={
								<Fragment>
									{this.props.title}
									{this.props.propertyData &&
										this.props.propertyData.level === "rep" && (
											<i className="icon-Properties_icon" />
										)}
								</Fragment>
							}
							isCentered={true}
							propClassName="property-create"
							breadcrumbItems={this.props.breadcrumbItems}
						>
							<div className="top-left-content">
								<div style={{ display: "inline-block" }}>
									<PropertyStatusButton
										status={this.state.status}
										toggleStatus={this.toggleStatus}
										admin={isAdmin}
										mode={this.state.mode}
										level={"property"}
										integrationName={
											integrationsDataByOrgName &&
											integrationsDataByOrgName.integration_name
										}
									/>
								</div>
								{this.state.status &&
								this.state.initialStateModel.status.published &&
								!this.state.status.published &&
								isAdmin ? (
									<div style={{ display: "inline-block" }}>
										<div className="property-status property-status-reason">
											<span>Reason </span>
											{
												<Fragment>
													<div style={{ display: "inline-block" }}>
														<DropdownButton
															title={
																this.state.status.notes
																	? this.state.showOtherField
																		? "Other"
																		: this.state.status.notes
																	: this.state.showOtherField
																	? "Other"
																	: "Select Reason"
															}
															className="primary-btn active-reason-btn"
															id={`property-not-live-reason`}
														>
															<MenuItem
																onClick={this.handleReasonNotesChange}
																data-not-live-reason="Remodel / Construction"
															>
																Remodel / Construction
															</MenuItem>
															<MenuItem
																onClick={this.handleReasonNotesChange}
																data-not-live-reason="Long Term Guest"
															>
																Long Term Guest
															</MenuItem>
															<MenuItem
																onClick={this.handleReasonNotesChange}
																data-not-live-reason="Maintenance"
															>
																Maintenance
															</MenuItem>
															<MenuItem
																onClick={this.handleReasonNotesChange}
																data-not-live-reason="Permit Issue"
															>
																Permit Issue
															</MenuItem>
															<MenuItem
																onClick={this.handleReasonNotesChange}
																data-not-live-reason="Property Sold"
															>
																Property Sold
															</MenuItem>
															<MenuItem
																onClick={this.handleReasonNotesChange}
																data-not-live-reason="Other"
															>
																Other
															</MenuItem>
														</DropdownButton>
													</div>
													{this.state.showOtherField ? (
														<div style={{ display: "inline-block" }}>
															<InputField
																type="text"
																name="status_notes"
																placeholder="Please enter a reason."
																size="25"
																maxLength="500"
																onChange={this.handleReasonNotesChange}
																className="active-status-reason-input"
															/>
														</div>
													) : (
														""
													)}
												</Fragment>
											}
										</div>
									</div>
								) : (
									""
								)}
							</div>
							{
								<Fragment>
									{this.props.mobileMode && (
										<div className="kebab-menu" id="property-kebab-menu">
											{[0, 1, 2].map(el => (
												<div key={el} className="kebab-item" />
											))}
										</div>
									)}
									<div
										className={`${
											this.props.mobileMode ? "mobile-" : ""
										}actions-container`}
									>
										<DropdownButton
											title={"Actions"}
											className="primary-btn white-btn"
											id={`${
												this.props.mobileMode ? "mobile-" : ""
											}property-actions`}
										>
											{this.state.mode == "edit" &&
												this.state.initialStateModel.status.published && (
													<li role="presentation">
														<Link
															onClick={e => {
																e.stopPropagation();
															}}
															target="_blank"
															to={{
																pathname: `http://www.redawning.com/node/${this.state.propertyId}`
															}}
														>
															Preview Property
														</Link>
													</li>
												)}
											{this.state.mode == "edit" && isAdmin && (
												<li role="presentation">
													<Link
														to={{
															pathname: "/properties/create",
															state: {
																cloneData: {
																	property: this.props.propertyData
																}
															}
														}}
													>
														Clone this {this.state.type}
													</Link>
												</li>
											)}
											{this.state.mode == "edit" && (
												<li role="presentation">
													<Link
														onClick={e => e.stopPropagation()}
														to={{
															pathname: `/calendar/${this.state.propertyId}/edit`
														}}
													>
														View Calendar
													</Link>
												</li>
											)}
											{this.state.mode == "edit" && (
												<MenuItem onClick={this.showValidationModal}>
													View Validations
												</MenuItem>
											)}
											{this.state.mode == "edit" &&
												(this.state.isSmartConciergeViewer ||
													this.state.isSmartConciergeAdmin) && (
													<li role="presentation">
														<Link
															onClick={e => {
																e.stopPropagation();
															}}
															to={{
																pathname: `/smart-concierge/${
																	this.props.propertyData.repconfig
																		.property_collection_id
																		? `collections/${this.props.propertyData.repconfig.property_collection_id}/`
																		: ""
																}properties/${this.state.propertyId}`
															}}
														>
															View Smart Concierge
														</Link>
													</li>
												)}
											{isAdmin &&
											this.state.mode == "edit" &&
											this.props.propertyData.repconfig ? (
												!this.props.propertyData.repconfig.collection_name ? (
													<MenuItem
														onClick={() => {
															this.setState({ showModal: "Add To Collection" });
														}}
													>
														Add to Collection
													</MenuItem>
												) : (
													<MenuItem
														onClick={() => {
															this.setState({
																showModal: "Delete From Collection"
															});
														}}
													>
														Remove from Collection{" "}
														{!this.props.mobileMode && (
															<strong>
																{
																	this.props.propertyData.repconfig
																		.collection_name
																}
															</strong>
														)}
													</MenuItem>
												)
											) : null}
											<MenuItem
												onClick={() => {
													this.setState({ showModal: "faq" });
												}}
											>
												FAQ
											</MenuItem>
										</DropdownButton>
									</div>
								</Fragment>
							}
							<Link
								to={
									!this.props.collection
										? "/properties"
										: `/collections/${this.props.collectionId}`
								}
								className="primary-btn black-btn pull-left left-margin"
								onClick={this.compareState}
							>
								Cancel
							</Link>
							{isAdmin && (
								<PrimaryButton
									cssClass="pull-left left-margin"
									fullWidth={false}
									type="button"
									onClick={this.addProperty}
									disabled={!this.compareState() && this.state.mode !== "clone"}
								>
									Save
								</PrimaryButton>
							)}
						</Title>

						<div id="content" className="property-form">
							<div
								className={"property-item row" + (!isAdmin ? " readonly" : "")}
							>
								<FieldWrap>
									<Form horizontal>
										<Row>
											<Col md={12} xs={12} sm={12} lg={12}>
												<PanelGroup
													accordion
													id="accordion-controlled-example"
													activeKey={this.state.activeKey}
													onSelect={activeKey => this.panelOnSelect(activeKey)}
												>
													<Panel eventKey="1">
														<Panel.Heading>
															<Panel.Title toggle>
																Details
																{this.renderChevronIcon(1)}
															</Panel.Title>
														</Panel.Heading>
														<Panel.Body collapsible>
															<Detail
																formValidation={this.state.formValidation}
																type={this.state.type}
																data={this.state.data}
																isAdmin={this.state.isAdmin}
																external_property_id={
																	this.state.external_property_id
																}
																isFetching={this.state.isFetching}
																fetchError={this.state.fetchError}
																editAddress={this.state.editAddress}
																mapOptions={this.state.mapOptions}
																mapEvents={this.mapEvents}
																countries={countries}
																integrationsDataByOrgName={
																	this.state.integrationsDataByOrgName
																}
																propertyContentLocks={
																	this.state.propertyContentLocks
																}
																updateLocks={this.updateLocks}
																handleDataChange={this.handleDataChange}
																handleInputStateChange={
																	this.handleInputStateChange
																}
																addManually={this.addManually}
																isAddressPopulated={this.isAddressPopulated}
																handleChangeAddress={this.handleChangeAddress}
																showModal={this.showModal}
																handleRTEChange={this.handleRTEChange}
																checkinInstructionsPopover={
																	this.checkinInstructionsPopover
																}
																propertyTitleTooltip={this.propertyTitleTooltip}
																mode={this.state.mode}
															/>
															<Row>
																<Col
																	md={6}
																	sm={12}
																	xs={12}
																	className="rows-section"
																>
																	<Row>
																		<Col xs={12}>
																			<InputLabel
																				id="description"
																				htmlFor="description"
																			>
																				Description &nbsp;
																				{this.state.isAdmin &&
																				this.state.propertyContentLocks &&
																				this.state.propertyContentLocks.title &&
																				this.state.integrationsDataByOrgName &&
																				this.state.integrationsDataByOrgName
																					.integration_name &&
																				this.state.mode !== "create" &&
																				this.state.integrationsDataByOrgName
																					.integration_name !== "racalendar" ? (
																					<OverlayTrigger
																						placement="top"
																						overlay={
																							<Tooltip
																								id="tip-description"
																								className="properties_description__tooltip"
																							>
																								{this.state
																									.propertyContentLocks &&
																								this.state.propertyContentLocks
																									.description === "LOCKED"
																									? "Unlock to enable PMS integration to overwrite description content."
																									: "Lock to prevent PMS integration from overwriting edited description content."}
																							</Tooltip>
																						}
																					>
																						<a
																							href="#"
																							onClick={e => {
																								this.updateLocks(
																									"description",
																									this.state
																										.propertyContentLocks
																										.description === "LOCKED"
																										? "UNLOCKED"
																										: "LOCKED"
																								);
																							}}
																						>
																							<i
																								className={
																									this.state
																										.propertyContentLocks &&
																									this.state
																										.propertyContentLocks
																										.description === "LOCKED"
																										? "icon-lock"
																										: "icon-unlock"
																								}
																							/>
																						</a>
																					</OverlayTrigger>
																				) : (
																					""
																				)}
																			</InputLabel>
																			<div
																				className={
																					formValidation.description
																						? "form-validation"
																						: ""
																				}
																			>
																				<RTE
																					value={this.state.RTE_description}
																					name="RTE_description"
																					isAdmin={isAdmin}
																					onChange={this.handleRTEChange}
																				/>
																			</div>
																			{formValidation.description && (
																				<span className="required-error-text">
																					Description is required
																				</span>
																			)}
																		</Col>
																	</Row>
																</Col>
															</Row>
														</Panel.Body>
													</Panel>
													{(!this.state.init &&
														// show room panel if property is rep level outside a collection
														this.props.propertyData &&
														this.props.propertyData.units) ||
													// show room panel if property is key level and inside a collection
													(this.props.propertyData &&
														!this.props.propertyData.units &&
														this.props.propertyData &&
														this.props.propertyData.repconfig
															.collection_name) ? (
														<Panel eventKey="2">
															<Panel.Heading>
																<Panel.Title toggle>
																	Rooms{" "}
																	{`(${
																		this.props.propertyData &&
																		this.props.propertyData.units
																			? this.props.propertyData.units
																			: 0
																	})`}
																	{this.renderChevronIcon(2)}
																</Panel.Title>
															</Panel.Heading>
															<Panel.Body collapsible>
																<Row>
																	<Rooms
																		totalItemsCount={
																			this.props.propertyData &&
																			this.props.propertyData.units
																		}
																		fetchPropertyDetail={
																			this.props.fetchPropertyDetail
																		}
																		mobileMode={this.props.mobileMode}
																		admin={isAdmin}
																		isSmartConciergeAdmin={
																			isSmartConciergeAdmin
																		}
																		isSmartConciergeViewer={
																			isSmartConciergeViewer
																		}
																	/>
																</Row>
															</Panel.Body>
														</Panel>
													) : (
														""
													)}
													<Panel
														eventKey={this.state.init ? "1" : "3"}
														className="property-type-section"
													>
														<Panel.Heading>
															<Panel.Title toggle>
																Accommodations
																{this.renderChevronIcon(3)}
															</Panel.Title>
														</Panel.Heading>
														<Panel.Body collapsible>
															<Aux>
																{this.state.isAdmin &&
																this.state.propertyContentLocks &&
																this.state.propertyContentLocks
																	.accommodations &&
																this.state.integrationsDataByOrgName &&
																this.state.integrationsDataByOrgName
																	.integration_name &&
																this.state.mode !== "create" &&
																this.state.integrationsDataByOrgName
																	.integration_name !== "racalendar" ? (
																	<div>
																		<InputLabel
																			id="AccommodationsLock"
																			htmlFor="accommodations_lock"
																		>
																			Accommodations Lock &nbsp;
																			<OverlayTrigger
																				placement="top"
																				overlay={
																					<Tooltip
																						id="tip-accommodations"
																						className="properties_accommodations__tooltip"
																					>
																						{this.state.propertyContentLocks &&
																						this.state.propertyContentLocks
																							.accommodations === "LOCKED"
																							? "Unlock to enable PMS integration to overwrite accommodations content."
																							: "Lock to prevent PMS integration from overwriting edited accommodations content."}
																					</Tooltip>
																				}
																			>
																				<a
																					href="#"
																					onClick={e => {
																						this.updateLocks(
																							"accommodations",
																							this.state.propertyContentLocks
																								.accommodations === "LOCKED"
																								? "UNLOCKED"
																								: "LOCKED"
																						);
																					}}
																				>
																					<i
																						className={
																							this.state.propertyContentLocks &&
																							this.state.propertyContentLocks
																								.accommodations === "LOCKED"
																								? "icon-lock"
																								: "icon-unlock"
																						}
																					/>
																				</a>
																			</OverlayTrigger>
																		</InputLabel>
																		<hr />
																	</div>
																) : (
																	""
																)}
																<Accomodation
																	type={this.state.type}
																	data={this.state.data}
																	isAdmin={this.state.isAdmin}
																	formValidation={this.state.formValidation}
																	propertyTypeOption={propertyTypeOption}
																	maxSleepsOptions={maxSleepsOptions}
																	numberOfBedrooms={this.state.numberOfBedrooms}
																	numberOfBedroomsValues={numberOfBedrooms}
																	rooms={this.state.rooms}
																	commonRooms={this.state.commonRooms}
																	bathroomInt={this.state.bathroomInt}
																	bathroomFloat={this.state.bathroomFloat}
																	kitchenList={kitchenList}
																	handicapList={handicapList}
																	numberOfBathrooms={numberOfBathrooms}
																	studioRoom={this.state.studioRoom}
																	handleDataChange={this.handleDataChange}
																	handleBedoomChange={this.handleBedoomChange}
																	createRoomConfig={this.createRoomConfig}
																	createCommonAreaConfig={
																		this.createCommonAreaConfig
																	}
																	createCommonRoom={this.createCommonRoom}
																	createDefaultRadioButton={
																		this.createDefaultRadioButton
																	}
																	handleInputStateChange={
																		this.handleInputStateChange
																	}
																/>
															</Aux>
														</Panel.Body>
													</Panel>
													<Panel eventKey={this.state.init ? "1" : "4"}>
														<Panel.Heading>
															<Panel.Title toggle>
																Amenities & Entertainment
																{this.renderChevronIcon(4)}
															</Panel.Title>
														</Panel.Heading>
														<Panel.Body collapsible>
															<Aux>
																{this.state.isAdmin &&
																this.state.propertyContentLocks &&
																this.state.propertyContentLocks.amenities &&
																this.state.integrationsDataByOrgName &&
																this.state.integrationsDataByOrgName
																	.integration_name &&
																this.state.mode !== "create" &&
																this.state.integrationsDataByOrgName
																	.integration_name !== "racalendar" ? (
																	<div>
																		<InputLabel
																			id="AmenitiesLock"
																			htmlFor="amenities_lock"
																		>
																			Amenities &amp; Entertainment Lock &nbsp;
																			<OverlayTrigger
																				placement="top"
																				overlay={
																					<Tooltip
																						id="tip-amenities"
																						className="properties_amenities__tooltip"
																					>
																						{this.state.propertyContentLocks &&
																						this.state.propertyContentLocks
																							.amenities === "LOCKED"
																							? "Unlock to enable PMS integration to overwrite amenities content."
																							: "Lock to prevent PMS integration from overwriting edited amenities content."}
																					</Tooltip>
																				}
																			>
																				<a
																					href="#"
																					onClick={e => {
																						this.updateLocks(
																							"amenities",
																							this.state.propertyContentLocks
																								.amenities === "LOCKED"
																								? "UNLOCKED"
																								: "LOCKED"
																						);
																					}}
																				>
																					<i
																						className={
																							this.state.propertyContentLocks &&
																							this.state.propertyContentLocks
																								.amenities === "LOCKED"
																								? "icon-lock"
																								: "icon-unlock"
																						}
																					/>
																				</a>
																			</OverlayTrigger>
																		</InputLabel>
																		<hr />
																	</div>
																) : (
																	""
																)}
																<AmenitiesEntertainment
																	data={this.state.data}
																	isAdmin={this.state.isAdmin}
																	amenities={this.state.amenities}
																	formValidation={this.state.formValidation}
																	petsAllowed={this.state.petsAllowed}
																	internetIncluded={this.state.internetIncluded}
																	parkingIncluded={this.state.parkingIncluded}
																	toggleAmenities={this.toggleAmenities}
																/>
															</Aux>
														</Panel.Body>
													</Panel>
													<Panel eventKey={this.state.init ? "1" : "5"}>
														<Panel.Heading>
															<Panel.Title toggle>
																Policies
																{this.renderChevronIcon(5)}
															</Panel.Title>
														</Panel.Heading>
														<Panel.Body collapsible>
															<Policies
																propertyContentLocks={
																	this.state.propertyContentLocks
																}
																integrationsDataByOrgName={
																	this.state.integrationsDataByOrgName
																}
																data={this.state.data}
																isAdmin={this.state.isAdmin}
																policies={this.state.policies}
																cico_times={this.state.cico_times}
																formValidation={this.state.formValidation}
																petsAllowedValue={this.state.petsAllowed}
																petsRequestValue={
																	petPolicies &&
																	this.state.policies.pets.restrictions[0].meta
																		.pets_permit
																}
																petsFeeValue={
																	petPolicies &&
																	this.state.policies.pets.restrictions[0]
																		.adjustment &&
																	this.state.policies.pets.restrictions[0]
																		.adjustment.amount
																}
																petsFeeFrequencyValue={
																	petPolicies &&
																	this.state.policies.pets.restrictions[0]
																		.duration &&
																	this.state.policies.pets.restrictions[0]
																		.duration.duration_per
																}
																internetIncludedValue={
																	this.state.internetIncluded
																}
																internetTypeValue={
																	internetPolicies &&
																	this.state.policies.internet.restrictions[0]
																		.meta.internet_type
																}
																internetCoverageValue={
																	internetPolicies &&
																	this.state.policies.internet.restrictions[0]
																		.meta.internet_coverage
																}
																internetFeeValue={
																	internetPolicies &&
																	this.state.policies.internet.restrictions[0]
																		.adjustment &&
																	this.state.policies.internet.restrictions[0]
																		.adjustment.amount
																}
																internetFeeFrequencyValue={
																	internetPolicies &&
																	this.state.policies.internet.restrictions[0]
																		.duration &&
																	this.state.policies.internet.restrictions[0]
																		.duration.duration_per
																}
																parkingIncludedValue={
																	this.state.parkingIncluded
																}
																parkingTypeValue={
																	parkingPolicies &&
																	this.state.policies.parking.restrictions[0]
																		.meta &&
																	this.state.policies.parking.restrictions[0]
																		.meta.parking_type
																}
																parkingLocationValue={
																	parkingPolicies &&
																	this.state.policies.parking.restrictions[0]
																		.meta &&
																	this.state.policies.parking.restrictions[0]
																		.meta.parking_location
																}
																parkingReservationValue={
																	parkingPolicies &&
																	this.state.policies.parking.restrictions[0]
																		.meta &&
																	this.state.policies.parking.restrictions[0]
																		.meta.parking_reservation
																}
																parkingFeeValue={
																	parkingPolicies &&
																	this.state.policies.parking.restrictions[0]
																		.adjustment &&
																	this.state.policies.parking.restrictions[0]
																		.adjustment.amount
																}
																parkingFeeFrequencyValue={
																	parkingPolicies &&
																	this.state.policies.parking.restrictions[0]
																		.duration &&
																	this.state.policies.parking.restrictions[0]
																		.duration.duration_per
																}
																propertyCommData={propertyCommData}
																RTE_special_terms={this.state.RTE_special_terms}
																handleCiCoOnChange={this.handleCiCoOnChange}
																createDefaultRadioButton={
																	this.createDefaultRadioButton
																}
																handlePolicyChange={this.handlePolicyChange}
																handleRTEChange={this.handleRTEChange}
																updateLocks={this.updateLocks}
																handleCommDataOnChange={
																	this.handleCommDataOnChange
																}
															/>
															<Row>
																<Col
																	md={6}
																	sm={12}
																	xs={12}
																	className="rows-section bottom-margin-10"
																>
																	<Row>
																		<Col xs={12}>
																			<InputLabel
																				id="special_terms"
																				htmlFor="special_terms"
																			>
																				Special Terms{" "}
																				<em className="optional">(Optional)</em>
																			</InputLabel>
																			<RTE
																				value={this.state.RTE_special_terms}
																				name="RTE_special_terms"
																				isAdmin={this.state.isAdmin}
																				onChange={this.handleRTEChange}
																			/>
																		</Col>
																	</Row>
																</Col>
															</Row>
															<Row>
																<Col
																	sm={12}
																	md={6}
																	className="bottom-margin-10"
																>
																	<InputLabel>Alarm Code</InputLabel>
																	<InputField
																		type="text"
																		name="alarm_code"
																		value={
																			_.get(propertyCommData, "alarm_code") ||
																			""
																		}
																		onChange={e =>
																			this.handleCommDataOnChange(e)
																		}
																	/>
																</Col>
															</Row>
															{this.renderCustomFields()}
															{this.state.mode === "edit" &&
																!_.isEmpty(this.state.freeCancelPolicy) && (
																	<Row>
																		<Col
																			md={6}
																			sm={12}
																			xs={12}
																			className="rows-section"
																		>
																			<Row>
																				<Col md={4}>
																					<InputLabel htmlFor="freeCancelPolicy">
																						Cancellation Policy
																					</InputLabel>
																					<InputField
																						type="select"
																						name="freeCancelPolicy"
																						value={this.state.freeCancelPolicy}
																						onChange={
																							this.handleInputStateChange
																						}
																					>
																						{freeCancelPolicy}
																					</InputField>
																				</Col>
																			</Row>
																		</Col>
																		<Col md={12} sm={12}>
																			<hr />
																		</Col>
																		<Col
																			md={12}
																			sm={12}
																			xs={12}
																			className="rows-section"
																		>
																			<Row>
																				<Col md={2}>
																					<InputLabel>
																						Seasonal Policy{" "}
																						{this.seasonalPoliciesPopover(
																							"",
																							this.seasonalPoliciesTooltip
																						)}
																					</InputLabel>
																				</Col>
																				<Col md={3}>
																					<PrimaryButton
																						cssClass="blue-btn "
																						fullWidth={true}
																						onClick={e =>
																							this.addSeasonalPolicy(e)
																						}
																						disabled={
																							this.state.freeCancelPolicy ===
																							"yes_3"
																						}
																					>
																						Add Seasonal Policy
																					</PrimaryButton>
																				</Col>
																			</Row>
																			<br />
																			{this.createSeasonalPolicies()}
																		</Col>
																	</Row>
																)}
														</Panel.Body>
													</Panel>
													<Panel
														eventKey={this.state.init ? "1" : "6"}
														className="currency-panel"
													>
														<Panel.Heading>
															<Panel.Title toggle>
																<span>Taxes, Fees, & Licenses</span>
																{this.renderChevronIcon(6)}
																{_.get(
																	feesList,
																	"manager_fee_schedules.fee_schedules.length"
																) ||
																_.get(
																	feesList,
																	"property_fee_schedules.fee_schedules.length"
																) ? (
																	<span className="fs-warning">
																		Fee schedules exists
																		<span
																			className="clickable-text"
																			onClick={e => this.scrollToFees(e)}
																		>
																			Configure here
																		</span>
																	</span>
																) : (
																	""
																)}
															</Panel.Title>
														</Panel.Heading>
														<Panel.Body collapsible>
															<Row>
																<Col sm={6} xs={6}>
																	<InputLabel
																		htmlFor="currency"
																		className={
																			this.state.initialStateModel.status
																				.published == true && `text-disabled`
																		}
																	>
																		Currency{" "}
																		{this.state.initialStateModel.status
																			.published == true &&
																			infoPopover(
																				"Currency is disabled when the property status is live. Please contact your account manager.",
																				null,
																				"top",
																				"icon-info"
																			)}
																	</InputLabel>
																	{this.createDefaultRadioButton(
																		currency,
																		price.currency,
																		"",
																		this.state.initialStateModel.status
																			.published == true
																	)}
																</Col>
															</Row>
															<Row>
																<Col md={6} sm={12} xs={12}>
																	<InputLabel htmlFor="tax_percent">
																		Tax Percent
																	</InputLabel>
																	<div className="placeholder-wrap">
																		<InputField
																			type="number"
																			name="tax_percent"
																			value={price.tax_percent}
																			onChange={this.handlePricingChange.bind(
																				this
																			)}
																		/>
																		<span className="placeholder">%</span>
																	</div>
																	<span>
																		Some channels may collect and remit taxes
																		for this property. &nbsp;
																		<Link
																			className="clickable-text"
																			target="_blank"
																			to={{
																				pathname:
																					"https://www.redawning.com/channel-collected-tax-help"
																			}}
																		>
																			Learn more
																		</Link>
																	</span>
																</Col>
															</Row>
															<Row>
																<Col md={6} sm={12} xs={12}>
																	<InputLabel htmlFor="cleaning_fee">
																		Total Cleaning and Per Stay Fees
																	</InputLabel>
																	<OverlayTrigger
																		placement="top"
																		overlay={
																			<Popover
																				id="popover-basic"
																				placement="top"
																			>
																				<p>
																					Any other required taxes or fees that
																					must be charged per night.
																				</p>
																			</Popover>
																		}
																	>
																		<span className="input-question-mark total-cleaning-per-stay-fees">
																			<i className="icon-question-mark"></i>
																		</span>
																	</OverlayTrigger>
																	<div className="placeholder-wrap">
																		<InputField
																			type="number"
																			name="cleaning_fee"
																			value={price.cleaning_fee}
																			onChange={this.handlePricingChange.bind(
																				this
																			)}
																		/>
																		<span className="placeholder">
																			{currencySymbol}
																		</span>
																	</div>
																</Col>
															</Row>
															<hr />
															{isAdmin && (
																<Fragment>
																	<Row>
																		<Col md={12} sm={12} xs={12}>
																			<InputLabel id="licenseLocationRequired">
																				Local Taxes and Laws{" "}
																				<em className="optional">
																					(Optional - location dependent)
																				</em>
																			</InputLabel>
																		</Col>
																	</Row>
																	{this.state.isAdmin &&
																	this.state.propertyContentLocks &&
																	this.state.propertyContentLocks.licenses &&
																	this.state.integrationsDataByOrgName &&
																	this.state.integrationsDataByOrgName
																		.integration_name &&
																	this.state.mode !== "create" &&
																	this.state.integrationsDataByOrgName
																		.integration_name !== "racalendar" ? (
																		<Row>
																			<Col md={12} sm={12} xs={12}>
																				<InputLabel id="licenseLocks">
																					Lock Licenses &nbsp;
																					<OverlayTrigger
																						placement="top"
																						overlay={
																							<Tooltip
																								id="tip-licenses"
																								className="properties_licenses__tooltip"
																							>
																								{this.state
																									.propertyContentLocks &&
																								this.state.propertyContentLocks
																									.licenses === "LOCKED"
																									? "Unlock to enable PMS integration to overwrite licenses."
																									: "Lock to prevent PMS integration from overwriting edited licenses."}
																							</Tooltip>
																						}
																					>
																						<a
																							href="#"
																							onClick={e => {
																								this.updateLocks(
																									"licenses",
																									this.state
																										.propertyContentLocks
																										.licenses === "LOCKED"
																										? "UNLOCKED"
																										: "LOCKED"
																								);
																							}}
																						>
																							<i
																								className={
																									this.state
																										.propertyContentLocks &&
																									this.state
																										.propertyContentLocks
																										.licenses === "LOCKED"
																										? "icon-lock"
																										: "icon-unlock"
																								}
																							/>
																						</a>
																					</OverlayTrigger>
																				</InputLabel>
																			</Col>
																			<Col md={12} sm={12} xs={12}>
																				<hr />
																			</Col>
																		</Row>
																	) : (
																		""
																	)}
																	{this.state.licenseLocationRequired &&
																		this.state.licenseLocationRequired
																			.isRequired && (
																			<Row>
																				<Col md={6} sm={12} xs={12}>
																					<div className="license-container form-validation">
																						<span className="required-error-text">
																							{this.state
																								.licenseLocationRequired &&
																								this.state
																									.licenseLocationRequired
																									.message}
																						</span>
																					</div>
																				</Col>
																			</Row>
																		)}
																	<Row className="bottom-margin-5">
																		<Col md={8} sm={12} xs={12}>
																			{this.createLicenseConfig()}
																		</Col>
																	</Row>
																	{isAdmin && (
																		<Row>
																			<Col md={3} sm={12} className="pull-left">
																				<PrimaryButton
																					cssClass="blue-btn "
																					fullWidth={true}
																					onClick={this.createLicense}
																					disabled={
																						this.state.data.licenses.length ===
																						4
																					}
																				>
																					<i className="icon-Plus create-icon" />{" "}
																					Add{" "}
																					{this.state.data.licenses.length !== 0
																						? "additional "
																						: ""}
																					license
																				</PrimaryButton>
																			</Col>
																		</Row>
																	)}
																	{this.state.propertyExemption ===
																		"propertyExemption" && (
																		<Row>
																			<Col md={6} sm={12} xs={12}>
																				<div
																					className={`license-container ${
																						formValidation.exemption_reason
																							? "form-validation"
																							: ""
																					}`}
																				>
																					<Row>
																						<Col md={12} sm={12} xs={12}>
																							<InputLabel id="exemption_reason">
																								Exemption Reason
																							</InputLabel>
																							<InputField
																								type="select"
																								value={
																									this.state.exemption_reason
																								}
																								name="exemption_reason"
																								onChange={e =>
																									this.exemptionReasonOnChange(
																										e
																									)
																								}
																							>
																								{exemptionReasons}
																							</InputField>
																						</Col>
																					</Row>
																				</div>
																				{formValidation.exemption_reason && (
																					<span className="required-error-text">
																						Exemption Reason is required
																					</span>
																				)}
																			</Col>
																		</Row>
																	)}
																	<hr />
																</Fragment>
															)}
															{this.state.data.location.address.country ===
																"FR" && (
																<Row>
																	<Col sm={6} xs={6}>
																		<InputLabel
																			id="residency_category"
																			className={
																				formValidation.residency_category &&
																				"form-validation"
																			}
																		>
																			Residency Category (France){" "}
																			{formValidation.residency_category &&
																				" is Required"}
																		</InputLabel>
																		{this.createDefaultRadioButton(
																			residencyCategory,
																			this.state.data.residency_category
																		)}
																	</Col>
																</Row>
															)}
														</Panel.Body>
													</Panel>
													{this.state.mode !== "create" && (
														<Panel
															eventKey={this.state.init ? "1" : "7"}
															className="currency-panel"
														>
															<Panel.Heading>
																<Panel.Title toggle id="feeSchedule">
																	Fee Schedule
																	{this.renderChevronIcon("7")}
																</Panel.Title>
															</Panel.Heading>
															<Panel.Body collapsible>
																<PropertiesFeesSchedule
																	isInternalPropertyAdmin={
																		this.isInternalPropertyAdmin
																	}
																	feesList={this.state.feesList}
																	isAdmin={isAdmin}
																	getFeesList={this.getFeesList}
																/>
															</Panel.Body>
														</Panel>
													)}
													{!isAdmin && this.state.photos.length === 0 ? (
														""
													) : (
														<Panel
															eventKey={
																this.state.init
																	? "1"
																	: this.isInternalPropertyAdmin
																	? "8"
																	: "7"
															}
															className="image-upload-section"
														>
															<Panel.Heading>
																<Panel.Title id="photos" toggle>
																	Photos
																	{this.state.photos.length > 0 && (
																		<h4 className="panel-subtext">
																			{this.state.photos.length} Photo
																			{this.state.photos.length > 1 && "s"}{" "}
																			Uploaded
																		</h4>
																	)}
																	{this.renderChevronIcon(
																		this.isInternalPropertyAdmin ? "8" : "7"
																	)}
																</Panel.Title>
															</Panel.Heading>
															<Panel.Body collapsible>
																<Row>
																	<Col md={12} className="top-margin">
																		<Row>
																			<Col xs={12}>
																				<ul className="image-upload-bulletin">
																					<li>
																						<strong>
																							The first{" "}
																							<i className="icon-star-full"></i>{" "}
																							position is your Lead Photo. This
																							is your most important photo and
																							we recommend an exterior view
																							capturing the front of the
																							property. For apartments and
																							condos, a photo that highlights
																							the view or displays the main
																							living space may be more suitable.
																						</strong>
																					</li>
																					<li>
																						<strong>
																							Photos 2-6 are your next top
																							priority photos. Include at least
																							1 living space and 1 bedroom
																							photo, as well as a photo of any
																							special feature (e.g. pool/hot
																							tub).
																						</strong>
																					</li>
																					<li>
																						<strong>
																							Click and hold while dragging your
																							photos into the appropriate sort
																							order. Your photo order will be
																							the way guests tour your property
																							in a slide show.
																						</strong>
																					</li>
																				</ul>
																			</Col>
																		</Row>
																		<Row className="top-margin">
																			<Col md={12} sm={12} xs={12}>
																				<div
																					className={`bed-config-div image-group ${
																						formValidation.photos
																							? "form-validation"
																							: ""
																					}`}
																				>
																					<PhotoGallery
																						isAdmin={isAdmin}
																						photos={this.state.photos}
																						selectedPictures={
																							this.state.selectedPictures
																						}
																						selectImage={this.selectImage}
																						changePhoto={this.changePhoto}
																						swapImages={this.swapImages}
																						updateImage={this.updateImage}
																						photoIsProcessing={
																							this.state.photoIsProcessing
																						}
																						updateLocks={this.updateLocks}
																						propertyContentLocks={
																							this.state.propertyContentLocks
																						}
																						integrationsDataByOrgName={
																							this.state
																								.integrationsDataByOrgName
																						}
																					/>
																				</div>
																				{formValidation.photos && (
																					<span className="required-error-text">
																						At least one photo is required
																					</span>
																				)}
																				{formValidation.photoFileType && (
																					<span className="required-error-text">
																						Images must be uploaded in .gif,
																						.png, .jpeg, or. jpg format.
																					</span>
																				)}
																				{formValidation.photoFileSize && (
																					<span className="required-error-text">
																						Images must be less than 50MB.
																					</span>
																				)}
																			</Col>
																		</Row>
																		{isAdmin && (
																			<Fragment>
																				<Row className="image-options">
																					{this.state.photos.length > 0 &&
																						!this.state.photoIsProcessing && (
																							<Col xs={12} sm={4}>
																								<InputField
																									type="checkbox"
																									value={
																										this.state.selectAllImages
																									}
																									name="selectAllImages"
																									cbChange={this.toggleSelectAllImages.bind(
																										this
																									)}
																								>
																									Select All
																								</InputField>
																							</Col>
																						)}
																					{this.state.selectedPictures.length >
																						0 && (
																						<Col
																							xs={12}
																							sm={8}
																							className="right-end"
																						>
																							<div className="image-btns-wrap">
																								<PrimaryButton
																									cssClass="white-btn pull-left left-margin"
																									fullWidth={false}
																									type="button"
																									onClick={
																										this.cancelSelectedImages
																									}
																								>
																									Cancel
																								</PrimaryButton>
																								<PrimaryButton
																									cssClass="primary-btn pull-left left-margin"
																									fullWidth={false}
																									type="button"
																									onClick={
																										this.deleteSelectedImages
																									}
																								>
																									Delete Selected Items (
																									{
																										this.state.selectedPictures
																											.length
																									}
																									)
																								</PrimaryButton>
																							</div>
																						</Col>
																					)}
																				</Row>
																				<Row>
																					<Col xs={12}>
																						<div className="photo-tips-container">
																							<i className="icon-light-bulb"></i>
																							<button
																								type="button"
																								className="clickable-text"
																								onClick={e =>
																									this.openPhotoTipModal(e)
																								}
																							>
																								Photo Tips
																							</button>
																						</div>
																						<ul className="image-upload-bulletin">
																							<li>
																								Photos must be of GIF, PNG,
																								JPEG, or JPG.
																							</li>
																							<li>
																								Photos larger than 25MB may not
																								be accepted by all channels.
																							</li>
																							<li>
																								Simply double-click on an image
																								to add or edit photo captions.
																							</li>
																							<li>
																								Select the desired image and
																								click "delete" button to remove
																								images.
																							</li>
																						</ul>
																					</Col>
																				</Row>
																			</Fragment>
																		)}
																	</Col>
																</Row>
															</Panel.Body>
														</Panel>
													)}
													<Panel eventKey={this.state.init ? "1" : "9"}>
														<Panel.Heading>
															<Panel.Title toggle>
																Calendar Settings
																{this.renderChevronIcon("9")}
															</Panel.Title>
														</Panel.Heading>
														<Panel.Body collapsible>
															<Row>
																<Col md={12}>
																	<InputLabel>Booking Window</InputLabel>
																</Col>
																<br />
																<br />
																<Col md={6}>
																	<InputLabel>
																		Start (hours before check-in date)
																	</InputLabel>
																	<InputField
																		type="number"
																		value={this.state.booking_lead_hours}
																		name="booking_lead_hours"
																		onChange={e =>
																			this.handleInputStateChange(e)
																		}
																	></InputField>
																</Col>
															</Row>
															<br />
															<Row>
																<Col md={6}>
																	<InputLabel>End</InputLabel>
																	<InputField
																		type="select"
																		value={this.state.price.block_calendar}
																		name="block_calendar"
																		onChange={e => this.handlePricingChange(e)}
																	>
																		{blockFeatureCalendar}
																	</InputField>
																</Col>
															</Row>
															<br />
															<Row>
																<Col md={6}>
																	<InputLabel>
																		Turnover Block{" "}
																		{this.checkinInstructionsPopover(
																			"",
																			this.turnoverBlockTooltip
																		)}
																	</InputLabel>
																	<InputField
																		type="select"
																		value={this.state.turnover_block || ""}
																		name="turnover_block"
																		onChange={e =>
																			this.handleInputStateChange(e)
																		}
																	>
																		{turnoverBlock}
																	</InputField>
																</Col>
															</Row>
															{this.state.integrationsDataByOrgName
																.integration_name === "racalendar" &&
															this.state.mode === "edit" ? (
																<Fragment>
																	<br />
																	<Row>
																		<Col sm={12}>
																			<InputLabel id="calendarSource">
																				Calendar Source
																			</InputLabel>
																			{this.createCalendarSourceRadioButton(
																				calendarSource,
																				this.state.calendarSource
																			)}
																		</Col>
																	</Row>
																	<br />
																	{(this.state.calendarSource ===
																		"ical_one_time_import" ||
																		this.state.calendarSource ===
																			"ical_source_of_truth") && (
																		<Fragment>
																			<Row>
																				<Col sm={6}>
																					<InputLabel
																						id="calendarUrl"
																						className={
																							formValidation.calendarUrl &&
																							"form-validation"
																						}
																					>
																						Calendar Address
																					</InputLabel>
																					<InputField
																						type="text"
																						value={this.state.calendar.url}
																						name="url"
																						className={
																							formValidation.calendarUrl &&
																							"form-validation"
																						}
																						onChange={e =>
																							this.handleCalendarChange(e)
																						}
																						autoComplete="off"
																					></InputField>
																					{formValidation.calendarUrl && (
																						<span className="required-error-text">
																							Calendar Address is required
																						</span>
																					)}
																				</Col>
																			</Row>
																			<br />
																			{this.state.calendarSource ===
																				"ical_source_of_truth" && (
																				<Fragment>
																					<Row>
																						<Col sm={6}>
																							<InputLabel id="updateFrequency">
																								Update Frequency
																							</InputLabel>
																							<InputField
																								type="select"
																								value={
																									this.state.calendar.frequency
																								}
																								name="frequency"
																								onChange={e =>
																									this.handleCalendarChange(e)
																								}
																							>
																								{updateFrequency}
																							</InputField>
																						</Col>
																					</Row>
																					<br />
																				</Fragment>
																			)}
																			<Row>
																				<Col sm={12}>
																					<InputLabel
																						id="use_checkout_date"
																						className={
																							formValidation.use_checkout_date &&
																							"form-validation"
																						}
																					>
																						Check-Out Date
																					</InputLabel>
																					{this.createCalendarSourceRadioButton(
																						checkOutDate,
																						this.state.calendar.use_checkout_date.toString()
																					)}
																					{formValidation.use_checkout_date && (
																						<span className="required-error-text">
																							Check-Out date Selection is
																							required
																						</span>
																					)}
																				</Col>
																			</Row>
																			<br />
																			<Row>
																				<Col
																					md={12}
																					sm={12}
																					className="top-margin pull-left"
																				>
																					<Link
																						className="clickable-text"
																						to={{
																							pathname: `/calendar/${this.state.propertyId}/edit`
																						}}
																					>
																						View Calendar
																					</Link>
																				</Col>
																			</Row>
																		</Fragment>
																	)}
																</Fragment>
															) : (
																<Fragment>
																	<br />
																	<Row>
																		<Col md={12}>
																			<InputLabel id="calendarSource">
																				Calendar Source
																			</InputLabel>
																			<Row>
																				<label className="radio-wrap radio-input">
																					<input
																						type="radio"
																						className="form-radio-label radio-label"
																						defaultChecked={
																							this.state
																								.integrationsDataByOrgName
																								.integration_name
																						}
																					/>
																					<div
																						className="radio-label"
																						style={{
																							marginLeft: "35px",
																							pointerEvents: "none"
																						}}
																					>
																						{this.state
																							.integrationsDataByOrgName
																							.integration_name === "racalendar"
																							? "Portal"
																							: this.state
																									.integrationsDataByOrgName
																									.integration_name}
																					</div>
																				</label>
																			</Row>
																		</Col>
																	</Row>
																</Fragment>
															)}
														</Panel.Body>
													</Panel>
													{this.viewReviews && this.state.mode == "edit" && (
														<Panel eventKey="reviews">
															<Panel.Heading>
																<Panel.Title toggle>
																	Reviews
																	{this.renderChevronIcon("reviews")}
																</Panel.Title>
															</Panel.Heading>
															<Panel.Body id="reviews" collapsible>
																<Row>
																	<Reviews
																		view={"property"}
																		id={this.state.propertyId}
																	/>
																</Row>
															</Panel.Body>
														</Panel>
													)}
													{(this.viewFreshDeskTickets || this.viewCrmCases) &&
														this.state.mode == "edit" && (
															<Panel eventKey="support-ticket">
																<Panel.Heading>
																	<Panel.Title toggle>
																		Support Tickets
																		{this.renderChevronIcon("support-ticket")}
																	</Panel.Title>
																</Panel.Heading>
																<Panel.Body collapsible>
																	<SupportTickets
																		level={"property"}
																		type={"freshdesk"}
																		propertyId={this.state.propertyId}
																	/>
																</Panel.Body>
															</Panel>
														)}
													{this.viewDevices && this.state.mode == "edit" && (
														<Panel eventKey={"12"}>
															<Panel.Heading>
																<Panel.Title toggle>
																	Devices
																	{this.renderChevronIcon("12")}
																</Panel.Title>
															</Panel.Heading>
															<Panel.Body collapsible>
																<Row className="dashboard">
																	<EditDevices
																		view={"property"}
																		org={this.props.org}
																		propertyId={this.state.propertyId}
																		propertyTitle={_.get(
																			this.state.propertyData,
																			"content.title"
																		)}
																		teamMemberPropertiesList={
																			this.state.teamMemberPropertiesList
																		}
																		globalTeamMembers={
																			this.state.globalTeamMembers
																		}
																		handlePropertyOptions={
																			this.handlePropertyOptions
																		}
																	/>
																</Row>
															</Panel.Body>
														</Panel>
													)}
													<Panel eventKey="guestbook">
														<Panel.Heading>
															<Panel.Title toggle>
																Welcome Guide &nbsp;
																<span className="badge">New!</span>
																{this.renderChevronIcon("13")}
															</Panel.Title>
														</Panel.Heading>
														<Panel.Body
															id="guestbook"
															className="guestbook-preview"
															collapsible
														>
															<GuestBook propertyId={this.state.propertyId} />
														</Panel.Body>
													</Panel>
												</PanelGroup>
											</Col>
										</Row>
										<Modal
											className="map-modal lg-modal"
											show={this.state.showModal === "map"}
											onHide={this.closeModal}
										>
											<Modal.Header closeButton>
												<Modal.Title>Select Map Pointer</Modal.Title>
											</Modal.Header>
											<Modal.Body>
												<GoogleMap
													mapId="map"
													autoCompleteInput="add-input"
													autoCompleteCity="city"
													autoCompleteProvince="province"
													mapOptions={this.state.mapOptions}
													baseLocation={this.state.mapBaseLocation}
													mapEvents={this.mapEvents}
													address={data.location.address}
												/>
											</Modal.Body>
										</Modal>
										{this.props.propertyData && (
											<ValidationModal
												view="edit"
												show={this.state.showValidations}
												property={this.props.propertyData}
												onHide={this.closeValidations}
												validationChannels={this.state.validationChannels}
												showValidationModalError={
													this.state.showValidationModalError
												}
												redAwningFatalError={this.state.redAwningFatalError}
											/>
										)}
										<SubmitModal
											submitModalTotal={this.state.submitModalTotal}
											activeModal={this.state.showModal}
											showModal={this.showModal}
											closeModal={this.closeSubmitModal}
											photos={this.state.submitModalPhotos}
											submitStatus={this.state.submitStatus}
											submitImageStatus={this.state.submitImageStatus}
											mobileMode={this.props.mobileMode}
											imageStatusMessage={this.state.imageStatusMessage}
											progressMessage={this.state.progressMessage}
											error={this.state.error}
											deletedPhotos={this.state.submitModalDeletedPhotos}
											triggerRedirect={this.triggerRedirect}
											propertyId={this.state.propertyId}
											mode={this.state.mode}
											collection={this.props.collection}
											collectionId={this.props.collectionId}
											failedImagesCount={this.state.failedImagesCount}
											propertiesForm={true}
											validationChannels={this.state.validationChannels}
											propertyStatus={this.state.status.published}
											history={this.props.history}
											redAwningFatalError={this.state.redAwningFatalError}
										/>
										<AddToCollection
											open={
												this.state.showModal === "Add To Collection" ||
												this.state.showModal === "Delete From Collection"
											}
											onHide={this.closeModal}
											propertyId={this.state.propertyId}
											type={this.state.showModal}
											collectionId={this.props.collectionId}
											collectionSuggestionData={
												this.state.collectionSuggestionData
											}
											collectionName={
												this.props.propertyData &&
												this.props.propertyData.repconfig &&
												this.props.propertyData.repconfig.collection_name
													? this.props.propertyData.repconfig.collection_name
													: undefined
											}
										/>
										<NavigationPrompt
											when={this.compareState()}
											onSave={this.addProperty}
										/>
										<Modal
											show={this.state.showModal == "tax-cleaning-prompt"}
											className="tax-cleaning-prompt-modal"
										>
											<Modal.Header></Modal.Header>
											<Modal.Body style={{ paddingTop: "0px" }}>
												<Row className="text-center bottom-margin">
													<Col xs={12}>
														<h2>Setting property Live.</h2>
														<h2>
															{this.state.price.tax_percent == 0 && (
																<Fragment>
																	"<strong>Tax Percent</strong>"{" "}
																</Fragment>
															)}
															{this.state.price.tax_percent == 0 &&
																this.state.price.cleaning_fee == 0 &&
																"and "}
															{this.state.price.cleaning_fee == 0 && (
																<Fragment>
																	"<strong>Total Cleaning Per Stay Fees</strong>
																	"{" "}
																</Fragment>
															)}{" "}
															has a value of <strong>0</strong>.
														</h2>
														<h2>Do you want to continue?</h2>
													</Col>
												</Row>
												<Row>
													<Col xs={12}>
														<PrimaryButton
															onClick={this.handleFormSubmit}
															cssClass="pull-right"
															fullWidth={false}
														>
															Yes
														</PrimaryButton>
														<PrimaryButton
															cssClass="white-btn pull-right right-margin"
															fullWidth={false}
															onClick={this.closeModal.bind(this)}
														>
															No
														</PrimaryButton>
													</Col>
												</Row>
											</Modal.Body>
										</Modal>
										<Modal
											show={this.state.showModal == "unpublish-prompt"}
											className="md-modal"
											onHide={this.closeModal.bind(this)}
										>
											<Modal.Header closeButton></Modal.Header>
											<Modal.Body style={{ paddingTop: "0px" }}>
												<Row className="text-center bottom-margin">
													<Col xs={12}>
														<h2>
															Please email us at{" "}
															<a
																className="clickable-text"
																href="mailto:hostsuccess@redawning.com"
															>
																hostsuccess@redawning.com
															</a>{" "}
															to unpublish your listing.
														</h2>
													</Col>
												</Row>
												<Row>
													<Col xs={12}>
														<PrimaryButton
															cssClass="pull-right right-margin"
															fullWidth={false}
															onClick={this.closeModal.bind(this)}
														>
															Ok
														</PrimaryButton>
													</Col>
												</Row>
											</Modal.Body>
										</Modal>
										<Modal
											show={this.state.showModal == "overwrite-modal"}
											onHide={this.closeModal.bind(this)}
										>
											<Modal.Header closeButton>
												<Modal.Title>
													This will overwrite the previously entered address. Do
													you want to continue?
												</Modal.Title>
											</Modal.Header>
											<Modal.Body>
												<Row>
													<Col xs={12}>
														<PrimaryButton
															cssClass="left-margin pull-right"
															fullWidth={false}
															onClick={this.overwriteAddress}
														>
															Yes
														</PrimaryButton>
														<PrimaryButton
															cssClass="white-btn pull-right margin-right-15"
															fullWidth={false}
															onClick={this.closeModal.bind(this)}
														>
															No
														</PrimaryButton>
													</Col>
												</Row>
											</Modal.Body>
										</Modal>
										<Modal
											show={this.state.showModal == "photo-tips-modal"}
											onHide={this.closeModal.bind(this)}
											className="photo-tips-modal"
										>
											<Modal.Header closeButton>
												<Modal.Title>Photo Tips</Modal.Title>
											</Modal.Header>
											<Modal.Body>
												<Row>
													<div className="individual-photo-tips-container">
														<Col xs={1}>
															<i className="icon-camera"></i>
														</Col>
														<Col xs={11}>
															<span>
																Using high-quality images will attract more
																visitors.
															</span>
														</Col>
													</div>
												</Row>
												<Row>
													<div className="individual-photo-tips-container">
														<Col xs={1}>
															<i className="icon-sun"></i>
														</Col>
														<Col xs={11}>
															<span>
																Use as much natural lighting as possible. Open
																the curtains and turn on all the lights to make
																a room look bright and open. If it's nighttime,
																turn on your lights. Avoid using flash.
															</span>
														</Col>
													</div>
												</Row>
												<Row>
													<div className="individual-photo-tips-container">
														<Col xs={1}>
															<i className="icon-image-collection"></i>
														</Col>
														<Col xs={11}>
															<span className="bottom-margin-5">
																Choose the best photos for the first six photos.
																Make sure the lead photo is the best. Include at
																least one living space and bedroom in those 6.
															</span>
															<span className="bottom-margin-5">
																Arrange and group additional photos in this
																sequence: living area, kitchen, bedroom,
																bathroom and exterior.
															</span>
														</Col>
													</div>
												</Row>
												<Row>
													<div className="individual-photo-tips-container bottom-margin">
														<Col xs={1}>
															<i className="icon-checklist"></i>
														</Col>
														<Col xs={11}>
															<span>
																Check validations after uploading photos to be
																sure the lead photo is accepted by the major
																channels.
															</span>
														</Col>
													</div>
												</Row>
												<Row>
													<Col xs={12} className="top-margin">
														<PrimaryButton
															cssClass="left-margin pull-right"
															fullWidth={false}
															onClick={this.closeModal.bind(this)}
														>
															Done
														</PrimaryButton>
													</Col>
												</Row>
											</Modal.Body>
										</Modal>
										<FaqModal
											propFormState={this}
											show={this.state.showModal}
											closeModal={this.closeModal}
										/>
										<VideoModal
											modal={videoModal}
											url={videoURL}
											closeModal={this.handleVideoModal}
										/>
									</Form>
								</FieldWrap>
							</div>
						</div>
					</Fragment>
				)}
			</div>
		);
	}
}
const mapStateToProps = state => {
	return {
		permissions: state.authState.permissions,
		isInternalPropertyAdmin: state.roleManager.isInternalPropertyAdmin,
		org: state.roleManager.org,
		expanded_organization_roles:
			state.authState.user.expanded_organization_roles,
		roles: state.roleManager.roles,
		mobileMode: state.updateMobileState.mobileMode,
		unsavedData: state.updateUnsavedData.unsavedData
	};
};

const mapDispatchToProps = dispatch => {
	return {
		updateUnsavedData: bool => {
			dispatch(updateUnsavedData(bool));
		}
	};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(PropertiesForm)
);
