import styled from 'styled-components';

//used for mobile table list items
export const KebabStyleDropdown = styled.div`
    position: absolute;
    top: 4px;
    height: 42px;
    left: 2px;
    .dropdown.open .dropdown-menu {
        display: flex;
        position: absolute;
        top: 2px;
        left: -120px;
        min-width: 100px;
        width: 120px;
        padding-left: 5px;
        padding-right: 5px;
        li {
            display: inline-block;
            text-align: center;
            flex: 1;
            a {
                padding: 0px;
            }
        }
    }
    .primary-btn.secondary-btn {
        background: none;
        color: transparent;
        padding: 0px;
        width: 10px;
        height: 40px;
        &:focus {
            border: none;
            background: none;
        }
    }
    .btn:active, .btn-group.open .dropdown-toggle {
        box-shadow: none;
    }
    .open>.dropdown-toggle.btn-default {
        background: none;
        border: none;
        &:focus, &:hover {
            color: transparent;
        }
    }
`;