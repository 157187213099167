import React, { Component } from "react";
import { connect } from "react-redux";
import { Title } from "../global/Title";
import { API } from "aws-amplify";
import queryString from "query-string";
import moment from "moment";
import { Link } from "react-router-dom";

import {
	FieldWrap,
	InputField,
	InputLabel
} from "../global/forms/FormElements";
import Form from "react-bootstrap/lib/Form";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import Panel from "react-bootstrap/lib/Panel";
import { PanelGroup } from "react-bootstrap";
import DateRangePicker from "react-dates/lib/components/DateRangePicker";
import AwesomeDebouncePromise from "awesome-debounce-promise";

import PageLayout from "../global/PageLayout";
import Loader from "../global/Loader";
import constants from "../../js/constants";
import { getSessionId } from "../../helpers/helpers";

import CreateReservationModal from "./CreateReservationModal";
import ReservationSummaryModal from "./ReservationSummaryModal";
import ReactTableComponent from "../global/ReactTableComponent";

const searchAPI = text => fetch("/search?text=" + encodeURIComponent(text));

const searchAPIDebounced = AwesomeDebouncePromise(searchAPI, 1200);

class CreateReservation extends Component {
	constructor(props) {
		super(props);
		this.reactTable = null;
		this.org = this.props.org;
		this.properties = [];
		this.breadcrumbItems = [
			{
				title: "Reservations",
				link: "/reservations"
			},
			{
				title: "Create"
			}
		];
		this.state = {
			isLoading: false,
			quoteIsLoading: false,
			bookNowIsLoading: false,
			propertyIsLoading: false,
			searchIsLoading: false,
			quoteErrMsg: "",
			bookNowSuccess: false,
			bookNowErrMsg: "",
			promotionCodeData: [],
			promotionCode: "",
			selectedProperty: {},
			selectedPropertyData: {},
			pageSize: 10,
			activePage: 1,
			totalCount: 0,
			quoteData: {},
			reservationData: {},
			summaryData: {},
			searchData: [],
			searchDates: {
				startDate: null,
				endDate: null
			},
			summaryDates: {
				startDate: null,
				endDate: null
			},
			bedrooms: "any",
			keyword: "",
			wcAccess: false,
			freeCancel: false,
			smoking: false,
			pool: false,
			hotTub: false,
			numGuest: 1,
			bathrooms: "any",
			result: null,
			adults: 1,
			children: 0,
			showModal: false,
			petsAllowed: false,
			showSummaryModal: false,
			searchInput: null,
			summaryInput: null,
			cico: [],
			adultOptions: [],
			childOptions: [],
			addons: [],
			guestData: {
				first_name: "",
				last_name: "",
				email: "",
				phone: "",
				cell_phone: "",
				point_of_sale: "portal",
				address: {
					postal_code: "",
					country: ""
				},
				payments: [
					{
						method: "creditcard",
						method_details: {
							creditcard_number: "",
							cvv: "",
							expiration_month: "",
							expiration_year: ""
						}
					}
				]
			},
			columns: [
				{
					Header: "Property ID",
					accessor: "entity_id",
					maxWidth: 150,
					sortable: false,
					Cell: row => {
						const propertyId = row && row.original && row.original.entity_id;

						return (
							<div>
								<Link
									className="clickable-text"
									to={{ pathname: `/properties/${propertyId}/edit` }}
									target="_blank"
									onClick={e => e.stopPropagation()}
								>
									{propertyId}
								</Link>
							</div>
						);
					}
				},
				{
					Header: "Property Name",
					accessor: "label",
					sortable: false,
					minWidth: 130,
					Cell: row => {
						const label = row && row.original && row.original.label;

						return (
							<div>
								<strong>{label}</strong>
							</div>
						);
					}
				},
				{
					Header: "Bedroom(s)",
					accessor: "fs_bedrooms",
					sortable: false,
					maxWidth: 120
				},
				{
					Header: "Baths",
					accessor: "fs_bathrooms",
					sortable: false,
					maxWidth: 100
				},
				{
					Header: "Sleeps",
					accessor: "is_field_sleeps_max",
					sortable: false,
					maxWidth: 100
				},
				{
					Header: "Price",
					accessor: "fs_setfrom_price",
					sortable: false,
					maxWidth: 150,
					Cell: row => {
						const price = row && row.original && row.original.fs_setfrom_price;
						const cur = row && row.original && row.original.ss_currency;

						return (
							<div>
								<strong>
									{constants.CURRENCY[cur && cur.toUpperCase()]}
									{price}
								</strong>
							</div>
						);
					}
				}
			],
			validations: {
				first_name: false,
				last_name: false,
				email: false,
				phone: false,
				postal_code: false,
				country: false,
				creditcard_number: false,
				expiration_month: false,
				expiration_year: false,
				cvv: false
			}
		};
	}

	async componentWillMount() {
		this.setState({
			isLoading: true
		});

		await this.getPromotionCode();

		this.setState({
			isLoading: false
		});
	}

	async componentWillReceiveProps(newProps) {
		let queryStringActivePage = this.props.location.search
			? parseInt(queryString.parse(this.props.location.search).page)
			: 1;
		let newQueryStringActivePage = newProps.location.search
			? parseInt(queryString.parse(newProps.location.search).page)
			: 1;

		if (queryStringActivePage !== newQueryStringActivePage) {
			newQueryStringActivePage = !(newQueryStringActivePage - 1 < 0)
				? newQueryStringActivePage - 1
				: 0;

			await this.searchProperties(parseInt(newQueryStringActivePage));
		}
	}

	getPromotionCode = async () => {
		try {
			let response = await API.get(
				constants.RAPAPI.NAME,
				`/promotion/channel/promo_codes?organization=${this.org}`,
				{ response: true, isCognito: true }
			);

			if (response && response.data && response.data.length) {
				this.setState({
					promotionCodeData: response.data
				});
			}
		} catch (e) {
			this.setState({
				error: true
			});
		}
	};

	searchProperties = async page => {
		const {
			searchDates,
			bedrooms,
			pageSize,
			petsAllowed,
			keyword,
			wcAccess,
			freeCancel,
			smoking,
			bathrooms,
			numGuest,
			pool,
			hotTub
		} = this.state;

		this.setState({
			searchIsLoading: true,
			searchData: [],
			totalCount: 0
		});

		let baseUrl = `/search/organizations/${this.org}?query=q`;
		let searchParam = "";
		let startMoment = moment(searchDates.startDate);
		let endMoment = moment(searchDates.endDate).subtract(1, "days");
		let rows = pageSize;
		let start = (!page ? 0 : page) * pageSize;

		searchParam =
			searchParam +
			"&fq=-dm_dates:[" +
			startMoment.format("YYYY-MM-DD") +
			"T00:00:00Z TO " +
			endMoment.format("YYYY-MM-DD") +
			"T00:00:00Z]";

		if (bedrooms !== "any") {
			searchParam = searchParam + `&fq=fs_bedrooms:[${bedrooms} TO 50]`;
		}

		if (bathrooms !== "any") {
			searchParam = searchParam + `&fq=fs_bathrooms:[${bathrooms} TO 50]`;
		}

		if (numGuest) {
			searchParam = searchParam + `&fq=is_field_sleeps_max:[${numGuest} TO 50]`;
		}

		if (keyword.length) {
			searchParam = searchParam + `&fq=(spell:${keyword})`;
		}

		if (petsAllowed) {
			searchParam = searchParam + "&fq=sm_field_pets_ok:yes";
		}

		if (wcAccess) {
			searchParam = searchParam + "&fq=sm_field_handicap_accessible:yes";
		}

		if (freeCancel) {
			searchParam = searchParam + "&fq=bs_freecancel:true";
		}

		if (smoking) {
			searchParam = searchParam + "&fq=ss_smoking:yes";
		}

		if (pool) {
			searchParam = searchParam + "&fq=sm_field_pool:yes";
		}

		if (hotTub) {
			searchParam = searchParam + '&fq=sm_featured:("Hot Tub")';
		}

		searchParam = searchParam + "&rows=" + rows;
		searchParam = searchParam + "&start=" + start;

		searchParam = encodeURIComponent(searchParam);

		searchParam = searchParam + "&include_child_orgs=true";

		try {
			let resp = await API.get("wapi", baseUrl + searchParam);
			if (
				resp &&
				resp.response &&
				resp.response.docs &&
				resp.response.docs.length
			) {
				this.setState({
					searchData: resp.response.docs,
					totalCount: resp.response.numFound,
					activePage: page ? page + 1 : 1,
					searchIsLoading: false
				});
			} else {
				this.setState({
					searchIsLoading: false
				});
			}
		} catch (e) {
			this.setState({
				error: true,
				searchIsLoading: false
			});
		}
	};

	getProperty = async keyword => {
		let data = [];
		let offset = 0;
		let limit = 1;
		const tid = getSessionId();

		this.setState({
			propertyIsLoading: true
		});

		try {
			data = await API.get(
				"rapapi",
				`/properties?organization=${
					this.org
				}&limit=${limit}&offset=${offset}&tid=${tid}&keyword=${encodeURIComponent(
					keyword
				)}`,
				{ response: true, isCognito: true }
			);
			if (data && data.data && data.data.length) {
				this.setState({
					selectedPropertyData: data.data[0],
					propertyIsLoading: false
				});
			} else {
				this.setState({
					propertyIsLoading: false
				});
			}
		} catch (e) {
			console.log(e);
			this.setState({
				propertyIsLoading: false
			});
		}
	};

	handleReservationQuote = async (addons = null) => {
		const {
			promotionCode,
			searchDates,
			summaryDates,
			summaryData,
			adults,
			children
		} = this.state;
		if (addons) {
			this.setState({
				addons: addons
			});
		}
		const propertyId = summaryData.entity_id;
		const checkIn = moment(
			summaryDates.startDate ? summaryDates.startDate : searchDates.startDate
		).format("YYYY-MM-DD");
		const checkOut = moment(
			summaryDates.endDate ? summaryDates.endDate : searchDates.endDate
		).format("YYYY-MM-DD");

		this.setState({
			quoteIsLoading: true,
			quoteErrMsg: ""
		});

		try {
			const quotePayload = {
				checkin: checkIn,
				checkout: checkOut,
				numadults: adults,
				numchild: children,
				travelinsurance: false,
				addons: addons ? addons : this.state.addons
			};
			if (promotionCode) {
				quotePayload.promo_code = promotionCode;
			}
			let response = await API.post(
				constants.RAPAPI.NAME,
				`/properties/${encodeURIComponent(propertyId)}/quote?organization=${
					this.org
				}`,
				{ body: quotePayload, response: true, isCognito: true }
			);

			if (response && response.data) {
				this.setState({
					quoteData: response.data,
					quoteIsLoading: false
				});
			}
		} catch (e) {
			const errorMessage =
				e && e.response && e.response.data && e.response.data.Message;
			const genErrMsg = "Please contact Redawning Support";

			this.setState({
				quoteErrMsg: errorMessage ? errorMessage : genErrMsg,
				quoteData: {},
				quoteIsLoading: false
			});
		}
	};

	handleBookNow = async () => {
		const { promotionCode, quoteData, guestData } = this.state;

		const newValidations = this.validGuestData();

		this.setState(
			{
				validations: newValidations
			},
			async () => {
				const { validations } = this.state;

				let resp;

				const notValid = Object.values(validations).filter(x => {
					return x === true;
				});

				if (notValid.length) {
					return;
				} else {
					let guestDataCopy = JSON.parse(JSON.stringify(guestData));

					guestDataCopy.quote_id = quoteData.quote_id;
					guestDataCopy.listing_id = quoteData.listing_id;

					const countryCode = constants.PROPERTY_VIEW.COUNTRIES.filter(
						country => country.Name === guestDataCopy.address.country
					);
					guestDataCopy.address.country = countryCode.length
						? countryCode[0].Code
						: guestDataCopy.address.country;

					guestDataCopy.payments[0].currency = quoteData.currency;
					guestDataCopy.payments[0].amount = parseFloat(
						quoteData.payment_schedule[0].amount
					);

					guestDataCopy.payments[0].method_details.expiration_month = parseFloat(
						guestData.payments[0].method_details.expiration_month
					);
					guestDataCopy.payments[0].method_details.expiration_year = parseFloat(
						guestData.payments[0].method_details.expiration_year
					);

					guestDataCopy.checkin_date = quoteData.checkin_date;
					guestDataCopy.checkout_date = quoteData.checkout_date;
					guestDataCopy.campaign_source = this.org;
					guestDataCopy.organization_name = this.org;

					promotionCode.length
						? (guestDataCopy.promo_code = promotionCode)
						: "";

					this.setState({
						bookNowIsLoading: true,
						bookNowErrMsg: ""
					});

					try {
						resp = await API.post(
							constants.RAPAPI.NAME,
							`/properties/reservations`,
							{ body: guestDataCopy, response: true, isCognito: true }
						);

						this.setState({
							reservationData: resp.data,
							showSummaryModal: false,
							bookNowIsLoading: false,
							bookNowSuccess: true
						});
					} catch (e) {
						const errorMessage =
							e && e.response && e.response.data && e.response.data.Message;
						const genErrMsg = "Please contact Redawning Support";

						this.setState({
							bookNowErrMsg: errorMessage ? errorMessage : genErrMsg,
							bookNowIsLoading: false
						});
					}
				}
			}
		);
	};

	validQuoteData = () => {
		const { summaryDates, selectedProperty, adults } = this.state;

		let data = {
			property: false,
			adults: false,
			dates: false
		};

		if (!Object.keys(selectedProperty).length) {
			data.property = true;
		} else {
			data.property = false;
		}

		if (summaryDates.startDate === null || summaryDates.endDate === null) {
			data.dates = true;
		} else {
			data.dates = false;
		}

		if (adults <= 0) {
			data.adults = true;
		} else {
			data.adults = false;
		}

		return data;
	};

	validGuestData = () => {
		const { guestData, validations } = this.state;
		let validationsCopy = { ...validations };

		const required = [
			"first_name",
			"last_name",
			"email",
			"phone",
			"cell_phone"
		];

		Object.entries(guestData).map(x => {
			if (x[0] === "address") {
				if (!x[1].country.length) {
					validationsCopy.country = true;
				} else {
					validationsCopy.country = false;
				}

				if (!x[1].postal_code.length) {
					validationsCopy.postal_code = true;
				} else {
					validationsCopy.postal_code = false;
				}
			} else if (x[0] === "payments") {
				if (!x[1][0].method_details.creditcard_number.length) {
					validationsCopy.creditcard_number = true;
				} else {
					validationsCopy.creditcard_number = false;
				}

				if (!x[1][0].method_details.expiration_month.length) {
					validationsCopy.expiration_month = true;
				} else {
					validationsCopy.expiration_month = false;
				}

				if (!x[1][0].method_details.expiration_year.length) {
					validationsCopy.expiration_year = true;
				} else {
					validationsCopy.expiration_year = false;
				}

				if (!x[1][0].method_details.cvv.length) {
					validationsCopy.cvv = true;
				} else {
					validationsCopy.cvv = false;
				}
			} else if (required.includes(x[0])) {
				if (x[0] === "email") {
					if (!x[1].length || !x[1].match(constants.EMAIL_VALIDATION)) {
						validationsCopy[x[0]] = true;
					} else {
						validationsCopy[x[0]] = false;
					}
				} else {
					if (!x[1].length) {
						validationsCopy[x[0]] = true;
					} else {
						validationsCopy[x[0]] = false;
					}
				}
			}
		});

		return validationsCopy;
	};

	handleDatesOnChange = (stateName, startDate, endDate) => {
		const state = this.state[stateName];

		this.setState(
			{
				[stateName]: {
					...state,
					startDate: startDate ? startDate : null,
					endDate: endDate ? endDate : null
				}
			},
			() => {
				if (startDate !== null && endDate !== null) {
					if (stateName == "summaryDates") {
						this.handleReservationQuote();
					} else {
						if (
							this.props.location &&
							this.props.location.search &&
							this.props.location.search.includes("page")
						) {
							this.props.history.push("/reservations/create");
						} else {
							this.searchProperties();
						}
					}
				}
			}
		);
	};

	handlePropertyOnChange = value => {
		this.setState(
			{
				selectedProperty: value
			},
			async () => {
				await this.getProperty(value);
				this.handleReservationQuote();
			}
		);
	};

	handleGuestInfoModal = (e, bool) => {
		e.preventDefault();
		const { guestData } = this.state;

		this.setState({
			showModal: bool,
			bookNowErrMsg: "",
			guestData: {
				first_name: "",
				last_name: "",
				email: "",
				phone: "",
				cell_phone: "",
				point_of_sale: "portal",
				address: {
					postal_code: "",
					country: ""
				},
				payments: [
					{
						...guestData.payments[0],
						method_details: {
							...guestData.payments[0].method_details,
							creditcard_number: "",
							cvv: "",
							expiration_month: "",
							expiration_year: ""
						}
					}
				]
			},
			validations: {
				first_name: false,
				last_name: false,
				email: false,
				phone: false,
				postal_code: false,
				country: false,
				creditcard_number: false,
				expiration_month: false,
				expiration_year: false,
				cvv: false
			}
		});
	};

	handleSummaryModal = async (bool, data) => {
		const { numGuest } = this.state;

		if (data) {
			await this.getProperty(data.entity_id);
		}

		if (bool) {
			this.setState(
				{
					showSummaryModal: bool,
					summaryErrMsg: "",
					summaryData: {
						...data
					},
					adultOptions: this.setNumOptions(
						1,
						data.is_field_sleeps_max,
						"adult"
					),
					childOptions: this.setNumOptions(
						0,
						data.is_field_sleeps_max - numGuest
					),
					adults: numGuest,
					addons: []
				},
				() => {
					this.handleReservationQuote();
				}
			);
		} else {
			this.setState({
				showSummaryModal: bool,
				summaryErrMsg: "",
				adults: 1,
				children: 0,
				summaryData: {},
				summaryDates: {
					startDate: null,
					endDate: null
				},
				quoteData: {},
				promotionCode: "",
				bookNowSuccess: false,
				validations: {
					first_name: false,
					last_name: false,
					email: false,
					phone: false,
					postal_code: false,
					country: false,
					creditcard_number: false,
					expiration_month: false,
					expiration_year: false,
					cvv: false
				}
			});
		}
	};

	handleOnChange = (e, quote) => {
		const name = e.target.name,
			value = e.target.value;

		this.setState(
			{
				[name]: value
			},
			async () => {
				const { searchDates } = this.state;

				if (quote) {
					this.handleReservationQuote();
				}

				const request = () => {
					if (
						this.props.location &&
						this.props.location.search &&
						this.props.location.search.includes("page")
					) {
						this.props.history.push("/reservations/create");
					} else {
						this.searchProperties();
					}
				};

				if (
					(name == "bedrooms" ||
						name == "keyword" ||
						name == "numGuest" ||
						name == "bathrooms") &&
					searchDates.startDate &&
					searchDates.endDate
				) {
					if (name === "keyword") {
						const result = await searchAPIDebounced(value);
						this.setState({ result });
						request();
					} else {
						request();
					}
				}
			}
		);
	};

	handleGuestDataOnChange = e => {
		const { guestData, validations } = this.state;
		const name = e.target.name,
			value = e.target.value;

		if (name === "postal_code" || name === "country") {
			this.setState(
				{
					guestData: {
						...guestData,
						address: {
							...guestData.address,
							[name]: value
						}
					}
				},
				() => {
					if (validations[name] == true) {
						const newValidations = this.validGuestData();

						this.setState({
							validations: newValidations
						});
					}
				}
			);
		} else {
			this.setState(
				{
					guestData: {
						...guestData,
						[name]: value
					}
				},
				() => {
					if (validations[name] == true) {
						const newValidations = this.validGuestData();

						this.setState({
							validations: newValidations
						});
					}
				}
			);
		}
	};

	handlePaymentOnChange = e => {
		const { guestData, validations } = this.state;
		const name = e.target.name,
			value = e.target.value;

		this.setState(
			{
				guestData: {
					...guestData,
					payments: [
						{
							...guestData.payments[0],
							method_details: {
								...guestData.payments[0].method_details,
								[name]: value
							}
						}
					]
				}
			},
			() => {
				if (validations[name] == true) {
					const newValidations = this.validGuestData();

					this.setState({
						validations: newValidations
					});
				}
			}
		);
	};

	errMsg = (modal, errorData) => {
		if (errorData.length) {
			return (
				<Row>
					<Col xs={12} sm={12}>
						<div className="msg-container _error">{errorData}</div>
					</Col>
				</Row>
			);
		}
	};

	clearGuestData = () => {
		this.setState({
			clearGuestData: {},
			guestData: {
				first_name: "",
				last_name: "",
				email: "",
				phone: "",
				cell_phone: "",
				point_of_sale: "portal",
				address: {
					postal_code: "",
					country: ""
				},
				payments: [
					{
						method: "creditcard",
						method_details: {
							creditcard_number: "",
							cvv: "",
							expiration_month: "",
							expiration_year: ""
						}
					}
				]
			}
		});
	};

	disableCheckOutbtn = () => {
		const requiredQuoteData = this.validQuoteData();

		if (
			!requiredQuoteData.property &&
			!requiredQuoteData.dates &&
			!requiredQuoteData.adults
		) {
			return false;
		} else {
			return true;
		}
	};

	getRef = r => {
		this.reactTable = r;
	};

	handleDateInput = (name, input) => {
		this.setState({
			[name]: input
		});
	};

	pageOnChange = page => {
		this.props.history.push(`/reservations/create?page=${page}`);
	};

	handleCheckbox = state => {
		this.setState(
			{
				[state]: !this.state[state]
			},
			() => {
				const { searchDates } = this.state;
				if (searchDates.startDate && searchDates.endDate) {
					if (
						this.props.location &&
						this.props.location.search &&
						this.props.location.search.includes("page")
					) {
						this.props.history.push("/reservations/create");
					} else {
						this.searchProperties();
					}
				}
			}
		);
	};

	clearSearch = () => {
		this.setState(
			{
				searchDates: {
					startDate: null,
					endDate: null
				},
				bedrooms: "any",
				bathrooms: "any",
				numGuest: 1,
				searchData: [],
				petsAllowed: false,
				keyword: "",
				wcAccess: false,
				freeCancel: false,
				smoking: false,
				pool: false,
				hotTub: false
			},
			() => {
				this.props.history.push("/reservations/create");
			}
		);
	};

	setNumOptions = (startOpt = 0, numOpt = 0) => {
		const results = [];
		for (let i = startOpt; i <= numOpt; i++) {
			results.push(
				<option key={i} value={i}>
					{i}
				</option>
			);
		}
		return results;
	};

	handleAdultChildrenOnChange = (e, maxSleep) => {
		let name = e.target.name;
		let value = e.target.value;

		if (name == "adults") {
			this.setState(
				{
					adults: value,
					childOptions: this.setNumOptions(0, maxSleep - value)
				},
				() => {
					this.handleReservationQuote();
				}
			);
		} else {
			this.setState(
				{
					children: value,
					adultOptions: this.setNumOptions(1, maxSleep - value)
				},
				() => {
					this.handleReservationQuote();
				}
			);
		}
	};

	ratesSummary = () => {
		const { quoteData, quoteIsLoading } = this.state;

		let originalPrice = 0;

		if (
			quoteData &&
			quoteData.applied_discounts &&
			quoteData.applied_discounts.length > 0
		) {
			originalPrice =
				quoteData.original_rental_price +
				quoteData.original_tax +
				quoteData.cleaning_fee +
				quoteData.service_fee /
					(1 - Math.abs(quoteData.applied_discounts[0].value) / 100);
		}

		let total = 0;

		if (
			quoteData &&
			quoteData.payment_schedule &&
			quoteData.payment_schedule.length > 0
		) {
			quoteData.payment_schedule.map(payment => {
				total = total + payment.amount;
			});
		}

		return (
			<Row>
				<Col xs={12} sm={12}>
					<div className={`rates-summary ${quoteIsLoading && "isLoading"}`}>
						{quoteIsLoading && <Loader />}
						<h2 className="detail-label">
							<strong>Rates Summary</strong>
						</h2>
						<div className="reservation-billing">
							<h4>Rental Price</h4>
							<div className="reservation-billing-border"></div>
							<h4
								className={
									quoteData &&
									quoteData.applied_discounts &&
									quoteData.applied_discounts.length > 0 &&
									"discount-price"
								}
							>
								{quoteData &&
									quoteData.applied_discounts &&
									quoteData.applied_discounts.length > 0 && (
										<span className="line-through">{`$ ${
											quoteData && quoteData.original_rental_price
												? quoteData.original_rental_price.toFixed(2)
												: 0
										}`}</span>
									)}
								<span
									className={
										quoteData &&
										quoteData.applied_discounts &&
										quoteData.applied_discounts.length > 0 &&
										"bold"
									}
								>{`$ ${
									quoteData && quoteData.rental_price
										? quoteData.rental_price.toFixed(2)
										: 0
								}`}</span>
							</h4>
						</div>
						<div className="reservation-billing">
							<h4>Cleaning Fee</h4>
							<div className="reservation-billing-border"></div>
							<h4>
								<span>{`$ ${
									quoteData && quoteData.cleaning_fee
										? quoteData.cleaning_fee.toFixed(2)
										: 0
								}`}</span>
							</h4>
						</div>
						<div className="reservation-billing">
							<h4>Service Fee</h4>
							<div className="reservation-billing-border"></div>
							<h4>
								<span>{`$ ${
									quoteData && quoteData.service_fee
										? quoteData.service_fee.toFixed(2)
										: 0
								}`}</span>
							</h4>
						</div>
						<div className="reservation-billing">
							<h4>Tax</h4>
							<div className="reservation-billing-border"></div>
							<h4>
								<span>{`$ ${
									quoteData && quoteData.tax ? quoteData.tax.toFixed(2) : 0
								}`}</span>
							</h4>
						</div>
						<div className={`reservation-billing`}>
							<h4>
								<strong>Total</strong>
								{quoteData &&
									quoteData.applied_discounts &&
									quoteData.applied_discounts.length > 0 && (
										<span className="discount-title">
											(
											{quoteData &&
											quoteData.applied_discounts &&
											quoteData.applied_discounts[0] &&
											quoteData.applied_discounts[0].name
												? quoteData.applied_discounts[0].name
												: ""}
											)
										</span>
									)}
							</h4>
							<div className="reservation-billing-border"></div>
							<h4
								className={
									quoteData &&
									quoteData.applied_discounts &&
									quoteData.applied_discounts.length > 0 &&
									"discount-price"
								}
							>
								{quoteData &&
									quoteData.applied_discounts &&
									quoteData.applied_discounts.length > 0 && (
										<span className="line-through">
											{`$ ${originalPrice.toFixed(2)}`}
										</span>
									)}
								<strong>{`$ ${total.toFixed(2)}`}</strong>
							</h4>
						</div>
					</div>
				</Col>
			</Row>
		);
	};

	render() {
		const {
			isLoading,
			summaryDates,
			promotionCode,
			promotionCodeData,
			quoteIsLoading,
			quoteData,
			quoteErrMsg,
			showModal,
			adults,
			petsAllowed,
			guestData,
			bookNowSuccess,
			bookNowErrMsg,
			bookNowIsLoading,
			selectedPropertyData,
			searchData,
			pageSize,
			activePage,
			columns,
			totalCount,
			searchInput,
			searchDates,
			bedrooms,
			searchIsLoading,
			summaryInput,
			showSummaryModal,
			summaryData,
			propertyIsLoading,
			adultOptions,
			childOptions,
			keyword,
			wcAccess,
			freeCancel,
			smoking,
			bathrooms,
			numGuest,
			pool,
			hotTub,
			validations,
			reservationData
		} = this.state;

		let bedroomsOption = [
			<option key={"any"} value={"any"}>
				Any
			</option>
		];
		for (let i = 0; i <= constants.PROPERTY_VIEW.OPT_MAX_VALUE; i++) {
			bedroomsOption.push(
				<option key={i} value={i}>
					{i == 0 ? "Studio" : i}
				</option>
			);
		}

		let bathroomsOption = [
			<option key={"any"} value={"any"}>
				Any
			</option>
		];
		for (let i = 1; i <= constants.PROPERTY_VIEW.OPT_MAX_VALUE; i++) {
			bathroomsOption.push(
				<option key={i} value={i}>
					{i}
				</option>
			);
		}

		let promotions = [
			<option key={0} value={""}>
				None
			</option>
		];
		if (promotionCodeData.length) {
			for (let i = 0; i < promotionCodeData.length; i++) {
				const org_promo_id = promotionCodeData[i].org_promo_id;

				promotions.push(
					<option key={i + 1} value={org_promo_id}>
						{org_promo_id}
					</option>
				);
			}
		}

		let defaultOption = [];
		for (let i = 1; i <= constants.PROPERTY_VIEW.OPT_MAX_VALUE; i++) {
			defaultOption.push(
				<option key={i} value={i}>
					{i}
				</option>
			);
		}

		return (
			<PageLayout isCentered={true}>
				{(isLoading || propertyIsLoading) && <Loader />}
				<Title
					title="Create Reservation"
					isCentered={true}
					breadcrumbItems={this.breadcrumbItems}
				></Title>
				{!isLoading && (
					<div id="content" className="create-reservation">
						<div className="property-item row">
							<FieldWrap>
								<Form horizontal>
									<Col md={12} xs={12} sm={12} lg={12}>
										<PanelGroup id="reservation-panel" defaultActiveKey={1}>
											<Panel eventKey="1">
												<Panel.Heading>
													<Panel.Title>Reservation Details</Panel.Title>
												</Panel.Heading>
												<Panel.Body>
													<Row>
														<Col md={12} sm={12} xs={12}>
															<Col md={6} sm={6} xs={6} className="start-date">
																<InputLabel>Check-In</InputLabel>
															</Col>
															<Col md={6} sm={6} xs={6}>
																<InputLabel>Check-Out</InputLabel>
															</Col>
														</Col>
													</Row>
													<Row>
														<Col md={12} sm={12}>
															<DateRangePicker
																noBorder
																startDate={searchDates.startDate}
																startDateId="startexcluded"
																startDatePlaceholderText="MM/DD/YYYY"
																endDate={searchDates.endDate}
																endDateId="endexcluded"
																endDatePlaceholderText="MM/DD/YYYY"
																focusedInput={searchInput}
																isOutsideRange={day =>
																	day.isBefore(
																		moment.utc().subtract(10, "hours")
																	)
																}
																onFocusChange={searchInput =>
																	this.handleDateInput(
																		"searchInput",
																		searchInput
																	)
																}
																onDatesChange={({ startDate, endDate }) =>
																	this.handleDatesOnChange(
																		"searchDates",
																		startDate,
																		endDate
																	)
																}
															/>
														</Col>
													</Row>
													<br />
													<Row>
														<Col md={12} sm={12} xs={12}>
															<Col md={6} sm={6} xs={6} className="no-pad-left">
																<InputLabel>Bedroom(s)</InputLabel>
															</Col>
															<Col md={6} sm={6} xs={6}>
																<InputLabel>Guests</InputLabel>
															</Col>
														</Col>
													</Row>
													<Row className="bottom-margin">
														<Col md={12} sm={12} xs={12}>
															<Col md={6} sm={6} xs={6} className="no-pad-left">
																<InputField
																	type="select"
																	value={bedrooms}
																	name="bedrooms"
																	onChange={e => this.handleOnChange(e)}
																>
																	{bedroomsOption}
																</InputField>
															</Col>
															<Col
																md={6}
																sm={6}
																xs={6}
																className="no-pad-right"
															>
																<InputField
																	type="select"
																	value={numGuest}
																	name="numGuest"
																	onChange={e => this.handleOnChange(e)}
																>
																	{defaultOption}
																</InputField>
															</Col>
														</Col>
													</Row>
													<Row>
														<Col md={12} sm={12} xs={12}>
															<Col md={6} sm={6} xs={6} className="no-pad-left">
																<InputLabel>Bathroom(s)</InputLabel>
															</Col>
															<Col md={6} sm={6} xs={6}>
																<InputLabel>Keyword</InputLabel>
															</Col>
														</Col>
													</Row>
													<Row className="bottom-margin">
														<Col md={12} sm={12} xs={12}>
															<Col md={6} sm={6} xs={6} className="no-pad-left">
																<InputField
																	type="select"
																	value={bathrooms}
																	name="bathrooms"
																	onChange={e => this.handleOnChange(e)}
																>
																	{bathroomsOption}
																</InputField>
															</Col>
															<Col
																md={6}
																sm={6}
																xs={6}
																className="no-pad-right"
															>
																<InputField
																	type="text"
																	value={keyword}
																	name="keyword"
																	onChange={e => this.handleOnChange(e)}
																/>
															</Col>
														</Col>
													</Row>
													<Row>
														<Col md={6} sm={12} xs={12}>
															<Col md={6} sm={6} xs={12}>
																<div className="filters-wrap">
																	<InputField
																		type="checkbox"
																		value={petsAllowed}
																		cbChange={() =>
																			this.handleCheckbox("petsAllowed")
																		}
																	>
																		Pets Allowed
																	</InputField>
																</div>
															</Col>
															<Col md={6} sm={6} xs={12}>
																<div className="filters-wrap">
																	<InputField
																		type="checkbox"
																		value={smoking}
																		cbChange={() =>
																			this.handleCheckbox("smoking")
																		}
																	>
																		Smoking
																	</InputField>
																</div>
															</Col>
															<Col md={6} sm={6} xs={12}>
																<div className="filters-wrap">
																	<InputField
																		type="checkbox"
																		value={freeCancel}
																		cbChange={() =>
																			this.handleCheckbox("freeCancel")
																		}
																	>
																		Free Cancellation
																	</InputField>
																</div>
															</Col>
															<Col md={6} sm={6} xs={12}>
																<div className="filters-wrap">
																	<InputField
																		type="checkbox"
																		value={wcAccess}
																		cbChange={() =>
																			this.handleCheckbox("wcAccess")
																		}
																	>
																		Wheelchair Accessible
																	</InputField>
																</div>
															</Col>
														</Col>
														<Col md={6} sm={12} xs={12}>
															<Col md={6} sm={6} xs={12}>
																<div className="filters-wrap">
																	<InputField
																		type="checkbox"
																		value={pool}
																		cbChange={() => this.handleCheckbox("pool")}
																	>
																		Pool
																	</InputField>
																</div>
															</Col>
															<Col md={6} sm={6} xs={12}>
																<div className="filters-wrap">
																	<InputField
																		type="checkbox"
																		value={hotTub}
																		cbChange={() =>
																			this.handleCheckbox("hotTub")
																		}
																	>
																		Hot Tub
																	</InputField>
																</div>
															</Col>
														</Col>
													</Row>
													<Row>
														<Col md={12} sm={12} xs={12}>
															<span
																className="clickable-text pull-right"
																onClick={this.clearSearch}
															>
																Clear Search
															</span>
														</Col>
													</Row>
													<Row className="top-margin">
														<Col md={12} sm={12}>
															<ReactTableComponent
																apiBase={true}
																getRef={this.getRef}
																reactTable={this.reactTable}
																className="-highlight"
																noDataText={
																	searchIsLoading
																		? "Loading..."
																		: "No Properties Found."
																}
																minRows={0}
																showPagination={true}
																data={searchData}
																resizable={false}
																pageSize={pageSize}
																activePage={activePage}
																columns={columns}
																loading={searchIsLoading}
																totalCount={Number(totalCount)}
																pageSizeOnChange={pageSize =>
																	this.pageSizeOnChange(pageSize)
																}
																pageOnChange={page => this.pageOnChange(page)}
																getTrProps={(state, rowInfo, column) => {
																	return {
																		onClick: (e, handleOriginal) => {
																			this.handleSummaryModal(
																				true,
																				rowInfo.original
																			);
																		},
																		style: {
																			cursor: "pointer"
																		}
																	};
																}}
															/>
														</Col>
													</Row>
													<br />
												</Panel.Body>
											</Panel>
										</PanelGroup>
									</Col>
								</Form>
							</FieldWrap>
						</div>
						<ReservationSummaryModal
							ratesSummary={this.ratesSummary}
							errMsg={this.errMsg}
							showSummaryModal={showSummaryModal}
							summaryDates={summaryDates}
							summaryInput={summaryInput}
							adults={adults}
							promotionCodeData={promotionCodeData}
							promotionCode={promotionCode}
							promotions={promotions}
							quoteErrMsg={quoteErrMsg}
							quoteIsLoading={quoteIsLoading}
							summaryData={summaryData}
							handleDatesOnChange={this.handleDatesOnChange}
							handleDateInput={this.handleDateInput}
							handleSummaryModal={this.handleSummaryModal}
							handleOnChange={this.handleOnChange}
							handleGuestInfoModal={this.handleGuestInfoModal}
							selectedPropertyData={selectedPropertyData}
							searchDates={searchDates}
							handleAdultChildrenOnChange={this.handleAdultChildrenOnChange}
							handleReservationQuote={this.handleReservationQuote}
							adultOptions={adultOptions}
							childOptions={childOptions}
							quoteData={quoteData}
							isInternalPropertyAdmin={this.props.isInternalPropertyAdmin}
						/>
						<CreateReservationModal
							reservationData={reservationData}
							quoteData={quoteData}
							guestData={guestData}
							bookNowSuccess={bookNowSuccess}
							bookNowErrMsg={bookNowErrMsg}
							bookNowIsLoading={bookNowIsLoading}
							showModal={showModal}
							ratesSummary={this.ratesSummary}
							errMsg={this.errMsg}
							handleGuestInfoModal={this.handleGuestInfoModal}
							handlePaymentOnChange={this.handlePaymentOnChange}
							handleGuestDataOnChange={this.handleGuestDataOnChange}
							handleBookNow={this.handleBookNow}
							disableCheckOutbtn={this.disableCheckOutbtn}
							handleSummaryModal={this.handleSummaryModal}
							clearGuestData={this.clearGuestData}
							selectedPropertyData={selectedPropertyData}
							history={this.props.history}
							validations={validations}
						/>
					</div>
				)}
			</PageLayout>
		);
	}
}

const mapStateToProps = state => {
	return {
		isInternalPropertyAdmin: state.roleManager.isInternalPropertyAdmin,
		org: state.roleManager.org,
		user: state.authState.user
	};
};

export default connect(mapStateToProps, null)(CreateReservation);
