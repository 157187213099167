import React, { Component } from 'react';
import moment from 'moment';
import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Modal from 'react-bootstrap/lib/Modal';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import DateRangePicker from 'react-dates/lib/components/DateRangePicker';

import { InputField, PrimaryButton, FieldWrap, InputLabel } from '../../global/forms/FormElements';

export class CicoModal extends Component {
    state = {
        focusedInput: null,
        mCheckIn: "",
        mCheckOut: "",
    }

    cicoPeriods = (day) => {
        const modal = this.props.cicoModal;

        if (day){
            if (moment(day).isBetween(modal.data.start_date, modal.data.end_date) || moment(day).isSame(modal.data.start_date) || moment(day).isSame(modal.data.end_date)) {
                return true;
            } 
        }        
    }

    handleDatesChange = ({startDate,endDate}) => {
        this.setState({
            isDisabled: false
        })
        if (startDate) {
            this.props.handlePeriodDateChange(startDate,'start_date', 'cico', {startDate,endDate})
        }
        if (endDate) {
            this.props.handlePeriodDateChange(endDate,'end_date', 'cico', {startDate,endDate})
        }
    }


    handleEmptyPlaceholder = (e, date) => {
        e.target.placeholder = ''
        e.target.value = date
    }

    handleDatedPlaceholder = (e, date) => {
        e.target.placeholder = date
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.editEntirePricePeriod !== this.props.editEntirePricePeriod || nextProps.pricingModal !== this.props.pricingModal ) {
            this.setState({
                mCheckIn: "",
                mCheckOut: ""
            });
        };
    }

    render() {
        const modal = this.props.cicoModal,
            errors = this.props.errors,
            item = this.props.getPropertyById(modal.property_id)[0];
        let isDisabled = false,
            errorMessage = '';

        if (this.state.mCheckIn === "" && this.state.mCheckOut === "") {
            isDisabled = false
        }
        else {
            isDisabled = true
        };

        Object.keys(errors).map(error => {
            if(errors[error].show) {
                isDisabled = true
                if (errors[error].type === '2') {
                    errorMessage = errors[error].message.replace(/"/g,'')
                }   
            }
        });

        return (
            <Modal 
                className='calendar-modal pricing-modal' 
                backdrop={false} 
                bsClass='modal' 
                id='pricingModal' 
                show={modal.show}
                onHide={e=>this.props.hideCicoModal()}
            >
            <Modal.Header closeButton>
                {
                    modal.property_id ?
                    <Modal.Title componentClass='h3'>#{modal.property_id}<br/>{item && item.content && item.content.title}</Modal.Title> 
                    :
                    <Modal.Title componentClass='h3'>Add Check-in / Check-out</Modal.Title>
                }
                {
                    modal.property_id ?
                    <p className='property-desc'>
                        {item && item.content && item.content.location && item.content.location.address && item.content.location.address.street_address} 
                        {item && item.content && item.content.location && item.content.location.address && item.content.location.address.street_address2}
                    </p> 
                    : 
                    ''
                }
            </Modal.Header>
                <Modal.Body>
                    <Grid fluid={true}>
                    <Row>
                        {
                            (modal.mode === 1) && 
                            !this.props.oneDayCicoPeriod &&
                            modal.view !== "list" &&
                            <Col md={12} className="bottom-margin-20">
                                <InputField
                                    cssClass="form-check-label checkbox-label"
                                    type="checkbox"
                                    value={this.props.editEntireCicoPeriod}
                                    cbChange={this.props.toggleEntireCicoPeriod}
                                >
                                    Edit Entire Check-in/Check-out Period
                                </InputField>
                            </Col>
                        }
                        <Col xs={12}>
                            <InputLabel>
                                Please enter dates in <strong>mm/dd/yyyy</strong> format.
                            </InputLabel>
                        </Col>
                        <Col xs={6}>Start Date</Col>
                        <Col xs={6}>End Date</Col>
                        <Col xs={12}>
                            {
                                item && 
                                <DateRangePicker 
                                    startDate={modal.cicoDates.startDate}
                                    startDateId='start'
                                    endDate={modal.cicoDates.endDate}
                                    endDateId='end'
                                    focusedInput={this.state.focusedInput}
                                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                                    onDatesChange={this.handleDatesChange}
                                    noBorder
                                    startDatePlaceholderText="Check-In"
                                    endDatePlaceholderText="Check-Out"
                                    minimumNights={0}
                                    isDayHighlighted={this.cicoPeriods}
                                    disabled={!this.props.admin}
                                />
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="top-margin-20">
                            <FieldWrap>
                                <InputLabel htmlFor='checkin'>Check-In Allowed &nbsp;
                                    {
                                        modal && modal.cicoLocked ?
                                        <OverlayTrigger placement='top' overlay={<Tooltip id="tip-cicolocked" className='cico_locked__tooltip'>																																							
                                            {(modal.cicoLocked && modal.cicoLocked === 'LOCKED') ? 
                                                "PMS integration is not overwriting CICO periods. This setting can be changed in Property Policies." 
                                                : 
                                                "PMS integration is overwriting CICO periods. This setting can be changed in Property Policies."}
                                            </Tooltip>}
                                        >
                                            <a href="#!">
                                                <i className={(modal.cicoLocked && modal.cicoLocked === 'LOCKED') ? 
                                                    "icon-lock dim-gray" 
                                                    : 
                                                    "icon-unlock dim-gray"}
                                                />
                                            </a>
                                        </OverlayTrigger> 
                                        : 
                                        ''
                                    }
                                </InputLabel>
                                <Row className='seven-cols'>
                                    {
                                        Object.entries(modal.data.check_in_allowed).map((day,id)=>{
                                            return (
                                                <Col xs={4} sm={1} key={id}>
                                                    <div className='badge-checkbox'>
                                                        <input 
                                                            onChange={e=>this.props.handleBadgeChange(e)} 
                                                            disabled={!this.props.admin} 
                                                            checked={day[1]} id={'checkin_'+day[0]} 
                                                            type='checkbox' 
                                                            name='check_in_allowed' 
                                                            value={day[0]}
                                                        />
                                                        <label htmlFor={'checkin_'+day[0]}>{day[0].substr(0,3)}</label>
                                                    </div>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            </FieldWrap>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <FieldWrap>
                                <InputLabel htmlFor='checkout'>Check-Out Allowed &nbsp;
                                    {
                                        modal && modal.cicoLocked ?
                                        <OverlayTrigger placement='top' overlay={<Tooltip id="tip-cicolocked" className='cico_locked__tooltip'>																																							
                                            {(modal.cicoLocked && modal.cicoLocked === 'LOCKED') ? 
                                                "PMS integration is not overwriting CICO periods. This setting can be changed in Property Policies." 
                                                : 
                                                "PMS integration is overwriting CICO periods. This setting can be changed in Property Policies."}
                                            </Tooltip>}
                                        >
                                            <a href="#!">
                                                <i className={(modal.cicoLocked && modal.cicoLocked === 'LOCKED') ? 
                                                    "icon-lock dim-gray" 
                                                    : 
                                                    "icon-unlock dim-gray"}
                                                />
                                            </a>
                                        </OverlayTrigger> 
                                        : ''
                                    }
                                </InputLabel>
                                <Row className='seven-cols'>
                                    {
                                        Object.entries(modal.data.check_out_allowed).map((day,id)=>{
                                            return (
                                                <Col xs={4} sm={1} key={id}>
                                                    <div className='badge-checkbox'>
                                                        <input 
                                                            onChange={e=>this.props.handleBadgeChange(e)} 
                                                            disabled={!this.props.admin} 
                                                            checked={day[1]} 
                                                            id={'checkout_'+day[0]} 
                                                            type='checkbox' 
                                                            name='check_out_allowed' 
                                                            value={day[0]}
                                                        />
                                                        <label htmlFor={'checkout_'+day[0]}>{day[0].substr(0,3)}</label>
                                                    </div>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                                {
                                    this.props.admin && 
                                    <Row className='top-margin-20'>
                                        {
                                            (modal.mode === 1) ?
                                            <Col xs={6}>
                                                <button 
                                                    onClick={this.props.deleteCico} 
                                                    className='btn-delete'
                                                >
                                                    Delete
                                                    <span className={'glyphicon glyphicon-refresh'+(this.props.isDeleting ? ' spinning':' hide')}>
                                                    </span>
                                                </button>
                                            </Col> :
                                            ''
                                        }
                                        <Col xs={12} sm={(modal.mode === 1) ? 6 : 12}>
                                            <PrimaryButton 
                                                disabled={isDisabled} 
                                                cssClass={(modal.mode === 1)? 'black-btn ':''} 
                                                type='button' 
                                                onClick={e =>this.props.submitCico(e, item ? item.cico : [])} 
                                                fullWidth={true}
                                            >
                                                {(modal.mode === 1) ? 'Update': 'Add'}
                                                <span className={'glyphicon glyphicon-refresh'+(this.props.loading ? ' spinning':' hide')}>
                                                </span>
                                            </PrimaryButton>
                                        </Col>
                                    </Row>
                                }
                                <Row>
                                    <Col xs={12}>
                                        <span className={errorMessage ?'error-msg':'hide'}>{errorMessage}</span>
                                    </Col>
                                </Row>
                            </FieldWrap>
                        </Col>
                    </Row>
                    </Grid>
                </Modal.Body>
            </Modal>
        )
    }
}