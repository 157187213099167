import React, { Component } from 'react';
import Link from 'react-router-dom/Link';
import { connect } from 'react-redux';
import { Title } from '../global/Title';
import { API } from 'aws-amplify';
import queryString from 'query-string';
import { CSVLink } from "react-csv";

import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import Popover from 'react-bootstrap/lib/Popover';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import { PieChart } from 'react-minimal-pie-chart';
import Modal from 'react-bootstrap/lib/Modal';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import { InputField } from '../global/forms/FormElements';
import PageLayout from '../global/PageLayout';
import Loader from '../global/Loader';
import ReactTableComponent from '../global/ReactTableComponent';
import { 
    tableSort,
    getSessionId,
    getOrgData
} from '../../helpers/helpers';

class ReportsAvailability extends Component {
    constructor(props) {
        super(props);
        this.reactTable = null;
        this.org = this.props.org;
        this.tid = getSessionId();
        this.allOrganizations = [];
        this.availabilityInfoMessage = "Percentage of nights available in the next 365 days";
        this.breadcrumbItems = [
            { title: "Reports" },
            { title: "Availability" },
        ];
        this.CSVHeaders = [
            { label: 'Property ID', key: 'property_id'},
            { label: 'Reference ID', key: 'reference_id'},
            { label: 'Property Name', key: 'title'},
            { label: 'Blocked (No pricing)', key: 'block_pct_no_pricing_365'},
            { label: 'Blocked by Manager', key: 'block_pct_by_managers_365'},
            { label: 'Blocked by Reservations', key: 'block_pct_reservation_365'},
            { label: 'Temporary Hold', key: 'block_pct_by_channel_reservation_365'},
            { label: 'Availability', key: 'avail_pct_365'},
        ];
        this.state = {
            isLoading: false,
            pageSize: 10,
            activePage: 1,
            availabilityReports: [],
            initStateAvailabilityReports: [],
            CSVReports: [],
            totalCount: 0,
            keyword: "",
            dropdownIsOpen: false,
            showAllOrg: true,
            sorted: {
                id: "avail_pct_365",
                desc: false
            },
            columns: [],
            modal: '',
            currentAvailability: {},
            pieChartData: [],
        };
    }

    updateColumns = () => {
        const { 
            showAllOrg,
            availabilityReports
        } = this.state;
        let columns = [];

        columns = [
            ...columns,
            {
                Header:() => tableSort("Property ID"),
                accessor: 'property_id',
                'Cell': row=>{
                    const propertyId = row && row.original && row.original.property_id;

                    return(
                        <div>
                            <Link 
                                className="clickable-text" 
                                to={`/calendar/${propertyId}/edit`}
                            >
                                {propertyId}
                            </Link>
                        </div>
                    )
                }
            },
            {
                Header:() => tableSort("Reference ID"),
                accessor: 'reference_id',
            },
            {
                Header:() => tableSort("Property Name"),
                accessor: 'title',
            },
            {
                Header: row=>{
                    return tableSort("Availability", '', true, {message: this.availabilityInfoMessage, title: '', placement: 'top', iconName: 'icon-info'});
                },
                'accessor': 'avail_pct_365',
                'Cell': row=>{
                    let data = row && row.original;
                    let availability = row && row.original && row.original.avail_pct_365;
                    let percentage = !isNaN(availability) ? (Math.round(availability * 100) / 100).toFixed() : 0;

                    return(
                        <div className='avail-column'>
                            <span>
                                <strong>{percentage}%</strong>
                            </span>
                            <OverlayTrigger 
                                placement='top' 
                                overlay={
                                    <Tooltip
                                        id="pie-chart-availability"
                                    >
                                        365-Day Availability
                                    </Tooltip>
                                }
                            >
                                <span
                                    className='pie-chart'
                                    onClick={() => this.handlePieChart(data, 'pie-chart-modal')}>
                                    <i className='icon-pie-chart'></i>
                                </span>
                            </OverlayTrigger>
                        </div>
                    )
                }
            }
        ];

        if(showAllOrg && (availabilityReports && availabilityReports[0] && availabilityReports[0].organization_name)) {
            let orgName = 
                {
                    Header:() => tableSort("Organization"),
                    'accessor': 'organization_name',
                    "minWidth": 100,
                    'sortable': false,
                    'Cell': row=>(
                        <div className='organization_name'>
                            <strong>{row.value}</strong>
                        </div>
                    )
                };

            columns.splice(2, 0, orgName);
        }

        return columns;
    }

    handlePieChart = (data, modalName) => {
        const avail_catalog = [
            { name: 'avail_pct_365', label: 'Available', color: '#52d726' },
            { name: 'block_pct_by_channel_reservation_365', label: 'Temporary Hold', color: '#ffaf00' },
            { name: 'block_pct_reservation_365', label: 'Blocked by Reservations', color: '#ff7300' },
            { name: 'block_pct_by_managers_365', label: 'Blocked by Manager', color: '#ffec01' },
            { name: 'block_pct_no_pricing_365', label: 'Blocked (No pricing)', color: '#fe0000' },
        ];
        let pieChartData = [];

        avail_catalog.forEach((catalog) => {
            if(data[catalog.name] && data[catalog.name] > 0) {
                pieChartData.push({
                    title: catalog.label,
                    value: data[catalog.name],
                    color: catalog.color
                });
            };
        });

        this.setState({
            pieChartData: pieChartData,
            currentAvailability: data
        }, () => {
            this.handleModal(modalName);
        });
    }

    handleModal = (name) => {
        this.setState({
            modal: name
        }, () => {
            this.setState({
                currentAvailability: {}
            });
        });
    }
    
    async componentWillMount() {
        const { user } = this.props;
        this.setState({
            isLoading: true
        });

        let queryStringActivePage = this.props.location.search ? 
        parseInt(queryString.parse(this.props.location.search).page) : 
        1;

        let orgData = getOrgData(user, this.org);

        this.allOrganizations = orgData.allOrganizations;

        await this.getAvailabilityReports(queryStringActivePage);

        this.setState({
            isLoading: false
        });
    }

    async componentWillReceiveProps(newProps) {
        let queryStringActivePage = 
            this.props.location.search ? 
            parseInt(queryString.parse(this.props.location.search).page) : 
            1;
        let newQueryStringActivePage = 
            newProps.location.search ? 
            parseInt(queryString.parse(newProps.location.search).page) : 
            1;

        if(queryStringActivePage !== newQueryStringActivePage) {
            newQueryStringActivePage = 
                !((newQueryStringActivePage - 1) < 0) ? 
                newQueryStringActivePage - 1 : 
                0;

            await this.fetchAvailabilityReports(parseInt(newQueryStringActivePage), this.state.sorted);
        }
    }

    getAvailabilityReports = async (activePage) => {
        const { 
            showAllOrg,
            pageSize,
            sorted
        } = this.state;
        let offset = (activePage - 1) * pageSize;
        let sortDesc = sorted.desc ? "-" : "+";

        try {
            let response = await API.get('rptapi',`/reports/listings_availability?organization=${showAllOrg ? encodeURIComponent(this.allOrganizations.join()) : this.org}&limit=${encodeURIComponent(pageSize)}&offset=${encodeURIComponent(offset)}&tid=${encodeURIComponent(this.tid)}&sort=${encodeURIComponent(sorted.id)}${encodeURIComponent(sortDesc)}`, { response: true, isCognito: true });

            if(response && response.data && response.data.items && response.data.items.length) {
                this.setState({
                    activePage: activePage,
                    availabilityReports: response.data.items,
                    initStateAvailabilityReports: response.data.items,
                    totalCount: response.headers["x-total-count"]
                }, () => {
                    this.setState({
                        columns: this.updateColumns(),
                    })
                });
            };
        } 
        catch(e) {
            this.setState({
                error: true
            });
        };
    }

    fetchAvailabilityReports = async (activePage, sorted, compare) => {
        const { 
            showAllOrg,
            pageSize,
            keyword,
            initStateAvailabilityReports
        } = this.state;
        this.setState({
            isLoading: true
        });

        let offset = pageSize * activePage;
        let sortDesc = sorted.desc ? "-" : "+";

        try {
            let response = await API.get('rptapi',`/reports/listings_availability?organization=${showAllOrg ? encodeURIComponent(this.allOrganizations.join()) : this.org}&limit=${encodeURIComponent(pageSize)}&offset=${encodeURIComponent(offset)}&tid=${encodeURIComponent(this.tid)}&sort=${encodeURIComponent(sorted.id)}${encodeURIComponent(sortDesc)}&keyword=${encodeURIComponent(keyword)}`, { response: true, isCognito: true });

            if(response && response.data && response.data.items) {
                this.setState({
                    activePage: activePage + 1,
                    availabilityReports: response.data.items,
                    initStateAvailabilityReports: compare ? response.data.items : initStateAvailabilityReports,
                    pageSize: pageSize,
                    totalCount: response.headers["x-total-count"],
                    sorted: {
                        ...this.state.sorted,
                        id: sorted ? sorted.id : this.state.sorted.id,
                        desc: sorted ? sorted.desc : this.state.sorted.desc,
                    },
                    isLoading: false
                }, () => {
                    this.setState({
                        columns: this.updateColumns(),
                    })
                });
            };
        } 
        catch(e) {
            this.setState({
                error: true,
                isLoading: false
            });
        };
    }

    getAvailabilityReportsCSV = async () => {
        const { 
            showAllOrg,
            keyword
        } = this.state;
        const tid = getSessionId();
        let response = [];
        let reports = [];
        let offset = 0;
        let limit = 100;
        let count = 0;
        let totalCount = 0;

        this.setState({
            isLoading: true,
            dropdownIsOpen: false
        });

        try {
            response = await API.get("rptapi", `/reports/listings_availability?organization=${showAllOrg ? encodeURIComponent(this.allOrganizations.join()) : this.org}&limit=${limit}&offset=${offset}&tid=${tid}&keyword=${encodeURIComponent(keyword)}`, { response: true, isCognito: true });
            if(response && response.data && response.data.items) {
                
                response.data.items.forEach((x) => {
                    reports = [...reports, x];
                });

                count = count + response.data.items.length;

                totalCount = response.data.pagination.total_results;


                while(count < totalCount) {
                    try {
                        offset = offset + 100;

                        response = await API.get("rptapi", `/reports/listings_availability?organization=${showAllOrg ? encodeURIComponent(this.allOrganizations.join()) : this.org}&limit=${limit}&offset=${offset}&tid=${tid}&keyword=${encodeURIComponent(keyword)}`, { response: true, isCognito: true });

                        response.data.items.forEach((x) => {
                            reports = [...reports, x];
                        });
        
                        count = count + response.data.items.length;
                    }
                    catch(e) {
                        console.log(e)
                    };
                };
                if(count == totalCount) {
                    this.CSVReportsCopy = reports;         
                    
                    this.setState({
                        CSVReports: reports,
                        isLoading: false,
                    });
                }
            };
        }
        catch(e) {
            console.log(e)
            this.setState({
                isLoading: false,
            });
        }
    }

    downloadCSV = async () => {
        const { 
            CSVReports
        } = this.state;

        if(CSVReports !== this.CSVReportsCopy) {
            await this.getAvailabilityReportsCSV();

            const CSVcomponent = document.getElementById("download-csv");
            CSVcomponent.click();
        }
        else {
            const CSVcomponent = document.getElementById("download-csv");
            CSVcomponent.click();
        }
    }

    popover = (message, title, placement, iconName) => {
        return (
            <span>
                <OverlayTrigger 
                    placement={placement}
                    overlay={<Popover
                        id="popover-basic"
                        placement={placement}
                        title={title ? title : null}
                    >
                        <p>{message}</p>
                    </Popover>}
                >
                    <div className="info-popover">
                        <i className={iconName}></i>
                    </div> 
                </OverlayTrigger>
            </span>
        )
    }

    pageOnChange = (page) => {
        this.props.history.push(`/reports/availability?page=${page}`);
    }

    toggleDropdown = () => this.setState({ dropdownIsOpen: !this.state.dropdownIsOpen })

    pageSizeOnChange = (newPageSize) => {
        if(!isNaN(newPageSize) && newPageSize.trim() !== "" && newPageSize !== this.state.pageSize) {
            this.setState({
                pageSize: parseInt(newPageSize),
            }, () => {
                this.fetchAvailabilityReports(0, this.state.sorted); 
            });
        };
    }

    onSortedChange = (newSorted) => {
        this.fetchAvailabilityReports(this.state.activePage - 1, newSorted[0]);
    }
   
    handleSearch = (e) => {
        e.preventDefault();
        this.fetchAvailabilityReports(0, this.state.sorted);
    }

    handleSearchChange = (e) => {
        let name = e.target.name;
        let val = e.target.value;

        this.setState({
            [name] : val
        });
    }

    getTooltip = (id, text) => {
        return <Tooltip id={id}>{text}</Tooltip>
    }

    getRef = (r) => {
        this.reactTable = r;
    }

    handleShowAllOrg = (name) => {
        this.setState({ 
            [name]: !this.state[name]
        }, () => {
            this.fetchAvailabilityReports(0, this.state.sorted); 
        });
    }
    
    render() {
        const { 
            modal,
            isLoading, 
            activePage, 
            pageSize,
            sorted, 
            columns,
            availabilityReports,
            dropdownIsOpen,
            CSVReports,
            totalCount,
            showAllOrg,
            pieChartData
        } = this.state;

        return(
            <PageLayout isCentered={true}>

                {isLoading && <Loader />}

                <Title 
                    title="Availability" 
                    isCentered={true} 
                    breadcrumbItems={this.breadcrumbItems}
                >
                    {
                        this.allOrganizations.length > 1 &&
                        this.org !== '_global_' && 
                        this.org !== 'travelagencies' &&
                        <div className="filters-wrap">
                            <InputField 
                                type="checkbox" 
                                value={showAllOrg} 
                                cbChange={() => this.handleShowAllOrg('showAllOrg')}
                            >
                                All Organizations
                            </InputField>
                        </div>
                    }
                    <DropdownButton  
                        title="Actions"
                        className="primary-btn white-btn"
                        id="property-actions"
                        onToggle={this.toggleDropdown}
                        open={dropdownIsOpen}
                    >
                        <MenuItem 
                            onClick={availabilityReports.length ? this.downloadCSV : null}
                            disabled={!availabilityReports.length}
                        >
                            Download CSV
                        </MenuItem>
                        <CSVLink
                            asyncOnClick={true}
                            headers={this.CSVHeaders}
                            data={CSVReports}
                            filename={`${this.org}-availability-reports.csv`}
                            target="_blank"
                            id="download-csv"
                        >
                        </CSVLink>
                    </DropdownButton>
                    <form 
                        className='search-inp'
                        onSubmit={e => this.handleSearch(e)}
                    >
                        <InputField 
                            type='searchbox' 
                            placeholder='Search' 
                            name='keyword'
                            value={this.state.keyword}
                            onChange={e => this.handleSearchChange(e)}
                            clearSearch={()=> {
                                let compareAvailabilityData = this.state.availabilityReports === this.state.initStateAvailabilityReports;
                                this.setState({
                                    keyword: ''
                                }, !compareAvailabilityData ? () => this.fetchAvailabilityReports(0, this.state.sorted, true) : null);
                            }} 
                        />
                    </form>
                </Title>
                <div id="content" className='reports-availability'>
                    <div className="container-fluid">
                        <div className="reports_channels">
                            <ReactTableComponent
                                apiBase={true}
                                getRef={this.getRef}
                                reactTable={this.reactTable}
                                className='-highlight'
                                noDataText={isLoading ? "Loading..." : "No Reports found. Please contact Customer Support."}
                                minRows={0}
                                showPagination={true}
                                data={availabilityReports}
                                resizable={false}
                                pageSize={pageSize}
                                activePage={activePage}
                                columns={columns}
                                defaultSortDesc={true}
                                defaultSorted={[sorted]}
                                totalCount={Number(totalCount)}
                                pageSizeOnChange={(pageSize) => this.pageSizeOnChange(pageSize)}
                                pageOnChange={(page) => this.pageOnChange(page)}
                                onSortedChange={this.onSortedChange}
                            />
                        </div>
                    </div>
                </div>
                <Modal
                    className="pie-chart-modal"
                    show={modal === "pie-chart-modal"} 
                    onHide={() => this.handleModal('')}
                >
                    <Modal.Header closeButton>
                        <Row className="text-center">
                            <Col md={12}>
                                <h1><strong>365-Day Availability</strong></h1>
                            </Col>
                        </Row>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={12}>
                                <PieChart
                                    data={pieChartData}
                                    label={({ dataEntry }) => `${dataEntry.value}%`}
                                    labelStyle={{
                                        fontSize: '5px',
                                        fill: '#121212',
                                        fontWeight: 'bold'
                                    }}
                                    labelPosition={70}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className='legend-container'>
                                {
                                    pieChartData.map((data, i) => {
                                        return (
                                            <div className='legend' key={i}>
                                                <span 
                                                    className='legend-circle'
                                                    style={{
                                                        backgroundColor: data.color
                                                    }}
                                                ></span>
                                                <span className='legend-text'>{data.title}</span>
                                            </div>
                                        )   
                                    })
                                }
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </PageLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        org: state.roleManager.org,
        user: state.authState.user,
    }
}

export default connect(
    mapStateToProps,
    null
)(ReportsAvailability);