import React, { Component, Fragment } from "react";
import { Title } from "../global/Title";
import { API } from "aws-amplify";
import axios from "axios";
import { delay } from "../../js/actions";
import Dropzone from "react-dropzone";
import Link from "react-router-dom/Link";
import { connect } from "react-redux";
import {
	InputLabel,
	InputField,
	PrimaryButton
} from "../global/forms/FormElements";
import {
	updateOrganization,
	updateOrganizationTitle,
	updateOrganizationId,
	setRole
} from "../../js/actions/index";
import queryString from "query-string";
import Modal from "react-bootstrap/lib/Modal";
import PageLayout from "../global/PageLayout";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import PanelGroup from "react-bootstrap/lib/PanelGroup";
import Panel from "react-bootstrap/lib/Panel";
import Loader from "../global/Loader";
import constants from "../../js/constants";
import DropdownButton from "react-bootstrap/lib/DropdownButton";
import MenuItem from "react-bootstrap/lib/MenuItem";
import moment from "moment";
import Switch from "react-switch";
import OverlayTrigger from "react-bootstrap/lib/OverlayTrigger";
import Tooltip from "react-bootstrap/lib/Tooltip";
import {
	tableSort,
	infoPopover,
	getSessionId,
	suggestReplyBtn
} from "../../helpers/helpers";
import DateRangePicker from "react-dates/lib/components/DateRangePicker";
import ReactTableComponent from "../global/ReactTableComponent";
import { DatePicker } from "../global/forms/SingleDatePicker";
import _ from "lodash";
import Picky from "react-picky";
import "react-picky/dist/picky.css";

import Pdf from "../../assets/icons/icon-pdf.png";
import File from "../../assets/icons/icon-file.png";
import ImageFile from "../../assets/icons/icon-image-file.png";
import Docs from "../../assets/icons/icon-docs.png";
import Sheets from "../../assets/icons/icon-sheets.png";
import PropertyRoomInfo from "../content/properties/PropertyRoomInfo";
import SupportTickets from "./SupportTickets";

const ContactGSMessage = props => {
	const details = props.details;
	const showAddFees = props.showAddFees;
	const details_rid = details.reservation_id;
	const isRestrictedFee = props.isRestrictedFee;
	if (
		showAddFees &&
		details_rid &&
		(!details.addl_fee_payment_options || isRestrictedFee)
	) {
		return (
			<div>
				<h4>
					Contact{" "}
					<a
						className="clickable-text"
						href={`mailto:guest@redawning.com?subject=Request to add a fee.&body=I am requesting an additional fee for reservation id: ${details_rid}."`}
					>{`guest@redawning.com`}</a>{" "}
					to add a fee.
				</h4>
			</div>
		);
	} else {
		return null;
	}
};

const AddFeesButton = props => {
	const details = props.details;
	const showAddAdditionalFeesModal = props.showAddAdditionalFeesModal;
	const noPayProcList = props.noPayProcList;
	const showAddFees = props.showAddFees;
	const isAdmin = props.isAdmin;
	const guestServices = props.guestServices;
	const isRestrictedFee = props.isRestrictedFee;
	const isShow =
		!isRestrictedFee &&
		(isAdmin || guestServices) &&
		showAddFees &&
		details.addl_fee_payment_options &&
		(details.addl_fee_payment_options.includes("invoice") ||
			details.addl_fee_payment_options.includes("card_on_file"));

	const [loading, setLoading] = React.useState(false);
	const handleOnClick = () => {
		setLoading(true);
		Promise.all([
			props.handleCheckCommissionAdditionalFee(),
			props.handleOptionalFees()
		]).finally(() => {
			setLoading(false);
			showAddAdditionalFeesModal();
		});
	};

	if (isShow) {
		return (
			<Fragment>
				{loading ? (
					<div className="invoice-container">
						<Loader />
					</div>
				) : (
					<PrimaryButton
						cssClass="blue-btn "
						onClick={handleOnClick}
						disabled={
							details.campaign_source &&
							noPayProcList.includes(details.campaign_source.toLowerCase())
						}
					>
						<i className="icon-Plus create-icon" /> Add a Fee
					</PrimaryButton>
				)}
				<div>
					<br />
				</div>
			</Fragment>
		);
	} else {
		return <div></div>;
	}
};

/** Property reservation detail page */
class PropertyReservationDetail extends Component {
	baseUrl = `${window.location.hostname}`;
	pingInterval = null;
	org = this.props.org;
	reviewAccess = false;
	isAdmin =
		this.props.roles.indexOf(constants.USER_TYPES.PROPERTY_MANAGER_ADMIN) > -1;
	guestServices =
		this.props.roles.indexOf(constants.USER_TYPES.GUEST_SERVICES) > -1;
	showAddFees = true;
	transactionsTable = null;
	threadTable = null;
	scheduledActionsthreadTable = null;
	scheduledActionsthreadLogs = null;
	NOTE_TYPE = [
		{
			label: "Check-in Details",
			value: "check_in_details"
		},
		{
			label: "Host Notes",
			value: "host_notes"
		}
	];
	taxPaidInfo = "This is taxes paid on your behalf.";
	blockNightsWordMatch = ["stay", "extend", "night", "day", "additional"];
	attributeName = {
		check_in_details: "Check in Details",
		host_notes: "Host Notes"
	};
	noteName = function() {
		let option = [];
		this.NOTE_TYPE.forEach((type, i) => {
			option.push(
				<option key={i} value={type.value}>
					{type.label}
				</option>
			);
		});
		return option;
	};
	currentNote = {};
	initReservationNotes = [];
	feeDefault = "-- Select a Fee --";
	currentOrg = "";
	breadcrumbItems = [
		{
			title: "Reservations",
			link: "/reservations"
		},
		{
			title: `Reservation ${this.props.match.params.reservation_id} Details`
		}
	];
	markStatusOptions = [
		{ label: "In Progress", value: "in_progress" },
		{ label: "Complete", value: "complete" }
	];
	markPriorityOptions = [
		{ label: "Critical", value: "critical" },
		{ label: "High", value: "high" },
		{ label: "Medium", value: "medium" },
		{ label: "Low", value: "low" }
	];
	viewMessages = false;
	viewFreshDeskTickets = false;
	viewCrmCases = false;
	tidThreads = getSessionId();
	state = {
		errorMsg: "",
		commCenterAccess: false,
		init: true,
		modal: "",
		error: false,
		details: {},
		reservationNotes: [],
		totalPayment: 0,
		showInputFeeNameField: false,
		isBlockNightsWordMatch: false,
		vccData: {},
		vccShow: false,
		vccIsLoading: false,
		showTransactions: false,
		feeTypes: [
			this.feeDefault,
			"Pet",
			"Additional Guest",
			"Crib",
			"Additional Bed",
			"Early Check-In",
			"Late Check-Out",
			"Other"
		],
		feeTypesMap: {},
		fee_quantity: 1,
		fee_name: this.feeDefault,
		host_fee: 0,
		pm_promotion_detail: {
			id: 0,
			name: "",
			rental_charge_diff: 0.0,
			tax_diff: 0.0,
			ra_commission_diff: 0.0,
			cc_fee_diff: 0.0,
			type: "",
			prental_price: 0.0,
			ptax: 0.0,
			pcc_processing: 0.0,
			pra_commission: 0.0,
			total: 0.0,
			promo_code: null
		},
		feesList: "",
		isLoading: true,
		threadLoading: false,
		invoiceLoading: true,
		activeKey: 1,
		reservationId: this.props.match.params.reservation_id,
		orgRoles: this.props.user.expanded_organization_roles,
		dropdownIsOpen: false,
		isInvoiceSelected: false,
		invoice_items: [],
		invoice_items_arr: [],
		columns: [],
		pageSize: 5,
		activePage: 1,
		totalCount: "",
		mode: "",
		currentSub: {},
		showOnlyUnread: false,
		websocketSend: false,
		reviewData: {},
		reviewErrMsg: "",
		previewHTML: "",
		threadSubject: "",
		threadMessage: "",
		threadData: [],
		threadPageSize: 5,
		threadActivePage: 1,
		threadKeyword: "",
		attachments: [],
		modalData: {},
		replyTo: "",
		markStatus: [],
		markPriority: [],
		threadDates: {
			startDate: null,
			endDate: null
		},
		threadSorted: {
			id: "msg_dt",
			desc: true
		},
		priorityFilters: {
			critical: true,
			high: true,
			medium: true,
			low: false
		},
		statusFilters: {
			new: true,
			complete: true,
			in_progress: true,
			responded: true,
			reopened: true
		},
		resendActionErrMsg: "",
		cancelActionIsLoading: false,
		resendActionIsLoading: false,
		cancelActionErrMsg: "",
		scheduleMsgMode: "",
		scheduledDate: moment(),
		scheduledActionsPageSize: 10,
		scheduledActionsPage: 1,
		scheduledActionIsLoading: false,
		currentScheduledAction: "",
		scheduledActionsList: [],
		guestVerificationData: null,
		guestVerificationIsLoading: false,
		suggestedReplies: [],
		suggestedRepliesCurrentIndex: 0,
		isSuggestedReplyLoading: false,
		guestVerificationError: "",
		reservationCommData: {},
		propertyCommData: {},
		editHostNotes: false,
		hostNotesErr: "",
		hostNotes: "",
		guestVerificationModalIsLoading: false,
		guestVerificationModal: false,
		scheduledActionsLogs: [],
		scheduledActionsLogsErrMsg: "",
		scheduledActionsLogsColumns: [
			{
				Header: () => tableSort("Name"),
				accessor: "display_name",
				Cell: row => {
					const display_name = _.get(row, "original.display_name");

					return (
						<div>
							<div>{display_name}</div>
						</div>
					);
				}
			},
			{
				Header: () => tableSort("Delivery Type"),
				accessor: "delivery_type",
				Cell: row => {
					const delivery_type = _.get(row, "original.delivery_type");

					return (
						<div>
							<div className="capitalize">{delivery_type}</div>
						</div>
					);
				}
			},
			{
				Header: () => tableSort("Status"),
				accessor: "delivery_status",
				Cell: row => {
					const delivery_status = _.get(row, "original.delivery_status");

					return (
						<div>
							<div className="capitalize">
								<strong>{delivery_status}</strong>
							</div>
						</div>
					);
				}
			}
		],
		scheduledActionsColumns: [
			{
				maxWidth: 100,
				Cell: row => {
					function calculateStartIndices(totalItems, itemsPerPage) {
						let startIndices = [];

						for (let i = 0; i < totalItems; i += itemsPerPage) {
							startIndices.push(i);
						}

						return startIndices;
					}

					function calculateLastIndices(totalItems, itemsPerPage) {
						let lastIndices = [];

						for (let i = itemsPerPage - 1; i < totalItems; i += itemsPerPage) {
							lastIndices.push(Math.min(i, totalItems - 1));
						}

						if ((totalItems - 1) % itemsPerPage !== 0) {
							lastIndices.push(totalItems - 1);
						}

						return lastIndices;
					}

					const { scheduledActionsList } = this.state;
					const status = row && row.original && row.original.status;
					const currentIndex = row && row.index;
					const priorIndexStatus =
						scheduledActionsList &&
						scheduledActionsList[currentIndex - 1] &&
						scheduledActionsList[currentIndex - 1].status;
					const itemsPerPage = row && row.pageSize;
					const startIndices = calculateStartIndices(
						scheduledActionsList.length,
						itemsPerPage
					);
					const lastIndices = calculateLastIndices(
						scheduledActionsList.length,
						itemsPerPage
					);
					let topStatusType = "";
					let bottomStatusType = "";
					let msg = "";

					if (priorIndexStatus == "failed" || priorIndexStatus == "cancelled") {
						topStatusType = "failed";
					} else if (priorIndexStatus == "success") {
						topStatusType = "success";
					}

					if (status == "failed" || status == "cancelled") {
						bottomStatusType = "failed";
					} else if (status == "success") {
						bottomStatusType = "success";
					}

					if (status == "failed") {
						msg = "Failed";
					} else if (status == "cancelled") {
						msg = "Cancelled";
					} else if (status == "pending") {
						msg = "Pending";
					} else if (status == "success") {
						msg = "Sent";
					}
					return (
						<div className="time-line-container">
							{!startIndices.includes(currentIndex) && (
								<div className={`top-vertical-line ${topStatusType}`}></div>
							)}
							{!lastIndices.includes(currentIndex) && (
								<div
									className={`bottom-vertical-line ${bottomStatusType}`}
								></div>
							)}
							<OverlayTrigger
								placement="top"
								overlay={this.getTooltip("scheduled-actions-status", msg)}
							>
								<div className={`circle ${bottomStatusType}`}>
									<div className="inner-circle"></div>
								</div>
							</OverlayTrigger>
						</div>
					);
				}
			},
			{
				minWidth: 275,
				Cell: row => {
					const title = _.get(row, "original.title");
					const executed_date =
						row && row.original && row.original.executed_date;
					const due_date = row && row.original && row.original.due_date;
					const status = row && row.original && row.original.status;
					let statusMsg = "";
					let dateFormat = `${moment(due_date).format("MM/DD/YY")} at ${moment(
						due_date
					).format("hh:mm A")}`;

					if (status == "success") {
						statusMsg = "Sent";
						dateFormat = `${moment(executed_date).format(
							"MM/DD/YY"
						)} at ${moment(executed_date).format("hh:mm A")}`;
					} else if (status == "pending") {
						statusMsg = "Sending";
					}

					return (
						<div>
							<div className="bold-text">{title}</div>
							<div>{`${statusMsg} ${dateFormat}`}</div>
						</div>
					);
				}
			},
			{
				minWidth: 150,
				Cell: row => {
					const data = _.get(row, "original");

					return (
						<div>
							{_.includes(_.get(data, "capabilities", []), "view") && (
								<span
									className="clickable-text sbold-text"
									onClick={e => this.handleViewTemplate(e, data)}
								>
									View
								</span>
							)}
						</div>
					);
				}
			},
			{
				minWidth: 150,
				Cell: row => {
					const data = _.get(row, "original");
					const status = _.get(row, "original.status");
					const action_type = _.get(row, "original.action_type");
					let msg = "";

					if (status == "success" || status == "failed") {
						msg = "Resend";
					} else if (status == "pending" || status == "cancelled") {
						msg = "Edit Schedule";
					}

					return (
						<div>
							{(_.includes(_.get(data, "capabilities", []), "edit") ||
								_.includes(_.get(data, "capabilities", []), "replay")) &&
								action_type !== "review" && (
									<span
										className="clickable-text sbold-text"
										onClick={e =>
											this.handleScheduledActionModal(e, data, "schedule-modal")
										}
									>
										{msg}
									</span>
								)}
						</div>
					);
				}
			},
			{
				minWidth: 150,
				Cell: row => {
					const data = row && row.original;
					const status = row && row.original && row.original.status;

					return (
						<div>
							{_.includes(_.get(data, "capabilities", []), "cancel") && (
								<span
									className="clickable-text sbold-text"
									onClick={e =>
										this.handleScheduledActionModal(e, data, "cancel-modal")
									}
								>
									Cancel
								</span>
							)}
						</div>
					);
				}
			},
			{
				minWidth: 50,
				Cell: row => {
					const data = _.get(row, "original");
					const ids = _.get(row, "original.message_ids");

					return (
						<div>
							{_.includes(_.get(data, "capabilities", []), "logs") && (
								<OverlayTrigger
									placement="top"
									overlay={this.getTooltip("logs", "Logs")}
								>
									<div onClick={e => this.handleLogsModal(e, data)}>
										<i className="icon-property_details"></i>
									</div>
								</OverlayTrigger>
							)}
						</div>
					);
				}
			}
		],
		threadColumns: [
			{
				Header: () => tableSort("Last Message"),
				accessor: "msg_dt",
				sortable: true,
				maxWidth: 200,
				Cell: row => {
					const messages = _.get(row, "original.messages");
					const subject = _.get(row, "original.subject");

					if (messages.length) {
						const createdDate = _.get(
							messages[messages.length - 1],
							"created_dt"
						);
						return (
							<OverlayTrigger
								placement="top"
								overlay={subject ? this.getTooltip("subject", subject) : null}
							>
								<div>{createdDate && moment(createdDate).format("llll")}</div>
							</OverlayTrigger>
						);
					} else {
						<div></div>;
					}
				}
			},
			{
				sortable: false,
				maxWidth: 75,
				Cell: row => {
					const messages = row && row.original && row.original.messages;
					const newMessage = messages.filter(msg => msg.status == "new");
					const numberMsg = (
						<OverlayTrigger
							placement="top"
							overlay={this.getTooltip(
								"newMessage",
								`${newMessage.length} New Message`
							)}
						>
							<div className="msg-icon">
								<span className="msg-count">
									{newMessage.length > 10 ? "9+" : newMessage.length}
								</span>
							</div>
						</OverlayTrigger>
					);

					return (
						<div>
							<Col xs={2} className="no-padding">
								{newMessage.length > 0 && numberMsg}
							</Col>
						</div>
					);
				}
			},
			{
				Header: () => tableSort("Priority"),
				accessor: "priority_update_dt ",
				sortable: true,
				width: 100,
				Cell: row => {
					const priority = _.get(row, "original.priority", "");
					const priorityIcons = {
						critical: "icon-critical",
						high: "icon-triangle",
						medium: "icon-circle",
						low: "icon-upside-down-triangle"
					};

					return (
						<div>
							{priority && (
								<OverlayTrigger
									placement="top"
									overlay={this.getTooltip(
										"priority",
										`${
											priority
												? `${priority.charAt(0).toUpperCase()}${priority.slice(
														1
												  )}`
												: ""
										} Priorty`
									)}
								>
									<div
										className={`thread-msg-priority-icon ${
											priority ? priority : ""
										}`}
									>
										<i className={priorityIcons[priority]}></i>
									</div>
								</OverlayTrigger>
							)}
						</div>
					);
				}
			},
			{
				Header: () => tableSort("Status"),
				accessor: "status",
				sortable: true,
				width: 100,
				Cell: row => {
					const status = _.get(row, "original.status");

					return <div className="capitalize">{status}</div>;
				}
			},
			{
				Header: "Subject",
				accessor: "type",
				sortable: false,
				Cell: row => {
					const subject = row && row.original && row.original.subject;
					return (
						<div className="white-space-normal">
							<strong>
								{subject && subject.length > 100
									? subject.substring(0, 100) + "..."
									: subject}
							</strong>
						</div>
					);
				}
			},
			{
				maxWidth: 70,
				sortable: false,
				Cell: row => {
					const threadId = row && row.original && row.original.thread_id;

					const reply = (
						<OverlayTrigger
							placement="top"
							overlay={this.getTooltip("reply", "Reply")}
						>
							<span
								onClick={e => {
									e.stopPropagation();
									this.handleOpenMsgModal(threadId, "edit");
								}}
							>
								<i className="icon-reply"></i>
							</span>
						</OverlayTrigger>
					);
					return <div className="comm-center-actions">{reply}</div>;
				}
			}
		],
		useCommissionAdditionalFeePolicy: null,
		additionalFeeCommissionRate: 0.13,
		amountDisabled: true,
		addonFetched: false
	};

	getReview = async () => {
		const { reservationId } = this.state;
		const tid = getSessionId();
		let resp;

		try {
			resp = await API.get(
				"rapapi",
				`/organizations/${this.props.org}/reviews?reservation_id=${reservationId}&tid=${tid}&limit=1&offset=0`,
				{ response: true }
			);
			if (_.get(resp, "data.length")) {
				this.setState({
					reviewData: resp.data[0]
				});
			}
		} catch (err) {
			const errMsg = _.get(err.response, "data.Message", "");
			let msg =
				"We encountered an error while retrieving reviews. Please try again shortly.";

			if (errMsg) {
				msg = errMsg.replace(/^\('\s*|\',\s*-1\)$/g, "");
			}

			this.setState({
				reviewErrMsg: msg
			});
		}
	};

	getPMCommissionPercentage = policies => {
		const commissions = policies
			.filter(policy => policy.item === "payment")
			.reduce((accumulate, policy) => {
				const restriction = policy.restrictions
					.filter(restrict => restrict.adjustment.base.includes("other"))
					.reduce((acc, restrict) => {
						if (restrict.adjustment.type === "percent") {
							return acc + parseFloat(restrict.adjustment.amount);
						}
					}, 0);
				return accumulate + restriction;
			}, 0);

		return commissions;
	};

	hasAddonFees = () => {
		return (
			this.state.feeTypesMap && Object.keys(this.state.feeTypesMap).length > 0
		);
	};

	handleOptionalFees = async () => {
		try {
			if (this.state.addonFetched) {
				return;
			}
			const response = await API.get(
				"rapapi",
				`/properties/reservations/${this.state.reservationId}/adjustments`
			);

			if (response.additional_fees && response.additional_fees.length > 0) {
				const feeTypes = response.additional_fees.map(fee => fee.name);
				const feeTypesMap = {};
				response.additional_fees.forEach(
					(feeType, index) => (feeTypesMap[feeType.name] = feeType)
				);
				feeTypes.unshift(this.feeDefault);
				feeTypes.push("Other");
				this.setState({
					feeTypes,
					feeTypesMap
				});
			}

			this.setState({
				addonFetched: true
			});
		} catch (err) {
			this.setState({
				error:
					err.response && err.response.data && err.response.data.Message
						? err.response.data.Message
						: "Failed to fetch additional fees",
				isLoading: false
			});
		}
	};

	handleCheckCommissionAdditionalFee = async () => {
		if (this.state.useCommissionAdditionalFeePolicy !== null) {
			return;
		}

		let useCommissionAdditionalFeePolicy = this.state
			.useCommissionAdditionalFeePolicy;
		let additionalFeeCommissionRate = this.state.additionalFeeCommissionRate;
		try {
			const { property_manager_details } = await API.get(
				constants.ACCAPI.NAME,
				`/organizations/${this.org}`,
				{
					headers: { "Cache-Control": "must-revalidate" }
				}
			);

			if ("use_commissions_for_additional_fees" in property_manager_details) {
				useCommissionAdditionalFeePolicy =
					property_manager_details.use_commissions_for_additional_fees === "1";
			}

			if (useCommissionAdditionalFeePolicy === true) {
				const { data: reservationPolicies } = await API.get(
					constants.RAPAPI.NAME,
					constants.RAPAPI.PROPERTY_RESERVATIONS_BY_ID_POLICIES(
						this.state.reservationId
					),
					{ response: true, isCognito: true }
				);

				additionalFeeCommissionRate = this.getPMCommissionPercentage(
					reservationPolicies
				);
				additionalFeeCommissionRate = Number(
					additionalFeeCommissionRate / 100
				).toFixed(2);
			}
		} catch (e) {
			console.log(e, "on proccess check commission additional fee");
		}

		this.setState({
			useCommissionAdditionalFeePolicy: useCommissionAdditionalFeePolicy,
			additionalFeeCommissionRate: additionalFeeCommissionRate
		});
	};

	getHostFee = () => {
		const feePrice = this.state.feeTypesMap[this.state.fee_name];
		let hostFee = this.state.host_fee;
		if (feePrice && feePrice.recipient === constants.FEE_RECIPIENT.REDAWNING) {
			return Number(0);
		} else if (
			feePrice &&
			feePrice.recipient === constants.FEE_RECIPIENT.HOST
		) {
			hostFee = feePrice.price * Number(this.state.fee_quantity);
		}
		return this.state.useCommissionAdditionalFeePolicy === true
			? Number(hostFee) -
					Number(hostFee) * this.state.additionalFeeCommissionRate
			: Number(hostFee);
	};

	getRedawningFee = () => {
		const feePrice = this.state.feeTypesMap[this.state.fee_name];

		if (feePrice && feePrice.recipient === constants.FEE_RECIPIENT.REDAWNING) {
			return Number(feePrice.price) * Number(this.state.fee_quantity);
		}
		return Number(this.state.host_fee) * this.state.additionalFeeCommissionRate;
	};

	updateColumns = () => {
		let columns = [];
		columns = [
			...columns,
			{
				Header: "Date",
				accessor: "created_datetime",
				sortable: false,
				Cell: row => {
					const date = row && row.original && row.created_datetime;

					return <div>{moment(date).format("YYYY-MM-DD")}</div>;
				}
			},
			{
				Header: "Type",
				accessor: "type",
				sortable: false,
				Cell: row => {
					const type = row && row.original && row.original.type;

					return <div>{type[0].toUpperCase() + type.slice(1)}</div>;
				}
			},
			{
				Header: "Amount",
				accessor: "amount",
				sortable: false,
				Cell: row => {
					const price = row && row.original && row.original.amount;
					const currency = row && row.original && row.original.currency;

					return (
						<div>
							<strong>{`${constants.CURRENCY[currency]} ${price}`}</strong>
						</div>
					);
				}
			}
		];
		return columns;
	};

	async updateInvoiceStatuses() {
		if (this.state.invoice_items && this.state.invoice_items.length > 0) {
			let invoices = [];

			for (let i = 0; i < this.state.invoice_items.length; i++) {
				const status = this.state.invoice_items[i]["status"];
				const description = this.state.invoice_items[i]["line_items"][0][
					"description"
				];
				const amount = this.state.invoice_items[i]["line_items"][0]["amount"];

				invoices.push({
					status: status,
					name: description,
					amount: amount
				});
			}

			this.setState({
				invoice_items_arr: invoices,
				invoiceLoading: false
			});
		}
	}

	async componentDidMount() {
		this.setState({
			isLoading: true
		});

		const {
			threadPageSize,
			showOnlyUnread,
			priorityFilters,
			statusFilters,
			threadSorted
		} = this.state;

		this.checkPermissions();

		if (this.viewMessages) {
			const params = JSON.parse(
				localStorage.getItem("reservation-details-threads")
			);

			if (params) {
				this.setState(
					{
						threadPageSize: params.threadPageSize || threadPageSize,
						showOnlyUnread: params.showOnlyUnread || showOnlyUnread,
						priorityFilters: params.priorityFilters || priorityFilters,
						statusFilters: params.statusFilters || statusFilters,
						threadSorted: params.threadSorted || threadSorted
					},
					async () => {
						await this.getThreads(0, "", "", true);
					}
				);
			} else {
				await this.getThreads(0, "", "", true);
			}
		}

		this.getReview();
		await this.getReservationDetails();
		await this.getScheduledActions();
		this.getReservationNotes();
		this.getVirtualCreditCard();
		this.getReservationInvoices();

		if (_.get(window, "data.dashboard")) {
			document.getElementById("threadTable").scrollIntoView({
				block: "center",
				behavior: "smooth"
			});
		}

		this.setState({
			isLoading: false
		});
	}

	checkPermissions = () => {
		if (_.isArray(this.props.permissions)) {
			if (this.props.permissions.includes("view_messages")) {
				this.viewMessages = true;
			}
		}

		if (
			this.props.permissions.includes("view_reviews") ||
			this.props.permissions.includes("view_reviews_unrestricted")
		) {
			this.reviewAccess = true;
		}

		if (this.props.permissions.includes("view_freshdesk_tickets")) {
			this.viewFreshDeskTickets = true;
		}

		if (this.props.permissions.includes("view_crm_cases")) {
			this.viewCrmCases = true;
		}
	};

	handleLogsModal = (e, data) => {
		e.preventDefault();

		this.setState(
			{
				scheduledActionIsLoading: true,
				currentScheduledAction: data
			},
			async () => {
				await this.getLogs();

				this.setState({
					scheduledActionIsLoading: false,
					modal: "logs-modal"
				});
			}
		);
	};

	getScheduledActions = async () => {
		let resp;
		try {
			resp = await API.get(
				"ramapi",
				`/commhub/organizations/${this.props.org}/scheduled-actions?reservation_id=${this.state.reservationId}`
			);
			if (resp.length) {
				this.setState({
					scheduledActionsList: resp
				});
			}
		} catch (err) {
			console.log(err);
		}
	};

	componentWillUnmount() {
		const { websocket } = this.state;

		clearInterval(this.pingInterval);

		if (websocket) {
			websocket.close();
		}

		this.setLocalStorage();
	}

	setLocalStorage = () => {
		const {
			showOnlyUnread,
			threadPageSize,
			priorityFilters,
			statusFilters,
			threadSorted
		} = this.state;

		const pagination = {
			showOnlyUnread: showOnlyUnread,
			threadPageSize: threadPageSize,
			priorityFilters: priorityFilters,
			statusFilters: statusFilters,
			threadSorted: threadSorted
		};

		localStorage.setItem(
			"reservation-details-threads",
			JSON.stringify(pagination)
		);
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		const {
			modal,
			websocket,
			threadActivePage,
			websocketSend,
			threadSubject,
			threadMessage,
			attachments,
			modalData
		} = this.state;

		if (websocket) {
			websocket.onmessage = async event => {
				if (event && event.data) {
					const parseData = JSON.parse(event.data);

					if (parseData.thread_id == modalData.thread_id || websocketSend) {
						if (modal.length) {
							const threadResp = await this.getThread(parseData.thread_id);

							this.setState({
								mode: "edit",
								threadSubject: !websocketSend ? threadSubject : "",
								threadMessage: !websocketSend ? threadMessage : "",
								attachments: !websocketSend ? attachments : [],
								modalData: threadResp && threadResp.data ? threadResp.data : {},
								suggestedReplies: [],
								suggestedRepliesCurrentIndex: 0,
								threadLoading: false,
								isLoading: false,
								websocketSend: false
							});
						}
					}

					if (threadActivePage == 1) {
						this.getThreads(threadActivePage);
					}
				}
			};

			websocket.onclose = event => {
				if (event) {
					this.connectWebsocket();
				}
			};
		}
	}

	getCurrentSub = async () => {
		let resp;
		try {
			resp = await API.get("ramapi", `/commhub/currentsubscriber`, {
				response: true
			});
			if (resp) {
				this.setState({
					currentSub: resp.data
				});
			}
		} catch (err) {
			console.log(err);
		}
	};

	connectWebsocket = async () => {
		const { reservationId } = this.state;
		const body = {
			websocket_type: "reservation",
			organization_id: this.props.orgId,
			category: "reservation",
			subcategory: "inquiry",
			internal_id: reservationId,
			internal_id_type: "rid",
			role_type: "guest"
		};
		let resp;

		try {
			resp = await API.post("ramapi", `/commhub/websocket-token`, {
				response: true,
				body: body
			});
			if (resp && resp.data && resp.data.token_id) {
				const socket = await new WebSocket(
					`wss://${
						APIConfig.ENV == "DEV"
							? "dev.wss.redawning.com"
							: "wss.redawning.com"
					}/ramapi-v1?ra-ouid-token=${resp.data.token_id}`
				);

				this.setState({
					websocket: socket
				});
			}
		} catch (err) {
			console.log(err);
		}
		return resp;
	};

	sendPing = () => {
		const { websocket } = this.state;

		if (websocket && websocket.readyState == 1) {
			websocket.send(JSON.stringify("ping"));
		}
	};

	getThreads = async (active_page, sort, page_size, init, hideLoader, tid) => {
		const {
			threadPageSize,
			threadSorted,
			showOnlyUnread,
			threadDates,
			threadKeyword,
			currentSub,
			reservationId,
			statusFilters,
			priorityFilters
		} = this.state;
		let resp;
		const newPageSize = page_size ? page_size : threadPageSize;
		let newActivePage = active_page === 0 ? 0 : active_page - 1;
		const startDate = moment(threadDates.startDate).isValid()
			? moment(threadDates.startDate).format("YYYY-MM-DD")
			: "";
		const endDate = moment(threadDates.endDate).isValid()
			? moment(threadDates.endDate).format("YYYY-MM-DD")
			: "";
		const offset = newPageSize * newActivePage;
		const newSorted = sort ? sort : threadSorted;
		const sortDesc = newSorted.desc ? "-" : "+";

		const priorityKeys = _.keys(_.pickBy(priorityFilters, Boolean));
		const statusKeys = _.keys(_.pickBy(statusFilters, Boolean));
		const priorityQueryString =
			priorityKeys.length > 0 ? `&priority=${priorityKeys.join(",")}` : "";
		const statusQueryString =
			statusKeys.length > 0 ? `&thread_statuses=${statusKeys.join(",")}` : "";

		if (!tid) {
			this.tidThreads = getSessionId();
		}

		if (!hideLoader) {
			this.setState({
				threadIsLoading: true
			});
		}

		if (init || !(currentSub && currentSub.subscriber_id)) {
			await this.getCurrentSub();
		}

		const checkInDate = moment(threadDates.startDate).isValid()
			? `&checkin_date=${startDate ? encodeURIComponent(startDate) : ""}${
					endDate ? `:${endDate}` : ""
			  }`
			: "";

		try {
			resp = await API.get(
				"ramapi",
				`/commhub/threads?internal_id_type=rid&internal_id=${reservationId}&tsid=${
					this.tidThreads
				}&limit=${threadPageSize}&offset=${offset}${priorityQueryString}${statusQueryString}&sort=${
					threadSorted.id
				}${encodeURIComponent(sortDesc)}${checkInDate}${
					showOnlyUnread ? "&status=new" : ""
				}${
					threadKeyword ? `&keyword=${encodeURIComponent(threadKeyword)}` : ""
				}&ignore_optins=true`,
				{ response: true }
			);

			if (resp && resp.data) {
				this.setState(
					{
						threadData: resp.data,
						threadActivePage: newActivePage + 1,
						threadPageSize: newPageSize,
						threadSorted: {
							...threadSorted,
							id: newSorted.id,
							desc: newSorted.desc
						},
						threadIsLoading: false
					},
					() => {
						if (init) {
							this.setState(
								{
									commCenterAccess: true
								},
								async () => {
									await this.getGuestVerification();

									this.connectWebsocket();

									this.pingInterval = setInterval(() => {
										this.sendPing();
									}, 300000);
								}
							);
						}

						this.setLocalStorage();
					}
				);
			}
		} catch (err) {
			if (err && err.response && err.response.status == 401) {
				this.setState({
					commCenterAccess: false
				});
			}
			this.setState({
				threadIsLoading: false
			});
		}
	};

	submitThread = async () => {
		const {
			threadSubject,
			threadMessage,
			reservationId,
			websocket,
			attachments
		} = this.state;
		let body = {
			priority: "medium",
			renders: [
				{
					content_type: "text",
					rendered_body: threadMessage
				}
			],
			subject: threadSubject,
			category: "reservation",
			subcategory: "inquiry",
			internal_id: reservationId,
			internal_id_type: "rid"
		};
		let resp;

		this.setState({
			threadLoading: true,
			websocketSend: true
		});

		if (attachments.length) {
			resp = await this.uploadAttachments();

			if (resp.length) {
				body.attachments = resp;
			}
		}

		websocket.send(JSON.stringify(body));
	};

	submitMessage = async () => {
		const {
			threadMessage,
			modalData,
			websocket,
			attachments,
			markPriority
		} = this.state;
		const body = {
			thread_id: modalData.thread_id,
			priority: markPriority.value || "medium",
			renders: [
				{
					content_type: "text",
					rendered_body: threadMessage
				}
			]
		};
		let resp;

		this.setState({
			threadLoading: true,
			websocketSend: true
		});

		if (attachments.length) {
			resp = await this.uploadAttachments();

			if (resp.length) {
				body.attachments = resp;
			}
		}

		websocket.send(JSON.stringify(body));
	};

	getVirtualCreditCard = async () => {
		const { reservationId, vccShow } = this.state;
		let resp = {};

		this.setState({
			vccIsLoading: true
		});

		try {
			resp = await API.get(
				"rapapi",
				`/properties/reservations/${reservationId}/virtualpayoutmethod${
					vccShow ? "?full_details=true" : ""
				}`,
				{ response: true }
			);
			if (resp && resp.data && resp.data.virtual_payout_id) {
				this.setState(
					{
						vccData: resp.data,
						vccIsLoading: false
					},
					() => {
						this.setState({
							columns: this.updateColumns()
						});
					}
				);
			}
		} catch (err) {
			console.log(err);
			this.setState({
				vccIsLoading: false
			});
		}
	};

	reloadView = async invoiceCount => {
		let tryTimes = 1;
		let delay = 0;

		this.setState({
			isLoading: true
		});

		let updateInvoices = async () => {
			await this.getReservationInvoices();

			if (
				invoiceCount &&
				tryTimes <= 10 &&
				this.state.invoice_items.length == invoiceCount
			) {
				tryTimes++;
				delay += 250;

				setTimeout(async () => {
					await updateInvoices();
				}, delay);
			}
		};

		await this.getReservationNotes();
		await this.getReservationDetails();
		await updateInvoices();

		this.setState({
			isLoading: false
		});
	};

	getReservationNotes = async () => {
		const { reservationId } = this.state;
		let data;

		try {
			data = await API.get(
				constants.RAPAPI.NAME,
				`/properties/reservations/${reservationId}/attributes`,
				{}
			);

			if (data.length) {
				const filterNotes = data.filter(
					note =>
						note.attribute_name === "check_in_details" ||
						note.attribute_name === "host_notes"
				);

				this.setState({
					reservationNotes: filterNotes.length ? filterNotes : []
				});

				this.initReservationNotes = data;
			} else {
				this.setState({
					reservationNotes: [
						{
							edit: true,
							attribute_name: "check_in_details",
							attribute_value: ""
						}
					]
				});
			}
		} catch (e) {
			this.setState({
				reservationNotes: [
					{
						edit: true,
						attribute_name: "check_in_details",
						attribute_value: ""
					}
				]
			});
		}
	};

	getGuestVerification = async () => {
		const { reservationId } = this.state;
		let resp;

		this.setState({
			guestVerificationIsLoading: true
		});

		try {
			resp = await API.get(
				"gbapi",
				`/reservations/${reservationId}/commdata/guest_verified`
			);
			if (_.isNull(resp) || _.isObject(resp)) {
				this.setState({
					guestVerificationData: resp,
					guestVerificationIsLoading: false
				});
			}
		} catch (e) {
			this.setState({
				guestVerificationIsLoading: false
			});
		}
	};

	getSuggestedReply = async (e, init) => {
		e && e.preventDefault();
		const { modalData } = this.state;

		this.setState({
			isSuggestedReplyLoading: true
		});

		try {
			const resp = await API.get(
				"ramapi",
				`/commhub/threads/${modalData.thread_id}/suggestions?num_suggestions=3`
			);

			if (_.get(resp, "replies")) {
				if (init) {
					this.setState({
						suggestedReplies: resp.replies,
						threadMessage: resp.replies[0],
						isSuggestedReplyLoading: false
					});
				} else {
					const { suggestedReplies } = this.state;

					this.setState({
						suggestedReplies: [...suggestedReplies, ...resp.replies],
						isSuggestedReplyLoading: false
					});
				}
			}
		} catch (err) {
			console.log(err);
		}
	};

	responseSelector = async (e, direction) => {
		e.preventDefault();
		const { suggestedRepliesCurrentIndex } = this.state;
		let index = suggestedRepliesCurrentIndex;

		if (direction == "foward") {
			index++;
		} else if (direction == "back") {
			index--;
		}

		if (index > this.state.suggestedReplies.length - 1) {
			await this.getSuggestedReply();
		}

		this.setState({
			suggestedRepliesCurrentIndex: index,
			threadMessage: this.state.suggestedReplies[index]
		});
	};

	submitGuestVerification = async () => {
		const { reservationId } = this.state;
		let data = {
			reservation_id: reservationId,
			vendor: "stripe"
		};

		this.setState({
			guestVerificationModalIsLoading: true
		});

		try {
			await API.put(
				"gbapi",
				`/reservations/${reservationId}/commdata/guest_verified`,
				{ body: data }
			);

			await this.getGuestVerification();

			this.setState({
				guestVerificationModalIsLoading: false,
				guestVerificationModal: false
			});
		} catch (e) {
			let msg = `Oops! We encountered an error. Please contact customer support.`;

			if (_.get(e, "response.data.Message.length")) {
				if (e.response.data.Message.match(/'(.*?)'/)) {
					msg = e.response.data.Message.match(/'(.*?)'/)[1];
				}
			}

			this.setState({
				guestVerificationError: msg,
				guestVerificationModalIsLoading: false
			});
		}
	};

	handleGuestVerificationModal = bool => {
		this.setState({
			guestVerificationError: "",
			guestVerificationModal: bool
		});
	};

	updateMessageStatus = async (thread_id, body) => {
		await API.patch("ramapi", `/commhub/threads/${thread_id}/messages`, {
			body: body
		});
	};

	getLogs = async () => {
		const { currentScheduledAction } = this.state;
		let resp;
		let logs = [];

		let payload = {
			body: {
				scheduled_action_id: currentScheduledAction.scheduled_action_id,
				capability: "logs"
			},
			response: true
		};

		try {
			resp = await API.post(
				"ramapi",
				`/commhub/organizations/${this.props.org}/scheduled-actions/${currentScheduledAction.scheduled_action_id}`,
				payload
			);
			if (_.get(resp, "data[0].recipient_logs.length")) {
				const filterLogs = resp.data[0].recipient_logs.filter(
					log => log.delivery_type !== "portal"
				);

				logs = [...logs, ...filterLogs];
			}

			this.setState({
				scheduledActionsLogs: logs
			});
		} catch (err) {
			let errMsg =
				"Oops! It seems there was a problem retrieving your logs. Please contact our customer support team for assistance.";

			if (_.get(err, "response.data.Message.length")) {
				if (err.response.data.Message.match(/'(.*?)'/)) {
					errMsg = err.response.data.Message.match(/'(.*?)'/)[1];
				}
			}

			this.setState({
				scheduledActionsLogsErrMsg: errMsg
			});
		}
	};

	checkValidation = notes => {
		notes.forEach((note, i) => {
			if (notes.length == 2) {
				if (
					notes[0].attribute_name == notes[1].attribute_name &&
					notes[i].edit
				) {
					notes.splice(i, 1, {
						...note,
						validation_duplicate: true
					});
				} else {
					notes.splice(i, 1, {
						...note,
						validation_duplicate: false
					});
				}
			}

			return notes;
		});
	};

	submitReservationNotes = async index => {
		const { reservationNotes } = this.state;
		let copyReservationNotes = [...reservationNotes];
		let response;

		copyReservationNotes.splice(index, 1, {
			...reservationNotes[index],
			loading: true
		});

		this.checkValidation(copyReservationNotes);

		this.setState(
			{
				reservationNotes: copyReservationNotes
			},
			async () => {
				const { reservationNotes, reservationId } = this.state;
				let copyReservationNotes = [...reservationNotes];

				const validations = copyReservationNotes.filter(note => {
					return note.validation_duplicate;
				});

				if (!validations.length) {
					if (this.initReservationNotes.length) {
						if (
							reservationNotes.length == 1 &&
							reservationNotes[0].attribute_name !==
								this.initReservationNotes[0].attribute_name
						) {
							await API.del(
								constants.RAPAPI.NAME,
								`/properties/reservations/${reservationId}/attributes?attribute_name=${this.initReservationNotes[0].attribute_name}`,
								{}
							);
						}
					}

					try {
						response = await API.post(
							constants.RAPAPI.NAME,
							`/properties/reservations/${reservationId}/attributes`,
							{ body: [copyReservationNotes[index]] }
						);

						if (response) {
							let note = response.filter(x => {
								return (
									x.attribute_name == copyReservationNotes[index].attribute_name
								);
							});

							if (note.length) {
								copyReservationNotes.splice(index, 1, {
									...note[0],
									edit: false,
									loading: false
								});
							}

							this.setState(
								{
									reservationNotes: copyReservationNotes
								},
								() => {
									this.initReservationNotes = this.state.reservationNotes;
								}
							);
						}
					} catch (e) {
						console.log(e);

						copyReservationNotes.splice(index, 1, {
							...reservationNotes[index],
							loading: false
						});

						this.setState({
							reservationNotes: copyReservationNotes
						});
					}
				} else {
					copyReservationNotes.splice(index, 1, {
						...reservationNotes[index],
						loading: false
					});

					this.setState({
						reservationNotes: copyReservationNotes
					});
				}
			}
		);
	};

	delReservationNotes = async () => {
		const { reservationNotes, reservationId } = this.state;
		let notesCopy = [...reservationNotes];
		let response;

		notesCopy.splice(this.currentNote.index, 1, {
			...reservationNotes[this.currentNote.index],
			loading: true
		});

		this.setState({
			modal: "",
			reservationNotes: notesCopy
		});

		try {
			response = await API.del(
				constants.RAPAPI.NAME,
				`/properties/reservations/${reservationId}/attributes?attribute_name=${
					reservationNotes[this.currentNote.index].attribute_name
				}`,
				{}
			);

			if (response) {
				let note = response.filter(x => {
					return (
						x.attribute_name ==
						reservationNotes[this.currentNote.index].attribute_name
					);
				});

				if (!note.length) {
					const { reservationNotes } = this.state;

					notesCopy.splice(this.currentNote.index, 1);

					this.setState(
						{
							reservationNotes: notesCopy
						},
						() => {
							this.initReservationNotes = reservationNotes;
							this.currentNote = {};
						}
					);
				}
			}
		} catch (e) {
			console.log(e);
		}
	};

	delReservationNotesModal = (index, modalName) => {
		const { reservationNotes } = this.state;

		if (modalName) {
			this.currentNote = {
				...reservationNotes[index],
				index: index
			};

			this.setState({
				modal: modalName
			});
		} else {
			this.setState(
				{
					modal: modalName
				},
				() => {
					this.currentNote = {};
				}
			);
		}
	};

	delNewReservationNotes = index => {
		const { reservationNotes } = this.state;
		let notes = [...reservationNotes];

		notes.splice(index, 1);

		this.setState({
			reservationNotes: notes
		});
	};

	addReservationNotes = () => {
		const { reservationNotes } = this.state;
		let notes = [...reservationNotes];

		notes.push({
			edit: true,
			attribute_name: "check_in_details",
			attribute_value: ""
		});

		this.setState({
			reservationNotes: notes
		});
	};

	reservationNotesOnChange = (e, index) => {
		const { reservationNotes } = this.state;
		const name = e.target.name;
		const value = e.target.value;
		let notes = [...reservationNotes];

		notes.splice(index, 1, {
			...reservationNotes[index],
			[name]: value
		});

		this.setState(
			{
				reservationNotes: notes
			},
			() => {
				if (notes[index].validation_duplicate) {
					this.checkValidation(notes);

					this.setState({
						reservationNotes: notes
					});
				}
			}
		);
	};

	cancelBtnReservationNotes = index => {
		const { reservationNotes } = this.state;
		let notesCopy = [...reservationNotes];

		const currReservNote = this.initReservationNotes.filter(
			note => notesCopy[index].attribute_name == note.attribute_name
		);

		notesCopy.splice(index, 1, {
			...currReservNote[0],
			edit: !reservationNotes[index].edit
		});

		this.setState({
			reservationNotes: notesCopy
		});
	};

	editReservationNotes = index => {
		const { reservationNotes } = this.state;
		let notes = [...reservationNotes];

		notes.splice(index, 1, {
			...reservationNotes[index],
			edit: !reservationNotes[index].edit,
			autofocus: true
		});

		this.setState({
			reservationNotes: notes
		});
	};

	renderReservationNotes = () => {
		const { reservationNotes } = this.state;

		return reservationNotes.map((note, i) => {
			return (
				["check_in_details", "host_notes"].includes(note.attribute_name) && (
					<Row key={i}>
						<Col xs={12} sm={12} md={6} className="bottom-margin-30">
							<Col
								xs={12}
								sm={12}
								className={`reservation-notes ${!note.edit ? "saved" : ""}`}
							>
								{note.loading && <Loader />}
								<Col xs={11} sm={11}>
									<InputField
										type="select"
										name="attribute_name"
										className={`${
											note.validation_duplicate && note.edit
												? "form-validation"
												: ""
										}`}
										value={note.attribute_name}
										onChange={e => this.reservationNotesOnChange(e, i)}
										disabled={!note.edit}
									>
										{this.noteName()}
									</InputField>
								</Col>
								<Col xs={1} sm={1}>
									{!note.edit ? (
										<i
											className={`icon-edit ${this.props.mobileMode &&
												"mobile"}`}
											onClick={() => this.editReservationNotes(i)}
										/>
									) : (
										<i
											className={`icon-Delete ${this.props.mobileMode &&
												"mobile"}`}
											onClick={
												note.reservation_id
													? () =>
															this.delReservationNotesModal(i, "delete-modal")
													: () => this.delNewReservationNotes(i)
											}
										/>
									)}
								</Col>
								<Col xs={12} sm={12} className="text-area-container">
									<InputField
										cssClass="text-area"
										type="textarea"
										name="attribute_value"
										value={note.attribute_value}
										onChange={e => this.reservationNotesOnChange(e, i)}
										autoFocus={note.autofocus}
										disabled={!note.edit}
									/>
								</Col>
								{note.edit ? (
									<Col xs={12} sm={12} className="btn-container">
										<PrimaryButton
											cssClass="pull-right left-margin"
											fullWidth={false}
											onClick={() => this.submitReservationNotes(i)}
											disabled={!note.attribute_value.length}
										>
											Save
										</PrimaryButton>
										{note.reservation_id && (
											<PrimaryButton
												cssClass="pull-right white-btn"
												fullWidth={false}
												onClick={() => this.cancelBtnReservationNotes(i)}
											>
												Cancel
											</PrimaryButton>
										)}
									</Col>
								) : (
									""
								)}
							</Col>
							{note.validation_duplicate && note.edit && (
								<span className="required-error-text">
									Cannot have duplicate{" "}
									{this.attributeName[note.attribute_name]}.
								</span>
							)}
						</Col>
					</Row>
				)
			);
		});
	};

	/** Get reservation details API call. */
	getReservationDetails = async () => {
		let details = {};

		try {
			details = await API.get(
				constants.RAPAPI.NAME,
				constants.RAPAPI.PROPERTY_RESERVATIONS_BY_ID(this.state.reservationId),
				{}
			);

			if (details) {
				this.setPropertyOrg(details.organization_name);

				var totalPayment =
					(details.price.rental_price ? details.price.rental_price : 0) +
					(details.price.cleaning_fee ? details.price.cleaning_fee : 0) +
					(details.price.tax ? details.price.tax : 0) +
					(details.price.ra_commission ? details.price.ra_commission : 0) +
					(details.price.cc_processing ? details.price.cc_processing : 0) +
					(details.price.adjustments ? details.price.adjustments : 0) +
					(details.price.other_fees ? details.price.other_fees : 0) +
					(details.price.channel_collected_tax
						? details.price.channel_collected_tax
						: 0);

				this.currentOrg = details.organization_name;
				this.setState(
					{
						details,
						totalPayment
					},
					async () => {
						await this.getReservationPromoDetails();
					}
				);
			}
		} catch (e) {
			console.log(e, "Error Get Reservation Details");
		}
	};

	setPropertyOrg = orgName => {
		let organization = this.props.user.expanded_organization_roles.filter(
			org => {
				if (org.organization_name === orgName) {
					return true;
				}
			}
		);

		if (this.org !== orgName) {
			if (organization && organization.length) {
				this.props.updateOrganization(organization[0].organization_name);
				this.props.updateOrganizationTitle(organization[0].title);
				this.props.updateOrganizationId(organization[0].organization_id);
				this.props.setRole(organization[0].roles);

				sessionStorage.setItem("org", organization[0].organization_name);
				sessionStorage.setItem("orgTitle", organization[0].title);
				sessionStorage.setItem("orgId", organization[0].organization_id);

				sessionStorage.removeItem("reservations-listing");
				sessionStorage.removeItem("properties-listing");
			}
		}
	};

	/** Get reservation details API call. */
	getReservationInvoices = async () => {
		let resp = [];

		this.setState({
			invoiceLoading: true
		});

		try {
			resp = await API.get(
				constants.RAPAPI.NAME,
				constants.RAPAPI.PROPERTY_RESERVATIONS_INVOICES_BY_ID(
					this.state.reservationId
				),
				{}
			);
			if (resp.length) {
				this.setState(
					{
						invoice_items: res
					},
					() => {
						this.updateInvoiceStatuses();
					}
				);
			} else {
				this.setState({
					invoiceLoading: false
				});
			}
		} catch (e) {
			this.setState({
				invoiceLoading: false
			});
		}
	};

	/** Get reservation details API call. */
	getReservationPromoDetails = async () => {
		let pm_promotion_detail = this.state.pm_promotion_detail;

		try {
			let promotions_details = await API.get(
				constants.RAPAPI.NAME,
				constants.RAPAPI.PROMOTION_TRANSACTION_BY_RESERVATION_ID(
					this.state.reservationId
				),
				{}
			);

			if (promotions_details && promotions_details.type === "pm_promotion") {
				if (typeof promotions_details.id == "number") {
					pm_promotion_detail.id = promotions_details.id;
				}

				if (typeof promotions_details.name == "string") {
					pm_promotion_detail.name = promotions_details.name;
				}

				if (typeof promotions_details.type == "string") {
					pm_promotion_detail.type = promotions_details.type;
				}

				if (typeof promotions_details.rental_charge_diff == "number") {
					pm_promotion_detail.rental_charge_diff =
						promotions_details.rental_charge_diff;
					pm_promotion_detail.prental_price =
						promotions_details.rental_charge_diff * -1;
				}

				if (typeof promotions_details.tax_diff == "number") {
					pm_promotion_detail.tax_diff = promotions_details.tax_diff;
					pm_promotion_detail.ptax = promotions_details.tax_diff * -1;
				}

				if (typeof promotions_details.cc_fee_diff == "number") {
					pm_promotion_detail.cc_fee_diff = promotions_details.cc_fee_diff;
					pm_promotion_detail.pcc_processing =
						promotions_details.cc_fee_diff * -1;
				}

				if (typeof promotions_details.ra_commission_diff == "number") {
					pm_promotion_detail.ra_commission_diff =
						promotions_details.ra_commission_diff;
					pm_promotion_detail.pra_commission =
						promotions_details.ra_commission_diff * -1;
				}

				if (typeof promotions_details.promo_code == "string") {
					pm_promotion_detail.promo_code = promotions_details.promo_code;
				}

				pm_promotion_detail.total = pm_promotion_detail.rental_charge_diff;
			}
		} catch (e) {
			console.log(e, "Error Get Reservation Promotion Details");
		}

		this.setState({
			pm_promotion_detail
		});
	};

	displayAdditionalFees = (mobileMode, details) => {
		let invoice_items_arr = Object.assign([], this.state.invoice_items_arr);

		const feesList = details.price.additional_fees.map((value, index) => {
			const name = value.name;
			const qty =
				value.qty && value.qty !== null && Number(value.qty) > 1
					? Number(value.qty)
					: "";
			const amountString = value.amount.toFixed(2);
			const amount = Number(amountString);
			const ra_amount = (
				amount +
				(amount + amount * 0.13 - amount * 1)
			).toFixed(2);
			const statuses = {
				created: "Created",
				pending: "Pending",
				past_due: "Past Due",
				paid: "Paid",
				uncollectable: "Contact Support",
				void: "Void",
				unknown: "Contact Support"
			};
			let statusResult = false;
			const { details } = this.state;
			const currency =
				(value && value.currency) ||
				(details && details.price && details.price.currency);
			const currencySymbol = currency && constants.CURRENCY[currency];

			const renderAdditionalFeeItems = (
				mobileMode,
				index,
				statusResult,
				name,
				amountString,
				qty
			) => {
				if (mobileMode) {
					return (
						<div className="reservation-item" key={"feeKey" + index}>
							<p>
								{name}
								{qty ? ` (${qty})` : ""}{" "}
								{statusResult ? `(Invoice: ${statuses[statusResult]})` : ""}
							</p>
							<p>
								<strong>
									{currencySymbol}
									{amountString}
								</strong>
							</p>
						</div>
					);
				} else {
					return (
						<div className="reservation-billing" key={"feeKey" + index}>
							<h4>
								{name}
								{qty ? ` (${qty})` : ""}{" "}
								{statusResult ? `(Invoice: ${statuses[statusResult]})` : ""}
							</h4>
							<div className="reservation-billing-border"></div>
							<h4>
								<strong>
									{currencySymbol}
									{statusResult === "void" ? (
										<span style={{ textDecoration: "line-through" }}>
											{amountString}
										</span>
									) : (
										amountString
									)}
								</strong>
							</h4>
						</div>
					);
				}
			};

			for (let i = 0; i <= invoice_items_arr.length; i++) {
				const val = invoice_items_arr[i];

				if (val && val.name) {
					const NAME = name.replace(" ", "").toLowerCase();
					const VAL_NAME = val.name.replace(" ", "").toLowerCase();
					const RA_AMOUNT = Number(ra_amount).toFixed(2);
					const VAL_AMOUNT = Number(val.amount).toFixed(2);

					if (NAME === VAL_NAME && RA_AMOUNT === VAL_AMOUNT) {
						statusResult = invoice_items_arr[i].status;
						invoice_items_arr.splice(i, 1);
						i--;

						return renderAdditionalFeeItems(
							mobileMode,
							index,
							statusResult,
							name,
							amountString,
							qty
						);
					}
				}
			}

			return renderAdditionalFeeItems(
				mobileMode,
				index,
				statusResult,
				name,
				amountString,
				qty
			);
		});

		return feesList;
	};

	refreshAmount = () => {
		const feeName = this.state.fee_name;
		let host_fee = this.state.feeTypesMap[feeName]
			? this.state.feeTypesMap[feeName].price
			: this.state.host_fee;
		let amountDisabled = false;
		if (this.state.feeTypesMap[feeName]) {
			amountDisabled = true;
			host_fee = Number(host_fee) * Number(this.state.fee_quantity);
		}
		this.setState({
			host_fee,
			amountDisabled
		});
	};

	feesNamesOnChange = e => {
		const target = e.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		let match = false;

		if (value.length <= 0) {
			this.handleResetFeeForm();
			return;
		}

		if (value === "Other") {
			this.setState({
				showInputFeeNameField: true
			});
		}

		if (value !== "Additional Guest" && value !== "Additional Bed") {
			this.blockNightsWordMatch.forEach(elem => {
				if (value.toLocaleLowerCase().includes(elem)) {
					match = true;
				}
			});
		}

		if (match) {
			this.setState({
				isBlockNightsWordMatch: true
			});
		} else if (this.state.isBlockNightsWordMatch) {
			this.setState({
				isBlockNightsWordMatch: false
			});
		}
	};

	handleResetFeeForm = () => {
		this.setState({
			showInputFeeNameField: false,
			isBlockNightsWordMatch: false,
			fee_name: this.feeDefault,
			host_fee: 0,
			fee_quantity: 1,
			isInvoiceSelected: false
		});
	};

	closeButton = async e => {
		this.handleResetFeeForm(e);
		this.setState({
			error: "",
			modal: "",
			isLoading: false
		});
	};

	handleSubmitAdditionalFees = async (e, currency) => {
		this.setState({
			isLoading: true
		});

		let payment_type = "auto";

		if (
			this.state.details.addl_fee_payment_options &&
			this.state.details.addl_fee_payment_options.length == 1
		) {
			payment_type = this.state.details.addl_fee_payment_options[0];
		} else if (this.state.isInvoiceSelected) {
			payment_type = "invoice";
		}

		if (
			this.state.details.organization_name &&
			this.state.fee_name &&
			!(this.state.fee_name === this.feeDefault) &&
			Number(this.state.host_fee) >= 0
		) {
			const metadata = {};
			if (this.state.amountDisabled) {
				metadata.quantity = this.state.fee_quantity;
			}

			let post_body = {
				org_name: this.state.details.organization_name,
				payment_type: payment_type,
				additional_fees: [
					{
						name: this.state.fee_name,
						currency: currency,
						amount: Number(this.getHostFee().toFixed(2)),
						ra_amount: Number(this.getRedawningFee().toFixed(2)),
						metadata: metadata
					}
				]
			};

			try {
				await API.post(
					"rapapi",
					`/properties/reservations/${this.state.reservationId}/adjustments`,
					{ body: post_body }
				);
			} catch (err) {
				this.setState({
					error:
						err.response && err.response.data && err.response.data.Message
							? err.response.data.Message
							: "Failed to add new additional fee",
					isLoading: false
				});
				return;
			}
		}

		this.setState({
			isLoading: false,
			modal: ""
		});

		await this.reloadView(this.state.invoice_items.length);
	};

	showAddAdditionalFeesModal = () => {
		this.handleResetFeeForm();
		this.setState({
			modal: "add-a-fee-modal",
			error: "",
			isLoading: false
		});
	};

	handleMessageStatus = async data => {
		let filterNewMsg = [];

		if (data && data.messages && data.messages.length) {
			filterNewMsg = data.messages.filter(msg => msg.status == "new");
		}

		if (filterNewMsg.length) {
			this.setState({ isLoading: true });
			let body = [];

			filterNewMsg.map(msg => {
				body.push({ message_id: msg.message_id, status: "read" });
			});

			await this.updateMessageStatus(data && data.thread_id, body);
		}
		return filterNewMsg;
	};

	changeMsgStatusToUnread = async data => {
		let filterLastMsg = [];

		if (data && data.messages && data.messages.length) {
			filterLastMsg = data.messages.filter(
				(msg, i) =>
					i == data.messages.length - 1 && data.messages.status !== "new"
			);
		}

		if (filterLastMsg.length) {
			let body = [];

			filterLastMsg.map(msg => {
				body.push({ message_id: msg.message_id, status: "new" });
			});

			await this.updateMessageStatus(data && data.thread_id, body);
		}
		return filterLastMsg;
	};

	changeMsgStatusToRead = async data => {
		let filterNewMsg = [];

		if (data && data.messages && data.messages.length) {
			filterNewMsg = data.messages.filter(msg => msg.status == "new");
		}

		if (filterNewMsg.length) {
			let body = [];

			filterNewMsg.map(msg => {
				body.push({ message_id: msg.message_id, status: "read" });
			});

			await this.updateMessageStatus(data && data.thread_id, body);
		}
		return filterNewMsg;
	};

	updateThreadAttributes = async type => {
		const { modalData, markStatus } = this.state;
		const id = _.get(modalData, "thread_id");
		const body = {
			[type]: markStatus.value
		};

		await API.patch("ramapi", `/commhub/threads/${id}`, { body: body });
	};

	handleOpenMsgModal = async (id, mode) => {
		this.setState(
			{
				mode: mode
			},
			async () => {
				const { mode, details } = this.state;

				this.setState({
					threadIsLoading: true
				});

				if (mode == "create") {
					await this.getReservationCommData(_.get(details, "reservation_id"));

					await this.getPropertyCommData(_.get(details, "property_id"));

					this.setState({
						modal: "ask-question",
						threadIsLoading: false
					});
				} else {
					let replyTo = "";

					const threadResp = await this.getThread(id);

					if (!_.isEmpty(threadResp) && _.get(threadResp, "data")) {
						const data = _.get(threadResp, "data");
						let markStatus = [];
						let markPriority = [];

						if (
							_.get(data, "internal_id") &&
							_.get(data, "internal_id_type") === "rid"
						) {
							await this.getReservationCommData(data.internal_id);
						}

						if (_.get(data, "property_id")) {
							await this.getPropertyCommData(data.property_id);
						}

						if (!_.isEmpty(data)) {
							const { currentSub } = this.state;

							if (_.get(data, "messages.length")) {
								const msgLength = data.messages.length;

								for (let i = msgLength - 1; i >= 0; i--) {
									if (
										data.messages[i].posted_by_subscriber_id !==
										currentSub.subscriber_id
									) {
										replyTo =
											data.messages[i].posted_by_subscriber_display_name;
										break;
									}
								}
							}

							if (data.status) {
								markStatus = this.markStatusOptions.find(
									option => option.value === data.status
								);
							}

							if (data.priority) {
								markPriority = this.markPriorityOptions.find(
									option => option.value === data.priority
								);
							}
						}

						this.setState(
							{
								replyTo: replyTo,
								modalData: data,
								markStatus: markStatus,
								markPriority: markPriority,
								threadIsLoading: false
							},
							() => {
								this.setState({
									modal: "ask-question"
								});
							}
						);
					} else {
						this.setState({
							modal: "error-modal",
							errorMsg: threadResp,
							threadIsLoading: false
						});
					}
				}
			}
		);
	};

	isOutsideRange = () => {
		return false;
	};

	handleCloseMsgModal = () => {
		this.setState(
			{
				modal: ""
			},
			async () => {
				const { mode } = this.state;

				if (mode != "create") {
					const { modalData, threadActivePage, markAsUnread } = this.state;

					this.setState({ threadIsLoading: true });

					if (!markAsUnread) {
						const newThreads = await this.getThread(modalData.thread_id);

						await this.changeMsgStatusToRead(newThreads.data);
					} else {
						await this.changeMsgStatusToUnread(modalData);
					}

					await this.getThreads(threadActivePage, "", "", "", true);

					this.setState({
						modalData: {},
						threadMessage: "",
						markAsUnread: false,
						threadIsLoading: false,
						threadSubject: "",
						attachments: [],
						markStatus: [],
						markPriority: [],
						mode: "",
						suggestedReplies: [],
						suggestedRepliesCurrentIndex: 0,
						reservationCommData: {},
						propertyCommData: {},
						editHostNotes: false,
						hostNotes: "",
						hostNotesErr: ""
					});
				} else {
					this.setState({
						threadSubject: "",
						threadMessage: "",
						attachments: [],
						mode: ""
					});
				}
			}
		);
	};

	getThread = async id => {
		let resp;
		let errMsg = `Oops! We encountered an error. Please contact customer support.`;
		try {
			resp = await API.get("ramapi", `/commhub/threads/${id}`, {
				response: true
			});
		} catch (err) {
			if (_.get(err, "response.data.Message.length")) {
				if (err.response.data.Message.match(/'(.*?)'/)) {
					errMsg = err.response.data.Message.match(/'(.*?)'/)[1];
				}
			}
		}
		return resp || errMsg;
	};

	toggleDropdown = () =>
		this.setState({ dropdownIsOpen: !this.state.dropdownIsOpen });

	async handleInputChange(event) {
		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;

		await this.setState({
			[name]: value
		});
		this.refreshAmount();
	}

	renderOverlay = (icon, func, overlay) => {
		return (
			<OverlayTrigger
				placement="top"
				overlay={this.getTooltip(overlay.id, overlay.text)}
				onClick={func}
			>
				<i className={icon} />
			</OverlayTrigger>
		);
	};

	getTooltip = (id, text) => {
		return <Tooltip id={id}>{text}</Tooltip>;
	};

	copyVccNum = () => {
		const { vccData } = this.state;
		const method_details = vccData && vccData.method_details;

		if (method_details.creditcard_number) {
			navigator.clipboard.writeText(method_details.creditcard_number);
		}
	};

	toggleTransactions = () => {
		const { showTransactions, vccData } = this.state;

		this.setState(
			{
				showTransactions: !showTransactions
			},
			() => {
				this.setState({
					vccData: vccData
				});
			}
		);
	};

	toggleAsUnread = () => {
		const { markAsUnread } = this.state;

		this.setState({
			markAsUnread: !markAsUnread
		});
	};

	handleShowOnlyUnread = (name, apiCall) => {
		this.setState(
			{
				[name]: !this.state[name]
			},
			() => {
				apiCall(0);
			}
		);
	};

	pageSizeOnChange = (pageSize, type) => {
		if (type === "thread") {
			this.setState(
				{
					threadPageSize: pageSize
				},
				async () => {
					const { threadSorted } = this.state;

					this.getThreads(0, threadSorted, pageSize);
				}
			);
		} else {
			this.setState({
				activePage: 1,
				pageSize: parseInt(pageSize)
			});
		}
	};

	pageOnChange = (page, type) => {
		this.setState({
			activePage: parseInt(page)
		});
	};

	stateOnChange = (value, type) => {
		this.setState({
			[type]: value
		});
	};

	scheduleTimeOnChange = e => {
		const name = e.target.name;
		const value = e.target.value;

		this.setState({
			[name]: value
		});
	};

	onSortedChange = newSorted => {
		const { threadActivePage } = this.state;

		this.setState(
			{
				threadSorted: newSorted[0]
			},
			() => {
				this.getThreads(threadActivePage - 1, newSorted[0]);
			}
		);
	};

	handleSearch = e => {
		e.preventDefault();

		this.getThreads(0);
	};

	handleSearchChange = e => {
		const name = e.target.name;
		const val = e.target.value;

		this.setState({
			[name]: val
		});
	};

	renderVcc = () => {
		const {
			vccShow,
			vccData,
			vccIsLoading,
			columns,
			pageSize,
			activePage,
			totalCount,
			showTransactions
		} = this.state;

		const method_details = vccData && vccData.method_details;
		const creditCardNum =
			(method_details &&
				method_details.creditcard_number &&
				method_details.creditcard_number.match(/.{1,4}/g).join(" ")) ||
			"";
		const month =
			method_details && method_details.expiration_month
				? moment(`${method_details.expiration_month}`, "MM").format("MM")
				: "";
		const year =
			method_details && method_details.expiration_year
				? moment(`${method_details.expiration_year}`, "YYYY").format("YY")
				: "";

		return (
			<Row>
				<Col xs={12} className="top-margin">
					<div
						className={`vcc-container ${
							!Object.keys(vccData).length ? "no-border" : ""
						}`}
					>
						{vccIsLoading && <Loader />}
						{Object.keys(vccData).length > 0 && (
							<Fragment>
								<Col xs={12} className="limit">
									<Col xs={11} className="limit">
										<div>Limit</div>
										{vccShow && !vccIsLoading ? (
											<h2>
												{constants.CURRENCY[vccData.currency]}
												{vccData.limit}
											</h2>
										) : (
											<h2>****</h2>
										)}
									</Col>
									<Col xs={1} className="cc-status">
										<div>Status</div>
										<div
											className={`${
												vccData.is_active ? "active" : "not-active"
											}`}
										>
											<strong>
												{vccData.is_active ? "Active" : "Not Active"}
											</strong>
										</div>
									</Col>
								</Col>
								<Col xs={6} className="cc-number">
									<div>Card Number</div>
									{vccShow && !vccIsLoading ? (
										<h2>
											{creditCardNum}{" "}
											{this.renderOverlay("icon-copy", this.copyVccNum, {
												id: "vccCopy",
												text: "Copy"
											})}
										</h2>
									) : (
										<h2>**** **** **** {method_details.creditcard_last4}</h2>
									)}
								</Col>
								<Col xs={3} className="expiration">
									<div>Expiration</div>
									{vccShow && !vccIsLoading ? (
										<h2>{`${month}/${year}`}</h2>
									) : (
										<h2>** **</h2>
									)}
								</Col>
								<Col xs={2} className="cvv">
									<div>CVV</div>
									{vccShow && !vccIsLoading ? (
										<h2>{method_details.cvv}</h2>
									) : (
										<h2>***</h2>
									)}
								</Col>
								<Col xs={1} className="vcc-show">
									{this.renderOverlay(
										`icon-eye-${vccShow ? "close" : "open"}`,
										this.toggleVcc,
										{ id: "vccShow", text: vccShow ? "Hide" : "Show" }
									)}
								</Col>
								<Col xs={12} className="limit top-margin">
									<span
										className="clickable-text right-margin"
										onClick={this.toggleTransactions}
									>
										{`${showTransactions ? "Hide" : "Show"} Transactions`}
									</span>
								</Col>
								{showTransactions && (
									<Col xs={12} className="top-margin transactions-table">
										<ReactTableComponent
											getRef={r => (this.transactionsTable = r)}
											reactTable={this.transactionsTable}
											className="-highlight"
											noDataText={"No Transactions"}
											minRows={0}
											showPagination={true}
											data={vccData.transactions}
											resizable={false}
											pageSize={pageSize}
											activePage={activePage}
											columns={columns}
											totalCount={Number(totalCount)}
											pageSizeOnChange={pageSize =>
												this.pageSizeOnChange(pageSize, "transactions")
											}
											pageOnChange={page =>
												this.pageOnChange(page, " transactions")
											}
										/>
									</Col>
								)}
							</Fragment>
						)}
					</div>
				</Col>
			</Row>
		);
	};

	toggleVcc = () => {
		const { vccShow } = this.state;

		this.setState(
			{
				vccShow: !vccShow
			},
			() => {
				const { vccShow, vccData } = this.state;
				const creditcard_number =
					vccData &&
					vccData.method_details &&
					vccData.method_details.creditcard_number;

				if (vccShow == true && !creditcard_number) {
					this.getVirtualCreditCard();
				}
			}
		);
	};

	isRestrictedFee = details => {
		if (
			(details.channel &&
				details.channel.toLowerCase() === "airbnb" &&
				moment(details.checkout_date).isBefore(
					moment().format("YYYY-MM-DD")
				)) ||
			(details.addl_fee_payment_options.length &&
				(details.addl_fee_payment_options.includes("none") ||
					details.addl_fee_payment_options.includes("api")))
		) {
			return true;
		} else {
			return false;
		}
	};

	handleMessageOnChange = e => {
		const name = e.target.name;
		const value = e.target.value;

		this.setState({
			[name]: value
		});
	};

	renderMessages = () => {
		const { modalData, currentSub } = this.state;

		if (modalData && modalData.messages && modalData.messages.length) {
			const reverseArr = modalData.messages.slice().reverse();

			const renderMessage = (
				checkSubscriber,
				renderType,
				message,
				msg,
				firstOriginContext
			) => {
				const displayName =
					!_.get(msg, "posted_by_subscriber_display_name") ||
					_.get(msg, "posted_by_subscriber_display_name")
						.toLowerCase()
						.includes("none")
						? "Guest"
						: msg.posted_by_subscriber_display_name;
				const checkTextPosition =
					checkSubscriber ||
					firstOriginContext == _.get(msg, "context.origin_context");
				const hasHtml = (msg && msg.renders).filter(
					render => render.content_type === "html"
				);

				return (
					<div className="thread-msg-container">
						<Col
							xs={12}
							sm={12}
							className={`sender ${checkTextPosition ? "right" : "left"} ${
								msg.status == "new" ? "new-msg" : ""
							}`}
						>
							<span>
								{displayName} &nbsp; {moment(msg.created_dt).format("llll")}
							</span>
						</Col>
						<Col xs={12} sm={12}>
							<div
								className={`bubbles ${checkTextPosition ? "right" : "left"}`}
							>
								<div
									className={`msg ${
										checkTextPosition ? "right current-sub " : ""
									}${renderType == "text" ? "break-space " : ""}${
										hasHtml.length ? "hasHtml" : ""
									}`}
								>
									{message}
								</div>
							</div>
						</Col>
						{msg && msg.attachments && msg.attachments.length > 0 && (
							<Col xs={12} sm={12} className="attachments top-margin">
								<div className="attach-title">
									{msg.attachments.length} Attachments:
								</div>
								{(msg && msg.attachments).map((attachment, i) => {
									let imgSrc = File;

									if (attachment.mime_type.includes("pdf")) {
										imgSrc = Pdf;
									} else if (
										attachment.mime_type.includes("xls") ||
										attachment.mime_type.includes("xlm")
									) {
										imgSrc = Sheets;
									} else if (
										attachment.mime_type.includes("txt") ||
										attachment.mime_type.includes("doc")
									) {
										imgSrc = Docs;
									} else if (
										attachment.mime_type.includes("image") ||
										attachment.mime_type.includes("png") ||
										attachment.mime_type.includes("jpeg")
									) {
										imgSrc = ImageFile;
									}

									return (
										<Col xs={4} sm={4} key={i}>
											<OverlayTrigger
												placement="top"
												overlay={this.getTooltip(
													"filename",
													`${attachment.filename}`
												)}
											>
												<div className="attach-border">
													<a
														className="attach-container"
														href={attachment.uri}
														target="_blank"
													>
														<div className="attach-icon-container">
															<img src={imgSrc}></img>
														</div>
														<div className="attach-file-name">
															{attachment &&
															attachment.filename &&
															attachment.filename.length > 15
																? attachment.filename.substring(0, 15) + "..."
																: attachment.filename}
														</div>
													</a>
												</div>
											</OverlayTrigger>
										</Col>
									);
								})}
							</Col>
						)}
					</div>
				);
			};

			return reverseArr.map((msg, i) => {
				const hasHtml = (msg && msg.renders).filter(
					render => render.content_type === "html"
				);
				const hasEmailHtml = (msg && msg.renders).filter(
					render => render.content_type === "email_html"
				);
				const hasEmailText = (msg && msg.renders).filter(
					render => render.content_type === "email_text"
				);
				const hasText = (msg && msg.renders).filter(
					render => render.content_type === "text"
				);
				const hasSms = (msg && msg.renders).filter(
					render => render.content_type === "sms_text"
				);
				const firstOriginContext = _.get(
					modalData.messages,
					"[0].context.origin_context",
					""
				);

				if (msg.renders.length) {
					let message = "";
					let renderType = "";
					const checkSubscriber =
						(currentSub && currentSub.subscriber_id) ==
						msg.posted_by_subscriber_id;

					if (hasHtml.length) {
						message = (
							<div
								dangerouslySetInnerHTML={{ __html: hasHtml[0].rendered_body }}
							></div>
						);
						renderType = "html";
					} else if (hasEmailText.length) {
						const newEmailText = hasEmailText[0].rendered_body
							.replace(/\n +/g, "\n")
							.replace(/ +/g, " ")
							.trim();

						message = <div className="left-text">{newEmailText}</div>;
						renderType = "text";
					} else if (hasEmailHtml.length) {
						message = (
							<div
								dangerouslySetInnerHTML={{
									__html: hasEmailHtml[0].rendered_body
								}}
							></div>
						);
						renderType = "html";
					} else if (hasText.length) {
						message = (
							<div className="left-text">{hasText[0].rendered_body}</div>
						);
						renderType = "text";
					} else if (hasSms.length) {
						message = (
							<div className="left-text">{hasSms[0].rendered_body}</div>
						);
					}

					return (
						<div key={i}>
							{renderMessage(
								checkSubscriber,
								renderType,
								message,
								msg,
								firstOriginContext
							)}
						</div>
					);
				}
			});
		}
	};

	handleDatesChange = newDates => {
		this.setState(
			{
				threadDates: {
					...newDates
				}
			},
			() => {
				const { threadDates } = this.state;
				let startElement = document.getElementById("start");
				let startElementValue = startElement.value;
				let endElement = document.getElementById("end");
				let endElementValue = endElement.value;

				if (
					(moment(threadDates && threadDates.startDate).isValid() &&
						endElementValue == "") ||
					(moment(threadDates && threadDates.endDate).isValid() &&
						startElementValue == "")
				) {
					this.getThreads(0);
				} else if (
					moment(threadDates && threadDates.startDate).isValid() &&
					moment(threadDates && threadDates.endDate).isValid()
				) {
					this.getThreads(0);
				} else if (
					!(threadDates && threadDates.startDate) &&
					!(threadDates && threadDates.endDate)
				) {
					this.getThreads(0);
				}
			}
		);
	};

	addAttachment = file => {
		const { attachments } = this.state;
		let newAttachments = [...attachments];

		newAttachments.push(file[0]);

		this.setState({
			attachments: newAttachments
		});
	};

	removeAttachment = (e, index) => {
		e.preventDefault();
		const { attachments } = this.state;
		let copyAttachments = [...attachments];

		copyAttachments.splice(index, 1);

		this.setState({
			attachments: copyAttachments
		});
	};

	formatFileSize = fileSize => {
		const units = ["B", "KB", "MB", "GB", "TB"];
		let size = fileSize;
		let unitIndex = 0;

		while (size >= 1024 && unitIndex < units.length - 1) {
			size /= 1024;
			unitIndex++;
		}

		return Math.round(size) + units[unitIndex];
	};

	renderNewAttachments = () => {
		const { attachments } = this.state;

		return (
			<div className="new-attachments">
				{attachments.map((attach, i) => {
					return (
						<div className="new-attach-container" key={i}>
							<span className="new-attach">
								<span>
									<span className="new-attach-name">
										{attach && attach.name && attach.name.length > 20
											? attach.name.substring(0, 20) + "..."
											: attach.name}
									</span>
									<span className="attach-file-size">{`(${this.formatFileSize(
										attach.size
									)})`}</span>
								</span>
								<OverlayTrigger
									placement="top"
									overlay={this.getTooltip(
										"remove-attachment",
										"Remove Attachment"
									)}
								>
									<span
										className="new-attach-icon"
										onClick={e => this.removeAttachment(e, i)}
									>
										<i className="icon-Cross"></i>
									</span>
								</OverlayTrigger>
							</span>
						</div>
					);
				})}
			</div>
		);
	};

	uploadAttachments = async () => {
		const { attachments } = this.state;
		let resp;
		let body = [];

		attachments.forEach(attachment => {
			body.push({
				filename: attachment.name,
				mime_type: attachment.type
			});
		});

		try {
			resp = await API.post("ramapi", `/commhub/attachments`, { body: body });

			if (resp && resp.length) {
				let newResp = [...resp];

				resp.forEach((x, i) => {
					attachments.forEach(y => {
						if (x.filename == y.name) {
							newResp[i] = {
								...newResp[i],
								file: y
							};
						}
					});
				});

				for (let i = 0; i < newResp.length; i++) {
					try {
						axios
							.put(newResp[i].uri, newResp[i].file, {
								headers: {
									filename: newResp[i].file.name,
									"content-type": newResp[i].file.type
								}
							})
							.catch(error => {
								console.error(error);
							});
					} catch (e) {
						console.log(e, "Error Storing to S3");
					}
					await delay(1500);
				}
			}
		} catch (err) {
			console.log(err);
		}

		return resp;
	};

	handleScheduledActionModal = (e, data, modal) => {
		e && e.preventDefault();

		if (modal) {
			let date = moment();
			let mode = "sendNow";

			if (data && data.status) {
				if (data.status == "pending") {
					date = moment(data.due_date).utc();
					mode = "schedule";
				}
			}

			this.setState(
				{
					currentScheduledAction: data,
					scheduledDate: date,
					scheduleMsgMode: mode
				},
				() => {
					this.setState({
						modal: modal
					});
				}
			);
		} else {
			this.setState(
				{
					modal: ""
				},
				() => {
					this.setState({
						scheduleMsgMode: "",
						scheduledDate: moment(),
						resendActionErrMsg: "",
						cancelActionErrMsg: ""
					});
				}
			);
		}
	};

	onDateChange = date => {
		this.setState({
			scheduledDate: date
		});
	};

	handleSendAction = async (e, sendNow) => {
		e.preventDefault();
		const { currentScheduledAction, scheduledDate } = this.state;
		const todaysDate = moment();
		let methodType = "post";
		let dueDate = todaysDate.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
		let payload = {
			body: {
				scheduled_action_id: currentScheduledAction.scheduled_action_id,
				capability: "replay",
				due_date: dueDate
			}
		};

		if (!sendNow && moment(scheduledDate).isAfter(todaysDate)) {
			methodType = "put";
			dueDate = scheduledDate.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
			payload.body = {
				...currentScheduledAction,
				due_date: dueDate,
				status: "pending"
			};
		}

		this.setState({
			resendActionErrMsg: "",
			resendActionIsLoading: true
		});

		try {
			await API[methodType](
				"ramapi",
				`/commhub/organizations/${this.props.org}/scheduled-actions/${currentScheduledAction.scheduled_action_id}`,
				payload
			);

			await this.getScheduledActions();

			this.setState({
				resendActionIsLoading: false,
				currentScheduledAction: "",
				modal: ""
			});
		} catch (err) {
			const errorMsg = _.get(err.response, "data.Message", "");
			let msg = "An error has occurred. Please contact customer support.";

			if (errorMsg) {
				msg = errorMsg.replace(/^\('\s*|\',\s*-1\)$/g, "");
			}

			this.setState({
				resendActionErrMsg: msg,
				resendActionIsLoading: false
			});
		}
	};

	cancelAction = async () => {
		const { currentScheduledAction } = this.state;
		let payload = {
			headers: {
				Accept: "application/json"
			},
			body: {
				scheduled_action_id: currentScheduledAction.scheduled_action_id,
				capability: "cancel"
			}
		};

		this.setState({
			cancelActionErrMsg: "",
			cancelActionIsLoading: true
		});

		try {
			await API.post(
				"ramapi",
				`/commhub/organizations/${this.props.org}/scheduled-actions/${currentScheduledAction.scheduled_action_id}`,
				payload
			);

			await this.getScheduledActions();

			this.setState({
				scheduledActionsPage: 1,
				cancelActionIsLoading: false,
				currentScheduledAction: "",
				modal: ""
			});
		} catch (err) {
			const errMsg = _.get(err.response, "data.Message", "");
			let msg =
				"An Error Occurred While Rendering the Template. Please Contact Customer Service for Assistance.";

			if (errMsg) {
				msg = errMsg.replace(/^\('\s*|\',\s*-1\)$/g, "");
			}

			this.setState({
				cancelActionErrMsg: msg,
				cancelActionIsLoading: false
			});
		}
	};

	renderMsg = (msg, type) => {
		return (
			<Row>
				<Col xs={12} sm={12} className="top-margin">
					<div className={`msg-container ${type}`}>{msg}</div>
				</Col>
			</Row>
		);
	};

	handleViewTemplate = async (e, data) => {
		e.preventDefault();

		this.setState(
			{
				scheduledActionIsLoading: true,
				currentScheduledAction: data
			},
			async () => {
				await this.getViewTemplate();

				this.setState({
					scheduledActionIsLoading: false
				});
			}
		);
	};

	getViewTemplate = async () => {
		const { currentScheduledAction } = this.state;
		let resp;
		let payload = {
			responseType: "blob",
			headers: {
				Accept: "*/*"
			},
			body: {
				scheduled_action_id: currentScheduledAction.scheduled_action_id,
				capability: "view"
			},
			response: true
		};

		try {
			resp = await API.post(
				"ramapi",
				`/commhub/organizations/${this.props.org}/scheduled-actions/${currentScheduledAction.scheduled_action_id}`,
				payload
			);

			this.blobToHtml(resp.data);
		} catch (err) {
			const errMsg = _.get(err.response, "data.Message", "");
			let msg =
				"An Error Occurred While Rendering the Template. Please Contact Customer Service for Assistance.";

			if (errMsg) {
				msg = errMsg.replace(/^\('\s*|\',\s*-1\)$/g, "");
			}

			this.setState({
				previewHTML: "",
				errorMsg: msg,
				modal: "error-modal"
			});
		}
	};

	blobToHtml = async blob => {
		if (!blob) return;

		let htmlContent = "";

		await new Promise((resolve, reject) => {
			const reader = new FileReader();

			reader.onload = (function() {
				return function(e) {
					htmlContent = e.target.result;
					resolve();
				};
			})(blob);
			reader.readAsText(blob);
		});

		this.setState({
			previewHTML: htmlContent,
			modal: "preview-modal"
		});
	};

	closePreviewModal = () => {
		this.setState({
			modal: "",
			previewHTML: "",
			currentScheduledAction: ""
		});
	};

	handlePriorityFilter = name => {
		const { priorityFilters } = this.state;

		this.setState(
			{
				priorityFilters: {
					...priorityFilters,
					[name]: !priorityFilters[name]
				}
			},
			() => {
				const { threadSorted, threadPageSize } = this.state;

				this.getThreads(0, threadSorted, threadPageSize);
			}
		);
	};

	handleStatusFilters = name => {
		const { statusFilters } = this.state;

		this.setState(
			{
				statusFilters: {
					...statusFilters,
					[name]: !statusFilters[name]
				}
			},
			() => {
				const { threadSorted, threadPageSize } = this.state;

				this.getThreads(0, threadSorted, threadPageSize);
			}
		);
	};

	renderRequestIdentification = () => {
		const { guestVerificationData, guestVerificationIsLoading } = this.state;
		const verifiedStatus = _.get(guestVerificationData, "verified", "");

		if (guestVerificationIsLoading) {
			return <Loader />;
		}
		// Verified / Failed
		if (
			!_.isNull(guestVerificationData) &&
			!_.isNull(guestVerificationData.verified)
		) {
			return (
				<span>
					<div
						className={`identification-status left-padding-5 ${
							verifiedStatus ? "verified" : "unverified"
						}`}
					>
						{verifiedStatus
							? `Verified as ${_.get(
									guestVerificationData,
									"verified_first_name",
									""
							  )} ${_.get(guestVerificationData, "verified_last_name", "")}`
							: "Unverified"}
						<span className="left-padding-5">
							<i
								className={verifiedStatus ? "icon-gree_check" : "icon-wrong"}
							></i>
						</span>
					</div>
				</span>
			);
		}
		// Pending
		else if (
			!_.isNull(guestVerificationData) &&
			_.isNull(guestVerificationData.verified)
		) {
			return (
				<span className={`identification-status left-padding-5 pending`}>
					Indentity Verification Requested
				</span>
			);
		}
		// Request Identity
		else if (_.isNull(guestVerificationData)) {
			return (
				<span
					className="clickable-text left-padding-5"
					onClick={() => this.handleGuestVerificationModal(true)}
				>
					Request Identity Verification
				</span>
			);
		}
	};

	handleMarkPriority = value => {
		this.setState({
			markPriority: value
		});
	};

	handleMarkStatus = value => {
		this.setState(
			{
				markStatus: value
			},
			async () => {
				const { threadActivePage } = this.state;
				this.setState({ threadLoading: true });

				await this.updateThreadAttributes("status");

				this.setState({ threadLoading: false });

				await this.getThreads(threadActivePage, "", "", "", true);
			}
		);
	};

	renderPicky = (label, options, value, func, disabled) => {
		return (
			<Picky
				className="left-margin picky-dropdown-up"
				placeholder={label}
				labelKey="label"
				valueKey="value"
				options={options}
				value={value}
				onChange={value => func(value, label)}
				keepOpen={false}
				dropdownHeight={600}
				disabled={disabled}
				render={({
					style,
					item,
					isSelected,
					selectValue,
					labelKey,
					valueKey
				}) => {
					return (
						<li
							style={{ ...style }}
							className={isSelected ? "selected" : ""}
							key={item[valueKey]}
							onClick={() => selectValue(item)}
						>
							<span style={{ fontWeight: isSelected ? "bold" : "normal" }}>
								{item[labelKey] !== null && item[labelKey] !== ""
									? item[labelKey]
									: item[valueKey]}
							</span>
						</li>
					);
				}}
			/>
		);
	};

	changeSourceName = sourceName => {
		let name = sourceName;

		if (sourceName === "homeaway") {
			name = "VRBO";
		}

		return name;
	};

	renderStarsReview = rating => {
		return [1, 2, 3, 4, 5].map(star => {
			return (
				<i
					key={star}
					className={`icon-star-full ${star <= rating ? "fill" : ""}`}
				></i>
			);
		});
	};

	renderReview = () => {
		const { reviewData } = this.state;

		return (
			<Col md={12}>
				<Row className="reservation-reviews">
					{!_.isEmpty(reviewData) && reviewData !== null ? (
						<div className="reviews">
							<div className="panel-reviews">
								<Col md={12}>
									<div className="reviews-tr-custom">
										<div className="reviews-col">
											<div className="small-text">
												<strong>Reviewer Source</strong>
											</div>
											<div className="capitalize">
												{this.changeSourceName(_.get(reviewData, "source"))}
											</div>
										</div>
										<div className="reviews-col">
											<div className="small-text">
												<strong>Organization Name</strong>
											</div>
											<div>{_.get(reviewData, "organization_name")}</div>
										</div>
										<div className="reviews-col">
											<div className="small-text">
												<strong>Review ID</strong>
											</div>
											<div>{_.get(reviewData, "review_id")}</div>
										</div>
										<div className="reviews-col">
											<div className="small-text">
												<strong>Reservation ID</strong>
											</div>
											{_.get(reviewData, "reservation_id") && (
												<div className="display-flex">
													{_.get(reviewData, "reservation_id")}
												</div>
											)}
										</div>
										<div className="reviews-col">
											<div className="small-text">
												<strong>Property ID</strong>
											</div>
											{_.get(reviewData, "property_id") && (
												<div className="display-flex">
													<Link
														className='clickable-text'
														target='_blank'
														to={{ pathname: `/properties/${_.get(reviewData, "property_id")}/edit`}} 
													>
														<span>{_.get(reviewData, "property_id")}</span>
													</Link>
												</div>
											)}
										</div>
										<div className="reviews-col">
											<div className="small-text">
												<strong>Guests</strong>
											</div>
											<div>{_.get(reviewData, "number_of_guests")}</div>
										</div>
										<div className="reviews-col">
											<div className="small-text">
												<strong>Nights</strong>
											</div>
											<div>{_.get(reviewData, "number_of_nights")}</div>
										</div>
										{_.get(reviewData, "response.response_id", "") && (
											<div className="reviews-col">
												<div className="small-text">Response Date</div>
												<div>
													{moment(
														_.get(reviewData, "response.created", "")
													).isValid()
														? moment(
																_.get(reviewData, "response.created", "")
														  ).format("MM/DD/YYYY hh:mm A")
														: ""}
												</div>
											</div>
										)}
									</div>
								</Col>
								<Col md={12} className="reviews-reply">
									<div className="top-margin reviews-reply-title">
										{`${
											_.get(reviewData, "reviewer.name", "")
												? `${_.get(reviewData, "reviewer.name", "")}'s`
												: ""
										}Review:`}
									</div>
									<div className="reviews-bubble">
										{_.get(reviewData, "comment")}
									</div>
								</Col>
								{_.get(reviewData, "private_comment", "") && (
									<Col md={12} className="reviews-reply">
										<div className="reviews-reply-title">Private Message:</div>
										<div className="reviews-bubble">
											{_.get(reviewData, "private_comment", "")}
										</div>
									</Col>
								)}
								{_.get(reviewData, "response_status", "") !== "no_response" &&
									_.get(reviewData, "response.comment", "") && (
										<Fragment>
											<Col md={12}>
												<div className="flex-align-right top-margin">
													<div className="reviews-reply full-width">
														{_.get(reviewData, "response.reviewer.name") && (
															<div className="reviews-reply-title">
																{`${_.get(
																	reviewData,
																	"response.reviewer.name"
																)}'s Reply:`}
															</div>
														)}
														<div className="reviews-bubble">
															{_.get(reviewData, "response.comment", "")}
														</div>
													</div>
												</div>
											</Col>
										</Fragment>
									)}
							</div>
						</div>
					) : (
						<div>No Reviews</div>
					)}
				</Row>
			</Col>
		);
	};

	getReservationCommData = async (id, type) => {
		let resp;

		try {
			resp = await API.get(
				"gbapi",
				`/reservations/${id}/commdata?view=preview`,
				{ response: true }
			);
			if (resp) {
				let state = {};
				if (type == "guestVerification") {
					state = {
						reservationCommData: resp.data
					};
				} else {
					state = {
						reservationCommData: resp.data,
						hostNotes: _.get(resp, "data.host_notes") || ""
					};
				}

				this.setState(state);
			}
		} catch (err) {}
	};

	getPropertyCommData = async id => {
		let resp;

		try {
			resp = await API.get("gbapi", `/properties/${id}/commdata?view=preview`, {
				response: true
			});
			if (resp) {
				this.setState({
					propertyCommData: resp.data
				});
			}
		} catch (err) {}
	};

	navigate = (e, url) => {
		if (e) {
			e.stopPropagation();
		}
		const data = { dashboard: true };
		const newWindow = window.open(url, "_blank");

		newWindow.data = data;
	};

	submitHostNotes = async () => {
		const { hostNotes, reservationCommData } = this.state;
		let body = {
			attribute_name: "host_notes",
			attribute_value: hostNotes
		};
		let resp;
		const loader =
			this.view == "threads" ? "messageIsLoading" : "threadLoading";

		this.setState({
			hostNotesErr: "",
			[loader]: true
		});

		try {
			resp = await API.post(
				constants.RAPAPI.NAME,
				`/properties/reservations/${reservationCommData.reservation_id}/attributes`,
				{ body: [body] }
			);
			if (resp) {
				await this.getReservationCommData(
					reservationCommData.reservation_id,
					"hostNotes"
				);

				this.setState({
					messageIsLoading: false,
					editHostNotes: false,
					threadLoading: false
				});
			}
		} catch (err) {
			let msg = `Oops! We encountered an error. Please contact customer support.`;

			if (_.get(err, "response.data.Message.length")) {
				if (err.response.data.Message.match(/'(.*?)'/)) {
					msg = err.response.data.Message.match(/'(.*?)'/)[1];
				}
			}

			this.setState({
				hostNotesErr: msg,
				messageIsLoading: false,
				editHostNotes: false,
				threadLoading: false
			});
		}
	};

	toggleState = (e, stateName) => {
		e.preventDefault();

		this.setState({
			[stateName]: !this.state[stateName]
		});
	};

	hostNotesOnChange = e => {
		e.preventDefault();
		const value = e.target.value;

		this.setState({
			hostNotes: value
		});
	};

	handleShowAllOrg = (name, apiCall) => {
		this.setState(
			{
				[name]: !this.state[name]
			},
			() => {
				apiCall(0);
			}
		);
	};

	threadPageOnChange = direct => {
		const { threadActivePage } = this.state;
		let page = 1;

		switch (direct) {
			case "prev":
				page = threadActivePage - 1;
				break;
			case "next":
				page = threadActivePage + 1;
				break;
			default:
				page = 1;
		}

		this.getThreads(page, "", "", "", "", true);
	};

	render() {
		const {
			mode,
			init,
			modal,
			reservationNotes,
			isLoading,
			details,
			totalPayment,
			pm_promotion_detail,
			threadColumns,
			threadPageSize,
			threadActivePage,
			reservationId,
			threadData,
			threadSubject,
			threadMessage,
			modalData,
			threadLoading,
			invoiceLoading,
			threadDates,
			focusedInput,
			threadSorted,
			threadIsLoading,
			showOnlyUnread,
			markAsUnread,
			threadKeyword,
			commCenterAccess,
			scheduledActionsList,
			scheduledActionsColumns,
			currentScheduledAction,
			scheduledActionIsLoading,
			scheduledActionsPage,
			scheduledActionsPageSize,
			scheduledDate,
			scheduleMsgMode,
			resendActionIsLoading,
			cancelActionIsLoading,
			resendActionErrMsg,
			activeKey,
			previewHTML,
			priorityFilters,
			replyTo,
			guestVerificationModalIsLoading,
			guestVerificationError,
			statusFilters,
			markStatus,
			markPriority,
			isSuggestedReplyLoading,
			suggestedReplies,
			suggestedRepliesCurrentIndex,
			scheduledActionsLogs,
			scheduledActionsLogsColumns,
			scheduledActionsLogsErrMsg,
			errorMsg,
			reviewData,
			propertyCommData,
			reservationCommData,
			hostNotes,
			editHostNotes,
			hostNotesErr,
			guestVerificationModal,
			cancelActionErrMsg,
			reviewErrMsg
		} = this.state;
		let { mobileMode } = this.props,
			daysUntilCheckin = moment(details.checkin_date).diff(moment(), "days"),
			isCheckoutOlder =
				moment().diff(details.checkout_date, "days") > 60 ? true : false,
			guestSum =
				details.adults || details.children
					? details.adults + details.children
					: 0;

		const parsed = queryString.parse(this.props.location.search);
		const rental_price =
			details &&
			details.price &&
			details.price.rental_price &&
			details.price.rental_price;
		const cleaning_fee =
			details &&
			details.price &&
			details.price.cleaning_fee &&
			details.price.cleaning_fee;
		const other_fees =
			details &&
			details.price &&
			details.price.other_fees &&
			details.price.other_fees;
		const ra_commission =
			details &&
			details.price &&
			details.price.ra_commission &&
			details.price.ra_commission;
		const tax = details && details.price && details.price.tax;
		const channel_collected_tax =
			details && details.price && details.price.channel_collected_tax;
		const cc_processing =
			details &&
			details.price &&
			details.price.cc_processing &&
			details.price.cc_processing;
		const adjustments =
			details &&
			details.price &&
			details.price.adjustments &&
			details.price.adjustments;
		const additional_fees =
			details && details.price && details.price.additional_fees;
		const currency =
			details && details["price"] && details["price"]["currency"];
		const currencySymbol = currency && constants.CURRENCY[currency];

		if (!this.showAddFees && parsed["showAddFees"] == "true") {
			this.showAddFees = parsed["showAddFees"] == "true";
		}

		let scheduledActionBtnTag = scheduleMsgMode == "sendNow" ? "Send" : "Save";
		let scheduledText = "Send";

		if (currentScheduledAction && currentScheduledAction.status) {
			if (
				currentScheduledAction.status != "pending" &&
				currentScheduledAction.status != "cancelled"
			) {
				scheduledText = "Resend";
			}
		}

		const status =
			reviewData && reviewData.response_status == "no_response"
				? "No Response"
				: reviewData && reviewData.response_status;

		return (
			<PageLayout isCentered={true}>
				<div className="reservation-details-container">
					{isLoading && <Loader />}

					<Title
						title={`Reservation ${reservationId} Details`}
						breadcrumbItems={this.breadcrumbItems}
						isCentered={true}
						handleBack={e => this.props.history.goBack()}
					>
						{(this.isAdmin || this.guestServices) && !isLoading && (
							<DropdownButton
								title="Actions"
								className="primary-btn white-btn"
								id="property-actions"
								onToggle={this.toggleDropdown}
								disabled={isLoading}
							>
								<MenuItem
									onClick={() =>
										this.props.history.push(
											`/reservations/${details.reservation_id}/report-a-problem`
										)
									}
								>
									Report a Problem
								</MenuItem>
							</DropdownButton>
						)}
					</Title>
					<div id="content" style={mobileMode ? { textAlign: "center" } : {}}>
						<Grid fluid={true} className={mobileMode ? "mobile-col" : ""}>
							<div className={mobileMode ? "mobile-col" : ""}>
								<PanelGroup
									accordion
									className="property-item reservation-details"
									id="details"
									activeKey={activeKey}
									onSelect={activeKey => {
										if (mobileMode) {
											this.setState({
												activeKey,
												init: false
											});
										}
									}}
								>
									{mobileMode ? (
										<Fragment>
											<Panel eventKey={1}>
												<Panel.Heading>
													<Panel.Title toggle>
														<strong>Reservation Information</strong>
														<span
															className={
																"pull-right " +
																(this.state.activeKey === 1
																	? "glyphicon glyphicon-chevron-up"
																	: "glyphicon glyphicon-chevron-down")
															}
														></span>
													</Panel.Title>
												</Panel.Heading>
												<Panel.Collapse>
													<Panel.Body>
														<Row>
															<Col xs={12}>
																<div className="reservation-item">
																	<p>Property</p>
																	<p>
																		<strong>
																			{details.property_id} |
																			{details.external_property_id
																				? ` ${details.external_property_id} |`
																				: ""}
																			{` ${details.property_title}`}
																		</strong>
																	</p>
																</div>
															</Col>
															<Col xs={6}>
																<div className="reservation-item">
																	<p>Check-In</p>
																	<p>
																		<strong>
																			{moment(details.checkin_date).format(
																				"MMM Do, YYYY"
																			)}
																		</strong>
																	</p>
																</div>
																<div className="reservation-item">
																	<p># of Nights</p>
																	<p>
																		<strong>
																			{moment(details.checkout_date).diff(
																				details.checkin_date,
																				"days"
																			)}
																		</strong>
																	</p>
																</div>
																<div className="reservation-item">
																	<p>Date Booked</p>
																	<p>
																		<strong>
																			{moment(details.book_date).format(
																				"MMM Do, YYYY"
																			)}
																		</strong>
																	</p>
																</div>
															</Col>
															<Col xs={6}>
																<div className="reservation-item">
																	<p>Check-Out</p>
																	<p>
																		<strong>
																			{moment(details.checkout_date).format(
																				"MMM Do, YYYY"
																			)}
																		</strong>
																	</p>
																</div>
																<div className="reservation-item">
																	<p># of Guests</p>
																	<p>
																		<strong>{guestSum}</strong>
																	</p>
																</div>
																<div className="reservation-item">
																	<p>Status</p>
																	<p>
																		<strong>{details.status}</strong>
																	</p>
																</div>
															</Col>
														</Row>
													</Panel.Body>
												</Panel.Collapse>
											</Panel>
											{commCenterAccess && this.messageAccess && (
												<Panel eventKey={init ? 1 : 2}>
													<Panel.Heading>
														<Panel.Title toggle>
															<span
																className={
																	"pull-right " +
																	(this.state.activeKey === 2
																		? "glyphicon glyphicon-chevron-up"
																		: "glyphicon glyphicon-chevron-down")
																}
															></span>
															<strong>Communication Center</strong>
														</Panel.Title>
													</Panel.Heading>
													<Panel.Collapse>
														<Panel.Body>
															<div className="communication-center">
																{threadIsLoading && <Loader />}

																<Row>
																	{details.first_name && (
																		<Fragment>
																			<Col md={6} xs={12}>
																				<div className="guest-notes-title">
																					Guest Name
																				</div>
																			</Col>
																			<Col xs={12} className="bottom-margin-20">
																				<h4>{`${details.first_name} ${details.last_name}`}</h4>
																			</Col>
																		</Fragment>
																	)}
																	{details.guest_notes && (
																		<Fragment>
																			<Col md={6} xs={12}>
																				<div className="guest-notes-title">
																					Guest Notes{" "}
																					<span className="small-text">
																						(Guest Reservation Message)
																					</span>
																				</div>
																			</Col>
																			<Col xs={12}>
																				<div className="guest-notes">{`${details.guest_notes}`}</div>
																			</Col>
																		</Fragment>
																	)}
																	<Col
																		md={6}
																		sm={12}
																		className="bottom-margin-20"
																	>
																		<PrimaryButton
																			cssClass="blue-btn "
																			onClick={() =>
																				this.handleOpenMsgModal("", "create")
																			}
																		>
																			<i className="icon-Plus create-icon" />{" "}
																			New Message
																		</PrimaryButton>
																	</Col>
																</Row>
																<Row>
																	<Col
																		md={12}
																		sm={12}
																		className="reports_performance"
																	>
																		<ReactTableComponent
																			manual
																			minRows={0}
																			className="-highlight"
																			getRef={r => (this.threadTable = r)}
																			reactTable={this.threadTable}
																			noDataText={
																				isLoading ? "Loading..." : "No Messages"
																			}
																			showPagination={false}
																			data={threadData}
																			resizable={false}
																			pageSize={Number(threadPageSize)}
																			activePage={threadActivePage}
																			columns={threadColumns}
																			pageSizeOnChange={pageSize =>
																				this.pageSizeOnChange(
																					pageSize,
																					"thread"
																				)
																			}
																			pageOnChange={page =>
																				this.pageOnChange(page, "thread")
																			}
																			onSortedChange={this.onSortedChange}
																			getTrProps={(state, rowInfo, column) => {
																				const data =
																					rowInfo && rowInfo.original;
																				return {
																					onClick: () => {
																						this.handleOpenMsgModal(
																							data.thread_id,
																							"edit"
																						);
																					},
																					style: {
																						cursor: "pointer"
																					}
																				};
																			}}
																		/>
																	</Col>
																</Row>
																<Row>
																	<Col
																		md={12}
																		sm={12}
																		className="pagination-container top-margin-20"
																	>
																		<div className="page_size-input-container">
																			<div className="page-size">
																				<span className="right-margin-5">
																					Rows per page:
																				</span>
																				<InputField
																					className="pageSize"
																					type="number"
																					name="pageSize"
																					autoComplete="off"
																					defaultValue={Number(threadPageSize)}
																					onKeyUp={e => {
																						let value = e.target.value;

																						if (value > 100) {
																							value = 100;
																						} else if (value < 0) {
																							value = 1;
																						}

																						if (
																							value.length &&
																							e.keyCode === 13 &&
																							value !== 0 &&
																							Number.isInteger(Number(value))
																						) {
																							this.pageSizeOnChange(
																								value,
																								"thread"
																							);
																						}
																					}}
																				/>
																				<span className="left-margin">
																					Page &nbsp; {threadActivePage}
																				</span>
																			</div>
																			<button
																				name="prev"
																				className="transaction-pagination-btn"
																				type="button"
																				onClick={() =>
																					this.threadPageOnChange("firstPage")
																				}
																				disabled={threadActivePage == 1}
																			>
																				<i className="icon-first-page"></i>
																			</button>
																			<button
																				name="prev"
																				className="transaction-pagination-btn"
																				type="button"
																				onClick={() =>
																					this.threadPageOnChange("prev")
																				}
																				disabled={threadActivePage == 1}
																			>
																				Prev
																			</button>
																			<button
																				name="next"
																				className="transaction-pagination-btn"
																				type="button"
																				onClick={() =>
																					this.threadPageOnChange("next")
																				}
																				disabled={
																					_.get(threadData, "length") <= 0
																				}
																			>
																				Next
																			</button>
																		</div>
																	</Col>
																</Row>
															</div>
														</Panel.Body>
													</Panel.Collapse>
												</Panel>
											)}
											<Panel eventKey={3}>
												<Panel.Heading>
													<Panel.Title toggle>
														<strong>Payment Information</strong>
														<span
															className={
																"pull-right " +
																(this.state.activeKey === 3
																	? "glyphicon glyphicon-chevron-up"
																	: "glyphicon glyphicon-chevron-down")
															}
														></span>
													</Panel.Title>
												</Panel.Heading>
												<Panel.Collapse>
													<Panel.Body>
														{details &&
															details.price &&
															Object.keys(details.price).length && (
																<Fragment>
																	{this.renderVcc()}
																	<Row>
																		<Col xs={12}>
																			<div className="reservation-item">
																				<p>Total Payment</p>
																				<p>
																					<strong>
																						{currencySymbol}
																						{totalPayment.toFixed(2)}
																					</strong>
																				</p>
																			</div>
																			{!rental_price ? (
																				""
																			) : (
																				<div className="reservation-item">
																					<p>Rental</p>
																					<p>
																						<strong>
																							{currencySymbol}
																							{rental_price.toFixed(2)}
																						</strong>
																					</p>
																				</div>
																			)}
																		</Col>
																		<Col xs={6}>
																			{!cleaning_fee ? (
																				""
																			) : (
																				<div className="reservation-item">
																					<p>Cleaning Fee</p>
																					<p>
																						<strong>
																							{currencySymbol}
																							{cleaning_fee.toFixed(2)}
																						</strong>
																					</p>
																				</div>
																			)}
																			{!other_fees ? (
																				""
																			) : (
																				<div className="reservation-item">
																					<p>Other Fees</p>
																					<p>
																						<strong>
																							{currencySymbol}
																							{other_fees.toFixed(2)}
																						</strong>
																					</p>
																				</div>
																			)}
																			{!ra_commission ? (
																				""
																			) : (
																				<div className="reservation-item">
																					<p>Commission</p>
																					<p>
																						<strong>
																							{currencySymbol}
																							{ra_commission.toFixed(2) || 0}
																						</strong>
																					</p>
																				</div>
																			)}
																		</Col>
																		<Col xs={6}>
																			{!tax ? (
																				""
																			) : (
																				<div className="reservation-item">
																					<p>Tax</p>
																					<p>
																						<strong>
																							{currencySymbol}
																							{tax.toFixed(2)}
																						</strong>
																					</p>
																				</div>
																			)}
																			{!channel_collected_tax ? (
																				""
																			) : (
																				<div className="reservation-item">
																					<p>
																						Tax Paid{" "}
																						{infoPopover(
																							this.taxPaidInfo,
																							null,
																							"top",
																							"icon-question-mark"
																						)}
																					</p>
																					<p>
																						<strong>
																							{currencySymbol}
																							{channel_collected_tax.toFixed(2)}
																						</strong>
																					</p>
																				</div>
																			)}
																			{!cc_processing ? (
																				""
																			) : (
																				<div className="reservation-item">
																					<p>Processing Fee</p>
																					<p>
																						<strong>
																							{currencySymbol}
																							{cc_processing.toFixed(2) || 0}
																						</strong>
																					</p>
																				</div>
																			)}
																		</Col>
																		<Col xs={12}>
																			{!adjustments ? (
																				""
																			) : (
																				<div className="reservation-item">
																					<p>Adjustments</p>
																					<p>
																						<strong>
																							{currencySymbol}
																							{adjustments.toFixed(2)}
																						</strong>
																					</p>
																				</div>
																			)}
																		</Col>
																		<Col xs={12}></Col>
																		{additional_fees &&
																			details.price.additional_fees.length >
																				0 && (
																				<div className="reservation-item">
																					<p>Adjustment Details</p>
																					{this.displayAdditionalFees(
																						mobileMode,
																						details
																					)}
																					<p>
																						<sup>
																							( Additional fees are inclusive of
																							tax )
																						</sup>
																					</p>
																				</div>
																			)}
																		{!pm_promotion_detail.id ? (
																			""
																		) : (
																			<Col xs={12}>
																				<div className="reservation-item">
																					<p>Promotions Applied</p>
																					<p>
																						<strong>
																							{currencySymbol}
																							{pm_promotion_detail.total.toFixed(
																								2
																							)}
																						</strong>
																					</p>
																				</div>
																			</Col>
																		)}
																	</Row>
																</Fragment>
															)}
													</Panel.Body>
												</Panel.Collapse>
											</Panel>
											<Panel eventKey={4}>
												<Panel.Heading>
													<Panel.Title toggle>
														<span
															className={
																"pull-right " +
																(this.state.activeKey === 4
																	? "glyphicon glyphicon-chevron-up"
																	: "glyphicon glyphicon-chevron-down")
															}
														></span>
														<strong>Reservation Notes</strong>{" "}
														<p>
															Your internal reservation notes or tasks - will
															not be sent to the guests or RedAwning
														</p>
													</Panel.Title>
												</Panel.Heading>
												<Panel.Collapse>
													<Panel.Body>
														{this.renderReservationNotes()}
														<Row className="top-margin-20">
															<Col sm={3} className="pull-left">
																<PrimaryButton
																	cssClass="blue-btn "
																	onClick={this.addReservationNotes}
																	disabled={reservationNotes.length == 2}
																>
																	<i className="icon-Plus create-icon" /> Add
																	Reservation Note
																</PrimaryButton>
															</Col>
														</Row>
													</Panel.Body>
												</Panel.Collapse>
											</Panel>
											{
												(this.reviewAccess &&
												!isLoading &&
												_.get(reviewData, "review_id")) && (
													<Panel eventKey={5}>
														<Panel.Heading>
															<Panel.Title toggle>
																<span
																	className={
																		"pull-right " +
																		(this.state.activeKey === 5
																			? "glyphicon glyphicon-chevron-up"
																			: "glyphicon glyphicon-chevron-down")
																	}
																></span>
																<strong>Review</strong>
																<div className="reviews-tr">
																	<div className="reviews-col">
																		{this.renderStarsReview(
																			_.get(reviewData, "rating", "")
																		)}
																	</div>
																	{status && (
																		<div className="right-padding reviews-col capitalize">
																			({status})
																		</div>
																	)}
																</div>
															</Panel.Title>
														</Panel.Heading>
														<Panel.Body collapsible>
															{
																reviewErrMsg
																? this.renderReview()
																: this.renderMsg(reviewErrMsg, "_error")
															}
														</Panel.Body>
													</Panel>
												)}
											<Panel eventKey={6}>
												<Panel.Heading>
													<Panel.Title toggle>
														<strong>Guest Information</strong>
														<span
															className={
																"pull-right " +
																(this.state.activeKey === 6
																	? "glyphicon glyphicon-chevron-up"
																	: "glyphicon glyphicon-chevron-down")
															}
														></span>
													</Panel.Title>
												</Panel.Heading>
												<Panel.Collapse>
													<Panel.Body>
														{details.share_contact_status == "1" ||
														daysUntilCheckin <= 30 ? (
															<Row>
																<Col xs={12}>
																	<div>
																		<div>Name</div>
																		<div>
																			<strong>{`${details.first_name} ${details.last_name}`}</strong>
																		</div>
																	</div>
																</Col>
																<Col xs={12}>
																	<div>
																		<div>Email</div>
																		<div>
																			<strong>
																				<a href={`mailto:${details.email}`}>
																					{details.email}
																				</a>
																			</strong>
																		</div>
																	</div>
																</Col>
																<Col xs={12}>
																	<div>
																		<div>Phone</div>
																		<div>
																			<strong>
																				<a href={`tel:${details.phone}`}>
																					{details.phone}
																				</a>
																			</strong>
																		</div>
																	</div>
																</Col>
															</Row>
														) : (
															<Row>
																<Col xs={12}>
																	{isCheckoutOlder
																		? "Renter information is not displayed for reservations older than 60 days."
																		: "Renter information will be displayed within 30 days of the check in date."}
																</Col>
															</Row>
														)}
													</Panel.Body>
												</Panel.Collapse>
											</Panel>
											{details.guest_notes ? (
												<Panel eventKey={7}>
													<Panel.Heading>
														<Panel.Title toggle>
															<strong>Guest Notes</strong>
															<span
																className={
																	"pull-right " +
																	(this.state.activeKey === 7
																		? "glyphicon glyphicon-chevron-up"
																		: "glyphicon glyphicon-chevron-down")
																}
															></span>
														</Panel.Title>
													</Panel.Heading>
													<Panel.Collapse>
														<Panel.Body>
															<Row>
																<Col xs={12} sm={4}>
																	{`${details.guest_notes}`}
																</Col>
															</Row>
														</Panel.Body>
													</Panel.Collapse>
												</Panel>
											) : (
												""
											)}
										</Fragment>
									) : (
										<Fragment>
											<Panel eventKey={1}>
												<Panel.Heading>
													<Panel.Title toggle>
														<h2>Reservation Information</h2>
														<div className="fs-warning">
															For more details on{" "}
															<Link
																className="clickable-text"
																to={`/account/faq/getting_paid`}
															>
																Payout Timing
															</Link>
															.
														</div>
													</Panel.Title>
												</Panel.Heading>
												<Panel.Collapse>
													<Panel.Body>
														<Row>
															<Col xs={6} sm={6}>
																<Row>
																	<Col xs={6}>
																		<span className="detail-label">
																			Property ID:{" "}
																			<h2>
																				<Link
																					className='clickable-text'
																					target='_blank'
																					to={{ pathname: `/properties/${_.get(details, "property_id")}/edit`}} 
																				>
																					<strong>{_.get(details, "property_id")}</strong>
																				</Link>
																			</h2>
																		</span>
																		{details.external_property_id ? (
																			<span className="detail-label">
																				Reference ID:{" "}
																				<h2>
																					<strong>
																						{details.external_property_id}
																					</strong>
																				</h2>
																			</span>
																		) : (
																			""
																		)}
																		<span className="detail-label">
																			Property:{" "}
																			<h2>
																				<strong>
																					{details.property_title}
																				</strong>
																			</h2>
																		</span>
																	</Col>
																</Row>
															</Col>
															<Col xs={6} sm={6}>
																<Row>
																	<Col xs={6}>
																		<span className="detail-label">
																			Date Booked:{" "}
																		</span>
																		<h2>
																			<strong>
																				{moment(details.book_date).format(
																					"MMM Do, YYYY"
																				)}
																			</strong>
																		</h2>
																		{details && details.cancellation_date && (
																			<Fragment>
																				<span className="detail-label">
																					Date Cancelled:{" "}
																				</span>
																				<h2>
																					<strong>
																						{moment(
																							details.cancellation_date
																						).format("MMM Do, YYYY")}
																					</strong>
																				</h2>
																			</Fragment>
																		)}
																	</Col>
																</Row>
															</Col>
														</Row>
														<hr style={{ borderTopColor: "#ddd" }} />
														<br />
														<Row>
															<Col xs={12} sm={6}>
																<Row className="bottom-margin-20">
																	<Col xs={6}>
																		<span className="detail-label">
																			Check-In
																		</span>
																		<h2>
																			<strong>
																				{moment(details.checkin_date).format(
																					"MMM Do, YYYY"
																				)}
																			</strong>
																		</h2>
																	</Col>
																	<Col xs={6}>
																		<span className="detail-label">
																			Check-Out
																		</span>
																		<h2>
																			<strong>
																				{moment(details.checkout_date).format(
																					"MMM Do, YYYY"
																				)}
																			</strong>
																		</h2>
																	</Col>
																</Row>
																<Row>
																	<Col xs={6}>
																		<span className="detail-label">
																			Days Until Check-In
																		</span>
																		<h2>
																			<strong>
																				{daysUntilCheckin > 0
																					? daysUntilCheckin
																					: 0}
																			</strong>
																		</h2>
																	</Col>
																	<Col xs={6}>
																		<span className="detail-label">Guests</span>
																		<h2 className="pull-left">
																			<strong>{guestSum}</strong>
																		</h2>
																		{(details.adults || details.children) && (
																			<span className="sub-text">
																				(
																				{`${details.adults} Adults, ${details.children} Children`}
																				)
																			</span>
																		)}
																	</Col>
																	<Col xs={12}>
																		<span className="detail-label">
																			# of Nights
																		</span>
																		<h2>
																			<strong>
																				{moment(details.checkout_date).diff(
																					details.checkin_date,
																					"days"
																				)}
																			</strong>
																		</h2>
																	</Col>
																</Row>
																{this.renderVcc()}
															</Col>
															{details &&
															details.price &&
															Object.keys(details.price).length ? (
																<Col xs={12} sm={6}>
																	<h2 className="detail-label">
																		<strong>Payment Breakdown</strong>
																	</h2>
																	{!rental_price ? (
																		""
																	) : (
																		<div className="reservation-billing">
																			<h4>Rental</h4>
																			<div className="reservation-billing-border"></div>
																			<h4>
																				<strong>
																					{currencySymbol}
																					{rental_price.toFixed(2)}
																				</strong>
																			</h4>
																		</div>
																	)}
																	{!cleaning_fee ? (
																		""
																	) : (
																		<div className="reservation-billing">
																			<h4>Cleaning Fee</h4>
																			<div className="reservation-billing-border"></div>
																			<h4>
																				<strong>
																					{currencySymbol}
																					{cleaning_fee.toFixed(2)}
																				</strong>
																			</h4>
																		</div>
																	)}
																	{!other_fees ? (
																		""
																	) : (
																		<div className="reservation-billing">
																			<h4>Other Fees</h4>
																			<div className="reservation-billing-border"></div>
																			<h4>
																				<strong>
																					{currencySymbol}
																					{other_fees.toFixed(2)}
																				</strong>
																			</h4>
																		</div>
																	)}
																	{!tax ? (
																		""
																	) : (
																		<div className="reservation-billing">
																			<h4>Tax</h4>
																			<div className="reservation-billing-border"></div>
																			<h4>
																				<strong>
																					{currencySymbol}
																					{tax.toFixed(2)}
																				</strong>
																			</h4>
																		</div>
																	)}
																	{!channel_collected_tax ? (
																		""
																	) : (
																		<div className="reservation-billing">
																			<h4>
																				Tax Paid{" "}
																				{infoPopover(
																					this.taxPaidInfo,
																					null,
																					"top",
																					"icon-question-mark"
																				)}
																			</h4>
																			<div className="reservation-billing-border"></div>
																			<h4>
																				<strong>
																					{currencySymbol}
																					{channel_collected_tax.toFixed(2)}
																				</strong>
																			</h4>
																		</div>
																	)}
																	{!ra_commission ? (
																		""
																	) : (
																		<div className="reservation-billing">
																			<h4>Commission</h4>
																			<div className="reservation-billing-border"></div>
																			<h4>
																				<strong>
																					{currencySymbol}
																					{ra_commission.toFixed(2) || 0}
																				</strong>
																			</h4>
																		</div>
																	)}
																	{!cc_processing ? (
																		""
																	) : (
																		<div className="reservation-billing">
																			<h4>Processing Fee</h4>
																			<div className="reservation-billing-border"></div>
																			<h4>
																				<strong>
																					{currencySymbol}
																					{cc_processing.toFixed(2) || 0}
																				</strong>
																			</h4>
																		</div>
																	)}
																	{!adjustments ? (
																		""
																	) : (
																		<div className="reservation-billing">
																			<h4>Adjustments</h4>
																			<div className="reservation-billing-border"></div>
																			<h4>
																				<strong>
																					{currencySymbol}
																					{adjustments.toFixed(2)}
																				</strong>
																			</h4>
																		</div>
																	)}
																	<div className="reservation-billing">
																		<h1>
																			<strong>Total Payment</strong>
																		</h1>
																		<div className="reservation-billing-border"></div>
																		<h1>
																			<strong>
																				{currencySymbol}
																				{totalPayment.toFixed(2)}
																			</strong>
																		</h1>
																	</div>
																	<div className="reservation-billing">
																		<hr style={{ borderTopColor: "#ddd" }} />
																		<br />
																	</div>
																	{(additional_fees &&
																		details.price.additional_fees.length) >
																		0 && (
																		<Fragment>
																			<h2 className="detail-label">
																				<strong>Adjustment Details</strong>
																			</h2>
																			<sup>
																				( Additional fees are inclusive of tax )
																			</sup>
																		</Fragment>
																	)}
																	{(additional_fees &&
																		details.price.additional_fees.length) > 0 &&
																		this.displayAdditionalFees(
																			mobileMode,
																			details
																		)}
																	{
																		<ContactGSMessage
																			details={details}
																			showAddFees={this.showAddFees}
																			isRestrictedFee={this.isRestrictedFee(
																				details
																			)}
																		/>
																	}
																	{invoiceLoading ? (
																		<div className="invoice-container">
																			<Loader />
																		</div>
																	) : (
																		<AddFeesButton
																			showAddAdditionalFeesModal={
																				this.showAddAdditionalFeesModal
																			}
																			details={details}
																			noPayProcList={
																				this.NO_PAYMENT_PROCESSING_LIST
																			}
																			showAddFees={this.showAddFees}
																			isAdmin={this.isAdmin}
																			guestServices={this.guestServices}
																			isRestrictedFee={this.isRestrictedFee(
																				details
																			)}
																			handleCheckCommissionAdditionalFee={
																				this.handleCheckCommissionAdditionalFee
																			}
																			handleOptionalFees={
																				this.handleOptionalFees
																			}
																		/>
																	)}
																	{pm_promotion_detail.id != 0 && (
																		<h2 className="detail-label">
																			<strong>Promotions Applied</strong>
																		</h2>
																	)}
																	{pm_promotion_detail.id != 0 && (
																		<Fragment>
																			<div className="reservation-billing">
																				<h4>{pm_promotion_detail.name}</h4>
																				{pm_promotion_detail.promo_code && (
																					<p className="small-text">
																						Promo Code: "
																						{pm_promotion_detail.promo_code}"
																					</p>
																				)}
																				<div className="reservation-billing-border"></div>
																				<h4>
																					<strong>
																						{currencySymbol}
																						{pm_promotion_detail.total.toFixed(
																							2
																						)}
																					</strong>
																				</h4>
																			</div>
																			<div>
																				<p className="small-text">
																					(Rental amount above is NET of this
																					amount)
																				</p>
																			</div>
																		</Fragment>
																	)}
																</Col>
															) : (
																""
															)}
														</Row>
													</Panel.Body>
												</Panel.Collapse>
											</Panel>
											{commCenterAccess && this.viewMessages && (
												<Panel eventKey={1}>
													<Panel.Heading>
														<Panel.Title toggle>
															<h2>Communication Center</h2>
														</Panel.Title>
													</Panel.Heading>
													<Panel.Collapse>
														<Panel.Body>
															<div className="communication-center">
																<Row>
																	{!isLoading && (
																		<Fragment>
																			<Col md={6} xs={12}>
																				<div className="flex-align-items-center position-relative">
																					<div className="guest-notes-title">
																						Guest Information
																					</div>
																					{this.renderRequestIdentification()}
																				</div>
																				<span className="custom-hr" />
																			</Col>
																			{details.share_contact_status == "1" ||
																			daysUntilCheckin <= 30 ? (
																				<Fragment>
																					<Col xs={12}>
																						<div className="reservation-item">
																							<div>Name</div>
																							<div>
																								<strong>{`${details.first_name} ${details.last_name}`}</strong>
																							</div>
																						</div>
																					</Col>
																					<Col xs={12}>
																						<div className="reservation-item">
																							<div>Email</div>
																							<div>
																								<strong>
																									<a
																										href={`mailto:${details.email}`}
																									>
																										{details.email}
																									</a>
																								</strong>
																							</div>
																						</div>
																					</Col>
																					<Col
																						xs={12}
																						className="bottom-margin-30"
																					>
																						<div className="reservation-item">
																							<div>Phone</div>
																							<div>
																								<strong>
																									<a
																										href={`tel:${details.phone}`}
																									>
																										{details.phone}
																									</a>
																								</strong>
																							</div>
																						</div>
																					</Col>
																				</Fragment>
																			) : (
																				<Col
																					xs={12}
																					className="top-margin bottom-margin-30"
																				>
																					{isCheckoutOlder
																						? "Renter information is not displayed for reservations older than 60 days."
																						: "Renter information will be displayed within 30 days of the check in date."}
																				</Col>
																			)}
																		</Fragment>
																	)}
																</Row>
																{this.viewMessages && (
																	<Row>
																		<Col
																			md={12}
																			xs={12}
																			className="bottom-margin-30"
																		>
																			<div className="guest-notes-title">
																				Messages
																			</div>
																			<span className="custom-hr" />
																		</Col>
																		<div className="dashboard">
																			<Col md={12} className="db-comm-center">
																				{threadIsLoading && <Loader />}

																				<Col
																					md={12}
																					sm={12}
																					className="comm-center-filter"
																				>
																					<Col
																						lg={12}
																						md={12}
																						sm={12}
																						className="filter-row"
																					>
																						<div className="priority-label">
																							Status:
																						</div>
																						<div className="filters-wrap">
																							<InputField
																								type="checkbox"
																								value={statusFilters.new}
																								cbChange={() =>
																									this.handleStatusFilters(
																										"new"
																									)
																								}
																							>
																								New
																							</InputField>
																						</div>
																						<div className="filters-wrap">
																							<InputField
																								type="checkbox"
																								value={statusFilters.complete}
																								cbChange={() =>
																									this.handleStatusFilters(
																										"complete"
																									)
																								}
																							>
																								Complete
																							</InputField>
																						</div>
																						<div className="filters-wrap">
																							<InputField
																								type="checkbox"
																								value={
																									statusFilters.in_progress
																								}
																								cbChange={() =>
																									this.handleStatusFilters(
																										"in_progress"
																									)
																								}
																							>
																								In Progress
																							</InputField>
																						</div>
																						<div className="filters-wrap">
																							<InputField
																								type="checkbox"
																								value={statusFilters.responded}
																								cbChange={() =>
																									this.handleStatusFilters(
																										"responded"
																									)
																								}
																							>
																								Responded
																							</InputField>
																						</div>
																						<div className="filters-wrap">
																							<InputField
																								type="checkbox"
																								value={statusFilters.reopened}
																								cbChange={() =>
																									this.handleStatusFilters(
																										"reopened"
																									)
																								}
																							>
																								Reopened
																							</InputField>
																						</div>
																						<div>
																							<span className="splitter"></span>
																						</div>
																						<div className="priority-label">
																							Priority:
																						</div>
																						<div className="filters-wrap">
																							<InputField
																								type="checkbox"
																								value={priorityFilters.critical}
																								cbChange={() =>
																									this.handlePriorityFilter(
																										"critical"
																									)
																								}
																							>
																								Critical
																							</InputField>
																						</div>
																						<div className="filters-wrap">
																							<InputField
																								type="checkbox"
																								value={priorityFilters.high}
																								cbChange={() =>
																									this.handlePriorityFilter(
																										"high"
																									)
																								}
																							>
																								High
																							</InputField>
																						</div>
																						<div className="filters-wrap">
																							<InputField
																								type="checkbox"
																								value={priorityFilters.medium}
																								cbChange={() =>
																									this.handlePriorityFilter(
																										"medium"
																									)
																								}
																							>
																								Medium
																							</InputField>
																						</div>
																						<div className="filters-wrap">
																							<InputField
																								type="checkbox"
																								value={priorityFilters.low}
																								cbChange={() =>
																									this.handlePriorityFilter(
																										"low"
																									)
																								}
																							>
																								Low
																							</InputField>
																						</div>
																						<div>
																							<span className="splitter"></span>
																						</div>
																						<div className="filters-wrap">
																							<InputField
																								type="checkbox"
																								value={showOnlyUnread}
																								cbChange={() =>
																									this.handleShowAllOrg(
																										"showOnlyUnread",
																										this.getThreads
																									)
																								}
																							>
																								Show only Unread
																							</InputField>
																						</div>
																					</Col>
																					<Col
																						lg={12}
																						md={12}
																						sm={12}
																						className="filter-row no-wrap"
																					>
																						<DateRangePicker
																							orientation={
																								this.props.mobileMode
																									? "vertical"
																									: "horizontal"
																							}
																							noBorder
																							showClearDates={true}
																							minimumNights={0}
																							startDateId="start"
																							endDateId="end"
																							isOutsideRange={
																								this.isOutsideRange
																							}
																							startDatePlaceholderText="MM/DD/YYYY"
																							endDatePlaceholderText="MM/DD/YYYY"
																							startDate={
																								threadDates &&
																								threadDates.startDate
																							}
																							endDate={
																								threadDates &&
																								threadDates.endDate
																							}
																							focusedInput={focusedInput}
																							onFocusChange={focusedInput =>
																								this.setState({ focusedInput })
																							}
																							onDatesChange={
																								this.handleDatesChange
																							}
																						/>
																						<form
																							className="search-box"
																							onSubmit={e =>
																								this.handleSearch(e)
																							}
																						>
																							<InputField
																								type="search"
																								placeholder="Search"
																								name="threadKeyword"
																								value={threadKeyword}
																								onChange={e =>
																									this.handleSearchChange(e)
																								}
																								handleClear={() => {
																									this.setState(
																										{
																											threadKeyword: ""
																										},
																										() => this.getThreads(0)
																									);
																								}}
																							/>
																						</form>
																					</Col>
																				</Col>
																				<Col md={12} sm={12} id="threadTable">
																					<ReactTableComponent
																						manual
																						minRows={0}
																						className="-highlight"
																						getRef={r => (this.threadTable = r)}
																						reactTable={this.threadTable}
																						noDataText={
																							isLoading
																								? "Loading..."
																								: "No Messages"
																						}
																						showPagination={false}
																						data={threadData}
																						resizable={false}
																						pageSize={Number(threadPageSize)}
																						activePage={threadActivePage}
																						columns={threadColumns}
																						pageSizeOnChange={pageSize =>
																							this.pageSizeOnChange(
																								pageSize,
																								"thread"
																							)
																						}
																						pageOnChange={page =>
																							this.pageOnChange(page, "thread")
																						}
																						onSortedChange={this.onSortedChange}
																						getTrProps={(
																							state,
																							rowInfo,
																							column
																						) => {
																							const data =
																								rowInfo && rowInfo.original;
																							return {
																								onClick: () => {
																									this.handleOpenMsgModal(
																										data.thread_id,
																										"edit"
																									);
																								},
																								style: {
																									cursor: "pointer"
																								}
																							};
																						}}
																					/>
																				</Col>
																				<Col
																					md={12}
																					sm={12}
																					className="pagination-container top-margin-20"
																				>
																					<div className="page_size-input-container">
																						<div className="page-size">
																							<span className="right-margin-5">
																								Rows per page:
																							</span>
																							<InputField
																								className="pageSize"
																								type="number"
																								name="pageSize"
																								autoComplete="off"
																								defaultValue={Number(
																									threadPageSize
																								)}
																								onKeyUp={e => {
																									let value = e.target.value;

																									if (value > 100) {
																										value = 100;
																									} else if (value < 0) {
																										value = 1;
																									}

																									if (
																										value.length &&
																										e.keyCode === 13 &&
																										value !== 0 &&
																										Number.isInteger(
																											Number(value)
																										)
																									) {
																										this.pageSizeOnChange(
																											value,
																											"thread"
																										);
																									}
																								}}
																							/>
																							<span className="left-margin">
																								Page &nbsp;&nbsp;{" "}
																								{threadActivePage}
																							</span>
																						</div>
																						<button
																							name="prev"
																							className="transaction-pagination-btn"
																							type="button"
																							onClick={() =>
																								this.threadPageOnChange(
																									"firstPage"
																								)
																							}
																							disabled={threadActivePage == 1}
																						>
																							<i className="icon-first-page"></i>
																						</button>
																						<button
																							name="prev"
																							className="transaction-pagination-btn"
																							type="button"
																							onClick={() =>
																								this.threadPageOnChange("prev")
																							}
																							disabled={threadActivePage == 1}
																						>
																							Prev
																						</button>
																						<button
																							name="next"
																							className="transaction-pagination-btn"
																							type="button"
																							onClick={() =>
																								this.threadPageOnChange("next")
																							}
																							disabled={
																								_.get(threadData, "length") <= 0
																							}
																						>
																							Next
																						</button>
																					</div>
																				</Col>
																				<Col
																					md={5}
																					sm={12}
																					className="top-margin bottom-margin-20"
																				>
																					<PrimaryButton
																						cssClass="blue-btn "
																						onClick={() =>
																							this.handleOpenMsgModal(
																								"",
																								"create"
																							)
																						}
																					>
																						<i className="icon-Plus create-icon" />{" "}
																						New Message
																					</PrimaryButton>
																				</Col>
																			</Col>
																		</div>
																	</Row>
																)}
																<Row>
																	{
																		<Fragment>
																			<Col md={12} xs={12}>
																				<div className="guest-notes-title">
																					Automation
																				</div>
																				<span className="custom-hr" />
																			</Col>
																			<div className="bottom-margin-20">
																				<div className="comm-management">
																					<Col
																						md={12}
																						xs={12}
																						className="db-team-members scheduled-actions-table"
																					>
																						<div className="position-relative">
																							{scheduledActionIsLoading && (
																								<Loader />
																							)}

																							<ReactTableComponent
																								getRef={r =>
																									(this.scheduledActionsthreadTable = r)
																								}
																								reactTable={
																									this
																										.scheduledActionsthreadTable
																								}
																								className="-highlight"
																								noDataText={
																									isLoading
																										? "Loading..."
																										: "No Scheduled Message"
																								}
																								minRows={0}
																								showPagination={true}
																								data={scheduledActionsList}
																								resizable={false}
																								pageSize={Number(
																									scheduledActionsPageSize
																								)}
																								activePage={
																									scheduledActionsPage
																								}
																								columns={
																									scheduledActionsColumns
																								}
																								totalCount={Number(
																									scheduledActionsList.length
																								)}
																								pageSizeOnChange={pageSize =>
																									this.stateOnChange(
																										pageSize,
																										"scheduledActionsPageSize"
																									)
																								}
																								pageOnChange={page =>
																									this.stateOnChange(
																										page,
																										"scheduledActionsPage"
																									)
																								}
																							/>
																						</div>
																					</Col>
																				</div>
																			</div>
																		</Fragment>
																	}
																	{details.guest_notes && (
																		<Fragment>
																			<Col md={6} xs={12}>
																				<div className="guest-notes-title">
																					Guest Notes{" "}
																					<span className="small-text">
																						(Guest Reservation Message)
																					</span>
																				</div>
																			</Col>
																			<Col xs={12}>
																				<div className="guest-notes">{`${details.guest_notes}`}</div>
																			</Col>
																		</Fragment>
																	)}
																</Row>
															</div>
														</Panel.Body>
													</Panel.Collapse>
												</Panel>
											)}
											{this.reviewAccess &&
												!isLoading &&
												_.get(reviewData, "review_id") && (
													<Panel eventKey={1}>
														<Panel.Heading>
															<Panel.Title toggle>
																<div className="reviews">
																	<div className="reviews-tr display-flex">
																		<div className="right-padding">
																			<h2>Review</h2>
																		</div>
																		<div className="right-padding reviews-col">
																			{this.renderStarsReview(
																				_.get(reviewData, "rating", "")
																			)}
																		</div>
																		{status && (
																			<div className="right-padding reviews-status capitalize">
																				({status})
																			</div>
																		)}
																	</div>
																</div>
															</Panel.Title>
														</Panel.Heading>
														<Panel.Body
															className="review-custom-body"
															collapsible
														>
															{!reviewErrMsg
																? this.renderReview()
																: this.renderMsg(reviewErrMsg, "_error")}
														</Panel.Body>
													</Panel>
												)}
											{(this.viewFreshDeskTickets || this.viewCrmCases) && (
												<Panel eventKey={1}>
													<Panel.Heading>
														<Panel.Title>Support Tickets</Panel.Title>
													</Panel.Heading>
													<Panel.Body>
														{_.get(details, "property_id") && (
															<SupportTickets
																level={"reservation"}
																type={"freshdesk"}
																reservationId={reservationId}
															/>
														)}
													</Panel.Body>
												</Panel>
											)}
											<Panel eventKey={1}>
												<Panel.Heading>
													<Panel.Title toggle>
														<h2>Reservation Notes</h2>{" "}
														<p>
															Internal Use Only - will not be sent to Guest or
															RedAwning
														</p>
													</Panel.Title>
												</Panel.Heading>
												<Panel.Collapse>
													<Panel.Body>
														{this.renderReservationNotes()}
														<Row className="top-margin-15">
															<Col md={6} sm={12} className="pull-left">
																<PrimaryButton
																	cssClass="blue-btn "
																	onClick={this.addReservationNotes}
																	disabled={reservationNotes.length == 2}
																>
																	<i className="icon-Plus create-icon" /> Add
																	Reservation Notes
																</PrimaryButton>
															</Col>
														</Row>
													</Panel.Body>
												</Panel.Collapse>
											</Panel>
											{!commCenterAccess && (
												<Panel eventKey={1}>
													<Panel.Heading>
														<Panel.Title toggle>
															<h2>Guest Information</h2>
														</Panel.Title>
													</Panel.Heading>
													<Panel.Collapse>
														<Panel.Body>
															{details.share_contact_status == "1" ||
															daysUntilCheckin <= 30 ? (
																<Row>
																	<Col xs={12}>
																		<div className="reservation-item">
																			<p>Name</p>
																			<p>
																				<strong>{`${details.first_name} ${details.last_name}`}</strong>
																			</p>
																		</div>
																	</Col>
																	<Col xs={12}>
																		<div className="reservation-item">
																			<p>Email</p>
																			<p>
																				<strong>
																					<a href={`mailto:${details.email}`}>
																						{details.email}
																					</a>
																				</strong>
																			</p>
																		</div>
																	</Col>
																	<Col xs={12}>
																		<div className="reservation-item">
																			<p>Phone</p>
																			<p>
																				<strong>
																					<a href={`tel:${details.phone}`}>
																						{details.phone}
																					</a>
																				</strong>
																			</p>
																		</div>
																	</Col>
																</Row>
															) : (
																<Row>
																	<Col xs={12}>
																		{isCheckoutOlder
																			? "Renter information is not displayed for reservations older than 60 days."
																			: "Renter information will be displayed within 30 days of the check in date."}
																	</Col>
																</Row>
															)}
														</Panel.Body>
													</Panel.Collapse>
												</Panel>
											)}
											{details.guest_notes && !commCenterAccess ? (
												<Panel eventKey={1}>
													<Panel.Heading>
														<Panel.Title toggle>
															<h2>Guest Notes</h2>
														</Panel.Title>
													</Panel.Heading>
													<Panel.Collapse>
														<Panel.Body>
															<Row>
																<Col xs={12} sm={4}>
																	{`${details.guest_notes}`}
																</Col>
															</Row>
														</Panel.Body>
													</Panel.Collapse>
												</Panel>
											) : (
												""
											)}
										</Fragment>
									)}
								</PanelGroup>
							</div>
						</Grid>
					</div>
					<Modal
						show={guestVerificationModal}
						className="guest-verification-modal"
						onHide={() => this.handleGuestVerificationModal(false)}
						backdrop={"static"}
					>
						{guestVerificationModalIsLoading && <Loader />}

						<Modal.Body>
							<Row className="text-center bottom-margin">
								<Col xs={12}>
									<h2>
										Verify "
										<strong>{`${details.first_name} ${details.last_name}`}</strong>
										"?
									</h2>
								</Col>
							</Row>
							<Row>
								<Col xs={12}>
									<PrimaryButton
										cssClass="pull-right"
										fullWidth={false}
										onClick={this.submitGuestVerification}
									>
										Yes
									</PrimaryButton>
									<PrimaryButton
										cssClass="white-btn pull-right right-margin"
										fullWidth={false}
										onClick={() => this.handleGuestVerificationModal(false)}
									>
										Cancel
									</PrimaryButton>
								</Col>
							</Row>
							<Row>
								{guestVerificationError.length > 0 && (
									<Col md={12}>
										{this.renderMsg(guestVerificationError, "_error")}
									</Col>
								)}
							</Row>
						</Modal.Body>
					</Modal>
					<Modal
						show={modal == "cancel-modal"}
						className="cancel-modal"
						onHide={e => this.handleScheduledActionModal(e, "", "")}
						backdrop={cancelActionIsLoading ? "static" : true}
					>
						{cancelActionIsLoading && <Loader />}

						<Modal.Body>
							<Row className="text-center bottom-margin">
								<Col xs={12}>
									<h2>Are you sure you want to cancel</h2>
									<h2>
										"<strong>{_.get(currentScheduledAction, "title")}</strong>"?
									</h2>
								</Col>
							</Row>
							<Row>
								<Col xs={12}>
									<PrimaryButton
										cssClass="pull-right"
										fullWidth={false}
										onClick={this.cancelAction}
									>
										Yes
									</PrimaryButton>
									<PrimaryButton
										cssClass="white-btn pull-right right-margin"
										fullWidth={false}
										onClick={e => this.handleScheduledActionModal(e, "", "")}
									>
										No
									</PrimaryButton>
								</Col>
							</Row>
							<Row>
								{cancelActionErrMsg.length > 0 && (
									<Col md={12}>
										{this.renderMsg(cancelActionErrMsg, "_error")}
									</Col>
								)}
							</Row>
						</Modal.Body>
					</Modal>
					<Modal
						show={modal == "schedule-modal"}
						className="schedule-modal"
						onHide={e => this.handleScheduledActionModal(e, "", "")}
						backdrop={resendActionIsLoading ? "static" : true}
					>
						{resendActionIsLoading && <Loader />}

						<Modal.Header className="text-center">
							<h1>{_.get(currentScheduledAction, "title")}</h1>
						</Modal.Header>
						<Modal.Body>
							<Row className="bottom-margin text-center">
								{scheduleMsgMode == "schedule" ? (
									<Fragment>
										<Col xs={12}>
											<DatePicker
												date={scheduledDate}
												numberOfMonths={1}
												onDateChange={date => this.onDateChange(date)}
												isOutsideRange={day =>
													day.isBefore(moment().add(1, "day"), "day")
												}
												placeholder="MM/DD/YYYY"
												displayFormat="MM/DD/YYYY"
											/>
										</Col>
										<Col xs={12} className="top-margin">
											{_.includes(
												_.get(currentScheduledAction, "capabilities", []),
												"replay"
											) && (
												<span
													className="clickable-text"
													onClick={e => this.handleSendAction(e, "sendNow")}
												>
													{_.get(currentScheduledAction, "status") ==
														"success" ||
													_.get(currentScheduledAction, "status") == "failed"
														? "Resend "
														: "Send "}
													Now
												</span>
											)}
										</Col>
									</Fragment>
								) : (
									<Fragment>
										<Col xs={12}>
											<h2>{scheduledText} this message now?</h2>
										</Col>
										{_.includes(
											_.get(currentScheduledAction, "capabilities", []),
											"edit"
										) && (
											<Col xs={12} className="top-margin">
												<span
													className="clickable-text"
													onClick={() =>
														this.stateOnChange("schedule", "scheduleMsgMode")
													}
												>
													Click here to Schedule
												</span>
											</Col>
										)}
									</Fragment>
								)}
							</Row>
							<Row>
								<Col xs={12}>
									<PrimaryButton
										cssClass="pull-right"
										fullWidth={false}
										onClick={e => this.handleSendAction(e)}
									>
										{scheduledActionBtnTag}
									</PrimaryButton>
									<PrimaryButton
										cssClass="white-btn pull-right right-margin"
										fullWidth={false}
										onClick={e => this.handleScheduledActionModal(e, "", "")}
									>
										Cancel
									</PrimaryButton>
								</Col>
							</Row>
							<Row>
								{resendActionErrMsg && (
									<Col md={12} className="no-padding">
										<Col md={12}>
											{resendActionErrMsg.length > 0 &&
												this.renderMsg(resendActionErrMsg, "_error")}
										</Col>
									</Col>
								)}
							</Row>
						</Modal.Body>
					</Modal>
					<Modal
						show={modal == "preview-modal"}
						className="preview-modal"
						onHide={this.closePreviewModal}
					>
						<Modal.Header closeButton></Modal.Header>
						<Modal.Body>
							<Row>
								{!scheduledActionIsLoading && previewHTML.length > 0 && (
									<Fragment>
										<div className="template-wrap">
											<div
												dangerouslySetInnerHTML={{ __html: previewHTML }}
											></div>
											{currentScheduledAction &&
												currentScheduledAction.message_action &&
												currentScheduledAction.message_action.attachments &&
												currentScheduledAction.message_action.attachments
													.length > 0 && (
													<div className="attachments-container">
														<div className="attach-title">
															{
																currentScheduledAction.message_action
																	.attachments.length
															}{" "}
															Attachments:
														</div>
														<div className="attachments top-margin display-flex">
															{currentScheduledAction.message_action.attachments.map(
																(attachment, i) => {
																	if (attachment.uri) {
																		let imgSrc = File;

																		if (attachment.mime_type.includes("pdf")) {
																			imgSrc = Pdf;
																		} else if (
																			attachment.mime_type.includes("xls") ||
																			attachment.mime_type.includes("xlm")
																		) {
																			imgSrc = Sheets;
																		} else if (
																			attachment.mime_type.includes("txt") ||
																			attachment.mime_type.includes("doc")
																		) {
																			imgSrc = Docs;
																		} else if (
																			attachment.mime_type.includes("image") ||
																			attachment.mime_type.includes("png") ||
																			attachment.mime_type.includes("jpeg")
																		) {
																			imgSrc = ImageFile;
																		}

																		return (
																			<div key={i}>
																				<OverlayTrigger
																					placement="top"
																					overlay={this.getTooltip(
																						"filename",
																						`${attachment.filename}`
																					)}
																				>
																					<div className="attach-border">
																						<a
																							className="attach-container"
																							href={attachment.uri}
																							target="_blank"
																						>
																							<div className="attach-icon-container">
																								<img src={imgSrc}></img>
																							</div>
																							<div className="attach-file-name">
																								{attachment &&
																								attachment.filename &&
																								attachment.filename.length > 15
																									? attachment.filename.substring(
																											0,
																											15
																									  ) + "..."
																									: attachment.filename}
																							</div>
																						</a>
																					</div>
																				</OverlayTrigger>
																			</div>
																		);
																	}
																}
															)}
														</div>
													</div>
												)}
										</div>
									</Fragment>
								)}
							</Row>
						</Modal.Body>
					</Modal>
					<Modal show={modal == "delete-modal"} className="del-note-modal">
						<Modal.Body>
							<Row className="text-center bottom-margin">
								<Col xs={12}>
									<h2>Are you sure you want to delete</h2>
									<h2>
										"
										<strong>
											{
												this.attributeName[
													_.get(this.currentNote, "attribute_name")
												]
											}
										</strong>
										"?
									</h2>
								</Col>
							</Row>
							<Row>
								<Col xs={12}>
									<PrimaryButton
										cssClass="pull-right"
										fullWidth={false}
										onClick={this.delReservationNotes}
									>
										Delete
									</PrimaryButton>
									<PrimaryButton
										cssClass="white-btn pull-right right-margin"
										fullWidth={false}
										onClick={() => this.delReservationNotesModal("", "")}
									>
										Cancel
									</PrimaryButton>
								</Col>
							</Row>
						</Modal.Body>
					</Modal>
					<Modal show={modal == "add-a-fee-modal"} className="add-a-fee-modal">
						<Modal.Header closeButton onClick={() => this.closeButton(this)}>
							<Modal.Title>
								{this.state.isLoading ? (
									<div className="submit-loader">
										<Loader />
									</div>
								) : (
									"Add a Fee"
								)}
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Row>
								<Col xs={12}>
									{details && !this.state.error ? (
										<div>
											<div className="reservation-billing">
												<h2 className="detail-label">
													<strong>Fee Description:</strong>
												</h2>
											</div>
											<div className="reservation-billing">
												{!this.state.showInputFeeNameField ? (
													<div className="form-field-select">
														<select
															name="fee_name"
															type="select"
															className="form-field custom-input type-select"
															onChange={e => {
																this.handleInputChange(e);
																this.feesNamesOnChange(e);
															}}
														>
															{this.state.feeTypes.map((option, i) => (
																<option key={`fee-type-${i}`} value={option}>
																	{option}
																</option>
															))}
														</select>
														<span className="caret"></span>
													</div>
												) : (
													<InputField
														cssClass="text-input"
														type="text"
														name="fee_name"
														onChange={e => {
															this.handleInputChange(e);
															this.feesNamesOnChange(e);
														}}
													/>
												)}
											</div>
											{this.state.isBlockNightsWordMatch ? (
												<div className="reservation-billing">
													<h5>
														<strong>
															* If you are extending a stay, please block nights
															first.
														</strong>
													</h5>
												</div>
											) : (
												<div className="reservation-billing">
													<h5>&nbsp;</h5>
												</div>
											)}
											<div className="reservation-billing">
												<h2 className="detail-label">
													<strong>Fee Amount:</strong>
												</h2>
											</div>
											<div className="reservation-billing">
												<Row>
													{this.hasAddonFees() && this.state.amountDisabled ? (
														<Col md={6} sm={12} xs={12}>
															<InputLabel>
																<strong>Quantity</strong> <em></em>
															</InputLabel>
															<InputField
																cssClass="text-input"
																type="number"
																name="fee_quantity"
																min="1"
																placeholder={this.state.fee_quantity}
																value={this.state.fee_quantity}
																onChange={e => this.handleInputChange(e)}
															/>
														</Col>
													) : (
														""
													)}
													<Col md={6} sm={12} xs={12}>
														<InputLabel>
															<strong>Amount</strong> <em>({currency})</em>
														</InputLabel>
														<InputField
															cssClass="text-input"
															type="number"
															name="host_fee"
															disabled={this.state.amountDisabled}
															placeholder={Number(this.state.host_fee).toFixed(
																2
															)}
															value={
																Number(this.state.host_fee)
																	? this.state.host_fee
																	: ""
															}
															onChange={e => {
																this.handleInputChange(e);
																this.feesNamesOnChange(e);
															}}
														/>
													</Col>
												</Row>
											</div>
											<div className="reservation-billing">
												<hr style={{ borderTopColor: "#ddd" }} />
												<br />
											</div>
											<div className="reservation-billing">
												<label
													htmlFor="host_gets_fee"
													className="form-label form-label-add-fees"
												>
													<strong>Host Fee:</strong>
													{currencySymbol}
													{this.getHostFee().toFixed(2)}
													{currency}
												</label>
											</div>
											<div className="reservation-billing">
												<label
													htmlFor="redawning_gets_fee"
													className="form-label form-label-add-fees"
												>
													<strong>RedAwning Fee:</strong>{" "}
													{`${currencySymbol}${this.getRedawningFee().toFixed(
														2
													)} ${currency}`}
												</label>
											</div>
											<div className="reservation-billing">
												<label
													htmlFor="guest_charged_fee"
													className="form-label form-label-add-fees"
												>
													<strong>Total Guest Payment:</strong>
													{currencySymbol}
													{(this.getHostFee() + this.getRedawningFee()).toFixed(
														2
													)}
													{currency}
												</label>
											</div>
											<div className="reservation-billing">&nbsp;</div>
											{this.state.details.addl_fee_payment_options &&
												this.state.details.addl_fee_payment_options.length >
													1 &&
												this.state.details.addl_fee_payment_options.includes(
													"invoice"
												) && (
													<div className="reservation-billing">
														<label
															htmlFor="guest_charged_fee"
															className="form-label form-label-add-fees"
														>
															Invoice:
															<br />
															<Switch
																onColor={"#69c"}
																uncheckedIcon={false}
																checkedIcon={false}
																height={20}
																width={45}
																checked={this.state.isInvoiceSelected}
																onChange={() => {
																	this.setState({
																		isInvoiceSelected: !this.state
																			.isInvoiceSelected
																	});
																}}
															/>
														</label>
													</div>
												)}
											<div className="modal-btns">
												<PrimaryButton
													cssClass="blue-btn pull-left left-margin"
													onClick={e => {
														this.handleSubmitAdditionalFees(e, currency);
													}}
													disabled={
														this.state.fee_name === this.feeDefault ||
														!currency ||
														(Number(this.state.host_fee) * 1).toFixed(2) <= 0
													}
												>
													Submit New Fee
												</PrimaryButton>
											</div>
										</div>
									) : (
										<div>
											{this.state.error.includes("past checkout") ? (
												<Fragment>
													<h4>
														<strong>
															We're sorry, this reservation does not support
															adding fees past check out, please contact&nbsp;
															<a
																className="clickable-text"
																href={`mailto:guest@redawning.com?subject=Request to add a fee.&body=I am requesting an additional fee past checkout for reservation id: ${reservationId}.`}
															>
																guest@redawning.com
															</a>
															&nbsp; to add a fee.
														</strong>
													</h4>
												</Fragment>
											) : (
												<Fragment>
													<h4>
														<strong>
															We're sorry, there was a problem adding the fee,
															please contact&nbsp;
															<a
																className="clickable-text"
																href={`mailto:guest@redawning.com?subject=Request to add a fee.&body=I am requesting an additional fee for reservation id: ${reservationId}.`}
															>
																guest@redawning.com
															</a>
															&nbsp; to add a fee.
														</strong>
													</h4>
												</Fragment>
											)}
										</div>
									)}
								</Col>
							</Row>
						</Modal.Body>
					</Modal>
					<Modal
						show={modal == "ask-question"}
						className="ask-question-modal"
						onHide={this.handleCloseMsgModal}
						backdrop={threadLoading ? "static" : true}
						animation
					>
						{threadLoading && <Loader />}

						<Modal.Header closeButton></Modal.Header>
						<Modal.Body>
							<Row>
								<Col xs={12} sm={6}>
									<Row>
										<Col xs={12} sm={12}>
											{mode == "create" && (
												<div className="property-title">{reservationId}</div>
											)}
											{modalData && modalData.property_title && (
												<div className="property-title">
													{modalData && modalData.property_title} &nbsp;
													<span className="subtext">
														<Link
															to={`/properties/${modalData &&
																modalData.property_id}/edit`}
														>
															{modalData && modalData.property_id}
														</Link>
													</span>
												</div>
											)}
											{moment(modalData && modalData.checkin_date).isValid() &&
												mode != "create" && (
													<div className="reservation-details">
														<span>Check-In Date:</span>{" "}
														<strong>
															{moment(modalData && modalData.checkin_date)
																.utc()
																.format("MM/DD/YYYY")}
														</strong>
														,&nbsp;
														<span>Check-Out Date:</span>{" "}
														<strong>
															{moment(modalData && modalData.checkout_date)
																.utc()
																.format("MM/DD/YYYY")}
														</strong>
													</div>
												)}
											<div className="thread-subject top-margin">
												{mode != "create"
													? _.get(modalData, "subject")
													: "New Message"}
											</div>
											{mode == "create" && (
												<Row>
													<Col xs={12} sm={12}>
														<InputLabel htmlFor="subject">Subject:</InputLabel>
														<InputField
															type="text"
															name="threadSubject"
															value={threadSubject}
															onChange={e => this.handleMessageOnChange(e)}
														/>
													</Col>
												</Row>
											)}
										</Col>
									</Row>
									{modalData &&
										modalData.messages &&
										modalData.messages.length && (
											<Row>
												<Col xs={12} sm={12} className="thread-container">
													{this.renderMessages()}
												</Col>
											</Row>
										)}
									<Row>
										<Col xs={12} sm={12}>
											{this.renderNewAttachments()}
										</Col>
										<Col xs={12} sm={12}>
											<InputLabel htmlFor="message">
												{_.get(modalData, "messages.length") && replyTo
													? `Reply to ${replyTo}:`
													: "Message:"}
											</InputLabel>
											{mode == "edit" &&
												suggestReplyBtn(
													suggestedReplies,
													suggestedRepliesCurrentIndex,
													isSuggestedReplyLoading,
													this.getSuggestedReply,
													this.responseSelector
												)}
											<InputField
												cssClass={`${mode == "create" ? "create" : ""}`}
												type="textarea"
												name="threadMessage"
												value={threadMessage}
												onChange={e => this.handleMessageOnChange(e)}
											/>
										</Col>
									</Row>
									<Row>
										<Col xs={12} sm={12} className="thread-button-container">
											<span className="add-attachment">
												<OverlayTrigger
													placement="top"
													overlay={this.getTooltip(
														"add-attachment",
														"Attach Files"
													)}
												>
													<div>
														<Dropzone
															multiple={true}
															accept={constants.ACCEPTED_FILE_TYPES.join(",")}
															onDrop={this.addAttachment}
															className="attach-dropzone"
														></Dropzone>
														<i className="icon-attachment"></i>
													</div>
												</OverlayTrigger>
											</span>
											<PrimaryButton
												fullWidth={false}
												type="button"
												onClick={
													mode == "create"
														? this.submitThread
														: this.submitMessage
												}
												disabled={
													(mode == "edit" && !threadMessage.length) ||
													(mode == "create" &&
														(!threadSubject.length || !threadMessage.length))
												}
											>
												Send Message
											</PrimaryButton>
										</Col>
									</Row>
									<Row>
										<Col xs={12} sm={12} className="thread-modal-btn-container">
											<Col xs={6} sm={6} className="thread-modal-btn-left">
												{mode == "edit" && (
													<Fragment>
														<button
															className={`primary-btn white-btn full-width ${
																markAsUnread ? "brand-btn" : ""
															}`}
															type="button"
															onClick={this.toggleAsUnread}
														>
															Mark As Unread
														</button>
														{this.renderPicky(
															"Status",
															this.markStatusOptions,
															markStatus,
															this.handleMarkStatus
														)}
													</Fragment>
												)}
												{this.renderPicky(
													"Priority",
													this.markPriorityOptions,
													markPriority,
													this.handleMarkPriority
												)}
											</Col>
											<Col xs={6} sm={6} className="thread-modal-btn-right">
												<button
													type="button"
													className="primary-btn white-btn right-margin"
													onClick={this.handleCloseMsgModal}
												>
													Close Window
												</button>
												<PrimaryButton
													cssClass={`white-btn right-margin`}
													fullWidth={false}
													type="button"
													onClick={e =>
														this.navigate(
															e,
															`/threads/${_.get(modalData, "thread_id")}`
														)
													}
												>
													<i className="icon-open-new-tab"></i>
												</PrimaryButton>
												<PrimaryButton
													cssClass={`white-btn right-margin`}
													fullWidth={false}
													type="button"
													onClick={() =>
														navigator.clipboard.writeText(
															`https://${this.baseUrl}/threads/${_.get(
																modalData,
																"thread_id"
															)}`
														)
													}
												>
													<i className="icon-link"></i>
												</PrimaryButton>
											</Col>
										</Col>
									</Row>
								</Col>
								<Col xs={12} sm={6}>
									<div className="thread-info">
										<Row>
											<Col xs={12} sm={12} className="flex-space-between">
												<div>
													<div>
														<strong>
															{_.get(propertyCommData, "title") || ""}
														</strong>
													</div>
												</div>
												<div>
													<div>
														<span className="right-margin-5">
															<span className="right-margin-5">
																Property ID:
															</span>
															<span
																className="clickable-text"
																onClick={e =>
																	this.navigate(
																		e,
																		`/properties/${_.get(
																			propertyCommData,
																			"property_id"
																		)}/edit`
																	)
																}
															>
																{_.get(propertyCommData, "property_id") || ""}
															</span>
														</span>
														<span>
															<i className="icon-house-icon"></i>
														</span>
													</div>
												</div>
											</Col>
										</Row>
										<PropertyRoomInfo propertyData={propertyCommData} />
										<Row>
											<Col
												xs={12}
												sm={12}
												className="top-margin flex-align-right"
											>
												<PrimaryButton
													cssClass="blue-btn right-margin"
													fullWidth={false}
													type="button"
													onClick={e =>
														this.navigate(
															e,
															`/properties/${_.get(
																propertyCommData,
																"property_id"
															)}/edit`
														)
													}
												>
													<span className="right-margin-5">View Property</span>
													<span>
														<i className="icon-open-new-tab"></i>
													</span>
												</PrimaryButton>
												<PrimaryButton
													cssClass="blue-btn "
													fullWidth={false}
													type="button"
													onClick={e =>
														this.navigate(
															e,
															`/calendar/${_.get(
																propertyCommData,
																"property_id"
															)}/edit`
														)
													}
												>
													<span className="right-margin-5">View Calendar</span>
													<span>
														<i className="icon-open-new-tab"></i>
													</span>
												</PrimaryButton>
											</Col>
										</Row>
										<Row>
											<Col xs={12} sm={12}>
												<hr />
												<div>
													<span>
														<span className="right-margin-5">Reservation</span>
													</span>
												</div>
												<div className="display-flex">
													<div className="right-margin-5">
														<strong>{`${_.get(
															reservationCommData,
															"first_name"
														) || ""} ${_.get(
															reservationCommData,
															"last_name"
														) || ""}`}</strong>
													</div>
													<div>
														|{" "}
														<a
															className="clickable-text"
															href={`mailto:${_.get(
																reservationCommData,
																"to_guest_email"
															) || ""}`}
														>
															{_.get(reservationCommData, "to_guest_email") ||
																""}
														</a>
													</div>
												</div>
												<div className="flex-space-between">
													<div>
														Reservation ID:{" "}
														<strong>
															{_.get(reservationCommData, "reservation_id") ||
																""}
														</strong>
													</div>
													<div>
														Guest Cancellation Policy: 100% Refundable Until{" "}
														{moment(
															_.get(
																reservationCommData,
																"cancellation_policy.last_refundable_datetime"
															)
														).format("M/D/YY @ hA") || ""}
													</div>
												</div>
												<div className="flex-space-between">
													<div>
														Booked On:{" "}
														<strong>
															{_.get(reservationCommData, "channel_name") || ""}
														</strong>
													</div>
													<div>
														Host Cancellation Policy: 100% Refundable Until{" "}
														{moment(
															_.get(
																reservationCommData,
																"channel_cancellation_policy.last_refundable_datetime"
															)
														).format("M/D/YY @ hA") || ""}
													</div>
												</div>
												<div className="flex-space-between">
													<div>
														Door Code:{" "}
														<strong>
															{_.get(reservationCommData, "door_code") || ""}
														</strong>
													</div>
													<div>
														<span className="right-margin-5">Identity:</span>
														{this.renderRequestIdentification()}
													</div>
												</div>
												<div>
													<div>
														WiFi Name:{" "}
														<strong>
															{_.get(reservationCommData, "wifi_name") || ""}
														</strong>
													</div>
													<div>
														WiFi Password:{" "}
														<strong>
															{_.get(reservationCommData, "wifi_password") ||
																""}
														</strong>
													</div>
												</div>
											</Col>
										</Row>
										<Row>
											<Col xs={12} sm={12} className="top-margin-30">
												<div className="thread-reservation-box-container">
													<Col xs={6} sm={4}>
														<div>Checked-In:</div>
														<div>
															<strong>
																{_.get(reservationCommData, "checkin_date") ||
																	""}
															</strong>
														</div>
													</Col>
													<Col xs={6} sm={4}>
														<div>Check-Out:</div>
														<div>
															<strong>
																{_.get(reservationCommData, "departure_date") ||
																	""}
															</strong>
														</div>
													</Col>
													<Col xs={6} sm={4}>
														<div>Nights:</div>
														<div>
															<strong>
																{_.get(
																	reservationCommData,
																	"number_of_nights"
																) || ""}
															</strong>
														</div>
													</Col>
													<Col xs={6} sm={4}>
														<div>Guests:</div>
														<div>
															<strong>
																{_.get(
																	reservationCommData,
																	"number_of_guests"
																) || ""}
															</strong>
														</div>
													</Col>
													<Col xs={6} sm={4}>
														<div>Date Booked:</div>
														<div>
															<strong>
																{_.get(reservationCommData, "book_date") || ""}
															</strong>
														</div>
													</Col>
													<Col xs={6} sm={4}>
														<div>Property:</div>
														<div
															className="clickable-text"
															onClick={e =>
																this.navigate(
																	e,
																	`/properties/${_.get(
																		reservationCommData,
																		"property_id"
																	)}/edit`
																)
															}
														>
															{_.get(reservationCommData, "property_id") || ""}
														</div>
													</Col>
												</div>
											</Col>
										</Row>
										<Row>
											<Col
												xs={12}
												sm={12}
												className="top-margin-30 flex-align-right"
											>
												<PrimaryButton
													cssClass="blue-btn "
													fullWidth={false}
													type="button"
													onClick={e =>
														this.navigate(
															e,
															`/reservations/${_.get(
																reservationCommData,
																"reservation_id"
															)}/details`
														)
													}
												>
													<span className="right-margin-5">
														View Reservation
													</span>
													<span>
														<i className="icon-open-new-tab"></i>
													</span>
												</PrimaryButton>
											</Col>
										</Row>
										{_.isObject(reservationCommData) &&
											!_.isEmpty(reservationCommData) && (
												<Fragment>
													<Row>
														<Col xs={12} sm={12}>
															<hr />
															<div className="host-notes-title">
																<div>Host Notes:</div>
																{!editHostNotes &&
																	_.get(reservationCommData, "host_notes") && (
																		<i
																			onClick={e =>
																				this.toggleState(e, "editHostNotes")
																			}
																			className="icon-edit"
																		></i>
																	)}
															</div>
														</Col>
														<Col
															xs={12}
															sm={12}
															className="host-notes-container"
														>
															{editHostNotes ||
															(!editHostNotes &&
																!_.get(reservationCommData, "host_notes")) ? (
																<InputField
																	cssClass="text-area"
																	type="textarea"
																	name="attribute_value"
																	value={hostNotes}
																	onChange={e => this.hostNotesOnChange(e)}
																/>
															) : (
																<pre>
																	{_.get(reservationCommData, "host_notes")}
																</pre>
															)}
														</Col>
													</Row>
													{(editHostNotes ||
														!_.get(reservationCommData, "host_notes")) && (
														<Row>
															<Col
																xs={12}
																sm={12}
																className="top-margin-5 flex-align-right"
															>
																{(editHostNotes ||
																	(!editHostNotes &&
																		_.get(
																			reservationCommData,
																			"host_notes"
																		))) && (
																	<PrimaryButton
																		cssClass="white-btn right-margin"
																		fullWidth={false}
																		type="button"
																		onClick={e =>
																			this.toggleState(e, "editHostNotes")
																		}
																	>
																		Cancel
																	</PrimaryButton>
																)}
																<PrimaryButton
																	cssClass="blue-btn "
																	fullWidth={false}
																	type="button"
																	onClick={this.submitHostNotes}
																	disabled={!hostNotes.length}
																>
																	{`${
																		!editHostNotes &&
																		!_.get(reservationCommData, "host_notes")
																			? "Add"
																			: "Edit"
																	}`}{" "}
																	Note
																</PrimaryButton>
															</Col>
														</Row>
													)}
													<Row>
														{hostNotesErr.length > 0 &&
															this.renderMsg(hostNotesErr, "_error")}
													</Row>
												</Fragment>
											)}
									</div>
								</Col>
							</Row>
						</Modal.Body>
					</Modal>
					<Modal show={modal == "error-modal"}>
						<Modal.Body>
							<Row className="text-center bottom-margin">
								<Col xs={12} className="msg-container _error">
									{errorMsg}
								</Col>
							</Row>
							<Row>
								<Col xs={12}>
									<PrimaryButton
										cssClass="pull-right"
										fullWidth={false}
										onClick={() => this.setState({ modal: "" })}
									>
										Close
									</PrimaryButton>
								</Col>
							</Row>
						</Modal.Body>
					</Modal>
					<Modal
						show={modal == "logs-modal"}
						className="logs-modal"
						onHide={() =>
							this.setState({
								modal: "",
								currentScheduledAction: "",
								scheduledActionsLogsErrMsg: ""
							})
						}
					>
						<Modal.Header closeButton>
							<h1>Logs</h1>
							<h2>{_.get(currentScheduledAction, "title")}</h2>
						</Modal.Header>
						<Modal.Body>
							<Row>
								<Col xs={12} className="logs-modal">
									{!scheduledActionsLogsErrMsg ? (
										<ReactTableComponent
											getRef={r => (this.scheduledActionsthreadLogs = r)}
											reactTable={this.scheduledActionsthreadLogs}
											className="-highlight"
											noDataText={
												scheduledActionIsLoading
													? "Loading..."
													: "No logs available"
											}
											minRows={0}
											showPagination={false}
											data={scheduledActionsLogs}
											resizable={false}
											pageSize={100}
											activePage={1}
											columns={scheduledActionsLogsColumns}
										/>
									) : (
										<Col xs={12} className="msg-container _error">
											{scheduledActionsLogsErrMsg}
										</Col>
									)}
								</Col>
							</Row>
						</Modal.Body>
					</Modal>
				</div>
			</PageLayout>
		);
	}
}

const mapStateToProps = state => {
	return {
		permissions: state.authState.permissions,
		orgId: state.roleManager.orgId,
		org: state.roleManager.org,
		roleManager: state.roleManager,
		user: state.authState.user,
		mobileMode: state.updateMobileState.mobileMode,
		roles: state.roleManager.roles
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setRole: role => {
			dispatch(setRole(role));
		},
		updateOrganization: org => {
			dispatch(updateOrganization(org));
		},
		updateOrganizationTitle: org => {
			dispatch(updateOrganizationTitle(org));
		},
		updateOrganizationId: orgId => {
			dispatch(updateOrganizationId(orgId));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PropertyReservationDetail);
