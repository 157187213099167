import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import TableComponent from "../global/TableComponent";
import { Title } from "../global/Title";
import Link from "react-router-dom/Link";
import {
	Grid,
	Row,
	Col,
	Tooltip,
	OverlayTrigger,
	PanelGroup,
	Panel,
	Modal
} from "react-bootstrap";
import { InputField, PrimaryButton } from "../global/forms/FormElements";
import apiHandler from "../../js/apiHandler";
import { API } from "aws-amplify";
import Pagination from "react-js-pagination";
import PageLayout from "../global/PageLayout";
import constants from "../../js/constants";
import Loader from "../global/Loader";
import { PropertiesModal } from "../content/properties/PropertiesModal";
import DropdownButton from "react-bootstrap/lib/DropdownButton";
import { ValidationModal } from "../content/properties/ValidationModal";
import queryString from "query-string";
import {
	updateOrganization,
	updateOrganizationTitle,
	updateOrganizationId,
	setRole
} from "../../js/actions/index";
import { tableSort } from "../../helpers/helpers";

/** @class Component for show collection table. */
class Collection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			admin: false,
			showOrgSubs: true,
			orgsTreeData: {},
			isLoading: true,
			initLoader: true,
			validationLoading: false,
			pageSize: 10,
			propertiesPageSize: 10,
			activePage: 1,
			keyword: "",
			activeKey: undefined,
			collections: [],
			deleteProgressRow: [],
			activeCollectionId: null,
			properties: [],
			roomLoader: false,
			isNull: true,
			totalItemsCount: 0,
			modal: undefined,
			currentDeleteId: undefined,
			currentCollection: undefined,
			currentCollectionName: undefined,
			currentPropertyName: undefined,
			showValidations: false,
			selectedProperty: null,
			validationChannels: [],
			columns: [
				{
					Header: () => tableSort("Room Type ID"),
					accessor: "property_id",
					width: 120,
					Cell: row => (
						<div className="propertyId">
							<strong>{row.value}</strong>
						</div>
					)
				},
				{
					Header: () => tableSort("Reference ID"),
					accessor: "reference_id",
					width: 200,
					Cell: row => (
						<div className="referenceId">
							<OverlayTrigger
								placement="bottom"
								overlay={
									<Tooltip
										className="org-modal__tooltip"
										id="external_property_id"
									>
										{row.original.external_property_id}
									</Tooltip>
								}
							>
								<span>
									<strong>
										{row &&
										row.original &&
										row.original.external_property_id &&
										row.original.external_property_id.length > 24
											? row.original.external_property_id.substring(0, 23) +
											  "..."
											: row.original.external_property_id}
									</strong>
								</span>
							</OverlayTrigger>
						</div>
					)
				},
				{
					Header: "Room Type Details",
					accessor: "content.title",
					minWidth: 350,
					sortable: false,
					Cell: row => {
						let location = row.original.content.location.address,
							address = "";
						if (location) {
							let stateProvince = [];
							stateProvince = constants.STATES_PROVINCES.filter(value => {
								return (
									value.code == location["province"] ||
									value.abbrev == location["province"]
								);
							});
							address = `${location["street_address"]}
                                           ${location["street_address2"]}
                                           ${location["city"]}
                                           ${
																							stateProvince.length
																								? stateProvince[0].name
																								: location["province"]
																						}
                                           ${location["postal_code"]}`;
						}
						return (
							<div className="address">
								<strong>{row.value == "" ? "-" : row.value}</strong>{" "}
								{address.trim() == "" ? "" : `- ${address}`}
							</div>
						);
					}
				},
				{
					Header: () => tableSort("# of Rooms"),
					accessor: "units",
					width: 100,
					Cell: row => <div className="no-of-rooms">{row.value || 0}</div>
				},
				{
					Header: () => tableSort("Status"),
					accessor: "status",
					width: 90,
					Cell: row => (
						<div>
							{row.value.published ? (
								<strong className="text-success">Live</strong>
							) : (
								<strong className="text-danger">Not Live</strong>
							)}
						</div>
					)
				},
				{
					Header: "Actions",
					width: 180,
					sortable: false,
					Cell: row => {
						let data = row.original;
						let status = data.status.published;
						if (
							this.state.deleteProgressRow.indexOf(row.original.property_id) >
							-1
						) {
							return (
								<div className="room-type-loader">
									<Loader />
								</div>
							);
						}
						return (
							<div>
								<OverlayTrigger
									placement="bottom"
									overlay={this.getTooltip(
										"edit",
										`${this.state.admin ? "Update" : "View"} ${
											data.level === "rep" ? "Room Type" : "Property"
										}`
									)}
								>
									<span>
										<i className="icon-edit" />
									</span>
								</OverlayTrigger>
								<OverlayTrigger
									placement="bottom"
									overlay={this.getTooltip(
										"edit",
										`${this.state.admin ? "Edit" : "View"} Calendar`
									)}
								>
									<span
										onClick={e => {
											e.stopPropagation(),
												this.props.history.push({
													pathname: `/calendar/${data.property_id}/edit`
												});
										}}
										className="left-margin"
									>
										<i className="icon-Calender"></i>
									</span>
								</OverlayTrigger>
								<OverlayTrigger
									onClick={e => {
										e.stopPropagation();
									}}
									placement="bottom"
									overlay={this.getTooltip("validation", "View Validations")}
								>
									<span
										onClick={e => this.showValidationModal(e, data)}
										className="left-margin"
									>
										<img
											className="validation-icon"
											src="/app/assets/icons/validation_error.svg"
										/>
									</span>
								</OverlayTrigger>
								{(this.state.isSmartConciergeViewer ||
									this.state.isSmartConciergeAdmin) && (
									<OverlayTrigger
										placement="bottom"
										overlay={this.getTooltip(
											"smart-concierge",
											"Smart Concierge"
										)}
									>
										<span
											onClick={e => {
												e.stopPropagation(),
													this.props.history.push({
														pathname: `/smart-concierge/collections/${data.repconfig.property_collection_id}/properties/${row.original.property_id}`
													});
											}}
											style={{ marginLeft: "15px" }}
										>
											<i className="icon-concierge" />
										</span>
									</OverlayTrigger>
								)}
								{this.state.admin && (
									<OverlayTrigger
										placement="bottom"
										overlay={this.getTooltip(
											"delete",
											"Remove from Collection"
										)}
									>
										<span
											className="left-margin"
											onClick={e =>
												this.showDeleteModal(
													e,
													data.property_id,
													data.repconfig.collection_name,
													data.content.title
												)
											}
										>
											<i className="icon-Cross" />
										</span>
									</OverlayTrigger>
								)}
							</div>
						);
					}
				}
			]
		};
		this.breadcrumbItems = [
			{
				title: "Collections"
			}
		];
		this.org = props.roleManager.org;
		this.orgId = props.roleManager.orgId;
		this.roles = props.roleManager.roles;
		this.properties = {};
		this.init = this.init.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
		this.panelSelect = this.panelSelect.bind(this);
		this.removeFromCollection = this.removeFromCollection.bind(this);
		this.showValidationModal = this.showValidationModal.bind(this);
		this.closeValidations = this.closeValidations.bind(this);
	}

	componentDidMount() {
		this.init();
	}

	componentWillReceiveProps(newProps) {
		let { location } = newProps;
		let page =
			this.props.location && this.props.location.search
				? parseInt(queryString.parse(this.props.location.search).page)
				: 0;
		let newPage =
			newProps.location && newProps.location.search
				? parseInt(queryString.parse(newProps.location.search).page)
				: 0;

		if (page !== newPage) {
			this.setState({
				activePage: Number(newPage),
				activeKey: undefined
			});
		}
		if (location && location.state && location.state.org != this.org) {
			this.org = location.state.org;
			this.orgId = location.state.orgId;
			this.roles = location.state.roles;
			this.setState({
				activeKey: undefined
			});
			this.init();
		}
	}
	/** Initialize page */
	async init() {
		let admin = false;
		if (this.roles.indexOf(constants.USER_TYPES.PROPERTY_MANAGER_ADMIN) > -1) {
			admin = true;
		}
		if (
			this.props.roleManager.roles.indexOf(
				constants.USER_TYPES.SMART_CONCIERGE_VIEWER
			) > -1
		) {
			this.setState({
				isSmartConciergeViewer: true
			});
		}
		if (
			this.props.roleManager.roles.indexOf(
				constants.USER_TYPES.SMART_CONCIERGE_ADMIN
			) > -1
		) {
			this.setState({
				isSmartConciergeAdmin: true
			});
		}
		let page = queryString.parse(this.props.location.search).page;
		this.setState({
			admin,
			activePage: page ? (isNaN(Number(page)) ? 1 : Number(page)) : 1,
			keyword: "",
			activeKey: undefined,
			collections: [],
			properties: [],
			isLoading: true
		});

		this.properties = [];

		let collectionId =
			this.props &&
			this.props.match &&
			this.props.match.params &&
			this.props.match.params.collectionId;
		// if collection id params exist and is a number
		if (!isNaN(parseInt(collectionId))) {
			try {
				let collectionResponse = await apiHandler.get(
					`/propertycollections/${collectionId}`
				);
				this.setPropertyOrg(
					collectionResponse &&
						collectionResponse.data &&
						collectionResponse.data.organization_name
				);
				this.org =
					collectionResponse &&
					collectionResponse.data &&
					collectionResponse.data.organization_name;
				this.orgId =
					collectionResponse &&
					collectionResponse.data &&
					collectionResponse.data.organization_id;
			} catch (e) {
				console.log(e);
			}
		}

		await this.getCollections();
		let collections = this.state.collections;
		let collection = collections.findIndex(
			collection =>
				collection.collection_id === this.props.match.params.collectionId
		);

		if (collection > -1) {
			await this.setState({
				activePage: parseInt(collection / this.state.pageSize) + 1
			});
			await this.panelSelect(
				collection % this.state.pageSize,
				this.props.match.params.collectionId
			);
		}
	}
	setPropertyOrg = orgName => {
		let organization = this.props.user.expanded_organization_roles.filter(
			org => {
				if (org.organization_name === orgName) {
					return true;
				}
			}
		);

		if (this.org !== orgName) {
			if (organization && organization.length) {
				this.props.updateOrganization(organization[0].organization_name);
				this.props.updateOrganizationTitle(organization[0].title);
				this.props.updateOrganizationId(organization[0].organization_id);
				this.props.setRole(organization[0].roles);

				sessionStorage.setItem("org", organization[0].organization_name);
				sessionStorage.setItem("orgTitle", organization[0].title);
				sessionStorage.setItem("orgId", organization[0].organization_id);

				sessionStorage.removeItem("reservations-listing");
				sessionStorage.removeItem("properties-listing");
			}
		}
	};
	/** Get collections from API call. */
	async getCollections() {
		let offset = this.state.pageSize * this.state.activePage;
		let orgsString = "";
		let parentOrg = this.props.roleManager.org;

		this.setState({
			isLoading: true
		});

		let orgsTreeData = await API.get(
			"accapi",
			`/organizationtree?parent=${encodeURIComponent(parentOrg)}`
		);

		this.setState({
			orgsTreeData
		});

		let orgsTreeParent = this.state.orgsTreeData[0];
		let searchVal = this.state.keyword;
		let useKeyword = true;
		let parentOrgId = encodeURIComponent(orgsTreeParent.organization_id);
		let orgs = [parentOrgId];

		function walk(node) {
			let children = node.child_organizations;
			if (children && children.length) {
				for (let i = 0; i < children.length; i++) {
					// Children are siblings to each other
					if (children && children[i] && children[i].organization_id) {
						let child = children[i];
						let childOrgid = encodeURIComponent(child.organization_id);

						if (searchVal) {
							if (
								String(searchVal).toLowerCase() ===
								String(childOrgid).toLowerCase()
							) {
								orgs = [childOrgid];
								useKeyword = false;
								return;
							} else if (
								String(searchVal).toLowerCase() ===
								String(child.title).toLowerCase()
							) {
								orgs = [childOrgid];
								useKeyword = false;
								return;
							} else if (
								String(searchVal).toLowerCase() ===
								String(child.manager_uid).toLowerCase()
							) {
								orgs = [childOrgid];
								useKeyword = false;
								return;
							} else {
								orgs.push(childOrgid);
							}
						} else {
							orgs.push(childOrgid);
						}

						walk(child);
					}
				}
			}
		}

		if (
			this.state.showOrgSubs &&
			orgsTreeParent &&
			orgsTreeParent.child_organizations &&
			orgsTreeParent.child_organizations.length &&
			parentOrg !== "_global_" &&
			parentOrg !== "travelagencies" &&
			parentOrg !== "propertymanagers" &&
			parentOrg !== "propertyowners"
		) {
			walk(orgsTreeParent);
		}

		if (orgs.length < 1) {
			if (this.props.location.search) {
				this.props.history.push("/collections");
			} else {
				this.getCollections();
			}
			return;
		} else {
			orgsString = orgs.join();
		}

		let searchQuery = "";
		if (this.state.keyword && useKeyword) {
			searchQuery = `&keyword=${encodeURIComponent(this.state.keyword)}`;
		}

		try {
			let collections = await apiHandler.get(
				`/propertycollections?organization_ids=${orgsString}${searchQuery}`
			);
			if (!collections.error) {
				let isNull = Boolean(collections.data.length);
				this.setState({
					collections: collections.data,
					isLoading: false,
					initLoader: false,
					isNull
				});
				if (
					collections.data.length !== 0 &&
					parseInt(collections.data.length / this.state.pageSize) + 1 <
						this.state.activePage
				) {
					this.props.history.push("?page=1");
					return;
				}
			} else {
				this.setState({
					collections: [],
					initLoader: false,
					isLoading: false
				});
			}
		} catch (e) {
			this.setState({
				collections: [],
				initLoader: false,
				isLoading: false
			});
		}
	}
	/**
	 * Select particular collection to collapse.
	 * @param {*} index index of collection panel
	 * @param {*} collectionId collection id
	 * @param {*} deleteStatus delete status.
	 */
	async panelSelect(index, collectionId, deleteStatus) {
		let activeKey = this.state.activeKey === index ? undefined : index;
		activeKey = deleteStatus ? index : activeKey;
		await this.setState({
			activeKey,
			roomLoader: true,
			properties: [],
			activeCollectionId: collectionId,
			propertiesPageSize: 10
		});
		this.props.history.push(
			"/collections/" + collectionId + this.props.location.search
		);
		if (this.properties[collectionId]) {
			this.setState({
				properties: this.properties[collectionId],
				roomLoader: false
			});
		} else {
			try {
				let properties = await apiHandler.get(
					`/propertycollections/${collectionId}/properties?status=all`
				);
				if (properties.error) {
					this.setState({
						properties: [],
						roomLoader: false
					});
					return;
				}
				properties = properties.data;
				this.properties[collectionId] = properties;
				if (this.state.activeKey === index)
					this.setState({
						properties,
						roomLoader: false
					});
			} catch (e) {
				this.setState({
					properties: [],
					roomLoader: false
				});
			}
		}
	}
	/**
	 * When click on add existing property of collection open existing property.
	 * @param {*} currentCollection current collection id
	 * @param {*} currentCollectionName current collection name
	 */
	addExistingProperty(currentCollection, currentCollectionName) {
		this.setState({
			currentCollection,
			currentCollectionName,
			modal: "Add Property"
		});
	}
	/** Close validation modal */
	closeValidations() {
		this.setState({
			showValidations: false
		});
	}
	/** Show delete modal to delete room type from collection
	 * @param {string} id property id
	 * @param {string} currentCollectionName current collection name
	 * @param {string} currentPropertyName current room type name
	 */
	showDeleteModal(e, id, currentCollectionName, currentPropertyName) {
		e.stopPropagation();
		this.setState({
			currentDeleteId: id,
			currentCollectionName,
			currentPropertyName,
			modal: "Delete Room"
		});
	}
	/**
	 * show validation modal.
	 * @param {*} e
	 * @param {*} data data of room type.
	 */
	showValidationModal(e, data) {
		e.preventDefault();
		let propId = data.property_id,
			property = this.state.selectedProperty;
		if (!property) {
			property = {};
		}
		if (propId != property.property_id) {
			this.setState({
				validationLoading: true
			});
			apiHandler
				.get(`/properties/${propId}/validations`, { iscognito: true })
				.then(validations => {
					if (validations.data) {
						const defaultChannels = constants.CHANNELS;
						let redAwningFatalError = false;
						let channels = {};

						validations.data.forEach(item => {
							if (
								item.channel === "redawning" &&
								item.severity_property === "fatal"
							) {
								redAwningFatalError = true;
							}
							if (defaultChannels[item.channel]) {
								let channelName = defaultChannels[item.channel];

								if (
									channels[channelName] &&
									item.severity_property === "fatal"
								) {
									channels[channelName].push(item);
								} else if (item.severity_property === "fatal") {
									channels[channelName] = new Array(item);
								}
							}
						});

						this.setState({
							redAwningFatalError: redAwningFatalError,
							showValidations: true,
							selectedProperty: data,
							validationChannels: channels,
							validationLoading: false
						});
					} else {
						this.setState({
							validationLoading: false
						});
					}
				});
		} else {
			this.setState({
				showValidations: true
			});
		}
	}

	/**
	 * remove room type from collection APi call.
	 * @param {*} roomId
	 */
	removeFromCollection(roomId) {
		let deleteProgressRow = this.state.deleteProgressRow;
		deleteProgressRow.push(roomId);
		this.setState({ deleteProgressRow, modal: undefined });
		let { activeCollectionId, activeKey } = this.state;

		if (activeCollectionId) {
			apiHandler
				.update(
					"delete",
					`/propertycollections/${activeCollectionId}/properties/${roomId}`
				)
				.then(async data => {
					this.properties[activeCollectionId] = undefined;
					let element = document.getElementById("row" + roomId);
					if (element) {
						element.classList.add("animation-delete");
					}
					setTimeout(() => {
						if (
							this.state.propertiesPageSize === this.state.properties.length
						) {
							this.setState({
								propertiesPageSize: this.state.propertiesPageSize - 1
							});
						}
						deleteProgressRow = this.state.deleteProgressRow;
						deleteProgressRow.splice(
							deleteProgressRow.findIndex(data => data === roomId),
							1
						);
						let properties = this.state.properties;
						properties.splice(
							properties.findIndex(data => data.property_id === roomId),
							1
						);
						this.properties[activeCollectionId] = [...properties];
						if (element) {
							element.classList.remove("animation-delete");
						}
						this.setState({
							properties: [...properties],
							deleteProgressRow
						});
					}, 1000);
				});
		}
	}

	/**
	 * Handle page size change.
	 */
	handlePageSizeChange(e) {
		e.preventDefault();
		let pageSize = e.target.value;
		if (
			pageSize.trim() != "" &&
			Number.isInteger(parseInt(pageSize)) &&
			pageSize != this.state.pageSize
		) {
			this.setState({
				pageSize: Number(pageSize),
				activeKey: undefined
			});
		}
	}

	/** Handle search */
	handleSearch(e) {
		if (e) {
			e.preventDefault();
		}
		this.getCollections();
	}

	/** Handle past reservation to search reservation made in past will call the API */
	handleShowOrgSubs = e => {
		this.setState({
			showOrgSubs: !this.state.showOrgSubs
		});
		this.getCollections();
	};

	/** Handle change of text box  */
	handleChange(e) {
		let name = e.target.name;
		let val = e.target.value;
		this.setState({
			keyword: val
		});
	}
	/** Get tooltip to icon */
	getTooltip(id, text) {
		return (
			<Tooltip id={id} className="properties__tooltip">
				{text}
			</Tooltip>
		);
	}

	/** Create collection panels from collection get from API */
	createCollectionPanels() {
		let collections = this.state.collections;
		let activePage = Number(this.state.activePage);
		let pageSize = this.state.pageSize;
		collections = collections.filter(
			(data, index) =>
				index >= (activePage - 1) * pageSize && index < activePage * pageSize
		);
		let collectionPanels = collections.map((collection, index) => {
			let subOrgName = "";
			if (
				collection &&
				collection.organization_name &&
				this.state.showOrgSubs
			) {
				subOrgName = "- " + collection.organization_name;
			}

			return (
				<Panel eventKey={index} key={index}>
					<Panel.Heading
						onClick={() => this.panelSelect(index, collection.collection_id)}
					>
						<Panel.Title
							className={this.state.activeKey === index ? "newClass" : ""}
							toggle
						>
							<Col xs={9} sm={9} md={9}>
								<strong>{collection.name}</strong> <span>{subOrgName}</span>
								<p
									className="col-desc"
									dangerouslySetInnerHTML={{ __html: collection.description }}
								></p>
							</Col>
							<Col xs={3} sm={3} md={3}>
								<div className="pull-right action-btn">
									<OverlayTrigger
										placement="bottom"
										overlay={this.getTooltip(
											"edit",
											`${this.state.admin ? "Update" : "View"} Collection`
										)}
									>
										<span
											onClick={e => {
												e.stopPropagation(),
													this.props.history.push({
														pathname: `/collections/${collection.collection_id}/edit`,
														state: { data: collection }
													});
											}}
											className="properties__options"
										>
											<i className="icon-edit" />
										</span>
									</OverlayTrigger>
									<OverlayTrigger
										placement="bottom"
										overlay={this.getTooltip("preview", "Preview")}
										onClick={e => e.stopPropagation()}
									>
										<span
											onClick={e => {
												e.stopPropagation(),
													window.open(
														`${
															collection.url !== ""
																? constants.COGNITO.BaseURL +
																  "/" +
																  collection.url
																: ""
														}`,
														"_blank"
													);
											}}
											className={`properties__options ${
												collection.url !== ""
													? ""
													: "properties__options--disabled"
											}`}
										>
											<i className="icon-property_details" />
										</span>
									</OverlayTrigger>
									{this.state.admin && (
										<div className="properties__options">
											<DropdownButton
												title={
													<Fragment>
														<OverlayTrigger
															placement="bottom"
															overlay={this.getTooltip(
																"add_property",
																"Add Room Types"
															)}
														>
															<i className="icon-Plus" />
														</OverlayTrigger>
														{(this.state.isSmartConciergeAdmin ||
															this.state.isSmartConciergeViewer) && (
															<OverlayTrigger
																placement="bottom"
																overlay={this.getTooltip(
																	"smart-concierge",
																	"Smart Concierge"
																)}
															>
																<span
																	onClick={e => {
																		e.stopPropagation(),
																			this.props.history.push({
																				pathname: `/smart-concierge/collections/${collection.collection_id}`
																			});
																	}}
																	to={`/smart-concierge/collections/${collection.collection_id}`}
																	style={{ marginLeft: "30px" }}
																>
																	<i className="icon-concierge" />
																</span>
															</OverlayTrigger>
														)}
													</Fragment>
												}
												onClick={e => {
													e.stopPropagation();
												}}
												className="menu-item-button"
												noCaret={true}
												id={"property-dropdown"}
											>
												<li
													className="menu-item"
													onClick={e => {
														e.stopPropagation(),
															this.props.history.push(
																`/collections/${collection.collection_id}/rooms/create`
															);
													}}
												>
													Create Room Type
												</li>
												<li
													className="menu-item"
													onClick={e => {
														e.stopPropagation();
														this.addExistingProperty(
															collection.collection_id,
															collection.name
														);
													}}
												>
													Add Existing Property
												</li>
											</DropdownButton>
										</div>
									)}
								</div>
							</Col>
						</Panel.Title>
					</Panel.Heading>
					<Panel.Body collapsible>
						<Col md={11} className="room-table">
							{this.state.roomLoader ? (
								<div className="loader">
									<Loader />
								</div>
							) : this.state.properties &&
							  this.state.properties.length === 0 ? (
								<div className="no-properties-div">
									<h2>No Room Types In This Collection </h2>
									{this.state.admin && (
										<Link
											to={`/collections/${collection.collection_id}/rooms/create`}
											className="primary-btn"
										>
											Add Room Type
										</Link>
									)}
									{this.state.admin && (
										<button
											className="primary-btn white-btn left-margin"
											onClick={() =>
												this.addExistingProperty(
													collection.collection_id,
													collection.name
												)
											}
										>
											Add Existing Property
										</button>
									)}
								</div>
							) : (
								<TableComponent
									resizable={false}
									minRows={0}
									pageSize={this.state.propertiesPageSize}
									loading={this.state.isLoading}
									showPagination={true}
									onPageSizeChange={val => {
										this.setState({
											propertiesPageSize: Number(val)
										});
									}}
									itemType={
										this.state.properties && this.state.properties.length == 1
											? "Property / Room Type"
											: "Properties / Room Types"
									}
									defaultPageSize={-1}
									data={this.state.properties}
									columns={this.state.columns}
									totalItemsCount={
										this.state.properties && this.state.properties.length
									}
									getTheadTrProps={props => ({ className: "t-header" })}
									getTrProps={(state, rowInfo, column) => {
										let data = rowInfo.original;
										return {
											onClick: (e, handleOriginal) => {
												this.props.history.push({
													pathname: `/collections/${collection.collection_id}/rooms/${rowInfo.original.property_id}/edit`,
													state: { data: data }
												});
											},
											style: {
												cursor: "pointer"
											}
										};
									}}
									getTrGroupProps={(props, rowInfo) => {
										return {
											id: "row" + rowInfo.original.property_id
										};
									}}
								></TableComponent>
							)}
						</Col>
					</Panel.Body>
				</Panel>
			);
		});
		return collectionPanels;
	}

	render() {
		return (
			<PageLayout isCentered={true}>
				<Fragment>
					{this.state.initLoader ? (
						<div className="loader">
							<Loader />
						</div>
					) : (
						""
					)}

					<Title
						title="Collections"
						isCentered={true}
						breadcrumbItems={this.breadcrumbItems}
					>
						{this.state.orgsTreeData &&
						this.state.orgsTreeData[0] &&
						this.state.orgsTreeData[0].child_organizations &&
						this.state.orgsTreeData[0].child_organizations.length &&
						this.state.orgsTreeData[0].organization_name !== "_global_" &&
						this.state.orgsTreeData[0].organization_name !== "travelagencies" &&
						this.state.orgsTreeData[0].organization_name !==
							"propertymanagers" &&
						this.state.orgsTreeData[0].organization_name !==
							"propertyowners" ? (
							<div className="filters-wrap">
								<InputField
									type="checkbox"
									value={this.state.showOrgSubs}
									cbChange={this.handleShowOrgSubs}
								>
									All Organizations
								</InputField>
							</div>
						) : (
							""
						)}
						<form className="search-inp" onSubmit={this.handleSearch}>
							<InputField
								type="searchbox"
								placeholder="Search"
								name="keyword"
								value={this.state.keyword}
								onChange={e =>
									this.setState({
										keyword: e.target.value
									})
								}
								clearSearch={async () => {
									await this.setState({
										keyword: ""
									});
									this.getCollections();
								}}
							/>
						</form>
						{this.state.admin && (
							<Link className="primary-btn" to="/collections/create">
								<i className="icon-Plus create-icon" />
								Create
							</Link>
						)}
					</Title>
					<div id="content" className="properties ">
						<Grid fluid={true}>
							<Row>
								{this.state.initLoader ? (
									<Col xs={12}>
										<div className="no-collection">Loading...</div>
									</Col>
								) : (
									""
								)}
								<Col xs={12}>
									{!this.state.isNull ? (
										<div className="no-collection">
											No collections found. Please create a new collection.
										</div>
									) : (
										<PanelGroup
											accordion
											id={"collection-panels"}
											className="collections"
											activeKey={this.state.activeKey}
											onSelect={activeKey => {
												this.setState({ activeKey });
											}}
										>
											{this.createCollectionPanels()}
										</PanelGroup>
									)}
								</Col>
								<Col xs={12}>
									{this.state.collections.length > 0 && (
										<div className="pagination-container">
											<div className="units-info-container">
												{this.state.collections.length}{" "}
												{this.state.collections.length === 1
													? "Collection"
													: "Collections"}
												&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
											</div>
											<div className="page_size-input-container">
												<div className="page-size">
													<InputField
														className="pageSize"
														type="number"
														name="pageSize"
														autoComplete="off"
														defaultValue={this.state.pageSize}
														onChange={e => {
															if (e.target.value > 100) {
																e.target.value = 100;
															} else if (e.target.value < 0) {
																e.target.value = 1;
															}
														}}
														onKeyUp={e => {
															if (
																e.keyCode === 13 &&
																e.target.value !== 0 &&
																Number.isInteger(Number(e.target.value))
															) {
																this.handlePageSizeChange(e);
															}
														}}
													/>
													/ page
												</div>
											</div>
											<Pagination
												itemsCountPerPage={this.state.pageSize}
												totalItemsCount={this.state.collections.length}
												pageRangeDisplayed={5}
												onChange={e => {
													this.props.history.push("?page=" + e);
												}}
												activePage={Number(this.state.activePage)}
											/>
										</div>
									)}
								</Col>
							</Row>
						</Grid>
					</div>
					<PropertiesModal
						org={this.props.roleManager.org}
						collectionId={this.state.currentCollection}
						collectionName={this.state.currentCollectionName}
						show={this.state.modal === "Add Property"}
						closeModal={value => {
							this.setState({
								modal: undefined,
								currentCollectionName: undefined
							});
							if (value) {
								this.init();
							}
						}}
					/>
					{this.state.selectedProperty != null && (
						<ValidationModal
							history={this.props.history}
							show={this.state.showValidations}
							property={this.state.selectedProperty}
							onHide={this.closeValidations}
							validationChannels={this.state.validationChannels}
							redAwningFatalError={this.state.redAwningFatalError}
						/>
					)}
					<Modal
						className="room-delete"
						show={this.state.modal === "Delete Room"}
						onHide={() => this.setState({ modal: undefined })}
					>
						<Modal.Body className="new-importing auto-height-import">
							<Col md={12} lg={12} sm={12} xs={12}>
								<h1>
									Are you sure you want to remove{" "}
									{this.state.currentPropertyName} from{" "}
									{this.state.currentCollectionName}?
								</h1>
							</Col>
							<Col md={6} className="pri-btn">
								<PrimaryButton
									cssClass="pull-left white-btn col-md-12 "
									fullWidth={true}
									type="button"
									onClick={() => this.setState({ modal: undefined })}
								>
									Cancel
								</PrimaryButton>
							</Col>
							<Col md={6} className="pri-btn">
								<PrimaryButton
									cssClass="pull-right col-md-12 "
									fullWidth={true}
									type="button"
									onClick={() => {
										this.removeFromCollection(this.state.currentDeleteId);
									}}
								>
									Delete
								</PrimaryButton>
							</Col>
						</Modal.Body>
					</Modal>
				</Fragment>
			</PageLayout>
		);
	}
}

const mapStateToProps = state => {
	return {
		roleManager: state.roleManager,
		user: state.authState.user
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setRole: role => {
			dispatch(setRole(role));
		},
		updateOrganization: org => {
			dispatch(updateOrganization(org));
		},
		updateOrganizationTitle: org => {
			dispatch(updateOrganizationTitle(org));
		},
		updateOrganizationId: orgId => {
			dispatch(updateOrganizationId(orgId));
		}
	};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Collection)
);
