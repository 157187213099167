import React, { Fragment } from 'react';
// import { Form, Col, Collapse, Row, Button, PanelGroup, Panel, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Form from 'react-bootstrap/lib/Form';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import PanelGroup from 'react-bootstrap/lib/PanelGroup';
import Panel from 'react-bootstrap/lib/Panel';
import { FieldWrap, InputLabel, InputField, PrimaryButton } from '../global/forms/FormElements';
import { Title } from '../global/Title';
import PageLayout from '../global/PageLayout';
import { Auth, API } from 'aws-amplify';
import Loader from '../global/Loader';
import Modal from 'react-bootstrap/lib/Modal';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import constants from '../../js/constants';
import { updateUser } from '../../js/actions';
import Switch from "react-switch";

/** @class Account page is gives information about */
class Account extends React.Component {
    constructor(props) {
        super(props);
        this.isAdmin = false;
        this.isViewer = false;
        this.isAdmin = (this.props.roleManager.indexOf(constants.USER_TYPES.ACCOUNT_MANAGER_ADMIN) > -1)
        this.isViewer = (this.props.roleManager.indexOf(constants.USER_TYPES.ACCOUNT_MANAGER_VIEWER) > -1)
        this.state = {
            commCenterAccess: false,
            modal: '',
            modalData: {},
            addNotificationData: {
                name: '',
                value: '',
            },
            isLoading: true,
            notificationIsLoading: false,
            isSaved: undefined,
            uid: "",
            first_name: "",
            last_name: "",
            street_address: "",
            city: "",
            province: "",
            postal_code: "",
            country: "",
            mobile_phone: "",
            home_phone: "",
            work_phone: "",
            email: "",
            showModal: false,
            activeKey: 1,
            currentSub: {},
        }
        this.breadcrumbItems = [{
			title: "My Profile",
        }]
        this.countries = [<option key="Select" value="">Select Country</option>].concat(constants.PROPERTY_VIEW.COUNTRIES.map(country=>{
			return(<option key={country.Code} value={country.Code}>{country.Name}</option>)
		}))
        this.org = this.props.orgName;
        this.init = this.init.bind(this);
    }

    async componentDidMount() {
        this.init();
    }

    componentWillReceiveProps(newProps) {
        let {location} = newProps;
		if(location && location.state && location.state.org!=this.org) {
			this.org=location.state.org
			this.init();
		}
    }

    getCurrentSub = async () => {
        let resp;
        try {
            resp = await API.get('ramapi', `/commhub/currentsubscriber`, { response: true });
            if(resp && resp.data) {

                if(resp && resp.data && resp.data.message_type_data && resp.data.message_type_data.email && resp.data.message_type_data.email.length) {
                    const mainEmail = resp.data.message_type_data.email.filter((x) => x.addr == this.props.user.contact_info.email);

                    if(!mainEmail.length) {
                        resp.data.message_type_data.email.unshift({ addr: this.props.user.contact_info.email, name: 'my email', optIn: false});
                    }
                    else {
                        resp.data.message_type_data.email.map((x, i) => {
                            if(x.addr == this.props.user.contact_info.email) {
                                resp.data.message_type_data.email[i] = {
                                    ...x,
                                    optIn: true
                                }
                            }
                        });
                    };
                }
                else {
                    resp.data.message_type_data.email = [...resp.data.message_type_data.email, { addr: this.props.user.contact_info.email, name: 'my email', optIn: false}]
                };

                if(resp && resp.data && resp.data.message_type_data && resp.data.message_type_data.sms && resp.data.message_type_data.sms.length) {
                    const mainSms = resp.data.message_type_data.sms.filter((x) => x.phone == this.props.user.contact_info.mobile_phone);

                    if(!mainSms.length) {
                        resp.data.message_type_data.sms.upshift({ phone: this.props.user.contact_info.mobile_phone, name: 'my phone', optIn: false});
                    }
                    else {
                        resp.data.message_type_data.sms.map((x, i) => {
                            if(x.phone == this.props.user.contact_info.mobile_phone) {
                                resp.data.message_type_data.sms[i] = {
                                    ...x,
                                    optIn: true
                                }
                            }
                        });
                    };
                }
                else {
                    resp.data.message_type_data.sms = [...resp.data.message_type_data.sms, { phone: this.props.user.contact_info.mobile_phone, name: 'my phone', optIn: false}]
                };

                this.setState({
                    currentSub: resp && resp.data,
                    commCenterAccess: true,
                });
            };
        }
        catch(err) {
            this.setState({
                commCenterAccess: false,
            });
            console.log(err);
        };
    }

    submitNotification = async () => {
        const { 
            currentSub,
            modalData,
            addNotificationData
        } = this.state;
        let currentSubCopy = JSON.parse(JSON.stringify(currentSub));
        let resp;

        if(currentSubCopy && currentSubCopy.message_type_data && currentSubCopy.message_type_data.email && currentSubCopy.message_type_data.email.length) {
            let emailIndex;
            let mainEmailData;

            currentSubCopy.message_type_data.email.map((x, i) => {
                if(typeof x.optIn != 'undefined') {
                    emailIndex = i;
                    mainEmailData = x;
                };
            });

            if(mainEmailData && mainEmailData.optIn == false) {
                currentSubCopy.message_type_data.email.splice(emailIndex, 1);
            }
            else {
                delete currentSubCopy.message_type_data.email[emailIndex].optIn;
            };
        };

        if(currentSubCopy && currentSubCopy.message_type_data && currentSubCopy.message_type_data.sms && currentSubCopy.message_type_data.sms.length) {
            let smsIndex;
            let mainSmsData;

            currentSubCopy.message_type_data.sms.map((x, i) => {
                if(typeof x.optIn != 'undefined') {
                    smsIndex = i;
                    mainSmsData = x;
                };
            });

            if(mainSmsData && mainSmsData.optIn == false) {
                currentSubCopy.message_type_data.sms.splice(smsIndex, 1);
            }
            else {
                delete currentSubCopy.message_type_data.sms[smsIndex].optIn;
            };
        };

        // Delete Notification
        if(modalData && modalData.data) {
            currentSubCopy.message_type_data[modalData.type].splice(modalData.index, 1);
        };

        if(addNotificationData && addNotificationData.name) {
            currentSubCopy.message_type_data[modalData.type].push({ name: addNotificationData.name, [modalData.type == 'email' ? 'addr' : 'phone']: addNotificationData.value })
        };


        this.handleModal('', '');

        this.setState({
            notificationIsLoading: true
        });

        try {
            resp = await API.put('ramapi', `/commhub/subscribers/${currentSub.subscriber_id}`, { body: currentSubCopy });
            if(resp) {
                if(resp && resp.message_type_data && resp.message_type_data.email && resp.message_type_data.email.length) {
                    const mainEmail = resp.message_type_data.email.filter((x) => x.addr == this.props.user.contact_info.email);

                    if(!mainEmail.length) {
                        resp.message_type_data.email.unshift({ addr: this.props.user.contact_info.email, name: 'my email', optIn: false});
                    }
                    else {
                        resp.message_type_data.email.map((x, i) => {
                            if(x.addr == this.props.user.contact_info.email) {
                                resp.message_type_data.email[i] = {
                                    ...x,
                                    optIn: true
                                }
                            }
                        });
                    };
                }
                else {
                    resp.message_type_data.email = [...resp.message_type_data.email, { addr: this.props.user.contact_info.email, name: 'my email', optIn: false}]
                };

                if(resp && resp.message_type_data && resp.message_type_data.sms && resp.message_type_data.sms.length) {
                    const mainSms = resp.message_type_data.sms.filter((x) => x.phone == this.props.user.contact_info.mobile_phone);

                    if(!mainSms.length) {
                        resp.message_type_data.sms.upshift({ phone: this.props.user.contact_info.mobile_phone, name: 'my phone', optIn: false});
                    }
                    else {
                        resp.message_type_data.sms.map((x, i) => {
                            if(x.phone == this.props.user.contact_info.mobile_phone) {
                                resp.message_type_data.sms[i] = {
                                    ...x,
                                    optIn: true
                                }
                            }
                        });
                    };
                }
                else {
                    resp.message_type_data.sms = [...resp.message_type_data.sms, { phone: this.props.user.contact_info.mobile_phone, name: 'my phone', optIn: false}]
                };
            };

            this.setState({
                currentSub: resp,
                notificationIsLoading: false
            });
        }
        catch(err) {
            console.log(err);
            this.setState({
                notificationIsLoading: false
            });
        };
    }

    /** Initialize page */
    async init() {
        if(APIConfig.ENV === "DEV") {
            await this.getCurrentSub();
        };
        if(this.isAdmin || this.isViewer){
            API.get(constants.ACCAPI.NAME, constants.ACCAPI.GET_USER(this.org), null).then((res) => {
                this.setState({
                    data: res,
                    isLoading: false,
                })
            }).catch((err) => {
                this.setState({
                    isLoading: false,
                })
            });
        }
         this.setState({
            uid: this.props.user.uid,
            first_name: (this.props.user.contact_info.first_name) ? this.props.user.contact_info.first_name : "",
            last_name: (this.props.user.contact_info.last_name) ? this.props.user.contact_info.last_name : "",
            street_address: (this.props.user.contact_info.street_address) ? this.props.user.contact_info.street_address : "",
            city: (this.props.user.contact_info.city) ? this.props.user.contact_info.city : "",
            province: (this.props.user.contact_info.province) ? this.props.user.contact_info.province : "",
            postal_code: (this.props.user.contact_info.postal_code) ? this.props.user.contact_info.postal_code : "",
            country: (this.props.user.contact_info.country) ? this.props.user.contact_info.country : "",
            mobile_phone: (this.props.user.contact_info.mobile_phone) ? this.props.user.contact_info.mobile_phone : "",
            home_phone: (this.props.user.contact_info.home_phone) ? this.props.user.contact_info.home_phone : "",
            work_phone: (this.props.user.contact_info.work_phone) ? this.props.user.contact_info.work_phone : "",
            email: (this.props.user.contact_info.email) ? this.props.user.contact_info.email : "",
        });
        if(!(this.isAdmin || this.isViewer)) {
            this.setState({
                isLoading: false
            })
        }
    }
    /** Handle text filed change. */
    textChange(event) {
        let newState = this.state;
        newState[event.target.name] = event.target.value;
        this.setState({
            newState
        });
    }
    /** Close modal. */
    closeModal() {
        this.setState({
            isSaved: undefined,
            showModal: false,
            roleValidation:false,
            emailValidation:false
        })
    }
    /** invoke when click on save button call API to save user data */
    clickSave() {
        this.setState({
            isLoading: true,
        })
        let data = {
            uid: this.state.uid,
            contact_info: {
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                street_address: this.state.street_address,
                city: this.state.city,
                province: this.state.province,
                postal_code: this.state.postal_code,
                country: this.state.country,
                mobile_phone: this.state.mobile_phone,
                home_phone: this.state.home_phone,
                work_phone: this.state.work_phone,
                email: this.state.email,
            }
        }
        let email = this.state.email.match(constants.EMAIL_VALIDATION)
        if (!email) {
            this.setState({
                isLoading: false,
                isSaved: true,
                showModal: "Notification Modal",
                msg: constants.EMAIL_INVALID_MESSAGE
            })
        } else {
            API.patch(constants.ACCAPI.NAME, constants.ACCAPI.CURRENT_USER, { body: data }).then((res) => {
                this.setState({
                    isLoading: false,
                    isSaved: true,
                    showModal: "Notification Modal",
                    msg: 'Details Saved'
                })
                this.props.updateUser(res)
            }).catch((err) => {
                this.setState({
                    isSaved: false,
                    isLoading: false,
                    msg: 'Error saving details',
                    showModal: "Notification Modal",
                })
            })
        }
    }

    optInOnChange = (bool, index, type) => {
        const { currentSub } = this.state;
        let copyCurrentSub = currentSub; 

        if(type === 'email') {
            copyCurrentSub.message_type_data.email.splice(index, 1, { ...currentSub.message_type_data.email[index], optIn: bool})
        }
        else if(type === 'sms') {
            copyCurrentSub.message_type_data.sms.splice(index, 1, { ...currentSub.message_type_data.sms[index], optIn: bool})
        }

        this.setState({
            currentSub: copyCurrentSub
        }, () => {
            this.submitNotification()
        });
    }

    handleModal = (e, name, type, index, data) => {
        e && e.preventDefault();

        this.setState({
            modal: name,
        }, () => {
            const { 
                modal,
            } = this.state;
            
            if(modal && modal.length) {
                this.setState({
                    modalData: {
                        type: type,
                        data: data,
                        index: index
                    }
                });
            }
            else {
                this.setState({
                    modalData: {},
                    addNotificationData: {
                        name: '',
                        value: '',
                    }
                });
            };
        });
    }

    handleNotificationInput = (e) => {
        const { addNotificationData } = this.state;
        let name = e.target.name;
        let value = e.target.value;

        this.setState({
            addNotificationData: {
                ...addNotificationData,
                [name]: value
            }
        })
    }

    renderNotification = (data, type) => {
        return data.map((x, i) => {
            if(type == 'email' && (typeof x.optIn != 'undefined') || type == 'sms' && (typeof x.optIn != 'undefined')) {
                return (
                    <Row key={i}>
                        <Col sm={6} xs={12} className='border'>
                            <div>{x.name}</div>
                            <div className='flex-space-between'>
                                <div>{x.addr || x.phone}</div>
                                <Switch 
                                    onColor={"#5AD888"} 
                                    uncheckedIcon={false} 
                                    checkedIcon={false}
                                    height={20} 
                                    width={45}
                                    checked={x.optIn}
                                    onChange={bool => this.optInOnChange(bool, i, type)}
                                />
                            </div>    
                        </Col>
                    </Row>
                )
            }
            else {
                if(type == 'email' || type == 'sms') {
                    return (
                        <Row key={i}>
                            <Col sm={6} xs={12} className='border'>
                                <div>{x.name}</div>
                                <div className='flex-space-between'>
                                    <div>{x.addr || x.phone}</div>
                                    <span onClick={e => this.handleModal(e, 'prompt', type, i, x)}>
                                        <i className='icon icon-Delete'></i>
                                    </span>
                                </div> 
                            </Col>
                        </Row>
                    )
                }
                else {
                    return (
                        <Row key={i}>
                            <Col sm={6} xs={12} className='flex-space-between border'>
                                <span>{x.name}</span>
                                <span onClick={e => this.handleModal(e, 'prompt', type, i, x)}>
                                    <i className='icon icon-Delete'></i>
                                </span>
                            </Col>
                        </Row>
                    )
                };
            };
        })
    }
    
    render() {
        const {
            modal,
            modalData,
            currentSub,
            isLoading,
            addNotificationData,
            notificationIsLoading,
            commCenterAccess
        } = this.state;

        return (
            <PageLayout isCentered={true}>
                <Title title='My Profile' isCentered={true} breadcrumbItems={this.breadcrumbItems}>
                    <PrimaryButton onClick={this.clickSave.bind(this)} cssClass="primary-btn pull-left panal-button" fullWidth={false} type="button"> Save </PrimaryButton>
                </Title>
                <div id="content">
                    <div className="property-item row">
                        <FieldWrap>
                            <Form horizontal>
                                {(isLoading || notificationIsLoading) && <Loader />}
                                    <Row className="row-padding">
                                        <Modal  className="submit-modal" show={this.state.showModal === 'Notification Modal'} onHide={this.closeModal.bind(this)}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    {this.state.isSaved === true ? (
                                                        <div>
                                                            <span className='status-icon status-icon--success'></span>
                                                            <span className="submit-heading">{this.state.msg}</span>
                                                        </div>) :
                                                        (<div>
                                                            <span className='status-icon status-icon--error'></span>
                                                            <span className="submit-heading">{this.state.msg}</span>
                                                        </div>)}
                                                </Modal.Title>
                                            </Modal.Header>
                                        </Modal>
                                        <Col xs={12}>
                                            <PanelGroup accordion id="account-settings" defaultActiveKey={1} activeKey={this.state.activeKey} onSelect={(activeKey) => { this.setState({ activeKey }) }}>
                                                <Panel eventKey={1}>
                                                    <Panel.Heading>
                                                        <Panel.Title toggle>
                                                            <strong>Profile Information <span className={'pull-right '+(this.state.activeKey === 1 ? 'glyphicon glyphicon-chevron-up': 'glyphicon glyphicon-chevron-down')}></span></strong>
                                                        </Panel.Title>
                                                    </Panel.Heading>
                                                    <Panel.Body collapsible>
                                                        <Row>
                                                            <Col sm={4} xs={12}>
                                                                <InputLabel htmlFor="firstname">First Name</InputLabel>
                                                                <InputField type="text" name="first_name" onChange={this.textChange.bind(this)} value={this.state.first_name} />
                                                            </Col>
                                                            <Col sm={4} xs={12}>
                                                                <InputLabel htmlFor="lastname">Last Name</InputLabel>
                                                                <InputField type="text" name="last_name" onChange={this.textChange.bind(this)} value={this.state.last_name} />
                                                            </Col>
                                                            <Col sm={4} xs={12}>
                                                                <InputLabel htmlFor="email">Email</InputLabel>
                                                                {/* <InputField type="text" readOnly name="email" onChange={this.textChange.bind(this)} value={this.state.email} /> */}
                                                                <span className="readOnly-text">{this.state.email}</span>
                                                            </Col>
                                                        </Row>
                                                        <Row className="bottom-margin"></Row>
                                                        <Row>
                                                            <Col sm={4} xs={12}>
                                                                <InputLabel htmlFor="homephone">Home Phone</InputLabel>
                                                                <InputField type="text" name="home_phone" onChange={this.textChange.bind(this)} value={this.state.home_phone} />
                                                            </Col>
                                                            <Col sm={4} xs={12}>
                                                                <InputLabel htmlFor="officephone">Office Phone</InputLabel>
                                                                <InputField type="text" name="work_phone" onChange={this.textChange.bind(this)} value={this.state.work_phone} />
                                                            </Col>
                                                            <Col sm={4} xs={12}>
                                                                <InputLabel htmlFor="mobilephone">Mobile Phone</InputLabel>
                                                                <InputField type="text" pattern="[0-9]" name="mobile_phone" onChange={this.textChange.bind(this)} value={this.state.mobile_phone} />
                                                            </Col>
                                                        </Row>
                                                        <Row className="bottom-margin"></Row>
                                                        <Row className="bottom-margin"></Row>
                                                        <Row>
                                                            <Col sm={4} xs={12}>
                                                                <InputLabel htmlFor="address">Address</InputLabel>
                                                                <InputField type="text" name="street_address" onChange={this.textChange.bind(this)} value={this.state.street_address} />
                                                            </Col>
                                                            <Col sm={4} xs={12}>
                                                                <InputLabel htmlFor="city">City</InputLabel>
                                                                <InputField type="text" name="city" onChange={this.textChange.bind(this)} value={this.state.city} />
                                                            </Col>
                                                            <Col sm={4} xs={12}>
                                                                <InputLabel htmlFor="state">State/Province</InputLabel>
                                                                <InputField type="text" name="province" onChange={this.textChange.bind(this)} value={this.state.province} />
                                                            </Col>
                                                        </Row>
                                                        <Row className="bottom-margin"></Row>
                                                        <Row>
                                                            <Col sm={4} xs={12}>
                                                                <InputLabel htmlFor="zipcode">Zip / Postal Code</InputLabel>
                                                                <InputField type="text" name="postal_code" onChange={this.textChange.bind(this)} value={this.state.postal_code} />
                                                            </Col>
                                                            <Col sm={4} xs={12}>
                                                                <InputLabel htmlFor="country">Country</InputLabel>
                                                                <InputField type="select" value={this.state.country || ""} placeholder="Country" name="country" onChange={this.textChange.bind(this)}>
                                                                    {this.countries}
																</InputField>
                                                            </Col>
                                                        </Row>
                                                    </Panel.Body>
                                                </Panel>
                                                {
                                                    commCenterAccess &&
                                                    <Panel eventKey={1}>
                                                        <Panel.Heading>
                                                            <Panel.Title toggle>
                                                                <strong>Notification Settings <span className={'pull-right '+(this.state.activeKey === 1 ? 'glyphicon glyphicon-chevron-up': 'glyphicon glyphicon-chevron-down')}></span></strong>
                                                            </Panel.Title>
                                                        </Panel.Heading>
                                                        <Panel.Body collapsible>
                                                            <div className="notification-settings">
                                                                {
                                                                    !isLoading &&
                                                                    <Fragment>
                                                                        {
                                                                            (currentSub && currentSub.message_type_data && currentSub.message_type_data.email) &&
                                                                            <Fragment>
                                                                            <Row>
                                                                                <Col sm={12} xs={12} className='display-flex'>
                                                                                    <h2>Email</h2>
                                                                                    <span onClick={e => this.handleModal(e, 'add', 'email')}>
                                                                                        <i className='icon icon-icon-add'></i>
                                                                                    </span>
                                                                                </Col>
                                                                            </Row>
                                                                            {this.renderNotification(currentSub.message_type_data.email, 'email')}
                                                                            </Fragment>
                                                                        }
                                                                        <br/>
                                                                        {
                                                                            (currentSub && currentSub.message_type_data && currentSub.message_type_data.sms) &&
                                                                            <Fragment>
                                                                                <Row>
                                                                                    <Col sm={12} xs={12} className='display-flex'>
                                                                                        <h2>SMS</h2>
                                                                                        <span onClick={e => this.handleModal(e, 'add', 'sms')}>
                                                                                            <i className='icon icon-icon-add'></i>
                                                                                        </span>
                                                                                    </Col>
                                                                                </Row>
                                                                                {this.renderNotification(currentSub.message_type_data.sms, 'sms')}
                                                                            </Fragment>
                                                                        }
                                                                        <br/>
                                                                        {
                                                                            (currentSub && currentSub.message_type_data && currentSub.message_type_data.device_notification) &&
                                                                            <Fragment>
                                                                                <Row>
                                                                                    <Col sm={12} xs={12}><h2>Devices</h2></Col>
                                                                                </Row>
                                                                                {this.renderNotification(currentSub.message_type_data.device_notification, 'device_notification')}
                                                                            </Fragment>
                                                                        }
                                                                    </Fragment>
                                                                }
                                                            </div>
                                                        </Panel.Body>
                                                    </Panel>
                                                }
                                            </PanelGroup>
                                        </Col>
                                    </Row>
                            </Form>
                        </FieldWrap>
                    </div>
                </div>
                <Modal
                    show={modal == 'add'} 
                    onHide={this.handleModal}
                >
                    <Modal.Body>
                        <Row>
                            <Col sm={12} xs={12}>
                                <h2>Add {modalData.type == 'email' ? 'Email' : 'Phone'} Notification</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} xs={12}>
                                <InputLabel>Name</InputLabel>
                                <InputField 
                                    type="text" 
                                    name="name" 
                                    onChange={e =>this.handleNotificationInput(e)} 
                                    value={addNotificationData && addNotificationData.name}
                                />
                            </Col>
                            <Col sm={12} xs={12}>
                                <InputLabel>{modalData.type == 'email' ? 'Email' : 'Phone'}</InputLabel>
                                <InputField 
                                    type="text" 
                                    name="value" 
                                    onChange={e =>this.handleNotificationInput(e)} 
                                    value={addNotificationData && addNotificationData.value}
                                />
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col md={6}>
                                <PrimaryButton 
                                    cssClass="pull-left white-btn col-md-12 " 
                                    fullWidth={true} 
                                    type="button" 
                                    onClick={this.handleModal}
                                >
                                    Cancel
                                </PrimaryButton>
                            </Col>
                            <Col md={6}>
                                <PrimaryButton 
                                    cssClass="pull-right col-md-12 " 
                                    fullWidth={true} 
                                    type="button" 
                                    onClick={this.submitNotification}
                                >
                                    save
                                </PrimaryButton>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
                <Modal
                    show={modal == 'prompt'} 
                    onHide={this.handleModal}
                >
                    <Modal.Body>
                        <Row className="text-center">
                            <Col md={12}>
                                <h1>Are you sure you want to delete</h1>
                                <h1>"{modalData && modalData.data && modalData.data.name}"?</h1>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col md={6}>
                                <PrimaryButton 
                                    cssClass="pull-left white-btn col-md-12 " 
                                    fullWidth={true} 
                                    type="button" 
                                    onClick={this.handleModal}
                                >
                                    Cancel
                                </PrimaryButton>
                            </Col>
                            <Col md={6}>
                                <PrimaryButton 
                                    cssClass="pull-right col-md-12 " 
                                    fullWidth={true} 
                                    type="button" 
                                    onClick={this.submitNotification}
                                >
                                    Delete
                                </PrimaryButton>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </PageLayout>
        )
    }
}


const mapStateToProps = state => {
    return {
        roleManager: state.roleManager.roles,
        user: state.authState.user,
        orgName: state.roleManager.org
    }
}


const mapDispatchToProps = dispatch => {
    return {
        updateUser: (user) => {
            dispatch(updateUser(user));
        },
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Account));
