import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropertiesForm from '../content/properties/PropertiesForm';

import PageLayout from '../global/PageLayout';

/** For adding new room in collection use PropertyForm as child component */
class AddRoom extends Component {
	constructor(props) {
		super(props);
		this.cloneData = null;
		this.breadcrumbItems = [{
			title: "Collections",
			link: "/collections"
        },{
			title: "Create Room Type"
		}]
		if(props.location.state && props.location.state.cloneData) {
			let propState = props.location.state;
			this.cloneData = propState.cloneData;
		}
	}

	render() {
		return (
			<PageLayout isCentered={true}>
				<PropertiesForm 
					collection={true} 
					collectionId={this.props.match.params.collectionId} 
					breadcrumbItems={this.breadcrumbItems}  
					title="Create Room Type" 
					cloneData={this.cloneData}
				/>
			</PageLayout>
		)
	}
}

const mapStateToProps = state => {
    return {
        org: state.roleManager.org
    }
}

export default withRouter(connect(
    mapStateToProps,
    null
)(AddRoom));
