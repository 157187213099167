import React from 'react'
import 'react-dates/initialize';
import SingleDatePicker from 'react-dates/lib/components/SingleDatePicker';

export class DatePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            focused: null
        }
    }

    render() {
        return (
            <SingleDatePicker
                {...this.props}
                noBorder
                focused={this.state.focused}
                onFocusChange={({ focused }) => this.setState({ focused })}
            />
        )
    }
}