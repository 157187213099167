const orgModal = (state = {}, action) => {
    switch (action.type) {
        case 'ORG_MODAL':
            return Object.assign({}, state, {
                show: action.show
            });            
        default:
            return state
    }
 }
  
  export default orgModal