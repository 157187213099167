import React, { Component } from 'react';
import ReactTable from 'react-table/react-table.min.js';
import Pagination from 'react-js-pagination';
import { InputField } from '../global/forms/FormElements';
import Loader from './Loader';

class ReactTableComponent extends Component {
    render(){
        return (
            <ReactTable
                {...this.props}
                ref={this.props.getRef ? (r) => this.props.getRef(r, this.props.reactTableName ? this.props.reactTableName : undefined) : null}
                page={this.props.apiBase ?  0 : parseInt(this.props.activePage - 1)}
                PaginationComponent={
                    (props) => {
                        return (this.props.reactTable && this.props.reactTable.state && this.props.reactTable.state.sortedData.length > 0 && this.props.showPagination) ? (
                            <div
                                className="pagination-container"
                            >
                                <div className="units-info-container">
                                    {
                                        this.props.totalCount ?
                                        this.props.totalCount :
                                        (this.props.reactTable && 
                                        this.props.reactTable.state && 
                                        this.props.reactTable.state.sortedData.length)
                                    }
                                    &nbsp;&nbsp;
                                    {props.itemType ? props.itemType : ''}
                                    &nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                                </div>
                                <div className="page_size-input-container">
                                    <div 
                                        className='page-size' 
                                    >
                                        <InputField
                                            className="pageSize"
                                            type='number' 
                                            name='pageSize'
                                            autoComplete="off"
                                            defaultValue={props.pageSize}
                                            disabled={props.disabledPageSize}
                                            onKeyUp={(e) => {
                                                let value = e.target.value;

                                                if(value > 100) {
                                                    value = 100;
                                                }
                                                else if(value < 0) {
                                                    value = 1;
                                                };
                                                if(value.length && e.keyCode === 13 && value !== 0 && Number.isInteger(Number(value))) {
                                                    this.props.pageSizeOnChange(value)
                                                };
                                            }}
                                        /> 
                                        / page
                                    </div>
                                </div>
                                <Pagination
                                    activePage={this.props.activePage}
                                    hideFirstLastPages={this.props.hideFirstLastPages}
                                    prevPageText={this.props.prevPageText ? "Prev" : undefined}
                                    nextPageText={this.props.nextPageText ? "Next" : undefined}
                                    itemsCountPerPage={props.pageSize}
                                    totalItemsCount={
                                        this.props.totalCount ?
                                        this.props.totalCount :
                                        (this.props.reactTable && 
                                        this.props.reactTable.state && 
                                        this.props.reactTable.state.sortedData.length)
                                    }
                                    onChange={this.props.pageOnChange}
                                />
                            </div>
                        ) :
                        ""
                    }
                }
                LoadingComponent={
                    (props)=> {
                        return <Loader hide={props.loading ? false : true}/>
                    }
                }
            />
        )
    }
}
export default ReactTableComponent