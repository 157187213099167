import React, { Fragment } from 'react';
import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Modal from 'react-bootstrap/lib/Modal';
import { InputField, PrimaryButton, FieldWrap, InputLabel } from '../../global/forms/FormElements';
// import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-dates/initialize';
import DateRangePicker from 'react-dates/lib/components/DateRangePicker';

/**
 * Add or edit pricing modal use on pricing page.
 */
export class PricingModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            focusedInput: null,
            mCheckIn: "",
            mCheckOut: "",
            pricingCalendar: [],
        }
        this.ovrLabel = [
            {name: "priceOvrSource", value: "min_price", label: "Minimum Price"},
            {name: "priceOvrSource", value: "price_ovr", label: "Modify Price"},
        ];
    }   

    propertyPricePeriods = (day) => {
        if (day && this.state.pricingCalendar){
            for (let i = 0; i < this.state.pricingCalendar.length; i++) {
                let period_start = moment(this.state.pricingCalendar[i].period_start)
                let period_end = moment(this.state.pricingCalendar[i].period_end).add(1, 'd')
                if (moment(day).isBetween(period_start, period_end) || moment(day).isSame(period_start) || moment(day).isSame(period_end)) {
                    return true;
                } 
            }
        }        
    }

    /**
     * Handle date change on add pricing modal.
     * @param {*} startDate
     * @param {*} endDate
     */
    handleDatesChange = ({startDate,endDate}) => {
        this.setState({
            isDisabled: false
        });
        if (startDate) {
            this.props.handlePeriodDateChange(startDate,'period_start', 'price', {startDate,endDate})
        };
        if (endDate) {
            this.props.handlePeriodDateChange(endDate,'period_end', 'price', {startDate,endDate})
        };
    }

    handleEmptyPlaceholder = (e, date) => {
        e.target.placeholder = ''
        e.target.value = date
    }

    handleDatedPlaceholder = (e, date) => {
        e.target.placeholder = date
    }

    handleManualDateChange = e => {
        e.preventDefault()
        const { 
            pricingModal: { 
                data: { 
                    period_start 
                } 
            } 
        } = this.props

        let newVal = e.target.value.replace(/[.-]/g, '/')

        if (e.target.name === 'mStartDate') {
            this.setState({
                mCheckIn: newVal,
            })
            if (moment(newVal, 'MM/DD/YYYY', true).isValid()) {
                this.props.handlePeriodDateChange(moment(newVal), 'period_start', 'price')
                this.setState({
                    mCheckIn: "",
                })
            }
        }
        else {
            this.setState({
                mCheckOut: newVal,
            })
            if (moment(newVal, 'MM/DD/YYYY', true).isValid()) {
                this.setState({
                    mCheckOut: "",
                })
                if (moment(newVal) >= moment(period_start)) {
                    this.props.handlePeriodDateChange(moment(newVal), 'period_end', 'price')
                }
                else {
                    this.props.errors.period_end.show = true
                }
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            pricingCalendar: nextProps.propertyPricePeriods
        });

        if(nextProps.editEntirePricePeriod !== this.props.editEntirePricePeriod || nextProps.pricingModal !== this.props.pricingModal ) {
            this.setState({
                mCheckIn: "",
                mCheckOut: ""
            });
        };
    }
    
    render() {
        let modal = this.props.pricingModal;
        let item = this.props.getPropertyById(modal.property_id)[0];
        let errors = this.props.errors;
        let errorMessage = '';
        let isDisabled = false;
        const smartPricingEnabled = 
            (item && item.smart_pricing_enabled) ?
            true : 
            false;
       const smartPricingdata = modal.priceOvr;

        if (this.state.mCheckIn === "" && this.state.mCheckOut === "") {
            isDisabled = false
        }
        else {
            isDisabled = true
        };

        Object.keys(errors).map(error => {
            if(errors[error].show) {
                isDisabled = true
                if (errors[error].type === '2') {
                    errorMessage = errors[error].message.replace(/"/g,'')
                }   
            }
        })

        return (
            <Modal 
                className={`calendar-modal pricing-modal ${smartPricingEnabled ? "override-price" : ""}`} 
                backdrop={false} 
                bsClass='modal' 
                id='pricingModal' 
                show={modal.show} 
                onHide={e=>this.props.hidePricingModal()}
            >
                <Modal.Header closeButton>
                    {
                        smartPricingEnabled &&
                        <Modal.Title componentClass='h3' className="ovr-price-title">Price Override</Modal.Title>
                    }
                    {
                        modal.property_id ?
                        <Modal.Title componentClass='h3'>#{modal.property_id}<br/>{item && item.content && item.content.title}</Modal.Title> :
                        <Modal.Title componentClass='h3'>Add Pricing</Modal.Title>
                    }
                    {
                        modal.property_id ?
                        <p className='property-desc'>{item && item.content && item.content.location && item.content.location.address && item.content.location.address.street_address} {item && item.content && item.content.location && item.content.location.address && item.content.location.address.street_address2}</p>:''
                    }
                </Modal.Header>
                <Modal.Body>
                    <Grid fluid={true}>
                        <Row>
                            {
                                smartPricingEnabled &&
                                <Col md={12} className="bottom-margin-20">
                                    <InputField
                                        cssClass="form-check-label checkbox-label"
                                        type="checkbox"
                                        value={modal.minPriceOnly}
                                        cbChange={this.props.toggleMinPriceOnly}
                                    >
                                        Only Modify Minimum Stay
                                    </InputField>
                                </Col>
                            }
                            {
                                (this.props.admin) &&
                                (modal.mode === 1) && 
                                !this.props.oneDayPricePeriod &&
                                modal.view !== "list" &&
                                !smartPricingEnabled &&
                                <Col md={12} className="bottom-margin-20">
                                    <InputField
                                        cssClass="form-check-label checkbox-label"
                                        type="checkbox"
                                        value={this.props.editEntirePricePeriod}
                                        cbChange={this.props.toggleEntirePricePeriod}
                                    >
                                        Edit Entire Price Period
                                    </InputField>
                                </Col>
                            }
                            {
                                !smartPricingEnabled &&
                                <Col xs={12}>
                                    <InputLabel>
                                        Please enter dates in <strong>mm/dd/yyyy</strong> format.
                                    </InputLabel>
                                </Col>
                            }
                            <Fragment>
                                <Col xs={6}>Start (Night of Check-In)</Col>
                                <Col xs={6}>End (Last Night of Stay)</Col>
                                <Col xs={12} className="bottom-margin-5">
                                    {
                                        item && 
                                        <div className="date-picker-calendar-group ">
                                            <DateRangePicker 
                                                startDate={modal.pricingDates.startDate}
                                                startDateId='start'
                                                endDate={modal.pricingDates.endDate}
                                                endDateId='end'
                                                focusedInput={this.state.focusedInput}
                                                onFocusChange={focusedInput => this.setState({ focusedInput })}
                                                onDatesChange={e => this.handleDatesChange(e, smartPricingEnabled)}
                                                noBorder
                                                startDatePlaceholderText="Check-In"
                                                endDatePlaceholderText="Check-Out"
                                                minimumNights={0}
                                                isDayHighlighted={this.propertyPricePeriods}
                                                disabled={!this.props.admin}
                                            />
                                        </div>
                                    }
                                </Col>
                            </Fragment>
                        </Row>
                        {
                            //** Smart Pricing */
                            smartPricingEnabled ?
                            <Fragment>
                                {
                                    !modal.minPriceOnly &&
                                    <Fragment>
                                        <Row>
                                            <div className="parallel-radio-container">
                                                {this.props.createDefaultRadioButton(this.ovrLabel, modal.priceOvrSource, this.props.handleSmartPricingSource, true)}
                                            </div>
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={12} className="top-margin-10 bottom-margin-5">
                                                Price: $ {modal && modal.data && modal.data.weekday_price}
                                            </Col>
                                            <Col xs={12} sm={12}>
                                                <FieldWrap>
                                                    {
                                                        modal.priceOvrSource == "price_ovr" ?
                                                        <Fragment>
                                                            <InputLabel htmlFor='weekendPrice'>Price Override</InputLabel>
                                                            <InputField 
                                                                disabled={!this.props.admin} 
                                                                onChange={e=>this.props.handleSmartPricingChange(e)} 
                                                                value={smartPricingdata.price || ""} 
                                                                type='number'
                                                                name='price'
                                                            />
                                                        </Fragment>
                                                        :
                                                        <Fragment>
                                                            <InputLabel htmlFor='weekendPrice'>Minimum Price</InputLabel>
                                                            <InputField 
                                                                disabled={!this.props.admin} 
                                                                onChange={e=>this.props.handleSmartPricingChange(e)} 
                                                                value={smartPricingdata.min_price ||""} 
                                                                type='number'
                                                                name='min_price'
                                                            />
                                                        </Fragment>
                                                    }
                                                </FieldWrap>
                                            </Col>
                                        </Row>
                                    </Fragment>
                                }
                                <Row>
                                    <Col xs={12} sm={12} className="top-margin-20 bottom-margin-5">
                                        Min # of Nights: {modal && modal.data && modal.data.minstay}
                                    </Col>
                                    <Col xs={12} sm={12}>
                                        <FieldWrap>
                                            <InputLabel htmlFor='minstay'>Min # of Nights Override</InputLabel>
                                            <InputField 
                                                disabled={!this.props.admin} 
                                                onChange={e=>this.props.handleSmartPricingChange(e)} 
                                                value={smartPricingdata.min_stay}
                                                type='number'
                                                name='min_stay'
                                            />
                                        </FieldWrap>
                                    </Col>
                                </Row>
                            </Fragment>
                            :
                            <Fragment>
                                <Row>
                                    <Col xs={12} sm={6}>
                                        <FieldWrap>
                                            <InputLabel htmlFor='weekendPrice'>Weekend Price</InputLabel>
                                            <InputField 
                                                disabled={!this.props.admin} 
                                                onChange={e=>this.props.handlePeriodAttrChange(e,'weekend_price')} 
                                                value={modal.data.weekend_price} 
                                                type='number' 
                                                id='weekendPrice'
                                                placeholder="0.00" 
                                            />
                                            <span 
                                                className={`error-msg ${errors.weekend_price.show?'':'hide'}`}
                                            >
                                                {errors.weekend_price.message}
                                            </span>
                                        </FieldWrap>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <FieldWrap>
                                            <InputLabel htmlFor='weekdayPrice'>Weekday Price</InputLabel>
                                            <InputField 
                                                disabled={!this.props.admin} 
                                                onChange={e=>this.props.handlePeriodAttrChange(e,'weekday_price')}
                                                value={modal.data.weekday_price}
                                                type='number' 
                                                id='weekdayPrice' 
                                                placeholder="0.00"
                                            />
                                            <span 
                                                className={`error-msg ${errors.weekday_price.show?'':'hide'}`}
                                            >
                                            {errors.weekday_price.message}
                                            </span>
                                        </FieldWrap>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={6}>
                                        <FieldWrap>
                                            <InputLabel htmlFor='weeklyPrice'>$/Day Weekly Price</InputLabel>
                                            <InputField 
                                                disabled={!this.props.admin} 
                                                onChange={e=>this.props.handlePeriodAttrChange(e,'weekly_price')}
                                                value={modal.data.weekly_price}
                                                type='number' 
                                                id='weeklyPrice'
                                                placeholder="0.00"
                                            />
                                            <span 
                                                className={`error-msg ${errors.weekly_price.show || errors.avg_price.show ? '' : 'hide'}`}
                                            >
                                                {errors.weekly_price.show ? errors.weekly_price.message : errors.avg_price.show ? errors.avg_price.message : ""}
                                            </span>
                                        </FieldWrap>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <FieldWrap>
                                            <InputLabel htmlFor='monthly_price'>$/Day Monthly Price</InputLabel>
                                            <InputField 
                                                disabled={!this.props.admin} 
                                                onChange={e=>this.props.handlePeriodAttrChange(e,'monthly_price')}
                                                value={modal.data.monthly_price}
                                                type='number' 
                                                id='monthly_price'
                                                placeholder="0.00"
                                            />
                                            <span 
                                                className={`error-msg ${errors.monthly_price.show || errors.avg_price.show ? '' : 'hide'}`}
                                            >
                                                {errors.monthly_price.show ? errors.monthly_price.message : ""}
                                            </span>
                                        </FieldWrap>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={6}>
                                        <FieldWrap>
                                            <InputLabel htmlFor='minstay'>Minimum # of Nights</InputLabel>
                                            <InputField 
                                                disabled={!this.props.admin} 
                                                onChange={e=>this.props.handlePeriodAttrChange(e,'minstay')} 
                                                value={modal.data.minstay}
                                                type='number' 
                                                id='minstay'
                                                placeholder="0"
                                            />
                                            <span 
                                                className={`error-msg ${errors.minstay.show?'':'hide'}`}
                                            >
                                            {errors.minstay.message}
                                            </span>
                                        </FieldWrap>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <FieldWrap>
                                            <InputLabel htmlFor='minstay'>Maximum # of Nights</InputLabel>
                                            <InputField 
                                                disabled={!this.props.admin} 
                                                onChange={e=>this.props.handlePeriodAttrChange(e,'maxstay')} 
                                                value={modal.data.maxstay == null ? '' : modal.data.maxstay}
                                                type='number' 
                                                id='maxstay'
                                                placeholder="0"
                                            />
                                        </FieldWrap>
                                    </Col>
                                </Row>
                            </Fragment>
                        }
                        {
                            this.props.admin && 
                            <Row className='top-margin-20'>
                                {
                                    modal.mode ?
                                    <Col xs={6}>
                                        <button 
                                            onClick={
                                                smartPricingEnabled ?
                                                e => this.props.deletePricingOvr(e)
                                                :
                                                e => this.props.deletePricing(e, item)
                                            }
                                            className='btn-delete'
                                        >
                                            Delete {smartPricingEnabled ? "Override" : "Pricing"}
                                            <span className={'glyphicon glyphicon-refresh'+(this.props.isDeleting ? ' spinning':' hide')}>
                                            </span>
                                        </button>
                                    </Col> :
                                    ''
                                }
                                <Col xs={12} sm={modal.mode ? 6 : 12}>
                                    <PrimaryButton 
                                        disabled={isDisabled} 
                                        cssClass={modal.mode? 'black-btn ':''} 
                                        type='button' 
                                        onClick={
                                            smartPricingEnabled ?
                                            e => this.props.submitPricingOvr(e)
                                            :
                                            e => this.props.submitPricing(e, item)
                                        } 
                                        fullWidth={true}
                                    >
                                        {modal.mode ? 'Update': 'Add'} Price
                                        <span className={'glyphicon glyphicon-refresh'+(this.props.loading ? ' spinning':' hide')}>
                                        </span>
                                    </PrimaryButton>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col xs={12}>
                                <span className={errorMessage ?'error-msg':'hide'}>{errorMessage}</span>
                            </Col>
                        </Row>
                    </Grid>
                </Modal.Body>
            </Modal>
        )
    }
}