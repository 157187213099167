import React, { Component, Fragment } from 'react';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import Link from 'react-router-dom/Link';
import { API } from 'aws-amplify';

import Loader from '../global/Loader';
import _ from "lodash";
import moment from 'moment';
import PanelGroup from 'react-bootstrap/lib/PanelGroup';
import Panel from 'react-bootstrap/lib/Panel';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';

import Pdf from '../../assets/icons/icon-pdf.png';
import File from '../../assets/icons/icon-file.png';
import ImageFile from '../../assets/icons/icon-image-file.png';
import Docs from '../../assets/icons/icon-docs.png';
import Sheets from '../../assets/icons/icon-sheets.png'

class SupportTickets extends Component {
    casePriority = [
        {value: 'P3', label: 'low'},
        {value: 'P2', label: 'medium'},
        {value: 'P1', label: 'high'},
    ]
    freshDeskPriority = [
        {value: '1', label: 'low'},
        {value: '2', label: 'medium'},
        {value: '3', label: 'high'},
        {value: '4', label: 'urgent'},
    ];
    status = [
        {value: "", label: "Unknown"},
        {value: "2", label: "Open"},
        {value: "3", label: "Pending"},
        {value: "4", label: "Resolved"},
        {value: "8", label: "In Progress-PM"},
        {value: "6", label: "In Progress- Guest"},
        {value: "12", label: "In Progress- RA"},
        {value: "13", label: "In Progress- Channel"},
        {value: "5", label: "Closed"},
    ]
    state = {
        isLoading: true,
        activeKey: '',
        ticketsList: [],
    };

    async componentDidMount() {
        if(this.props.level !== 'search' && this.props.level !== 'reviews') {
            await this.getFreshdeskTickets();
        };

        this.setState({
            isLoading: false
        });
    }

    getFreshdeskTickets = async () => {
        let resp;
        let url = '';
        let qsLevel = '';

        switch(this.props.type) {
            case 'freshdesk':
                url = `/freshdesk/tickets`;
                break;
            case 'case':
                url = `/organizations/${this.props.org}/cases`;
                break;
        };

        switch(this.props.level) {
            case 'property':
                qsLevel = `/?property_id=${this.props.propertyId}`;
                break;
            case 'reservation':
                qsLevel = `/?reservation_id=${this.props.reservationId}`;
                break;
        };

        try {
            resp = await API.get("pomapi", `${url}${qsLevel}`);

            let arr = _.isArray(resp) ? [...resp] : [resp];

            this.setState({
                ticketsList: arr
            });
        }
        catch(err) {
            console.log(err)
        };
    }

    handlePanelOnSelect = (key) => {
        this.setState({
            activeKey: key,
        });
    }

    getTooltip = (id, text) => {
        return <Tooltip id={id} className='properties__tooltip'>{text}</Tooltip>
    }

    render() {
        const {
            isLoading,
            activeKey,
        } = this.state;
        const ticketsList = this.props.ticketsList || this.state.ticketsList;

        return(
            <div className='support-tickets'>
                {
                    !ticketsList.length &&
                    <div className='support-loader'>
                        {
                            isLoading && <Loader/>
                        }
                        {
                            isLoading ? 'Loading...' : (this.props.level == 'search' ? (this.props.init ? '' : 'Sorry, there were no results with your search criteria') : 'No Support Tickets Found')
                        }
                    </div>
                }
                {
                    !isLoading &&
                    <PanelGroup 
                        accordion id="accordion-controlled-example"
                        activeKey={activeKey}
                        onSelect={(key) => this.handlePanelOnSelect(key)}
                    >
                        {
                            ticketsList.map((ticket, index) => {
                                const priority = (this.props.type == 'freshdesk' ? this.freshDeskPriority : this.casePriority).find((x) => x.value == _.get(ticket, 'priority', ''));
                                const find_status = this.status.find((x) => x.value == _.get(ticket, 'status'));
                                const status = this.props.type == 'freshdesk' ? _.get(find_status, 'label') : _.get(ticket, 'state');
                                const escalated = `${_.get(ticket, 'is_escalted') ? '' : 'Not '}Escalated`;
                                const hostOutreach = `${_.get(ticket, 'reached_out_to_host') ? '' : 'No '}Host Outreach`;
                                const survey = `Survey ${_.get(ticket, 'do_not_send_survey') ? 'Enabled' : 'Disabled '}`;
                                const description = _.get(ticket, 'description') || _.get(ticket, 'description_text') || "";
                                const created = this.props.type == 'freshdesk' ? _.get(ticket, 'created_at') : _.get(ticket, 'created_dt');
                                const updated = this.props.type == 'freshdesk' ? _.get(ticket, 'updated_at') : _.get(ticket, 'updated_dt');
                                const url = /^(https?:\/\/)/.test(_.get(ticket, 'url', '')) ? new URL(_.get(ticket, 'url', '')) : '';
                                const baseUrl = url.origin;
                                
                                return (
                                    <Panel key={index + 1} eventKey={index + 1}>
                                        <Panel.Heading>
                                            <Panel.Title
                                                toggle
                                            >
                                                <div className='panel-title-container'>
                                                    <div className='panel-subject'>
                                                        <div>{ticket.subject}</div>
                                                        <div className='display-flex'>
                                                            <div className='create-date'>Created: {moment(created).fromNow()}</div>
                                                            {
                                                                this.props.type == 'freshdesk' &&
                                                                <div className='due-date'>&nbsp; &#8226; &nbsp; Due: {moment(ticket.due_by).fromNow()}</div>
                                                            } 
                                                        </div>
                                                    </div>
                                                    <div className='panel-status'>
                                                        <div className='capitalize'>{status}</div>
                                                    </div>
                                                    <div className='panel-priority'>
                                                        <div className={`right-margin-5 circle ${_.get(priority, 'label')}`}>
                                                        </div>
                                                        <div className='capitalize'>{_.get(priority, 'label')}</div>
                                                    </div>
                                                    <div>
                                                        <div 
                                                            className={`panel-chevron ${activeKey === (index + 1) ? 'glyphicon glyphicon-chevron-up': 'glyphicon glyphicon-chevron-down'}`}>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Panel.Title>
                                        </Panel.Heading>
                                        <Panel.Body collapsible>
                                            <Col md={12}>
                                                <Col md={5} sm={12}>
                                                    <div>
                                                        {
                                                            this.props.type == 'freshdesk' ?
                                                            <Fragment>
                                                                <strong>Contact:</strong> {_.get(ticket, 'contact_type')}
                                                            </Fragment>
                                                            :
                                                            <Fragment>
                                                                <strong>Assigned To:</strong> {_.get(ticket, 'assigned_to_username')}
                                                            </Fragment>
                                                        }
                                                    </div>
                                                    <div>
                                                        <strong>Type:</strong> {this.props.type == 'freshdesk' ? _.get(ticket, 'type') : _.get(ticket, 'case_type')}
                                                    </div>
                                                    <div>
                                                        <strong className='right-margin-5'>Status:</strong>
                                                        <span className='capitalize'>{status}</span>
                                                    </div>
                                                    <div>
                                                        <strong>{this.props.type == 'freshdesk' ? 'Ticket': 'Case'} ID:</strong> {_.get(ticket, 'id')}
                                                    </div>
                                                    {
                                                        _.get(ticket, 'property_id') &&
                                                        <div>
                                                            <strong>Property ID:</strong>
                                                            &nbsp;
                                                            <Link
                                                                className='clickable-text'
                                                                target='_blank'
                                                                to={{ pathname: `/properties/${_.get(ticket, 'property_id')}/edit`}} 
                                                            >
                                                                {_.get(ticket, 'property_id')} 
                                                            </Link>
                                                        </div>
                                                    }
                                                    {   
                                                        _.get(ticket, 'reservation_id') &&
                                                        <div>
                                                            <strong>Reservation ID:</strong>
                                                            &nbsp;
                                                            <Link
                                                                className='clickable-text'
                                                                target='_blank' 
                                                                to={{ pathname: `/reservations/${_.get(ticket, 'reservation_id', '')}/details`}} 
                                                            >
                                                                {_.get(ticket, 'reservation_id')} 
                                                            </Link>
                                                        </div>
                                                    }
                                                    <br/>
                                                    <div>
                                                        <strong>Created:</strong> {moment(created).format("MM/DD/YYYY hh:mm A")}
                                                    </div>
                                                    <div>
                                                        <strong>Updated:</strong> {moment(updated).fromNow()}
                                                    </div>
                                                    <br/>
                                                    {
                                                        this.props.type == 'freshdesk' &&
                                                        <Fragment>
                                                            <div>
                                                                <strong>Due:</strong> {moment(ticket.due_by).format("MM/DD/YYYY hh:mm A")}
                                                            </div>
                                                            <div>
                                                                <strong>First Response Due:</strong> {moment(ticket.fr_due_by).format("MM/DD/YYYY hh:mm A")}
                                                            </div>
                                                        </Fragment>
                                                    }
                                                    <br/>
                                                    {
                                                        this.props.type == 'freshdesk' &&
                                                        <Fragment>
                                                            <h2>STATUS FLAGS</h2>
                                                            <div className='status-flags'>
                                                                <div>
                                                                    <i className={_.get(ticket, 'is_escalted') ? 'icon-checkbox_check' : 'icon-cross'}></i>
                                                                    {escalated}
                                                                </div>
                                                                <div>
                                                                    <i className={_.get(ticket, 'reached_out_to_host') ? 'icon-checkbox_check' : 'icon-cross'}></i>
                                                                    {hostOutreach}
                                                                </div>
                                                                <div>
                                                                    <i className={_.get(ticket, 'do_not_send_survey') ? 'icon-checkbox_check' : 'icon-cross'}></i>
                                                                    {survey}
                                                                </div>
                                                            </div>
                                                            <br/>
                                                            <h2>CONTACT INFO</h2>
                                                            <div>
                                                                <strong>Requestor ID:</strong> {_.get(ticket, 'requester_id')}
                                                            </div>
                                                        </Fragment>
                                                    }
                                                </Col>
                                                <Col md={7} sm={12} className='right-panel'>
                                                    <div className='panel-description'>
                                                        <strong>Description:</strong>
                                                        <div dangerouslySetInnerHTML={{ __html: description }}></div>
                                                    </div>
                                                    <hr/>
                                                    {
                                                        this.props.type == 'freshdesk' ?
                                                        <Fragment>
                                                        <Row>
                                                            <Col md={12} sm={12}>
                                                                <strong>Attachments:</strong>
                                                                {
                                                                    _.get(ticket, 'attachments.length') > 0 ?
                                                                    <div className='attachments margin-top'>
                                                                        {
                                                                            _.get(ticket, 'attachments').map((attachment, i) => {
                                                                                let imgSrc = File;

                                                                                if(_.get(attachment, 'content_type').includes('pdf')) {
                                                                                    imgSrc = Pdf;
                                                                                }
                                                                                else if(_.get(attachment, 'content_type').includes('xls') || _.get(attachment, 'content_type').includes('xlm')) {
                                                                                    imgSrc = Sheets;
                                                                                }
                                                                                else if(_.get(attachment, 'content_type').includes('txt') || _.get(attachment, 'content_type').includes('doc')) {
                                                                                    imgSrc = Docs;
                                                                                }
                                                                                else if(_.get(attachment, 'content_type').includes('image') || _.get(attachment, 'content_type').includes('png') || _.get(attachment, 'content_type').includes('jpeg')) {
                                                                                    imgSrc = ImageFile;
                                                                                };

                                                                                return (
                                                                                    <Col xs={6} sm={6} key={i}>
                                                                                        <OverlayTrigger 
                                                                                            placement='top' 
                                                                                            overlay={this.getTooltip('filename', `${_.get(attachment, 'name')}`)} 
                                                                                        >
                                                                                            <div className="attach-border">
                                                                                                <a
                                                                                                    className="attach-container"
                                                                                                    href={_.get(attachment, 'attachment_url')}
                                                                                                    target="_blank"
                                                                                                >
                                                                                                    <div className="attach-icon-container">
                                                                                                        <img src={imgSrc}></img>
                                                                                                    </div>
                                                                                                    <div className='attach-file-name'>
                                                                                                        {_.get(attachment, 'name.length') > 15 ? _.get(attachment, 'name').substring(0, 15) + "..." : _.get(attachment, 'name')}
                                                                                                    </div>
                                                                                                </a>
                                                                                            </div>
                                                                                        </OverlayTrigger>
                                                                                    </Col>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                    :
                                                                    <div>No Attachments (0)</div>
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <br/>
                                                        <Row>
                                                            <Col md={12} sm={12} className='panel-cc-email'>
                                                                <strong>CC Emails:</strong>
                                                                {
                                                                    _.get(ticket, 'cc_emails.length') ?
                                                                    <ul>
                                                                        {
                                                                            _.get(ticket, 'cc_emails', []).map((email) => {
                                                                                return (
                                                                                    <li>
                                                                                        <a className='clickable-text' href={`mailto:${email}`}>{email}</a>
                                                                                    </li>
                                                                                )
                                                                            })
                                                                        }
                                                                    </ul>
                                                                    :
                                                                    <div>No CC recipients (0)</div>
                                                                }
                                                            </Col>
                                                        </Row>
                                                        </Fragment>
                                                        :
                                                        <Row>
                                                            <Col md={12} sm={12}>
                                                                <strong>{this.props.type == 'freshdesk' ? 'Ticket': 'Case'} Overview:</strong>
                                                                {
                                                                    <div>
                                                                        <a className='clickable-text' target="_blank" href={`${url}`}>{baseUrl}</a>
                                                                    </div>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    }
                                                </Col>
                                            </Col>
                                        </Panel.Body>
                                    </Panel>
                                )
                            })
                        }
                        
                    </PanelGroup>
                }
            </div>
        )
    }
}
export default SupportTickets;