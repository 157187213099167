import React, { Component, Fragment } from 'react';
import { API } from 'aws-amplify';

import { withRouter } from 'react-router';

import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import PageLayout from '../global/PageLayout';
import { Title } from '../global/Title';
import { InputLabel, InputField, PrimaryButton } from '../global/forms/FormElements';
import Loader from '../global/Loader';

class ReportProblemReservation extends Component {
    reservationId = this.props.match.params.reservation_id;
    breadcrumbItems = [
        {
            title: "Reservations",
            link: "/reservations"
        },
        {
            title: `Reservation ${this.reservationId} Details`,
            link: `/reservations/${this.reservationId}/details`
        },
        {
            title: "Report a Problem"
        }
    ];
    RESERVATION_ISSUES = [
        {
            label: "I have a concern about rates or minimum stay requirements",
            value: "I have a concern about rates or minimum stay requirements"
        },
        {
            label: "I have an issue with availability",
            value: "I have an issue with availability"
        },
        {
            label: "I need to request a cancellation",
            value: "I need to request a cancellation"
        },
    ];
    SUCCESS_MESSAGES = {
        "I have a concern about rates or minimum stay requirements": "Thank you for reporting an issue with your rates.  An account representative will be reaching out shortly to discuss rates and make any necessary changes for future reservations.",
        "I have an issue with availability" : "Thank you for reporting an issue with your availability.  Please note that per your contract reservations coming from the RedAwning network supersede any personal stays or other reservations that were not entered prior to this booking.  An account representative will be reaching out shortly to discuss your calendar as well as reservation acceptance.",
        "I need to request a cancellation" : "Your request for cancellation has been received. A member of our team will be reaching out to go over final details as well as make final determination as to the status of the reservation."
    }
    state = {
        org: "",
        roles: "",
        showModal: "",
        data: {
            problem_report: "",
            detail_reason: "",
            alternative_nid: "",
            alternative_nid_link: ""
        },
        isLoading: false,
        formSucess: false,
        errMessage: '',
    }

    submitForm = async () => {
        const { 
            data
        } = this.state;
        let dataCopy = {...data};

        if(dataCopy.alternative_nid) {
            if(dataCopy.alternative_nid == "2") {
                dataCopy.alternative_nid = 0;
            };
            dataCopy.alternative_nid = parseInt(dataCopy.alternative_nid);
        };

        this.setState({
            isLoading: true,
            errMessage: '',
        });

        try {
            await API.post('rapapi', `/properties/reservations/${this.reservationId}/report-problem`, { body: dataCopy });

            this.setState({
                formSucess: true,
                isLoading: false
            });
        }
        catch(e) {
            let errMessage = 
                (e && e.response && e.response.data && e.response.data.Message)||
                "Something went wrong. Please contact Customer Service."
            this.setState({
                isLoading: false,
                errMessage: errMessage,
            });
        };
    }

	createRadioButton = (labels, state) => {
		return (
			labels.map((label, i) => (
			<Row key={i}>
				<label
					id={label["name"]}
					className="radio-wrap radio-input"
				>
					<input
						type="radio" 
						className="form-radio-label radio-label"
						name={label["name"]}  
						onChange={e => this.handleState(e)}
						defaultChecked={state === label.value ? (label.value === "" ? " " : label.value) : null}
						value={label['value']}
					/>
				<div
					className="radio-label"
					style={{marginLeft: "35px"}}
				>
					{label["title"]}
				</div>
				</label>
			</Row>
		))
	)}

    handleState = (e) => {
        const { 
            data,
            errMessage
        } = this.state;
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            data: {
                ...data,
                [name]: value     
            }
        }, () => {
            if(errMessage.length) {
                this.setState({
                    errMessage: ''
                });
            }
        });
    }

    handleRoute = () => {
        this.props.history.push(`/reservations/${this.reservationId}/details`);
    }

    render() {
        const {
            isLoading,
            formSucess,
            errMessage,
            data
        } = this.state;

        const altPropertyAvail = [
			{ name: "alternative_nid", value: "1", title: "Yes" },
			{ name: "alternative_nid", value: "2", title: "No" },
		];

        let problems = [];
		this.RESERVATION_ISSUES.map(issue => {
			problems.push(<option key={issue.value} value={issue.value}>{issue.label}</option>)
		});

        return (
            <PageLayout isCentered={true}>
                <Title 
                    title='Report a Problem' 
                    isCentered={true}
                    breadcrumbItems={this.breadcrumbItems}
                >
                </Title>
                {
                    isLoading && <Loader/>
                }
                <div id='content' className='report-problem-reservation'>
                    <Grid fluid={true}>
                        <Col xs={12} className='main-container'>
                            {
                                !formSucess ?
                                <Fragment>
                                <Row>
                                    <Col xs={12} className='title'>
                                        <h1>Report a Problem</h1>
                                        <h2>Reservation {this.reservationId}</h2>
                                    </Col>
                                    <Col xs={12} className='description'>
                                        Thank you for hosting with RedAwning.com.  
                                        Please note that RedAwning is constantly working to get your property fully booked.  
                                        As such <span className="emphasize">all reservations you are sent are automatically confirmed and accepted;</span> &nbsp;
                                        this means that your guests have already received a booking confirmation and are expecting to stay at your property.  
                                        Please give us a little more information on the issue you are reporting:
                                    </Col>
                                    <Col xs={12} className='bottom-margin-20'>
                                        <InputField 
                                            type="select"
                                            value={data.problem_report}
                                            name="problem_report"
                                            onChange={e => this.handleState(e)}
                                        >
                                            <option value="" disabled></option>
                                            {problems}
                                        </InputField>
                                    </Col>
                                    <Col xs={12} className='description'>
                                        Please consider all requests to cancel carefully.  
                                        Canceling a confirmed reservation WILL impact your performance not only on the channel the booking was initiated from but also on all channels within the RedAwning network.  
                                        Per your contract you will be responsible for relocation fees incurred by RedAwning or the channel for accommodating these guests. 
                                    </Col>
                                    <Col xs={12} className='description'>
                                        If rate, minimum stay or availability issues result in a need for the host to cancel the reservation, a $250 reservation cancellation fee will be charged to the host.
                                    </Col>
                                </Row>
                                {
                                    data.problem_report === 'I need to request a cancellation' &&
                                    <Row>
                                        <Col xs={12} className='bottom-margin'>
                                            <InputLabel 
                                                htmlFor="detail_reason"
                                            >
                                                Cancellation Reason:
                                            </InputLabel>
                                            <InputField
                                                cssClass="text-area"
                                                type="textarea"
                                                value={data.detail_reason}
                                                name="detail_reason"
                                                onChange={e => this.handleState(e)}
                                            >
                                            </InputField>
                                        </Col>
                                        <Col xs={12} className='bottom-margin'>
                                            <InputLabel 
                                                htmlFor="alternative"
                                            >
                                                Do you have an alternative property available to offer for the same rate?
                                            </InputLabel>
                                            {this.createRadioButton(altPropertyAvail, data.alternative_nid)}
                                        </Col>
                                        {
                                            data.alternative_nid === '1' &&
                                            <Fragment>
                                                <Col xs={12} className='bottom-margin-30'>
                                                    <InputLabel 
                                                        htmlFor="alternative_nid_link"
                                                    >
                                                        Please provide a link or RedAwning NID to the alternative property:
                                                    </InputLabel>
                                                    <InputField 
                                                        type="text"
                                                        value={data.alternative_nid_link}
                                                        name="alternative_nid_link"
                                                        onChange={e => this.handleState(e)}
                                                    >
                                                    </InputField>
                                                </Col>
                                            </Fragment>
                                        }
                                        {
                                            data.alternative_nid === '2' &&
                                            <Fragment>
                                                <Col xs={12} className='bottom-margin'>
                                                    ** Please note that the RedAwning team will do our best to relocate the guest - any fees incurred will be passed on to you.
                                                </Col>
                                            </Fragment>
                                        }
                                        <Col xs={12} className='bottom-margin-30'>
                                            <span>** Please note this form is only a request to cancel a booking. A member of our team will be reaching out to go over final details as well as make a final determination as to the status of the reservation. If we do end up cancelling the booking, a $250 fee may apply, in addition to potential relocation costs.</span>
                                        </Col>
                                    </Row>
                                }
                                {
                                    errMessage &&
                                    <Row className="flex-align-center bottom-margin">
                                        <span className='center-content required-error-text'>{errMessage}</span>
                                    </Row>
                                }
                                <Row className="flex-align-center">
                                    <Col>
                                        <PrimaryButton 
                                            cssClass="col-md-12 " 
                                            type="button"
                                            onClick={this.submitForm}
                                            disabled={
                                                !data.problem_report.length ||
                                                (data.problem_report === 'I need to request a cancellation' &&
                                                (!data.detail_reason.length ||
                                                ((!data.alternative_nid || data.alternative_nid === '1') && !data.alternative_nid_link.length)))
                                            }
                                        >
                                            Submit
                                        </PrimaryButton>
                                    </Col>
                                </Row>
                                </Fragment>
                                :
                                <Fragment>
                                    <Row className='bottom-margin-20'>
                                        <Col xs={12} className='title'>
                                            <h1>Report a Problem</h1>
                                            <h2>Reservation {this.reservationId}</h2>
                                        </Col>
                                        <Col xs={12} className='description center-content'>
                                            {this.SUCCESS_MESSAGES[data.problem_report]}
                                        </Col>
                                    </Row>
                                    <Row className="flex-align-center">
                                    <Col>
                                        <PrimaryButton 
                                            cssClass="col-md-12 " 
                                            type="button"
                                            onClick={this.handleRoute}
                                        >
                                            Return to Reservation
                                        </PrimaryButton>
                                    </Col>
                                </Row>
                                </Fragment>
                            }
                        </Col>
                    </Grid>
                </div>
            </PageLayout>
        )
    }
}

export default withRouter(ReportProblemReservation);