import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Modal from 'react-bootstrap/lib/Modal';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import { InputField, PrimaryButton, InputLabel } from '../global/forms/FormElements';
import { API } from 'aws-amplify';
import Pagination from 'react-js-pagination';
import Picky from 'react-picky';
import Loader from '../global/Loader';
import _ from "lodash";
import moment from 'moment';
import PanelGroup from 'react-bootstrap/lib/PanelGroup';
import Panel from 'react-bootstrap/lib/Panel';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import ErrorModal from '../global/forms/ErrorModal';
import { 
    getSessionId,
    suggestReplyBtn
} from '../../helpers/helpers';
import SupportTickets from './SupportTickets';

class Reviews extends Component {
    modalMsg = '';
    errorReplyMsg = "There was an issue sending your reply. Please contact customer support for assistance.";
    errorChangeStatusMsg = "There was an issue changing the status to 'No Response'. Please contact customer support for assistance.";
    getReviewsErrorMsg = "We encountered an error while retrieving the reviews. Please try again shortly."
    reviewsRatingsOptions = [
        {value: '', label: 'Show All Ratings'},
        {value: 5, label: '5 Star'},
        {value: 4, label: '4 Star'},
        {value: 3, label: '3 Star'},
        {value: 2, label: '2 Star'},
        {value: 1, label: '1 Star'},
    ];
    initState = {
        isLoading: true,
        modal: '',
        createSupportTicketModal: false,
        tid: getSessionId(),
        reviewsList: [],
        reviewsRatingsOptionsValue: {
            value: '', 
            label: 'Show All Ratings'
        },
        reviewsStatusFilters: {
            no_response: true,
            pending: true,
            complete: true,
        },
        reviewsActiveKey: '',
        reviewsActivePage: 1,
        reviewsPageSize: 10,
        reviewsTotalCount: 0,
        reviewsSorted: {
            name: 'Review Date',
            id: 'review_date',
            desc: true
        },
        reviewsSummary: '',
        keyword: '',
        reviewsAverageRating: '',
        pendingReviews: this.props.pendingReviews || '',
        suggestedReplies: [],
        suggestedRepliesCurrentIndex: 0,
        isSuggestedReplyLoading: false,
        getReviewsErrorMsg: '',
        supportTicketErrorMsg: '',
        supportTicketSuccess: false,
        supportTicketIsLoading: false,
        ticketsList: [],
        supportTicketData: {
            subject: '',
            description: '',
            reservation_id: '',
            review_id: '',
        }
    }
    state = {
        ...this.initState,
    }

    init = async () => {
        const { 
            reviewsRatingsOptionsValue,
            reviewsStatusFilters
        } = this.state;
        const itemName = this.props.view == 'dashboard' ? 'db-reviews' : 'prop-reviews';

        const params = JSON.parse(sessionStorage.getItem(itemName));

        if(params) {
            this.setState({
                reviewsRatingsOptionsValue: params.ratingsFilters || reviewsRatingsOptionsValue,
                reviewsStatusFilters: params.statusFilters || reviewsStatusFilters,
            }, async () => {
                await this.getReviews();

                if(this.props.view == 'property') {
                    await this.getSummary();
                };

                this.setState({
                    isLoading: false
                });
            });
        }
        else {
            await this.getReviews();

            this.setState({
                isLoading: false
            });
        };
    }

    setSessionStorage = () => {
        const { 
            reviewsRatingsOptionsValue,
            reviewsStatusFilters,
        } = this.state;
        const itemName = this.props.view == 'dashboard' ? 'db-reviews' : 'prop-reviews';

        const pagination = {
            ratingsFilters: reviewsRatingsOptionsValue,
            statusFilters: reviewsStatusFilters
        };

        sessionStorage.setItem(itemName, JSON.stringify(pagination));
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(_.get(prevProps, 'org') != _.get(this.props, 'org')) {
            this.setState({
                ...this.initState,
                tid: getSessionId()
            }, () => {
                this.init();
            });
        };
    }

    componentWillUnmount() {
        this.setSessionStorage();
    }

    calculateAverageRating = (ratings) => {
        const total = ratings.reduce((acc, rating) => acc + rating, 0);
        return total / ratings.length;
    };

    getFreshdeskTickets = async (review) => {
        let resp;
        let url = `/organizations/${_.get(review, 'organization_name')}/cases`;
        let qsLevel = `/?reservation_id=${_.get(review, 'reservation_id')}`;

        try {
            resp = await API.get("pomapi", `${url}${qsLevel}`);

            let arr = _.isArray(resp) ? [...resp] : [resp];

            this.setState({
                ticketsList: arr
            });
        }
        catch(err) {
            this.setState({
                ticketsList: []
            });
        };
    }

    getSummary = async () => {
        let resp;

        try {
            resp = await API.get("gbapi", `/properties/${this.props.id}/commdata/review_summary`, { responseType: "text", response: true });
            if(_.get(resp, 'data')) {
                this.setState({
                    reviewsSummary: resp.data,
                });
            };
        }
        catch(err) {
            console.log(err)
        };
    }

    getReviews = async () => {
        const {
            tid,
            keyword,
            reviewsStatusFilters,
            reviewsRatingsOptionsValue,
            reviewsPageSize,
            reviewsActivePage,
            reviewsSorted
        } = this.state;
        const activePage = ((reviewsActivePage == 0) ? 0 : (reviewsActivePage - 1));
        const ratingsFilter = _.get(reviewsRatingsOptionsValue, 'value') ? `&ratings=${reviewsRatingsOptionsValue.value}` : '';
        const statusKeys = _.keys(_.pickBy(reviewsStatusFilters, Boolean));
        const statusQueryString = statusKeys.length > 0 ? `&response_status=${statusKeys.join(',')}` : '';
        const offset = reviewsPageSize * activePage;
        const sortDesc = reviewsSorted.desc ? "-" : "+";
        const sort = `&sort=${reviewsSorted.id}${encodeURIComponent(sortDesc)}`
        const keywordQueryString = keyword.length ? (_.isFinite(Number(keyword)) ? `&reservation_id=${encodeURIComponent(keyword)}` : `&guest_name=${encodeURIComponent(keyword)}`) : '';
        const guestName = keyword.length ? `&guest_name=${encodeURIComponent(keyword)}` : '';
        const url = {
            property: `/properties/${this.props.id}/reviews?tid=${tid}&limit=${reviewsPageSize}&offset=${offset}${ratingsFilter}${sort}${keywordQueryString}`,
            dashboard: `/organizations/${this.props.org}/reviews?tid=${tid}&limit=${reviewsPageSize}&offset=${offset}&include_suborgs=true${ratingsFilter}${sort}${keywordQueryString}`,
            reservation: `/organizations/${this.props.org}/reviews?reservation_id=${this.props.id}&tid=${tid}&limit=${reviewsPageSize}&offset=${offset}${ratingsFilter}${sort}${guestName}`
        };
        let resp;

        this.setState({
            getReviewsErrorMsg: '',
        });

		try { 
			resp = await API.get("rapapi", `${url[this.props.view]}${statusQueryString}`, { response: true });
            if(_.get(resp, 'data.length')) {
                let ratingsList = [];
                let averageRating = '';

                resp.data.forEach((review) => {
                    if(_.get(review, 'rating')) {
                        ratingsList.push(review.rating);
                    };    
                });

                if(ratingsList.length) {
                    averageRating = this.calculateAverageRating(ratingsList);
                };

                this.setState({
                    reviewsActiveKey: '',
                    reviewsActivePage: activePage + 1,
                    reviewsList: resp.data,
                    pendingReviews: _.get(resp, 'headers.pending_response_count') || '',
                    reviewsTotalCount: resp.headers['x-total-count'],
                    reviewsAverageRating: averageRating
                });
            }
            else {
                this.setState({
                    reviewsActiveKey: '',
                    reviewsActivePage: 1,
                    reviewsList: [],
                });
            };

            this.setSessionStorage();
		}
        catch(err) {
            const errorMsg = _.get(err.response, 'data.Message', '');
            let msg = this.getReviewsErrorMsg;

            if(errorMsg) {
                msg = errorMsg.replace(/^\('\s*|\',\s*-1\)$/g, '');
            };

            this.setState({
                getReviewsErrorMsg: msg,
            });
		};
    }

    handleFormSubmit = async (e, changeStatusToNoResp) => {
        e.preventDefault();
        const { 
            selectedReview,
            reviewsList,
            reviewsActiveKey
        } = this.state;
        let resp;
        const fullName = `${this.props.user.contact_info.first_name ? `${this.props.user.contact_info.first_name} ` : ''}${this.props.user.contact_info.last_name}`;
        const emailAdd = this.props.user.contact_info.email;
        let body = {};

        if(changeStatusToNoResp) {
            body = {
                state: selectedReview.state,
                response_status: 'no_response',
            };
        }
        else {
            body = {
                ...selectedReview.response,
                reviewer: {
                    name: fullName,
                    email: emailAdd
                }
            };
        };

        this.setState({
            isLoading: true
        });

        try {
            resp = await API[changeStatusToNoResp ? 'put' : 'post']("rapapi", `/properties/${selectedReview.property_id}/reviews/${selectedReview.review_id}`, { body: body });

            let newSelectedReview = {};

            if(changeStatusToNoResp) {
                newSelectedReview = {
                    ...selectedReview,
                    response_status: 'no_response',
                };
            }
            else {
                newSelectedReview = {
                    ...selectedReview,
                    response_status: 'complete',
                    response: {
                        ...resp
                    }
                };
            }
            let copyReviewsList = _.cloneDeep(reviewsList);
            
            reviewsList.forEach((review, i) => {
                if(selectedReview.review_id == review.review_id) {
                    copyReviewsList[i] = newSelectedReview;
                };
            });
            this.setState({
                reviewsList: copyReviewsList,
                selectedReview: newSelectedReview,
                reviewsActiveKey: changeStatusToNoResp ? '' : reviewsActiveKey,
                isLoading: false
            }, () => {
                if(this.props.view == 'dashboard') {
                    this.props.getPendingReviews();
                };
            });
        }
        catch(e) {
            this.modalMsg = changeStatusToNoResp ? this.errorChangeStatusMsg : this.errorReplyMsg;
            this.setState({
                modal: 'error-modal',
                isLoading: false
            });
        }
    }

    getSuggestedReply = async (e, init) => {
        e && e.preventDefault();
        const { selectedReview } = this.state;

        this.setState({
            isSuggestedReplyLoading: true
        });

        let body = {
            message: selectedReview.comment,
            count: 3,
            metadata: {
                review_id: selectedReview.review_id
            }
        };

        if(selectedReview.property_id) {
            body.metadata.property_id = selectedReview.property_id;
        };

        if(selectedReview.reservation_id) {
            body.metadata.reservation_id = selectedReview.reservation_id;
        };

        try {
            const resp = await API.post('cbapi', `/message-generation/review`, { body: body });

            if(_.get(resp, 'replies')) {
                if(init) {
                    this.setState({
                        suggestedReplies: resp.replies,
                        selectedReview: {
                            ...selectedReview,
                            response: {
                                ...selectedReview.response,
                                comment: resp.replies[0]
                            }
                        },
                        isSuggestedReplyLoading: false
                    });
                }
                else {
                    const { suggestedReplies } = this.state;

                    this.setState({
                        suggestedReplies: [...suggestedReplies ,...resp.replies],
                        isSuggestedReplyLoading: false
                    });
                }
            };
        }
        catch(err) {
            const errorMsg = _.get(err.response, 'data.Message', '');
            let msg = 'We encountered an error while generating your message. Please try again shortly.'

            if(errorMsg) {
                msg = errorMsg.replace(/^\('\s*|\',\s*-1\)$/g, '');
            };

            this.modalMsg = msg;
            this.setState({
                modal: 'error-modal',
                isSuggestedReplyLoading: false
            });
        };
    }

    renderStarsReview = (rating) => {
        return [1,2,3,4,5].map((star) => {
            return <i key={star} className={`icon-star-full ${star <= rating ? 'fill': ''}`}></i>
        })
    }

    navigate = (e, url, data) => {
        if(e) {
            e.stopPropagation();
        };
 
        const newWindow = window.open(url, '_blank');

        if(data) {
            newWindow.data = data;
        };
    };

    getTooltip = (id, text) => {
        return <Tooltip id={id}>{text}</Tooltip>
    }

    handlePanelOnSelect = (key) => {
        const index = key - 1;

        this.setState({
            suggestedReplies: [],
            suggestedRepliesCurrentIndex: 0,
        }, async () => {
            const { reviewsList } = this.state;

            this.setState({ 
                isLoading: true
            });
    
            if(key !== null) {
                await this.getFreshdeskTickets(reviewsList[index]);
            };

            this.setState({ 
                isLoading: false,
                selectedReview: reviewsList[index],
                reviewsActiveKey: key,
            });
        });
    }

    handleCommentOnChange = (e) => {
        const { selectedReview } = this.state;
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            selectedReview: {
                ...selectedReview,
                response: {
                    ...selectedReview.response,
                    [name]: value
                }
            }
        });
    }

    handlePageOnChange = (page) => {
        this.setState({
            reviewsActiveKey: '',
            reviewsActivePage: page
        }, () => {
            this.fetchReviews();
        });
    }

    handlePageSizeOnChange = (pageSize) => {
        this.setState({
            reviewsPageSize: pageSize,
            reviewsActivePage: 1,
            reviewsActiveKey: '',
        }, () => {
            this.fetchReviews();
        });
    }

    handleSortOnChange = (e, name, id) => {
        e.preventDefault();
        const { reviewsSorted } = this.state;
        let sort =  reviewsSorted.id == id ? !reviewsSorted.desc : false;

        this.setState({
            reviewsActiveKey: '',
            reviewsActivePage: 1,
            reviewsSorted: {
                ...reviewsSorted,
                name: name,
                id: id,
                desc: sort
            }
        }, () => {
            this.fetchReviews();
        });
    }

    handleFilterRatings = (value) => {
        this.setState({
            reviewsRatingsOptionsValue: value
        }, () => {
            this.fetchReviews();
        });
    }

    handleStatusFilters = (name) => {
        const { reviewsStatusFilters } = this.state;

        this.setState({
            reviewsStatusFilters: {
                ...reviewsStatusFilters,
                [name]: !reviewsStatusFilters[name]
            },
        }, () => {
            this.fetchReviews();
        });
    }

    handleSearchSubmit = (e) => {
        e.preventDefault();

        this.setState({
            reviewsActivePage: 1,
            reviewsActiveKey: '',
        }, () => {
            this.fetchReviews();
        });
    }

    reloadReviews = () => {
        this.setState({
            reviewsActivePage: 1,
            reviewsActiveKey: '',
            tid: getSessionId()
        }, () => {
            this.fetchReviews();
        });
    }

    handleSearchOnChange = (e) => {
        e.preventDefault();
        const value = e.target.value;

        this.setState({
            keyword: value
        });
    }

    renderTableHeader = (name, id) => {
        const { reviewsSorted } = this.state;

        return(
            <div 
                className={`sort-container${reviewsSorted.id == id ? (reviewsSorted.desc ? ' -sort-desc' : ' -sort-asc') : ''}`}
                onClick={(e) => this.handleSortOnChange(e, name, id)}
            >
                <span>{name} &nbsp;</span>
                <span>
                    <i className="icon-dwon_arrow"></i>
                    <i className="icon-up-arrow"></i>
                </span>
            </div>
        )
    }

    responseSelector = async (e, direction) => {
        e.preventDefault();
        const {
            suggestedRepliesCurrentIndex
        } = this.state;
        let index = suggestedRepliesCurrentIndex;

        if(direction == 'foward') {
            index++;
        }
        else if(direction == 'back') {
            index--;
        };

        if(index > (this.state.suggestedReplies.length - 1)) {
            await this.getSuggestedReply();
        };

        this.setState({
            suggestedRepliesCurrentIndex: index,
            selectedReview: {
                ...this.state.selectedReview,
                response: {
                    ...this.state.selectedReview.response,
                    comment: this.state.suggestedReplies[index]
                }
            },
        })
    }

    changeSourceName = (sourceName) => {
        let name = sourceName;

        if(sourceName === 'homeaway') {
            name = 'VRBO'
        };

        return name;
    }

    handleCreateSupportTicketModal = (e, bool) => {
        const {
            selectedReview,
            supportTicketData
        } = this.state;
        e && e.preventDefault();

        this.setState({
            createSupportTicketModal: bool,
            supportTicketData: {
                ...supportTicketData,
                review_id: _.get(selectedReview, 'review_id', ''),
                reservation_id: _.get(selectedReview, 'reservation_id', ''),
                subject: `Review Case ID ${_.get(selectedReview, 'review_id', '') || ''}`,
                description: `${_.get(selectedReview, 'reviewer.name', '') ? `${_.get(selectedReview, 'reviewer.name')}'s` : ''} Review: ${_.get(selectedReview, 'comment', '') || ''}`,
            }
        }, () => {
            if(!bool) {
                this.setState({
                    supportTicketErrorMsg: '',
                    supportTicketSuccess: false,
                    supportTicketData: {
                        subject: '',
                        description: '',
                        review_id: '',
                        reservation_id: '',
                    }
                });
            }
        })
    }

    handleSupportTicketModal = (name) => {
        this.setState({
            modal: name
        })
    }

    handleSupportTicketOnChange = (e) => {
        const { supportTicketData }  = this.state;
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            supportTicketData: {
                ...supportTicketData,
                [name]: value
            }
        })
    }

    submitSupportTicket = async () => {
        const {
            supportTicketData
        } = this.state;
        let body = {
            ...supportTicketData,
        };

        this.setState({
            supportTicketIsLoading: true
        });

		try { 
			await API.post("pomapi", `/organizations/${this.props.org}/cases`, { body: body });

            this.setState({
                supportTicketSuccess: true,
                supportTicketIsLoading: false
            });
		}
        catch(err) {
            const errorMsg = _.get(err.response, 'data.Message', '');
            let msg = "We encountered an error while sending your support ticket. Please try again shortly.";

            if(errorMsg) {
                msg = errorMsg.replace(/^\('\s*|\',\s*-1\)$/g, '');
            };

            this.setState({
                supportTicketErrorMsg: msg,
                supportTicketIsLoading: false
            });
		};
    }

    renderReviewsPanal = () => {
        const {
            reviewsList,
            reviewsActiveKey,
            reviewsPageSize,
            reviewsActivePage,
            reviewsTotalCount,
            selectedReview,
            isSuggestedReplyLoading,
            suggestedReplies,
            suggestedRepliesCurrentIndex,
            ticketsList
        } = this.state;

        return (
            <Col md={12} className='top-margin panel-reviews'>
                <div className='reviews-th'>
                    <div className='reviews-col'>
                        {this.renderTableHeader('Ratings', 'ratings')}
                    </div>
                    <div className='reviews-col'> 
                        {this.renderTableHeader('Property Name', 'property_title')}
                    </div>
                    <div className='reviews-col'> 
                        {this.renderTableHeader('Guest Name', 'guest_name')}
                    </div>
                    <div className='reviews-col'>
                        {this.renderTableHeader('Visit Date', 'visit_date')}
                    </div>
                    <div className='reviews-col'>
                        {this.renderTableHeader('Review Date', 'review_date')}
                    </div>
                    <div className='reviews-col'>
                        {this.renderTableHeader('Status', 'response_status')}
                    </div>
                    <div className='reviews-col'></div>
                </div>
                <PanelGroup 
                    accordion id="accordion-controlled-example"
                    activeKey={reviewsActiveKey}
                    onSelect={(key) => this.handlePanelOnSelect(key)}
                >
                    {
                        reviewsList.map((review, index) => {
                            const status = review.response_status == 'no_response' ? 'No Response' : review.response_status;

                            return(
                                <Panel key={index + 1} eventKey={index + 1}>
                                    <Panel.Heading>
                                        <Panel.Title
                                            toggle
                                        >
                                            <div className='reviews-tr'>
                                                <div className='reviews-col'>{this.renderStarsReview(review.rating)}</div>
                                                <div className='reviews-col'>{review.property_title}</div>
                                                <div className='reviews-col'>{_.get(review, 'reviewer.name')}</div>
                                                <div className='reviews-col'>{moment(review.visit_date).isValid() ? moment(review.visit_date).format('MM-DD-YYYY') : ''}</div>
                                                <div className='reviews-col'>{moment(review.created).isValid() ? moment(review.created).format("MM/DD/YYYY hh:mm A") : ''}</div>
                                                <div className='reviews-col capitalize'>{status}</div>
                                                <div className='reviews-col'>
                                                    <div 
                                                        className={`panel-chevron ${reviewsActiveKey === (index + 1) ? 'glyphicon glyphicon-chevron-up': 'glyphicon glyphicon-chevron-down'}`}>
                                                    </div>
                                                </div>
                                            </div>
                                        </Panel.Title>
                                    </Panel.Heading>
                                    <Panel.Body collapsible>
                                        {
                                            _.get(selectedReview, 'review_id') &&
                                            <Fragment>
                                                <Col md={12}>
                                                    <div className='reviews-tr-custom'>
                                                        <div className='reviews-col'>
                                                            <div className='small-text'>Reviewer Source</div>
                                                            <div className='capitalize'>{this.changeSourceName(selectedReview.source)}</div>
                                                        </div>
                                                        <div className='reviews-col'>
                                                            <div className='small-text'>Organization Name</div>
                                                            <div>{selectedReview.organization_name}</div>
                                                        </div>
                                                        <div className='reviews-col'>
                                                            <div className='small-text'>Review ID</div>
                                                            <div>{selectedReview.review_id}</div>
                                                        </div>
                                                        <div className='reviews-col'>
                                                            <div className='small-text'>Reservation ID</div>
                                                            {
                                                                selectedReview.reservation_id &&
                                                                <div className='display-flex'>
                                                                    <span 
                                                                        className='clickable-text'
                                                                        onClick={(e) => {
                                                                            this.navigate(e, `/reservations/${selectedReview.reservation_id}/details`);
                                                                        }}
                                                                    >
                                                                        {review.reservation_id}
                                                                    </span>
                                                                    <OverlayTrigger 
                                                                        placement='top' 
                                                                        overlay={this.getTooltip('goTo', `Navigate to Reservation ${selectedReview.reservation_id}`)} 
                                                                    >
                                                                        <span
                                                                            className='flex-align-items-center'
                                                                            onClick={(e) => {
                                                                                this.navigate(e, `/reservations/${selectedReview.reservation_id}/details`);
                                                                            }}
                                                                        >
                                                                            <i className='icon-open-new-tab'></i>
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </div> 
                                                            }
                                                        </div>
                                                        <div className='reviews-col'>
                                                            <div className='small-text'>Property ID</div>
                                                            {
                                                                selectedReview.property_id &&
                                                                <div className='display-flex'>
                                                                    <span 
                                                                        className='clickable-text'
                                                                        onClick={(e) => {
                                                                            this.navigate(e, `/properties/${selectedReview.property_id}/edit`, { scrollTo: 'reviews'});
                                                                        }}
                                                                    >
                                                                        {selectedReview.property_id}
                                                                    </span>
                                                                    <OverlayTrigger 
                                                                        placement='top' 
                                                                        overlay={this.getTooltip('goTo', `Navigate to Property ${selectedReview.property_id}`)} 
                                                                    >
                                                                        <span
                                                                            className='flex-align-items-center'
                                                                            onClick={(e) => {
                                                                                this.navigate(e, `/properties/${selectedReview.property_id}/edit`);
                                                                            }}
                                                                        >
                                                                            <i className='icon-open-new-tab'></i>
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </div> 
                                                            }
                                                        </div>
                                                        <div className='reviews-col'>
                                                            <div className='small-text'>Guests</div>
                                                            <div>{selectedReview.number_of_guests}</div> 
                                                        </div>
                                                        <div className='reviews-col'>
                                                            <div className='small-text'>Nights</div>
                                                            <div>{selectedReview.number_of_nights}</div> 
                                                        </div>
                                                        {
                                                            _.get(selectedReview, 'response.response_id', '') &&
                                                            <div className='reviews-col'>
                                                                <div className='small-text'>Response Date</div>
                                                                <div>{moment(selectedReview.response.created).isValid() ? moment(selectedReview.response.created).format('MM/DD/YYYY hh:mm A') : ''}</div> 
                                                            </div>
                                                        }
                                                    </div>
                                                </Col>
                                                <Col md={12} className='reviews-reply'>
                                                    {
                                                        _.get(selectedReview, 'reviewer.name') &&
                                                        <div className='top-margin reviews-reply-title'>
                                                            {`${_.get(selectedReview, 'reviewer.name')}'s Review:`}
                                                        </div>
                                                    }
                                                    <div className='reviews-bubble'>
                                                        {selectedReview.comment}
                                                    </div>
                                                </Col>
                                                {
                                                    _.get(selectedReview, 'private_comment', '') &&
                                                    <Col md={12} className='reviews-reply'>
                                                        <div className='reviews-reply-title'>
                                                            Private Message:
                                                        </div>
                                                        <div className='reviews-bubble'>
                                                            {_.get(selectedReview, 'private_comment', '')}
                                                        </div>
                                                    </Col>
                                                }
                                                {
                                                    _.get(review, 'response_status', '') !== 'no_response' &&
                                                    <Col md={12}>
                                                        <div className='flex-align-right top-margin'>
                                                            {
                                                                !_.get(selectedReview, 'response.response_id', '') ?
                                                                <Fragment>
                                                                    <div className='reviews-reply'>
                                                                        <div className='reviews-reply-title no_pad'>
                                                                            {`Reply to ${_.get(selectedReview, 'reviewer.name')}'s Review:`}
                                                                            {suggestReplyBtn(suggestedReplies, suggestedRepliesCurrentIndex, isSuggestedReplyLoading, this.getSuggestedReply, this.responseSelector)}
                                                                        </div>
                                                                        <InputField
                                                                            type="textarea"
                                                                            name="comment"
                                                                            value={_.get(selectedReview, 'response.comment', '')}
                                                                            onChange={e =>this.handleCommentOnChange(e)}
                                                                        />
                                                                    </div>
                                                                </Fragment>
                                                                :
                                                                <Fragment>
                                                                    <div className='reviews-reply full-width'>
                                                                        {
                                                                            _.get(selectedReview, 'response.reviewer.name') &&
                                                                            <div className='reviews-reply-title'>
                                                                                {`${_.get(selectedReview, 'response.reviewer.name')}'s Reply:`}
                                                                            </div>
                                                                        }
                                                                        <div className='reviews-bubble'>
                                                                            {_.get(selectedReview, 'response.comment', '')}
                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            }
                                                        </div>
                                                    </Col>
                                                }
                                                <Col md={12} className='flex-align-right top-margin'>
                                                    {
                                                        this.props.permissions.includes('create_crm_cases') &&
                                                        <PrimaryButton
                                                            cssClass='white-btn right-margin'
                                                            fullWidth={false} 
                                                            type="button" 
                                                            onClick={(e) => this.handleCreateSupportTicketModal(e, true)}
                                                        >
                                                            Create Support Ticket
                                                        </PrimaryButton>
                                                    }
                                                    {
                                                        (ticketsList.length > 0 &&
                                                        _.get(selectedReview, 'reservation_id') &&
                                                        this.props.permissions.includes('create_crm_cases')) &&
                                                        <PrimaryButton
                                                            cssClass='white-btn right-margin'
                                                            fullWidth={false} 
                                                            type="button" 
                                                            onClick={() => this.handleSupportTicketModal('support-ticket-modal')}
                                                        >
                                                            <div className='flex-align-items-center'>
                                                                <i className='icon-headset right-margin-5'></i>
                                                                <div><strong>{`${ticketsList.length} Ticket${ticketsList.length > 1 ? 's' : ''}`}</strong></div>
                                                            </div>
                                                        </PrimaryButton>
                                                    }
                                                    {
                                                        (!_.get(selectedReview, 'response.response_id', '') &&
                                                        _.get(review, 'response_status', '') !== 'no_response') &&
                                                        <Fragment>
                                                            <PrimaryButton
                                                                cssClass='white-btn right-margin'
                                                                fullWidth={false} 
                                                                type="button" 
                                                                onClick={(e) =>this.handleFormSubmit(e, 'changeStatusToNoResp')}
                                                            >
                                                                No Response
                                                            </PrimaryButton>
                                                            <PrimaryButton 
                                                                fullWidth={false} 
                                                                type="button" 
                                                                onClick={(e) =>this.handleFormSubmit(e)}
                                                                disabled={!_.get(selectedReview, 'response.comment.length')}
                                                            >
                                                                Send Response
                                                            </PrimaryButton>
                                                        </Fragment>
                                                    }
                                                </Col>
                                            </Fragment>
                                        }
                                    </Panel.Body>
                                </Panel>
                            )
                        })
                    }
                </PanelGroup>
                <div className="pagination-container">
                    <div className="units-info-container">
                        {reviewsTotalCount} &nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;
                    </div>
                    <div className="page_size-input-container">
                        <div 
                            className='page-size' 
                        >
                            <InputField
                                className="pageSize"
                                type='number' 
                                name='pageSize'
                                autoComplete="off"
                                defaultValue={reviewsPageSize}
                                onKeyUp={(e) => {
                                    let value = e.target.value;

                                    if(value > 100) {
                                        value = 100;
                                    }
                                    else if(value < 0) {
                                        value = 1;
                                    };
                                    if(value.length && e.keyCode === 13 && value !== 0 && Number.isInteger(Number(value))) {
                                        this.handlePageSizeOnChange(value)
                                    };
                                }}
                            /> 
                            / page
                        </div>
                    </div>
                    <Pagination
                        activePage={reviewsActivePage}
                        hideFirstLastPages={false}
                        prevPageText={"Prev"}
                        nextPageText={"Next"}
                        itemsCountPerPage={parseInt(reviewsPageSize)}
                        totalItemsCount={parseInt(reviewsTotalCount)}
                        onChange={(page) => this.handlePageOnChange(page)}
                    />
                </div>
            </Col>
        )
    }

    closeModal = () => {
        this.setState({
            modal: ''
        });
    }

    fetchReviews = async () => {

        this.setState({ isLoading: true });
                                                    
        await this.getReviews();

        this.setState({ isLoading: false });
    }

    render() {
        const {
            modal,
            keyword,
            isLoading,
            reviewsList,
            reviewsAverageRating,
            reviewsRatingsOptionsValue,
            reviewsStatusFilters,
            pendingReviews,
            reviewsTotalCount,
            getReviewsErrorMsg,
            reviewsSummary,
            selectedReview,
            supportTicketData,
            supportTicketErrorMsg,
            supportTicketSuccess,
            supportTicketIsLoading,
            createSupportTicketModal,
            ticketsList
        } = this.state;

        return (
            <Col md={12} sm={12} className="reviews">
                {
                    (this.props.dashboardTabs == 'reviews' || this.props.view !== 'dashboard') &&
                    <Fragment>

                        {isLoading && <Loader/>}
                        
                        <Col md={12} sm={12} className="custom-container">
                            {
                                reviewsSummary &&
                                <Row className='top-margin bottom-margin'>
                                    <Col md={12} sm={12}>
                                        Summary:
                                    </Col>
                                    <Col md={6} sm={12}>
                                        <div className='reviews-summary'>
                                            {reviewsSummary}
                                        </div>
                                    </Col>
                                </Row>
                            }
                            <Row>
                                <Col md={12} sm={12} className='reviews-header'>
                                    <div className='reviews-total-container'>
                                        <div className='total'>{reviewsTotalCount}</div>
                                        <div>Reviews</div>
                                    </div>
                                    <div className='average-rating-container'>
                                        <div>Average Rating</div>
                                        <div className='display-flex'>
                                            {this.renderStarsReview(reviewsAverageRating)}
                                        </div>
                                    </div>
                                    <div className='reviews-total-open-container'>
                                        <div 
                                            className={`total ${this.props. pendingReviews || pendingReviews ? 'highlight' : ''}`}
                                        >
                                            {this.props. pendingReviews || pendingReviews}
                                        </div>
                                        <div>Pending</div>
                                    </div>
                                    <div className='ratings-container'>
                                        <Picky
                                            placeholder="Filter Ratings"
                                            labelKey="label"
                                            valueKey="value"
                                            filterPlaceholder="Filter"
                                            options={this.reviewsRatingsOptions}
                                            value={reviewsRatingsOptionsValue}
                                            multiple={false}
                                            includeSelectAll={false}
                                            defaultFocusFilter={true}
                                            keepOpen={false}
                                            onChange={value => this.handleFilterRatings(value)}
                                            dropdownHeight={600}
                                            render={({
                                                style,
                                                item,
                                                isSelected,
                                                selectValue,
                                                labelKey,
                                                valueKey,
                                            }) => {
                                                return (
                                                    <li
                                                        style={{ ...style }} 
                                                        className={isSelected ? "selected" : ""} 
                                                        key={item[labelKey]}
                                                        onClick={() => selectValue(item)}
                                                    >
                                                    <span
                                                        style={{fontWeight: isSelected ? "bold" : "normal"}}
                                                    >
                                                        {item[labelKey]}{" "}{item[valueKey] && this.renderStarsReview(item[valueKey])}
                                                    </span>
                                                    </li>
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className='status-container'>
                                        <div className='status-label'>Status:</div>
                                        <div className="filters-wrap">
                                            <InputField 
                                                type="checkbox" 
                                                value={reviewsStatusFilters.no_response} 
                                                cbChange={() => this.handleStatusFilters("no_response")}>
                                                No Response
                                            </InputField>
                                        </div>
                                        <div className="filters-wrap">
                                            <InputField 
                                                type="checkbox" 
                                                value={reviewsStatusFilters.pending} 
                                                cbChange={() => this.handleStatusFilters("pending")}>
                                                Pending
                                            </InputField>
                                        </div>
                                        <div className="filters-wrap">
                                            <InputField 
                                                type="checkbox" 
                                                value={reviewsStatusFilters.complete} 
                                                cbChange={() => this.handleStatusFilters("complete")}>
                                                Complete
                                            </InputField>
                                        </div>
                                    </div>
                                    <form 
                                        className='search-box'
                                        onSubmit={e =>this.handleSearchSubmit(e)}
                                    >
                                        <InputField 
                                            type='search' 
                                            placeholder={`Search by ${this.props.view === 'reservation' ? 'Guest Name' : 'Reservation ID or Guest Name'}`}
                                            name='keyword'
                                            value={keyword}
                                            onChange={e => this.handleSearchOnChange(e)}
                                            handleClear={()=> {
                                                this.setState({
                                                    keyword: ''
                                                }, () => {
                                                    this.fetchReviews();
                                                });
                                            }} 
                                        />
                                    </form>
                                </Col>
                            </Row>
                            {
                                reviewsList.length ?
                                <Fragment>
                                    <Row>
                                        <Col md={12} sm={12} className='flex-align-right'>
                                            <div 
                                                className='clickable-text'
                                                onClick={this.reloadReviews}
                                            >
                                                Refresh Reviews
                                            </div>
                                        </Col>
                                        {this.renderReviewsPanal()}
                                    </Row>
                                </Fragment>
                                :
                                <Row>
                                    <Col md={12} sm={12} className='top-margin'>
                                        <div className={`no-data-found-container ${getReviewsErrorMsg ? 'error-text' : ''}`}>
                                            {
                                                getReviewsErrorMsg ? getReviewsErrorMsg : 'No reviews yet.'
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            }
                        </Col>
                    </Fragment>
                }
                <ErrorModal
                    modal={modal}
                    closeModal={this.closeModal}
                    msg={this.modalMsg}
                />
                <Modal 
                    show={createSupportTicketModal}
                    className='create-support-ticket-modal'
                    onHide={() => this.handleCreateSupportTicketModal('', false)}
                    backdrop='static'
                >

                    {supportTicketIsLoading && <Loader/>}

                    <Modal.Header closeButton>
                        {
                            !supportTicketSuccess &&
                            <h1>Create Support Ticket {`(ID ${_.get(selectedReview, 'review_id', '')})`}</h1>
                        }
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            {
                                supportTicketSuccess ?
                                <Fragment>
                                    <Col md={12} sm={12} className="flex-align-center top-margin bottom-margin-30">
                                        <span className='status-icon status-icon--success'></span>
                                        <h1>Support Ticket Created</h1>
                                    </Col>
                                    <Col md={12} sm={12} className='flex-align-right'>
                                        <PrimaryButton
                                            cssClass='white-btn'
                                            fullWidth={false} 
                                            type="button" 
                                            onClick={() => {
                                                this.setState({
                                                    createSupportTicketModal: false,
                                                    supportTicketSuccess: false,
                                                });
                                            }}
                                        >
                                            Close
                                        </PrimaryButton>                       
                                    </Col>
                                </Fragment>
                                :
                                <Fragment>
                                    <Col md={12} sm={12} className='bottom-margin'>
                                        <InputLabel htmlFor="subject"><strong>Subject:</strong></InputLabel>
                                        <InputField
                                            type="text"
                                            name="subject"
                                            value={_.get(supportTicketData, 'subject', '')}
                                            onChange={e =>this.handleSupportTicketOnChange(e)}
                                        />                            
                                    </Col>
                                    <Col md={12} sm={12} className='bottom-margin'>
                                        <InputLabel htmlFor="description"><strong>Description:</strong></InputLabel>
                                        <InputField
                                            type="textarea"
                                            name="description"
                                            value={_.get(supportTicketData, 'description', '')}
                                            onChange={e =>this.handleSupportTicketOnChange(e)}
                                        />                              
                                    </Col>
                                    <Col md={12} sm={12} className='flex-align-right'>
                                        <PrimaryButton
                                            cssClass='white-btn right-margin'
                                            fullWidth={false} 
                                            type="button" 
                                            onClick={(e) => this.handleCreateSupportTicketModal(e, false)}
                                        >
                                            Cancel
                                        </PrimaryButton>
                                        <PrimaryButton 
                                            fullWidth={false} 
                                            type="button" 
                                            onClick={(e) =>this.submitSupportTicket(e)}
                                            disabled={
                                                !_.get(supportTicketData, 'subject', '') ||
                                                !_.get(supportTicketData, 'description', '')
                                            }
                                        >
                                            Submit
                                        </PrimaryButton>                            
                                    </Col>
                                    {
                                        supportTicketErrorMsg &&
                                        <Col md={12} sm={12} className="top-margin flex-align-center">
                                            <div className='msg-container _error'>
                                                <span>{supportTicketErrorMsg}</span> 
                                            </div>                              
                                        </Col>
                                    }
                                </Fragment>
                            }
                            
                        </Row>
                    </Modal.Body>
                </Modal>
                <Modal 
                    show={modal == 'support-ticket-modal'}
                    className='support-ticket-modal'
                    onHide={() => this.handleSupportTicketModal('', '')}
                >
                   <Modal.Header closeButton>
                        <div className='modal-header-container'>
                            <h1>Support Tickets</h1>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <SupportTickets
                            type={'case'}
                            level={'reviews'}
                            org={this.props.org}
                            reservationId={_.get(selectedReview, 'reservation_id', '')}
                            ticketsList={ticketsList}
                        />
                    </Modal.Body> 
                </Modal>
            </Col>
        )
    }
}

const mapStateToProps = state => {
    return {
        permissions: state.authState.permissions,
        user: state.authState.user,
        org: state.roleManager.org,
    }
}

export default withRouter(connect(
    mapStateToProps,
    null
)(Reviews));