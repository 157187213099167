import React, { Component, Fragment} from 'react';
import NavLink from 'react-router-dom/NavLink';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
    setRole,
    updateUserPermissions,
    openOrganizationModal,
} from '../../js/actions';
import constants from '../../js/constants';
import { API } from 'aws-amplify';
import Loader from '../global/Loader';

class SideNavComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
            activeIndex: 10,
            isOpen:false,
            showAccountOptions: false,
            showReportOptions: false,
            isLoading: false,
        }
        this.isAccountViewer = (props.roles.indexOf(constants.USER_TYPES.ACCOUNT_MANAGER_VIEWER) >= 0) || false;
        this.hostSupport = (props.roles.indexOf(constants.USER_TYPES.HOST_SUPPORT) >= 0) || false;
        this.guestServices = (props.roles.indexOf(constants.USER_TYPES.GUEST_SERVICES) >= 0) || false;
        this.isSmartConciergeViewer = (props.roles.indexOf(constants.USER_TYPES.SMART_CONCIERGE_VIEWER) > -1) || false;
        this.isSmartConciergeAdmin = (props.roles.indexOf(constants.USER_TYPES.SMART_CONCIERGE_ADMIN) > -1) || false;
        this.permissions = props.permissions;
        this.reportOptionsCount = 0;
        this.finance = false;
        this.accountOption = 3;
        this.profileOption = 7;
        this.renderNavLinks = this.renderNavLinks.bind(this);
        this.collapseSideBarIn = this.collapseSideBarIn.bind(this);
        this.collapseSideBarOut = this.collapseSideBarOut.bind(this);
        this.logout = this.logout.bind(this);
        this.changeOrg = this.changeOrg.bind(this);
        this.links = {
            '/properties': {
                name: 'Properties',
                icon: 'icon-house-icon'
            },
            '/collections' : {
                name: 'Collections',
                icon: 'icon-collections'
            },
            '/calendar': {
                name: 'Calendar',
                icon: 'icon-Calender'
            },
            '/fees': {
                name: 'Fees Schedule',
                icon: 'icon-fees'
            },
            '/reservations': {
                name: 'Reservations',
                icon: 'icon-Reservations'
            },
            '/smart-concierge': {
                name: 'Smart Concierge',
                icon: 'icon-concierge'
            },
            '/manage-website': {
                name: 'Website',
                icon: 'icon-Website_icon'
            },
            '/cleaning': {
                name: 'Cleaning',
                icon: 'icon-sparkle-hand'
            }
        }
        this.name = `${props.user.contact_info.first_name || 'Account'} ${props.user.contact_info.last_name || ''}`
    }

    componentDidMount() {
        this.handlePermissions();
        this.handleFinanceRole();
    }

    componentDidUpdate(nextProps) {
        if(nextProps.orgName !== this.props.orgName) {
            this.isAccountViewer = (this.props.roles.indexOf(constants.USER_TYPES.ACCOUNT_MANAGER_VIEWER) >= 0) || false;
            this.hostSupport = (this.props.roles.indexOf(constants.USER_TYPES.HOST_SUPPORT) >= 0) || false;
            this.guestServices = (this.props.roles.indexOf(constants.USER_TYPES.GUEST_SERVICES) >= 0) || false;
            this.isSmartConciergeViewer = (this.props.roles.indexOf(constants.USER_TYPES.SMART_CONCIERGE_VIEWER) > -1) || false;
            this.isSmartConciergeAdmin = (this.props.roles.indexOf(constants.USER_TYPES.SMART_CONCIERGE_ADMIN) > -1) || false;

            this.getUserPermissions();
            this.handleFinanceRole();
        };
    }

    getUserPermissions = async () => {
        this.setState({
            isLoading: true
        });

        try {
            let resp = await API.get("accapi", `/currentuser/${this.props.orgName}/permissions`);
            if(_.get(resp, 'permissions.length')) {

                this.props.updateUserPermissions(resp.permissions);
                
                this.handlePermissions();
            };

            this.setState({
                isLoading: false,
            });
        }
        catch(err) {
            this.reportOptionsCount = reportOptionsCount;
            this.setState({
                isLoading: false,
                error: true,
            });
        }
    }

    handleFinanceRole = () => {
        const {
            user,
            roles,
        } = this.props;

        // If the user has a financial role in any organization, the role will be added to the current organization if it does not already exist.
        if(_.get(user, 'organization_roles.length')) {
            let financeRole = [];

            user.organization_roles.forEach((org) => {
                if(org && org.roles && org.roles.length) {
                    org.roles.forEach((role) => {
                        if(role == constants.USER_TYPES.FINANCE) {
                            financeRole.push(role);
                        };
                    });
                };
            });

            if(financeRole.length > 0) {
                if(!roles.includes(constants.USER_TYPES.FINANCE)) {
                    let rolesCopy = [...roles];

                    rolesCopy.push(constants.USER_TYPES.FINANCE);

                    this.props.setRole(rolesCopy);
                };
                this.finance = true;
            }
            else {
                this.finance = false;
            };
        };
    }

    handlePermissions = () => {
        const { permissions } = this.props;
        let reportOptionsCount = 0;

        if(_.isArray(permissions)) {
            const permissionsType = [
                'view_report_listing_availability',
                'view_report_listing_validations',
                'view_report_listing_channels',
                'view_payments'
            ];

            permissions.forEach((permission) => {
                if(permissionsType.includes(permission)) {
                    reportOptionsCount ++;
                };
            });
        };

        this.permissions = permissions;
        this.reportOptionsCount = reportOptionsCount;
    }

    /**
     * Toggle account option on side nav.
     */
    toggleOption(option){
        this.setState({
            [option]: !this.state[option],
            collapsed: true
        })
    }
    
    /**
     * For collapse side bar nav in side.
     */
    collapseSideBarIn(){
        this.setState({
            isOpen: true
        })
    }
    /**
     * For collapse side bar out side.
     */
    collapseSideBarOut(){
        this.setState({
            isOpen: false,
            collapsed:true,
            showAccountOptions: false,
            showReportOptions: false,
        })
    }
   
    /**
     * 
     * @param {*} index 
     * @param {*} e 
     */
    toggleClass(index, e) {
        this.setState({ activeIndex: index });
    };
    /**
     * Logout user.
     * @param {*} e 
     */
    async logout(e) {
        e.preventDefault();

        if(this.props.unsavedData) {
            this.props.history.push("/");
        }
        else {
            try {
                await this.props.onLogout();
                this.props.history.push("/");
            }
            catch(err) {
                console.log(err);
            };
        };

        if (typeof sessionStorage !== 'undefined') {
            const keys = Object.keys(sessionStorage);
            
            const sessionStorageItems = {};
            
            keys.forEach(key => {
                sessionStorageItems[key] = sessionStorage.getItem(key);
            });

            Object.keys(sessionStorageItems).forEach((key) => {
                if(key == 'org' || key == 'orgId' || key == 'orgTitle') {
                    return;
                }
                else {
                    sessionStorage.removeItem(key);
                };
            });
        };
    }

    /**
     * Rendar navigation link.
     */
    renderNavLinks() {
        const { roleManager } = this.props;

        if(roleManager.config) {
            return Object.keys(this.links).map((item,id) => {
                let linkDetails = roleManager.config[item] ? this.links[item]: null;

                if(linkDetails) {
                    return (
                        <li key={id}>
                            {   
                                item === '/smart-concierge' && 
                                (this.isSmartConciergeViewer === false && this.isSmartConciergeAdmin === false) ? '' 
                                :
                                <NavLink to={item} onClick={this.collapseSideBarOut}>
                                    <i className={linkDetails.icon}></i>
                                    <span>{linkDetails.name}</span>
                                </NavLink>
                            }
                        </li>
                    )
                }
            })
        }
    }

    /**
     * change organization modal open.
     * @param {*} e 
     */
    changeOrg(e) {
        sessionStorage.removeItem('reservations-listing');
        sessionStorage.removeItem('properties-listing');
        this.props.openOrganizationModal('change-org-modal');
        this.collapseSideBarOut()
    }

    navLinkContainer = () => {
        const height = window.innerHeight;

        return { 
            height: height - 90,
            overflowX: "hidden",
            overflowY: this.state.isOpen ? "scroll" : "hidden"
        }
    }

    render() {
        const {
            first_name,
            last_name,
        } = this.props.user.contact_info
        const {
            isOpen,
            showReportOptions,
            showAccountOptions,
            isLoading,
        } = this.state;




        return (
            <div className='side-nav'>
                {
                    isLoading && <Loader/>
                }
                <button 
                    className={`visible-xs navbar-toggle toggle-btn ${isOpen ?" expand" : "" }`} 
                    onClick={isOpen ? this.collapseSideBarOut : this.collapseSideBarIn}
                >
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                </button>
                <div 
                    onMouseEnter={this.collapseSideBarIn} 
                    onMouseLeave={this.collapseSideBarOut} 
                    className={`sidebar ${!isOpen ? 'collapsed': 'expand'}`}
                >
                    <div className="sidebar-wrap">
                        <div className="sidebar-header">
                            <div className="logo-wrap">
                                <div className="logo-container">
                                    <img src="/app/assets/logo-2x.png" alt="RedAwning"/>
                                    <span className="logo-subtitle">Smart Portal</span>
                                </div>
                            </div>
                        </div>
                        <div style={this.navLinkContainer()}>
                       {
                            (this.props.isInternalPropertyAdmin || this.hostSupport || this.guestServices || this.finance) &&
                            <Fragment>
                                <div className="org-wrap">
                                {
                                    isOpen ? 
                                    <div>
                                        <span className="sidebar-name-container">Account Management</span>
                                    </div> : 
                                    <span className="user-profile">A</span>
                                }
                                </div>
                                <ul className="list-unstyled components">
                                    {
                                        (_.includes(this.permissions, 'get_organization_list') ||
                                        _.includes(this.permissions, 'create_organization')) &&
                                        <Fragment>
                                            <li>
                                                <NavLink to='/account-management/organizations'>
                                                    <i className="icon-organization"></i>
                                                    <span>Organizations</span>
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to='/account-management/users'>
                                                    <i className="icon-users"></i>
                                                    <span>Users</span>
                                                </NavLink>
                                            </li>
                                        </Fragment>
                                    }
                                    {
                                        (this.props.isInternalPropertyAdmin || this.hostSupport || this.finance) &&
                                        <li>
                                            <NavLink to='/account-management/promotions'>
                                                <i className="icon-promotions"></i>
                                                <span>Promotions</span>
                                            </NavLink>
                                        </li>
                                    }
                                    {
                                        (this.props.isInternalPropertyAdmin || this.hostSupport || this.guestServices) &&
                                        <li>
                                            <NavLink to='/account-management/chat'>
                                                <i className="icon-eye-open"></i>
                                                <span>Chat <sup>AI</sup></span>
                                            </NavLink>
                                        </li>
                                    }
                                    {
                                        (
                                            _.includes(this.permissions, 'view_freshdesk_tickets') ||
                                            _.includes(this.permissions, 'view_crm_cases') ||
                                            _.includes(this.permissions, 'create_crm_cases') ||
                                            _.includes(this.permissions, 'create_edit_freshdesk_tickets')
                                        ) &&
                                        <li>
                                            <NavLink to='/account-management/support-tickets'>
                                                <i className="icon-headset"></i>
                                                <span>Support Tickets</span>
                                            </NavLink>
                                        </li>
                                    }
                                </ul>
                            </Fragment>
                        }
                        <div className="org-wrap">
                            {
                                isOpen ? 
                                <div 
                                    role="button" 
                                    onClick={this.changeOrg}
                                >
                                    <p className="org-label">Organization</p>
                                    <span className="org-name">{this.props.orgTitle ? this.props.orgTitle : this.props.orgName}</span>
                                    <span className="org-edit">Change</span>
                                </div> : 
                                <span className="user-profile">{this.props.orgTitle ? this.props.orgTitle.substr(0,1) : this.props.orgName.substr(0,1)}</span>
                            }
                        </div>
                            <ul className="list-unstyled components">
                                <li>
                                    <NavLink to={'/dashboard'} onClick={this.collapseSideBarOut}>
                                        <i className='icon-dashboard'></i>
                                        <span>Dashboard</span>
                                    </NavLink>
                                </li>
                                {this.renderNavLinks()}
                                {
                                    (this.isAccountViewer ||
                                    ((_.isArray(this.permissions)) && 
                                    (_.includes(this.permissions, 'view_report_listing_validations') ||
                                    _.includes(this.permissions, 'view_report_listing_channels') ||
                                    _.includes(this.permissions, 'view_payments') ||
                                    _.includes(this.permissions, 'view_report_listing_availability')))) &&
                                    <li>
                                        <NavLink 
                                            to='/reports' 
                                            className="cursor-pointer" 
                                            onClick={e => {
                                                e.preventDefault(); 
                                                this.toggleOption("showReportOptions")
                                            }}
                                        >
                                            <i className="glyphicon icon-documents"></i>
                                            <span>Reports</span>
                                            <span 
                                                className={`pull-right ${showReportOptions ? 'glyphicon glyphicon-chevron-up': 'glyphicon glyphicon-chevron-down'}`}>
                                            </span> 
                                        </NavLink>
                                        <div 
                                            id="navbar-collapse" 
                                            style={{transition: 'all 0.5s', height: showReportOptions ? (this.reportOptionsCount * 45) + 'px' : '0px', overflow:'hidden'}}
                                        >
                                            <div className="navbar-account-container">
                                                <ul className="nav navbar-nav sub-option">
                                                    {
                                                        _.includes(this.permissions, 'view_report_listing_validations') && 
                                                        <li>
                                                            <NavLink 
                                                                to='/reports/validations' 
                                                                exact
                                                            >
                                                                <i className="glyphicon icon-validation"></i>
                                                                <span>Validations</span>
                                                            </NavLink>
                                                        </li>
                                                    }
                                                    {
                                                        this.isAccountViewer &&
                                                        <li>
                                                            <NavLink 
                                                                to='/reports/performance' 
                                                                exact
                                                            >
                                                                <i className="glyphicon icon-spreadsheet"></i>
                                                                <span>Performance</span>
                                                            </NavLink>
                                                        </li>
                                                    }
                                                    {
                                                        _.includes(this.permissions, 'view_report_listing_channels') &&
                                                        <li>
                                                            <NavLink 
                                                                to='/reports/channels' 
                                                                exact
                                                            >
                                                                <i className="glyphicon icon-chart"></i>
                                                                <span>Channels</span>
                                                            </NavLink>
                                                        </li>
                                                    }
                                                    {
                                                        _.includes(this.permissions, 'view_payments') &&
                                                        <li>
                                                            <NavLink 
                                                                to='/reports/taxes' 
                                                                exact
                                                            >
                                                                <i className="glyphicon icon-tax"></i>
                                                                <span>Taxes</span>
                                                            </NavLink>
                                                        </li>
                                                    }
                                                    {
                                                        _.includes(this.permissions, 'view_report_listing_availability') &&
                                                        <li>
                                                            <NavLink 
                                                                to='/reports/availability' 
                                                                exact
                                                            >
                                                                <i className="glyphicon icon-percent"></i>
                                                                <span>Availability</span>
                                                            </NavLink>
                                                        </li>
                                                    }
                                                </ul>
                                            </div>
                                        </div>    
                                    </li>
                                }
                                <li>
                                    <NavLink 
                                        to='/account' 
                                        className="cursor-pointer"
                                        onClick={e => {e.preventDefault(); this.toggleOption("showAccountOptions")}}
                                    >
                                        <i className="icon-Settings_icon"></i>
                                        <span>Account</span>
                                        <span 
                                            className={`pull-right ${showAccountOptions ? 'glyphicon glyphicon-chevron-up': 'glyphicon glyphicon-chevron-down'}`}>
                                        </span> 
                                    </NavLink>
                                    <div 
                                        id="navbar-collapse" 
                                        style={{transition: 'all 0.5s', height: showAccountOptions ? (this.profileOption * 45) + 'px' : '0px', overflow:'hidden'}}
                                    >
                                        <div className="navbar-account-container">
                                            <ul className="nav navbar-nav sub-option">
                                                <li>
                                                    <NavLink to='/account/profile' exact>
                                                        {
                                                            first_name ? 
                                                            <span className="user-abbrev">{(first_name.substr(0,1) + (last_name ? last_name.substr(0,1) : ''))}</span> : 
                                                            <i className="glyphicon glyphicon-user"/>
                                                        }
                                                        <span>My Profile</span>
                                                    </NavLink>
                                                </li>
                                                {
                                                    (_.includes(this.permissions, 'get_organization_details') ||
                                                    _.includes(this.permissions, 'update_organization')) && 
                                                    <li>
                                                        <NavLink 
                                                            to={'/account/organization-details'} 
                                                            activeClassName="logout-active" 
                                                            exact
                                                        >
                                                            <i className="glyphicon icon-organization"></i>
                                                            <span>Organization Details</span>
                                                        </NavLink>
                                                    </li>
                                                }
                                                {
                                                    this.isAccountViewer && 
                                                    <li>
                                                        <NavLink 
                                                            to={'/account/users'} 
                                                            activeClassName="logout-active" 
                                                            exact
                                                        >
                                                            <i className="glyphicon icon-users"></i>
                                                            <span>Organization Users</span>
                                                        </NavLink>
                                                    </li>
                                                }
                                                {
                                                    (_.isArray(this.permissions) && 
                                                    (_.includes(this.permissions, 'view_payments') || 
                                                    _.includes(this.permissions, 'administer_payments'))) &&
                                                    <li>
                                                        <NavLink 
                                                            to={'/accounts/payments'} 
                                                            activeClassName="logout-active" 
                                                            exact
                                                        >
                                                            <i className="glyphicon glyphicon-credit-card"></i>
                                                            <span>Payments</span>
                                                        </NavLink>
                                                    </li>
                                                }
                                                <li>
                                                    <NavLink 
                                                        to={'/account/faq'} 
                                                        activeClassName="logout-active" 
                                                        exact
                                                    >
                                                        <i className="glyphicon icon-faq"></i>
                                                        <span>FAQ</span>
                                                    </NavLink>
                                                </li>
                                                {
                                                    APIConfig.ENV=="DEV" && 
                                                    <li>
                                                        <NavLink to='/api'>
                                                            <i className="glyphicon glyphicon-cloud-download"></i>
                                                            <span>API</span>
                                                        </NavLink>
                                                    </li>
                                                }
                                                <li>
                                                    <NavLink 
                                                        to='/' 
                                                        activeClassName="logout-active"
                                                        onClick={this.logout}
                                                        exact
                                                    >
                                                        <i className="glyphicon glyphicon-log-out"></i>
                                                        <span>Sign Out</span>
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>    
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>       
        )
    }
}

const mapStateToProps = state => {
    return {
        roleManager: state.roleManager,
        roles: state.roleManager.roles,
        permissions: state.authState.permissions,
        isInternalPropertyAdmin: state.roleManager.isInternalPropertyAdmin,
        orgName: state.roleManager.org,
        orgTitle: state.roleManager.orgTitle,
        orgId: state.roleManager.orgId,
        user: state.authState.user,
        mobileMode: state.updateMobileState.mobileMode,
        unsavedData: state.updateUnsavedData.unsavedData
    }
}

const mapDispatchToProps = dispatch => {
    return {
        openOrganizationModal: (name) => {
            dispatch(openOrganizationModal(name))
        },
        setRole: (role) => {
            dispatch(setRole(role));
        },
        updateUserPermissions: (role) => {
            dispatch(updateUserPermissions(role));
        },
    }
}

export const SideNav =  withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SideNavComponent));