import React, { Component } from 'react';
import { API } from 'aws-amplify';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import Modal from 'react-bootstrap/lib/Modal';

import { FieldWrap, InputLabel, InputField, PrimaryButton } from '../global/forms/FormElements';
import { Title } from '../global/Title';
import PageLayout from '../global/PageLayout';
import Loader from '../global/Loader';
import constants from '../../js/constants'
import ReactTableComponent from '../global/ReactTableComponent';
import { tableSort, getSessionId } from '../../helpers/helpers';

class Account extends Component {
    reactTable = null;
    breadcrumbItems = [
        { title: "Users" }
    ];
    org = this.props.org;
    isAdmin = this.props.roleManager.indexOf(constants.USER_TYPES.ACCOUNT_MANAGER_ADMIN) > -1;
    isViewer = this.props.roleManager.indexOf(constants.USER_TYPES.ACCOUNT_MANAGER_VIEWER) > -1;
    isInternalAdmin = this.props.roleManager.indexOf(constants.USER_TYPES.INTERNAL_ACCOUNT_ADMIN) > -1;
    isRedawningEmail = this.props.user.contact_info.email.split("@")[1] == "redawning.com";
    state = {
        isLoading: false,
        isSaved: undefined,
        showModal: false,
        usersList: [],
        currentUserData: {},
        activePage: 1,
        pageSize: 10,
        validation: {
            email: false,
            roles: false,
            validEmailFormat: false,
            validRedawningEmail: false,
            emailAlreadyExist: false,
        },
        data: {
            email: "",
            roles: [],
        },
        columns: [
            {
                Header:() => tableSort("First Name"),
                'accessor': 'contact_info.first_name',
                'minWidth': 40,
                'Cell': row => (
                    <div>
                        <strong>{row.value}</strong>
                    </div>
                )
            },
            {
                Header:() => tableSort("Last Name"),
                'accessor': 'contact_info.last_name',
                'minWidth': 40,
                'Cell': row => (
                    <div>
                        <strong>{row.value}</strong>
                    </div>
                )
            },
            {
                Header:() => tableSort("Email"),
                'accessor': 'contact_info.email',
                'minWidth': 60,
                'Cell': row => (
                    <div>
                        {row.value}
                    </div>
                )
            },
            {
                'Header': 'Roles',
                'accessor': 'organization_roles',
                'minWidth': 100,
                'sortable': false,
                'Cell': row => {
                    let currentOrg = [];

                    row && row.original && row.original.organization_roles && row.original.organization_roles.map((org) =>{
                        if(org.organization_name === this.org) {
                            currentOrg = [...currentOrg, org];
                        }
                    });
                    
                    return (currentOrg[0].roles.map((role, i) => {
                        return (
                            <div key={i}>
                                <div>{role}</div>
                            </div>)
                        }))
                }
            }, {
                'Header': 'Actions',
                'minWidth': 30,
                'sortable': false,
                'Cell': row => (
                    <div>
                        <OverlayTrigger placement='bottom' overlay={this.getTooltip('edit', 'Update User')} onClick={e => this.clickEdit(row.index)}>
                            <span className='properties__options' style={{cursor: 'pointer'}}>
                                <i className='icon-edit' />
                            </span>
                        </OverlayTrigger>
                        <OverlayTrigger placement='bottom' overlay={this.getTooltip('delete', 'Delete User')} onClick={e => this.clickDelete(row.index)}>
                            <span className='properties__options' style={{cursor: 'pointer'}}>
                                <i className='icon-Delete' />
                            </span>
                        </OverlayTrigger>
                    </div>
                )
            }
        ],
    }

    async componentDidMount() {
        await this.getUsers();

        if(!this.isAdmin) {
            let columns = this.state.columns;

            columns.splice(4, 1)

            this.setState({
                columns
            });
        };
    }

    getUsers = async () => {
        this.setState({
            isLoading: true
        });

        try {
            if(this.isAdmin || this.isViewer){
                const tid = getSessionId();
                const limit = 100;
                let offset = 0;

                let resp = await API.get(constants.ACCAPI.NAME, constants.ACCAPI.GET_USER(this.org) + `?tid=${tid}&limit=${limit}&offset=${offset}`, { response: true });

                if(resp && resp.data) {
                    let userList = [];
                    let count = resp.data.length;
                    let totalCount = resp.headers['x-total-count'];

                    resp.data.forEach((user)=> {      
                        ((curUser)=>{
                            // Run as closure to ensure unique user in loop
                            curUser.organization_roles.forEach((org)=> {
                                if (org.organization_name == this.org) {
                                    // Check that user roles to compare are from active org
                                    // if user only has one role and it's Guest role do not add them to userList
                                    // if not an internal admin and the user email includes _svc_ do not add them to userList.
                                    if (org.roles && org.roles.length === 1 && org.roles[0] === 'Guest' || (!this.isInternalAdmin && (curUser && curUser.contact_info && curUser.contact_info.email && curUser.contact_info.email.includes("_svc_")))) {
                                        return
                                    } 
                                    else {
                                        userList.push(curUser)
                                    }
                                }                            
                            })
                        })(user);
                    });

                    while (totalCount > count) {
                        offset = offset + 100;

                        try {
                            let resp2 = await API.get(constants.ACCAPI.NAME, constants.ACCAPI.GET_USER(this.org) + `?tid=${tid}&limit=${limit}&offset=${offset}`, { response: true });

                            if(resp2 && resp2.data) {
                                count = count + resp2.data.length;
            
                                resp2.data.forEach((user)=> {      
                                    ((curUser)=>{
                                        curUser.organization_roles.forEach((org)=> {
                                            if (org.organization_name == this.org) {
                                                if (org.roles && org.roles.length === 1 && org.roles[0] === 'Guest' || (!this.isInternalAdmin && (curUser && curUser.contact_info && curUser.contact_info.email && curUser.contact_info.email.includes("_svc_")))) {
                                                    return
                                                } 
                                                else {
                                                    userList.push(curUser)
                                                }
                                            }                            
                                        })
                                    })(user);
                                });
                            };
                        }
                        catch(e) {
                            console.log(e);
                            this.setState({
                                isLoading: false,
                            });
                        };
                    };

                    this.setState({
                        usersList: [...userList],
                        isLoading: false,
                    });
                }
            }
        } catch(e) {
            console.log(e);
            this.setState({
                isLoading: false,
            });
        }
    }

    getTooltip = (id, text) => {
        return <Tooltip id={id} className='properties__tooltip'>{text}</Tooltip>
    }

    handleModalInputChange = (e) => {
        let name = e.target.name,
            value = e.target.value;

        this.setState({ 
            data: {...this.state.data, [name]: value.toLowerCase()}}, 
            () => this.validationHandler("email", null, "internalAdmin"));
    }

    closeModal = () => {
        this.setState({
            showModal: false,
            data: 
                {...this.state.data,
                    email: "",
                    roles: [],
            },
            validation: 
                {...this.state.validation, 
                    email: false,
                    roles: false,
                    validEmailFormat: false,
                    emailAlreadyExist: false,
                    validRedawningEmail: false,
                },
        })
    }

    clickAddUser = (e) => {
        e.stopPropagation();
        this.setState({
            showModal: "AddUserModal",
            data: {
                ...this.state.data,
                email: "",
                roles: [],
            },
            validation: 
                {...this.state.validation, 
                    email: false,
                    roles: false,
                    validEmailFormat: false,
                    emailAlreadyExist: false,
                    validRedawningEmail: false,
                },
        })
    }

    isDisabled = () => {
        const { validation } = this.state; 

        return validation.email ||
        validation.emailAlreadyExist ||
        validation.validEmailFormat || 
        validation.roles || 
        validation.validRedawningEmail
    }

    handleSubmitAddUser = async () => {
        let { data, org } = this.state; 

        this.setState({ 
            isLoading: true 
        });

        this.closeModal();

        data.email.trim();

        try {
            await API.post(constants.ACCAPI.NAME,  constants.ACCAPI.GET_USER(this.org), { body: data, response: true });
            await this.getUsers();
            this.setState({ 
                isLoading: false,
                isSaved: true,
                showModal: "Notification Modal",
                msg: "Successfully Added User",
            })
        }
        catch(err) {
            this.setState({
                isLoading: false,
                isSaved: false,
                showModal: "Notification Modal",
                msg: this.handleCustomErrorMessage(err && err.response && err.response.data && err.response.data.Message),
            })
        }
    }

    handleCustomErrorMessage = (error) => {
        let errorMessage = "";

        if(error && error.includes('Duplicate')) {
            errorMessage = "User Already Exists.";
        }
        else {
            errorMessage = "Error Adding User.";
        }
        return errorMessage;
    }

    toggleCheck = (e) => {
        let roles = this.state.data.roles,
             name = e.target.name;
     
         if (roles.indexOf(name) > -1) {
             roles.splice(roles.indexOf(name), 1);
         } else {
             roles.push(name);
         }
         this.setState({ 
             data: {...this.state.data, roles: roles},
             validation: {...this.state.validation, roles: false}}, 
             () => this.validationHandler(null, "roles", "internalAdmin"));
     }

    clickEdit = (index) => {
        if (this.isAdmin) {
            let data = JSON.parse(JSON.stringify(this.state.usersList[index]));

            let currentOrgData = data.organization_roles.filter(org => {
                return org.organization_name === this.org
            });

            this.setState({
                showModal: "Edit Modal",
                currentUserData: {
                    ...this.state.currentUserData,
                    data
                },
                data: {
                    ...this.state.data,
                    email: data.contact_info.email,
                    roles: currentOrgData[0].roles,
                }
            });
        }
    }

    handleSubmitEditUser = async () => {
        const rolesData = this.state.data.roles;
        const currentUserDataUid = this.state.currentUserData.data.uid;

        this.setState({ 
            isLoading: true 
        });
        this.closeModal();

        try {
            await API.post(constants.ACCAPI.NAME, constants.ACCAPI.EDIT_USER(this.org,encodeURIComponent(currentUserDataUid)), { body: rolesData });
            await this.getUsers();
            this.setState({ 
                isLoading: false,
                isSaved: true,
                showModal: "Notification Modal",
                msg: "User Updated",
            })
        } 
        catch(err) {
            console.log(err)
            this.setState({
                isSaved: false,
                isLoading: false,
                showModal: "Notification Modal",
                msg: 'Error Updating User',
            })
        }
    }

    clickDelete = (index) => {
        if (this.isAdmin) {
            let data = this.state.usersList[index];

            this.setState({
                showModal: "Delete User",
                currentUserData: {
                    ...this.state.currentUserData, data
                },
            })
        }
    }

    handleSubmitDeleteUser = async () => {
        let currentUserDataUid = this.state.currentUserData.data.uid;

        this.closeModal();
        this.setState({ isLoading: true });

        try {
            await API.del(constants.ACCAPI.NAME, constants.ACCAPI.DELETE_USER(this.org, encodeURIComponent(currentUserDataUid)));
            await this.getUsers();
            this.setState({ 
                isLoading: false,
                isSaved: true,
                showModal: "Notification Modal",
                msg: "Successfully Deleted User",
            });
        }
        catch(err) {
            console.log(err)
            this.setState({
                isLoading: false,
                isSaved: false,
                showModal: "Notification Modal",
                msg: 'Error Deleting User',
            })
        }
    }

    pageOnChange = (page) => {
        this.setState({
            activePage: parseInt(page)
        });
    }

    pageSizeOnChange = (pageSize, e) => {
        this.setState({
            activePage: 1,
            pageSize: parseInt(pageSize),
        });
    }

    getRef = (r) => {
        this.reactTable = r;
    }

    validationHandler = (email, roles, internalAdmin) => {
        let emailData = this.state.data.email;
        let rolesData = this.state.data.roles;
        let validation = this.state.validation;

        if(email) {
            if(!emailData.length) {
                validation.email = true;
            } else {
                validation.email = false;
            }
            if(this.state.usersList.find((d) => d.contact_info.email === emailData)) {
                validation.emailAlreadyExist = true;
            } else {
                validation.emailAlreadyExist = false;
            }
            if(emailData.length && !emailData.match(constants.EMAIL_VALIDATION)) {
                validation.validEmailFormat = true;
            }
            else {
                validation.validEmailFormat = false;
            }
        }
        if(roles) {
            if(!rolesData.length) {
                validation.roles = true;
            } else {
                validation.roles = false;
            }
        }
        if(internalAdmin) {
            if(emailData.length > 0 && 
                emailData.split("@")[1] !== "redawning.com" && 
                rolesData.indexOf(constants.USER_TYPES.INTERNAL_ACCOUNT_ADMIN) > -1) {
                    
                validation.validRedawningEmail = true;
            } else {
                validation.validRedawningEmail = false;
            }
        }

        this.setState({ 
            validation: this.state.validation
        });
    }

    generateUserTypeArray = () => {
        let data = [];
        for (let key in constants.USER_TYPES) {
            data.push(
                <InputField 
                    key={key} 
                    type="checkbox" 
                    value={this.state.data.roles.indexOf(constants.USER_TYPES[key]) > -1} 
                    name={constants.USER_TYPES[key]} 
                    cbChange={this.toggleCheck}>
                    {(constants.USER_TYPES[key]).replace(/([A-Z])/g, ' $1').trim()}
                </InputField>
            );
        };

        if(!this.isInternalAdmin && !this.isRedawningEmail) {
            data = data.filter((userType) => {  
                return (
                    userType.key !== "INTERNAL_ACCOUNT_ADMIN" && 
                    userType.key !== "SMART_CONCIERGE_VIEWER" && 
                    userType.key !== "SMART_CONCIERGE_ADMIN" &&
                    userType.key !== "GUEST_SERVICES" &&
                    userType.key !== "HOST_SUPPORT" &&
                    userType.key !== "FULL_SERVICE_HOST" &&
                    userType.key !== "FINANCE" &&
                    userType.key !== "REVIEWS_ADMIN" &&
                    userType.key !== "REVIEWS_VIEWER"
                );
            })
        };

        return (
            <Col md={10} className="checkbox-group padding-box panel-heading">
                {data}
            </Col>
        )
    }

    render() {
        const {
            msg,
            data,
            isSaved,
            isLoading,
            showModal,
            validation,
            columns,
            usersList,
            pageSize,
            activePage,
            currentUserData
        } = this.state;

        return (
            <PageLayout isCentered={true}>
                <Title 
                    title='Users' 
                    isCentered={true} 
                    breadcrumbItems={this.breadcrumbItems}
                >
                {
                    this.isAdmin && 
                    <PrimaryButton 
                        cssClass="primary-btn pull-right" 
                        onClick={this.clickAddUser} 
                        fullWidth={false}
                        disabled={isLoading}
                    >
                            <i className='icon-Plus create-icon'/>
                        Add User
                    </PrimaryButton>
                }
                </Title>
                <div id="content">
                    <div className="property-item row">
                        <FieldWrap>
                            {
                                isLoading === true && <Loader />
                            }
                            <Row className="row-padding">
                                <Modal 
                                    className="submit-modal" 
                                    show={showModal === 'Notification Modal'} 
                                    onHide={this.closeModal}
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            {
                                                isSaved === true ? (
                                                <div>
                                                    <span className='status-icon status-icon--success'></span>
                                                    <span className="submit-heading">{msg}</span>
                                                </div>) :
                                                (<div>
                                                    <span className='status-icon status-icon--error'></span>
                                                    <span className="submit-heading">{msg}</span>
                                                </div>)
                                            }
                                        </Modal.Title>
                                    </Modal.Header>
                                </Modal>
                                <Modal 
                                    className="submit-modal" 
                                    show={showModal === "AddUserModal"} 
                                    onHide={this.closeModal}
                                    backdrop={false}
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            Add User
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className='edit-user-modal'>
                                            <Row>
                                                <Col sm={12} xs={12} md={12} className="model-margin">
                                                    <InputLabel 
                                                        htmlFor="email"
                                                        className={validation.email && 'form-validation'}
                                                    >
                                                        Email
                                                    </InputLabel>
                                                    <InputField 
                                                        type="text"
                                                        className={validation.email && 'form-validation'}
                                                        name="email" 
                                                        onChange={this.handleModalInputChange}
                                                        value={data.email}
                                                        autoFocus
                                                    />
                                                    {
                                                        validation.email && 
                                                        !validation.validEmailFormat &&
                                                        <span className="required-error-text">
                                                            Email is required
                                                        </span>
                                                    }
                                                    {
                                                        validation.validEmailFormat &&
                                                        <span className="required-error-text">
                                                            {constants.EMAIL_INVALID_MESSAGE}
                                                        </span>
                                                    }
                                                    {
                                                        validation.emailAlreadyExist &&
                                                        <span className="required-error-text">
                                                            Email already exists.
                                                        </span>
                                                    }
                                                    <br/>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12} className="checkbox-group padding-box panel-heading">
                                                    <InputLabel
                                                        className={validation.roles && 'form-validation'}
                                                        cssClass="col-md-12 float-left padding-box" 
                                                        htmlFor="email"
                                                    >
                                                        Roles
                                                    </InputLabel>
                                                    <Col sm={12} xs={12} md={12}>
                                                        {this.generateUserTypeArray()}
                                                    </Col>
                                                    {
                                                        validation.roles && 
                                                        <Col sm={12} xs={12} md={12}>
                                                            <span className="required-error-text">
                                                                At least one role is required
                                                            </span>
                                                        </Col>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12} xs={12} md={12}>
                                                    <PrimaryButton 
                                                        onClick={this.handleSubmitAddUser} 
                                                        cssClass="primary-btn pull-left margin-btn top-margin" 
                                                        fullWidth={false} 
                                                        type="button"
                                                        disabled={
                                                            isLoading || 
                                                            !data.email.length ||
                                                            !data.roles.length ||
                                                            this.isDisabled()
                                                        }
                                                    >
                                                        Save
                                                    </PrimaryButton>
                                                </Col>
                                                <Col sm={12} xs={12} md={12}>
                                                    {
                                                        validation.validRedawningEmail && 
                                                        <span className="col-md-12 error-text">
                                                            Internal Account Admin can only be assigned to users with a RedAwning email address.
                                                        </span>
                                                    }
                                                </Col>
                                            </Row>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                                <Modal 
                                    className="submit-modal" 
                                    show={showModal === "Edit Modal"}
                                    onHide={this.closeModal}
                                    backdrop={false}
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            Edit User
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className='edit-user-modal'>
                                        <Row>
                                            <Col sm={12} xs={12} md={12} className="model-margin">
                                                <InputLabel 
                                                    htmlFor="email">
                                                    Email
                                                </InputLabel>
                                                <span 
                                                    className="readOnly-text">
                                                    {data.email}
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12} className="checkbox-group padding-box panel-heading">
                                                <InputLabel
                                                    className={validation.roles && 'form-validation'}
                                                    cssClass="col-md-12 float-left padding-box" 
                                                    htmlFor="email"
                                                >
                                                    Roles
                                                </InputLabel>
                                            <Col sm={12} xs={12} md={12}>
                                                {this.generateUserTypeArray()}
                                            </Col>
                                                {
                                                    validation.roles && 
                                                    <Col sm={12} xs={12} md={12}>
                                                        <span className="col-md-12 error-text">
                                                            At least one role is required
                                                        </span>
                                                    </Col>
                                                }
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} xs={12} md={12}>
                                                <PrimaryButton 
                                                    onClick={this.handleSubmitEditUser} 
                                                    cssClass="primary-btn pull-left margin-btn top-margin" 
                                                    fullWidth={false} 
                                                    type="button"
                                                    disabled={
                                                        isLoading || 
                                                        !data.email.length ||
                                                        !data.roles.length ||
                                                        this.isDisabled()
                                                    }
                                                >
                                                    Save
                                                </PrimaryButton>
                                            </Col>
                                            <Col sm={12} xs={12} md={12}>
                                                {
                                                    validation.validRedawningEmail && 
                                                    <span className="col-md-12 error-text">
                                                        Internal Account Admin can only be assigned to users with a RedAwning email address.
                                                    </span>
                                                }
                                            </Col>
                                        </Row>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                                <Modal 
                                    className="delete-room-modal xs-modal" 
                                    show={showModal === "Delete User"} 
                                    onHide={this.closeModal}
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            Confirm
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Row>
                                            <Col xs={12} className="bottom-margin">
                                                {
                                                    currentUserData ? 
                                                    <p>Are you sure you want to delete {currentUserData.contact_info && currentUserData.contact_info.email} ? </p> :(<div/>)
                                                }
                                            </Col>
                                            <div className="modal-btns">
                                                <PrimaryButton 
                                                    ssClass="transparent-btn pull-left" 
                                                    fullWidth={false} 
                                                    onClick={this.closeModal}
                                                >
                                                    No
                                                </PrimaryButton>
                                                <PrimaryButton 
                                                    cssClass="pull-left left-margin" 
                                                    fullWidth={false} 
                                                    onClick={e => this.handleSubmitDeleteUser(this.handleSubmitDeleteUser)}
                                                >
                                                    Yes
                                                </PrimaryButton>
                                            </div>
                                        </Row>
                                    </Modal.Body>
                                </Modal>
                                {
                                    (this.isViewer || this.isAdmin) && 
                                    <Col xs={12}>
                                        <Row>
                                            <ReactTableComponent
                                                getRef={this.getRef}
                                                reactTable={this.reactTable}
                                                className="-highlight"
                                                resizable={false} 
                                                minRows={0}
                                                columns={columns}
                                                data={usersList}
                                                pageSize={pageSize}
                                                showPagination={true}
                                                activePage={activePage}
                                                noDataText="No Users Found"
                                                totalItemsCount={usersList.length}
                                                pageOnChange={(page) => this.pageOnChange(page)}
                                                pageSizeOnChange={(pageSize) => this.pageSizeOnChange(pageSize)}
                                            />
                                        </Row>
                                    </Col>
                                }
                            </Row>
                        </FieldWrap>
                    </div>
                </div>
            </PageLayout>
        )
    }
}


const mapStateToProps = state => {
    return {
        roleManager: state.roleManager.roles,
        user: state.authState.user,
        org: state.roleManager.org,
    }
}

export default withRouter(connect(
    mapStateToProps,
    null
)(Account));
