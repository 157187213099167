import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

function ErrorModal(props)  {    
    return (
        <Modal 
            show={props.modal == 'error-modal'}
            className='error-modal'
            onHide={props.closeModal}
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={12} sm={12} className="flex-align-center">
                        <span className='msg-container _error'>{props.msg}</span>                                 
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default ErrorModal;