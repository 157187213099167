import React from 'react';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Modal from 'react-bootstrap/lib/Modal';
import { InputField, PrimaryButton } from '../../global/forms/FormElements';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

/**
 * @class Photo Caption modal component. 
 */
export default class PhotoCaption extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            caption: props.image.title? props.image.title : '',
            orginalImagesrc: this.props.image.url ? this.props.image.url : ('data:image/'+this.props.image.data_type+';base64,'+this.props.image.data),
            imagesrc: this.props.image.url ? this.props.image.url : ('data:image/'+this.props.image.data_type+';base64,'+this.props.image.data),
            toggleCropper: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.saveCaption = this.saveCaption.bind(this);
        this.cropper = null;
    }

    /**
     * Handle caption text changes.
     * @param {*} ev 
     */
    handleChange(ev) {
        let name = ev.target.name;
        this.setState({
            [name]: ev.target.value
        })
    }

    /**
     * Close a photo caption modal. 
     */
    closeModal() {
        this.props.toggleDialog();
    }
    
    /**
     * save current caption and pass current caption to parent component.
     */
    saveCaption() {
        this.cropper.getCroppedCanvas().toBlob((blob) => {
            const formData = new FormData();
            const imageData = this.cropper.getCroppedCanvas().toDataURL();
            const imageUpdated = _.get(this.cropper, 'replaced') || _.get(this.cropper.getData(), 'rotate');
            let updateImage = false;

            formData.append('croppedImage', blob);

            if (imageUpdated) {
                updateImage = true;
            };

            this.props.updateImage(this.state.caption, "", imageData, formData.getAll('croppedImage')[0], updateImage);

            this.props.toggleDialog();
        });
    }

    cropImage = () => {
        const imageData = this.cropper.getCroppedCanvas().toDataURL();

        this.setState({
            imagesrc: imageData,
            toggleCropper: false
        });
    }

    rotateImage = (dir) => {
        const direction = dir === "right" ? 90 : -90;

        this.cropper.rotate(direction);
    }

    zoomImage = (dir) => {
        const zoom = dir === 'zoom-in' ? 0.1 : -0.1;

        this.cropper.zoom(zoom);
    }

    resetImage = () => {
        const { orginalImagesrc } = this.state;

        this.setState({
            imagesrc: orginalImagesrc,
            toggleCropper: false
        }, () => {
            this.cropper.clear();
            this.cropper.reset();
        });
    }

    setDragMode = (type) => {
        this.cropper.setDragMode(type);
    }

    clearCrop = () => {
        this.cropper.clear();

        this.setState({
            toggleCropper: false
        });
    }

    editPhotoBtn = (type, info, func, icon) => {
        return (
            <OverlayTrigger 
                placement='top' 
                overlay={this.getTooltip(type, info)}
            >
                <span onClick={() => func(type ? type : '')}>
                    <i className={icon} />
                </span>
            </OverlayTrigger>
        )
    }

    getTooltip = (id, text) => {
        return <Tooltip id={id}>{text}</Tooltip>
	}

    render() {
        const { 
            toggleCropper,
            imagesrc
        } = this.state;
        let image = this.props.image;
        if(!image) {
            return null;
        };
        
        return (
            <Modal className="image-caption-modal" backdrop="static" show={true} onHide={this.closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <span className="submit-heading">Edit Image and Caption</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12} sm={12} style={{ width: "100%"}}>
                            <Cropper
                                crossOrigin='anonymous'
                                style={{ height: 500, width: "100%" }}
                                src={imagesrc}
                                dragMode={'move'}
                                minCropBoxHeight={10}
                                minCropBoxWidth={10}
                                background={true}
                                responsive={true}
                                toggleDragModeOnDblclick={false}
                                autoCrop={false}
                                checkCrossOrigin={true}
                                checkOrientation={false}
                                onInitialized={(instance) => {
                                    this.cropper = instance;
                                }}
                                guides={true}
                                cropend={() => {
                                    if(this.cropper.cropped) {
                                        this.setState({
                                            toggleCropper: true
                                        });
                                    }
                                    else {
                                        this.setState({
                                            toggleCropper: false
                                        });
                                    }
                                }}
                            />
                        </Col>
                        <Col xs={12} sm={12} className="rotate-container">
                            {
                                this.editPhotoBtn('move', 'Move', this.setDragMode, 'icon-move')
                            }
                            {
                                !toggleCropper ?
                                this.editPhotoBtn('crop', 'Crop Tool', this.setDragMode, 'icon-crop')
                                :
                                this.editPhotoBtn('cancel', 'Cancel Crop Tool', this.clearCrop, 'icon-cross')
                            }
                            {
                                this.editPhotoBtn('zoom-in', 'Zoom-in', this.zoomImage, 'icon-zoom-in')
                            }
                            {
                                this.editPhotoBtn('zoom-out', 'Zoom-out', this.zoomImage, 'icon-zoom-out')
                            }
                            {
                                this.editPhotoBtn('left', 'Rotate Left', this.rotateImage, 'icon-rotate-left')
                            }
                            {
                                this.editPhotoBtn('right', 'Rotate Right', this.rotateImage, 'icon-rotate-right')
                            }
                            {
                                this.editPhotoBtn('reset', 'Reset', this.resetImage, 'icon-wrong')
                            }
                            {
                                toggleCropper &&
                                this.editPhotoBtn('crop', 'Crop Image', this.cropImage, 'icon-checkbox_check')
                            }
                        </Col>
                        <Col xs={12} sm={12} className="caption-input-col">
                            <InputField 
                                cssClass="text-area caption-input" 
                                type="textarea" 
                                placeholder="Enter image caption" 
                                name="caption" 
                                value={this.state.caption} 
                                onChange={this.handleChange.bind(this)}
                            />
                        </Col>
                        <Col xs={12} sm={12} className="caption-save-col">
                            <PrimaryButton 
                                cssClass="caption-save-btn blue-btn " 
                                fullWidth={false} 
                                onClick={this.saveCaption}
                            >
                                Save
                            </PrimaryButton>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        )
    }
}