import React, { Component, Fragment } from 'react';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Form from 'react-bootstrap/lib/Form';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import Modal from 'react-bootstrap/lib/Modal';
import { FieldWrap, InputField, PrimaryButton } from '../global/forms/FormElements';
import { updateOrganization, updateOrganizationTitle, updateOrganizationId, setRole } from '../../js/actions/index';
import { Title } from '../global/Title';
import PageLayout from '../global/PageLayout';
import apiHandler from '../../js/apiHandler';
import { API } from 'aws-amplify';
import Loader from '../global/Loader';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import { connect } from 'react-redux';
import constants from '../../js/constants'
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import SSMLRTE from './../global/forms/SSMLRTE';
import moment from 'moment';
import ReactTable from 'react-table/react-table.min.js';
import Pagination from 'react-js-pagination';
import { tableSort } from '../../helpers/helpers';

const capitalizeFirstLetter = word => 
    word && word.charAt(0).toUpperCase() + word.slice(1)

const configureLevel = level => 
    level === "properties" ? "property" : level.split('s')[0]

const getInnerText = HTMLstring => {
    let tempDivElement = document.createElement('div')
    tempDivElement.innerHTML = HTMLstring
    return tempDivElement.textContent || tempDivElement.innerText || ''
}

class SmartConcierge extends Component {
    constructor(props) {
        super(props)
        this.propertyId = 
        this.props.match && 
        this.props.match.params && 
        this.props.match.params.propertyId;
        this.cbArray = []
        this.api = {
            apiName: "gbapi",
        }
        this.hierarchy = {
            "organization": 0, 
            "collection": 1, 
            "property": 2, 
            "room": 3,
        }
        this.org = this.props.orgName
        this.orgTitle = this.props.orgTitle
        this.orgId = this.props.orgId
        this.isSmartConciergeViewer = 
            this.props.roleManager && 
            this.props.roleManager.indexOf(constants.USER_TYPES.SMART_CONCIERGE_VIEWER) > -1;
        this.isSmartConciergeAdmin = 
            this.props.roleManager && 
            this.props.roleManager.indexOf(constants.USER_TYPES.SMART_CONCIERGE_ADMIN) > -1;
        this.smartConciergeLevel = 
            this.props.match.params.level === "properties" || this.props.match.params.collectionId ? "Property" : 
            (this.props.match.params.level) === "collections" ? "Collection" : "Organization";
        this.state = {
            downloading: false,
            groupedByCategory: [],
            currentAudio: "",
            audioLoading: false,
            ssmlRteValue: "",
            ssmlRtePlainTextValue: "",
            showGuestBookPDF: false,
            isLoading: false,
            query: "",
            filter: "",
            queryFilter: "",
            sorted: {
                id: "category_name",
                desc: false, 
            },
            urlPaths: this.props.history.location.pathname.split('/'),
            activeConciergeId: "",
            interactions: [],
            selectedInteractions: [],
            activeInteraction: {},
            cInteraction: {},
            pageSize: 10,
            showInputId: undefined,
            filteredList: [],
            configuredQuestions: [],
            cQuestionCategories: [],
            checkboxSelectedValues: [],
            levelConfig: {
                id: "", 
                level: "",
            },
            modals: {
                addQuestionsModal: false,
                discardModal: false,
                previewAudioModal: false,
                deleteModal: false,
                similarQuestionsModal: false,
                unpublishedModal: false,
                overwriteModal: false,
            },
            columns: [
                {
                    Header:() => tableSort("ID"),
                    'accessor':'property_interaction_id',
                    'width': 60,
                    'Cell': row => (
                        <div>
                            <span className='question'>{row.original.property_interaction_id}</span>
                        </div>
                    )
                },
                {
                    Header:() => tableSort("Category"),
                    'accessor':'category_name',
                    'width': 150,
                    'Cell': row => (
                        <div>
                            <span className='question capitalize'>{row.original.category_name === "aaa - messages" ? "messages" : row.original.category_name}</span>
                        </div>
                    )
                },
                {
                    Header:() => tableSort("Configured Level"),
                    'accessor':'level',
                    'width': 150,
                    'sortMethod': this.sortByLevel,
                    'Cell': row => {
                        let {
                            level
                        } = this.state.levelConfig,
                        isLevel = level.split('s')[0] === row.original.level || 
                        level === "properties" && row.original.level === "property"
                        return (
                            <div>
                                {isLevel ?
                                    <span className='question'>
                                        <strong> 
                                            {capitalizeFirstLetter(row.original.level)} 
                                        </strong>
                                    </span> :
                                    <span className='question'>
                                        {capitalizeFirstLetter(row.original.level)}
                                    </span>
                                }
                            </div>
                        )
                    }
                },
                {
                    Header:() => tableSort("Interaction"),
                    'accessor':'interaction_name',
                    'width': 150,
                    'Cell': row => ( 
                        <div>
                            <span className='question capitalize'>
                                {row.original.interaction_name && row.original.interaction_name !== 'message - welcome' ? row.original.interaction_name.split('-')[1] : 'Welcome Message'}
                            </span>
                        </div>
                    )
                },
                {
                    'Header': 'Question / Answer',
                    'minWidth': 320,
                    'sortable': false,
                    'accessor': 'interaction_response',
                    'Cell': row => {

                        let interactionResponse = row && row.original && row.original.interaction_response;
                       
                        if(interactionResponse.match("{wifi_pw}.*.{wifi_pw}")) {
                            interactionResponse = this.parseInteractionResponseWifiPw(interactionResponse);
                        };

                        return (
                        <div>
                            <span className='question'>
                                {
                                    row.original.interaction_samples && 
                                    row.original.interaction_samples[0] ? 
                                    row.original.interaction_samples[0] : 
                                    'N/A'
                                }
                            </span>
                            <hr/>
                            {this.state.showInputId !== row.original.property_interaction_id ? 
                                this.parseInteractionResponse(interactionResponse) : 
                                <Fragment>
                                    <div style={{width: "475px"}}>
                                        <SSMLRTE
                                            value={interactionResponse} 
                                            ssmlrteOnChange={this.ssmlrteOnChange}
                                        />
                                        <div className='btn-box' style={{margin: "2px 0px 0px 0px", float: "right"}}>
                                        <span 
                                            className={`character-limit ${Math.sign(1000 - this.state.ssmlRtePlainTextValue.length) === -1 ? "over-character-limit" : ""}`}
                                        >
                                            {1000 - this.state.ssmlRtePlainTextValue.length}
                                        </span>
                                        <button 
                                            className='primary-btn white-btn' 
                                            onClick={(e) => this.close(e)}
                                        >
                                            {this.isSmartConciergeAdmin ? 'Cancel' : 'Close'}
                                        </button>
                                        {this.isSmartConciergeAdmin && <Fragment>
                                            {Math.sign(1000 - this.state.ssmlRtePlainTextValue.length) === -1 ? <OverlayTrigger
                                                placement='right' 
                                                overlay={this.getTooltip("saveAnswer", Math.sign(1000 - this.state.ssmlRtePlainTextValue.length) === -1 ? "1000 Character Limit" : "")}>
                                                <button 
                                                    id="save-answer" 
                                                    className='primary-btn left-margin' 
                                                    onClick={(e) => this.saveAnswer(e)}
                                                    style={Math.sign(1000 - this.state.ssmlRtePlainTextValue.length) === -1 ? { border: "1px solid #D5D5D5", color: "black", backgroundColor: "#D5D5D5", cursor: 'not-allowed'} : null}
                                                >
                                                    Save
                                                </button>
                                            </OverlayTrigger>: 
                                                <button 
                                                    id="save-answer" 
                                                    className='primary-btn left-margin' 
                                                    onClick={(e) => this.saveAnswer(e)}
                                                >
                                                Save
                                                </button>
                                            }
                                        </Fragment>}
                                        </div>
                                    </div>
                                </Fragment>
                            }
                        </div>
                    )}
                }, 
                {
                    Header:() => tableSort("Status"),
                    'accessor': 'status',
                    'width': 120,
                    'Cell': row => {
                        const { status } = row.original

                        const toggledStatus = this.state.activeInteraction.status

                        return this.state.showInputId !== row.original.property_interaction_id || this.isSmartConciergeViewer && !this.isSmartConciergeAdmin ? (
                            <div>
                                {status ? <strong className='text-success'>Live</strong>:<strong className='text-danger'>Not Live</strong>}
                            </div>
                        ) : (
                            <div className="property-status" style={{ cursor: 'pointer' }}>
                                <div className={"primary-btn active-btn live-btn" + (toggledStatus ? ' is-live' : ' not-live')} 
                                    onClick={(e) => this.isSmartConciergeAdmin ? this.toggleStatus(e) :
                                    null}
                                >
                                    {toggledStatus ? 
                                        'Live': 
                                        'Not Live'}
                                    <i className="icon icon-selection_tip"></i>
                                </div>
                            </div>    
                        )
                    }
                },
                {
                    'Header': 'Actions',
                    'minWidth': 100,
                    'sortable': false,
                    'Cell': row => {
                        let interactionResponseLength = 
                            row && 
                            row.original && 
                            row.original.interaction_response &&
                            row.original.interaction_response.replace(/<p>/g, "").replace(/<\/p>/g,"").replace(/\s/g,'').length;
                        let onClickHandler = 
                            interactionResponseLength ? e=> this.previewAudio(e, row.original) : null;
                        return (
                            <div className='table-pad'>
                                {row.original.interaction_name !== 'message - welcome' ?
                                    <OverlayTrigger placement='bottom' overlay={this.getTooltip('audio', 'Preview Audio')} onClick={onClickHandler}>
                                        <span className='properties__options' style={interactionResponseLength ? { cursor: 'pointer' } : { cursor: 'not-allowed'}}> 
                                            <div className="circle" style={interactionResponseLength ? null : {border: "1px solid #d3d3d3"}}>
                                                <div className="play-button" style={interactionResponseLength ? null : {borderLeft: "7px solid #d3d3d3"}}/>
                                            </div>
                                        </span>
                                    </OverlayTrigger> :
                                    <div 
                                        className='action-placeholder'
                                    />
                                }
                                {this.isSmartConciergeAdmin &&
                                    <OverlayTrigger placement='bottom' overlay={this.getTooltip('edit', `${row.original.interaction_response ? 'Update Answer' : 'Add Answer'}`)} onClick={e => { e.preventDefault(); this.clickEdit(row.original) }}>
                                        <span className='properties__options' style={{ cursor: 'pointer' }}>
                                            <i className='icon-edit' />
                                        </span>
                                    </OverlayTrigger>
                                }
                                {this.isSmartConciergeAdmin &&
                                    <OverlayTrigger 
                                        placement='bottom' 
                                        overlay={this.getTooltip('delete', 'Delete Question')} 
                                        onClick={e => {!this.state.showInputId && this.clickDelete(e, row.original)}}
                                    >
                                        <span className='properties__options' style={{ cursor: 'pointer' }}>
                                            <i className='icon-Delete' />
                                        </span>
                                    </OverlayTrigger>
                                }
                            </div> 
                        )
                    }
                },
            ],
        }
    }

    componentWillMount() {
        this.state.activePage = this.props.location && this.props.location.search ? parseInt(queryString.parse(this.props.location.search).page) : 1
    }

    componentDidMount() {
        this.init()
    }

    componentWillReceiveProps(newProps) {
        let { location } = newProps
        if (location && location.state && location.state.org != this.org) {
            this.org = location.state.org
            this.init()
        }
    }

    init = () => {
        this.getCategories()
        this.getInteractions()

        let { urlPaths } = this.state,
        id = !Number.isNaN(parseInt(urlPaths[urlPaths.length - 1])) ? urlPaths[urlPaths.length - 1] : '', 
        level = urlPaths[urlPaths.length - 2]
        
        if (urlPaths.length > 2) {
            this.setState({
                levelConfig: { 
                ...this.state.levelConfig,
                id: id,
                level: level,},
                activeConciergeId: id,
            })
        }
        else {
            this.setState({
                levelConfig: {
                    ...this.state.levelConfig,
                    level: "organization",
                }
            })
        }
        if (level === "collections" && this.state.collectionData === undefined) {
            this.getCollectionData(id, level)
        }
        else if ((level === "properties" || level === "rooms") && this.state.propertyData === undefined) {
            this.getPropertyData(id, level)
        }
        else {
            this.getPropertyInteractions()
            this.setState({
                breadcrumbItems: [{title: 'Smart Concierge'}],
                itemTitle: this.props.orgTitle,
            })
        }
    }

    /**
     * handles search-box focus change
    */
    handleFocusChange = () => {
        let queryFilter = document.getElementById('query-filter')
        
        if (queryFilter) {
            this.setState({
                searchboxFocused: document.activeElement === queryFilter
            })
        }
    }

    /**
     * updates sorted state
     * @param {Array} newSorted
    */
    handleSortedChange = ([ newSorted ]) => {
        this.setState({
            sorted: newSorted,
        })
    }

    /**
     * updates activePage for pagination and url
     * @param {String} page string page number
    */
    handlePageChange = page => {
        this.props.history.push(`${this.props.history.location.pathname}?page=${page}`);

        this.setState({
            activePage: page,
        });
    }

    /**
     * checks for page content
     * @param {Number} page number page number
     * @param {Number} page number data count
     * @param {Number} page number page size
    */
    checkForPageContent = (activePage, dataCount, pageSize) => {
        return activePage > Math.ceil(dataCount / pageSize)
    }

    /**
     * checks for page content
     * @param {Number} page number data count
     * @param {Number} page number page size
    */
    getAvailablePage = (dataCount, pageSize) => {
        return Math.ceil(dataCount / pageSize)
    }

    /**
     * updates current organization
     * @param {String} org string organization name
    */
    updateOrg = org => {
        let orgName = org
        let orgTitle = this.lookupOrgTitle(orgName)
        
        let organization = this.props.user.expanded_organization_roles.filter(org=>{
            if (org.organization_name == orgName) {
                return true
            }
        })
        if (organization && organization.length) {
            this.props.updateOrganization(orgName)
            this.props.updateOrganizationTitle(orgTitle)
            this.props.updateOrganizationId(organization[0].organization_id)
            this.props.setRole(organization[0].roles)
        }
        sessionStorage.setItem("org", orgName)
        sessionStorage.setItem("orgTitle", orgTitle)
        sessionStorage.setItem("orgId", organization[0].organization_id)
    }

    /**
     * Looks up org title by org name
     * @param {String} orgName string organization name
    */
    lookupOrgTitle = orgName => {
        return this.props.user.expanded_organization_roles.filter (
            org => org.organization_name === orgName
        )[0].title
    }

    /**
     * Returns for /propertyinteractions
     * @param {Array} paths array of string paths
    */
    configureIds = paths => {
        let ids = {}
        paths.forEach((p, i) => {
            if (p === 'collections') {
                ids['collectionId'] = paths[i + 1]
            }
            if (p === 'properties') {
                ids['propertyId'] = paths[i + 1]
            }
            if (p === 'rooms') {
                ids['roomId'] = paths[i + 1]
            }
        })
        return ids
    }

    /**
     * Sets title
     * @param {string} title
    */
    configureBreadcrumbs = title => {
        this.setState({
            breadcrumbItems: [
                {
                    title: 'Smart Concierge',
                    link: '/smart-concierge',
                },
                {
                    title: title
                },
            ],
            itemTitle: title,
        })
    }

     /*API CALLS*/
     getCategories = async() => {
        try {
            let response = await apiHandler.get('/categories', this.api)
            response = response.data
            this.setState({
                categories: response,
            })
        }
        catch (err) {
            console.error(err)
        }
    }
    /**
	 * Gets all assignable interactions
	*/
    getInteractions = async() => {
        let response = await apiHandler.get('/interactions', this.api)
        
        if (response.data) {
            let [ welcomeInteraction ] = response.data.filter(el => el.interaction_name === 'message - welcome'),
            welcomeInteractionIndex = response.data.map(i => i.interaction_name).indexOf('message - welcome')

            response.data.splice(welcomeInteractionIndex, 1), response.data.unshift(welcomeInteraction)

            this.setState({
                interactions: response.data,
            })
        }
    }
    /**
	 * Pass this function to SSMLRTE to grab the value
	 * @param {string} value as string HTML
	 */
    ssmlrteOnChange = (htmlValue, plainTextValue) => {
        let { activeInteraction } = this.state
        if (!getInnerText(activeInteraction.interaction_response.toString('html'))) {
            activeInteraction.status = 0
        }
        this.setState({
            showGuestBookPDF: false, 
            activeInteraction,
            ssmlRteValue: htmlValue,
            ssmlRtePlainTextValue: plainTextValue,
        });
    }
    
    /**
	 * Gets assigned available interactions for property
	 * @param {string} id collection id, property id
     * @param {string} level configured level
	 */
    getPropertyInteractions = async (id, level) => {
        this.setState({
            isLoading: true,
        })
        const { collectionId } = this.configureIds(this.state.urlPaths)
        
        let path = `/propertyinteractions?org_name=${this.props.orgName}${collectionId ? `&collection_id=${collectionId}` : ''}${id && level !== 'collections' ? `&prop_id=${id}` : ''}`
        
        try {
            let response = await apiHandler.get(path, this.api)

            let indexOfNewlyAddedQuestion, pageOfNewlyAddedQuestion, sortedByCategoryList;
            let levelFormattedList = this.configureQuestionLevel(response.data);
            let [ welcomeInteraction ] = response.data.filter(el => el.interaction_name === 'message - welcome');

            if (welcomeInteraction) {
                welcomeInteraction.category_name = "aaa - messages"
                this.setState({
                    welcomeInteraction,
                })
            }
            if (this.state.newlyAddedQuestion !== undefined) {
                sortedByCategoryList = levelFormattedList.sort((a, b) => {
                    let textA = a.category_name.toUpperCase()
                    let textB = b.category_name.toUpperCase()
                    return (
                        (textA < textB) ? -1 : 
                        (textA > textB) ? 1 : 
                        0
                    )
                })
                indexOfNewlyAddedQuestion = sortedByCategoryList.map(i => i.interaction_id).indexOf(this.state.newlyAddedQuestion),
                pageOfNewlyAddedQuestion = Math.ceil(((indexOfNewlyAddedQuestion + 1) / this.state.pageSize))

                let queryStringActivePage = this.props.location.search ? 
                parseInt(queryString.parse(this.props.location.search).page) : 
                0

                if (queryStringActivePage !== pageOfNewlyAddedQuestion) {
                    this.handlePageChange(pageOfNewlyAddedQuestion) 
                }
                this.setState({
                    activeInteraction: sortedByCategoryList[indexOfNewlyAddedQuestion]
                })
            }

            let noQuestionsOnCurrentPage = this.checkForPageContent(this.state.activePage, response.data.length, this.state.pageSize),
            nextAvailablePageWithQuestions = this.getAvailablePage(response.data.length, this.state.pageSize)

            if (noQuestionsOnCurrentPage) {
                this.handlePageChange(nextAvailablePageWithQuestions)
            }
            this.setState({
                PDFRendered: false,
                configuredQuestions: levelFormattedList,
                showInputId: indexOfNewlyAddedQuestion !== undefined && 
                sortedByCategoryList[indexOfNewlyAddedQuestion] ? 
                sortedByCategoryList[indexOfNewlyAddedQuestion].property_interaction_id : 
                undefined,
                isLoading: false,
            })
            if (this.state.overwriteMode) {
                this.rebrandActiveInteraction(levelFormattedList)
            }
            this.getCQuestionCategories(levelFormattedList)
            this.generateCheckboxSelectedValues(levelFormattedList)
        }
        catch (err) {
            this.setState({
                isLoading: false,
            })
        }
    }

    configureQuestionLevel = data => {
        data && data.forEach(q => {
            if (q.property_collection_id) {
                q['level'] = "collection"
            }
            else if (q.nid) {
                q['level'] = "property"
            }
            else if (q.parent_id) {
                q['level'] = 'room'
            }
            else {
                q['level'] = 'organization'
            }
        })
        return data
    }

    sortByLevel = (a, b) => {
        return this.hierarchy[a] < this.hierarchy[b] ? 1 : 
        this.hierarchy[a] > this.hierarchy[b] ? -1 : 
        0
    }

    getPropertyData = async (propertyId, level) => {
        this.setState({
            isLoading: true,
        })
        try {
            let data = await API.get("rapapi", "/properties/" + propertyId, {})

            const { collectionId } = this.props.match.params

            if (data && Object.keys(data.repconfig).length === 0 && collectionId) {
                this.props.history.push({
                    pathname: 
                    `/smart-concierge/properties/${data.property_id}`
                })
            }
            else if (data && Object.keys(data.repconfig).length && (data.repconfig.property_collection_id !== collectionId)) {
                this.props.history.push({
                    pathname: `/smart-concierge/collections/${data.repconfig.property_collection_id}/properties/${data.property_id}`
                })
                this.init()
            }
            else {
                if (data) {
                    this.configureBreadcrumbs(data.content.title)
                    if (data.organization_name !== this.props.orgName) {
                        this.updateOrg(data.organization_name)
                    }
                    this.getPropertyInteractions(propertyId, level)
                    this.setState({
                        propertyData: data,
                    })
                }
            }
        } 
        catch (err) {
            this.setState({
                isLoading: false, 
            })
        }
    }

    getCollectionData = async (collectionId, level) => {
        this.setState({
            isLoading: true,
        })
        if (collectionId) {
            try {
                let data = await API.get("rapapi", "/propertycollections/" + collectionId, {})
                this.configureBreadcrumbs(data.name)
                if (data.organization_name !== this.props.orgName) {
                    this.updateOrg(data.organization_name)
                }
                this.getPropertyInteractions(collectionId, level)
                this.setState({
                    collectionData: data,
                })
            }
            catch (err) {
                this.setState({
                    isLoading: false,
                })
            }
        }
        else {
            this.setState({
                isLoading: false,
            })
        }
    }

    clickAdd = interaction => {
        const { 
            interaction_id 
        } = interaction,
        { 
            selectedInteractions,
        } = this.state

        let iIds = selectedInteractions.map(i => i.interaction_id)

        let newIdx = iIds.indexOf(interaction_id)
        
        if (!iIds.includes(interaction_id)) {
            selectedInteractions.push(interaction)
        }
        else {
           selectedInteractions.splice(newIdx, 1)
        }
        this.setState({
            showGuestBookPDF: false,
            selectedInteractions,
        })
    }

    clickEdit = interaction => {
        this.setState({
            showGuestBookPDF: false,
            showInputId: interaction.property_interaction_id,
            activeInteraction: interaction,
            overwriteMode: interaction.level !== configureLevel(this.state.levelConfig.level), 
        })
    }

    getRowProps = (state, rowInfo, column) => {
        return this.state.showInputId !== rowInfo.original.property_interaction_id && {
            onClick: e => {
                e.preventDefault();
                this.clickEdit(rowInfo.original);
            },
            style: {
                cursor: 'pointer'
            }
        };
    }

    clickDelete = (e, interaction) => {
        e.stopPropagation()
        this.setState({
            activeInteraction: interaction,
        })
        this.setModal('deleteModal')
    }

    handleDelete = () => {
        this.setState({
            isLoading: true 
        })
        let { 
            property_interaction_id, 
        } = this.state.activeInteraction,
        { 
            level 
        } = this.state.levelConfig

        let payload = { 
            "organization_name": this.props.orgName, 
        }

        API.del("gbapi", `/propertyinteractions/${property_interaction_id}`, {"body": payload}).then(()=> {
            this.setState({
                query: "",
                newlyAddedQuestion: undefined,
            }, ()=> {
                this.getPropertyInteractions(this.state.activeConciergeId, level)
            })
        }).catch(()=> {
            this.setState({
                isLoading: false,
            })
        })
    }

    close = (e) => {
        e.preventDefault()
        this.setState({
            activeInteraction: {},
            showInputId: undefined
        })
    }

    getTooltip = (id, text) => {
        return <Tooltip id={id} className='properties__tooltip'>{text}</Tooltip>
    }

    addQuestion = e => {
        e.preventDefault()

        this.hideModal('overwriteModal')

        let iDs, overwriteInteraction

        if (this.state.overwriteMode) {
            iDs = this.state.interactions.map(i => i.interaction_id),
            overwriteInteraction = this.state.interactions[
                iDs.indexOf(
                    this.state.activeInteraction.interaction_id
                )
            ]
            this.state.selectedInteractions.push(overwriteInteraction)
        }
        this.setState({
            isLoading: true,
        })
        let payload = {
            "organization_name": this.props.orgName,
            "interactions": this.state.selectedInteractions,
        }, 
        {
            levelConfig: {
                id,
                level,
            }
        } = this.state
        
        if (level === "collections") {
            payload['property_collection_id'] = id
        }
        if (level === "properties" || level === "rooms") {
            payload['property_id'] = id
        }
        API.post("gbapi", "/propertyinteractions", {"body": payload}).then(() => {
            let newlyAddedQuestion = this.state.selectedInteractions[this.state.selectedInteractions.length -1]
            this.setState({
                selectedInteractions: [],
                query: "",
                newlyAddedQuestion: newlyAddedQuestion.interaction_id,
                sorted: {
                    id: "category_name",
                    desc: false, 
                }
            }, ()=> {
                this.getPropertyInteractions(id, level)
            })
            if (!this.state.overwriteMode) {
                this.hideModal('addQuestionsModal')
            }
        })
    }

    rebrandActiveInteraction = allInteractions => {
        this.setState({
            overwriteMode: false,
        })
        let [ rebrandedInteraction ] = this.configureQuestionLevel(allInteractions).filter(q => {
            return q.interaction_id === this.state.activeInteraction.interaction_id && q.level === configureLevel(this.state.levelConfig.level)
        })
        this.state.activeInteraction.property_interaction_id = rebrandedInteraction.property_interaction_id
        this.state.activeInteraction.level = configureLevel(this.state.levelConfig.level)

        if (this.state.levelConfig.level === 'collections') {
            this.state.activeInteraction.property_collection_id = this.state.activeConciergeId
        }
        if (this.state.levelConfig.level === 'properties') {
            this.state.activeInteraction.nid = this.state.activeConciergeId
        }
        this.saveAnswer()
    }

    saveAnswer = e => {
        if (e) {
            e.preventDefault()
        }
        if(Math.sign(1000 - this.state.ssmlRtePlainTextValue.length) !== -1) {
            if (this.state.overwriteMode) {
                this.setModal('overwriteModal')
            }
            else {
                this.setState({
                    isLoading: true,
                })
                const {
                    activeInteraction: { 
                        property_interaction_id, 
                        interaction_id, 
                        uid,
                        interaction_response,
                        level: qLevel,
                    },
                    activeConciergeId,
                } = this.state, 
                { 
                    orgName: organization_name 
                } = this.props  
                let payload = { 
                    organization_name,
                    property_interaction_id,
                    interaction_id,
                    uid,
                    // parse interaction_reponse <wifi_pw> tags to custom {wifi_pw} tags
                    interaction_response: this.state.ssmlRteValue.replace(/<wifi_pw>/g, "{wifi_pw}").replace(/<\/wifi_pw>/g, "{wifi_pw}"),
                }
                if (qLevel === "collection") {
                    payload['property_collection_id'] = activeConciergeId
                }
                if (qLevel === "property" || qLevel === "room") {
                    payload['property_id'] = activeConciergeId
                }
                API.put("gbapi", `/propertyinteractions/${property_interaction_id}`, {"body": payload}).then(()=> {
                    let { status } = this.state.configuredQuestions [
                        this.state.configuredQuestions
                            .map(q => q.property_interaction_id)
                                .indexOf (
                                    this.state.activeInteraction.property_interaction_id
                                )
                    ]
                    this.setState({
                        query: "",
                        newlyAddedQuestion: undefined,
                    })
                    if (this.state.activeInteraction.status !== status) {
                        this.updateStatus()
                    } else {
                        this.getPropertyInteractions(this.state.activeConciergeId, this.state.levelConfig.level)
                    }
                }).catch(()=> {
                    this.setState({
                        query: "",
                        isLoading: false,
                        activeInteraction: {},
                    })
                })
            }
        } 
    }
    
    toggleStatus = (e) => {
        e.stopPropagation()
        if (getInnerText(this.state.activeInteraction.interaction_response.toString("html"))) {
            this.setState({
                activeInteraction: {
                    ...this.state.activeInteraction,
                    status: this.state.activeInteraction.status === 1 ? 0 : 1,
                }
            })
        } 
        else {
            this.setModal('unpublishedModal')
        }
    }

    updateStatus = () => {
        this.setState({
            isLoading: true,
        })
        let payload = {
            "status": this.state.activeInteraction.status,
        }
        const { property_interaction_id } = this.state.activeInteraction

        API.patch("gbapi", `/propertyinteractions/${property_interaction_id}`, {"body": payload }).then(() => {
            this.setState({
                activeInteraction: {},
                showInputId: undefined,
            })
            this.getPropertyInteractions(this.state.activeConciergeId, this.state.levelConfig.level)
        }).catch(()=> {
            this.setState({
                isLoading: false,
            })
        })
    }

    generateInteractionsList() {
        let interactionsList = this.state.queryFilter || this.state.filter ? 
        this.state.filteredList : 
        this.state.interactions,
        initialValues = this.state.checkboxSelectedValues

        return interactionsList !== undefined ? interactionsList.map(interaction => {
            let isDisabled
            if (initialValues) {
                isDisabled = initialValues.includes(interaction.interaction_id)
            }
            return (
                <div className={`select-question-box ${isDisabled ? 'muted' : ''}`}  key={interaction.interaction_id}>
                    <InputField 
                        type='checkbox' 
                        disabled={isDisabled} 
                        value={
                            isDisabled || 
                            this.state.selectedInteractions
                                .map(i => i.interaction_id)
                                .includes(interaction.interaction_id)
                            } 
                        cbChange={
                            ()=> this.clickAdd(interaction)
                        } 
                    />
                    {isDisabled ?
                        <OverlayTrigger 
                            placement='right' 
                            overlay={this.getTooltip('interaction', `Interaction already added to ${this.state.itemTitle} Smart Concierge`)} 
                        >
                            <span style={{cursor: "not-allowed"}}>{interaction.description}</span>
                        </OverlayTrigger> : 
                        <span>{interaction.description}</span>
                    }
                    {interaction && interaction.interaction_name !== 'message - welcome' &&
                    <span className='pull-right'>
                        <OverlayTrigger 
                            placement='bottom' 
                            overlay={this.getTooltip('details', 'Get details')}
                        >
                            <div className="information-icon" onClick={()=> { this.setState({cInteraction: interaction}); this.setModal('similarQuestionsModal');}} />
                        </OverlayTrigger>
                    </span>}
                    <span className='pull-right'>
                        {capitalizeFirstLetter(interaction.category_name)} 
                    </span>
                </div>
            )
        }) : <Loader/>
    }

    generateCheckboxSelectedValues = questions => {
        let sVals = []
        questions.forEach(q => {
            sVals.push(q.interaction_id)
        })
        this.setState({
            checkboxSelectedValues: sVals,
        })
    }

    getCQuestionCategories = questions => {
        let catArr = []
        questions && questions.length && questions.forEach(q => {
            if (catArr.indexOf(q.category_name) === -1 && 
                q.interaction_response && 
                q.status && 
                q.category_name !== "aaa - messages") {
                catArr.push(q.category_name)
            }
        })
        this.groupByCategory(catArr.sort(), questions)
    }

    groupByCategory = (catArr, questions) => {
        let grouped = {}
        catArr.forEach(category => {
            grouped[category] = []
            questions.forEach(q => {
                if (category === q.category_name && 
                    q.interaction_response && 
                    q.status && 
                    q.category_name !== 'aaa - messages') {
                    grouped[category].push(q)
                }
            })
        })
        this.setState({
            groupedByCategory: grouped,
        })
    }

    generateCategoriesList = () => {
        let option = this.state.filter && document.getElementById('query-filter') && document.getElementById('query-filter').value === '' ? <option key={0}>Show all questions</option> : <option key={0}>Filter by category</option>;

        if (this.state.categories) {
            return [option].concat(this.state.categories.map(category => 
                <option key={category.interaction_category_id + 1}>{capitalizeFirstLetter(category.category_name)}</option>
            ))
        }
        else {
            return option
        }
    }

    previewAudio = async (e, interaction) => {
        e.stopPropagation();
        // opens Audio Modal
        this.setModal('previewAudioModal');
        // set active Interaction to state and clear current audio in state.
        this.setState({
            activeInteraction: interaction,
            audioLoading: true,
            currentAudio: "",
        });
        let audioComplete = false;
        let timeStamp = new Date().getTime();
        let customErrorMessage = "There is a problem processing your Interaction Response. Please contact Customer Support.";

        try {
            let audioData = await apiHandler.get(`/propertyinteractions/${interaction.property_interaction_id}/preview`, this.api);
        
            if(audioData && audioData.data && audioData.data.status && audioData.data.status.toLowerCase() !== "completed") {
                // if audio status comes back as "failed"
                if(audioData && audioData.data && audioData.data.status && audioData.data.status.toLowerCase() === "error") {
                    audioComplete = true;
                    this.setState({
                        audioLoading: false,
                        audioError: customErrorMessage
                    });
                } else {
                    let startTime = moment().valueOf();

                    while (audioComplete === false) {
                        let audioDataResponse = await apiHandler.get(`/propertyinteractions/${interaction.property_interaction_id}/preview`, this.api);
                        // will stop the while loop after 10 seconds
                        if ((moment().valueOf() - startTime) > 10000) {
                            audioComplete = true;
                            this.setState({
                                audioError: customErrorMessage,
                                audioLoading: false 
                            });
                        } else if (audioDataResponse.data.status === "completed") {
                            audioComplete = true;
                            // add delay to audio playback since the audio is not returning right away after API status = "completed"
                            setTimeout(() => {
                                this.setState({ 
                                    currentAudio: 
                                        audioDataResponse && 
                                        audioDataResponse.data && 
                                        audioDataResponse.data.url !== null ? 
                                        `${audioDataResponse.data.url}?timeStamp=${timeStamp}` : 
                                        "",
                                    audioError: 
                                        audioDataResponse && 
                                        audioDataResponse.data && 
                                        audioDataResponse.data.url === null ? 
                                        customErrorMessage : 
                                        "",
                                    audioLoading: false,
                                });
                            }, 2500);
                        };
                    };
                };
            // if audio status comes back as "completed"
            } else {
                // add delay to audio playback since the audio is not returning right away after API status = "completed"
                setTimeout(() => {
                    this.setState({
                        currentAudio: 
                            audioData && 
                            audioData.data && 
                            audioData.data.url !== null ? 
                            `${audioData.data.url}?timeStamp=${timeStamp}` : 
                            "",
                        audioError: 
                            audioData && 
                            audioData.data && 
                            audioData.data.url === null ? 
                            customErrorMessage : 
                            "",
                        audioLoading: false,
                    });
                }, 2500);
            };
        } catch (err) {
            console.log(err)
            this.setState({
                audioError: customErrorMessage,
                audioLoading: false 
            });
        }
    }

    parseInteractionResponse = (response) => {
        let interactionResponseArray = [];

        // check if <p></p> tag exist in reponse
        if(response.match(/<p>(.*?)<\/p>/g)) {
            response.match(/<p>(.*?)<\/p>/g).map((val) =>{
                interactionResponseArray.push(val);
            });
        } else {
            interactionResponseArray.push(response);
        }
        let interactionResponseSpacingArray = interactionResponseArray.map((resp, i) => {

            // replace all HTML entities wiht their appropriate symbol
            let removeResponseTags = resp.replace(/<\/?[^>]+(>|$)/g, "").replace(/&quot;/g, '"').replace(/&#x27;/g, "'").replace(/&amp;/g, "&").replace(/&lt;/g, "<").replace(/&gt;/g, ">");

            if(removeResponseTags.replace(/ +(?= )/g,'') === '' || removeResponseTags.replace(/ +(?= )/g,'') === ' ') {
                // will render <br/> for every occurance of <p></p> tag
                return <br key={i} />;
            } else {
                return <div className='question' key={i}>{removeResponseTags}</div>;
            }
        });
        return interactionResponseSpacingArray;
    }

    parseInteractionResponseWifiPw = (response) => {
        let interactionResponse = response;

        const interactionResponseParseWifiPw = interactionResponse.match("{wifi_pw}.*.{wifi_pw}" ).toString().split("{wifi_pw}");
        let wifi_pw = [];
        let wifi_html = [];

        interactionResponseParseWifiPw.map((response) => { 
        if (response.length > 0) {
            wifi_pw.push(response.replace( /^/, "{wifi_pw}").replace(/$/, "{wifi_pw}"));
            wifi_html.push( response.replace( /^/, "<wifi_pw>").replace(/$/, "</wifi_pw>"));
        }});
        for (let i=0; i <= wifi_pw.length; i++) { 
            interactionResponse = interactionResponse.replace(wifi_pw[i],wifi_html[i] );
        };

        return interactionResponse;
    }

    downloadPDF = async () => {
        this.setState({
            downloading: true
        });

        try {
            let resp = await API.get("rapapi", `/properties/${this.propertyId}/guestbook`, { responseType: "text", response: true, isCognito: true });

            if(resp && resp.data) {
                let downloadLink = document.createElement('a');
                downloadLink.target = '_blank';
                downloadLink.download = 'Guestbook.pdf';

                let binary_string = window.atob(resp.data)
                let len = resp.data.length;
                let bytes = new Uint8Array(len);
                for (let i = 0; i < len; i++) {
                    bytes[i] = binary_string.charCodeAt(i);
                }
                let blob = new Blob([bytes.buffer], { type: 'application/pdf' })
              
                let url = URL.createObjectURL(blob);
              
                downloadLink.href = url;

                document.body.append(downloadLink);

                downloadLink.click();

                document.body.removeChild(downloadLink);

                this.setState({
                    downloading: false
                });
            };
        }
        catch(e) {
            console.log(e);
            this.setState({
                downloading: false
            });
        }
    }

    /**
	 * Triggers render generation of guestbook. Must be manually triggered or PDFDownloadLink will not work
	 */
    triggerPDFGeneration = () => {
        this.setState({
            showGuestBookPDF: true,
        })
    }

    /* Interactions search by category */
    filterByCategory = e => {
        let value
        this.setState({
            queryFilter: "",
        })

        if (e && e.target && e.target.value) {
            ({ value } = e.target)
        }
        const { interactions } = this.state
        
        if (value === 'Show all questions' || value === undefined) {
            this.setState({
                filter: null, 
                interactions,
            })
            let categoryFilter = document.getElementById('category-filter')

            if (categoryFilter) {
                categoryFilter.children[0].selected = true
            }
        } 
        else {
            let filteredList = interactions.filter(el => el.category_name.toLowerCase() === value.toLowerCase())
            this.setState({
                filter: value, 
                filteredList,
            })
        }
    }
    
    /* Interactions search by query */
	filterByQuery = e => {
        let queryFilter = e.target.value.toLowerCase().replace(/[^a-zA-Z]/g, "")
        if (document.getElementById('category-filter')) {
            document.getElementById('category-filter').children[0].selected = true
        }
        const { interactions } = this.state

        if (queryFilter) {
            let filteredList = interactions.filter(el => el.description.toLowerCase().indexOf(queryFilter) > -1 || el.category_name.toLowerCase().indexOf(queryFilter) > -1)
            this.setState({
                filteredList,
                queryFilter,
                filter: "",
            }) 
        } 
        else {
            this.setState({
                queryFilter: "",
            })
        }
    }
    /* Guest book questions search */
    handleSearch = e => {
        let query = e.target.value.toLowerCase()
        if (query) {
            let newList = this.state.configuredQuestions.filter(el => el.interaction_samples !== undefined && el.interaction_samples[0].length && el.interaction_samples[0].toLowerCase().indexOf(query) > -1 || el.level.toLowerCase().indexOf(query) > -1 || el.interaction_response && el.interaction_response.toLowerCase().indexOf(query) > -1 || el.category_name.toLowerCase().indexOf(query) > -1)
            this.setState({
                newList, 
                query,
            })
        }
        else {
            this.setState({
                query: "",
            })
        }
    }
    
    discard = modal => {
        const { modals, configuredQuestions } = this.state
        if (this.state.selectedInteractions.length) {
            modals['discardModal'] = true
            if (modal === 'discardModal') {
                modals['discardModal'] = false
                modals['addQuestionsModal'] = false
                this.generateCheckboxSelectedValues(configuredQuestions)
                this.setState({
                    selectedInteractions: [],
                    queryFilter: "",
                    filter: "",
                })
            }
        } 
        else {
            this.hideModal(modal)
        }
        this.setState({modals,})
    }

    setModal = modal => {
        const { 
            modals 
        } = this.state

        modals[modal] = true

        this.setState({
            modals,
        })
    }

    hideModal = modal => {
        const { 
            modals 
        } = this.state

        if (modal === "addQuestionsModal") {
            if(document.getElementById('query-filter'))
                document.getElementById('query-filter').value = ''
            this.setState({
                filter: '',
                queryFilter: '',
            })
        }
        modals[modal] = false
        this.setState({
            modals,
        })
    }

    onConfirm = modal => {
        if (modal === 'deleteModal') {
            this.handleDelete()
        }
        else {  
            this.clickEdit(this.state.activeInteraction)
        }
        this.hideModal(modal)
    }

    render() {
        let configuredQuestionsCount = this.state.configuredQuestions.length,
        totalItems = this.state.interactions.length,
        selectedItems = this.state.checkboxSelectedValues.length + 
        this.state.selectedInteractions.length,
        interactionFilter = this.state.queryFilter || this.state.filter

        const {
            modals: { 
                addQuestionsModal, 
                discardModal,
                deleteModal,
                similarQuestionsModal,
                unpublishedModal,
                previewAudioModal,
                overwriteModal,
            },
            newList,
            activeInteraction,
            configuredQuestions,
            cInteraction,
            downloading,
            levelConfig: { 
                level 
            },
            activeConciergeId,
        } = this.state;

        let activeInteractionResponsePreviewMessage = this.state.activeInteraction.interaction_response ? this.state.activeInteraction.interaction_response : "";

        if(activeInteractionResponsePreviewMessage && activeInteractionResponsePreviewMessage.match("{wifi_pw}.*.{wifi_pw}")) {
            activeInteractionResponsePreviewMessage = this.parseInteractionResponseWifiPw(activeInteractionResponsePreviewMessage);
        };

        return !this.state.isLoading ? (
            <PageLayout isCentered={true}>

                {downloading && <Loader />}

                <Title 
                    title={`${this.state.itemTitle || ""} Smart Concierge`}
                    isCentered={true} 
                    breadcrumbItems={this.state.breadcrumbItems}
                >
                    <div className="smart-concierge-controls-container">
                        <form className="search-inp">
                            <InputField 
                                placeholder='Search'
                                type="searchbox"
                                autoComplete="off"
                                onChange={
                                    e => this.handleSearch(e)
                                }
                                value={this.state.query}
                                clearSearch={()=> {
                                    this.setState({
                                        query: ''
                                    })
                                }}
                            />
                        </form>
                        {
                            !(level === "organization" &&
                            this.isSmartConciergeAdmin === false) &&
                            <DropdownButton
                                title={'Actions'}
                                className="primary-btn white-btn left-margin"
                                id="smartConcierge-actions"
                                style={{
                                    marginLeft: "5px"
                                }}
                            >
                                {
                                    this.isSmartConciergeAdmin && 
                                    <MenuItem 
                                        disabled={
                                            this.state.downloading ||
                                            !this.propertyId
                                        }
                                    >
                                        <span 
                                            onClick={
                                                (!this.state.downloading && 
                                                this.propertyId)  ? 
                                                this.downloadPDF : 
                                                null
                                            }
                                        >
                                            {!this.state.downloading ? 'Download Guestbook' : 'Downloading...'}
                                        </span>
                                        <span 
                                            className={'glyphicon glyphicon-refresh' + (this.state.downloading ? ' spinning' : ' hide')}
                                        />
                                    </MenuItem>
                                }
                                {
                                    level !== "organization" ?
                                    <Fragment>
                                        {this.isSmartConciergeAdmin &&
                                            <MenuItem divider />
                                        }
                                        <MenuItem 
                                            onClick={()=> {
                                                this.props.history.push(`/${level === 'collections' ? 'collections' : 'properties'}/${activeConciergeId}${'/edit'}`)}
                                            }
                                        >
                                            View {level === 'collections' ? 'Collection' : `${this.state.propertyData && this.state.propertyData.level === 'key' ? 'Property' : 'Room Type'}`}
                                        </MenuItem>
                                    </Fragment> : 
                                    ""
                                }
                            </DropdownButton>
                        }
                        {
                            this.isSmartConciergeAdmin &&
                            <PrimaryButton 
                                cssClass="pull-right left-margin" 
                                fullWidth={false} 
                                type="button" 
                                onClick={()=> this.setModal('addQuestionsModal')}
                            >
                                <i className='icon-Plus create-icon' />
                                Add {this.smartConciergeLevel} Questions
                            </PrimaryButton>
                        }
                    </div>
                </Title>
                <div 
                    id="content" 
                    className='smart-concierge' 
                >
                    <div className="property-item row">
                        <FieldWrap>
                            <Row className="row-padding">
                                {(this.isSmartConciergeViewer || this.isSmartConciergeAdmin) && <Col xs={12}>
                                    <Row>
                                        <ReactTable
                                            minRows={0}
                                            page={this.state.activePage - 1}
                                            pageSize={this.state.pageSize}
                                            resizable={false}
                                            columns={this.state.columns}
                                            data={
                                                this.state.query && this.state.query.length ? newList : configuredQuestions
                                            }
                                            defaultSorted={[this.state.sorted]}
                                            getTrProps={this.getRowProps}
                                            className="-highlight smart-concierge-table"
                                            noDataText="No configured questions found. Please add questions."
                                            PaginationComponent={
                                                (props) => {
                                                    if(this.state.configuredQuestions.length > 0) {
                                                        return (
                                                            <div className="pagination-container">
                                                                    <div className="units-info-container">
                                                                        {configuredQuestionsCount} {configuredQuestionsCount == 1 ? "Question" : "Questions"}&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                                                                    </div>
                                                                    <div className="page_size-input-container">
                                                                    <form onSubmit={e => { e.preventDefault(); }}>
                                                                        <div 
                                                                            className='page-size' 
                                                                        >
                                                                            <InputField
                                                                                className="pageSize"
                                                                                type='number' 
                                                                                name='pageSize'
                                                                                autoComplete="off"
                                                                                defaultValue={this.state.pageSize}
                                                                                onChange={(e) => {
                                                                                    if (e.target.value > configuredQuestionsCount) {
                                                                                        e.target.value = configuredQuestionsCount
                                                                                    } 
                                                                                    if (e.target.value < 0) {
                                                                                        e.target.value = 1;
                                                                                    }
                                                                                }}
                                                                                onKeyUp={(e) => {
                                                                                    if(e.keyCode === 13 && e.target.value !== 0 && Number.isInteger(Number(e.target.value))) {
                                                                                        e.preventDefault();
                                                                                        let pageSize = e.target.value;
    
                                                                                        if (pageSize.trim() !== '' && Number.isInteger(parseInt(pageSize))) {
                                                                                            let intPageSize = parseInt(pageSize)
                                                                                            this.setState({
                                                                                                pageSize: intPageSize
                                                                                            }, ()=> {
                                                                                                let noContentWithNewPageSize = this.checkForPageContent(this.state.activePage, configuredQuestionsCount, intPageSize)
                                                                                                if (noContentWithNewPageSize) {
                                                                                                    let nextAvaiblePage = this.getAvailablePage(configuredQuestionsCount, intPageSize)
                                                                                                    this.handlePageChange(nextAvaiblePage)
                                                                                                }
                                                                                            })
                                                                                        };
                                                                                    };
                                                                                }}
                                                                            />
                                                                            / page
                                                                        </div>
                                                                        </form>
                                                                    </div>
                                                                    <Pagination
                                                                        activePage={props.page + 1}
                                                                        itemsCountPerPage={this.state.pageSize}
                                                                        totalItemsCount={configuredQuestionsCount}
                                                                        innerClass='pagination pull-right'
                                                                        onChange={this.handlePageChange}
                                                                    />
                                                            </div>
                                                        )
                                                    }
                                                    else {
                                                        return null;
                                                    }
                                                }
                                            }
                                            onSortedChange={this.handleSortedChange}
                                        />
                                    </Row>
                                </Col>}
                            </Row>
                        </FieldWrap>
                    </div>
                </div>
                <Modal 
                    show={addQuestionsModal} 
                    className='add-questions-modal' 
                    onHide={()=> this.discard('addQuestionsModal')} 
                    animation
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.itemTitle} Questions</Modal.Title>
                        <Row>
                            <Col xs={4}>
                                <InputField type='select' className='select-box' id="category-filter" onChange={e=> {this.filterByCategory(e)}}>
                                    {this.generateCategoriesList()}
                                </InputField>
                            </Col>
                            <Col xs={8}>
                                <div className={`search-box${this.state.queryFilter ? ' dirty' : ''}${this.state.searchboxFocused ? ' focus' : ''}`}>
                                    <InputField 
                                        placeholder='Search' 
                                        autoFocus
                                        value={this.state.queryFilter}
                                        autoComplete="off"
                                        type="searchbox" 
                                        maxLength="50" 
                                        id="query-filter"
                                        onFocus={()=> {
                                            this.handleFocusChange()
                                        }}
                                        onBlur={()=> {
                                            this.handleFocusChange()
                                        }}
                                        onChange={e=> this.filterByQuery(e)}
                                        clearSearch={()=> {
                                            this.setState({
                                                queryFilter: "",
                                            })
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Modal.Header>
                    <Modal.Body>
                        {this.generateInteractionsList()}
                        {this.state.queryFilter && this.state.filteredList.length === 0 && <div className="alert-box">
                            No questions found.
                        </div>}
                    </Modal.Body>
                    <Modal.Footer>
                        <span className='pull-left'>
                            <strong>{selectedItems}</strong> of {totalItems} questions selected
                        </span>
                        {
                            interactionFilter && 
                            <span 
                                className='pull-left'>&nbsp;<strong>|</strong> Filtered by: <OverlayTrigger placement='top' overlay={this.getTooltip('delete', `Remove ${interactionFilter.length < 15 ? interactionFilter : 'this'} filter`)} onClick={this.filterByCategory}><strong className="filter">{interactionFilter && interactionFilter.slice(0, 15)}</strong></OverlayTrigger>
                            </span>
                        }
                            <button className='primary-btn pull-right' disabled={!this.state.selectedInteractions.length} onClick={(e)=> {e.currentTarget.blur(); this.addQuestion(e)}}>Add to {this.smartConciergeLevel} Guest Book</button>
                    </Modal.Footer>
                </Modal>
                <Modal show={discardModal} onHide={()=>this.hideModal('discardModal')}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            All <strong>selected</strong> questions will be lost. Are you sure you want to continue?
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={12} className="top-margin">
                                <PrimaryButton
                                    onClick={()=> this.discard('discardModal')}
                                    cssClass="pull-right"
                                    fullWidth={false}
                                >
                                    Yes
                                </PrimaryButton>
                                <PrimaryButton 
                                    cssClass='white-btn pull-right' 
                                    fullWidth={false} 
                                    onClick={()=> this.hideModal('discardModal')}
                                >
                                    No
                                </PrimaryButton>
                            </Col>
                        </Row>
                    </Modal.Body>
				</Modal>
                <Modal 
                    show={similarQuestionsModal} 
                    onHide={() => 
                        this.hideModal('similarQuestionsModal')
                    }
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Similar Questions for {cInteraction.description}</Modal.Title>
                        <h5>Similarly phrased questions will yield the same answer:</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            {cInteraction.similar_questions && 
                            cInteraction.similar_questions.length && 
                            cInteraction.similar_questions.map((sQuestion, i) =>
                            <Col md={12} key={i}><strong>{sQuestion}</strong></Col>)}
                         </Row>
                    </Modal.Body>
                    <Modal.Header { ...{ style: { paddingTop: "0px", } } }>
                        <Modal.Title>Synonyms</Modal.Title>
                        <h5>Similar words used in the above questions will yield the same answer:</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            {cInteraction.synonyms && 
                            cInteraction.synonyms.length && 
                            cInteraction.synonyms.map((synonym, i) => 
                                <Col md={4} key={i}>
                                    <span className="capitalize"><strong>{synonym}</strong></span>
                                </Col>
                            )}
                        </Row>
                    </Modal.Body>
                </Modal>
                <Modal show={deleteModal} onHide={()=> this.hideModal('deleteModal')}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.state.activeInteraction.status ? 'This question is published! Are you sure you would like to continue?' : 'Are you sure you would like to delete this question?'}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={12} className="top-margin">
                                <PrimaryButton
                                    onClick={()=> this.onConfirm('deleteModal')}
                                    cssClass="pull-right"
                                    fullWidth={false}
                                >
                                    Yes
                                </PrimaryButton>
                                <PrimaryButton 
                                    cssClass='white-btn pull-right' 
                                    fullWidth={false} 
                                    onClick={()=> this.hideModal('deleteModal')}
                                >
                                    No
                                </PrimaryButton>
                            </Col>
                        </Row>
                    </Modal.Body>
				</Modal>
                <Modal show={overwriteModal} onHide={()=> this.hideModal('overwriteModal')}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {`You are overwriting a question configured at a higher level (${capitalizeFirstLetter(this.state.activeInteraction.level)}) for this ${capitalizeFirstLetter(configureLevel(this.state.levelConfig.level))}. Would you like to continue?`}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={12} className="top-margin">
                                <PrimaryButton
                                    onClick={e => {this.addQuestion(e)}}
                                    cssClass="pull-right"
                                    fullWidth={false}
                                >
                                    Yes
                                </PrimaryButton>
                                <PrimaryButton 
                                    cssClass='white-btn pull-right' 
                                    fullWidth={false} 
                                    onClick={()=> {
                                        document.getElementById('save-answer').blur()
                                        this.hideModal('overwriteModal')}
                                    }
                                >
                                    No
                                </PrimaryButton>
                            </Col>
                        </Row>
                    </Modal.Body>
				</Modal>
                <Modal show={previewAudioModal} onHide={()=>this.hideModal('previewAudioModal')}>
                    {
                        !this.state.currentAudio.length && <Fragment>
                            <Modal.Header closeButton={this.state.audioLoading === false && this.state.currentAudio.length === 0}>
                                <Modal.Title>
                                    Preview
                                </Modal.Title>
                                {this.state.audioLoading === true ? <Modal.Body>
                                    <Row>
                                        <div className="submit-audio-loader">
                                            <Loader />
                                        </div>  
                                    </Row>
                                </Modal.Body> :
                                    <Fragment>
                                        <br/>
                                        <span>
                                            {this.state.audioError}
                                        </span>
                                    </Fragment>}
                            </Modal.Header>
                        </Fragment>
                    }
                    {
                        this.state.audioLoading === false && this.state.currentAudio.length > 0 && <Fragment>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    Preview
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                <audio id="Player" autoPlay={this.state.audioLoading === false && this.state.currentAudio.length > 0} controls style={{ width: "100%"}}>
                                    <source src={this.state.currentAudio} type="audio/mp3"></source>
                                </audio>
                                <br/>
                                <Col xs={12}>
                                    {activeInteraction && 
                                    activeInteraction.interaction_response && 
                                    activeInteraction.interaction_response.replace(/<p>/g, "").replace(/<\/p>/g,'').length ? 
                                        this.parseInteractionResponse(Object.entries(activeInteraction).length !== 0 ? activeInteractionResponsePreviewMessage : '') :
                                        <p>No Response Found</p>
                                    }                               
                                </Col>
                                </Row>
                            </Modal.Body>
                        </Fragment>
                    }
				</Modal>
                <Modal show={unpublishedModal} 
                       onHide={() => this.hideModal('unpublishedModal')}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            You cannot publish a question without an answer. Please provide an answer in the empty field.
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={12} className="top-margin">
                                <PrimaryButton
                                    onClick={()=> this.hideModal('unpublishedModal')}
                                    cssClass="pull-right"
                                    fullWidth={false}
                                >
                                    OK
                                </PrimaryButton>
                            </Col>
                        </Row>
                    </Modal.Body>
				</Modal>
            </PageLayout>
        ) : (
        <Loader/>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.authState.user,
        roleManager: state.roleManager.roles,
        orgTitle: state.roleManager.orgTitle,
        orgId: state.roleManager.orgId,
        orgName: state.roleManager.org,
        isInternalPropertyAdmin: state.roleManager.isInternalPropertyAdmin,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setRole: (role) => {
            dispatch(setRole(role));
        },
        updateOrganization: (org) => {
            dispatch(updateOrganization(org))
        },
        updateOrganizationTitle: (org) => {
            dispatch(updateOrganizationTitle(org))
        },
        updateOrganizationId: (orgId) => {
            dispatch(updateOrganizationId(orgId))
        },
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(SmartConcierge));
