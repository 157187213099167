import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import { InputField } from '../global/forms/FormElements';

import { FlexibleXYPlot } from 'react-vis/dist/make-vis-flexible';
import XAxis from 'react-vis/dist/plot/axis/x-axis';
import YAxis from 'react-vis/dist/plot/axis/y-axis';
import VerticalBarSeries from 'react-vis/dist/plot/series/vertical-bar-series';
import Hint from 'react-vis/dist/plot/hint';
import HorizontalGridLines from 'react-vis/dist/plot/horizontal-grid-lines';

import Loader from '../global/Loader';

class DashboardEarnings extends Component {
    breadcrumbItems = [
        { title: "Reports" },
        { title: "Earnings" },
    ];

    state = {
        graphHeight: 350,
        activeKey: "1",
        tooltip: null,
    };

    /** Set tooltip on graph to show value in graph. */
    setTooltip = (tooltip) => {
        this.setState({tooltip})
    }
    /** Remove tooltip remove tooltip from graph */
    removeTooltip = () => {
        this.setState({
            tooltip: null
        })
    }
    /** Format x axis for assign label and format date */
    formatXAxis = (t) => {
        const { last30Days } = this.props;
        let label = t;

        if(t==1 || t==32) {
            return ''
        };

        Object.keys(last30Days).map(date=>{
            if(last30Days[date].x==t) {
                let mDate = moment(date);
                let day = mDate.format('D');
                label = (day==1||day==15)?mDate.format('Do MMM') : mDate.format('Do')
            }
        });

        return label
    }

    render() {
        const { 
            graphHeight,
            tooltip
        } = this.state;

        const {
            org,
            allOrganizations,
            handleShowAllOrg,
            showAllOrgEarnings,
            graphData,
            getEarnings,
            earningsIsLoading
        } = this.props;

        return(
            <div className="earnings">

                {earningsIsLoading && <Loader />}
                
                <Row>
                    <Col md={12} sm={12}>
                        <Col md={6} sm={6} xs={6} className="left">
                            <h2>
                                Earnings
                            </h2>
                        </Col>
                        <Col md={6} sm={6} xs={6} className="right">
                            {
                                allOrganizations.length > 1 && 
                                org !== '_global_' && 
                                org !== 'travelagencies' ? 
                                <div className="filters-wrap">
                                    <InputField 
                                        type="checkbox" 
                                        value={showAllOrgEarnings} 
                                        cbChange={() => handleShowAllOrg("showAllOrgEarnings", getEarnings)}>
                                        All Organizations
                                    </InputField>
                                </div> : 
                                ''
                            }
                        </Col>
                    </Col>
                </Row>
                <Row>
                    <div className="chart-container">
                        <FlexibleXYPlot 
                            height={graphHeight}
                        >
                            <XAxis 
                                tickLabelAngle={-90} 
                                tickFormat={this.formatXAxis} 
                                tickTotal={graphData.length}
                            />
                            <YAxis tickFormat={t=>`$${t}`}/>
                            <HorizontalGridLines/>
                            <VerticalBarSeries
                                onValueMouseOver={this.setTooltip}
                                onValueMouseOut={this.removeTooltip}
                                data={graphData} 
                                color='#69c' 
                            />
                            {
                                tooltip ?
                                <Hint value={tooltip}>
                                    <div className="rv-hint__content">
                                        <span>{`$${tooltip.y.toFixed(2)}`}</span>
                                    </div>
                                </Hint>: null
                            }
                        </FlexibleXYPlot>
                    </div>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        org: state.roleManager.org
    }
}

export default connect(
    mapStateToProps,
    null
)(DashboardEarnings);