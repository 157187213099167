import React from 'react';
import Switch from 'react-router-dom/Switch';
import Route from 'react-router-dom/Route';
import Redirect from 'react-router-dom/Redirect';
import Properties from './views/Properties.js';
import Account from './views/Account.js';
import Pricing from './views/Pricing.js';
import Collection from './views/Collection.js';
import Rooms from './views/Rooms.js';
import AddCollection from './views/AddCollection';
import EditProperty from './views/EditProperty.js';
import {ManageWebsite} from './views/ManageWebsite';
import AddProperty from './views/AddProperty';
import AddRoom from './views/AddRoom';
import PropertyReservationListing from './views/PropertyReservationListing.js';
import PropertyReservationDetail from './views/PropertyReservationDetail.js'
import ReportProblemReservation from './views/ReportProblemReservation';
import CreateReservation from './views/CreateReservation.js';
import DashboardEarnings from './views/DashboardEarnings';
import DashboardRevenue from './views/DashboardRevenue';
import ReportsPerformance from './views/ReportsPerformance';
import ReportsChannels from './views/ReportsChannels';
import ReportsTaxes from './views/ReportsTaxes';
import ReportsAvailability from './views/ReportsAvailability';
import ReportsValidations from './views/ReportsValidations';
import User from './views/User';
import SmartConcierge from './views/SmartConcierge';
import EditRoom from './views/EditRoom';
import Organizations from './views/Organizations.js';
import AddOrganization from './views/AddOrganization.js';
import OrganizationDetails from './views/OrganizationDetails.js';
import Users from './views/Users.js';
import EditUser from './views/EditUser.js';
import Promotions from './views/Promotions.js';
import AddPromotion from './views/AddPromotion.js';
import EditPromotion from './views/EditPromotion.js';
import Payments from './views/Payments.js';
import FeesSchedule from './views/FeesSchedule';
import AddFeeSchedule from './views/AddFeeSchedule';
import Cleaning from './views/Cleaning';
import Faq from './views/Faq';
import Dashboard from './views/Dashboard';
import KnowledgeBot from './views/KnowledgeBot';
import SupportTicketsSearch from './views/SupportTicketsSearch.js';

const Components = {
  Properties,
  Account,
  Pricing,
  EditProperty,
  ManageWebsite,
  AddProperty,
  PropertyReservationListing,
  PropertyReservationDetail,
  ReportProblemReservation,
  CreateReservation,
  DashboardEarnings,
  DashboardRevenue,
  ReportsPerformance,
  ReportsChannels,
  ReportsTaxes,
  ReportsAvailability,
  ReportsValidations,
  User,
  Collection,
  Rooms,
  AddCollection,
  AddRoom,
  EditRoom,
  SmartConcierge,
  Organizations,
  AddOrganization,
  OrganizationDetails,
  Users,
  EditUser,
  Promotions,
  AddPromotion,
  EditPromotion,
  Payments,
  FeesSchedule,
  AddFeeSchedule,
  Cleaning,
  Faq,
  Dashboard,
  KnowledgeBot,
  SupportTicketsSearch
}
let getRoleBasedRoutes = (role) => {
  let paths = Object.keys(role.config);
  if(paths.length){
    return paths.map((route,id)=>{
      return <Route key={id+1} exact path={route} component={Components[role.config[route]]}/>
    }).concat(<Route key={0} exact path='/' render={() => (<Redirect to={paths[0]} />)}/>)
  }
}

export const Main = (props) => {
  let routes = getRoleBasedRoutes(props.role);
  return (
        <main 
          className={props.mobileMode ? 'mobile' : 'desktop'}
        >
          <Switch>
            <Route exact path='/collections' render={props => {
              return <Redirect to={'/collections/id' + (props.location.search)} />}
              }  />
              <Route path='/pricing' render={props => {
                let pathname = props.location.pathname
                return (
                  <Redirect to={pathname && pathname.replace('pricing', 'calendar') + (props.location.search ? props.location.search : '')} />
                )
              }}  
            />
            {routes}
            <Route path='/account/profile' component={Account} />
            <Route path='/reports/validations' component={ReportsValidations} />
            <Route path='/reports/performance' component={ReportsPerformance} />
            <Route path='/reports/channels' component={ReportsChannels} />
            <Route path='/reports/taxes' component={ReportsTaxes} />
            <Route path='/reports/availability' component={ReportsAvailability} />
            <Route path='/accounts/payments' component={Payments} />
            {/* {APIConfig.ENV=="DEV" && <Route path='/api' component={Api}/>} */}
            <Route render={() => <Redirect to='/account/profile' />} />
          </Switch>
        </main>
        )
      }
