import React, { Fragment, Component } from 'react';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import ProgressBar from 'react-bootstrap/lib/ProgressBar';
import Modal from 'react-bootstrap/lib/Modal';
import Link from 'react-router-dom/Link';
import Loader from '../../global/Loader';
import Panel from 'react-bootstrap/lib/Panel';
import PanelGroup from 'react-bootstrap/lib/PanelGroup';
import { withRouter } from 'react-router';

class GlobalSubmitModal extends Component {
    initialFail = false;
    state ={
        errorActiveKey: "1"
    }

    handleContinue = (e) => {
        e.preventDefault();
        this.props.history.push(this.props.continueUrl);
        this.props.closeModal();
    }

    render() {
        this.initialFail = false;

        let defaultValue = 100 / this.props.submitModalTotal;
        let progress = Math.floor(defaultValue * this.props.submitStatus);
 
        let progressStyle = 'success';

        if(this.props.error && this.props.error.show) {
            progressStyle = 'warning';
            if(this.props.submitStatus === 1) {
                this.initialFail = true;
            }
            if(this.initialFail && this.props.mode !== 'edit') {
                progress = 100;
                progressStyle = 'danger';
            }
        }

        let closeButton = {};

        if(progress == 100) {
            closeButton = {
                closeButton: true
            };
        };

        return (
            <Modal 
                backdrop='static' 
                className={`submit-modal ${this.props.className ? this.props.className : ""}`}
                show={this.props.activeModal === 'submit-modal'} 
                onHide={this.props.closeModal}
            >
                <Modal.Header {...closeButton}>
                    <Modal.Title>
                        {
                            progress == 100 ? 
                            (!this.props.error.show ? 
                                <span className='status-icon status-icon--success'/>
                                : 
                                <span className={`status-icon status-icon${this.props.mode === "edit" ? "--warning" : "--error"}`}/>)
                                : 
                                <div className="submit-loader">
                                    <Loader />
                                </div>
                        }
                        <span 
                            className="submit-heading">
                            {
                                this.props.error && this.props.error.show ? 
                                'Error' : 
                                this.props.progressMessage
                            }
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12} 
                            className="bottom-margin"
                        >
                            <div 
                                className="upload-status"
                            >
                                <div className='progress-wrapper'>
                                    <ProgressBar 
                                        bsStyle={progressStyle} 
                                        now={progress} 
                                    />
                                    <h5 className='progress-percent'>
                                        {Math.floor(progress)}%
                                    </h5>
                                </div>
                                {
                                    this.props.error.message.length ?
                                    <p className="progress-error">
                                        {this.props.error.message}
                                    </p> 
                                    : ""
                                }
                                {
                                    this.props.error.detail.length ?
                                    <PanelGroup 
                                        accordion id="errorMessage" 
                                        onSelect={errorActiveKey => this.setState({ errorActiveKey })}
                                        defaultActiveKey="1"
                                    >
                                        <Panel eventKey="1">
                                            <Panel.Heading>
                                                <Panel.Title 
                                                    toggle
                                                >
                                                    Error Message
                                                    <span 
                                                        className={
                                                            `pull-right ${this.state.errorActiveKey === '1' ? 
                                                            'glyphicon glyphicon-chevron-up': 
                                                            'glyphicon glyphicon-chevron-down'}`}
                                                    >
                                                    </span>
                                                </Panel.Title>
                                                <Panel.Body collapsible>
                                                    {
                                                        this.props.error.detail.map((err, idx) => {
                                                            return (
                                                                <Fragment key={idx}>
                                                                    <pre>
                                                                        <code>
                                                                            {JSON.stringify(err)}
                                                                        </code>
                                                                    </pre>
                                                                </Fragment>
                                                            )
                                                        }) 
                                                    }
                                                </Panel.Body>
                                            </Panel.Heading> 
                                        </Panel>
                                    </PanelGroup>
                                    : ""
                                }
                            </div>
                        </Col>
                    </Row>
                    {progress == 100 &&
                        <Row>
                            <Col xs={12} 
                                className="top-margin"
                            >     
                                {
                                    !this.initialFail && 
                                    this.props.showViewAll &&
                                    <Link
                                        to={this.props.viewAllUrl} 
                                        className='primary-btn white-btn'>
                                        {this.props.viewAllLabel}
                                    </Link>
                                }
                                {
                                    !this.initialFail && 
                                    <button
                                        type="button"
                                        onClick={this.handleContinue} 
                                        className='primary-btn left-margin'>
                                        Continue Editing
                                    </button>
                                }  
                            </Col>
                        </Row>
                    }
                </Modal.Body>
            </Modal>
        )
    }
}

export default withRouter(GlobalSubmitModal);