import React from 'react';
import moment from 'moment';
import Week from 'react-big-calendar/lib/Week';
import {segStyle, inRange, eventSegments, endOfRange, eventLevels} from 'react-big-calendar/lib/utils/eventLevels';
import EventRow from 'react-big-calendar/lib/EventRow';
import { ShowMoreRow } from './ShowMoreRow';
import dates from 'react-big-calendar/lib/utils/dates';
import { Cell } from './Cell';
import Link from 'react-router-dom/Link';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import _ from "lodash";

const CELL_WIDTH = 145;
Week.range=(date, ref)=> {
    let mDate=moment(date),
        start,end;
    start= date//mDate.startOf('week').toDate();
    end= mDate.add(13,'days')//mDate.add(1,'weeks').endOf('week').toDate();
    return dates.range(start,end)
}

export class WeekView extends Week  {
    constructor(props) {
        super(props);
        this.range = Week.range(this.props.date,this.props);
        this.cellStyle = segStyle(1,this.range.length);
        this.eventAccessor = props.eventAccessor;
        this.calendarWidth = {
            width: (this.range.length * CELL_WIDTH) + 'px'
        }
        this.setRefs = this.setRefs.bind(this);
        this.cellRefs = {};
    }
    /**
     * Get filtered events from event accessor access events.
     */
    getFilteredEvents(i) {
        let refs=this.props,
            evts=refs.events,
            range=this.range,
            start=range[0],
            end=range[range.length-1],
            first=endOfRange(range).first,
            last=endOfRange(range).last,
            filteredEvts=[],
            segments=[],
            levels=[];
        this.eventAccessor.forEach(acc=>{
            if(evts[i] && evts[i][acc])
            filteredEvts= filteredEvts.concat(evts[i][acc].filter(e=>{
                let period_end = e && (e.period_end || e.period && e.period.period_end),
                exception = period_end && moment(this.props.today).isSame(period_end)
                /* remove custom mobile rep blocks from desktop view */
                if(e && (e.period && e.period.type=="BlockedByBooking" || e.mobile_block_id))
                    return false
                if(exception)
                    return true
                return inRange(e,start,end,refs)
            }));
        })
        filteredEvts.forEach(evt=>{
            if(evt.period_start) {
                evt.period_start=moment(evt.period_start).hours(12)
                evt.period_end=moment(evt.period_end).hours(12)
            }
            if(evt.start_date) {
                evt.start_date=moment(evt.start_date).hours(12)
                evt.end_date=moment(evt.end_date).hours(12)
            }
            if(evt.period) {
                evt.period.period_start=moment(evt.period.period_start).hours(12)
                evt.period.period_end=moment(evt.period.period_end).hours(12)
            }
        })
        segments=filteredEvts.map(e=>{
            return eventSegments(e,first,last,refs,range);
        })
        let priceSegments = segments.filter(seg=>{
            return (seg.event.price_period_id || seg.event.cico_id ) ? true:false
        })
        let otherSegments = segments.filter(seg=>{
            return (!seg.event.price_period_id && !seg.event.cico_id) ? true:false
        })
        let priceLevels = eventLevels(priceSegments,2)
        let otherLevels = eventLevels(otherSegments,2)
        levels = {
            levels: priceLevels.levels.concat(otherLevels.levels),
            extra: priceLevels.extra.concat(otherLevels.extra)
        }
        return {
            segments: segments,
            levels: levels,
            first: first,
            last: last,
            filteredEvts: filteredEvts
        }
    }
    /**
     * Invoke when click on show more.
     * @param {*} slot 
     * @param {*} segments 
     * @param {*} propId 
     */
    onShowMore(slot, segments,propId) {
        let _props = Object.assign({},this.props);
        let events = segments.filter(seg=>{
            return seg.left <= slot && seg.right >= slot;
        }).map(evt=>{
            return evt.event
        });
        let el = this.cellRefs[propId][this.range[slot-1]];
        let bounds = el.getBoundingClientRect();
        _props = Object.assign(_props,{
                events: events,
                dayHeaderFormat: "D",
                position: {
                    top: bounds.top + window.scrollY,
                    left: bounds.left + (bounds.width/2),
                    width: 120
                },
            slotStart: this.range[slot-1],
            EventComponent: 'EventCell',
            eventWrapperComponent: this.props.components.eventWrapper,
            onSelect: (evt,e)=>this.props.onSelectEvent(evt,e,propId)
        })
        this.props.handleShowMore(_props)
    }

    setRefs(ref,date,propId) {
        let propRef = this.cellRefs[propId] || {
            propId
        }
        propRef[date] = ref
        this.cellRefs[propId] = propRef
    }
    /**
     * Render different properties rows with dates.
     * @param {*} properties 
     */
    renderPropertyColumn(properties) {
        return (
            <div className='property-column'>
                <div className='property-cont'>
                    <span>Property</span>
                </div>
                {
                    properties.map((item, id)=>{
                        let groupId = item[this.props.groupAccessor];
                        const externalPropertyId = _.get(item, 'external_property_id');

                        return (
                            <div key={id} className='property-cont'>
                                <Link 
                                    to={{pathname:`/calendar/${groupId}/edit`}} 
                                >
                                    <span 
                                        style={{ fontWeight: "normal" }}>
                                        Property ID:
                                    </span>
                                    <br/>
                                        <span><strong>{groupId}</strong></span>
                                    <br/>
                                    {   
                                        externalPropertyId ?
                                        <div>
                                            <span 
                                                style={{ fontWeight: "normal" }}>
                                                Reference ID:
                                            </span>
                                            <br/>
                                            <OverlayTrigger 
                                                placement='right' 
                                                overlay={
                                                    <Tooltip 
                                                        className="org-modal__tooltip" 
                                                        id='property_id'
                                                    >
                                                        {externalPropertyId}
                                                    </Tooltip>
                                                }>
                                                <span>
                                                    <strong>
                                                        {externalPropertyId.length > 23 ? externalPropertyId.substring(0, 24) + "..." : externalPropertyId}
                                                    </strong>
                                                </span>
                                            </OverlayTrigger>
                                            <br/>
                                        </div>
                                        : ''
                                    }
                                    <span 
                                        style={{ fontWeight: "normal" }}>
                                        Property:
                                    </span>
                                    <br/>
                                    {
                                        _.get(item, 'content.location.address.street_address') ?
                                        <OverlayTrigger 
                                            placement='right' 
                                            overlay={
                                                <Tooltip 
                                                    className="org-modal__tooltip" 
                                                    id='property_location'
                                                >
                                                    {_.get(item, 'content.location.address.street_address')} 
                                                    {_.get(item, 'content.location.address.street_address2')}
                                                </Tooltip>
                                            }>
                                            <span>
                                                <strong>
                                                    {_.get(item, 'content.title')}
                                                </strong>
                                            </span>
                                        </OverlayTrigger>
                                        :
                                        <span>
                                            <strong>
                                                {_.get(item, 'content.title')}
                                            </strong>
                                        </span>
                                    }
                                    <br/>
                                </Link>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    /**
     * Render header of pricing page. 
     */
    renderHeader() {
        let headerStyle = this.cellStyle;
        return (
            <div className='rbc-row rbc-month-header' style={this.calendarWidth}>
                {
                    this.range.map((date,i)=>{
                        let dateObj=moment(date);
                        return (                                
                            <div style={headerStyle} className='rbc-header' key={i}>
                                <span className='day-header'>{dateObj.format('ddd')}</span>
                                <span className='date-header'>{dateObj.format('D')}</span>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    render() {
        let properties = this.props.events,_this=this;
        this.range = Week.range(this.props.date,this.props);

        return (
            <div className='rbc-month-view multi-view' ref='monthNode'>
                {this.renderPropertyColumn(properties)}
                <div className='calendar-column'>
                    {this.renderHeader()}
                    <div className='days-wrapper' style={this.calendarWidth}>
                    {
                        properties.map((item,i) => {
                            let events = this.getFilteredEvents(i),
                                groupId = item[_this.props.groupAccessor];
                            return(
                                <div className='rbc-month-row' key={i} >
                                    <div className='rbc-row-bg'>
                                        <Cell 
                                            {..._this.props} 
                                            currency={item.price? item.price.currency: 'USD'} 
                                            handleRefs={this.setRefs} 
                                            filteredEvts={events.filteredEvts} 
                                            propId={groupId} 
                                            range={_this.range} 
                                            cellStyle={_this.cellStyle}
                                            propertyData={item}
                                        />
                                    </div>
                                    <div className='rbc-row-content'>
                                        <div className='rbc-row property-row'>
                                            <div className='rbc-date-cell' style={_this.cellStyle}>
                                                
                                            </div>
                                        </div>
                                        {
                                            events.levels.levels.map((segs,i)=>{
                                                segs = segs.map((data) => {
                                                    if(data) 
                                                        data.event.currency = item.price? item.price.currency: 'USD';
                                                    return data
                                                })
                                                return (
                                                    <EventRow
                                                        {..._this.props}
                                                        onSelect={(evt,e)=>_this.props.onSelectEvent(evt,e,groupId)}
                                                        key={i}
                                                        start={events.first}
                                                        end={events.last}
                                                        segments={segs}
                                                        slots={_this.range.length}
                                                        EventComponent='month'
                                                        eventWrapperComponent={_this.props.components.eventWrapper}
                                                        />
                                                )
                                            })
                                        }                 
                                        { !!events.levels.extra.length &&
                                            <ShowMoreRow
                                                {..._this.props}
                                                start={events.first}
                                                end={events.last}
                                                segments={events.levels.extra}
                                                slots={_this.range.length}
                                                EventComponent='month'
                                                eventWrapperComponent={_this.props.components.eventWrapper}
                                                onShowMore={slot=>this.onShowMore(slot,events.levels.extra,groupId)}
                                            />
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                    </div>
                </div>       
            </div>
        )
    }
}


