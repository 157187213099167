import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Picky from "react-picky";
import { API } from "aws-amplify";
import { Editor } from "@tinymce/tinymce-react";
import OverlayTrigger from "react-bootstrap/lib/OverlayTrigger";
import Tooltip from "react-bootstrap/lib/Tooltip";
import Dropzone from "react-dropzone";
import axios from "axios";
import Switch from "react-switch";

import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import Modal from "react-bootstrap/lib/Modal";
import {
	PrimaryButton,
	InputLabel,
	InputField
} from "../global/forms/FormElements";
import moment from "moment";

import Loader from "../global/Loader";
import Pdf from "../../assets/icons/icon-pdf.png";
import File from "../../assets/icons/icon-file.png";
import ImageFile from "../../assets/icons/icon-image-file.png";
import Docs from "../../assets/icons/icon-docs.png";
import Sheets from "../../assets/icons/icon-sheets.png";

import constants from "../../js/constants";
import { delay } from "../../js/actions";

class EditTemplates extends Component {
	triggerConjunction = [
		{ name: "triggerConjunction", value: "before", label: "Before" },
		{ name: "triggerConjunction", value: "after", label: "After" }
	];
	triggerEvent = [
		{ name: "triggerEvent", value: "checkin", label: "Checkin" },
		{ name: "triggerEvent", value: "checkout", label: "Checkout" },
		{ name: "triggerEvent", value: "booking", label: "Booking" }
	];
	initPropertyCommData = {
		alarm_code: "",
		wifi_name: "",
		wifi_password: ""
	};
	editorRef = null;
	state = {
		defaultTemplate: [],
		templates: [],
		templateTitle: "",
		templateSubject: "",
		templatePickyValue: "",
		templateActions: [],
		templateContext: {},
		rteTags: [],
		previewHTML: "",
		customMsg: "",
		attachments: [],
		previewError: false,
		initIsLoading: true,
		templateIsLoading: false,
		templateTrigger: {
			time: "86400",
			timeUnits: "days",
			conjunction: "before",
			event: "checkin"
		},
		currentAttachment: {},
		editTemplate: false,
		customFields: [],
		propertyCommData: {
			alarm_code: "",
			wifi_name: "",
			wifi_password: ""
		}
	};

	async componentDidMount() {
		this.setState({
			initIsLoading: true
		});

		if (this.props.propertyId !== "global") {
			await this.parseCommData();
		}

		this.getCommDataFields();

		await this.getTemplates();

		await this.getTemplateActions();

		if (this.state.templateTrigger.conjunction == "before") {
			this.triggerEvent.forEach((event, i) => {
				if (event.value == "booking") {
					this.triggerEvent.splice(i, 1);
				}
			});
		}

		this.setState({
			initIsLoading: false
		});
	}

	async componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.propertyId !== this.props.propertyId ||
			_.get(prevProps, "org") != _.get(this.props, "org")
		) {
			this.initPropertyCommData = {
				alarm_code: "",
				wifi_name: "",
				wifi_password: ""
			};
			this.setState(
				{
					initIsLoading: true,
					defaultTemplate: [],
					templates: [],
					templateTitle: "",
					templateSubject: "",
					templatePickyValue: "",
					templateActions: [],
					templateContext: {},
					rteTags: [],
					previewHTML: "",
					customMsg: "",
					attachments: [],
					previewError: false,
					templateTrigger: {
						time: "86400",
						timeUnits: "days",
						conjunction: "before",
						event: "checkin"
					},
					editTemplate: false,
					propertyCommData: {
						alarm_code: "",
						wifi_name: "",
						wifi_password: ""
					}
				},
				async () => {
					if (this.props.propertyId !== "global") {
						await this.parseCommData();
					}

					this.getCommDataFields();

					await this.getTemplates();

					await this.getTemplateActions();

					if (this.state.templateTrigger.conjunction == "before") {
						this.triggerEvent.forEach((event, i) => {
							if (event.value == "booking") {
								this.triggerEvent.splice(i, 1);
							}
						});
					}

					this.setState({
						initIsLoading: false
					});
				}
			);
		}
	}

	parseCommData = async () => {
		let respCustomFields = await this.getCommDataCustomFields();
		let respCommData = await this.getCommData();

		let data = {
			alarm_code: _.get(respCommData, "alarm_code") || "",
			wifi_name: _.get(respCommData, "wifi_name") || "",
			wifi_password: _.get(respCommData, "wifi_password") || ""
		};

		if (respCustomFields && !_.isEmpty(respCustomFields)) {
			respCustomFields.forEach(field => {
				if (_.get(field, "name") && _.get(field, "custom_field")) {
					data[field.name] = _.get(respCommData, `${[field.name]}`) || "";
				}
			});
		}

		this.setState(
			{
				customFields: respCustomFields,
				propertyCommData: data
			},
			() => {
				this.initPropertyCommData = this.state.propertyCommData;
			}
		);
	};

	getCommDataCustomFields = async () => {
		let resp;

		try {
			resp = await API.get(
				"gbapi",
				`/organizations/${this.props.org}/commdata-custom-fields`
			);
		} catch (e) {}

		return resp;
	};

	getCommDataFields = async () => {
		let resp;

		try {
			resp = await API.get(
				"gbapi",
				`/organizations/${this.props.org}/commdata-fields`
			);
			if (resp && !_.isEmpty(resp)) {
				function formatTags(data, type) {
					let exclude = ["amenities", "photos"];
					let tags = [];

					data.forEach(x => {
						if (!exclude.includes(x.name)) {
							tags.push({
								label: x.title,
								value: `{{${type}.${x.name}}} `
							});
						}
					});

					return tags;
				}

				let reservationTag = [];
				let propertyTag = [];

				if (_.get(resp, "reservation")) {
					reservationTag = formatTags(resp.reservation, "reservation");
				}

				if (_.get(resp, "property")) {
					propertyTag = formatTags(resp.property, "property");
				}

				this.setState({
					rteTags: {
						reservation: reservationTag,
						property: propertyTag
					}
				});
			}
		} catch (e) {}
	};

	getCommData = async () => {
		let resp;

		try {
			resp = await API.get(
				"gbapi",
				`/properties/${this.props.propertyId}/commdata`
			);
		} catch (e) {}

		return resp;
	};

	getTemplates = async () => {
		let resp = [];
		try {
			resp = await API.get(
				"ramapi",
				`/commhub/organizations/${this.props.org}/templates`
			);
			if (resp.length) {
				let defaultTemplate = resp.filter(
					template =>
						(template.name && template.name.toLowerCase()) == "generic message"
				);

				this.setState({
					defaultTemplate: defaultTemplate
				});
			}
		} catch (e) {
			console.log(e);
		}
	};

	getTemplateActions = async () => {
		let resp = [];
		let url =
			this.props.propertyId == "global" || !this.props.propertyId
				? `/commhub/organizations/${this.props.org}/actions`
				: `/commhub/properties/${this.props.propertyId}/actions`;

		try {
			resp = await API.get("ramapi", url);
			if (resp.length) {
				let newTemplateActions = [];
				let orgActions =
					this.props.propertyId == "global" || !this.props.propertyId
						? resp.filter(action => !action.property_id)
						: resp;
				let sortedActions = orgActions.sort(function(a, b) {
					let nameA = a.title.toLowerCase();
					let nameB = b.title.toLowerCase();

					if (nameA < nameB) {
						return -1;
					}
					if (nameA > nameB) {
						return 1;
					}
					return 0;
				});

				sortedActions.forEach(action => {
					let actionCopy = action;

					if (
						action &&
						action.customizable_field_values &&
						action.customizable_field_values.length
					) {
						action.customizable_field_values.forEach(
							(customField, customIndex) => {
								if (!customField.value) {
									actionCopy.customizable_field_values[customIndex].value = "";
								}
							}
						);
					}

					newTemplateActions.push({
						label: action.title,
						value: action.message_action_id,
						data: actionCopy
					});
				});

				newTemplateActions.push({
					label: "Create New Message",
					value: "createNewMessage",
					data: {
						title: "",
						templates: this.state.defaultTemplate,
						trigger_id: 1,
						offset_secs: "-86400",
						offset_event: "checkin",
						enabled: true,
						attachments: [],
						owner_organization_name: this.props.org,
						customizable_field_values: []
					}
				});

				this.setState({
					templateActions: newTemplateActions
				});
			}
		} catch (err) {
			console.log(err);
		}
	};

	uploadAttachments = async () => {
		const { attachments } = this.state;
		let resp;
		let body = [];

		attachments.forEach(attachment => {
			body.push({
				filename: attachment.name,
				mime_type: attachment.type
			});
		});

		try {
			resp = await API.post("ramapi", `/commhub/attachments`, { body: body });

			if (resp && resp.length) {
				let newResp = [...resp];

				resp.forEach((x, i) => {
					attachments.forEach(y => {
						if (x.filename == y.name) {
							newResp[i] = {
								...newResp[i],
								file: y
							};
						}
					});
				});

				for (let i = 0; i < newResp.length; i++) {
					try {
						axios
							.put(newResp[i].uri, newResp[i].file, {
								headers: {
									filename: newResp[i].file.name,
									"content-type": newResp[i].file.type
								}
							})
							.catch(error => {
								console.error(error);
							});
					} catch (e) {
						console.log(e, "Error Storing to S3");
					}
					await delay(1500);
				}
			}
		} catch (err) {
			console.log(err);
		}

		return resp;
	};

	handleStatus = bool => {
		const { templatePickyValue } = this.state;

		this.setState({
			templatePickyValue: {
				...templatePickyValue,
				data: {
					...templatePickyValue.data,
					enabled: bool
				}
			}
		});
	};

	resetPropertyCommData = () => {
		this.setState({
			propertyCommData: this.initPropertyCommData
		});
	};

	savePropertySettings = async () => {
		const { propertyCommData, templatePickyValue } = this.state;
		let resp;

		this.setState({
			templateIsLoading: true
		});

		try {
			resp = await API.put(
				"gbapi",
				`/properties/${this.props.propertyId}/commdata`,
				{ body: propertyCommData }
			);
			if (resp && !_.isEmpty(resp)) {
				await this.parseCommData();

				if (!_.isEmpty(templatePickyValue)) {
					await this.getTemplateContext();

					await this.previewTemplate();
				}

				this.setState({
					templateIsLoading: false
				});
			}
		} catch (err) {
			console.log(err);
			this.setState({
				templateIsLoading: false
			});
		}
	};

	saveTemplateActions = async () => {
		const {
			templatePickyValue,
			customMsg,
			attachments,
			templateTrigger,
			templateTitle,
			templateSubject
		} = this.state;
		const time =
			templateTrigger.timeUnits == "days"
				? moment.duration(templateTrigger.time, "days")
				: moment.duration(templateTrigger.time, "hours");
		const timeAsSeconds = time.asSeconds();
		let offsetSec = timeAsSeconds;
		const customField =
			templatePickyValue &&
			templatePickyValue.data &&
			templatePickyValue.data.customizable_field_values;
		const specialMessageType = customField.filter(
			value => value.name == "special_message"
		);
		const builtIn =
			templatePickyValue &&
			templatePickyValue.data &&
			templatePickyValue.data.config &&
			templatePickyValue.data.config.built_in;

		if (templateTrigger.conjunction == "before") {
			offsetSec = timeAsSeconds * -1;
		}

		let body = {
			...templatePickyValue.data,
			subject: templateSubject,
			customizable_field_values: [
				{
					name:
						specialMessageType.length ||
						templatePickyValue.value == "createNewMessage"
							? "special_message"
							: "gen_text",
					value: customMsg
				}
			]
		};

		if (templatePickyValue.data.offset_event !== null) {
			body.offset_secs = offsetSec;
			body.offset_event = templateTrigger.event;
		}

		if (templatePickyValue.value == "createNewMessage") {
			body.title = templateTitle;
		}

		if (templatePickyValue.value == "createNewMessage" || !builtIn) {
			body.subject = templateSubject;
		}

		this.setState({
			templateIsLoading: true
		});

		let attachResp;
		if (attachments.length) {
			attachResp = await this.uploadAttachments();

			if (attachResp.length) {
				body.attachments = [
					...templatePickyValue.data.attachments,
					...attachResp
				];
			}
		}

		let url =
			this.props.propertyId == "global" || !this.props.propertyId
				? `organizations/${this.props.org}`
				: `properties/${this.props.propertyId}`;

		let resp;
		try {
			resp = await API[
				templatePickyValue.value == "createNewMessage" ? "post" : "put"
			](
				"ramapi",
				`/commhub/${url}/actions${
					templatePickyValue.value == "createNewMessage"
						? ""
						: `/${templatePickyValue.data.message_action_id}`
				}`,
				{ body: body }
			);

			if (Object.keys(resp).length > 0) {
				await this.getTemplateActions();

				this.setState(
					{
						templateTitle: resp && resp.title,
						templateSubject: resp && resp.subject,
						templatePickyValue: {
							label: resp.title,
							value: resp.message_action_id,
							data: resp
						},
						attachments: []
					},
					async () => {
						await this.getTemplateContext();

						await this.previewTemplate();

						this.setState({
							templateIsLoading: false
						});
					}
				);
			}
		} catch (err) {
			console.log(err);
			this.setState({
				templateIsLoading: false
			});
		}
	};

	blobToHtml = async blob => {
		if (!blob) return;

		let htmlContent = "";

		await new Promise((resolve, reject) => {
			const reader = new FileReader();

			reader.onload = (function() {
				return function(e) {
					htmlContent = e.target.result;
					resolve();
				};
			})(blob);
			reader.readAsText(blob);
		});

		this.setState({
			previewHTML: htmlContent
		});
	};

	handleModal = (e, name) => {
		e.preventDefault();

		this.setState({
			modal: name
		});
	};

	deleteTemplate = async () => {
		const { templatePickyValue } = this.state;

		this.setState({
			templateIsLoading: true,
			modal: ""
		});

		let url =
			this.props.propertyId == "global" || !this.props.propertyId
				? `organizations/${this.props.org}`
				: `properties/${this.props.propertyId}`;

		let resp;
		try {
			resp = await API.del(
				"ramapi",
				`/commhub/${url}/actions/${templatePickyValue.data.message_action_id}`,
				{ response: true }
			);

			await this.getTemplateActions();

			const { templateActions } = this.state;

			this.setState(
				{
					templatePickyValue: templateActions[0]
				},
				async () => {
					await this.previewTemplate();

					this.setState({
						templateIsLoading: false
					});
				}
			);
		} catch (e) {
			console.log(e);
			this.setState({
				templateIsLoading: false
			});
		}
	};

	previewTemplate = async previewBtn => {
		const { templateContext, templatePickyValue, customMsg } = this.state;
		let resp;
		let templateHTML = "";
		let specialMessage = [];
		const customField =
			templatePickyValue &&
			templatePickyValue.data &&
			templatePickyValue.data.customizable_field_values;
		const template =
			templatePickyValue &&
			templatePickyValue.data &&
			templatePickyValue.data.templates &&
			templatePickyValue.data.templates[0];
		const specialMessageType = customField.filter(
			value => value.name == "special_message"
		);
		const duration = moment.duration(
			Math.abs(templatePickyValue.data.offset_secs),
			"seconds"
		);

		this.setState(
			{
				previewError: false,
				templateTrigger: {
					time:
						Math.abs(templatePickyValue.data.offset_secs) >= "86400"
							? duration.asDays()
							: duration.asHours(),
					timeUnits:
						Math.abs(templatePickyValue.data.offset_secs) >= "86400"
							? "days"
							: "hours",
					conjunction: templatePickyValue.data.offset_secs
						? templatePickyValue.data.offset_secs < 0
							? "before"
							: "after"
						: "before",
					event: templatePickyValue.data.offset_secs
						? templatePickyValue.data.offset_event
							? templatePickyValue.data.offset_event
							: "booking"
						: "checkin"
				}
			},
			() => {
				if (this.state.templateTrigger.conjunction == "before") {
					this.triggerEvent.forEach((event, i) => {
						if (event.value == "booking") {
							this.triggerEvent.splice(i, 1);
						}
					});
				}
			}
		);

		templateHTML = await this.getTemplate(template.template_id);

		let body = {
			template: templateHTML,
			mime_type: template.mime_type,
			context: {
				...templateContext
			}
		};

		if (previewBtn && customMsg) {
			body.context.custom = {
				special_message:
					specialMessageType.length ||
					templatePickyValue.value == "createNewMessage"
						? customMsg
						: this.editorRef.getContent({ format: "text" })
			};
		} else if (customField.length) {
			specialMessage = customField.filter(value => {
				return value.name == "special_message" || value.name == "gen_text";
			});

			body.context.custom = {
				special_message: specialMessage.length ? specialMessage[0].value : ""
			};
		}

		try {
			resp = await API.post("ramapi", `/commhub/templates/renders`, {
				responseType: "blob",
				body: body,
				response: true
			});

			this.blobToHtml(resp.data);

			if (!previewBtn) {
				this.setState({
					customMsg: specialMessage.length ? specialMessage[0].value : ""
				});
			}
		} catch (err) {
			this.setState({
				previewHTML: "",
				previewError: true
			});
			console.log(err);
		}
	};

	getTemplateContext = async () => {
		let resp;
		try {
			resp = await API.get(
				"ramapi",
				`/commhub/organizations/${this.props.org}/contexts?view=preview${
					this.props.propertyId == "global" || !this.props.propertyId
						? ""
						: `&nid=${this.props.propertyId}`
				}`
			);
			if (resp && !_.isEmpty(resp)) {
				this.setState({
					templateContext: resp
				});
			}
		} catch (err) {
			console.log(err);
		}
	};

	getTemplate = async id => {
		let resp = "";
		let resp2 = "";
		let templateHtml = "";

		try {
			resp = await API.get(
				"ramapi",
				`/commhub/organizations/${this.props.org}/templates${
					id ? `/${id}/?download_type=s3` : ""
				}`
			);
			if (resp) {
				resp2 = await axios.get(resp.uri);
				templateHtml = resp2.data;
			}
		} catch (err) {
			console.log(err);
		}

		return templateHtml;
	};

	templatesOnChange = value => {
		this.setState(
			{
				templatePickyValue: value,
				templateTitle: "",
				editTemplate: false
			},
			async () => {
				const { templatePickyValue } = this.state;
				let data = templatePickyValue && templatePickyValue.data;

				if (this.editorRef) {
					if (this.editorRef.editorManager) {
						this.editorRef.editorManager.execCommand(
							"mceRemoveEditor",
							true,
							"tinymce"
						);
						this.editorRef.editorManager.execCommand(
							"mceAddEditor",
							true,
							"tinymce"
						);
					}
				}

				this.setState({
					templateIsLoading: true
				});

				await this.getTemplateContext();

				await this.previewTemplate();

				this.setState({
					templateSubject: (data && data.subject) || "",
					attachments: [],
					templateIsLoading: false
				});
			}
		);
	};

	handlePreviewTemplate = async () => {
		this.setState({
			templateIsLoading: true
		});

		await this.previewTemplate("preview_btn");

		this.setState({
			templateIsLoading: false
		});
	};

	handleTemplateMsg = value => {
		this.setState({
			customMsg: value
		});
	};

	addAttachment = file => {
		const { attachments } = this.state;
		let newAttachments = [...attachments];

		newAttachments.push(file[0]);

		this.setState({
			attachments: newAttachments
		});
	};

	removeAttachment = (e, index) => {
		e.preventDefault();
		const { attachments } = this.state;
		let copyAttachments = [...attachments];

		copyAttachments.splice(index, 1);

		this.setState({
			attachments: copyAttachments
		});
	};

	formatFileSize = fileSize => {
		const units = ["B", "KB", "MB", "GB", "TB"];
		let size = fileSize;
		let unitIndex = 0;

		while (size >= 1024 && unitIndex < units.length - 1) {
			size /= 1024;
			unitIndex++;
		}

		return Math.round(size) + units[unitIndex];
	};

	getTooltip = (id, text) => {
		return (
			<Tooltip id={id} className="properties__tooltip">
				{text}
			</Tooltip>
		);
	};

	renderNewAttachments = () => {
		const { attachments } = this.state;

		return (
			<div className="new-attachments">
				{attachments.map((attach, i) => {
					return (
						<div className="new-attach-container" key={i}>
							<span className="new-attach">
								<span>
									<span className="new-attach-name">
										{attach && attach.name && attach.name.length > 20
											? attach.name.substring(0, 20) + "..."
											: attach.name}
									</span>
									<span className="attach-file-size">{`(${this.formatFileSize(
										attach.size
									)})`}</span>
								</span>
								<OverlayTrigger
									placement="top"
									overlay={this.getTooltip(
										"remove-attachment",
										"Remove Attachment"
									)}
								>
									<span
										className="new-attach-icon"
										onClick={e => this.removeAttachment(e, i)}
									>
										<i className="icon-Cross"></i>
									</span>
								</OverlayTrigger>
							</span>
						</div>
					);
				})}
			</div>
		);
	};

	handleDeleteAttachment = (e, data) => {
		console.log(data);
		e.preventDefault();

		this.setState({
			modal: "delete-attachment-prompt",
			currentAttachment: data
		});
	};

	deleteAttachment = async () => {
		const { templatePickyValue, currentAttachment } = this.state;

		this.setState({
			modal: "",
			templateIsLoading: true
		});

		let url =
			this.props.propertyId == "global" || !this.props.propertyId
				? `organizations/${this.props.org}`
				: `properties/${this.props.propertyId}`;
		let resp;

		const filteredAttachments = templatePickyValue.data.attachments.filter(
			x => x.attachment_id !== currentAttachment.attachment_id
		);

		let body = {
			...templatePickyValue.data,
			attachments: filteredAttachments
		};

		try {
			resp = await API.put(
				"ramapi",
				`/commhub/${url}/actions/${templatePickyValue.data.message_action_id}`,
				{ body: body }
			);

			if (Object.keys(resp).length > 0) {
				await this.getTemplateActions();

				this.setState(
					{
						templateTitle: resp && resp.title,
						templateSubject: resp && resp.subject,
						templatePickyValue: {
							label: resp.title,
							value: resp.message_action_id,
							data: resp
						},
						attachments: []
					},
					async () => {
						await this.previewTemplate();

						this.setState({
							templateIsLoading: false
						});
					}
				);
			}
		} catch (err) {
			console.log(err);
			this.setState({
				templateIsLoading: false
			});
		}
	};

	handleTemplateTrigger = e => {
		const name = e.target.name;
		const value = e.target.value;

		if (value == "before") {
			this.triggerEvent.forEach((event, i) => {
				if (event.value == "booking") {
					this.triggerEvent.splice(i, 1);
				}
			});
		} else if (value == "after") {
			this.triggerEvent.push({
				name: "triggerEvent",
				value: "booking",
				label: "Booking"
			});
		}

		this.setState({
			templateTrigger: {
				...this.state.templateTrigger,
				[name]: value
			}
		});
	};

	handleTemplateOnChange = e => {
		const name = e.target.name;
		const value = e.target.value;

		this.setState({
			[name]: value
		});
	};

	toggleRadio = e => {
		const { templateTrigger } = this.state;
		let name = e.target.name;
		let value = e.target.value;

		this.setState({
			templateTrigger: {
				...templateTrigger,
				[name]: value
			}
		});
	};

	resetTemplateData = () => {
		this.setState({
			templatePickyValue: "",
			previewHTML: "",
			editTemplate: false
		});
	};

	editTemplate = () => {
		const { editTemplate } = this.state;

		this.setState({
			editTemplate: !editTemplate
		});
	};

	handlePropertySettingsData = e => {
		const { propertyCommData } = this.state;
		const name = e.target.name;
		const value = e.target.value;

		this.setState({
			propertyCommData: {
				...propertyCommData,
				[name]: value
			}
		});
	};

	navigate = (e, url) => {
		if (e) {
			e.stopPropagation();
		}
		const data = { dashboard: true };
		const newWindow = window.open(url, "_blank");

		newWindow.data = data;
	};

	comparePropertySettings = () => {
		const { propertyCommData } = this.state;

		return !_.isEqual(this.initPropertyCommData, propertyCommData);
	};

	renderCustomFields = () => {
		const { customFields, propertyCommData } = this.state;

		if (!_.isEmpty(customFields)) {
			return customFields.map((field, i) => {
				return (
					<Col key={i} sm={12} md={6} className="bottom-margin-10">
						<InputLabel>{_.get(field, "title", "")}</InputLabel>
						<InputField
							type="text"
							value={propertyCommData[_.get(field, "name", "")]}
							name={_.get(field, "name", "")}
							onChange={this.handlePropertySettingsData}
						></InputField>
					</Col>
				);
			});
		}
	};

	render() {
		const {
			modal,
			initIsLoading,
			templateActions,
			templatePickyValue,
			customMsg,
			previewHTML,
			previewError,
			templateIsLoading,
			templateTrigger,
			templateTitle,
			templateSubject,
			currentAttachment,
			editTemplate,
			propertyCommData
		} = this.state;
		const requiredContexts =
			templatePickyValue &&
			templatePickyValue.data &&
			templatePickyValue.data.templates &&
			templatePickyValue.data.templates[0] &&
			templatePickyValue.data.templates[0].required_contexts;
		const templateAttachments =
			templatePickyValue &&
			templatePickyValue.data &&
			templatePickyValue.data.attachments;
		const allowHostDisable =
			templatePickyValue &&
			templatePickyValue.data &&
			templatePickyValue.data.config &&
			templatePickyValue.data.config.allow_host_disable;
		const builtIn =
			templatePickyValue &&
			templatePickyValue.data &&
			templatePickyValue.data.config &&
			templatePickyValue.data.config.built_in;

		let triggerTimeOptions = [];
		constants.TEMPLATE_SEND_MESSAGE.forEach(type => {
			triggerTimeOptions.push(
				<option key={type.value} value={type.value}>
					{type.label}
				</option>
			);
		});

		let triggerConjunctionOptions = [];
		this.triggerConjunction.forEach(function(type) {
			triggerConjunctionOptions.push(
				<option key={type.value} value={type.value}>
					{type.label}
				</option>
			);
		});

		let triggerEventOptions = [];
		this.triggerEvent.forEach(function(type) {
			triggerEventOptions.push(
				<option key={type.value} value={type.value}>
					{type.label}
				</option>
			);
		});
		return (
			<Col md={12} className="top-margin">
				<div className="template-panel">
					{templateIsLoading && <Loader />}

					<Row>
						{!initIsLoading && this.props.propertyId !== "global" && (
							<Col sm={12} md={12}>
								<Row>
									<Col sm={12} md={6} className="bottom-margin-10">
										<InputLabel>Property Settings:</InputLabel>
									</Col>
								</Row>
								<Row>
									<Col sm={12} md={6} className="bottom-margin-10">
										<InputLabel>Wifi Name</InputLabel>
										<InputField
											type="text"
											value={propertyCommData.wifi_name}
											name="wifi_name"
											onChange={this.handlePropertySettingsData}
										></InputField>
									</Col>
									<Col sm={12} md={6} className="bottom-margin-10">
										<InputLabel>Wifi Password</InputLabel>
										<InputField
											type="text"
											value={propertyCommData.wifi_password}
											name="wifi_password"
											onChange={this.handlePropertySettingsData}
										></InputField>
									</Col>
								</Row>
								<Row>
									<Col sm={12} md={6} className="bottom-margin-10">
										<InputLabel>Alarm Code</InputLabel>
										<InputField
											type="text"
											value={propertyCommData.alarm_code}
											name="alarm_code"
											onChange={this.handlePropertySettingsData}
										></InputField>
									</Col>
									{this.renderCustomFields()}
								</Row>
								{this.comparePropertySettings() && (
									<Row>
										<Col sm={12} md={12} className="bottom-margin top-margin">
											<PrimaryButton
												cssClass="pull-right"
												fullWidth={false}
												type="button"
												onClick={this.savePropertySettings}
											>
												Save
											</PrimaryButton>
											<PrimaryButton
												cssClass="white-btn pull-right right-margin"
												fullWidth={false}
												type="button"
												onClick={this.resetPropertyCommData}
											>
												Cancel
											</PrimaryButton>
										</Col>
									</Row>
								)}
							</Col>
						)}
						<Col md={6} className="bottom-margin-20">
							<InputLabel>Automated Messages:</InputLabel>
							<div className="msg-template-container">
								<Picky
									placeholder="filter by"
									valueKey="value"
									labelKey="label"
									filterPlaceholder="Filter"
									options={templateActions}
									value={templatePickyValue}
									multiple={false}
									includeSelectAll={false}
									defaultFocusFilter={true}
									keepOpen={false}
									onChange={value => this.templatesOnChange(value)}
									dropdownHeight={600}
									render={({
										style,
										item,
										isSelected,
										selectValue,
										labelKey,
										valueKey
									}) => {
										return (
											<li
												style={{ ...style }}
												className={isSelected ? "selected" : ""}
												key={item[valueKey]}
												onClick={() => selectValue(item)}
											>
												<span
													style={{ fontWeight: isSelected ? "bold" : "normal" }}
												>
													{item[labelKey]}
													{"    "}
													{item[valueKey] == "createNewMessage" ? (
														<i className="icon-Plus create-icon"></i>
													) : (
														""
													)}
												</span>
											</li>
										);
									}}
								/>
								{initIsLoading && <Loader />}
							</div>
						</Col>
					</Row>
					<Row>
						{editTemplate && (
							<Col md={6} className="no-padding">
								{templatePickyValue &&
									templatePickyValue.label &&
									templatePickyValue.label.length > 0 && (
										<Fragment>
											{templatePickyValue.value == "createNewMessage" && (
												<Fragment>
													<Col md={12} className="bottom-margin-10">
														<InputLabel>Title</InputLabel>
														<InputField
															type="text"
															value={templateTitle}
															name="templateTitle"
															onChange={this.handleTemplateOnChange}
														></InputField>
													</Col>
												</Fragment>
											)}
											{(!builtIn ||
												templatePickyValue.value == "createNewMessage") && (
												<Col md={12} className="bottom-margin-10">
													<InputLabel>Subject</InputLabel>
													<InputField
														type="text"
														value={templateSubject}
														name="templateSubject"
														onChange={this.handleTemplateOnChange}
													></InputField>
												</Col>
											)}
											{((templatePickyValue &&
												templatePickyValue.data &&
												templatePickyValue.data.offset_secs !== 0) ||
												templatePickyValue.value == "createNewMessage") && (
												<Fragment>
													<Col md={12} className="bottom-margin-20">
														<Col md={4} className="trigger-time">
															<div>
																<InputLabel>Send Message</InputLabel>
																<InputField
																	type="text"
																	value={templateTrigger.time}
																	name="time"
																	onChange={this.handleTemplateTrigger}
																></InputField>
															</div>
															<div className="trigger-time-radio-container">
																<label className="radio-wrap radio-input">
																	<input
																		type="radio"
																		className="form-radio-label radio-label"
																		name="timeUnits"
																		onChange={e => this.toggleRadio(e)}
																		value={"days"}
																		checked={
																			templateTrigger.timeUnits == "days"
																		}
																	/>
																	<div
																		className="radio-label"
																		style={{ marginLeft: "35px" }}
																	>
																		Day(s)
																	</div>
																</label>
																<label className="radio-wrap radio-input">
																	<input
																		type="radio"
																		className="form-radio-label radio-label"
																		name="timeUnits"
																		onChange={e => this.toggleRadio(e)}
																		value={"hours"}
																		checked={
																			templateTrigger.timeUnits == "hours"
																		}
																	/>
																	<div
																		className="radio-label"
																		style={{ marginLeft: "35px" }}
																	>
																		Hour(s)
																	</div>
																</label>
															</div>
														</Col>
														<Col md={4} className="trigger-conjunction">
															<InputField
																type="select"
																value={templateTrigger.conjunction}
																name="conjunction"
																onChange={this.handleTemplateTrigger}
															>
																{triggerConjunctionOptions}
															</InputField>
														</Col>
														<Col md={4} className="trigger-event">
															<InputLabel>Event</InputLabel>
															<InputField
																type="select"
																value={templateTrigger.event}
																name="event"
																onChange={this.handleTemplateTrigger}
															>
																{triggerEventOptions}
															</InputField>
														</Col>
													</Col>
												</Fragment>
											)}
											<Col md={12} className="flex-space-between">
												<InputLabel>Custom Message</InputLabel>
												{allowHostDisable && (
													<div className="template-status-container">
														<span className="status-text">
															{templatePickyValue.data.enabled
																? "Enabled"
																: "Disabled"}
														</span>
														<Switch
															onColor={"#5AD888"}
															uncheckedIcon={false}
															checkedIcon={false}
															height={20}
															width={45}
															checked={templatePickyValue.data.enabled}
															onChange={bool => this.handleStatus(bool)}
														/>
													</div>
												)}
											</Col>
											<Col md={12}>
												<Editor
													id="tinymce"
													onInit={(evt, editor) => (this.editorRef = editor)}
													init={{
														height: 500,
														menubar: false,
														plugins: [
															"advlist autolink lists link image charmap print preview anchor",
															"searchreplace visualblocks code fullscreen",
															"insertdatetime media table paste code help wordcount"
														],
														toolbar:
															"undo redo | formatselect | " +
															"bold italic underline forecolor backcolor link | alignleft aligncenter " +
															"alignright alignjustify | bullist numlist outdent indent | addTag",
														content_style:
															"body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
														block_formats:
															"Paragraph=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6;",
														resize: false,
														toolbar_mode: "wrap",
														statusbar: false,
														setup: editor => {
															if (requiredContexts.length) {
																editor.ui.registry.addMenuButton("addTag", {
																	type: "menubutton",
																	text: "Add Tag",
																	icon: "plus",
																	fetch: callback => {
																		const { rteTags } = this.state;
																		let items = [];

																		let compiledTags = [];

																		requiredContexts.forEach(type => {
																			if (rteTags[type]) {
																				compiledTags = [
																					...compiledTags,
																					...rteTags[type]
																				];
																			}
																		});

																		compiledTags.sort((a, b) =>
																			a.label.localeCompare(b.label)
																		);

																		compiledTags.forEach(tag => {
																			items.push({
																				type: "menuitem",
																				text: tag.label,
																				onAction: function() {
																					editor.insertContent(tag.value);
																				}
																			});
																		});

																		callback(items);
																	}
																});
															}
														}
													}}
													onEditorChange={this.handleTemplateMsg}
													value={customMsg}
												/>
												{this.renderNewAttachments()}
											</Col>
											<Col md={12}>
												{this.props.propertyId !== "global" ? (
													<div>
														Changes here apply to Property{" "}
														{this.props.propertyId} - {this.props.propertyTitle}
														.
													</div>
												) : (
													<div>
														To update messaging per property select
														"Communication & Access By Property" above.
													</div>
												)}
											</Col>
											<Col md={12} className="top-margin-20 template-btn-bar">
												<div className="left-bar">
													{templatePickyValue &&
														templatePickyValue.data &&
														templatePickyValue.data.owner_organization_name ==
															this.props.org &&
														templatePickyValue.value !== "createNewMessage" && (
															<OverlayTrigger
																placement="top"
																overlay={this.getTooltip(
																	"delete-template",
																	"Delete Template"
																)}
															>
																<span
																	onClick={e =>
																		this.handleModal(e, "delete-prompt")
																	}
																>
																	<i className="icon-Delete"></i>
																</span>
															</OverlayTrigger>
														)}
												</div>
												<div className="right-bar">
													<div className="add-attachment">
														<OverlayTrigger
															placement="top"
															overlay={this.getTooltip(
																"add-attachment",
																"Attach Files"
															)}
														>
															<div>
																<Dropzone
																	multiple={true}
																	accept={constants.ACCEPTED_FILE_TYPES.join(
																		","
																	)}
																	onDrop={this.addAttachment}
																	className="attach-dropzone"
																></Dropzone>
																<i className="icon-attachment"></i>
															</div>
														</OverlayTrigger>
													</div>
													{templatePickyValue.value == "createNewMessage" && (
														<PrimaryButton
															cssClass="white-btn pull-left left-margin"
															fullWidth={false}
															type="button"
															onClick={this.resetTemplateData}
														>
															Cancel
														</PrimaryButton>
													)}
													<PrimaryButton
														cssClass="white-btn pull-left left-margin"
														fullWidth={false}
														type="button"
														onClick={this.handlePreviewTemplate}
													>
														Preview
													</PrimaryButton>
													<PrimaryButton
														cssClass="pull-left left-margin"
														fullWidth={false}
														type="button"
														onClick={this.saveTemplateActions}
														disabled={
															(!templateTitle.length ||
																!templateSubject.length) &&
															templatePickyValue.value == "createNewMessage"
														}
													>
														Save
													</PrimaryButton>
												</div>
											</Col>
											<Col md={12} className="top-margin-20">
												{templateAttachments.length > 0 && (
													<Col
														xs={12}
														sm={12}
														className="attachments margin-top"
													>
														<div className="attach-title">
															{templateAttachments.length} Attachments:
														</div>
														{templateAttachments.map((attachment, i) => {
															let imgSrc = File;

															if (attachment.mime_type.includes("pdf")) {
																imgSrc = Pdf;
															} else if (
																attachment.mime_type.includes("xls") ||
																attachment.mime_type.includes("xlm")
															) {
																imgSrc = Sheets;
															} else if (
																attachment.mime_type.includes("txt") ||
																attachment.mime_type.includes("doc")
															) {
																imgSrc = Docs;
															} else if (
																attachment.mime_type.includes("image") ||
																attachment.mime_type.includes("png") ||
																attachment.mime_type.includes("jpeg")
															) {
																imgSrc = ImageFile;
															}

															return (
																<Col xs={4} sm={4} key={i}>
																	<OverlayTrigger
																		placement="top"
																		overlay={this.getTooltip(
																			"filename",
																			`${attachment.filename}`
																		)}
																	>
																		<div className="attach-border">
																			<span
																				className="delete-attachment-container"
																				onClick={e =>
																					this.handleDeleteAttachment(
																						e,
																						attachment
																					)
																				}
																			>
																				<i className="icon-Cross"></i>
																			</span>
																			<a
																				className="attach-container"
																				href={attachment.uri}
																				target="_blank"
																			>
																				<div className="attach-icon-container">
																					<img src={imgSrc}></img>
																				</div>
																				<div className="attach-file-name">
																					{attachment &&
																					attachment.filename &&
																					attachment.filename.length > 15
																						? attachment.filename.substring(
																								0,
																								15
																						  ) + "..."
																						: attachment.filename}
																				</div>
																			</a>
																		</div>
																	</OverlayTrigger>
																</Col>
															);
														})}
													</Col>
												)}
											</Col>
										</Fragment>
									)}
							</Col>
						)}
						<Col md={editTemplate ? 6 : 12}>
							{(previewError ||
								(!templateIsLoading && previewHTML.length > 0)) && (
								<Fragment>
									<div className="flex-space-between">
										<span
											className="clickable-text bottom-margin-5 sbold-text"
											onClick={this.editTemplate}
										>
											{editTemplate ? "Return to Preview" : "Edit Message"}
										</span>
										<span
											className="clickable-text bottom-margin-5 sbold-text"
											onClick={this.resetTemplateData}
										>
											Close
										</span>
									</div>
									<div className="template-wrap">
										<div
											dangerouslySetInnerHTML={{ __html: previewHTML }}
										></div>
										{previewError && (
											<div>
												An Error Occurred While Rendering the Template. Please
												Contact Customer Service for Assistance.
											</div>
										)}
									</div>
								</Fragment>
							)}
						</Col>
					</Row>
					<Modal show={modal == "delete-prompt"}>
						<Modal.Header></Modal.Header>
						<Modal.Body>
							<Row className="text-center bottom-margin">
								<Col xs={12}>
									<h2>
										Delete{" "}
										{`"${templatePickyValue &&
											templatePickyValue.data &&
											templatePickyValue.data.title}"`}
									</h2>
									<h2>Do you want to continue?</h2>
								</Col>
							</Row>
							<Row>
								<Col xs={12}>
									<PrimaryButton
										onClick={this.deleteTemplate}
										cssClass="pull-right"
										fullWidth={false}
									>
										Yes
									</PrimaryButton>
									<PrimaryButton
										cssClass="white-btn pull-right right-margin"
										fullWidth={false}
										onClick={e => this.handleModal(e, "")}
									>
										No
									</PrimaryButton>
								</Col>
							</Row>
						</Modal.Body>
					</Modal>
					<Modal show={modal == "delete-attachment-prompt"}>
						<Modal.Header></Modal.Header>
						<Modal.Body>
							<Row className="text-center bottom-margin">
								<Col xs={12}>
									<h2>
										Delete Attachment{" "}
										{`"${_.get(currentAttachment, "filename")}"`}
									</h2>
									<h2>Do you want to continue?</h2>
								</Col>
							</Row>
							<Row>
								<Col xs={12}>
									<PrimaryButton
										onClick={this.deleteAttachment}
										cssClass="pull-right"
										fullWidth={false}
									>
										Yes
									</PrimaryButton>
									<PrimaryButton
										cssClass="white-btn pull-right right-margin"
										fullWidth={false}
										onClick={e => this.handleModal(e, "")}
									>
										No
									</PrimaryButton>
								</Col>
							</Row>
						</Modal.Body>
					</Modal>
				</div>
			</Col>
		);
	}
}

const mapStateToProps = state => {
	return {
		org: state.roleManager.org
	};
};

export default withRouter(connect(mapStateToProps, null)(EditTemplates));
