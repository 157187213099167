import React, { Component, Fragment } from 'react';
import { FieldWrap, InputLabel, InputField, PrimaryButton } from '../../global/forms/FormElements';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import Aux from '../../hoc/AuxComp';

/**
 * Address card component show Address of properties.
 * @class
 */
export class AddressCard extends Component {
    render() {
        let { data, handleChangeAddress, countries, isAddressPopulated, admin, type, edit, addManually, isFetching, fetchError } = this.props,
            address1 = (data.location.address['street_address'] ? (data.location.address['street_address']) : "")
                        + (data.location.address['street_address2'] ? (", " + data.location.address['street_address2']) : ""),
            address2 = (data.location.address['city'] ? (data.location.address['city'] + ", ") : "")
                        + (data.location.address['province'] ? (data.location.address['province'] + " ") : "")
                        + (data.location.address['postal_code'] ? (data.location.address['postal_code']) : "")
                        + (data.location.address['country'] ? (", " + data.location.address['country']) : "")
        return(
            <Row>
                <Col xs={12}>
                    <InputLabel
                        id="address"
                        htmlFor="add-input"
                        className={(this.props.formValidation.address || this.props.formValidation.longLatRequired) && 'form-validation'}
                    >
                        {type} Address
                    </InputLabel>
                </Col>
                    <Col xs={12}>
                        <InputField 
                            id="add-input" 
                            type="text" 
                            placeholder="Search Address" 
                            name="search_address"
                            className={(this.props.formValidation.address || this.props.formValidation.longLatRequired) && 'form-validation'}
                        />
                        {!isAddressPopulated && admin && <PrimaryButton cssClass="blue-btn btn-add-manually" fullWidth={false} onClick={this.props.addManually} type="button">Add Manually</PrimaryButton>}
                        {this.props.formValidation.address && <span className="required-error-text">Property Address is required</span>}
                        {this.props.formValidation.longLatRequired && <span className="required-error-text">A valid address is required.</span>}
                    </Col>
                    <Col xs={12}>
                        <div className={`address-card rows-section ${edit||isAddressPopulated?"":"hidden"}`}>
                            {!edit && <Row>
                                <Col xs={11}>
                                    <span className="address-card__label">{address1}</span>
                                    <span>{address2}</span>
                                </Col>
                                {admin && <Col xs={1} className="pull-right">
                                    <OverlayTrigger placement='bottom' overlay={<Tooltip id="edit" className='properties__tooltip'>Edit Property Address</Tooltip>}>
                                        <a href="#" onClick={addManually}>
                                            <i className='icon-edit' />
                                        </a>
                                    </OverlayTrigger>
                                </Col>}
                            </Row>}
                            {(edit) && <Aux>
                                <Row>
                                    <Col md={6}>
                                        <InputLabel 
                                            htmlFor="street_address"
                                            className={this.props.formValidation.street_address && 'form-validation'}
                                        >
                                            Address Line 1
                                        </InputLabel>
                                        <InputField 
                                            type="text" 
                                            placeholder="Address Line 1" 
                                            name="street_address" 
                                            value={data.location.address.street_address} 
                                            onChange={handleChangeAddress}
                                            className={this.props.formValidation.street_address && 'form-validation'}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <InputLabel 
                                            htmlFor="street_address2"
                                        >
                                            Address Line 2
                                        </InputLabel>
                                        <InputField 
                                            type="text" 
                                            placeholder="Address Line 2" 
                                            name="street_address2" 
                                            value={data.location.address.street_address2} 
                                            onChange={handleChangeAddress}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <InputLabel 
                                            htmlFor="city"
                                            className={this.props.formValidation.city && 'form-validation'}
                                        >
                                            City
                                        </InputLabel>
                                        <InputField 
                                            type="text" 
                                            value={data.location.address.city} 
                                            placeholder="City" 
                                            name="city" 
                                            onChange={handleChangeAddress} 
                                            id='city'
                                            className={this.props.formValidation.city && 'form-validation'}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <InputLabel 
                                            htmlFor="state"
                                            className={this.props.formValidation.province && 'form-validation'}
                                        >
                                            State/Province
                                        </InputLabel>
                                        <InputField 
                                            type="text" 
                                            value={data.location.address.province} 
                                            placeholder="State/Province" 
                                            name="province" 
                                            id='province' 
                                            onChange={handleChangeAddress}
                                            className={this.props.formValidation.province && 'form-validation'}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <InputLabel 
                                            htmlFor="country"
                                            className={this.props.formValidation.country && 'form-validation'}
                                        >
                                            Country
                                        </InputLabel>
                                        <InputField 
                                            type="select" 
                                            value={data.location.address.country || ''} 
                                            placeholder="Country" 
                                            name="country" 
                                            onChange={handleChangeAddress}
                                            className={this.props.formValidation.country && 'form-validation'}
                                        >
                                            {countries}
                                        </InputField>
                                    </Col>
                                    <Col md={6}>
                                        <InputLabel 
                                            htmlFor="title"
                                        >
                                            Postal Code
                                        </InputLabel>
                                        <InputField 
                                            type="text" 
                                            value={data.location.address.postal_code} 
                                            placeholder="Postal Code" 
                                            name="postal_code" 
                                            onChange={handleChangeAddress}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} className="pull-right">
                                        <PrimaryButton 
                                            cssClass="top-margin pull-right" 
                                            fullWidth={false} 
                                            type="button" 
                                            onClick={this.props.addManually}
                                        >
                                            Apply <span className={'glyphicon glyphicon-refresh'+(isFetching ? ' spinning':' hide')}></span>
                                        </PrimaryButton>
                                    </Col>
                                </Row>
                                {fetchError && <Row>
                                    <Col xs={12} className="pull-right">
                                        <p className="error-msg">
                                            Address coordinates could not be found. Please drag pin on map to approximate location of property / room type.
                                        </p>
                                    </Col>
                                </Row>}
                            </Aux>}
                        </div>
                    </Col>
            </Row>
        )
    }
}