import React, { Component } from 'react';
import Faq from "react-faq-component";
import { withRouter } from 'react-router';

import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import PageLayout from '../global/PageLayout';
import { Title } from '../global/Title';
import { FieldWrap } from '../global/forms/FormElements';
import { faqContent } from '../../helpers/FaqContent';
import VideoModal from '../global/forms/VideoModal';

class FaqComponent extends Component {
    breadcrumbItems = [
        { title: "FAQ" }
    ];
    faqScrollId = this.props.match && this.props.match.params && this.props.match.params.id || ""
    faqScrollNames = [
        { index: 0, name: "building_your_property_listing", value: 0, section: true },
        { index: 0, name: "creating_a_title", value: 0 },
        { index: 0, name: "writing_a_description", value: 1 },
        { index: 0, name: "selecting_your_amenities", value: 2 },
        { index: 0, name: "setting_special_policies", value: 3 },
        { index: 0, name: "pricing_your_unit", value: 4 },

        { index: 1, name: "going_live", value: 0, section: true },
        { index: 1, name: "validations", value: 0 },
        { index: 1, name: "marketing_channels", value: 1 },

        { index: 2, name: "taking_reservations", value: 0, section: true },
        { index: 2, name: "calendar_management", value: 0 },
        { index: 2, name: "charging_for_optional_addons", value: 1 },
        { index: 2, name: "sending_a_rental_agreement", value: 2 },
        { index: 2, name: "rejecting_a_reservation", value: 3 },

        { index: 3, name: "during_the_stay", value: 0, section: true },
        { index: 3, name: "welcoming_guests", value: 0 },

        { index: 4, name: "post_stay", value: 0, section: true },
        { index: 4, name: "guests_issues", value: 0 },
        { index: 4, name: "reviews", value: 1 },
        { index: 4, name: "getting_paid", value: 2 },

        { index: 5, name: "payments", value: 0, section: true },
        { index: 5, name: "stripe_setup", value: 0 },
    ];
    state = {
        videoModal: '',
        videoURL: ''
    }
    faqData = faqContent(this);

    setRowOption = (option, i) => {
        this.faqScrollNames.forEach((data) => {

            if((data.name == this.faqScrollId && i == data.index)) {

                setTimeout(() => {
                    option[data.value].scrollIntoView({
                        block: "center",
                        behavior: "smooth"
                    });
                }, 1000);

                if(!data.section) {
                    setTimeout(() => {
                        option[data.value].expand();
                    }, 1500);
                };
            }
        });
    }

    handleVideoModal = () => {   
        this.setState({
            videoModal: "",
        });
    }
    
    render() {
        const {
            videoModal,
            videoURL
        } = this.state;

        return (
            <PageLayout isCentered={true}>
                <Title 
                    title="FAQ"
                    isCentered={true}
                    breadcrumbItems={this.breadcrumbItems}
                >
                </Title>
                <div id='content' className='faq-component'>
                    <div className="container-fluid">
                        <FieldWrap>
                            {
                                this.faqData.map((data, i) => {
                                    return (
                                        <Row className="faq-container" key={i}>
                                            <Col sm={12} md={12}>
                                                <Faq
                                                    data={data}
                                                    getRowOptions={(option) =>this.setRowOption(option, i)}
                                                />
                                            </Col>
                                        </Row>
                                    )
                                })
                            }
                            <VideoModal
                                modal={videoModal}
                                url={videoURL}
                                closeModal={this.handleVideoModal}
                            />
                        </FieldWrap>
                    </div>
                </div>
            </PageLayout>
        )
    }
}

export default withRouter(FaqComponent);;