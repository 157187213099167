import React, { Component } from 'react';
import { API } from 'aws-amplify';
import Link from 'react-router-dom/Link';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import TableComponent from '../global/TableComponent';
import PageLayout from '../global/PageLayout';
import { Title } from '../global/Title';
import { InputField } from '../global/forms/FormElements';
import constants from '../../js/constants';
import Modal from 'react-bootstrap/lib/Modal';
import DateRangePicker from 'react-dates/lib/components/DateRangePicker';
import moment from 'moment';
import { getSessionId } from '../../helpers/helpers';

const aStyle = {
    'textDecoration': 'underline',
    'cursor': 'pointer'
};
  
class Promotions extends Component {
    breadcrumbItems = [
        { title: "Promotions" }
    ];
    all
    org = this.props.roleManager.org;
    roles = this.props.roleManager.roles;
    allOrgs = this.props.organizations;
    tid = getSessionId();
    state = {
        promotionsList: [],
        isLoading: false,
        pageSize: 10,
        activePage: 0,
        totalCount: 0,
        data: {},
        keyword: '',
        showModal: false,
        status: true,
        modalTitle: 'Organization(s)',
        modalData: [],
        sorted: {
            id: "id",
            desc: true
        },
        promotionsDates: {
            startDate: moment(),
            endDate: null,
        },
        columns: [
            {
                'Header':'Promotion ID',
                'accessor': 'id',
                'sortable': false,
                'width': 120,
                'Cell': row=>(
                    <div>
                        <strong>{row.original.id}</strong>
                    </div>
                )
            },
            {
                'Header':'Booking End',
                'accessor': 'end_date',
                'sortable': false,
                'width': 120,
                'Cell': row=>(
                    <div>
                        {
                            row &&
                            row.original &&
                            row.original.end_date ?
                            moment(row.original.end_date).format("MM/DD/YYYY") :
                            ""
                        }
                    </div>
                )
            },
            {
                'Header':'Organization(s)',
                'accessor': 'organizations',
                'sortable': false,
                'minWidth': 190,
                'Cell': row=>{ 

                    if(row.original.qualify_all_properties ==1) {
                        return "All Organizations";
                    };

                    if(row.original.organizations.length > 5) {
                        let shortlist = row.original.organizations.slice(0,4);

                        return (
                            <div>
                                {
                                    shortlist.map((org) => {
                                        return (<div key={org.organization_name}>{org.organization_name}</div>)
                                    })
                                }
                                <a 
                                    style={aStyle} 
                                    onClick={e => this.toggleModal(e, 'Organizations', row.original.organizations)}
                                >
                                    {row.original.organizations.length} Organizations
                                </a>
                            </div>
                        );
                    };

                    return(row.original.organizations.map((org) => {
                        return (<div key={org.organization_name}>{org.organization_name}</div>)
                    }));

                }
            },       
            {
                'Header':'Properties',
                'accessor': 'properties',
                'minWidth': 190,
                'sortable': false,
                'Cell': row=>{ 

                    if(row.original.qualify_all_properties ==1) {
                        return "All Properties"
                    };

                    if (row.original.properties.length > 5) {
                        let shortlist = row.original.properties.slice(0,4);
                        
                        return(
                            <div>
                                {
                                    shortlist.map((prop) => {
                                        return (<div key={prop.property_id}>{prop.title}</div>)
                                    })
                                }
                                <a 
                                    style={aStyle} 
                                    onClick={e => this.toggleModal(e, 'Properties', row.original.properties)}
                                >
                                    {row.original.properties.length} Properties
                                </a>
                            </div>
                        );
                    };

                    return(row.original.properties.map((prop) => {
                        return (<div key={prop.property_id}>{prop.title}</div>)
                    }));
                }
            },
            {
                'Header':'Channels',
                'accessor': 'channels',
                'sortable': false,
                'minWidth': 190,
                'Cell': row=>{ 
                    if(row.original.channels.length > 5) {
                        let shortlist = row.original.channels.slice(0,4);

                        return(
                            <div>
                                {   
                                    shortlist.map((chan) => {
                                        return (<div key={chan.channel_name}>{chan.channel_name}</div>)
                                    })
                                }
                                <a 
                                    style={aStyle} 
                                    onClick={e => this.toggleModal(e, 'Channels',row.original.channels)}
                                >
                                    {row.original.channels.length} Channels
                                </a>
                            </div>
                        );
                    };

                    return(row.original.channels.map((chan) => {
                        return (<div key={chan.channel_name}>{chan.channel_name}</div>)
                    }));
                }
            },
            {
                'Header':'Status',
                'accessor': 'status',
                'sortable': false,
                'width': 100,
                'Cell': row=>(
                    <div>
                        <strong className={row && row.original && row.original.status === "LIVE" ? 'text-success' : 'text-danger'}>{row.original.status == 'LIVE' ? 'Live' : 'Not Live'  }</strong> 
                    </div>
                )
            },
            {
                'Header':'Actions',
                'width': 75,
                'sortable': false,
                'Cell': row=>{
                    return (
                        <div>
                            <OverlayTrigger
                                placement='bottom'
                                overlay={this.getTooltip('edit',`Update Promotion`)}>
                                <Link
                                    to={`/account-management/promotions/${row.original.id}/edit`}
                                    className='properties__options'>
                                        <i className='icon-edit' />
                                </Link>
                            </OverlayTrigger>
                        </div>
                    )
                }
            }
        ]
    }
    
    async componentDidMount() {
        const params = JSON.parse(sessionStorage.getItem('acc-mgmt-promo'));

        this.mapOrganizations();

        if(params) {
            const { 
                pageSize,
                activePage,
                keyword,
                sorted,
                promotionsDates
            } = this.state;

            this.setState({
                status: params.status == true ? true : false,
                pageSize: params.pageSize || pageSize,
                keyword: params.keyword || keyword,
                sorted: params.sorted || sorted,
                promotionsDates: {
                    startDate: 
                        (params && params.promotionsDates && params.promotionsDates.startDate) ? 
                        moment(params.promotionsDates.startDate) :
                        promotionsDates.startDate,
                    endDate: 
                        (params && params.promotionsDates && params.promotionsDates.endDate) ?
                        moment(params.promotionsDates.endDate) :
                        promotionsDates.endDate,
                }
            }, async () => {
                await this.fetchPromotions(params.activePage != 0 ? (params.activePage - 1) : activePage);
            });
        }
        else {
            await this.fetchPromotions(0);
        };
    }

    componentWillUnmount() {
        this.setSessionStorage();
    }

    mapOrganizations = async () => {
        let newOrgMap = new Map();

        newOrgMap.set('property_manager', { title: 'Smart Site', name: 'Smart Site' });
        
        this.allOrgs.forEach(function(org) {
            newOrgMap.set(org.organization_id, { title: org.title, name: org.organization_name });
        });

        this.setState({
            organizationMap: newOrgMap,
        });
    }

    fetchPromotions = async (active_page, sort, page_size) => {
        const { 
            pageSize,
            keyword,
            sorted,
            promotionsDates,
            status
        } = this.state;

        const startDate = moment(promotionsDates.startDate).format("YYYY-MM-DD");
        const endDate = 
            moment(promotionsDates.endDate).isValid() ? 
            moment(promotionsDates.endDate).format("YYYY-MM-DD") :
            "";
        const newPageSize = page_size ? page_size : pageSize;
        let newActivePage = ((active_page === 0) ? 0 : active_page);
        const newSorted = sort ? sort : sorted;
        const offset = newPageSize * newActivePage;
        const sortDesc = sorted.desc ? "-" : "+";
        const newStatus = status ? "live" : "";

        this.setState({ 
            isLoading: true 
        });

        try {
            let promoData = await API.get(constants.RAPAPI.NAME, constants.RAPAPI.PROMOTIONS_LIST(this.tid, pageSize, offset, sorted.id, sortDesc, keyword, startDate, endDate, newStatus), { response: true });

            promoData.data.list.forEach(function(promotion) {
                promotion.organizations.forEach(function(org) {
                    org.organization_name = this.getOrganizationTitle(org.organization_id);
                }, this);

                promotion.channels.forEach(function(channel) {
                    channel.channel_name = this.getOrganizationTitle(channel.organization_id);
                }, this);

            }, this);

            const newPromoList = promoData.data.list.filter((promo) => {
                return (promo && promo.channels && promo.channels.length > 0);
            });
            
            this.setState({
                activePage: (newActivePage + 1),
                pageSize: pageSize,
                sorted: {
                    ...sorted,
                    id: newSorted.id,
                    desc: newSorted.desc,
                },
                promotionsList: [...newPromoList],
                totalCount: promoData.headers["x-total-count"],
                isLoading: false
            }, () => {
                this.setSessionStorage();
            });
        }
        catch (e) {
            this.setState({
                isLoading: false
            });
        };
    }

    setSessionStorage = () => {
        const { 
            status,
            pageSize,
            activePage,
            keyword,
            sorted,
            promotionsDates
        } = this.state;

        const pagination = {
            status: status,
            pageSize: pageSize,
            activePage: activePage,
            keyword: keyword,
            sorted: sorted,
            promotionsDates: promotionsDates
        };

        sessionStorage.setItem('acc-mgmt-promo', JSON.stringify(pagination));
    }

    handlePageSizeChange = (newPageSize) => {
        const { pageSize } = this.state;

        if(!isNaN(newPageSize) && newPageSize.trim() !== "" && newPageSize !== pageSize) {
            this.setState({
                pageSize: newPageSize
            }, () => {
                this.fetchPromotions(0, '', newPageSize);
            });
        };
    }
    
    handlePageChange = (page) => {
        this.fetchPromotions(page);
    }

    handleSearch = (e) => {
        e.preventDefault();

        this.fetchPromotions(0);
    }

    handleClearSearch = () => {
        this.setState({
            keyword: ''
        }, () => {
            this.fetchPromotions(0);
        });
    }

    handleSearchChange = (e) => {
        const name = e.target.name;
        const val = e.target.value;

        this.setState({
            [name] : val
        });
    }

    getTooltip = (id,text) => {
        return <Tooltip id={id} className='properties__tooltip'>{text}</Tooltip>
    }

    getOrganizationTitle(id) {
        let org = this.state.organizationMap.get(id)
        if(org) {
            return org.title
        }
        return id;
    }

    getTdProps=(state, rowInfo, column) => {
        if (
            column.Header === "Promotion" ||
            column.Header === "End Date"){
            
            return {
                onClick: () => {
                    this.state.data = rowInfo.original
                    this.props.history.push(`/account-management/promotions/${rowInfo.original.id}/edit`)
                },
                style: {
                    cursor: 'pointer'
                }
            }
        }

        return {} 
    }

    getTrProps = (state, rowInfo, column) => {
        if (rowInfo === undefined) {
            return {};
        };

        return {
            'data-qnt': 
                rowInfo &&
                rowInfo.original &&
                rowInfo.original.children &&
                rowInfo.original.children.length ? "1" : "0",
        };
    }

    toggleModal = (e, title, data) => {
        e.preventDefault();
        const { showModal } = this.state;

        this.setState({
            showModal: !showModal
        }, () => {

            this.setState({
                modalTitle: title ? title : '',
                modalData: data ? data : [],
            });
        });
    }

    handleDatesChange = (newDates) => {
        this.setState({
            promotionsDates: {
                ...newDates
            }
        }, () => {
            const { 
                promotionsDates
            } = this.state;
            const endElement = document.getElementById('end');
            const endElementValue = endElement.value;

            if(moment(promotionsDates && promotionsDates.startDate).isValid() && (endElementValue == "")) {
                this.fetchPromotions(0);
            }
            else if (moment(promotionsDates && promotionsDates.startDate).isValid() && moment(promotionsDates && promotionsDates.endDate).isValid()) {
                this.fetchPromotions(0);
            };
        });
    }

    isOutsideRange = () => {
        return false;
    }

    handleStatus = () => {
        const { status } = this.state;

        this.setState({
            status: !status
        }, () => {
            this.fetchPromotions(0);
        });
    }

    render() {
        const {
            focusedInput,
            promotionsDates,
            status,
            keyword,
            modalData,
            pageSize,
            isLoading,
            promotionsList,
            columns,
            totalCount,
            activePage,
            sorted,
            showModal,
            modalTitle
        } = this.state;

        return (
            <PageLayout isCentered={true}>
                <div className="promotions-list">
                    <Title
                        title='Promotions'
                        isCentered={true}
                        removeOrganizationDropdown={true}
                        addDefaultTitle="Account Management"
                        breadcrumbItems={this.breadcrumbItems}
                    >
                        <div className="filters-wrap">
                            <InputField 
                                type="checkbox" 
                                value={status} 
                                cbChange={this.handleStatus}
                            >
                                Live Status
                            </InputField>
                        </div> 
                        <DateRangePicker
                            noBorder
                            showClearDates={true}
                            minimumNights={0}
                            startDateId='start'
                            endDateId='end'
                            isOutsideRange={this.isOutsideRange}
                            startDatePlaceholderText="MM/DD/YYY"
                            endDatePlaceholderText="MM/DD/YYY"
                            startDate={promotionsDates && promotionsDates.startDate}
                            endDate={promotionsDates && promotionsDates.endDate}
                            focusedInput={focusedInput}
                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                            onDatesChange={this.handleDatesChange}
                        />
                        <form
                            className='search-inp'
                            onSubmit={(e) => this.handleSearch(e)}
                        >
                            <InputField
                                type='searchbox'
                                placeholder='Search'
                                name='keyword'
                                value={keyword}
                                onChange={this.handleSearchChange}
                                clearSearch={this.handleClearSearch}
                            />
                        </form>
                        <Link className='primary-btn' to='/account-management/promotions/create'>
                            <i className='icon-Plus create-icon' />
                            Create
                        </Link>
                    </Title>
                    <div id='content' className='promotions'>
                        <Grid fluid={true}>
                            <Row>
                                <Col xs={12}>
                                    <TableComponent 
                                        className='-highlight'
                                        resizable={false} 
                                        minRows={0}
                                        pageSize={parseInt(pageSize)}
                                        loading={isLoading}
                                        data={promotionsList}
                                        columns={columns}
                                        itemType="Promotions"
                                        noDataText={isLoading ? "Loading..." : "No Promotions found. Please create a Promotion."}
                                        totalItemsCount={Number(totalCount)}
                                        showPagination={true}
                                        activePage={
                                            activePage !== 0 ?
                                            activePage : 1}
                                        onPageSizeChange={this.handlePageSizeChange}
                                        handlePageChange={this.handlePageChange}
                                        defaultSorted={[sorted]}
                                        getTrProps = {this.getTrProps}
                                        getTdProps={this.getTdProps}
                                        manual
                                        Pag
                                    >
                                    </TableComponent>
                                </Col>
                            </Row>
                        </Grid>
                    </div>
                    <Modal 
                        className="org-modal" 
                        show={showModal} 
                        onHide={(e) => this.toggleModal(e)}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {modalTitle}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="org-wrap">
                                <ul>
                                    {
                                        modalData.map((data) => {
                                            let id = 0;
                                            let name = "";

                                            if(data.organization_id !=undefined) {
                                                id = data.organization_id
                                                name = this.getOrganizationTitle(data.organization_id)
                                            }
                                            else {
                                                id = data.property_id
                                                name = data.title
                                            };

                                            return (<li key={id}>{name}</li>) 
                                        })
                                    }
                                </ul> 
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </PageLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        roleManager: state.roleManager,
		organizations: state.authState.user.expanded_organization_roles,
    }
}

export default withRouter(connect(
    mapStateToProps,
    null
)(Promotions));