import React, { Fragment } from 'react';
import Picky from 'react-picky';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import isEqual from "lodash/isEqual";
import { Title } from '../global/Title';
import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Modal from 'react-bootstrap/lib/Modal';
import ProgressBar from 'react-bootstrap/lib/ProgressBar';
import CalendarView from '../global/calendar/CalendarView';
import { FieldWrap, InputField, InputLabel, PrimaryButton } from '../global/forms/FormElements';
import Autosuggest from 'react-autosuggest'
import constants, { STRING_CONSTANTS } from '../../js/constants';
import moment from 'moment';
import apiHandler from '../../js/apiHandler';
import Pagination from 'react-js-pagination';
import Loader from '../global/Loader';
import dates from 'react-big-calendar/lib/utils/dates';
import Popup from 'react-big-calendar/lib/Popup';
import Overlay from 'react-overlays/lib/Overlay';
import { API } from 'aws-amplify';
import { AvailabilityModal } from '../content/pricing/AvailabilityModal';
import { PricingModal } from '../content/pricing/PricingModal';
import { MobileCalendarModal } from '../content/pricing/MobileCalendarModal';
import { AvailableRoomModal } from '../content/pricing/AvailableRoomModal';
import { CicoModal } from '../content/pricing/CicoModal';
import { setRole, updateOrganization, updateOrganizationId, updateOrganizationTitle } from '../../js/actions/index';
import queryString from 'query-string';
import { delay } from '../../js/actions';
import { getOrgData, infoPopover } from '../../helpers/helpers';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import CreateReservationModal from './CreateReservationModal';

class Pricing extends React.Component {
    constructor(props) {
        super(props);
        // Initialize state
        this.today = moment().format('YYYY-MM-DD');
        this.tid = (Math.floor(Math.random() * ((99999 - 10000) + 1)) + 10000)
        this.xDown = null
        this.yDown = null
        this.multiRepAvailability = {}
        this.multiRepMobileBlocks = {}
        this.multiRepFinalAvail = {}
        this.multiAvailTable = {}
        this.repTBlocks = {}
        this.availNotesTypes = [];
        this.initAvailNotes = [];
        this.state = {
            showAllOrg: true,
            updatedPricePeriodArray: [],
            updatedAvailabilityArray: [],
            keyword: '',
            selectedDate: moment().toDate(),
            notAvailable: false,
            status: 0,
            loading: false,
            updatingPricing: false,
            isDeleting: false,
            isScrolling: false,
            propertyList: [],
            pagination: this.getInitialPaginationModal(),
            activeProperty: '',
            reservationList: [],
            events: [],
            popupProps: {},
            showSuggestion: false,
            showPopup: false,
            unAvailableRooms: {},
            availableRoomPropertyId: '',
            availableRoomModal: {
                show: false,
                period_start: '',
                period_end: '',
            },
            view: constants.CALENDAR.VIEW.MULTI_PROPERTY,
            listViewKey: 1,
            availableChildren: [],
            isLoading: true,
            gettingReservations: false,
            downloading: false,
            importing: false,
            value: '',
            importId: '',
            importProcess: false,
            suggestions: [],
            autoLoader: false,
            propertyChildren: [],
            CSVModal: false,
            successImportCSV: false,
            successImportCSVModal: false,
            editEntirePricePeriod: false,
            editEntireCicoPeriod: false,
            initCicoDate: {},
            pricingCurrentDateClicked: {},
            cicoCurrentDateClicked: {},
            oneDayPricePeriod: false,
            oneDayCicoPeriod: false,
            noteValidation: false,
            duplicateNotes: {},
            typeValidation: [],
            availNoteDate: null,
            availMode: "",
            showIcalUrlModal: false,
            showUrlSuccessMsg: false,
            importCompleted: {
                all: 0,
                completed: 0,
                error: 0
            },
            eventAccessor: [
                'price_periods', 
                'reservations', 
                'availability', 
                'cico',
            ],
            pricingModal: this.getInitialPricingModal(),
            cicoModal: this.getInitialCicoModal(),
            validations: {
                first_name: false,
                last_name: false,
                email: false,
                phone: false,
                postal_code: false,
                country: false,
                creditcard_number: false,
                expiration_month: false,
                expiration_year: false,
                cvv: false,
            },
            availabilityModal: {
                type: 'block',
                show: false,
                mode: 0,
                property_id: null,
                data: {
                    availability_id: null,
                    period_start: moment(),
                    period_end: moment(),
                    type: '',
                    expires: null
                },
                priceOvrSource: "min_price",
                priceOvr: {
                    period_start: moment(),
                    period_end: moment(),
                    currency:  "USD",
                    price: "",
                    min_price: "",
                    min_stay: "",
                },
                notes: [
                    {
                        type: "",
                        note: "",
                    }
                ],
                reservations: {
                    showGuestInfoModal: false,
                    reservationDates: {
                        startDate: null,
                        endDate: null
                    },
                    quoteIsLoading: false,
                    quoteData: {},
                    quoteErrMsg: '',
                    bookNowSuccess: false,
                    bookNowErrMsg: '',
                    bookNowIsLoading: false,
                    promotionCodeData: [],
                    promotionCode: '',
                    guestData: {
                        first_name: '',
                        last_name: '',
                        email: '',
                        phone: '',
                        cell_phone: '',
                        point_of_sale: 'portal',
                        address: {
                            postal_code: '',
                            country: '',
                        },
                        payments: [
                            {
                                method: 'creditcard',
                                method_details: {
                                    creditcard_number: '',
                                    cvv: '',
                                    expiration_month: '',
                                    expiration_year: ''
                                }
                            }
                        ]
                    },
                    adults: 1,
                    children: 0,
                    adultOptions: [],
                    childOptions: [],
                },
                initPricingDate: {},
                expiresTime: "",
                children: [],
                iCalSourceOfTruth: false,
                collectionId: "",
            },
            mobileCalendarModal: {
                pricingPanel: this.getInitialPricingModal(),
                availabilityPanel: this.getInitialAvailabilityModal(),
                cicoPanel: this.getInitialCicoModal(),
            },
            propertyPricePeriods: [],
            importModal: false,
            availabilityFeed: {
                feed_type: "ical",
                url: "",
                frequency: "",
                one_time_run_ts: "",
                use_checkout_date: "",
            },
            selectedFrequency: {},
            calendarSource: "",
            importError: '',
            importByProperty: false,
            errors: this.getInitialErrorState(),
            pricingHighlightedDates: [],
            blockedHighlightedDates: [],
            listView: false,
            integrationsDataByOrgName: {"integration_name": "racalendar"},
        }
        this.availabilityDragHandler = {
            start: false,
            sDate: null,
            eDate: null,
            propId: null
        }
        this.pricingDragHandler = {
            start: false,
            sDate: null,
            eDate: null,
            propId: null
        }
        this.calendarSource = [
            {name: "calendarSource", value: "ical_one_time_import", label: "iCal - One-Time Import"},
            {name: "calendarSource", value: "ical_source_of_truth", label: "iCal - Source of Truth"},
        ];
        this.checkOutDate = [
            {name: "use_checkout_date", value: "1", label: "Calendar uses Check-Out Date"},
            {name: "use_checkout_date", value: "0", label: "Calendar uses Last Night of Stay"},
        ];
        let { roles } = props.roleManager;
        this.isAdmin = roles && roles.indexOf(constants.USER_TYPES.PROPERTY_MANAGER_ADMIN) > -1;
        this.guestServices = roles && roles.indexOf(constants.USER_TYPES.GUEST_SERVICES) > -1;
        this.org = props.roleManager.org;
        this.noAvailabilityFeed = false;
        this.allOrganizations = [];
        this.setPropertyOrg = this.setPropertyOrg.bind(this);
        this.onNavigate = this.onNavigate.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.getPageByNum = this.getPageByNum.bind(this);
        this.fetchProperties = this.fetchProperties.bind(this);
        this.getCalendarTitle = this.getCalendarTitle.bind(this);
        this.onChangeFilters = this.onChangeFilters.bind(this);
        this.addPricing = this.addPricing.bind(this);
        this.editPricing = this.editPricing.bind(this);
        this.addAvailability = this.addAvailability.bind(this);
        this.submitPricing = this.submitPricing.bind(this);
        this.submitAvailability = this.submitAvailability.bind(this);
        this.deleteAvailability = this.deleteAvailability.bind(this);
        this.deletePricing = this.deletePricing.bind(this);
        this.handleBlockedDrag = this.handleBlockedDrag.bind(this);
        this.handleBlockedDragStart = this.handleBlockedDragStart.bind(this);
        this.handleBlockedDragEnd = this.handleBlockedDragEnd.bind(this);
        this.handlePricingDrag = this.handlePricingDrag.bind(this);
        this.handlePricingDragStart = this.handlePricingDragStart.bind(this);
        this.handlePricingDragEnd = this.handlePricingDragEnd.bind(this);
        this.handleShowMore = this.handleShowMore.bind(this);
        this.handleBackBtn = this.handleBackBtn.bind(this);
        this.toggleImportModal = this.toggleImportModal.bind(this);
        this.toggleListView = this.toggleListView.bind(this);
        this.toggleImportByProperty = this.toggleImportByProperty.bind(this);
        this.fetchPropertiesSuggestions = this.fetchPropertiesSuggestions.bind(this);
        this.importByProperty = this.importByProperty.bind(this);
        this.toggleImportByCSV = this.toggleImportByCSV.bind(this);
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
        this.getEventTitle = this.getEventTitle.bind(this);
        this.showAvailableRooms = this.showAvailableRooms.bind(this);
    }

    /**
     * For Init pagination modal.
     */
    getInitialPaginationModal() {
        return ({
            activePage: 0,
            itemsCountPerPage: 10,
            totalItemsCount: 0,
            pageRangeDisplayed: 5
        })
    }

    /**
     * Initial Error state, don't know what type 1 or 2 is? Contact your local administator ;)
     */
    getInitialErrorState() {
        return ({
            period_start: {
                message: 'Selected dates overlap with an existing period.',
                show: false,
                type: '2',
            },
            period_end: {
                message: 'End date cannot be before Start date.',
                show: false,
                type: '2',
            },
            minstay: {
                message: 'Minimum Nights is required',
                show: false,
                type: '1',
            },
            weekday_price: {
                message: 'Weekday price is required',
                show: false,
                type: '1',
            },
            weekend_price: {
                message: 'Weekend price is required',
                show: false,
                type: '1',
            },
            weekly_price: {
                message: 'Weekly price is required',
                show: false,
                type: '1',
            },
            monthly_price: {
                message: 'Monthly price is required',
                show: false,
                type: '1',
            },
            avg_price: {
                message: '$/Day Weekly Price cannot be more than sum of $/Day Weekend and Weekday Price',
                show: false,
                type: '1',
            },
            server_error: {
                message: 'Unable to process your request. Please contact Customer Support.',
                show: false,
                type: '2',
            },
            parsing_error: {
                message: 'Unable to process your request. Please contact Customer Support.',
                show: false,
                type: '2',
            },
            fetchError: {
                message: 'Unable to get properties. Please contact Customer Support.',
                show: false,
                type: '2',
            }
        })
    }

    /**
     * Update list view calendar active panel key
     * @param {Number} key
     */
    updateListViewPanelKey = key => {
        this.setState({
            listViewKey: key,
        })
    }

    /**
     * get Initial Pricing Modal
     */
    getInitialPricingModal() {
        // get the initial state of the pricing modal
        return ({
            show: false,
            mode: 0,
            property_id: null,
            pricingDates: {
                startDate: moment(),
                endDate: moment(),
            },
            data: {
                price_period_id: null,
                period_start: moment(),
                period_end: moment(),
                minstay: '',
                weekday_price: '',
                weekend_price: '',
                weekly_price: '',
                monthly_price: '',
            },
            initPricingDate: {},
            priceOvrSource: "min_price",
            minPriceOnly: false,
            priceOvr: {
                period_start: moment(),
                period_end: moment(),
                currency:  "USD",
                price: "",
                min_price: "",
                min_stay: "",
            },
        })
    }

    getInitialCicoModal = (date) => {
        return ({
            show: false,
            mode: 0,
            property_id: null,
            cicoLocked: null,
            cicoDates: {
                startDate: moment(),
                endDate: moment(),
            },
            data: {
                cico_id: null,
                start_date: date ? moment(date) : moment(),
                end_date: date ? moment(date) : moment(),
                minstay: '',
                check_in_allowed: {
                    'monday': true,
                    'tuesday': true,
                    'wednesday': true,
                    'thursday': true,
                    'friday': true,
                    'saturday': true,
                    'sunday': true
                },
                check_out_allowed: {
                    'monday': true,
                    'tuesday': true,
                    'wednesday': true,
                    'thursday': true,
                    'friday': true,
                    'saturday': true,
                    'sunday': true
                }
            }
        })
    }

    getInitialAvailabilityModal(){
        let availabilityData = {
            type: 'block',
            show: false,
            mode: 0,
            property_id: null,
            availabilityDates: {
                startDate: moment(),
                endDate: moment(),
            },
            data: {
                availability_id: null,
                period_start: moment(),
                period_end: moment(),
                type: ''
            },
            notes: [
                {
                    type: "",
                    note: "",
                }
            ],
            reservations: {
                showGuestInfoModal: false,
                reservationDates: {
                    startDate: null,
                    endDate: null
                },
                quoteIsLoading: false,
                quoteData: {},
                quoteErrMsg: '',
                bookNowSuccess: false,
                bookNowErrMsg: '',
                bookNowIsLoading: false,
                promotionCodeData: [],
                promotionCode: '',
                guestData: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone: '',
                    cell_phone: '',
                    point_of_sale: 'portal',
                    address: {
                        postal_code: '',
                        country: '',
                    },
                    payments: [
                        {
                            method: 'creditcard',
                            method_details: {
                                creditcard_number: '',
                                cvv: '',
                                expiration_month: '',
                                expiration_year: ''
                            }
                        }
                    ]
                },
                adults: 1,
                children: 0,
                adultOptions: [],
                childOptions: [],
            },
            expiresTime: "00:00:00Z",
            children: [],
            iCalSourceOfTruth: false,
            collectionId: "",
        }
        if (this.props.mobileMode) {
            availabilityData = { 
                ...availabilityData, 
                reservationData: null,
            }
        }
        return availabilityData
    }

    async getCicoLockedStatus(property_id) {
        let propertyContentLocksResponse = {}
        let cicoLocked = null

        this.setState({ isLoading: true });

        if (this.isAdmin && this.state.integrationsDataByOrgName && this.state.integrationsDataByOrgName.integration_name !== "racalendar" && property_id) {
            try {
                propertyContentLocksResponse = await API.get("rapapi", `/properties/${property_id}/contentlocks`, {});
                if (propertyContentLocksResponse && propertyContentLocksResponse.cico) {
                    cicoLocked = propertyContentLocksResponse.cico

                }
            } catch (err) {
                console.log('Error getting property content locks', err)
            }
        }

        this.setState({ isLoading: false });

        return cicoLocked
    }

    componentDidUpdate() {
        this.activeProperty = this.getPropertyById(this.state.activeProperty)[0]
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.activeProperty) {
            if (nextProps.mobileMode) {
                let [ rbc ] = document.getElementsByClassName('rbc-calendar')
                if (rbc) {
                    rbc.addEventListener('touchstart', this.handleTouchStart, false)
                    rbc.addEventListener('touchmove', this.handleTouchMove, false)
                }
            }
        }
        if (nextProps.mobileMode === false) {
            this.hideMobileCalendarModal()
        }
        if (nextProps.mobileMode === true) {
            if (moment(this.state.selectedDate).isBefore(moment(this.today), 'days')) {
                this.setState({
                    selectedDate: moment(this.today).toDate()
                })
            }
        }
        let { location } = nextProps;
        let page =  
            this.props.location && this.props.location.search ?
            parseInt(queryString.parse(this.props.location.search).page) : 0;
        let newPage = 
            nextProps.location && nextProps.location.search ?
            parseInt(queryString.parse(nextProps.location.search).page) : 0;

        let queryStringCollectionId = 
            this.props.match &&
            this.props.match.params && 
            this.props.match.params.property_id;
        let newQueryStringCollectionId = 
            nextProps &&
            nextProps.match &&
            nextProps.match.params && 
            nextProps.match.params.property_id;
            
        if (location && location.state && location.state.org != this.org) {
            this.org = location.state.org;
            let roles = location.state.roles
            this.isAdmin = roles && roles.indexOf(constants.USER_TYPES.PROPERTY_MANAGER_ADMIN) > -1;
            newPage = 1
        }

        if(queryStringCollectionId !== newQueryStringCollectionId) {
            if (!this.state.gettingReservations) {
                this.getReservations()
            }
        }

        if(page !== newPage) {
            let pagination = this.state.pagination,
                reservations = this.state.reservationList;
            let propertyLevelArr = [];

            pagination.activePage = Number(newPage) ?Number(newPage) : 1;
            this.fetchProperties(Number(newPage)?( Number(newPage) - 1 ): 1).then(async(properties) => {

                pagination.totalItemsCount = properties.total;

                if(properties.data) {
                    for(let i = 0; i < properties.data.length; i++) {

                        // Smart Pricing Override
                        await this.configSmartPriceOvr(properties.data[i]);
                    };
                };

                await Promise.all(properties.data.map(async(prop,index) => {
                    propertyLevelArr.push(prop.level)
                    properties.data[index]['reservations'] = reservations.filter(resv => {
                        if (properties.data[index].property_id == resv.property_id) {
                            return resv;
                        };
                    });
                    if (properties.data[index].level === 'rep') {
                        let availability = await this.getRepAvailability(properties.data[index].property_id, properties.data[index].price_periods || [], (properties.data[index].repconfig.units_active && properties.data[index].repconfig.units_active.length) || 0) || []
                        properties.data[index]['availability'] = availability;
                    };
                }));
                this.setState({
                    pagination: pagination,
                    events: properties.data,
                    propertyList: properties.data,
                    comparePropertyList: properties.data,
                    propertyLevels: propertyLevelArr,
                    s3CsvFile: "",
                    isLoading: false
                });
            })
        }
    }

    async componentDidMount() {
        const { user } = this.props;

        function stopWheel(e){
            return false;
        }
        window.addEventListener("mousewheel", stopWheel, false);
        window.addEventListener("DOMMouseScroll", stopWheel, false);

        let orgData = getOrgData(user, this.org);
        this.allOrganizations = orgData.allOrganizations;

        const params = JSON.parse(sessionStorage.getItem('calendar-listing'));

        if(params) {
            this.setState({
                pagination: {
                    ...this.state.pagination,
                    itemsCountPerPage: params.itemsCountPerPage
                }
            });
        };

        await this.getavailNotesTypes();

        if (this.props.match && this.props.match.params && this.props.match.params.property_id) {
            // Single Property Page.
            let property = {};
            let propertyPricePeriods = {};

            try {
                property = await apiHandler.get(constants.RAPAPI.PROPERTIES + `/${this.props.match.params.property_id}`, { isCognito: true });

                if(property && property.data) {
                    this.setPropertyOrg(property.data.organization_name);
                };
            }
            catch(e) {
                console.log(e, 'Error initial GET properties');
            }
            
            try {
                propertyPricePeriods = await apiHandler.get('/properties/' + this.props.match.params.property_id + '/priceperiods');
            }
            catch(e) {
                console.log(e, 'Error property Price Period');
            }
            
            this.setState({
                propertyPricePeriods: propertyPricePeriods && propertyPricePeriods.data,
            }, () => {
                this.getReservations();
            })
        }
        else {
            // Multi Property Page.
            if (!this.state.gettingReservations) {
                this.getReservations();
            }
        }
        
        let integrationsDataByOrgNameResponse = {"integration_name": "racalendar"};
            
        try {
            integrationsDataByOrgNameResponse = await API.get("rapapi", `/integrations/${sessionStorage.getItem('org')}`)
        } catch (err)  {
            integrationsDataByOrgNameResponse = {"integration_name": "racalendar"};
        }

        this.setState({
            integrationsDataByOrgName: (integrationsDataByOrgNameResponse && integrationsDataByOrgNameResponse.integration_name) ? integrationsDataByOrgNameResponse : {"integration_name": "racalendar"}
        })
    }

    componentWillUnmount() {
        window.removeEventListener("mousewheel", this.onScroll, false);
        window.removeEventListener("DOMMouseScroll", this.onScroll, false);
        let [ rbc ] = document.getElementsByClassName('rbc-calendar');
        if (rbc) {
            rbc.removeEventListener('touchstart', this.handleTouchStart, false);
            rbc.removeEventListener('touchmove', this.handleTouchMove, false);
        }
    }

    getavailNotesTypes = async () => {
        let data  = [];

        try {
            data = await API.get("rapapi", "/availabilitynotes/enums");

            if(data.length) {
                this.availNotesTypes = data;
            };
        }
        catch(e) {
            console.log(e)
        };
    }

    getAvailabilityNotes = async (prop_id, avail_id) => {
        let data = [];

        try {
            data = await API.get("rapapi", `/properties/${prop_id}/availability/${avail_id}/notes`)
        }
        catch(e) {
            console.log(e)
        }
        return data;
    }

    updateAvailabilityNotes = async (prop_id, avail_id, data, rest) => {
        try {
            await API[rest]("rapapi", `/properties/${prop_id}/availability/${avail_id}/notes${rest === "del" ? `?type=${data.type}` : ""}`, { body: data });
        }
        catch(e) {
            console.log(e)
        };   
    }
    
    getTouches = e => {
        e.stopPropagation()
        return e.touches || e.originalEvent.touches;
    }

    handleTouchStart = e => {
        e.stopPropagation()
        const firstTouch = this.getTouches(e)[0]                                      
        this.xDown = firstTouch.clientX                                      
        this.yDown = firstTouch.clientY                                      
    }

    handleTouchMove = e => {
        e.stopPropagation()
        if (!this.xDown || !this.yDown) {
            return
        }
        let xUp = e.touches[0].clientX
        let yUp = e.touches[0].clientY
        let xDiff = this.xDown - xUp
        let yDiff = this.yDown - yUp

        let panel, increment, date

        if (this.state.mobileCalendarModal.availabilityPanel.show) {
            panel = 'availability'
        }
        if (this.state.mobileCalendarModal.pricingPanel.show) {
            panel = 'pricing'
        }
        
        if (panel) {    
            date = this.state.dateClicked
            increment = 'days'
        }
        else {
            date = this.state.selectedDate
            increment = 'months'
        }
     
        if (Math.abs(xDiff) > Math.abs(yDiff)) {
            if (xDiff > 0 ) {
                date = moment(date).add(1, increment)
            } else {
                date = moment(date).subtract(1, increment)
                
            }                       
        }
        /* reset values */
        this.xDown = null
        this.yDown = null

        if (date) {
            if (panel) {
                if (moment(date).isSameOrAfter(this.today)) {
                    this.handleUpdateModalEventsByDate(e, moment(date).format('YYYY-MM-DD'), panel, this.state.propertyList[0])
                }
            }
            else {
                this.onNavigate(date)
            }
        }
    }

    handleFileChange = e => {
        e.preventDefault()

        let input = document.getElementById('upload-csv');

        if (input.value) {
            this.setState({
                importError: "",
                templateError: "",
            })
        }
        
        const reader = new FileReader()
        let base64, fileName
        reader.onload = e => {
            if (e.target.result) {
                [ , base64 ] = e.target.result.split('base64,')
            }
            if (input.files[0] && input.files[0].name) {
                let wholeName = input.files[0].name,
                shortenedName
                if (wholeName.length >= 20) {
                    let [ 
                        noExtension, 
                        extension, 
                    ] = wholeName.split('.')

                    let spliceIndex = (noExtension.length - 14) > 6 ? 
                    noExtension.length - 6 : 
                    (noExtension.length - (noExtension.length - 14))

                    let precedeExtension = wholeName.substring(spliceIndex, noExtension.length)
                    shortenedName = wholeName.substring(0, 14).concat(`...${precedeExtension}.${extension}`)
                    fileName = shortenedName
                }
                else {
                    fileName = wholeName
                }
            }
            this.setState({
                csvPayload: {
                    payload: base64, 
                    fileName,
                },
            })
        }
        if (input && input.files && input.files[0]) {
            reader.readAsDataURL(input.files[0])
        }
    }
    
    /**
     * Set one modal display false, and the other true
     * @param {string} sDate start date
     * @param {string} eDate end date
     * @param {string} id id of parent property
     */
    swapModals = (sDate, eDate, id) => {
        //opens addAvailability modal
        this.addAvailability(sDate, eDate, id)
        //closes available room modal
        this.closeAvailableRoomModal()
    }
    
    /**
     * Set organization in session storage and change organization as per current Property or Room Type
     * @param {string} orgName 
     */
    setPropertyOrg(orgName) {
        let organization = this.props.user.expanded_organization_roles.filter(org => {
            if(org.organization_name === orgName) {
                return true
            }
        });

        if(this.org !== orgName) {
            let allUserRoles = this.props.isInternalPropertyAdmin ? Object.values(constants.USER_TYPES).filter((role) => {
                return role;
            }) : [];
    
            if(organization && organization.length) {
                this.props.updateOrganization(organization[0].organization_name);
                this.props.updateOrganizationTitle(organization[0].title);
                this.props.updateOrganizationId(organization[0].organization_id);
                this.props.setRole(this.props.isInternalPropertyAdmin ? allUserRoles: organization[0].roles);
    
                sessionStorage.setItem('org', organization[0].organization_name);
                sessionStorage.setItem('orgTitle', organization[0].title);
                sessionStorage.setItem('orgId', organization[0].organization_id);

                sessionStorage.removeItem('reservations-listing');
                sessionStorage.removeItem('properties-listing');
            };
        };
    }

    /**
     * Toggle between listView calendar week view.
     */
    toggleListView() {
        this.setState({ listView: !this.state.listView })
    }

    onSuggestionsClearRequested = () => {}

    onScroll(e) {
        if (document.body.className === 'modal-open') {
            return;
        }
        let date, { isScrolling } = this.state;
        if (this.state.view == constants.CALENDAR.VIEW.MONTH && !isScrolling && !this.state.listView) {
            if ((window.innerHeight + window.scrollY) + 1 >= document.body.offsetHeight && e.deltaY > 0) {
                window.scrollTo(0, 0);
                date = moment(this.state.selectedDate).add(1, 'month').toDate()
                this.setState({
                    selectedDate: date
                });
            } else if (window.scrollY == 0 && e.deltaY < 0) {
                date = moment(this.state.selectedDate).subtract(1, 'month').toDate()
                this.setState({
                    selectedDate: date
                });
            }
            isScrolling = true
            this.setState({ isScrolling });
            window.setTimeout(() => {
                this.setState({
                    isScrolling: false
                })
            }, 200)
        }
    }

    /**
     * Navigate to date select in calendar.
     * @param {*} date 
     */
    onNavigate(date, view) {
        let today = moment(),
        newDate = moment(date),
        diff = newDate.diff(today, 'days')

        if (this.props.mobileMode) {
            if (diff < 0) {
                newDate = today
            }
        }
        this.setState({
            selectedDate: moment(newDate).toDate()
        })
    }

    /**
     * Return all events with propertyId (rep level)
     * @param {*} property
     */
    getAllEventsWithPropertyIds = property => {
        return property.availability.filter(event => {
            return event.propertyId
        })
    }

    handleExpiresTime = (e, type) => {
        const name = e.target.name;
        const value = e.target.value;

        if(type === "mobile") {
            const avail = {...this.state.mobileCalendarModal};

            this.setState({
                mobileCalendarModal: {
                    ...avail,
                    availabilityPanel: {
                        ...avail.availabilityPanel,
                        [name]: value
                    }
                }
            });
        }
        else {
            this.setState({
                availabilityModal: {
                    ...this.state.availabilityModal,
                    [name]: value
                }
            });
        };
    }

    /**
     * Return new event with propertyId (rep-availability)
     * @param {*} date
     * @param {*} id
     */
    generateNewEventWithPropertyId = (date, id) => {
        let fDate = moment(date).format('YYYY-MM-DD')
        let unavailability = {
            blockTypes: [],
            period: {
                period_start: fDate,
                period_end: fDate,
                type: 'Rooms Available',
            },
            propertyId: [],
            roomsAvailable: this.state.availTable[fDate],
        }
        this.multiRepAvailability[id].forEach(item => {
            let startDate = moment(item.period_start).format('YYYY-MM-DD')
            let endDate = moment(item.period_end).format('YYYY-MM-DD')
            if (moment(fDate).isBetween(startDate, endDate, null, '[]')) {
                unavailability.blockTypes.push({
                        type: item.type, 
                        quantity: item.units, 
                        availability_ids: item.availability_ids
                    }
                )
            }
        })
        return unavailability
    }

    /**
     * Return cico by corresponding price period (mobile)
     * @param {*} cico
     * @param {*} period
     */
    getCicoByPricePeriod = (cico, date) => {
        let data = {};
        const dateFormat = moment(date).format('YYYY-MM-DD');

        cico.forEach(e => {
            const start_date = moment(e.start_date).format('YYYY-MM-DD');
            const end_date = moment(e.end_date).format('YYYY-MM-DD');

            if(moment(dateFormat).isBetween(start_date, end_date) || moment(dateFormat).isSame(start_date) || moment(dateFormat).isSame(end_date)) {
                data = e;
            };
        });
        return data;
    }
    /**
     * Return block be clicked date (mobile)
     * @param {*} date
     * @param {*} availability
     */
    getBlockByDate = (date, availability) => {
        let fDate = moment(date).format('YYYY-MM-DD')
        let [ block ] = availability.filter(block => {
            let startDate = moment(block.period.period_start).format('YYYY-MM-DD'),
            endDate = moment(block.period.period_end).format('YYYY-MM-DD')
            if (moment(fDate).isBetween(startDate, endDate, null, '[]')) {
                return true
            }
            else {
                return false
            }
        })
        return block && block.period
    }
    /**
     * Return reservation by date (mobile)
     * @param {*} block block of type booking
     * @param {*} id property id
     */
    getReservationByBlockDate = (block, id) => {
        let event = this.state.reservationList.filter(reservation => {
            let isExactReservationMatch = false,
            checkIn = moment(reservation.checkin_date).format('YYYY-MM-DD'),
            checkOut = moment(reservation.checkout_date).format('YYYY-MM-DD')
            if (reservation.property_id === id) {
                if(moment(block.period_start).isSame(checkIn, 'day') && moment(block.period_end).isSame(checkOut, 'day')) {
                    isExactReservationMatch = true
                }
            }
            return isExactReservationMatch
        })[0]
        
        this.setState({
            mobileCalendarModal: {
                ...this.state.mobileCalendarModal,
                availabilityPanel: {
                    ...this.state.mobileCalendarModal.availabilityPanel,
                    property_id: id,
                    reservationData: event,
                    show: true,
                }
            },
        })
    }
    /**
     * Update show state of mobile calendar modal panels
     * @param {*} key active key
     * @param {*} e
     * @param {*} property active property
     */
    updateShowActivePanel = (key, e, property) => {
        let panels = {
            1 : 'pricing',
            2 : 'availability',
            3 : 'cico'
        };
        let panel = panels[key];

        let fDate = moment(this.state.dateClicked).format('YYYY-MM-DD'),
        modal = this.state.mobileCalendarModal
        
        if (key === 1) {
            modal.pricingPanel.show = true
            modal.availabilityPanel = this.getInitialAvailabilityModal()
        }
        if (key === 2) {
            modal.availabilityPanel.show = true
            modal.pricingPanel = this.getInitialPricingModal()
        }
        this.setState({
            mobileCalendarModal: modal,
        })
        this.handleUpdateModalEventsByDate(e, fDate, panel, property)
    }
    
    /**
     * Update events in mobile calendar modal by emulating calendar event or cell click
     * @param {*} e 
     * @param {*} date
     * @param {*} panel
     * @param {*} property
     */
    handleUpdateModalEventsByDate = (e, date, panel, property) => {
        e.preventDefault();

        let initialErrors = this.getInitialErrorState();
        let [ pricePeriod ] = property.price_periods.filter(({ period_start, period_end }) => (moment(date).isBetween(moment(period_start).format('YYYY-MM-DD'), moment(period_end).format('YYYY-MM-DD'), null, '[]')));
        let cico = this.getCicoByPricePeriod(property.cico, date);
    
        /* Handles Pricing and Cico period */
        if(panel === 'pricing' || panel === "cico") {
            if (pricePeriod) {      
                this.editPricing(e, pricePeriod, property.property_id, cico, date)
            }
            else {
                this.addPricing(date, date, property.property_id, date, cico)
            };
        };

        /* Handles Availability */
        if (panel === 'availability') {
            if (property.level === 'rep') {
                let event
                let events = this.getAllEventsWithPropertyIds(property)
                let [evt] = events.filter(evt => moment(moment(evt.period.period_start).format('YYYY-MM-DD')).isSame(moment(date).format('YYYY-MM-DD')))
                if (evt) {
                    event = evt
                }
                else {
                    event = this.generateNewEventWithPropertyId(date, property.property_id)
                }
                /* handles addAvailability in mobileMode */
                this.showAvailableRooms(event, property.property_id, event.period.period_start)
            }
            else {
                let block = this.getBlockByDate(date, property.availability)

                if (block && block.type !== 'Temporary') {
                    if (block.type === 'BlockedByBooking') {
                        this.getReservationByBlockDate(block, property.property_id)
                    }
                    else {
                        this.editAvailability(block, property.property_id)
                    }
                }
                else {
                    this.addAvailability(date, date, property.property_id)
                }
            }
        };

        this.setState({
            dateClicked: date,
            errors: initialErrors,
        })
    }

    updateData() {
        if (!this.state.gettingReservations) {
            this.getReservations()
        }
    }

    handleDoubleClick(e) {
        // Override Double Click Event
    }

    handleBackBtn(e) {
        e.preventDefault();
        this.props.history.goBack()
    }
    onChangeFilters(e) {
        let filters = this.state.eventAccessor;
        let el = e.target;
        let index = filters.indexOf(el.value);
        if (el.checked) {
            filters.push(el.value);
        }
        else if (index >= 0) {
            filters.splice(index, 1)
        }
        this.setState({
            eventAccessor: filters
        });

        if (this.state.activeProperty != '') {
            this.onPropertySelected(this.state.activeProperty)
        }
    }

    /**
     * Retrieve properties.
     * @param {*} reservations current reservation data.
     */
    async getProperties(reservations) {
        // getting Properties data
        let properties = {}, 
            availabilityFeed = {}, 
            activeId,
            errors = this.getInitialErrorState(),
            { state, pathname } = this.props.location,
            org = this.org;
        const { showAllOrg } = this.state;
        //used for mobile calendar keys
        let propertyLevelArr = []
            
        let queryStringActivePage = 
            this.props.location.search ? 
            parseInt(queryString.parse(this.props.location.search).page) : 0,

        paginationPage = queryStringActivePage === 0 ? 1 : queryStringActivePage,
        offsetPage = (queryStringActivePage - 1) > 0 ? (queryStringActivePage - 1) : 0
        
        properties.data = [];
        try {
            activeId = this.props.match.params.property_id;
            if (activeId && state && state.data) {
                properties.data.push(JSON.parse(state.data));
                this.props.history.replace({
                    pathname: pathname,
                    state: null
                })
            }
            // Single Property Data
            else if (activeId) {
                let property,
                    status = 0;
                property = await apiHandler.get(constants.RAPAPI.PROPERTIES + `/${activeId}`, { isCognito: true });
                if (property.data) {
                    properties.data.push(property.data);

                    // Smart Pricing Override
                    await this.configSmartPriceOvr(property.data);
                };

                if (property.response) {
                    status = property.response.status
                };

                try {
                    availabilityFeed = await apiHandler.get(constants.RAPAPI.PROPERTIES + `/${activeId}/availabilityfeed`, { isCognito: true });

                    if (availabilityFeed && availabilityFeed.data) {
                        properties.data[0].availabilityFeed = availabilityFeed.data;

                        let UPDATE_FREQUENCY = [];
                        let calendarSource = "ical_one_time_import";

                        if(availabilityFeed && availabilityFeed.data && availabilityFeed.data.frequency && availabilityFeed.data.frequency.length){
                            for(let i = 0; i < constants.UPDATE_FREQUENCY.length; i++) {
                                if(availabilityFeed.data.frequency == constants.UPDATE_FREQUENCY[i].value) {
                                    UPDATE_FREQUENCY.push(constants.UPDATE_FREQUENCY[i])
                                };
                            };

                            calendarSource = "ical_source_of_truth";
                        };

                        if(availabilityFeed && availabilityFeed.data && availabilityFeed.data.use_checkout_date && availabilityFeed.data.use_checkout_date.length) {
                            availabilityFeed.data.use_checkout_date = availabilityFeed.data.use_checkout_date == "true" ? "1" : "0";
                        };

                        this.setState({
                            calendarSource: calendarSource,
                            selectedFrequency: UPDATE_FREQUENCY.length ? UPDATE_FREQUENCY[0] : {},
                            availabilityFeed: availabilityFeed.data
                        })
                    }
                    if (availabilityFeed.response && availabilityFeed.response.status) {
                        this.noAvailabilityFeed = true;
                    }
                } 
                catch (e) {
                    console.log(e)
                };

                this.setState({
                    propertyList: properties.data,
                    activeProperty: activeId,
                    events: properties.data,
                    status
                })
            }
            // Multi Property Data
            else {
                properties = await apiHandler.getProperties({
                    page: offsetPage,
                    limit: this.state.pagination.itemsCountPerPage,
                    org,
                    allOrganizations: this.allOrganizations,
                    showAllOrg: showAllOrg,
                    fl: ['level', 'property_id', 'external_property_id', 'content', 'availability', 'price_periods', 'cico', 'price', 'repconfig', 'units', 'organization_name']
                });
                // Route to Single Calendar View if Organization only has 1 property.
                if((properties && properties.data.length >= 1) && (properties && properties.total == 1)) {
                    this.props.history.push(`/calendar/${properties.data[0].property_id}/edit`);
                }
                else {
                    try {
                        for(let i = 0; i < properties.data.length; i++) {
                            let availabilityFeed = await apiHandler.get(constants.RAPAPI.PROPERTIES + `/${properties.data[i].property_id}/availabilityfeed`, { isCognito: true });

                            if(availabilityFeed.data) {
                                properties.data[i].availabilityFeed = availabilityFeed.data;
                            };

                            // Smart Pricing Override
                            await this.configSmartPriceOvr(properties.data[i]);
                        };
                    }
                    catch (e) {
                        console.log(e)
                    };

                    if(Number(properties.total) && properties.data.length === 0) {
                        this.props.history.push('?page=1');
                        return;
                    };
                };
            };
            // mapping the reservations list to their respective properties in a single array
            if (properties.data.length) {
                await Promise.all(properties.data.map(async(prop,index) => {
                    propertyLevelArr.push(prop.level)
                    properties.data[index]['reservations'] = reservations.filter(resv => {
                        if (properties.data[index].property_id == resv.property_id && properties.data[index].level !== 'rep') {
                            return resv;
                        }
                    });
                    
                    if (properties.data[index].level === 'rep') {
                        let availability = await this.getRepAvailability(properties.data[index].property_id, properties.data[index].price_periods || [], (properties.data[index].repconfig.units_active && properties.data[index].repconfig.units_active.length) || 0) || []
                        if (availability) {
                            properties.data[index]['availability'] = availability
                        }
                    }
                }))
                this.setState({
                    propertyList: properties.data,
                    comparePropertyList: properties.data,
                    view: constants.CALENDAR.VIEW.MULTI_PROPERTY,
                    events: properties.data,
                    propertyLevels: propertyLevelArr,
                    successImportCSVModal: this.state.successImportCSV ? true : false,
                    isLoading: false
                });
                this.initPagination(properties.total, paginationPage);
                if (activeId) {
                    this.onPropertySelected(activeId);
                    if(properties.data.length && properties.data[0] !== org) {
                        this.setPropertyOrg(properties.data[0].organization_name);
                    };
                };
            } else {
                errors.fetchError.show = true;
                this.setState({
                    view: constants.CALENDAR.VIEW.MULTI_PROPERTY,
                    events: [],
                    isLoading: false,
                    errors: errors
                });
            }

        } catch (e) {
            console.log(e);
            errors.fetchError.show = true;
            this.setState({
                view: constants.CALENDAR.VIEW.MULTI_PROPERTY,
                events: [],
                isLoading: false,
                errors: errors
            });
        }

        this.forceUpdate();
    }
    
    checkSmartPricingOvr = async (propertyId) => {
        let enabled = false;

        try {
            await API.get('smartypi', `/smartpricing/${propertyId}`, { response: true });
            enabled = true;
        }
        catch(e) {
            console.log(e)
            enabled = false;
        };

        return enabled;
    }

    getSmartPricingOvr = async (propertyId) => {
        let response = {};
        let overrides = [];

        try {
            response = await API.get('smartypi', `/smartpricing/${propertyId}/overrides`, { response: true });
            
            if(response && response.data && response.data.overrides) {
                overrides = response.data.overrides;
            };
        }
        catch(e) {
            console.log(e)
        };

        return overrides;
    }

    /**
     * Fetch current reservation data.
     */
    async getReservations() {
        // getting reservations data
        this.setState({
            editEntirePricePeriod: false,
            editEntireCicoPeriod: false,
            gettingReservations: true,
            isLoading: true,
            view: constants.CALENDAR.VIEW.MULTI_PROPERTY,
            events: [],
            s3CsvFile: "",
            errors: this.getInitialErrorState()
        });
        const maximum = 99999
        const minimum = 10000
        const tid = (Math.floor(Math.random() * ((maximum - minimum) + 1)) + minimum)

        let allReservations = [],
            done = false,
            offset = 0;
        const { showAllOrg } = this.state;

        try {
            while (!done) {
                let params = `?organization${showAllOrg ? 's' : ''}=${showAllOrg ? this.allOrganizations.join() : this.org}&status=current&checkout=${moment().format('YYYY-MM-DD')}&limit=100&tid=${tid}&offset=${offset}`
                let reservations = await apiHandler.get(constants.RAPAPI.PROPERTY_RESERVATIONS + params, { isCognito: true });
                if (!reservations.data) {
                    reservations.data = []
                }
                allReservations = allReservations.concat(reservations.data)
                if (reservations.headers) {
                    if (allReservations.length >= reservations.headers['x-total-count'])
                        done = true
                    else
                        offset += 100;
                }
            }
            this.setState({
                gettingReservations: false,
                reservationList: allReservations,
            })
            allReservations.forEach(resv => {
                resv['checkin_date'] = moment(resv['checkin_date']).hours(12);
                resv['checkout_date'] = moment(resv['checkout_date'])
                    .subtract(1, 'days').hours(12)
            })
            this.getProperties(allReservations);
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Initialize current pagination modal.
     * @param {*} total total number of property. 
     * @param {*} page current page number.
     */
    initPagination = (total, page) => {
        this.setState({
            pagination: {
                ...this.state.pagination,
                totalItemsCount: total,
                activePage: page,
            }
        })
    }

    handleShowAllOrg = (name) => {
        this.setState({ 
            [name]: !this.state[name]
        }, () => {
            if(this.props.location && this.props.location.search && (queryString.parse(this.props.location.search).page > 1)) {
                this.props.history.push('?page=1');
            }
            else {
                this.setState({
                    isLoading: true
                });

                const { 
                    reservationList,
                    pagination
                } = this.state;
                let propertyLevelArr = [];

                this.fetchProperties(0, 0).then(async(properties) => { 
                    if(properties.data) {
                        for(let i = 0; i < properties.data.length; i++) {
                            await this.configSmartPriceOvr(properties.data[i]);
                        };
                    };
    
                    await Promise.all(properties.data.map(async(prop,index) => {
                        propertyLevelArr.push(prop.level);

                        properties.data[index]['reservations'] = reservationList.filter(resv => {
                            if (properties.data[index].property_id == resv.property_id) {
                                return resv;
                            };
                        });

                        if (properties.data[index].level === 'rep') {
                            let availability = await this.getRepAvailability(properties.data[index].property_id, properties.data[index].price_periods || [], (properties.data[index].repconfig.units_active && properties.data[index].repconfig.units_active.length) || 0) || []
                            properties.data[index]['availability'] = availability;
                        };
                    }));

                    this.setState({
                        pagination: {
                            ...pagination,
                            totalItemsCount: properties.total
                        },
                        events: properties.data,
                        propertyList: properties.data,
                        comparePropertyList: properties.data,
                        propertyLevels: propertyLevelArr,
                        s3CsvFile: "",
                        isLoading: false
                    });
                })
            };
        });
    }

    /**
     * For fetch properties. 
     * @param {*} page current page number and limit of properties.
     * @param {*} limit 
     */
     async fetchProperties(page, limit) {
        const { showAllOrg } = this.state;
        let properties = [],
            org = this.org;
        let count = limit || this.state.pagination.itemsCountPerPage;
        this.setState({
            isLoading: true
        })

        let keyword = this.searchInput ? this.searchInput.value : '';

        try {
            properties = await apiHandler.getProperties({
                page,
                limit: count,
                org,
                allOrganizations: this.allOrganizations,
                showAllOrg: showAllOrg,
                keyword: keyword.toLowerCase(),
            });
        } catch (e) {
            console.log("ERROR::", e)
        }
        return properties;
    }

    handlePageSizeChange(e) {
        e.preventDefault()
        e.target.pageSize.blur()
        let pageSize = e.target.pageSize.value,
            pagination = this.state.pagination,
            reservations = this.state.reservationList;
        if (!isNaN(pageSize) && pageSize.trim() != '' && pageSize != pagination.itemsCountPerPage) {
            this.fetchProperties(0, pageSize).then(async(properties) => {
                pagination.totalItemsCount = properties.total;
                pagination.itemsCountPerPage = pageSize;

                if(properties.data) {
                    for(let i = 0; i < properties.data.length; i++) {

                        // Smart Pricing Override
                        await this.configSmartPriceOvr(properties.data[i]);
                    };
                };

                await Promise.all(properties.data.map(async(prop,index) => {
                    properties.data[index]['reservations'] = reservations.filter(resv => {
                        if (properties.data[index].property_id == resv.property_id) {
                            return resv;
                        }
                    });
                    if (properties.data[index].level === 'rep') {
                        let availability = await this.getRepAvailability(properties.data[index].property_id, properties.data[index].price_periods || [], (properties.data[index].repconfig.units_active && properties.data[index].repconfig.units_active.length) || 0) || []
                        properties.data[index]['availability'] = availability;
                    }
                }))
                this.setState({
                    pagination: pagination,
                    events: properties.data,
                    propertyList: properties.data,
                    s3CsvFile: "",
                    isLoading: false
                }, () => {
                    const pagination = {
                        itemsCountPerPage: this.state.pagination.itemsCountPerPage,
                    };
                    sessionStorage.setItem('calendar-listing', JSON.stringify(pagination));
                });
            })
        }
    }

    /**
     * change page number in URL 
     * @param {string} page current active page number. 
     */
    getPageByNum(page) {
        if (page !== this.state.pagination.activePage) {
            this.props.history.push('?page='+page)
        }
    }
    /**
     * Get month view calendar title,
     */
    getCalendarTitle() {
        let { mobileMode } = this.props, 
        property
        if (this.state.view == constants.CALENDAR.VIEW.MONTH) {
            property = this.getPropertyById(this.state.activeProperty)[0];
            if(this.state.propertyList[0] && this.state.propertyList[0].level === 'repchild') {
                return {
                    title: `Room ${property && property.property_id} Calendar`,                   
                    level: property && property.level,
                    breadcrumbItems: [{
                        title: "Calendar",
                        link: "/calendar"
                    },
                    {
                        title: property && property.repconfig.parent_name,
                        link: `/calendar/${property && property.repconfig.parent_id}/edit`
                    }, 
                    {
                        title: property && property.content.title
                    }]
                };
            } else {
                return {
                    title: `${mobileMode ? "" : 
                    `${property && property.level === "key" ? "Property" : "Room Type"}`}  ${property && property.property_id} Calendar`,
                    level: property && property.level,
                    breadcrumbItems: [{
                        title: "Calendar",
                        link: "/calendar"
                    }, {
                        title: property && property.content.title || property.property_id
                    }]
                };
            } 
        }
        return {
            title: "Calendar",
            breadcrumbItems: [{
                title: "Calendar",
            }]
        }
    }

    /**
     * Get event title on calender availability period.
     * @param {*} event 
     * @returns return type of availability of block. 
     */
    getEventTitle(event) {
        // adding titles on the various types of events in the calendar

        const { 
            propertyChildren, 
        } = this.state
        
        if (event.price_period_id) {
            return 
        }

        if (event && event.period && event.period.type === 'Rooms Available') {
            let num = event.roomsAvailable
            let numClass = num < 1 ? 'room no-rooms' : 'room';
            //TODO Check logic event.period.propertyId
            let CountClass = num < propertyChildren[event.period.propertyId].length ? ' less-rooms' : '';

            return num === 1 ? <div className={numClass + CountClass}><span className="count">1</span> Room Available</div> : <div className={numClass + CountClass}><span className="count">{num < 0 ? 0 : num}</span>{this.props.mobileMode ? "" : " Rooms Available"}</div>;
        }
        if (event && event.period) {
            if (event.period.type=="Temporary") {
                return constants.CALENDAR.TEMPORARY
            }
            return `${constants.CALENDAR.AVAILABILITY_TITLE[event.period.type]} ${event.period.notes && event.period.notes[0] ? `(${event.period.notes[0].type})` : ""}`  || constants.CALENDAR.AVAILABILITY_TITLE["Default"]
        }
        if (event && event.availability_ids) {
            return "Rooms Unavailable"
        }
        if (event && event.mobile_block_id) {
            return "Mobile Rep Block"
        }
        if (event && event.cico_id)
            return ''
        return `Reservation # ${event.reservation_id}`
   }

    /**
     * Get event start date.
     * @param {*} event 
     * @returns return start date of event. 
     */

    getEventStart = event => {
        if (event) {
            // determining the event start date accessor for different types of events in the calendar
            let anyEvent = event && (event.checkin_date || event.period_start || event.start_date || event.period && event.period.period_start)
    
            let startsBeforeAndEndsAfterToday = event && event.period && event.period.availability_id && moment(event.period.period_start).isSameOrBefore(this.today) && moment(event.period.period_end).isSameOrAfter(this.today) || event.reservation_id && moment(event.checkin_date).isSameOrBefore(this.today) && moment(event.checkout_date).isSameOrAfter(this.today)
    
            if (this.state.view === "month") {
                if (moment(anyEvent).isSameOrAfter(this.today) || this.activeProperty.level !== "rep" && startsBeforeAndEndsAfterToday) {
                    return anyEvent
                }
            }
            else {
                return anyEvent
            }
        }
    }

    /**
     * Get event end date.
     * @param {*} event 
     * @returns return end date of event. 
     */
    getEventEnd(event) {
        // determining the event end date accessor for different types of events in the calendar
        return event && (event.checkout_date || event.period_end || event.end_date || event.period && event.period.period_end) 
    }

    /**
     * Get event style 
     * @param {*} event
     * @returns Class name of event. 
     */
    setEventStyles = event => {
        // adds specific classes for different types of events in the calendar
        let cls
        if (event && event.price_period_id)
            cls = 'prices'  
        else if (event && event.propertyId)
            cls = 'rep-availability'              
        else if (event && event.period) {
            if (event.period.type == "Temporary") {
                cls = 'availability temporary';
            } else {
                cls = 'availability';
            }
        }
        else if (event && event.cico_id)
            cls = 'hide'
        else if (event && event.mobile_block_id) {
            cls = 'availability rooms-unavailable'
            if (event.type.split('-')[1]) {
                cls += " zero-rooms"
            }
        }
        else {
            cls = 'reservations'
        }
        return {
            className: cls
        }
    }

    /**
     * Show event details.
     * @param {*} event event details.
     * @param {*} e 
     * @param {string} id send id of property.  
     */
    showEventDetails(event, e, id) {
        if (event && event.propertyId) {
            this.showAvailableRooms(event, id, event.period.period_start);
        }
        else if (event.period) {
            this.editAvailability(event.period, id)
        }
        else if(event.cico_id) {
            this.editCico(e, event, id, "", "list");
        }
        else if (event.reservation_id) {
            if (this.props.mobileMode) {
                this.handleUpdateModalEventsByDate (
                    e, 
                    event.checkin_date, 
                    'availability', 
                    this.getPropertyById(id)[0],
                )
            }
            else {
                this.props.history.push({
                    pathname: `/reservations/${event.reservation_id}/details`
                })
            }
        }
    }

    /**
     * Show available room modal on room type calendar
     * @param {Array} event object of rooms not available.
     * @param {string} availableRoomPropertyId room type id.
     */
    showAvailableRooms(event, availableRoomPropertyId, dateClicked){
        if (event) {
            if (this.props.mobileMode) {
                this.setState({
                    mobileCalendarModal: {
                        ...this.state.mobileCalendarModal,
                        availabilityPanel: {
                            ...this.state.mobileCalendarModal.availabilityPanel,
                            property_id: availableRoomPropertyId,
                            data: {
                                ...this.state.mobileCalendarModal.availabilityPanel.data,
                                period_start: dateClicked,
                                period_end: dateClicked,
                                type: 'BlockedByManager',
                            },
                            show: true,
                        },
                    },
                    dateClicked,
                    unAvailableRooms: event
                })
            }
            else {
                this.setState({
                    availableRoomModal: {
                        show: true,
                        period_start: event && event.period && event.period.period_start,
                        period_end: event && event.period && event.period.period_end,
                    },
                    availableRoomPropertyId,
                    unAvailableRooms: event
                })
            }
        }
    }

    onView(view, id) {
        // change the event list when the view changes to multi property view
        if (view == constants.CALENDAR.VIEW.MULTI_PROPERTY) {
            this.setState({
                view: constants.CALENDAR.VIEW.MULTI_PROPERTY,
                activeProperty: '',
                events: this.state.propertyList
            })
            this.getPageByNum(this.state.pagination.activePage)
        }
        else {
            this.onPropertySelected(id)
        }

    }

    /**
     * Get property information from property id.
     * @param {string} id property id.
     */
    getPropertyById(id) {
        // method to get the property info by property_id
        let properties = this.state.propertyList;
        return properties.filter(prop => {
            if (prop.property_id == id) {
                return prop;
            }
        })
    }

    /**
     * Array difference a1 - a2 
     * @param {array} a1 array one
     * @param {array} a2 array two
     */
    arr_diff (a1, a2) {
        let diff = [];
        a1.forEach(a => {
            let aaa = true;
            a2.forEach(aa => {
                if (a === aa) {
                    aaa = false;
                    return;
                }
            })
            if(aaa === true) {
                diff.push(a)
            }
        })
        return diff;
    }

    /**
     * Get availability period of room type calendar.
     * @param {string} propertyId 
     * @param {object} prices 
     * @param {number} units number of live units
     * @returns availability array of room type.
     */
    async getRepAvailability(propertyId, prices, units) {
        let children = [], 
        availTable = {}, 
        mBlocks = []

        try {
            this.setState({
                isLoading: true
            })
            let availabilityByDateData = await apiHandler.get(`/properties/${propertyId}/availabledates?range=730`)

            let repLevelTBlocks = await apiHandler.get(`/properties/${propertyId}/availability`)

            if (repLevelTBlocks) {
                this.repTBlocks[propertyId] = repLevelTBlocks.data
            }
            if (availabilityByDateData) {
                availabilityByDateData = availabilityByDateData.data
                if (availabilityByDateData) {
                    this.multiAvailTable[propertyId] = {}
                    availabilityByDateData.forEach(obj => {
                        if (obj.num_available < units) {
                            if (obj.num_available === 0) {
                                //no rooms available
                                obj['type'] = '0'
                            }
                            else {
                                //some rooms available
                                obj['type'] = '1'
                            }
                        }
                        this.multiAvailTable[propertyId][obj.date] = obj.num_available
                        availTable[obj.date] = obj.num_available
                    })
                    /* generate UI blocks for mobile view */
                    availabilityByDateData.forEach((el, idx) => {
                        if (el.type) {
                            traverseByIndex(idx, el.type)
                        }
                    })
                }
            }
            
            let availability = await apiHandler.get(`/properties/${propertyId}/repavailability`)

            if (availability) {
                availability = availability.data
                this.multiRepAvailability[propertyId] = availability
                this.multiRepMobileBlocks[propertyId] = mBlocks
            }
            function traverseByIndex (idx, type) {
                let mBlock = {
                    type: type === '1' ? 
                    'MobileUIBlock' : 
                    'MobileUIBlock-0',
                    period_start: availabilityByDateData[idx].date,
                    mobile_block_id: `muib${Math.floor(Math.random() * 10000)}`,
                },
                innerIdx = idx, 
                hasTraversed = false
                while (hasTraversed === false && innerIdx < (availabilityByDateData.length - idx)) {
                    if (availabilityByDateData[innerIdx].type === type) {
                        mBlock['period_end'] = availabilityByDateData[innerIdx].date
                    }
                    else {
                        hasTraversed = true
                    }
                    innerIdx +=1
                }
                if (hasTraversed) {
                    if (mBlocks.map(b => b.period_end).indexOf(mBlock.period_end) === -1) {
                        mBlocks.push(mBlock)
                    }
                }
            }
           
            const maximum = 99999
            const minimum = 10000
            let tid = (Math.floor(Math.random() * ((maximum - minimum) + 1)) + minimum);
            let childrenRes = await apiHandler.get(`/properties/${propertyId}/children?limit=100&offset=0&tid=${tid}`);
            let childrenResCount = childrenRes.headers['x-total-count'];
            children = childrenRes.data;

            if (children && children.length < childrenResCount) {
                let offset = 0
                let lessThanCount = childrenResCount / children.length

                for (let loopCount = 0; loopCount < lessThanCount; loopCount++) {
                    offset = offset + 100
                    let res = await apiHandler.get(`/properties/${propertyId}/children?limit=100&offset=${offset}&tid=${tid}`)
                    children = children.concat(res.data)
                }
            }
             
            let propertyChildren = this.state.propertyChildren
            propertyChildren[propertyId] = children

            this.setState({ 
                availableChildren: children, 
                availTable, 
                propertyChildren,
                availability,
            })

            let availability41 = [];
            children.forEach((child, index) => {
                child.availability.forEach((availObj, index1) => {
                    for (let i = moment(availObj.period.period_start); i.isSameOrBefore(availObj.period.period_end); i = i.add(1, 'days')) {
                        availability41.push({ start: i.format('YYYY-MM-DD'), index, index1 });
                    }
                })
            })

            let FinalAvail = []
            prices.forEach((date) => {
                for (let i = moment(date.period_start); i.isSameOrBefore(date.period_end);i = i.add(1, 'days')) {
                    let newDate = i.format('YYYY-MM-DD');
                    let avail = availability41.filter((data) => data.start === newDate)
                    FinalAvail.push({
                        propertyId: avail.map(date => date.index),
                        roomsAvailable: availTable[newDate] !== undefined ? availTable[newDate] : children.length - avail.length,
                        period: {
                            period_start: newDate,
                            period_end: newDate,
                            type: 'Rooms Available',
                            propertyId,
                        }
                    })
                    availability41 = this.arr_diff(availability41, avail)
                }
            })

            if (availability && availability.length) {
                FinalAvail.forEach((availObj, i) => {
                    let blockTypes = []
                    availability.forEach(item => {
                        let fDate = moment(availObj.period.period_start)
                        if (fDate.isSameOrAfter(item.period_start) && fDate.isSameOrBefore(item.period_end)) {
                            blockTypes.push({
                                    type: item.type, 
                                    quantity: item.units, 
                                    availability_ids: item.availability_ids
                                }
                            )
                            FinalAvail[i]['blockTypes'] =  blockTypes
                        }
                    })
                })
            }
            this.multiRepFinalAvail[propertyId] = FinalAvail

            if (repLevelTBlocks && repLevelTBlocks.data) {
                FinalAvail = FinalAvail.concat(repLevelTBlocks.data)
            }
            if (mBlocks.length) {
                FinalAvail = FinalAvail.concat(mBlocks)
            }
            return FinalAvail
        } catch (e) {
            console.log(e)
            return []
        }
    }

    /**
     * On select any property.
     * @param {string} id property or room type id
     */
    async onPropertySelected(id) {
        // clickhandler on selecting a property from multi property view
        let property = this.getPropertyById(id)[0];
        let prices = property && property.price_periods || [], availability = []
        if (property && property.level === 'rep') {
            availability = [] //this.state.propertyList[property.property_id].availability
            for (let i = 0; i < this.state.propertyList.length; i++) {
                if (this.state.propertyList[i].property_id === property.property_id) {
                    availability = this.state.propertyList[i].availability
                }
            }
            // availability = await this.getRepAvailability(property && property.property_id, prices, property && property.units) || []
        } else {
            availability = property && property.availability.filter(evt => {
                if (evt.period.type == "BlockedByBooking")
                    return false
                return true
            }) || []
        }
        let reservations = property && property.reservations || [],
            cico = property && property.cico || [],
            eventList = reservations,
            acc = this.state.eventAccessor,
            title = property && property.content.title + " #" + id
        if (property && property.level === 'rep') {
            eventList = []
        }
        if (acc.indexOf('price_periods') > -1)
            eventList = eventList.concat(prices);
        if (acc.indexOf('availability') > -1)
            eventList = eventList.concat(availability);
        if (acc.indexOf('cico') > -1)
            eventList = eventList.concat(cico);
        
        this.setState({
            activeProperty: id,
            events: eventList,
            view: constants.CALENDAR.VIEW.MONTH,
            title: title,
            isLoading: false
        })
    }

    /**
     * Handle on click of cell popup a date with more number of events. 
     * @param {object} _props 
     */
    handleShowMore(_props) {
        this.setState({
            showPopup: true,
            popupProps: _props
        })
    }

    /**
     * Toggle import modal
     */
    toggleImportModal() {
        this.setState({
            importModal: !this.state.importModal
        })
    }

    toggleShowIcalUrlModal = () => {
        this.setState({
            showIcalUrlModal: !this.state.showIcalUrlModal
        });
    }

    /**
     * For close Import process after complete. 
     */
    toggleImportByProperty() {
        this.setState({
            importByProperty: !this.state.importByProperty,
            value: '',
            importId: undefined,
            importProcess: false
        })
    }

    /**
     * After adding price and cico period reinitialize pricing page.
     */
    updateProperty() {
        this.toggleImportByProperty()
        this.updateData();
    }

    /**
     * Import pricing and cico function.  
     */
    async importByProperty() {
        let i = 0, apis = 0;
        this.setState({
            importProcess: true,
            importCompleted: {
                all: 0,
                completed: 0,
                error: 0
            },
        })
        let price_periods = this.state.importId.price_periods
        let cico = this.state.importId.cico;
        if (price_periods || cico) {
            price_periods = price_periods.filter((data) => new Date(data.period_end).getTime() > new Date().getTime())
            cico = cico.filter((data) => new Date(data.end_date).getTime() > new Date().getTime())
            if (price_periods.length > 0 || cico > 0) {
                this.setState({
                    importCompleted: {
                        all: price_periods.length + cico.length,
                        completed: 0,
                        error: 0
                    }
                })
            } else {
                this.toggleImportByProperty()
                this.setState({
                    notAvailable: true
                })
                return
            }
        } else {
            this.toggleImportByProperty()
            this.setState({
                notAvailable: true
            })
            return
        }
        try {
            await apiHandler.update('delete', `/properties/${this.state.activeProperty}/cico`, {})
        } catch (err) {
            i += 1;
            let importCompleted = this.state.importCompleted;
            importCompleted.error += 1
            this.setState({
                importCompleted
            })
            console.log(err)
        }  
        try {
            await apiHandler.update('delete', `/properties/${this.state.activeProperty}/priceperiods`, {})
        } catch(err) {
            i += 1;
            let importCompleted = this.state.importCompleted;
            importCompleted.error += 1
            this.setState({
                importCompleted
            })
            console.log(err)
        }

        for (let data of cico) {
            delete data.cico_id;
            await apiHandler.post(`/properties/${this.state.activeProperty}/cico`, { body: data }).then((addRes) => {
                i += 1;
                if (addRes.error) {
                    this.setState({
                        importCompleted: {
                            all: this.state.importCompleted.all,
                            completed: this.state.importCompleted.completed,
                            error: this.state.importCompleted.error + 1
                        }
                    })
                } else {
                    this.setState({
                        importCompleted: {
                            all: this.state.importCompleted.all,
                            completed: i,
                            error: this.state.importCompleted.error
                        }
                    })
                }
            }).catch((err) => {
                i += 1;
                this.setState({
                    importCompleted: {
                        all: this.state.importCompleted.all,
                        completed: this.state.importCompleted.completed,
                        error: this.state.importCompleted.error + 1
                    }
                })
            })
        }      
        for (let data of price_periods) {
            delete data.price_period_id
            delete data.name
            await apiHandler.post(`/properties/${this.state.activeProperty}/priceperiods`, { body: data }).then((addRes) => {
                i += 1;
                if (addRes.error) {
                    this.setState({
                        importCompleted: {
                            all: this.state.importCompleted.all,
                            completed: this.state.importCompleted.completed,
                            error: this.state.importCompleted.error + 1
                        }
                    })
                } else {
                    this.setState({
                        importCompleted: {
                            all: this.state.importCompleted.all,
                            completed: i,
                            error: this.state.importCompleted.error
                        }
                    })
                }
            }).catch((err) => {
                i += 1
                this.setState({
                    importCompleted: {
                        all: this.state.importCompleted.all,
                        completed: this.state.importCompleted.completed,
                        error: this.state.importCompleted.error + 1
                    }
                })
            })
        }
    }

    /**
     * Toggle modal import by CSV. 
     */
    toggleImportByCSV() {
        this.setState({
            csvPayload: undefined,
            CSVModal: !this.state.CSVModal,
            importError: '',
            templateError: '',
        })
    }

    changeCalendarSource = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        }, () => {
            const { availabilityFeed } = this.state;

            if (value === "ical_one_time_import") {
                this.setState({
                    selectedFrequency: {},
                    availabilityFeed: {
                        ...availabilityFeed,
                        feed_type: "ical",
                        one_time_run_ts: "1574677833",
                        frequency: "",
                    }
                });
            }
            else if (value === "ical_source_of_truth") {
                this.setState({
                    selectedFrequency: constants.UPDATE_FREQUENCY[1],
                    availabilityFeed: {
                        ...availabilityFeed,
                        feed_type: "ical",
                        one_time_run_ts: "",
                        frequency: 120,
                    }
                });
            };
        })
    }

    handleSmartPricingSource = (e) => {
        const { 
            pricingModal,
            mobileCalendarModal
        } = this.state;
        const value = e.target.value;

        if(this.props.mobileMode) {
            this.setState({
                mobileCalendarModal: {
                    ...mobileCalendarModal,
                    pricingPanel: {
                        ...mobileCalendarModal.pricingPanel,
                        priceOvrSource: value,
                    },
                }
            });
        }
        else {
            this.setState({
                pricingModal: {
                    ...pricingModal,
                    priceOvrSource: value,
                }
            });
        };
    }

    handleSmartPricingChange = (e) => {
        const { 
            pricingModal,
            mobileCalendarModal
        } = this.state;
        const name = e.target.name;
        const value = e.target.value;

        if(this.props.mobileMode) {
            this.setState({
                mobileCalendarModal: {
                    ...mobileCalendarModal,
                    pricingPanel: {
                        ...mobileCalendarModal.pricingPanel,
                        priceOvr: {
                            ...mobileCalendarModal.pricingPanel.priceOvr,
                            [name]: value
                        }
                    },
                }
            });
        }
        else {
            this.setState({
                pricingModal: {
                    ...pricingModal,
                    priceOvr: {
                        ...pricingModal.priceOvr,
                        [name]: value
                    }
                }
            });
        };
    }

    configSmartPriceOvr = async (propertyData) => {

        const smartPricingEnabled = await this.checkSmartPricingOvr(propertyData.property_id);
    
        if(smartPricingEnabled) {

            const smartPricingOverrides = await this.getSmartPricingOvr(propertyData.property_id);
    
            // Add smart_pricing_enabled to property data.
            propertyData.smart_pricing_enabled = true;
    
            // Match overrides for each price_period.
            if(propertyData.price_periods) {
    
                propertyData.price_periods.forEach((price) => {
                    const period_start = moment(price.period_start).format('YYYY-MM-DD');
                    const period_end = moment(price.period_end).format('YYYY-MM-DD');
                    let price_period_overrides = [];
    
                    smartPricingOverrides.forEach((override) => {
                        const overrideDate = moment(override.date).format('YYYY-MM-DD');
    
                        if(
                            moment(overrideDate).isBetween(period_start, period_end) || 
                            moment(overrideDate).isSame(period_start) || 
                            moment(overrideDate).isSame(period_end)
                        ) {
                            price_period_overrides.push(override);
                        };
                    });
    
                    price.price_period_overrides = price_period_overrides;
                });
            };
        };
    }

    toggleMinPriceOnly = () => {
        const { 
            pricingModal,
            mobileCalendarModal
         } = this.state;

        if(this.props.mobileMode) {
            this.setState({
                mobileCalendarModal: {
                    ...mobileCalendarModal,
                    pricingPanel: {
                        ...mobileCalendarModal.pricingPanel,
                        minPriceOnly: !mobileCalendarModal.pricingPanel.minPriceOnly
                    },
                }
            });
        }
        else {
            this.setState({
                pricingModal: {
                    ...pricingModal,
                    minPriceOnly: !pricingModal.minPriceOnly
                }
            });
        };
    }

    changeAvailabilityFeed = (e) => {
        const { availabilityFeed } = this.state;
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            availabilityFeed: {
                ...availabilityFeed,
                [name]: value
            }
        });
    }

    changeFrequency = (value) => {
        const { availabilityFeed } = this.state;

        this.setState({
            selectedFrequency: value,
            availabilityFeed: {
                ...availabilityFeed,
                frequency: value.value
            }
        });
    }

    viewSettingsAvailabilityModal = () => {
        this.setState({
            availabilityModal: {
                ...this.state.availabilityModal,
                show: false
            },
            importModal: true
        });
    }

    /**
     * Import iCal URL function
     */
    async importIcal() {
        let availabilityFeed = {...this.state.availabilityFeed};
        let error;
        let response;

        this.setState({ 
            importing: true, 
            importError: '' 
        });

        if(availabilityFeed.use_checkout_date) {
            availabilityFeed.use_checkout_date = availabilityFeed.use_checkout_date == "1" ? "true" : "false";
        };

        try {
            response = await API.put(constants.RAPAPI.NAME, constants.RAPAPI.AVAILABILITY_FEED(this.state.activeProperty), { body: availabilityFeed })
            this.setState({ 
                importModal: false 
            });
            this.updateData();

        } 
        catch (e) {
            if (e.response && e.response.data.Message) {
                error = e.response.data.Message.split("\"")[1] || 'An error has occurred'
            } 
            else {
                error = 'An error has occurred';
            };
        };
        this.setState({ 
            importing: false, 
            importError: error || '' 
        });
    }

    /**
     * Import CICO/Pricing data by CSV Upload
     */
    postCSVPricingFile = async e => {
        e.preventDefault();
        let offset = 500 + Math.floor(Math.random() * 1000);
       
        this.setState({
            importing: true,
            importError: "",
            templateError: "",
        })
        let path, payload;
        let customErrorMessage = 'There was an issue importing the file. Please contact Customer Support.';

        if (this.state.activeProperty) {
            ({ payload } = this.state.csvPayload)
            path = `/properties/${this.state.activeProperty}/pricingfile`
        }
        else {
            payload =  { 
                'org_name' : this.org, 
                'file': JSON.stringify(this.state.csvPayload.payload), 
            }
            path = `/properties/pricingfile`
        }
        
        try {
            API.post('rapapi', path, {"body": payload}).then(async response => {
                if (response && response.status && response.status.toLowerCase() === "created") {
                    let startTime = moment().valueOf()
                    this.callTokenResponsePost(response, startTime)
                }
            }).catch(async err => {
                let errorMessage
                if (err && err.response && err.response.data && err.response.data.Message) {
                    let startIdx = err.response.data.Message.indexOf('['), 
                    endIdx = err.response.data.Message.indexOf(']')
                    
                    if (startIdx === -1 || endIdx === -1) {
                        startIdx = err.response.data.Message.indexOf("'")
                        endIdx = err.response.data.Message.lastIndexOf("'")
                        errorMessage = err.response.data.Message.substring(startIdx + 1, endIdx)
                    }
                    else {
                        errorMessage = `The following columns cannot be empty: ${err.response.data.Message && err.response.data.Message.substring(startIdx + 1, endIdx).replace(/["']/g, "")}`
                    }
                }
                else {
                    errorMessage = customErrorMessage;
                }
                await delay(offset);
                this.setState({
                    importError: errorMessage,
                    importing: false,
                })
            })
        } catch (err) {
            await delay(offset);
            this.setState({
                importing: false,
                importError: customErrorMessage
            })
        }
    }

    callTokenResponsePost = async (response, startTime) => {
        let offset = 500 + Math.floor(Math.random() * 1000);
        let customErrorMessage = "There was an issue importing the file. Please contact Customer Support.";

        try {
            let statusComplete = false 
            while (statusComplete === false) {
                let tokenResponse = await API.get('rapapi', response.status_url, {})
                if (moment().valueOf() - startTime > 600000 || tokenResponse && tokenResponse.status && tokenResponse.status.toLowerCase() === "error") {
                    statusComplete = true
                    await delay(offset);
                    this.setState({
                        importing: false,
                        importError: tokenResponse.response ? tokenResponse.response : customErrorMessage,
                    })
                }
                else if (tokenResponse && tokenResponse.status && tokenResponse.status.toLowerCase() === "completed") {
                    statusComplete = true
                    await delay(offset);
                    this.setState({
                        importing: false,
                        successImportCSV: true,
                    });
                    this.updateData()
                    this.toggleImportByCSV()
                }
                if (!statusComplete) {
                    // Randon delay of 500ms to 1500ms before checking the status again
                    await delay(offset);	
                }
            }
        } catch (err) {
            if ((moment().valueOf() - startTime) < 30000) {
                await delay(offset);
                this.callTokenResponsePost(response, startTime)
            } else {
                this.setState({
                    importing: false,
                    importError: customErrorMessage
                })
            }
        }
    }
    
    getCSVPricingFile = async e => {
        e.preventDefault()
        
        this.setState({
            downloading: true,
        })
        let path,
            offset = this.state.pagination.itemsCountPerPage * (this.state.pagination.activePage - 1);
        let customErrorMessage = "There was an issue downloading the file. Please contact Customer Support.";
        
        if (this.state.activeProperty) {
            path = `/properties/${this.state.activeProperty}/pricingfile`
        }
        else {
            path = `/properties/pricingfile?organization=${this.org}&limit=${this.state.pagination.itemsCountPerPage}&tid=${this.tid}&offset=${offset}&sort=property_id${encodeURIComponent('+')}`
        }
        try {
            let response = await API.get('rapapi', path, {})

            if (response && response.status && response.status.toLowerCase() === "created") {
                let startTime = moment().valueOf()
                this.callTokenResponseGet(response, startTime, customErrorMessage);
            }
        } catch(err) {
            this.setState({
                downloading: false,
                templateError: customErrorMessage
            })
        }
    }

    callTokenResponseGet = async (response, startTime, customErrorMessage) => {
        let csvLink = document.getElementById('csv-link');
        let timeStamp = new Date().getTime();
        let offset = 500 + Math.floor(Math.random() * 1000);
        
        try {
            let templateComplete = false
            
            while (templateComplete === false) {
                    let tokenResponse = await API.get('rapapi', response.status_url, {})

                    if ((moment().valueOf() - startTime) > 600000 || tokenResponse && tokenResponse.status && tokenResponse.status.toLowerCase() === "error") {
                        templateComplete = true
                        await delay(offset);
                        this.setState({
                            downloading: false,
                            templateError: tokenResponse.response ? tokenResponse.response : customErrorMessage,
                        })
                    }
                    else if (tokenResponse && tokenResponse.status && tokenResponse.status.toLowerCase() === "completed") {
                        templateComplete = true
                        await delay(offset);
                        this.setState({
                            downloading: false,
                            s3CsvFile: `${tokenResponse.response}?timeStamp=${timeStamp}`,
                        })
                        setTimeout(()=> {
                            csvLink.click()
                        }, 50)
                    }
                    if (!templateComplete) {
						// Randon delay of 500ms to 1500ms before checking the status again
						await delay(offset);	
					}
            }
        } catch(e) {    
                if ((moment().valueOf() - startTime) < 30000) {
                    await delay(offset);
                    this.callTokenResponseGet(response, startTime);
                } else {
                    this.setState({
                        downloading: false,
                        templateError: customErrorMessage
                    });
                };
        }
    }

    // Events for Pricing Modal
    /**
     * Add pricing on calendar
     * @param {*} sDate start date of pricing
     * @param {*} eDate end date of pricing
     * @param {*} id property id
     */
    addPricing(sDate, eDate, id, dateClicked, cico) {
        // Add Pricing Click handler
        let modal = this.getInitialPricingModal(),
            errors = this.getInitialErrorState();

        if (this.props.mobileMode) {
            let cicoData =
                cico ? 
                (
                    Object.keys(cico).length ? 
                    cico 
                    :
                    this.getInitialCicoModal(sDate).data
                )
                :
                this.getInitialCicoModal(sDate).data;

            this.setState({
                mobileCalendarModal: {
                    ...this.state.mobileCalendarModal,
                    pricingPanel: {
                        ...modal,
                        show: true,
                        property_id: id,
                        data: {
                            ...modal.data,
                            period_start: moment(sDate).format('YYYY-MM-DD'),
                            period_end: moment(eDate).format('YYYY-MM-DD')
                        },
                        pricingDates: {
                            startDate: moment(sDate),
                            endDate: moment(eDate),
                        },
                    },
                    cicoPanel: {
                        ...this.state.cicoModal,
                        property_id: id,
                        data: cicoData
                    }
                },
                errors: errors,
                dateClicked,
            });
        }
        else {
            this.setState({
                pricingModal: {
                    ...modal,
                    show: true,
                    property_id: id,
                    data: {
                        ...modal.data,
                        period_start: moment(sDate).format('YYYY-MM-DD'),
                        period_end: moment(eDate).format('YYYY-MM-DD')
                    },
                    pricingDates: {
                        startDate: moment(sDate),
                        endDate: moment(eDate),
                    },
                },
                errors: errors
            })
        }
    }

    addCico = (sDate, eDate, id, dateClicked, e) => {
        e && e.preventDefault();
        let modal = this.getInitialCicoModal(),
            errors = this.getInitialErrorState();

        if (this.props.mobileMode) {
            this.setState({
                mobileCalendarModal: {
                    ...this.state.mobileCalendarModal,
                    pricingPanel: {
                        ...this.state.mobileCalendarModal.pricingPanel,
                        show: true
                    },
                    cicoDates: {
                        startDate: moment(sDate),
                        endDate: moment(eDate),
                    },
                },
                errors: errors,
                dateClicked,
            })
        }
        else {
            this.setState({
                cicoModal: {
                    ...modal,
                    show: true,
                    property_id: id,
                    data:  {
                        ...modal.data,
                        start_date: moment(sDate).format('YYYY-MM-DD'),
                        end_date: moment(eDate).format('YYYY-MM-DD')
                    },
                    cicoDates: {
                        startDate: moment(sDate),
                        endDate: moment(eDate),
                    },
                },
                errors: errors
            })
        }
    }

    /**
     * Select property from suggestions in import pricing by property modal
     * @param {string} index 
     */
    selectProperty(index) {
        this.setState({
            importId: this.state.suggestions[index]
        })
    }

    /**
     * Edit pricing of function.
     * @param {*} event 
     * @param {object} e 
     * @param {string} id 
     * @param {object} cico 
     */
    async editPricing(event, e, id, cico, dateClicked, priceOvrData, view) {
        event.stopPropagation();
        let modal = this.getInitialPricingModal();
        let errors = this.getInitialErrorState();
 
        Object.keys(e).map(key => {
            modal.data[key] = (dateClicked && (key === 'period_start' || key ===  'start_date' || key === 'period_end' || key ===  'end_date')) ? dateClicked : e[key]
        });

        let priceOvrSource = "min_price";
        let priceOvr = {
            period_start: 
                view === "list" ? 
                moment(e.period_start)
                :
                moment(dateClicked),
            period_end: 
                view === "list" ? 
                moment(e.period_end)
                :
                moment(dateClicked),
            currency:  "USD",
            price: "",
            min_price: "",
            min_stay: "",
        };

        if(priceOvrData && priceOvrData[0]) {
            priceOvrSource = 
                priceOvrData[0].price ?
                "price_ovr"
                :
                "min_price";

            priceOvr.currency = 
                priceOvrData[0].currency ?  
                priceOvrData[0].currency : "USD";
            priceOvr.price = 
                priceOvrData[0].price ?  
                priceOvrData[0].price : "";
            priceOvr.min_price = 
                priceOvrData[0].min_price ?  
                priceOvrData[0].min_price : "";
            priceOvr.min_stay = 
                priceOvrData[0].min_stay ?  
                priceOvrData[0].min_stay : "";
        };

        if (this.props.mobileMode) {
            let cicoData =
                cico ? 
                (
                    Object.keys(cico).length ? 
                    cico 
                    :
                    this.getInitialCicoModal().data
                )
                :
                this.getInitialCicoModal().data;
           
            let cicoLockedStatus = await this.getCicoLockedStatus(id);

            this.setState({
                mobileCalendarModal: {
                    ...this.state.mobileCalendarModal,
                    pricingPanel: {
                        ...modal,
                        show: true,
                        mode: 1,
                        property_id: id,
                        initPricingDate: {
                            period_start: e.period_start,
                            period_end: e.period_end
                        },
                        priceOvrEnabled: e.smart_pricing_enabled,
                        priceOvrSource: priceOvrSource,
                        priceOvr: priceOvr,
                        pricingDates: {
                            startDate: 
                                view === "list" ? 
                                moment(e.period_start) : 
                                moment(dateClicked),
                            endDate: 
                                view === "list" ? 
                                moment(e.period_end) : 
                                moment(dateClicked),
                        },
                    },
                    cicoPanel: {
                        ...this.state.cicoModal,
                        property_id: id,
                        data: cicoData,
                        cicoLocked: cicoLockedStatus,
                        cicoDates: {
                            startDate: 
                                view === "list" ? 
                                moment(e.period_start) : 
                                moment(dateClicked),
                            endDate: 
                                view === "list" ? 
                                moment(e.period_end) : 
                                moment(dateClicked),
                        }
                    }
                },
                errors: errors,
                dateClicked,
            })
        }
        else {
            this.setState({
                pricingModal: {
                    ...modal,
                    view: view,
                    show: true,
                    mode: 1,
                    property_id: id,
                    initPricingDate: {
                        period_start: e.period_start,
                        period_end: e.period_end
                    },
                    priceOvrEnabled: e.smart_pricing_enabled,
                    priceOvrSource: priceOvrSource,
                    priceOvr: priceOvr,
                    pricingDates: {
                        startDate: 
                            view === "list" ? 
                            moment(e.period_start) : 
                            moment(dateClicked),
                        endDate: 
                            view === "list" ? 
                            moment(e.period_end) : 
                            moment(dateClicked),
                    },
                },
                pricingCurrentDateClicked: dateClicked,
                oneDayPricePeriod: moment(e.period_start).isSame(e.period_end) || false,
                errors: errors,
            })
        }
    }

    editCico = async (e, data, id, dateClicked, view) => {
        e && e.stopPropagation();

        const { mobileMode } = this.props;
        let modal = this.getInitialCicoModal(),
        errors = this.getInitialErrorState(); 

        let cicoLockedStatus = await this.getCicoLockedStatus(id);

        this.setState({
            cicoModal: {
                ...modal,
                view: view,
                show: mobileMode ? false : true,
                mode: 1,
                property_id: id,
                cicoLocked: cicoLockedStatus,
                data: {
                    ...data,
                    start_date: 
                        view === 'list' ? 
                        data.start_date : 
                        dateClicked,
                    end_date:
                        view === 'list' ? 
                        data.end_date : 
                        dateClicked,
                },
                cicoDates: {
                    startDate: 
                        view === "list" ? 
                        moment(data.start_date) : 
                        moment(dateClicked),
                    endDate: 
                        view === "list" ? 
                        moment(data.end_date) : 
                        moment(dateClicked),
                }
            },
            initCicoDate: {
                start_date: data.start_date,
                end_date: data.end_date
            },
            cicoCurrentDateClicked: dateClicked,
            oneDayCicoPeriod: moment(data.start_date).isSame(data.end_date) || false,
            errors: errors,
        });
    }

    /**
     * Delete pricing from calendar.
     */
    async deletePricing(priceObj) {
        let modal = this.props.mobileMode ? this.state.mobileCalendarModal.pricingPanel : this.state.pricingModal,
            property_id = (priceObj && priceObj.property_id) ? priceObj.property_id : modal.property_id, 
            price_period_id = (priceObj && priceObj.price_period_id) ? priceObj.price_period_id : modal.data.price_period_id;
        
        this.setState({
            isDeleting: true
        })

        await apiHandler.update('delete', `/properties/${property_id}/priceperiods/${price_period_id}`, {}).then(async(res) => {

            this.updateData();
            modal.show = false;
            if (this.props.mobileMode) {
                this.setState({
                    mobileCalendarModal: {
                        ...this.state.mobileCalendarModal,
                        pricingPanel: modal,
                    },
                    isDeleting: false,
                })
            }
            else {
                this.setState({
                    pricingModal: modal,
                    isDeleting: false,
                })
            }
            return res;
        })
    }

    deleteCico = async () => {
        const modal = 
            this.props.mobileMode ? 
            this.state.mobileCalendarModal.cicoPanel : 
            this.state.cicoModal;

        this.setState({
            isDeleting: true
        });

        await apiHandler.update('delete', `/properties/${modal.property_id}/cico/${modal.data.cico_id}`, {});

        if(this.props.mobileMode) {
            this.hideMobileCalendarModal();
        }
        else {
            this.setState({
                cicoModal: {
                    ...modal,
                    show: false,
                },
            });
        };

        this.setState({
            isDeleting: false
        });
        
        this.updateData();
    }

    /**
     * For close pricing modal.
     */
    hidePricingModal() {
        // close pricing modal click handler
        let modal = this.state.pricingModal;
        modal.show = false;
        this.setState({
            pricingModal: modal,
            editEntirePricePeriod: false,
        })
    }
    hideCicoModal = () => {
        this.setState({
            cicoModal: {
                ...this.state.cicoModal,
                show: false,
            },
            editEntireCicoPeriod: false,
        })
    }
    
    /**
     * This function will invoke when pricing modal's ci or co days select or deselect.
     */
    handleBadgeChange(e) {
        // onChange handler for checkin/checkout badges
        let modal = 
            this.props.mobileMode ? 
            this.state.mobileCalendarModal.cicoPanel :
            this.state.cicoModal,
            el = e.target;

        modal.data[el.name][el.value] = el.checked;

        if (this.props.mobileMode) {
            this.setState({
                mobileCalendarModal: {
                    ...this.state.mobileCalendarModal,
                    cicoPanel: modal,
                }
            })
        }
        else {
            this.setState({
                cicoModal: modal
            })
        }
    }

    toggleEntirePricePeriod = () => {
        const { editEntirePricePeriod } = this.state;

        this.setState({
            editEntirePricePeriod: !editEntirePricePeriod
        }, () => {
            const { 
                pricingModal, 
                pricingCurrentDateClicked,
                editEntirePricePeriod
            } = this.state;

            this.setState({
                pricingModal: {
                    ...pricingModal,
                    data: {
                        ...pricingModal.data,
                        period_start: 
                            editEntirePricePeriod ? 
                            moment(pricingModal.initPricingDate.period_start).format('YYYY-MM-DD') : 
                            moment(pricingCurrentDateClicked).format('YYYY-MM-DD'),
                        period_end: 
                            editEntirePricePeriod ? 
                            moment(pricingModal.initPricingDate.period_end).format('YYYY-MM-DD') : 
                            moment(pricingCurrentDateClicked).format('YYYY-MM-DD')
                    },
                    pricingDates: {
                        startDate: 
                            editEntirePricePeriod ? 
                            moment(pricingModal.initPricingDate.period_start) : 
                            moment(pricingCurrentDateClicked),
                        endDate: 
                            editEntirePricePeriod ? 
                            moment(pricingModal.initPricingDate.period_end) : 
                            moment(pricingCurrentDateClicked)
                    }
                },
            });
        });
    }

    toggleEntireCicoPeriod = () => {
        const { editEntireCicoPeriod } = this.state;

        this.setState({
            editEntireCicoPeriod: !editEntireCicoPeriod
        }, () => {
            const { 
                initCicoDate, 
                cicoModal, 
                cicoCurrentDateClicked,
                editEntireCicoPeriod
            } = this.state;

            this.setState({
                cicoModal: {
                    ...cicoModal,
                    data: {
                        ...cicoModal.data,
                        start_date: 
                            editEntireCicoPeriod ? 
                            moment(initCicoDate.start_date).format('YYYY-MM-DD') : 
                            moment(cicoCurrentDateClicked).format('YYYY-MM-DD'),
                        end_date: 
                            editEntireCicoPeriod ? 
                            moment(initCicoDate.end_date).format('YYYY-MM-DD') : 
                            moment(cicoCurrentDateClicked).format('YYYY-MM-DD')
                    },
                    cicoDates: {
                        startDate: 
                            editEntireCicoPeriod ? 
                            moment(initCicoDate.start_date) :
                            moment(cicoCurrentDateClicked),
                        endDate: 
                            editEntireCicoPeriod ? 
                            moment(initCicoDate.end_date) :
                            moment(cicoCurrentDateClicked)
                    }
                },
            });
        });
    }

    /**
     * Handle date change on pricing modal
     * @param {object} date selected date will pass here 
     * @param {string} el start or end date key period_start, period_end.
     */
    handlePeriodDateChange(date, el, type, dates) {
        // onChange handler for start/end date change of the period
        let modal = 
            this.props.mobileMode ? 
            (type === "price" ? 
                this.state.mobileCalendarModal.pricingPanel 
                : 
                this.state.mobileCalendarModal.cicoPanel)
            : 
            (type === "price" ? 
                {...this.state.pricingModal} 
                : 
                {...this.state.cicoModal});
        let errors = this.state.errors;
        const mobileTypes = {
            price: "pricingPanel",
            cico: "cicoPanel"
        };
        const types = {
            price: "pricingModal",
            cico: "cicoModal"
        };
        const dateTypes = {
            price: "pricingDates",
            cico: "cicoDates"
        };

        modal.data[el] = date.format('YYYY-MM-DD');

        // Smart Pricing Override
        if(type === "price") {
            modal.priceOvr[el]  = date.format('YYYY-MM-DD');
        };

        if (this.props.mobileMode) {
            this.setState({
                mobileCalendarModal: {
                    ...this.state.mobileCalendarModal,
                    [mobileTypes[type]]: {
                        ...modal,
                        [dateTypes[type]]: {
                            ...dates,
                        },
                    },
                },
                errors: errors,
            })
        }
        else {
            this.setState({
                [types[type]]: {
                    ...modal,
                    [dateTypes[type]]: {
                        ...dates,
                    },
                    editEntirePricePeriod: false,
                    errors: errors
                }
            });
        }
    }

    /**
     * Handle error for weekly average price.
     * @param {object} modal for getting values
     * @param {object} errors for setting avg_price errors
     */
    handleWeeklyAveragePrice = (modal, errors) => {
        let { 
            weekday_price, 
            weekend_price, 
        } = modal.data

        const weeklyAvgDayPrice = ((parseFloat(weekday_price) * 5) + (parseFloat(weekend_price) * 2)) / 7;

        if (parseInt(modal.data['weekly_price']) > weeklyAvgDayPrice) {
            errors['avg_price'].show = true;
        }

        if (parseInt(modal.data['weekly_price']) <= weeklyAvgDayPrice || (weekday_price === "" || weekend_price === "")) {
            errors['avg_price'].show = false;
        }
    }

    /**
     * Handle pricing modal field change.
     * @param {object} e element for get a value 
     * @param {*} el field key name will here
     */
    handlePeriodAttrChange(e, el) {
        // onChange handlers for other fields of the period
        let modal = this.state.pricingModal;
        let errors = this.state.errors;
        
        if(el != 'maxstay') {
            if (e.target.value == '' || el === 'minstay' && parseInt(e.target.value) <= 0) {
                errors[el].show = true;
            }
            else {
                errors[el].show = false;
            };
        };

        modal.data[el] = e.target.value;

        this.setState({
            pricingModal: modal,
            errors: errors
        })
        this.handleWeeklyAveragePrice(modal, errors);
    }

    /**
     * Handle pricing panel field change.
     * @param {object} e element for get a value 
     * @param {string} el field key name 
     */
    handlePricingPanelAttrChange(e, el){
        // onChange handler for pricing panel field changes
        let panelData = this.state.mobileCalendarModal.pricingPanel
        let errors = this.state.errors

        if(el != 'maxstay') {
            if (e.target.value == '' || el === 'minstay' && parseInt(e.target.value) <= 0) {
                errors[el].show = true
            }
            else {
                errors[el].show = false
            };
        };

        panelData.data[el] = e.target.value;
        this.setState({
            mobileCalendarModal: {
                ...this.state.mobileCalendarModal,
                pricingPanel: panelData,
            },
            errors: errors
        })
        this.handleWeeklyAveragePrice(panelData, errors)
    }

    deletePricingOvr = async (e) => {
        e.preventDefault();
        const { 
            mobileCalendarModal 
        } = this.state;
        let modal = 
            this.props.mobileMode ? 
            this.state.mobileCalendarModal.pricingPanel 
            :
            this.state.pricingModal;
        let payload = {
            period_start: moment(modal.priceOvr.period_start).format('YYYY-MM-DD'),
            period_end: moment(modal.priceOvr.period_end).format('YYYY-MM-DD'),
        };
         
        this.setState({
            isDeleting: true
        });

        try {
            await API.del('smartypi', `/smartpricing/${modal.property_id}/overrides`, { body: payload });

            if(this.props.mobileMode) {
                this.setState({
                    mobileCalendarModal: {
                        ...mobileCalendarModal,
                        pricingPanel: {
                            ...mobileCalendarModal.pricingPanel,
                            show: false,
                        },
                    },
                    isDeleting: false,
                    loading: false,
                });
            }
            else {
                this.setState({
                    pricingModal: {
                        ...this.state.pricingModal,
                        show: false,
                    },
                    isDeleting: false,
                    loading: false,
                });
            };

            this.updateData();
        }
        catch(e) {
            console.log(e)
        };
    }

    submitPricingOvr = async (e) => {
        e.preventDefault();
        const { 
            mobileCalendarModal 
        } = this.state;
        const modal = 
            this.props.mobileMode ? 
            this.state.mobileCalendarModal.pricingPanel :
            this.state.pricingModal;
        let payload = {
            currency: modal.data.currency,
            period_start: moment(modal.priceOvr.period_start).format('YYYY-MM-DD'),
            period_end: moment(modal.priceOvr.period_end).format('YYYY-MM-DD'),
            min_stay: modal.priceOvr.min_stay
        };
        
        this.setState({
            loading: true,
            updatingPricing: true,
        });

        if(!modal.minPriceOnly) {
            // Change price override data based on override source.
            if(modal.priceOvrSource === "min_price") {
                payload.min_price = modal.priceOvr.min_price;
            }
            else {
                payload.price = modal.priceOvr.price;
            };
        };

        try {
            await API.post("smartypi", '/smartpricing/' + modal.property_id + '/overrides', { body: payload });

            if(this.props.mobileMode) {
                this.setState({
                    mobileCalendarModal: {
                        ...mobileCalendarModal,
                        pricingPanel: {
                            ...mobileCalendarModal.pricingPanel,
                            show: false,
                        },
                    },
                    updatingPricing: false,
                    loading: false,
                });
            }
            else {
                this.setState({
                    pricingModal: {
                        ...this.state.pricingModal,
                        show: false,
                    },
                    updatingPricing: false,
                    loading: false,
                });
            };

            this.updateData();
        }
        catch(e) {
            console.log(e)
        };
    }

    /**
     * Submit pricing details (for save current pricing or create new pricing).
     */
    async submitPricing(e) {
        // save pricing modal or mobile calendar modal if mobile view
        e.preventDefault();
        let modal = 
            this.props.mobileMode ? 
            this.state.mobileCalendarModal.pricingPanel :
            this.state.pricingModal,
        events = this.state.events,
        periodData = Object.assign({}, modal.data),
        errors = this.state.errors,
        pricingExists = false;

        events.map(event => {
            // Check to see if we want to PATCH or POST
            // PATCH: existing price period or cico period respectively
            // POST: no existing price period or cico period respectively
            if (event.property_id === modal.property_id) {
                // If in Pricing and Availability view with multple properties, 
                // check for matching property ID
                if (event.price_periods && event.price_periods.length) {
                    pricingExists = true
                }
            }
            else if (event.price_period_id) {
                // If in Pricing and Availability view with singular property, 
                // check for existing price_period_id or cico_id respectively.
                if (event.price_period_id) {
                    pricingExists = true
                }
            }            
        })

        this.setState({
            updatedPricePeriodArray: [],
        })

        Object.keys(modal.data).forEach(key=> {
            if (modal.data[key] === "") {
                errors[key].show = true
            }
        })

        if (Object.values(modal.data).includes("")) {
            this.setState({
                errors
            })
        }
        else {
            this.setState({
                loading: true,
                updatingPricing: true,
            })
            
            if(!periodData.weekly_price || periodData.weekly_price === 0) {
                periodData.weekly_price = periodData.weekday_price;
            }

            delete periodData.check_in_allowed
            delete periodData.check_out_allowed
            delete periodData.cico_id
            
            let updatePricing = async (periodDataObj, done=false) => {    
                let path = '/properties/' + modal.property_id + '/priceperiods';
                let method = 'post';
                if (pricingExists) {
                    method = 'patch'
                }
                
                await apiHandler.update(method, path, {
                    body: periodDataObj
                }).then(res => {
                    if (res && !res.error) {  
                        this.setState({
                            updatingPricing: false,
                        });

                        modal.show = false;
                        
                        if (this.props.mobileMode) {
                            this.setState({
                                mobileCalendarModal: {
                                    ...this.state.mobileCalendarModal,
                                    pricingPanel: modal,
                                },
                                loading: false,
                            })
                        }
                        else {
                            this.setState({
                                pricingModal: modal,
                                loading: false
                            })
                        };

                        this.updateData();
                    } else if (res && res.response && res.response.status === 400) {
                        errors.period_start.show = true;
                        this.setState({
                            updatingPricing: false,
                            loading: false,
                            errors: errors
                        })
                    } else {
                        errors.server_error.show = true;
                        this.setState({
                            updatingPricing: false,
                            loading: false,
                            errors: errors
                        })
                    }

                    return res;
                });
            } 
            
            // Pricing changes
            try {
                delete periodData.price_period_id
                delete periodData.name
                delete periodData.currency
                delete periodData.price_period_start
                periodData.period_start = moment(periodData.period_start).format('YYYY-MM-DD')
                periodData.period_end = moment(periodData.period_end).format('YYYY-MM-DD')
                await updatePricing(periodData);
                this.updateData();
            } catch (e) {
                let errors = this.state.errors;
                errors.parsing_error.show = true;
                errors.parsing_error.message = `Error: ${e.message}`;
                this.setState({
                    updatingPricing: true,
                    loading: false,
                    errors: errors
                })
                if (e) throw e;
            }
        }
    }

    submitCico = (e, propertyCico) => {
        e.preventDefault();

        let modal = 
            (this.props.mobileMode ? 
            this.state.mobileCalendarModal.cicoPanel
            :
            this.state.cicoModal),
            cicoPeriodData = {...modal.data},
            errors = this.state.errors,
            cicoData = {};

        cicoData['cico_id'] = cicoPeriodData.cico_id;
        cicoData['start_date'] = moment(cicoPeriodData.start_date).format('YYYY-MM-DD');
        cicoData['end_date'] = moment(cicoPeriodData.end_date).format('YYYY-MM-DD');
        cicoData['check_in_allowed'] = cicoPeriodData.check_in_allowed;
        cicoData['check_out_allowed'] = cicoPeriodData.check_out_allowed;


        let updateCico = async (cicoDataObj) => {
            let path = '/properties/' + modal.property_id + '/cico';
            let method = propertyCico.length ? 'patch' : 'post';
            
            this.setState({
                loading: true
            })

            await apiHandler.update(method, path, {
                body: cicoDataObj
            }).then(res => {
                if (!res.error) {

                    if(this.props.mobileMode) {
                        this.hideMobileCalendarModal();
                    }
                    else {
                        modal.show = false;
                    };

                    this.setState({
                        loading: false
                    });
                    this.updateData();
                } else if (res && res.response && res.response.status === 400) {
                    errors.start_date.show = true;
                    this.setState({
                        loading: false,
                        errors: errors
                    })
                } else {
                    errors.server_error.show = true;
                    this.setState({
                        loading: false,
                        errors: errors
                    })
                }
                return res;
            });
        };

        try {
            delete cicoData.cico_id;

            cicoData.start_date = moment(cicoData.start_date).format('YYYY-MM-DD');
            cicoData.end_date = moment(cicoData.end_date).format('YYYY-MM-DD');

            updateCico(cicoData);
        } 
        catch (e) {
            let errors = this.state.errors;
            errors.parsing_error.show = true;
            errors.parsing_error.message = `Error: ${e.message}`;
            this.setState({
                loading: false,
                errors: errors
            });
            if (e) throw e;
        }
    }

    /**
     * Events for Blocking Property
     * @param {*} sDate event start date.
     * @param {*} eDate event end date
     * @param {*} id property id
     */
    async addAvailability(sDate, eDate, id) {
        // onClick handler for blocking a property date
        let modal = Object.assign({}, this.getInitialAvailabilityModal()),
            errors = this.getInitialErrorState();
        let property = this.getPropertyById(id)[0];
        modal.show = true;
        modal.mode = 0;
        modal.property_id = id;
        modal.data.type = "BlockedByManager";
        modal.data.period_start = moment(sDate).format('YYYY-MM-DD');
        modal.data.period_end = moment(eDate).format('YYYY-MM-DD');
        modal.children = this.state.propertyChildren[id];

        this.state.propertyList.forEach((property) => {
            if(
                property.property_id === id && 
                (   
                    property && 
                    property.availabilityFeed && 
                    property.availabilityFeed.feed_type === "ical" && 
                    property && 
                    property.availabilityFeed && 
                    property.availabilityFeed.frequency
                )
            ) {
                modal.iCalSourceOfTruth = true;
                modal.collectionId = property && property.repconfig && property.repconfig.property_collection_id;
            };
        });
        if (this.props.mobileMode) {
            this.setState({
                mobileCalendarModal: {
                    ...this.state.mobileCalendarModal,
                    availabilityPanel: {
                        ...modal,
                        availabilityDates: {
                            startDate: moment(sDate),
                            endDate: moment(eDate)
                        },
                        reservations: {
                            ...modal.reservations,
                            reservationDates: {
                                startDate: moment(sDate),
                                endDate: moment(eDate)
                            },
                            adultOptions: this.setNumOptions(1, (property && property.content && property.content.sleep_max), 'adult'),
                            childOptions: this.setNumOptions(0, (property && property.content && property.content.sleep_max - 1)),
                        }
                    },
                },
                dateClicked: sDate,
                errors: errors,
                availMode: "add",
            }, async () => {
                await this.getPromotionCode(property.organization_name);
            })
        }
        else {
            this.setState({
                availabilityModal: {
                    ...modal,
                    availabilityDates: {
                        startDate: moment(sDate),
                        endDate: moment(eDate)
                    },
                    reservations: {
                        ...modal.reservations,
                        reservationDates: {
                            startDate: moment(sDate),
                            endDate: moment(eDate)
                        },
                        adultOptions: this.setNumOptions(1, (property && property.content && property.content.sleep_max), 'adult'),
                        childOptions: this.setNumOptions(0, (property && property.content && property.content.sleep_max - 1)),
                    }
                },
                availMode: "add",
                errors: errors
            }, async () => {
                await this.getPromotionCode(property.organization_name);
            })
        }
    }

    /**
     * Edit current availability.
     * @param {*} period period with start and end date 
     * @param {*} id property id.
     */
    async editAvailability (period, id) {
        // onClick handler for editing an existing blocked period
        let modal = Object.assign({}, this.state.availabilityModal),
            errors = this.getInitialErrorState(),
            availNote = [];
        modal.show = true;
        modal.mode = 1;
        modal.property_id = id;
        modal.expiresTime = "";

        this.setState({ 
            isLoading: true,
            noteValidation: false,
            duplicateNotes: {},
            initAvailData: {},
        });

        availNote = await this.getAvailabilityNotes(id, period.availability_id);

        this.initAvailNotes = availNote;

        if(availNote.length) {
            modal.notes = availNote;
        }
        else {
            modal.notes = [{
                type: "",
                note: ""
            }];
        };
        
        if (period) {
            period = Object.assign({}, period);
            period.period_start = moment(period.period_start).hours(12).format('YYYY-MM-DD')
            period.period_end = moment(period.period_end).hours(12).format('YYYY-MM-DD')
        }
        modal.data = Object.assign({}, period);
        modal.children = this.state.propertyChildren[id]

        this.state.propertyList.forEach((property) => {
            if(
                property.property_id === id && 
                (property && 
                    property.availabilityFeed && 
                    property.availabilityFeed.feed_type === "ical" && 
                    property && 
                    property.availabilityFeed && 
                    property.availabilityFeed.frequency)) {
                modal.iCalSourceOfTruth = true;
                modal.collectionId = property && property.repconfig && property.repconfig.property_collection_id;
            };
        });

        if (this.props.mobileMode) {
            this.setState({
                mobileCalendarModal: {
                    ...this.state.mobileCalendarModal,
                    availabilityPanel: {
                        ...modal,
                        availabilityDates: {
                            startDate: moment(period.period_start),
                            endDate: moment(period.period_end)
                        },
                    },
                },
                isLoading: false,
            });
        }
        else {
            this.setState({
                availabilityModal: {
                    ...modal,
                    availabilityDates: {
                        startDate: moment(period.period_start),
                        endDate: moment(period.period_end)
                    },
                },
                initAvailData: {
                    ...modal.data
                },
                availMode: "edit",
                errors: errors,
                isLoading: false
            })
        }
    }

    /**
     * Delete current availability.
     */
    deleteAvailability() {
        let modal = this.props.mobileMode ? 
        this.state.mobileCalendarModal.availabilityPanel : 
        this.state.availabilityModal;
        this.setState({
            isDeleting: true,
        })
        apiHandler.update('delete', `/properties/${modal.property_id}/availability/${modal.data.availability_id}`, {}).then(res => {
            this.updateData();
            modal.show = false;
            if (this.props.mobileMode) {
                this.setState({
                    mobileCalendarModal: {
                        ...this.state.mobileCalendarModal,
                        availabilityPanel: modal,
                    },
                    isDeleting: false,
                })
            }
            else {
                this.setState({
                    availabilityModal: modal,
                    isDeleting: false
                })
            }
            return res;
        })
    }

    handleAvailNoteOnChange = (e, index, mobile) => {
        let copyNotes = 
            mobile ? 
            [...this.state.mobileCalendarModal.availabilityPanel.notes] : 
            [...this.state.availabilityModal.notes];
        let name = e.target.name,
            value = e.target.value

        copyNotes.splice(index, 1, {
            ...copyNotes[index],
            [name]: value
        });

        let state = 
            mobile ? 
            { 
                mobileCalendarModal: {
                    ...this.state.mobileCalendarModal,
                    availabilityPanel: {
                        ...this.state.mobileCalendarModal.availabilityPanel,
                        notes: copyNotes
                    }
                }
            } :
            {
                availabilityModal: {
                    ...this.state.availabilityModal,
                    notes: copyNotes
                }
            };

        this.setState(
            state
        , () => {
            const data = 
                mobile ? 
                this.state.mobileCalendarModal.availabilityPanel.notes : 
                this.state.availabilityModal.notes;

            if((copyNotes[index].type === "Other" && this.state.noteValidation === true)) {
                let valid = false;

                if(copyNotes[index].note === "") {
                    valid = true;
                }
                else {
                    valid = false;
                };

                this.setState({
                    noteValidation: valid
                });
            };

            if(Object.keys(this.state.duplicateNotes).length) {
                let hasDuplicates = this.checkDuplicate(data, "type");

                this.setState({
                    duplicateNotes: hasDuplicates
                });
            };

            if(this.state.typeValidation.length) {
                let typeValid = [];
                this.state.typeValidation.forEach((note, i) => { 
                    if(note.type === "" && note.note.length) {
                        typeValid.push(i)
                    }
                    else {
                        if(typeValid.includes(i)) {
                            typeValid.splice(i, 1)
                        };
                    }
                });

                this.setState({
                    typeValidation: typeValid
                });
            };
        });
    }

    addAvailNote = (e, mobile) => {
        e.preventDefault();

        let copyNotes = 
            mobile ? 
            [...this.state.mobileCalendarModal.availabilityPanel.notes] : 
            [...this.state.availabilityModal.notes];

        copyNotes.push(
            {
                type: "",
                note: "",
            }
        );

        let state = 
            mobile ? 
            { 
                mobileCalendarModal: {
                    ...this.state.mobileCalendarModal,
                    availabilityPanel: {
                        ...this.state.mobileCalendarModal.availabilityPanel,
                        notes: copyNotes
                    }
                }
            } :
            {
                availabilityModal: {
                    ...this.state.availabilityModal,
                    notes: copyNotes
                }
            };

        this.setState(state);
    }

    deleteAvailNote = (index, mobile) => {
        const data = 
            mobile ? 
            this.state.mobileCalendarModal.availabilityPanel.notes : 
            this.state.availabilityModal.notes;
        let copyNotes = 
            mobile ? 
            [...this.state.mobileCalendarModal.availabilityPanel.notes] : 
            [...this.state.availabilityModal.notes];

        copyNotes.splice(index, 1);

        let state = 
            mobile ? 
            { 
                mobileCalendarModal: {
                    ...this.state.mobileCalendarModal,
                    availabilityPanel: {
                        ...this.state.mobileCalendarModal.availabilityPanel,
                        notes: copyNotes
                    }
                }
            } :
            {
                availabilityModal: {
                    ...this.state.availabilityModal,
                    notes: copyNotes
                }
            };

        this.setState(
            state
        , () => {
            if(Object.keys(this.state.duplicateNotes).length) {
                let hasDuplicates = this.checkDuplicate(data, "type");

                this.setState({
                    duplicateNotes: hasDuplicates
                });
            };

            if(this.state.typeValidation.length) {
                let typeValid = [];
                this.state.typeValidation.forEach((note, i) => { 
                    if(note.type === "" && note.note.length) {
                        typeValid.push(i)
                    }
                    else {
                        if(typeValid.includes(i)) {
                            typeValid.splice(i, 1)
                        };
                    }
                });

                this.setState({
                    typeValidation: typeValid
                });
            };
        });
    }

    handleAvailExpiresDate = (date, type) => {
        if(type === "mobile") {
            const avail = {...this.state.mobileCalendarModal};

            this.setState({
                mobileCalendarModal: {
                    ...avail,
                    availabilityPanel: {
                        ...avail.availabilityPanel,
                        data: {
                            ...avail.availabilityPanel.data,
                            expires: 
                                !date ? 
                                null : 
                                moment(date).format("YYYY-MM-DD")
                        }
                    }
                }
            })
        }
        else {
            const avail = {...this.state.availabilityModal};

            this.setState({
                availabilityModal: {
                    ...avail,
                    data: {
                        ...avail.data,
                        expires: 
                            !date ? 
                            null : 
                            moment(date).format("YYYY-MM-DD")
                    }
                }
            })
        }
        
    }

    /**
     * Hide availability modal
     */
    hideAvailabilityModal() {
        let modal = this.state.availabilityModal;
        modal.show = false;
        this.setState({
            availabilityModal: modal
        }, () => {
            modal.collectionId = "";
            modal.iCalSourceOfTruth = false;
            modal.notes = [
                {
                    type: "",
                    note: "",
                }
            ];
            this.initAvailNotes = [];
        });
    }

    /**
     * Hide mobile calendar modal
     */
    hideMobileCalendarModal = () => {
        const pricingPanel = this.getInitialPricingModal(),
            availabilityPanel = this.getInitialAvailabilityModal(),
            cicoPanel = this.getInitialCicoModal(),
            initialErrors = this.getInitialErrorState();

        this.initAvailNotes = [];

        this.setState({
            mobileCalendarModal: {
                ...this.state.mobileCalendarModal,
                pricingPanel,
                availabilityPanel,
                cicoPanel,
            },
            errors: initialErrors,
            availabilityModal: {
                ...this.state.availabilityModal,
                notes: [
                    {
                        type: "",
                        note: "",
                    }
                ]
            }
        })
    }

    /**
     * For handle dates in block date modal.
     * @param {*} date date selected.
     * @param {*} el field name where date changed.
     */
    handleBlockedDateChange(date, el, dates) {
        // handle blocked date change for availability modal
        let modal = this.props.mobileMode ? 
        this.state.mobileCalendarModal.availabilityPanel : 
        this.state.availabilityModal;
        let errors = this.state.errors;

        modal.data[el] = date.format('YYYY-MM-DD');
        errors.period_start.show = false;

        if (this.props.mobileMode) {
            this.setState({
                mobileCalendarModal: {
                    ...this.state.mobileCalendarModal,
                    availabilityPanel: {
                        ...modal,
                        availabilityDates: {
                            ...dates
                        }
                    },
                },
                errors: errors,
            })
        }
        else {
            this.setState({
                availabilityModal: {
                    ...modal,
                    availabilityDates: {
                        ...dates
                    }
                },
                errors: errors
            })
        }
    }

    updateAvailabilityArray = async (obj) => {
        await this.setState({
            updatedAvailabilityArray: [...this.state.updatedAvailabilityArray, obj]
        })
    }

    handleAvailabilityConversion = async (elm, period_start, period_end, availability_id) => {
        let _self = this,
            updateAvailabilityArray = _self.updateAvailabilityArray,
            isCurrentAvailabilityId = false;

        if (availability_id === elm.availability_id) {
            isCurrentAvailabilityId = true
        }
        delete elm.availability_id
        delete elm.created
        delete elm.updated

        let this_start = new Date(moment(elm.period_start)),
            this_end = new Date(moment(elm.period_end)),

            this_start_is_between = moment(this_start).isBetween(period_start, period_end),
            this_end_is_between = moment(this_end).isBetween(period_start, period_end),

            this_start_is_before = moment(this_start).isBefore(period_start),
            
            this_end_is_after = moment(this_end).isAfter(period_end),

            this_start_is_same = moment(this_start).isSame(period_start),
            this_end_is_same = moment(this_end).isSame(period_end),

            this_start_is_same_that_end = moment(this_start).isSame(period_end),
            this_end_is_same_that_start = moment(this_end).isSame(period_start);

        if (!this_start_is_between && 
            !this_end_is_between &&
            !this_start_is_same &&
            !this_end_is_same &&
            !this_start_is_same_that_end &&
            !this_end_is_same_that_start &&
            !(this_start_is_before && this_end_is_after)) {
            // Has no overlap with change requested
            await updateAvailabilityArray(elm)
        } else {
            // Has overlap with change requested                    
            if (
                isCurrentAvailabilityId ||
                (this_start_is_between && this_end_is_between) ||
                (this_start_is_same && this_end_is_same) ||
                (this_start_is_same && this_end_is_between) ||
                (this_start_is_between && this_end_is_same) ||
                (this_start_is_same && this_end_is_same_that_start) ||
                (this_end_is_same && this_start_is_same_that_end)
            ) {
                // Has complete overlap with change requested, do not add to updated list
                console.log('Has complete overlap')                      
            } 
            else if (this_start_is_before && this_end_is_after) {
                // Current start is before and Current end is after
                // Split into two periods on either end
                
                // start before
                let startBeforeObj = Object.assign({}, elm);
                // end after
                let endAfterObj = Object.assign({}, elm);

                startBeforeObj.period_end = moment(period_start).subtract(1, 'd').format('YYYY-MM-DD');
                
                endAfterObj.period_start = moment(period_end).add(1, 'd').format('YYYY-MM-DD');

                await updateAvailabilityArray(startBeforeObj)
                await updateAvailabilityArray(endAfterObj)

            } else if (this_start_is_before) {
                // Current start is before
                // Current end date will be requested start date -1 day
                // Update this iteration end date

                elm.period_end = moment(period_start).subtract(1, 'd').format('YYYY-MM-DD');
                await updateAvailabilityArray(elm)
            } else if (this_end_is_after) {
                // Current end is after
                // Current start date will be requested end date +1 day
                // Update this iteration start date

                elm.period_start = moment(period_end).add(1, 'd').format('YYYY-MM-DD');
                await updateAvailabilityArray(elm)
            }
        }
    }

    /**
     * Submit availability.
     * @param {*} level level is indicate whether property is key or rep or repchild. 
     * @param {*} units if rep level property unit is number odf child in rep level property
     */
    async submitAvailability(level, units) {
        // save the blocked dates for a property
        let _self = this;
        let notesData = this.props.mobileMode ? 
            this.state.mobileCalendarModal.availabilityPanel.notes : 
            this.state.availabilityModal.notes;
        let modal = this.props.mobileMode ? 
        this.state.mobileCalendarModal.availabilityPanel : 
        this.state.availabilityModal,
            data = JSON.parse(JSON.stringify(modal.data)),
            errors = this.state.errors,
            basePath = `/properties/${modal.property_id}`,
            path = `${basePath}/availability/`,
            repPath = `${basePath}/repavailability/`,
            availability_id = data.availability_id;

        // Availability Notes validations.
        let otherTypeValid = [];
        let typeValid = [];

        otherTypeValid = notesData.filter((note) => { return note.type === "Other"});

        notesData.forEach((note, i) => { 
            if(note.type === "" && note.note.length) {
                typeValid.push(i)
            }
        });

        let hasDuplicates = this.checkDuplicate(notesData, "type");

        if(otherTypeValid && otherTypeValid[0] && otherTypeValid[0].note === "" || Object.keys(hasDuplicates).length || typeValid.length) {

            if(otherTypeValid && otherTypeValid[0] && otherTypeValid[0].note === "") {
                this.setState({
                    noteValidation: true
                });
            };

            if(Object.keys(hasDuplicates).length) {
                this.setState({
                    duplicateNotes: hasDuplicates
                });
            };

            if(typeValid.length) {
                this.setState({
                    typeValidation: typeValid
                });
            };

            this.setState({
                loading: false
            });

        }
        else {
            this.setState({
                loading: true
            });

            let currentAvailability = await apiHandler.get(`/properties/${modal.property_id}`);
            let copyAvailablity = [];

            delete data.availability_id
            delete data.created
            delete data.updated
            
            if (currentAvailability && currentAvailability.data && currentAvailability.data.availability) {
                copyAvailablity = JSON.parse(JSON.stringify(currentAvailability.data.availability));

                async function getTypeAvailability(item, index) {
                    if (item && item.period && item.period.type === data.type) {
                        // We should be able to handle data conversion here
                        if (level === "rep") {
                            item.period.units = Number(units);
                        }
                    }
                }
                currentAvailability.data.availability.map(getTypeAvailability)
            }

            if (level === "rep") {
                data.units = Number(units);
            }
            await this.updateAvailabilityArray(data)
            
            if (level === "rep") {
                path = repPath;
            } else {
                path;
            }

            let availResponse = {};
            let overlappingDates = [];
            
            try {
                if(level !== "rep") {
                    if(!isEqual(this.state.initAvailData, modal.data) || modal.data.expires && modal.expiresTime) {

                        if(modal.data.expires && modal.expiresTime) {
                            modal.data.expires = data.expires.split("T")[0] + "T" + modal.expiresTime;
                        };

                        // Check if there is overlapping Dates.
                        overlappingDates = copyAvailablity.filter((date) => {
                            const period_start = moment(date.period.period_start).format("YYYY-MM-DD");
                            const period_end = moment(date.period.period_end).format("YYYY-MM-DD");
                            
                            return (
                                (moment(modal.data.period_start).isBetween(period_start, period_end) || 
                                moment(modal.data.period_end).isBetween(period_start, period_end) ||
                                moment(modal.data.period_start).isSame(period_start, period_end) ||
                                moment(modal.data.period_end).isSame(period_start, period_end)) && 
                                date.period.availability_id !== modal.data.availability_id &&
                                date.period.type !== "Temporary"
                            );
                        });
    
                        if(overlappingDates.length) {  
                            throw `The new period from ${modal.data.period_start} to ${modal.data.period_end} overlaps with existing block from ${overlappingDates[0].period.period_start} to ${overlappingDates[0].period.period_end}`;
                        }
                        else if(this.state.availMode == "edit") {
                            await API.del('rapapi', path + modal.data.availability_id);
    
                            availResponse = await API.post('rapapi', path, { body: modal.data });
                        }
                        else if(this.state.availMode == "add") {
                            availResponse = await API.post('rapapi', path, { body: modal.data });
                        };
                    };

                    // Config Availability Notes.
                    let copyAvailNotes = [...notesData];
                    let currAvailNotesType = [];

                    copyAvailNotes.forEach((type) => {
                        currAvailNotesType.push(type.type);
                    });

                    this.initAvailNotes.forEach((type) => {
                        if(!currAvailNotesType.includes(type.type)) {
                            copyAvailNotes.push({
                                ...type,
                                custom: "delete"
                            });
                        };
                    });

                    let filteredDelNotes = [];
                    filteredDelNotes = copyAvailNotes.filter((note) => { return note.custom });

                    let filteredPostNotes = [];
                    filteredPostNotes = copyAvailNotes.filter((note) => { return !note.custom });

                    if(filteredDelNotes.length) {
                        filteredDelNotes.forEach(async (note) => {
                            try {
                                await this.updateAvailabilityNotes(modal.property_id, (availResponse.availability_id ? availResponse.availability_id : modal.data.availability_id), note, "del");
                            }
                            catch(e) {
                                console.log(e);
                            };
                        });
                    };

                    if(filteredPostNotes.length) {
                        filteredPostNotes.forEach(async (note) => {
                            try {
                                await this.updateAvailabilityNotes(modal.property_id, (availResponse.availability_id ? availResponse.availability_id : modal.data.availability_id), note, "post");
                            }
                            catch(e) {
                                console.log(e);
                            };
                        });
                    };
                }
                else {
                    // Create or Update Rep Availability
                    await API.put('rapapi', path, { body: this.state.updatedAvailabilityArray });
                };

                this.updateData();

                modal.show = false;

                if (this.props.mobileMode) {
                    this.setState({
                        loading: false,
                        mobileCalendarModal: {
                            ...this.state.mobileCalendarModal,
                            availabilityPanel: modal,
                        },
                        updatedAvailabilityArray: []
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        availabilityModal: modal,
                        updatedAvailabilityArray: []
                    })
                }

            }
            catch(e) {
                console.log(e);
                if (e && e.response && e.response.status === 400) {
                    errors.period_start.show = true;

                    if (e.response && e.response.data && e.response.data.Message) {
                        errors.period_start.show = false;
                        errors.server_error.message = e.response.data.Message
                        errors.server_error.show = true
                    }

                    this.setState({
                        loading: false,
                        errors: errors,
                        updatedAvailabilityArray: []
                    });
                }
                else {
                    if(typeof e == "string") {
                        errors.server_error.message = e;
                    };
                    errors.server_error.show = true;
                    

                    this.setState({
                        loading: false,
                        errors: errors,
                        updatedAvailabilityArray: []
                    });
                };
            };
        };
    }

    checkDuplicate = (state, type) => {
		let data = [...state];
		let duplicateArr = [];
		let duplicateList = {};
		
		for(let i = 0; i < data.length; i++) {
			duplicateArr.splice(i, 0, data[i][type])
		};
		for (let i = 0; i < duplicateArr.length; i++) {
			if(duplicateList.hasOwnProperty(duplicateArr[i])) {
				duplicateList[duplicateArr[i]].push(i);
			}
			else if (duplicateArr.lastIndexOf(duplicateArr[i]) !== i) {
				duplicateList[duplicateArr[i]] = [i];
			};
		};
        delete duplicateList[""];

		return duplicateList;
	}

    /**
     * Function invoke when calendar drag event start on big calendar which dates are blocked.
     * @param {*} e element
     * @param {*} date date selected.
     * @param {*} propId property id 
     */
    handleBlockedDragStart(e, date, propId) {
        e.preventDefault();
        this.availabilityDragHandler = {
            start: true,
            sDate: date,
            eDate: null,
            propId: propId
        }
    }

    /**
     * Function invoke when drag a mouse over a other dates and will select blocked dates only.
     * @param {*} e element 
     * @param {*} date current date
     * @param {*} propId property id.
     */
    handleBlockedDrag(e, date, propId) {
        e.preventDefault();
        if (this.availabilityDragHandler.start && this.availabilityDragHandler.propId == propId) {
            this.setState({
                blockedHighlightedDates: dates.range(this.availabilityDragHandler.sDate, date)
            })
        } else {
            this.setState({
                blockedHighlightedDates: []
            })
        }
        if (this.availabilityDragHandler.propId != propId) {
            this.availabilityDragHandler = {
                start: false,
                sDate: null,
                eDate: null,
                propId: null
            }
        }
    }

    /**
     * Function invoke when drag event is over only on blocked dates.
     * @param {*} e element
     * @param {*} date date at last point 
     * @param {*} propId property id
     */
    handleBlockedDragEnd(e, date, propId) {
        e.preventDefault();
        this.availabilityDragHandler.start = false;
        this.availabilityDragHandler.eDate = date;
        if (this.availabilityDragHandler.propId) {
            if (moment(this.availabilityDragHandler.eDate).isBefore(this.availabilityDragHandler.sDate)) {
                this.availabilityDragHandler.eDate = this.availabilityDragHandler.sDate;
                this.availabilityDragHandler.sDate = date;
            }
            this.addAvailability(this.availabilityDragHandler.sDate, this.availabilityDragHandler.eDate, propId)
            this.availabilityDragHandler = {
                start: false,
                sDate: null,
                eDate: null,
                propId: null
            }
        }
        this.setState({
            blockedHighlightedDates: []
        })
    }

    /**
     * Handel pricing drag start.
     * @param {*} e element
     * @param {*} date date of start drag
     * @param {*} propId property Id
     */
    handlePricingDragStart(e, date, propId) {
        e.preventDefault();
        this.pricingDragHandler = {
            start: true,
            sDate: date,
            eDate: null,
            propId: propId
        }
    }
    /**
     * Handle pricing drag over other dates.
     * @param {*} e element
     * @param {*} date draged date
     * @param {*} propId Property Id
     */
    handlePricingDrag(e, date, propId) {
        e.preventDefault();
        if (this.pricingDragHandler.start && this.pricingDragHandler.propId == propId) {
            this.setState({
                pricingHighlightedDates: dates.range(this.pricingDragHandler.sDate, date)
            })
        } else {
            this.setState({
                pricingHighlightedDates: []
            })
        }
        if (this.pricingDragHandler.propId != propId) {
            this.pricingDragHandler = {
                start: false,
                sDate: null,
                eDate: null,
                propId: null
            }
        }
    }

    /**
     * Close available room modal.
     */
    closeAvailableRoomModal() {
        this.setState({
            availableRoomModal: {
                show: false,
                period_start: '',
                period_end: '',
            },
            unAvailableRooms: {}
        })
    }

    /**
     * Function invoke when drop detected after drag on pricing cells.
     * @param {*} e element
     * @param {*} date end date 
     * @param {*} propId Property Id
     */
    handlePricingDragEnd(e, date, propId) {
        e.preventDefault();
        this.pricingDragHandler.start = false;
        this.pricingDragHandler.eDate = date;
        if (this.pricingDragHandler.propId) {
            if (moment(this.pricingDragHandler.eDate).isBefore(this.pricingDragHandler.sDate)) {
                this.pricingDragHandler.eDate = this.pricingDragHandler.sDate;
                this.pricingDragHandler.sDate = date;
            }
            this.addPricing(this.pricingDragHandler.sDate, this.pricingDragHandler.eDate, propId);
            this.pricingDragHandler = {
                start: false,
                sDate: null,
                eDate: null,
                propId: null
            }
            this.setState({
                pricingHighlightedDates: []
            })
        }
    }

    // <-- Create Reservations ---> ** Start **


    getPromotionCode = async (orgName) => {
        const { 
            availabilityModal,
            mobileCalendarModal,
        } = this.state;

        try {
            let response = await API.get(constants.RAPAPI.NAME,`/promotion/channel/promo_codes?organization=${orgName}`, { response: true, isCognito: true });

            if(response && response.data && response.data.length) {
                this.setState({
                    mobileCalendarModal: {
                        ...mobileCalendarModal,
                        availabilityPanel: {
                            ...mobileCalendarModal.availabilityPanel,
                            reservations: {
                                ...mobileCalendarModal.availabilityPanel.reservations,
                                promotionCodeData: response.data,
                            }
                        }
                    },
                    availabilityModal: {
                        ...availabilityModal,
                        reservations: {
                            ...availabilityModal.reservations,
                            promotionCodeData: response.data,
                        }
                    }
                });
            };
        } 
        catch(e) {
            console.log(e)
        };
    }

    handleBookNow = async (mobile) => {
        const { 
            availabilityModal,
            mobileCalendarModal,
        } = this.state;

        const newValidations = this.validGuestData(mobile);

        this.setState({
            validations: newValidations
        }, async () => {
            const { 
                validations
            } = this.state;

            const notValid = Object.values(validations).filter((x) => {
                return x === true;
            });
    
            if(notValid.length) {
                return;
            }
            else {
                const guestData = mobile ? mobileCalendarModal.availabilityPanel.reservations : availabilityModal.reservations;

                let guestDataCopy = JSON.parse(JSON.stringify(guestData.guestData));
        
                guestDataCopy.quote_id = guestData.quoteData.quote_id;
                guestDataCopy.listing_id = guestData.quoteData.listing_id;
        
                const countryCode = constants.PROPERTY_VIEW.COUNTRIES.filter((country) => country.Name ===  guestDataCopy.address.country);
                guestDataCopy.address.country = (countryCode.length ? countryCode[0].Code : guestDataCopy.address.country);
        
                guestDataCopy.payments[0].currency = guestData.quoteData.currency;
                guestDataCopy.payments[0].amount = parseFloat(guestData.quoteData.payment_schedule[0].amount);
        
                guestDataCopy.payments[0].method_details.expiration_month = parseFloat(guestData.guestData.payments[0].method_details.expiration_month);
                guestDataCopy.payments[0].method_details.expiration_year = parseFloat(guestData.guestData.payments[0].method_details.expiration_year);
        
                guestDataCopy.checkin_date = guestData.quoteData.checkin_date;
                guestDataCopy.checkout_date = guestData.quoteData.checkout_date;
                guestDataCopy.campaign_source = this.org;
                guestDataCopy.organization_name = this.org;
        
                guestData.promotionCode.length ? guestDataCopy.promo_code = guestData.promotionCode : '';
        
                if(mobile) {
                    this.setState({
                        mobileCalendarModal: {
                            ...mobileCalendarModal,
                            availabilityPanel: {
                                ...mobileCalendarModal.availabilityPanel,
                                show: false,
                                reservations: {
                                    ...mobileCalendarModal.availabilityPanel.reservations,
                                    bookNowIsLoading: true,
                                    bookNowErrMsg: ''
                                }
                            }
                        }
                    });
                }
                else {
                    this.setState({
                        availabilityModal: {
                            ...availabilityModal,
                            reservations: {
                                ...availabilityModal.reservations,
                                bookNowIsLoading: true,
                                bookNowErrMsg: ''
                            }
                        }
                    });
                };
        
                try {
                    await API.post(constants.RAPAPI.NAME,`/properties/reservations`, { body: guestDataCopy, response: true, isCognito: true });
        
                    if(mobile) {
                        this.setState({
                            mobileCalendarModal: {
                                ...mobileCalendarModal,
                                availabilityPanel: {
                                    ...mobileCalendarModal.availabilityPanel,
                                    show: false,
                                    reservations: {
                                        ...mobileCalendarModal.availabilityPanel.reservations,
                                        showGuestInfoModal: false,
                                    }
                                }
                            }
                        });
                    }
                    else {
                        this.setState({
                            availabilityModal: {
                                ...availabilityModal,
                                show: false,
                                reservations: {
                                    ...availabilityModal.reservations,
                                    showGuestInfoModal: false,
                                }
                            }
                        });
                    };
        
                    this.updateData();
                } 
                catch(e) {
                    const errorMessage = e && e.response && e.response.data && e.response.data.Message;
                    const genErrMsg = 'Please contact Redawning Support';
        
                    if(mobile) {
                        this.setState({
                            mobileCalendarModal: {
                                ...mobileCalendarModal,
                                availabilityPanel: {
                                    ...mobileCalendarModal.availabilityPanel,
                                    show: false,
                                    reservations: {
                                        ...mobileCalendarModal.availabilityPanel.reservations,
                                        bookNowErrMsg: errorMessage ? errorMessage : genErrMsg,
                                        bookNowIsLoading: false
                                    }
                                }
                            }
                        });
                    }
                    else {
                        this.setState({
                            availabilityModal: {
                                ...availabilityModal,
                                reservations: {
                                    ...availabilityModal.reservations,
                                    bookNowErrMsg: errorMessage ? errorMessage : genErrMsg,
                                    bookNowIsLoading: false
                                }
                            }
                        });
                    }
                };
            };
        });
    }

    handleReservationQuoteMobile = async () => {
        const { 
            mobileCalendarModal
        } = this.state;

        const requiredQuoteData = this.validQuoteData(mobileCalendarModal.availabilityPanel);

        if(!requiredQuoteData.dates && !requiredQuoteData.adults) {
            const checkIn = moment(mobileCalendarModal.availabilityPanel.reservations.reservationDates.startDate).format("YYYY-MM-DD"),
                  checkOut = moment(mobileCalendarModal.availabilityPanel.reservations.reservationDates.endDate).format("YYYY-MM-DD");

            this.setState({
                mobileCalendarModal: {
                    ...mobileCalendarModal,
                    availabilityPanel: {
                        ...mobileCalendarModal.availabilityPanel,
                        reservations: {
                            ...mobileCalendarModal.availabilityPanel.reservations,
                            quoteIsLoading: true,
                            quoteErrMsg: '',
                        }
                    }
                }
            });
    
            try {
                let response = await API.get(constants.RAPAPI.NAME,`/properties/${encodeURIComponent(mobileCalendarModal.availabilityPanel.property_id)}/quote?organization=${this.org}&checkin=${encodeURIComponent(checkIn)}&checkout=${encodeURIComponent(checkOut)}&numadults=${encodeURIComponent(mobileCalendarModal.availabilityPanel.reservations.adults)}&numchild=${encodeURIComponent(mobileCalendarModal.availabilityPanel.reservations.children)}&travelinsurance=false${mobileCalendarModal.availabilityPanel.reservations.promotionCode ? `&promo_code=${encodeURIComponent(mobileCalendarModal.availabilityPanel.reservations.promotionCode)}` : ''}`, { response: true, isCognito: true });

                if(response && response.data) {
                    this.setState({
                        mobileCalendarModal: {
                            ...mobileCalendarModal,
                            availabilityPanel: {
                                ...mobileCalendarModal.availabilityPanel,
                                reservations: {
                                    ...mobileCalendarModal.availabilityPanel.reservations,
                                    quoteErrMsg: '',
                                    quoteData: response.data,
                                    quoteIsLoading: false,
                                }
                            }
                        }
                    });
                };
            } 
            catch(e) {
                const errorMessage = e && e.response && e.response.data && e.response.data.Message;
                const genErrMsg = 'Please contact Redawning Support';

                this.setState({
                    mobileCalendarModal: {
                        ...mobileCalendarModal,
                        availabilityPanel: {
                            ...mobileCalendarModal.availabilityPanel,
                            reservations: {
                                ...mobileCalendarModal.availabilityPanel.reservations,
                                quoteErrMsg: errorMessage ? errorMessage : genErrMsg,
                                quoteData: {},
                                quoteIsLoading: false
                            }
                        }
                    }
                });
            };
        }
    }

    handleReservationQuote = async (addons = null) => {
        const { 
            availabilityModal
        } = this.state;
        const requiredQuoteData = this.validQuoteData(availabilityModal);

        if(!requiredQuoteData.dates && !requiredQuoteData.adults) {
            const checkIn = moment(availabilityModal.reservations.reservationDates.startDate).format("YYYY-MM-DD"),
                  checkOut = moment(availabilityModal.reservations.reservationDates.endDate).format("YYYY-MM-DD");

            this.setState({
                availabilityModal: {
                    ...availabilityModal,
                    reservations: {
                        ...availabilityModal.reservations,
                        quoteIsLoading: true,
                        quoteErrMsg: '',
                    }
                }
            });
    
            try {
                const quotePayload = {
                    "checkin": checkIn,
                    "checkout": checkOut,
                    "numadults": availabilityModal.reservations.adults,
                    "numchild": availabilityModal.reservations.children,
                    "travelinsurance": false,
                    addons,
                };

                if (availabilityModal.reservations.promotionCode) {
                    quotePayload.promo_code = availabilityModal.reservations.promotionCode;
                }
                let response = await API.post(constants.RAPAPI.NAME,`/properties/${encodeURIComponent(availabilityModal.property_id)}/quote?organization=${this.org}`, { body: quotePayload, response: true, isCognito: true });

                if(response && response.data) {
                    this.setState({
                        availabilityModal: {
                            ...availabilityModal,
                            reservations: {
                                ...availabilityModal.reservations,
                                quoteErrMsg: '',
                                quoteData: response.data,
                                quoteIsLoading: false,
                            }
                        }
                    });
                };
            } 
            catch(e) {
                const errorMessage = e && e.response && e.response.data && e.response.data.Message;
                const genErrMsg = 'Please contact Redawning Support';

                this.setState({
                    availabilityModal: {
                        ...availabilityModal,
                        reservations: {
                            ...availabilityModal.reservations,
                            quoteErrMsg: errorMessage ? errorMessage : genErrMsg,
                            quoteData: {},
                            quoteIsLoading: false
                        }
                    }
                });
            };
        }
    }

    toggleAvailModalType = (value, mobile) => {
        const { 
            availabilityModal,
            mobileCalendarModal,
        } = this.state;

        if(mobile) {
            this.setState({
                mobileCalendarModal: {
                    ...mobileCalendarModal,
                    availabilityPanel: {
                        ...mobileCalendarModal.availabilityPanel,
                        type: value
                    }
                }
            }, () => {
                if(value == 'create') {
                    this.handleReservationQuoteMobile();
                };
            })
        }
        else {
            this.setState({
                availabilityModal: {
                    ...availabilityModal,
                    type: value
                }
            }, () => {
                if(value == 'create') {
                    this.handleReservationQuote();
                };
            })
        };
    }

    setNumOptions = (startOpt = 0, numOpt = 0) => {
        const results = [];
        for (let i = startOpt; i <= numOpt; i++) {
            results.push(<option key={i} value={i}>{i}</option>);
        };
        return results;
    }

    handleAvailReserveOnChange = (e, mobile, maxSleep) => {
        const { 
            availabilityModal,
            mobileCalendarModal,
        } = this.state;
        const name = e.target.name;
        const value = e.target.value;

        if(mobile) {
            let newState = {
                mobileCalendarModal: {
                    ...mobileCalendarModal,
                    availabilityPanel: {
                        ...mobileCalendarModal.availabilityPanel,
                        reservations: {
                            ...mobileCalendarModal.availabilityPanel.reservations,
                            quoteErrMsg: '',
                            [name]: value,
                        }
                    }
                }
            };
            
            if(name == 'adults') {
                newState.mobileCalendarModal.availabilityPanel.reservations.childOptions = this.setNumOptions(0, (maxSleep - value));
            }
            else if(name == 'children') {
                newState.mobileCalendarModal.availabilityPanel.reservations.adultOptions = this.setNumOptions(1, (maxSleep - value));
            };

            this.setState(newState, () => {
                this.handleReservationQuoteMobile();
            });
        }
        else {
            let newState = {
                availabilityModal: {
                    ...availabilityModal,
                    reservations: {
                        ...availabilityModal.reservations,
                        quoteErrMsg: '',
                        [name]: value
                    }
                }
            };

            if(name == 'adults') {
                newState.availabilityModal.reservations.childOptions = this.setNumOptions(0, (maxSleep - value));
            }
            else if(name == 'children') {
                newState.availabilityModal.reservations.adultOptions = this.setNumOptions(1, (maxSleep - value));
            };

            this.setState(newState, () => {
                this.handleReservationQuote();
            });
        }
    }

    handleOnDateChange = (startDate, endDate, mobile) => {
        const { 
            availabilityModal,
            mobileCalendarModal,
        } = this.state;

        if(mobile) {
            this.setState({
                mobileCalendarModal: {
                    ...mobileCalendarModal,
                    availabilityPanel: {
                        ...mobileCalendarModal.availabilityPanel,
                        reservations: {
                            ...mobileCalendarModal.availabilityPanel.reservations,
                            reservationDates: {
                                ...mobileCalendarModal.availabilityPanel.reservations.reservationDates,
                                quoteErrMsg: '',
                                startDate: 
                                    startDate ? 
                                    startDate : 
                                    null,
                                endDate: 
                                    endDate ? 
                                    endDate : 
                                    null
                            }
                        }
                    }
                }
            }, () => {
                if(startDate !== null || endDate !== null) {
                    this.handleReservationQuoteMobile();
                }
            });
        }
        else {
            this.setState({
                availabilityModal: {
                    ...availabilityModal,
                    reservations: {
                        ...availabilityModal.reservations,
                        reservationDates: {
                            ...availabilityModal.reservations.reservationDates,
                            quoteErrMsg: '',
                            startDate: 
                                startDate ? 
                                startDate : 
                                null,
                            endDate: 
                                endDate ? 
                                endDate : 
                                null
                        }
                    }
                }
            }, () => {
                if(startDate !== null || endDate !== null) {
                    this.handleReservationQuote();
                };
            });
        }
    }

    ratesSummary = (modal, mobile) => {
        const {
            mobileCalendarModal,
            availabilityModal,
        } = this.state;
        const data = mobile ? mobileCalendarModal.availabilityPanel.reservations : availabilityModal.reservations;
        const requiredQuoteData = this.validQuoteData(mobile ? mobileCalendarModal.availabilityPanel : availabilityModal);

        let originalPrice = 0;

        if(data.quoteData && data.quoteData.applied_discounts && data.quoteData.applied_discounts.length > 0) {
            originalPrice = 
                (data.quoteData.original_rental_price) +
                (data.quoteData.original_tax) +
                (data.quoteData.cleaning_fee) +
                (data.quoteData.service_fee / (1 - (Math.abs(data.quoteData.applied_discounts[0].value) / 100)))
        };

        let total = 0;
        
        if(data.quoteData && data.quoteData.payment_schedule && data.quoteData.payment_schedule.length > 0) {
            data.quoteData.payment_schedule.map((payment) => {
                total = total + payment.amount;
            });
        };

        return (
            <Col xs={12} sm={12} className='rates-summary'>
                {data.quoteIsLoading && <Loader />}
                    
                <div className={data.quoteIsLoading ? 'isLoading' : ''}>
                    <h2 className="detail-label"><strong>Rates Summary</strong></h2>
                    {
                        !requiredQuoteData.dates && !requiredQuoteData.adults ?
                        <Fragment>
                            <div className="reservation-billing">
                                <h4>Rental Price</h4>
                                <div className="reservation-billing-border"></div>
                                <h4 
                                    className={data.quoteData && data.quoteData.applied_discounts && data.quoteData.applied_discounts.length > 0 && 'discount-price'}
                                >
                                    {   
                                        (data.quoteData && 
                                        data.quoteData.applied_discounts && 
                                        data.quoteData.applied_discounts.length > 0) && 
                                        <span className='line-through'>
                                            {`$ ${data.quoteData && data.quoteData.original_rental_price ? (data.quoteData.original_rental_price).toFixed(2) : 0}`}
                                        </span>
                                    }
                                        <span 
                                            className={data.quoteData && data.quoteData.applied_discounts && data.quoteData.applied_discounts.length > 0 && 'bold'}
                                        >
                                            {`$ ${data.quoteData && data.quoteData.rental_price ? (data.quoteData.rental_price).toFixed(2) : 0}`}
                                        </span>
                                </h4> 
                            </div>
                            <div className="reservation-billing">
                                <h4>Cleaning Fee</h4>
                                <div className="reservation-billing-border"></div>
                                <h4><span>{`$ ${data.quoteData && data.quoteData.cleaning_fee ? (data.quoteData.cleaning_fee).toFixed(2) : 0}`}</span></h4> 
                            </div>
                            <div className="reservation-billing">
                                <h4>Service Fee</h4>
                                <div className="reservation-billing-border"></div>
                                <h4><span>{`$ ${data.quoteData && data.quoteData.service_fee ? (data.quoteData.service_fee).toFixed(2) : 0}`}</span></h4> 
                            </div>
                            <div className="reservation-billing">
                                <h4>Tax</h4>
                                <div className="reservation-billing-border"></div>
                                <h4><span>{`$ ${data.quoteData && data.quoteData.tax ? (data.quoteData.tax.toFixed(2)) : 0}`}</span></h4> 
                            </div>
                            <div className={`reservation-billing`}>
                                <h4><strong>Total</strong>{data.quoteData && data.quoteData.applied_discounts && data.quoteData.applied_discounts.length > 0 && <span className='discount-title'>({data.quoteData && data.quoteData.applied_discounts && data.quoteData.applied_discounts[0] && data.quoteData.applied_discounts[0].name ? data.quoteData.applied_discounts[0].name : ''})</span>}</h4>
                                <div className="reservation-billing-border"></div>
                                <h4 className={data.quoteData && data.quoteData.applied_discounts && data.quoteData.applied_discounts.length > 0 && 'discount-price'}>
                                    {
                                        data.quoteData && data.quoteData.applied_discounts && data.quoteData.applied_discounts.length > 0 && 
                                        <span className='line-through'>
                                            {`$ ${originalPrice.toFixed(2)}`}
                                        </span>
                                    }
                                    <strong>
                                        {`$ ${total.toFixed(2)}`}
                                    </strong>
                                </h4>
                            </div>
                        </Fragment>
                        :
                        <Fragment>
                            <div className='required-quote-data'>
                                {
                                    requiredQuoteData.adults &&
                                    <span>Number of Adults required.</span>
                                }
                                {
                                    requiredQuoteData.dates &&
                                    <span>Check-in/Check-out dates required.</span>
                                }
                            </div>
                        </Fragment>
                    }
                </div>
            </Col>
        )
    }

    validQuoteData = (quoteData) => {
        let data = {
            adults: false,
            dates: false,
        };

        if(quoteData.reservations.reservationDates.startDate === null || quoteData.reservations.reservationDates.endDate === null) {
            data.dates = true;
        }
        else {
            data.dates = false;
        };

        if(quoteData.reservations.adults <= 0) {
            data.adults = true;
        }
        else {
            data.adults = false;
        };

        return data;
    }

    errMsg = (modal, errorData) => {
        if(errorData.length) {
            return (
                <Row>
                    <Col xs={12} sm={12}>
                        <div className='msg-container _error'>
                            {errorData}
                        </div>
                    </Col>
                </Row>
            )
        }
    }

    disableCheckOutbtn = (mobile) => {
        const { 
            availabilityModal,
            mobileCalendarModal,
        } = this.state;

        const requiredQuoteData = this.validQuoteData(mobile ? mobileCalendarModal.availabilityPanel : availabilityModal);

        if(!requiredQuoteData.property && !requiredQuoteData.dates && !requiredQuoteData.adults) {
            return false;
        }
        else {
            return true;
        };
    }

    validGuestData = (mobile) => {
        const {
            validations,
            mobileCalendarModal,
            availabilityModal
        } = this.state;
        let validationsCopy = {...validations}

        const guestData = 
            mobile ? 
            mobileCalendarModal.availabilityPanel.reservations.guestData : 
            availabilityModal.reservations.guestData;

        const required = [
            'first_name',
            'last_name',
            'email',
            'phone',
            'cell_phone'
        ];
        
        Object.entries(guestData).map((x) => {
            if(x[0] === 'address') {
                if(!x[1].country.length) {
                    validationsCopy.country = true;
                }
                else {
                    validationsCopy.country = false;
                };

                if(!x[1].postal_code.length) {
                    validationsCopy.postal_code = true;
                }
                else {
                    validationsCopy.postal_code = false;
                };
            }
            else if (x[0] === 'payments') {
                if(!x[1][0].method_details.creditcard_number.length) {
                    validationsCopy.creditcard_number = true;
                }
                else {
                    validationsCopy.creditcard_number = false;
                };

                if(!x[1][0].method_details.expiration_month.length) {
                    validationsCopy.expiration_month = true;
                }
                else {
                    validationsCopy.expiration_month = false;
                };

                if(!x[1][0].method_details.expiration_year.length) {
                    validationsCopy.expiration_year = true;
                }
                else {
                    validationsCopy.expiration_year = false;
                };

                if(!x[1][0].method_details.cvv.length) {
                    validationsCopy.cvv = true;
                }
                else {
                    validationsCopy.cvv = false;
                };
            }
            else if(required.includes(x[0])) {
                if(x[0] === 'email') {
                    if(!x[1].length || !x[1].match(constants.EMAIL_VALIDATION)) {
                        validationsCopy[x[0]] = true;
                    }
                    else {
                        validationsCopy[x[0]] = false;
                    };
                }
                else {
                    if(!x[1].length) {
                        validationsCopy[x[0]] = true;
                    }
                    else {
                        validationsCopy[x[0]] = false;
                    };
                };
            };
        });

        return validationsCopy;
    }

    handleGuestInfoModal = (e, showGuestInfoModal, mobile) => {
        e.preventDefault();
        const { 
            availabilityModal,
            mobileCalendarModal,
        } = this.state;

        if(mobile) {
            this.setState({
                validations: {
                    first_name: false,
                    last_name: false,
                    email: false,
                    phone: false,
                    postal_code: false,
                    country: false,
                    creditcard_number: false,
                    expiration_month: false,
                    expiration_year: false,
                    cvv: false,
                },
                mobileCalendarModal: {
                    ...mobileCalendarModal,
                    availabilityPanel: {
                        ...mobileCalendarModal.availabilityPanel,
                        reservations: {
                            ...mobileCalendarModal.availabilityPanel.reservations,
                            showGuestInfoModal: showGuestInfoModal,
                            bookNowErrMsg: '',
                            guestData: {
                                ...mobileCalendarModal.availabilityPanel.reservations.guestData, 
                                payments: [
                                    {
                                        ...mobileCalendarModal.availabilityPanel.reservations.guestData.payments[0],
                                        method_details: {
                                            ...mobileCalendarModal.availabilityPanel.reservations.guestData.payments[0].method_details,
                                            creditcard_number: '',
                                            cvv: '',
                                            expiration_month: '',
                                            expiration_year: ''
                                        }
                                    }
                                ]
                            }
                        }
                    }
                }
            })
        }
        else {
            this.setState({
                validations: {
                    first_name: false,
                    last_name: false,
                    email: false,
                    phone: false,
                    postal_code: false,
                    country: false,
                    creditcard_number: false,
                    expiration_month: false,
                    expiration_year: false,
                    cvv: false,
                },
                availabilityModal: {
                    ...availabilityModal,
                    reservations: {
                        ...availabilityModal.reservations,
                        showGuestInfoModal: showGuestInfoModal,
                        bookNowErrMsg: '',
                        guestData: {
                            ...availabilityModal.reservations.guestData, 
                            payments: [
                                {
                                    ...availabilityModal.reservations.guestData.payments[0],
                                    method_details: {
                                        ...availabilityModal.reservations.guestData.payments[0].method_details,
                                        creditcard_number: '',
                                        cvv: '',
                                        expiration_month: '',
                                        expiration_year: ''
                                    }
                                }
                            ]
                        }
                    }
                }
            })
        }
    }

    handlePaymentOnChange = (e, mobile) => {
        const { 
            availabilityModal,
            mobileCalendarModal,
            validations
        } = this.state;
        const name = e.target.name,
              value = e.target.value;

        if(mobile) {
            this.setState({
                mobileCalendarModal: {
                    ...mobileCalendarModal,
                    availabilityPanel: {
                        ...mobileCalendarModal.availabilityPanel,
                        reservations: {
                            ...mobileCalendarModal.availabilityPanel.reservations,
                            guestData: {
                                ...mobileCalendarModal.availabilityPanel.reservations.guestData,
                                payments: [
                                    {
                                        ...mobileCalendarModal.availabilityPanel.reservations.guestData.payments[0],
                                        method_details: {
                                            ...mobileCalendarModal.availabilityPanel.reservations.guestData.payments[0].method_details,
                                            [name]: value
                                        }
                                    }
                                ]
                            }
                        }
                    }
                }
            }, () => {
                if(validations[name] == true) {
                    const newValidations = this.validGuestData(mobile);
        
                    this.setState({
                        validations: newValidations
                    });
                };
            });
        }
        else {
            this.setState({
                availabilityModal: {
                    ...availabilityModal,
                    reservations: {
                        ...availabilityModal.reservations,
                        guestData: {
                            ...availabilityModal.reservations.guestData,
                            payments: [
                                {
                                    ...availabilityModal.reservations.guestData.payments[0],
                                    method_details: {
                                        ...availabilityModal.reservations.guestData.payments[0].method_details,
                                        [name]: value
                                    }
                                }
                            ]
                        }
                    }
                }
            }, () => {
                if(validations[name] == true) {
                    const newValidations = this.validGuestData(mobile);
        
                    this.setState({
                        validations: newValidations
                    });
                };
            });
        }
    }

    handleGuestDataOnChange = (e, mobile) => {
        const { 
            availabilityModal,
            mobileCalendarModal,
            validations
        } = this.state;
        const name = e.target.name,
              value = e.target.value;
  
        if(mobile) {
            if(name === 'postal_code' || name === 'country') {
                this.setState({
                    mobileCalendarModal: {
                        ...mobileCalendarModal,
                        availabilityPanel: {
                            ...mobileCalendarModal.availabilityPanel,
                            reservations: {
                                ...mobileCalendarModal.availabilityPanel.reservations,
                                guestData: {
                                    ...mobileCalendarModal.availabilityPanel.reservations.guestData,
                                    address: {
                                        ...mobileCalendarModal.availabilityPanel.reservations.guestData.address,
                                        [name]: value
                                    }
                                }
                            }
                        }
                    }
                }, () => {
                    if(validations[name] == true) {
                        const newValidations = this.validGuestData(mobile);
            
                        this.setState({
                            validations: newValidations
                        });
                    };
                });
            }
            else {
                this.setState({
                    mobileCalendarModal: {
                        ...mobileCalendarModal,
                        availabilityPanel: {
                            ...mobileCalendarModal.availabilityPanel,
                            reservations: {
                                ...mobileCalendarModal.availabilityPanel.reservations,
                                guestData: {
                                    ...mobileCalendarModal.availabilityPanel.reservations.guestData,
                                    [name]: value
                                }
                            }
                        }
                    }
                }, () => {
                    if(validations[name] == true) {
                        const newValidations = this.validGuestData(mobile);
            
                        this.setState({
                            validations: newValidations
                        });
                    };
                });
            };
        }
        else {
            if(name === 'postal_code' || name === 'country') {
                this.setState({
                    availabilityModal: {
                        ...availabilityModal,
                        reservations: {
                            ...availabilityModal.reservations,
                            guestData: {
                                ...availabilityModal.reservations.guestData,
                                address: {
                                    ...availabilityModal.reservations.guestData.address,
                                    [name]: value
                                }
                            }
                        }
                    }
                }, () => {
                    if(validations[name] == true) {
                        const newValidations = this.validGuestData(mobile);
            
                        this.setState({
                            validations: newValidations
                        });
                    };
                });
            }
            else {
                this.setState({
                    availabilityModal: {
                        ...availabilityModal,
                        reservations: {
                            ...availabilityModal.reservations,
                            guestData: {
                                ...availabilityModal.reservations.guestData,
                                [name]: value
                            }
                        }
                    }
                }, () => {
                    if(validations[name] == true) {
                        const newValidations = this.validGuestData(mobile);
            
                        this.setState({
                            validations: newValidations
                        });
                    };
                });
            };
        }
    }

    renderCreateReservationModal = () => {
        const { 
            availabilityModal,
            mobileCalendarModal,
        } = this.state;
        const data = this.props.mobileMode ? mobileCalendarModal.availabilityPanel : availabilityModal;
        
        return (
            <CreateReservationModal
                mobileMode={this.props.mobileMode}
                showSuccessMessage={false}
                quoteData={data.reservations.quoteData}
                guestData={data.reservations.guestData}
                bookNowSuccess={data.reservations.bookNowSuccess}
                bookNowErrMsg={data.reservations.bookNowErrMsg}
                bookNowIsLoading={data.reservations.bookNowIsLoading}
                showModal={data.reservations.showGuestInfoModal}
                ratesSummary={this.ratesSummary}
                errMsg={this.errMsg}
                validations={this.state.validations}
                handleGuestInfoModal={this.handleGuestInfoModal}
                handlePaymentOnChange={this.handlePaymentOnChange}
                handleGuestDataOnChange={this.handleGuestDataOnChange}
                handleBookNow={this.handleBookNow}
            />
        )
    }

    // <-- Create Reservations ---> ** End **

    /**
     * Pricing modal render function.
     * @returns JSX element will return 
     */
    renderPricingModal() {
        // method to render the pricing modal
        let errors = this.state.errors;
        let isDisabled = false;

        Object.keys(errors).map(error => {
            if (errors[error].show) {
                isDisabled = true;
            }
        })
        return (
            <PricingModal
                pricingModal={this.state.pricingModal}
                errors={this.state.errors}
                admin={this.isAdmin}
                loading={this.state.loading}
                isDeleting={this.state.isDeleting}
                mobileMode={this.props.mobileMode}
                propertyPricePeriods={this.state.propertyPricePeriods}
                editEntirePricePeriod={this.state.editEntirePricePeriod}
                oneDayPricePeriod={this.state.oneDayPricePeriod}
                getPropertyById={this.getPropertyById.bind(this)}
                handlePeriodDateChange={this.handlePeriodDateChange.bind(this)}
                handlePeriodAttrChange={this.handlePeriodAttrChange.bind(this)}
                handleBadgeChange={this.handleBadgeChange.bind(this)}
                submitPricing={this.submitPricing.bind(this)}
                deletePricing={this.deletePricing.bind(this)}
                hidePricingModal={this.hidePricingModal.bind(this)}
                toggleEntirePricePeriod={this.toggleEntirePricePeriod}
                createDefaultRadioButton={this.createDefaultRadioButton}
                handleSmartPricingSource={this.handleSmartPricingSource}
                handleSmartPricingChange={this.handleSmartPricingChange}
                submitPricingOvr={this.submitPricingOvr}
                deletePricingOvr={this.deletePricingOvr}
                toggleMinPriceOnly={this.toggleMinPriceOnly}
            />
        )
    }

    renderCicoModal = () => {
        return (
            <CicoModal
                cicoModal={this.state.cicoModal}
                errors={this.state.errors}
                admin={this.isAdmin}
                loading={this.state.loading}
                isDeleting={this.state.isDeleting}
                oneDayCicoPeriod={this.state.oneDayCicoPeriod}
                editEntireCicoPeriod={this.state.editEntireCicoPeriod}
                getPropertyById={this.getPropertyById.bind(this)}
                handlePeriodDateChange={this.handlePeriodDateChange.bind(this)}
                handleBadgeChange={this.handleBadgeChange.bind(this)}
                submitCico={this.submitCico}
                deleteCico={this.deleteCico}
                hideCicoModal={this.hideCicoModal}
                toggleEntireCicoPeriod={this.toggleEntireCicoPeriod}
            />
        )
    }

    /**
     * Invoke function for fetching a suggestions of property names for import by property.
     * @param {object} event element to get string from it's target. 
     */
    fetchPropertiesSuggestions(event) {
        if (typeof (event.value) === 'string') {
            setTimeout(() => {
                if (event.value === this.state.value && this.state.value.length > 2) {
                this.setState({
                        suggestion: [],
                        autoLoader: true
                    })
                    apiHandler.get(`/properties?organization=${this.org}&keyword=${encodeURIComponent(event.value)}`).then((suggestion) => {
                        this.setState({
                            suggestions: suggestion.data,
                            autoLoader: false
                        })
                    }).catch((e) => {
                        console.log(e);
                        this.setState({
                            suggestions: [],
                            autoLoader: false
                        })
                    })
                }
            }, 1500)
        }
    }

    componentDidCatch(error) {
        console.log(error)
    }

    /**
     * For render availability modal.
     */
    renderAvailabilityModal() {
        // method to render the availability modal
        return (<AvailabilityModal
            children={this.state.availableChildren}
            availabilityModal={this.state.availabilityModal}
            getPropertyById={this.getPropertyById.bind(this)}
            hideAvailabilityModal={() => this.hideAvailabilityModal(this)}
            handleBlockedDateChange={this.handleBlockedDateChange.bind(this)}
            errors={this.state.errors}
            admin={this.isAdmin}
            guestServices={this.guestServices}
            deleteAvailability={this.deleteAvailability}
            submitAvailability={this.submitAvailability}
            viewSettingsAvailabilityModal={this.viewSettingsAvailabilityModal}
            handleAvailNoteOnChange={this.handleAvailNoteOnChange}
            addAvailNote={this.addAvailNote}
            deleteAvailNote={this.deleteAvailNote}
            handleAvailExpiresDate={this.handleAvailExpiresDate}
            handleExpiresTime={this.handleExpiresTime}
            availNotesTypes={this.availNotesTypes}
            availNoteDate={this.state.availNoteDate}
            noteValidation={this.state.noteValidation}
            typeValidation={this.state.typeValidation}
            duplicateNotes={this.state.duplicateNotes}
            loading={this.state.loading}
            isDeleting={this.state.isDeleting}
            activeProperty={this.state.activeProperty}
            /** Create Reservations */
            ratesSummary={this.ratesSummary}
            availabilityModalMode={this.state.availabilityModalMode}
            toggleAvailModalType={this.toggleAvailModalType}
            handleAvailReserveOnChange={this.handleAvailReserveOnChange}
            handleOnDateChange={this.handleOnDateChange}
            errMsg={this.errMsg}
            handleGuestInfoModal={this.handleGuestInfoModal}
            disableCheckOutbtn={this.disableCheckOutbtn}
            handleReservationQuote={this.handleReservationQuote}
            isInternalPropertyAdmin={this.props.isInternalPropertyAdmin}
        />)
    }

    /**
     * Render pricing and availability modal data in one modal for mobile view.
     */
    renderMobileCalendarModal() {
        const {
            errors
        } = this.state

        let property_id = this.state.mobileCalendarModal.pricingPanel.property_id || 
        this.state.mobileCalendarModal.availabilityPanel.property_id

        return (
            <MobileCalendarModal
                integrationName={this.state.integrationsDataByOrgName}
                hideMobileCalendarModal={()=> this.hideMobileCalendarModal()}
                activeProperty={this.activeProperty}
                updateShowActivePanel={this.updateShowActivePanel}
                mobileCalendarModal={this.state.mobileCalendarModal}
                getPropertyById={this.getPropertyById.bind(this)}
                handleUpdateModalEventsByDate={this.handleUpdateModalEventsByDate}
                dateClicked={this.state.dateClicked}
                today={this.today}
                history={this.props.history}
                events={this.state.events}
                errors={errors}
                handleTouchStart={this.handleTouchStart}
                handleTouchMove={this.handleTouchMove}
                /* pricing */
                handlePeriodDateChange={this.handlePeriodDateChange.bind(this)}
                handlePricingPanelAttrChange={this.handlePricingPanelAttrChange.bind(this)}
                submitPricing={this.submitPricing.bind(this)}
                deletePricing={this.deletePricing.bind(this)}
                propertyPricePeriods={this.state.propertyPricePeriods}
                createDefaultRadioButton={this.createDefaultRadioButton}
                handleSmartPricingSource={this.handleSmartPricingSource}
                handleSmartPricingChange={this.handleSmartPricingChange}
                submitPricingOvr={this.submitPricingOvr}
                deletePricingOvr={this.deletePricingOvr}
                toggleMinPriceOnly={this.toggleMinPriceOnly}
                /* availability */
                availableChildren={this.state.availableChildren}
                handleBlockedDateChange={this.handleBlockedDateChange.bind(this)}
                admin={this.isAdmin}
                deleteAvailability={this.deleteAvailability}
                submitAvailability={this.submitAvailability}
                loading={this.state.loading}
                isDeleting={this.state.isDeleting}
                viewSettingsAvailabilityModal={this.viewSettingsAvailabilityModal}
                availabilityModal={this.state.availabilityModal}
                handleAvailNoteOnChange={this.handleAvailNoteOnChange}
                addAvailNote={this.addAvailNote}
                deleteAvailNote={this.deleteAvailNote}
                handleAvailExpiresDate={this.handleAvailExpiresDate}
                handleExpiresTime={this.handleExpiresTime}
                availNotesTypes={this.availNotesTypes}
                noteValidation={this.state.noteValidation}
                typeValidation={this.state.typeValidation}
                duplicateNotes={this.state.duplicateNotes}
                availNoteDate={this.state.availNoteDate}
                /* available room */
                totalUnits={
                    this.getPropertyById(property_id)[0] && 
                    this.getPropertyById(property_id)[0].units
                } 
                children={this.state.propertyChildren[property_id] || []}
                unavailability={this.state.unAvailableRooms}
                reservations={this.state.reservationList}
                /* cico */
                submitCico={this.submitCico}
                deleteCico={this.deleteCico}
                handleBadgeChange={this.handleBadgeChange.bind(this)}
                cicoLocked={this.state.cicoLocked}
                /** Create Reservations */
                ratesSummary={this.ratesSummary}
                availabilityModalMode={this.state.availabilityModalMode}
                toggleAvailModalType={this.toggleAvailModalType}
                handleAvailReserveOnChange={this.handleAvailReserveOnChange}
                handleOnDateChange={this.handleOnDateChange}
                errMsg={this.errMsg}
                handleGuestInfoModal={this.handleGuestInfoModal}
                disableCheckOutbtn={this.disableCheckOutbtn}
            />
        )
    }

    /**
     * For close no available modal when selected property don't have any priceperiods.
     */
    closeAvailable() {
        this.setState({
            notAvailable: false
        })
    }

    closeSuccessImportCSVModal = () => {
        this.setState({
            successImportCSV: false,
            successImportCSVModal: false
        });
    }

    handleSearch = (e, compare) => {
        e ? e.preventDefault() : "";
        let pagination = this.state.pagination, 
            reservations = this.state.reservationList;
        pagination.activePage = 1;
        let propertyLevelArr = []
        
        this.fetchProperties(0, 0).then(async(properties) => {
            pagination.totalItemsCount = properties.total;

            if(properties.data) {
                for(let i = 0; i < properties.data.length; i++) {

                    // Smart Pricing Override
                    await this.configSmartPriceOvr(properties.data[i]);
                };
            };

            await Promise.all(properties.data.map(async(prop,index) => {
                propertyLevelArr.push(prop.level)
                properties.data[index]['reservations'] = reservations.filter(resv => {
                    if (properties.data[index].property_id == resv.property_id) {
                        return resv;
                    }
                });
                if (properties.data[index].level === 'rep') {
                    let availability = await this.getRepAvailability(properties.data[index].property_id, properties.data[index].price_periods || [], (properties.data[index].repconfig.units_active && properties.data[index].repconfig.units_active.length) || 0) || []
                    properties.data[index]['availability'] = availability;
                }
            }))
            this.setState({
                pagination: pagination,
                events: properties.data,
                propertyList: properties.data,
                propertyLevels: propertyLevelArr,
                comparePropertyList: compare ? 
                properties.data :
                this.state.comparePropertyList,
                isLoading: false
            })
        })
    }

    clearSearch = (e, isEqualList) => {
        let [xButton] = document.getElementsByClassName('x-button'),
        [pricingSearchForm] = document.getElementsByClassName('pricing-search-bar')
        xButton.classList.add('icon-hide')
        pricingSearchForm.classList.remove('dirty')
        this.searchInput.value = ''

        if (!isEqualList) {
            this.handleSearch(e, 'compare')
        }
    }
    
    handleSearchChange = (e) => {
        let [xButton] = document.getElementsByClassName('x-button'),
        [pricingSearchForm] = document.getElementsByClassName('pricing-search-bar')

        if (e.target.value !== '') {
            pricingSearchForm.classList.add('dirty')
            xButton.classList.remove('icon-hide')
        }
        else {
            pricingSearchForm.classList.remove('dirty')
            xButton.classList.add('icon-hide')
        }
    }

    createDefaultRadioButton = (labels, state, onChangeFunc, popOver) => {
        const smartPricingInfo = {
            min_price: "Add or modify minimum price override.",
            price_ovr: "Add or modify price override.",
        };
		return (
			labels.map((label, i) => (
            <Col md={6} sm={6} key={i}>
                <label
                    className="radio-wrap radio-input"
                >
                    <input
                        type="radio" 
                        className="form-radio-label radio-label"
                        name={label["name"]}  
                        onChange={e => onChangeFunc(e)}
                        checked={state == label.value ? label.value : ""}
                        value={label['value']}
                    />
                    <div
                        className="radio-label"
                    >
                        {label["label"]}
                    </div>
                </label>
                {   popOver &&
                    infoPopover(smartPricingInfo[label.value], null, "top", "icon-info")
                }
            </Col>
		))
    )}

    render() {
        let calendarHeight = {};
        let activeId = this.props.match.params.property_id;
        let isMobileCalendarModalView = 
            this.state.mobileCalendarModal.pricingPanel.show || 
            this.state.mobileCalendarModal.availabilityPanel.show;
 
        const { 
            pagination: { 
                totalItemsCount 
            } 
        } = this.state

        let errors = this.state.errors;
        if (this.props.mobileMode === false) {
            if (this.state.view == constants.CALENDAR.VIEW.MULTI_PROPERTY) {
                calendarHeight.height = 60 + (150 * this.state.events.length);
            }
        }
        const inputProps = {
            placeholder: 'Property Title, ID, or Reference ID',
            value: this.state.value,
            className: 'form-field',
            onChange: (event, { newValue }) => {
                if (typeof (newValue) !== 'string') {
                    newValue = newValue.content.title
                }
                this.setState({
                    value: newValue
                });
            },
        };
        let progress = this.state.importCompleted.completed / this.state.importCompleted.all * 100,
        noPropertiesReturnedFromAPI = false,
        breadcrumbs, 
        message
        if (!this.state.isLoading && !this.state.propertyList.length) {
            noPropertiesReturnedFromAPI = true
            breadcrumbs = [{
                title: "Calendar",
                link: "/calendar"
            }],
                message = errors.fetchError.show ? 
                errors.fetchError.message : 
                "No properties found. Please create a property to set pricing and availability";
            if (this.state.activeProperty) {
                breadcrumbs.push({
                    title: this.state.activeProperty
                })
                if (this.state.status === 404) {
                    message = STRING_CONSTANTS.PROPERTY_NOT_FOUND;
                } else if (this.state.status === 403) {
                    message = STRING_CONSTANTS.NO_ACCESS_PROPERTY;
                }
            }
        }
        let repChild = this.state.propertyList[0] && 
        this.state.propertyList[0].level === 'repchild'

        let listViewKey = repChild &&
        this.state.listViewKey !== null ?
        2 :
        this.state.listViewKey

        return (
            <div className='main-container'>
                <div 
                    id='content' 
                    className={`${activeId ? 'monthview' : 'weekview'}-pricing`}
                >
                    <Loader hide={this.state.isLoading ? false : true} />
                    {
                       ((!this.state.isLoading && this.props.mobileMode) || (this.state.isLoading && !this.props.mobileMode) || (!this.state.isLoading && !this.props.mobileMode)) &&
                        <Grid 
                            fluid={true} 
                            className={this.props.mobileMode ? `mobile-col${noPropertiesReturnedFromAPI ? ' big-top-margin' : ''}` : ''}
                        >
                        {!activeId && !noPropertiesReturnedFromAPI ?                             
                            <Col>
                                <div className='searchbar-wrapper'>
                                    <form 
                                        className='search-inp pricing-search-bar' 
                                        onSubmit={this.handleSearch}>
                                            <InputField 
                                                type='searchbox' 
                                                inputRef={ref => this.searchInput = ref}
                                                className="weeklyView pricing-search-bar" 
                                                placeholder='Search' 
                                                name='keyword' 
                                                onChange={this.handleSearchChange} 
                                            />
                                            <label 
                                                className={`x-button icon-hide`} 
                                                onClick={(e)=>{
                                                        let isEqualList = isEqual(this.state.propertyList, this.state.comparePropertyList)
                                                        this.clearSearch(e, isEqualList)
                                                    }
                                                }
                                            />
                                    </form>
                                </div>
                            </Col> : ''}
                            {
                                noPropertiesReturnedFromAPI === false && 
                                <Row>
                                    {!(this.state.view == 'custom' && this.props.match.params.property_id) && <Col xs={12}>
                                        <CalendarView
                                            handleShowAllOrg={this.handleShowAllOrg}
                                            showAllOrg={this.state.showAllOrg}
                                            allOrganizations={this.allOrganizations}
                                            org={this.org}
                                            repChild={repChild}
                                            integrationName={this.state.integrationsDataByOrgName}
                                            reservations={
                                                this.state.reservationList
                                                    .filter(res => res.property_id === this.state.activeProperty)
                                            }
                                            listViewKey={listViewKey}
                                            updateListViewPanelKey={this.updateListViewPanelKey}
                                            history={this.props.history}
                                            currentProperty={this.getPropertyById(this.state.activeProperty)[0]}
                                            admin={this.isAdmin}
                                            guestServices={this.guestServices}
                                            popup={true}
                                            date={this.state.selectedDate}
                                            onNavigate={this.onNavigate}
                                            events={this.state.events.map(event => {
                                                    if (event.cico_id || event.price_period_id || event.mobile_block_id || event.period && event.period.type === "Temporary") {
                                                        if (event.price_period_id) {
                                                            if (moment(event.period_start).format('YYYY-MM-DD') !== this.today) {
                                                                /* preserved original price period start for Weekly view */
                                                                event['price_period_start'] = moment(event.period_start).format('YYYY-MM-DD')
                                                            };
                                                        };

                                                        /* alter period start for Monthly view */
                                                        let period_end = event.period_end || (event.period && event.period_end) || event.end_date;
                                                        let period_start = event.period_start || (event.period && event.period.period_start) || event.start_date;

                                                        if (moment(period_end).isSameOrAfter(this.today) &&
                                                            moment(period_start).isSameOrBefore(this.today)) {
                                                            
                                                            /* alter cico start period */
                                                            if(event.cico_id) {
                                                                event.start_date = moment(this.today).format('YYYY-MM-DD')
                                                            }
                                                            else {
                                                                /* alter price/block start period */
                                                                if (event.period) {
                                                                    event.period['period_start'] = moment(this.today).format('YYYY-MM-DD')
                                                                }
                                                                else {
                                                                    event['period_start'] = moment(this.today).format('YYYY-MM-DD')
                                                                };
                                                            };
                                                        };
                                                    };
                                                    return event
                                                })
                                            }
                                            startAccessor={this.getEventStart}
                                            endAccessor={this.getEventEnd}
                                            titleAccessor={this.getEventTitle}
                                            eventPropGetter={this.setEventStyles}
                                            onUpdateModalEventsByDate={this.handleUpdateModalEventsByDate}
                                            onSelectEvent={(event, e, id) => this.showEventDetails(event, e, id, this)}
                                            eventAccessor={this.state.eventAccessor}
                                            groupAccessor='property_id'
                                            view={this.state.view}
                                            totalRooms={
                                                this.state.availableChildren && 
                                                this.state.availableChildren.length
                                            }
                                            isMobileCalendarModalView={isMobileCalendarModalView}
                                            today={this.today}
                                            onView={view => this.onView(view, this)}
                                            showImport={this.toggleImportModal}
                                            showAvailableRooms={this.showAvailableRooms}
                                            multiRepFinalAvail={this.multiRepFinalAvail}
                                            multiAvailTable={this.multiAvailTable}
                                            propertyLevels={this.state.propertyLevels}
                                            showImportFromProperty={this.toggleImportByProperty}
                                            showImportByCSV={this.toggleImportByCSV}
                                            toggleShowIcalUrlModal={this.toggleShowIcalUrlModal}
                                            addPricing={this.addPricing}
                                            editPricing={this.editPricing}
                                            editCico={this.editCico}
                                            addCico={this.addCico}
                                            addAvailability={this.addAvailability}
                                            activeProperty={this.state.activeProperty}
                                            onChangeFilters={this.onChangeFilters}
                                            getCalendarTitle={this.getCalendarTitle}
                                            height={calendarHeight}
                                            handleShowMore={this.handleShowMore}
                                            onDoubleClick={this.handleDoubleClick}
                                            handleBack={this.handleBackBtn}
                                            listView={this.state.listView}
                                            propertyLevel={this.activeProperty && this.activeProperty.level}
                                            toggleListView={this.toggleListView}
                                            pageSize={this.state.pagination.itemsCountPerPage}
                                            handlePageSizeChange={this.handlePageSizeChange}
                                            currency={this.state.propertyList.length && this.state.propertyList[0].price ? this.state.propertyList[0].price.currency : 'USD'}
                                            dragEvents={{
                                                handleBlockedDrag: this.handleBlockedDrag,
                                                handleBlockedDragStart: this.handleBlockedDragStart,
                                                handleBlockedDragEnd: this.handleBlockedDragEnd,
                                                handlePricingDrag: this.handlePricingDrag,
                                                handlePricingDragStart: this.handlePricingDragStart,
                                                handlePricingDragEnd: this.handlePricingDragEnd,
                                                pricingHighlightedDates: this.state.pricingHighlightedDates,
                                                pricingPropId: this.pricingDragHandler.propId,
                                                blockedHighlightedDates: this.state.blockedHighlightedDates,
                                                blockedPropId: this.availabilityDragHandler.propId
                                            }}
                                        />
                                        {this.state.view == constants.CALENDAR.VIEW.MULTI_PROPERTY && totalItemsCount > 0 &&
                                        <div className={`pagination-container${this.props.mobileMode ? ' mobile' : ''}`} >
                                            <div className="units-info-container">
                                                {totalItemsCount} {totalItemsCount == 1 ? 'Property' : 'Properties'}&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                                            </div>
                                            <div className="page_size-input-container">
                                                <form className='page-size' onSubmit={this.handlePageSizeChange}>
                                                    <InputField type='text' name='pageSize' defaultValue={this.state.pagination.itemsCountPerPage} /> / page
                                                </form>
                                            </div>
                                            <Pagination
                                                activePage={this.state.pagination.activePage}
                                                itemsCountPerPage={this.state.pagination.itemsCountPerPage}
                                                totalItemsCount={parseInt(totalItemsCount)}
                                                linkClass={this.props.mobileMode ? "mobile-link" : ""}
                                                pageRangeDisplayed={this.props.mobileMode ? 1 : this.state.pagination.pageRangeDisplayed}
                                                onChange={page => this.getPageByNum(page)}
                                                innerClass={`pagination${!this.props.mobileMode ? ' pull-right' : ''}`}
                                            />
                                        </div>}
                                    </Col>}
                                </Row>
                            }
                            {
                                noPropertiesReturnedFromAPI && 
                                <Row>
                                    <Col xs={12}>
                                        <Title 
                                            breadcrumbItems={breadcrumbs} 
                                            title='Property Calendar'
                                        >
                                            {
                                                !this.state.mobileMode &&
                                                this.allOrganizations.length > 1 &&
                                                this.org !== '_global_' && 
                                                this.org !== 'travelagencies' &&
                                                <div className="filters-wrap">
                                                    <InputField 
                                                        type="checkbox" 
                                                        value={this.state.showAllOrg} 
                                                        cbChange={() => this.state.handleShowAllOrg('showAllOrg')}
                                                    >
                                                        All Organizations
                                                    </InputField>
                                                </div>
                                            }
                                        </Title>
                                        <div className="no-collection">
                                            {message}
                                        </div>
                                    </Col>
                                </Row>
                            }
                        </Grid>
                    }
                    {this.renderMobileCalendarModal()}
                    {this.renderPricingModal()}
                    {this.renderAvailabilityModal()}
                    {this.renderCicoModal()}
                    {this.renderCreateReservationModal()}
                    <Modal
                        className="import-modal" 
                        show={this.state.CSVModal} 
                        onHide={this.toggleImportByCSV}
                        backdrop="static"
                    >
                        <Modal.Header closeButton>
                            <h1>Import Pricing</h1> 
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col xs={12}>  
                                    <a  
                                        download={!this.state.downloading ? (`pricing_template_${this.state.activeProperty ? this.state.activeProperty : this.org}.xlsx`) : null}
                                        id="csv-link"
                                        className="clickable-text bottom-margin"
                                        href={this.state.s3CsvFile || "#"}
                                        onClick={(e) => {
                                            !this.state.downloading && 
                                            !this.state.s3CsvFile &&
                                            this.getCSVPricingFile(e)
                                            } 
                                        }
                                    >
                                        Download Pricing Template
                                        <span 
                                                className={'glyphicon glyphicon-refresh' + (this.state.downloading ? ' spinning' : ' hide')}
                                        />
                                    </a>
                                    <FieldWrap 
                                        style={
                                            {   
                                                marginTop: '10px',
                                                position: 'relative', 
                                            }
                                        }
                                    >
                                        <input 
                                            type="file" 
                                            name="file" 
                                            id="upload-csv" 
                                            accept=".xls, .csv, application/vnd.ms-excel" 
                                            onChange={e=> {this.handleFileChange(e)}}
                                            onClick={() => {
                                                // clear input file value when clicked
                                                let input = document.getElementById('upload-csv');
                                                input.value = "";
                                                this.setState({
                                                    csvPayload: "",
                                                });
                                            }}
                                        />
                                        <label 
                                            htmlFor="upload-csv"
                                        >
                                            <span 
                                                id="csv-label-wrapper"
                                            >
                                                Choose File
                                            </span>
                                            <span id="csv-file-name">
                                                {
                                                    this.state.csvPayload && 
                                                    this.state.csvPayload.fileName ? 
                                                    this.state.csvPayload.fileName : 
                                                    'No file chosen'
                                                }
                                            </span>
                                        </label>
                                    </FieldWrap>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <ul className="file-type-bulletin">
                                        <li>Accepted File Type: .xls, .csv</li>
                                    </ul>
                                </Col>
                            </Row>
                            {
                                this.state.csvPayload && this.state.csvPayload.fileName &&
                                <Row>
                                    <Col
                                        xs={12} 
                                        style={{
                                            marginTop: '10px'
                                        }}
                                    >
                                        <PrimaryButton 
                                            onClick={!this.state.importing ? e => this.postCSVPricingFile(e) : null} 
                                            type="button"
                                            fullWidth={false}
                                            disabled={this.state.importing}
                                        >
                                            Import Pricing 
                                            <span 
                                                className={'glyphicon glyphicon-refresh' + ((this.state.importing && this.state.CSVModal) ? ' spinning' : ' hide')}
                                            />
                                        </PrimaryButton>
                                    </Col>
                                </Row>
                            }
                            {this.state.importError &&
                                <p 
                                    className='error-msg'
                                    style={{
                                        marginTop: '5px'
                                    }}
                                >
                                    {this.state.importError}
                                </p> 
                            }
                            {this.state.templateError &&
                                <p 
                                    className='error-msg'
                                    style={{
                                        marginTop: '5px'
                                    }}
                                >
                                    {this.state.templateError}
                                </p> 
                            }
                        </Modal.Body>
                    </Modal>
                    <Modal className="submit-modal importSuccess" show={this.state.successImportCSVModal} onHide={this.closeSuccessImportCSVModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <span className='status-icon status-icon--success'>
                                </span>
                                <span className="submit-heading">
                                    Import Successful
                                </span>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <PrimaryButton
                                cssClass="pull-right"
                                fullWidth={false} 
                                type="button" 
                                onClick={this.closeSuccessImportCSVModal}
                            >
                                Continue
                            </PrimaryButton>
                        </Modal.Body>
                    </Modal>
                    <Modal 
                        className="import-modal" 
                        show={this.state.importModal} 
                        onHide={this.toggleImportModal}
                        backdrop="static"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Calendar Settings
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FieldWrap>
                                <Row>
                                    <Col xs={12} sm={12}>
                                        <InputLabel>
                                            Calendar Source
                                        </InputLabel>
                                    </Col>
                                </Row>
                                <div className="parallel-radio-container">
                                    {this.createDefaultRadioButton(this.calendarSource, this.state.calendarSource, this.changeCalendarSource)}
                                </div>
                            </FieldWrap>
                            <FieldWrap>
                                    <InputLabel>
                                    Calendar Address
                                </InputLabel>
                                <InputField 
                                    placeholder="Enter iCal URL"  
                                    name="url"
                                    type="text" 
                                    value={this.state.availabilityFeed.url}
                                    onChange={e => this.changeAvailabilityFeed(e)}
                                />
                            </FieldWrap>
                            {
                                this.state.calendarSource === "ical_source_of_truth" &&
                                <FieldWrap>
                                    <InputLabel>
                                        Update Frequency
                                    </InputLabel>
                                    <Picky
                                        placeholder=""
                                        labelKey="label"
                                        valueKey="value"
                                        options={constants.UPDATE_FREQUENCY}
                                        value={this.state.selectedFrequency.label}
                                        multiple={false}
                                        keepOpen={false}
                                        onChange={value => this.changeFrequency(value)}
                                        dropdownHeight={600}
                                        render={({
                                            style,
                                            item,
                                            isSelected,
                                            selectValue,
                                            labelKey,
                                            valueKey,
                                            }) => {
                                                console.log
                                            return (
                                                <li
                                                    style={{ ...style }} 
                                                    className={isSelected ? "selected" : ""} 
                                                    key={item[valueKey]}
                                                    onClick={() => selectValue(item)}
                                                >
                                                    <span
                                                        style={{
                                                        fontWeight: isSelected ? "bold" : "normal"
                                                        }}
                                                    >
                                                        {item[labelKey]}
                                                </span>
                                                </li>
                                            );
                                        }}
                                    />
                                </FieldWrap>
                            }
                            <FieldWrap>
                                <Row>
                                    <Col xs={12} sm={12}>
                                        <InputLabel>
                                            Check-Out Date
                                        </InputLabel>
                                    </Col>
                                </Row>
                                <div className="parallel-radio-container">
                                    {this.createDefaultRadioButton(this.checkOutDate, this.state.availabilityFeed.use_checkout_date, this.changeAvailabilityFeed)}
                                </div>
                            </FieldWrap>
                            <PrimaryButton 
                                onClick={this.importIcal.bind(this)} 
                                type="button" 
                                fullWidth={false}
                                disabled={
                                    (this.state.availabilityFeed && this.state.availabilityFeed.feed_type && !this.state.availabilityFeed.feed_type.length) ||
                                    (this.state.availabilityFeed && this.state.availabilityFeed.url && !this.state.availabilityFeed.url.length) ||
                                    (this.state.availabilityFeed && this.state.availabilityFeed.use_checkout_date && !this.state.availabilityFeed.use_checkout_date.length)
                                }
                            >
                                Save 
                                <span className={'glyphicon glyphicon-refresh' + ((this.state.importing && this.state.importModal) ? ' spinning' : ' hide')} ></span>
                            </PrimaryButton>
                            <p className={this.state.importError ? 'center-text error-msg' : 'hide'}>{this.state.importError}</p>
                        </Modal.Body>
                    </Modal>
                    <Modal className="import-modal" show={this.state.notAvailable} onHide={this.closeAvailable.bind(this)}>
                        <Modal.Body className='importing' style={{ height: '190px' }}>
                            <FieldWrap>
                                <Col xs={12} className="bottom-margin">
                                    <div className="importing-loader submit-modal">
                                        <span className='status-icon status-icon--error' />
                                        <div className='progress-wrapper upload-status'>
                                            <p>Pricing Not Available</p>
                                        </div>
                                        <button className='primary-btn' onClick={this.closeAvailable.bind(this)}>Close</button>
                                    </div>
                                </Col>
                            </FieldWrap>
                        </Modal.Body>
                    </Modal>
                    <Modal className="show-ical-url" show={this.state.showIcalUrlModal} onHide={this.toggleShowIcalUrlModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                ICal Url
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col xs={1}>
                                    <OverlayTrigger 
                                        placement='top' 
                                        overlay={<Tooltip id={'copy'} className='properties__tooltip'>Copy Text</Tooltip>}>
                                        <span
                                            className="show-url-icon-container"
                                            onClick={e => {
                                                e.preventDefault();
                                                const text = `https://api.redawning.com/papi-v1/listings/${activeId}/reservations/ical`;

                                                navigator.clipboard.writeText(text).then(() => {
                                                    this.setState({
                                                        showUrlSuccessMsg: true
                                                    }, () => {
                                                        setTimeout(() => {
                                                            this.setState({
                                                                showUrlSuccessMsg: false
                                                            });
                                                        }, 3000);
                                                    });
                                                });
                                            }}
                                        >
                                            <i className="icon-copy"></i>
                                        </span>
                                    </OverlayTrigger>
                                </Col>
                                <Col xs={11}>
                                    <InputField 
                                        type="text"
                                        name="ical-url" 
                                        value={`https://api.redawning.com/papi-v1/listings/${activeId}/reservations/ical`}
                                        readOnly
                                    />
                                </Col>
                            </Row>
                            {
                                this.state.showUrlSuccessMsg &&
                                <Row>
                                    <Col 
                                        xs={12}
                                        className="success-msg-container"
                                    >
                                        <i className="icon-yes"></i>
                                        <span>
                                            Copied to clipboard.
                                        </span>
                                    </Col>
                                </Row>
                            }
                        </Modal.Body>
                    </Modal>
                    <Modal className="import-modal" show={this.state.importByProperty} onHide={this.toggleImportByProperty}>
                        {this.state.importProcess ?
                            <Modal.Body className="importing">
                                <Col xs={12} className="bottom-margin">
                                    <div className="importing-loader submit-modal">
                                        {progress === 100 ? (this.state.importCompleted.error === 0 ? <span className='status-icon status-icon--success'></span> : <span className='status-icon status-icon--error' />) : <Loader />}
                                        <div className='progress-wrapper upload-status'>
                                            {progress === 100 ? (this.state.importCompleted.error === 0 ? <p>Pricing Imported</p> : <p>{this.state.importCompleted.error} Pricing Not Imported</p>) : <p>Importing Prices...</p>}
                                            <ProgressBar bsStyle={progress === 100 ? (this.state.importCompleted.error === 0 ? 'success' : 'danger') : 'warning'} now={parseInt(progress)} />
                                            <h5 className='progress-percent'>{parseInt(progress)}%</h5>
                                        </div>
                                        {progress === 100 ? (<div className="import-btns">
                                            <button className='primary-btn' onClick={this.updateProperty.bind(this)}>Close</button></div>) : null}
                                    </div>
                                </Col>
                            </Modal.Body>
                            :
                            <div>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        Import Pricing By Property
                                </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <FieldWrap>
                                        <Autosuggest
                                            onSuggestionsFetchRequested={this.fetchPropertiesSuggestions}
                                            suggestions={this.state.suggestions}
                                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                            getSuggestionValue={(event) => event}
                                            renderSuggestion={
                                                (renderSuggestion) => 
                                                    <div>{renderSuggestion.content.title} (ID: {renderSuggestion.property_id}) {renderSuggestion.external_property_id ? `(Reference ID: ${renderSuggestion.external_property_id})` : ''} </div>}
                                            inputProps={inputProps}
                                            onSuggestionSelected={(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => { this.selectProperty(suggestionIndex) }}
                                        />
                                        {this.state.autoLoader ? <div className="auto-suggest-loader">
                                            <Loader />
                                        </div> : null}
                                    </FieldWrap>
                                    <PrimaryButton type="button" fullWidth={false} disabled={!this.state.importId || !this.state.value} onClick={this.importByProperty}>Import</PrimaryButton>
                                </Modal.Body>
                            </div>
                        }
                    </Modal>
                    <AvailableRoomModal 
                        modal={this.state.availableRoomModal} 
                        onHide={this.closeAvailableRoomModal.bind(this)}
                        swapModals={this.swapModals}
                        totalUnits={
                            this.getPropertyById(this.state.availableRoomPropertyId)[0] && 
                            this.getPropertyById(this.state.availableRoomPropertyId)[0].units
                        } 
                        children={this.state.propertyChildren[this.state.availableRoomPropertyId] || []}
                        unAvailableChildren={this.state.unAvailableRooms}  
                    />
                    <Overlay
                        show={this.state.showPopup}
                        rootClose={true}
                        onHide={() => this.setState({ showPopup: false })}
                        placement="bottom"
                    >
                        <Popup {...this.state.popupProps} />
                    </Overlay>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        isInternalPropertyAdmin: state.roleManager.isInternalPropertyAdmin,
        roleManager: state.roleManager,
        user: state.authState.user,
        mobileMode: state.updateMobileState.mobileMode,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setRole: (role) => {
            dispatch(setRole(role));
        },
        updateOrganization: (org) => {
            dispatch(updateOrganization(org))
        },
        updateOrganizationTitle: (org) => {
            dispatch(updateOrganizationTitle(org))
        },
        updateOrganizationId: (orgId) => {
            dispatch(updateOrganizationId(orgId))
        },
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Pricing));
