import React, { Component, Fragment } from 'react';
import { Title } from '../../global/Title';
import { FieldWrap, InputLabel, InputField, PrimaryButton } from '../../global/forms/FormElements';
import Form from 'react-bootstrap/lib/Form';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import Link from 'react-router-dom/Link';
import PanelGroup from 'react-bootstrap/lib/PanelGroup';
import Panel from 'react-bootstrap/lib/Panel';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import Modal from 'react-bootstrap/lib/Modal'
import Loader from '../../global/Loader';
import _ from "lodash";
import { API } from 'aws-amplify';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import moment from 'moment';
import DateRangePicker from 'react-dates/lib/components/DateRangePicker';
import Picky from 'react-picky';
import ProgressBar from 'react-bootstrap/lib/ProgressBar';
import LocalizedStrings from 'react-localization';
import { infoPopover, getSessionId } from '../../../helpers/helpers';

// taken from Underscore.js
// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce(func, wait, immediate) {

	let timeout
	return function() {
		let context = this, args = arguments
		let later = function() {
		timeout = null
		if (!immediate) func.apply(context, args)
		}
		let callNow = immediate && !timeout
		clearTimeout(timeout)
		timeout = setTimeout(later, wait)
		if (callNow) func.apply(context, args)
	}
}

const isExisty = function (value) {
    return value !== null && value !== undefined;
};

const isEmpty = function (value) {
    if (value instanceof Array) {
        return value.length === 0;
    }
    return value === '' || !isExisty(value);
};

const isEmptyTrimed = function (value) {
    if (typeof value === 'string') {
        return value.trim() === '';
    }
    return true;
};

const RuleSetContains = function(object, value) {
	let contains = false;
	if (typeof object ==='object') { 
		contains = object.some(function(rule,idx) {
			return rule.attribute === value;
		});
	}
	return contains;

}

const validations = {
    matchRegexp: (value, regexp) => {
        const validationRegexp = (regexp instanceof RegExp ? regexp : (new RegExp(regexp)));
        return (isEmpty(value) || validationRegexp.test(value));
    },
    // eslint-disable-next-line
    isEmail: value => validations.matchRegexp(value, /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i),
    isEmpty: value => isEmpty(value),
    required: value => !isEmpty(value),
    trim: value => !isEmptyTrimed(value),
    isNumber: value => validations.matchRegexp(value, /^-?[0-9]\d*(\d+)?$/i),
	isFloat: value => validations.matchRegexp(value, /^(?:-?[1-9]\d*|-?0)?(?:\.\d+)?$/i),
    isPositive: (value) => {
        if (isExisty(value)) {
            return (validations.isNumber(value) || validations.isFloat(value)) && value >= 0;
        }
        return true;
    },
    maxNumber: (value, max) => isEmpty(value) || parseInt(value, 10) <= parseInt(max, 10),
    minNumber: (value, min) => isEmpty(value) || parseInt(value, 10) >= parseInt(min, 10),
    maxFloat: (value, max) => isEmpty(value) || parseFloat(value) <= parseFloat(max),
    minFloat: (value, min) => isEmpty(value) || parseFloat(value) >= parseFloat(min),
    isString: value => !isEmpty(value) || typeof value === 'string' || value instanceof String,
    minStringLength: (value, length) => validations.isString(value) && value.length >= length,
    maxStringLength: (value, length) => validations.isString(value) && value.length <= length,
    // eslint-disable-next-line no-undef
    isFile: value => value instanceof File,
    maxFileSize: (value, max) => validations.isFile(value) && value.size <= parseInt(max, 10),
    allowedExtensions: (value, fileTypes) => validations.isFile(value) && fileTypes.split(',').indexOf(value.type) !== -1,
};

let strings = new LocalizedStrings({
	en:{
		promotion_name: "Promotion Name",
		promotion_details: "Promotion Details",
		select_properties: "Select Properties",
		specific_properties_by_organization: "Specific Properties by Organization",
		channel: "Channel",
		channels: "Channels",
		add_channel: "Add Channel",
		segments: "Segments",
		properties:"Properties",
		property: "Property",
		organization: "Organization",
		organizations: "Organizations",
		all_properties: "All Properties",
		field_is_required: "{0} is required.",
		field_optional: "{0} (Optional)",
		fields_are_required: "{0} are required.",
		at_least_one_field_is_required: "At least one {0} is required.",
		create_promotion: "Create Promotion",
		edit_promotion: "Edit Promotion",
		status: "Status",
		clone_promotion: "Clone Promotion",
		accounting_adjustments: "Accounting Adjustments",
		redawning_promotion: "RedAwning Promotion",
		manager_owner_promotion: "Manager/Owner Promotion",
		promotion_configuration: "Promotion Configuration",
		promotion_type: "Promotion Type",
		discount: "Discount",
		nights_free: "Nights Free",
		additional_nights_free: "Night(s) Free",
		additional_nights_is_required: "Additional Night(s) is required",
		discount_type: "Discount Type",
		p_off_per_stay: "% Off per Stay",
		d_off_per_stay: "$ Off per Stay",
		discount_value: "Discount Value",
		discount_value_is_required: "Discount Value is required.",
		minimum_paid_nights: "Minimum Night(s)",
		maximum_paid_nights: "Maximum Night(s)",
		check_in_allowed: "Check-In Allowed",
		check_out_allowed: "Check-Out Allowed",
		book_dates:"Book Dates",
		early_bird: "Early Bird",
		days_before_check_in_date: "Days before Check-In Date",
		book_between: "Book Between",
		last_minute: "Last Minute",
		within_hours_days_of_check_in_time:"Within # Hours/Day(s) of Check-In Time",
		start: "Start",
		end: "End",
		stay_dates: "Stay Dates",
		start_night_of_check_in: "Start (Night of Check-In)",
		end_last_night_of_stay : "End (Last Night of Stay)",
		blackout_dates: "Blackout Dates",
		add_blackout_dates: "Add Blackout Dates",
		promotion_saved: "Promotion Saved",
		saving_promotion: "Saving Promotion",
		updating: "Updating...",
		saving: "Saving...",
		save: "Save",
		cancel:"Cancel",
		view_all_promotions: "View All Promotions",
		continue_editiong: "Continue Editing",
		unable_to_save_the_promotion:"Unable to save the promotion.",
		promotion_name_not_exceed_20_char: "Promotion Name should not exceed 20 characters.",
		// specific error copy
		minimum_paid_nights_must_be_greater_than_additional_nights_free: "Minimum Paid Nights must be greater than Additional Nights Free.",
		maximum_paid_nights_must_be_greater_than_minimum_paid_nights: "Maximum Paid Nights must be greater than Minimum Paid Nights.",
		// channel specific error copy
		bookingcom_book_dates_end_must_be_on_or_before_stay_date_end : "Book Date End must be on or before Stay Date End.",
		bookingcom_requires_early_bird : "Booking.com requires Stay Dates if Book Dates is Early Bird.",
		bookingcom_requires_stay_dates_when_book_between_dates_exist : "Booking.com requires Stay Dates when Book Between Dates exist.",
		bookingcom_cannot_be_before_creation_date: "Days before Check-In Date cannot be before the promotion creation date.",
		bookingcom_does_not_allow_flat_rate: "Booking.com does not allow flat rate discounts.",
		bookingcom_does_not_allow_nights_free: "Booking.com does not allow Nights Free Promotion Type",
		bookingcom_min_max_percent_requirement: "Booking.com requires the discount value to be no less than 10% and no more than 80%",
		airbnb_requires_Early_Bird_promotions_to_be_a_multiple_of_28_or_30_days_not_to_exceed_364_days : "Airbnb requires Early Bird promotions to be a multiple of 28 or 30 days not to exceed 364 days.",
		airbnb_requires_last_minute_promotions_to_be_28_days_or_less: "Airbnb requires Last Minute promotions to be 28 days or less and does not allow hour increments.",
		airbnb_does_not_allow_book_between_promotions: "Airbnb does not allow Book Between promotions.",
		airbnb_requires_stay_dates: "Airbnb requires Stay Dates.",
		airbnb_does_not_allow_blackout_dates : "Airbnb does not allow Blackout Dates.",
		airbnb_requires_discount_value_to_be_an_integer: "Airbnb requires Discount Value to be an integer.",
		// DateRangePicker additional validation checks error copy
		error_invalid_field_date: "Invalid Book Between dates.",
		error_end_before_start : "End date cannot be before start date.",
		error_before_today: "Start date cannot be before today. ie: {0}",
		error_end_same_as_start: "End date cannot be the same as start date.",
		// Expedia error message
		expedia_promotion_type: 'Expedia does not support Nights Free.',
		expedia_discount_type: 'Expedia does not support $ Off per Stay.',
		expedia_discount_value: 'Expedia does not support discount value greater than 75.',
		expedia_minimum_nights: 'Expedia does not support minimum nights greater than 28.',
		expedia_maximum_nights: 'Expedia does not support maximum nights greater than 28.',
		expedia_book_dates: 'Expedia does not support last minute book dates.',
		expedia_stay_dates: 'Expedia requires stay dates be after the start of booking date.',
		expedia_requires_value_to_be_an_interger: "Expedia requires Discount Value to be an integer."
	}
});

const channels_info = [
	{
		channel_name: 'bookingcom',
		dev_id: '9668a083-9214-4652-8ec3-42c110c8b8fa',
		prod_id: '63960b27-a9a5-40b4-a12d-b85cfe46c2e8'
	},
	{
		channel_name: 'airbnb',
		dev_id: '438e8c51-a292-4f65-95bd-2da0d20df0b1',
		prod_id: '77fe67e5-8bfd-4474-9e50-a6943606d3b0'
	},
	{
		channel_name: 'expedia',
		dev_id: 'c067ad13-7a4a-4ac1-9e56-f683f01edee2',
		prod_id: 'a12ee314-a01f-4c69-b41a-966f94e0ddbc'
	},
	{
		channel_name: 'ranative',
		dev_id: '7045f194-7208-43be-ac2e-9ac2aedbda96',
		prod_id: 'a1776ff9-063d-49ac-a427-544ad7283b58'
	},
	{
		channel_name: 'rawebsite',
		dev_id: '02f6aa76-5fdf-43b9-81c4-5048bdabb551',
		prod_id: 'a84fc212-3b04-463c-ba67-b10cf526200a'
	},
	{
		channel_name: 'tpronet',
		dev_id: '001f22f7-e652-4ce6-9faf-ca4a385acb5e',
		prod_id: 'c5073ee1-2355-447d-bfc5-83cb4888f657'
	},
	{
		channel_name: 'property_manager',
		dev_id: 'property_manager',
		prod_id: 'property_manager'
	}
]

const defaultValidation = {
	formValid: true,
	name: false,
	nameErrorMessage:false,
	details: false,
	detailsErrorMessage:false,
	organizations: false,
	organizationsErrorMessage:false,
	properties: false,
	propertiesErrorMessage:false,
	channels: false,
	channelsErrorMessage:false,
	discountType: false,
	discountTypeErrorMessage: false,
	promotionName: false,
	promotionNameErrorMessage: false,
	promotionType: false,
	promotionTypeErrorMessage: false,
	discountValue: false,
	discountValueErrorMessage:false,
	minimumNights: false,
	minimumNightsErrorMessage:false,
	maximumNights: false,
	maximumNightsErrorMessage:false,
	bookdays: false,
	bookdaysErrorMessage:false,
	bookdates: false,
	bookdatesErrorMessage: false,
	bookhours: false,
	bookhoursErrorMessage:false,
	check_in_allowed: false,
	check_in_allowedErrorMessage:false,
	check_out_allowed: false,
	check_out_allowedErrorMessage:false,
	excludeddates:false,
	excludeddatesErrorMessage:false,
	errorMessage: false,
	expediaPromotionType: false,
	expediaDiscountType: false,
	expediaDiscountValue: false,
	expediaDiscountValueInteger: false,
	expediaMinimumNights: false,
	expediaMaximumNights: false,
	expediaBookDates: false,
	expediaStayDates: false,
	bookingcomMinMaxValue: false,
	bookingcomMinMaxValueErrorMessage: false,
}

class PromotionsForm extends Component {
	constructor(props) {
		super(props);
		this.error = false;
		this.progress = 0;
		this.dirty = true;
		this.initPromotionData = {...this.props.promotionData};
        this.state = {
			airbnbSelected: false,
			bookingcomSelected: false,
			expediaSelected: false,
			bookingcomMobileOnly: false,
			days: Array(365).fill(null),
			minnights: Array(50).fill(null),
			maxnights: Array(50).fill(null),
			bookingdaysmap: Array(365).fill(null),
			bookinghoursmap: Array(37).fill(0).map((e,i)=>i+1).map(function(k,v){
				if(v <=5) {
					return {key:v, value: (v*4), title: (v*4)+" hours"}
				} else if (v==6){
					return {key:v, value: (v*4), title: "1 day"}
				} else if(v==7){
					return {key:v, value: 36, title: "36 hours"}
				} else {
					return {key:v, value: (24 * (v - 6)), title: (v - 6) +" days"}
				}
			}).slice(1,37),
			bookDateType: 'BOOKDAYS',
			startDate: null,
			endDate: null,
			focusedInput: null,
			stayStartDate:null,
			stayEndDate: null,
			stayFocusedInput: null,
			excludeStartDate: null,
			excludeEndDate: null,
			excludeFocusedInput: null,
			currentExcludedField: null,
			excludeFields: [],
			channelSelected: null, 
			selectableChannles: [],
			check_in_allowed: {
				'monday': true,
				'tuesday': true,
				'wednesday': true,
				'thursday': true,
				'friday': true,
				'saturday': true,
				'sunday': true
			},
			check_out_allowed: {
				'monday': true,
				'tuesday': true,
				'wednesday': true,
				'thursday': true,
				'friday': true,
				'saturday': true,
				'sunday': true
			},
			mode: this.props.mode,
			org: this.props.org,
			roles: this.props.roles,
			tid: 0,
			isLoading: false,
			propertyLoader:false,
			init: true,
			activeKey: '1',
			requiredValidation: Object.assign({}, defaultValidation),
			promotionData: {...this.props.promotionData},
			properties:[],
			channels: [],
			segmentList:[],
			showModal: false,
			error: false,
			progress: 0,
			errorMessage: false,
			status: 'live',
		}
	}

	async componentDidMount() {
		this.init();
		this.loadChannels();
		this.getProperties();
	}

	init = () => {
		const { 
			promotionData,
		} = this.state;
		let bookDateType = 'BOOKDAYS';
		let excludeFields = [];
		let startDate = null;
		let endDate = null;
		let stayStartDate = null;
		let stayEndDate = null;
		let bookingcomMobileOnly = false;
		let check_in_allowed = {
			'monday': true,
			'tuesday': true,
			'wednesday': true,
			'thursday': true,
			'friday': true,
			'saturday': true,
			'sunday': true
		};
		let check_out_allowed = {
			'monday': true,
			'tuesday': true,
			'wednesday': true,
			'thursday': true,
			'friday': true,
			'saturday': true,
			'sunday': true
		};

		promotionData.rule_set.rules.map((rule) => {
			let dateTypeRules = ['BOOKDAYS','BOOKDATES','BOOKHOURS'];
			if(dateTypeRules.includes(rule.attribute)) {
				bookDateType = rule.attribute;			
			};
		});

		promotionData.rule_set.rules.map((rule) => {
			let dateTypeRules = ['EXCLUDEDDATES'];

			if(dateTypeRules.includes(rule.attribute)) {
				let dates = rule.value.split('|');
				excludeFields.push({
					"value": rule.value,
					"startDate": moment(dates[0], "YYYY-MM-DD"), 
					"endDate": moment(dates[1], "YYYY-MM-DD"), 
					"focusedInput": null
				})
			};
		});

		promotionData.segments.map((segment) => {
			const bookingcomChannel = channels_info.find(channel => channel.channel_name === 'bookingcom');

			if((bookingcomChannel.dev_id == segment.organization_id || 
				bookingcomChannel.prod_id == segment.organization_id) &&
				(segment.identifier == 'app' || segment.identifier == 'all')
			) {
				bookingcomMobileOnly = true;			
			};
		});

		if(bookDateType == 'BOOKDATES') {
			let bookdates = this.getRuleValue('BOOKDATES').split('|');
			startDate = moment(bookdates[0], "YYYY-MM-DD");
			endDate = moment(bookdates[1], "YYYY-MM-DD");
		};

		let staydates = this.getRuleValue('STAYDATES').split('|');
		if (staydates.length > 1) { 
			stayStartDate = moment(staydates[0], "YYYY-MM-DD");
			stayEndDate = moment(staydates[1], "YYYY-MM-DD");
		};
		
		if (this.getRuleValue('CHECKINALLOWED') !="") { 
			let checkInAllowed = this.getRuleValue('CHECKINALLOWED').split(',');
			Object.entries(check_in_allowed).map((day, id)=>{
				if(checkInAllowed.includes(id.toString())) {
					check_in_allowed[day[0]] = true;
				} else {
					check_in_allowed[day[0]] = false;
				};
			});
		};

		if (this.getRuleValue('CHECKOUTALLOWED') !="") { 
			let checkOutAllowed = this.getRuleValue('CHECKOUTALLOWED').split(','); 
			Object.entries(check_out_allowed).map((day,id)=>{
				if(checkOutAllowed.includes(id.toString())) {
					check_out_allowed[day[0]] = true;
				} else {
					check_out_allowed[day[0]] = false;
				};
			});
		};

		this.setState({
			bookDateType: bookDateType,
			excludeFields: excludeFields,
			startDate: startDate,
			endDate: endDate,
			stayStartDate: stayStartDate,
			stayEndDate: stayEndDate,
			check_in_allowed: check_in_allowed,
			check_out_allowed: check_out_allowed,
			bookingcomMobileOnly: bookingcomMobileOnly,
		});
	}
	
	handleOnChange = (e) => {
		e.preventDefault();
		const { promotionData } = this.state;
		const name = e.target.name;
		const value = e.target.value;

		if(name == 'name' || name == 'details') {
			this.setState({
				promotionData: {
					...promotionData,
					[name]: value
				}
			}, () => {
				const { requiredValidation } = this.state;

				if(requiredValidation.name || requiredValidation.details) {
					const newValidation = this.handleFormValidation();

					this.setState({
						requiredValidation: newValidation
					});
				};
			})
		};
	}

    handleChangeStatus = () => {
		const { promotionData } = this.state;
		let status = promotionData.status != 'LIVE' ? 'LIVE' : 'NOTLIVE';

		this.setState({
			promotionData: {
				...promotionData,
				status: status
			}
		});
	}

	addUpdatePromotion = async () => {
		const { 
			mode,
			promotionData,
		} = this.state;

		this.showModal('submit-modal');

        this.setState({
			progressMessage: mode === 'edit' ? strings.updating : strings.saving,
			error: false,
			progress: 10,
			progressStyle:"success"
		});
	
	   	let copyPromotionData = _.cloneDeep(promotionData);

		let organizationMap = [];
		let promoData;

		copyPromotionData.organizations.forEach(function(organization){
			organizationMap.push({"organization_id" : organization});
		});

		let channelsMap = [];    
		copyPromotionData.channels.forEach(function(channel){
			channelsMap.push({"organization_id" : channel});
		});

		let propertiesMap = [];    
		copyPromotionData.properties.forEach(function(property){
			propertiesMap.push({"property_id" : property});
		});

		copyPromotionData.organizations = organizationMap;
		copyPromotionData.channels = channelsMap;
		copyPromotionData.properties = propertiesMap;

		delete copyPromotionData.changeset;
		delete copyPromotionData.evaluation;
		
        try {
			// Create Promotions
            if(mode === 'add') {
				promoData = await API.post("rapapi", `/promotions`, { "body": copyPromotionData });
				
				this.setState({
					mode: 'edit',
					promotionData: {
						...promoData
					}
				});
			}
			// Edit Promotions
			else {
				promoData = await API.put("rapapi", `/promotion/${copyPromotionData.id}`, { "body": copyPromotionData });
			};

			this.initPromotionData = {...promoData};
        } catch (err) {
			this.setState({
				error: true,
				errorMessage: strings.unable_to_save_the_promotion,
				progressStyle: "danger",
			});
		} 

		this.setState({ progress: 100 });	
	} 
	
	/**
	 * Handle collapsible sections of forms.
	 */
	handleSelect = () => {
		if (this.state.init) {
			this.setState({
				init: false
			})
		};
	}

	toggleGlyph = (e, selectedKey) => {
		e.preventDefault();
		const { activeKey, init } = this.state;

		if (activeKey !== selectedKey && !init) {
  			this.setState({
                activeKey: selectedKey
            });
		}
		else {
            this.setState({
				activeKey: null
			});
		};
	}

	handleFormValidation = () => {
		const { 
			promotionData,
			bookDateType
		} = this.state;

		let requiredValidation = {...defaultValidation};
		const airbnbNewListingPromo = promotionData.segments.filter(segment => segment.identifier == 'NEW_LISTING_PROMOTION');

		if (validations.isEmpty(promotionData.name) || !validations.maxStringLength(promotionData.name, 68)) {
			requiredValidation.name = true;
			requiredValidation.promotionNameErrorMessage = strings.promotion_name_not_exceed_20_char;
		};

		// Promotion Details - required - Max 255 chars
		if (validations.isEmpty(promotionData.details) || !validations.maxStringLength(promotionData.details, 255)) {
			requiredValidation.details = true;
		};

		// Promotion Organizations - required 
		if (!validations.required(promotionData.organizations)){
			requiredValidation.organizations = true;
		};

		// Promotion Properties - required 
		if (!validations.required(promotionData.properties)){
			requiredValidation.properties = true;
		};

		// Promotion Channels - required
		if (!validations.required(promotionData.channels)){
			requiredValidation.channels = true;
		};

		// Segments  are optional
		// Promo types req 1 should always be set
		// Discount types req 1 should always be set
		// DiscountValue value req ('percent','amount','compday') 1 of type number based on percent , amount or compday
		if (!validations.required(promotionData.value)){
			requiredValidation.discountValue = true;
		};

		// if promo type is Nights Free the discount required value is [number minimum 1] night
		if (promotionData.type =='NF') {
			if (!validations.minNumber(promotionData.value, 1)){
				requiredValidation.discountValue = true;
			};

			if (!validations.isNumber(promotionData.value)){
				requiredValidation.discountValue = true;
			};
		};

		// Default Discount
		if (promotionData.type =='DD') {
			// Should be set to percent or amount
			if (promotionData.discount =='percent') {
				// Percent value can be number | int or float with a value greater then 1
				if (!validations.isNumber(promotionData.value) && !validations.isFloat(promotionData.value)){
					requiredValidation.discountValue = true;
				};

				if (!validations.minNumber(promotionData.value, 1)){
					requiredValidation.discountValue = true;
				};
			} 
			else {
				// Amount value must be float
				if (!validations.minNumber(promotionData.value, 1)){
					requiredValidation.discountValue = true;
				};
				
				if (!validations.isFloat(promotionData.value)){
					requiredValidation.discountValue = true;
				};

				if(!validations.isPositive(promotionData.value)){
					requiredValidation.discountValue = true;	
				};
			}
		};

		// If this is a Nights Free promotion - MINIMUMNIGHTS is greater then Nights Free
		if (promotionData.type =='NF') {
			// MINIMUMNIGHTS should be greater then nights free
			if (!validations.minNumber(this.getRuleValue('MINIMUMNIGHTS'), parseInt(promotionData.value) + 1)){
				requiredValidation.minimumNights = true;
				requiredValidation.minimumNightsErrorMessage =  strings.minimum_paid_nights_must_be_greater_than_additional_nights_free;
			};
		};

		// MINIMUMNIGHTS is required, form field defaults to 1
		if (!validations.minNumber(this.getRuleValue('MINIMUMNIGHTS'), 1)){
			requiredValidation.minimumNights = true;
		};

		// Maximum nights is optional
		if (this.getRuleValue('MAXIMUMNIGHTS')) {
			// MAXIMUMNIGHTS should be greater then or equal to MINIMUMNIGHTS
			if (!validations.minNumber(this.getRuleValue('MAXIMUMNIGHTS'), parseInt(this.getRuleValue('MINIMUMNIGHTS')) + 1)) {
				requiredValidation.maximumNights = true;
				requiredValidation.maximumNightsErrorMessage = strings.maximum_paid_nights_must_be_greater_than_minimum_paid_nights;
			};
		}

		// Must allow ate least one Check-In Allowed day
		if (validations.isEmpty(this.getRuleValue('CHECKINALLOWED'))) {
			requiredValidation.check_in_allowed = true;
		};

		// Must allow at least one Check-Out Allowed day
		if (validations.isEmpty(this.getRuleValue('CHECKOUTALLOWED'))) {
			requiredValidation.check_out_allowed = true
		};

		// BOOKING DATES
		// Early Bird
		if (bookDateType === 'BOOKDAYS' && !airbnbNewListingPromo.length) {
			let value = this.getRuleValue('BOOKDAYS');

			if (!validations.required(value)) {
				requiredValidation.bookdays = true;
			};
			if (!validations.minNumber(value, 1)){
				requiredValidation.bookdays = true;
			};
		};

		// Book Between
		if (bookDateType === 'BOOKDATES' && !airbnbNewListingPromo.length) {
			let value = this.getRuleValue('BOOKDATES').split('|');
			if (!moment(value[0], "YYYY-MM-DD", true).isValid() || !moment(value[1], "YYYY-MM-DD", true).isValid() ){
				requiredValidation.bookdates = true;
			};
		};

		// Last Minute
		if (bookDateType=== 'BOOKHOURS' && !airbnbNewListingPromo.length) {
			let value = this.getRuleValue('BOOKHOURS')
			if (!validations.required(value)) {
				requiredValidation.bookhours = true;
			};
			if (!validations.minNumber(value, 1)){
				requiredValidation.bookhours= true;
			};
		};

		// STAY Dates are optional - Validate if they exist
		let staydates = this.getRuleValue('STAYDATES').split('|'); 
		if (staydates.length > 1) { 
			if (!moment(staydates[0], "YYYY-MM-DD", true).isValid() || !moment(staydates[1], "YYYY-MM-DD", true).isValid()) {
				requiredValidation.staydates = true;
			};
		};

		return requiredValidation;
	}

	channelValidations () {
		const {
			mode,
			promotionData,
			bookDateType,
			bookingcomMobileOnly
		} = this.state;
		let staydates = this.getRuleValue('STAYDATES').split('|');
		const airbnbNewListingPromo = promotionData.segments.filter(segment => segment.identifier == 'NEW_LISTING_PROMOTION');
		let requiredValidation = {};
		let currentDate = moment();

		channels_info.forEach((channel_info) => {
			promotionData.channels.forEach((assigned_channel) => {
				// Bookingcom validations
				if(	channel_info.channel_name == 'bookingcom' && (_.isEqual(assigned_channel, channel_info.dev_id) || _.isEqual(assigned_channel, channel_info.prod_id)) && !airbnbNewListingPromo.length) {
					// Book Between | BOOKDATES
					if (bookDateType === 'BOOKDATES') {
						if (staydates.length > 1) {
							let bookdates = this.getRuleValue('BOOKDATES').split('|');
							let bookend = moment(bookdates[1], "YYYY-MM-DD", true);
							let stayend = moment(staydates[1], "YYYY-MM-DD", true);
							if(bookend > stayend) {
								requiredValidation.bookdates = true;
								requiredValidation.bookdatesErrorMessage = strings.bookingcom_book_dates_end_must_be_on_or_before_stay_date_end;
							};
						}
						else {
							// Book Between requires stay dates.
							requiredValidation.staydates = true
							requiredValidation.staydatesErrorMessage = strings.bookingcom_requires_stay_dates_when_book_between_dates_exist;
						};
					};

					// End Book Between promotion validation for booking.com
					if(bookDateType === 'BOOKDAYS') {
						let staystart = moment(staydates[0], "YYYY-MM-DD", true);
						// Early Bird requires stay dates.
						if((staydates.length < 2)) {
							requiredValidation.staydates = true;
							requiredValidation.staydatesErrorMessage = strings.bookingcom_requires_early_bird;
						};
						// Days Before Check-In Validation
						if(mode =='add') {
							if(this.getRuleValue('BOOKDAYS') > staystart.diff(currentDate, 'days')) {
								requiredValidation.bookdays = true;
								requiredValidation.bookdaysErrorMessage = strings.bookingcom_cannot_be_before_creation_date;
							};
						}
						else {
							if(this.getRuleValue('BOOKDAYS') > staystart.diff(moment(promotionData && promotionData.created_at), 'days')) {
								requiredValidation.bookdays = true;
								requiredValidation.bookdaysErrorMessage = strings.bookingcom_cannot_be_before_creation_date;
							};
						};
					};

					if(promotionData.discount === "amount") {
						requiredValidation.discountType = true;
						requiredValidation.discountTypeErrorMessage = strings.bookingcom_does_not_allow_flat_rate;
					};

					if(promotionData.type === "NF") {
						requiredValidation.promotionType = true;
						requiredValidation.promotionTypeErrorMessage = strings.bookingcom_does_not_allow_nights_free;
					};

					// The Discount Value cannot be less than 10% or more than 80%
					if((_.toNumber(promotionData.value) < 10 || _.toNumber(promotionData.value) > 80) && promotionData.discount == 'percent' && bookingcomMobileOnly == true) {
						requiredValidation.bookingcomMinMaxValue = true;
						requiredValidation.bookingcomMinMaxValueErrorMessage = strings.bookingcom_min_max_percent_requirement;
					};
				};

				// Airbnb validations
				if(channel_info.channel_name == 'airbnb' && (_.isEqual(assigned_channel, channel_info.dev_id) || _.isEqual(assigned_channel, channel_info.prod_id)) && !airbnbNewListingPromo.length) {
					let verify = function(days, multipleof){
						let modulusremainder = (days % multipleof);
		
						if(modulusremainder ==0) {
							return true;
						};
		
						return false;
					};
						
					// EarlyBird Validation. Must be (multiple of 28, or 30) up to 364   
					if (bookDateType === 'BOOKDAYS') {
						let days = this.getRuleValue('BOOKDAYS')
						let failed = (!verify(days, 28) && !verify(days, 30))
						// currently we only allow 365 which is addressed in the varify above, however if we ever allow more
						// this would break our validation so I'm adding days > 365 to avoid future breakage.
						if(failed || days > 364){
							requiredValidation.bookdays = true;
							requiredValidation.bookdaysErrorMessage = strings.airbnb_requires_Early_Bird_promotions_to_be_a_multiple_of_28_or_30_days_not_to_exceed_364_days;
						};
					};
					// Last Minute. Must be full days with a max of 28 days
					if (bookDateType=== 'BOOKHOURS') {
						let hours = this.getRuleValue('BOOKHOURS')
						let failed = !verify(hours, 24)
						if (failed || hours > (24*28)) {
							requiredValidation.bookhours = true;
							requiredValidation.bookhoursErrorMessage = strings.airbnb_requires_last_minute_promotions_to_be_28_days_or_less;
						};
					};
					// Book Between promotion | BOOKDATES
					if (bookDateType === 'BOOKDATES') {
						requiredValidation.bookdates = true;
						requiredValidation.bookdatesErrorMessage = strings.airbnb_does_not_allow_book_between_promotions;
					};
		
					// Staydates are required
					if(!RuleSetContains(promotionData.rule_set.rules, 'STAYDATES')) {
						requiredValidation.staydates = true;
						requiredValidation.staydatesErrorMessage = strings.airbnb_requires_stay_dates;
					};
		
					// Blackout Dates are not allowed
					if(RuleSetContains(promotionData.rule_set.rules, 'EXCLUDEDDATES')) {
						requiredValidation.excludeddates = true;
						requiredValidation.excludeddatesErrorMessage = strings.airbnb_does_not_allow_blackout_dates;
					};
					
					if (!validations.isNumber(promotionData.value)){
						requiredValidation.discountValue = true;
						requiredValidation.discountValueErrorMessage = strings.airbnb_requires_discount_value_to_be_an_integer;
					};
				};

				// Expedia Validations
				if(channel_info.channel_name == 'expedia' && (_.isEqual(assigned_channel, channel_info.dev_id) || _.isEqual(assigned_channel, channel_info.prod_id))) {
					if(promotionData.type !== 'DD') {
						requiredValidation.expediaPromotionType = true;
					};
		
					if(promotionData.discount !== 'percent') {
						requiredValidation.expediaDiscountType = true;
					};
		
					if(promotionData.value > 75) {
						requiredValidation.expediaDiscountValue = true;
					};
	
					// Check Discount Value to only accept integers
					if(promotionData.value % 1 !== 0) {
						requiredValidation.expediaDiscountValueInteger = true;
						requiredValidation.discountValueErrorMessage = strings.expedia_requires_value_to_be_an_interger;
					};
	
					// Check Book Type
					let bookType = promotionData.rule_set.rules.filter((x) => {
						return x.attribute === 'BOOKDAYS' || x.attribute === 'BOOKDATES' || x.attribute === 'BOOKHOURS';
					});
	
					// Check Stay Dates
					let stayDates = promotionData.rule_set.rules.filter((x) => {
						return x.attribute === 'STAYDATES';
					});
	
					if(promotionData.rule_set.rules.length) {
						promotionData.rule_set.rules.map((rule) => {
							if(rule.attribute === 'MINIMUMNIGHTS' && rule.value > 28) {
								requiredValidation.expediaMinimumNights = true;
							};
		
							if(rule.attribute === 'MAXIMUMNIGHTS' && rule.value > 28) {
								requiredValidation.expediaMaximumNights = true;
							};
		
							if(rule.attribute === 'BOOKHOURS') {
								requiredValidation.expediaBookDates = true;
							};
		
							if((bookType && bookType[0] && bookType[0].attribute === 'BOOKDATES') && rule.attribute === 'STAYDATES') {
								let book_dates = bookType[0].value.split('|');
								let book_dates_startDate = book_dates[0];
	
								let stay_dates = stayDates[0].value.split('|');
								let stay_dates_startDate = stay_dates[0];
	
								if(moment(book_dates_startDate).isAfter(moment(stay_dates_startDate))) {
									requiredValidation.expediaStayDates = true;
								};
							};
						});
					};
				}
			});
		});

		return requiredValidation;
	}

	/**
	 * Handle form submit.
	 */
	
	handleFormSubmit = (e) => {
		e.preventDefault();
		const { 
			promotionData,
		} = this.state;
		const airbnbNewListingPromo = promotionData.segments.filter(segment => segment.identifier == 'NEW_LISTING_PROMOTION');
		const bookingcomSegmentAll = promotionData.segments.filter(segment => segment.identifier == 'all');
		let copyPromotionData = _.cloneDeep(promotionData);

		this.setState({
			error: false,
			errorMessage: "",
		});

		// Remove selected segments that do not have an assigned channel
		let remove = [];
		promotionData.segments.some((assigned) => {
			// Remove 'App' segment if Mobile/Tablet Browser segment exist
			if(assigned.identifier == 'app' && bookingcomSegmentAll.length) {
				remove.push(assigned);
			}
			else if(!promotionData.channels.includes(assigned.organization_id)) {
				remove.push(assigned);
			};
		});

		remove.forEach((segment) => {
			let idx = promotionData.segments.findIndex((current) => {
				if(current.segment_id == segment.segment_id) {
					return true;
				};
			});
			copyPromotionData.segments.splice(idx, 1);
		});

		// Remove selected rules if airbnb new listing is selected
		if(airbnbNewListingPromo.length) {
			const filteredRules = promotionData.rule_set.rules.filter((rule) => {
				return rule.attribute === 'CHECKINALLOWED' || rule.attribute === 'CHECKOUTALLOWED';
			});
			
			const transformedRules = filteredRules.map((rule) => {
				return {...rule, value: '0,1,2,3,4,5,6'};
			});

			copyPromotionData.rule_set.rules = transformedRules;
		};

		// Form Validations
		let requiredValidation = this.handleFormValidation();

		// Channel Validations
		let channelValidation = this.channelValidations();

		// Combine Form and Channel Validations
		requiredValidation = {...requiredValidation, ...channelValidation};

		// We will set requiredValidation.formValid to false if any field value in requiredValidation is true with the exception of the formValid value.
		for (let [key, value] of Object.entries(requiredValidation)) {
			if (key !='formValid' && value == true) {
				requiredValidation.formValid = false;
			};
		};

		this.setState({
			promotionData: {...copyPromotionData},
			requiredValidation: {...requiredValidation}
		}, () => {
			// Form is not valid
			if (!requiredValidation.formValid) {
				this.setState({
					error: true,
					errorMessage: requiredValidation.errorMessage ? requiredValidation.errorMessage : strings.unable_to_save_the_promotion,
					progressStyle: "danger",
					progress: 100
				}, async () => {
					let errorField = document.getElementsByClassName('form-validation').item(0);
					setTimeout(() => {
						errorField.scrollIntoView({
							block: "center",
							behavior: "smooth"
						});
					}, 500);
				});

				return;
			}
			// Form is Valid
			else {
				this.addUpdatePromotion();
			};
		});
	}

	toggleBookingcomMobileOnly = (segment) => {
		const { bookingcomMobileOnly } = this.state;

		this.setState({
			bookingcomMobileOnly: !bookingcomMobileOnly
		}, () => {
			const { 
				promotionData,
				bookingcomMobileOnly
			} = this.state;
			let copyPromotionData = _.cloneDeep(promotionData);

			if(bookingcomMobileOnly) {
				// Remove Logged out and Logged in for Bookingcom if Mobile/Tablet Browser is selected
				let filterOutLoggedInOut = promotionData.segments.filter(x => (x.identifier != 'subscribers' && x.identifier != 'public'));
				copyPromotionData.segments = filterOutLoggedInOut;

				copyPromotionData.segments.push({
					identifier: segment.identifier, 
					organization_id: segment.organization_id, 
					segment_id: segment.id
				});
			}
			else {
				// Remove Mobile/Tablet Browser for Bookingcom if Logged in/out is selected
				let filterOutMobileTablet = promotionData.segments.filter(x => x.identifier != 'all');
				copyPromotionData.segments = filterOutMobileTablet;

				promotionData.segments.some((assigned, idx) => {
					if (segment.id == assigned.segment_id) {
						copyPromotionData.segments.splice(idx, 1)
					};
				});
			};
	
			this.setState({
				promotionData: copyPromotionData
			});
		});
	}

	createBookingcomSegments = (channel) => {
		const { 
			segmentList,
			promotionData,
			bookingcomMobileOnly
		} = this.state;
		const bookingcomChannel = channels_info.find(channel => channel.channel_name === 'bookingcom');
		const bookingcomSegments = segmentList.filter(segment => segment.segment_id && segment.organization_id === channel);
		const bookingcomSegmentApp = segmentList.filter(segment => segment.identifier == 'app');
		const bookingcomSegmentloggedAssigned = promotionData.segments.filter(segment => 
			(bookingcomChannel.dev_id == segment.organization_id || bookingcomChannel.prod_id == segment.organization_id) &&
			(segment.identifier == 'subscribers' || segment.identifier == 'public'));

		if(bookingcomChannel.dev_id == channel || bookingcomChannel.prod_id == channel) {
			return (
				<Fragment>
					{
						bookingcomSegments.map((segment) => {
							if(segment.identifier == 'subscribers' || segment.identifier == 'public') {
								let disabled = bookingcomMobileOnly;

								return this.createSegment(segment, disabled);
							};
						})
					}
					<Row>
						<Col md={6}>
							<InputField
								cssClass="form-check-label checkbox-label"
								type="checkbox"
								name='bookingcomMobileOnly'
								value={bookingcomMobileOnly}
								cbChange={() => this.toggleBookingcomMobileOnly(bookingcomSegmentApp.length && bookingcomSegmentApp[0])}
								disabled={bookingcomSegmentloggedAssigned.length}
							>
								Mobile Only
							</InputField>
						</Col>
					</Row>
					{
						bookingcomMobileOnly &&
						bookingcomSegments.map((segment, i) => {
							if(segment.identifier == 'app' || segment.identifier == 'all') {
								let disabled = false;
								
								if(segment.identifier == 'app') {
									disabled = true;
								}
								else if(segment.identifier == 'all' && bookingcomSegmentloggedAssigned.length) {
									disabled = true;
								};

								return (
									<div className='left-margin' key={i}>
										{this.createSegment(segment, disabled)}
									</div>
								);
							};
						})
					}
				</Fragment>
			)
		};
	}

	createSegments = () => {
		const { 
			segmentList,
			channelSelected
		} = this.state;
		let compileList = [];

		if (segmentList.length > 0) { 
			segmentList.forEach((isSegment) => {
				if(Object.keys(isSegment).concat().includes('segment_id')) {
					const bookingcomChannel = channels_info.find(x => x.channel_name === 'bookingcom');

					if( channelSelected !='undefined' && 
						channelSelected == isSegment.organization_id &&
						(bookingcomChannel.dev_id !== isSegment.organization_id && bookingcomChannel.prod_id !== isSegment.organization_id)
					){
						compileList.push(this.createSegment(isSegment));
					};
				}
			});
		};

		return compileList;
	}

	createAssignedSegments = (channel) => {
		const { 
			segmentList
		} = this.state;
		let compileAssignedList = [];

		if (segmentList.length > 0) { 
			segmentList.forEach((isSegment) => {
				if(Object.keys(isSegment).concat().includes('segment_id')) {
					const bookingcomChannel = channels_info.find(channel => channel.channel_name === 'bookingcom');

					if( channel !='undefined' && 
						channel == isSegment.organization_id &&
						(bookingcomChannel.dev_id !== isSegment.organization_id && bookingcomChannel.prod_id !== isSegment.organization_id)
					){
						compileAssignedList.push(this.createSegment(isSegment));
					};
				};
			});
		};

		return compileAssignedList;
	}

	createSegment = (label, disabled) => (
		<Row key={"row-segment-id"+label['id']}>
			<Col md={6} key={"col-segment-id"+label['id']}>
				<InputField
					cssClass="form-check-label checkbox-label"
					type="checkbox"
					name={label['title']}
					value={this.segmentChecked(label)}
					label={label['title']}
					cbChange={e => {this.toggleSegments(e, label)}}
					key={label['id']}
					disabled={disabled}
				>
					{label['title']}
				</InputField>
			</Col>
		</Row>
	)

	segmentChecked = (segment) => {
		const { 
			promotionData,
			bookingcomMobileOnly
		} = this.state;
		let checked = false;

		if(segment.identifier == 'app' && bookingcomMobileOnly) {
			checked = true;
		}
		else {
			if(promotionData.segments.length > 0) { 
				promotionData.segments.forEach((assigned) => {
					if(segment.id == assigned.segment_id) {
						checked = true;
						return;
					};
				});
			};
		}
		
		return checked;
	}
	
	toggleSegments = (e, segment) => {
		const { 
			promotionData,
			segmentList
		} = this.state;
		const bookingcomChannel = channels_info.find(channel => channel.channel_name === 'bookingcom');
		const bookingcomSegmentApp = segmentList.filter(segment => segment.identifier == 'app');
		const airbnbChannel = channels_info.find(channel => channel.channel_name === 'airbnb');
		let copyPromotionData = _.cloneDeep(promotionData);
		let assignedTo = false;

		promotionData.segments.some((assigned, idx) => {
			// Add 'App' segment if 'Mobile/Table Browser' is unselected
			if(assigned.identifier == 'all' && segment.identifier == 'all') {
				copyPromotionData.segments.splice(idx, 1);

				if(bookingcomSegmentApp.length) {
					copyPromotionData.segments.push({
						identifier: _.get(bookingcomSegmentApp, '[0]identifier'), 
						organization_id: _.get(bookingcomSegmentApp, '[0]organization_id'),
						segment_id: _.get(bookingcomSegmentApp, '[0]id'),
					});
				};

				assignedTo = true;
			}
			else if (segment.id == assigned.segment_id) {
				copyPromotionData.segments.splice(idx, 1);
				assignedTo = true;
			};
		});

		if (!assignedTo) {
			// Configure promotions segements when New Listing Promotion segment is selected
			if((airbnbChannel.dev_id == segment.organization_id || 
				airbnbChannel.prod_id == segment.organization_id) &&
				(segment.identifier == 'NEW_LISTING_PROMOTION')
			) {
				copyPromotionData.type = 'DD';
				copyPromotionData.discount = 'percent';
				copyPromotionData.value = '20';
			};

			// Configure promotions segments when Mobile/Tablet Browser is selected
			if((bookingcomChannel.dev_id == segment.organization_id || 
				bookingcomChannel.prod_id == segment.organization_id) &&
				(segment.identifier == 'all')
			) {
				promotionData.segments.some((assigned, idx) => {
					if (assigned.identifier == 'app') {
						copyPromotionData.segments.splice(idx, 1);
					};
				});
			};

			copyPromotionData.segments.push({
				identifier: segment.identifier, 
				organization_id: segment.organization_id, 
				segment_id: segment.id
			});
		};

		this.setState({
			promotionData: copyPromotionData
		}, () => {
			const { requiredValidation } = this.state;

			if(requiredValidation.bookdays || requiredValidation.bookdates || requiredValidation.bookhours) {
				const newValidation = this.handleFormValidation();

				this.setState({
					requiredValidation: newValidation
				});
			};
		});
	}

	loadChannels = async () => {
		const {
			channels,
			promotionData
		} = this.state;
		
		this.setState({ 			
			isLoading: true
		}) 
		
		let channelsResp = [];
		try { 
			channelsResp = await API.get("rapapi", `/promotion/channels`, { response: true });
		} catch(e) {
		};

		let segmentsResp = [];
		try { 
			segmentsResp = await API.get("rapapi", `/promotion/segments`, { response: true });
		} catch(e) {
		};

		let segmentList = [];
		let selectableChannles = [];
		if (channelsResp.data.list.length > 0) {
			if (segmentsResp.data.list.length > 0) {

				this.segments = segmentsResp.data.list;

				channelsResp.data.list.sort(function(a, b) {
					return a.title.localeCompare(b.title)
				});

				channelsResp.data.list.forEach(function(channel){
					let channelId = channel.id;

					if (!promotionData.channels.includes(channelId)) {
						selectableChannles.push(channel)
					};

					segmentList.push(channel);

					this.segments.forEach(function(segment){
						if (segment.organization_id == channelId) { 
							segmentList.push(segment)
						}
					}, this);
				}, this);
			};
		};

		this.setState({
			...channels,
			channels: channelsResp.data.list,
			selectableChannles: selectableChannles,
			isLoading: false,
			segmentList: segmentList
		}, () => {
			const channelsSeleted = this.channelsSelected();

			this.setState({
				airbnbSelected: channelsSeleted.airbnbSelected,
				bookingcomSelected: channelsSeleted.bookingcomSelected,
				expediaSelected: channelsSeleted.expediaSelected
			});
		});
	}	

	getChannelName = (channelId) => {
		const { channels } = this.state;
		let title = '';

		channels.forEach((channel) => {
			if(channel.id == channelId){
				title = channel.title;
				return;
			};
		});

		return title;
	}
	
	configuredChannels = () => (
		this.state.promotionData.channels.map(this.dispayConfiguredChannels)
	)

	dispayConfiguredChannels = channel => (
		<Row className='bottom-margin' key={"dispay-configured-channels-"+channel}>
			<Col md={12} xs={12} sm={12} lg={12}>
				<div className="channels-div pull-left" md={10} xs={10} sm={10} lg={10}>
					<Col key={"dispay-configured-channels-channel-"+channel} md={5} xs={5} sm={5} lg={5}>	
						<InputLabel
							htmlFor="channelname"
							className="form-label"
						>
							{strings.channel}
						</InputLabel>														
						{/* get the channel name */}
						<div className="radio-label">{this.getChannelName(channel)}</div>
					</Col>
					<Col key={"dispay-configured-channels-segment-"+channel} md={5} xs={5} sm={5} lg={5}>	
						<InputLabel
							htmlFor="segments"
							className="form-label"
						>
							{strings.segments}
						</InputLabel>
						{this.createAssignedSegments(channel)}
						{this.createBookingcomSegments(channel)}
					</Col>
				</div>
				<Col md={2} xs={2} sm={2} lg={2}>
					<a
						href="#"
						className="left-margin"
						onClick={(e) => this.deleteChannel(e,channel)}>
						<i
							className="icon icon-Delete"
						/>
					</a>
                </Col>
			</Col>
		</Row>
	)

	createChannels = () => (
		this.state.selectableChannles.map(this.createChannel)
	)

	createChannel = label => {
		const {
			bookDateType,
		} = this.state;
		let cssStyle = {
			color: '#ccc',
		};
		let cssStyle2 = {
			cursor: 'not-allowed',
		};

		return (
			<Row key={"row-"+label['name']}>
				<Col md={6} key={"col-"+label['name']}>
					<InputField
						cssClass="form-check-label checkbox-label"
						type="radio"
						name={label['title']}
						value={this.channelChecked(label['id'])}
						label={label['title']}
						key={label['name']}
						cbChange={e => {
							if(((label['name'] == 'airbnb') && bookDateType == 'BOOKDATES') || ((label['name'] == 'expedia') && bookDateType == 'BOOKHOURS')) {
								return;
							}
							else {
								this.selectChannel(e, label['id'])
							};
						}}
						style={((label['name'] == 'airbnb') && bookDateType == 'BOOKDATES') || ((label['name'] == 'expedia') && bookDateType == 'BOOKHOURS') ? cssStyle : {}}
						style2={((label['name'] == 'airbnb') && bookDateType == 'BOOKDATES') || ((label['name'] == 'expedia') && bookDateType == 'BOOKHOURS') ? cssStyle2 : {}}
					>
						{label['title']}
					</InputField>
					{((label['name'] == 'airbnb') && bookDateType == 'BOOKDATES') && infoPopover("Airbnb does not support Book Between.", null, "top", "icon-info")}
					{((label['name'] == 'expedia') && bookDateType == 'BOOKHOURS') && infoPopover("Expedia does not support Last Minute.", null, "top", "icon-info")}
				</Col>
			</Row>
		)
	}
	
	
	channelChecked = channel => {
		if(this.state.channelSelected === channel) {
			return channel;
		};
		return null;
	}  
	
	selectChannel = (e, channel) => {
		this.setState({
			channelSelected: channel
		});
	}

	deleteChannel = (e, channel) => {
		e.preventDefault();
		const { 
			channels,
			promotionData,
			selectableChannles
		} = this.state;
		let copyPromotionData = _.cloneDeep(promotionData);
		let copySelectableChannles = _.cloneDeep(selectableChannles);

		if(copyPromotionData.channels.includes(channel)) {
			// Remove channel
			let idx = copyPromotionData.channels.indexOf(channel);
			copyPromotionData.channels.splice(idx, 1);
			let removal = [];

			if(copyPromotionData.segments.length > 0) { 
				copyPromotionData.segments.forEach(function(assigned, sidx) {
					if (channel == assigned.organization_id) {
				 		removal.push(sidx);
					};
				}, this)
			};

			removal.reverse();

			// remove the assigned segments of the removed channel
			removal.forEach(function(position) {
				copyPromotionData.segments.splice(position, 1)
			}, this);

			// add the channel back to the available list
			channels.forEach(function(schannel) {
				if(schannel.id ==channel) {
					copySelectableChannles.push(schannel)
					copySelectableChannles.sort(function(a, b) {
						return a.title.localeCompare(b.title)
					});
				};
			}, this);

			this.setState({
				channelSelected: null,
				promotionData: copyPromotionData,
				selectableChannles: copySelectableChannles,
			}, () => {
				const { bookDateType } = this.state;
				const channelsSeleted = this.channelsSelected();
				let airbnbSelected = false;
				let bookingcomSelected = false;
				let expediaSelected = false;
				let copyBookDateType = _.cloneDeep(bookDateType);
	
				if(channelsSeleted.airbnbSelected) {
					airbnbSelected = true;
	
					if(copyPromotionData.discount == 'amount') {
						copyPromotionData.discount = 'percent';
					};
	
					if(bookDateType == 'BOOKDATES') {
						copyBookDateType = 'BOOKDAYS';
					};
				}
				else {
					airbnbSelected = false;
				};
	
				if(channelsSeleted.bookingcomSelected) {
					bookingcomSelected = true;
	
					let newRules = copyPromotionData.rule_set.rules.filter((rule) => rule.attribute != "MINIMUMNIGHTS" && rule.attribute != "MAXIMUMNIGHTS");
	
					copyPromotionData.rule_set.rules = newRules;
				}
				else {
					bookingcomSelected = false;
				};
	
				if(channelsSeleted.expediaSelected) {
					expediaSelected = true;
	
					if(bookDateType == 'BOOKHOURS') {
						copyBookDateType = 'BOOKDAYS';
					};
				}
				else {
					expediaSelected = false;
				};

				this.setState({
					airbnbSelected: airbnbSelected,
					bookingcomSelected: bookingcomSelected,
					expediaSelected: expediaSelected,
					bookDateType: copyBookDateType,
				})
			});
		} 
	}

	addChannel = (e, channel) => {
		if(channel == null) {
			return;
		};

		const { 
			promotionData,
			selectableChannles
		} = this.state;
		let copyPromotionData = _.cloneDeep(promotionData);
		let copySelectableChannles = _.cloneDeep(selectableChannles);

		copyPromotionData.channels.push(channel);

		copySelectableChannles.forEach((schannel) => {
			if(schannel.id == channel) {
				let idx = copySelectableChannles.indexOf(schannel);
				copySelectableChannles.splice(idx, 1);
			};
		});

		this.setState({
			channelSelected: null,
			promotionData: copyPromotionData,
			selectableChannles: copySelectableChannles,
		}, () => {
			const { requiredValidation } = this.state;

			if(requiredValidation.channels) {
				const newValidation = this.handleFormValidation();

				this.setState({
					requiredValidation: newValidation
				});
			};

			const { bookDateType } = this.state;
			const channelsSeleted = this.channelsSelected();
			let airbnbSelected = false;
			let bookingcomSelected = false;
			let expediaSelected = false;
			let copyBookDateType = _.cloneDeep(bookDateType);

			if(channelsSeleted.airbnbSelected) {
				airbnbSelected = true;

				if(copyPromotionData.discount == 'amount') {
					copyPromotionData.discount = 'percent';
				};

				if(bookDateType == 'BOOKDATES') {
					copyBookDateType = 'BOOKDAYS';
				};
			}
			else {
				airbnbSelected = false;
			};

			if(channelsSeleted.bookingcomSelected) {
				bookingcomSelected = true;

				let newRules = copyPromotionData.rule_set.rules.filter((rule) => rule.attribute != "MINIMUMNIGHTS" && rule.attribute != "MAXIMUMNIGHTS");

				copyPromotionData.rule_set.rules = newRules;
			}
			else {
				bookingcomSelected = false;
			};

			if(channelsSeleted.expediaSelected) {
				expediaSelected = true;

				if(bookDateType == 'BOOKHOURS') {
					copyBookDateType = 'BOOKDAYS';
				};
			}
			else {
				expediaSelected = false;
			};

			this.setState({
				airbnbSelected: airbnbSelected,
				bookingcomSelected: bookingcomSelected,
				expediaSelected: expediaSelected,
				bookDateType: copyBookDateType,
			});
		});
	}

	channelsSelected = () => {
		const { 
			channels,
			promotionData
		} = this.state;
		let airbnbSelected = [];
		let bookingcomSelected = [];
		let expediaSelected = [];

		for (let i = 0; i < channels.length; i++) {
			if(channels[i].name == 'airbnb' || channels[i].name == 'bookingcom' || channels[i].name == 'expedia') {
				for (let x = 0; x < promotionData.channels.length; x++) {
					if(channels[i].id == promotionData.channels[x]) {
						if(channels[i].name == 'airbnb') {
							airbnbSelected.push(channels[i]);
						};
						if(channels[i].name == 'bookingcom') {
							bookingcomSelected.push(channels[i]);
						};
						if(channels[i].name == 'expedia') {
							expediaSelected.push(channels[i]);
						};
					};
				};
			};
		};

		return {
			airbnbSelected: airbnbSelected.length ? true : false,
			bookingcomSelected: bookingcomSelected.length ? true : false,
			expediaSelected: expediaSelected.length ? true : false,
		};
	}

	/** Promotion Types TODO NEW PROMOTION ENUM TYPES API*/
	promotionTypes = [
		{ enum: 'DD', name:'Discount'},
		{ enum: 'NF', name:'Nights Free'}
	]; 

	createPromotionTypes = () => {
		const { promotionData } = this.state;
		const airbnbNewListingPromo = promotionData.segments.filter(segment => segment.identifier == 'NEW_LISTING_PROMOTION');
		const disabled = airbnbNewListingPromo.length ? true : false;

		return this.promotionTypes.map((type) => this.createPromotionType(type, disabled));
	}

	createPromotionType = (label, disabled) => (
		<Row key={"row-"+label['name']}>
			<Col md={8} key={"col-"+label['name']}>
				<InputField
					cssClass="form-check-label checkbox-label"
					type="radio"
					name={label['name']}
					value={this.promotionTypeChecked(label['enum'])}
					label={label['name']}
					cbChange={e => {this.togglePromotionType(e, label['enum'])}}
					key={label['name']}
					disabled={disabled}
				>
					{label['name']}
				</InputField>
			</Col>
		</Row>
	)
	
	promotionTypeChecked = type => {
		const { promotionData } = this.state;

		if(promotionData.type === type) {
			return type;
		};

		return null;
	}  
	
	togglePromotionType = (e, promoType) => {
		const { promotionData } = this.state;

		let requiredValidation = {
			discountValue: false,
			formValid: true
		};

		let discountType = 'percent';
		let valueType = '';

		if (promoType === 'NF') {
			discountType = 'compday';
			valueType = '1';
		};
		
		this.setState({
			promotionData: {
				...promotionData,
				type: promoType,
				discount: discountType,
				value: valueType
			},
			requiredValidation: {
				...requiredValidation
			}
		});
	}
	/** Discount Types */
	discountTypes = [
		{ enum: 'percent', name:'% Off per Stay'},
		{ enum: 'amount', name:'$ Off per Stay'}
		/*, Nights free is a hidden value
		{ enum: 'compday', name: 'Nights Free' }*/
	];

	createDiscountTypes = () => {
		const { promotionData } = this.state;
		const airbnbNewListingPromo = promotionData.segments.filter(segment => segment.identifier == 'NEW_LISTING_PROMOTION');
		const disabled = airbnbNewListingPromo.length ? true : false;

		return this.discountTypes.map((types) => this.createDiscountType(types, disabled))
	}

	createDiscountType = (label, disabled) => {
		const {
			airbnbSelected
		} = this.state;

		return (
			<Row key={"row-"+label['name']}>
				<Col md={8} key={"col-"+label['name']}>
					<InputField
						cssClass="form-radio-label radio-label"
						type="radio"
						name={label['name']}
						value={this.discountTypeChecked(label['enum'])}
						label={label['name']}
						cbChange={e => {
							if((label['enum'] == 'amount') && airbnbSelected) {
								return;
							}
							else {
								this.toggleDiscountType(e, label['enum'])
							};
						}}
						key={label['name']}
						disabled={disabled || (label['enum'] == 'amount' && airbnbSelected)}
					>
						{label['name']}
					</InputField>
					{
						(label['enum'] == 'amount' && airbnbSelected) && 
						infoPopover("Airbnb does not support $ Off per Stay.", null, "top", "icon-info")
					}
				</Col>
			</Row>
		)
	}
	
	discountTypeChecked = discount => {
		const { promotionData } = this.state;

		if(promotionData.discount === discount) {
			return discount;
		};

		return null;
	}  
	
	toggleDiscountType = (e, discountType) => {
		const { promotionData } = this.state;

		this.setState({
			promotionData: {
				...promotionData,
				discount: discountType,
			}
		});
	}

	handleDiscountValueChange = (e) => {
		const { promotionData } = this.state;
		const value = e.target.value;

		this.setState({
			promotionData: {
				...promotionData,
				value: value
			}
		}, () => {
			const { requiredValidation } = this.state;

			if(requiredValidation.discountValue || requiredValidation.bookingcomMinMaxValue) {
				const formValidation = this.handleFormValidation();
				const channelValidation = this.channelValidations();
				const combineValidation = {...formValidation, ...channelValidation};

				this.setState({
					requiredValidation: combineValidation
				});
			};
		});
	}
	
	handleBadgeChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		const checked = e.target.checked;

		this.setState({
			[name]: {
				...this.state[name],
				[value]: checked
			}
		}, () => {
			const { 
				check_in_allowed,
				check_out_allowed
			} = this.state;
			let copyCheckInAllowed = _.cloneDeep(check_in_allowed);
			let copyCheckOutAllowed = _.cloneDeep(check_out_allowed);

			const formatAllowed = function(allowed) {
				let to_apply = [];
				Object.keys(allowed).forEach(function(key){
					let days_of_the_week = ['monday','tuesday','wednesday','thursday','friday','saturday','sunday'];
					if(allowed[key]) {
						to_apply.push(days_of_the_week.indexOf(key))				
					};
				});
	
				return to_apply.join(",");
			};

			if (name ==='check_in_allowed'){ 
				this.setRuleValue({ 'target': { 'name': 'CHECKINALLOWED','value': formatAllowed(copyCheckInAllowed)}}); 
			};
			if (name ==='check_out_allowed'){ 
				this.setRuleValue({ 'target': { 'name': 'CHECKOUTALLOWED','value': formatAllowed(copyCheckOutAllowed)}});
			};
		});
	}
	
	getRuleValue = (name) => {
		const { promotionData } = this.state;
		let rules = _.get(promotionData, 'rule_set.rules');
		let value = "";

		rules.some((rule, index, _arr) => {
			if(rule.attribute === name) {
				value = rule.value.toString();
			}
			return rule.attribute === name;
		});
		return value;	  
	}

	setRuleValue = (e) => {
		const { promotionData } = this.state;
		let copyPromotionData = _.cloneDeep(promotionData);
		let name = e.target.name;
		let value = e.target.value;
		let rules = copyPromotionData.rule_set.rules;
		let r =[];

		/* Rule Templates */
		// Minimum Night of stay to qualify
		r['MINIMUMNIGHTS'] = {"attribute": "MINIMUMNIGHTS", "operation":"GREATERTHANEQUAL", "value": "1"};
		// Maximum night of stay - must be less then value to qualify
		r['MAXIMUMNIGHTS'] = {"attribute": "MAXIMUMNIGHTS", "operation":"LESSTHANEQUAL", "value": "7"};
		// allowed check in days
		r['CHECKINALLOWED'] = {"attribute": "CHECKINALLOWED", "operation":"IN", "value": "0,1,2,3,8,5,6"};
		// allowed check out days
		r['CHECKOUTALLOWED'] = {"attribute": "CHECKOUTALLOWED", "operation":"IN", "value": "0,1,2,3,8,5,6"};
		// EarlyBird - Reservation Days before stay
		r['BOOKDAYS'] = {"attribute": "BOOKDAYS", "operation":"GREATERTHANEQUAL", "value": "90"};
		// BOOKBETWEEN - Must make reservation between dates
		r['BOOKDATES'] = {"attribute": "BOOKDATES", "operation":"BETWEEN", "value": "2019-08-16|2019-09-22"};
		// LASTMINUTE - Must make reservation within x number of hours before stay
		r['BOOKHOURS'] = {"attribute": "BOOKHOURS", "operation":"LESSTHANEQUAL", "value": "12"};
		// Stay Dates - stay dates must be within this range of dates
		r['STAYDATES'] = {"attribute": "STAYDATES", "operation":"BETWEEN", "value": "2019-05-01|2019-09-22"};
		// User Segment - custom user segmentation, must be present to qualify
		// theese are now added as rules on the backend
		//r['USERSEGMENT'] = {"attribute": "USERSEGMENT", "operation":"IN", "value": "guest,friend"};
		// EXCLUDEDDATES
		r['EXCLUDEDDATES'] = {"attribute": "EXCLUDEDDATES", "operation":"OUTSIDE", "value": "2019-05-01|2019-09-22"};
		/* remove an existing rule if we are updating its value with the exception of Exclude dates and additional dates*/	
		if (name !='EXCLUDEDDATES' && name !='ADDITIONALDATES') { 
			rules.some((rule, index, _arr) => {
				// remove the rule we are planning to add / update
				if (rule.attribute === name) { 
					_arr.splice(index, 1)
				}
				// remove MAXIMUMNIGHTS if the value is zero
				if (rule.attribute ==='MAXIMUMNIGHTS' && rule.value === 0) { 
					_arr.splice(index, 1)
				}
			});
		};

		let newVal = r[name];
		newVal.value = value;

		// additional basic rule validation before the new rule add
		// Zero value check. We will not set any rule with a value of zero
		// unless it is a checkin allowed or checkout allowed rule
		if (value !=0 || (name==='CHECKINALLOWED'|| name==='CHECKOUTALLOWED')) { 
			rules.push(newVal)
		};

		this.setState({
			promotionData: {
				...promotionData,
				rule_set: {  
					rules: [
						...copyPromotionData.rule_set.rules
					]
				}
			}
		}, () => {
			const { requiredValidation } = this.state;

			if(requiredValidation.minimumNights) {
				const newValidation = this.handleFormValidation();

				this.setState({
					requiredValidation: newValidation
				});
			};
		});
	}

	/** Promotion Types TODO NEW PROMOTION ENUM TYPES API*/
	bookDateTypes = [
		{ enum: 'BOOKDAYS', name:'Early Bird'},
		{ enum: 'BOOKDATES', name:'Book Between'},
		{ enum: 'BOOKHOURS', name:'Last Minute'}
	];

	createDateTypes = () => {
		const { promotionData } = this.state;
		const airbnbNewListingPromo = promotionData.segments.filter(segment => segment.identifier == 'NEW_LISTING_PROMOTION');
		const disabled = airbnbNewListingPromo.length ? true : false;

		return this.bookDateTypes.map((type) => this.createDateType(type, disabled))
	}

	createDateType = (label, disabled) => {
		let cssStyle = {
			color: '#ccc',
		};
		let cssStyle2 = {
			cursor: 'not-allowed',
		};

		return (
			<Row className='bottom-margin' key={"row-"+label['name']}>
				<Col md={8} key={"col-"+label['name']}>
					<InputField
						cssClass="form-radio-label radio-label"
						type="radio"
						name={label['name']}
						value={this.dateTypeChecked(label['enum'])}
						label={label['name']}
						key={label['name']}
						cbChange={e => {
							if(((label['enum'] == 'BOOKDATES') && this.state.airbnbSelected) || ((label['enum'] == 'BOOKHOURS') && this.state.expediaSelected)) {
								return;
							}
							else {
								this.toggleDateType(e, label['enum'])
							};
						}}
						style={((label['enum'] == 'BOOKDATES') && this.state.airbnbSelected) || ((label['enum'] == 'BOOKHOURS') && this.state.expediaSelected) ? cssStyle : {}}
						style2={((label['enum'] == 'BOOKDATES') && this.state.airbnbSelected) || ((label['enum'] == 'BOOKHOURS') && this.state.expediaSelected) ? cssStyle2 : {}}
						disabled={disabled}
					>
						{label['name']}
						<br />
					</InputField>
					{((label['enum'] == 'BOOKDATES') && this.state.airbnbSelected) && infoPopover("Airbnb does not support Book Between.", null, "top", "icon-info")}
					{((label['enum'] == 'BOOKHOURS') && this.state.expediaSelected) && infoPopover("Expedia does not support Last Minute.", null, "top", "icon-info")}
				</Col>
			</Row>
		)
	}
	
	
	dateTypeChecked = type => {
		const { bookDateType } = this.state;

		if(bookDateType === type) {
			return type;
		};

		return null;
	}  
	
	toggleDateType = (e, dateType) => {
		let requiredValidation = Object.assign({}, defaultValidation);

		this.setState({
			bookDateType: dateType,
			requiredValidation: {
				...requiredValidation
			}
		}, () => {
			this.setState({
				channelSelected: ''
			});
		});
	}

	handleDatesChange = (e) => {
		const { promotionData } = this.state;
		const name = e.target.name;
		const value = e.target.value;

		if(name != 'STAYDATES') { 
			promotionData.rule_set.rules.map(function (rule, index, _arr) {
				let dateTypeRules = ['BOOKDAYS','BOOKDATES','BOOKHOURS'];
				if(dateTypeRules.includes(rule.attribute)) {
					_arr.splice(index, 1)
				}
			}, this);
		};

		if(name =='BOOKDAYS' || name =='BOOKHOURS') {
			this.setState({
				startDate: null,
				endDate: null
			}, () => {
				const { requiredValidation } = this.state;

				if(requiredValidation.bookdays || requiredValidation.bookhours) {
					const formValidation = this.handleFormValidation();
					const channelValidation = this.channelValidations();
					const combineValidation = {...formValidation, ...channelValidation};
	
					this.setState({
						requiredValidation: combineValidation
					});
				};
			});
		};

		this.setRuleValue({ 'target': { 'name': name,'value': value}})
	}
	// Book Between Dates OnChange 
	// need to stage the values before we add them to the rule set
	handleCalendarDatesChange = async (startDate, endDate) => {
		this.setState({
			startDate: null,
			endDate:null
		}, () => {
			let start;
			let end;
	
			if (startDate != null) { 
				start = startDate.format('YYYY-MM-DD');
				this.setState({
					startDate: startDate
				});
			}
			if (endDate != null && endDate !=this.state.endDate) { 
				end = endDate.format('YYYY-MM-DD');
				this.setState({
					endDate: endDate
				});
				if(start !=null && end !=null) { 		
					this.handleDatesChange({'target': {'name': 'BOOKDATES', 'value': start +"|"+ end}})
				}
			} 
		});
	}

	// Book Between Dates OnClose 
	handleCalendarDatesOnClose = async () => {
		let requiredValidation = Object.assign({}, defaultValidation);
		// clear any existing validation errors
		this.setState({
			requiredValidation: {
				...requiredValidation
			}
		},
		// after state change run our hack validation
		function(){
			// We are exiting the Book Between DateRangePicker
			// This is a Hack to detect invalid user typed dates as the DateRangePicker
			// has limited support for typed input validation callbacks
			let today = moment(moment().format("MM/DD/YYYY").toString(),"MM/DD/YYYY", true) // today date, used to display a specific validation error.
			let start = document.getElementById('startbookdate').value //start input field
			let end = document.getElementById('endbookdate').value // end input field
			let startLength = start.length // used to test if user has typed a date into the start input field
			let endLength = end.length // used to test if user has typed a date into the end input field
			// Error Messages
			let errorInvalidDate = strings.formatString(strings.error_invalid_field_date, strings.book_between)
			let errorEndBeforeStart = strings.error_end_before_start;
			let errorBeforeToday = strings.formatString(strings.error_before_today, moment().format('MM/DD/YYYY').toString());
			let errorEndSameStart = strings.error_end_same_as_start;
			let setErrorMessage = function(requiredValidation,start,end,today) {
				// Generic error message
				requiredValidation = {bookdates: true, bookdatesErrorMessage: errorInvalidDate}
				// We can attempt to evaluate the input fields for a more descriptive error message
				// 1) Is the end date befor the start date?
				// 2) Is the start date before today? if so the this is an invalid date range.
				if (end.isBefore(start)) {
					requiredValidation = {bookdates: true, bookdatesErrorMessage: errorEndBeforeStart}
				}

				if(start.isBefore(today)) {
					requiredValidation = {bookdates: true, bookdatesErrorMessage: errorBeforeToday}
				}

				if(end.isSame(start)) {
					requiredValidation = {bookdates: true, bookdatesErrorMessage: errorEndSameStart}
				}

				this.setState({
					requiredValidation: {
						...requiredValidation
					}
				});
			}.bind(this)
			// Dates were deleted or not selected, this gives us a clean starting point for this hack.
			// By BOOKDATES not existing in the ruleset the default validation show detect an error if Book Between is required.

			const { promotionData } = this.state;
			let copyPromotionData = _.cloneDeep(promotionData);

			if (startLength == 0 || endLength == 0 || (startLength == 0 && endLength == 0)) { 
				copyPromotionData.rule_set.rules.map(function (rule, index, _arr) {
					let dateTypeRules = ['BOOKDATES'];
					if(dateTypeRules.includes(rule.attribute)) {
						_arr.splice(index, 1)
					}
				}, this)
	
				this.setState({
					promotionData: {
						...copyPromotionData,
						rule_set: {  
							rules: [
								...copyPromotionData.rule_set.rules
							]
						}
					}
				})
				return
			}

			// We have some string date values now.
			// Let's verify the values in the input field match what has been saved in the BOOKDATES rule.
			// If they do not match we know the DateRangePicker found them invalid and did not apply them.
			// It's to bad the DateRangePicker component does not allow a hook to detect invalid date entries.
			if (startLength != 0 && endLength != 0) { 

				let error = false
				// Convert input values to moment objects
				start = moment(start, "MM/DD/YYYY", true)
				end = moment(end, "MM/DD/YYYY", true)
				// Get the BOOKDATES rule if it exists
				let bookdates = this.getRuleValue('BOOKDATES')
				let bookstart = null
				let bookend = null
				let requiredValidation = {}

				// We have a BOOKDATES Rule let's evaluate the rule against the input fields
				if(bookdates.length > 0){ 
					// Test the BOOKDATES rule value, it would be a string at this point. Format example: 2020-03-19|2020-04-14
					let bookdates = this.getRuleValue('BOOKDATES').split('|');
					// convert to moment objects
					bookstart = moment(bookdates[0], "YYYY-MM-DD", true)
					bookend = moment(bookdates[1], "YYYY-MM-DD", true)
					// Set error true if start and bookstart do not match
					if(start.toString() != bookstart.toString()){
						// If start input field and bookstart value from rule do not match then 
						// the value of start was rejected by the DateRangePicker validation code.
						// The reasons for this could be an out of range date or invalid date format typed in the input field.
						error = true
					}
					// Set error to true if end and bookend do not match
					if(end.toString() !=bookend.toString()) {
						// same reasons as above testing the end input value and bookend value for the rule.
						error = true
					}

					if(error){ 
						setErrorMessage(requiredValidation, start, end, today)
					}

				} else {
					// If there was no rule then we must have invalid date ranges in the input fields.
					// There was a validation error in the DateRangePicker and the dates were not pushed to state. 
					// The DateRangePicker does not all a callback to check for invaled typed date values, thus we have this hack in place.
					setErrorMessage(requiredValidation, start, end, today)
				}
			}
		});
	}
	
	handleCalendarStayDateChange = (stayStartDate, stayEndDate) => {
		this.setState({
			stayStartDate: null,
			stayEndDate:null
		}, () => {
			const { promotionData } = this.state;
			let copyPromotionData = _.cloneDeep(promotionData);
			let start;
			let end;

			if (stayStartDate != null) { 
				start = stayStartDate.format('YYYY-MM-DD');
				this.setState({
					stayStartDate: stayStartDate
				});
			}
			if (stayEndDate != null && stayEndDate != this.state.stayEndDate) { 
				end =  stayEndDate.format('YYYY-MM-DD');
				this.setState({
					stayEndDate: stayEndDate
				});

				if(start !=null && end !=null) { 
					this.handleDatesChange({'target': {'name': 'STAYDATES', 'value': start +"|"+ end}})
				};
			}

			if(!stayStartDate || !stayEndDate) {
				copyPromotionData.rule_set.rules.map(function (rule, index, _arr) {
					if(rule.attribute == 'STAYDATES') {
						_arr.splice(index, 1);

						this.setState({
							promotionData: {
								...copyPromotionData,
								rule_set: {
									rules: [
										...copyPromotionData.rule_set.rules
									]
								}
							}
						})
					}
				}, this)
			}
		});
	}

	handleCalendarStayDateOnClose = () => {
		let requiredValidation = Object.assign({}, defaultValidation);
		// clear any existing validation errors
		this.setState({
			requiredValidation: {
				...requiredValidation
			}
		},
		// after state change run our hack validation
		function(){
			let today = moment(moment().format("MM/DD/YYYY").toString(),"MM/DD/YYYY", true)
			let start = document.getElementById('startstaydate').value
			let end = document.getElementById('endstaydate').value
			let startLength = start.length
			let endLength = end.length
			let errorEndBeforeStart = strings.error_end_before_start;
			let errorBeforeToday = strings.formatString(strings.error_before_today, moment().format('MM/DD/YYYY').toString());
			let errorEndSameStart = strings.error_end_same_as_start;
			let setErrorMessage = function(requiredValidation, start, end, today) {

				if (end.isBefore(start)) {
					requiredValidation = {staydates: true, staydatesErrorMessage: errorEndBeforeStart}
				}
	
				if(start.isBefore(today)) {
					requiredValidation = {staydates: true, staydatesErrorMessage: errorBeforeToday}
				}
	
				if(end.isSame(start)) {
					requiredValidation = {staydates: true, staydatesErrorMessage: errorEndSameStart}
				}

				this.setState({
					requiredValidation: {
						...requiredValidation
					}
				},function(){
					document.getElementById('endstaydate').blur();
				});
			}.bind(this)
	
			if(startLength > 0 && endLength > 0) {
				start = moment(start, "MM/DD/YYYY", true)
				end = moment(end, "MM/DD/YYYY", true)
				setErrorMessage(requiredValidation, start, end, today)	
			}
		})
	}

	handleCalendarExcludeDateChange = (excludeStartDate, excludeEndDate) => {
		if(excludeStartDate == null){
			this.setState({
				excludeStartDate: null,
				excludeEndDate: null
			});
		};
		if (excludeStartDate != null) { 
			this.setState({
				excludeStartDate: excludeStartDate
			});
		};
		if (excludeEndDate != null && excludeEndDate != this.state.excludeEndDate) { 
			this.setState({
				excludeEndDate: excludeEndDate
			});
		};
	}

	handleExcludeDateOutOfRange = (date) => {
		if(!this.state.stayStartDate) {
			if(date > moment()){
				return false;
			};
		};

		if(date < this.state.stayStartDate || date > this.state.stayEndDate){ 
			return true; 
		}
		else { 
			return false;
		};
	}

	applyExcludeDate = (e) => {
		e.preventDefault();
		const { 
			excludeStartDate,
			excludeEndDate,
			excludeFields
		} = this.state;
		let start;
		let end;

		if (excludeStartDate != null && excludeEndDate != null) {
			let copyExcludeFields = _.cloneDeep(excludeFields);
			start = excludeStartDate.format('YYYY-MM-DD');
			end =  excludeEndDate.format('YYYY-MM-DD');

			copyExcludeFields.push({
				"value": start  +"|"+ end,
				"startDate": moment(start, "YYYY-MM-DD"), 
				"endDate": moment(end, "YYYY-MM-DD"), 
				"focusedInput": null
			});

			this.setState({
				excludeFields: copyExcludeFields,
				excludeStartDate: null, 
				excludeEndDate: null, 
			}, () => {
				this.setRuleValue({'target': {'name': 'EXCLUDEDDATES', 'value': start  +"|"+ end}}); 
			});
		};
	}

	removeExcludeDate = (e, index, value) => {
		e.preventDefault();
		const { 
			excludeFields,
			promotionData
		} = this.state;
		let copyPromotionData = _.cloneDeep(promotionData);
		let copyExcludeFields = _.cloneDeep(excludeFields);

		// Remove rule set from promotions data.
		copyPromotionData.rule_set.rules.some((rule, index, _arr) => {
			if(rule.attribute ==='EXCLUDEDDATES' && rule.value === value) { 
				_arr.splice(index, 1)
			}
		});

		// Remove excluded date from state.
		copyExcludeFields.splice(index, 1);

		this.setState({
			excludeFields: copyExcludeFields,
			promotionData: copyPromotionData
		});
	}
	
	excludedDateRender() {
		const { excludeFields } = this.state;

        return excludeFields.map((rule, index, _arr) => {
            return this.createExcludeDate(rule, index)
        });
    }
	
	createExcludeDate = (rule, index) => {
		const { 
			requiredValidation
		} = this.state;

		return (
        <Row className='bottom-margin' key={index}>
			<Col md={12} xs={12} sm={12} lg={12}>
				<div className="exclude-div pull-left" md={10} xs={10} sm={10} lg={10}>
					<Col md={5} xs={5} sm={5} lg={5}>
						<InputLabel 
							htmlFor='startexcluded' 
							className={requiredValidation.excludeddates ? 'form-validation' : undefined}
						>
							{strings.start_night_of_check_in}
						</InputLabel>
					</Col>
					<Col md={5} xs={5} sm={5} lg={5}>
						<InputLabel 
							htmlFor='endexcluded' 
							className={requiredValidation.excludeddates ? 'form-validation' : undefined}
						>
							{strings.end_last_night_of_stay}
						</InputLabel>
					</Col>
					<Col md={10} xs={10} sm={10} lg={10}>
						<DateRangePicker
							disabled
							noBorder
							startDate={_.get(rule, 'startDate')} 
							startDateId={"startexcluded-"+rule['value']}
							startDatePlaceholderText="mm/dd/yyyy"
							endDate={_.get(rule, 'endDate')} 
							endDateId={"endexcluded-"+rule['value']}
							endDatePlaceholderText="mm/dd/yyyy"
							onDatesChange={() => null}
							focusedInput={_.get(rule, 'focusedInput')}
							onFocusChange={() => null}
						/>
					</Col>
				</div>
				<Col md={2} xs={2} sm={2} lg={2}>
					<a 
						href="#" 
						className="left-margin"
						onClick={(e) => this.removeExcludeDate(e, index, rule['value'])}
					>
						<i 
							className="icon icon-Delete"
						/>
					</a>
				</Col>
			</Col>
        </Row>
	)}
	
	getOrganizationsAssigned = () => {
		const { promotionData } = this.state;
		let assigned = [];
		let assignedToPromotion = promotionData.organizations;

		if(this.props.expanded_organizations.length > 0) { 
			this.props.expanded_organizations.forEach((organization) => {
				if (assignedToPromotion.includes(organization.organization_id)) {
					assigned.push(organization)
				};
			});
		};

		return assigned;
	}

	loadProperties = debounce(() => {
		this.getProperties();
	}, 200);

	setOrganizations = (orgs) => {
		const { promotionData } = this.state
		this.dirty = true;
		let organizationMap = [];

		orgs.forEach((organization) => {
			organizationMap.push(organization.organization_id)
		});

		if (promotionData.organizations != organizationMap) { 
			this.setState({
				promotionData: {  
					...promotionData,
					organizations: organizationMap,
					properties: []
				}
			}, () => {
				const { requiredValidation } = this.state;

				if(requiredValidation.organizations) {
					const newValidation = this.handleFormValidation();

					this.setState({
						requiredValidation: newValidation
					});
				};
			});
		};
	}

	getPropertiesAssigned = () => {
		const { promotionData } = this.state;
		let assigned = [];

		if(promotionData.properties.length > 0 ) { 
			promotionData.properties.forEach((property) => {
				assigned.push({
					property_id: property, 
					title: property
				});
			});
		};
		return assigned;
	} 
	
	setProperties = (properties) => {
		const { promotionData } = this.state;
		let propertiesMap = [];

		properties.forEach((property) => {
			propertiesMap.push(parseInt(property.property_id))
		});

		this.setState({
			promotionData: {  
				...promotionData,
				properties: propertiesMap
			}
		}, () => {
			const { requiredValidation } = this.state;

			if(requiredValidation.properties) {
				const newValidation = this.handleFormValidation();

				this.setState({
					requiredValidation: newValidation
				});
			};
		});
	}

	getProperties = async () => {
		// if dirty go fetch
		if (!this.dirty) {
			return
		}

		const { 
			properties,
			promotionData
		} = this.state;
		let copyPromotionData = _.cloneDeep(promotionData);
		this.dirty = false;
		let propertiesAssigned = this.getPropertiesAssigned();
		
		this.setState({ 			
			...properties,
			properties: propertiesAssigned,
			propertyLoader: true
		});

		let orgsAssigned = this.getOrganizationsAssigned();
		// see if we have Organizations Assigned else reset the state and bail
		if (orgsAssigned.length <= 0) {
			this.setState({ 
				isLoading: false,
				propertyLoader:false	
			}) 
			return;
		};

		let orgs = []
		orgsAssigned.forEach((organization) => {
			orgs.push(organization.organization_name);
		});

		orgs = orgs.join('%2C');

		let currentPromotionPropertyList = copyPromotionData.properties;
		const tid = getSessionId();

		try {
			let apiInit = { 
				response: true, 
			};

			let limit = 200;
			let offset = 0;
			let res = await API.get("rapapi", `/promotion/properties?organizations=${orgs}&offset=${offset}&tid=${tid}&limit=${limit}`, apiInit);
			
			let totalCount = res.headers['x-total-count'];
			let collection = []
			
			res.data['list'].forEach(function(property) {
				collection.push(property);
			});
			
			let process = collection.length < totalCount;

			while(process) {
				offset = offset + limit;
				let res = await API.get("rapapi", `/promotion/properties?organizations=${orgs}&offset=${offset}&tid=${tid}&limit=${limit}`, apiInit);
				
				if (res.data['list'].length ==0) {
					break;
				}
				res.data['list'].forEach(function(property) {
					collection.push(property);
				});
				process = collection.length < totalCount;
			}
			
			if(collection.length > 0) { 
				collection.forEach(function(property){

					if (!currentPromotionPropertyList.includes(parseInt(property.property_id))) {

						propertiesAssigned.push({'property_id': parseInt(property.property_id),'title': property.property_id + (Boolean(property.reference_code) ? " - "+ property.reference_code : "") +" - "+ property.title, 'reference_code': property.reference_code });

					} else {
						let idx = currentPromotionPropertyList.indexOf(parseInt(property.property_id));
						propertiesAssigned[idx].title = property.property_id +" - "+ property.title;
					}
				});
			}
			// find all properties that are orphaned due to the current selected organizations list
			// save the index position of the properties in a list for removal
			let removal = [];
			for (let index = 0; index < propertiesAssigned.length; index++) {
				if(Number.isInteger(propertiesAssigned[index].title)){
					let idx = copyPromotionData.properties.indexOf(parseInt(properties[index].title));
					removal.push(idx);            
				};
			};
			// we need to start from the end for this to work.
			// reverse the removal array
			// remove properties from the selectable list and the promotion
			removal.reverse();
			removal.forEach((position) => {
				currentPromotionPropertyList.splice(position, 1);
				propertiesAssigned.splice(position, 1);
			});

			this.setState({ 
				...properties,
				properties: propertiesAssigned,
				propertyLoader:false
			}) 
		} catch(e) {
			this.setState({ 
				propertyLoader:false	
			});
		};

		return properties;
	}

	toggleAllProperties = (val) => {
		const { promotionData } = this.state;

		this.setState({
			promotionData: {
				...promotionData,
				qualify_all_properties: val
			}
		});
	}

	allPropertiesChecked = (val) => {
		const { promotionData } = this.state;

		if(promotionData.qualify_all_properties === val) {
			return true;
		};

		return null;
	}  

	toggleAccountAdjustment = (val) => {
		const { promotionData } = this.state;

		this.setState({
			promotionData: {
				...promotionData,
				accounting_adjustment: val
			}
		});
	}

	accountAdjustmentChecked = (val) => {
		const { promotionData } = this.state;

		if(promotionData.accounting_adjustment === val) {
			return true;
		};

		return null;
	} 

	showModal = (name) => {
		this.setState({ 
			showModal: name
		});
	}
	
	closeModal = () => {
		this.setState({ 
			showModal: null
		}, () => {
			const {
				error,
				promotionData
			} = this.state;

			if(error == false && promotionData.id){
				this.props.history.push(`/account-management/promotions/${promotionData.id}/edit`);
			};
		});
	}
	/**
     * Select an option to continue editing will invoke this function.
     * @param {} e 
     */
    handleContinue = (e) => {
		const { mode } = this.state;

        if(mode === 'edit') {
            e.preventDefault();
            this.closeModal();
        };
	}

    render() {
		const {
			init,
			mode,
			days,
			activeKey,
			isLoading,
			properties,
			promotionData,
			propertyLoader,
			requiredValidation,
			selectableChannles,
			channelSelected,
			bookingcomSelected,
			check_in_allowed,
			check_out_allowed,
			bookDateType,
			startDate,
			endDate,
			focusedInput,
			bookingdaysmap,
			bookinghoursmap,
			stayStartDate,
			stayEndDate,
			stayFocusedInput,
			showModal,
			progress,
			error,
			progressStyle,
			errorMessage,
			excludeStartDate,
			excludeEndDate,
			excludeFocusedInput,
			minnights,
			maxnights
		} = this.state;

		const airbnbNewListingPromo = promotionData.segments.filter(segment => segment.identifier == 'NEW_LISTING_PROMOTION');
		const airbnbNewListingDisabled = airbnbNewListingPromo.length ? true : false;
	
		return (
			<div className="container-fluid">                      
				<div id='content' className='promotionsform'>
					<div className="container-fluid">

						{isLoading && <Loader />}

						<Title
							title={(mode =='add' ? strings.create_promotion : strings.edit_promotion + promotionData.id +' ')}
							isCentered={true}
							removeOrganizationDropdown={true}
							addDefaultTitle=""
							breadcrumbItems={this.props.breadcrumbItems}
						>
							<div className="top-left-content">
								<div className="property-status">
									<span>{strings.status}</span>
									<div 
										className={
											"primary-btn active-btn live-btn" + 
											(promotionData.status != 'LIVE'  ? ' not-live': ' is-live')} 
										onClick={this.handleChangeStatus}
									>
										{promotionData.status != 'LIVE' ? 'Not Live': 'Live'}
										<i className="icon icon-selection_tip"></i>
									</div>
								</div>
							</div>
							{
								mode=='edit' &&
								<DropdownButton  
									title={'Actions'}
									className="primary-btn white-btn"
									id="promotion-actions"
								>
									<li role="presentation">
										<Link to={{ pathname: "/account-management/promotions/create", state: { cloneData: promotionData } }}>{strings.clone_promotion}</Link>  
									</li>
								</DropdownButton>
							}
							<Link 
								to={'/account-management/promotions'} 
								className="primary-btn black-btn pull-left left-margin">
								{strings.cancel}
							</Link>
							<PrimaryButton 
								cssClass="pull-left left-margin" 
								fullWidth={false} 
								type="button" 
								onClick={this.handleFormSubmit}
							>
									{strings.save}
							</PrimaryButton>
						</Title>
						<div id="content">
                         	<div className="property-item row">
									<Form horizontal>
										<Row>
										<Col md={12} xs={12} sm={12} lg={12}>
											<PanelGroup 
												accordion id="accordion-controlled-example" 
												defaultActiveKey={init ? '1' : '1'} 
												onSelect={this.handleSelect}
											>
												<Panel eventKey='1'>
													<Panel.Heading>
														<Panel.Title 
															toggle
															onClick={e => {this.toggleGlyph(e, "1")}}
														> 
															{strings.promotion_details}
																<span 
																	className={'pull-right '+ (activeKey === '1' || init ? 'glyphicon glyphicon-chevron-up': 'glyphicon glyphicon-chevron-down')}>
																</span>
														</Panel.Title>
													</Panel.Heading>
													<Panel.Body collapsible>
														<Row className='bottom-margin'>
															<Col md={12} xs={12} sm={12} lg={12}>
																<InputLabel 
																	htmlFor="name"
																	className={requiredValidation.name || requiredValidation.promotionName ? 'form-validation' : ''}
																>
																	{strings.promotion_name} <span>(must be 20 characters or less)</span>
																</InputLabel>
																<InputField 
																	type="text"
																	className={requiredValidation.name || requiredValidation.promotionName ? 'form-validation' : ''}
																	placeholder=""
																	name="name" 
																	defaultValue={promotionData.name}
																	onChange={this.handleOnChange}
																	maxLength={20}
																/>
																{
																	requiredValidation.name && 
																	<span className="required-error-text">
																		{strings.formatString(strings.field_is_required, strings.promotion_name)}
																	</span>
																}
																{
																	requiredValidation.promotionName && 
																	<span className="required-error-text">
																		{requiredValidation.promotionNameErrorMessage}
																	</span>
																}
															</Col>
														</Row>
														<Row className='bottom-margin'>
															<Col md={12} xs={12} sm={12} lg={12}>																
																{/* Promotion Details */}
																<InputLabel 
																	htmlFor="details"
																	className={requiredValidation.details ? 'form-validation' : ''}
																>
																	{strings.promotion_details}
																</InputLabel>
																<InputField 
																	cssClass="text-area" 
																	type="textarea"
																	className={requiredValidation.details ? 'form-validation' : ''}
																	placeholder=""
																	name="details" 
																	defaultValue={promotionData.details}
																	onChange={this.handleOnChange}
																/>
																{
																	requiredValidation.details && 
																	<span className="required-error-text">
																		{strings.formatString(strings.field_is_required, strings.promotion_details)}
																	</span>
																}
															</Col>
														</Row>
														<Row>
															<Col md={12} xs={12} sm={12} lg={12}>
																<InputLabel 
																	htmlFor="properties"
																>
																	{strings.select_properties}
																</InputLabel>
																</Col>
														</Row>
														{
															mode == 'edit' && 
															(this.initPromotionData.qualify_all_properties == 1) &&
															<Row key="row-all-properties">
																<Col md={6} key="col-all-properties">
																	<InputField
																		cssClass="form-check-label checkbox-label"
																		type="radio"
																		name={strings.all_properties}
																		value={this.allPropertiesChecked(1)}
																		label={strings.all_properties}
																		cbChange={e => {this.toggleAllProperties(1)}}
																		key="1"
																	>
																		{strings.all_properties}
																	</InputField>
																</Col>
															</Row>
														}
														<Row className='bottom-margin' key="row-specific-properties">
															<Col md={6} key="col-specific-properties">
																<InputField
																	cssClass="form-check-label checkbox-label"
																	type="radio"
																	name={strings.specific_properties_by_organization}
																	value={this.allPropertiesChecked(0)}
																	label={strings.specific_properties_by_organization}
																	cbChange={e => {this.toggleAllProperties(0)}}
																	key="0"
																>
																	{strings.specific_properties_by_organization}
																</InputField>
															</Col>
														</Row>
														{
															(promotionData.qualify_all_properties ==0) &&
															<Row className='bottom-margin'>
																<Col md={12} xs={12} sm={12} lg={12} className={propertyLoader ? 'muted': ''}>
																	{/* Organization */}
																	<InputLabel 
																		cssClass="form-label"
																		htmlFor="organizations"
																		className={requiredValidation.organizations ? 'form-validation' : ''}
																	>
																		{strings.organizations}
																	</InputLabel>
																	<Picky
																		className={requiredValidation.organizations ? 'form-validation' : ''}
																		placeholder={""}
																		labelKey="title"
																		valueKey="organization_id"
																		options={this.props.expanded_organizations}
																		value={this.getOrganizationsAssigned()}
																		multiple={true}
																		includeSelectAll={false}
																		includeFilter={true}
																		onChange={orgs => this.setOrganizations(orgs)}
																		onClose={orgs => this.loadProperties()}
																		dropdownHeight={600} 
																		filterDebounce={100}   
																		keepOpen={true}  
																		disabled={propertyLoader}
																	/>
																	{
																		propertyLoader ? 
																		<div className="property-loader">
																			<Loader />
																		</div> : null
																	}
																	{
																		requiredValidation.organizations && 
																		<span className="required-error-text">
																			{strings.formatString(strings.at_least_one_field_is_required, strings.organization)}
																		</span>
																	}
																</Col>
															</Row>
														}
														{
															promotionData.qualify_all_properties ==0 && 
															<Row className='bottom-margin'>
																<Col md={12} xs={12} sm={12} lg={12} className={propertyLoader ? 'muted': ''}>
																	{/* Property(s) */}
																	<InputLabel 
																		htmlFor="properties"
																		className={requiredValidation.properties && 'form-validation' ? 'form-validation' : ''} 
																	>
																		<span className={propertyLoader ? 'muted' : null}>{strings.properties} </span>
																	</InputLabel>
																<Picky
																		className={requiredValidation.properties ? 'form-validation' : ''}
																		placeholder={""}
																		labelKey="title"
																		valueKey="property_id"
																		options={properties}
																		value={this.getPropertiesAssigned()}
																		multiple={true}
																		includeSelectAll={true}
																		onChange={properties => this.setProperties(properties)}
																		dropdownHeight={600}
																		keepOpen={true}   
																		includeFilter={true}   
																		disabled={propertyLoader}
																	/> 
																	{/*propertyLoader*/}
																	{
																		propertyLoader && 
																		promotionData.qualify_all_properties == 0  ? 
																		<div className="property-loader">
																			<Loader />
																		</div> : null
																	}
																	{
																		requiredValidation.properties && 
																		<span className="required-error-text">
																			{strings.formatString(strings.at_least_one_field_is_required, strings.property)}
																		</span>
																	}
																</Col>
															</Row>
														}
														{this.configuredChannels()}
														<Row className='bottom-margin'>
															<Col md={12} xs={12} sm={12} lg={12}>
																{
																	selectableChannles.length > 0 &&
																	<div className="channels-div pull-left" md={12} xs={12} sm={12} lg={12}>
																		<Col md={5} xs={5} sm={5} lg={5}>															
																			{/* Channels(s) */}
																			<InputLabel
																				htmlFor="channels"
																				cssClass="form-label"
																				className={requiredValidation.channels ? 'form-validation' : ''}
																			>
																				{strings.channel}	
																			</InputLabel>
																			{this.createChannels()}
																			{
																				requiredValidation.channels && 
																				<span className="required-error-text">
																					{strings.formatString(strings.field_is_required, strings.channel)}
																				</span>
																			}
																		</Col>
																		<Col md={5} xs={5} sm={5} lg={5}>	
																			<InputLabel
																				htmlFor="segments"
																				className="form-label"
																			>
																				{strings.segments}
																			</InputLabel>
																			{this.createSegments()}
																			{this.createBookingcomSegments(channelSelected)}
																		</Col>
																	</div>
																} 
															</Col>
														</Row>
														<Row className='bottom-margin'>
															<Col md={10} xs={10} sm={10} lg={10}>
																{
																	selectableChannles.length > 0 &&
																	<FieldWrap>
																		<PrimaryButton
																			cssClass="pull-left blue-btn" 
																			fullWidth={false} 
																			type="button" 
																			onClick={(e) => {
																				this.addChannel(e, channelSelected)
																			}}
																		>
																			{strings.add_channel}
																		</PrimaryButton>
																	</FieldWrap>
																}
															</Col>
														</Row>
														<Row>
															<Col md={10} xs={10} sm={10} lg={10}>
																<InputLabel
																	htmlFor="accountingadjustments"
																	className="form-label"
																>
																	{strings.accounting_adjustments}
																</InputLabel>
															</Col>
														</Row>
														{
															mode == 'edit' && 
															(this.initPromotionData.accounting_adjustment == 'RA') &&
															<Row key="row-ra-promotion">
																<Col md={6} key="col-ra-promotion">
																	<InputField
																		cssClass="form-check-label checkbox-label"
																		type="radio"
																		name={strings.redawning_promotion}
																		value={this.accountAdjustmentChecked('RA')}
																		label={strings.redawning_promotion}
																		cbChange={e => {this.toggleAccountAdjustment('RA')}}
																		key="1"
																	>
																		{strings.redawning_promotion}
																	</InputField>
																</Col>
															</Row>
														}
														<Row className='bottom-margin' key="row-pm-promotion">
															<Col md={6} key="col-pm-promotion">
																<InputField
																	cssClass="form-check-label checkbox-label"
																	type="radio"
																	name={strings.manager_owner_promotion}
																	value={this.accountAdjustmentChecked('PM')}
																	label={strings.manager_owner_promotion}
																	cbChange={e => {this.toggleAccountAdjustment('PM')}}
																	key="0"
																>
																	{strings.manager_owner_promotion}
																</InputField>
															</Col>
														</Row>
													</Panel.Body>
												</Panel>												
												<Panel eventKey={init ? "1" : "2"}>
													<Panel.Heading>
														<Panel.Title 
															toggle
															onClick={e => {this.toggleGlyph(e, "2")}}
														> 
															{strings.promotion_configuration}
															<span 
																className={'pull-right '+(activeKey === '2' || init ? 'glyphicon glyphicon-chevron-up': 'glyphicon glyphicon-chevron-down')}>
															</span>
														</Panel.Title>
													</Panel.Heading>
													<Panel.Body collapsible>
														<Row className='bottom-margin'>
															<Col md={12} xs={12} sm={12} lg={12}>
																{/* Promotion Type */}
																<InputLabel 
																		htmlFor="promotiontype"
																		className={requiredValidation.promotionType || requiredValidation.expediaPromotionType  ? 'form-validation' : ''}
																	>
																	{strings.promotion_type}
																</InputLabel>
																{this.createPromotionTypes()}
																{
																	requiredValidation.promotionType ? 
																	<span className="required-error-text">
																		{requiredValidation.promotionTypeErrorMessage}
																	</span> : ''
																}
																{
																	requiredValidation.expediaPromotionType ? 
																	<span className="required-error-text">
																		{strings.expedia_promotion_type}
																	</span> : ''
																}
															</Col>
														</Row>
														{
															promotionData.type === "DD" && 
															<Row className='bottom-margin'>
																<Col md={12} xs={12} sm={12} lg={12}>
																	<InputLabel 
																		htmlFor="discountType"
																		className={requiredValidation.discountType || requiredValidation.expediaDiscountType ? 'form-validation' : ''}
																	>
																		{strings.discount_type}
																	</InputLabel>
																	{this.createDiscountTypes()}
																	{
																		requiredValidation.discountType ? 
																		<span className="required-error-text">
																			{requiredValidation.discountTypeErrorMessage ? requiredValidation.discountTypeErrorMessage : ""}
																		</span> : ""
																	}
																	{
																		requiredValidation.expediaDiscountType ? 
																		<span className="required-error-text">
																			{strings.expedia_discount_type}
																		</span> : ""
																	}
																	<br/>
																	<InputLabel 
																		htmlFor="discountValue"
																		className={
																			requiredValidation.bookingcomMinMaxValue ||
																			requiredValidation.discountValue || 
																			requiredValidation.expediaDiscountValue ||
																			requiredValidation.expediaDiscountValueInteger ? 'form-validation' : ''
																		}
																	>
																		{strings.discount_value}
																	</InputLabel>
																	<InputField 
																		type="text"
																		className={
																			requiredValidation.bookingcomMinMaxValue ||
																			requiredValidation.discountValue || 
																			requiredValidation.expediaDiscountValue ||
																			requiredValidation.expediaDiscountValueInteger ? 'form-validation' : ''
																		}
																		placeholder=""
																		name="discountValue" 
																		defaultValue={promotionData.value}
																		onChange={this.handleDiscountValueChange}
																		disabled={airbnbNewListingDisabled}
																	/>

																	{
																		(requiredValidation.discountValue || requiredValidation.expediaDiscountValueInteger) ? 
																		<span className="required-error-text">
																			{
																				requiredValidation.discountValueErrorMessage ? 
																				requiredValidation.discountValueErrorMessage : 
																				strings.discount_value_is_required
																			}
																		</span> : ''
																	}
																	{
																		requiredValidation.expediaDiscountValue &&
																		<span className="required-error-text">
																			{strings.expedia_discount_value}
																		</span>
																	}
																	{
																		requiredValidation.bookingcomMinMaxValueErrorMessage &&
																		<span className="required-error-text">
																			{strings.bookingcom_min_max_percent_requirement}
																		</span>
																	}
																</Col>
															</Row>
														}
														{
															promotionData.type === "NF" && 
															<Row className='bottom-margin'>
															<Col md={12} xs={12} sm={12} lg={12}>
																<InputLabel 
																		htmlFor="discountValue"
																		className={requiredValidation.discountValue ? 'form-validation' : ''}
																	>
																	{strings.additional_nights_free}
																</InputLabel>
																<InputField 
																	htmlFor="discountValue"
																	type="select" 
																	name="discountValue"
																	value={promotionData.value}
																	onChange={this.handleDiscountValueChange}>
																		{
																			days.map((value, index) => (
																				<option key={index + 1} value={index + 1}>{index + 1}</option>
																			))
																		}
																	</InputField>
																	{
																		requiredValidation.discountValue && 
																		<span className="required-error-text">
																			{strings.additional_nights_is_required}
																		</span>
																	}
															</Col>
														</Row>}
														<Row className='bottom-margin'>
															<Col md={12} xs={12} sm={12} lg={12}>
																<InputLabel 
																	htmlFor="minimumNights"
																	className={`${(requiredValidation.minimumNights || requiredValidation.expediaMinimumNights ? 'form-validation' : '')} ${bookingcomSelected ? 'text-disabled' : ''}`}
																>
																	{strings.minimum_paid_nights}
																</InputLabel>
																{(bookingcomSelected) && infoPopover("Booking.com does not support Minimum Nights(s).", null, "top", "icon-info")}
																<InputField 
																	className={requiredValidation.minimumNights ? 'form-validation' : ''}
																	htmlFor="minimumNights"
																	type="select" 
																	name="MINIMUMNIGHTS"
																	value={this.getRuleValue('MINIMUMNIGHTS')}
																	onChange={this.setRuleValue}
																	disabled={bookingcomSelected || airbnbNewListingDisabled}
																>
																	{
																		minnights.map((value, index) => (
																			<option key={index + 1} value={index + 1}>{index + 1}</option>
																		))
																	}
																</InputField>
																{
																	requiredValidation.minimumNights && 
																	<span className="required-error-text">
																		{
																			requiredValidation.minimumNightsErrorMessage ? 
																			requiredValidation.minimumNightsErrorMessage : 
																			strings.formatString(strings.field_is_required, strings.minimum_paid_nights)
																		}
																	</span>
																}
																{
																	requiredValidation.expediaMinimumNights && 
																	<span className="required-error-text">
																		{strings.expedia_minimum_nights}
																	</span>
																}
															</Col>
														</Row>
														<Row className='bottom-margin'>
															<Col md={12} xs={12} sm={12} lg={12}>
																<InputLabel 
																	htmlFor="maximumNights"
																	className={`${(requiredValidation.maximumNights || requiredValidation.expediaMaximumNights ? 'form-validation' : '')} ${bookingcomSelected ? 'text-disabled' : ''}`}
																>
																	{strings.formatString(strings.field_optional, strings.maximum_paid_nights)}
																</InputLabel>
																{(bookingcomSelected) && infoPopover("Booking.com does not support Maximum Nights(s)", null, "top", "icon-info")}
																<InputField 
																	className={requiredValidation.maximumNights ? 'form-validation' : ''}
																	htmlFor="maximumNights"
																	type="select" 
																	name="MAXIMUMNIGHTS"
																	value={this.getRuleValue('MAXIMUMNIGHTS')}
																	onChange={this.setRuleValue}
																	disabled={bookingcomSelected || airbnbNewListingDisabled}
																>
																	<option value="0"></option>
																	{
																		maxnights.map((value, index) => (
																			<option key={index + 1} value={index + 1}>{index + 1}</option>
																		))
																	}
																</InputField>
																{
																	requiredValidation.maximumNights && 
																	<span className="required-error-text">
																		{
																			requiredValidation.maximumNightsErrorMessage ? 
																			requiredValidation.maximumNightsErrorMessage : ''
																		}
																	</span>
																}
																{
																	requiredValidation.expediaMaximumNights && 
																	<span className="required-error-text">
																		{strings.expedia_maximum_nights}
																	</span>
																}
															</Col>
														</Row>
														<br/>
														{
															(promotionData &&
															promotionData.channels &&
															promotionData.channels.includes('c067ad13-7a4a-4ac1-9e56-f683f01edee2')) &&
															<Row>
																<Col md={12} xs={12} sm={12} lg={12}>
																	<ul className="promo-bulletin">
																		<li>Expedia does not support check-in/check-out allowed and is ignored on sync.</li>
																	</ul>
																</Col>
															</Row>
														}
														<Row>
															<Col md={12} xs={12} sm={12} lg={12}>
																<FieldWrap>
																	<InputLabel 
																		htmlFor='check_in_allowed'
																		className={requiredValidation.check_in_allowed ? 'form-validation' : ''}
																	>
																		{strings.check_in_allowed}
																	</InputLabel>
																	<Row className='seven-cols'>
																		{
																			Object.entries(check_in_allowed).map((day,id)=>{	
																				return (
																				<Col xs={2} sm={1} key={id}>
																					<div className={`badge-checkbox ${airbnbNewListingDisabled ? 'checkbox-disabled' : ''}`}>
																						<input 
																							onChange={e=>this.handleBadgeChange(e)} 
																							checked={day[1]} 
																							id={'checkin_'+day[0]} 
																							type='checkbox' 
																							name='check_in_allowed' 
																							value={day[0]}
																							disabled={airbnbNewListingDisabled}
																						/>
																						<label htmlFor={'checkin_'+day[0]}>{day[0].substr(0,3)}</label>
																					</div>
																				</Col>
																			)})
																		}
																	</Row>
																</FieldWrap>
															</Col>
														</Row>
														<Row className='bottom-margin'>
															<Col md={12} xs={12} sm={12} lg={12}>
																<FieldWrap>
																	<InputLabel 
																		htmlFor='check_out_allowed'
																		className={requiredValidation.check_out_allowed ? 'form-validation' : ''}
																	>
																		{strings.check_out_allowed}
																	</InputLabel>
																	<Row className='seven-cols'>
																		{
																			Object.entries(check_out_allowed).map((day,id)=>{
																				return (
																				<Col xs={2} sm={1} key={id}>
																					<div className={`badge-checkbox ${airbnbNewListingDisabled ? 'checkbox-disabled' : ''}`}>
																						<input 
																							onChange={e=>this.handleBadgeChange(e)} 
																							checked={day[1]} 
																							id={'checkout_'+day[0]} 
																							type='checkbox' 
																							name='check_out_allowed' 
																							value={day[0]}
																							disabled={airbnbNewListingDisabled}
																						/>
																						<label htmlFor={'checkout_'+day[0]}>{day[0].substr(0,3)}</label>
																					</div>
																				</Col>
																			)})
																		}
																	</Row>
																</FieldWrap>
															</Col>
														</Row>
														<Row>
															<Col md={12} xs={12} sm={12} lg={12}>
																<FieldWrap>
																	<InputLabel 
																		htmlFor='checkout'
																		className={requiredValidation.bookdates || requiredValidation.expediaBookDates ? 'form-validation' : ''}
																	>
																	{strings.book_dates}
																	</InputLabel>
																	{this.createDateTypes()}
																</FieldWrap>
																{
																	requiredValidation.bookdates && 
																	<span className="required-error-text">
																		{requiredValidation.bookdatesErrorMessage}
																	</span>
																}
																{
																	requiredValidation.expediaBookDates && 
																	<span className="required-error-text">
																		{strings.expedia_book_dates}
																	</span>
																}
															</Col>
														</Row>
														{
															bookDateType === "BOOKDATES" && 
															<Row>
																<Col md={5} xs={5} sm={5} lg={5} className="check_right check_bottom check-in">
																	<InputLabel 
																		htmlFor='startbookdate'
																		className={requiredValidation.bookdates ? 'form-validation' : ''}
																	>
																		{strings.start}
																	</InputLabel>
																</Col>
																<Col md={5} xs={5} sm={5} lg={5} className="check_right check_bottom check-in">
																	<InputLabel 
																		htmlFor='startbookdate'
																		className={requiredValidation.bookdates ? 'form-validation' : ''}
																	>
																		{strings.end}
																	</InputLabel>
																</Col>
															</Row>
														}
														<Row className='bottom-margin'>
															<Col md={10} xs={10} sm={10} lg={10}>
																<FieldWrap>

																	{/* BETWEEN - BOOKDATES*/}
																	{
																		bookDateType === "BOOKDATES" && 
																		<div className={requiredValidation.bookdates ? 'form-validation' : ''}>
																			<DateRangePicker
																				/* need error state */
																				noBorder
																				startDate={startDate}
																				startDateId="startbookdate"
																				startDatePlaceholderText="mm/dd/yyyy"
																				endDate={endDate}
																				endDateId="endbookdate"
																				endDatePlaceholderText="mm/dd/yyyy"
																				onDatesChange={({ startDate, endDate }) => this.handleCalendarDatesChange(startDate, endDate)}
																				focusedInput={focusedInput}
																				onFocusChange={focusedInput => this.setState({ focusedInput }, function(){ })}
																				onClose={onClose => this.handleCalendarDatesOnClose()}
																				disabled={airbnbNewListingDisabled}
																			/>
																		</div>
																	}
																	{
																		(bookDateType === "BOOKDATES" && 
																		requiredValidation.bookdates) && 
																		<span className="required-error-text">
																			{
																				requiredValidation.bookdatesErrorMessage ? 
																				requiredValidation.bookdatesErrorMessage : 
																				strings.formatString(strings.fields_are_required, strings.book_dates)
																			}
																		</span>
																	}
																	{/* EARLY BIRD - BOOKDAYS*/}
																	{
																		bookDateType === "BOOKDAYS" && 
																		<InputLabel htmlFor='BOOKDAYS' className={requiredValidation.bookdays ? 'form-validation' : ''}>
																			{strings.days_before_check_in_date}
																		</InputLabel>
																	}
																	{
																		bookDateType === "BOOKDAYS" && 
																		<InputField 
																			type="select" 
																			className={requiredValidation.bookdays ? 'form-validation' : ''}
																			name="BOOKDAYS"
																			value={this.getRuleValue('BOOKDAYS')}
																			onChange={e=>this.handleDatesChange(e)}
																			disabled={airbnbNewListingDisabled}
																		>
																			<option value="0"></option>
																			{
																				bookingdaysmap.map((value, index) => (
																					<option key={index + 1} value={index + 1}>{index + 1}</option>
																				))
																			}
																		</InputField>
																	}
																	{
																		(bookDateType === "BOOKDAYS" && 
																		requiredValidation.bookdays) && 
																		<span className="required-error-text">
																			{
																				requiredValidation.bookdaysErrorMessage ? 
																				requiredValidation.bookdaysErrorMessage : 
																				strings.formatString(strings.field_is_required, strings.days_before_check_in_date)
																			}
																		</span>
																	}

																	{/* LAST MINUTE - BOOKHOURS*/}
																	{
																		bookDateType === "BOOKHOURS" && 
																		<InputLabel htmlFor='BOOKHOURS' className={requiredValidation.bookhours ? 'form-validation' : ''}>
																			{strings.within_hours_days_of_check_in_time}
																		</InputLabel>
																	}

																	{
																		bookDateType=== "BOOKHOURS" &&
																		<InputField 
																			type="select" 
																			className={requiredValidation.bookhours ? 'form-validation' : ''}
																			name="BOOKHOURS"
																			value={this.getRuleValue('BOOKHOURS')}
																			onChange={e=>this.handleDatesChange(e)}
																			disabled={airbnbNewListingDisabled}
																		>
																			<option value="0"></option>
																			{
																				bookinghoursmap.map((obj) => (
																					<option key={obj.key} value={obj.value}>{obj.title}</option>
																				))
																			}
																		</InputField>
																	}
																	{
																		(bookDateType=== "BOOKHOURS" && 
																		requiredValidation.bookhours) && 
																		<span className="required-error-text">
																			{
																				requiredValidation.bookhoursErrorMessage ? 
																				requiredValidation.bookhoursErrorMessage : 
																				strings.formatString(strings.field_is_required, strings.within_hours_days_of_check_in_time)
																			}
																		</span>
																	}
																</FieldWrap>
															</Col>
														</Row>
														<Row className='bottom-margin'>
															<Col md={12} xs={12} sm={12} lg={12}>
															<InputLabel 
																className={requiredValidation.staydates || requiredValidation.expediaStayDates ? 'form-validation' : ''} 
																htmlFor='staydates'
															>
																	{requiredValidation.staydatesErrorMessage ? strings.stay_dates : strings.formatString(strings.field_optional, strings.stay_dates)}
															</InputLabel>
															</Col>
														</Row>
														<Row>
															<Col md={5} xs={5} sm={5} lg={5} className="check_right check_bottom check-in">
																<InputLabel 
																	className={requiredValidation.staydates ? 'form-validation' : ''} 
																	htmlFor='startbookdate'
																>
																		{strings.start_night_of_check_in}
																</InputLabel>
															</Col>
															<Col md={5} xs={5} sm={5} lg={5} className="check_right check_bottom check-in">
																<InputLabel 
																	className={requiredValidation.staydates ? 'form-validation' : ''}
																	htmlFor='endbookdate'
																>
																	{strings.end_last_night_of_stay}
																</InputLabel>
															</Col>
														</Row>									
														<Row className='bottom-margin'>
															<Col md={10} xs={10} sm={10} lg={10}>
																<FieldWrap>
																	{
																		<div className={requiredValidation.staydates ? 'form-validation' : undefined}>
																			<DateRangePicker
																				noBorder
																				startDate={stayStartDate}
																				startDateId="startstaydate"
																				startDatePlaceholderText="mm/dd/yyyy"
																				endDate={stayEndDate}
																				endDateId="endstaydate"
																				endDatePlaceholderText="mm/dd/yyyy"
																				onDatesChange={({ startDate, endDate }) => this.handleCalendarStayDateChange(startDate, endDate)}
																				focusedInput={stayFocusedInput}
																				onFocusChange={stayFocusedInput => this.setState({ stayFocusedInput })}
																				onClose={onClose=> this.handleCalendarStayDateOnClose()}
																				disabled={airbnbNewListingDisabled}
																			/>
																		</div>
																	}
																</FieldWrap>
																{
																	requiredValidation.staydates && 
																	<span className="required-error-text">
																		{
																			requiredValidation.staydatesErrorMessage ? 
																			requiredValidation.staydatesErrorMessage : 
																			strings.formatString(strings.fields_are_required, strings.stay_dates)
																		}
																	</span>
																}	
																{
																	requiredValidation.expediaStayDates && 
																	<span className="required-error-text">
																		{strings.expedia_stay_dates}
																	</span>
																}
															</Col>
														</Row>
														<Row className='bottom-margin'>
															<Col md={12} xs={12} sm={12} lg={12}>
																<InputLabel 
																	className={requiredValidation.excludeddates ? 'form-validation' : ''}
																	htmlFor='excludeddates'
																>
																	{strings.formatString(strings.field_optional, strings.blackout_dates)}
																</InputLabel>
															</Col>
														</Row>
														<div className={requiredValidation.excludeddates ? 'form-validation' : ''}>
															{this.excludedDateRender()}
														</div>
														{
															requiredValidation.excludeddates && 
															<span className="required-error-text">
																{
																	requiredValidation.excludeddatesErrorMessage ? 
																	requiredValidation.excludeddatesErrorMessage : ''
																}
															</span>
														}
														<Row>
															<Col md={5} xs={5} sm={5} lg={5} className="check_right check_bottom check-in">
																<InputLabel 
																	className={requiredValidation.excludeddates ? 'form-validation' : ''} 
																	htmlFor='startexcluded'>
																		{strings.start_night_of_check_in}
																</InputLabel>
															</Col>
															<Col md={5} xs={5} sm={5} lg={5} className="check_right check_bottom check-in">
																<InputLabel 
																	className={requiredValidation.excludeddates ? 'form-validation' : ''} 
																	htmlFor='endexcluded'
																>
																		{strings.end_last_night_of_stay}
																</InputLabel>
															</Col>
														</Row>
														<Row className='bottom-margin'>
															<Col md={10} xs={10} sm={10} lg={10}>
																<FieldWrap>
																	{
																		<div className={requiredValidation.excludeddates ? 'form-validation' : undefined}>
																			<DateRangePicker
																				noBorder
																				startDate={excludeStartDate} 
																				startDateId="startexcluded"
																				startDatePlaceholderText="mm/dd/yyyy"
																				endDate={excludeEndDate}
																				endDateId="endexcluded"
																				endDatePlaceholderText="mm/dd/yyyy"
																				onDatesChange={({ startDate, endDate }) => this.handleCalendarExcludeDateChange(startDate, endDate)}
																				focusedInput={excludeFocusedInput}
																				onFocusChange={excludeFocusedInput => this.setState({ excludeFocusedInput })}
																				isOutsideRange={(date)=> { return this.handleExcludeDateOutOfRange(date)}}
																				initialVisibleMonth={moment(stayStartDate).isValid() ? () => moment(stayStartDate) : null}
																				disabled={airbnbNewListingDisabled}
																			/>
																		</div>
																	}
																</FieldWrap>
															</Col>
														</Row>
														<Row>
															<Col md={10} xs={10} sm={10} lg={10}>
																<FieldWrap>
																	<PrimaryButton
																		cssClass="pull-left blue-btn" 
																		fullWidth={false} 
																		type="button" 
																		onClick={this.applyExcludeDate}
																		disabled={airbnbNewListingDisabled}
																	>
																		{strings.add_blackout_dates}
																	</PrimaryButton>
																</FieldWrap>
															</Col>
														</Row>
													</Panel.Body>
												</Panel>
											</PanelGroup>
										</Col>
									</Row>
									<Modal 
										backdrop="static" 
										className="submit-modal lg-modal promotion-modal" 
										show={showModal === 'submit-modal'} 
										onHide={this.closeModal}
									>
										<Modal.Header closeButton>
											<Modal.Title>
												{
													progress == 100 ? 
													!error ? 
														<span className='status-icon status-icon--success'>
														</span> : 
														<span className='status-icon status-icon--error'>
														</span> : 
														<div className="submit-loader">
															<Loader />
														</div>
												}
												<span 
													className="submit-heading">
													{
														error ? 
														'Error' : 
														(progress == 100 ? strings.promotion_saved : strings.saving_promotion)
													}
												</span>
											</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											<div className='progress-wrapper'>
												<ProgressBar 
													bsStyle={progressStyle} 
													now={progress} 
												/>
												<h5 className='progress-percent pull-right'>
													{parseInt(progress)}%
												</h5>
											</div>
												<p>
													{errorMessage ? errorMessage: null}
												</p>
												<div className="modal-btns">
												{
													progress == 100 && 
													!error && 
													<Link
														to={"/account-management/promotions"} 
														className='primary-btn white-btn pull-left'>
														{strings.view_all_promotions}
													</Link>
												}
												{
													progress==100 && 
													!error &&
													<Link 
														to={`/account-management/promotions/${promotionData.id}/edit`} 
														onClick={this.handleContinue} 
														className='primary-btn left-margin pull-right'>
														{strings.continue_editiong}
													</Link>
												}
												</div>
										</Modal.Body>
									</Modal>
								</Form>
							</div>
						</div>
					</div>
				</div>
			</div>
        ) 
    }      
}

const mapStateToProps = state => {
    return {
		org: state.roleManager.org,
		expanded_organizations: state.authState.user.expanded_organization_roles,
    }
}

export default withRouter(connect(
    mapStateToProps,
    null
)(PromotionsForm));

export const updatePromotion = promotionData => {
    return {
        type: UPDATE_PROMOTION_DATA,
        promotionData
    }
}