const updateUnsavedData = (state = {unsavedData:false}, action) => {
    switch (action.type) {
        case 'UPDATE_UNSAVED_DATA_STATE':
            return Object.assign({}, state, {
                unsavedData: action.unsavedData
            });            
        default:
            return state
    }
}
  
export default updateUnsavedData