import React, { Component, Fragment } from 'react';

import { InputLabel, InputField, PrimaryButton } from '../global/forms/FormElements';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import Panel from 'react-bootstrap/lib/Panel';
import { PanelGroup } from 'react-bootstrap';
import Modal from 'react-bootstrap/lib/Modal';
import moment from 'moment';


import Loader from '../global/Loader';

export default class CreateReservation extends Component {

    render () {
        const {
            quoteData,
            guestData,
            bookNowSuccess,
            bookNowErrMsg,
            bookNowIsLoading,
            showModal,
            showSuccessMessage,
            validations,
            mobileMode,
            reservationData
        } = this.props;

        return(
            <Modal
                className={`${mobileMode ? 'mobile' : ''} reservation-modal`}
                show={showModal} 
                onHide={(e) => this.props.handleGuestInfoModal(e, false, mobileMode)}
                backdrop="static"
            >
            <Modal.Body>
                {
                    bookNowIsLoading && 
                    <Loader />
                }
                <Row>
                    <Modal.Header closeButton>
                        <Modal.Body>
                            <Row>
                                <Col md={12} sm={12} xs={12}>
                                    <div className="reservation-summary">
                                        <h1>{quoteData.listing_id}</h1>
                                        <div><strong>Check-in:</strong> &nbsp;  {moment(quoteData.checkin_date).format('dddd,  MMM  DD')}</div>
                                        <div><strong>Check-out:</strong> &nbsp;  {moment(quoteData.checkout_date).format('dddd,  MMM  DD')}</div>
                                        <div><strong>{moment(quoteData.checkout_date).diff(moment(quoteData.checkin_date), 'days')}</strong>-Night Stay</div>
                                    </div>
                                </Col>
                            </Row>
                            {
                                this.props.ratesSummary(true, mobileMode)
                            }
                            {
                                (!bookNowSuccess && !showSuccessMessage) ?
                                <Fragment>
                                    <Row>
                                        <Col md={12} xs={12} sm={12} lg={12}>
                                            <PanelGroup
                                                id="reservation-panel"
                                                defaultActiveKey={1}
                                                onSelect={activeKey => this.panelOnSelect(activeKey)}
                                            >
                                                    <Panel eventKey="1">
                                                        <Panel.Heading>
                                                            <Panel.Title>
                                                                Guest Information
                                                            </Panel.Title>
                                                        </Panel.Heading>
                                                        <Panel.Body>
                                                            <Row>
                                                                <Col md={12} sm={12} xs={12} className="bottom-margin-10">
                                                                    <Col md={6} sm={6} xs={6}>
                                                                        <InputLabel
                                                                            className={validations.first_name && 'form-validation'}
                                                                        >
                                                                            First Name 
                                                                        </InputLabel>
                                                                        <InputField 
                                                                            type="text" 
                                                                            value={guestData.first_name} 
                                                                            name="first_name"
                                                                            onChange={(e) =>this.props.handleGuestDataOnChange(e, mobileMode)}
                                                                        >
                                                                        </InputField>
                                                                        {
                                                                            validations.first_name &&
                                                                            <span
                                                                                className={validations.first_name && 'required-error-text'}
                                                                            >
                                                                                First Name is required.
                                                                            </span>
                                                                        }
                                                                    </Col>
                                                                    <Col md={6} sm={6} xs={6}>
                                                                        <InputLabel
                                                                            className={validations.last_name && 'form-validation'}
                                                                        >
                                                                            Last Name 
                                                                        </InputLabel>
                                                                        <InputField 
                                                                            type="text" 
                                                                            value={guestData.last_name} 
                                                                            name="last_name"
                                                                            onChange={(e) =>this.props.handleGuestDataOnChange(e, mobileMode)}
                                                                        >
                                                                        </InputField>
                                                                        {
                                                                            validations.last_name &&
                                                                            <span
                                                                                className={validations.last_name && 'required-error-text'}
                                                                            >
                                                                                Last Name is required.
                                                                            </span>
                                                                        }
                                                                    </Col>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={12} sm={12} xs={12} className="bottom-margin-10">
                                                                    <Col md={6} sm={6} xs={6}>
                                                                        <InputLabel
                                                                            className={validations.phone && 'form-validation'}
                                                                        >
                                                                            Phone Number
                                                                        </InputLabel>
                                                                        <InputField 
                                                                            type="text" 
                                                                            value={guestData.phone} 
                                                                            name="phone"
                                                                            onChange={(e) =>this.props.handleGuestDataOnChange(e, mobileMode)}
                                                                        >
                                                                        </InputField>
                                                                        {
                                                                            validations.phone &&
                                                                            <span
                                                                                className={validations.phone && 'required-error-text'}
                                                                            >
                                                                                Phone Number is required.
                                                                            </span>
                                                                        }
                                                                    </Col>
                                                                    <Col md={6} sm={6} xs={6}>
                                                                        <InputLabel
                                                                            className={validations.cell_phone && 'form-validation'}
                                                                        >
                                                                            Cell Phone
                                                                        </InputLabel>
                                                                        <InputField 
                                                                            type="text" 
                                                                            value={guestData.cell_phone} 
                                                                            name="cell_phone"
                                                                            onChange={(e) =>this.props.handleGuestDataOnChange(e, mobileMode)}
                                                                        >
                                                                        </InputField>
                                                                        {
                                                                            validations.cell_phone &&
                                                                            <span
                                                                                className={validations.cell_phone && 'required-error-text'}
                                                                            >
                                                                                Cell Phone is required.
                                                                            </span>
                                                                        }
                                                                    </Col>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={12} sm={12} xs={12} className="bottom-margin-10">
                                                                    <Col md={6} sm={6} xs={6}>
                                                                        <InputLabel
                                                                            className={validations.email && 'form-validation'}
                                                                        >
                                                                            Email 
                                                                        </InputLabel>
                                                                        <InputField 
                                                                            type="text" 
                                                                            value={guestData.email} 
                                                                            name="email"
                                                                            onChange={(e) =>this.props.handleGuestDataOnChange(e, mobileMode)}
                                                                        >
                                                                        </InputField>
                                                                        {
                                                                            validations.email &&
                                                                            <span
                                                                                className={validations.email && 'required-error-text'}
                                                                            >
                                                                                Enter a valid Email.
                                                                            </span>
                                                                        }
                                                                    </Col>
                                                                </Col>
                                                            </Row>
                                                        </Panel.Body>
                                                    </Panel>
                                                    <Panel eventKey="1">
                                                        <Panel.Heading>
                                                            <Panel.Title>
                                                                Billing Address
                                                            </Panel.Title>
                                                        </Panel.Heading>
                                                        <Panel.Body>
                                                            <Row>
                                                                <Col md={12} sm={12} xs={12} className="bottom-margin-10">
                                                                    <Col md={6} sm={6} xs={6}>
                                                                        <InputLabel
                                                                            className={validations.postal_code && 'form-validation'}
                                                                        >
                                                                            Postal Code
                                                                        </InputLabel>
                                                                        <InputField 
                                                                            type="text" 
                                                                            value={guestData.address.postal_code} 
                                                                            name="postal_code"
                                                                            onChange={(e) =>this.props.handleGuestDataOnChange(e, mobileMode)}
                                                                        >
                                                                        </InputField>
                                                                        {
                                                                            validations.postal_code &&
                                                                            <span
                                                                                className={validations.postal_code && 'required-error-text'}
                                                                            >
                                                                                Postal Code is required.
                                                                            </span>
                                                                        }
                                                                    </Col>
                                                                    <Col md={6} sm={6} xs={6}>
                                                                        <InputLabel
                                                                            className={validations.country && 'form-validation'}
                                                                        >
                                                                            Country
                                                                        </InputLabel>
                                                                        <InputField 
                                                                            type="text" 
                                                                            value={guestData.address.country} 
                                                                            name="country"
                                                                            onChange={(e) =>this.props.handleGuestDataOnChange(e, mobileMode)}
                                                                        >
                                                                        </InputField>
                                                                        {
                                                                            validations.country &&
                                                                            <span
                                                                                className={validations.country && 'required-error-text'}
                                                                            >
                                                                                Country is required.
                                                                            </span>
                                                                        }
                                                                    </Col>
                                                                </Col>
                                                            </Row>
                                                        </Panel.Body>
                                                    </Panel>
                                                    <Panel eventKey="3">
                                                        <Panel.Heading>
                                                            <Panel.Title>
                                                                Payment Information
                                                            </Panel.Title>
                                                        </Panel.Heading>
                                                        <Panel.Body>
                                                            <Row>
                                                                <Col md={12} sm={12} xs={12} className="bottom-margin-10">
                                                                    <Col md={12} sm={12} xs={12}>
                                                                        <InputLabel
                                                                            className={validations.creditcard_number && 'form-validation'}
                                                                        >
                                                                            Credit Card Number
                                                                        </InputLabel>
                                                                    </Col>
                                                                    <Col md={12} sm={12} xs={12}>
                                                                        <InputField 
                                                                            type="text" 
                                                                            value={guestData.creditcard_number} 
                                                                            name="creditcard_number"
                                                                            onChange={(e) =>this.props.handlePaymentOnChange(e, mobileMode)}
                                                                            maxLength='19'
                                                                        >
                                                                        </InputField>
                                                                        {
                                                                        validations.creditcard_number &&
                                                                        <span
                                                                            className={validations.creditcard_number && 'required-error-text'}
                                                                        >
                                                                            Credit Card Number is required.
                                                                        </span>
                                                                    }
                                                                    </Col>
                                                                </Col>
                                                            </Row>
                                                        <Row>
                                                            <Col md={12} sm={12} xs={12} className="bottom-margin-10">
                                                                <Col md={4} sm={4} xs={4}>
                                                                    <InputLabel
                                                                        className={validations.creditcard_number && 'form-validation'}
                                                                    >
                                                                        MM
                                                                    </InputLabel>
                                                                </Col>
                                                                <Col md={4} sm={4} xs={4}>
                                                                    <InputLabel
                                                                        className={validations.expiration_year && 'form-validation'}
                                                                    >
                                                                        YYYY
                                                                    </InputLabel>
                                                                </Col>
                                                                <Col md={4} sm={4} xs={4}>
                                                                    <InputLabel
                                                                        className={validations.cvv && 'form-validation'}
                                                                    >
                                                                        CVV
                                                                    </InputLabel>
                                                                </Col>
                                                                <Col md={4} sm={4} xs={4}>
                                                                    <InputField 
                                                                        type="text" 
                                                                        value={guestData.expiration_month} 
                                                                        name="expiration_month"
                                                                        onChange={(e) =>this.props.handlePaymentOnChange(e, mobileMode)}
                                                                        maxLength='2'
                                                                    >
                                                                    </InputField>
                                                                    {
                                                                        validations.expiration_month &&
                                                                        <span
                                                                            className={validations.expiration_month && 'required-error-text'}
                                                                        >
                                                                            Month is required.
                                                                        </span>
                                                                    }
                                                                </Col>
                                                                <Col md={4} sm={4} xs={4}>
                                                                    <InputField 
                                                                        type="text" 
                                                                        value={guestData.expiration_year} 
                                                                        name="expiration_year"
                                                                        onChange={(e) =>this.props.handlePaymentOnChange(e, mobileMode)}
                                                                        maxLength='4'
                                                                    >
                                                                    </InputField>
                                                                    {
                                                                        validations.expiration_year &&
                                                                        <span
                                                                            className={validations.expiration_year && 'required-error-text'}
                                                                        >
                                                                            Year is required.
                                                                        </span>
                                                                    }
                                                                </Col>
                                                                <Col md={4} sm={4} xs={4} className="bottom-margin-10">
                                                                    <InputField 
                                                                        type="text" 
                                                                        value={guestData.cvv} 
                                                                        name="cvv"
                                                                        onChange={(e) =>this.props.handlePaymentOnChange(e, mobileMode)}
                                                                        maxLength='4'
                                                                    >
                                                                    </InputField>
                                                                    {
                                                                        validations.cvv &&
                                                                        <span
                                                                            className={validations.cvv && 'required-error-text'}
                                                                        >
                                                                            CVV is required.
                                                                        </span>
                                                                    }
                                                                </Col>
                                                            </Col>
                                                        </Row>
                                                    </Panel.Body>
                                                </Panel>
                                            </PanelGroup>
                                        </Col>
                                    </Row>
                                    {this.props.errMsg(true, bookNowErrMsg)}
                                    <Row>
                                        <Col xs={12} sm={12}>
                                            <PrimaryButton 
                                                fullWidth={true} 
                                                type="button" 
                                                onClick={() => this.props.handleBookNow(mobileMode)}
                                            >
                                                Book Now
                                            </PrimaryButton>
                                        </Col>
                                    </Row>
                                </Fragment>
                                :
                                <Fragment>
                                    <Row className="bottom-margin-10">
                                        <Col xs={12} sm={12}>
                                            <div className='success-msg-container'>
                                                <Col xs={12} sm={12} className="title">
                                                    <span className='status-icon status-icon--success'></span>
                                                    <h1>Reservation Successfully Created!</h1>
                                                </Col>
                                                <Col xs={12} sm={12} className="content">
                                                    <Col xs={12} sm={12}>
                                                        Reservation ID:
                                                    </Col>
                                                    <Col xs={12} sm={12} className="bottom-margin-10">
                                                        <h2>{reservationData.reservation_id}</h2>
                                                    </Col>
                                                    <Col xs={6} sm={6}>
                                                        Checkin Date:
                                                    </Col>
                                                    <Col xs={6} sm={6}>
                                                        Checkout Date:
                                                    </Col>
                                                    <Col xs={6} sm={6}>
                                                        <h2>{reservationData.checkin_date}</h2>
                                                    </Col>
                                                    <Col xs={6} sm={6}>
                                                        <h2>{reservationData.checkout_date}</h2>
                                                    </Col>
                                                </Col>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={12} className="flex-space-between">
                                            <PrimaryButton 
                                                fullWidth={false} 
                                                type="button" 
                                                onClick={() => this.props.history.push("/reservations")}
                                            >
                                                View All Reservations
                                            </PrimaryButton>
                                            <PrimaryButton
                                                fullWidth={false} 
                                                type="button"
                                                onClick={(e) => {
                                                    this.props.handleGuestInfoModal(e, false);
                                                    this.props.handleSummaryModal(false);
                                                    this.props.clearGuestData();
                                                }}
                                            >
                                                Create Another Reservation
                                            </PrimaryButton>
                                        </Col>
                                    </Row>
                                </Fragment>
                            }  
                        </Modal.Body>
                    </Modal.Header>
                </Row>
            </Modal.Body>
        </Modal>
        )
    }
}

