import React, { Component, Fragment } from 'react';
import BigCalendar from 'react-big-calendar';
import moment from 'moment';
import { connect } from 'react-redux';
import { WeekView } from './WeekView';
import styled from 'styled-components';
import { MobileWeekView } from './MobileWeekView'
import { CalendarToolbar } from './CalendarToolbar';
import MonthView from './MonthView';
export const StyledWrapper = props => {
    let Wrapper = styled.div`
        width: 110px;
        .SingleDatePicker.SingleDatePicker_1 {
            .SingleDatePicker_picker__directionLeft {
                display: ${props.view === 'mobile-week-view' ? 'block' : 'none'};
                z-index: 999;
                left: -209px !important;
            }
            .SingleDatePickerInput {
                width: 100%;
                .DateInput {
                    width: 100%;
                    .DateInput_input {
                        background-color: #f5f5f5;
                        padding: 3.5px 6px;
                    }
                    .DateInput_input__focused {
                        box-shadow: none;
                        border-color: #6699cc;
                    }
                    .DateInput_fang {
                        display: none;
                    }
                }
                .DateInput:first-child,
                .DateInput:last-child {
                    margin: 0px;
                }
            }
        }
    `;
    return <Wrapper>{props.children}</Wrapper>
}

BigCalendar.momentLocalizer(moment);

/**
 * Current calendar view set calendar view as week view or month view.
 */
class CalendarView extends Component {
    state = {
        filterDropdown: false,
        calendarLoaded: false,
        expandedKey: false,
        formats: {
            weekdayFormat: this.props.mobileMode ? 'dd' : 'ddd',
        },
        views: {
            month: MonthView,
            week: false,
            day: false,
            custom: this.props.mobileMode ? MobileWeekView : WeekView,
        },
        mobileCalendarKeys: {
            key: [
                'available', 
                'reservation', 
                'blocked', 
                'no-pricing',
            ],
            repchild: [
                'available', 
                'reservation', 
                'blocked',
            ],
            rep: [
                'all-rooms-available', 
                'some-rooms-available', 
                'no-rooms-available', 
                'no-pricing',
            ],
        },
    }
    componentDidMount() {
        this.setState({
            calendarLoaded: true,
        })
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            views: {
                ...this.state.views, 
                custom: newProps.mobileMode ? MobileWeekView : WeekView
            },
            formats: {
                ...this.state.formats,
                weekdayFormat: newProps.mobileMode ? 'dd' : 'ddd'
            }
        })
    }
    /**
     * render mobile calendar event keys.
     * @param {*} level current property level.
     */
    renderMobileCalendarKeys = level => {  
        let keys, 
        { 
            mobileCalendarKeys,
        } = this.state
        if (level === 'key') {
            ({ key: keys } = mobileCalendarKeys)
        }
        if (level === 'repchild') {
            ({ repchild: keys } = mobileCalendarKeys)
        }
        if (level === 'rep') {
            ({rep: keys} = mobileCalendarKeys)
        }
        return keys.map((key, idx) => (
                <div 
                    key={idx} 
                    className="key-wrapper"
                >
                    <div className={`key ${key}`}/>
                    <p className="key-label">
                        {key.replace(/-/g, " ")}
                    </p>
                </div>
            )
        )
    }
    /**
     * Filter drop down or checkbox as per screen size
     */
    toggleFilter = () => {
        this.setState({
            filterDropdown:!this.state.filterDropdown
        })
    }

    render() {
        let superProps = this.props
        
        const { 
            formats,
            views,
        } = this.state

        let components = {
            toolbar: CalendarToolbar({superProps,toggleFilter:this.toggleFilter, filterDropdown: this.state.filterDropdown})
        }
        return this.props.mobileMode ? (
            <div 
                className="mobile-calendar" 
                ref="mobileCalendar"
                style={{
                    border: superProps.activeProperty ? 
                    `2px solid ${this.props.listView === false ? '#999' : 'transparent'}` : "none"
                }}
            >   {this.state.calendarLoaded &&
                    <Fragment>
                        <BigCalendar
                            rootElement={this.refs.mobileCalendar}
                            {...this.props}
                            defaultView='custom'
                            components={components}
                            formats={formats}
                            views={views}
                        />
                        {this.props.listView === false && (
                            <Fragment>
                                {this.props.propertyLevel &&
                                    <div className="calendar-key-container">
                                        {this.renderMobileCalendarKeys(this.props.propertyLevel)}
                                    </div>
                                }
                                {this.props.propertyLevel === undefined && 
                                this.props.propertyLevels && (
                                    <Fragment>
                                        {this.props.propertyLevels.includes("key") &&
                                        !this.props.propertyLevels.includes("rep") && (
                                            <div className="calendar-key-container multi-prop">
                                                {this.renderMobileCalendarKeys('key')}
                                            </div>
                                        )}
                                        {!this.props.propertyLevels.includes("key") &&
                                        this.props.propertyLevels.includes("rep") && (
                                            <div className="calendar-key-container multi-prop">
                                                {this.renderMobileCalendarKeys('rep')}
                                            </div>
                                        )}
                                        {this.props.propertyLevels.includes("key") &&
                                        this.props.propertyLevels.includes("rep") && (
                                            <Fragment>
                                                <div className="calendar-key-container multi-prop">
                                                    {this.renderMobileCalendarKeys('key')}
                                                </div>
                                                <span onClick={()=> {
                                                    this.setState({
                                                        expandedKey: !this.state.expandedKey
                                                    })
                                                }}>{`${this.state.expandedKey ? 'Hide' : 'View'} Rep Level Key`}</span>
                                                {this.state.expandedKey &&
                                                    <div className="calendar-key-container multi-prop">
                                                        {this.renderMobileCalendarKeys('rep')}
                                                    </div>
                                                }
                                            </Fragment>
                                        )}
                                    </Fragment>
                                )
                            }
                            </Fragment>
                        )}
                    </Fragment>
            }
            </div>
        ) : (
            <div 
                className='calendar' 
                style={(this.props.listView&&this.props.view=='month')?{height:'auto'}:this.props.height} 
                ref="calendar"
            >
                {this.state.calendarLoaded && <BigCalendar
                rootElement={this.refs.calendar}
                {...this.props}
                defaultView='custom'
                components={components}
                formats={formats}
                views={views}
                />}
            </div>
        )
    }
}

export default connect (
    ({updateMobileState: { mobileMode }}) => ({mobileMode})
)(CalendarView)