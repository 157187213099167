import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { API } from 'aws-amplify';

import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import Modal from 'react-bootstrap/lib/Modal';

import { PrimaryButton } from '../../global/forms/FormElements';
import ReactTableComponent from '../../global/ReactTableComponent';
import Loader from '../../global/Loader';
import constants from '../../../js/constants';

class PropertiesFeesSchedule extends Component {
    reactTableGlobal = null;
    reactTableProperty = null;
    isAdmin = this.props.isAdmin;
    FEE_TYPE = [];
    paramsPropertyId = 
        (this.props.match && this.props.match.params && this.props.match.params.property_id) ||
        (this.props.match && this.props.match.params && this.props.match.params.roomId)
    state = {
        isLoading: false,
        deleteFeeLoader: false,
        activeFee: {},
        activeModal: "",
        globalFeesPageSize: 10,
        globalFeesActivePage: 1,
        propertyFeesPageSize: 10,
        propertyFeesActivePage: 1,
        propertyFeesData: [],
        defaultSort: {
            id: "fee_schedule_name",
            desc: false,
        },
        globalFeesColumns: [
            {
                'Header':'Fee Name',
                'accessor': 'fee_schedule_name',
                'Cell': row=> (
                    <div>
                        <strong>
                            {row && row.original && row.original.fee_schedule_name}
                        </strong>
                    </div>
                )
            },
            {
                'Header':'Fee Type',
                'accessor': 'fee_type',
                'Cell': row=> {
                    let feeType = "";
    
                    this.FEE_TYPE.map((fee) => {
                        if(fee.value == (row && row.original && row.original.fee_type)) {
                            feeType = fee.title;
                        }
                    });
                    return (
                        <div>
                            {feeType}
                        </div>
                    );
                }
            },
            {
                'Header':'Fee Applies',
                'accessor': 'applies',
                'Cell': row=>{
                    const applies = (row && row.original && row.original.applies);
                    const feeApplies = constants.FEE_APPLIES_TYPE.filter((app) => {
                        return app.value === applies;
                    });

                    return (  
                        <div>
                            <span>{feeApplies && feeApplies[0] && feeApplies[0].label}</span>
                        </div>
                    )
                    
                }
            },
            {
                'Header':'Fee Criteria',
                'accessor': 'criterion',
                'Cell': row=>{
                    const criterion = (row && row.original && row.original.criterion);

                    return criterion.map((criteria, i) => {
                        const criteriaName = constants.CRITERIA_NAME.filter((name) => {
                            return name.value === criteria.criteria_name;
                        });
                        const criteriaOperator = constants.CRITERIA_OPERATOR.filter((op) => {
                            return op.value === criteria.operator;
                        });
                        return (
                            <Fragment key={i}>
                                <div className='fs-list-view-criteria'>
                                    <span><strong>{criteriaName && criteriaName[0] && criteriaName[0].label} {criteria.criteria_name === 'charge_name' ? (criteriaOperator && criteriaOperator[0] && criteriaOperator[0].label) : ''}</strong> </span>
                                    {
                                        criteria.criteria_name !== 'charge_name' ?
                                        <span>{`${criteriaOperator && criteriaOperator[0] && criteriaOperator[0].label} ${criteria.criteria_name !== 'charge_name' ? criteria.values : ''}`}</span> :
                                        ''
                                    }
                                    {
                                        (criteria.values.length > 0 && criteria.criteria_name === 'charge_name') &&
                                        <ul>
                                            {
                                                criteria.values.split(',').map((value, x) => {
                                                    return (
                                                        <li key={x}>
                                                            {value}
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    }
                                </div>
                                <span className='hr'/>
                            </Fragment>
                        )
                    });
                    
                }
            },
            {
                'Header':'Fee Value',
                'accessor': 'value',
                'Cell': row=>{
                    const VALUE_TYPE = [
                        {value: "amount", label: "$"},
                        {value: "percent", label: "%"},
                    ];
                    const original = (row && row.original);
                    const valueType = VALUE_TYPE.filter((type) => {
                        return type.value === original.value_type;
                    });
                    
                    return(
                    <div>
                        {`${(valueType && valueType[0] && valueType[0].value) === 'amount' ? valueType[0].label : ''}${original.value}${(valueType && valueType[0] && valueType[0].value) === 'percent' ? valueType[0].label : ''}`}
                    </div>
                )}
            },
            {
                'Header':'Required',
                'accessor': 'required',
                'Cell': row=>{
                    return(
                        <div>
                            {row && row.original && row.original.required ? 'Yes' : 'No'}
                        </div>
                    )}
            },
            {
                'Header':'Status',
                'accessor': 'active',
                'Cell': row=> {
                    return(
                    <div>
                        {
                            (row && row.original && row.original.active) === true ? 
                            <strong className='text-success'>Live</strong> : 
                            <strong className='text-danger'>Not Live</strong>
                        }
                    </div>
                )}
            },
            {
                'Header':'',
                'width': 100,
                'sortable': false,
                'Cell': row=>{
                    return (
                        <div>
                        </div>
                    )
                }
            }
        ],
        propertyFeesColumns: [
            {
                'Header':'Fee Name',
                'accessor': 'fee_schedule_name',
                'Cell': row=> (
                    <div>
                        <strong>
                            {row && row.original && row.original.fee_schedule_name}
                        </strong>
                    </div>
                )
            },
            {
                'Header':'Fee Type',
                'accessor': 'fee_type',
                'Cell': row=> {
                    let feeType = "";

                    this.FEE_TYPE.map((fee) => {
                        if(fee.value == (row && row.original && row.original.fee_type)) {
                            feeType = fee.title;
                        }
                    });
                    return (
                        <div>
                            {feeType}
                        </div>
                    );
                }
            },
            {
                'Header':'Fee Applies',
                'accessor': 'applies',
                'Cell': row=>{
                    const applies = (row && row.original && row.original.applies);
                    const feeApplies = constants.FEE_APPLIES_TYPE.filter((app) => {
                        return app.value === applies;
                    });

                    return (  
                        <div>
                            <span>{feeApplies && feeApplies[0] && feeApplies[0].label}</span>
                        </div>
                    )
                    
                }
            },
            {
                'Header':'Fee Criteria',
                'accessor': 'criterion',
                'Cell': row=>{
                    const criterion = (row && row.original && row.original.criterion);

                    return criterion.map((criteria, i) => {
                        const criteriaName = constants.CRITERIA_NAME.filter((name) => {
                            return name.value === criteria.criteria_name;
                        });
                        const criteriaOperator = constants.CRITERIA_OPERATOR.filter((op) => {
                            return op.value === criteria.operator;
                        });
                        return (
                            <Fragment key={i}>
                                <div className='fs-list-view-criteria'>
                                    <span><strong>{criteriaName && criteriaName[0] && criteriaName[0].label} {criteria.criteria_name === 'charge_name' ? (criteriaOperator && criteriaOperator[0] && criteriaOperator[0].label) : ''}</strong> </span>
                                    {
                                        criteria.criteria_name !== 'charge_name' ?
                                        <span>{`${criteriaOperator && criteriaOperator[0] && criteriaOperator[0].label} ${criteria.criteria_name !== 'charge_name' ? criteria.values : ''}`}</span> :
                                        ''
                                    }
                                    {
                                        (criteria.values.length > 0 && criteria.criteria_name === 'charge_name') &&
                                        <ul>
                                            {
                                                criteria.values.split(',').map((value, x) => {
                                                    return (
                                                        <li key={x}>
                                                            {value}
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    }
                                </div>
                                <span className='hr'/>
                            </Fragment>
                        )
                    });
                    
                }
            },
            {
                'Header':'Fee Value',
                'accessor': 'value',
                'Cell': row=>{
                    const VALUE_TYPE = [
                        {value: "amount", label: "$"},
                        {value: "percent", label: "%"},
                    ];
                    const original = (row && row.original);
                    const valueType = VALUE_TYPE.filter((type) => {
                        return type.value === original.value_type;
                    });
                    
                    return(
                    <div>
                        {`${(valueType && valueType[0] && valueType[0].value) === 'amount' ? valueType[0].label : ''}${original.value}${(valueType && valueType[0] && valueType[0].value) === 'percent' ? valueType[0].label : ''}`}
                    </div>
                )}
            },
            {
                'Header':'Required',
                'accessor': 'required',
                'Cell': row=>{
                    return(
                        <div>
                            {row && row.original && row.original.required ? 'Yes' : 'No'}
                        </div>
                    )}
            },
            {
                'Header':'Status',
                'accessor': 'active',
                'Cell': row=> {
                    return(
                    <div>
                        {
                            (row && row.original && row.original.active) === true ? 
                            <strong className='text-success'>Live</strong> : 
                            <strong className='text-danger'>Not Live</strong>
                        }
                    </div>
                )}
            },
            {
                'Header':'Actions',
                'width': 100,
                'sortable': false,
                'Cell': row=> {
                    return (
                        <div>
                            <OverlayTrigger
                                placement='bottom'
                                overlay={this.getTooltip('edit',`${this.isAdmin ? 'Update' : 'View'} Fee`)}
                            >
                                <Link
                                    onClick={(e)=>{e.stopPropagation()}}
                                    to={`${this.props.history.location.pathname.replace("/edit", "")}/fees/${row && row.original && row.original.fee_schedule_id}`} 
                                    className='properties__options'>
                                        <i className='icon-edit'/>
                                </Link>
                            </OverlayTrigger>
                            {
                                this.isAdmin &&
                                <Fragment>
                                    <OverlayTrigger
                                        placement='bottom'
                                        overlay={this.getTooltip('edit',`Delete Fee`)}>
                                            <span
                                                className='properties__options'
                                                onClick={e => this.openModal(e, "delete-fee-modal", (row && row.original))}
                                            >
                                                <i className='icon icon-Delete'/>
                                            </span>
                                    </OverlayTrigger>
                                </Fragment>
                            }
                        </div>
                    )
                }
            }
        ]
    }

    async componentWillMount() {
        this.setState({
            isLoading: true
        });

        await this.getFeesMetaData();

        this.setState({
            isLoading: false
        });
    }

    getFeesMetaData = async () => {
        let data = {};
        try {
            data = await API.get("rapapi", "/feeschedules/enums");
            if(data && data.fee_type) {
                Object.entries(data.fee_type).forEach((feeType) => {
                    this.FEE_TYPE.push({ name: "fee_type", value: feeType[0], title: feeType[1]})
                });
            };
        }
        catch(e) {
            this.setState({
                error: true,
                isLoading: false,
            });
        }
    }
    
    pageSizeOnChange = (pageSize, type) => {
		if (type == "global") {
			this.setState({
				globalFeesActivePage: 1,
				globalFeesPageSize: parseInt(pageSize),
			});
		}
		else {
			this.setState({
				propertyFeesActivePage: 1,
				propertyFeesPageSize: parseInt(pageSize),
			});
		};
	}
	
	pageOnChange = (page, type) => {
		if(type == "global") {
			this.setState({
				globalFeesActivePage: parseInt(page)
			});
		}
		else {
			this.setState({
				propertyFeesActivePage: parseInt(page)
			});
		};
    }

    getTooltip = (id, text) => {
        return <Tooltip id={id} className='properties__tooltip'>{text}</Tooltip>
    }
    
    closeModal = () => {
        this.setState({
            activeModal: "",
        });
    }

    openModal = (e, modalName, feeData) => {
        e.stopPropagation();
        this.setState({
            activeModal: modalName,
            activeFee: feeData,
        });
    }

	deleteFeeHandler = async (e) => {
		e.preventDefault();
		
		this.setState({
			deleteFeeLoader: true,
			activeModal: ""
		});

        try {
            await API.del("rapapi", `/properties/${this.paramsPropertyId}/feeschedules/${this.state.activeFee.fee_schedule_id}`);
            
            await this.props.getFeesList();
            
			this.setState({
				deleteFeeLoader: false
			});
        }
        catch(e) {
            this.setState({
                activeModal: "delete-error-modal",
                deleteFeeLoader: false
            });
        };
    }

    getRef = (r, name) => {
        this[name] = r;
    }

    render() {
        const { 
            isLoading, 
            deleteFeeLoader, 
            globalFeesColumns,
            globalFeesPageSize,
            globalFeesActivePage,
            defaultSort,
            propertyFeesColumns,
            propertyFeesPageSize,
            propertyFeesActivePage,
            activeModal,
            activeFee,
        } = this.state;
        const { feesList } = this.props;

        return(
            <div className="fs-table-component-cntr">
                <Row className="bottom-margin">
                    <Col xs={12}>
                        <button 
                            type="button"
                            onClick={(e) =>{ e.preventDefault; this.props.history.push(`${this.props.history.location.pathname.replace("/edit", "")}/fees/create`)}}
                            className="primary-btn pull-right black-btn">
                                <i className="icon-Plus create-icon"></i>
                            Add Property Fee
                        </button>
                    </Col>
                </Row>

                {(deleteFeeLoader) && <Loader />}

                {
                    (feesList && feesList.manager_fee_schedules && feesList.manager_fee_schedules.fee_schedules && feesList.manager_fee_schedules.fee_schedules.length > 0) &&
                    <Fragment>
                        <Row>
                            <Col md={12}>
                                <h2>Global Fees</h2>
                            </Col>
                        </Row>
                        <Row className="bottom-margin">
                            <Col xs={12} className="property-global-fees-cntr">
                                <ReactTableComponent
                                    getRef={(r) => this.getRef(r, "reactTableGlobal")}
                                    reactTable={this.reactTableGlobal}
                                    className='-highlight'
                                    minRows={0}
                                    resizable={false}
                                    showPagination={true}
                                    data={feesList && feesList.manager_fee_schedules && feesList.manager_fee_schedules.fee_schedules}
                                    columns={globalFeesColumns}
                                    pageSize={globalFeesPageSize}
                                    activePage={globalFeesActivePage}
                                    noDataText={isLoading ? "Loading..." : "No Global Fees found."}
                                    defaultSorted={[defaultSort]}
                                    itemType={'Fees'}
                                    pageSizeOnChange={(pageSize) => this.pageSizeOnChange(pageSize, "global")}
                                    pageOnChange={(page) => this.pageOnChange(page, "global")}
                                />
                            </Col>
                        </Row>
                    </Fragment>
                }
                <Row>
                    <Col md={12}>
                        <h2>Property Fees</h2>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <ReactTableComponent
                            getRef={(r) => this.getRef(r, "reactTableProperty")}
                            reactTable={this.reactTableProperty}
                            className='-highlight'
                            minRows={0}
                            resizable={false}
                            showPagination={true}
                            data={feesList && feesList.property_fee_schedules && feesList.property_fee_schedules.fee_schedules}
                            columns={propertyFeesColumns}
                            pageSize={propertyFeesPageSize}
                            activePage={propertyFeesActivePage}
                            noDataText={isLoading ? "Loading..." : "No Property Fees found."}
                            defaultSorted={[defaultSort]}
                            itemType={'Fees'}
                            pageSizeOnChange={(pageSize) => this.pageSizeOnChange(pageSize)}
                            pageOnChange={(page) => this.pageOnChange(page)}
                            getTrProps = {(state, rowInfo, column) => {
                                return {
                                    onClick: (e, handleOriginal) => {
                                        this.props.history.push(`${this.props.history.location.pathname.replace("/edit", "")}/fees/${rowInfo.original.fee_schedule_id}`);
                                    },
                                    style: {
                                        cursor: 'pointer'
                                    }
                                }
                            }}
                        />
                    </Col>
                </Row>
                <Modal
                    className="submit-modal"
                    show={activeModal === "delete-fee-modal"} 
                    onHide={this.closeModal}
                >
                    <Modal.Body>
                        <Row className="text-center">
                            <Col md={12}>
                                <h1>Are you sure you want to delete</h1>
                                <h1>"{activeFee.fee_schedule_name}"?</h1>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col md={6}>
                                <PrimaryButton 
                                    cssClass="pull-left white-btn col-md-12 " 
                                    fullWidth={true} 
                                    type="button" 
                                    onClick={this.closeModal}
                                >
                                    Cancel
                                </PrimaryButton>
                            </Col>
                            <Col md={6}>
                                <PrimaryButton 
                                    cssClass="pull-right col-md-12 " 
                                    fullWidth={true} 
                                    type="button" 
                                    onClick={e => this.deleteFeeHandler(e)}
                                >
                                    Delete
                                </PrimaryButton>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
                <Modal
                className="submit-modal"
                show={activeModal === "delete-error-modal"} 
                onHide={this.closeModal}
            >
                <Modal.Body>
                    <Row className="fs-del-modal">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <div>
                                    <span className='status-icon status-icon--error'></span>
                                    <span className="submit-heading">Error Deleting Fee.</span>
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                    </Row>
                </Modal.Body>
            </Modal>
            </div>
        )
    }
}

export default withRouter(PropertiesFeesSchedule);
