import React, { Component, Fragment } from "react";

import {
	InputLabel,
	InputField,
	PrimaryButton
} from "../global/forms/FormElements";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import Modal from "react-bootstrap/lib/Modal";
import DateRangePicker from "react-dates/lib/components/DateRangePicker";
import moment from "moment";

import Loader from "../global/Loader";
import { AddonForm } from "../content/pricing/AddonForm";

export default class ReservationSummaryModal extends Component {
	/**
	 * Get addons fee from fee schedules
	 */
	getAddonFees() {
		const all_fee_schedules = this.props.selectedPropertyData.fee_schedules;
		if (!all_fee_schedules) {
			return [];
		}
		const isInternalPropertyAdmin = this.props.isInternalPropertyAdmin;
		return all_fee_schedules.filter(function(fs) {
			// non-admin can not see other fees beside that has recipient = 'host'
			return (
				!fs.required && (isInternalPropertyAdmin || fs.recipient === "host")
			);
		});
	}

	addonFeesInput = () => {
		if (!this.getAddonFees()) {
			return "";
		}
		const { quoteIsLoading, quoteData } = this.props;
		return (
			<Row>
				<Col xs={12} sm={12}>
					<AddonForm
						availableAddons={this.getAddonFees()}
						quoteIsLoading={quoteIsLoading}
						quoteData={quoteData}
						handleReservationQuote={this.props.handleReservationQuote}
						isInternalPropertyAdmin={this.props.isInternalPropertyAdmin}
					></AddonForm>
				</Col>
			</Row>
		);
	};

	render() {
		const {
			mobileMode,
			showSummaryModal,
			summaryIsLoading,
			summaryDates,
			summaryInput,
			adults,
			children,
			promotionCodeData,
			promotionCode,
			promotions,
			quoteErrMsg,
			summaryData,
			searchDates,
			selectedPropertyData,
			adultOptions,
			childOptions,
			quoteData
		} = this.props;

		return (
			<Modal
				className={`${mobileMode ? "mobile" : ""} reservation-modal`}
				show={showSummaryModal}
				onHide={e => this.props.handleSummaryModal(false)}
				backdrop="static"
			>
				<Modal.Body>
					{summaryIsLoading && <Loader />}
					<Row>
						<Modal.Header closeButton>
							<Modal.Body>
								<Row>
									<Col md={12} sm={12} xs={12}>
										<Row>
											<Col md={12} sm={12} xs={12}>
												<h1>{summaryData.entity_id}</h1>
											</Col>
											<Col md={12} sm={12} xs={12}>
												<h2>{summaryData.label}</h2>
											</Col>
										</Row>
										<br />
										<Row>
											<Col md={12} sm={12} xs={12}>
												<Col md={6} sm={6} xs={6} className="start-date">
													<InputLabel>Check-In</InputLabel>
												</Col>
												<Col md={6} sm={6} xs={6}>
													<InputLabel>Check-Out</InputLabel>
												</Col>
											</Col>
										</Row>
										<Row>
											<Col md={12} sm={12}>
												<DateRangePicker
													noBorder
													startDate={
														summaryDates.startDate
															? summaryDates.startDate
															: searchDates.startDate
													}
													startDateId="startexcluded"
													startDatePlaceholderText="MM/DD/YYYY"
													endDate={
														summaryDates.endDate
															? summaryDates.endDate
															: searchDates.endDate
													}
													endDateId="endexcluded"
													endDatePlaceholderText="MM/DD/YYYY"
													focusedInput={summaryInput}
													onFocusChange={summaryInput =>
														this.props.handleDateInput(
															"summaryInput",
															summaryInput
														)
													}
													onDatesChange={({ startDate, endDate }) =>
														this.props.handleDatesOnChange(
															"summaryDates",
															startDate,
															endDate
														)
													}
													isDayBlocked={day => {
														if (
															selectedPropertyData &&
															selectedPropertyData.availability &&
															selectedPropertyData.availability.length
														) {
															for (
																let i = 0;
																i < selectedPropertyData.availability.length;
																i++
															) {
																if (
																	selectedPropertyData.availability[i].period
																		.type !== "Temporary"
																) {
																}
																const startDate = moment(
																	selectedPropertyData.availability[i].period
																		.period_start
																).format("YYYY-MM-DD");
																const endDate = moment(
																	selectedPropertyData.availability[i].period
																		.period_end
																).format("YYYY-MM-DD");

																if (
																	startDate === moment(day).format("YYYY-MM-DD")
																) {
																	return (
																		startDate ===
																		moment(day).format("YYYY-MM-DD")
																	);
																}

																if (
																	endDate === moment(day).format("YYYY-MM-DD")
																) {
																	return (
																		endDate === moment(day).format("YYYY-MM-DD")
																	);
																}

																if (moment(day).isBetween(startDate, endDate)) {
																	return moment(day).isBetween(
																		startDate,
																		endDate
																	);
																}
															}
														}
													}}
												/>
											</Col>
										</Row>
										<br />
										<Row>
											<Col md={12} sm={12} xs={12}>
												<Col md={6} sm={6} xs={6} className="no-pad-left">
													<InputLabel>Adults</InputLabel>
												</Col>
												<Col md={6} sm={6} xs={6} className="no-pad-right">
													<InputLabel>Children</InputLabel>
												</Col>
											</Col>
										</Row>
										<Row>
											<Col md={12} sm={12} xs={12}>
												<Col md={6} sm={6} xs={6} className="no-pad-left">
													<InputField
														type="select"
														value={adults}
														name="adults"
														onChange={e =>
															this.props.handleAdultChildrenOnChange(
																e,
																summaryData.is_field_sleeps_max
															)
														}
													>
														{adultOptions}
													</InputField>
												</Col>
												<Col md={6} sm={6} xs={6} className="no-pad-right">
													<InputField
														type="select"
														value={children}
														name="children"
														onChange={e =>
															this.props.handleAdultChildrenOnChange(
																e,
																summaryData.is_field_sleeps_max
															)
														}
													>
														{childOptions}
													</InputField>
												</Col>
											</Col>
										</Row>
										<br />
										{this.addonFeesInput()}
										{promotionCodeData.length > 0 && (
											<Fragment>
												<Row>
													<Col md={12} sm={12}>
														<InputLabel>Promotion</InputLabel>
													</Col>
												</Row>
												<Row>
													<Col md={12} sm={12} xs={12}>
														<InputField
															type="select"
															value={promotionCode}
															name="promotionCode"
															onChange={e => this.props.handleOnChange(e, true)}
														>
															{promotions}
														</InputField>
													</Col>
												</Row>
												<br />
											</Fragment>
										)}
										{this.props.ratesSummary()}
										{this.props.errMsg(false, quoteErrMsg)}
										<Row>
											<Col xs={12} sm={12}>
												<PrimaryButton
													fullWidth={true}
													type="button"
													onClick={e =>
														this.props.handleGuestInfoModal(e, true)
													}
													disabled={!Object.keys(quoteData).length}
												>
													Checkout
												</PrimaryButton>
											</Col>
										</Row>
									</Col>
								</Row>
							</Modal.Body>
						</Modal.Header>
					</Row>
				</Modal.Body>
			</Modal>
		);
	}
}
