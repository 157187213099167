import React, { Fragment } from 'react';
import moment from 'moment';
import { FieldWrap, InputLabel, InputField, PrimaryButton } from '../forms/FormElements';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import { Title } from '../Title';
import { Link } from 'react-router-dom';
import { DatePicker } from '../forms/SingleDatePicker';
import styled from 'styled-components';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';

const Responsive = props => {
    const StyledWrapper = styled.div`
        position: absolute;
        top: 15px;
        .dropdown-toggle {
            line-height: 26px;
        }
        .dropdown-menu {
            left: -154px;
        }
    `;
    let childrenWithNewProps = React.Children.map(props.children, el => React.cloneElement(el, {
            style: {
                backgroundColor: 'transparent',
                boxShadow: 'none',
                border: 'none',
            }
        })
    )
    return props.mobile ? (
        <div 
            className="kebab-menu"
        >
            <div className="kebab-item"/>
            <div className="kebab-item"/>
            <div className="kebab-item"/>
            <StyledWrapper>
                {childrenWithNewProps}
            </StyledWrapper>
        </div>
    ) : (
        props.children
    ) 
}

/**
 * Calender header tool bar is filter, import and select month range of current calendar.
 */
export const CalendarToolbar = ({superProps, toggleFilter, filterDropdown}) => (props) => {
    let selected=moment(props.date);
    let end=moment(props.date).add(13,'days');
    let label,header=superProps.getCalendarTitle();
    if(props.view=='custom' && selected.month()!=end.month()) {
        label=selected.format('DD MMM YYYY') + ' - ' + end.format('DD MMM YYYY');
    }
    else {
        label = selected.format('MMMM YYYY')
    }
    return (
        <div className='calendar-toolbar'>
            <Title 
                mobileMode={superProps.mobileMode} 
                breadcrumbItems={header.breadcrumbItems} 
                title={header.title} 
                level={header.level} 
                handleBack={
                    props.view == 'month' ? superProps.handleBack : null
                }
            >
                <div className='calendar-toolbar-options'>
                {(props.view=='month' && !superProps.listView) && !superProps.mobileMode &&
                    <div className='filters-dropdown-wrapper'>
                        <a href="#!" onClick={toggleFilter} className="filters-dropdown">
                            <i className="icon-filter"></i>
                        </a>
                        <div className={`filters-wrapper ${filterDropdown?'show':''}`}>
                            <FieldWrap>
                                <input type='checkbox' id='filterAvailability' checked={superProps.eventAccessor.indexOf('availability')>=0? true:false} name='filters' value='availability' onChange={superProps.onChangeFilters}/>
                                <label htmlFor='filterAvailability'>Availability</label>
                            </FieldWrap>
                            {!superProps.repChild ?
                                <FieldWrap>
                                    <input type='checkbox' id='filterPricing' name='filters' checked={superProps.eventAccessor.indexOf('price_periods')>=0? true:false} value='price_periods' onChange={superProps.onChangeFilters}/>
                                    <label htmlFor='filterPricing'>Pricing</label>
                                </FieldWrap>
                            : ''}
                        </div>
                    </div>    
                }
                {
                    props.view=='custom' &&
                    !superProps.mobileMode &&
                    superProps.allOrganizations.length > 1 &&
                    superProps.org !== '_global_' && 
                    superProps.org !== 'travelagencies' &&
                    <div className="filters-wrap">
                        <InputField 
                            type="checkbox" 
                            value={superProps.showAllOrg} 
                            cbChange={() => superProps.handleShowAllOrg('showAllOrg')}
                        >
                            All Organizations
                        </InputField>
                    </div>
                }
                {(((props.view=='month' && !superProps.listView) || props.view=='custom')) && !superProps.mobileMode &&
                    <div>
                        <PrimaryButton 
                            type="button" 
                            cssClass="white-btn btn-today" 
                            fullWidth={false} 
                            onClick={()=>props.onNavigate('DATE',moment())}
                        >
                            Today
                        </PrimaryButton>
                    </div>
                }
                {((props.view=='month' && !superProps.listView) || props.view=='custom') && !superProps.mobileMode &&
                    <div className='month-wrapper'>
                        <a 
                            className="btn-nav icon-left_arrow" 
                            href="#!"
                            onClick={()=>props.onNavigate('PREV')} 
                            title="Previous"
                        />
                        <DatePicker
                            id="monthPick"
                            date={selected}
                            onDateChange={(date)=> {
                                if (date) {
                                    props.onNavigate('DATE',date.toDate())
                                }
                            }}
                            numberOfMonths={1}
                            isOutsideRange={() => false}
                            displayFormat="MMMM YYYY" 
                        />
                        <a 
                            className={!superProps.mobileMode ? "btn-nav icon-right_arrow" : "icon-right_arrow"} href="#!"
                            onClick={()=>props.onNavigate('NEXT')} 
                            title="Next"
                        />
                    </div>
                }
                {
                    props.view=='month' && superProps.admin ? 
                    <div>
                    {
                        props.view=='month' && superProps.admin && !superProps.repChild ?
                        <div>
                            <Responsive
                                mobile={superProps.mobileMode}
                            >
                            <DropdownButton
                                title={'Actions'}
                                id='import-by-property'
                                className="primary-btn secondary-btn"
                                style={{marginLeft: "5px"}}
                            >   
                                <MenuItem header>Import Pricing</MenuItem>
                                <MenuItem onClick={superProps.showImportFromProperty}>By Property</MenuItem>
                                <MenuItem onClick={superProps.showImportByCSV}>By File</MenuItem>
                                {
                                    superProps.integrationName.integration_name === "racalendar" &&
                                    <Fragment>
                                        <MenuItem divider />
                                        <MenuItem header>Import Availability</MenuItem>
                                        <MenuItem onClick={superProps.showImport}>By iCal</MenuItem>
                                    </Fragment>
                                }
                                <MenuItem divider />
                                <MenuItem header>Property</MenuItem>
                                <li>
                                    <Link 
                                        to={{
                                            pathname:
                                            superProps.currentProperty && 
                                            superProps.currentProperty.repconfig && 
                                            superProps.currentProperty.repconfig.property_collection_id ? 
                                            `/collections/${superProps.currentProperty.repconfig.property_collection_id}/rooms/${superProps.activeProperty}/edit` : 
                                            `/properties/${superProps.activeProperty}/edit`,
                                            state: { routeFromCalendar: true },
                                        }}
                                    >
                                        View Settings
                                    </Link>
                                </li>
                                <li>
                                    <OverlayTrigger 
                                        placement='bottom' 
                                        overlay={<Tooltip id={'icalUrl'} className='properties__tooltip'>{`https://api.redawning.com/papi-v1/listings/${superProps.activeProperty}/reservations/ical`}</Tooltip>}
                                    >
                                        <span 
                                            onClick={(e) => {
                                                superProps.toggleShowIcalUrlModal();
                                            }} 
                                        >
                                            Show iCal URL
                                        </span>
                                    </OverlayTrigger>
                                </li>
                            </DropdownButton>
                            </Responsive>
                        </div> 
                        :
                         <div>
                             <Responsive mobile={superProps.mobileMode}>
                                <DropdownButton
                                    title={'Import'}
                                    id='import-by-property'
                                    className="primary-btn secondary-btn"
                                    style={{
                                        marginLeft: "5px",
                                    }}
                                >   
                                {
                                    superProps.integrationName.integration_name === "racalendar" &&
                                    <Fragment>
                                        <MenuItem header>Import Availability</MenuItem>
                                        <MenuItem onClick={superProps.showImport}>By iCal</MenuItem>
                                    </Fragment>
                                }
                                <li>
                                    <Link 
                                        to={{
                                            pathname:
                                            superProps.currentProperty && 
                                            superProps.currentProperty.repconfig && 
                                            superProps.currentProperty.repconfig.property_collection_id ? 
                                            `/collections/${superProps.currentProperty.repconfig.property_collection_id}/rooms/${superProps.activeProperty}/edit` : 
                                            `/properties/${superProps.activeProperty}/edit`,
                                            state: { routeFromCalendar: true },
                                        }}
                                    >
                                        View Settings
                                    </Link>
                                </li>
                                </DropdownButton>
                            </Responsive>
                        </div>
                    }
                    </div>
                    : ''
                }
                    {props.view === "custom" && superProps.admin &&
                        <Responsive 
                            mobile={superProps.mobileMode}
                        >
                            <PrimaryButton 
                                fullWidth={false}
                                onClick={superProps.showImportByCSV}
                                style={{
                                    marginLeft: '5px', 
                                    maxHeight: '34px',
                                }}
                            >
                                Import Pricing
                            </PrimaryButton>
                        </Responsive>
                    }
                </div>
            </Title>             
        </div>
    )
}