import React, { Component, Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import { InputLabel, InputField } from '../../global/forms/FormElements';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import TimePicker from 'react-bootstrap-time-picker';

export default class Policies extends Component {

    shouldComponentUpdate(nextProps) {
        return (
            nextProps.formValidation.property_type !== this.props.formValidation.property_type ||
            nextProps.formValidation.petsAllowed !== this.props.formValidation.petsAllowed ||
            nextProps.formValidation.petsRequest !== this.props.formValidation.petsRequest ||
            nextProps.formValidation.petsFee !== this.props.formValidation.petsFee ||
            nextProps.formValidation.petsFeeFrequency !== this.props.formValidation.petsFeeFrequency ||
            nextProps.formValidation.internetIncluded !== this.props.formValidation.internetIncluded ||
            nextProps.formValidation.internet_type !== this.props.formValidation.internet_type ||
            nextProps.formValidation.internet_coverage !== this.props.formValidation.internet_coverage ||
            nextProps.formValidation.internetFee !== this.props.formValidation.internetFee ||
            nextProps.formValidation.internetFeeFrequency !== this.props.formValidation.internetFeeFrequency ||
            nextProps.formValidation.parkingIncluded !== this.props.formValidation.parkingIncluded ||
            nextProps.formValidation.parking_type !== this.props.formValidation.parking_type ||
            nextProps.formValidation.parking_location !== this.props.formValidation.parking_location ||
            nextProps.formValidation.parking_reservation !== this.props.formValidation.parking_reservation ||
            nextProps.formValidation.parkingFee !== this.props.formValidation.parkingFee ||
            nextProps.formValidation.parkingFeeFrequency !== this.props.formValidation.parkingFeeFrequency ||
            
            nextProps.cico_times.check_in_start !== this.props.cico_times.check_in_start ||
            nextProps.cico_times.check_in_end !== this.props.cico_times.check_in_end ||
            nextProps.cico_times.check_out !== this.props.cico_times.check_out ||
            nextProps.data.children_ok !== this.props.data.children_ok ||
            nextProps.data.smoking_allowed !== this.props.data.smoking_allowed ||
            nextProps.petsAllowedValue !== this.props.petsAllowedValue ||
            nextProps.petsFeeValue !== this.props.petsFeeValue ||
            nextProps.internetIncludedValue !== this.props.internetIncludedValue ||
            nextProps.internetTypeValue !== this.props.internetTypeValue ||
            nextProps.internetCoverageValue !== this.props.internetCoverageValue ||
            nextProps.internetFeeValue !== this.props.internetFeeValue ||
            nextProps.internetFeeFrequencyValue !== this.props.internetFeeFrequencyValue ||
            nextProps.parkingIncludedValue !== this.props.parkingIncludedValue ||
            nextProps.parkingTypeValue !== this.props.parkingTypeValue ||
            nextProps.parkingLocationValue !== this.props.parkingLocationValue ||
            nextProps.parkingReservationValue !== this.props.parkingReservationValue ||
            nextProps.parkingFeeValue !== this.props.parkingFeeValue ||
            nextProps.parkingFeeFrequencyValue !== this.props.parkingFeeFrequencyValue ||
            nextProps.integrationsDataByOrgName.integration_name !== this.props.integrationsDataByOrgName.integration_name ||
            nextProps.propertyContentLocks.cico !== this.props.propertyContentLocks.cico ||
            nextProps.propertyContentLocks.policies.pets !== this.props.propertyContentLocks.policies.pets ||
            nextProps.propertyContentLocks.policies.internet !== this.props.propertyContentLocks.policies.internet ||
            nextProps.propertyContentLocks.policies.parking !== this.props.propertyContentLocks.policies.parking ||
            nextProps.propertyCommData.wifi_name !== this.props.propertyCommData.wifi_name ||
            nextProps.propertyCommData.wifi_password !== this.props.propertyCommData.wifi_password
        );
    }

    render() {
        const {
            data,
            isAdmin,
            cico_times,
            formValidation,
            petsAllowedValue,
            petsRequestValue,
            petsFeeValue,
            petsFeeFrequencyValue,
            internetIncludedValue,
            internetTypeValue,
            internetCoverageValue,
            internetFeeValue,
            internetFeeFrequencyValue,
            parkingIncludedValue,
            parkingTypeValue,
            parkingLocationValue,
            parkingReservationValue,
            parkingFeeValue,
            parkingFeeFrequencyValue,
            propertyContentLocks,
            integrationsDataByOrgName,
            handleCiCoOnChange,
            createDefaultRadioButton,
            handlePolicyChange,
            updateLocks,
            handleCommDataOnChange,
            propertyCommData
        } = this.props;
  
        const childrenAllowed = [
            {name: "children_ok", value: "yes", title: "Yes"},
            {name: "children_ok", value: "over 12", title: "Over 12"},
            {name: "children_ok", value: "no", title: "No"},
        ];
        const smokingAllowed = [
            {name: "smoking_allowed", value: "true", title: "Yes"},
            {name: "smoking_allowed", value: "false", title: "No"}
        ];
        const petsAllowed = [
            {name: "petsAllowed", value: "yes", title: "Yes"},
            {name: "petsAllowed", value: "charges may apply", title: "Fee May Apply"},
            {name: "petsAllowed", value: "fee applies", title: "Fee Applies"},
            {name: "petsAllowed", value: "no", title: "No"}
        ];
        const petsRequest = [
            {name: "petsRequest", value: "upon request", title: "Pets Allowed Upon Request"},
            {name: "petsRequest", value: "yes", title: "No Request Required"}
        ];
        const petsFeeFrequency = [
            {name: "petsFeeFrequency", value: "day", title: "Per Day"},
            {name: "petsFeeFrequency", value: "week", title: "Per Week"},
            {name: "petsFeeFrequency", value: "stay", title: "Per Stay"},
            {name: "petsFeeFrequency", value: "per pet per day", title: "Per Pet / Day"},
            {name: "petsFeeFrequency", value: "per pet per week", title: "Per Pet / Week"},
            {name: "petsFeeFrequency", value: "per pet per stay", title: "Per Pet / Stay"}
        ];
        const internetIncluded = [
            {name: "internetIncluded", value: "yes", title: "Yes"},
            {name: "internetIncluded", value: "charges may apply", title: "Fee May Apply"},
            {name: "internetIncluded", value: "fee applies", title: "Fee Applies"},
            {name: "internetIncluded", value: "no", title: "No"}
        ];
        const internetType = [
            {name: "internet_type", value: "wifi", title: "WiFi"},
            {name: "internet_type", value: "cable", title: "Cable"},
        ];
        const internetCoverage = [
            {name: "internet_coverage", value: "entire property", title: "Entire Property"},
            {name: "internet_coverage", value: "some rooms", title: "Some Rooms"},
            {name: "internet_coverage", value: "public areas", title: "Public Areas"},
            {name: "internet_coverage", value: "business center", title: "Business Center"},
        ];
        const internetFeeFrequency = [
            {name: "internetFeeFrequency", value: "minute", title: "Per Minute"},
            {name: "internetFeeFrequency", value: "hour", title: "Per Hour"},
            {name: "internetFeeFrequency", value: "day", title: "Per Day"},
        ];
        const parkingIncluded = [
            {name: "parkingIncluded", value: "yes", title: "Yes"},
            {name: "parkingIncluded", value: "charges may apply", title: "Fee May Apply"},
            {name: "parkingIncluded", value: "fee applies", title: "Fee Applies"},
            {name: "parkingIncluded", value: "no", title: "No"}
        ];
        const parkingType = [
            {name: "parking_type", value: "public", title: "Public"},
            {name: "parking_type", value: "private", title: "Private"},
        ];
        const parkingLocation = [
            {name: "parking_location", value: "on site", title: "On Site"},
            {name: "parking_location", value: "location nearby", title: "Nearby"},
        ];
        const parkingReservation = [
            {name: "parking_reservation", value: "not needed", title: "Not Needed"},
            {name: "parking_reservation", value: "needed", title: "Needed"},
            {name: "parking_reservation", value: "not possible", title: "Not Possible"},
        ];
        const parkingFeeFrequency = [
            {name: "parkingFeeFrequency", value: "hour", title: "Per Hour"},
            {name: "parkingFeeFrequency", value: "day", title: "Per Day"},
            {name: "parkingFeeFrequency", value: "week", title: "Per Week"},
            {name: "parkingFeeFrequency", value: "stay", title: "Per Stay"},
        ];
        
        return (
            <Fragment>
                {
                    isAdmin && 
                    integrationsDataByOrgName.integration_name !== 'racalendar' ?
                    <Row>
                        <Col md={12} sm={12} xs={12}>
                            <InputLabel 
                                id="title"
                                htmlFor="title"
                                className={formValidation.title && 'form-validation'}
                            >		
                                Check-In / Check-Out Periods Lock &nbsp;
                                <OverlayTrigger placement='top' overlay={<Tooltip id="tip-cico" className='properties_cico__tooltip'>																																							
                                                {(propertyContentLocks.cico === 'LOCKED') ? 
                                                        "Unlock to enable PMS integration to overwrite check-in / check-out periods." : 
                                                        "Lock to prevent PMS integration from overwriting edited check-in / check-out periods."}
                                        </Tooltip>}>
                                        <a href="#" onClick={(e) => {
                                                        updateLocks('cico', propertyContentLocks.cico === "LOCKED" ? "UNLOCKED" : "LOCKED")
                                                }}>
                                                <i className={(propertyContentLocks.cico === 'LOCKED') ? 
                                                        "icon-lock" : 
                                                        "icon-unlock"} />
                                        </a>
                                </OverlayTrigger>
                            </InputLabel>
                        </Col>
                        <Col md={12} sm={12} xs={12}>
                            <hr />
                        </Col>
                    </Row> 
                    : ''
                }
                <Row>
                    <Col md={6} className="time-picker-col">
                        <InputLabel>
                            Check-In
                        </InputLabel>
                        <TimePicker 
                            name="check_in_start" 
                            id="check_in_start"
                            value={cico_times.check_in_start}
                            className="form-field" 
                            onChange={value => handleCiCoOnChange(value, 'check_in_start')}
                        />
                        <i className="cico icon-Checkin"></i>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className="time-picker-col">
                        <InputLabel>
                            Check-In End
                        </InputLabel>
                        <TimePicker 
                            name="check_in_end" 
                            id="check_in_end"
                            value={cico_times.check_in_end}
                            className="form-field" 
                            onChange={value => handleCiCoOnChange(value, 'check_in_end')}
                        />
                        <i className="cico icon-Checkin"></i>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className="time-picker-col">
                        <InputLabel>
                            Check-Out
                        </InputLabel>
                        <TimePicker 
                            name="check_out" 
                            id="check_out" 
                            className="form-field"
                            value={cico_times.check_out} 
                            onChange={value => handleCiCoOnChange(value, 'check_out')}
                        />
                        <i className="cico icon-checkout"></i>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <InputLabel>
                            Children Allowed
                        </InputLabel>
                        {createDefaultRadioButton(childrenAllowed, data.children_ok)}
                    </Col>
                    <Col md={12} sm={12}>
                        <hr/>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <InputLabel>
                            Smoking Allowed
                        </InputLabel>
                        {createDefaultRadioButton(smokingAllowed, data.smoking_allowed.toString())}
                    </Col>
                    <Col md={12} sm={12}>
                        <hr/>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} sm={12} className="bottom-margin-5">
                        {
                            isAdmin && 
                            integrationsDataByOrgName.integration_name !== 'racalendar' &&
                            <InputLabel>
                                Pets Lock &nbsp;
                                <OverlayTrigger placement='top' overlay={<Tooltip id="tip-pets" className='properties_pets__tooltip'>																																							
                                    {(propertyContentLocks.policies.pets === 'LOCKED') ? 
                                        "Unlock to enable PMS integration to overwrite pets content." : 
                                        "Lock to prevent PMS integration from overwriting edited pets content."}
                                    </Tooltip>}
                                >
                                    <a href="#" onClick={(e) => {
                                        updateLocks('policies', (propertyContentLocks.policies.pets === 'LOCKED') ? { ...propertyContentLocks.policies, pets: "UNLOCKED" } : { ...propertyContentLocks.policies, pets: "LOCKED" })}}
                                    >
                                        <i className={(propertyContentLocks.policies.pets === 'LOCKED') ? 
                                            "icon-lock" : 
                                            "icon-unlock"} 
                                        />
                                    </a>
                                </OverlayTrigger>
                            </InputLabel>
                        }
                        <hr/>
                    </Col>
                    <br/>
                    <Col md={2} sm={12}>
                        <InputLabel
                            className={formValidation.petsAllowed && 'form-validation'}
                        >
                            Pets Allowed
                        </InputLabel>
                        {createDefaultRadioButton(petsAllowed, petsAllowedValue, 'pets')}
                        {formValidation.petsAllowed && <span className="required-error-text">Pets Allowed is required</span>}
                    </Col>
                    {
                        (petsAllowedValue === "yes" ||
                        petsAllowedValue === "charges may apply" ||
                        petsAllowedValue === "fee applies") && 
                        <Col md={2} sm={12}>
                            <InputLabel
                                className={formValidation.petsRequest && 'form-validation'}
                            >
                                Pet Request
                            </InputLabel>
                            {createDefaultRadioButton(petsRequest, petsRequestValue, 'pets')}
                            {formValidation.petsRequest && <span className="required-error-text">Pet Request is required</span>}
                        </Col>
                    }
                    {
                        (petsAllowedValue === "fee applies") && 
                        <Fragment>
                            <Col md={2} sm={12}>
                                <InputLabel
                                    id="petsFee"
                                    className={formValidation.petsFee && 'form-validation'}
                                >
                                    Fee
                                </InputLabel>
                                <InputField
                                    type='number'
                                    name="petsFee" 
                                    value={petsFeeValue || ""} 
                                    onChange={e => handlePolicyChange(e, 'pets')}
                                />
                                {formValidation.petsFee && <span className="required-error-text">Fee is required</span>}
                            </Col>
                            <Col md={2} sm={12}>
                                <InputLabel
                                    id="petsFeeFrequency"
                                    className={formValidation.petsFeeFrequency && 'form-validation'}
                                >
                                    Fee Frequency
                                </InputLabel>
                                {createDefaultRadioButton(petsFeeFrequency, petsFeeFrequencyValue, 'pets')}
                                {formValidation.petsFeeFrequency && <span className="required-error-text">Fee Frequency is required</span>}
                            </Col>
                        </Fragment>
                    }
                    <Col md={12} sm={12}>
                        <hr/>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} sm={12} className="bottom-margin-5">
                        {
                            isAdmin && 
                            integrationsDataByOrgName.integration_name !== 'racalendar' &&
                            <InputLabel>
                                Internet Lock &nbsp;
                                <OverlayTrigger placement='top' overlay={<Tooltip id="tip-internet" className='properties_internet__tooltip'>																																							
                                    {(propertyContentLocks.policies.internet === 'LOCKED') ? 
                                        "Unlock to enable PMS integration to overwrite internet content." : 
                                        "Lock to prevent PMS integration from overwriting edited internet content."}
                                    </Tooltip>}
                                >
                                    <a href="#" onClick={(e) => {
                                        updateLocks('policies', (propertyContentLocks.policies.internet === 'LOCKED') ? { ...propertyContentLocks.policies, internet: "UNLOCKED" } : { ...propertyContentLocks.policies, internet: "LOCKED" })}}
                                    >
                                        <i className={(propertyContentLocks.policies.internet === 'LOCKED') ? 
                                            "icon-lock" : 
                                            "icon-unlock"} 
                                        />
                                    </a>
                                </OverlayTrigger>
                            </InputLabel>
                        }
                        <hr/>
                    </Col>
                    <br/>
                    <Col md={2} sm={12}>
                        <InputLabel
                            className={formValidation.internetIncluded && 'form-validation'}
                        >
                            Internet Included
                        </InputLabel>
                        {createDefaultRadioButton(
                            internetIncluded, 
                            internetIncludedValue)}
                        {formValidation.internetIncluded && <span className="required-error-text">Internet Included is required</span>}
                    </Col>
                    {
                        (internetIncludedValue === "yes" ||
                        internetIncludedValue === "charges may apply" ||
                        internetIncludedValue === "fee applies") &&
                        <Fragment>
                            <Col md={2} sm={12}>
                                <InputLabel
                                    className={formValidation.internet_type && 'form-validation'}
                                >
                                    Internet Type
                                </InputLabel>
                                {createDefaultRadioButton(internetType, internetTypeValue)}
                                {formValidation.internet_type && <span className="required-error-text">Internet Type is required</span>}
                            </Col>
                            <Col md={2} sm={12}>
                                <InputLabel
                                    className={formValidation.internet_coverage && 'form-validation'}
                                >
                                    Internet Coverage
                                </InputLabel>
                                {createDefaultRadioButton(
                                    internetCoverage, 
                                    internetCoverageValue)}
                                {formValidation.internet_coverage && <span className="required-error-text">Internet Coverage is required</span>}
                            </Col>
                        </Fragment>
                    }
                    {
                        (internetIncludedValue === "fee applies") && 
                        <Fragment>
                            <Col md={2} sm={12}>
                                <InputLabel
                                    id="internetFee"
                                    className={formValidation.internetFee && 'form-validation'}
                                >
                                    Fee
                                </InputLabel>
                                <InputField 
                                    type="number"
                                    name="internetFee" 
                                    value={internetFeeValue || ""} 
                                    onChange={e => handlePolicyChange(e, 'internet')}
                                />
                                {formValidation.internetFee && <span className="required-error-text">Fee is required</span>}
                            </Col>
                            <Col md={2} sm={12}>
                                <InputLabel
                                    id="internetFeeFrequency"
                                    className={formValidation.internetFeeFrequency && 'form-validation'}
                                >
                                    Fee Frequency
                                </InputLabel>
                                {createDefaultRadioButton(
                                    internetFeeFrequency, 
                                    internetFeeFrequencyValue)}
                                {formValidation.internetFeeFrequency && <span className="required-error-text">Fee Frequency is required</span>}
                            </Col>
                        </Fragment>
                    }
                    {
                        internetTypeValue == 'wifi' &&
                        <Fragment>
                            <Col md={12} sm={12}>
                                <Row>
                                    <Col md={6} sm={12} xs={12}>
                                        <InputLabel>
                                        Wi-Fi Name
                                        </InputLabel>
                                        <InputField 
                                            type="text"
                                            name="wifi_name" 
                                            value={propertyCommData.wifi_name || ""} 
                                            onChange={e => handleCommDataOnChange(e)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} sm={12} xs={12}>
                                        <InputLabel
                                            className='top-margin'
                                        >
                                            Wi-Fi Password
                                        </InputLabel>
                                        <InputField 
                                            type="text"
                                            name="wifi_password" 
                                            value={propertyCommData.wifi_password || ""} 
                                            onChange={e => handleCommDataOnChange(e)}
                                        />
                                    </Col>
                                </Row>	
                            </Col>
                        </Fragment>
                    }
                </Row>
                <Row>
                    <Col md={12} sm={12} className="bottom-margin-5">
                        {
                            isAdmin && 
                            integrationsDataByOrgName.integration_name !== 'racalendar' &&
                            <InputLabel>
                                Parking Lock &nbsp;
                                <OverlayTrigger placement='top' overlay={<Tooltip id="tip-parking" className='properties_parking__tooltip'>																																							
                                    {(propertyContentLocks.policies.parking === 'LOCKED') ? 
                                        "Unlock to enable PMS integration to overwrite parking content." : 
                                        "Lock to prevent PMS integration from overwriting edited parking content."}
                                    </Tooltip>}
                                >
                                    <a href="#" onClick={(e) => {
                                        updateLocks('policies', (propertyContentLocks.policies.parking === 'LOCKED') ? { ...propertyContentLocks.policies, parking: "UNLOCKED" } : { ...propertyContentLocks.policies, parking: "LOCKED" })}}
                                    >
                                        <i className={(propertyContentLocks.policies.parking === 'LOCKED') ? 
                                            "icon-lock" : 
                                            "icon-unlock"} 
                                        />
                                    </a>
                                </OverlayTrigger>
                            </InputLabel>
                        }
                        <hr/>
                    </Col>
                    <br/>
                    <Col md={2} sm={12}>
                        <InputLabel
                            className={formValidation.parkingIncluded && 'form-validation'}
                        >
                            Parking Included
                        </InputLabel>
                        {createDefaultRadioButton(
                            parkingIncluded,
                            parkingIncludedValue)}
                        {formValidation.parkingIncluded && <span className="required-error-text">Parking Included is required</span>}
                    </Col>
                    {
                        (parkingIncludedValue === "yes" ||
                        parkingIncludedValue === "charges may apply" ||
                        parkingIncludedValue === "fee applies") &&
                        <Fragment>
                            <Col md={2} sm={12}>
                                <InputLabel
                                    className={formValidation.parking_type && 'form-validation'}
                                >
                                    Parking Type
                                </InputLabel>
                                {createDefaultRadioButton(
                                    parkingType, 
                                    parkingTypeValue)}
                                {formValidation.parking_type && <span className="required-error-text">Parking Type is required</span>}
                            </Col>
                            <Col md={2} sm={12}>
                                <InputLabel
                                    className={formValidation.parking_location && 'form-validation'}
                                >
                                    Parking Location
                                </InputLabel>
                                {createDefaultRadioButton(
                                    parkingLocation,
                                    parkingLocationValue)}
                                {formValidation.parking_location && <span className="required-error-text">Parking Location is required</span>}
                            </Col>
                            <Col md={2} sm={2}>
                                <InputLabel
                                    className={formValidation.parking_reservation && 'form-validation'}
                                >
                                    Parking Reservation
                                </InputLabel>
                                {createDefaultRadioButton(
                                    parkingReservation,
                                    parkingReservationValue)}
                                {formValidation.parking_reservation && <span className="required-error-text">Parking Reservation is required</span>}
                            </Col>
                            {
                                (parkingIncludedValue === "fee applies") && 
                                <Fragment>
                                    <Col md={2} sm={12}>
                                        <InputLabel
                                            id="parkingFee"
                                            className={formValidation.parkingFee && 'form-validation'}
                                        >
                                            Fee
                                        </InputLabel>
                                        <InputField 
                                            type="number"
                                            name="parkingFee" 
                                            value={parkingFeeValue || ""} 
                                            onChange={e => handlePolicyChange(e, 'parking')}
                                        />
                                        {formValidation.parkingFee && <span className="required-error-text">Fee is required</span>}
                                    </Col>
                                    <Col md={2} sm={12}>
                                        <InputLabel
                                            id="parkingFeeFrequency"
                                            className={formValidation.parkingFeeFrequency && 'form-validation'}
                                        >
                                            Fee Frequency
                                        </InputLabel>
                                        {createDefaultRadioButton(
                                            parkingFeeFrequency,
                                            parkingFeeFrequencyValue)}
                                        {formValidation.parkingFeeFrequency && <span className="required-error-text">Fee Frequency is required</span>}
                                    </Col>
                                </Fragment>
                            }
                        </Fragment>
                    }
                    <Col md={12} sm={12}>
                        <hr/>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}