import React, { Component } from 'react';
import ReactTable from 'react-table/react-table.min.js';
import Pagination from 'react-js-pagination';
import { InputField } from '../global/forms/FormElements';
import Loader from './Loader';
import { withRouter } from 'react-router';

/**
 * For table component and pagination.
 * @param {*} props 
 */

class TableComponent extends Component {
    handleSubmit = e => {
        e.preventDefault();
        if (this.props.onPageSizeChange) {
            let pageSize = e.target.value;
            
            if (pageSize.trim() !== '' && Number.isInteger(parseInt(pageSize))) {
                this.props.onPageSizeChange(pageSize);
            }
        }
    }
    render(){
        const { props } = this;
       
        return (
            <ReactTable
                PaginationComponent={
                    (props) => {
                        let pageChange = (page) => {
                            if(props.handlePageChange) {
                                props.handlePageChange(page-1)
                            } else {
                                props.onPageChange(page-1)
                            }
                        }
                        return props.totalItemsCount > 0 && props.showPagination && (
                            <div
                                className={`pagination-container${this.props.mobileMode ? ' mobile' : ''}${this.props.cssClass ? ` ${this.props.cssClass}` : ''}`} 
                            >
                                    <div className="units-info-container">
                                        {props.totalItemsCount} {props.itemType}&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                                    </div>
                                    <div className="page_size-input-container">
                                        <div 
                                            className='page-size' 
                                        >
                                            <InputField
                                                className="pageSize"
                                                type='number' 
                                                name='pageSize'
                                                autoComplete="off"
                                                defaultValue={props.pageSize}
                                                onChange={(e) => {
                                                    const { customMaxPageSize } = this.props;

                                                    if(e.target.value > (customMaxPageSize || 100)) {
                                                        e.target.value = customMaxPageSize || 100;
                                                    } else if(e.target.value < 0) {
                                                        e.target.value = 1;
                                                    }
                                                }}
                                                onKeyUp={(e) => {
                                                    if(e.keyCode === 13 && e.target.value !== 0 && Number.isInteger(Number(e.target.value))) {
                                                        this.handleSubmit(e)
                                                    }
                                                }}
                                            /> 
                                            / page
                                        </div>
                                    </div>
                                    <Pagination
                                        activePage={props.activePage ? props.activePage : props.page + 1}
                                        linkClass={this.props.mobileMode ? "mobile-link" : ""}
                                        pageRangeDisplayed={this.props.mobileMode ? 1 : 5}
                                        itemsCountPerPage={props.pageSize}
                                        totalItemsCount={parseInt(props.totalItemsCount)}
                                        innerClass={`pagination${!this.props.mobileMode ? ' pull-right' : ''}`}
                                        onChange={pageChange}
                                    />
                            </div>
                        )
                    }
                }
                LoadingComponent={
                    (props)=> {
                        return <Loader hide={props.loading ? false : true}/>
                    }
                }
             {...props} />
            )
    }
}
export default withRouter(TableComponent)