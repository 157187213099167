import React from 'react';
/**
 * @class Used on properties form for add amenities in properties.
 */
export class CheckBoxGroup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            collapsed: true
        }
        this.handleChange = this.handleChange.bind(this);
        this.togglePanel = this.togglePanel.bind(this);

    }

    /**
     * Is panel toggled or not.
     */
    togglePanel() {
        let isCollapsed = this.state.collapsed;
        this.setState({
            collapsed: !isCollapsed
        })
    }

    /**
     * Handle checkbox changes.
     * @param {*} event 
     */
    handleChange(event) {
        let target = event.target;
        this.props.cbClick(target.name, target.value, target.checked); 
    }
    
    componentDidMount() {
        if(!!this.props.defaultExpanded) {
            this.setState({
                collapsed: false
            })
        }
    }

    render() {
        let _this = this;
        let props = this.props;
        let classes= props.cbClass ? (' '+props.cbClass) : '';
        let checkboxes = [];
        let uncheckedboxes = [];
        
        if(props.items) {
            let values = props.values || [];
            props.items.forEach(function(item, index){
                let itemVal = item.value || '';
                let itemName = props.name;
                let itemSelected = values.indexOf(itemVal) >= 0 ? true : false;
                let cBox = (
                    <label 
                        key={index} 
                        className="checkbox-wrap" 
                        key={item.value}
                    >
                        <input 
                            type="checkbox" 
                            onChange={_this.handleChange} 
                            defaultChecked={itemSelected} 
                            name={itemName} 
                            defaultValue={itemVal}
                        />
                        <div 
                            className={
                                `checkbox-label 
                                    ${
                                        // Pets Validation
                                        ((itemVal === "Pets OK" && props.formValidationPetsAmenityRequired.validation && props.formValidationPetsAmenityRequired.petsAllowed !== "no") || 
                                        (props.values.includes("Pets OK") && itemVal === "Pets OK" && props.formValidationPetsAmenityRequired.validation && props.formValidationPetsAmenityRequired.petsAllowed === "no")) ||
                                        // Internet Validation
                                        (((itemVal === "Internet" || itemVal === "Internet -- Wireless") && props.formValidationInternetAmenityRequired.validation && props.formValidationInternetAmenityRequired.internetIncluded !== "no") || 
                                        (((props.values.includes("Internet") && itemVal === "Internet") || 
                                        (props.values.includes("Internet -- Wireless") && itemVal === "Internet -- Wireless")) && 
                                        props.formValidationInternetAmenityRequired.validation && props.formValidationInternetAmenityRequired.internetIncluded === "no")) || 
                                        // Parking Validation - parkingIncluded = "yes"
                                        ((itemVal === "Parking -- Free") && props.formValidationParkingAmenityRequired.validation && props.formValidationParkingAmenityRequired.parkingIncluded === "yes") ||
                                        // parkingIncluded = "charges may apply", "fee applies"
                                        ((itemVal === "Parking" || itemVal === "Parking -- Covered" || itemVal === "Parking -- Off Street" || itemVal === "Parking -- On Street" || itemVal === "Parking -- RV" ) && 
                                        props.formValidationParkingAmenityRequired.validation && 
                                        (props.formValidationParkingAmenityRequired.parkingIncluded === "charges may apply" || 
                                        props.formValidationParkingAmenityRequired.parkingIncluded === "fee applies") ||
                                        // parkingIncluded = "no"
                                        ((props.values.includes("Parking -- Free") && itemVal === "Parking -- Free") || 
                                        (props.values.includes("Parking") && itemVal === "Parking") || 
                                        (props.values.includes("Parking -- Covered") && itemVal === "Parking -- Covered") || 
                                        (props.values.includes("Parking -- Off Street") && itemVal === "Parking -- Off Street") ||
                                        (props.values.includes("Parking -- On Street") && itemVal === "Parking -- On Street") ||
                                        (props.values.includes("Parking -- RV") && itemVal === "Parking -- RV")) && 
                                        props.formValidationParkingAmenityRequired.validation && 
                                        (props.formValidationParkingAmenityRequired.parkingIncluded === "no")) ? "form-validation" : ""}`
                                    }
                        > 
                            {item.label}
                            <span 
                                className="icon-Cross checkbox-close"
                            >
                            </span>
                        </div>
                    </label>
                )
                if(itemSelected) {
                    checkboxes.push(cBox)
                } else {
                    uncheckedboxes.push(cBox);
                }
            });
            if(checkboxes.length === 0) {
                checkboxes.push(<span key={0} className="placeholder-text">Add {this.props.label || 'Amenities'}</span>)
            }
        }
        
        return (
            <div 
                className={
                    "checkbox-group" + 
                    classes + 
                    (this.state.collapsed || (uncheckedboxes.length === 0) ? ' collapsed': ' expanded') + 
                    ((this.props.formValidationAmenities || 
                    (this.props.formValidationPetsAmenityRequired && this.props.formValidationPetsAmenityRequired.validation) || 
                    (this.props.formValidationInternetAmenityRequired && this.props.formValidationInternetAmenityRequired.validation) ||
                    (this.props.formValidationParkingAmenityRequired && this.props.formValidationParkingAmenityRequired.validation)) ? " form-validation" : "")}>
                <div className="checked-boxes">
                    <div className="checked-boxes-inner">{checkboxes}</div>
                    { uncheckedboxes.length !== 0 ?
                        <div className="cb-toggle" onClick={this.togglePanel}>
                            { this.state.collapsed  ?
                                (<div className="add-text">Add {this.props.label || 'Amenities'} +</div>)
                                : (<div className="primary-btn active-btn">Done</div>)
                            }
                        </div>
                    : null }
                </div>
                <div className="unchecked-boxes">
                    {uncheckedboxes}
                </div>
            </div>
        )
    }
}