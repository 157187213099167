import React from 'react';
import Col from 'react-bootstrap/lib/Col';

import ExpandableImage from '../components/global/ExpandableImage';

export const faqContent = (_this) => {

    const openModal = (e, _this, url) => {
        e.preventDefault();
        e.stopPropagation();

        _this.setState({
            videoModal: 'video-modal',
            videoURL: url
        });
    };

    return [
        {
            title: 
                <Col>Building Your Property Listing:
                    <span 
                        onClick={e => openModal(e, _this, 'https://www.youtube.com/embed?si=AUKdMEbbWnmZQBje&rel=0&playlist=uQYrQbMAOsg&loop=1')}
                    >
                        <i className="icon-play"/>
                    </span>
                </Col>,
            rows: [
                {
                    title: <Col>Creating a Title</Col>,
                    content: 
                    <Col>
                        <div>Your title is one of the first things guests will see when viewing your property listing, but writing one doesn’t have to be daunting.</div>
                        <ol>
                            <li>Think about the two main features of your property - for example hot tub and a 2 minute walk to the beach.</li>
                            <li>Think about the ideal guest for your property.</li>
                        </ol>
                        <div>Then, try to include these components in your title.  For example:  Cozy Cottage with Hot Tub 2 Min from Beach - perfect for families.</div>
                    </Col>,
                },
                {
                    title: <Col>Writing a Description</Col>,
                    content:
                    <Col>
                        <div>When writing a description, keep in mind that about 90% of guests will only read a property description after they have narrowed down their property selections to 5 or less!  So while a description is important, make sure your photos and amenities are complete too since guests often search by amenities and see your first photo in their search results.  Your description should offer concrete details about your property such as:</div>
                        <ol>
                            <li>Distance to local attractions</li>
                            <li>Bedding Types (and what floor they are on)</li>
                            <li>Special features photos may not as easily show (i.e. pillow top mattresses, 48 jet hot tub, chef’s kitchen)</li>
                        </ol>
                        <div>Basically you are trying to convince a traveler to select your home over alternatives.  Give travelers the information they need to fall in love with your home.</div>
                    </Col>,
                },
                {
                    title: <Col>Selecting Your Amenities</Col>,
                    content:
                    <Col>
                        <div>Amenities are very important to guests - especially in popular leisure destinations.  When guests have many properties to choose from, they will filter their choices by selecting amenities first to narrow down the field.  The best way to ensure your property is included is to mark ALL amenities that your property offers, even amenities that appear to be duplicated since RedAwning maps to different requirements by website partner.  The RedAwning portal offers an extensive list of amenities and if one that you need isn’t there please add it to your description and also let your onboarding specialist know so that we can add it in a future update.</div>
                    </Col>,
                },
                {
                    title: <Col>Setting Special Policies</Col>,
                    content:
                    <Col>
                        <div>Note that every policy limitation you set may discourage some guests from selecting your property, but it's important for you to comply with any local rules as well as personal preferences for your property.  The policies you can set directly in the portal are Pets, Smoking, and Children related policies.  You can also add in the Notes Section any other specific policies you have or as part of local requirements such as:</div>
                        <ol>
                            <li>Quiet Hours</li>
                            <li>Front Desk or Gate Attendant will require ID</li>
                        </ol>
                        <div>Please note RedAwning terms and conditions do require all primary guests to be at least 25 years of age and reservations can be cancelled without penalty if guests do not meet this requirement.</div>
                        <div>Our Policies section also includes specifics you can set for checkin/checkout time, defaulted to our recommendation, as well as Internet access and parking.</div>
                    </Col>,
                },
                {
                    title: 
                        <Col>Pricing Your Property:
                            <span 
                                onClick={e => openModal(e, _this, 'https://www.youtube.com/embed?si=d7atbOjSXjJc1OeX&rel=0&playlist=ZOVv2guEWik&loop=1')}
                            >
                                <i className="icon-play"/>
                            </span>
                        </Col>,
                    content:
                    <Col>
                        <div>RedAwning HIGHLY recommends taking advantage of our automated Smart Pricing solution which generates the most revenue for you with no work to keep updating your pricing manually.  One of our owners almost doubled his yearly income just by making a change to Smart Pricing - contact your onboarding specialist to sign up or with any questions if you have not already signed up for this free solution.</div>
                        <div>Even with Smart  Pricing you will need to set a minimum nightly rate for your property.  To do this take in consideration</div>
                        <ol>
                            <li>The low season nightly rates for comparable properties in the area.</li>
                            <li>Your annual income estimate from RedAwning.</li>
                            <li>Our Smart Pricing will set rates higher than your minimum in most periods, especially after you start to receive bookings since it adjusts for your own demand in addition to destination demand.</li>
                        </ol>
                        <div>With this information in mind, set what your  minimum nightly rate should be.</div>
                    </Col>,
                },
            ],
        },
        {
            title: <Col>Going Live:</Col>,
            rows: [
                {
                    title: <Col>Validations
                        <span
                            onClick={e => openModal(e, _this, 'https://www.youtube.com/embed?si=evR0xKlyqPaSGc7a&rel=0&playlist=3aEtW97mFaw&loop=1')}
                        >
                            <i className="icon-play"/>
                        </span></Col>,
                    content: 
                    <Col>
                        <div>Our system automatically runs validations by channel for your property to be sure that your listing meets the unique listing requirements of each marketing channel.  Most are self explanatory and rely on industry best practices (i.e. at least 20 photos, high res photos, amenities selected) but if you see a validation requirement you don’t understand or have questions, please reach out to your onboarding specialist for help.</div>
                    </Col>,
                },
                {
                    title: <Col>Marketing Channels</Col>,
                    content:
                    <Col>
                        <div>Your property listing can go live very quickly, often on the same day you set it Live in our Portal, while some channels take 2-3 days to publish your listing, and once your listing is live in each major channel you can see links to your live listings by channel right in our Portal under the “Reports” tab.</div>
                    </Col>,
                },
            ],
        },
        {
            title: <Col>Taking Reservations:</Col>,
            rows: [
                {
                    title: <Col>Calendar Management</Col>,
                    content: 
                    <Col>
                        <div>RedAwning will automatically block your calendar for each booking you receive via all channels.  If you need to block your calendar for personal use or family or friends stays, be sure to block those dates on your calendar now or as soon as you know them as RedAwning presents your property everywhere and may book open dates at any moment.</div>
                    </Col>,
                },
                {
                    title: <Col>Charging for optional add-ons</Col>,
                    content:
                    <Col>
                        <div>If your property has features that require an additional fee not already covered in your Policy Settings above (such as a pool heat fee) please let your onboarding specialist know standards, and you can also email Guest Services at Guest@RedAwning.com for special add-on fees unique to a guest. Those fees will need to be collected from guests and we are happy to assist with that.</div>
                    </Col>,
                },
                {
                    title: <Col>Sending a Rental Agreement</Col>,
                    content:
                    <Col>
                        <div>RedAwing includes a Guest Agreement with all reservations.  However, if you require an additional rental agreement or addendum from the guests due to HOA rules or other legal requirements, please feel free to send this directly to the guest. You will receive complete guest contact information 28 days prior to arrival.</div>
                    </Col>,
                },
                {
                    title: <Col>Rejecting a Reservation</Col>,
                    content:
                    <Col>
                        <div>Don’t. All of your performance with RedAwning and on the marketing channels we work with depends on you accepting every booking for available dates, even if you wanted to use those dates yourself but forgot to block your calendar. In addition to creating a bad guest experience, rejecting a booking will deprioritize your listing on most channels, including for example, losing Superhost status on Airbnb for a year, and most channels also charge you additional fees to reject a valid booking. RedAwning automatically accepts all bookings for open dates. If a cancellation can not be avoided, such as due to a maintenance issue, let our team know IMMEDIATELY and also seek an alternative property you can offer to the impacted guests. Please note that any additional channel fees for a cancellation or relocation will be passed on to you. Also rejection of multiple bookings is grounds for termination by RedAwning as guest satisfaction is critical to our success together.</div>
                    </Col>,
                },
            ],
        },
        {
            title: <Col>During the Stay:</Col>,
            rows: [
                {
                    title: <Col>Welcoming Guests</Col>,
                    content: 
                    <Col>
                        <div>Contactless check in is here to stay (keycode or lockbox), and while we HIGHLY recommend offering this service there are still many ways you should interact with your guests for great experience. How about:</div>
                        <ol>
                            <li>A welcome basket with some local favorite treats.</li>
                            <li>Information pamphlets on some lesser known attractions you love.</li>
                            <li>A personal note welcoming guests to your home and also detailing any information about the place like instructions for the remotes, hot tub, or resetting the wifi if necessary.</li>
                            <li>Be sure your contact information, or that of a local maintenance / cleaning assistant is available should the guests run into any problems during their stay.</li>
                        </ol>
                        <div>The RedAwning Guest Services team is also available to support you and your guests 24/7.</div>
                    </Col>,
                },
            ],
        },
        {
            title: <Col>Post Stay:</Col>,
            rows: [
                {
                    title: <Col>Guest Issues</Col>,
                    content: 
                    <Col>
                        <div>Accidents happen.  If you need to file a damage claim for accidental guest damage please do so here:</div>
                        <a href="https://www.redawning.com/how-to-submit-damage-claim" target="_blank">https://www.redawning.com/how-to-submit-damage-claim</a>
                    </Col>,
                },
                {
                    title: <Col>Reviews
                        <span
                            onClick={e => openModal(e, _this, 'https://www.youtube.com/embed?si=40uVLTIke93LNBGo&rel=0&playlist=cVRYSSwv-ro&loop=1')}
                        >
                            <i className="icon-play"/>
                        </span></Col>,
                    content: 
                    <Col>
                        <div>For marketing channels that support reviews of Guests, RedAwning gives all guests a 5 star review about 3 days after check out unless instructed otherwise.  If you feel a guest's behavior or use of your home deserves less than 3 stars please let our review team know by email(<a href="mailto: reviews@redawning.com" target="_blank">reviews@redawning.com</a>) know within 3 days of check out. We also review all guest reviews for your property and will notify you of any negative reviews so that you may respond or modify practices, as well as especially positive reviews, or other reviews that include suggestions for actionable changes.</div>
                        <br/>
                        <div>
                            <strong>Responding to Guest Reviews</strong>
                            <br/>
                            <strong>RedAwning clients now have the ability to respond to their own reviews via the RedAwning Hospitality Platform!</strong> Clients who want access to the beta version of this product can contact their Account Manager to have it enabled for their account. For Clients who don't want to respond to their own reviews, RedAwning posts replies and shares feedback as needed. To participate, clients must adhere to the following guidelines: 
                            <ol>
                                <li><strong>Respond to all reviews, including negative ones</strong>: Guests feel more confident booking with hosts who respond to feedback. A short, friendly public reply to a negative review shows that the host is receptive to suggestions, attentive, and cares about their guests&#39; experiences. 
                                </li>
                                <li><strong>Keep replies friendly:</strong> Use a professional tone when replying to guest reviews, and never include any derogatory remarks.
                                </li>
                                <li><strong>No direct sales via reviews:</strong> Don't include any phone numbers or website links in review responses, or attempt to do any off-channel selling.
                                </li>
                                <li><strong>No blaming the guest, RedAwning, or the channel:</strong> It's ok to respond with clarification, but be careful about placing blame.
                                </li>
                            </ol>
                            <br/>
                            <strong>How does it work?</strong>
                            <br/>
                            Write your own replies, or use AI to generate a response by clicking the “Generate Message” button. Use the arrows to review different AI-generated responses until you’re satisfied, or make edits manually. See just Pending, Complete, or No Response reviews by using the checkboxes at the top of the page, or filter to see reviews with a specific star rating (*note: all channels star ratings are normalized on a scale of 1-5; for example, an 8/10 score is displayed as a 4 star rating in the dashboard). <a href="https://www.loom.com/share/37778f0f1147482096262bff1592b8dc?sid=948a609d-f89c-4c61-a6a1-d68d61432be9"><strong>Watch a one-minute demo video here</strong></a>.
                            <br/>
                            <br/>
                            <strong>What does it look like?</strong>
                            <br/>
                            <div style={{display: 'flex', flexWrap: 'wrap', gap: 10}}>
                                <ExpandableImage
                                    src='/app/assets/faq-reviews-generate-message.png'
                                    alt='Generate Message'
                                />
                                <ExpandableImage
                                    src='/app/assets/faq-reviews-send-response.png'
                                    alt='Send Response'
                                />
                            </div>
                            <br/>
                            <br/>
                            <strong>Where do I find it?</strong> 
                            <br/>
                            Once enabled, you will see a <strong>Reviews</strong> tab in the RedAwning Smart Portal (aka the RedAwning Hospitality Platform).
                        </div>
                    </Col>,
                },
                {
                    title: <Col>Getting Paid</Col>,
                    content: 
                    <Col>
                        <div>Payments due to you are held in your account at Stripe and released to you automatically on the first business day after each guest checks out, or five business days after check in for longer stays.  Note that the banking system may add 5-7 business days for the funds to arrive in your account after we send them, though it's usually faster.</div>
                        <br/>
                        <div>For reservations taken by a channel partner, such as Airbnb, who manages their own payments from guests, we will process payments to you the first business day after we receive the payments from the channel partner, which usually will add 3-5 days to payout timing.  You can see more information on Airbnb payout timing here: <a href="https://www.airbnb.com/help/article/425" target="_blank">https://www.airbnb.com/help/article/425</a></div>
                        <br/>
                        <div>The Payments tab under the Account Menu in the RedAwning Portal reflects activity in your own Stripe Connect account.</div>
                        <br/>
                        <div>Every time RedAwning collects a payment that is due to you, you will see a deposit into your Stripe Connect account.  This can include a guest reservation deposit (usually 10% of a booking), a pet fee, 100% of a guest reservation payment for last minute bookings, or any other amounts that are due to you.</div>
                        <br/>
                        <div>When a reservation is cancelled, you will see a withdrawal from your Stripe Connect account for any refund due to the guest. </div>
                        <br/>
                        <div>You will also see a withdrawal from your Stripe Connect account when a payout is sent to your bank account, such as for a completed stay.</div>
                        <br/>
                        <div>Note that for reservations taken by a channel partner, such as Airbnb, who manages their own payments from guests, you will not see any amounts in your Stripe Connect account or the Payments report until we send those amounts to your Stripe Connect account, after receiving them from the channel partner.</div>
                        <br/>
                        <div>If you have not received a payment as expected after allowing for the normal banking system delays for payments per the information above, please complete this form so that our payments team can assist you directly: <a href="https://www.redawning.com/payout-inquiry" target="_blank">https://www.redawning.com/payout-inquiry</a></div>
                    </Col>,
                },
            ],
        },
        {
            title: <Col>Payments and Reporting:</Col>,
            rows: [
                {
                    title: <Col>Stripe Setup
                        <span
                            onClick={e => openModal(e, _this, 'https://www.youtube.com/embed?si=6tJFsbtBC1QrL03p&rel=0&playlist=pJKjtOg8IVE&loop=1')}
                        >
                            <i className="icon-play"/>
                        </span></Col>,
                    content: 
                    <Col>
                        <div>Setting up Stripe takes a few easy steps:</div>
                        <ol>
                            <li>Click “account” menu in the lower left hand of your screen, click “payment” under the account menu, once you are under the payment section click the “Red Stripe Connect” button. Stripe will walk you through the setup.</li>
                            <li>We have prepared the video above as a step-by-step walkthrough.</li>
                        </ol>
                    </Col>,
                },
                {
                    title: <Col>Reports
                        <span
                            onClick={e => openModal(e, _this, 'https://www.youtube.com/embed?si=oNqU5COnNblaq7Ko&rel=0&playlist=6YoFYgfxB2A&loop=1')}
                        >
                            <i className="icon-play"/>
                        </span></Col>,
                    content: 
                    <Col>
                        <div>RedAwning offers many helpful reports for your vacation rental including reports on validations errors, performance, taxes, and even availability percentage.</div>
                    </Col>,
                },
            ],
        }
    ]   
}
