import React from 'react';
import Loader from '../../global/Loader';
import Autosuggest from 'react-autosuggest';
import Col from 'react-bootstrap/lib/Col';
import Modal from 'react-bootstrap/lib/Modal';
import ProgressBar from 'react-bootstrap/lib/ProgressBar';
import { PrimaryButton, FieldWrap } from '../../global/forms/FormElements';
import apiHandler from '../../../js/apiHandler';

export class PropertiesModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            suggestions: [],
            value: ''
        }
        this.fetchPropertiesSuggestions = this.fetchPropertiesSuggestions.bind(this);
        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
        this.selectProperty = this.selectProperty.bind(this)
    }

    /**
     * Invoke when any property selected.
     * @param {number} index 
     */
    selectProperty(index) {
        this.setState({
            importId: this.state.suggestions[index]
        })
    }

    /**
     * fetch properties suggestions
     * @param {*} event 
     */
    fetchPropertiesSuggestions(event) {
        if (typeof (event.value) === 'string') {
            setTimeout(() => {
                if (event.value === this.state.value && this.state.value.length > 2) {
                    this.setState({
                        suggestion: [],
                        autoLoader: true
                    })
                    apiHandler.get(`/properties?organization=${this.props.org}&keyword=${encodeURIComponent(event.value)}`).then((suggestion) => {
                        let suggestionData = suggestion.data.filter(property => {
                            if (!property.repconfig.collection_name)
                                return true
                        })
                        this.setState({
                            suggestions: suggestionData,
                            autoLoader: false
                        })
                    }).catch((e) => {
                        console.log(e);
                        this.setState({
                            suggestions: [],
                            autoLoader: false
                        })
                    })
                }
            }, 1500)
        }
    }

    onSuggestionsClearRequested() {

    }

    /**
     * When click on import property it will add property into particular collection.
     */
    async importProperty() {
        let i = 0;
        this.setState({
            importProcess: true,
            importCompleted: undefined,
        })
        try {
            let collectionData = await apiHandler.put(`/propertycollections/${this.props.collectionId}/properties/${this.state.importId.property_id}`, { body: {}, response: true })
            
            if (collectionData.response) {
                this.setState({
                    importCompleted: false
                })
            } else {
                this.setState({
                    importCompleted: true
                })
            }
        } catch(e) {
            console.log(e)
            this.setState({
                importCompleted: false
            })
        }
    }

    /**
     * for show import process started.
     * @param {*} value 
     */
    toggleImportByProperty(value) {
        this.setState({
            importProcess: false,
            value: '',
            suggestion: [],
        })
        this.props.closeModal(value);
    }

    render() {
        const inputProps = {
            placeholder: 'Property Name',
            value: this.state.value,
            className: 'form-field',
            onChange: (event, { newValue }) => {
                if (typeof (newValue) !== 'string') {
                    newValue = newValue.content.title
                }
                this.setState({
                    value: newValue
                });
            },
        };
        return (<Modal className="import-modal" show={this.props.show} onHide={() => this.toggleImportByProperty(false)}>
            {this.state.importProcess ?
                <Modal.Body className="importing">
                    <Col xs={12} className="bottom-margin">
                        <div className="importing-loader submit-modal">
                            {this.state.importCompleted === true && <span className='status-icon status-icon--success'></span>}
                            {this.state.importCompleted === false && <span className='status-icon status-icon--error' />}
                            {this.state.importCompleted === undefined && <Loader />}
                            <div className='progress-wrapper upload-status'>
                                {this.state.importCompleted === true && <p>Property Added to {this.props.collectionName}</p>}
                                {this.state.importCompleted === false && <p>Error</p>}
                                {this.state.importCompleted === undefined && <p>Adding Property to {this.props.collectionName}</p>}
                            </div>
                            {(this.state.importCompleted === true || this.state.importCompleted === false) &&
                                (<div className="import-btns">
                                    <button className='primary-btn' onClick={() => this.toggleImportByProperty(true)}>Close</button>
                                </div>)}
                        </div>
                    </Col>
                </Modal.Body>
                :
                <div>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Add Property To Collection
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FieldWrap>
                            <Autosuggest
                                onSuggestionsFetchRequested={this.fetchPropertiesSuggestions}
                                suggestions={this.state.suggestions}
                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                getSuggestionValue={(event) => event}
                                renderSuggestion={(renderSuggestion) => <div>{renderSuggestion.content.title} (ID: {renderSuggestion.property_id})</div>}
                                inputProps={inputProps}
                                onSuggestionSelected={(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => { this.selectProperty(suggestionIndex) }}
                            />
                            {this.state.autoLoader && <div className="auto-suggest-loader">
                                <Loader />
                            </div>}
                        </FieldWrap>
                        <PrimaryButton type="button" fullWidth={false} disabled={!this.state.importId || !this.state.value} onClick={this.importProperty.bind(this)}>Add</PrimaryButton>
                    </Modal.Body>
                </div>
            }
        </Modal>)
    }
} 
