import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import { InputLabel, InputField, PrimaryButton } from '../global/forms/FormElements';
import Switch from "react-switch";
import { API } from 'aws-amplify';
import EditTemplates from './EditTemplates';
import Modal from 'react-bootstrap/lib/Modal';
import Picky from 'react-picky';
import Loader from '../global/Loader';
import { 
    getSessionId,
    infoPopover
} from '../../helpers/helpers';
import { delay } from '../../js/actions';
import ReactTableComponent from '../global/ReactTableComponent';
import EditDevices from './EditDevices';
import _ from "lodash";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Constants from '../../js/constants';
import moment from 'moment';

class CommunicationManagement extends Component {
    viewSubscriptions = false;
    viewTemplates = false;
    subCatInfo = 'If a team member is unavailable, messages will be sent to the next available team member or to Redawning Guest Support.';
    tid = getSessionId();
    propertyCount = 0;
    copyTeamMemberData = {
        subscriberId: '',
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        role_type: '',
    };
    copyTeamMemberPropertyOptionsValue = '';
    initSubCategories = {};
    initPrimaryRoles = [];
    initState = {
        mode: 'create',
        modal: '',
        isLoading: true,
        subcategoryError: false,
        teamMembersIsLoading: true,
        teamMembersError: false,
        propertiesIsLoading: true,
        propertyOptionsValue: { 
            property_id: 'global', 
            title: 'Global',
            label: 'Global'
        },
        teamMemberPropertiesIsLoading: false,
        teamMemberPropertyOptionsValue: [],
        subCategories: {},
        propertiesList: [],
        teamMemberPropertiesList: [],
        globalTeamMembers: [],
        selectedTeamMember: {},
        sourceTeamMemberData: {},
        teamMemberErrorMsg: '',
        teamMemberDeleteErrorMsg: '',
        teamMemberPropertiesErrorMsg: '',
        unassociatedTeamMembers: [],
        unassociatedTeamMembersOptionsValue: [],
        unassociatedTeamMembersIsLoading: false,
        teamMemberData: {
            subscriberId: '',
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            role_type: '',
        },
        teamMemberValidations: {
            subscriberId: false,
            first_name: false,
            last_name: false,
            email: false,
            phone_number: false,
            role_type: false,
            emailFormat: false,
            notificationPref: false,
            subCategories: false,
        },
        globalColumns: [],
        propertyColumns: [],
        subCatActiveKey: '',
    }
    state = {
        ...this.initState,
    }

    reloadColumns = (property) => {
        let columns = [];

        columns = [
            {
                sortable: false,
                sortable: false,
                'Cell': row=>{
                    const opt_ins = row && row.original && row.original.opt_ins;
                    const subscriber = row && row.original && row.original.subscriber;
                    const display_name = subscriber && row.original.subscriber.display_name;
                    let role = opt_ins && row.original.opt_ins[0] && row.original.opt_ins[0].role_type;

                    if(role == 'guest') {
                        role = 'host';
                    };

                    return(
                        <div>
                            {
                                role ? <span className="team-member-tag">{role[0].charAt(0).toUpperCase() + role.slice(1)}</span> : ''
                            }
                            <span className='bold-text'>{display_name}</span>
                        </div>
                    )
                }
            },
            {
                sortable: false,
                'Cell': row=>{
                    const { propertyOptionsValue } = this.state;
                    const data =  row && row.original;
                    const message_type_data = data && row.original.subscriber && row.original.subscriber.message_type_data;
                    const email_enabled = message_type_data && row.original.subscriber.message_type_data.email && row.original.subscriber.message_type_data.email.length;
                    const sms_enabled = message_type_data && row.original.subscriber.message_type_data.sms && row.original.subscriber.message_type_data.sms.length;
                    const portal_enabled = message_type_data && row.original.subscriber.message_type_data.portal && row.original.subscriber.message_type_data.portal.length;

                    return(
                        <div>
                            <span className="team-member-tag">Notifications</span>
                            <div>
                                <span className='sbold-text'>
                                    {(email_enabled || sms_enabled || portal_enabled) ? 'Enabled' : 'Disabled'} {" "}
                                    {
                                        propertyOptionsValue.property_id == 'global' &&
                                        <Fragment>
                                            |{" "}
                                            <span className="clickable-text" onClick={e => this.initTeamMemberData(e, data, 'delete')}>Edit</span>
                                        </Fragment>
                                    }
                                </span>
                            </div>
                        </div>
                    )
                }
            },
            {
                sortable: false,
                'Cell': row=>{
                    const { propertyOptionsValue } = this.state;
                    const data = row && row.original;
                    const opt_ins = data && row.original.opt_ins;
                    let propertyIds = [];

                    if(opt_ins && row.original.opt_ins.length) {
                        opt_ins.forEach((optin) => {
                            if(optin && optin.internal_id) {
                                propertyIds.push(optin.internal_id);
                            };
                        });
                    };

                    const newPropertyIds = [...new Set(propertyIds)];

                    return(
                        <div>
                            <span className="team-member-tag">Responsibilities</span>
                            <div>
                                <span className='sbold-text'>
                                    {!newPropertyIds.length || (this.propertyCount == newPropertyIds.length) ? 'All Properties' : 'Specific Properties'} {" "}
                                    {
                                        propertyOptionsValue.property_id == 'global' &&
                                        <Fragment>
                                            |{" "}
                                            <span 
                                                className="clickable-text" 
                                                onClick={e => this.handleSelectedTeamMember(e, data, 'add-tm-property')}
                                            >
                                                Edit
                                            </span>
                                        </Fragment>
                                    }
                                </span>
                            </div>
                        </div>
                    )
                }
            },
        ];

        if(property) {
            columns.push({
                sortable: false,
                minWidth: 100,
                'Cell': row=>{
                    const data =  row && row.original;

                    return(
                        <div>
                            <span 
                                className="clickable-text sbold-text" 
                                onClick={e => this.handleSelectedTeamMember(e, data, 'remove-tm-property')}
                            >
                                Remove from Property
                            </span>
                        </div>
                    )
                }
            })
        }
        else {
            columns.push({
                sortable: false,
                minWidth: 100,
                'Cell': row=>{
                    const { propertyOptionsValue } = this.state;
                    const data =  row && row.original;

                    return(
                        <div>
                            {
                                propertyOptionsValue.property_id == 'global' &&
                                <span 
                                    className="clickable-text sbold-text" 
                                    onClick={e => this.handleSelectedTeamMember(e, data, 'remove-tm')}
                                >
                                    Remove
                                </span>
                            }
                        </div>
                    )
                }
            })
        }

        return columns;
    }

    async componentDidMount() {
        this.checkPermissions();
        this.getChildOrgProperties();
        this.getProperties();
        this.getTeamMembers();
        await this.getSubCategories();

        this.setState({
            isLoading: false
        });
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if(_.get(prevProps, 'org') != _.get(this.props, 'org')) {
            this.setState({
                ...this.initState
            });

            this.checkPermissions();
            this.getChildOrgProperties();
            this.getProperties();
            this.getTeamMembers();
            await this.getSubCategories();

            this.setState({
                isLoading: false
            });
        };
    }

    getTeamMembers = async () => {
        const { propertyOptionsValue } = this.state;
        let resp;
        let allTeamMembers = [];
        let unassociatedTeamMembers = [];
        let propertyTeamMembers = [];
        let globalTeamMembers = [];

        try {
            resp = await API.get('ramapi',`/commhub/organizations/${this.props.org}/subscribers`, { response: true, isCognito: true });

            if(resp && resp.data && resp.data.length) {
                if(propertyOptionsValue.property_id == 'global') {
                    allTeamMembers = resp.data;
                }
                else {
                    resp.data.forEach((tm) => {
                        let globalOptins = tm.opt_ins.filter((optin) => (!optin.internal_id));
                        if(globalOptins.length) {
                            globalTeamMembers.push(tm);
                        };

                        let propertyOptins = tm.opt_ins.filter((optin) => (optin.internal_id &&optin.internal_id.includes(propertyOptionsValue.property_id.toString())));
                        if(propertyOptins.length) {
                            propertyTeamMembers.push(tm);
                        };

                        let unassociatedOptins = tm.opt_ins.filter((optin) => (optin.internal_id && !optin.internal_id.includes(propertyOptionsValue.property_id.toString())));
                        if(unassociatedOptins.length) {
                            let roleType = '';
                            if(tm.opt_ins[0].role_type == 'guest') {
                                roleType = 'host';
                            }
                            else {
                                roleType = tm.opt_ins[0].role_type;
                            };

                            unassociatedTeamMembers.push({
                                ...tm,
                                subscriber_id: tm.subscriber.subscriber_id,
                                label: `${roleType.length ? roleType[0].charAt(0).toUpperCase() + roleType.slice(1) + " - " : ""}${tm.subscriber.display_name}`
                            });
                        };
                    });
                };
            };

            this.setState({
                globalTeamMembers: propertyOptionsValue.property_id == 'global' ? allTeamMembers : globalTeamMembers,
                propertyTeamMembers: propertyTeamMembers,
                unassociatedTeamMembers: unassociatedTeamMembers,
                globalColumns: this.reloadColumns(),
                propertyColumns: this.reloadColumns(true),
                teamMembersIsLoading: false,
            });
        } 
        catch(e) {
            console.log(e);
            this.setState({
                teamMembersError: true,
                teamMembersIsLoading: false,
            });
        };
    }

    getSubCategories = async () => {
        let resp;

        try {
            resp = await API.get('ramapi',`/commhub/catsubcats`, { response: true, isCognito: true });

            if(resp && resp.data && resp.data.length) {
                let subCat = resp.data;
                let categories = {};
                let groupSubCatInPairs = {};

                subCat.forEach((x) => {
                    let defaultData = {
                        sms_host: false,
                        email_host: false,
                        portal_host: false,
                        sms_guest: false,
                        email_guest: false,
                        portal_guest: false,
                        sms_schedule: false,
                        email_schedule: false,
                        sms_host_availability: {
                            monday: true,
                            tuesday: true,
                            wednesday: true,
                            thursday: true,
                            friday: true,
                            saturday: true,
                            sunday: true,
                            start: '09:00:00Z',
                            end: '21:00:00Z',
                            timeZone: 'US/Pacific'
                        },
                        email_host_availability: {
                            monday: true,
                            tuesday: true,
                            wednesday: true,
                            thursday: true,
                            friday: true,
                            saturday: true,
                            sunday: true,
                            start: '09:00:00Z',
                            end: '21:00:00Z',
                            timeZone: 'US/Pacific'
                        },
                    };

                    if(x.subcategory !== '_default' && !x.category.includes('*') && !x.subcategory.includes('*')) {
                        if((x.role_type == 'guest')) {
                            if(categories['host'] === undefined) {
                                categories['host'] = [{...x, ...defaultData}];
                            } else {
                                categories['host'].push({...x, ...defaultData});
                            };
                        } else if (categories[x.role_type] === undefined) {
                            categories[x.role_type] = [{...x, ...defaultData}];
                        } else {
                            categories[x.role_type].push({...x, ...defaultData});
                        };
                    };
                });

                function groupObjectsInPairs(array) {
                    const resultArray = [];
                    
                    for (let i = 0; i < array.length; i += 2) {
                      const pair = [array[i], array[i + 1]].filter(Boolean);
                      resultArray.push(pair);
                    };
                  
                    return resultArray;
                };

                Object.entries(categories).forEach((cat) => {
                    let sortedCat = [];

                    sortedCat = cat[1].sort(function(a, b) {
                        const roleA = a.role_type.toLowerCase();
                        const roleB = b.role_type.toLowerCase();
                    
                        if (roleA < roleB) {
                            return -1;
                        } else if (roleA > roleB) {
                            return 1;
                        } else {
                            return 0;
                        };
                    });

                    groupSubCatInPairs[cat[0]] = groupObjectsInPairs(sortedCat);
                });

                let primaryRoles = [];
                Object.entries(categories).forEach((entry) => {
                    primaryRoles.push({
                        key: entry[0].charAt(0).toUpperCase() + entry[0].slice(1),
                        value: entry[0]
                    });
                });

                this.initSubCategories = groupSubCatInPairs;
                this.copySubCategories = groupSubCatInPairs;
                this.initPrimaryRoles = primaryRoles;

                this.setState({
                    subCategories: groupSubCatInPairs
                });
            };
        } 
        catch(e) {
            console.log(e)
            this.setState({
                subcategoryError: true
            });
        };
    }

    getChildOrgs = async () => {
        let resp_childOrgs;
        let orgList = [this.props.org];

        function getOrganizationName(data) {
            let organizationNames = [];
        
            function extractOrgNames(organizations) {
                organizations.forEach(org => {
                    organizationNames.push(org.organization_name);
                    if (org.child_organizations && org.child_organizations.length > 0) {
                        extractOrgNames(org.child_organizations);
                    }
                });
            }
        
            extractOrgNames(data);
            return organizationNames;
        }

        try {
            resp_childOrgs = await API.get("accapi", `/organizationtree?parent=${this.props.org}`);

            if(_.get(resp_childOrgs, '[0].child_organizations.length')) {
                let orgNames = getOrganizationName(resp_childOrgs[0].child_organizations);

                orgList = [...orgList, ...orgNames];
            };
        }
        catch(e) {
        }

        return orgList;
    }

    getChildOrgProperties = async () => {
        let orgNames = await this.getChildOrgs();

        let resp1;
        let resp2;
        let limit = 200;
        let offset = 0;
        let propertyList = [];
        let totalCount = 0;

        try {
            resp1 = await API.get("rapapi", `/promotion/properties?organizations=${orgNames.join(',')}&offset=${offset}&tid=${this.tid}&limit=${limit}`, { response: true, isCognito: true });

            if(_.get(resp1, 'data.list.length')) {

                totalCount = resp1.headers['x-total-count'];

                resp1.data.list.forEach((property) => {
                    propertyList.push({
                        ...property,
                        label: `${property.organization_title ? `${property.organization_title} -` : ''} ${property.property_id ? `${property.property_id} -` : ''} ${property.title ? property.title : ''}`
                    })
                });

                while (propertyList.length < totalCount) {
                    offset = offset + 200;
                    resp2 = await API.get("rapapi", `/promotion/properties?organizations=${orgNames.join(',')}&offset=${offset}&tid=${this.tid}&limit=${limit}`, { response: true, isCognito: true });

                    resp2.data.list.forEach((property) => {
                        propertyList.push({
                            ...property,
                            label: `${property.organization_title ? `${property.organization_title} -` : ''} ${property.property_id ? `${property.property_id} -` : ''} ${property.title ? property.title : ''}`
                        })
                    });
                };
            };

            propertyList.sort((a, b) => {
                if (a.label < b.label) {
                  return -1;
                }
                if (a.label > b.label) {
                  return 1;
                }
                return 0;
            });

            this.setState({
                teamMemberPropertiesList: propertyList,
                propertiesIsLoading: false,
            });
        }
        catch(e) {
            console.log(e)
            this.setState({
                propertiesIsLoading: false,
            });
        }
    }

    getProperties = async () => {
        let resp;
        let limit = 200;
        let offset = 0;
        let newPropertyList = [];

        try {
            resp = await API.get("rapapi", `/promotion/properties?organizations=${this.props.org}&offset=${offset}&tid=${this.tid}&limit=${limit}`, { response: true, isCognito: true });

            if(resp && resp.data && resp.data.list && resp.data.list.length) {

                this.propertyCount = resp.data.list.length;

                resp.data.list.forEach((property) => {
                    newPropertyList.push({
                        ...property,
                        label: `${property.property_id ? `${property.property_id} -` : ''} ${property.title ? property.title : ''}`
                    })
                });
            };

            this.setState({
                propertyOptionsValue: { 
                    property_id: 'global', 
                    title: 'Global',
                    label: 'Global'
                },
                propertiesList: [
                    { 
                        property_id: 'global', 
                        title: 'Global',
                        label: 'Global'
                    },
                    ...newPropertyList
                ],
                teamMemberPropertiesList: [
                    ...newPropertyList,
                ],
                propertiesIsLoading: false,
            });
        }
        catch(e) {
            console.log(e)
            this.setState({
                propertiesIsLoading: false,
            });
        }
    }

    checkPermissions = () => {
        if(_.isArray(this.props.permissions)) {
            if(this.props.permissions.includes('view_subscriptions')) {
                this.viewSubscriptions = true;
            };

            if(this.props.permissions.includes('view_templates')) {
                this.viewTemplates = true;
            };

            if(this.props.permissions.includes('view_devices')) {
                this.viewDevices = true;
            };
        };
    }

    duplicateOptins = (originalArray, internalIds) => {
        const duplicatedArray = [];
      
        originalArray.forEach((originalObject) => {
            internalIds.forEach((internalId) => {
                let {subscriber_org_opt_in, organization_id, priority, role_type, thread_id, ...newObj} = originalObject;
                const duplicatedObject = { ...newObj, internal_id: internalId, internal_id_type: 'nid' };
                duplicatedArray.push(duplicatedObject);
            });
        });
      
        return duplicatedArray;
    }

    removeDuplicateOptin(array) {
        let uniqueOptin = [];
        let newOptin = [];
    
        array.forEach(optin => {
            const isDuplicate = uniqueOptin.some(unique_optin => {
                const unique_message_type_data_selectors = unique_optin && unique_optin.message_type_data_selectors && unique_optin.message_type_data_selectors[0];
                const unique_participant_context = unique_optin && unique_optin.context && unique_optin.context.participant_context && unique_optin.context.participant_context[0];
                const unique_category_subcategory_id = unique_optin && unique_optin.category_subcategory_id;
                
                const optin_message_type_data_selectors = optin && optin.message_type_data_selectors && optin.message_type_data_selectors[0];
                const optin_participant_context = optin && optin.context && optin.context.participant_context && optin.context.participant_context[0];
                const optin_category_subcategory_id = optin && optin.category_subcategory_id;

                return (
                    unique_message_type_data_selectors == optin_message_type_data_selectors &&
                    unique_participant_context == optin_participant_context &&
                    unique_category_subcategory_id == optin_category_subcategory_id
                );
            });
    
            if (!isDuplicate) {
                uniqueOptin.push(optin);
            };
        });

        uniqueOptin.forEach((optin) => {
            newOptin.push({ ...optin, internal_id: null, internal_id_type: null });
        });
    
        return newOptin;
    }

    submitTeamMemberProperties = async () => {
        const {
            teamMemberPropertiesList,
            selectedTeamMember,
            teamMemberPropertyOptionsValue
        } = this.state;
        let data = {...selectedTeamMember}
        let propertyIds = [];
        let transformedArray = [];

        if(teamMemberPropertyOptionsValue.length !== teamMemberPropertiesList.length) {
            if(teamMemberPropertyOptionsValue.length) {
                teamMemberPropertyOptionsValue.forEach((property) => {
                    propertyIds.push(property.property_id);
                });
            };
        }
        else {
            propertyIds = [];
        };

        const newPropertyIds = [...new Set(propertyIds)];

        if(newPropertyIds.length) {
            const removeDuplicateOptin = this.removeDuplicateOptin(data.opt_ins);
            transformedArray = this.duplicateOptins(removeDuplicateOptin, newPropertyIds);
        }
        else {
            transformedArray = this.removeDuplicateOptin(data.opt_ins);
        };
        
        data.opt_ins = transformedArray;

        let resp;

        this.setState({
            teamMemberPropertiesIsLoading: true,
            teamMemberPropertiesErrorMsg: '',
        })
        
        try {
            resp = await API.put('ramapi',`/commhub/organizations/${this.props.org}/subscribers/${selectedTeamMember.subscriber.subscriber_id}`, { response: true, body: data });
            if(resp && resp.data) {
                await this.getTeamMembers();
            };

            this.resetTeamMember();

            this.setState({
                modal: '',
                selectedTeamMember: {},
                teamMemberPropertiesIsLoading: false
            })
        } 
        catch(e) {
            console.log(e);
            this.setState({
                teamMemberPropertiesErrorMsg: 'Oops! We encountered an error. Please contact customer support.',
                teamMemberPropertiesIsLoading: false
            });
        };
    }

    submitTeamMember = async () => {
        const {
            mode,
            subCategories,
            teamMemberData,
            sourceTeamMemberData,
        } = this.state;
        let email_uuid = Constants.generateUUID();
        let sms_uuid = Constants.generateUUID();
        let portal_uuid = Constants.generateUUID();
        let role = teamMemberData.role_type;

        function convertToSeconds (timeString) {
            const momentTime = moment.utc(timeString, 'HH:mm:ssZ');
            const seconds = momentTime.diff(moment.utc().startOf('day'), 'seconds');
            return seconds;
        };

        let data = {
            subscriber: {
                display_name: '',
                message_type_data: {}
            },
            organization_name: this.props.org,
            opt_ins: []
        };

        if(role.length) {
            // Create Team Member
            if(mode == 'create') {

                data.subscriber.display_name = `${teamMemberData.first_name}${teamMemberData.last_name ? ` ${teamMemberData.last_name}` : ''}`;

                if(teamMemberData.email) {
                    data.subscriber.message_type_data.email = [
                        {
                            _id: email_uuid,
                            addr: teamMemberData.email,
                            name: "my email"
                        }
                    ];
                };

                if(teamMemberData.phone_number) {
                    data.subscriber.message_type_data.sms = [
                        {
                            _id: sms_uuid,
                            phone_number: teamMemberData.phone_number,
                            name: "my phone"
                        }
                    ];
                };

                data.subscriber.message_type_data.portal = [
                    {
                        _id: portal_uuid,
                        name: "Portal"
                    }
                ];

                let subArr= [];
                subCategories[role].forEach((arr) => {
                    arr.forEach((sub) => {
                        if(sub.email_host || sub.email_guest || sub.sms_host || sub.sms_guest || sub.portal_host || sub.portal_guest) {
                            subArr.push(sub);
                        };
                    });
                });

                let newSubArr = [];
                if(subArr.length) {
                    subArr.forEach((sub) => {
                        let typeArr = [
                            'email_host',
                            'email_guest',
                            'sms_host',
                            'sms_guest',
                            'portal_host',
                            'portal_guest',
                        ];

                        typeArr.forEach((type) => {
                            let subData = {
                                organization_name: this.props.org,
                                opt_in_type: "external",
                                category: sub.category,
                                subcategory: sub.subcategory,
                                category_subcategory_id: sub.id,
                                message_type_data_selectors: [],
                                schedule: null,
                                context: {
                                    participant_context: []
                                }
                            };

                            if(sub[type] == true) {
                                let uuid = '';

                                if((type.includes('email') && !teamMemberData.email) || (type.includes('sms') && !teamMemberData.phone_number)) {
                                    return;
                                }
                                else {
                                    if(type.includes('email')) {
                                        uuid = email_uuid;
                                    }
                                    else if (type.includes('sms')) {
                                        uuid = sms_uuid;
                                    }
                                    else if (type.includes('portal')) {
                                        uuid = portal_uuid;
                                    };
    
                                    if(role == 'cleaner') {
                                        subData.context.participant_context.push('cleaner');
                                    }
                                    else if(type.includes('host')) {
                                        subData.context.participant_context.push('host');
                                    }
                                    else if(type.includes('guest')) {
                                        subData.context.participant_context.push('guest');
                                    };
    
                                    subData.message_type_data_selectors.push(uuid);
    
                                    if(type == 'email_host' || type == 'sms_host') {
                                        if(sub[`${type}_schedule`]) {
                                            subData.schedule = {
                                                time_start_secs: convertToSeconds(sub[`${type}_availability`].start),
                                                time_end_secs: convertToSeconds(sub[`${type}_availability`].end),
                                                timezone: sub[`${type}_availability`].timeZone,
                                                sunday: sub[`${type}_availability`].sunday,
                                                monday: sub[`${type}_availability`].monday,
                                                tuesday: sub[`${type}_availability`].tuesday,
                                                wednesday: sub[`${type}_availability`].wednesday,
                                                thursday: sub[`${type}_availability`].thursday,
                                                friday: sub[`${type}_availability`].friday,
                                                saturday: sub[`${type}_availability`].saturday
                                            };
                                        };
                                    };
    
                                    newSubArr.push(subData);
                                };
                            };
                        });
                    })
                }

                data.opt_ins = newSubArr;
            }
            else {
                // Update Team Member
                data.subscriber = {
                    ...sourceTeamMemberData.subscriber
                };

                data.subscriber.display_name = `${teamMemberData.first_name}${teamMemberData.last_name ? ` ${teamMemberData.last_name}` : ''}`;

                if(teamMemberData.email) {
                    if(_.get(sourceTeamMemberData, 'subscriber.message_type_data.email.length')) {
                        email_uuid = sourceTeamMemberData.subscriber.message_type_data.email[0]._id;

                        data.subscriber.message_type_data.email = [
                            {
                                ...sourceTeamMemberData.subscriber.message_type_data.email[0],
                                addr: teamMemberData.email,
                            }
                        ];
                    }
                    else {
                        if(!data.subscriber.message_type_data) {
                            data.subscriber.message_type_data = {};
                        };

                        if(!data.subscriber.message_type_data.email) {
                            data.subscriber.message_type_data.email = [];
                        };

                        data.subscriber.message_type_data.email = [
                            {
                                _id: email_uuid,
                                addr: teamMemberData.email,
                                name: "my email"
                            }
                        ];
                    };
                }
                else {
                    data.subscriber.message_type_data.email = [];
                };

                if(teamMemberData.phone_number) {
                    if(_.get(sourceTeamMemberData, 'subscriber.message_type_data.sms.length')) {
                        sms_uuid = sourceTeamMemberData.subscriber.message_type_data.sms[0]._id;

                        data.subscriber.message_type_data.sms = [
                            {
                                ...sourceTeamMemberData.subscriber.message_type_data.sms[0],
                                phone_number: teamMemberData.phone_number,
                            }
                        ];
                    }
                    else {
                        if(!data.subscriber.message_type_data) {
                            data.subscriber.message_type_data = {};
                        };
    
                        if(!data.subscriber.message_type_data.sms) {
                            data.subscriber.message_type_data.sms = [];
                        };

                        data.subscriber.message_type_data.sms = [
                            {
                                _id: sms_uuid,
                                phone_number: teamMemberData.phone_number,
                                name: "my phone"
                            }
                        ];
                    };
                }
                else {
                    data.subscriber.message_type_data.sms = [];
                };

                if(_.get(sourceTeamMemberData, 'subscriber.message_type_data.portal.length')) {
                    portal_uuid = sourceTeamMemberData.subscriber.message_type_data.portal[0]._id;
                }
                else {
                    if(!data.subscriber.message_type_data) {
                        data.subscriber.message_type_data = {};
                    };

                    if(!data.subscriber.message_type_data.portal) {
                        data.subscriber.message_type_data.portal = [];
                    };

                    data.subscriber.message_type_data.portal = [
                        {
                            _id: portal_uuid,
                            name: "Portal"
                        }
                    ];
                };

                let propertyIds = [];
                if(_.get(sourceTeamMemberData, 'opt_ins.length')) {
                    sourceTeamMemberData.opt_ins.forEach((optin) => {
                        if(optin && optin.internal_id) {
                            propertyIds.push(optin.internal_id);
                        };
                    });
                };
                const newPropertyIds = [...new Set(propertyIds)];

                let newSubArr = [];
                subCategories[role].forEach((arr) => {
                    arr.forEach((sub) => {
                        if(teamMemberData.email.length) {
                            // Host Email
                            if(sub.email_host) {
                                let subData = {
                                    organization_name: this.props.org,
                                    category: sub.category,
                                    subcategory: sub.subcategory,
                                    category_subcategory_id: sub.id,
                                    message_type_data_selectors: [],
                                    subscriber_id: sourceTeamMemberData.subscriber.subscriber_id,
                                    opt_in_type: "external",
                                    schedule: null,
                                    context: {
                                        participant_context: ['host']
                                    }
                                };

                                let emailOptins = sourceTeamMemberData.opt_ins.filter((optin) => (
                                    (optin.context.participant_context && optin.context.participant_context.includes('guest')) &&
                                    optin.category_subcategory_id == sub.id && 
                                    optin.message_type_data_selectors.includes(email_uuid)
                                ));

                                if(emailOptins.length) {
                                    emailOptins.forEach((optin) => {
                                        subData = {...optin};

                                        subData.context.participant_context = ['host'];

                                        if(sub.email_host_schedule) {
                                            subData.schedule = {
                                                time_start_secs: convertToSeconds(sub.email_host_availability.start),
                                                time_end_secs: convertToSeconds(sub.email_host_availability.end),
                                                timezone: sub.email_host_availability.timeZone,
                                                sunday: sub.email_host_availability.sunday,
                                                monday: sub.email_host_availability.monday,
                                                tuesday: sub.email_host_availability.tuesday,
                                                wednesday: sub.email_host_availability.wednesday,
                                                thursday: sub.email_host_availability.thursday,
                                                friday: sub.email_host_availability.friday,
                                                saturday: sub.email_host_availability.saturday
                                            };
                                        }
                                        else {
                                            subData.schedule = null;
                                        };

                                        newSubArr.push(subData)
                                    });
                                }
                                else {
                                    subData.message_type_data_selectors.push(email_uuid);

                                    if(sub.email_host_schedule) {
                                        subData.schedule = {
                                            time_start_secs: convertToSeconds(sub.email_host_availability.start),
                                            time_end_secs: convertToSeconds(sub.email_host_availability.end),
                                            timezone: sub.email_host_availability.timeZone,
                                            sunday: sub.email_host_availability.sunday,
                                            monday: sub.email_host_availability.monday,
                                            tuesday: sub.email_host_availability.tuesday,
                                            wednesday: sub.email_host_availability.wednesday,
                                            thursday: sub.email_host_availability.thursday,
                                            friday: sub.email_host_availability.friday,
                                            saturday: sub.email_host_availability.saturday
                                        };
                                    };

                                    if(newPropertyIds.length) {
                                        newPropertyIds.forEach((id) => {
                                            newSubArr.push({
                                                ...subData,
                                                internal_id: id,
                                                internal_id_type: 'nid'
                                            });
                                        });
                                    }
                                    else {
                                        newSubArr.push(subData);
                                    };
                                };
                            };

                            // Guest Email
                            if(sub.email_guest) {
                                let subData = {
                                    organization_name: this.props.org,
                                    category: sub.category,
                                    subcategory: sub.subcategory,
                                    category_subcategory_id: sub.id,
                                    message_type_data_selectors: [],
                                    subscriber_id: sourceTeamMemberData.subscriber.subscriber_id,
                                    opt_in_type: "external",
                                    schedule: null,
                                    context: {
                                        participant_context: []
                                    }
                                };

                                let emailOptins = sourceTeamMemberData.opt_ins.filter((optin) => (
                                    ((optin.context.participant_context && optin.context.participant_context.includes('cleaner')) ||
                                    (optin.context.participant_context && optin.context.participant_context.includes('guest'))) &&
                                    optin.category_subcategory_id == sub.id && 
                                    optin.message_type_data_selectors.includes(email_uuid)
                                ));

                                if(emailOptins.length) {
                                    emailOptins.forEach((optin) => {
                                        subData = {...optin};

                                        if(role == 'cleaner') {
                                            subData.context.participant_context = ['cleaner'];
                                        }
                                        else if(role == 'host') {
                                            subData.context.participant_context = ['guest'];
                                        };

                                        subData.schedule = null;

                                        newSubArr.push(subData);
                                    });
                                }
                                else {
                                    subData.message_type_data_selectors.push(email_uuid);

                                    if(role == 'cleaner') {
                                        subData.context.participant_context = ['cleaner'];
                                    }
                                    else if(role == 'host') {
                                        subData.context.participant_context = ['guest'];
                                    };

                                    if(newPropertyIds.length) {
                                        newPropertyIds.forEach((id) => {
                                            newSubArr.push({
                                                ...subData,
                                                internal_id: id,
                                                internal_id_type: 'nid'
                                            });
                                        });
                                    }
                                    else {
                                        newSubArr.push(subData);
                                    };
                                };
                            };
                        };
                        

                        if(_.get(teamMemberData, 'phone_number.length')) {
                            // Host SMS
                            if(sub.sms_host ) {
                                let subData = {
                                    organization_name: this.props.org,
                                    category: sub.category,
                                    subcategory: sub.subcategory,
                                    category_subcategory_id: sub.id,
                                    message_type_data_selectors: [],
                                    subscriber_id: sourceTeamMemberData.subscriber.subscriber_id,
                                    opt_in_type: "external",
                                    schedule: null,
                                    context: {
                                        participant_context: ['host']
                                    }
                                };

                                let smsOptins = sourceTeamMemberData.opt_ins.filter((optin) => (
                                    (optin.context.participant_context && optin.context.participant_context.includes('host')) &&
                                    optin.category_subcategory_id == sub.id && 
                                    optin.message_type_data_selectors.includes(sms_uuid)
                                ));

                                if(smsOptins.length) {
                                    smsOptins.forEach((optin) => {
                                        subData = {...optin};

                                        subData.context.participant_context = ['host'];

                                        if(sub.sms_host_schedule) {
                                            subData.schedule = {
                                                time_start_secs: convertToSeconds(sub.sms_host_availability.start),
                                                time_end_secs: convertToSeconds(sub.sms_host_availability.end),
                                                timezone: sub.sms_host_availability.timeZone,
                                                sunday: sub.sms_host_availability.sunday,
                                                monday: sub.sms_host_availability.monday,
                                                tuesday: sub.sms_host_availability.tuesday,
                                                wednesday: sub.sms_host_availability.wednesday,
                                                thursday: sub.sms_host_availability.thursday,
                                                friday: sub.sms_host_availability.friday,
                                                saturday: sub.sms_host_availability.saturday
                                            };
                                        }
                                        else {
                                            subData.schedule = null;
                                        };

                                        newSubArr.push(subData);
                                    });
                                }
                                else {
                                    subData.message_type_data_selectors.push(sms_uuid);

                                    if(sub.sms_host_schedule) {
                                        subData.schedule = {
                                            time_start_secs: convertToSeconds(sub.sms_host_availability.start),
                                            time_end_secs: convertToSeconds(sub.sms_host_availability.end),
                                            timezone: sub.sms_host_availability.timeZone,
                                            sunday: sub.sms_host_availability.sunday,
                                            monday: sub.sms_host_availability.monday,
                                            tuesday: sub.sms_host_availability.tuesday,
                                            wednesday: sub.sms_host_availability.wednesday,
                                            thursday: sub.sms_host_availability.thursday,
                                            friday: sub.sms_host_availability.friday,
                                            saturday: sub.sms_host_availability.saturday
                                        };
                                    };

                                    if(newPropertyIds.length) {
                                        newPropertyIds.forEach((id) => {
                                            newSubArr.push({
                                                ...subData,
                                                internal_id: id,
                                                internal_id_type: 'nid'
                                            });
                                        });
                                    }
                                    else {
                                        newSubArr.push(subData);
                                    };
                                };
                            }

                            // Guest SMS
                            if(sub.sms_guest) {
                                let subData = {
                                    organization_name: this.props.org,
                                    category: sub.category,
                                    subcategory: sub.subcategory,
                                    category_subcategory_id: sub.id,
                                    message_type_data_selectors: [],
                                    subscriber_id: sourceTeamMemberData.subscriber.subscriber_id,
                                    opt_in_type: "external",
                                    schedule: null,
                                    context: {
                                        participant_context: []
                                    }
                                };

                                let smsOptins = sourceTeamMemberData.opt_ins.filter((optin) => (
                                    ((optin.context.participant_context && optin.context.participant_context.includes('cleaner')) ||
                                    (optin.context.participant_context && optin.context.participant_context.includes('guest'))) &&
                                    optin.category_subcategory_id == sub.id && 
                                    optin.message_type_data_selectors.includes(sms_uuid)
                                ));

                                if(smsOptins.length) {
                                    smsOptins.forEach((optin) => {
                                        subData = {...optin};

                                        if(role == 'cleaner') {
                                            subData.context.participant_context = ['cleaner'];
                                        }
                                        else if(role == 'host') {
                                            subData.context.participant_context = ['guest'];
                                        };

                                        subData.schedule = null;

                                        newSubArr.push(subData);
                                    });
                                }
                                else {
                                    subData.message_type_data_selectors.push(sms_uuid);

                                    if(role == 'cleaner') {
                                        subData.context.participant_context = ['cleaner'];
                                    }
                                    else if(role == 'host') {
                                        subData.context.participant_context = ['guest'];
                                    };

                                    if(newPropertyIds.length) {
                                        newPropertyIds.forEach((id) => {
                                            newSubArr.push({
                                                ...subData,
                                                internal_id: id,
                                                internal_id_type: 'nid'
                                            });
                                        });
                                    }
                                    else {
                                        newSubArr.push(subData);
                                    };
                                };
                            }
                        }
                        
                        // Host Portal
                        if(sub.portal_host) {
                            let subData = {
                                organization_name: this.props.org,
                                category: sub.category,
                                subcategory: sub.subcategory,
                                category_subcategory_id: sub.id,
                                message_type_data_selectors: [],
                                subscriber_id: sourceTeamMemberData.subscriber.subscriber_id,
                                opt_in_type: "external",
                                schedule: null,
                                context: {
                                    participant_context: ['host']
                                }
                            };

                            let portalOptins = sourceTeamMemberData.opt_ins.filter((optin) => (
                                (optin.context.participant_context && optin.context.participant_context.includes('host')) &&
                                optin.category_subcategory_id == sub.id && 
                                optin.message_type_data_selectors.includes(portal_uuid)
                            ));

                            if(portalOptins.length) {
                                portalOptins.forEach((optin) => {
                                    subData = {...optin};

                                    subData.context.participant_context = ['host'];

                                    newSubArr.push(optin)
                                });
                            }
                            else {
                                subData.message_type_data_selectors.push(portal_uuid);

                                if(newPropertyIds.length) {
                                    newPropertyIds.forEach((id) => {
                                        newSubArr.push({
                                            ...subData,
                                            internal_id: id,
                                            internal_id_type: 'nid'
                                        });
                                    });
                                }
                                else {
                                    newSubArr.push(subData);
                                };
                            };
                        };

                        // Guest Portal
                        if(sub.portal_guest) {
                            let subData = {
                                organization_name: this.props.org,
                                category: sub.category,
                                subcategory: sub.subcategory,
                                category_subcategory_id: sub.id,
                                message_type_data_selectors: [],
                                subscriber_id: sourceTeamMemberData.subscriber.subscriber_id,
                                opt_in_type: "external",
                                schedule: null,
                                context: {
                                    participant_context: []
                                }
                            };

                            let portalOptins = sourceTeamMemberData.opt_ins.filter((optin) => (
                                ((optin.context.participant_context && optin.context.participant_context.includes('cleaner')) ||
                                (optin.context.participant_context && optin.context.participant_context.includes('guest'))) && 
                                optin.message_type_data_selectors.includes(portal_uuid) &&
                                optin.category_subcategory_id == sub.id
                            ));

                            if(portalOptins.length) {
                                portalOptins.forEach((optin) => {
                                    subData = {...optin};

                                    if(role == 'cleaner') {
                                        subData.context.participant_context = ['cleaner'];
                                    }
                                    else if(role == 'host') {
                                        subData.context.participant_context = ['guest'];
                                    };

                                    newSubArr.push(subData);
                                });
                            }
                            else {
                                subData.message_type_data_selectors.push(portal_uuid);

                                if(role == 'cleaner') {
                                    subData.context.participant_context = ['cleaner'];
                                }
                                else if(role == 'host') {
                                    subData.context.participant_context = ['guest'];
                                };

                                if(newPropertyIds.length) {
                                    newPropertyIds.forEach((id) => {
                                        newSubArr.push({
                                            ...subData,
                                            internal_id: id,
                                            internal_id_type: 'nid'
                                        });
                                    });
                                }
                                else {
                                    newSubArr.push(subData);
                                };
                            };
                        };
                    });
                });

                let subCatNotAssociated = sourceTeamMemberData.opt_ins.filter((optin) => optin.subcategory == '*' || optin.subcategory == '_default');

                data.opt_ins = [...subCatNotAssociated, ...newSubArr];
            };
        };

        this.setState({
            teamMemberValidations: this.handleTeamMemberValidations()
        }, async () => {
            const { teamMemberValidations } = this.state;
            let validations = [];
            
            Object.entries(teamMemberValidations).forEach((entry) => {
                if(entry[1] == true) {
                    validations.push(entry[1])
                };
            });

            if(!validations.length) {
                this.setState({
                    teamMembersIsLoading: true,
                    teamMemberErrorMsg: '',
                    teamMemberDeleteErrorMsg: '',
                });

                let resp;

                try {
                    resp = await API[mode == 'edit' ? 'put' : 'post']('ramapi',`/commhub/organizations/${this.props.org}/subscribers${mode == 'edit' ? `/${sourceTeamMemberData.subscriber.subscriber_id}` : ''}`, { response: true, body: data });
                    if(resp && resp.data) {
                        await this.getTeamMembers();
                        
                        this.handleCancelTeamMember();
                    };
                } 
                catch(e) {
                    let msg = `Oops! We encountered an error while attempting to ${mode == 'create' ? 'add a new member' : 'update a team member'}. Please contact customer support.`;
        
                    if(e && e.response && e.response.status == '409') {
                        msg = 'This subscriber already exists';
                    }
                    else if(e && e.response && e.response.data && e.response.data.Message && e.response.data.Message.length) {
                        if(e.response.data.Message.match(/'(.*?)'/)) {
                            msg = e.response.data.Message.match(/'(.*?)'/)[1];
                        };
                    };
        
                    this.setState({
                        teamMemberErrorMsg: msg,
                        teamMembersIsLoading: false
                    });
                };
            };
        });
    }

    handleSelectedTeamMember = (e, data, type) => {
        e.preventDefault();

        this.setState({
            teamMemberErrorMsg: '',
            teamMemberDeleteErrorMsg: '',
            selectedTeamMember: data
        }, () => {
            if(type == 'remove-tm-property') {
                this.handleModal('', 'remove-tm-property');
            }
            else if(type == 'remove-tm') {
                this.handleModal('', 'remove-tm');
            }
            else if ('add-tm-property') {
                const { 
                    selectedTeamMember,
                    teamMemberPropertiesList,
                } = this.state;
                let teamMemberProperties = [];

                if(selectedTeamMember && selectedTeamMember.opt_ins && selectedTeamMember.opt_ins.length) {
                    selectedTeamMember.opt_ins.forEach((optin) => {
                        if(optin && optin.internal_id) {
                            teamMemberProperties.push(optin.internal_id);
                        };
                    });
                };

                let newTeamMemberProperties = [...new Set(teamMemberProperties)];

                let optionsValue = [];
                if(!newTeamMemberProperties.length) {
                    optionsValue = [...teamMemberPropertiesList];
                }
                else {
                    optionsValue = teamMemberPropertiesList.filter((prop) => newTeamMemberProperties.includes(prop.property_id.toString()));
                };

                this.copyTeamMemberPropertyOptionsValue = optionsValue;

                this.setState({
                    teamMemberPropertyOptionsValue: optionsValue
                }, () =>{
                    this.handleModal('', 'add-tm-property');
                })   
            };
        });
    }

    removeTeamMember = async () => {
        const { selectedTeamMember } = this.state;

        this.setState({
            teamMemberErrorMsg: '',
            teamMemberDeleteErrorMsg: '',
            teamMembersIsLoading: true
        });

        this.handleModal('', '');

        try {
            await API.del('ramapi',`/commhub/organizations/${this.props.org}/subscribers/${selectedTeamMember.subscriber.subscriber_id}`);
            await this.getTeamMembers();

            this.setState({
                selectedTeamMember: {},
            });
        } 
        catch(e) {
            let msg = 'Oops! We encountered an error while attempting to delete a team member. Please contact customer support.';
        
            if(e && e.response && e.response.data && e.response.data.Message && e.response.data.Message.length) {
                if(e.response.data.Message.match(/'(.*?)'/)) {
                    msg = e.response.data.Message.match(/'(.*?)'/)[1];
                };
            };

            this.setState({
                teamMemberDeleteErrorMsg: msg,
                teamMembersIsLoading: false
            });
        };
    }

    handleSubCat = (name, role, index1, index2, type, bool) => {
        const { subCategories } = this.state;
        let copyArr = _.cloneDeep(subCategories);
        let value = type == 'checkbox' ? !copyArr[role][index1][index2][name] : bool;

        let data = {
            ...copyArr[role][index1][index2],
            [name]: value
        };

        if(name.includes('email') || name.includes('sms')) {
            if(copyArr[role][index1][index2][name] == true) {
                data = {
                    ...data,
                    [`${name}_schedule`]: false
                };
            };
        };

        copyArr[role][index1].splice(index2, 1, data);

        this.setState({
            subCategories: {
                ...copyArr
            }
        }, () => {
            const { teamMemberValidations } = this.state;

            if(teamMemberValidations.subCategories) {
                let validations = this.handleSubCatValidations();

                this.setState({
                    teamMemberValidations: {
                        ...teamMemberValidations,
                        ...validations
                    }
                });
            };

        });
    }

    renderSubCatSwitch = (name, label, role, index1, index2, func) => {
        const { subCategories } = this.state;

        return (
            <div className='switch'>
                <div className='right-margin'>{label}</div>
                <Switch 
                    onColor={"#69c"} 
                    uncheckedIcon={false} 
                    checkedIcon={false}
                    height={25} 
                    width={50}
                    checked={subCategories[role][index1][index2][name] || false}
                    onChange={bool => func(name, role, index1, index2, 'switch', bool)}
                />
            </div>
        );
    }

    handleSubCatAvailability = (e, name, cat, role, index1, index2, type) => {
        const { subCategories } = this.state;
        let copyArr = _.cloneDeep(subCategories);
        let value = type == 'checkbox' ? !copyArr[role][index1][index2][cat][name] : e.target.value;

        copyArr[role][index1][index2][cat][name] = value;

        this.setState({
            subCategories: {
                ...copyArr
            }
        });
    }

    renderWeeklyCheckbox = (x, cat, role, index1, index2) => {
        const { subCategories } = this.state;
        return (
            <div key={x.value} className="filters-wrap">
                <label 
                    className='checkbox-wrap checkbox-input'
                >
                    <input 
                        type="checkbox" 
                        onChange={() =>this.handleSubCatAvailability('', x.value, cat, role, index1, index2, 'checkbox')}
                        checked={subCategories[role][index1][index2][cat][x.value] ? true : false}
                    />
                    <div className="checkbox-label">{x.label}</div>
                </label>
            </div>
        );
    }

    subCatActiveKey = (e, key) => {
        e.preventDefault();
        const { subCatActiveKey } = this.state;
        const newKey = key !== subCatActiveKey ? key : '';

        this.setState({
            subCatActiveKey: newKey
        });
    }

    renderSubCatAvailability = (data, name, role, index1, index2) => {
        const SUB_WEEK = [
            { name: 'week', value: 'monday', label: 'M'},
            { name: 'week', value: 'tuesday', label: 'T'},
            { name: 'week', value: 'wednesday', label: 'W'},
            { name: 'week', value: 'thursday', label: 'T'},
            { name: 'week', value: 'friday', label: 'F'},
            { name: 'week', value: 'saturday', label: 'S'},
            { name: 'week', value: 'sunday', label: 'Su'},
        ];

        let subTime = [];
        Constants.TIME_15.forEach((x, i) => {
            subTime.push(<option key={i} value={x.value}>{x.label}</option>)
        });
        let subTimeZone = [];
        Constants.TIME_ZONE.forEach((x, i) => {
            subTimeZone.push(<option key={i} value={x.value}>{x.label}</option>)
        });

        return (
            <Col md={12} className='sub-cat-avail'>
                <Col md={12} className='small-text no-padding'>
                    Available to Reply to Guest Message: {" "}{infoPopover(this.subCatInfo, null, "top", "icon-question-mark")}
                </Col>
                <Col md={12} className='sub-cat-week no-padding'>
                    {
                        SUB_WEEK.map((x) => {
                            return this.renderWeeklyCheckbox(x, name, role, index1, index2)
                        })
                    }
                    <div className='sub-cat-time'>
                        <InputLabel>
                            Start
                        </InputLabel>
                        <InputField
                            name="start"
                            type="select" 
                            value={data[name].start} 
                            onChange={(e) => this.handleSubCatAvailability(e, 'start', name, role, index1, index2, 'select')}
                        >
                            {subTime}
                        </InputField>
                    </div>
                    <div className='sub-cat-time'>
                        <InputLabel>
                            End
                        </InputLabel>
                        <InputField
                            name="end"
                            type="select" 
                            value={data[name].end} 
                            onChange={(e) => this.handleSubCatAvailability(e, 'end', name, role, index1, index2, 'select')}
                        >
                            {subTime}
                        </InputField>
                    </div>
                    <div className='sub-cat-time tz'>
                        <InputLabel>
                            Time Zone
                        </InputLabel>
                        <InputField
                            name="timeZone"
                            type="select" 
                            value={data[name].timeZone} 
                            onChange={(e) => this.handleSubCatAvailability(e, 'timeZone', name, role, index1, index2, 'select')}
                        >
                            {subTimeZone}
                        </InputField>
                    </div>
                </Col>
            </Col>
        )
    }

    renderSubCat = () => {
        const { 
            subCategories,
            teamMemberData,
            subCatActiveKey
        } = this.state;

        return Object.entries(subCategories).map((category) => {
            if(teamMemberData.role_type == category[0]) {
                return category[1].map((subCat, index1) => {
                    return (
                        <div key={index1} className='sub-cat-container'>
                            {
                                subCat.map((x, index2) => {
                                    const activeKey = x.id;
                                    let label = `${x && x.role_type}: ${x && x.category} ${x && x.subcategory}`;

                                    return (
                                        <Col key={index2} xs={6} className={`sub-cat-tab ${subCatActiveKey == activeKey ? 'open': ''}`}>
                                            <a 
                                                href="#"
                                                className='flex-align-items-center'
                                                onClick={e => this.subCatActiveKey(e, activeKey)}
                                            >
                                                <i className={`icon-gree_check ${(x.sms_host || x.sms_guest || x.email_host || x.email_guest || x.portal_host || x.portal_guest) ? 'checked' : ''}`}></i>
                                                <span className={`capitalize ${subCatActiveKey == activeKey ? 'bold-text' : ''}`}>{label}</span>
                                                <i className={subCatActiveKey == activeKey ? 'glyphicon glyphicon-chevron-down' : 'glyphicon glyphicon-chevron-up'}></i>
                                            </a>
                                        </Col>
                                    )
                                })
                            }
                            {
                                subCat.map((x, index2) => {
                                    const activeKey = x.id;

                                    return (
                                        <Col key={index2} xs={12} className={`no-padding sub-cat-body-container ${subCatActiveKey == activeKey ? 'open' : ''}`}>
                                            {
                                                (x && x.role_type != 'cleaner') ?
                                                <Fragment>
                                                    <Col md={12} className='sub-cat-title'>
                                                        {
                                                            (_.get(x, 'role_type') !== 'host') ?
                                                            <span>
                                                                Receive Guest Replies
                                                            </span>
                                                            :
                                                            <span>
                                                                Receive Message Sent to Guest
                                                            </span>
                                                        }
                                                    </Col>
                                                    <Col md={12} className='sub-cat-check-box-container'>
                                                        <div className='sub-cat-switch'>
                                                            {
                                                                this.renderSubCatSwitch('sms_host', `Receive via Text`, category[0], index1, index2, this.handleSubCat)
                                                            }
                                                        </div>
                                                        {
                                                            (_.get(x, 'role_type') !== 'host') &&
                                                            <div className='sub-cat-switch-schedule'>
                                                                {
                                                                    x.sms_host == true &&
                                                                    this.renderSubCatSwitch('sms_host_schedule', `Schedule`, category[0], index1, index2, this.handleSubCat)
                                                                }
                                                            </div>
                                                        }
                                                    </Col>
                                                        <div className='sub-cat-switch'>
                                                            {
                                                                x.sms_host_schedule == true &&
                                                                this.renderSubCatAvailability(x, 'sms_host_availability', category[0], index1, index2)
                                                            }
                                                        </div>
                                                    <Col md={12} className='sub-cat-check-box-container'>
                                                        <div className='sub-cat-switch'>
                                                            {
                                                                this.renderSubCatSwitch('email_host', `Receive via Email`, category[0], index1, index2, this.handleSubCat)
                                                            }
                                                        </div>
                                                        {
                                                            (_.get(x, 'role_type') !== 'host') &&
                                                            <div className='sub-cat-switch-schedule'>
                                                                {
                                                                    x.email_host == true &&
                                                                    this.renderSubCatSwitch('email_host_schedule', `Schedule`, category[0], index1, index2, this.handleSubCat)
                                                                }
                                                            </div>
                                                        }
                                                    </Col>
                                                        {
                                                            x.email_host_schedule == true &&
                                                            this.renderSubCatAvailability(x, 'email_host_availability', category[0], index1, index2)
                                                        }
                                                    <Col md={12} className='bottom-margin sub-cat-check-box-container'>
                                                        <div className='sub-cat-switch'>
                                                            {
                                                                this.renderSubCatSwitch('portal_host', `Receive Portal Message`, category[0], index1, index2, this.handleSubCat)
                                                            }
                                                        </div>
                                                    </Col>
                                                    {
                                                        (_.get(x, 'subcategory') !== 'inquiry' && _.get(x, 'role_type') !== 'host') &&
                                                        <Fragment>
                                                            <Col md={12} className='sub-cat-title'>
                                                                <span>
                                                                    Receive Message Sent to Guest
                                                                </span>
                                                            </Col>
                                                            <Col md={12} className='sub-cat-check-box-container'>
                                                                <div className='sub-cat-switch-custom'>
                                                                    <div className='switch'>
                                                                        <div className='right-margin'>Text</div>
                                                                        <Switch 
                                                                            onColor={"#69c"} 
                                                                            uncheckedIcon={false} 
                                                                            checkedIcon={false}
                                                                            height={25} 
                                                                            width={50}
                                                                            checked={x.sms_guest || false}
                                                                            onChange={bool => this.handleSubCat('sms_guest', category[0], index1, index2, 'switch', bool)}
                                                                        />
                                                                    </div>
                                                                    <div className='switch'>
                                                                        <div className='right-margin'>Email</div>
                                                                        <Switch 
                                                                            onColor={"#69c"} 
                                                                            uncheckedIcon={false} 
                                                                            checkedIcon={false}
                                                                            height={25} 
                                                                            width={50}
                                                                            checked={x.email_guest || false}
                                                                            onChange={bool => this.handleSubCat('email_guest', category[0], index1, index2, 'switch', bool)}
                                                                        />
                                                                    </div>
                                                                    <div className='switch'>
                                                                        <div className='right-margin'>Portal</div>
                                                                        <Switch 
                                                                            onColor={"#69c"} 
                                                                            uncheckedIcon={false} 
                                                                            checkedIcon={false}
                                                                            height={25} 
                                                                            width={50}
                                                                            checked={x.portal_guest || false}
                                                                            onChange={bool => this.handleSubCat('portal_guest', category[0], index1, index2, 'switch', bool)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Fragment>
                                                    }                                                   
                                                </Fragment>
                                                :
                                                <Fragment>
                                                    <Col md={6}>
                                                        <InputLabel className='capitalize top-margin-10'>
                                                            {`Receive ${x && x.category} ${x && x.subcategory}`}
                                                        </InputLabel>
                                                    </Col>
                                                    <Col md={6} className='sub-cat-check-box-container'>
                                                        <div className='sub-cat-switch-custom'>
                                                            <div className='switch'>
                                                                <div className='right-margin'>Text</div>
                                                                <Switch 
                                                                    onColor={"#69c"} 
                                                                    uncheckedIcon={false} 
                                                                    checkedIcon={false}
                                                                    height={25} 
                                                                    width={50}
                                                                    checked={x.sms_guest || false}
                                                                    onChange={bool => this.handleSubCat('sms_guest', category[0], index1, index2, 'switch', bool)}
                                                                />
                                                            </div>
                                                            <div className='switch'>
                                                                <div className='right-margin'>Email</div>
                                                                <Switch 
                                                                    onColor={"#69c"} 
                                                                    uncheckedIcon={false} 
                                                                    checkedIcon={false}
                                                                    height={25} 
                                                                    width={50}
                                                                    checked={x.email_guest || false}
                                                                    onChange={bool => this.handleSubCat('email_guest', category[0], index1, index2, 'switch', bool)}
                                                                />
                                                            </div>
                                                            <div className='switch'>
                                                                <div className='right-margin'>Portal</div>
                                                                <Switch 
                                                                    onColor={"#69c"} 
                                                                    uncheckedIcon={false} 
                                                                    checkedIcon={false}
                                                                    height={25} 
                                                                    width={50}
                                                                    checked={x.portal_guest || false}
                                                                    onChange={bool => this.handleSubCat('portal_guest', category[0], index1, index2, 'switch', bool)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Fragment>
                                            }
                                        </Col>
                                    )
                                })
                            }
                        </div>
                    )
                });
            }
        })
    }

    validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        
        return emailRegex.test(email);
    }

    handleTeamMemberValidations = () => {
        const { teamMemberData } = this.state;
        let validations = {};

        Object.keys(teamMemberData).forEach((key) => {
            if(key !== 'subscriberId') {
                if(key == 'email' || key == 'phone_number') {
                    if(key == 'email') {
                        if(!teamMemberData.phone_number && !teamMemberData.email) {
                            validations.email = true;
                        }
                        else if(teamMemberData.email.length && !this.validateEmail(teamMemberData.email)) {
                            validations['emailFormat'] = true;
                        }
                        else {
                            validations.email = false;
                            validations['emailFormat'] = false;
                        };
                    }
                    else if(key == 'phone_number') {
                        if(!teamMemberData.phone_number && !teamMemberData.email) {
                            validations.phone_number = true;
                        }
                        else {
                            validations.phone_number = false;
                            validations['emailFormat'] = false;
                        };
                    };
                }
                else {
                    if(!teamMemberData[key].length) {
                        validations[key] = true;
                    }
                    else {
                        validations[key] = false;
                    };
                };
            };
        });

        const subCatValidations = this.handleSubCatValidations()
        return {
            ...validations,
            ...subCatValidations
        };
    }

    handleModal = (e, name) => {
        e && e.preventDefault();

        this.setState({
            modal: name ? name : ''
        }, () => {
            this.setState({
                unassociatedTeamMembersOptionsValue: [],
                teamMemberPropertiesErrorMsg: ''
            });
        });
    }

    handleOnChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        this.setState({
            teamMemberErrorMsg: '',
            teamMemberData: {
                ...this.state.teamMemberData,
                [name]: value
            }
        }, () => {
            const { teamMemberValidations } = this.state;

            if(teamMemberValidations[name] || (name == 'email' && teamMemberValidations.emailFormat)) {
                this.setState({
                    teamMemberValidations: this.handleTeamMemberValidations()
                });
            };
        });
    }

    handleUnassociatedTeamMembers = (value) => {
        this.setState({
            unassociatedTeamMembersOptionsValue: value,
        });
    }

    handlePropertyOptions = (value) => {
        this.setState({
            teamMembersIsLoading: true,
            propertyOptionsValue: value,
        }, () => {
            this.handleModal('', '');
            this.getTeamMembers();
        });
    }

    handleTeamMemberPropertyOptions = (value) => {
        this.setState({
            teamMemberPropertyOptionsValue: value
        });
    }

    optInOnChange = (bool, name) => {
        this.setState({
            teamMemberErrorMsg: '',
            teamMemberData: {
                ...this.state.teamMemberData,
                [name]: bool
            }
        }, () => {
            const { teamMemberValidations } = this.state;

            if(teamMemberValidations.notificationPref || teamMemberValidations.email || teamMemberValidations.phone_number) {
                const teamMemberValidations = this.handleTeamMemberValidations();

                this.setState({
                    teamMemberValidations: {
                        ...notificationValidations,
                        ...teamMemberValidations
                    }
                });
            };
        });
    }

    initTeamMemberData = (e, data) => {
        e.preventDefault();
        const { globalTeamMembers } = this.state;
        let firstName = '';
        let lastName = '';
        let email = '';
        let phoneNumber = '';
        let roleType = '';
        let subscriberId = '';

        function formatTime (time) {
            const duration = moment.duration(time, 'seconds');
            const formattedTime = moment.utc(duration.asMilliseconds()).format('HH:mm:ss[Z]');
            return formattedTime;
        };

        if(globalTeamMembers.length) {
            if(_.get(data, 'subscriber.subscriber_id.length', '')) {
                subscriberId = data.subscriber.subscriber_id;
            };
            
            if(_.get(data, 'subscriber.display_name.length', '')) {
                let nameSplit = data.subscriber.display_name.split(" ");

                firstName = nameSplit[0] ? nameSplit[0] : "";
                lastName = nameSplit[1] ? nameSplit[1] : "";
            };

            if(_.get(data, 'opt_ins.length', '')) {
                for (let i = 0; i < data.opt_ins.length; i++) {
                    if(_.get(data.opt_ins[i], 'role_type', '') === 'host' || _.get(data.opt_ins[i], 'role_type', '') === 'guest') {
                        roleType = 'host';
                        break;
                    }
                    else if(_.get(data.opt_ins[i], 'role_type', '') === 'cleaner') {
                        roleType = 'cleaner';
                        break;
                    };
                };
            };
  
            if(_.get(data, 'subscriber.message_type_data.email.length', '')) {
                email = data.subscriber.message_type_data.email[0].addr;
            };

            if(_.get(data, 'subscriber.message_type_data.sms.length', '')) {
                phoneNumber = data.subscriber.message_type_data.sms[0].phone_number;
            };
        };

        let newSubCat = _.cloneDeep(this.initSubCategories);

        if(_.get(data, 'opt_ins.length', '') && roleType) {

            newSubCat[roleType].forEach((arr, index1) => {
                arr.forEach((sub, index2) => {
                    let optIns = data.opt_ins.filter((opt_in) => opt_in.category_subcategory_id == sub.id);
                    // sms_host, email_host, and portal_host is for "Receive Guest Replies"
                    // sms_guest, email_guest, and portal_guest is for "Receive Message Sent To Guest", "Cleaner", and "Host optins"
                    let sms_host = false;
                    let sms_guest = false;
                    let email_host = false;
                    let email_guest = false;
                    let portal_host = false;
                    let portal_guest = false;
                    let sms_host_schedule = false;
                    let email_host_schedule = false;
                    let sms_host_availability = {
                        monday: true,
                        tuesday: true,
                        wednesday: true,
                        thursday: true,
                        friday: true,
                        saturday: true,
                        sunday: true,
                        start: '09:00:00Z',
                        end: '21:00:00Z',
                        timeZone: 'US/Pacific'
                    };
                    let email_host_availability = {
                        monday: true,
                        tuesday: true,
                        wednesday: true,
                        thursday: true,
                        friday: true,
                        saturday: true,
                        sunday: true,
                        start: '09:00:00Z',
                        end: '21:00:00Z',
                        timeZone: 'US/Pacific'
                    };

                    if(optIns.length) {
                        optIns.forEach((opt_in) => {
                            if(_.get(opt_in, 'message_type_data_selectors.length', '')) {
                                opt_in.message_type_data_selectors.forEach((msg_type_id) => {
                                    if(msg_type_id == _.get(data, 'subscriber.message_type_data.sms[0]._id')) {
                                        if(_.get(opt_in, 'context.participant_context.length', '')) {
                                            if(opt_in.context.participant_context.includes('host')) {
                                                sms_host = true;
                                            };
                                            if(opt_in.context.participant_context.includes('guest') || opt_in.context.participant_context.includes('cleaner')) {
                                                sms_guest = true;
                                            };
                                        };
                                        
                                        if(sms_host && !_.isNil(opt_in.schedule) && !_.isEmpty(opt_in.schedule)) {
                                            sms_host_schedule = true;
                                            sms_host_availability.monday = opt_in.schedule.monday;
                                            sms_host_availability.tuesday = opt_in.schedule.tuesday;
                                            sms_host_availability.wednesday = opt_in.schedule.wednesday;
                                            sms_host_availability.thursday = opt_in.schedule.thursday;
                                            sms_host_availability.friday = opt_in.schedule.friday;
                                            sms_host_availability.saturday = opt_in.schedule.saturday;
                                            sms_host_availability.sunday = opt_in.schedule.sunday;
                                            sms_host_availability.start = formatTime(opt_in.schedule.time_start_secs);
                                            sms_host_availability.end = formatTime(opt_in.schedule.time_end_secs);
                                            sms_host_availability.timeZone = opt_in.schedule.timezone;
                                        };
                                    };

                                    if(msg_type_id == _.get(data, 'subscriber.message_type_data.email[0]._id')) {
                                        if(_.get(opt_in, 'context.participant_context.length', '')) {
                                            if(opt_in.context.participant_context.includes('host')) {
                                                email_host = true;
                                            };
                                            if(opt_in.context.participant_context.includes('guest') || opt_in.context.participant_context.includes('cleaner')) {
                                                email_guest = true;
                                            };
                                        };
                                        if(email_host && !_.isNil(opt_in.schedule) && !_.isEmpty(opt_in.schedule)) {
                                            email_host_schedule = true;
                                            email_host_availability.monday = opt_in.schedule.monday;
                                            email_host_availability.tuesday = opt_in.schedule.tuesday;
                                            email_host_availability.wednesday = opt_in.schedule.wednesday;
                                            email_host_availability.thursday = opt_in.schedule.thursday;
                                            email_host_availability.friday = opt_in.schedule.friday;
                                            email_host_availability.saturday = opt_in.schedule.saturday;
                                            email_host_availability.sunday = opt_in.schedule.sunday;
                                            email_host_availability.start = formatTime(opt_in.schedule.time_start_secs);
                                            email_host_availability.end = formatTime(opt_in.schedule.time_end_secs);
                                            email_host_availability.timeZone = opt_in.schedule.timezone;
                                        };
                                    };
                                    
                                    if(msg_type_id == _.get(data, 'subscriber.message_type_data.portal[0]._id')) {
                                        if(opt_in && _.get(opt_in, 'context.participant_context.length', '')) {
                                            if(opt_in.context.participant_context.includes('host')) {
                                                portal_host = true;
                                            };
                                            if(opt_in.context.participant_context.includes('guest') || opt_in.context.participant_context.includes('cleaner')) {
                                                portal_guest = true;
                                            };
                                        };
                                    };
                                })
                            }
                            
                        })
                    };

                    newSubCat[roleType][index1].splice(index2, 1, {
                        ...newSubCat[roleType][index1][index2],
                        sms_host: sms_host,
                        sms_guest: sms_guest,
                        email_host: email_host,
                        email_guest: email_guest,
                        portal_host: portal_host,
                        portal_guest: portal_guest,
                        sms_host_schedule: sms_host_schedule,
                        email_host_schedule: email_host_schedule,
                        sms_host_availability: sms_host_availability,
                        email_host_availability: email_host_availability
                    })
                })
            });
        }

        let teamMemberData = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            phone_number: phoneNumber,
            role_type: roleType,
            subscriberId: subscriberId
        };

        this.copyTeamMemberData = teamMemberData;
        this.copySubCategories = newSubCat;

        this.setState({
            mode: 'edit',
            subCatActiveKey: '',
            subCategories: newSubCat,
            teamMemberErrorMsg: '',
            teamMemberDeleteErrorMsg: '',
            sourceTeamMemberData: data,
            teamMemberData: teamMemberData,
            teamMemberValidations: {
                subscriberId: false,
                first_name: false,
                last_name: false,
                email: false,
                phone_number: false,
                role_type: false,
                emailFormat: false,
                notificationPref: false,
            },
        }, () => {
            setTimeout(() => {
				document.getElementById('edit-team-member').scrollIntoView({
					block: "center",
					behavior: "smooth"
				});
			}, 500);
        })
    }

    resetTeamMember = () => {
        this.copyTeamMemberData = '';

        this.setState({
            sourceTeamMemberData: {},
            subCatActiveKey: '',
            subCategories: this.initSubCategories,
            teamMemberData: {
                first_name: '',
                last_name: '',
                email: '',
                phone_number: '',
                role_type: '',
            },
            teamMemberValidations: {
                subscriberId: false,
                first_name: false,
                last_name: false,
                email: false,
                phone_number: false,
                role_type: false,
                emailFormat: false,
                notificationPref: false,
            },
            teamMemberErrorMsg: ''
        });
    }
    
    handleSubCatValidations = () => {
        const {
            subCategories,
            teamMemberData
        } = this.state;
        let subCat = [];

        if(teamMemberData.role_type) {
            subCategories[teamMemberData.role_type].forEach((arr) => {
                arr.forEach((sub) => {
                    if(sub.email_host || sub.email_guest || sub.sms_host || sub.sms_guest || sub.portal_host || sub.portal_guest) {
                        subCat.push(arr);
                    };
                });
            });
        }
        

        return {
            subCategories: subCat.length ? false : true
        };
    }

    handleCancelTeamMember = (e) => {
        e && e.preventDefault();

        this.resetTeamMember();

        this.setState({ 
            mode: 'create',
        });
    }

    renderMsg = (msg) => {
        return (
            <Row>
                <Col xs={12} sm={12} className='top-margin'>
                    <div className='msg-container _error'>
                        {msg}
                    </div>
                </Col>
            </Row>
        )
    }

    addUnassociatedTeamMembers = () => {
        this.setState({
            modal: 'add-unassociated-team-member-modal'
        })
    }

    submitUnassociatedTeamMembers = async () => {
        const { 
            unassociatedTeamMembersOptionsValue,
            propertyOptionsValue
        } = this.state;
        const tm = unassociatedTeamMembersOptionsValue;
        let count = 0;

        this.setState({
            unassociatedTeamMembersIsLoading: true
        });

        for(let i = 0; tm.length > i; i++) {
            let data = {...tm[i]};

            delete data.label;
            if(this.propertyCount == (tm[i].property_ids.length + 1)) {
                data.property_ids = [];
            }
            else {
                data.property_ids.push(propertyOptionsValue.property_id);
            };

            try {
                await API.put('ramapi',`/commhub/organizations/${this.props.org}/team-members/${tm[i].subscriber_id}`, { response: true, body: data });
            } 
            catch(e) {
                console.log(e);
            };

            count++;
            await delay(2000);
        };

        if(count == unassociatedTeamMembersOptionsValue.length) {

            await this.getTeamMembers();

            this.setState({
                unassociatedTeamMembersIsLoading: false,
                modal: '',
            });
        };
    }

    removeTeamMemberProperty = async () => {
        const { 
            selectedTeamMember,
            propertyOptionsValue
        } = this.state;
        let data = {
            ...selectedTeamMember,
        };

        const newPropertyIds = selectedTeamMember.property_ids.filter((id) => id != propertyOptionsValue.property_id);

        data.property_ids = newPropertyIds;

        this.setState({
            teamMemberErrorMsg: '',
            teamMemberDeleteErrorMsg: '',
            teamMembersIsLoading: true
        });

        this.handleModal('', '');

        try {
            await API.put('ramapi',`/commhub/organizations/${this.props.org}/team-members/${selectedTeamMember.subscriber_id}`, { body: data });

            await this.getTeamMembers();

            this.resetTeamMember();

            this.setState({
                selectedTeamMember: {},
            });
        } 
        catch(e) {
            let msg = 'Oops! We encountered an error while attempting to remove a team member. Please contact customer support.';
        
            if(e && e.response && e.response.data && e.response.data.Message && e.response.data.Message.length) {
                if(e.response.data.Message.match(/'(.*?)'/)) {
                    msg = e.response.data.Message.match(/'(.*?)'/)[1];
                };
            };

            this.setState({
                teamMemberDeleteErrorMsg: msg,
                teamMembersIsLoading: false
            });
        };
    }

    phoneNumberOnChange = (value) => {
        const { teamMemberData } = this.state;

        this.setState({
            teamMemberData: {
                ...teamMemberData,
                phone_number: value
            }
        }, () => {
            const { teamMemberValidations } = this.state;

            if(teamMemberValidations.phone_number) {
                this.setState({
                    teamMemberValidations: this.handleTeamMemberValidations()
                });
            };
        });
    }

	render() {
        const {
            mode,
            modal,
            globalColumns,
            propertyColumns,
            globalTeamMembers,
            propertyTeamMembers,
            teamMemberData,
            propertiesList,
            isLoading,
            subcategoryError,
            teamMemberPropertiesList,
            teamMembersError,
            teamMembersIsLoading,
            selectedTeamMember,
            teamMemberErrorMsg,
            propertyOptionsValue,
            teamMemberValidations,
            propertiesIsLoading,
            teamMemberDeleteErrorMsg,
            teamMemberPropertiesErrorMsg,
            teamMemberPropertiesIsLoading,
            teamMemberPropertyOptionsValue,
            sourceTeamMemberData,
            subCategories,
            unassociatedTeamMembers,
            unassociatedTeamMembersOptionsValue,
            unassociatedTeamMembersIsLoading,
        } = this.state;

        let primaryRolesOptions = [<option key={'key'} value="" disabled></option>];

        this.initPrimaryRoles.forEach(function (type) {
            primaryRolesOptions.push(<option key={type.key} value={type.value}>{type.key}</option>);
        });

		return (
			<Col md={12} sm={12} className={`comm-management ${this.props.dashboardTabs != 'comm-management' ? 'hide-view' : ''}`}>
                <Col md={12} sm={12} className="custom-container">
                    <Row>
                        <Col md={12} sm={12} className="flex-align-items-center">
                            <div className='right-margin'>
                                {
                                    propertyOptionsValue.property_id == 'global' ?
                                    <div>
                                        <h4>Global Messages & Settings for All Properties</h4>
                                    </div>
                                    :
                                    <div>
                                        <strong>
                                            <h1 className='display-inline'>{propertyOptionsValue.title}{" "}</h1>
                                            <span className='grey-font'>(Property {propertyOptionsValue.property_id})</span>
                                        </strong>
                                    </div>
                                }
                            </div>
                            <div>
                                <PrimaryButton
                                    cssClass="blue-btn "
                                    fullWidth={false} 
                                    type="button"
                                    onClick={e => this.handleModal(e, 'properties-modal')}
                                    disabled={propertiesIsLoading}
                                >
                                    {
                                        propertyOptionsValue.property_id == 'global' ? 
                                        'Customize for Property' :
                                        `Property  ${propertyOptionsValue.property_id}`
                                    } 
                                    <i className="icon icon-selection_tip"></i>
                                </PrimaryButton>
                            </div>
                        </Col>
                    </Row>
                    {
                        this.viewTemplates &&
                        <Row>
                            <EditTemplates
                                org={this.props.org}
                                propertyId={propertyOptionsValue.property_id}
                                propertyTitle={propertyOptionsValue.title}
                            />
                        </Row>
                    }
                    {
                        this.viewSubscriptions &&
                        <Row>
                            <Col md={12} sm={12} className='top-margin'>
                                <h2>Team Members & Responsibilities</h2>
                            </Col>
                            {
                                propertyOptionsValue.property_id !== 'global' &&
                                (unassociatedTeamMembers.length > 0) &&
                                <Col md={12} sm={12} className="bottom-margin">
                                    <span 
                                        className='clickable-text'
                                        onClick={this.addUnassociatedTeamMembers}
                                    >
                                        Add a team member with property responsibilities
                                    </span>
                                </Col>
                            }
                            <Col md={12} sm={12} className="db-team-members">
                                <Col md={12} sm={12} className={`container ${teamMembersError ? 'team-members-error-msg' : ''}`}>

                                    {(teamMembersIsLoading || isLoading) && <Loader/>}
                                    
                                    {
                                        (teamMembersError || subcategoryError) &&
                                        <Col md={12} sm={12} className='text-center'>
                                            <Row>
                                                <Col md={12} sm={12}>
                                                    <div>Oops! We encountered an issue while loading team members. Please reach out to our customer support for assistance.</div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    }
                                    {
                                        (!teamMembersError &&
                                        !subcategoryError) &&
                                        <Fragment>
                                            {
                                                (globalTeamMembers.length > 0) &&
                                                <Col md={12} sm={12}>
                                                    {
                                                        (propertyOptionsValue.property_id !== 'global') &&
                                                        <Fragment>
                                                            <Row>
                                                                <h4>Property Team Members</h4>
                                                            </Row>
                                                            <Row className="bottom-margin">
                                                                <ReactTableComponent
                                                                    manual
                                                                    className='-highlight'
                                                                    noDataText="No Property Members found."
                                                                    minRows={0}
                                                                    data={propertyTeamMembers}
                                                                    resizable={false}
                                                                    activePage={1}
                                                                    columns={propertyColumns}
                                                                    showPagination={true}
                                                                    pageSize={5}
                                                                    totalCount={5}
                                                                />
                                                            </Row>
                                                            {
                                                                (propertyTeamMembers.length == 0) &&
                                                                <Row className="bottom-margin">
                                                                    <span 
                                                                        className='clickable-text'
                                                                        onClick={() => this.handlePropertyOptions({property_id: "global", title: "Global", label: "Global"})}
                                                                    >
                                                                        Add or assign team members in global settings
                                                                    </span>
                                                                </Row>
                                                            }
                                                        </Fragment>
                                                    }
                                                    {
                                                        propertyOptionsValue.property_id == 'global' ?
                                                        <Row>
                                                            <Col md={12} sm={12} className='no-padding'>
                                                                <h4>Current Team Members</h4>
                                                            </Col>
                                                        </Row>
                                                        :
                                                        <Row>
                                                            <h4>Global Team Members</h4>
                                                        </Row>
                                                    }
                                                    {
                                                        (globalTeamMembers.length > 0) &&
                                                        <Row className="bottom-margin">
                                                            <ReactTableComponent
                                                                manual
                                                                className='-highlight'
                                                                noDataText="No Global Members found."
                                                                minRows={0}
                                                                data={globalTeamMembers}
                                                                resizable={false}
                                                                activePage={1}
                                                                columns={globalColumns}
                                                                showPagination={true}
                                                                pageSize={5}
                                                                totalCount={5}
                                                            />
                                                        </Row>
                                                    }
                                                </Col>
                                            }
                                            {
                                                propertyOptionsValue.property_id == 'global' &&
                                                <Col md={12} sm={12} className='no-padding'>
                                                    <Row>
                                                        <Col md={12} sm={12} id='edit-team-member' className='flex-space-between'>
                                                            {
                                                                mode == 'create' ?
                                                                <h4>Add Team Member</h4> :
                                                                <h4>Edit {sourceTeamMemberData && sourceTeamMemberData.subscriber && sourceTeamMemberData.subscriber.display_name ? `  -  ${sourceTeamMemberData.subscriber.display_name}` : ''}</h4>
                                                            }
                                                        </Col>
                                                        <Col md={12} sm={12} className="bottom-margin-10 no-padding">
                                                            <Col md={4} sm={12}>
                                                                <InputLabel
                                                                    className={teamMemberValidations.first_name ? 'form-validation' : ''}
                                                                >
                                                                    First Name
                                                                </InputLabel>
                                                                <InputField 
                                                                    type="text"
                                                                    value={teamMemberData.first_name} 
                                                                    name="first_name"
                                                                    onChange={e => this.handleOnChange(e)}
                                                                    className={teamMemberValidations.first_name ? 'form-validation' : ''}
                                                                >
                                                                </InputField>
                                                                {teamMemberValidations.first_name && <span className="required-error-text">First Name is required</span>}
                                                            </Col>
                                                            <Col md={4} sm={12}>
                                                                <InputLabel
                                                                    className={teamMemberValidations.last_name ? 'form-validation' : ''}
                                                                >
                                                                    Last Name
                                                                </InputLabel>
                                                                <InputField 
                                                                    type="text" 
                                                                    value={teamMemberData.last_name} 
                                                                    name="last_name"
                                                                    onChange={e => this.handleOnChange(e)}
                                                                    className={teamMemberValidations.last_name ? 'form-validation' : ''}
                                                                >
                                                                </InputField>
                                                                {teamMemberValidations.last_name && <span className="required-error-text">Last Name is required</span>}
                                                            </Col>
                                                            <Col md={4} sm={12}>
                                                                <InputLabel
                                                                    className={teamMemberValidations.role_type ? 'form-validation' : ''}
                                                                >
                                                                    Primary Role
                                                                </InputLabel>
                                                                <InputField 
                                                                    type="select" 
                                                                    value={teamMemberData.role_type} 
                                                                    name="role_type"
                                                                    onChange={e => this.handleOnChange(e)}
                                                                    className={teamMemberValidations.role_type ? 'form-validation' : ''}
                                                                >
                                                                    {primaryRolesOptions}
                                                                </InputField>
                                                                {teamMemberValidations.role_type && <span className="required-error-text">Primary Role is required</span>}
                                                            </Col>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12} sm={12} className="bottom-margin-10 no-padding">
                                                            <Col md={4} sm={12}>
                                                                <InputLabel
                                                                    className={(teamMemberValidations.email || teamMemberValidations.emailFormat) ? 'form-validation' : ''}
                                                                >
                                                                    Email Address
                                                                </InputLabel>
                                                                <InputField 
                                                                    type="text" 
                                                                    value={teamMemberData.email} 
                                                                    name="email"
                                                                    onChange={e => this.handleOnChange(e)}
                                                                    className={(teamMemberValidations.email || teamMemberValidations.emailFormat) ? 'form-validation' : ''}
                                                                >
                                                                </InputField>
                                                                {(teamMemberValidations.email || teamMemberValidations.emailFormat) && <span className="required-error-text">{teamMemberValidations.email ? 'Email is required' : "Please enter a valid Email"}</span>}
                                                            </Col>
                                                            <Col md={4} sm={12}>
                                                                <InputLabel
                                                                    className={teamMemberValidations.phone_number ? 'form-validation' : ''}
                                                                >
                                                                    Mobile Phone Number
                                                                </InputLabel>
                                                                <PhoneInput
                                                                    country="us"
                                                                    autoFormat={true}
                                                                    enableSearch={true}
                                                                    disableSearchIcon={true}
                                                                    value={teamMemberData.phone_number}
                                                                    name="phone_number"
                                                                    onChange={this.phoneNumberOnChange}
                                                                    className={teamMemberValidations.phone_number ? 'form-validation' : ''}
                                                                />
                                                                {teamMemberValidations.phone_number && <span className="required-error-text">Phone Number is required</span>}
                                                            </Col>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={8} sm={12} xs={12}>
                                                            {
                                                                (teamMemberData && teamMemberData.role_type && teamMemberData.role_type.length > 0) &&
                                                                <Fragment>
                                                                    <div className={`sub-cat ${teamMemberValidations.subCategories ? 'form-validation' : ''}`}>
                                                                        {this.renderSubCat()}

                                                                    </div>
                                                                    {
                                                                        teamMemberValidations.subCategories && 
                                                                        <div className="required-error-text">
                                                                            Please select at least 1 message opt-in.
                                                                        </div>
                                                                    }
                                                                </Fragment>
                                                            }
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12} sm={12} xs={12} className="flex-align-right">
                                                            <PrimaryButton
                                                                cssClass="white-btn "
                                                                fullWidth={false} 
                                                                type="button"
                                                                onClick={(e) => this.handleCancelTeamMember(e)}
                                                            >
                                                                Cancel
                                                            </PrimaryButton>
                                                            <PrimaryButton
                                                                cssClass="left-margin"
                                                                fullWidth={false} 
                                                                type="button"
                                                                onClick={this.submitTeamMember}
                                                                disabled={
                                                                    mode == 'edit' && 
                                                                    (_.isEqual(teamMemberData, this.copyTeamMemberData)) &&
                                                                    (_.isEqual(subCategories, this.copySubCategories))
                                                                }
                                                            >
                                                                {mode == 'edit' ? 'Update' : 'Add'}
                                                            </PrimaryButton>
                                                        </Col>
                                                    </Row>
                                                    {
                                                        (teamMemberErrorMsg.length > 0 || (teamMemberDeleteErrorMsg.length > 0)) && 
                                                        this.renderMsg(teamMemberErrorMsg  || teamMemberDeleteErrorMsg)
                                                    }
                                                </Col>
                                            }  
                                        </Fragment>
                                    }
                                </Col>
                            </Col>
                        </Row>
                    }
                    {
                        (this.viewDevices &&
                        !propertiesIsLoading) &&
                        <Row>
                            <EditDevices
                                org={this.props.org}
                                propertyId={propertyOptionsValue.property_id}
                                propertyTitle={propertyOptionsValue.title}
                                teamMemberPropertiesList={teamMemberPropertiesList}
                                globalTeamMembers={globalTeamMembers}
                                handlePropertyOptions={this.handlePropertyOptions}
                            />
                        </Row>
                    }
                </Col>
                <Modal 
                    show={modal == 'properties-modal'} 
                    className='comm-properties-modal' 
                    onHide={this.handleModal}
                >
                    <Modal.Header closeButton>
                        <h1>Select Property</h1>
                    </Modal.Header>
                    <Modal.Body>
                        <Picky
                            placeholder={"Property Search"}
                            labelKey="label"
                            valueKey="property_id"
                            options={propertiesList}
                            value={propertyOptionsValue}
                            multiple={false}
                            includeSelectAll={false}
                            includeFilter={true}
                            onChange={prop => this.handlePropertyOptions(prop)}
                            dropdownHeight={600} 
                            filterDebounce={100}   
                            keepOpen={true}
                            render={({
                                style,
                                item,
                                isSelected,
                                selectValue,
                                labelKey,
                                valueKey,
                                }) => {
                                    return (
                                        <li
                                            style={{ ...style }} 
                                            className={isSelected ? "selected" : ""} 
                                            key={item.property_id}
                                            onClick={() => selectValue(item)}
                                        >
                                            <span
                                                style={{fontWeight: isSelected ? "bold" : "normal"}}
                                            >
                                                {
                                                    <span>{item[labelKey]}</span>                                                    
                                                }
                                            </span>
                                        </li>
                                    );
                                }}
                        />
                    </Modal.Body>
                </Modal>
                <Modal 
                    show={modal == 'add-unassociated-team-member-modal'} 
                    className='comm-properties-modal' 
                    onHide={this.handleModal}
                    backdrop='static'
                >

                    {unassociatedTeamMembersIsLoading && <Loader/>}

                    <Modal.Header>
                        <h1>Select Team Member</h1>
                    </Modal.Header>
                    <Modal.Body>
                        <Picky
                            placeholder={"Select Team Member"}
                            labelKey="label"
                            valueKey="subscriber_id"
                            options={unassociatedTeamMembers}
                            value={unassociatedTeamMembersOptionsValue}
                            multiple={true}
                            includeSelectAll={true}
                            includeFilter={true}
                            onChange={prop => this.handleUnassociatedTeamMembers(prop)}
                            dropdownHeight={600} 
                            filterDebounce={100}   
                            keepOpen={true}
                            selectAllText='Select All'
                            numberDisplayed={1}
                            clearFilterOnClose={true}
                            renderSelectAll={({
                                filtered,
                                tabIndex,
                                selected,
                                allSelected,
                                toggleSelectAll,
                                multiple,
                              }) => {
                                if (multiple && !filtered) {
                                    return (
                                        <div
                                            key={tabIndex}
                                            tabIndex={tabIndex}
                                            role="option"
                                            className={allSelected ? 'option selected' : 'option'}
                                            onClick={toggleSelectAll}
                                        >
                                            <input
                                                type="checkbox"
                                                checked={allSelected}
                                                readOnly
                                            />
                                            Select All
                                        </div>
                                    );
                                }
                              }}
                        />
                        <Row>
                            <Col xs={12} className='top-margin'>
                                <PrimaryButton
                                    cssClass="pull-right"
                                    fullWidth={false} 
                                    type="button"
                                    onClick={this.submitUnassociatedTeamMembers}
                                    disabled={!unassociatedTeamMembersOptionsValue.length}
                                >
                                    Add
                                </PrimaryButton>
                                <PrimaryButton
                                    cssClass='white-btn pull-right right-margin'
                                    fullWidth={false} 
                                    type="button"
                                    onClick={(e) => this.handleModal(e, '')}
                                >
                                    Cancel
                                </PrimaryButton>
                            </Col>
                        </Row>
                        {
                            (teamMemberPropertiesErrorMsg.length > 0) &&
                            this.renderMsg(teamMemberPropertiesErrorMsg)
                        }
                    </Modal.Body>
                </Modal>
                <Modal 
                    show={modal == 'add-tm-property'} 
                    className='comm-properties-modal' 
                    onHide={this.handleModal}
                    backdrop='static'
                >

                    {teamMemberPropertiesIsLoading && <Loader/>}

                    <Modal.Header>
                        {
                            <h1>{selectedTeamMember && selectedTeamMember.display_name}</h1>
                        }
                    </Modal.Header>
                    <Modal.Body>
                        <Picky
                            placeholder={"Property Search"}
                            labelKey="label"
                            valueKey="property_id"
                            options={teamMemberPropertiesList}
                            value={teamMemberPropertyOptionsValue}
                            multiple={true}
                            includeSelectAll={true}
                            includeFilter={true}
                            onChange={prop => this.handleTeamMemberPropertyOptions(prop)}
                            dropdownHeight={600} 
                            filterDebounce={100}   
                            keepOpen={true}
                            selectAllText='All Properties'
                            numberDisplayed={1}
                            clearFilterOnClose={true}
                            renderSelectAll={({
                                filtered,
                                tabIndex,
                                selected,
                                allSelected,
                                toggleSelectAll,
                                multiple,
                              }) => {
                                if (multiple && !filtered) {
                                    return (
                                        <div
                                            key={tabIndex}
                                            tabIndex={tabIndex}
                                            role="option"
                                            className={allSelected ? 'option selected' : 'option'}
                                            onClick={toggleSelectAll}
                                        >
                                            <input
                                                type="checkbox"
                                                checked={allSelected}
                                                readOnly
                                            />
                                            All Properties
                                        </div>
                                    );
                                }
                              }}
                        />
                        <Row>
                            <Col xs={12} className='top-margin'>
                                <PrimaryButton
                                    cssClass="pull-right"
                                    fullWidth={false} 
                                    type="button"
                                    onClick={this.submitTeamMemberProperties}
                                    disabled={!teamMemberPropertyOptionsValue.length || _.isEqual(teamMemberPropertyOptionsValue, this.copyTeamMemberPropertyOptionsValue)}
                                >
                                    Update
                                </PrimaryButton>
                                <PrimaryButton
                                    cssClass='white-btn pull-right right-margin'
                                    fullWidth={false} 
                                    type="button"
                                    onClick={(e) => this.handleModal(e, '')}
                                >
                                    Cancel
                                </PrimaryButton>
                            </Col>
                        </Row>
                        {
                            (teamMemberPropertiesErrorMsg.length > 0) &&
                            this.renderMsg(teamMemberPropertiesErrorMsg)
                        }
                    </Modal.Body>
                </Modal>
                <Modal 
                    show={modal == 'remove-tm'} 
                    onHide={(e) => this.handleModal(e, '')}
                    backdrop='static'
                >
                    <Modal.Header>
                    </Modal.Header>
                    <Modal.Body>
                    <Row className="text-center bottom-margin">
                            <Col xs={12}>
                                <h2>Removing{" "} 
                                    <strong>{`"${selectedTeamMember && selectedTeamMember.subscriber && selectedTeamMember.subscriber.display_name}"`}</strong>{" "}
                                    will cancel all future messages and notifications for this user.
                                </h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <PrimaryButton
                                    cssClass="pull-right"
                                    fullWidth={false} 
                                    type="button"
                                    onClick={this.removeTeamMember}
                                >
                                    Remove
                                </PrimaryButton>
                                <PrimaryButton
                                    cssClass='white-btn pull-right right-margin'
                                    fullWidth={false} 
                                    type="button"
                                    onClick={(e) => this.handleModal(e, '')}
                                >
                                    Cancel
                                </PrimaryButton>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
                <Modal 
                    show={modal == 'remove-tm-property'} 
                    onHide={(e) => this.handleModal(e, '')}
                    backdrop='static'
                >
                    <Modal.Header>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="text-center bottom-margin">
                            <Col xs={12}>
                                <h2>Removing{" "} 
                                    <strong>{`"${selectedTeamMember && selectedTeamMember.display_name}"`}</strong>{" "}
                                    from {" "}{propertyOptionsValue.title}.
                                </h2>
                                <h2>Do you want to continue?</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <PrimaryButton
                                    cssClass="pull-right"
                                    fullWidth={false} 
                                    type="button"
                                    onClick={this.removeTeamMemberProperty}
                                >
                                    Remove
                                </PrimaryButton>
                                <PrimaryButton
                                    cssClass='white-btn pull-right right-margin'
                                    fullWidth={false} 
                                    type="button"
                                    onClick={(e) => this.handleModal(e, '')}
                                >
                                    Cancel
                                </PrimaryButton>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
			</Col>
		)
	}
}

const mapStateToProps = state => {
    return {
        permissions: state.authState.permissions,
        org: state.roleManager.org,
    }
}

export default withRouter(connect(
    mapStateToProps,
    null
)(CommunicationManagement));