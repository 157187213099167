import React from 'react';
// import { Grid, Row, Col, Glyphicon, Modal } from 'react-bootstrap';
import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import Modal from 'react-bootstrap/lib/Modal';
import { connect } from 'react-redux';
import styles from '../../styles/title.less';
import PageLayout from './PageLayout';
import Link from 'react-router-dom/Link';
import { withRouter } from 'react-router';
import OrganizationDropdown from './OrganizationDropdown';
import {openOrganizationModal,closeOrganizationModal} from '../../js/actions';
import OrganizationsTree from '../views/OrganizationTree';

/**
 * Show title on top of page contain breadcrumbs and
 */
class TitleComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSticky: false
        }
        this.changeOrg = this.changeOrg.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }
    /** For handle scroll when fixed title bar.*/
    handleScroll(event) {
        let _this = this;
        if(window.scrollY > 5) {
            _this.setState({
                isSticky: true
            });
        } else {
            _this.setState({
                isSticky: false
            });
        }
    }
    /** Change organization open an organization modal.*/
    changeOrg() {
        this.props.openOrganizationModal('org-tree-modal');
    }
    /**Hide Modal. */
    hideModal() {
        this.props.closeOrganizationModal('')
    }

    componentDidMount() {
        let title = document.querySelector('.title-container-wrap'),
            wrapper = document.querySelector('.page-container');
        window.addEventListener('scroll', this.handleScroll);
        if(window.innerWidth<1024) {
            let height = this.props.mobileMode ? 
            50 : 
            title.clientHeight;
            wrapper.style.paddingTop = height + 'px';
        } else {
            wrapper.style.paddingTop = 0;
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    render() {
        let props = this.props;
        let topItem, backBtn,
        removeOrgBreadCrumbItem = false;

        if (props.mobileMode) {
            if (props.match.params.reservation_id || props.match.params.property_id || props.match.params.roomId || props.match.path === "/properties/create") {
                removeOrgBreadCrumbItem = true
            }
        }
        let breadcrumbList =
            !this.props.removeOrganizationDropdown && !removeOrgBreadCrumbItem ?
                [<li key={0}>
                    <a 
                        href="#!"
                        onClick={this.changeOrg}
                    >
                        {props.orgTitle ? props.orgTitle: props.orgName}
                    </a>
                </li>] :
                (this.props.addDefaultTitle ? 
                    [<li key={0}>
                        {this.props.addDefaultTitle}
                    </li>] : 
                    []);
        let handleBack = props.handleBack;
        let hasBackLink = !!handleBack;
        if(props.breadcrumbItems && props.breadcrumbItems.length ) {
            breadcrumbList = breadcrumbList.concat(props.breadcrumbItems.map((item, index) => {
                return (
                    <li 
                        key={index + 1}
                        className={(index+1) === props.breadcrumbItems.length ? 'last-item' : `item-${index+1}`}
                    >
                        {
                            item.link ? 
                                (<Link to={item.link}>{(index+1) === props.breadcrumbItems.length ? (item.title.length > 24 ? item.title.substring(0, 1) + "..." : item.title) : item.title}</Link>) : 
                                (index+1) === props.breadcrumbItems.length ? (item.title.length > 30 ? item.title.substring(0, 30) + "..." : item.title) : item.title
                        }
                    </li>
                );
            }));
        }

        if(props.children) {
            topItem = (
                <Row>
                    <Col 
                        xs={12} 
                        sm={12} 
                        className={`${props.propClassName ? `${props.propClassName}-` : ''}title-column`}
                    >
                        <div className="top-items"
                            style={props.style ? props.style : {}}
                        >
                            {props.children}
                        </div>
                    </Col>
                </Row>
            );
        }
        if(!props.mobileMode) {
            if(hasBackLink) {
                backBtn = (
                    <div className='btn-back'>
                        <a href="#!" onClick={props.handleBack}><Glyphicon glyph='arrow-left'/></a>
                    </div>
                )
            }
        }
        return (
            <Grid fluid={true} className={'title-container-wrap'+(this.state.isSticky ? ' stuck' : '')}>
                <PageLayout plClass="title-layout" isCentered={props.isCentered}>
                    <div className="title-bar">
                        <div className="title-left-content">
                            <div className="title-container">
                                <div className={"title-inner"+( hasBackLink && !props.mobileMode ? ' has-back-btn' : '')}>
                                    { backBtn }
                                    <ul className="breadcrumb">
                                        {breadcrumbList}
                                    </ul>
                                    <div className={`page-title${props.mobileMode ? '-mobile' : ''}`}>
                                        <h1 className='pull-left'>{props.title}</h1>
                                        {props.level === 'rep' && !props.mobileMode && <i className="icon-Properties_icon" />}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="title-right-content">
                            { topItem }
                        </div>
                        <div className="title-shadow"></div>
                    </div>
                </PageLayout>
                <Modal className="org-modal" show={this.props.orgModal === 'change-org-modal'} onHide={this.hideModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Change Organization
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="org-wrap">
                            <OrganizationDropdown closeModal={this.hideModal}/>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal className="org-tree-modal" show={this.props.orgModal === 'org-tree-modal'} onHide={this.hideModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Organizations
                            <hr/>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <OrganizationsTree />
                    </Modal.Body>
                </Modal>
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    return {
        orgTitle: state.roleManager.orgTitle,
        orgId: state.roleManager.orgId,
        orgName: state.roleManager.org,
        orgModal: state.orgModal.show,
        mobileMode: state.updateMobileState.mobileMode,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        openOrganizationModal: (name) => {
            dispatch(openOrganizationModal(name))
        },
        closeOrganizationModal: (name) => {
            dispatch(closeOrganizationModal(name))
        }
    }
}

export const Title =  withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(TitleComponent));
