/** Handle API calls across the application **/
import Amplify, { API, Auth } from 'aws-amplify';
import Constants from './constants';

const CGT = Constants.COGNITO;
const RAPAPI = Constants.RAPAPI;

// Session Id
const maximum = 99999
const minimum = 10000
const sessionId = Math.floor(Math.random() * ((maximum - minimum) + 1)) + minimum;

Amplify.configure({
    Auth: {
        identityPoolId: CGT.IdentityPoolId, //REQUIRED - Amazon Cognito Identity Pool ID
        region: CGT.Region, // REQUIRED - Amazon Cognito Region
        userPoolId: CGT.UserPoolId, //OPTIONAL - Amazon Cognito User Pool ID
        userPoolWebClientId: CGT.ClientId, //OPTIONAL - Amazon Cognito Web Client ID
    },
    API: {
        endpoints: [
            {
                name: CGT.EndPoint.Name,
                endpoint: CGT.EndPoint.Url,
                region: CGT.Region
            },
            {
                name: CGT.Website.Name,
                endpoint: CGT.Website.Url,
                region: CGT.Region
            },
            {
                name: CGT.Account.Name,
                endpoint: CGT.Account.Url,
                region: CGT.Region
            },
            {
                name: CGT.Concierge.Name,
                endpoint: CGT.Concierge.Url,
                region: CGT.Region,
            },
            {
                name: CGT.Reports.Name,
                endpoint: CGT.Reports.Url,
                region: CGT.Region,
            },
            {
                name: CGT.Reports.Name,
                endpoint: CGT.Reports.Url,
                region: CGT.Region,
            },
            {
                name: CGT.SmartPricing.Name,
                endpoint: CGT.SmartPricing.Url,
                region: CGT.Region,
            },
            {
                name: CGT.BookingPal.Name,
                endpoint: CGT.BookingPal.Url,
                region: CGT.Region,
            },
            {
                name: CGT.CommHub.Name,
                endpoint: CGT.CommHub.Url,
                region: CGT.Region,
            },
            {
                name: CGT.GuestBenefits.Name,
                endpoint: CGT.GuestBenefits.Url,
                region: CGT.Region,
            },
            {
                name: CGT.KnowledgeBot.Name,
                endpoint: CGT.KnowledgeBot.Url,
                region: CGT.Region,
            },
            {
                name: CGT.PropertyOperationManagement.Name,
                endpoint: CGT.PropertyOperationManagement.Url,
                region: CGT.Region,
            },
        ]
    }
});

let ApiHandler = () => {

    /**
     * For get current authenticated user
     * @return {user} Will return current user object
     */
    let getAuthenticatedUser = async() => {
        let user = await Auth.currentAuthenticatedUser();
        return user;
    }
    /**
     * For get current user info
     * @return {userInfo} Will return user info object
     */
    let getUserInfo = async() => {
        let user = await Auth.currentUserInfo();
        return user;
    }

    /**
     * A one generic function for get API call.
     * @param {string} path relative path of API.
     * @param {object} options object key apiName to set different API URL. 
     * @return A promise that resolves to an object with JSON data, if successful.
     */
    let get = async(path, options) => {
        if(!path) {
            return;
        }

        options = options || {};
        let response;
        try {
            let apiName = (options.apiName != null) ? options.apiName : RAPAPI.NAME;
                response = await API.get(apiName, path, {
                    response: true
                });
        } catch(err) {
            console.log('Error while GET call: ', err);
            response = err;
        }
        return response;
    }

    /**
     * For get logged in user’s permissions.
     * @return {userInfo} Returns the logged in user’s permissions.
     */
    let getPermissions = async() => {
        let data = await get(RAPAPI.PERMISSIONS, { isCognito: true });
        return data;
    }

    /**
     * For fetch a properties.
     * @param {object} opts keys page, limit, tid, sortField, sortOrder, keyword, org.
     * @return {total, data} total will content total number of properties and data will contain current fetched properties.
     */
    let getProperties = async(opts) => {
        let { page, limit, tid, sortField, sortOrder, keyword, org, allOrganizations, showAllOrg, fl } = opts;
        let offset = page;
        let session = tid || sessionId;
        sortField = sortField || 'property_id';
        sortOrder = sortOrder || '+';
        keyword = keyword? `${encodeURIComponent(keyword)}`: '';
        org = org? `organization${showAllOrg ? 's' : ''}=${showAllOrg ? allOrganizations.join() : org}&`:'';
        let newPage;
        if (window.location.href.split('?page=')[1]) {
            newPage = window.location.href.split('?page=')[1]
        }
        else {
            newPage = 0
        };
        let filter;
        if(fl && fl.length) {
            filter = fl.join(',');
        };
        let newOffset = Number.isNaN((limit * offset)) ? ((newPage - 1 > 0 ? newPage - 1 : 0) * limit) : (limit * offset)
        let params = `?${org}limit=${limit}&tid=${session}&offset=${newOffset}&sort=${sortField}${encodeURIComponent(sortOrder)}&keyword=${keyword}${filter ? `&fl=${filter}` : ''}`
        try {
            let data = await get(RAPAPI.PROPERTIES + params, { isCognito: true });
            return {
                total: data.headers['x-total-count'],
                data: data.data
            };
        } catch(e) {
            console.log(e, 'error getProperties')
            return {
                total: 0,
                data: []
            }
        }
    }
    /**
     * For fetch a properties.
     * @return {total, data} total will content total number of properties and data will contain current fetched properties.
     */
    let getListings = async() => {
        let data = await get(RAPAPI.LISTINGS);
        return data;
    }

    /**
     * For make custom API call.
     * @param {string} type Enum post, put, patch, delete.
     * @param {string} path relative path for URL.
     * @param {object} pObj object for add body part.
     * @return {object} return response from API.
     */
    let update = async(type, path, pObj) => {
        if(!path) {
            return;
        }
        let response;
        type = type || 'post';
        try {
            switch(type) {
                case 'post':
                    response = await API.post(RAPAPI.NAME, path, pObj);
                    break;
                case 'put':
                    response = await API.put(RAPAPI.NAME, path, pObj);
                    break;
                case 'patch':
                    response = await API.patch(RAPAPI.NAME, path, pObj);
                    break;
                case 'delete':
                    response = await API.del(RAPAPI.NAME, path, pObj);
                    break;
            }

        } catch(err) {
            console.log('Error while '+type+' call: ',err);
            return {error: true, response: err.response}
        }
        return response;
    }

    /**
     * For make POST API call.
     * @param {string} path relative path for URL.
     * @param {object} pObj object for add body part.
     * @return {object} return response from API.
     */
    let post = async(path, pObj) => {
        return await update('post', path, pObj);
    }
    /**
     * For make PUT API call.
     * @param {string} path relative path for URL.
     * @param {object} pObj object for add body part.
     * @return {object} return response from API.
     */
    let put = async(path, pObj) => {
        return await update('put', path, pObj);
    }
    /**
     * For make patch API call.
     * @param {string} path relative path for URL.
     * @param {object} pObj object for add body part.
     * @return {object} return response from API.
     */
    let patch = async(path, pObj) => {
        return await update('patch', path, pObj);
    }

    return {
        getAuthenticatedUser,
        getPermissions,
        getProperties,
        getListings,
        get,
        post,
        put,
        patch,
        update,
        getUserInfo
    }
}

export default ApiHandler();