import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import { updateOrganization, updateOrganizationTitle, updateOrganizationId, setRole } from '../../js/actions/index';
import { InputLabel, InputField, PrimaryButton } from '../global/forms/FormElements';
import Link from 'react-router-dom/Link';
import { API } from 'aws-amplify';
import Modal from 'react-bootstrap/lib/Modal';
import Pagination from 'react-js-pagination';
import Picky from 'react-picky';
import Loader from '../global/Loader';
import ReactTableComponent from '../global/ReactTableComponent';
import _ from "lodash";
import Constants from '../../js/constants';
import moment from 'moment';
import PanelGroup from 'react-bootstrap/lib/PanelGroup';
import Panel from 'react-bootstrap/lib/Panel';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import DateRangePicker from 'react-dates/lib/components/DateRangePicker';
import { 
    tableSort
} from '../../helpers/helpers';
import { CSVLink } from "react-csv";
import Popover from 'react-bootstrap/lib/Popover';

class AdjustmentsPayouts extends Component {
    approvePayments = false;
    CSVHeaders = [
        { label: 'Date', key: 'created'},
        { label: 'Category', key: 'category'},
        { label: 'Description', key: 'description'},
        { label: 'Organization', key: 'organization_name'},
        { label: 'Property', key: 'property_id'},
        { label: 'Reservation', key: 'reservation_id'},
        { label: 'Status', key: 'status'},
        { label: 'Payment Statements ID', key: 'payment_statements_id'},
        { label: 'Amount', key: 'amount'},
        { label: 'Updated Date', key: 'updated'},
    ];
    CURRENCY = [
        {value: "USD"},
        {value: "CAD"},
        {value: "MXN"},
        {value: "EUR"},
        {value: "GBP"},
    ];
    ENTRIES_OPTIONS = [
        {value: 'payment', custom_value: 'Debit', label: 'Host Pays (Debit Account) [-]'},
        {value: 'charge', custom_value: 'Credit', label: 'Host Receives (Credit Account) [+]'},
    ];
    CATEGORIES_HOST_RECEIVES = [
        {value: 'Additional Guest Fee', label: 'Additional Guest Fee'},
        {value: 'Early Check In Fee', label: 'Early Check In Fee'},
        {value: 'Late Check Out Fee', label: 'Late Check Out Fee'},
        {value: 'Pet Fee', label: 'Pet Fee'},
        {value: 'Pool Heat Fee', label: 'Pool Heat Fee'},
        {value: 'Claim Payout', label: 'Claim Payout'},
        {value: 'Miscellaneous Payment', label: 'Miscellaneous Payment'},
        {value: 'Reservation Payout', label: 'Reservation Payout'},
        {value: 'Pricing Issue', label: 'Pricing Issue'},
        {value: 'Host Reimbursement', label: 'Host Reimbursement'},
        {value: 'Transient Occupancy Taxes', label: 'Transient Occupancy Taxes'}
    ];
    CATEGORIES_HOST_PAYS = [
        {value: 'Canceled By PM/PO', label: 'Canceled By PM/PO'},
        {value: 'Cancellation (Channel Penalty)', label: 'Cancellation (Channel Penalty)'},
        {value: 'Cancellation (Check-In Issue)', label: 'Cancellation (Check-In Issue)'},
        {value: 'Cancellation (Property Re-Rented)', label: 'Cancellation (Property Re-Rented)'},
        {value: 'Chargeback', label: 'Chargeback'},
        {value: 'Channel Host Cancellation Fee', label: 'Channel Host Cancellation Fee'},
        {value: 'Cleaner Errand Fee', label: 'Cleaner Errand Fee'},
        {value: 'Cleaning - Deep Cleaning', label: 'Cleaning - Deep Cleaning'},
        {value: 'Cleaning - Turnover Cleaning', label: 'Cleaning - Turnover Cleaning'},
        {value: 'Early Termination Fee', label: 'Early Termination Fee'},
        {value: 'Force Majeure', label: 'Force Majeure'},
        {value: 'FSC', label: 'FSC'},
        {value: 'FSC Wrongfully Paid', label: 'FSC Wrongfully Paid'},
        {value: 'Free Cancellation', label: 'Free Cancellation'},
        {value: 'Guest Paid PM/PO directly', label: 'Guest Paid PM/PO directly'},
        {value: 'Incorrect Payout', label: 'Incorrect Payout'},
        {value: 'Inspection', label: 'Inspection'},
        {value: 'Maintenance (Cadenced)', label: 'Maintenance (Cadenced)'},
        {value: 'Maintenance (One-Off)', label: 'Maintenance (One-Off)'},
        {value: 'Miscellaneous Fee', label: 'Miscellaneous Fee'},
        {value: 'Modified Dates Wrongfully Paid', label: 'Modified Dates Wrongfully Paid'},
        {value: 'Overpaid Host', label: 'Overpaid Host'},
        {value: 'Photography', label: 'Photography'},
        {value: 'Pricing Issue', label: 'Pricing Issue'},
        {value: 'Promotion', label: 'Promotion'},
        {value: 'RedAwning Host Booking Cancellation Fee', label: 'RedAwning Host Booking Cancellation Fee'},
        {value: 'Relocation Cost', label: 'Relocation Cost'},
        {value: 'Rejected Booking (Content)', label: 'Rejected Booking (Content)'},
        {value: 'Rejected Booking (Guest Age)', label: 'Rejected Booking (Guest Age)'},
        {value: 'Rejected Booking (Pricing)', label: 'Rejected Booking (Pricing)'},
        {value: 'Restocking', label: 'Restocking'},
        {value: 'Shorten Stay', label: 'Shorten Stay'},
        {value: 'Stay Issue', label: 'Stay Issue'},
        {value: 'Termination Fee', label: 'Termination Fee'}
    ];
    account_manager_admin = '';
    guest_services = '';
    initValidations = {};
    adjustmentsTid = '';
    upcomingTid = '';
    completedTid = '';
    initState = {
        adjustmentsIsLoading: false,
        getPropertiesIsLoading: false,
        initPayoutsIsLoading: true,
        orgList: [],
        orgValue: '',
        propertyList: [],
        adjustments: [],
        adjustmentsActivePage: 0,
        adjustmentsPageSize: 10,
        adjustmentsCount: '',
        adjustmentsData: {
            currency: 'USD',
            entries: '',
            organization: '',
            amount: '',
            description: '',
            category: '',
            property_id: '',
            reservation_id: '',
        },
        adjustmentsSorted: {
            id: "created",
            desc: true
        },
        adjustmentsFilters: {
            needs_approval: true,
            pending: true,
            cancelled: false,
            invoiced: false,
            paid: false,
        },
        completedPayoutsIsLoading: false,
        completedPayoutsActivePage: 1,
        completedPayoutsPageSize: 10,
        completedPayoutsStatements: [],
        completedPayoutsActiveKey: '',
        completedPayoutsCount: 0,
        completedPayoutsSort: {
            id: "posted",
            desc: true
        },
        completedPayoutsDates: {
            startDate: null,
            endDate: null,
        },
        upcomingPayoutsIsLoading: false,
        upcomingPayoutsActivePage: 1,
        upcomingPayoutsPageSize: 10,
        upcomingPayoutsStatements: [],
        upcomingPayoutsActiveKey: '',
        upcomingPayoutsCount: 0,
        upcomingPayoutsSort: {
            id: "posted",
            desc: true
        },
        upcomingPayoutsDates: {
            startDate: null,
            endDate: null,
        },
        propertyValue: '',
        entriesOptionsValue: [],
        validations: {
            amount: false,
            category: false,
            description: false
        },
        modal: '',
        errMsg: '',
        CSVAdjustments: [],
        currentAdjustments: '',
        adjustmentErrMsg: ''
    }
    state = {
        ...this.initState,
        columns: [
            {
                Header:() => tableSort("Date"),
                'accessor': 'created',
                'Cell': row=>{
                    const date = _.get(row, 'original.created');

                    return(
                        <div>
                            <div className='bold-text'>{moment(date).format("MM/DD/YY hh:mm A z")}</div>
                        </div>
                    )
                }
            },
            {
                Header:() => tableSort("Category"),
                'accessor': 'category',
                'Cell': row=>{
                    const category = _.get(row, 'original.category');

                    return(
                        <div>
                            <div>{category}</div>
                        </div>
                    )
                }
            },
            {
                Header:() => tableSort("Description"),
                'accessor': 'description',
                'minWidth': 125,
                'Cell': row=>{
                    const description = _.get(row, 'original.description');

                    return(
                        <div>
                            <div className='bold-text'>
                                <OverlayTrigger 
                                    placement='top'
                                    overlay={<Popover
                                        id="popover-basic"
                                        placement='top'
                                    >
                                        <p>{description}</p>
                                    </Popover>}
                                >
                                    <div>{description}</div>
                                </OverlayTrigger>
                            </div>
                        </div>
                    )
                }
            },
            {
                Header:() => "Organization",
                sortable: false,
                'accessor': 'organization_name',
                'minWidth': 125,
                'Cell': row=>{
                    const organization_name = _.get(row, 'original.organization_name');

                    return(
                        <div 
                            className='clickable-text'
                            onClick={e => this.handleOrgChange(e, organization_name)}
                        >
                            <div>{organization_name}</div>
                        </div>
                    )
                }
            },
            {
                Header:() => tableSort("Property"),
                'accessor': 'property_id',
                'minWidth': 125,
                'Cell': row=>{
                    const property_id = _.get(row, 'original.property_id');

                    return(
                        <div>
                            <div>{property_id}</div>
                        </div>
                    )
                }
            },
            {
                Header:() => tableSort("Reservation"),
                'accessor': 'reservation_id',
                'minWidth': 125,
                'Cell': row=>{
                    const reservation_id = _.get(row, 'original.reservation_id');

                    return(
                        <div>
                            <div>{(reservation_id && reservation_id != 0) ? reservation_id : ''}</div>
                        </div>
                    )
                }
            },
            {
                Header:() => tableSort("Status"),
                'accessor': 'status',
                'Cell': row=>{
                    const status = _.get(row, 'original.status');
                    const label = {
                        'needs_approval' : 'Needs Approval',
                        'topped_up' : 'Pending',
                        'pending' : 'Pending',
                        'cancelled' : 'Cancelled',
                        'invoiced' : 'Invoiced',
                        'paid' : 'Paid'
                    };

                    return(
                        <div>
                            <div>{status ? label[status] : ''}</div>
                        </div>
                    )
                }
            },
            {
                Header:() => tableSort("Amount"),
                'accessor': 'amount',
                'Cell': row=>{
                    const currency = _.get(row, 'original.currency');
                    const amount = _.get(row, 'original.amount');

                    return(
                        <div className={Math.sign(amount) == -1 ? "negative-amount" : ""}>
                            <strong>{Constants.CURRENCY[currency.toUpperCase()]} {Constants.NUM_FORMAT(Math.abs(amount))}</strong>
                        </div>
                    )
                }
            },
            {
                'width': 100,
                'Cell': row=>{
                    const status = _.get(row, 'original.status');
                    const category = _.get(row, 'category');
                    const id = _.get(row, 'original.payment_statements_id');
                    const data = _.get(row, 'original');
                    const cancelRequirement = [
                        'pending',
                        'needs_approval',
                    ];

                    return(
                        <div className='adjustments-table-actions'>
                            {
                                (status === 'needs_approval' && this.approvePayments) &&
                                <Fragment>
                                    <OverlayTrigger 
                                        placement='top' 
                                        overlay={this.getTooltip('approve-adjustment', 'Approve')} 
                                    >
                                        <span
                                            onClick={(e) => this.handleAdjustments(e, data, 'approve')}
                                        >
                                            <i className='icon-thumbs_up'></i>
                                        </span>
                                    </OverlayTrigger>
                                    <OverlayTrigger 
                                        placement='top' 
                                        overlay={this.getTooltip('deny-adjustment', 'Deny')} 
                                    >
                                        <span
                                            onClick={(e) => this.handleAdjustments(e, data, 'deny')}
                                        >
                                            <i className='icon-wrong'></i>
                                        </span>
                                    </OverlayTrigger>
                                </Fragment>
                            }
                            {
                                category !== 'carryover balance' &&
                                cancelRequirement.includes(status) &&
                                <OverlayTrigger 
                                    placement='top' 
                                    overlay={this.getTooltip('cancel-adjustment', 'Cancel')} 
                                >
                                    <span
                                        onClick={(e) => this.handleAdjustments(e, data, 'cancel')}
                                    >
                                        <i className='icon-cancel'></i>
                                    </span>
                                </OverlayTrigger>
                            }
                            {
                                id &&
                                <OverlayTrigger 
                                    placement='top' 
                                    overlay={this.getTooltip('open-statement', 'Open Statement')} 
                                >
                                    <span
                                        onClick={(e) => this.getPayoutsStatementsById(e, id)}
                                    >
                                        <i className='icon-property_details'></i>
                                    </span>
                                </OverlayTrigger>
                            }
                        </div>
                    )
                }
            },
        ],
        payoutsColumns: [
            {
                'accessor': 'description',
                'Cell': row=>{
                    const description = _.get(row, 'original.description');
                    const reservation_id = _.get(row, 'original.reservation.reservation_id');
                    const firstName = _.get(row, 'original.reservation.renter.first_name');
                    const lastName = _.get(row, 'original.reservation.renter.last_name');
                    const arrival_date = _.get(row, 'original.reservation.arrival_dt');
                    const departure_date = _.get(row, 'original.reservation.departure_dt');

                    return(
                        <div className='display-flex'>
                            {
                                reservation_id ?
                                <div className='payouts-right-margin'>
                                    {description ? `Adjustments for ` : ''}
                                    <Link 
                                        className="clickable-text" 
                                        to={`/reservations/${reservation_id}/details`}
                                    >
                                        Reservation {reservation_id}
                                    </Link>
                                </div>
                                :
                                ""
                            }
                            {
                                (firstName || lastName) ?
                                <div className='payouts-right-margin'>
                                    {(firstName || lastName) && `${firstName}${lastName ? ` ${lastName}` : ''}`}
                                </div>
                                :
                                ""
                            }
                            {
                                (arrival_date || departure_date) ?
                                <div className='payouts-right-margin'>
                                    {arrival_date  && moment(arrival_date).tz('UTC').format("MMM D, YYYY")} 
                                    {(arrival_date && departure_date) ? ' - ' : ''} 
                                    {departure_date  && moment(departure_date).tz('UTC').format("MMM D, YYYY")}
                                </div>
                                :
                                ""
                            }
                            {
                                (description) ?
                                <div className='payouts-right-margin text-wrap-auto'>
                                    {description}
                                </div>
                                :
                                ""
                            }
                        </div>
                    )
                }
            },
            {
                'maxWidth': 225,
                'accessor': 'amount',
                'Cell': row=>{
                    const currency = _.get(row, 'original.currency');
                    const amount = _.get(row, 'original.amount');

                    return(
                        <div className={`column-payout-amount ${Math.sign(amount) == -1 ? "negative-amount" : ""}`}>
                            <strong>{Constants.CURRENCY[currency.toUpperCase()]} {Constants.NUM_FORMAT(Math.abs(amount))}</strong>
                        </div>
                    )
            }
            },
        ]
    }

    getPayoutsStatementsById = async (e, id) => {
        e.preventDefault();
        e.stopPropagation();
        let resp;

        this.setState({
            adjustmentsIsLoading: true,
            currentPayout: {},
        });

        try {
            resp = await API.get("rapapi", `/organizations/${this.props.org}/paymentstatements/${id}`, { response: true });
            if(_.get(resp, 'data.id')) {
                const statement =  _.get(resp, 'data.statement') ? JSON.parse(resp.data.statement) : {};
                let totalAmount = '';
                let currency = '';

                if(_.get(statement, 'items.length')) {
                    let total = 0;
                    statement.items.forEach((item) => {
                        total = Number(item.amount) + total;
                    });

                    totalAmount = total;
                };

                if(_.get(statement, 'org.currency')) {
                    currency = statement.org.currency;
                };

                this.setState({
                    currentPayout: resp.data,
                    currentPayoutStatement: _.get(statement, 'items.length') ? statement.items : [],
                    currentPayoutTotalAmount: `${Constants.CURRENCY[currency.toUpperCase()]} ${Constants.NUM_FORMAT(Math.abs(totalAmount))}`,
                    adjustmentsIsLoading: false,
                    modal: 'adjustments-payouts-modal'
                });
            }
            else {
                this.setState({
                    adjustmentsIsLoading: false,
                });
            };
        }
        catch(e) {
            this.setState({
                adjustmentsIsLoading: false,
            });
        };
    }

    async componentDidMount() {
        await this.init();
        
        this.setState({
            isLoading: false
        });
    }

    componentWillUnmount() {
        const storageTypes = [
            'adjustments',
            'completed-payouts',
            'upcoming-payouts'
        ];

        storageTypes.forEach((type) => {
            this.setLocalStorage(type);
        });
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if(_.get(prevProps, 'org') != _.get(this.props, 'org')) {
            this.setState({
                ...this.initState,
            });

            await this.init();

            this.setState({
                isLoading: false
            });
        };
    }

    init = async () => {
        this.account_manager_admin = (_.get(this.props.roleManager, 'roles').indexOf(Constants.USER_TYPES.ACCOUNT_MANAGER_ADMIN) >= 0) ? true : false;
        this.guest_services = (_.get(this.props.roleManager, 'roles').indexOf(Constants.USER_TYPES.GUEST_SERVICES) >= 0) ? true : false;
        this.initValidations = {
            entries: false,
            amount: false,
            category: false,
            description: false
        };

        const {
            completedPayoutsPageSize,
            adjustmentsPageSize,
            adjustmentsFilters,
            upcomingPayoutsPageSize
        } = this.state;
        const adjustmentParams = JSON.parse(localStorage.getItem('db-adjustments'));
        const completedPayoutsParams = JSON.parse(localStorage.getItem('db-completed-payouts'));
        const upcomingPayoutsParams = JSON.parse(localStorage.getItem('db-upcoming-payouts'));

        this.checkPermissions();

        if(this.account_manager_admin || this.guest_services || this.props.isInternalPropertyAdmin) {
            if(adjustmentParams) {
                this.setState({
                    adjustmentsPageSize: adjustmentParams.adjustmentsPageSize || adjustmentsPageSize,
                    adjustmentsFilters: adjustmentParams.adjustmentsFilters || adjustmentsFilters,
                }, () => {
                    this.getAdjustments(0);
                });
            }
            else {
                this.getAdjustments(0);
            };

            await this.getProperties(this.props.org);
            await this.getChildOrgs();
        };

        this.adjustmentsTid = this.getSessionId();
        this.completedTid = this.getSessionId();
        this.upcomingTid = this.getSessionId();

        this.getPayoutsStatements(0, '', (_.get(completedPayoutsParams, 'completedPayoutsPageSize') || completedPayoutsPageSize), 'completed');
        await this.getPayoutsStatements(0, '', (_.get(upcomingPayoutsParams, 'upcomingPayoutsPageSize') || upcomingPayoutsPageSize), 'upcoming');

        this.setState({ 
            initPayoutsIsLoading: false
        });
    }

    checkPermissions = () => {
        if(_.isArray(this.props.permissions)) {
            if(this.props.permissions.includes('approve_payments')) {
                this.approvePayments = true;
            };
        };
    }

    getAdjustmentsCSV = async () => {
        const {
            adjustmentsFilters,
            adjustmentsSorted
        } = this.state;
        const tid = this.getSessionId();
        let resp = [];
        let adjustments = [];
        let offset = 0;
        let limit = 100;
        let count = 0;
        let totalCount = 0;
        let sort = adjustmentsSorted;
        const sortDesc = adjustmentsSorted.desc ? "-" : "+";
        let status = Object.keys(adjustmentsFilters).filter(key => adjustmentsFilters[key]);
        if(status.includes('pending') && !status.includes('topped_up')) { 
            status.push('topped_up');
        }
        else if(status.includes('topped_up') && !status.includes('pending')) {
            status = status.filter((x) => x !== 'topped_up');
        };
        const statusQueryString = _.isArray(status) && !_.isEmpty(status) ? `&status=${status.join(",")}`: '';

        this.setState({
            adjustmentsIsLoading: true,
        });

        try {
            resp = await API.get("rapapi", `/organizations/${this.props.org}/partnerjournalentries?tid=${encodeURIComponent(tid)}&offset=${offset}&limit=${limit}&sort=${encodeURIComponent(sort.id)}${encodeURIComponent(sortDesc)}${statusQueryString}`, { response: true, isCognito: true });
            if(_.get(resp, 'data')) {
                resp.data.forEach((x) => {
                    adjustments = [...adjustments, x];
                });

                count = count + resp.data.length;

                totalCount = resp.headers['x-total-count'];

                while(count < totalCount) {
                    try {
                        offset = offset + 100;

                        resp = await API.get("rapapi", `/organizations/${this.props.org}/partnerjournalentries?tid=${encodeURIComponent(tid)}&offset=${offset}&limit=${limit}&sort=${encodeURIComponent(sort.id)}${encodeURIComponent(sortDesc)}${statusQueryString}`, { response: true, isCognito: true });

                        resp.data.forEach((x) => {
                            adjustments = [...adjustments, x];
                        });
        
                        count = count + resp.data.length;
                    }
                    catch(e) {
                        console.log(e)
                    };
                };

                if(count == totalCount) {
                    let newAdjustments = [];

                    adjustments.forEach((adjustment) => {
                        const created = _.get(adjustment, 'created') ? moment(adjustment.created).format("MM/DD/YY hh:mm A z") : '';
                        const currency = _.get(adjustment, 'currency');
                        const amount = _.get(adjustment, 'amount');
                        const formatAmount = `${Math.sign(amount) == -1 ? '(' : ''}${Constants.CURRENCY[currency.toUpperCase()]}${Constants.NUM_FORMAT(Math.abs(amount))}${Math.sign(amount) == -1 ? ')' : ''}`;
                        const status = _.get(adjustment, 'status') == 'topped_up' ? 'pending' : adjustment.status;
                        const updated = _.get(adjustment, 'updated') ? moment(adjustment.updated).format("MM/DD/YY hh:mm A z") : '';

                        newAdjustments.push({
                            created: created,
                            category: adjustment.category,
                            description: adjustment.description,
                            organization_name: adjustment.organization_name,
                            property_id: adjustment.property_id,
                            reservation_id: adjustment.reservation_id,
                            status: status,
                            payment_statements_id: adjustment.payment_statements_id,
                            amount: formatAmount,
                            updated: updated,
                        });
                    });

                    this.setState({
                        CSVAdjustments: newAdjustments,
                        adjustmentsIsLoading: false,
                    });
                }
            };
        }
        catch(e) {
            console.log(e)
            this.setState({
                adjustmentsIsLoading: false,
            });
        }
    }

    handleAdjustmentsCSV = async () => {
        const CSVcomponent = document.getElementById("download-csv");

        await this.getAdjustmentsCSV();
        
        CSVcomponent.click();
    }

    getProperties = async (orgName) => {
        let resp;
        let limit = 100;
        let offset = 0;
        const tid = this.getSessionId();
        let propertyList = [
            {
                custom_label: '',
                custom_value: '',
            }
        ];

        this.setState({
            getPropertiesIsLoading: true
        });

        try {
            resp = await API.get("rapapi", `/promotion/properties?organizations=${orgName}&offset=${offset}&tid=${tid}&limit=${limit}`, { response: true, isCognito: true });

            if(_.get(resp, 'data.list.length')) {
                resp.data.list.forEach((prop) => {
                    propertyList.push({
                        ...prop,
                        custom_label: `${prop.property_id ? `${prop.property_id} -` : ''} ${prop.title ? prop.title : ''}`,
                        custom_value: prop.property_id
                    })
                });
            };

            this.setState({
                propertyValue: '',
                propertyList: propertyList,
                getPropertiesIsLoading: false
            });
        }
        catch(e) {
            this.setState({
                getPropertiesIsLoading: false
            });
        };
    }

    getChildOrgs = async () => {
        let resp_childOrgs;
        let orgList = [
            {
                custom_label: this.props.orgTitle,
                custom_value: this.props.org,
            }
        ];

        function getOrganizationDetails(data) {
            let organizationDetails = [];
        
            function extractDetails(organizations) {
                organizations.forEach(org => {
                    organizationDetails.push({
                        custom_label: org.title,
                        custom_value: org.organization_name
                    });
                    if (org.child_organizations && org.child_organizations.length > 0) {
                        extractDetails(org.child_organizations);
                    }
                });
            }
        
            extractDetails(data);
            return organizationDetails;
        }

        try {
            resp_childOrgs = await API.get("accapi", `/organizationtree?parent=${this.props.org}`);

            if(_.get(resp_childOrgs, '[0].child_organizations.length')) {
                let childOrgs = getOrganizationDetails(resp_childOrgs[0].child_organizations);

                orgList = [...orgList, ...childOrgs];
            };

            this.setState({
                orgList: orgList,
                orgValue: orgList[0],
                adjustmentsData: {
                    ...this.state.adjustmentsData,
                    organization: this.props.org
                }
            });
        }
        catch(e) {
        }
    }

    handleAdjustments = (e, data, type) => {
        e.preventDefault();
        
        this.setState({
            adjustmentErrMsg: '',
            currentAdjustments: {
                type: type,
                data: data
            },
            modal: 'adjustments-prompt-modal'
        });
    }

    approveOrCancelAdjustment = async () => {
        const { currentAdjustments } = this.state;

        this.setState({
            adjustmentErrMsg: '',
            adjustmentsModalIsLoading: true
        });
        const body = {
            ...currentAdjustments.data,
            status: _.get(currentAdjustments, 'type') === 'approve' ? 'pending' : 'cancelled'
        };

        let resp;
        try {
            resp = await API['put']("rapapi", `/organizations/${_.get(currentAdjustments, 'data.organization_name')}/partnerjournalentries/${_.get(currentAdjustments, 'data.id')}`, { body: body });

            await this.getAdjustments(0, '', '', true);

            this.setState({
                modal: '',
                adjustmentsModalIsLoading: false
            });
        }
        catch(e) {
            let msg = `Oops! We encountered an error while attempting to ${_.get(currentAdjustments, 'type')} adjustment. Please contact customer support.`;
        
            if(_.get(e, 'response.data.Message.length')) {
                if(e.response.data.Message.match(/'(.*?)'/)) {
                    msg = e.response.data.Message.match(/'(.*?)'/)[1];
                };
            };

            this.setState({
                adjustmentErrMsg: msg,
                adjustmentsModalIsLoading: false
            });
        };
    }

    handleAdjustmentsFilters = (filter) => {
        const {
            adjustmentsFilters,
        } = this.state;

        this.setState({
            adjustmentsFilters: {
                ...adjustmentsFilters,
                [filter]: !adjustmentsFilters[filter]
            }
        }, async () => {
            await this.getAdjustments(0, '', '', true);
        });
    }

    getSessionId = () => {
        const maximum = 99999
        const minimum = 10000
        return (Math.floor(Math.random() * ((maximum - minimum) + 1)) + minimum)
    }

    getAdjustments = async (active_page, sort, page_size, changeTid) => {
        const {
            adjustmentsFilters,
            adjustmentsSorted,
            adjustmentsPageSize,
        } = this.state;

        const newPageSize = page_size ? page_size : adjustmentsPageSize;
        let newActivePage = ((active_page === 0) ? 0 : active_page - 1);
        const offset = newPageSize * newActivePage;
        const newSorted = sort ? sort : adjustmentsSorted;
        const sortDesc = newSorted.desc ? "-" : "+";
        let status = Object.keys(adjustmentsFilters).filter(key => adjustmentsFilters[key]);
        if(status.includes('pending') && !status.includes('topped_up')) { 
            status.push('topped_up');
        }
        else if(status.includes('topped_up') && !status.includes('pending')) {
            status = status.filter((x) => x !== 'topped_up');
        };
        const statusQueryString = _.isArray(status) && !_.isEmpty(status) ? `&status=${status.join(",")}`: '';

        if(changeTid) {
            this.adjustmentsTid = this.getSessionId();
        };

        this.setState({
            adjustmentsIsLoading: true
        });

        try {
            let resp = await API.get("rapapi", `/organizations/${this.props.org}/partnerjournalentries?tid=${encodeURIComponent(this.adjustmentsTid)}&offset=${offset}&limit=${encodeURIComponent(newPageSize)}&sort=${encodeURIComponent(newSorted.id)}${encodeURIComponent(sortDesc)}${statusQueryString}`, { response: true, isCognito: true });
            if(resp && resp.data) {
                let respDataCopy = [...resp.data];

                this.setState({
                    adjustments: respDataCopy,
                    adjustmentsActivePage: newActivePage + 1,
                    adjustmentsPageSize: newPageSize,
                    adjustmentsSorted: newSorted,
                    adjustmentsCount: resp.headers["x-total-count"],
                    adjustmentsIsLoading: false,
                }, () => {
                    this.setLocalStorage('adjustments');
                });
            };
        }
        catch(e) {
            this.setState({
                adjustmentsIsLoading: false
            });
        };
    }

    getPayoutsStatements = async (active_page, sort, page_size, type, changeTid) => {
        const {
            upcomingPayoutsSort,
            upcomingPayoutsDates,
            upcomingPayoutsPageSize,
            completedPayoutsSort,
            completedPayoutsDates,
            completedPayoutsPageSize
        } = this.state;

        const payoutsSort = type == 'upcoming' ? upcomingPayoutsSort : completedPayoutsSort;
        const payoutsDates = type == 'upcoming' ? upcomingPayoutsDates : completedPayoutsDates;
        const payoutsPageSize = type == 'upcoming' ? upcomingPayoutsPageSize : completedPayoutsPageSize;
        const newActivePage = ((active_page === 0) ? 0 : active_page - 1);
        const newPageSize = page_size ? page_size : payoutsPageSize;
        const offset = newPageSize * newActivePage;
        const newSorted = sort ? sort : payoutsSort;
        const sortDesc = newSorted.desc ? "-" : "+";
        const startDate = moment(payoutsDates.startDate).format("YYYY-MM-DD");
        const endDate = moment(payoutsDates.endDate).format("YYYY-MM-DD");
        const futurePayouts = type == 'upcoming' ? '&future_payouts=true' : '';

        if(changeTid) {
            this[type == 'upcoming' ? 'upcomingTid' : 'completedTid'] = this.getSessionId();
        };
        let tid = this[type == 'upcoming' ? 'upcomingTid' : 'completedTid'];

        this.setState({
            [type == 'upcoming' ? 'upcomingPayoutsIsLoading' : 'completedPayoutsIsLoading']: true
        });

        try {
            let resp = await API.get("rapapi", `/organizations/${this.props.org}/paymentstatements?limit=${newPageSize}${futurePayouts}&offset=${offset}&tid=${encodeURIComponent(tid)}${moment(startDate).isValid() ? `&posted=${startDate}` : ''}${moment(endDate).isValid() ? `:${endDate}` : ''}&sort=${encodeURIComponent(newSorted.id)}${encodeURIComponent(sortDesc)}`, { response: true, isCognito: true });
            if(_.get(resp, 'data.length')) {
                let parseData = [];

                resp.data.forEach((x) => {
                    if(_.get(x, 'statement.length')) {
                        let statement = JSON.parse(x.statement);

                        parseData.push({
                            ...x,
                            statement: statement
                        });
                    }
                    else {
                        parseData.push({...x});
                    };
                });

                this.setState({
                    [type == 'upcoming' ? 'upcomingPayoutsPageSize' : 'completedPayoutsPageSize']: newPageSize,
                    [type == 'upcoming' ? 'upcomingPayoutsCount' : 'completedPayoutsCount']: resp.headers['x-total-count'],
                    [type == 'upcoming' ? 'upcomingPayoutsStatements' : 'completedPayoutsStatements']: parseData,
                    [type == 'upcoming' ? 'upcomingPayoutsActivePage' : 'completedPayoutsActivePage']: newActivePage + 1,
                    [type == 'upcoming' ? 'upcomingPayoutsIsLoading' : 'completedPayoutsIsLoading']: false,
                });
            }
            else {
                this.setState({
                    [type == 'upcoming' ? 'upcomingPayoutsStatements' : 'completedPayoutsStatements']: [],
                    [type == 'upcoming' ? 'upcomingPayoutsIsLoading' : 'completedPayoutsIsLoading']: false,
                });
            };
        }
        catch(e) {
            this.setState({
                [type == 'upcoming' ? 'upcomingPayoutsIsLoading' : 'completedPayoutsIsLoading']: false,
            });
        }
    }

    getPayoutsStatementCSV = async (e, statement) => {
        e.preventDefault();
        e.stopPropagation();

        const { 
            completedPayoutsDates,
        } = this.state;

        const startDate = moment(completedPayoutsDates.startDate).format("YYYY-MM-DD");
        const endDate = moment(completedPayoutsDates.endDate).format("YYYY-MM-DD");
        const fileName = _.get(statement, 'id') ? `${_.get(statement, 'organization_name')}_${_.get(statement, 'id')}.csv`:
            `${this.props.org}${moment(startDate).isValid() && moment(startDate).isValid(endDate) ? `_${startDate}_${endDate}`: ''}.csv`

        this.setState({
            completedPayoutsIsLoading: true
        });

        try {
            let resp = await API.get("rapapi", `/organizations/${this.props.org}/paymentstatements${_.get(statement, 'id') ? `/${_.get(statement, 'id')}` : `${moment(startDate).isValid() && moment(startDate).isValid(endDate) ? `?posted=${startDate}:${endDate}`: ''}`}`, {
                response: true,
                headers: {
                    'Accept': 'text/csv',
                },
                responseType: 'text',
            });
        
            if (resp && resp.data) {
                const blob = new Blob([resp.data], { type: 'text/csv' });
        
                const link = window.URL.createObjectURL(blob);
        
                const downloadLink = document.createElement('a');
                downloadLink.href = link;
                downloadLink.download = fileName;
        
                downloadLink.click();
        
                window.URL.revokeObjectURL(link);
            } 
            else {
                console.log("No data received");
            };

            this.setState({
                completedPayoutsIsLoading: false
            });
        }
        catch (e) {
            let msg = `Oops! We encountered an error. Please contact customer support.`;
        
            if(_.get(e, 'response.data.Message.length')) {
                if(e.response.data.Message.match(/'(.*?)'/)) {
                    msg = e.response.data.Message.match(/'(.*?)'/)[1];
                };
            };

            this.setState({
                errMsg: msg,
                modal: 'custom-modal',
                completedPayoutsIsLoading: false
            });
        };
    }

    getPayoutsStatementPDF = async (e, id) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            completedPayoutsIsLoading: true
        });

        try {
            let resp = await API.get("rapapi", `/organizations/${this.props.org}/paymentstatements/${id}/pdf`, {
                response: true,
                responseType: "text"
            });
        
            if (resp && resp.data) {
                let binary_string = window.atob(resp.data)
                let len = resp.data.length;
                let bytes = new Uint8Array(len);
                for (let i = 0; i < len; i++) {
                    bytes[i] = binary_string.charCodeAt(i);
                }
        
                const pdfBlob = new Blob([bytes.buffer], { type: 'application/pdf' });
        
                const pdfUrl = window.URL.createObjectURL(pdfBlob);
        
                const downloadLink = document.createElement('a');
                downloadLink.href = pdfUrl;
                downloadLink.download = 'payout_statement.pdf';
        
                downloadLink.click();
        
                window.URL.revokeObjectURL(pdfUrl);
            } 
            else {
                console.log("No data received");
            };

            this.setState({
                completedPayoutsIsLoading: false
            });
        }
        catch (e) {
            let msg = `Oops! We encountered an error. Please contact customer support.`;
        
            if(_.get(e, 'response.data.Message.length')) {
                if(e.response.data.Message.match(/'(.*?)'/)) {
                    msg = e.response.data.Message.match(/'(.*?)'/)[1];
                };
            };

            this.setState({
                errMsg: msg,
                modal: 'custom-modal',
                completedPayoutsIsLoading: false
            });
        };
    };

    handleSubmitPrompt = () => {
        const newValidations = this.handleValidations();

        this.setState({
            validations: newValidations
        }, () => {
            const { validations } = this.state;

            const hasValidations = Object.values(validations).some(value => value === true);

            if(!hasValidations) {
                this.setState({
                    modal: 'prompt-modal'
                });
            };
        });
    }

    submitAdjustments = async () => {
        const{ adjustmentsData } = this.state;
        let body = {
            currency: adjustmentsData.currency,
            amount: adjustmentsData.entries == 'charge' ? Math.abs(adjustmentsData.amount) : adjustmentsData.amount * -1,
            description: adjustmentsData.description,
            category: adjustmentsData.category,
            property_id: adjustmentsData.property_id,
            reservation_id: adjustmentsData.reservation_id,
            organization_name: adjustmentsData.organization,
        };

        this.setState({
            modal: '',
            adjustmentsIsLoading: true
        });

        let resp;
        try {
            resp = await API.post("rapapi", `/organizations/${adjustmentsData.organization}/partnerjournalentries`, { body: body });

            if(resp) {
                await this.getAdjustments(0, '', '', true);

                this.resetData();
            };

            this.setState({
                adjustmentsIsLoading: false
            });
        }
        catch(e) {
            let msg = `Oops! We encountered an error while attempting to add a adjustments. Please contact customer support.`;
        
            if(_.get(e, 'response.data.Message.length')) {
                if(e.response.data.Message.match(/'(.*?)'/)) {
                    msg = e.response.data.Message.match(/'(.*?)'/)[1];
                };
            };

            this.setState({
                errMsg: msg,
                modal: 'custom-modal',
                adjustmentsIsLoading: false
            });
        };
    }

    setLocalStorage = (type) => {
        const {
            adjustmentsPageSize,
            adjustmentsFilters,
            completedPayoutsPageSize,
            upcomingPayoutsPageSize
        } = this.state;

        if(type == 'adjustments') {
            const adjustmentPagination = {
                adjustmentsPageSize: adjustmentsPageSize,
                adjustmentsFilters: adjustmentsFilters,
            };

            localStorage.setItem('db-adjustments', JSON.stringify(adjustmentPagination));
        }
        else if(type == 'completed-payouts') {
            const completedPayoutsParams = {
                completedPayoutsPageSize: completedPayoutsPageSize
            };
    
            localStorage.setItem('db-completed-payouts', JSON.stringify(completedPayoutsParams));
        }
        else if(type == 'upcoming-payouts') {
            const upcomingPayoutsParams = {
                upcomingPayoutsPageSize: upcomingPayoutsPageSize
            };
    
            localStorage.setItem('db-upcoming-payouts', JSON.stringify(upcomingPayoutsParams));
        };
    }

    handleOnChange = (e, stateName) => {
        e.preventDefault();
        const name = e.target.name;
        let value = e.target.value;

        if(name == 'amount') {
            if(value < 0) {
                value = '';
            };
        };

        let newState = {
            [stateName]: {
                ...this.state[stateName],
                [name]: value
            }
        };

        this.setState({
            ...newState
        }, () => {
            const { validations } = this.state;

            if(validations[name]) {
                const newValidations = this.handleValidations();

                this.setState({
                    validations: newValidations
                });
            };
        })
    }

    handlePickyEntriesOnChange = (data) => {
        const { adjustmentsData } = this.state;

        this.setState({
            entriesOptionsValue: data.custom_value,
            adjustmentsData: {
                ...adjustmentsData,
                entries: data.value,
                category: '',
            }
        }, () => {
            const { validations } = this.state;

            if(validations.entries) {
                const newValidations = this.handleValidations();

                this.setState({
                    validations: newValidations
                });
            };
        })
    }

    handlePickyOnChange = (data, name, type) => {
        const { adjustmentsData } = this.state;
        const pickyValueName = type == 'org' ? 'orgValue' : 'propertyValue'

        this.setState({
            [pickyValueName]: data, 
            adjustmentsData: {
                ...adjustmentsData,
                [name]: data.custom_value
            }
        }, async () => {
            if(type == 'org') {
                await this.getProperties(data.custom_value)
            };
        });
    }

    handlePayoutsPanel = (e, key, type) => {
        const { 
            completedPayoutsActiveKey,
            upcomingPayoutsActiveKey
        } = this.state;
        const state = type == 'upcoming' ? upcomingPayoutsActiveKey : completedPayoutsActiveKey
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            [type == 'upcoming' ? 'upcomingPayoutsActiveKey' : 'completedPayoutsActiveKey']: state !== key ? key : ''
        });
    }

    getRef = (r, type) => {
        this[type] = r;
    }

    adjustmentsPageOnChange = async (page) => {
        await this.getAdjustments(page);
    }

    adjustmentsPageSizeOnChange = async (pageSize) => {
        await this.getAdjustments(0, '', pageSize, true);
    }

    adjustmentsOnSortedChange = async (sorted) => {
        const { adjustmentsActivePage } = this.state;
        await this.getAdjustments(adjustmentsActivePage, sorted[0], '', true);
    }

    resetData = () => {
        this.setState({
            entriesOptionsValue: [],
            propertyValue: '',
            orgValue: {
                custom_label: this.props.orgTitle,
                custom_value: this.props.org,
            },
            adjustmentsData: {
                currency: 'USD',
                entries: '',
                orgName: '',
                amount: '',
                description: '',
                category: '',
                property_id: '',
                reservation_id: '',
                organization: this.props.org,
            },
            validations: {
                amount: false,
                category: false,
                description: false
            },
        }, async () => {
            await this.getProperties(this.props.org);
        });
    }

    getTooltip = (id, text) => {
        return <Tooltip id={id}>{text}</Tooltip>
    }

    payoutsPageOnChange = (page, type) => {
        this.setState({
            [type == 'upcoming' ? 'upcomingPayoutsActiveKey' : 'completedPayoutsActiveKey']: '',
            [type == 'upcoming' ? 'upcomingPayoutsActivePage' : 'completedPayoutsActivePage']: page
        }, () => {
            this.getPayoutsStatements(page, '', '', type);
        });
    }

    payoutsPageSizeOnChange = (pageSize, type) => {
        this.setState({
            [type == 'upcoming' ? 'upcomingPayoutsActiveKey' : 'completedPayoutsActiveKey']: '',
            [type == 'upcoming' ? 'upcomingPayoutsPageSize' : 'completedPayoutsPageSize']: pageSize,
            [type == 'upcoming' ? 'upcomingPayoutsActivePage' : 'completedPayoutsActivePage']: 1,
        }, () => {
            this.getPayoutsStatements(0, '', pageSize, type, true);
            
            this.setLocalStorage(type == 'upcoming' ? 'upcoming-payouts' : 'completed-payouts');
        });
    }

    payoutsOnSort = (e, type) => {
        e.preventDefault();
        const { 
            upcomingPayoutsActivePage,
            completedPayoutsActivePage,
        } = this.state;
        const sort = type == 'upcoming' ? 'upcomingPayoutsSort' : 'completedPayoutsSort';
        const activePage = type == 'upcoming' ? upcomingPayoutsActivePage : completedPayoutsActivePage;

        this.setState({
            [sort]: {
                ...this.state[sort],
                desc: !this.state[sort].desc,
            },
            [type == 'upcoming' ? 'upcomingPayoutsActiveKey' : 'completedPayoutsActiveKey']: ''
        }, () => {
            this.getPayoutsStatements(activePage, '', '', type, true);
        });
    }

    payoutsHandleDatesOnChange = ({ startDate, endDate}, type) => {
        this.setState({
            [type == 'upcoming' ? 'upcomingPayoutsDates' : 'completedPayoutsDates']: {
                startDate: startDate,
                endDate: endDate
            },
            [type == 'upcoming' ? 'upcomingPayoutsActiveKey' : 'completedPayoutsActiveKey']: '',
        }, () => {
            if ((startDate === null && endDate === null)) {
                this.getPayoutsStatements(0, '', '', type, true);
            }
            else if(startDate || endDate) {
                this.getPayoutsStatements(0, '', '', type, true);
            };
        });
    }

    setPropertyOrg = (orgName) => {
        let organization = this.props.user.expanded_organization_roles.filter(org => {
            if(org.organization_name === orgName) {
                return true
            }
        });

        if(this.org !== orgName) {
            if(organization && organization.length) {
                this.props.updateOrganization(organization[0].organization_name);
                this.props.updateOrganizationTitle(organization[0].title);
                this.props.updateOrganizationId(organization[0].organization_id);
                this.props.setRole(organization[0].roles);
    
                sessionStorage.setItem('org', organization[0].organization_name);
                sessionStorage.setItem('orgTitle', organization[0].title);
                sessionStorage.setItem('orgId', organization[0].organization_id);

                sessionStorage.removeItem('reservations-listing');
                sessionStorage.removeItem('properties-listing');
            };
        };
    }

    handleOrgChange = (e, orgName) => {
        e.preventDefault();
        e.stopPropagation();

        this.setPropertyOrg(orgName);
    
        this.props.history.push("/dashboard/earnings");
    }

    hasValue = (data, attribute) => {
        const value = data[attribute];
        return value !== '' && value !== null && value !== undefined;
    };

    handleValidations = () => {
        const { adjustmentsData } = this.state;
        let validations = _.cloneDeep(this.initValidations);

        if(!this.hasValue(adjustmentsData, 'entries')) {
            validations.entries = true;
        };

        if(!this.hasValue(adjustmentsData, 'amount')) {
            validations.amount = true;
        };

        if(!this.hasValue(adjustmentsData, 'category')) {
            validations.category = true;
        };

        if(_.get(adjustmentsData, 'category') == 'Miscellaneous Fee' || _.get(adjustmentsData, 'category') == 'Miscellaneous Payment') {
            if(!this.hasValue(adjustmentsData, 'description')) {
                validations.description = true;
            };
        };

        return validations;
    }
    
    renderPayoutsPanel = (type) => {
        const {
            payoutsColumns,
            completedPayoutsCount,
            completedPayoutsActiveKey,
            completedPayoutsSort,
            completedPayoutsActivePage,
            completedPayoutsPageSize,
            completedPayoutsStatements,
            upcomingPayoutsStatements,
            upcomingPayoutsActiveKey,
            upcomingPayoutsActivePage,
            upcomingPayoutsPageSize,
            upcomingPayoutsCount,
            upcomingPayoutsSort,
        } = this.state;
        const upcoming = type == 'upcoming';
        const activeKey = upcoming ? upcomingPayoutsActiveKey : completedPayoutsActiveKey;
        const sort = upcoming ? upcomingPayoutsSort : completedPayoutsSort;
        const activePage = upcoming ? upcomingPayoutsActivePage : completedPayoutsActivePage;
        const pageSize = upcoming ? upcomingPayoutsPageSize : completedPayoutsPageSize;
        const statements = upcoming ? upcomingPayoutsStatements : completedPayoutsStatements;
        const totalCount = upcoming ? upcomingPayoutsCount : completedPayoutsCount;

        return (
            <Col md={12} className='top-margin panel-payouts'>
                <div className='payouts-sort-container'>
                    <div 
                        className={`table-sort-container ${sort.desc ? '-sort-desc' : '-sort-asc'}`}
                        onClick={e => {this.payoutsOnSort(e, type)}}
                    >
                        <span>Date</span>
                        <span className="sort-container">
                            <i className="icon-dwon_arrow"></i>
                            <i className="icon-up-arrow"></i>
                        </span>
                    </div>
                </div>
                <PanelGroup 
                    accordion
                    id={`${upcoming ? 'upcoming-': ''}payouts`}
                    activeKey={upcoming ? upcomingPayoutsActiveKey : completedPayoutsActiveKey}
                    onSelect={() => null}
                >
                    {
                        statements.map((payout, index) => {
                            const statement = payout.statement;
                            let totalAmount = '';
                            let statementCurrency = '';

                            if(_.get(statement, 'items.length')) {
                                let total = 0;
                                statement.items.forEach((item) => {
                                    total = Number(item.amount) + total;
                                });

                                totalAmount = total;
                            };
                            
                            if(_.get(statement, 'org.currency')) {
                                statementCurrency = statement.org.currency;
                            };

                            return(
                                <Panel key={index} eventKey={index + 1}>
                                    <Panel.Heading>
                                        <Panel.Title
                                            onClick={e => this.handlePayoutsPanel(e, (index + 1), type)}
                                            toggle
                                        >
                                            <div className='panel-payouts-title'>
                                                <div className='panel-payouts-left'>
                                                    <div className='panel-date payouts-right-margin'>
                                                        <strong>
                                                            {
                                                                upcoming ?
                                                                moment(payout.posted).utc().format('MM/DD/YY') :
                                                                moment(payout.posted).format('MM/DD/YY hh:mm A z')
                                                            }
                                                        </strong>
                                                    </div>
                                                    <div 
                                                        className='clickable-text'
                                                        onClick={e => this.handleOrgChange(e, payout.organization_name)}
                                                    >
                                                        {payout.organization_name}
                                                    </div>
                                                </div>
                                                <div className='panel-payouts-right'>
                                                    {
                                                        !upcoming &&
                                                        <Fragment>
                                                            <div className='panel-pdf'>
                                                                <OverlayTrigger 
                                                                    placement='top' 
                                                                    overlay={this.getTooltip('download-pdf', 'Download PDF')} 
                                                                >
                                                                    <span 
                                                                        className='flex-align-center' 
                                                                        onClick={(e) => this.getPayoutsStatementPDF(e, payout.id, type)}
                                                                    >
                                                                        PDF &nbsp; <i className='icon-folder-download'></i>
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </div>
                                                            <div className='panel-csv'>
                                                                <OverlayTrigger 
                                                                    placement='top' 
                                                                    overlay={this.getTooltip('csv-download', 'Download CSV')} 
                                                                >
                                                                    <span 
                                                                        className='flex-align-center' 
                                                                        onClick={(e) => this.getPayoutsStatementCSV(e, payout, type)}
                                                                    >
                                                                        CSV &nbsp; <i className='icon-folder-download'></i>
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </Fragment>
                                                    }
                                                    <div className='panel-amount'>
                                                        {
                                                            totalAmount &&
                                                            <div className={Math.sign(totalAmount) == -1 ? "negative-amount" : ""}>
                                                                <strong>
                                                                    {Constants.CURRENCY[upcoming ? _.get(payout, 'currency', '').toUpperCase() : statementCurrency.toUpperCase()]}
                                                                    {" "}
                                                                    {Constants.NUM_FORMAT(Math.abs(totalAmount))}
                                                                </strong>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className={'panel-chevron ' + (activeKey === (index + 1) ? 'glyphicon glyphicon-chevron-up': 'glyphicon glyphicon-chevron-down')}>
                                                    </div>
                                                </div>
                                            </div>
                                        </Panel.Title>
                                    </Panel.Heading>
                                    <Panel.Body collapsible>
                                        {
                                            activeKey == (index + 1) &&
                                            <Col md={12}>
                                                <ReactTableComponent
                                                    manual
                                                    className='-highlight'
                                                    noDataText="No payouts found."
                                                    minRows={0}
                                                    data={statement.items}
                                                    resizable={false}
                                                    activePage={activePage}
                                                    columns={payoutsColumns}
                                                    showPagination={false}
                                                    totalCount={Number(totalCount)}
                                                />
                                            </Col>
                                        }
                                    </Panel.Body>
                                </Panel>
                            )
                        })
                    }
                </PanelGroup>
                <div className="pagination-container">
                    <div className="units-info-container">
                        {totalCount} &nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;
                    </div>
                    <div className="page_size-input-container">
                        <div 
                            className='page-size' 
                        >
                            <InputField
                                className="pageSize"
                                type='number' 
                                name='pageSize'
                                autoComplete="off"
                                defaultValue={pageSize}
                                onKeyUp={(e) => {
                                    let value = e.target.value;

                                    if(value > 100) {
                                        value = 100;
                                    }
                                    else if(value < 0) {
                                        value = 1;
                                    };

                                    if(value.length && e.keyCode === 13 && value !== 0 && Number.isInteger(Number(value))) {
                                        this.payoutsPageSizeOnChange(value, type);
                                    };
                                }}
                            />
                            / page
                        </div>
                    </div>
                    <Pagination
                        activePage={activePage}
                        hideFirstLastPages={false}
                        prevPageText={"Prev"}
                        nextPageText={"Next"}
                        itemsCountPerPage={Number(pageSize)}
                        totalItemsCount={Number(totalCount)}
                        onChange={(page) => {this.payoutsPageOnChange(page, type)}}
                    />
                </div>
            </Col>
        )
    }

    closeModal = () => {
        this.setState({
            errMsg: '',
            modal: '',
        });
    }

    isOutsideRange = () => {
        return false;
    }

    renderAdjustmentsStatusFilter = () => {
        const { 
            adjustmentsFilters,
            adjustmentsIsLoading,
        } = this.state;
        const filters = [
            {label: 'Needs Approval', value: 'needs_approval'},
            {label: 'Pending', value: 'pending'},
            {label: 'Cancelled', value: 'cancelled'},
            {label: 'Invoiced', value: 'invoiced'},
            {label: 'Paid', value: 'paid'},
        ];

        return (
            filters.map((x, i) => {
                return (
                    <div key={i} className="filters-wrap">
                        <InputField 
                            type="checkbox" 
                            value={adjustmentsFilters[x.value]} 
                            cbChange={() => this.handleAdjustmentsFilters(x.value)}
                            disabled={adjustmentsIsLoading}
                        >
                            {x.label}
                        </InputField>
                    </div>
                )
            })
        )
    }
    
    render() {
        const {
            modal,
            errMsg,
            orgList,
            columns,
            orgValue,
            isLoading,
            validations,
            propertyList,
            propertyValue,
            adjustmentsData,
            completedPayoutsIsLoading,
            completedPayoutsDates,
            completedPayoutsStatements,
            completedPayoutsfocusedInput,
            getPropertiesIsLoading,
            adjustmentsIsLoading,
            adjustments,
            adjustmentsActivePage,
            adjustmentsPageSize,
            adjustmentsSorted,
            adjustmentsCount,
            entriesOptionsValue,
            CSVAdjustments,
            initPayoutsIsLoading,
            upcomingPayoutsIsLoading,
            upcomingPayoutsStatements,
            upcomingPayoutsDates,
            upcomingPayoutsFocusedInput,
            currentPayout,
            currentPayoutStatement,
            payoutsColumns,
            currentPayoutTotalAmount,
            currentAdjustments,
            adjustmentsModalIsLoading,
            adjustmentErrMsg
        } = this.state;

        let currencyOptions = [];
		for (let i = 0; i < this.CURRENCY.length; i++) {
			currencyOptions.push(<option key={i} value={this.CURRENCY[i].value}>{this.CURRENCY[i].value}</option>);
		};

        let categoryHostReceivesOptions = [<option key={0} value={''}></option>];
        for (let i = 0; i < this.CATEGORIES_HOST_RECEIVES.length; i++) {
			categoryHostReceivesOptions.push(<option key={i + 1} value={this.CATEGORIES_HOST_RECEIVES[i].value}>{this.CATEGORIES_HOST_RECEIVES[i].label}</option>);
		};

        let categoryHostPaysOptions = [<option key={0} value={''}></option>];
        for (let i = 0; i < this.CATEGORIES_HOST_PAYS.length; i++) {
			categoryHostPaysOptions.push(<option key={i + 1} value={this.CATEGORIES_HOST_PAYS[i].value}>{this.CATEGORIES_HOST_PAYS[i].label}</option>);
		};

        let amountValidationsText = '';
        if(validations.amount && validations.entries) {
            amountValidationsText = 'entry and value is required';
        }
        else if(validations.entries) {
            amountValidationsText = 'entry is required';
        }
        else if(validations.amount) {
            amountValidationsText = 'value is required';
        };

        let adjustmentsModalIcon = '';
        switch(_.get(currentAdjustments, 'type')) {
            case 'approve':
                adjustmentsModalIcon = 'icon-thumbs_up';
                break;
            case 'deny':
                adjustmentsModalIcon = 'icon-wrong';
                break;
            case 'cancel':
                adjustmentsModalIcon = 'icon-cancel';
                break;
        }

        return (
            <Col md={12} sm={12} className={`adjustments-payouts ${this.props.dashboardTabs != 'earnings' ? 'hide-view' : ''}`}>
                <Col md={12} sm={12} className="custom-container">
                    {
                        (this.account_manager_admin || this.guest_services || this.props.isInternalPropertyAdmin) &&
                        <Row>
                            <Col md={12} sm={12} className='top-margin'>
                                <h2>Adjustments</h2>
                            </Col>
                            <Col md={12} sm={12}>
                                <div className="adjustments-payouts-container">
                                    {
                                        (adjustmentsIsLoading || isLoading) && <Loader/>
                                    }
                                    <Col md={4} sm={12}>
                                        <InputLabel
                                            className={validations.amount || validations.entries ? 'form-validation' : ''}
                                        >
                                            Amount {amountValidationsText}
                                        </InputLabel>
                                        <div className='adjustments-amount'>
                                            <Picky
                                                placeholder="Select Options"
                                                className={`adjustments-amount__entries ${validations.entries ? 'form-validation' : ''}`}
                                                labelKey="label"
                                                valueKey="custom_value"
                                                options={this.ENTRIES_OPTIONS}
                                                value={entriesOptionsValue}
                                                multiple={false}
                                                includeSelectAll={false}
                                                includeFilter={false}
                                                onChange={val => this.handlePickyEntriesOnChange(val)}
                                                dropdownHeight={600} 
                                                filterDebounce={100}
                                                keepOpen={false}
                                                render={({
                                                    style,
                                                    item,
                                                    isSelected,
                                                    selectValue,
                                                    labelKey,
                                                    valueKey,
                                                    }) => {
                                                        const customIsSelected = entriesOptionsValue == item.custom_value;
                                                        return (
                                                            <li
                                                                style={{ ...style }} 
                                                                className={customIsSelected ? "selected" : ""} 
                                                                key={item.custom_value}
                                                                onClick={() => selectValue(item)}
                                                            >
                                                                <span
                                                                    style={{fontWeight: customIsSelected ? "bold" : "normal"}}
                                                                >
                                                                    <span>{item[labelKey]}</span>
                                                                </span>
                                                            </li>
                                                        );
                                                    }}
                                            />
                                            <InputField 
                                                placeholder='$'
                                                type="number" 
                                                name="amount"
                                                className={validations.amount ? 'form-validation' : ''}
                                                value={adjustmentsData.amount || ''} 
                                                onChange={e => this.handleOnChange(e, 'adjustmentsData')}
                                            >
                                            </InputField>
                                            <InputField 
                                                type="select" 
                                                name="currency"
                                                className='adjustments-amount__currency'
                                                value={adjustmentsData.currency || ''} 
                                                onChange={e => this.handleOnChange(e, 'adjustmentsData')}
                                            >
                                                {currencyOptions}
                                            </InputField>
                                        </div>
                                    </Col>
                                    <Col md={4} sm={12}>
                                        <InputLabel
                                            className={validations.category ? 'form-validation' : ''}
                                        >
                                            Category {validations.category && 'is required'}
                                        </InputLabel>
                                        <InputField 
                                            type="select" 
                                            name="category"
                                            className={validations.category ? 'form-validation' : ''}
                                            value={adjustmentsData.category || ''} 
                                            onChange={e => this.handleOnChange(e, 'adjustmentsData')}
                                        >
                                            {
                                                adjustmentsData.entries == 'charge' ? 
                                                categoryHostReceivesOptions : 
                                                categoryHostPaysOptions
                                            }
                                        </InputField>
                                    </Col>
                                    <Col md={4} sm={12}>
                                        <InputLabel
                                            className={validations.description ? 'form-validation' : ''}
                                        >
                                            Description {validations.description && 'is required'}
                                        </InputLabel>
                                        <InputField 
                                            type="text" 
                                            name="description"
                                            className={validations.description ? 'form-validation' : ''}
                                            value={adjustmentsData.description || ''} 
                                            onChange={e => this.handleOnChange(e, 'adjustmentsData')}
                                        >
                                        </InputField>
                                    </Col>
                                    <Col md={4} sm={12}>
                                        <InputLabel>
                                            Organization
                                        </InputLabel>
                                        <div className='input-loader-container'>
                                            {
                                                getPropertiesIsLoading && <Loader/>
                                            }
                                            <Picky
                                                placeholder={"Organization Search"}
                                                labelKey="custom_label"
                                                valueKey="custom_value"
                                                options={orgList}
                                                value={orgValue}
                                                multiple={false}
                                                includeSelectAll={false}
                                                includeFilter={true}
                                                onChange={val => this.handlePickyOnChange(val, 'organization', 'org')}
                                                dropdownHeight={600} 
                                                filterDebounce={100}
                                                keepOpen={false}
                                                render={({
                                                    style,
                                                    item,
                                                    isSelected,
                                                    selectValue,
                                                    labelKey,
                                                    valueKey,
                                                    }) => {
                                                        return (
                                                            <li
                                                                style={{ ...style }} 
                                                                className={isSelected ? "selected" : ""} 
                                                                key={item.custom_value}
                                                                onClick={() => selectValue(item)}
                                                            >
                                                                <span
                                                                    style={{fontWeight: isSelected ? "bold" : "normal"}}
                                                                >
                                                                    {<span>{item[labelKey]}</span>}
                                                                </span>
                                                            </li>
                                                        );
                                                    }}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={4} sm={12}>
                                        <InputLabel>
                                            Property
                                        </InputLabel>
                                        <div className='input-loader-container'>
                                            {
                                                getPropertiesIsLoading && <Loader/>
                                            }
                                            <Picky
                                                placeholder={"Property Search"}
                                                labelKey="custom_label"
                                                valueKey="custom_value"
                                                options={propertyList}
                                                value={propertyValue}
                                                multiple={false}
                                                includeSelectAll={false}
                                                includeFilter={true}
                                                onChange={val => this.handlePickyOnChange(val, 'property_id', 'property')}
                                                dropdownHeight={600} 
                                                filterDebounce={100}
                                                keepOpen={false}
                                                render={({
                                                    style,
                                                    item,
                                                    isSelected,
                                                    selectValue,
                                                    labelKey,
                                                    valueKey,
                                                    }) => {
                                                        return (
                                                            <li
                                                                style={{ ...style }} 
                                                                className={isSelected ? "selected" : ""} 
                                                                key={item.custom_value}
                                                                onClick={() => selectValue(item)}
                                                            >
                                                                <span
                                                                    style={{fontWeight: isSelected ? "bold" : "normal"}}
                                                                >
                                                                    {<span>{item[labelKey]}</span>}
                                                                </span>
                                                            </li>
                                                        );
                                                    }}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={4} sm={12}>
                                        <InputLabel>
                                            Reservation
                                        </InputLabel>
                                        <InputField 
                                            type="text" 
                                            name="reservation_id"
                                            value={adjustmentsData.reservation_id || ''} 
                                            onChange={e => this.handleOnChange(e, 'adjustmentsData')}
                                        >
                                        </InputField>
                                    </Col>
                                    <Col md={12} sm={12} xs={12} className="adjustments-actions">
                                        <PrimaryButton
                                            cssClass="white-btn "
                                            fullWidth={false} 
                                            type="button"
                                            onClick={this.resetData}
                                            disabled={adjustmentsIsLoading}
                                        >
                                            Cancel
                                        </PrimaryButton>
                                        <PrimaryButton
                                            cssClass="left-margin"
                                            fullWidth={false} 
                                            type="button"
                                            onClick={this.handleSubmitPrompt}
                                            disabled={adjustmentsIsLoading}
                                        >
                                            Add
                                        </PrimaryButton>
                                    </Col>
                                    <Col md={12} sm={12} xs={12} className='adjustments-filters'>
                                        {this.renderAdjustmentsStatusFilter()}
                                        <div className='adjustments-csv'>
                                            <OverlayTrigger 
                                                placement='top' 
                                                overlay={this.getTooltip('csv-download', 'Download CSV')} 
                                            >
                                                <span 
                                                    className='flex-align-center'
                                                    onClick={!adjustmentsIsLoading ? this.handleAdjustmentsCSV : null}
                                                >
                                                    CSV &nbsp; <i className='icon-folder-download'></i>
                                                </span>
                                            </OverlayTrigger>
                                        </div>
                                        <CSVLink
                                            asyncOnClick={true}
                                            headers={this.CSVHeaders}
                                            data={CSVAdjustments}
                                            filename={`${this.props.org}-adjustments.csv`}
                                            target="_blank"
                                            id="download-csv"
                                        >
                                        </CSVLink>
                                    </Col>
                                    <Col md={12} sm={12} className='bottom-margin display-inline-block adjustments-table'>
                                        <ReactTableComponent
                                            manual
                                            apiBase={true}
                                            getRef={(r) => this.getRef(r, 'adjustments')}
                                            reactTable={this.adjustments}
                                            className='-highlight'
                                            noDataText="No adjustments found."
                                            minRows={0}
                                            data={adjustments}
                                            resizable={false}
                                            activePage={adjustmentsActivePage}
                                            columns={columns}
                                            showPagination={true}
                                            pageSize={parseInt(adjustmentsPageSize)}
                                            totalCount={parseInt(adjustmentsCount)}
                                            pageOnChange={this.adjustmentsPageOnChange}
                                            pageSizeOnChange={this.adjustmentsPageSizeOnChange}
                                            onSortedChange={this.adjustmentsOnSortedChange}
                                            defaultSorted={[adjustmentsSorted]}
                                        />
                                    </Col>
                                </div>
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col md={12} sm={12} className='payouts-actions'>
                            <div className='payouts-title'>
                                <h2>Completed Payouts</h2>
                            </div>
                            <div className='flex-align-center'>
                                <div className='payouts-csv'>
                                    <OverlayTrigger 
                                        placement='top' 
                                        overlay={this.getTooltip('csv-download', 'Download CSV')} 
                                    >
                                        <span 
                                            className='flex-align-center' 
                                            onClick={!initPayoutsIsLoading ? (e) => this.getPayoutsStatementCSV(e) : null}
                                        >
                                            CSV &nbsp; <i className='icon-folder-download'></i>
                                        </span>
                                    </OverlayTrigger>
                                </div>
                                <div>
                                    <DateRangePicker
                                        noBorder
                                        showClearDates={true}
                                        minimumNights={0}
                                        startDateId='start'
                                        endDateId='end'
                                        isOutsideRange={this.isOutsideRange}
                                        startDatePlaceholderText="MM/DD/YYY"
                                        endDatePlaceholderText="MM/DD/YYY"
                                        startDate={completedPayoutsDates.startDate}
                                        endDate={completedPayoutsDates.endDate}
                                        focusedInput={completedPayoutsfocusedInput}
                                        onFocusChange={completedPayoutsfocusedInput => this.setState({ completedPayoutsfocusedInput })}
                                        onDatesChange={(date) =>this.payoutsHandleDatesOnChange(date, 'completed')}
                                        disabled={initPayoutsIsLoading || completedPayoutsIsLoading}
                                    />
                                </div>
                            </div>
                        </Col>
                        {
                            <Col md={12} sm={12}>
                                <div className="adjustments-payouts-container">
                                    {(completedPayoutsIsLoading || initPayoutsIsLoading) && <Loader/>}
                                    {
                                        !initPayoutsIsLoading &&
                                        completedPayoutsStatements.length ? 
                                        this.renderPayoutsPanel('completed') :
                                        <div className='payouts-no-data'>
                                            No data Found.
                                        </div>
                                    }
                                </div>
                            </Col>
                        }
                    </Row>
                    <Row>
                        <Col md={12} sm={12} className='payouts-actions'>
                            <div className='payouts-title'>
                                <h2>Upcoming Payouts</h2>
                            </div>
                            <div className='flex-align-center'>
                                <div>
                                    <DateRangePicker
                                        noBorder
                                        showClearDates={true}
                                        minimumNights={0}
                                        startDateId='start'
                                        endDateId='end'
                                        isOutsideRange={this.isOutsideRange}
                                        startDatePlaceholderText="MM/DD/YYY"
                                        endDatePlaceholderText="MM/DD/YYY"
                                        startDate={upcomingPayoutsDates.startDate}
                                        endDate={upcomingPayoutsDates.endDate}
                                        focusedInput={upcomingPayoutsFocusedInput}
                                        onFocusChange={upcomingPayoutsFocusedInput => this.setState({ upcomingPayoutsFocusedInput })}
                                        onDatesChange={(date) =>this.payoutsHandleDatesOnChange(date, 'upcoming')}
                                        disabled={initPayoutsIsLoading || upcomingPayoutsIsLoading}
                                    />
                                </div>
                            </div>
                        </Col>
                        {
                            <Col md={12} sm={12}>
                                <div className="adjustments-payouts-container">
                                    {(upcomingPayoutsIsLoading || initPayoutsIsLoading) && <Loader/>}
                                    {
                                        !initPayoutsIsLoading &&
                                        upcomingPayoutsStatements.length ? 
                                        this.renderPayoutsPanel('upcoming') :
                                        <div className='payouts-no-data'>
                                            No data Found.
                                        </div>
                                    }
                                </div>
                            </Col>
                        }
                    </Row>
                </Col>              
                <Modal
                    className="custom-modal"
                    show={modal === "custom-modal"} 
                    onHide={this.closeModal}
                >
                    <Modal.Header closeButton>
                        <Col md={12} className={errMsg ? `custom-error-msg-container`: ''}>
                            <div>{errMsg}</div>
                        </Col>
                    </Modal.Header>
                </Modal>
                <Modal
                    className="adjustments-payouts-modal"
                    show={modal === "adjustments-payouts-modal"} 
                    onHide={this.closeModal}
                >
                    <Modal.Header closeButton>
                        <h1>{_.get(currentPayout, 'organization_name')}</h1>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={12}>
                                <PanelGroup
                                    accordion
                                    id='adjustments-payouts-modal-panel'
                                    activeKey={1}
                                    onSelect={() => null}
                                >
                                    <Panel eventKey={1}>
                                        <Panel.Heading>
                                            <Panel.Title
                                                toggle
                                            >
                                                <div className='panel-payouts-title'>
                                                    <div className='panel-payouts-left'>
                                                        <div className='panel-date payouts-right-margin'>
                                                            <strong>{moment(_.get(currentPayout, 'posted')).format("MM/DD/YY hh:mm A z")}</strong>
                                                        </div>
                                                    </div>
                                                    <div className='panel-pdf'>
                                                        <OverlayTrigger 
                                                            placement='top' 
                                                            overlay={this.getTooltip('download-pdf', 'Download PDF')} 
                                                        >
                                                            <span 
                                                                className='flex-align-center' 
                                                                onClick={(e) => this.getPayoutsStatementPDF(e, _.get(currentPayout, 'id'))}
                                                            >
                                                                PDF &nbsp; <i className='icon-folder-download'></i>
                                                            </span>
                                                        </OverlayTrigger>
                                                    </div>
                                                    <div className='panel-csv'>
                                                        <OverlayTrigger 
                                                            placement='top' 
                                                            overlay={this.getTooltip('csv-download', 'Download CSV')} 
                                                        >
                                                            <span 
                                                                className='flex-align-center' 
                                                                onClick={(e) => this.getPayoutsStatementCSV(e, currentPayout)}
                                                            >
                                                                CSV &nbsp; <i className='icon-folder-download'></i>
                                                            </span>
                                                        </OverlayTrigger>
                                                    </div>
                                                    <div className='panel-amount'>
                                                        {
                                                            currentPayoutTotalAmount &&
                                                            <div className={Math.sign(currentPayoutTotalAmount) == -1 ? "negative-amount" : ""}>
                                                                <strong>{currentPayoutTotalAmount}</strong>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </Panel.Title>
                                        </Panel.Heading>
                                        <Panel.Body collapsible>
                                            <ReactTableComponent
                                                manual
                                                className='-highlight'
                                                noDataText="No payouts found."
                                                minRows={0}
                                                data={currentPayoutStatement}
                                                resizable={false}
                                                activePage={1}
                                                columns={payoutsColumns}
                                                showPagination={false}
                                                totalCount={0}
                                            />
                                        </Panel.Body>
                                    </Panel>
                                </PanelGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
                <Modal 
                    show={modal == 'prompt-modal'} 
                    onHide={this.closeModal}
                    backdrop='static'
                >
                    <Modal.Header>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="text-center bottom-margin">
                            <Col xs={12}>
                                <h2>
                                    {adjustmentsData.entries == 'payment' ? 'Debit [-]' : 'Credit [+]'}{" "}
                                    {Constants.CURRENCY[adjustmentsData.currency].toUpperCase()}{adjustmentsData.amount} from account?
                                </h2>
                                <h2>Are you sure?</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <PrimaryButton
                                    cssClass="pull-right"
                                    fullWidth={false} 
                                    type="button"
                                    onClick={this.submitAdjustments}
                                    disabled={adjustmentsIsLoading}
                                >
                                    Yes
                                </PrimaryButton>
                                <PrimaryButton
                                    cssClass='white-btn pull-right right-margin'
                                    fullWidth={false} 
                                    type="button"
                                    onClick={this.closeModal}
                                >
                                    Cancel
                                </PrimaryButton>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
                <Modal 
                    show={modal == 'adjustments-prompt-modal'} 
                    onHide={this.closeModal}
                    backdrop='static'
                    className='adjustments-prompt-modal'
                >
                    {
                        adjustmentsModalIsLoading &&
                        <Loader/>
                    }
                    <Modal.Body>
                        <Row>
                            <Col xs={12} className="flex-align-center">
                                <h2 className='flex-align-items-center'>
                                    <span><i className={adjustmentsModalIcon}></i></span>
                                    <span className='capitalize right-margin-5'>{_.get(currentAdjustments, 'type', '')}</span>
                                    "<strong className='capitalize right-margin-5'>{_.get(currentAdjustments, 'data.category')}</strong>
                                    <span className={Math.sign(_.get(currentAdjustments, 'data.amount')) == -1 ? "negative-amount" : ""}>
                                        <strong>{Constants.CURRENCY[_.get(currentAdjustments, 'data.currency', '').toUpperCase()]} {Constants.NUM_FORMAT(Math.abs(_.get(currentAdjustments, 'data.amount')))}</strong>
                                    </span>
                                    "?
                                </h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <PrimaryButton
                                    cssClass="pull-right"
                                    fullWidth={false} 
                                    type="button"
                                    onClick={this.approveOrCancelAdjustment}
                                    disabled={adjustmentsIsLoading}
                                >
                                    <span className='capitalize'>{_.get(currentAdjustments, 'type', '')}</span>
                                </PrimaryButton>
                                <PrimaryButton
                                    cssClass='white-btn pull-right right-margin'
                                    fullWidth={false} 
                                    type="button"
                                    onClick={this.closeModal}
                                >
                                    Cancel
                                </PrimaryButton>
                            </Col>
                            {
                                adjustmentErrMsg &&
                                <Col md={12}>
                                    <div className='custom-error-msg-container top-margin'>{adjustmentErrMsg}</div>
                                </Col>
                            }
                        </Row>
                    </Modal.Body>
                </Modal>
            </Col>
        )
    }
}

const mapStateToProps = state => {
    return {
        permissions: state.authState.permissions,
        user: state.authState.user,
        roleManager: state.roleManager,
        org: state.roleManager.org,
        orgTitle: state.roleManager.orgTitle,
        isInternalPropertyAdmin: state.roleManager.isInternalPropertyAdmin,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setRole: (role) => {
            dispatch(setRole(role));
        },
        updateOrganization: (org) => {
            dispatch(updateOrganization(org))
        },
        updateOrganizationTitle: (org) => {
            dispatch(updateOrganizationTitle(org))
        },
        updateOrganizationId: (orgId) => {
            dispatch(updateOrganizationId(orgId))
        },
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(AdjustmentsPayouts));