import { v4 as uuidv4 } from "uuid";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import Form from "react-bootstrap/lib/Form";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import PanelGroup from "react-bootstrap/lib/PanelGroup";
import Panel from "react-bootstrap/lib/Panel";
import Glyphicon from "react-bootstrap/lib/Glyphicon";
import Modal from "react-bootstrap/lib/Modal";
import Dropzone from "react-dropzone";
import { API, Storage } from "aws-amplify";
import SketchPicker from "react-color/lib/components/sketch/Sketch";
import OverlayTrigger from "react-bootstrap/lib/OverlayTrigger";
import Tooltip from "react-bootstrap/lib/Tooltip";
import { Tabs, Tab } from "react-bootstrap";
import _ from "lodash";

import { Title } from "../global/Title";
import Loader from "../global/Loader";
import constants from "../../js/constants";
import { Editor } from "@tinymce/tinymce-react";
import { delay } from "../../js/actions";
import { infoPopover } from "../../helpers/helpers";

// *** Make sure all calls for <Editor are wrapped in conditional logic
// *** and use handleAddEditorInitialized(value) to handle when we want to show the editors
import tinyMCEConfig from "!!raw-loader!../../styles/tinyMCEConfig.css";

import {
	FieldWrap,
	InputLabel,
	InputField,
	PrimaryButton
} from "../global/forms/FormElements";
import WebsiteSubmitModal from "../content/properties/WebsiteSubmitModal";
import CustomDomainInstructionModal from "../views/CustomDomainInstructionModal";
import ToggleButtonGroup from "react-bootstrap/lib/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/lib/ToggleButton";

const { STATUS_CHECK_MAX } = constants;

const instructions = {
	"website-title":
		"Your company name and/or title to display in the browser tab",
	"show-map": "Show a map and pin for each property",
	"show-filters":
		"Display a search bar so users can search by date and use filters",
	"show-chat": "Add live chat with a RedAwning representative",
	"include-child-orgs":
		"Display properties in sub-accounts; primarily for property managers",
	"accept-promo-codes":
		"Allow discount codes & configured promotions during check-out",
	"show-pagination":
		"Show a specified number of properties per page, rather than all properties",
	"smartsite-template":
		"The default template is the most common, whereas the spotlight template is typically used when there is one property to display",
	"layout-boundary-settings":
		"The default width is optimized for most header background images, whereas the full width setting stretches a high quality header image accross the full webpage width",
	"layout-alignment":
		"Center align the header text if selected, or left align by default",
	"hero-style":
		"To ensure text is visible over the header image, selecting a light or dark filter below is recommended",
	"hero-background-position":
		"Set which part of the header image is the most important and should remain in focus on smaller screens",
	"hero-height":
		"The amount of vertical space reserved for the header image, page title and description; smaller settings focus on the content below, whereas larger settings can detract from page content",
	"homepage-hero-height":
		"The amount of vertical space reserved for the header image, title and description, on the homepage",
	"favicon-image":
		"The 'favicon' is the square image shown on a webpage tab. For best results upload a square image ideally 32x32 pixels in size",
	"logo-image":
		"This is the image shown on the top left of your webpage.  Upload your current logo, but note we'll resize so the height is less than 100 pixels, and the width is less than 350 pixels",
	"hero-image":
		"Add a dramatic and high quality background image to capture and intrigue your vistors.  Note that your page title and description will be displayed over the image, so scenery and landscape images are recommended",
	"homepage-show-searchbar":
		"Display the property search and filter feature above properties on the homepage",
	"homepage-show-hero-searchbar":
		"Display a property search feature in the homepage header after the page title and description",
	"show-listings":
		"Display your properties on this page, after the hero and page content.  Show all of your properties, or add optional filters to display listings in a sepecific city, or ones that allow pets, for example",
	"listings-label":
		"Overwrite the 'Properties' label by specifying the type of units you manage here, for instance: Rooms, Condos, etc.",
	"embedded-website":
		"Advanced setting. Only use if embedding this website using an iframe or an approved plugin",
	"root-path":
		"Advanced setting. All links will have the specifed root path when proxy rules have been established to route traffic by path to this domain",
	"site-home-link":
		"Advanced setting. When linking from an primary website to book properties a URL can be provided to return to the referring website",
	"head-html": "Advanced. Add CSS or javascript within the <head> tag.",
	"footer-html":
		"Advanced. Add CSS or javascript within the  right before the </body> end tag."
};

/** Mange website page. */
class WebsiteComponent extends React.Component {
	constructor(props) {
		super(props);
		this.breadcrumbItems = [
			{
				title: "Manage Website"
			}
		];
		this.customDomainPendingInfo =
			"Verification is in process. This can take anywhere from a few minutes to a day.";
		this.customDomainFailedInfo =
			"Verification failed. Please contact customer support.";
		this.isInternalAdmin = props.roleManager.isInternalPropertyAdmin;
		this.countries = [];
		this.cityOptions = [];
		this.provinceOptions = [];
		this.bedsOptions = [];
		this.sleepsMaxOptions = [];
		this.countyOptions = [];
		this.org = props.roleManager.org;
		this.initCustomDomain = "";
		this.initCustomDomainStatus = "";
		this.primaryColorPickerRef = React.createRef();
		this.secondaryColorPickerRef = React.createRef();
		this.textColorPickerRef = React.createRef();
		this.predefinedImageSelectOptions = [];
		this.buildStatusLexicon = {
			new: "Queued...",
			provisioning: "Creating Website...",
			checking: "Checking...",
			complete: "",
			error: "There Was An Issue Processing The Request.",
			initializing: "Updating...",
			building: "Building...",
			deploying: "Deploying...",
			invalidate_cache: "Wrapping Up!!!"
		};
		this.state = {
			data: this.getInitialWebsiteData(props),
			editorsInitialized: [],
			isLoading: true,
			buildStatus: "checking",
			isSending: false,
			completedTask: 0,
			taskCount: 1,
			websiteFetchError: false,
			activeKey: 1,
			modalReset: false,
			submitStatus: "",
			domainStatus: "",
			modal: "",
			error: {
				status: false,
				msg: ""
			},
			fileName: {
				favicon_image: {
					name: null,
					dimensions: ""
				},
				logo_image: {
					name: null,
					dimensions: ""
				},
				hero_image: {
					name: null,
					dimensions: ""
				}
			},
			domainPath: "",
			customDomain: "",
			errorsModal: {
				show: false,
				errorList: []
			},
			primary_color: "",
			primary_color_tint: "",
			secondary_color: "",
			secondary_color_tint: "",
			text_color: "",
			primaryColorPicker: false,
			textColorPicker: false,
			validations: {
				customDomain: false
			},
			tabsActiveKey: 1,
			tabsSubActiveKey: 1
		};
		constants.PROPERTY_VIEW.COUNTRIES.map(country => {
			this.countries.push(
				<option
					key={country.Code}
					data-value={country.Name}
					value={country.Code}
				>
					{country.Name}
				</option>
			);
		});
	}

	async updateConfigFacetCounts() {
		const { data } = this.state;

		function getEveryOtherItem(arr) {
			return arr.filter((_, index) => index % 2 === 0);
		}

		// if you want a list of cities (as an example), add this to the wapi call:
		// facet=true&facet.field=sm_locs_city&facet.mincount=1&rows=0

		// if you want multiple fields, just add more facet.field items to the url:
		// ex: &facet.field=sm_locs_city&facet.field=sm_locs_province

		let baseUrl = `/search/organizations/${this.props.roleManager.org}?query=`;
		let searchParam = "q=*:*";
		searchParam +=
			"&facet=true&facet.field=sm_locs_city&facet.field=fs_bedrooms&facet.field=is_field_sleeps_max&facet.mincount=1&rows=0";
		searchParam = decodeURIComponent(searchParam);
		searchParam = encodeURIComponent(searchParam);
		searchParam += data.include_child_orgs ? "&include_child_orgs=true" : "";

		try {
			let resp = await API.get("wapi", baseUrl + searchParam);
			if (resp && resp.facet_counts && resp.facet_counts.facet_fields) {
				const facet_fields = resp.facet_counts.facet_fields;
				const {
					sm_locs_city,
					sm_locs_province,
					sm_locs_county,
					fs_bedrooms,
					is_field_sleeps_max
				} = facet_fields;

				if (sm_locs_city && sm_locs_city.length) {
					const locationCityList = getEveryOtherItem(sm_locs_city).sort();
					this.cityOptions.push(
						<option key={`city-0`} data-value="" value="">
							Location
						</option>
					);
					locationCityList.map((item, i) => {
						this.cityOptions.push(
							<option
								key={`${item.toLowerCase().replace(/[^a-zA-Z0-9]/g, "")}-${i +
									1}`}
								data-value={item}
								value={item}
							>
								{item}
							</option>
						);
					});
				}

				if (sm_locs_province && sm_locs_province.length) {
					const locationProvinceList = getEveryOtherItem(
						sm_locs_province
					).sort();
					this.provinceOptions.push(
						<option key={`province-0`} data-value="" value="">
							----
						</option>
					);
					locationProvinceList.map((item, i) => {
						this.provinceOptions.push(
							<option
								key={`${item.toLowerCase().replace(/[^a-zA-Z0-9]/g, "")}-${i +
									1}`}
								data-value={item}
								value={item}
							>
								{item}
							</option>
						);
					});
				}

				if (sm_locs_county && sm_locs_county.length) {
					const locationCountyList = getEveryOtherItem(sm_locs_county).sort();
					this.countyOptions.push(
						<option key={`county-0`} data-value="" value="">
							----
						</option>
					);
					locationCountyList.map((item, i) => {
						this.countyOptions.push(
							<option
								key={`${item.toLowerCase().replace(/[^a-zA-Z0-9]/g, "")}-${i +
									1}`}
								data-value={item}
								value={item}
							>
								{item}
							</option>
						);
					});
				}

				if (fs_bedrooms && fs_bedrooms.length) {
					const locationBedroomsList = getEveryOtherItem(fs_bedrooms).sort();
					this.bedsOptions.push(
						<option key={`beds-0`} data-value="" value="">
							Bedrooms
						</option>
					);
					locationBedroomsList.map((item, i) => {
						const itemToNumber = Number(item);
						const number = String(
							Number.isInteger(itemToNumber)
								? Math.round(itemToNumber)
								: itemToNumber
						);
						this.bedsOptions.push(
							<option
								key={`${number.toLowerCase().replace(/[^a-zA-Z0-9]/g, "")}-${i +
									1}`}
								data-value={number}
								value={number}
							>
								{number}+
							</option>
						);
					});
				}

				if (is_field_sleeps_max && is_field_sleeps_max.length) {
					const locationSleemsMaxList = getEveryOtherItem(
						is_field_sleeps_max
					).sort();
					this.sleepsMaxOptions.push(
						<option key={`sleeps-0`} data-value="" value="">
							----
						</option>
					);
					locationSleemsMaxList.map((item, i) => {
						const itemToNumber = Number(item);
						const number = String(
							Number.isInteger(itemToNumber)
								? Math.round(itemToNumber)
								: itemToNumber
						);
						this.sleepsMaxOptions.push(
							<option
								key={`${number.toLowerCase().replace(/[^a-zA-Z0-9]/g, "")}-${i +
									1}`}
								data-value={number}
								value={number}
							>
								{number}
							</option>
						);
					});
				}
			}
		} catch (e) {
			console.log(e);
		}
	}

	getInitialWebsiteData() {
		return {
			id: null,
			organization: "",
			title: "",
			description: "",
			relative_root_path: "",
			site_home_link: "",
			petsOkCount: 0,
			petsNotOkCount: 0,
			locationCityList: [],
			locationProvinceList: [],
			locationCountyList: [],
			locationBedroomsList: [],
			locationSleemsMaxList: [],
			homepage: {
				title: "",
				sub_title: "",
				page_content_top: "",
				page_content_bottom: "",
				page_content_hero_image: "",
				search_bar_in_hero: false,
				show_properties: true,
				property_filter_keywords: "",
				property_filter_city: "",
				property_filter_pets_ok: false,
				property_filter_beds: -1
			},
			aboutUs: "",
			template_id: "template-1",
			layout_boundary_settings: "default",
			include_child_orgs: "",
			logo_image: {
				url: "",
				tags: []
			},
			favicon_image: {
				url: "",
				tags: []
			},
			primary_color: "#274abb",
			primary_color_tint: this.calculateTint("#274abb", 0.5),
			secondary_color: "#666666",
			secondary_color_tint: this.calculateTint("#666666", 0.5),
			text_color: "#fff",
			hero_image: {
				url: "",
				tags: []
			},
			copyright_line:
				"REDAWNING.COM, INC. OR ITS AFFILIATES. ALL RIGHTS RESERVED",
			contact_info: {
				first_name: "",
				last_name: "",
				street_address: "",
				street_address2: "",
				city: "",
				province: "",
				postal_code: "",
				country: "US",
				mobile_phone: "",
				home_phone: "",
				work_phone: "",
				email: ""
			},
			facebook_link: "",
			twitter_link: "",
			instagram_link: "",
			youtube_link: "",
			google_key: "",
			origin_domain: "",
			default_domain: "",
			default_listing_type: "",
			domains: [],
			seo_description: "",
			seo_keywords: [],
			embed: false,
			show_filters: true,
			show_pagination: true,
			accept_promo_codes: false,
			search_bar_in_hero: false,
			show_chat: false,
			show_map: false,
			show_nearby_properties: false,
			custom_pages: [
				{
					page: 1,
					title: "",
					sub_title: "",
					page_content_top: "",
					page_content_bottom: "",
					show_properties: false,
					property_filter_keywords: "",
					property_filter_city: "",
					property_filter_pets_ok: false,
					property_filter_beds: -1,
					parent_page: "",
					page_content_hero_image: ""
				}
			],
			layout: {
				container_classnames: "flex items-center md:mx-24",
				content_classnames: ""
			},
			layout_alignment: "",
			hero_style: "",
			hero_background_position: ""
		};
	}

	resizeImageHeight = (originalWidth, originalHeight, maxWidth) => {
		const aspectRatio = originalWidth / originalHeight;
		const newWidth = Math.min(maxWidth, originalWidth);
		const newHeight = newWidth / aspectRatio;

		return {
			width: newWidth,
			height: Math.round(newHeight)
		};
	};

	resizeWithAspectRatio = (
		originalWidth,
		originalHeight,
		newWidth = null,
		newHeight = null
	) => {
		// Check if at least one new dimension is provided
		if (newWidth === null && newHeight === null) {
			throw new Error("Either newWidth or newHeight must be provided");
		}

		// Calculate the aspect ratio
		const aspectRatio = originalWidth / originalHeight;

		let finalWidth, finalHeight;

		if (newWidth !== null) {
			// If new width is provided, calculate the height
			finalWidth = newWidth;
			finalHeight = Math.round(newWidth / aspectRatio);
		} else {
			// If new height is provided, calculate the width
			finalHeight = newHeight;
			finalWidth = Math.round(newHeight * aspectRatio);
		}

		return this.resizeImageHeight(finalWidth, finalHeight, 1536);
	};

	returnEditorImageConfig = (editor, params = {}) => {
		let originalImageConfig = { width: 1, height: 1 };
		return {
			icon: "image",
			text: "Insert Image",
			onAction: () => {
				editor.windowManager.open({
					title: "Insert Image",
					onSubmit: async api => {
						this.setState({
							isLoading: true
						});
						api.disable("submit", true);

						const imgConfig = api.getData();

						if (_.get(imgConfig, "image.length")) {
							await this.handleCustomImage(imgConfig, api);
						}
						const newImgConfig = api.getData();

						let width = Number(newImgConfig.width);
						let height = Number(newImgConfig.height);

						let newImagePath;
						if (newImgConfig.source.includes("filters:")) {
							// Find the index of "image/"
							const imageIndex = newImgConfig.source.indexOf("image/");

							// Extract the part before "image/"
							const partBeforeImage = newImgConfig.source.substring(
								0,
								imageIndex
							);

							// Find the index of the last occurrence of "filters:quality(40)"
							const lastQualityIndex =
								newImgConfig.source.lastIndexOf("filters:quality(40)") +
								"filters:quality(40)".length;

							// Extract the part after the last "filters:quality(40)"
							const remainingString = newImgConfig.source.substring(
								lastQualityIndex
							);

							// Construct the new string with the replacement
							newImagePath =
								`${partBeforeImage}image/${width}x${height}/filters:format(webp)/filters:quality(40)/` +
								remainingString;
						} else {
							newImagePath = newImgConfig.source.replace(
								"image/",
								`image/${width}x${height}/filters:format(webp)/filters:quality(40)/`
							);
						}

						editor.execCommand(
							"mceInsertContent",
							false,
							`<img src="${newImagePath}" alt="${newImgConfig.title}" width="${width}" height="${height}" />`
						);
						if (
							params &&
							typeof params.i === "number" &&
							params.handleCustomHeroPageImage &&
							newImgConfig.source
						) {
							this.handleCustomHeroPageImage(newImgConfig.source, params.i);
						}
						this.setState({
							isLoading: false
						});
						api.enable("submit", true);
						api.close();
					},
					onChange: (api, details) => {
						let imgConfig = _.cloneDeep(api.getData());
						const maxWidth = 1536;
						if (details.name == "image") {
							if (_.get(imgConfig, "image.length")) {
								const file = imgConfig.image[0];
								if (file) {
									const reader = new FileReader();

									reader.onload = event => {
										const img = new Image();
										img.src = event.target.result;

										img.onload = () => {
											const width = Number(img.naturalWidth);
											const height = Number(img.naturalHeight);
											originalImageConfig.width = width;
											originalImageConfig.height = height;
											const resizedImage = this.resizeWithAspectRatio(
												originalImageConfig.width,
												originalImageConfig.height,
												width,
												height
											);

											api.setData({
												width: String(resizedImage.width)
											});
											api.setData({
												height: String(resizedImage.height)
											});
										};
									};

									reader.readAsDataURL(file);
								}
								api.setData({
									source: imgConfig.image[0].name
								});
								api.setData({
									predefinedImages: ""
								});
							} else {
								api.setData({
									width: ""
								});
								api.setData({
									height: ""
								});
								api.setData({
									source: ""
								});
							}
						} else if (details.name == "width") {
							const width = Number(imgConfig.width);
							// Set correct aspect ratio

							const resizedImage = this.resizeWithAspectRatio(
								originalImageConfig.width,
								originalImageConfig.height,
								width || 1,
								null
							);

							api.setData({
								width: String(resizedImage.width)
							});
							api.setData({
								height: String(resizedImage.height)
							});
						} else if (details.name == "height") {
							const height = Number(imgConfig.height);
							// Set correct aspect ratio

							const resizedImage = this.resizeWithAspectRatio(
								originalImageConfig.width,
								originalImageConfig.height,
								null,
								height || 1
							);

							api.setData({
								width: String(resizedImage.width)
							});
							api.setData({
								height: String(resizedImage.height)
							});
						} else if (details.name == "predefinedImages") {
							if (_.get(imgConfig, "predefinedImages.length")) {
								const parsedData = JSON.parse(imgConfig.predefinedImages);

								if (
									params &&
									typeof params.i === "number" &&
									params.handleCustomHeroPageImage &&
									parsedData.url
								) {
									this.handleCustomHeroPageImage(parsedData.url, params.i);
								}

								api.setData({
									image: []
								});
								api.setData({
									source: parsedData.url
								});
								api.setData({
									title: parsedData.title
								});
								api.setData({
									height: parsedData.height
								});
								api.setData({
									width: parsedData.width
								});
							} else {
								api.setData({
									image: []
								});
								api.setData({
									source: ""
								});
								api.setData({
									title: ""
								});
								api.setData({
									height: ""
								});
								api.setData({
									width: ""
								});
							}
						}
						let updatedImgConfig = _.cloneDeep(api.getData());
						const isSourceValid =
							updatedImgConfig.source &&
							updatedImgConfig.source.trim() !== "" &&
							updatedImgConfig.title &&
							updatedImgConfig.title.trim() !== "";
						const isWidthValid =
							updatedImgConfig.width && updatedImgConfig.width.trim() !== "";
						const isHeightValid =
							updatedImgConfig.height && updatedImgConfig.height.trim() !== "";
						if (isSourceValid && isWidthValid && isHeightValid) {
							api.enable("submit", true);
						} else {
							api.disable("submit", true);
						}
					},
					body: {
						type: "panel",
						items: [
							{
								type: "dropzone",
								name: "image",
								label: "Source"
							},
							{
								type: "selectbox",
								name: "predefinedImages",
								label: "Choose from predefined images",
								items: this.predefinedImageSelectOptions
							},
							{
								type: "input",
								name: "source",
								label: "Source"
							},
							{
								type: "input",
								name: "title",
								label: "Description"
							},
							{
								type: "input",
								name: "width",
								label: "Width"
							},
							{
								type: "input",
								name: "height",
								label: "Height"
							}
						]
					},
					buttons: [
						{
							type: "cancel",
							text: "Cancel"
						},
						{
							type: "submit",
							text: "Submit",
							name: "submit",
							primary: true,
							disabled: true
						}
					]
				});
			}
		};
	};

	returnEditorConfig = (data, params = {}) => {
		let tinyMCEConfigString = JSON.stringify(tinyMCEConfig);
		tinyMCEConfigString = tinyMCEConfigString.replace(/\\n/g, "");
		tinyMCEConfigString = tinyMCEConfigString.replace(/\\t/g, "");
		tinyMCEConfigString = tinyMCEConfigString.replaceAll("}", "} \r");

		const customStyles = `:root {
      --primary-color: ${data.primary_color};
      --secondary-color: ${data.secondary_color};
    }`;

		let editorConfigOptions = {};
		if (params.handleCustomHeroPageImage) {
			editorConfigOptions = {
				height: 40,
				width: 131,
				menubar: false,
				plugins: ["image"],
				toolbar: "imageSelect",
				block_formats: "",
				resize: false,
				statusbar: false,
				image_title: false,
				file_picker_types: "image",
				toolbar_mode: "wrap",
				image_caption: false,
				custom_colors: false
			};
		} else {
			editorConfigOptions = {
				height: 500,
				width: "auto",
				menubar: false,
				plugins: [
					"advlist autolink lists link charmap print preview anchor image",
					"searchreplace visualblocks fullscreen table",
					"insertdatetime media paste code help wordcount editimage"
				],
				paste_as_text: true,
				toolbar:
					"undo redo | formatselect | " +
					"bold italic underline forecolor backcolor link | alignleft aligncenter " +
					"alignright alignjustify | bullist numlist outdent indent | addTag | table | imageSelect | media",
				block_formats:
					"Paragraph=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6;",
				resize: true,
				statusbar: false,
				image_title: true,
				file_picker_types: "image",
				toolbar_mode: "wrap",
				content_style: customStyles + tinyMCEConfigString.slice(1, -1),
				image_caption: true,
				object_resizing: "img",
				image_class_list: [
					{ title: "None", value: "" },
					{ title: "Text Over Image", value: "caption-overlay" },
					{ title: "Rounded Corners", value: "rounded-md" }
				],
				table_class_list: [
					{ title: "None", value: "" },
					{ title: "Rounded Corners", value: "rounded-md" },
					{
						title: "Primary Background",
						value: "rounded-md bg-primary text-white"
					}
				],
				link_class_list: [
					{ title: "None", value: "" },
					{
						title: "Button",
						value:
							"button font-bold py-2 px-4 rounded hover:brightness-125 text-white bg-primary"
					}
				],
				color_map: [
					data.primary_color,
					"Primary Color",
					data.primary_color_tint,
					"Primary Color Tint",
					data.secondary_color,
					"Secondary Color",
					data.secondary_color_tint,
					"Secondary Color Tint",
					"374151",
					"Black",
					"FFFFFF",
					"White"
				],
				custom_colors: false,
				extended_valid_elements: "a[*],table[width=100%|style],tr,td[style]",
				valid_children: "+a[figure]",
				allow_html_in_named_anchor: true,
				table_sizing_mode: "relative", // Ensures percentage-based widths
				table_default_styles: {
					width: "100%", // Sets the table width to 100%
					"table-layout": "fixed" // Ensures equal width for cells
				},
				table_column_resizing: "preservetable" // Maintains table width when columns are resized
			};
		}
		return {
			...editorConfigOptions,
			setup: editor => {
				let debounceTimer;
				const resizeWithAspectRatio = this.resizeWithAspectRatio;

				editor.on("contextmenu", function(event) {
					let node = editor.selection.getNode();
					if (
						node.nodeName === "IMG" ||
						node.parentNode.parentNode.nodeName === "FIGCAPTION"
					) {
						event.preventDefault();
						editor.settings.contextmenu = false;
						editor.execCommand("mceImage");
					}
				});

				editor.on("click", function(e) {
					let node = editor.selection.getNode();
					if (node.parentNode.parentNode.nodeName === "FIGCAPTION") {
						e.preventDefault();
						if (node.parentNode.parentNode.parentNode.nodeName !== "A") {
							editor.selection.select(node.parentNode.parentNode);
						} else {
							editor.selection.select(node.parentNode.parentNode.parentNode);
						}
					}
				});

				editor.on("keydown", function(e) {
					if (e.keyCode === 46) {
						// 46 is the keycode for the delete key
						let selectedNode = editor.selection.getNode();
						if (
							selectedNode.tagName === "FIGCAPTION" &&
							selectedNode.parentNode.tagName === "FIGURE"
						) {
							e.preventDefault();
							editor.dom.remove(selectedNode.parentNode);
						} else if (selectedNode.tagName === "A") {
							e.preventDefault();
							editor.dom.remove(selectedNode);
						}
					}
				});

				editor.on("OpenWindow", function(e) {
					let dialog = e.dialog;
					let dialogData = { ...dialog.getData() };

					if (dialogData.src && dialogData.dimensions) {
						let selectedNode;
						let link = "";
						editor.execCommand("mceSelectNodeDepth", false, 0);
						selectedNode = editor.selection.getNode();
						const childNode = editor.selection.getNode();

						if (selectedNode.nodeName === "IMG") {
							// Select parent of IMG
							editor.execCommand("mceSelectNodeDepth", false, 1);
							selectedNode = editor.selection.getNode();
							if (selectedNode.nodeName === "A" && selectedNode.href) {
								link = selectedNode.href;
							} else {
								// Reset to IMG element as selected item if not inside anchor tag
								editor.execCommand("mceSelectNode", false, childNode);
								editor.execCommand("mceSelectNodeDepth", false, 0);
							}
						} else {
							// Not IMG element, look for FIGURE element
							editor.execCommand("mceSelectNodeDepth", false, 3);
							selectedNode = editor.selection.getNode();
							if (selectedNode.nodeName === "FIGURE") {
								editor.execCommand("mceSelectNodeDepth", false, 1);
								selectedNode = editor.selection.getNode();
								if (selectedNode.nodeName === "A" && selectedNode.href) {
									link = selectedNode.href;
								} else {
									// Reset to FIGURE element as selected item if not inside anchor tag
									editor.execCommand("mceSelectNode", false, childNode);
									editor.execCommand("mceSelectNodeDepth", false, 3);
									selectedNode = editor.selection.getNode();
								}
							} else {
								editor.execCommand("mceSelectNode", false, childNode);
								selectedNode = editor.selection.getNode();
							}
						}

						let imageClassList = e.target.settings.image_class_list;
						let classes = [];
						if (imageClassList.length > 0) {
							imageClassList.forEach(item => {
								classes.push({ text: item.title, value: item.value });
							});
						}
						const imageEditConfig = {
							title: "Insert/Edit Image",
							body: {
								type: "panel",
								items: [
									{
										type: "input",
										inputMode: "url",
										name: "source",
										label: "Source",
										disabled: true
									},
									{
										type: "input",
										inputMode: "text",
										name: "alt",
										label: "Description"
									},
									{
										type: "input",
										inputMode: "url",
										name: "link",
										label: "Link"
									},
									{
										type: "checkbox",
										name: "newwindow",
										label: "Open in New Tab"
									},
									{
										type: "input",
										inputMode: "text",
										name: "width",
										label: "Width"
									},
									{
										type: "input",
										inputMode: "text",
										name: "height",
										label: "Height"
									},
									{
										type: "listbox",
										name: "classes",
										label: "Class",
										items: classes
									}
								]
							},
							initialData: {
								link: link,
								source: dialogData.src.value,
								alt: dialogData.alt,
								width: dialogData.dimensions.width,
								height: dialogData.dimensions.height,
								classes: dialogData.classes
							},
							buttons: [
								{
									type: "cancel",
									text: "Cancel"
								},
								{
									type: "submit",
									text: "Submit",
									name: "submit",
									primary: true,
									disabled: true
								}
							],
							onChange: function(dialogApi, details) {
								let data = dialogApi.getData();

								const { name } = details;
								if (name === "width") {
									const { width } = data;
									const updatedDimensions = resizeWithAspectRatio(
										dialogData.dimensions.width,
										dialogData.dimensions.height,
										width,
										null
									);
									dialogApi.setData({
										width: String(updatedDimensions.width),
										height: String(updatedDimensions.height)
									});
								}

								if (name === "height") {
									const { height } = data;
									const updatedDimensions = resizeWithAspectRatio(
										dialogData.dimensions.width,
										dialogData.dimensions.height,
										null,
										height
									);
									dialogApi.setData({
										width: String(updatedDimensions.width),
										height: String(updatedDimensions.height)
									});
								}

								const updatedData = dialogApi.getData();

								const isSourceValid =
									updatedData.source &&
									updatedData.source.trim() !== "" &&
									updatedData.alt &&
									updatedData.alt.trim() !== "" &&
									(updatedData.newwindow
										? updatedData.link &&
										  updatedData.link.length &&
										  updatedData.newwindow
										: !updatedData.newwindow);
								const isWidthValid =
									updatedData.width && updatedData.width.trim() !== "";
								const isHeightValid =
									updatedData.height && updatedData.height.trim() !== "";

								if (isSourceValid && isWidthValid && isHeightValid) {
									dialogApi.enable("submit", true);
								} else {
									dialogApi.disable("submit", true);
								}
							},
							onSubmit: function(dialogApi) {
								const updatedData = dialogApi.getData();
								let width = Number(updatedData.width);
								let height = Number(updatedData.height);

								let newImagePath;
								if (updatedData.source.includes("filters:")) {
									// Find the index of "image/"
									const imageIndex = updatedData.source.indexOf("image/");

									// Extract the part before "image/"
									const partBeforeImage = updatedData.source.substring(
										0,
										imageIndex
									);

									// Find the index of the last occurrence of "filters:quality(40)"
									const lastQualityIndex =
										updatedData.source.lastIndexOf("filters:quality(40)") +
										"filters:quality(40)".length;

									// Extract the part after the last "filters:quality(40)"
									const remainingString = updatedData.source.substring(
										lastQualityIndex
									);

									// Construct the new string with the replacement
									newImagePath =
										`${partBeforeImage}image/${width}x${height}/filters:format(webp)/filters:quality(40)` +
										remainingString;
								} else {
									newImagePath = updatedData.source.replace(
										"image/",
										`image/${width}x${height}/filters:format(webp)/filters:quality(40)/`
									);
								}

								const img = `<img src="${newImagePath}" alt="${
									updatedData.alt
								}" width="${width}" height="${height}"${
									updatedData.classes
										? ' class="' + updatedData.classes + '"'
										: ""
								} />`;
								const figure = `<figure class="image">
													${img}
													<figcaption>
														<h2>
															<span style="color: #ffffff;">${updatedData.alt}</span>
														</h2>
													</figcaption>
												</figure>`;

								let updatedContent = figure;
								if (
									!updatedData.classes ||
									!updatedData.classes.toLowerCase().includes("over")
								) {
									updatedContent = img;
								}
								if (updatedData.link && updatedData.link.length) {
									updatedContent = `<a href="${updatedData.link}" ${
										updatedData.newwindow ? " target='_blank'" : ""
									}>${updatedContent}</a>`;
								}

								editor.execCommand("mceReplaceContent", false, updatedContent);

								dialogApi.close();
							}
						};

						dialog.redial(imageEditConfig);
					}
				});
				editor.on("NodeChange", function(e) {
					if (e.element && e.element.tagName == "IMG") {
						debounceTimer = setTimeout(() => {
							if (e.element.src && e.element.src.includes("/image/")) {
								let srcEl = e.element.src;
								srcEl = srcEl.replace(
									/\/image\/\d+x\d+\//,
									`/image/${e.element.width}x${e.element.height}/`
								);

								// Call editor.dom.setAttribs here
								editor.dom.setAttribs(e.element, {
									src: srcEl
								});
							}
							clearTimeout(debounceTimer);
						}, 250);
					}
				});

				this.handlePredefinedImageSelectOptions();

				if (editor && editor.ui && editor.ui.registry) {
					editor.ui.registry.addButton(
						"imageSelect",
						this.returnEditorImageConfig(editor, params)
					);
				}
			}
		};
	};

	async componentDidMount() {
		this.setState({ isLoading: true });

		document.addEventListener("mousedown", this.handleClickOutside);

		clearTimeout(this.timeoutID);
		this.getStatus();

		await this.getWebsiteData();
		await this.updateConfigFacetCounts();

		this.setState({ isLoading: false });
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClickOutside);
		clearTimeout(this.timeoutID);
	}

	/** Get website data.  */
	getWebsiteData = async () => {
		let { data } = this.state;
		let websiteData = null;

		try {
			websiteData = await API.get(
				"wapi",
				"/websites?organization=" + this.org,
				null
			);

			if (websiteData && websiteData.length) {
				let obj = {};
				const templateIdValue =
					data && data.template_id ? data.template_id : "";
				const customDomain =
					websiteData &&
					websiteData[0] &&
					websiteData[0].domains &&
					websiteData[0].domains[1]
						? websiteData[0].domains[1].name
						: "";
				const customDomainStatus =
					websiteData &&
					websiteData[0] &&
					websiteData[0].domains &&
					websiteData[0].domains[1]
						? websiteData[0].domains[1].status
						: "";

				websiteData[0].contact_info = Object.assign(
					data.contact_info,
					websiteData[0].contact_info
				);
				websiteData[0].primary_color_tint = this.calculateTint(
					_.get(websiteData[0], "primary_color")
						? websiteData[0].primary_color
						: "#274abb",
					0.5
				);
				websiteData[0].primary_color_tint = this.calculateTint(
					_.get(websiteData[0], "primary_color")
						? websiteData[0].primary_color
						: "#666666",
					0.5
				);

				data = Object.assign(data, websiteData[0]);

				obj = this.getSelectedTemplate(templateIdValue, obj);

				this.initCustomDomain = customDomain;
				this.initCustomDomainStatus = customDomainStatus;

				this.setState({
					data,
					websiteFetchError: false,
					domainPath: "https://" + data.default_domain,
					customDomain: customDomain,
					isLoading: false
				});
			}
		} catch (e) {
			console.log(e);
			this.setState({
				isLoading: false,
				websiteFetchError: true
			});
		}
		this.setState({
			modalReset: false
		});
	};

	updateCustomDomain = async websiteData => {
		const { customDomain } = this.state;
		const domains =
			websiteData &&
			websiteData.domains &&
			websiteData.domains[0] &&
			websiteData.domains[0];
		let copyData = { ...websiteData };
		let domainData = [
			{
				name: domains.name,
				status: domains.status,
				verification_cname: domains.verification_cname,
				verification_value: domains.verification_value
			},
			{
				name: customDomain
			}
		];

		copyData.domains = domainData;

		try {
			await API.put("wapi", `/websites/${websiteData.id}`, { body: copyData });

			this.initCustomDomain = customDomain;
		} catch (e) {
			console.log(e);
		}
	};

	handleAddEditorInitialized = value => {
		// This helps prevent loading and TinyMCE when we don't need it and lowers costs.
		// Only editors that have been initialized by the end user to edit will be loaded into view.
		let editorsInitialized = [...this.state.editorsInitialized];
		if (!editorsInitialized.includes(value)) {
			editorsInitialized.push(value);
			this.setState({ editorsInitialized });
		}
	};

	handleRemoveEditorInitialized = value => {
		// This helps prevent loading and TinyMCE when we don't need it and lowers costs.
		// Only editors that have been initialized by the end user to edit will be loaded into view.
		// When we want to reset the state of an editor being initialized, we use this.
		let editorsInitialized = [...this.state.editorsInitialized];
		if (editorsInitialized.includes(value)) {
			const newArr = editorsInitialized.filter(item => item !== value);
			this.setState({ editorsInitialized: newArr });
		}
	};

	/** Handle form submit after click on save button. It will add or update website information.*/
	handleFormSubmit = e => {
		e.preventDefault();
		const { data } = this.state;

		const newValidations = this.checkValidations([], true);

		this.setState(
			{
				validations: newValidations
			},
			async () => {
				const { validations } = this.state;

				const hasValidations = Object.values(validations).filter(value => {
					return value == true;
				});

				if (!hasValidations.length) {
					let websiteData = Object.assign({}, data);
					let logo = Object.assign({}, websiteData.logo_image);
					let favicon = Object.assign({}, websiteData.favicon_image);
					let hero = Object.assign({}, websiteData.hero_image);
					const images = { logo, favicon, hero };
					let count = 1;

					Object.keys(images).map(type => {
						if (images[type].data) {
							count = count + 1;
						}
					});

					this.setState(
						{
							isSending: true,
							taskCount: count,
							submitStatus: "Saving"
						},
						async () => {
							websiteData.organization = this.props.roleManager.org;
							favicon.url || delete websiteData.favicon_image;
							hero.url || delete websiteData.hero_image;
							logo.url || delete websiteData.logo_image;
							websiteData.primary_color =
								websiteData.primary_color || "#274abb";
							(websiteData.primary_color_tint =
								websiteData.primary_color_tint ||
								this.calculateTint("#274abb", 0.5)),
								(websiteData.secondary_color =
									websiteData.secondary_color || "#666666");
							(websiteData.secondary_color_tint =
								websiteData.secondary_color_tint ||
								this.calculateTint("#666666", 0.5)),
								(websiteData.text_color = websiteData.text_color || "#fff");
							websiteData.aboutUs = websiteData.aboutUs
								? websiteData.aboutUs.toString("html")
								: "";
							if (websiteData && websiteData.show_pagination == false) {
								if (websiteData && websiteData.property_display_count) {
									websiteData.property_display_count = "9";
								}
							}
							let new_custom_pages = [];
							websiteData.custom_pages.forEach(page => {
								if (!page.title || !page.title.length) {
									return;
								} else {
									new_custom_pages.push({
										...page,
										page: page.page.toString(),
										show_properties: page.show_properties
											? page.show_properties
											: false
									});
								}
							});

							websiteData.custom_pages = new_custom_pages;

							if (
								(websiteData.custom_pages.length == 1 &&
									!websiteData.custom_pages[0].title.length &&
									(!websiteData.custom_pages[0].show_properties ||
										!websiteData.custom_pages[0].page_content_top.length)) ||
								!new_custom_pages.length
							) {
								delete websiteData.custom_pages;
							}

							if (websiteData.id && websiteData.id != "") {
								await this.updateWebsiteConfig(
									websiteData,
									logo,
									favicon,
									hero,
									count
								);
							} else {
								await this.addWebsiteConfig(
									websiteData,
									logo,
									favicon,
									hero,
									count
								);
							}
							this.getStatus();
						}
					);
				} else {
					setTimeout(() => {
						const el = document.getElementsByClassName("form-validation");

						if (el.length) {
							el[0].scrollIntoView({
								block: "center",
								behavior: "smooth"
							});
						}
					}, 500);
				}
			}
		);
	};
	/** Update website configs function
	 * @param {*} websiteData
	 * @param {*} logo
	 * @param {*} favicon
	 * @param {*} hero
	 */
	updateWebsiteConfig = async (websiteData, logo, favicon, hero, count) => {
		const { customDomain } = this.state;
		let errorsModal = {
			show: false,
			errorList: []
		};

		if (customDomain !== this.initCustomDomain) {
			const domains =
				websiteData &&
				websiteData.domains &&
				websiteData.domains[0] &&
				websiteData.domains[0];
			const domainData = [
				{
					name: domains.name,
					status: domains.status,
					verification_cname: domains.verification_cname,
					verification_value: domains.verification_value
				},
				{
					name: customDomain
				}
			];

			websiteData.domains = domainData;
		}

		try {
			await API.put("wapi", `/websites/${websiteData.id}`, {
				body: websiteData
			});
			this.setState(
				{
					completedTask: this.state.completedTask + 1
				},
				async () => {
					await this.saveImages(websiteData, logo, favicon, hero, errorsModal);
					await this.getWebsiteData();
				}
			);
		} catch (e) {
			errorsModal.errorList.push("Website Configurations");
			this.setState({
				completedTask: this.state.completedTask + count,
				submitStatus: "Error",
				error: {
					status: true,
					msg: "Error update website configs"
				}
			});
		}
	};

	/**
	 * Add website configuration function
	 * @param {*} websiteData
	 * @param {*} logo
	 * @param {*} favicon
	 * @param {*} hero
	 */
	addWebsiteConfig = async (websiteData, logo, favicon, hero, count) => {
		const { customDomain } = this.state;
		let errorsModal = {
			show: false,
			errorList: []
		};
		let responseData = {};

		this.setState({
			submitStatus: "Creating Website"
		});

		try {
			responseData = await API.post("wapi", "/websites", { body: websiteData });

			if (customDomain !== this.initCustomDomain) {
				await this.updateCustomDomain(responseData);
			}

			this.setState(
				{
					completedTask: this.state.completedTask + 1
				},
				async () => {
					if (responseData.id) {
						await this.saveImages(
							responseData,
							logo,
							favicon,
							hero,
							errorsModal
						);
					}

					await this.getWebsiteData();
				}
			);
		} catch (e) {
			errorsModal.errorList.push("Website Configurations");
			this.setState({
				completedTask: this.state.completedTask + count,
				submitStatus: "Error",
				error: {
					status: true,
					msg: "Error create website configs"
				}
			});
		}
	};
	/**
	 * Save website images logo, favicon and hero.
	 * @param {*} websiteData
	 * @param {*} logo
	 * @param {*} favicon
	 * @param {*} hero
	 * @param {*} errorsModal
	 */
	saveImages = async (websiteData, logo, favicon, hero, errorsModal) => {
		let images = { logo, favicon, hero };
		let tokenList = [];

		await Promise.all(
			Object.keys(images).map(async type => {
				try {
					if (images[type].data) {
						let dataType = images[type].data_type;
						let token = await API.post(
							"wapi",
							`/websites/${websiteData.id}/imagetokens/${type}`,
							{ body: { title: type } }
						);
						if (token && token.token_id) {
							let upload_url = token.upload_url.split("s3://")[1];
							let bucket = upload_url.substr(0, upload_url.indexOf("/"));
							let id = upload_url.substring(
								upload_url.indexOf("/") + 1,
								upload_url.lastIndexOf("/")
							);

							Storage.configure({
								bucket: bucket,
								identityPoolId: constants.COGNITO.IdentityPoolId,
								region: constants.COGNITO.Region
							});

							await Storage.put(`${token.token_id}`, images[type].file, {
								contentType: dataType,
								customPrefix: {
									public: `${id}/public/`
								}
							});
							tokenList.push({ type, id: token.token_id });
						} else {
							throw new Error();
						}
					}
				} catch (e) {
					errorsModal.errorList.push(`${type} Image`);
				}
			})
		);
		for (let i = 0; i < tokenList.length; i++) {
			let uploadComplete = false,
				statusCheckCount = 0;
			while (!uploadComplete && statusCheckCount < STATUS_CHECK_MAX) {
				try {
					let bucketInfo = await API.get(
						"wapi",
						`/imagetokens/${tokenList[i].id}`
					);
					if (bucketInfo.status == "completed") {
						uploadComplete = true;
						this.setState({
							completedTask: this.state.completedTask + 1
						});
					}
					if (bucketInfo.error) {
						throw new Error(bucketInfo.error);
					}
				} catch (e) {
					errorsModal.errorList.push(`${tokenList[i].type} Image`);
					uploadComplete = true;
					this.setState({
						completedTask: this.state.completedTask + 1,
						submitStatus: "Error",
						error: {
							status: true,
							msg: `Error upload ${tokenList[i].type} image`
						}
					});
				}
				if (!uploadComplete) {
					// Randon delay of 500ms to 1500ms before checking the status again
					let offset = 500 + Math.floor(Math.random() * 1000);
					await delay(offset);
				}
				statusCheckCount++;
			}
		}
		let isSending = this.state.isSending;
		if (errorsModal.errorList.length) {
			errorsModal.show = true;
			isSending = false;
		}
		this.setState({
			errorsModal,
			isSending
		});
	};

	/**
	 * Update old images with new one.
	 * @param {*} file
	 */
	updateImage = async (file, type) => {
		if (file.length === 0) {
			return;
		}

		let fileName = this.state.fileName;
		let data = this.state.data;

		fileName[type].name = file[0].name;

		this.setState({
			fileName
		});
		const _this = this;

		const reader = new FileReader();
		// Read the image via FileReader API and save image result in state.
		reader.onload = (function() {
			return function(e) {
				if (e.target.result) {
					data[type] = {
						data: e.target.result.split(",")[1],
						data_type: file[0].type,
						file: file[0]
					};
					_this.setState({
						data
					});
				}
			};
		})(file);
		reader.readAsDataURL(file[0]);
	};
	/**
	 * Get dimensions of image.
	 * @param {*} e
	 */
	getDimensions = e => {
		let el = e.target;
		let id = e.target.id;
		let fileName = this.state.fileName;
		fileName[id].dimensions = el.naturalWidth + "x" + el.naturalHeight;
		this.setState({
			fileName
		});
	};
	/**
	 * @function {*} handleTemplateChange : on changing template function
	 * @param {String} value
	 */
	handleTemplateChange = value => {
		const { data } = this.state;
		let obj = {};
		data["template_id"] = value;

		obj = this.getSelectedTemplate(value, obj);

		this.setState({
			data
		});

		if (value === "template-2") {
			this.handleLayoutBoundaryChange(undefined);
		}
	};

	/**
	 * @function {*} handleLayoutBoundaryChange : on changing template function
	 * @param {String} value
	 */
	handleLayoutBoundaryChange = value => {
		const { data } = this.state;
		data["layout_boundary_settings"] = value ? value : "default";
		this.setState({
			data
		});
	};

	/**
	 * @function {*} handleLayoutAlignmentChange : on changing layout_alignment function
	 * @param {String} value
	 */
	handleLayoutAlignmentChange = value => {
		const { data } = this.state;
		data["layout_alignment"] = value ? value : "left";
		this.setState({
			data
		});
	};

	handleHeroStyleChange = value => {
		const { data } = this.state;
		data["hero_style"] = value;
		if (value === "bg_light") {
			data["hero_background_from_neutral"] = "from-neutral-200";
			data["hero_text_from_neutral"] = "text-neutral-600";
		}
		if (value === "bg_dark") {
			data["hero_background_from_neutral"] = "from-neutral-600";
			data["hero_text_from_neutral"] = "text-neutral-200";
		}
		if (value === "bg_none") {
			data["hero_background_from_neutral"] = "";
			data["hero_text_from_neutral"] = "text-neutral-600";
		}
		this.setState({
			data
		});
	};

	/**
	 * @function { * } : function for populate template depended fields here
	 * @param {*} obj
	 */
	getSelectedTemplate = (val, obj) => {
		switch (val) {
			case "template-2":
				break;
			default:
				break;
		}
		return obj;
	};

	timeoutID = "";
	getStatus = async websiteData => {
		clearTimeout(this.timeoutID);
		try {
			if (
				!websiteData ||
				!websiteData.length ||
				!websiteData[0].id ||
				!websiteData[0].build_token
			) {
				websiteData = await API.get(
					"wapi",
					"/websites?organization=" + this.org,
					null
				);
			}

			if (
				websiteData &&
				websiteData.length &&
				websiteData[0].id &&
				websiteData[0].build_token
			) {
				let statusCheck = await API.get(
					"wapi",
					`/websites/${websiteData[0].id}/build-status/${websiteData[0].build_token}`
				);

				if (
					statusCheck &&
					statusCheck.status &&
					(statusCheck.status === "new" ||
						statusCheck.status === "provisioning" ||
						statusCheck.status === "checking" ||
						statusCheck.status === "initializing" ||
						statusCheck.status === "building" ||
						statusCheck.status === "deploying" ||
						statusCheck.status === "invalidate_cache")
				) {
					this.setState({
						buildStatus: statusCheck.status
					});

					let timeoutDelay = 1500;
					const longDelayStatus = ["new", "provisioning"];
					if (
						statusCheck.status &&
						longDelayStatus.includes(statusCheck.status)
					) {
						timeoutDelay = 5000;
					}
					this.timeoutID = setTimeout(() => {
						this.getStatus(websiteData);
					}, timeoutDelay);
				} else {
					const status =
						statusCheck &&
						statusCheck.status &&
						!statusCheck.status.toLowerCase().includes("error")
							? statusCheck.status
							: "error";
					this.setState({
						buildStatus: status || "error"
					});
					clearTimeout(this.timeoutID);
				}
			} else {
				this.setState({
					buildStatus: "error"
				});
				clearTimeout(this.timeoutID);
			}
		} catch (e) {
			console.log(e);
			this.setState({
				buildStatus: "error"
			});
			clearTimeout(this.timeoutID);
		}
	};

	/**
	 * Handle change of website input thing.
	 * @param {*} event
	 */
	handleChange = event => {
		let data = this.state.data;
		let val = event.target.value;
		let name = event.target.name;
		data[name] = name == "seo_keywords" ? val.split(",") : val;
		if (name == `property_display_count`) {
			data[name] = val;
		}
		if (
			name == "show_pagination" ||
			name == "show_filters" ||
			name == "show_chat" ||
			name === "show_map" ||
			name === "show_nearby_properties" ||
			name === "include_child_orgs" ||
			name === "embed" ||
			name === "accept_promo_codes" ||
			name === "search_bar_in_hero"
		) {
			data[name] = event.target.checked;
		}
		if (name === "primary_color") {
			if (!val.includes("#")) {
				val = `#${val}`;
			}
			const color = {
				hex: val
			};
			this.handleChangeColorPicker(color);
		}

		if (name === "secondary_color") {
			if (!val.includes("#")) {
				val = `#${val}`;
			}
			const color = {
				hex: val
			};
			this.handleChangeSecondaryColorPicker(color);
		}

		if (name === "text_color") {
			data.theme_type =
				val === ("#fff" || "white") ? data.theme_type : "theme-6";
		}
		if (name === "theme_type") {
			switch (val) {
				case "theme-2":
					data.primary_color = "#ff6363";
					data.primary_color_tint = this.calculateTint("#ff6363", 0.5);
					data.text_color = "#fff";
					break;
				case "theme-3":
					data.primary_color = "#87d0b2";
					data.primary_color_tint = this.calculateTint("#87d0b2", 0.5);
					data.text_color = "#fff";
					break;
				case "theme-4":
					data.primary_color = "#008cd0";
					data.primary_color_tint = this.calculateTint("#008cd0", 0.5);
					data.text_color = "#fff";
					break;
				case "theme-5":
					data.primary_color = "#20bf5a";
					data.primary_color_tint = this.calculateTint("#20bf5a", 0.5);
					data.text_color = "#fff";
					break;
				default:
					data.primary_color = "#274abb";
					data.primary_color_tint = this.calculateTint("#274abb", 0.5);
					data.text_color = "#fff";
			}
		}
		this.setState({
			data: data
		});

		if (name === "include_child_orgs") {
			this.updateConfigFacetCounts();
		}
	};
	/** Handle contact change input boxes.  */
	handleContactChange = e => {
		let data = this.state.data;
		let val = e.target.value,
			name = e.target.name;
		data.contact_info[name] = val;
		this.setState({
			data
		});
	};
	/** Close modal. */
	closeModal = async () => {
		let errorsModal = Object.assign({}, this.state.errorsModal);
		errorsModal.show = false;
		this.setState({
			errorsModal,
			completedTask: 0,
			error: {
				status: false,
				msg: ""
			},
			isSending: false,
			modalReset: true
		});
	};
	/** Render error modal. */
	renderErrorModal = () => {
		let errorsModal = this.state.errorsModal;
		return (
			<Modal
				className="submit-modal"
				onHide={this.closeModal}
				show={errorsModal.show}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<span className="status-icon status-icon--error"></span>
						<span className="submit-heading">Error</span>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col xs={12} className="bottom-margin">
							<h4>The following items were not saved:</h4>
							<ul className="errors-list">
								{errorsModal.errorList.map((err, i) => {
									return <li key={i}>{err}</li>;
								})}
							</ul>
						</Col>
					</Row>
				</Modal.Body>
			</Modal>
		);
	};
	/** Handle text color picker will change website color to picked color. */
	handleTextColorPicker = () => {
		this.setState({
			textColorPicker: !this.state.textColorPicker,
			primaryColorPicker: false,
			secondaryColorPicker: false
		});
	};
	/** Handle primary theme color of website. */
	handlePrimaryColorPicker = () => {
		this.setState({
			primaryColorPicker: !this.state.primaryColorPicker,
			textColorPicker: false,
			secondaryColorPicker: false
		});
	};
	/** Handle secondary theme color of website. */
	handleSecondaryColorPicker = () => {
		this.setState({
			primaryColorPicker: false,
			textColorPicker: false,
			secondaryColorPicker: !this.state.secondaryColorPicker
		});
	};
	/**
	 * @function {*} handleSecondaryColorPickerOnFocus calling on focusing secondary color input field
	 */
	handleColorPickerOnFocus = event => {
		const name = event.target.id;
		let colorPickersObj = {
			primaryColorPicker: false,
			textColorPicker: false,
			secondaryColorPicker: false
		};
		let data = this.state;
		colorPickersObj[name] = true;
		data = Object.assign(data, colorPickersObj);
		this.setState(data);
	};
	/** Invoke when color picker go to be blur */
	handleColorPickerOnBlur = () => {
		this.setState({
			primaryColorPicker: false,
			textColorPicker: false,
			secondaryColorPicker: false
		});
	};
	/**
	 * Handle change color picker component.
	 * @param {*} color will get hex code of color by using color.hex
	 */
	handleChangeColorPicker = color => {
		this.checkColorValue(color.hex);
		let data = this.state.data;
		data.primary_color = color.hex;
		data.primary_color_tint = this.calculateTint(color.hex, 0.5);

		this.setState({
			primary_color: data.primary_color,
			primary_color_tint: this.calculateTint(color.hex, 0.5)
		});
	};

	calculateTint = (hex, factor) => {
		if (hex.startsWith("#")) {
			hex = hex.slice(1);
		}

		let r = parseInt(hex.substring(0, 2), 16);
		let g = parseInt(hex.substring(2, 4), 16);
		let b = parseInt(hex.substring(4, 6), 16);

		r = Math.round(r + (255 - r) * factor);
		g = Math.round(g + (255 - g) * factor);
		b = Math.round(b + (255 - b) * factor);

		r = r.toString(16).padStart(2, "0");
		g = g.toString(16).padStart(2, "0");
		b = b.toString(16).padStart(2, "0");

		return `#${r}${g}${b}`;
	};
	/**
	 * Handle change color picker component.
	 * @param {*} color will get hex code of color by using color.hex
	 */
	handleChangeSecondaryColorPicker = color => {
		let data = this.state.data;
		data.secondary_color = color.hex;
		data.secondary_color_tint = this.calculateTint(color.hex, 0.5);

		this.setState({
			secondary_color: data.secondary_color,
			secondary_color_tint: this.calculateTint(color.hex, 0.5)
		});
	};

	handlePickerClickOutside = (event, type) => {
		let types = {
			primary: {
				ref: "primaryColorPickerRef",
				state: "primaryColorPicker"
			},
			secondary: {
				ref: "secondaryColorPickerRef",
				state: "secondaryColorPicker"
			},
			text: {
				ref: "textColorPickerRef",
				state: "textColorPicker"
			}
		};

		if (
			this[types[type].ref].current &&
			!this[types[type].ref].current.contains(event.target)
		) {
			this.setState({ [types[type].state]: false });
		}
	};

	handleClickOutside = event => {
		this.handlePickerClickOutside(event, "primary");
		this.handlePickerClickOutside(event, "secondary");
		this.handlePickerClickOutside(event, "text");
	};
	/**
	 * Check value of color picked.
	 * @param {*} val
	 */
	checkColorValue = val => {
		let data = this.state.data;
		switch (val) {
			case "#274abb":
				data.theme_type = "theme-1";
				break;
			case "#ff6363":
				data.theme_type = "theme-2";
				break;
			case "#87d0b2":
				data.theme_type = "theme-3";
				break;
			case "#008cd0":
				data.theme_type = "theme-4";
				break;
			case "#20bf5a":
				data.theme_type = "theme-5";
				break;
			default:
				data.theme_type = "theme-6";
		}
		this.setState({ data: data });
	};
	/** Text color picker  */
	handleChangeTextColorPicker = color => {
		let data = this.state.data;
		data.text_color = color.hex;
		if (data.text_color !== "#fff") {
			data.theme_type = "theme-6";
		}
		this.setState({ text_color: data.text_color });
	};

	handleMCEChange = (value, name) => {
		const { data } = this.state;
		data[name] = value;

		this.setState({
			data
		});
	};

	setAttributeState = (name, value) => {
		const { data } = this.state;

		const parts = name.split(".");
		let update = data;

		for (let i = 0; i < parts.length - 1; i++) {
			if (!(parts[i] in update)) {
				update[parts[i]] = {};
			}
			update = update[parts[i]];
		}

		update[parts[parts.length - 1]] = value;

		this.setState({ ...data, ...update });
	};

	handleHomepageMCEChange = async (value, name) => {
		const { data } = this.state;
		let copyHomePage = data.homepage;
		copyHomePage[name] = value;
		await this.setState({
			data: {
				...data,
				homepage: copyHomePage
			}
		});
	};

	handleStateChange = e => {
		const name = e.target.name;
		const value = e.target.value;

		this.setState(
			{
				[name]: value
			},
			() => {
				const { validations } = this.state;

				if (validations[name] == true) {
					const newValidations = this.checkValidations([name]);

					this.setState({
						validations: newValidations
					});
				}
			}
		);
	};

	isValidDomainName = domainName => {
		const regex = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/i;
		return regex.test(domainName);
	};

	checkValidations = (names, checkAll) => {
		const { validations, customDomain } = this.state;
		let copyValidations = { ...validations };

		if (checkAll) {
			names = Object.keys(validations);
		}

		names.map(name => {
			if (name == "customDomain") {
				const isValidDomainName = this.isValidDomainName(customDomain);

				if (isValidDomainName || !customDomain.length) {
					copyValidations.customDomain = false;
				} else {
					copyValidations.customDomain = true;
				}
			}
		});

		return copyValidations;
	};

	renderOverlay = (icon, func, overlay, name) => {
		return (
			<OverlayTrigger
				placement="top"
				overlay={this.getTooltip(overlay.id, overlay.text)}
				onClick={e => func(e, name)}
			>
				<i className={icon} />
			</OverlayTrigger>
		);
	};

	getTooltip = (id, text) => {
		return <Tooltip id={id}>{text}</Tooltip>;
	};

	showInstructions = name => {
		if (instructions[`${name}`]) {
			return (
				<OverlayTrigger
					placement="top"
					overlay={this.getTooltip(name, instructions[`${name}`])}
				>
					<i className="icon-info icon-text-top" />
				</OverlayTrigger>
			);
		} else {
			return <Fragment></Fragment>;
		}
	};

	copyText = (e, name) => {
		e.preventDefault();
		const { data, customDomain } = this.state;
		let value = "";

		if (name == "customDomain") {
			value = customDomain;
		} else {
			value = data && data.domains && data.domains[1] && data.domains[1][name];
		}

		if (value) {
			navigator.clipboard.writeText(value);
		}
	};

	handleModal = (e, name) => {
		e && e.preventDefault();

		this.setState({
			modal: name
		});
	};

	panelTitle = (title, key) => {
		const { activeKey } = this.state;

		return (
			<strong>
				{title}
				<span
					className={`pull-right ${
						activeKey == key
							? "glyphicon glyphicon-chevron-up"
							: "glyphicon glyphicon-chevron-down"
					}`}
				></span>
			</strong>
		);
	};

	handleTabs = (key, parentActiveKey, parentPageTitle) => {
		if (key == "add") {
			let copyCustomPages = [...this.state.data.custom_pages];

			copyCustomPages.push({
				page: copyCustomPages.length + 1,
				title: "",
				sub_title: "",
				page_content_top: "",
				show_properties: false
			});

			this.setState({
				data: {
					...this.state.data,
					custom_pages: copyCustomPages
				},
				tabsActiveKey: copyCustomPages.length,
				tabsSubActiveKey: copyCustomPages.length
			});
		} else if (key === "add-sub" && parentActiveKey) {
			let copyCustomPages = [...this.state.data.custom_pages];

			let parentPage = "";
			if (parentActiveKey === "aboutPageTab") {
				parentPage = "About";
			} else if (parentActiveKey === "listingsPageTab") {
				parentPage = "Listings";
			} else if (parentPageTitle && parentPageTitle.length) {
				parentPage = parentPageTitle;
			}

			copyCustomPages.push({
				page: copyCustomPages.length + 1,
				title: "",
				sub_title: "",
				page_content_top: "",
				show_properties: false,
				parent_page: parentPage
			});

			this.setState({
				data: {
					...this.state.data,
					custom_pages: copyCustomPages
				},
				tabsActiveKey: parentActiveKey,
				tabsSubActiveKey: copyCustomPages.length
			});
		} else {
			if (parentActiveKey) {
				this.setState({
					tabsActiveKey: parentActiveKey,
					tabsSubActiveKey: key
				});
			} else {
				let subKey = key;
				if (key === "aboutPageTab") {
					subKey = "aboutUsTab";
				} else if (key === "listingsPageTab") {
					subKey = "listingsTab";
				}
				this.setState({
					tabsActiveKey: key,
					tabsSubActiveKey: subKey
				});
			}
		}
	};

	onChangeCustomPage = async (e, i) => {
		const { data } = this.state;
		let name = e.target.name;
		let value;

		if (e.target.type === "checkbox") {
			if (e.target.checked) {
				value = true;
			} else {
				value = false;
			}
		} else {
			e.preventDefault();
			value = e.target.value;
		}

		let copyCustomPages = [...data.custom_pages];

		if (name === "title") {
			copyCustomPages.forEach(customPage => {
				if (
					customPage.parent_page &&
					customPage.parent_page.length &&
					customPage.parent_page === copyCustomPages[i].title
				) {
					customPage.parent_page = value;
				}
			});
		}

		copyCustomPages.splice(i, 1, {
			...copyCustomPages[i],
			[name]: value
		});

		await this.setState({
			data: {
				...data,
				custom_pages: [...copyCustomPages]
			}
		});
	};

	onChangeHomePage = async e => {
		const { data } = this.state;
		let name = e.target.name;
		let value;

		if (e.target.type === "checkbox") {
			if (e.target.checked) {
				value = true;
			} else {
				value = false;
			}
		} else {
			e.preventDefault();
			value = e.target.value;
		}

		let copyHomePage = data.homepage;
		copyHomePage[name] = value;

		await this.setState({
			data: {
				...data,
				homepage: copyHomePage
			}
		});
	};

	handleCustomHeroPageImage = (value, i) => {
		const { data } = this.state;
		let copyCustomPages = [...data.custom_pages];
		copyCustomPages[i]["page_content_hero_image"] = value;
		this.setState({
			data: {
				...data,
				custom_pages: [...copyCustomPages]
			}
		});
	};

	handleRemoveCustomHeroPageImage = i => {
		const { data } = this.state;
		let copyCustomPages = [...data.custom_pages];
		delete copyCustomPages[i]["page_content_hero_image"];

		this.setState({
			data: {
				...data,
				custom_pages: [...copyCustomPages]
			}
		});
	};

	handleCustomTopPageMsg = (value, i) => {
		const { data } = this.state;
		let copyCustomPages = [...data.custom_pages];
		copyCustomPages[i]["page_content_top"] = value;

		this.setState({
			data: {
				...data,
				custom_pages: [...copyCustomPages]
			}
		});
	};

	handleCustomBottomPageMsg = (value, i) => {
		const { data } = this.state;
		let copyCustomPages = [...data.custom_pages];
		copyCustomPages[i]["page_content_bottom"] = value;

		this.setState({
			data: {
				...data,
				custom_pages: [...copyCustomPages]
			}
		});
	};

	deleteCustomPage = (i, tabsActiveKey = null) => {
		const { data } = this.state;
		let copyCustomPages = [...data.custom_pages];
		let newCustompages = [];

		copyCustomPages.splice(i, 1);

		copyCustomPages.map((page, i) => {
			newCustompages.push({
				...page,
				page: i + 1
			});
		});

		this.setState(
			{
				data: {
					...data,
					custom_pages: [...newCustompages]
				},
				editorsInitialized: []
			},
			() => {
				const { data } = this.state;

				if (newCustompages.length == 0) {
					this.setState({
						data: {
							...data,
							custom_pages: [
								{
									page: 1,
									title: "",
									sub_title: "",
									page_content_top: "",
									show_properties: false
								}
							]
						}
					});
				}
			}
		);
		this.handleTabs(tabsActiveKey !== null ? tabsActiveKey : "homePageTab");
	};

	updateCustomImageWebsiteConfig = async (imageConfig, api) => {
		const { data } = this.state;
		let dataCopy = _.cloneDeep(data);
		let customImages = [...(_.get(data, "custom_images") || [])];
		const type = imageConfig.image[0].type.split("/")[1];
		let customImageConfig = {
			title: imageConfig.title,
			height: imageConfig.height,
			width: imageConfig.width,
			url: `https://${data.default_domain}/image/${data.id}/${imageConfig.title}.${type}`
		};
		customImages.push(customImageConfig);
		dataCopy.custom_images = customImages;
		try {
			await API.put("wapi", `/websites/${data.id}`, { body: dataCopy });
			api.setData({ title: imageConfig.title });
			api.setData({ height: imageConfig.height });
			api.setData({ width: imageConfig.width });
			api.setData({
				source: `https://${data.default_domain}/image/${data.id}/${imageConfig.title}.${type}`
			});
			let imageConfigStringify = JSON.stringify(customImageConfig);
			let newImageConfigOption = {
				text: imageConfig.title,
				value: imageConfigStringify
			};
			this.handlePredefinedImageSelectOptions(newImageConfigOption);
			return `${imageConfig.title}.${type}`;
		} catch (err) {
			console.log(err);
		}
	};

	handleCustomImage = async (imgConfig, api) => {
		const { data } = this.state;
		let tokenId = "";
		try {
			let token = await API.post(
				"wapi",
				`/websites/${data.id}/imagetokens/${imgConfig.title}`,
				{ body: { title: imgConfig.title } }
			);
			if (_.get(token, "token_id")) {
				let upload_url = token.upload_url.split("s3://")[1];
				const bucket = upload_url.substr(0, upload_url.indexOf("/"));
				const id = upload_url.substring(
					upload_url.indexOf("/") + 1,
					upload_url.lastIndexOf("/")
				);
				tokenId = token.token_id;
				Storage.configure({
					bucket: bucket,
					identityPoolId: constants.COGNITO.IdentityPoolId,
					region: constants.COGNITO.Region
				});
				await Storage.put(`${token.token_id}`, imgConfig.image[0], {
					contentType: imgConfig.image.type,
					customPrefix: {
						public: `${id}/public/`
					}
				});
			} else {
				throw new Error();
			}
		} catch (err) {
			console.log(err);
		}
		let uploadComplete = false;
		let uploadSuccessful = false;
		let statusCheckCount = 0;
		while (!uploadComplete && statusCheckCount < STATUS_CHECK_MAX) {
			try {
				let bucketInfo = await API.get("wapi", `/imagetokens/${tokenId}`);
				if (bucketInfo.status === "completed") {
					uploadSuccessful = true;
					uploadComplete = true;
				}
				if (bucketInfo.error) {
					throw new Error(bucketInfo.error);
				}
			} catch (err) {
				uploadComplete = true;
			}
			if (!uploadComplete) {
				let offset = 500 + Math.floor(Math.random() * 1000);
				await delay(offset);
			}
			statusCheckCount++;
		}
		if (uploadSuccessful) {
			await this.updateCustomImageWebsiteConfig(imgConfig, api);
		}
	};

	handlePredefinedImageSelectOptions = newImageConfig => {
		const { data } = this.state;
		let selectOptions = [
			{
				text: "",
				value: ""
			}
		];
		if (_.get(data, "custom_images.length")) {
			data.custom_images.forEach(image => {
				let imageConfig = {
					title: image.title,
					url: image.url,
					height: image.height,
					width: image.width
				};
				let imageConfigStringify = JSON.stringify(imageConfig);
				selectOptions.push({
					text: image.title,
					value: imageConfigStringify
				});
			});
		}
		if (newImageConfig) {
			selectOptions.push(newImageConfig);
		}
		this.predefinedImageSelectOptions = selectOptions;
	};

	homePageTab = () => {
		const { data } = this.state;
		return (
			<Tab key={`homePageTab`} eventKey={`homePageTab`} title={`Homepage`}>
				<div className="tab-container">
					{data.show_filters && (
						<Row>
							<Col xs={12} sm={8} md={10} className="field-wrapper">
								<InputField
									cssClass="checkbox-label"
									type="checkbox"
									name="search_bar_in_hero"
									value={data.homepage.search_bar_in_hero || ""}
									cbChange={this.onChangeHomePage}
								>
									Show Search in Header
									{this.showInstructions("homepage-show-hero-searchbar")}
								</InputField>
							</Col>
						</Row>
					)}
					<Row>
						<Col xs={12} sm={12} md={12} className="field-wrapper">
							<InputLabel className="flex-space-between">
								<span>Header Title</span>
							</InputLabel>
							<InputField
								type="text"
								name="title"
								value={data.homepage.title || data.title || ""}
								onChange={e => this.onChangeHomePage(e)}
							/>
						</Col>
					</Row>
					<Row>
						<Col xs={12} sm={12} md={12} className="field-wrapper">
							<InputLabel className="flex-space-between">
								<span>Header Description</span>
							</InputLabel>
							<InputField
								type="text"
								name="sub_title"
								value={data.homepage.sub_title || data.description || ""}
								onChange={e => this.onChangeHomePage(e)}
							/>
						</Col>
					</Row>
					<Row>
						<Col xs={12} className="field-wrapper">
							<InputLabel htmlFor="page_content_top" cssClass="custom-label">
								Page Content
							</InputLabel>
							{this.state.editorsInitialized.includes(
								`editorRefHomepageContentTop`
							) && (
								<Editor
									onInit={(evt, editor) =>
										(this[`editorRefHomepageContentTop`] = editor)
									}
									init={this.returnEditorConfig(data)}
									onEditorChange={value =>
										this.handleHomepageMCEChange(value, "page_content_top")
									}
									value={data.homepage.page_content_top || ""}
								/>
							)}
							{!this.state.editorsInitialized.includes(
								`editorRefHomepageContentTop`
							) && (
								<Fragment>
									<span className="span-divider">|</span>
									<a
										className="link-text underline"
										href=""
										onClick={e => {
											e.preventDefault();
											this.handleAddEditorInitialized(
												"editorRefHomepageContentTop"
											);
										}}
									>
										{data.homepage.page_content_top
											? "Edit Page Content"
											: "Add Page Content"}
									</a>
									<Row className="text-left">
										<div
											className={`mce-content-body ${
												this.state.editorsInitialized.includes(
													"editorRefHomepageContentTop"
												)
													? "edit"
													: "preview"
											}`}
											dangerouslySetInnerHTML={{
												__html: data.homepage.page_content_top || ""
											}}
										></div>
									</Row>
								</Fragment>
							)}
						</Col>
					</Row>
					<Row>
						<Col xs={12} sm={12} md={2} className="field-wrapper">
							<InputField
								cssClass="checkbox-label"
								type="checkbox"
								name="show_properties"
								value={
									data.homepage.show_properties
										? data.homepage.show_properties
										: false
								}
								cbChange={e => {
									this.onChangeHomePage(e);
								}}
							>
								Show Listings
								{this.showInstructions("show-listings")}
							</InputField>
						</Col>
						{data.homepage.show_properties && (
							<Fragment>
								<Col xs={12} sm={12} md={1} className="field-wrapper">
									Filter By:
									<br />
									<span className="small muted">(optional)</span>
								</Col>
								{/* Properties by City */}
								<Col xs={12} sm={12} md={2} className="field-wrapper">
									<InputField
										type="select"
										name="property_filter_city"
										value={data.homepage.property_filter_city || ""}
										onChange={e => {
											this.onChangeHomePage(e);
										}}
									>
										{this.cityOptions}
									</InputField>
								</Col>

								{/* property_filter_beds */}
								<Col xs={12} sm={12} md={2} className="field-wrapper">
									<InputField
										type="select"
										name="property_filter_beds"
										value={data.homepage.property_filter_beds || ""}
										onChange={e => {
											this.onChangeHomePage(e);
										}}
									>
										{this.bedsOptions}
									</InputField>
								</Col>

								{/* Properties by Keywords */}
								<Col xs={12} sm={12} md={3} className="field-wrapper">
									<InputField
										type="text"
										name="property_filter_keywords"
										placeholder="Contain Keywords"
										value={
											data.homepage.property_filter_keywords
												? data.homepage.property_filter_keywords
												: ""
										}
										onChange={e => {
											this.onChangeHomePage(e);
										}}
									/>
								</Col>

								{/* Properties Pets OK */}
								<Col xs={12} sm={12} md={2} className="field-wrapper">
									<InputField
										type="checkbox"
										name="property_filter_pets_ok"
										value={
											data.homepage.property_filter_pets_ok
												? data.homepage.property_filter_pets_ok
												: false
										}
										cbChange={e => {
											this.onChangeHomePage(e);
										}}
									>
										Pets Allowed
									</InputField>
								</Col>
							</Fragment>
						)}
					</Row>

					{data.homepage.show_properties && (
						<Row>
							<Col xs={12} className="field-wrapper">
								<InputLabel
									htmlFor="page_content_bottom"
									cssClass="custom-label"
								>
									Content Below Listings
								</InputLabel>
								{this.state.editorsInitialized.includes(
									`editorRefHomepageContentBottom`
								) && (
									<Editor
										onInit={(evt, editor) =>
											(this[`editorRefHomepageContentBottom`] = editor)
										}
										init={this.returnEditorConfig(data)}
										onEditorChange={value =>
											this.handleHomepageMCEChange(value, "page_content_bottom")
										}
										value={data.homepage.page_content_bottom || ""}
									/>
								)}
								{!this.state.editorsInitialized.includes(
									`editorRefHomepageContentBottom`
								) && (
									<Fragment>
										<span className="span-divider">|</span>
										<a
											className="link-text underline"
											href=""
											onClick={e => {
												e.preventDefault();
												this.handleAddEditorInitialized(
													"editorRefHomepageContentBottom"
												);
											}}
										>
											{data.homepage.page_content_bottom
												? "Edit Page Content"
												: "Add Page Content"}
										</a>
										<Row>
											<div
												className={`mce-content-body ${
													this.state.editorsInitialized.includes(
														"editorRefHomepageContentBottom"
													)
														? "edit"
														: "preview"
												}`}
												dangerouslySetInnerHTML={{
													__html: data.homepage.page_content_bottom || ""
												}}
											></div>
										</Row>
									</Fragment>
								)}
							</Col>
						</Row>
					)}
				</div>
			</Tab>
		);
	};

	staticSubTabs = (data, parentPageTitle) => {
		let listOfParentPagesWithSubPages = [];
		return data.custom_pages.map((page, i) => {
			if (
				!page ||
				!page.parent_page ||
				!page.parent_page.length ||
				page.parent_page !== parentPageTitle
			) {
				return;
			}

			if (page && page.parent_page && page.parent_page.length) {
				if (!listOfParentPagesWithSubPages.includes(page.parent_page)) {
					listOfParentPagesWithSubPages.push(page.parent_page);
				}
			}

			let tabTitle = `${parentPageTitle} / * New ${parentPageTitle} Sub Page *`;
			if (page.title && page.title.length) {
				tabTitle = `${parentPageTitle} / ${page.title}`;
			}

			return this.subPageTabEditing(
				i,
				page,
				tabTitle,
				parentPageTitle,
				listOfParentPagesWithSubPages,
				data
			);
		});
	};

	aboutMainContentTab = data => {
		return (
			<Tab key={"aboutUsTab"} eventKey={"aboutUsTab"} title={`About`}>
				<div className="tab-container">
					<div className="row">
						<div className="field-wrapper col-xs-12">
							{this.state.editorsInitialized.includes(
								`editorRefAboutContent`
							) && (
								<Editor
									onInit={(evt, editor) =>
										(this[`editorRefAboutContent`] = editor)
									}
									init={this.returnEditorConfig(data)}
									onEditorChange={value =>
										this.handleMCEChange(value, "aboutUs")
									}
									value={data.aboutUs || ""}
								/>
							)}
							{!this.state.editorsInitialized.includes(
								`editorRefAboutContent`
							) && (
								<Fragment>
									<a
										className="link-text underline"
										href=""
										onClick={e => {
											e.preventDefault();
											this.handleAddEditorInitialized("editorRefAboutContent");
										}}
									>
										{data.aboutUs ? "Edit Page Content" : "Add Page Content"}
									</a>
									<Row>
										<div
											className={`mce-content-body ${
												this.state.editorsInitialized.includes(
													"editorRefAboutContent"
												)
													? "edit"
													: "preview"
											}`}
											dangerouslySetInnerHTML={{
												__html: data.aboutUs || ""
											}}
										></div>
									</Row>
								</Fragment>
							)}
						</div>
					</div>
				</div>
			</Tab>
		);
	};

	mainContent = (data, key) => {
		if (key === "about") {
			{
				return this.aboutMainContentTab(data);
			}
		} else if (key === "listings") {
			{
				return this.listingsMainContentTab(data);
			}
		}

		return {};
	};

	mainTabs = (key, mainTitle) => {
		const { data } = this.state;
		const parentActiveKey = `${key}PageTab`;
		return (
			<Tab key={parentActiveKey} eventKey={parentActiveKey} title={mainTitle}>
				<div className="tab-container">
					<Row>
						<Col xs={12} className="field-wrapper">
							<Tabs
								activeKey={this.state.tabsSubActiveKey}
								id={`${key}-tabs`}
								onSelect={key =>
									this.handleTabs(key, parentActiveKey, mainTitle)
								}
							>
								{this.mainContent(data, key)}

								{this.staticSubTabs(data, mainTitle, key)}

								<Tab
									key="addButtonAboutTab"
									eventKey={"add-sub"}
									title={
										<OverlayTrigger
											placement="top"
											overlay={this.getTooltip(
												"add-sub",
												`Add a Page Under ${mainTitle}`
											)}
										>
											<span>
												<i className="icon-Plus"></i>
											</span>
										</OverlayTrigger>
									}
								></Tab>
							</Tabs>
						</Col>
					</Row>
				</div>
			</Tab>
		);
	};

	listingsMainContentTab = data => {
		return (
			<Tab key={"listingsTab"} eventKey={"listingsTab"} title={`Listings`}>
				<div className="tab-container">
					<p>Your properties will be displayed on this page</p>
				</div>
			</Tab>
		);
	};

	customPageTabs = (tabsActiveKey = "homePageTab", tabsSubActiveKey = 1) => {
		const { data } = this.state;

		return data.custom_pages.map((page, i) => {
			let listOfParentPages = [];
			listOfParentPages.push(
				<option key={"none"} value={""}>
					{"No Parent Page"}
				</option>
			);
			data.custom_pages.map((parent_page, i) => {
				if (
					parent_page &&
					parent_page.title &&
					parent_page.title !== page.title &&
					!parent_page.parent_page
				) {
					listOfParentPages.push(
						<option key={i} value={parent_page.title}>
							{parent_page.title}
						</option>
					);
				}
			});

			if (page && page.parent_page && page.parent_page.length) {
				return;
			}

			return (
				<Tab
					key={i}
					eventKey={i + 1}
					title={page.title ? page.title : `* New Custom Page *`}
				>
					<div className="tab-container">
						<Row>
							<Col xs={12} className="row-heading">
								<Tabs
									activeKey={tabsSubActiveKey}
									id="property-tabs"
									onSelect={key =>
										this.handleTabs(key, tabsActiveKey, page.title)
									}
								>
									{this.customSubPageTabs(
										page.title,
										tabsActiveKey,
										tabsSubActiveKey
									)}
									{page.title && page.title.length && (
										<Tab
											eventKey={"add-sub"}
											title={
												<OverlayTrigger
													placement="top"
													overlay={this.getTooltip(
														"add-sub",
														`Add a Page Under ${
															page.title ? page.title : "This Page"
														}`
													)}
												>
													<span>
														<i className="icon-Plus"></i>
													</span>
												</OverlayTrigger>
											}
										></Tab>
									)}
								</Tabs>
							</Col>
						</Row>
						{/* If brand new parent tab, show this: */}
						{tabsSubActiveKey < 1 && (
							<Fragment>
								<Row>
									<Col xs={12} sm={12} md={12} className="field-wrapper">
										<InputLabel cssClass="custom-label">
											<span>Page Header Image</span>
										</InputLabel>

										{this.state.editorsInitialized.includes(
											`editorRef${i}CustomSubHeroImage`
										) &&
										(!page.page_content_hero_image ||
											!page.page_content_hero_image.length) ? (
											<Editor
												onInit={(evt, editor) =>
													(this[`editorRef${i}CustomSubHeroImage`] = editor)
												}
												init={this.returnEditorConfig(data, {
													i,
													handleCustomHeroPageImage: true
												})}
												value={
													page.page_content_hero_image &&
													page.page_content_hero_image.length
														? `<img style="max-height: 170px; max-width: 240px;" src="${page.page_content_hero_image}" />`
														: ""
												}
											/>
										) : (
											(!page.page_content_hero_image ||
												!page.page_content_hero_image.length) && (
												<Fragment>
													<span className="span-divider">|</span>
													<a
														className="link-text underline"
														href=""
														onClick={e => {
															e.preventDefault();
															this.handleAddEditorInitialized(
																`editorRef${i}CustomSubHeroImage`
															);
														}}
													>
														Add Hero Image
													</a>
												</Fragment>
											)
										)}

										{(
											page.page_content_hero_image &&
											page.page_content_hero_image.length
										)(
											<Fragment>
												<span className="span-divider">|</span>
												<a
													className="link-text underline"
													href=""
													onClick={e => {
														e.preventDefault();
														this.handleRemoveCustomHeroPageImage(i);
														this.handleRemoveEditorInitialized(
															`editorRef${i}CustomSubHeroImage`
														);
													}}
												>
													Remove Header Image
												</a>
												<div
													className="col-md-12"
													dangerouslySetInnerHTML={{
														__html:
															page.page_content_hero_image &&
															page.page_content_hero_image.length
																? `<img style="max-height: 170px; max-width: 240px;" src="${page.page_content_hero_image}" />`
																: ""
													}}
												></div>
											</Fragment>
										)}
									</Col>
								</Row>

								<Row>
									<Col xs={12} sm={12} md={12} className="field-wrapper">
										<InputLabel className="flex-space-between">
											<span>Title</span>
											<OverlayTrigger
												placement="top"
												overlay={this.getTooltip(
													"delete",
													`Delete Custom Page: ${page.title}`
												)}
											>
												<span
													className="pointer"
													onClick={() => this.deleteCustomPage(i)}
												>
													<i className="icon icon-Delete"></i>
												</span>
											</OverlayTrigger>
										</InputLabel>
										<InputField
											type="text"
											name="title"
											value={page.title || ""}
											onChange={e => this.onChangeCustomPage(e, i)}
										/>
									</Col>

									<Col xs={6} sm={6} md={6} className="field-wrapper">
										<InputLabel className="flex-space-between">
											<span>Is Subpage of:</span>
										</InputLabel>
										<InputField
											type="select"
											name="parent_page"
											value={page.parent_page || ""}
											onChange={e => this.onChangeCustomPage(e, i)}
										>
											{listOfParentPages}
										</InputField>
									</Col>
								</Row>

								<Row>
									<Col xs={12} sm={12} md={12} className="field-wrapper">
										<InputLabel className="flex-space-between">
											<span>Sub Title</span>
										</InputLabel>
										<InputField
											type="text"
											name="sub_title"
											value={page.sub_title || ""}
											onChange={e => this.onChangeCustomPage(e, i)}
										/>
									</Col>
								</Row>

								<Row>
									<Col xs={12} sm={12} md={12} className="field-wrapper">
										<InputLabel cssClass="custom-label">
											Page Content Top
										</InputLabel>
										{this.state.editorsInitialized.includes(
											`editorRef${i}CustomSubTopPage`
										) && (
											<Editor
												onInit={(evt, editor) =>
													(this[`editorRef${i}CustomSubTopPage`] = editor)
												}
												init={this.returnEditorConfig(data)}
												onEditorChange={value =>
													this.handleCustomTopPageMsg(value, i)
												}
												value={page.page_content_top || page.description || ""}
											/>
										)}
										{!this.state.editorsInitialized.includes(
											`editorRef${i}CustomSubTopPage`
										) && (
											<Fragment>
												<span className="span-divider">|</span>
												<a
													className="link-text underline"
													href=""
													onClick={e => {
														e.preventDefault();
														this.handleAddEditorInitialized(
															`editorRef${i}CustomSubTopPage`
														);
													}}
												>
													{page.page_content_top || page.description
														? "Edit Page Content"
														: "Add Page Content"}
												</a>
												<Row>
													<div
														className={`mce-content-body ${
															this.state.editorsInitialized.includes(
																`editorRef${i}CustomSubTopPage`
															)
																? "edit"
																: "preview"
														}`}
														dangerouslySetInnerHTML={{
															__html:
																page.page_content_top || page.description || ""
														}}
													></div>
												</Row>
											</Fragment>
										)}
									</Col>
								</Row>

								{/* start show_properties */}
								<Row>
									<Col xs={12} sm={12} md={2} className="field-wrapper">
										<InputField
											cssClass="checkbox-label"
											type="checkbox"
											name="show_properties"
											value={
												page.show_properties ? page.show_properties : false
											}
											cbChange={e => {
												this.onChangeCustomPage(e, i);
											}}
										>
											Show Listings
											{this.showInstructions("show-listings")}
										</InputField>
									</Col>
									{page.show_properties && (
										<Fragment>
											<Col xs={12} sm={12} md={1} className="field-wrapper">
												Filter By:
												<br />
												<span className="small muted">(optional)</span>
											</Col>

											{/* Properties by City */}
											<Col xs={12} sm={12} md={2} className="field-wrapper">
												<InputField
													type="select"
													name="property_filter_city"
													value={page.property_filter_city || ""}
													onChange={e => {
														this.onChangeCustomPage(e, i);
													}}
												>
													{this.cityOptions}
												</InputField>
											</Col>

											{/* property_filter_beds */}
											<Col xs={12} sm={12} md={2} className="field-wrapper">
												<InputField
													type="select"
													name="property_filter_beds"
													value={page.property_filter_beds || ""}
													onChange={e => {
														this.onChangeCustomPage(e, i);
													}}
												>
													{this.bedsOptions}
												</InputField>
											</Col>

											{/* Properties by Keywords */}
											<Col xs={12} sm={12} md={3} className="field-wrapper">
												<InputField
													type="text"
													name="property_filter_keywords"
													placeholder="Contain Keywords"
													value={
														page.property_filter_keywords
															? page.property_filter_keywords
															: ""
													}
													onChange={e => {
														this.onChangeCustomPage(e, i);
													}}
												/>
											</Col>

											{/* Properties Pets OK */}
											<Col xs={12} sm={12} md={2} className="field-wrapper">
												<InputField
													type="checkbox"
													name="property_filter_pets_ok"
													value={
														page.property_filter_pets_ok
															? page.property_filter_pets_ok
															: false
													}
													cbChange={e => {
														this.onChangeCustomPage(e, i);
													}}
												>
													Pets Allowed
												</InputField>
											</Col>
										</Fragment>
									)}
								</Row>
								{/* end show_properties */}

								{page.show_properties && (
									<Row>
										<Col xs={12} sm={12} md={12} className="field-wrapper">
											<InputLabel cssClass="custom-label">
												Page Content Bottom
											</InputLabel>
											{this.state.editorsInitialized.includes(
												`editorRef${i}CustomSubBottomPage`
											) && (
												<Editor
													onInit={(evt, editor) =>
														(this[`editorRef${i}CustomSubBottomPage`] = editor)
													}
													init={this.returnEditorConfig(data)}
													onEditorChange={value =>
														this.handleCustomBottomPageMsg(value, i)
													}
													value={page.page_content_bottom || ""}
												/>
											)}
											{!this.state.editorsInitialized.includes(
												`editorRef${i}CustomSubBottomPage`
											) && (
												<Fragment>
													<span className="span-divider">|</span>
													<a
														className="link-text underline"
														href=""
														onClick={e => {
															e.preventDefault();
															this.handleAddEditorInitialized(
																`editorRef${i}CustomSubBottomPage`
															);
														}}
													>
														{page.page_content_bottom
															? "Edit Page Content"
															: "Add Page Content"}
													</a>
													<Row>
														<div
															className={`mce-content-body ${
																this.state.editorsInitialized.includes(
																	`editorRef${i}CustomSubBottomPage`
																)
																	? "edit"
																	: "preview"
															}`}
															dangerouslySetInnerHTML={{
																__html: page.page_content_bottom || ""
															}}
														></div>
													</Row>
												</Fragment>
											)}
										</Col>
									</Row>
								)}
							</Fragment>
						)}
					</div>
				</Tab>
			);
		});
	};

	subPageTabEditing = (
		i,
		page,
		tabTitle,
		parentPageTitle,
		listOfParentPagesWithSubPages,
		data
	) => {
		return (
			<Tab key={i + 1} eventKey={i + 1} title={tabTitle}>
				<div className="tab-container">
					<Row>
						<Col xs={12} sm={12} md={12} className="field-wrapper">
							<InputField
								type="hidden"
								name="parent_page"
								value={parentPageTitle || ""}
								onChange={e => this.onChangeCustomPage(e, i)}
							/>
							<InputLabel cssClass="custom-label">
								<span>Page Header Image</span>
							</InputLabel>

							{this.state.editorsInitialized.includes(
								`editorRef${i}CustomHeroImage`
							) &&
							(!page.page_content_hero_image ||
								!page.page_content_hero_image.length) ? (
								<Editor
									onInit={(evt, editor) =>
										(this[`editorRef${i}CustomHeroImage`] = editor)
									}
									init={this.returnEditorConfig(data, {
										i,
										handleCustomHeroPageImage: true
									})}
									value={""}
								/>
							) : (
								(!page.page_content_hero_image ||
									!page.page_content_hero_image.length) && (
									<Fragment>
										<span className="span-divider">|</span>
										<a
											className="link-text underline"
											href=""
											onClick={e => {
												e.preventDefault();
												this.handleAddEditorInitialized(
													`editorRef${i}CustomHeroImage`
												);
											}}
										>
											Add Hero Image
										</a>
									</Fragment>
								)
							)}

							{page.page_content_hero_image &&
								page.page_content_hero_image.length && (
									<Fragment>
										<span className="span-divider">|</span>
										<a
											className="link-text underline"
											href=""
											onClick={e => {
												e.preventDefault();
												this.handleRemoveCustomHeroPageImage(i);
												this.handleRemoveEditorInitialized(
													`editorRef${i}CustomHeroImage`
												);
											}}
										>
											Remove Header Image
										</a>
										<Row>
											<div
												className="col-md-12"
												dangerouslySetInnerHTML={{
													__html:
														page.page_content_hero_image &&
														page.page_content_hero_image.length
															? `<img style="max-height: 170px; max-width: 240px;" src="${page.page_content_hero_image}" />`
															: ""
												}}
											></div>
										</Row>
									</Fragment>
								)}
						</Col>
					</Row>

					<Row>
						<Col xs={12} sm={12} md={12} className="field-wrapper">
							<InputLabel className="flex-space-between">
								<span>Title</span>
								{!listOfParentPagesWithSubPages.includes(page.title) && (
									<OverlayTrigger
										placement="top"
										overlay={this.getTooltip(
											"delete",
											`Delete Custom Page: ${page.title}`
										)}
									>
										<span
											className="pointer"
											onClick={() => this.deleteCustomPage(i)}
										>
											<i className="icon icon-Delete"></i>
										</span>
									</OverlayTrigger>
								)}
							</InputLabel>
							<InputField
								type="text"
								name="title"
								value={page.title || ""}
								onChange={e => this.onChangeCustomPage(e, i)}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12} sm={12} md={12} className="field-wrapper">
							<InputLabel className="flex-space-between">
								<span>Sub Title</span>
							</InputLabel>
							<InputField
								type="text"
								name="sub_title"
								value={page.sub_title || ""}
								onChange={e => this.onChangeCustomPage(e, i)}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12} sm={12} md={12} className="field-wrapper">
							<InputLabel cssClass="custom-label">Page Content</InputLabel>
							{this.state.editorsInitialized.includes(
								`editorRef${i}CustomTopPage`
							) && (
								<Editor
									onInit={(evt, editor) =>
										(this[`editorRef${i}CustomTopPage`] = editor)
									}
									init={this.returnEditorConfig(data)}
									onEditorChange={value =>
										this.handleCustomTopPageMsg(value, i)
									}
									value={page.page_content_top || page.description || ""}
								/>
							)}
							{!this.state.editorsInitialized.includes(
								`editorRef${i}CustomTopPage`
							) && (
								<Fragment>
									<span className="span-divider">|</span>
									<a
										className="link-text underline"
										href=""
										onClick={e => {
											e.preventDefault();
											this.handleAddEditorInitialized(
												`editorRef${i}CustomTopPage`
											);
										}}
									>
										{page.page_content_top || page.description
											? "Edit Page Content"
											: "Add Page Content"}
									</a>
									<Row>
										<div
											className={`mce-content-body ${
												this.state.editorsInitialized.includes(
													`editorRef${i}CustomTopPage`
												)
													? "edit"
													: "preview"
											}`}
											dangerouslySetInnerHTML={{
												__html: page.page_content_top || page.description || ""
											}}
										></div>
									</Row>
								</Fragment>
							)}
						</Col>
					</Row>

					{/* start show_properties */}
					<Row>
						<Col xs={12} sm={12} md={2} className="field-wrapper">
							<InputField
								cssClass="checkbox-label"
								type="checkbox"
								name="show_properties"
								value={page.show_properties ? page.show_properties : false}
								cbChange={e => {
									this.onChangeCustomPage(e, i);
								}}
							>
								Show Listings
								{this.showInstructions("show-listings")}
							</InputField>
						</Col>
						{page.show_properties && (
							<Fragment>
								<Col xs={12} sm={12} md={1} className="field-wrapper">
									Filter By:
									<br />
									<span className="small muted">(optional)</span>
								</Col>

								{/* Properties by City */}
								<Col xs={12} sm={12} md={2} className="field-wrapper">
									<InputField
										type="select"
										name="property_filter_city"
										value={page.property_filter_city}
										onChange={e => {
											this.onChangeCustomPage(e, i);
										}}
									>
										{this.cityOptions}
									</InputField>
								</Col>

								{/* property_filter_beds */}
								<Col xs={12} sm={12} md={2} className="field-wrapper">
									<InputField
										type="select"
										name="property_filter_beds"
										value={page.property_filter_beds}
										onChange={e => {
											this.onChangeCustomPage(e, i);
										}}
									>
										{this.bedsOptions}
									</InputField>
								</Col>

								{/* Properties by Keywords */}
								<Col xs={12} sm={12} md={3} className="field-wrapper">
									<InputField
										type="text"
										name="property_filter_keywords"
										placeholder="Contain Keywords"
										value={
											page.property_filter_keywords
												? page.property_filter_keywords
												: ""
										}
										onChange={e => {
											this.onChangeCustomPage(e, i);
										}}
									/>
								</Col>

								{/* Properties Pets OK */}
								<Col xs={12} sm={12} md={2} className="field-wrapper">
									<InputField
										type="checkbox"
										name="property_filter_pets_ok"
										value={
											page.property_filter_pets_ok
												? page.property_filter_pets_ok
												: false
										}
										cbChange={e => {
											this.onChangeCustomPage(e, i);
										}}
									>
										Pets Allowed
									</InputField>
								</Col>
							</Fragment>
						)}
					</Row>
					{/* end show_properties */}

					{page.show_properties && (
						<Row>
							<Col xs={12} sm={12} md={12} className="field-wrapper">
								<InputLabel cssClass="custom-label">
									Content Below Listings
								</InputLabel>
								{this.state.editorsInitialized.includes(
									`editorRef${i}CustomBottomPage`
								) && (
									<Editor
										onInit={(evt, editor) =>
											(this[`editorRef${i}CustomBottomPage`] = editor)
										}
										init={this.returnEditorConfig(data)}
										onEditorChange={value =>
											this.handleCustomBottomPageMsg(value, i)
										}
										value={page.page_content_bottom}
									/>
								)}
								{!this.state.editorsInitialized.includes(
									`editorRef${i}CustomBottomPage`
								) && (
									<Fragment>
										<span className="span-divider">|</span>
										<a
											className="link-text underline"
											href=""
											onClick={e => {
												e.preventDefault();
												this.handleAddEditorInitialized(
													`editorRef${i}CustomBottomPage`
												);
											}}
										>
											{page.page_content_bottom
												? "Edit Page Content"
												: "Add Page Content"}
										</a>
										<Row>
											<div
												className={`mce-content-body ${
													this.state.editorsInitialized.includes(
														`editorRef${i}CustomBottomPage`
													)
														? "edit"
														: "preview"
												}`}
												dangerouslySetInnerHTML={{
													__html: page.page_content_bottom || ""
												}}
											></div>
										</Row>
									</Fragment>
								)}
							</Col>
						</Row>
					)}
				</div>
			</Tab>
		);
	};

	// Sub Page
	customSubPageTabs = parentPageTitle => {
		const { data } = this.state;
		let listOfParentPagesWithSubPages = [];

		data.custom_pages.map(page => {
			if (page && page.parent_page && page.parent_page.length) {
				if (!listOfParentPagesWithSubPages.includes(page.parent_page)) {
					listOfParentPagesWithSubPages.push(page.parent_page);
				}
			}
		});

		return data.custom_pages.map((page, i) => {
			if (
				(page && page.parent_page && page.parent_page !== parentPageTitle) ||
				(!page.parent_page && parentPageTitle !== page.title)
			) {
				return;
			}

			let tabTitle = "* New Custom Page *";
			if (
				page.title &&
				page.title.length &&
				parentPageTitle &&
				parentPageTitle.length
			) {
				if (parentPageTitle === page.title) {
					tabTitle = page.title;
				} else {
					tabTitle = `${parentPageTitle} / ${page.title}`;
				}
			} else if (parentPageTitle && parentPageTitle.length) {
				tabTitle = `${parentPageTitle} / * New Custom Sub Page *`;
			}

			return this.subPageTabEditing(
				i,
				page,
				tabTitle,
				parentPageTitle,
				listOfParentPagesWithSubPages,
				data
			);
		});
	};

	render() {
		const {
			data,
			isLoading,
			fileName,
			customDomain,
			websiteFetchError,
			activeKey,
			primaryColorPicker,
			textColorPicker,
			secondaryColorPicker,
			domainPath,
			isSending,
			completedTask,
			submitStatus,
			error,
			modalReset,
			modal,
			validations,
			tabsActiveKey,
			tabsSubActiveKey,
			taskCount
		} = this.state;

		const divider = (
			<Row>
				<Col xs={12}>
					<div className="h-divider"></div>
				</Col>
			</Row>
		);
		const imageFormats = constants.IMAGE_FORMATS.toString();
		const customDomainStatus =
			data && data.domains && data.domains[1] && data.domains[1].status;
		const customDomainVerCname =
			data &&
			data.domains &&
			data.domains[1] &&
			data.domains[1].verification_cname;
		const customDomainVerValue =
			data &&
			data.domains &&
			data.domains[1] &&
			data.domains[1].verification_value;
		const customDomainStatusLabel = {
			active: "Active",
			certificate_pending: "Pending",
			certificate_failed: "Failed"
		};

		let isMaxParentPages = false;
		let maxParentPages = 3;
		let parentPagesCount = 0;
		data.custom_pages.map(page => {
			if (
				(page && !page.parent_page) ||
				(!page.parent_page.length && parentPagesCount <= maxParentPages)
			) {
				parentPagesCount += 1;
			}
			if (parentPagesCount >= maxParentPages) {
				isMaxParentPages = true;
			}
		});

		let tinyMCEConfigString = JSON.stringify(tinyMCEConfig);
		tinyMCEConfigString = tinyMCEConfigString.replace(/\\n/g, "");
		tinyMCEConfigString = tinyMCEConfigString.replace(/\\t/g, "");
		tinyMCEConfigString = tinyMCEConfigString.replaceAll("}", "} \r");

		const customStyles = `:root {
      --primary-color: ${data.primary_color};
      --secondary-color: ${data.secondary_color};
    }`;

		return (
			<div className="main-container manage-website-section">
				<style type="text/css">
					{`${customStyles} ${tinyMCEConfigString.slice(1, -1)}`}
				</style>
				<Loader hide={isLoading ? false : true} />
				<div id="content">
					<Title
						title="Manage Website"
						isCentered={false}
						breadcrumbItems={this.breadcrumbItems}
					>
						<div className={`pull-right${data && data.id ? "" : " hidden"}`}>
							{this.state.buildStatus &&
								this.state.buildStatus !== "complete" &&
								this.state.buildStatus !== "completed" &&
								this.buildStatusLexicon &&
								this.buildStatusLexicon[this.state.buildStatus] !== "" && (
									<span
										style={{
											fontSize: "14px",
											fontWeight: "bold",
											marginRight: "10px"
										}}
									>
										&nbsp;&nbsp;Build Status: &nbsp;&nbsp;
										<span
											className={`font-italic ${
												this.state.buildStatus === "error"
													? "text-danger"
													: "text-success"
											}`}
										>
											{this.buildStatusLexicon[this.state.buildStatus]}
										</span>
									</span>
								)}
							{data.default_domain &&
								!websiteFetchError &&
								this.state.buildStatus !== "provisioning" &&
								(this.state.buildStatus === "complete" ||
									this.state.buildStatus === "completed") && (
									<PrimaryButton
										type="link"
										target="_blank"
										href={"https://" + data.default_domain}
										cssClass="white-btn preview-btn"
										fullWidth={false}
									>
										Preview Selected Theme
									</PrimaryButton>
								)}
							{!websiteFetchError && this.state.buildStatus !== "provisioning" && (
								<PrimaryButton
									cssClass="save-btn"
									type="button"
									fullWidth={false}
									onClick={this.handleFormSubmit}
									disabled={
										!(
											this.state.buildStatus === "complete" ||
											this.state.buildStatus === "completed" ||
											this.state.buildStatus === "error"
										)
									}
								>
									Save
								</PrimaryButton>
							)}
						</div>
					</Title>
					<div className="property-item row">
						{websiteFetchError ? (
							<Col md={12}>
								<Col md={12}>
									<span>Oops something going wrong Error in fetch website</span>
								</Col>
							</Col>
						) : (
							<Fragment>
								<FieldWrap>
									<Form horizontal>
										<Row className="row-padding">
											<Col xs={12}>
												<div>
													<PanelGroup
														accordion
														className="property-item reports"
														id="reports"
														defaultActiveKey={1}
														activeKey={activeKey}
														onSelect={activeKey => {
															this.setState({
																activeKey,
																tabsActiveKey: "homePageTab",
																tabsSubActiveKey: 1
															});
														}}
													>
														{/* Start Site */}
														{!this.state.isLoading && (!data || !data.id) ? (
															<Panel eventKey={1}>
																<Panel.Body>
																	<Row>
																		<Col
																			xs={12}
																			sm={6}
																			md={6}
																			className="field-wrapper"
																		>
																			<Row>
																				<Col
																					xs={12}
																					sm={12}
																					md={12}
																					className="field-wrapper"
																				>
																					<InputField
																						type="text"
																						placeholder="Enter a title for the website"
																						name="title"
																						value={data.title || ""}
																						onChange={this.handleChange}
																					/>
																				</Col>
																				<Col
																					xs={12}
																					sm={12}
																					md={12}
																					className="field-wrapper"
																				>
																					<PrimaryButton
																						cssClass="save-btn"
																						type="button"
																						fullWidth={false}
																						onClick={this.handleFormSubmit}
																					>
																						Create Website
																					</PrimaryButton>
																				</Col>
																			</Row>
																		</Col>
																	</Row>
																</Panel.Body>
															</Panel>
														) : (
															<Fragment>
																<Panel
																	eventKey={1}
																	className={`${
																		data && data.id ? "" : "hidden"
																	}`}
																>
																	<Panel.Heading>
																		<Panel.Title toggle>
																			{this.panelTitle("Website Settings", 1)}
																		</Panel.Title>
																	</Panel.Heading>
																	<Panel.Collapse>
																		<Panel.Body>
																			<Row>
																				<Col xs={12} className="field-wrapper">
																					<Row>
																						<Col
																							xs={12}
																							sm={12}
																							md={4}
																							className="field-wrapper"
																						>
																							<InputLabel
																								htmlFor="title"
																								cssClass="custom-label"
																							>
																								Website Title
																								{this.showInstructions(
																									"website-title"
																								)}
																							</InputLabel>
																							<InputField
																								type="text"
																								placeholder="Website title displayed in the banner"
																								name="title"
																								value={data.title || ""}
																								onChange={this.handleChange}
																							/>
																						</Col>
																						<Col xs={12} md={8}>
																							<InputField
																								type="checkbox"
																								value={data.show_filters}
																								name="show_filters"
																								cbChange={this.handleChange}
																							>
																								Show Search
																								{this.showInstructions(
																									"show-filters"
																								)}
																							</InputField>
																							<InputField
																								type="checkbox"
																								value={data.show_chat}
																								name="show_chat"
																								cbChange={this.handleChange}
																							>
																								Show Chat
																								{this.showInstructions(
																									"show-chat"
																								)}
																							</InputField>
																							<InputField
																								type="checkbox"
																								value={data.include_child_orgs}
																								name="include_child_orgs"
																								cbChange={this.handleChange}
																							>
																								Show All Organizations
																								{this.showInstructions(
																									"include-child-orgs"
																								)}
																							</InputField>
																							<InputField
																								type="checkbox"
																								value={data.show_map}
																								name="show_map"
																								cbChange={this.handleChange}
																							>
																								Show Map View
																								{this.showInstructions(
																									"show-map"
																								)}
																							</InputField>
																							<InputField
																								type="checkbox"
																								value={data.accept_promo_codes}
																								name="accept_promo_codes"
																								cbChange={this.handleChange}
																							>
																								Accept Promotional Codes
																								{this.showInstructions(
																									"accept-promo-codes"
																								)}
																							</InputField>
																							<InputField
																								type="checkbox"
																								value={data.show_pagination}
																								name="show_pagination"
																								cbChange={this.handleChange}
																							>
																								Show Pagination
																								{this.showInstructions(
																									"show-pagination"
																								)}
																							</InputField>
																						</Col>
																					</Row>
																					{data.show_pagination == true && (
																						<Row>
																							<Col xs={12}>
																								<hr />
																							</Col>
																							<Col xs={12} lg={4}>
																								<InputLabel
																									htmlFor="propertyDisplayCount"
																									cssClass="custom-label"
																								>
																									Number of Properties to
																									Display Per Page
																								</InputLabel>
																								<div className="range-slider-container">
																									<InputField
																										id="propertyDisplayCount"
																										type="range"
																										value={
																											data.property_display_count ||
																											"9"
																										}
																										min="1"
																										max="100"
																										name="property_display_count"
																										onChange={this.handleChange}
																									/>
																									<span className="range-slider-count">
																										{data.property_display_count ||
																											"9"}
																									</span>
																								</div>
																							</Col>
																						</Row>
																					)}
																				</Col>
																			</Row>
																			{divider}
																			<Row>
																				<Col xs={12} sm={12} md={12}>
																					<div className="col-heading">
																						<h2>Logo & Images:</h2>
																					</div>
																				</Col>
																			</Row>
																			<Row>
																				<Col
																					xs={12}
																					sm={6}
																					md={4}
																					className="field-wrapper"
																				>
																					<InputLabel
																						htmlFor="favicon_image"
																						cssClass="custom-label"
																					>
																						Square Favicon Image
																						{this.showInstructions(
																							"favicon-image"
																						)}
																					</InputLabel>
																					<div className="file-input-wrap">
																						<Dropzone
																							style={{ height: "32px" }}
																							multiple={false}
																							accept={imageFormats}
																							onDrop={file =>
																								this.updateImage(
																									file,
																									"favicon_image"
																								)
																							}
																						>
																							<div className="file-input">
																								<span className="file-name">
																									{fileName.favicon_image
																										.name ||
																										"Upload Favicon Image"}
																								</span>
																								<span className="browse-btn">
																									Browse
																								</span>
																							</div>
																						</Dropzone>
																					</div>
																					<div className="image-preview">
																						{data.favicon_image &&
																						(data.favicon_image.url ||
																							data.favicon_image.data) ? (
																							<img
																								id="favicon_image"
																								onLoad={this.getDimensions}
																								src={
																									data.favicon_image.url
																										? (data.favicon_image.url.indexOf(
																												"http"
																										  ) < 0
																												? domainPath
																												: "") +
																										  data.favicon_image.url
																										: `data:${data.favicon_image.data_type};base64,${data.favicon_image.data}`
																								}
																								alt="Error Loading Image"
																							/>
																						) : (
																							<span>
																								<Glyphicon glyph="picture" />{" "}
																								Image Preview
																							</span>
																						)}
																					</div>
																					<div className="image-dimensions">
																						{fileName.favicon_image.dimensions}
																					</div>
																				</Col>
																				<Col
																					xs={12}
																					sm={6}
																					md={4}
																					className="field-wrapper"
																				>
																					<InputLabel
																						htmlFor="logo_image"
																						cssClass="custom-label"
																					>
																						Logo Image
																						{this.showInstructions(
																							"logo-image"
																						)}
																					</InputLabel>
																					<div className="file-input-wrap">
																						<Dropzone
																							style={{ height: "32px" }}
																							multiple={false}
																							accept={imageFormats}
																							onDrop={file =>
																								this.updateImage(
																									file,
																									"logo_image"
																								)
																							}
																						>
																							<div className="file-input">
																								<span className="file-name">
																									{fileName.logo_image.name ||
																										"Upload Logo Image"}
																								</span>
																								<span className="browse-btn">
																									Browse
																								</span>
																							</div>
																						</Dropzone>
																					</div>
																					<div className="image-preview">
																						{data.logo_image &&
																						(data.logo_image.url ||
																							data.logo_image.data) ? (
																							<img
																								id="logo_image"
																								onLoad={this.getDimensions}
																								src={
																									data.logo_image.url
																										? (data.logo_image.url.indexOf(
																												"http"
																										  ) < 0
																												? domainPath
																												: "") +
																										  data.logo_image.url
																										: `data:${data.logo_image.data_type};base64,${data.logo_image.data}`
																								}
																								alt="Error Loading Image"
																							/>
																						) : (
																							<span>
																								<Glyphicon glyph="picture" />{" "}
																								Image Preview
																							</span>
																						)}
																					</div>
																					<div className="image-dimensions">
																						{fileName.logo_image.dimensions}
																					</div>
																				</Col>
																				<Col
																					xs={12}
																					sm={6}
																					md={4}
																					className="field-wrapper"
																				>
																					<InputLabel
																						htmlFor="hero_image"
																						cssClass="custom-label"
																					>
																						Header Background Image
																						{this.showInstructions(
																							"hero-image"
																						)}
																					</InputLabel>
																					<div className="file-input-wrap">
																						<Dropzone
																							style={{ height: "32px" }}
																							multiple={false}
																							accept={imageFormats}
																							onDrop={file =>
																								this.updateImage(
																									file,
																									"hero_image"
																								)
																							}
																						>
																							<div className="file-input">
																								<span className="file-name">
																									{fileName.hero_image.name ||
																										"Upload Header Image"}
																								</span>
																								<span className="browse-btn">
																									Browse
																								</span>
																							</div>
																						</Dropzone>
																					</div>
																					<div className="image-preview">
																						{data.hero_image &&
																						(data.hero_image.url ||
																							data.hero_image.data) ? (
																							<img
																								id="hero_image"
																								onLoad={this.getDimensions}
																								src={
																									data.hero_image.url
																										? (data.hero_image.url.indexOf(
																												"http"
																										  ) < 0
																												? domainPath
																												: "") +
																										  data.hero_image.url
																										: `data:${data.hero_image.data_type};base64,${data.hero_image.data}`
																								}
																								alt="Error Loading Image"
																							/>
																						) : (
																							<span>
																								<Glyphicon glyph="picture" />
																								Image Preview
																							</span>
																						)}
																					</div>
																					<div className="image-dimensions">
																						{fileName.hero_image.dimensions}
																					</div>
																				</Col>
																			</Row>
																			{divider}
																			<Row>
																				<Col xs={12} sm={12} md={12}>
																					<Row>
																						<Col xs={12}>
																							<div className="col-heading">
																								<h2>Theme Colors:</h2>
																							</div>
																						</Col>
																					</Row>
																				</Col>
																				<Col xs={12} sm={12} md={12}>
																					<Row className="custom-theme-picker">
																						<Col
																							xs={12}
																							sm={6}
																							md={6}
																							lg={3}
																							xl={3}
																							className="color-picker-col"
																						>
																							<InputLabel
																								htmlFor="theme_type"
																								cssClass="custom-label"
																							>
																								Default Themes
																							</InputLabel>
																							<div className="color-picker">
																								<label>
																									<input
																										type="radio"
																										name="theme_type"
																										checked={
																											data.theme_type ===
																											"theme-1"
																										}
																										value="theme-1"
																										onChange={this.handleChange}
																									/>
																									<span className="color-bubble theme1-bg"></span>
																								</label>
																								<label>
																									<input
																										type="radio"
																										name="theme_type"
																										checked={
																											data.theme_type ===
																											"theme-2"
																										}
																										value="theme-2"
																										onChange={this.handleChange}
																									/>
																									<span className="color-bubble theme2-bg"></span>
																								</label>
																								<label>
																									<input
																										type="radio"
																										name="theme_type"
																										checked={
																											data.theme_type ===
																											"theme-3"
																										}
																										value="theme-3"
																										onChange={this.handleChange}
																									/>
																									<span className="color-bubble theme3-bg"></span>
																								</label>
																								<label>
																									<input
																										type="radio"
																										name="theme_type"
																										checked={
																											data.theme_type ===
																											"theme-4"
																										}
																										value="theme-4"
																										onChange={this.handleChange}
																									/>
																									<span className="color-bubble theme4-bg"></span>
																								</label>
																								<label>
																									<input
																										type="radio"
																										name="theme_type"
																										checked={
																											data.theme_type ===
																											"theme-5"
																										}
																										value="theme-5"
																										onChange={this.handleChange}
																									/>
																									<span className="color-bubble theme5-bg"></span>
																								</label>
																							</div>
																						</Col>
																						<Col
																							xs={12}
																							sm={6}
																							md={6}
																							lg={3}
																							xl={3}
																							className="color-picker-col"
																						>
																							<InputLabel
																								htmlFor="primary_color"
																								cssClass="custom-label"
																							>
																								Primary Color
																							</InputLabel>
																							<div className="display-flex">
																								<InputField
																									className="form-width-80"
																									type="text"
																									placeholder="Primary Color"
																									name="primary_color"
																									id="primaryColorPicker"
																									value={
																										data.primary_color || ""
																									}
																									onChange={this.handleChange}
																									onFocus={
																										this
																											.handleColorPickerOnFocus
																									}
																									onBlur={
																										this.handleColorPickerOnBlur
																									}
																								/>
																								<span
																									className="picker-hue"
																									style={{
																										backgroundColor:
																											data.primary_color
																									}}
																									onClick={
																										this
																											.handlePrimaryColorPicker
																									}
																								></span>
																								<span
																									className="picker-tint"
																									style={{
																										backgroundColor:
																											data.primary_color_tint
																									}}
																								></span>
																							</div>
																							{primaryColorPicker ? (
																								<div
																									ref={
																										this.primaryColorPickerRef
																									}
																								>
																									<SketchPicker
																										color={data.primary_color}
																										onChange={
																											this
																												.handleChangeColorPicker
																										}
																									/>
																								</div>
																							) : null}
																						</Col>
																						<Col
																							xs={12}
																							sm={6}
																							md={6}
																							lg={3}
																							xl={3}
																							className="color-picker-col"
																						>
																							<InputLabel
																								htmlFor="secondary_color"
																								cssClass="custom-label"
																							>
																								Secondary Color
																							</InputLabel>
																							<div className="display-flex">
																								<InputField
																									className="form-width-80"
																									type="text"
																									placeholder="Secondary Color"
																									name="secondary_color"
																									id="secondaryColorPicker"
																									value={
																										data.secondary_color || ""
																									}
																									onChange={this.handleChange}
																									onFocus={
																										this
																											.handleColorPickerOnFocus
																									}
																									onBlur={
																										this.handleColorPickerOnBlur
																									}
																								/>
																								<span
																									className="picker-hue"
																									style={{
																										backgroundColor:
																											data.secondary_color
																									}}
																									onClick={
																										this
																											.handleSecondaryColorPicker
																									}
																								></span>
																								<span
																									className="picker-tint"
																									style={{
																										backgroundColor:
																											data.secondary_color_tint
																									}}
																								></span>
																							</div>
																							{secondaryColorPicker ? (
																								<div
																									ref={
																										this.secondaryColorPickerRef
																									}
																								>
																									<SketchPicker
																										color={data.secondary_color}
																										onChange={
																											this
																												.handleChangeSecondaryColorPicker
																										}
																									/>
																								</div>
																							) : null}
																						</Col>
																						<Col
																							xs={12}
																							sm={6}
																							md={6}
																							lg={3}
																							xl={3}
																							className="color-picker-col"
																						>
																							<InputLabel
																								htmlFor="text_color"
																								cssClass="custom-label"
																							>
																								Text Color
																							</InputLabel>
																							<div className="display-flex">
																								<InputField
																									className="form-width-80"
																									type="text"
																									placeholder="Text Color"
																									name="text_color"
																									id="textColorPicker"
																									value={data.text_color || ""}
																									onChange={this.handleChange}
																									onFocus={
																										this
																											.handleColorPickerOnFocus
																									}
																									onBlur={
																										this.handleColorPickerOnBlur
																									}
																								/>
																								<span
																									className="picker-hue"
																									style={{
																										backgroundColor:
																											data.text_color
																									}}
																									onClick={
																										this.handleTextColorPicker
																									}
																								></span>
																								<span
																									className="picker-hue"
																									style={{
																										backgroundColor:
																											data.text_color
																									}}
																									onClick={
																										this.handleTextColorPicker
																									}
																								></span>
																							</div>
																							{textColorPicker ? (
																								<div
																									ref={this.textColorPickerRef}
																								>
																									<SketchPicker
																										color={data.text_color}
																										onChange={
																											this
																												.handleChangeTextColorPicker
																										}
																									/>
																								</div>
																							) : null}
																						</Col>
																					</Row>
																				</Col>
																			</Row>
																			{divider}
																			<Row>
																				<Col xs={12} sm={12} md={12}>
																					<div className="col-heading">
																						<h2>Optional Template Settings:</h2>
																					</div>
																				</Col>
																			</Row>
																			<Row>
																				<Col xs={12}>
																					<div className="display-inline-block right-margin top-margin-10">
																						<InputLabel
																							htmlFor="template_id"
																							cssClass="custom-label display-block"
																						>
																							&nbsp;&nbsp;Choose Your Template:
																							{this.showInstructions(
																								"smartsite-template"
																							)}
																						</InputLabel>
																						<ToggleButtonGroup
																							type="radio"
																							name="template_id"
																							value={data.template_id}
																							onChange={value =>
																								this.handleTemplateChange(value)
																							}
																						>
																							<ToggleButton
																								id="template-id-radio-1"
																								value="template-1"
																							>
																								<OverlayTrigger
																									placement="top"
																									overlay={
																										<Tooltip id="Template-1">
																											<img
																												src="/app/assets/thumbs/theme1.png"
																												alt="Template 1"
																											/>
																										</Tooltip>
																									}
																								>
																									<span>Default Template</span>
																								</OverlayTrigger>
																							</ToggleButton>
																							<ToggleButton
																								id="template-id-radio-2"
																								value="template-2"
																							>
																								<OverlayTrigger
																									placement="top"
																									overlay={
																										<Tooltip id="Template-2">
																											<img
																												src="/app/assets/thumbs/theme2.png"
																												alt="Template 1"
																											/>
																										</Tooltip>
																									}
																								>
																									<span>
																										Spotlight Template
																									</span>
																								</OverlayTrigger>
																							</ToggleButton>
																						</ToggleButtonGroup>
																					</div>
																					<div className="display-inline-block right-margin top-margin-10">
																						{data.template_id !==
																						"template-2" ? (
																							<Fragment>
																								<InputLabel
																									htmlFor="layout_boundary_settings"
																									cssClass="custom-label display-block"
																								>
																									&nbsp;&nbsp;Choose Your Header
																									Layout:
																									{this.showInstructions(
																										"layout-boundary-settings"
																									)}
																								</InputLabel>
																								<ToggleButtonGroup
																									type="radio"
																									name="layout_boundary_settings"
																									value={
																										data.layout_boundary_settings
																											? data.layout_boundary_settings
																											: "default"
																									}
																									onChange={value =>
																										this.handleLayoutBoundaryChange(
																											value
																										)
																									}
																								>
																									<ToggleButton
																										id="layout-inset-page"
																										value="default"
																									>
																										<span>Default Width</span>
																									</ToggleButton>
																									<ToggleButton
																										id="layout-full-width-page"
																										value="full-width-page"
																									>
																										<span>Full Width</span>
																									</ToggleButton>
																								</ToggleButtonGroup>
																							</Fragment>
																						) : (
																							""
																						)}
																					</div>
																					<div className="display-inline-block right-margin top-margin-10">
																						{data.template_id !==
																						"template-2" ? (
																							<Fragment>
																								<InputLabel
																									htmlFor="layout_alignment"
																									cssClass="custom-label display-block"
																								>
																									&nbsp;&nbsp;Text Alignment:
																									{this.showInstructions(
																										"layout-alignment"
																									)}
																								</InputLabel>
																								<ToggleButtonGroup
																									type="radio"
																									name="layout_alignment"
																									value={
																										data.layout_alignment
																											? data.layout_alignment
																											: "left"
																									}
																									onChange={
																										this
																											.handleLayoutAlignmentChange
																									}
																								>
																									<ToggleButton
																										id="layout-alignment-default"
																										value="left"
																									>
																										<span>Left</span>
																									</ToggleButton>
																									<ToggleButton
																										id="layout-alignment-center"
																										value="center"
																									>
																										<span>Center</span>
																									</ToggleButton>
																								</ToggleButtonGroup>
																							</Fragment>
																						) : (
																							""
																						)}
																					</div>
																					<div className="display-inline-block right-margin top-margin-10">
																						<div>
																							<InputLabel
																								htmlFor="hero_style"
																								cssClass="custom-label"
																							>
																								&nbsp;&nbsp;Header Image
																								Treatment:
																								{this.showInstructions(
																									"hero-style"
																								)}
																							</InputLabel>
																						</div>
																						<ToggleButtonGroup
																							type="radio"
																							name="hero_style"
																							value={
																								data.hero_style
																									? data.hero_style
																									: "bg_light"
																							}
																							onChange={value =>
																								this.handleHeroStyleChange(
																									value
																								)
																							}
																						>
																							<ToggleButton value="bg_light">
																								<span>Light</span>
																							</ToggleButton>
																							<ToggleButton value="bg_dark">
																								<span>Dark</span>
																							</ToggleButton>
																							<ToggleButton value="bg_none">
																								<span>None</span>
																							</ToggleButton>
																						</ToggleButtonGroup>
																					</div>
																					<div className="display-inline-block right-margin top-margin-10">
																						<div>
																							<InputLabel
																								htmlFor="hero_background_position"
																								cssClass="custom-label"
																							>
																								&nbsp;&nbsp;Header Image
																								Position:
																								{this.showInstructions(
																									"hero-background-position"
																								)}
																							</InputLabel>
																						</div>
																						<ToggleButtonGroup
																							type="radio"
																							name="hero_background_position"
																							value={
																								data.hero_background_position
																									? data.hero_background_position
																									: "object-center"
																							}
																						>
																							<ToggleButton
																								id="hero-background-center"
																								value="object-center"
																							>
																								<span>Centered</span>
																							</ToggleButton>
																							<ToggleButton
																								id="hero-background-center-top"
																								value="object-top"
																							>
																								<span>Top</span>
																							</ToggleButton>
																							<ToggleButton
																								id="hero-background-left-top"
																								value="object-left-top"
																							>
																								<span>TL</span>
																							</ToggleButton>
																							<ToggleButton
																								id="hero-background-right-top"
																								value="object-right-top"
																							>
																								<span>TR</span>
																							</ToggleButton>
																							<ToggleButton
																								id="hero-background-center-bottom"
																								value="object-bottom"
																							>
																								<span>Bottom</span>
																							</ToggleButton>
																							<ToggleButton
																								id="hero-background-left-bottom"
																								value="object-left-bottom"
																							>
																								<span>BL</span>
																							</ToggleButton>
																							<ToggleButton
																								id="hero-background-right-bottom"
																								value="object-right-bottom"
																							>
																								<span>BR</span>
																							</ToggleButton>
																						</ToggleButtonGroup>
																					</div>
																					<div className="display-inline-block right-margin top-margin-10">
																						<div>
																							<InputLabel
																								htmlFor="hero_min_height"
																								cssClass="custom-label"
																							>
																								&nbsp;&nbsp;Header Height:
																								{this.showInstructions(
																									"hero-height"
																								)}
																							</InputLabel>
																						</div>
																						<ToggleButtonGroup
																							type="radio"
																							name="hero_min_height"
																							value={
																								data.hero_min_height
																									? data.hero_min_height
																									: "min-h-80"
																							}
																							onChange={value =>
																								this.setAttributeState(
																									"hero_min_height",
																									value
																								)
																							}
																						>
																							<ToggleButton value="min-h-80">
																								<span>S</span>
																							</ToggleButton>
																							<ToggleButton value="min-h-auto">
																								<span>M</span>
																							</ToggleButton>
																							<ToggleButton value="min-h-[60vh]">
																								<span>L</span>
																							</ToggleButton>
																							<ToggleButton value="min-h-screen">
																								<span>Full</span>
																							</ToggleButton>
																						</ToggleButtonGroup>
																					</div>
																					<div className="display-inline-block right-margin top-margin-10">
																						<div>
																							<InputLabel
																								htmlFor="homepage_hero_min_height"
																								cssClass="custom-label"
																							>
																								&nbsp;&nbsp;Homepage Header
																								Height:
																								{this.showInstructions(
																									"homepage-hero-height"
																								)}
																							</InputLabel>
																						</div>
																						<ToggleButtonGroup
																							type="radio"
																							name="homepage_hero_min_height"
																							value={
																								data.homepage &&
																								data.homepage.hero_min_height
																									? data.homepage
																											.hero_min_height
																									: "min-h-auto"
																							}
																							onChange={value =>
																								this.setAttributeState(
																									"homepage.hero_min_height",
																									value
																								)
																							}
																						>
																							<ToggleButton value="min-h-80">
																								<span>S</span>
																							</ToggleButton>
																							<ToggleButton value="min-h-auto">
																								<span>M</span>
																							</ToggleButton>
																							<ToggleButton value="min-h-[60vh]">
																								<span>L</span>
																							</ToggleButton>
																							<ToggleButton value="min-h-screen">
																								<span>Full</span>
																							</ToggleButton>
																						</ToggleButtonGroup>
																					</div>
																				</Col>
																			</Row>
																			{divider}
																			<Row>
																				<Col xs={12} sm={12} md={12}>
																					<div className="col-heading">
																						<h2>Social Links:</h2>
																					</div>
																				</Col>
																			</Row>
																			<Row>
																				<Col
																					xs={12}
																					sm={6}
																					lg={3}
																					className="field-wrapper"
																				>
																					<InputLabel htmlFor="facebook_link">
																						Facebook
																					</InputLabel>
																					<InputField
																						type="text"
																						placeholder="Facebook"
																						name="facebook_link"
																						onChange={this.handleChange}
																						value={data.facebook_link || ""}
																					/>
																				</Col>
																				<Col
																					xs={12}
																					sm={6}
																					lg={3}
																					className="field-wrapper"
																				>
																					<InputLabel htmlFor="twitter_link">
																						Twitter
																					</InputLabel>
																					<InputField
																						type="text"
																						placeholder="Twitter"
																						name="twitter_link"
																						onChange={this.handleChange}
																						value={data.twitter_link || ""}
																					/>
																				</Col>
																				<Col
																					xs={12}
																					sm={6}
																					lg={3}
																					className="field-wrapper"
																				>
																					<InputLabel htmlFor="instagram_link">
																						Instagram
																					</InputLabel>
																					<InputField
																						type="text"
																						placeholder="Instagram"
																						name="instagram_link"
																						onChange={this.handleChange}
																						value={data.instagram_link || ""}
																					/>
																				</Col>
																				<Col
																					xs={12}
																					sm={6}
																					lg={3}
																					className="field-wrapper"
																				>
																					<InputLabel htmlFor="youtube_link">
																						YouTube
																					</InputLabel>
																					<InputField
																						type="text"
																						placeholder="YouTube"
																						name="youtube_link"
																						onChange={this.handleChange}
																						value={data.youtube_link || ""}
																					/>
																				</Col>
																			</Row>
																			{divider}
																			<Row>
																				<Col xs={12} sm={12} md={12}>
																					<div className="col-heading">
																						<h2>Copyright &amp; SEO:</h2>
																					</div>
																				</Col>
																			</Row>
																			<Row>
																				<Col
																					xs={12}
																					lg={4}
																					className="field-wrapper"
																				>
																					<InputLabel htmlFor="copyright_line">
																						Copyright Text
																					</InputLabel>
																					<InputField
																						type="textarea"
																						name="copyright_line"
																						placeholder="Copyright Text"
																						value={data.copyright_line || ""}
																						onChange={this.handleChange}
																						cssClass="text-area"
																					/>
																				</Col>
																				<Col
																					xs={12}
																					lg={4}
																					className="field-wrapper"
																				>
																					<InputLabel
																						htmlFor="seo_keywords"
																						cssClass="custom-label"
																					>
																						SEO Keywords
																					</InputLabel>
																					<InputField
																						type="textarea"
																						name="seo_keywords"
																						placeholder="SEO Keywords"
																						value={data.seo_keywords || ""}
																						onChange={this.handleChange}
																						cssClass="text-area"
																					/>
																				</Col>
																				<Col
																					xs={12}
																					lg={4}
																					className="field-wrapper"
																				>
																					<InputLabel
																						htmlFor="seo_description"
																						cssClass="custom-label"
																					>
																						SEO Description
																					</InputLabel>
																					<InputField
																						cssClass="text-area"
																						type="textarea"
																						placeholder="SEO Description"
																						name="seo_description"
																						value={data.seo_description || ""}
																						onChange={this.handleChange}
																					/>
																				</Col>
																			</Row>
																		</Panel.Body>
																	</Panel.Collapse>
																</Panel>

																<Panel
																	eventKey={2}
																	className={`${
																		data && data.id ? "" : "hidden"
																	}`}
																>
																	<Panel.Heading>
																		<Panel.Title toggle>
																			{this.panelTitle("Content Page(s)", 2)}
																		</Panel.Title>
																	</Panel.Heading>
																	<Panel.Collapse>
																		<Panel.Body>
																			<Row>
																				<Col xs={12} className="row-heading">
																					<Tabs
																						activeKey={tabsActiveKey}
																						id="property-tabs"
																						onSelect={key =>
																							this.handleTabs(
																								key,
																								undefined,
																								undefined
																							)
																						}
																					>
																						{this.homePageTab()}
																						{this.mainTabs(
																							"listings",
																							"Listings"
																						)}
																						{this.mainTabs("about", "About")}
																						{this.customPageTabs(
																							tabsActiveKey,
																							tabsSubActiveKey,
																							data.title
																						)}
																						{!isMaxParentPages && (
																							<Tab
																								eventKey={"add"}
																								title={
																									<OverlayTrigger
																										placement="top"
																										overlay={this.getTooltip(
																											"add",
																											`Add a Custom Page`
																										)}
																									>
																										<span>
																											<i className="icon-Plus"></i>
																										</span>
																									</OverlayTrigger>
																								}
																							></Tab>
																						)}
																					</Tabs>
																				</Col>
																			</Row>
																		</Panel.Body>
																	</Panel.Collapse>
																</Panel>
																<Panel
																	eventKey={3}
																	className={`${
																		data && data.id ? "" : "hidden"
																	}`}
																>
																	<Panel.Heading>
																		<Panel.Title toggle>
																			{this.panelTitle(
																				"Contact Information",
																				3
																			)}
																		</Panel.Title>
																	</Panel.Heading>
																	<Panel.Collapse>
																		<Panel.Body>
																			<Row>
																				<Col
																					xs={12}
																					sm={6}
																					md={6}
																					className="field-wrapper"
																				>
																					<InputLabel htmlFor="first_name">
																						First Name
																					</InputLabel>
																					<InputField
																						type="text"
																						value={
																							data.contact_info.first_name || ""
																						}
																						placeholder="First Name"
																						name="first_name"
																						onChange={this.handleContactChange}
																					/>
																				</Col>
																				<Col
																					xs={12}
																					sm={6}
																					md={6}
																					className="field-wrapper"
																				>
																					<InputLabel htmlFor="last_name">
																						Last Name
																					</InputLabel>
																					<InputField
																						type="text"
																						value={
																							data.contact_info.last_name || ""
																						}
																						placeholder="Last Name"
																						name="last_name"
																						onChange={this.handleContactChange}
																					/>
																				</Col>
																				<Col
																					xs={12}
																					sm={6}
																					md={6}
																					className="field-wrapper"
																				>
																					<InputLabel htmlFor="email">
																						Email
																					</InputLabel>
																					<InputField
																						type="text"
																						value={
																							data.contact_info.email || ""
																						}
																						placeholder="Email"
																						name="email"
																						onChange={this.handleContactChange}
																					/>
																				</Col>
																				<Col
																					xs={12}
																					sm={6}
																					md={6}
																					className="field-wrapper"
																				>
																					<InputLabel htmlFor="mobile_phone">
																						Mobile Phone
																					</InputLabel>
																					<InputField
																						type="text"
																						placeholder="Mobile Phone"
																						name="mobile_phone"
																						value={
																							data.contact_info.mobile_phone ||
																							""
																						}
																						onChange={this.handleContactChange}
																					/>
																				</Col>
																				<Col
																					xs={12}
																					sm={6}
																					md={6}
																					className="field-wrapper"
																				>
																					<InputLabel htmlFor="home_phone">
																						Home Phone
																					</InputLabel>
																					<InputField
																						type="text"
																						placeholder="Home Phone"
																						name="home_phone"
																						value={
																							data.contact_info.home_phone || ""
																						}
																						onChange={this.handleContactChange}
																					/>
																				</Col>
																				<Col
																					xs={12}
																					sm={6}
																					md={6}
																					className="field-wrapper"
																				>
																					<InputLabel htmlFor="work_phone">
																						Work Phone
																					</InputLabel>
																					<InputField
																						type="text"
																						placeholder="Work Phone"
																						name="work_phone"
																						value={
																							data.contact_info.work_phone || ""
																						}
																						onChange={this.handleContactChange}
																					/>
																				</Col>
																				<Col xs={12}>
																					<InputLabel htmlFor="street_address">
																						Address
																					</InputLabel>
																				</Col>
																				<Col
																					xs={12}
																					sm={6}
																					md={6}
																					className="field-wrapper"
																				>
																					<InputField
																						type="text"
																						placeholder="Address Line 1"
																						value={
																							data.contact_info
																								.street_address || ""
																						}
																						name="street_address"
																						onChange={this.handleContactChange}
																					/>
																				</Col>
																				<Col
																					xs={12}
																					sm={6}
																					md={6}
																					className="field-wrapper"
																				>
																					<InputField
																						type="text"
																						placeholder="Address Line 2"
																						value={
																							data.contact_info
																								.street_address2 || ""
																						}
																						name="street_address2"
																						onChange={this.handleContactChange}
																					/>
																				</Col>
																				<Col
																					xs={12}
																					sm={6}
																					md={6}
																					className="field-wrapper"
																				>
																					<InputLabel htmlFor="city">
																						City
																					</InputLabel>
																					<InputField
																						type="text"
																						placeholder="City"
																						name="city"
																						value={data.contact_info.city || ""}
																						onChange={this.handleContactChange}
																					/>
																				</Col>
																				<Col
																					xs={12}
																					sm={6}
																					md={6}
																					className="field-wrapper"
																				>
																					<InputLabel htmlFor="province">
																						State/Province
																					</InputLabel>
																					<InputField
																						type="text"
																						placeholder="Province"
																						name="province"
																						value={
																							data.contact_info.province || ""
																						}
																						onChange={this.handleContactChange}
																					/>
																				</Col>
																				<Col
																					xs={12}
																					sm={6}
																					md={6}
																					className="field-wrapper"
																				>
																					<InputLabel htmlFor="country">
																						Country
																					</InputLabel>
																					<InputField
																						type="select"
																						name="country"
																						value={data.contact_info.country}
																						onChange={this.handleContactChange}
																					>
																						{this.countries}
																					</InputField>
																				</Col>
																				<Col
																					xs={12}
																					sm={6}
																					md={6}
																					className="field-wrapper"
																				>
																					<InputLabel htmlFor="postal_code">
																						Postal Code
																					</InputLabel>
																					<InputField
																						type="text"
																						placeholder="Postal Code"
																						name="postal_code"
																						value={
																							data.contact_info.postal_code ||
																							""
																						}
																						onChange={this.handleContactChange}
																					/>
																				</Col>
																			</Row>
																		</Panel.Body>
																	</Panel.Collapse>
																</Panel>

																<Panel
																	eventKey={4}
																	className={`${
																		data && data.id ? "" : "hidden"
																	}`}
																>
																	<Panel.Heading>
																		<Panel.Title toggle>
																			{this.panelTitle("Advanced", 4)}
																		</Panel.Title>
																	</Panel.Heading>
																	<Panel.Collapse>
																		<Panel.Body>
																			<Row>
																				<Col
																					xs={12}
																					sm={6}
																					md={6}
																					className="field-wrapper"
																				>
																					<InputLabel htmlFor="default_listing_type">
																						Type of Accommodations/Label
																						{this.showInstructions(
																							"listings-label"
																						)}
																					</InputLabel>
																					<InputField
																						type="text"
																						name="default_listing_type"
																						value={
																							data.default_listing_type || ""
																						}
																						onChange={this.handleChange}
																					/>
																				</Col>
																			</Row>
																			<Row>
																				<Col
																					xs={12}
																					sm={6}
																					md={6}
																					className="field-wrapper"
																				>
																					<InputLabel htmlFor="google_key">
																						Google API Key
																					</InputLabel>
																					<InputField
																						type="text"
																						name="google_key"
																						value={data.google_key || ""}
																						onChange={this.handleChange}
																					/>
																				</Col>
																			</Row>
																			{divider}
																			<Row>
																				<Col
																					xs={12}
																					sm={6}
																					md={6}
																					className="field-wrapper"
																				>
																					<InputField
																						type="checkbox"
																						value={data.embed || ""}
																						name="embed"
																						cbChange={this.handleChange}
																					>
																						Use As Embedded Site
																						{this.showInstructions(
																							"embedded-website"
																						)}
																					</InputField>
																				</Col>
																			</Row>
																			{divider}
																			<Row className="bottom-margin domain">
																				<Col
																					xs={12}
																					sm={6}
																					md={6}
																					className="field-wrapper"
																				>
																					<InputLabel
																						htmlFor="relative_root_path"
																						cssClass="custom-label"
																					>
																						Root Path / Sub-Directory
																						{this.showInstructions("root-path")}
																					</InputLabel>
																					<InputField
																						type="text"
																						placeholder="Custom root path for site"
																						name="relative_root_path"
																						value={
																							data.relative_root_path || ""
																						}
																						onChange={this.handleChange}
																					/>
																				</Col>
																				<Col
																					xs={12}
																					sm={6}
																					md={6}
																					className="field-wrapper"
																				>
																					<InputLabel
																						htmlFor="site_home_link"
																						cssClass="custom-label"
																					>
																						Home Link
																						{this.showInstructions(
																							"site-home-link"
																						)}
																					</InputLabel>
																					<InputField
																						type="text"
																						placeholder="Primary website URL"
																						name="site_home_link"
																						value={data.site_home_link || ""}
																						onChange={this.handleChange}
																					/>
																				</Col>
																			</Row>
																			{divider}
																			<Row className="bottom-margin domain">
																				<Col
																					xs={12}
																					sm={6}
																					md={6}
																					className="field-wrapper"
																				>
																					<InputLabel htmlFor="default_domain">
																						Default Domain
																					</InputLabel>
																					<InputField
																						type="text"
																						value={data.default_domain || ""}
																						disabled={true}
																					/>
																				</Col>
																				<Col
																					xs={12}
																					sm={6}
																					md={6}
																					className="field-wrapper"
																				>
																					<InputLabel htmlFor="origin_domain">
																						Origin Domain
																					</InputLabel>
																					<InputField
																						type="text"
																						value={data.origin_domain || ""}
																						disabled={true}
																					/>
																				</Col>
																			</Row>
																			<Row>
																				<Col
																					xs={12}
																					sm={6}
																					md={6}
																					className="field-wrapper"
																				>
																					<div className="custom-domain-container">
																						<Col
																							xs={12}
																							className="no-padding cd-status-container"
																						>
																							<div className="cd-instructions">
																								{infoPopover(
																									"Custom Domain Instructions.",
																									null,
																									"top",
																									"icon-question-mark",
																									this.handleModal,
																									"cd-instruction-modal"
																								)}
																							</div>
																							{customDomainStatus &&
																								customDomainStatus.length >
																									0 && (
																									<div>
																										<strong>Status:</strong>
																										&nbsp;
																										<span
																											className={`cd-status${customDomainStatus &&
																												`-${customDomainStatus}`}`}
																										>
																											<strong>
																												{
																													customDomainStatusLabel[
																														customDomainStatus
																													]
																												}
																											</strong>
																										</span>
																										{(customDomainStatus ==
																											"certificate_failed" ||
																											customDomainStatus ==
																												"certificate_pending") &&
																											infoPopover(
																												customDomainStatus ==
																													"certificate_pending"
																													? this
																															.customDomainPendingInfo
																													: this
																															.customDomainFailedInfo,
																												null,
																												"top",
																												"icon-info"
																											)}
																									</div>
																								)}
																						</Col>
																						<Col xs={12} className="no-padding">
																							<InputLabel
																								className={
																									validations.customDomain &&
																									"form-validation"
																								}
																							>
																								Custom Domain
																							</InputLabel>
																						</Col>
																						<Col
																							xs={12}
																							className="no-padding bottom-margin-10"
																						>
																							<div className="cd-field">
																								<InputField
																									type="text"
																									name="customDomain"
																									value={customDomain || ""}
																									onChange={
																										this.handleStateChange
																									}
																									className={
																										validations.customDomain &&
																										"form-validation"
																									}
																									disabled={
																										customDomainStatus ==
																											"certificate_failed" ||
																										customDomainStatus ==
																											"certificate_pending"
																									}
																								/>
																								{customDomain.length > 0 &&
																									this.renderOverlay(
																										"icon-copy",
																										this.copyText,
																										{
																											id: "vccCopy",
																											text: "Copy"
																										},
																										"customDomain"
																									)}
																							</div>
																							{validations.customDomain && (
																								<span className="required-error-text">
																									Invalid Domain name.
																								</span>
																							)}
																						</Col>
																						{customDomainVerCname && (
																							<Col
																								xs={12}
																								className="no-padding bottom-margin-10"
																							>
																								<InputLabel>
																									Verification CName
																								</InputLabel>
																								<div className="cd-field">
																									<InputField
																										type="textarea"
																										value={
																											customDomainVerCname || ""
																										}
																										disabled={true}
																									/>
																									{this.renderOverlay(
																										"icon-copy",
																										this.copyText,
																										{
																											id: "vccCopy",
																											text: "Copy"
																										},
																										"verification_cname"
																									)}
																								</div>
																							</Col>
																						)}
																						{customDomainVerValue && (
																							<Col
																								xs={12}
																								className="no-padding bottom-margin-10"
																							>
																								<InputLabel>
																									Verification Value
																								</InputLabel>
																								<div className="cd-field">
																									<InputField
																										type="textarea"
																										value={
																											customDomainVerValue || ""
																										}
																										disabled={true}
																									/>
																									{this.renderOverlay(
																										"icon-copy",
																										this.copyText,
																										{
																											id: "vccCopy",
																											text: "Copy"
																										},
																										"verification_value"
																									)}
																								</div>
																							</Col>
																						)}
																					</div>
																				</Col>
																			</Row>
																			{this.isInternalAdmin && (
																				<Fragment>
																					{divider}
																					<Row>
																						<Col
																							xs={12}
																							className="row-heading"
																						>
																							<h2>Additional code snippets</h2>
																						</Col>
																					</Row>
																					<Row>
																						<Col
																							xs={12}
																							sm={6}
																							className="field-wrapper"
																						>
																							<InputLabel htmlFor="head_html">
																								Head HTML
																								{this.showInstructions(
																									"head-html"
																								)}
																							</InputLabel>
																							<InputField
																								value={data.head_html || ""}
																								cssClass="text-area code-snippet"
																								type="textarea"
																								name="head_html"
																								onChange={this.handleChange}
																							/>
																						</Col>
																						<Col
																							xs={12}
																							sm={6}
																							className="field-wrapper"
																						>
																							<InputLabel htmlFor="footer_html">
																								Footer HTML
																								{this.showInstructions(
																									"footer-html"
																								)}
																							</InputLabel>
																							<InputField
																								value={data.footer_html || ""}
																								cssClass="text-area code-snippet"
																								type="textarea"
																								name="footer_html"
																								onChange={this.handleChange}
																							/>
																						</Col>
																					</Row>
																				</Fragment>
																			)}
																		</Panel.Body>
																	</Panel.Collapse>
																</Panel>
															</Fragment>
														)}
													</PanelGroup>
												</div>
											</Col>
										</Row>
									</Form>
								</FieldWrap>
								<WebsiteSubmitModal
									show={isSending}
									onHide={this.closeModal}
									completedTask={completedTask}
									taskCount={taskCount}
									submitStatus={submitStatus}
									error={error}
									modalReset={modalReset}
									data={{
										logo_image: data.logo_image,
										favicon_image: data.favicon_image,
										hero_image: data.hero_image
									}}
								/>
								<CustomDomainInstructionModal
									modal={modal}
									handleModal={this.handleModal}
								/>
								{this.renderErrorModal()}
							</Fragment>
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		roleManager: state.roleManager
	};
};

export const ManageWebsite = connect(mapStateToProps, null)(WebsiteComponent);
