import React, { Fragment } from 'react';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import ProgressBar from 'react-bootstrap/lib/ProgressBar';
import Modal from 'react-bootstrap/lib/Modal';
import Link from 'react-router-dom/Link';
import Loader from '../../global/Loader';
import Panel from 'react-bootstrap/lib/Panel';
import PanelGroup from 'react-bootstrap/lib/PanelGroup';

export default class SubmitModal extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            errorActiveKey: "",
            activeKey: null,
        };
        this.imagesUploadingView = '';
        this.progress = 0;
        this.initialFail = false;
    }

    componentDidUpdate(nextProps) {
        if(nextProps.redAwningFatalError !== this.props.redAwningFatalError) {
            this.setState({ 
                activeKey: this.props.redAwningFatalError ? Object.keys(this.props.validationChannels).length : {},
            });
        };
    }

    /**
     * Function count new photos when added properties and on create property. 
     */
    countNewPhotos = () => {
        let photos = this.props.photos;
        let newPhotos = photos.filter((photo)=>{
            return (!photo.photo_id && photo.status !== "failure") || (photo.updated);
        });
        return newPhotos.length;
    }

    /**
     * Count number of photos to added and number of pictures are updated.
     */
    getStats = () => {
        let deletedCount = this.props.deletedPhotos ? this.props.deletedPhotos.length: 0;
        let addedCount = this.props.photos ? (this.countNewPhotos() || 0) : 0;

        let totalCount = addedCount + deletedCount;
        return {
            total: totalCount,
            addedCount,
            deletedCount
        }
    }

    /**
     * For update progress state Text and progress and styling.
     */
    updateProgress = () => {
        this.initialFail = false;
        this.imagesUploadingView = '';
        let stats = this.getStats();

        let defaultValue = 
            100 / (this.props.submitModalTotal ? (this.props.submitModalTotal + stats.total) : 1);
        let progress = Math.floor(defaultValue * this.props.submitStatus);
 
        let progressStyle = 'success';

        if(this.props.error && this.props.error.show) {
            progressStyle = 'warning';
            if(this.props.submitStatus === 1) {
                this.initialFail = true;
            }
            if(this.initialFail && this.props.mode !== 'edit') {
                progress = 100;
                progressStyle = 'danger';
            }
            else if(progress>=100 && this.initialFail && stats.total==this.props.failedImagesCount+1) {
                progressStyle = 'danger';
            }
        }

        this.progress = progress;

        this.imagesUploadingView = (
            <div>
                <div className='progress-wrapper'>
                    <ProgressBar 
                        bsStyle={progressStyle} 
                        now={progress} 
                    />
                    {(this.props.submitStatus >= 0 && stats.total > 1 && progress < 100) &&
                        <h5>
                            {this.props.imageStatusMessage === "Uploading" ? `Uploading image ${this.props.submitImageStatus} of ${stats.addedCount}`: ""}
                        </h5>
                    }
                        <h5 className='progress-percent'>
                            {Math.floor(progress)}%
                        </h5>
                </div>
                {this.props.error && this.props.error.show &&
                    (<div>
                        <p className="progress-error">
                            {this.props.error.message ? 
                                this.props.error.message : 
                                'Error details: '
                            }
                        </p>
                        <PanelGroup 
                            accordion id="errorMessage" 
                            onSelect={errorActiveKey => this.setState({ errorActiveKey })}
                            defaultActiveKey="1"
                        >
                            <Panel eventKey="1">
                                <Panel.Heading>
                                    <Panel.Title 
                                        toggle
                                    >
                                        Error Message
                                        <span className={`pull-right ${this.state.errorActiveKey === '1' ? 'glyphicon glyphicon-chevron-up': 'glyphicon glyphicon-chevron-down'}`}></span>
                                    </Panel.Title>
                                    <Panel.Body collapsible>
                                        <pre>
                                            <code>
                                                {this.props.error.detail ? 
                                                    JSON.stringify(this.props.error.detail) : 
                                                    (this.props.error ? JSON.stringify(this.props.error) : "")
                                                }
                                            </code>
                                        </pre>
                                    </Panel.Body>
                                </Panel.Heading> 
                            </Panel>
                        </PanelGroup>
                    </div>)
                }
                {this.props.error && this.props.error.show && this.props.error.imageError &&
                    (<div>
                        <p className="progress-error">
                            {this.props.error.imageError}
                        </p>
                        {(APIConfig.ENV=="DEV") && 
                            <pre>
                                <code>
                                    {this.props.error.detail ? 
                                        JSON.stringify(this.props.error.imageErrorDetail) : 
                                        ''
                                    }
                                </code>
                            </pre>
                        }
                    </div>)
                }
            </div>
        )
    }

    /**
     * Close modal after error or success.
     */
    closeModal = () => {
        if(this.props.propertiesForm) {
            if(!this.initialFail && (this.props.mode === "create" || this.props.mode === "clone")) {
                if(!this.props.collection) {
                    this.props.history.push(`/collections/${this.props.collectionId}/rooms/${this.props.propertyId}/edit`)
                }
                else {
                    this.props.history.push(`/properties/${this.props.propertyId}/edit`)
                };
            }
            else {
                this.props.closeModal(); 
            };
        }
        else {
            this.props.closeModal();
        };

        this.setState({
            activeKey: null
        });
    }

    /**
     * Select an option to continue editing will invoke this function.
     * @param {} e 
     */
    handleContinue = (e) => {
        if(this.props.mode === "edit") {
            e.preventDefault();
            this.closeModal();
        };
    }

    panelOnSelect = (activekey, e, customKey) => {
        if(e) e.preventDefault();
        
        this.setState({ 
            activeKey: customKey ? customKey : activekey,
        });
    }

    renderValidationData = (channel,list,index) => {

        return (
            <Panel eventKey={(index + 1)} key={index}>
               <Panel.Heading>
                   <Panel.Title 
                        toggle
                        onClick={e => this.panelOnSelect(null, e, (index + 1))}
                    >
                       {channel+" ("+(list[channel].length)+")"}
                       <span 
                            className={
                                `pull-right ${this.state.activeKey === (index + 1) ? 'glyphicon glyphicon-chevron-up': 'glyphicon glyphicon-chevron-down'}`
                            }
                        >
                        </span>
                   </Panel.Title>
                   <Panel.Body collapsible>
                       <div className="validation-modal">
                            <div className="messages-panel">
                                <div>
                                    <h4 className="error-category"><img className="validation-icon" src="/app/assets/icons/fatal.svg"/>Fatal Errors</h4>
                                        <ul>
                                        {
                                            list[channel].map((message, i)=> {
                                                return <li key={i}>{message.description}</li>
                                            })
                                        }
                                        </ul>
                                </div>
                            </div>
                       </div>
                    </Panel.Body>
               </Panel.Heading> 
           </Panel>
       )   
    }

    render() {
        this.updateProgress();
        let closeButton = {};
        
        if(this.progress==100) {
            closeButton = {
                closeButton: true
            };
        };

        return (
            <Modal 
                backdrop='static' 
                className={`submit-modal ${this.props.propertiesForm ? "properties-form": ""}`}
                show={this.props.activeModal === 'submit-modal'} 
                onHide={this.closeModal.bind(this)}
            >
                <Modal.Header {...closeButton}>
                    <Modal.Title>
                        {
                            this.progress==100 ? 
                            !this.props.error.show ? 
                                <span 
                                    className='status-icon status-icon--success'>
                                </span> : 
                                <span 
                                    className='status-icon status-icon--error'>
                                </span> : 
                                <div 
                                    className="submit-loader">
                                    <Loader />
                                </div>
                        }
                        <span 
                            className="submit-heading">
                            {
                                this.props.error && this.props.error.show ? 
                                'Error' : 
                                this.props.progressMessage
                            }
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12} 
                            className="bottom-margin">
                            <div 
                                className="upload-status">
                                {this.imagesUploadingView}
                            </div>
                        </Col>
                            {
                                (this.props.mode === "edit" &&
                                this.progress==100 &&
                                this.props.propertiesForm) &&
                                <Fragment>
                                    <Col xs={12} className="bottom-margin">
                                        <div className="property-status">
                                            <span className="panel-title">Status: </span>
                                            <div className={`primary-btn active-btn live-btn ${this.props.propertyStatus ? ' is-live' : ' not-live'}`}>
                                                {this.props.propertyStatus ? 
                                                    'Live': 
                                                    'Not Live'}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col 
                                        xs={12} 
                                        className="bottom-margin"
                                    >
                                        {
                                            Object.keys(this.props.validationChannels).length > 0 ?
                                            <Fragment>
                                                <span className="panel-title bottom-margin">Validations:</span>
                                                <PanelGroup 
                                                    accordion 
                                                    id="validations"
                                                    activeKey={this.state.activeKey}
                                                    onSelect={activeKey => this.panelOnSelect(activeKey)}
                                                >
                                                    {
                                                        Object.keys(this.props.validationChannels).map((channel, index)=>{
                                                        return this.renderValidationData(channel, this.props.validationChannels, index)
                                                    })}
                                                </PanelGroup> 
                                            </Fragment>
                                            :
                                            <h4>Congratulations! This property meets all distribution requirements.</h4>
                                        }
                                    </Col>
                                </Fragment>
                            }
                    </Row>
                    {this.progress==100 &&
                        <Row>
                            {this.props.mobileMode ? <Fragment>
                                <Col xs={12} className="top-margin">
                                    {!this.initialFail && this.props.propertiesForm &&
                                        (this.props.collection ? 
                                            <Link 
                                                to={`/collections/${this.props.collectionId}`} 
                                                className='primary-btn white-btn'>
                                                View All Collections
                                            </Link> :
                                            <Link 
                                                to='/properties' 
                                                className='primary-btn white-btn'>
                                                View All Properties
                                            </Link>)
                                    }
                                </Col>
                                <Col xs={12} className="top-margin">
                                    {!this.initialFail && this.props.propertiesForm &&
                                            (!this.props.collection ? 
                                            <Link 
                                                to={`/properties/${this.props.propertyId}/edit`} 
                                                onClick={this.handleContinue} 
                                                className='primary-btn'>
                                                Continue Editing
                                            </Link> : 
                                            <Link 
                                                to={`/collections/${this.props.collectionId}/rooms/${this.props.propertyId}/edit`} 
                                                onClick={this.handleContinue} 
                                                className='primary-btn'>
                                                Continue Editing
                                            </Link>)
                                        }
                                </Col>
                                <Col xs={12} className="top-margin">
                                    {!this.initialFail && this.props.propertiesForm &&
                                            <Link 
                                                to={`/calendar/${this.props.propertyId}/edit`} 
                                                className='primary-btn white-btn'
                                                style={{padding: '5px 45px'}}
                                            >
                                                Edit Calendar
                                            </Link>
                                        }
                                </Col>
                            </Fragment> :
                                <Col xs={12} className="top-margin">
                                    {!this.initialFail && this.props.propertiesForm &&
                                        (this.props.collection ? 
                                            <Link 
                                                to={`/collections/${this.props.collectionId}`} 
                                                className='primary-btn white-btn'>
                                                View All Collections
                                            </Link> :
                                            <Link 
                                                to='/properties' 
                                                className='primary-btn white-btn'>
                                                View All Properties
                                            </Link>)
                                    }
                                    {!this.initialFail && this.props.propertiesForm &&
                                        (!this.props.collection ? 
                                        <Link 
                                            to={`/properties/${this.props.propertyId}/edit`} 
                                            onClick={this.handleContinue} 
                                            className='primary-btn left-margin'>
                                            Continue Editing
                                        </Link> : 
                                        <Link 
                                            to={`/collections/${this.props.collectionId}/rooms/${this.props.propertyId}/edit`} 
                                            onClick={this.handleContinue} 
                                            className='primary-btn left-margin'>
                                            Continue Editing
                                        </Link>)
                                    }
                                    {!this.initialFail && this.props.propertiesForm &&
                                        <Link 
                                            to={`/calendar/${this.props.propertyId}/edit`} 
                                            className='primary-btn white-btn left-margin'>
                                            Edit Calendar
                                        </Link>
                                    }
                                </Col>
                            }
                        </Row>
                    }
                </Modal.Body>
            </Modal>
        )
    }
}
