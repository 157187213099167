import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { API } from 'aws-amplify';
import PageLayout from '../global/PageLayout';
import PromotionsForm from '../content/promotions/PromotionsForm';
import constants from '../../js/constants';
import { Title } from '../global/Title';
import Modal from 'react-bootstrap/lib/Modal'
import Link from 'react-router-dom/Link';
import Loader from '../global/Loader';
import _ from "lodash";

class EditPromotion extends Component { 

    constructor(props) {
        super(props)
        this.closeModal = this.closeModal.bind(this);
    }    
    state = {
        mode: 'edit',
        org: "",
        roles: "",
        isLoading: false,
        admin: false,
        promotionData: {}
    }

    async componentDidMount(){
        await this.getPromotion(this.props.match.params.promotionId);
    }

    getPromotion = async (promotionId) => {

        this.setState({ 
            isLoading: true
        });

        try {

            let response = await API.get(constants.RAPAPI.NAME, constants.RAPAPI.PROMOTION_BY_ID(promotionId), { response: true });

            var channels = []
            var organizations = []
            var properties = []

            // format channels
            response.data.channels.forEach(channel => {
                channels.push(channel['organization_id'])
            });
            response.data.channels = channels;

            //format organizations
            response.data.organizations.forEach(organization => {
                organizations.push(organization['organization_id'])
            });
            response.data.organizations = organizations;

            //format properties
            response.data.properties.forEach(property => {
                properties.push(property['property_id'])
            }); 
            response.data.properties = properties;

            this.setState({
                org: this.props.roleManager.org,
                roles: this.props.roleManager.roles,
                promotionData: response.data,
                name: [...response.data.name],
                isLoading: false,
            });

        } catch (e) {
            this.setState({
                isLoading: false
            })
        }
        return true;
    }

    closeModal() {
        this.props.history.push('/account-management/promotions') 
    }

    promoHasValidValue = () => {
        const { promotionData } = this.state;

        return _.has(promotionData, 'id') && !_.isNil(_.get(promotionData, 'id'));
    }

    render() {
 
        const { 
            promotionData,
            isLoading
        } = this.state;
        let breadcrumbItems = [];

        if(this.promoHasValidValue()) {
            breadcrumbItems = [
            {
                title: "Account Management"
            }, 
            {
                title: "Promotions",
                link:  "/account-management/promotions"
            },{
                title: this.state.promotionData.name,
                link:  "/account-management/promotions/"+this.state.promotionData.id+"/edit"
            }]
        }
             
        if(!this.promoHasValidValue()) {
            breadcrumbItems = [
            {
                title: "Account Management"
            }, 
            {
                title: "Promotions",
                link:  "/account-management/promotions"
            }]
        }
        return ( 
            <PageLayout isCentered={true}>
                {
                    isLoading && <Loader/>
                }
                {
                    (!isLoading && this.promoHasValidValue()) && 
                    <PromotionsForm 
                        org={this.state.org}
                        roles={this.state.roles}
                        mode="edit" 
                        breadcrumbItems={breadcrumbItems} 
                        promotionData={promotionData} 
                        title={`Edit ${promotionData.name}`} 
                    />
                }
                {
                    (!isLoading && !this.promoHasValidValue()) &&
                    <Fragment>
                        <Title
                            title='Edit Promotion'
                            isCentered={true}
                            removeOrganizationDropdown={true}
                            addDefaultTitle=""
                            breadcrumbItems={breadcrumbItems}
                        >
                        </Title>
                        <Modal className="submit-modal lg-modal" show={true} onHide={this.closeModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    <span className='status-icon status-icon--error'></span>
                                    <span className="submit-heading">
                                        Error
                                    </span>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Unable to load the requested promotion.</p>
                                <div className="modal-btns">
                                    <Link
                                        to={"/account-management/promotions"} 
                                        className='primary-btn white-btn pull-right '>
                                        View All Promotions
                                    </Link>
                                </div>							
                            </Modal.Body>
                        </Modal>
                    </Fragment>
                }
            </PageLayout>
        )  
    }
}

const mapStateToProps = state => {
    return {
        roleManager: state.roleManager
    }
}

export default withRouter(connect(
    mapStateToProps,
    null
)(EditPromotion));