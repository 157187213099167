import React, { Fragment } from 'react';
import moment from 'moment';
import Week from 'react-big-calendar/lib/Week';
import {segStyle, inRange, eventSegments, endOfRange, eventLevels} from 'react-big-calendar/lib/utils/eventLevels';
import { InputField } from '../../global/forms/FormElements';
import EventRow from 'react-big-calendar/lib/EventRow';
import { ShowMoreRow } from './ShowMoreRow';
import dates from 'react-big-calendar/lib/utils/dates';
import { Cell } from './Cell';
import Link from 'react-router-dom/Link';
import { DatePicker } from '../forms/SingleDatePicker';
import { StyledWrapper } from './CalendarView';

Week.range=(date, ref)=> {
    let mDate=moment(date),
        start,end;
    start= date
    end= mDate.add(13,'days')
    return dates.range(start,end)
}

export class MobileWeekView extends Week  {
    constructor(props) {
        super(props);
        this.range = Week.range(this.props.date,this.props);
        this.cellStyle = segStyle(1,this.range.length);
        this.eventAccessor = props.eventAccessor;
        this.setRefs = this.setRefs.bind(this);
        this.cellRefs = {};
        this.state = {
            manualDate: moment(this.props.date).format('MM/DD/YYYY'), 
        }
    }
    componentWillReceiveProps(nextProps){
        this.setState({
            manualDate: moment(nextProps.date).format('MM/DD/YYYY'),
        })
    }
    /**
     * Get filtered events from event accessor access events.
     */
    getFilteredEvents(i) {
        let refs=this.props,
            evts=refs.events,
            range=this.range,
            start=range[0],
            end=range[range.length-1],
            first=endOfRange(range).first,
            last=endOfRange(range).last,
            filteredEvts=[],
            segments=[],
            levels=[];
        this.eventAccessor.forEach(acc=>{
            if(evts[i] && evts[i][acc])
            filteredEvts= filteredEvts.concat(evts[i][acc].filter(e=>{
                let period_end = e && (e.period_end || e.period && e.period.period_end),
                exception = period_end && moment(this.props.today).isSame(period_end)
                /* remove events of type="Rooms Available" from mobile view */
                if (e && (e.period && e.period.type=="BlockedByBooking" || e.propertyId))
                    return false
                if(exception)
                    return true
                return inRange(e,start,end,refs)
            }));
        })
        filteredEvts.forEach(evt=>{
            if(evt.period_start) {
                evt.period_start=moment(evt.period_start).hours(12)
                evt.period_end=moment(evt.period_end).hours(12)
            }
            if(evt.start_date) {
                evt.start_date=moment(evt.start_date).hours(12)
                evt.end_date=moment(evt.end_date).hours(12)
            }
            if(evt.period) {
                evt.period.period_start=moment(evt.period.period_start).hours(12)
                evt.period.period_end=moment(evt.period.period_end).hours(12)
            }
        })
        segments=filteredEvts.map(e=>{
            return eventSegments(e,first,last,refs,range);
        })
        let priceSegments = segments.filter(seg=>{
            return (seg.event.price_period_id || seg.event.cico_id ) ? true:false
        })
        let otherSegments = segments.filter(seg=>{
            return (!seg.event.price_period_id && !seg.event.cico_id) ? true:false
        })
        let priceLevels = eventLevels(priceSegments,2)
        let otherLevels = eventLevels(otherSegments,2)
        levels = {
            levels: priceLevels.levels.concat(otherLevels.levels),
            extra: priceLevels.extra.concat(otherLevels.extra)
        }
        return {
            segments: segments,
            levels: levels,
            first: first,
            last: last,
            filteredEvts: filteredEvts
        }
    }
    /**
     * Invoke when click on show more.
     * @param {*} slot 
     * @param {*} segments 
     * @param {*} propId 
     */
    onShowMore(slot, segments,propId) {
        let _props = Object.assign({},this.props);
        let events = segments.filter(seg=>{
            return seg.left <= slot && seg.right >= slot;
        }).map(evt=>{
            return evt.event
        });
        let el = this.cellRefs[propId][this.range[slot-1]];
        let bounds = el.getBoundingClientRect();
        _props = Object.assign(_props,{
                events: events,
                dayHeaderFormat: "D",
                position: {
                    top: bounds.top + window.scrollY,
                    left: bounds.left + (bounds.width/2),
                    width: 120
                },
            slotStart: this.range[slot-1],
            EventComponent: 'EventCell',
            eventWrapperComponent: this.props.components.eventWrapper,
            onSelect: (evt,e)=>this.props.onSelectEvent(evt,e,propId)
        })
        this.props.handleShowMore(_props)
    }
    setRefs(ref,date,propId) {
        let propRef = this.cellRefs[propId] || {
            propId
        }
        propRef[date] = ref
        this.cellRefs[propId] = propRef
    }
    handleManualDateChange = e => {
        let newVal = e.target.value.replace(/[.-]/g, '/')
        if (moment(newVal, ['M/D/YYYY', 'MM/DD/YYYY'], true).isValid()) {
            if (moment(newVal, 'MM/DD/YYYY').isSameOrAfter(moment(this.props.today), 'day')) {
                document.getElementById('mobile-input-date').blur()
                this.props.onNavigate('DATE', moment(newVal, 'MM/DD/YYYY'))
            }
        }
        this.setState({
            manualDate: newVal,
        })
    }
    render() {
        let properties = this.props.events,_this=this;
        this.range = Week.range(this.props.date,this.props);
        let compareKey = { 
            "temporary": 1, "other": 0,
        },
        monthHeader = document.getElementsByClassName('rbc-month-header')[0],
        calendarColumn = document.getElementsByClassName('calendar-column')[0],
        daysWrapper = document.getElementsByClassName('days-wrapper')[0],
        daysWrapperBoundingClientRectLeft
        window.addEventListener('scroll', ()=> {
            if (monthHeader) {
                let leftBumper = document.getElementsByClassName('bumper-left')[0],
                rightBumper = document.getElementsByClassName('bumper-right')[0]
                if (window.pageYOffset >= 44) {
                    leftBumper && leftBumper.classList.add('show')
                    rightBumper && rightBumper.classList.add('show')
                    if (!monthHeader.classList.contains('fixed')) {
                        monthHeader.classList.add('fixed')
                    }
                    if (monthHeader.classList.contains('sticky')) {
                        monthHeader.classList.remove('sticky')
                    }
                    monthHeader.style.top = 54 + 'px'
                    monthHeader.style.left = daysWrapperBoundingClientRectLeft + 'px'
                    calendarColumn.style.marginTop = 35 + 'px'
                }
                else {
                    monthHeader.classList.remove('fixed')
                    calendarColumn.style.marginTop = 0 + 'px'
                    leftBumper && leftBumper.classList.remove('show')
                    rightBumper && rightBumper.classList.remove('show')
                }
            }
        })
        if (calendarColumn) {
            let initialBoundingClientRectTop = 98
            calendarColumn.addEventListener('scroll', ()=> {
                if (monthHeader.classList.contains('fixed')) {
                    monthHeader.classList.remove('fixed')
                }
                let newBoundingClientRectTop = calendarColumn.getBoundingClientRect().top
                daysWrapperBoundingClientRectLeft = daysWrapper.getBoundingClientRect().left
                if (!monthHeader.classList.contains('sticky')) {
                    monthHeader.classList.add('sticky')
                }
                calendarColumn.style.marginTop = 0 + 'px'
                monthHeader.style.top = (initialBoundingClientRectTop - newBoundingClientRectTop) - 44 + 'px'
            })
        }
        return (
            <Fragment>
                <div className="navigation-wrapper">
                    {
                        this.props.allOrganizations.length > 1 &&
                        this.props.org !== '_global_' && 
                        this.props.org !== 'travelagencies' &&
                        <div className="filters-wrap">
                            <InputField 
                                type="checkbox" 
                                value={this.props.showAllOrg} 
                                cbChange={() => this.props.handleShowAllOrg('showAllOrg')}
                            >
                                All Organizations
                            </InputField>
                        </div>
                    }
                    <div 
                        className="arrow-container multi-view"
                    >
                        {moment(this.props.date).isSame(this.props.today, 'day') ? (
                            <button className="plain-text-btn icon-left_arrow" style={{cursor: 'not-allowed', color: '#D5D5D5'}}/>
                        ) : (
                            <button 
                                className="plain-text-btn icon-left_arrow"
                                onClick={()=>this.props.onNavigate('PREV')} 
                                title="Previous"
                            />
                        )}
                    </div>
                    <div 
                        className="arrow-container multi-view"
                    >
                        <button
                            className="plain-text-btn icon-right_arrow"
                            onClick={()=>this.props.onNavigate('NEXT')} 
                            title="Next"
                        />
                    </div>
                    <div
                        style={{position: 'relative'}}
                    >
                        <StyledWrapper
                            view='mobile-week-view'
                        >
                            <DatePicker 
                                id="mobileWeekViewPicker"
                                date={moment(this.props.date)}
                                onDateChange={(date)=> {
                                    if (date) {
                                        this.props.onNavigate('DATE',date.toDate())
                                    }
                                }}
                                readOnly
                                numberOfMonths={1}
                                isOutsideRange={() => false}
                            />
                        </StyledWrapper>
                        <InputField
                            type="text"
                            id="mobile-input-date"
                            maxLength="10"
                            disabled={!this.props.admin}
                            value={this.state.manualDate}
                            onBlur={()=>{this.setState({
                                manualDate: moment(this.props.date).format('MM/DD/YYYY'),
                            })}}
                            onChange={(e)=> {
                                this.handleManualDateChange(e)
                            }}
                        />
                    </div>
                </div>
                <div className='rbc-month-view multi-view mobile-view' ref='monthNode'>
                    <div className="calendar-column">
                        <div className='rbc-row rbc-month-header'>
                            {
                                this.range.map((date,i)=>{
                                    let dateObj=moment(date);
                                    return (                                
                                        <div style={this.cellStyle} className='rbc-header' key={i}>
                                            <span 
                                                className='day-header'
                                            >
                                                {dateObj.format('dd')}
                                            </span>
                                            <span 
                                                className='date-header'
                                            >
                                                {dateObj.format('D')}
                                            </span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div 
                            className="bumper bumper-left"
                        />
                        <div 
                            className="bumper bumper-right"
                        />
                        <div className='days-wrapper'>
                            {
                                properties.map((item,i) => {
                                    let events = this.getFilteredEvents(i),
                                        groupId = item[_this.props.groupAccessor];
                                        events.levels.levels.sort((a, b) => {
                                            let typeA = a[0] && a[0].event && a[0].event.period && a[0].event.period.type === "Temporary" ? "temporary" : "other";
                                            let typeB = b[0] && b[0].event && b[0].event.period && b[0].event.period.type === "Temporary" ? "temporary" : "other";
                                            return compareKey[typeA] < compareKey[typeB] ? 1 : compareKey[typeA] > compareKey[typeB] ? -1 : 0
                                        })
                                    return (
                                        <Fragment key={i}>
                                            <div className="property-details-row">
                                                <Link
                                                    className="mobile-link" 
                                                    to={{pathname:`/calendar/${groupId}/edit`}}
                                                >
                                                    <strong>
                                                        {`${groupId} |`} {item.external_property_id ? `${item.external_property_id} |` : ''}
                                                        {` ${item.content.title}`}
                                                    </strong>
                                                </Link>
                                            </div>
                                            <div 
                                                className="spacing-row"
                                            />
                                            <div className='rbc-month-row'>
                                                <div className='rbc-row-bg'>
                                                    <Cell
                                                        {..._this.props} 
                                                        currency={item.price? item.price.currency: 'USD'} 
                                                        handleRefs={this.setRefs} 
                                                        filteredEvts={events.filteredEvts}
                                                        availability={item.availability} 
                                                        propId={groupId} 
                                                        range={_this.range} 
                                                        cellStyle={_this.cellStyle}
                                                        propertyLevel={item.level}
                                                        units={item.units}
                                                        propertyData={item}
                                                    />
                                                </div>
                                                <div className='rbc-row-content'>
                                                    <div className='rbc-row property-row'/>
                                                    {
                                                        events.levels.levels.map((segs,i)=>{
                                                            segs = segs.map(data => {
                                                                if(data) 
                                                                    data.event.currency = item.price? item.price.currency: 'USD';
                                                                return data
                                                            })
                                                            let fSegs = segs.filter(seg => {
                                                                return seg.left !== 0
                                                            })
                                                            return (
                                                                <EventRow
                                                                    {..._this.props}
                                                                    onSelect={(evt,e)=>_this.props.onSelectEvent(evt,e,groupId)}
                                                                    key={i}
                                                                    start={events.first}
                                                                    end={events.last}
                                                                    segments={fSegs}
                                                                    slots={_this.range.length}
                                                                    EventComponent='month'
                                                                    eventWrapperComponent={_this.props.components.eventWrapper}
                                                                />
                                                            )
                                                        })
                                                    }                 
                                                    { !!events.levels.extra.length &&
                                                        <ShowMoreRow
                                                            {..._this.props}
                                                            start={events.first}
                                                            end={events.last}
                                                            segments={events.levels.extra}
                                                            slots={_this.range.length}
                                                            EventComponent='month'
                                                            eventWrapperComponent={_this.props.components.eventWrapper}
                                                            onShowMore={slot=>this.onShowMore(slot,events.levels.extra,groupId)}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                })
                            }
                        </div>  
                    </div>    
                </div>
            </Fragment>
        )
    }    
}


