import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Modal from 'react-bootstrap/lib/Modal';
import Tabs from 'react-bootstrap/lib/Tabs';
import Tab from 'react-bootstrap/lib/Tab';
import { InputField, PrimaryButton, InputLabel } from '../../global/forms/FormElements';
import moment from 'moment';
import 'react-dates/initialize';
import DateRangePicker from 'react-dates/lib/components/DateRangePicker';
import { DatePicker } from '../../global/forms/SingleDatePicker';
import Constants from '../../../js/constants';
import _ from "lodash";
import { AddonForm } from "./AddonForm";

/**
 * @class For block dates on calendar has calendar. 
 */
export class AvailabilityModal extends Component {
    constructor(props) {
        super(props);
        this.expiresTimeLabel = Constants.TIME_15.map((time, i) => {
            return (
                <option key={i} value={time.value}>{time.label}</option>
            )
        });
        this.state = {
            focusedInput: null,
            reservationFocusedInput: null,
            checkoutDateArray: [],
            availability: [],
            startDate: undefined,
            endDate: undefined,
            maxDisableDates: undefined,
            units: 1,
            isDisabled: false,
            reservationDates: {
                startDate: null,
                endDate: null
            },
        }
        this.handleDatesChange = this.handleDatesChange.bind(this);
        this.getAvailabilityDates = this.getAvailabilityDates.bind(this);
        this.isDayBlocked = this.isDayBlocked.bind(this);
        this.submitAvailability = this.submitAvailability.bind(this);
    }

    /**
     * Handle date change on block modal calendar Form and to dates.
     * @param {*} param
     */
    async handleDatesChange({startDate, endDate}) {
        if (startDate) {
            this.props.handleBlockedDateChange(startDate,'period_start', {startDate, endDate})
        }
        if (endDate) {
            this.props.handleBlockedDateChange(endDate,'period_end', {startDate, endDate})
        }
        if (startDate && this.state.availability && this.state.availability.length && this.state.availability.length > 0) {
            for (var i = 0; i < this.state.availability.length; i++) {
                if (!(this.state.maxDisableDates) && startDate < moment(this.state.availability[i])) {
                    await this.setState({ maxDisableDates: moment(this.state.availability[i]).add(1, 'days') });
                }
            }
        }

        if (startDate && endDate && this.state.endDate) {
            this.setState({maxDisableDates: undefined})
        }
    }

    async submitAvailability() {
        let _self = this;
        _self.setState(state => ({
            isDisabled: true
        }));

        await _self.props.submitAvailability(_self.currentProperty && _self.currentProperty.level, _self.props.availabilityModal && _self.props.availabilityModal.children && _self.props.availabilityModal.children.length && _self.props.availabilityModal.children.length - _self.state.units)

        setTimeout(() => {
            _self.setState(state => ({
                isDisabled: false
            }));
        }, 500)
        
    }

    handleEmptyPlaceholder = (e, date) => {
        e.target.placeholder = ''
        e.target.value = date
    }

    handleDatedPlaceholder = (e, date) => {
        e.target.placeholder = date
    }

    handleManualDateChange = e => {
        e.preventDefault()

        let newVal = e.target.value.replace(/[.-]/g, '/')

        if (e.target.name === 'mStartDate') {
            if (moment(newVal, 'MM/DD/YYYY', true).isValid()) {
                this.handleDatesChange({startDate: moment(newVal)})
            }
        }
        else {
            if (moment(newVal, 'MM/DD/YYYY', true).isValid()) {
                this.handleDatesChange({endDate: moment(newVal)})
            }
        }
    }

    /**
     * For recognize is specific date is already blocked or not.
     * @param {*} day specific date.
     */
    isDayBlocked(day) {
        if (!this.state.checkoutDateArray.includes(moment(day).format("YYYY-MM-DD"))) {
            return (moment(day) < moment(new Date()))
        }
    }

    /**
     * For fetching date from availability.
     * @param {*} property_id 
     */
    getAvailabilityDates(property_id) {
        let checkoutDateArray = []
        let bookDates = [];
        let property = this.props.getPropertyById(property_id)[0];

        if(property && property.availability && property.availability.length){
            property.availability.forEach((avail) => {
                let take2YearData = new Date();
                take2YearData.setUTCHours(0,0,0,0)
                take2YearData.setUTCFullYear(take2YearData.getUTCFullYear() + 2)
                let date = avail.period && new Date(avail.period.period_start);
                if (date) {
                    date.setUTCHours(0, 0, 0, 0)
                    if(0 < date.getTimezoneOffset()){
                            date = date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
                    }
                }
                let endDate = avail.period && new Date(avail.period.period_end);
                if (endDate) {
                    endDate.setUTCHours(0, 0, 0, 0)
                    if(0 < endDate.getTimezoneOffset()){
                        endDate = endDate.setMinutes(endDate.getMinutes() + endDate.getTimezoneOffset());
                    }
                }
                while (new Date(date).getTime() <= new Date(endDate).getTime() && new Date(date).getTime() <= take2YearData.getTime()) {
                    bookDates.push(new Date(date).toISOString().substr(0, 10))
                    date = new Date(date).setDate(new Date(date).getDate() + 1);
                }
            })
            bookDates.forEach(date=>{
                if(!bookDates.includes(moment(date).subtract(1,"days").format("YYYY-MM-DD"))) {
                    checkoutDateArray.push(date);
                }
            })
            bookDates.sort()
            this.setState({
                availability: bookDates,
                checkoutDateArray: checkoutDateArray,
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        // if(nextProps.availabilityModal.property_id != this.property_id) {
            this.getAvailabilityDates(nextProps.availabilityModal.property_id)
            this.setState({
                units: nextProps.availabilityModal.children && nextProps.availabilityModal.children.length || 0
            })
        // }
    }
    
    /**
     * Handle unit change.
     * @param {*} units 
     */
    handleChange(units) {
        units = units.target.value;
        this.setState({
            units
        })
    }

    render() {
        const { reservationFocusedInput } = this.state;
        let modal = this.props.availabilityModal;
        let item = this.props.getPropertyById(modal.property_id)[0];
        let errors = this.props.errors;
        let isDisabled = false;
        this.currentProperty = item;

        if (!_.get(modal, 'availabilityDates.startDate') || !_.get(modal, 'availabilityDates.endDate') ) {
            isDisabled = true
        }
        else {
            isDisabled = false
        };

        let expiresTime = "00:00:00Z"
        if(modal.data.expires) {
            expiresTime = modal.data.expires.split("T")[1];
        };

        this.property_id = modal.property_id;

        let promotions = [<option key={0} value={''}>None</option>];
        if(modal && modal.reservations && modal.reservations.promotionCodeData && modal.reservations.promotionCodeData.length) {

            for (let i = 0; i < modal.reservations.promotionCodeData.length; i++) {
                const org_promo_id = modal.reservations.promotionCodeData[i].org_promo_id;

                promotions.push(<option key={i + 1} value={org_promo_id}>{org_promo_id}</option>);
            };
        };

        return (<Modal 
                    className='calendar-modal' 
                    backdrop={false} bsClass='modal' 
                    id='availabilityModal' 
                    show={modal.show} 
                    onHide={() => this.props.hideAvailabilityModal(this)}
                >
                <Modal.Header closeButton>
                    {
                        modal.mode == 1 ?
                        <Modal.Title 
                            componentClass='h3'
                        >
                            #{modal.property_id}<br/>{item && item.content && item.content.title}
                        </Modal.Title> 
                        :
                        <Fragment>
                            <Col xs={12} className='tab-container'>
                                <Tabs 
                                    activeKey={modal.type} 
                                    id="uncontrolled-tab-example" 
                                    className="mb-3"
                                    onSelect={(value) => this.props.toggleAvailModalType(value)}
                                >
                                    {
                                        this.props.admin &&
                                        <Tab eventKey="block" title="Block Property">
                                        </Tab>
                                    }
                                    <Tab eventKey="create" title="Create Reservation">
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Fragment>
                    }
                    {
                        modal && modal.data && modal.data.created &&
                        <Col xs={12}>
                            <InputLabel>
                                <strong>Created: </strong> {moment(modal.data.created).format('MM/DD/YYYY')}
                            </InputLabel>
                        </Col>
                    }
                </Modal.Header>
                <Modal.Body>
                    <Grid fluid={true}>
                        {
                            (modal.mode == 1 ||
                            modal.type === 'block') ?
                            <Fragment>
                                {!modal.iCalSourceOfTruth ? 
                                <Fragment>
                                    <Row className='bottom-margin'>
                                        <Col xs={12} className='bottom-margin-10'>
                                            <InputLabel>
                                                Please enter dates in <strong>mm/dd/yyyy</strong> format.
                                            </InputLabel>
                                        </Col>
                                        <Col xs={6} className='bottom-margin-5'>Start (Night of Check-In)</Col>
                                        <Col xs={6} className='bottom-margin-5'>End (Last Night of Stay)</Col>
                                        <Col xs={12} className='bottom-margin-10'>
                                            {
                                                item && 
                                                <DateRangePicker
                                                    startDate={modal.availabilityDates.startDate}
                                                    startDateId='start'
                                                    endDate={modal.availabilityDates.endDate}
                                                    endDateId='end'
                                                    focusedInput={this.state.focusedInput}
                                                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                                                    onDatesChange={this.handleDatesChange}
                                                    noBorder
                                                    startDatePlaceholderText="From"
                                                    endDatePlaceholderText="To"
                                                    isDayBlocked={this.isDayBlocked}
                                                    isOutsideRange={(day) => {
                                                        if (this.currentProperty !== 'rep') {
                                                            return false    
                                                        }
                                                        let dayIsBlocked = false;
                                                        if ((day.isBefore(moment().startOf('day')))) {
                                                            return true;
                                                        }
                                                        if (this.state.maxDisableDates && this.state.maxDisableDates.diff(day) < 0) {
                                                            dayIsBlocked = true;
                                                        }
                                                        return dayIsBlocked;
                                                    }}
                                                    renderDayContents={day => {
                                                        if (this.currentProperty !== 'rep') {
                                                            return <div>{day.format("D")}</div>    
                                                        }
                                                        let className = "";
                                                        if (this.state.availability && this.state.availability.length && this.state.availability.length > 0 && day.startOf("day").isSameOrAfter(moment().startOf("day"))) {
                                                            if (this.state.availability.includes(moment(day).subtract(1, "days").format("YYYY-MM-DD"))) {
                                                                    className = "checkInOnly";
                                                                }
                                                                if (this.state.checkoutDateArray.includes(day.format("YYYY-MM-DD"))) {
                                                                    className = "checkOutOnly";
                                                                }
                                                            }
                                                            return <div className={className}>{day.format("D")}</div>
                                                        }}
                                                />
                                            }
                                        </Col>
                                        <Col md={6} className='bottom-margin-5'>Expires (Date)</Col>
                                        <Col md={6} className='bottom-margin-5'>Expires (Time)</Col>
                                        <Col xs={12} className='bottom-margin-10'>
                                            <Col xs={6} className="expires-date">
                                                <DatePicker
                                                    showClearDate={!modal.data.expires ? false : true}
                                                    placeholder="MM/DD/YYYY"
                                                    initialVisibleMonth={() => moment(modal.data.period_start)}
                                                    date={
                                                        !modal.data.expires ?
                                                        null :
                                                        moment(modal.data.expires).utc()
                                                    }
                                                    onDateChange={(date)=> {
                                                        this.props.handleAvailExpiresDate(date);
                                                    }}
                                                    numberOfMonths={1}
                                                    isOutsideRange={(day) => {
                                                        const todaysDate = moment();
                                                        const period_end = moment(modal.data.period_end).add(1, 'days');

                                                        if(moment(day).isBetween(todaysDate, period_end)) {
                                                            return false;
                                                        }
                                                        else {
                                                            return true;
                                                        };
                                                    }}
                                                />
                                            </Col>
                                            <Col xs={6} className="expires-time">
                                                <InputField 
                                                    type="select"
                                                    name="expiresTime"
                                                    value={modal.expiresTime || expiresTime}
                                                    onChange={e => this.props.handleExpiresTime(e)}
                                                    disabled={!modal.data.expires ? true : false}
                                                >
                                                    {this.expiresTimeLabel}
                                                </InputField>
                                            </Col>
                                        </Col>
                                        {this.currentProperty && this.currentProperty.level === 'rep' &&
                                            <Col md={12} className="prop-type-col top-margin">
                                                <InputLabel htmlFor="title">Total Number of Rooms to <strong>Make Available</strong></InputLabel>
                                                <InputField type="select" value={this.state.units} onChange={this.handleChange.bind(this)}>
                                                    <option value="0">0</option>
                                                    {modal.children.map((e, index) => {
                                                        return <option key={index} value={index + 1}>{index + 1}</option>
                                                    })}
                                                </InputField>
                                            </Col>
                                        }
                                        {
                                            item && item.level !== "rep" &&
                                            <Fragment>
                                                <Col md={12}>Note(s):</Col>
                                                {
                                                    modal.notes.map((note, i) => {
                                                        const { duplicateNotes, typeValidation } = this.props;
                                                        let hasDuplicates = "";
                                                        let duplicateLabel = "";
                                                        let typeValid = "";

                                                        if(typeValidation.includes(i)) {
                                                            typeValid = true;
                                                        }

                                                        if(note.type === "Owner Stay") {
                                                            if(duplicateNotes && duplicateNotes["Owner Stay"] && duplicateNotes["Owner Stay"].includes(i)) {
                                                                duplicateLabel = "Owner Stay";
                                                                hasDuplicates = true;
                                                            } 
                                                            else {
                                                                duplicateLabel = "";
                                                                hasDuplicates = false;
                                                            };
                                                        } 
                                                        else if(note.type === "Direct Guest") {
                                                            if(duplicateNotes && duplicateNotes["Direct Guest"] && duplicateNotes["Direct Guest"].includes(i)) {
                                                                duplicateLabel = "Direct Guest";
                                                                hasDuplicates = true;
                                                            } 
                                                            else {
                                                                duplicateLabel = "";
                                                                hasDuplicates = false;
                                                            };
                                                        } 
                                                        else if(note.type === "Maintenance") {
                                                            if(duplicateNotes && duplicateNotes["Maintenance"] && duplicateNotes["Maintenance"].includes(i)) {
                                                                duplicateLabel = "Maintenance";
                                                                hasDuplicates = true;
                                                            } 
                                                            else {
                                                                duplicateLabel = "";
                                                                hasDuplicates = false;
                                                            };
                                                        }
                                                        else if(note.type === "Hold") {
                                                            if(duplicateNotes && duplicateNotes["Hold"] && duplicateNotes["Hold"].includes(i)) {
                                                                duplicateLabel = "Hold";
                                                                hasDuplicates = true;
                                                            } 
                                                            else {
                                                                duplicateLabel = "";
                                                                hasDuplicates = false;
                                                            };
                                                        }
                                                        else if(note.type === "Other") {
                                                            if(duplicateNotes && duplicateNotes["Other"] && duplicateNotes["Other"].includes(i)) {
                                                                duplicateLabel = "Other";
                                                                hasDuplicates = true;
                                                            } 
                                                            else {
                                                                duplicateLabel = "";
                                                                hasDuplicates = false;
                                                            };
                                                        };

                                                        return (
                                                            <Fragment key={i}>
                                                                <Col xs={12}>
                                                                    <Col xs={12} className="avail-notes-header-container">
                                                                        <Col xs={11}>
                                                                            <InputField
                                                                                type="select"
                                                                                name="type"
                                                                                className={`${hasDuplicates || typeValid ? "form-validation" : ""}`}
                                                                                onChange={(e) => this.props.handleAvailNoteOnChange(e, i)}
                                                                                value={note.type}
                                                                            >
                                                                                <option value="" disabled hidden>Type</option>
                                                                                {
                                                                                    this.props.availNotesTypes.map((type, index) => {
                                                                                        return(
                                                                                            <option value={type} key={index}>{type}</option>
                                                                                        );
                                                                                    })
                                                                                }  
                                                                            </InputField>
                                                                        </Col>
                                                                        <Col xs={1}>
                                                                            <i 
                                                                                className="icon-Delete" 
                                                                                onClick={() => this.props.deleteAvailNote(i)}
                                                                            ></i>
                                                                        </Col> 
                                                                    </Col>
                                                                </Col>
                                                                <Col xs={12} className={`${(note.type === "Other" && this.props.noteValidation === true) || hasDuplicates || typeValid ? "" : "bottom-margin-10" }`}>
                                                                    <InputField
                                                                        cssClass="text-area"
                                                                        className={`${note.type === "Other" && this.props.noteValidation === true ? "form-validation" : ""}`}
                                                                        type="textarea"
                                                                        name="note"
                                                                        value={note.note}
                                                                        onChange={(e) => this.props.handleAvailNoteOnChange(e, i)}
                                                                    />
                                                                </Col>
                                                                {
                                                                    note.type === "Other" && 
                                                                    this.props.noteValidation === true &&
                                                                    <Col xs={12}>
                                                                        <span className="required-error-text">Type Other requires more info.</span>
                                                                    </Col> 
                                                                }
                                                                {
                                                                    hasDuplicates && 
                                                                    <Col xs={12}>
                                                                        <span className="required-error-text">Cannot have duplicate {duplicateLabel}.</span>
                                                                    </Col> 
                                                                }
                                                                {
                                                                    typeValid && 
                                                                    <Col xs={12}>
                                                                        <span className="required-error-text">Type is required.</span>
                                                                    </Col> 
                                                                }
                                                            </Fragment>
                                                        )
                                                    })

                                                }
                                                {
                                                    modal.notes.length < 5 &&
                                                    <Col xs={12} className="center-content">
                                                        <span
                                                            onClick={this.props.addAvailNote}
                                                            className="clickable-text"
                                                        >
                                                            <i className="icon-Plus create-icon"/>
                                                            <span>
                                                                Add additional notes
                                                            </span>
                                                        </span>
                                                    </Col>
                                                }
                                            </Fragment>
                                        }    
                                    </Row>
                                    {
                                        this.props.admin && 
                                        <Row>
                                            {
                                                modal.mode ?
                                                <Col xs={6}>
                                                    <button onClick={this.props.deleteAvailability} className='btn-delete'>Delete this Block<span className={'glyphicon glyphicon-refresh'+(this.props.isDeleting ? ' spinning':' hide')}></span></button>
                                                </Col> :''
                                            }
                                            <Col xs={12} sm={modal.mode? 6:12}>
                                                <PrimaryButton cssClass={modal.mode? 'black-btn ':''} disabled={isDisabled || this.state.isDisabled} type='button' onClick={this.submitAvailability} fullWidth={true}>{item && item.level === "rep" ? 'Update Availability' : 'Block Property'}<span className={'glyphicon glyphicon-refresh'+(this.props.loading ? ' spinning':' hide')}></span></PrimaryButton>
                                            </Col>
                                        </Row>
                                    }
                                    <Row>
                                        <Col xs={12}>
                                            <span className={`error-msg ${errors.period_start.show?'':'hide'}`}>{errors.period_start.message}</span>
                                            <span className={`error-msg ${errors.period_end.show?'':'hide'}`}>{errors.period_end.message}</span>
                                            <span className={errors.server_error.show ?'error-msg':'hide'}>{errors.server_error.message}</span>
                                        </Col>
                                    </Row>
                                </Fragment> :
                                <Fragment>
                                    <Row className='bottom-margin'>
                                        <Col xs={12}>
                                            Blocks cannot be modified on this property's calendar as iCal is set as the source of truth. To modify blocks manually, update the property's iCal settings. 
                                        </Col>
                                        <Col xs={12} className="top-margin pull-left">
                                        {
                                            this.props.activeProperty ?  
                                            <span
                                                className="clickable-text"
                                                onClick={this.props.viewSettingsAvailabilityModal}
                                            >
                                                View Settings
                                            </span>
                                            :
                                            <Link
                                                className="clickable-text"
                                                to={{
                                                    pathname:
                                                    modal && 
                                                    modal.collectionId ? 
                                                    `/collections/${modal.collectionId}/rooms/${modal.property_id}/edit` : 
                                                    `/properties/${modal.property_id}/edit`,
                                                    state: { routeFromCalendar: true },
                                                }}
                                            >
                                                View Settings
                                            </Link>
                                        }
                                        </Col>
                                    </Row>
                                </Fragment>
                            }
                            </Fragment>
                            :
                            <div className='calendar-create-reservation-modal'>
                                <Row>
                                    <Col xs={12} className='bottom-margin-10'>
                                        <InputLabel>
                                            Please enter dates in <strong>mm/dd/yyyy</strong> format.
                                        </InputLabel>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} sm={12} xs={12}>
                                        <Col md={6} sm={6} xs={6} className="start-date">
                                            <InputLabel>
                                                Check-In
                                            </InputLabel>
                                        </Col>
                                        <Col md={6} sm={6} xs={6}>
                                            <InputLabel>
                                                Check-Out
                                            </InputLabel>
                                        </Col>
                                    </Col>
                                </Row>
                                <Row className='bottom-margin-10'>
                                    <Col md={12} sm={12}>
                                        <DateRangePicker
                                            noBorder
                                            startDate={modal.reservations.reservationDates.startDate}
                                            startDateId="startexcluded" 
                                            startDatePlaceholderText="MM/DD/YYYY"
                                            endDate={modal.reservations.reservationDates.endDate} 
                                            endDateId="endexcluded" 
                                            endDatePlaceholderText="MM/DD/YYYY"
                                            focusedInput={reservationFocusedInput}
                                            isOutsideRange={(day) => day.isBefore(moment.utc().subtract(10, 'hours'))}
                                            onFocusChange={focusedInput => this.setState({ reservationFocusedInput: focusedInput })}
                                            onDatesChange={({ startDate, endDate }) => this.props.handleOnDateChange(startDate, endDate)} 
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} sm={12} xs={12}>
                                        <Col md={6} sm={6} xs={6} className="no-pad-left">
                                            <InputLabel>
                                                Adults
                                            </InputLabel>
                                        </Col>
                                        <Col md={6} sm={6} xs={6} className="no-pad-right">
                                            <InputLabel>
                                                Children
                                            </InputLabel>
                                        </Col>
                                    </Col>
                                </Row>
                                <Row className='bottom-margin-10'>
                                    <Col md={12} sm={12} xs={12}>
                                        <Col md={6} sm={6} xs={6} className="no-pad-left">
                                            <InputField 
                                                type="select" 
                                                value={modal.reservations.adults} 
                                                name="adults"
                                                onChange={(e) => this.props.handleAvailReserveOnChange(e, '', item.content.sleep_max)}
                                            >
                                                {modal.reservations.adultOptions}
                                            </InputField>
                                        </Col>
                                        <Col md={6} sm={6} xs={6} className="no-pad-right">
                                            <InputField 
                                                type="select" 
                                                value={modal.reservations.children} 
                                                name="children"
                                                onChange={(e) => this.props.handleAvailReserveOnChange(e, '', item.content.sleep_max)}
                                            >
                                                {modal.reservations.childOptions}
                                            </InputField>
                                        </Col>
                                    </Col>
                                </Row>
                                {
                                    modal.reservations.promotionCodeData.length > 0 &&
                                    <Row className='bottom-margin-10'>
                                        <Col md={12} sm={12}>
                                            <InputLabel>
                                                Promotion
                                            </InputLabel>
                                        </Col>
                                        <Col md={12} sm={12} xs={12}>
                                            <InputField 
                                                type="select" 
                                                value={modal.reservations.promotionCode.org_promo_id} 
                                                name="promotionCode"
                                                onChange={(e) => this.props.handleAvailReserveOnChange(e)}
                                            >
                                                {promotions}
                                            </InputField>
                                        </Col>
                                    </Row>   
                                }
                                <AddonForm
                                    addons={this.props.addons}
                                    quoteIsLoading={modal.reservations.quoteIsLoading}
                                    quoteData={modal.reservations.quoteData}
                                    handleReservationQuote={this.props.handleReservationQuote}
                                    propertyId={this.property_id}
                                    isInternalPropertyAdmin={this.props.isInternalPropertyAdmin}
                                >

                                </AddonForm>
                                {this.props.ratesSummary()}
                                {this.props.errMsg(false, modal.reservations.quoteErrMsg)}
                                <Row>
                                    <Col xs={12} sm={12}>
                                        <PrimaryButton 
                                            fullWidth={true} 
                                            type="button" 
                                            onClick={(e) => this.props.handleGuestInfoModal(e, true)}
                                            disabled={this.props.disableCheckOutbtn() || modal.reservations.quoteIsLoading || modal.reservations.quoteErrMsg.length}
                                        >
                                            Checkout
                                        </PrimaryButton>
                                    </Col>
                                </Row>
                            </div>
                        }
                    </Grid>
                </Modal.Body>
            </Modal>)
    }
}
