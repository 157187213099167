import React, { Component } from 'react';
import Link from 'react-router-dom/Link';
import { connect } from 'react-redux';
import { Title } from '../global/Title';
import { API } from 'aws-amplify';
import { CSVLink } from "react-csv";

import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';

import { InputField } from '../global/forms/FormElements';
import constants from '../../js/constants';
import PageLayout from '../global/PageLayout';
import Loader from '../global/Loader';
import ReactTableComponent from '../global/ReactTableComponent';
import { tableSort } from '../../helpers/helpers';

class ReportsPerformance extends Component {
    constructor(props) {
        super(props);
        this.reactTable = null;
        this.CSVLink = null;
        this.org = this.props.org;
        this.breadcrumbItems = [
            { title: "Reports" },
            { title: "Performance" },
        ];
        this.CSVHeaders = [
            { label: 'Property ID', key: 'property_id'},
            { label: 'Reference ID', key: 'external_property_id'},
            { label: 'Property Name', key: 'property_name'},
            { label: 'Reservations', key: 'count'},
            { label: 'Revenue', key: 'amount'},
        ];
        this.state = {
            error: false,
            isLoading: false,
            activePage: 1,
            pageSize: 10,
            keyword: "",
            performanceReports: [],
            filteredReports: [],
            CSVReports: [],
            dropdownIsOpen: false,
            sorted: {
                id: "property_id",
                desc: true
            },
            columns: [
                {
                    Header:() => tableSort("Property ID"),
                    accessor: 'property_id',
                    'Cell': row=>{
                        const propertyId = row && row.original && row.original.property_id;

                        return(
                            <div>
                                <Link 
                                    className="clickable-text" 
                                    to={`/properties/${propertyId}/edit`}
                                >
                                    {propertyId}
                                </Link>
                            </div>
                        )
                    }
                },
                {
                    Header:() => tableSort("Reference ID"),
                    accessor: 'external_property_id'
                },
                {
                    Header:() => tableSort("Property Name"),
                    accessor: 'property_name'
                },
                {
                    Header:() => tableSort("Reservations"),
                    accessor: 'count'
                },
                {
                    Header:() => tableSort("Revenue"),
                    accessor: 'amount',
                    'Cell': row=>{
                        const amount = row && row.original && row.original.amount;

                        return(
                            <div>
                                $ {constants.NUM_FORMAT(amount)}
                            </div>
                        )
                    }
                }
            ]
        }
    };

    async componentWillMount() {
        this.setState({ 
            isLoading: true
        });

        await this.getPerformance();

        this.setState({
            isLoading: false
        });
    }
    
    getPerformance = async () => {
        let performanceReports;
        let org = this.org;
        let param = '?organization=' + org;

        try {
            performanceReports = await API.get(constants.RAPAPI.NAME,constants.RAPAPI.PROPERTY_PERFORMANCE+param,null);

            if(performanceReports && performanceReports.data) {
                this.setState({
                    performanceReports: performanceReports.data,
                });
            };
        } 
        catch(e) {
            this.setState({
                error: true
            });
        };
    }

    downloadCSV = () => {
        const CSVcomponent = document.getElementById("download-csv");
        CSVcomponent.click();
    }

    pageSizeOnChange = (pageSize) => {
        this.setState({
            activePage: 1,
            pageSize: parseInt(pageSize),
        });
    }

    pageOnChange = (page) => {
        this.setState({
            activePage: parseInt(page)
        });
    }

    filterChannels = (e) => {
        let filteredReports = [...this.state.filteredReports];
        const value = e.target.value;

        filteredReports.splice(0, 1, 
            {...this.state.filteredReports[0], id: "property_id", value: value.toString().toLowerCase() });

        this.setState({
            filteredReports: filteredReports,
            keyword: value,
            activePage: 1,
        });
    }

    clearSearch = () => {
        const { pageSize } = this.state;

        this.setState({
            activePage: 1,
            keyword: "",
            filteredReports: [],
            pageSize: pageSize,
        });
    }

    toggleDropdown = () => this.setState({ dropdownIsOpen: !this.state.dropdownIsOpen })

    getRef = (r) => {
        this.reactTable = r;
    }
    
    render() {
        const { 
            isLoading, 
            performanceReports, 
            activePage, 
            pageSize,
            sorted, 
            columns,
            filteredReports,
            dropdownIsOpen
        } = this.state;

        return(
            <PageLayout isCentered={true}>
                
                {isLoading && <Loader />}

                <Title 
                    title="Performance" 
                    isCentered={true} 
                    breadcrumbItems={this.breadcrumbItems}
                >
                    <DropdownButton  
                        title="Actions"
                        className="primary-btn white-btn"
                        id="property-actions"
                        onToggle={this.toggleDropdown}
                        open={dropdownIsOpen}
                    >
                        <MenuItem 
                            onClick={performanceReports.length ? this.downloadCSV : null}
                            disabled={!performanceReports.length}
                        >
                            Download CSV
                        </MenuItem>
                        <CSVLink
                            asyncOnClick={true}
                            headers={this.CSVHeaders}
                            data={performanceReports}
                            filename={`${this.org}-performance-reports.csv`}
                            target="_blank"
                            id="download-csv"
                        >
                        </CSVLink>
                    </DropdownButton>
                    <div className='search-inp' >
                        <InputField 
                            type='searchbox' 
                            placeholder='Search' 
                            name='keyword' 
                            value={this.state.keyword}
                            onChange={this.filterChannels}
                            clearSearch={this.clearSearch}
                        />
                    </div>
                </Title>
                {
                    <div id="content">
                        <div className="container-fluid">
                            <div className="reports_performance">
                                <ReactTableComponent 
                                    getRef={this.getRef}
                                    reactTable={this.reactTable}
                                    defaultFiltered={filteredReports}
                                    className='-highlight'
                                    noDataText={isLoading ? "Loading..." : "No Reports found. Please contact Customer Support."}
                                    minRows={0}
                                    showPagination={true}
                                    data={performanceReports}
                                    resizable={false}
                                    pageSize={pageSize}
                                    activePage={activePage}
                                    columns={columns}
                                    defaultSorted={[sorted]}
                                    pageSizeOnChange={(pageSize) => this.pageSizeOnChange(pageSize)}
                                    pageOnChange={(page) => this.pageOnChange(page)}
                                    defaultFilterMethod={(filter, row, column) => { 
                                        return filter.value.length ? 
                                            String(row.property_id.toLowerCase()).startsWith(filter.value) || 
                                            String(row.external_property_id.toLowerCase()).startsWith(filter.value) || 
                                            String(row.property_name.toLowerCase()).startsWith(filter.value) : 
                                            true
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                }
            </PageLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        org: state.roleManager.org
    }
}

export default connect(
    mapStateToProps,
    null
)(ReportsPerformance);