import React, { Component, Fragment } from 'react';
import Link from 'react-router-dom/Link';
import { API } from 'aws-amplify';
import 'react-picky/dist/picky.css';
import moment from 'moment';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import isEqual from "lodash/isEqual";

import Form from 'react-bootstrap/lib/Form';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import PanelGroup from 'react-bootstrap/lib/PanelGroup';
import Panel from 'react-bootstrap/lib/Panel';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import DateRangePicker from 'react-dates/lib/components/DateRangePicker';
import Popover from 'react-bootstrap/lib/Popover';

import { Title } from '../global/Title';
import { InputField, PrimaryButton, FieldWrap, InputLabel } from '../global/forms/FormElements';
import { updateUnsavedData } from '../../js/actions';
import GlobalSubmitModal from '../global/forms/GlobalSubmitModal';
import Loader from '../global/Loader';
import PageLayout from '../global/PageLayout';
import constants from '../../js/constants'
import NavigationPrompt from "../global/NavigationPrompt";
import Picky from 'react-picky';

class AddFeeSchedule extends Component {
    FEE_TYPE = [];
    RECIPIENT_TYPE = [];
    VALUE_TYPE = [
        {name: "value_type", value: "amount", title: "$ Amount"},
        {name: "value_type", value: "percent", title: "% Percent"},
    ];
    CHARGE_NAME = [
        {label: 'Rental Amount', value: 'rental_amount'},
    ];
    isAdmin = false;
    org = this.props.roleManager.org || "";
    orgTitle = this.props.roleManager.orgTitle || "";
    roles = this.props.roleManager.roles || [];
    pathname = this.props.history.location.pathname;
    breadcrumbItems = [{ title: "Fees" }];
    paramsFeeId = this.props.match && this.props.match.params && this.props.match.params.id;
    paramsPropertyId = 
        (this.props.match && this.props.match.params && this.props.match.params.property_id) ||
        (this.props.match && this.props.match.params && this.props.match.params.roomId)
    paramsLevel = (this.props.match && this.props.match.url && this.props.match.url.includes('global')) ? "global" : "property";
    duplicateCriteriaData = [];
    newFeeId = "";
    routeTo = 
        (this.pathname.includes("/collection") || this.pathname.includes("/properties")) ? 
        { pathname: this.pathname.replace(`/fees/${this.paramsFeeId}`, "/edit"), state: { routeFromFeeSchedule: true }} : "/fees";
    state = {
        init: true,
        error: false,
        mode: "create",
        activeKey: "1",
        isLoading: false,
        feeScheduleData: {},
        focusedInput: null,
        feesData: {},
        feesList: [],
        submitStatus: 0,
        chargeNames: [],
        description: '',
        modalError: {
            show: false,
            message: "",
            detail: [],
        },
        validations: {
            value_type: false,
            fee_schedule_name: false,
            applies: false,
            fee_type: false,
            value: false,
            effective_from_ts: false,
            effective_to_ts: false,
            duplicateCriteria: false,
            criterion: [],

        },
        data: {
            uid: null,
            nid: null,
            value_type: "",
            fee_schedule_name: "",
            applies: "",
            fee_type: "",
            value: "",
            active: true,
            effective_from_ts: null,
            effective_to_ts: null,
            recipient: "host",
            criterion: [],
            required: true,
            attributes: []
        },
        initialStateModel: {
            data: {},
            description: '',
        }
    }
    
    async componentWillMount() {
        this.setState({
            isLoading: true
        });

        await this.getFeesMetaData();

        if(this.roles.indexOf(constants.USER_TYPES.PROPERTY_MANAGER_ADMIN)> -1) {
            this.isAdmin = true;
        };

        if(this.paramsFeeId !== "create") {
            await this.getFeesData();
           
            if(!this.state.error) {
                this.updateFeesData(this.state.feesData, 'edit');
            };
        };

        await this.getFeesList();

        this.updateInitialStateModel();

        this.setState({
            isLoading: false,
        });
    }

    async componentDidUpdate(nextProps) {
        if(_.get(this.props, 'location.pathname') !== _.get(nextProps, 'location.pathname')) {
            this.setState({
                isLoading: true
            });

            this.paramsFeeId = _.get(this.props, 'match.params.id');
            this.paramsPropertyId = _.get(this.props, 'match.params.property_id') || _.get(this.props, 'match.params.roomId');
            
            await this.getFeesData();

            this.continue = false;

            this.setState({
                isLoading: false,
            });
        };
    }

    getFeesList = async () => {
        const { 
            feesData
         } = this.state;
         const path = 
            this.paramsLevel == "global" ?
            `/organizations/${this.org}/feeschedules` :
            `/properties/${this.paramsPropertyId}/feeschedules`;
        let data = [];
        
        try {
            data = await API.get("rapapi", path);

            if(this.paramsLevel == "global") {
                if(data && data.manager_fee_schedules && data.manager_fee_schedules.fee_schedules && data.manager_fee_schedules.fee_schedules.length) {

                    data.manager_fee_schedules.fee_schedules.map((fees) => {
                        if(fees.fee_schedule_name !== feesData.fee_schedule_name) {
                            this.CHARGE_NAME.push({
                                label: fees.fee_schedule_name,
                                value: fees.fee_schedule_name
                            });
                        }
                    });
                }
            }
            else {
                if(data && data.property_fee_schedules && data.property_fee_schedules.fee_schedules && data.property_fee_schedules.fee_schedules.length) {

                    data.manager_fee_schedules.fee_schedules.map((fees) => {
                        if(fees.fee_schedule_name !== feesData.fee_schedule_name) {
                            this.CHARGE_NAME.push({
                                label: fees.fee_schedule_name,
                                value: fees.fee_schedule_name
                            });
                        }
                    });

                    data.property_fee_schedules.fee_schedules.map((fees) => {
                        if(fees.fee_schedule_name !== feesData.fee_schedule_name) {
                            this.CHARGE_NAME.push({
                                label: fees.fee_schedule_name,
                                value: fees.fee_schedule_name
                            });
                        }
                    });
                }
            }

        }
        catch(e) {
            console.log(e, 'error getting fees');
        };
    }

    getFeesData = async () => {
        const path = 
            this.paramsLevel == "global" ?
            `/organizations/${this.org}/feeschedules/${this.paramsFeeId}` :
            `/properties/${this.paramsPropertyId}/feeschedules/${this.paramsFeeId}`;
        let data = {};

        try {
            data = await API.get("rapapi", path);
            if(data) {
                this.setState({
                    feesData: data,
                });
            };
        }
        catch(e) {
            this.setState({
                error: true,
                isLoading: false,
            });
        }
    }

    getFeesMetaData = async () => {
        let data = {};
        try {
            data = await API.get("rapapi", "/feeschedules/enums");
            if(data) {
                if(data.fee_type) {
                    Object.entries(data.fee_type).forEach((feeType) => {
                        this.FEE_TYPE.push({ name: "fee_type", value: feeType[0], title: feeType[1]})
                    });
                };

                if(data.recipient) {
                    Object.entries(data.recipient).forEach((x) => {
                        this.RECIPIENT_TYPE.push({ value: x[1], label: (x[1][0].toUpperCase() + x[1].slice(1)) })
                    });
                };
            };
        }
        catch(e) {
            this.setState({
                error: true,
                isLoading: false,
            });
        };
    }

    updateFeesData = (data, mode) => {
        const { validations } = this.state;
        const feesData = {...data};
        let criteriaValidations = [];
        let description = '';

        if(data && data.criterion && data.criterion.length) {
            data.criterion.forEach((criteria, i) => {

                // Convert Fee Criteria to array
                if(criteria.criteria_name == 'charge_name') {
                    let newValues = [];

                    criteria.values.split(',').map((x) => {
                        newValues.push({
                            label: x === 'rental_amount' ? 'Rental Amount' : x,
                            value: x
                        });
                    });

                    criteria.values = newValues;
                };
                
                // Add validations for criterion.
                criteriaValidations.push({ 
                    index: i, 
                    criteria_name: false, 
                    operator: false, 
                    values: false 
                });
            });
        };

        //Parse attributes
        let attributes = feesData.attributes || [];
        for (let attribute of attributes){ 
            if (attribute.attribute_name === 'description'){
                description = attribute.attribute_value;
                break;
            }
        }
        this.setState({
            mode: mode,
            description: description,
            data: {
                ...this.state.data,
                uid: (feesData && feesData.uid) || null,
                nid: (feesData && feesData.nid) || null,
                fee_schedule_id: (feesData && feesData.fee_schedule_id) || "",
                fee_schedule_name: (feesData && feesData.fee_schedule_name) || "",
                fee_type: (feesData && feesData.fee_type) || "",
                applies: (feesData && feesData.applies) || "",
                value: (feesData && feesData.value).toString() || "",
                value_type: (feesData && feesData.value_type) || "",
                active: (feesData && feesData.active),
                effective_from_ts: 
                    (feesData && feesData.effective_from_ts) ? 
                    moment.unix(feesData && feesData.effective_from_ts) : 
                    null,
                effective_to_ts: 
                    (feesData && feesData.effective_to_ts) ? 
                    moment.unix(feesData && feesData.effective_to_ts) : 
                    null,
                criterion: (feesData && feesData.criterion) || [],
                recipient: feesData.recipient || "host",
                required: (feesData && feesData.required),
            },
            validations: {
                ...validations,
                criterion: criteriaValidations
            }
        });
    }

    updateInitialStateModel = () => {
        this.setState({
            initialStateModel: {
                ...this.state.initialStateModel,
                data: {...this.state.data},
                description: this.state.description,
            }
        });
    }


    compareState = () => {
        let unsavedData = false;
        const stateModel = {
            data: {...this.state.data},
            description: this.state.description,
        };

        unsavedData = !isEqual(this.state.initialStateModel, stateModel);

		this.props.updateUnsavedData(unsavedData);

        return !isEqual(this.state.initialStateModel, stateModel);
    }

    handleFormSubmit = async () => {
        const { validations, data } = this.state;

        const requiredValidations = [
            { name: "value_type" },
            { name: "fee_schedule_name" },
            { name: "applies" },
            { name: "fee_type" },
            { name: "value" },
            { name: "duplicateCriteria" },
        ];

        validations.criterion.forEach((valid, index) => {
            requiredValidations.push(
                {
                    index: index,
                    name: "criterion",
                }
            );
        });

        if((data.effective_from_ts !== null || data.effective_to_ts !== null) || (validations.effective_from_ts === true || validations.effective_to_ts === true)) {
            [{ name: "effective_from_ts" }, { name: "effective_to_ts" }].forEach((config) => requiredValidations.push(config));
        };

        const checkValidations = this.handleValidations(requiredValidations);

        this.setState({
            validations: checkValidations
        }, async () => {
            const { validations, mode, description} = this.state;
            let dataCopy = { ...this.state.data };
            let attributes = [];
            const notValidValidations = Object.entries(validations).filter((notValid) => notValid[1] === true);
            const notValidCriteria = validations.criterion.filter((val) => (val.criteria_name || val.operator || val.values || val.validValue));
            const combinedValidations = [...notValidValidations, ...notValidCriteria];

            if(!combinedValidations.length) {
                this.setState({
                    activeModal: "submit-modal",
                    progressMessage: mode === "edit" ? "Updating..." : "Saving..."
                });
                
                // Convert value data to numbers.
                dataCopy.value = parseFloat(dataCopy.value);

                // Covert effective dates to timestamp.
                dataCopy.effective_from_ts = dataCopy.effective_from_ts !== null ? moment(dataCopy.effective_from_ts).unix() : null;
                dataCopy.effective_to_ts = dataCopy.effective_to_ts !== null ? moment(dataCopy.effective_to_ts).unix(): null;
        
                // Delete empty fields from schedule fees data.
                if(!dataCopy.criterion.length) {
                    delete dataCopy.criterion;
                };
                if(!dataCopy.effective_from_ts) {
                    delete dataCopy.effective_from_ts
                };
                if(!dataCopy.effective_to_ts) {
                    delete dataCopy.effective_to_ts
                };

                // Convert fee criteria to string.
                if(dataCopy && dataCopy.criterion && dataCopy.criterion.length) {
                    dataCopy.criterion.map((criteria) => {
                        let newValues = [];

                        if(typeof criteria.values == 'object') {
                            criteria.values.map((x) => {
                                newValues.push(x.value);
                            });

                            criteria.values = newValues.join();
                        }
                    });
                };

                if(description) {
                    attributes.push({
                        attribute_name: "description",
                        attribute_value: description
                    });
                }


                if(attributes.length){
                    dataCopy.attributes = attributes;
                }

                // Edit Schedule Fees
                let newData = {};

                if(mode == "edit") { 
                    if(this.paramsLevel == "global") {
                        try {
                            newData = await API.put("rapapi", `/organizations/${this.org}/feeschedules/${this.paramsFeeId}`, { body: dataCopy });
                            this.setState({
                                submitStatus: this.state.submitStatus + 1
                            });
                        }
                        catch(err) {
                            this.setModalError(err && err.response && err.response.data);
                        };
                    }
                    else {
                        try {
                            newData = await API.put("rapapi", `/properties/${this.paramsPropertyId}/feeschedules/${this.paramsFeeId}`, { body: dataCopy });
                            this.setState({
                                submitStatus: this.state.submitStatus + 1
                            });
                        }
                        catch(err) {
                            this.setModalError(err && err.response && err.response.data);
                        };
                    };
                }
                // Create Schedule Fees
                else { 
                    if(this.paramsLevel == "global") {
                        try {
                            newData = await API.post("rapapi", `/organizations/${this.org}/feeschedules`, { body: dataCopy });
                            this.setState({
                                submitStatus: this.state.submitStatus + 1
                            });
                        }
                        catch(err) {
                            this.setModalError(err && err.response && err.response.data);
                        };
                    }
                    else {
                        try {
                            newData = await API.post("rapapi", `/properties/${this.paramsPropertyId}/feeschedules`, { body: dataCopy });
                            this.setState({
                                submitStatus: this.state.submitStatus + 1
                            });
                        }
                        catch(err) {
                            this.setModalError(err && err.response && err.response.data);
                        };
                    };
                };
                if(!this.state.modalError.show) {
                    if(newData) {
                        this.newFeeId = newData.fee_schedule_id;
                        this.updateFeesData(newData, 'edit');
                    };
                    this.updateInitialStateModel();
                    this.setState({
                        progressMessage: "Fee Schedule Saved",
                    });
                };
            }
            else {
                if(combinedValidations.length) {
                    this.scrollToValidation();
                };  
            }
        });
    }


    scrollToValidation = () => {
		this.setState({
			activeKey:"1", 
			init: true,
		}, async () => {
			setTimeout(() => {
				document.getElementsByClassName('form-validation').item(0).scrollIntoView({
					block: "center",
					behavior: "smooth"
				});
			}, 500);	
		});
    };
    
    setModalError = (error) => {
        let detailError = [...this.state.modalError.detail];

        if(error && error.errors && error.errors[0] && error.errors[0].message) {
            detailError.push(error.errors[0].message);
        }
        else if(error && error.Message) {
            detailError.push(error.Message);
        };

        this.setState({ 
            submitStatus: this.state.submitStatus + 1,
            modalError: {
                show: true,
                message: "Error Saving Fee Schedule.",
                detail: detailError,
            }
        });
    }

    closeModal = () => {
        this.setState({
            activeModal: "",
            submitStatus: 0,
            modalError: {
                show: false,
                message: "",
                detail: "",
            }
        });
    }

    validCriteriaValueFormat = (criteriaName, criteriaOperator, criteriaValue) => {

        let needsMultipleValues = criteriaOperator === "in" || criteriaOperator === "between";
        let days_of_week = new Set([
            "sunday",
            "monday",
            "tuesday",
            "wednesday",
            "thursday",
            "friday",
            'saturday',
        ])
        let validateBasedOnCriteria = (criteria,value)=>{
            if (criteria === "stay_dow") {
                return days_of_week.has(value.toLowerCase());
            } else if(criteriaName === "booking_date" || criteriaName === "check_in_date"){
                return (/^\d{4}-\d{2}-\d{2}$/).test(value);
            }else if(criteriaName === "stay_date"){
                return (/^\d{1,2}\/\d{1,2}$/).test(value);
            }
            return (/^\d+$/).test(value);
        }

        if (needsMultipleValues){
            let values = criteriaValue.split(',');
            if (values.at(-1) === '') values.pop();
            if(values.length === 1 || (values.length > 2 && criteriaOperator === "between")) return false;
            return values.every((value) => validateBasedOnCriteria(criteriaName, value));
        }
            
        return validateBasedOnCriteria(criteriaName, criteriaValue);

    }

    handleValidations = (configs) => {
        const { data } = this.state;
        const validations = JSON.parse(JSON.stringify(this.state.validations));

        configs.forEach((config) => {
            if(config.name == "effective_from_ts" || config.name == "effective_to_ts") {
                if(data.effective_from_ts == null && data.effective_to_ts == null) { 
                    validations.effective_from_ts = false;
                    validations.effective_to_ts = false;
                }
                else {
                    if(!data[config.name]) {
                        validations[config.name] = true;
                    }
                    else {
                        validations[config.name] = false;
                    };
                };
            }
            else if(config.name == "duplicateCriteria") {
                if(data.criterion.length) {
                    const duplicateCriteriaName = this.checkDuplicates(data.criterion, "criteria_name");
                    const duplicateCriteriaOperator = this.checkDuplicates(data.criterion, "operator");
                    let hasDuplicates = {};

                    Object.entries(duplicateCriteriaName).forEach((name, x) => {
                        name[1].forEach((nameIndex) => {
                            Object.entries(duplicateCriteriaOperator).forEach((operator) => {
                                let arr = [];
                                name[1].forEach((nameIndex2) => {
                                    arr.push(operator[1].includes(nameIndex2) ? 1 : 2);
                                });
                                if(!arr.includes(2)) {
                                    operator[1].forEach((operatorIndex, y) => {
                                        if((operatorIndex == nameIndex) && !hasDuplicates.hasOwnProperty(name[0])) {
                                            hasDuplicates[name[0]] = [operatorIndex];
                                        }
                                        else if((operatorIndex == nameIndex)) {
                                            hasDuplicates[name[0]].push(operatorIndex)
                                        };
                                    });
                                };
                            });
                        })
                    });

                    this.duplicateCriteriaData = hasDuplicates;

                    if(Object.keys(hasDuplicates).length) {
                        validations[config.name] = true;
                    }
                    else {
                        validations[config.name] = false;
                    };
                };
            }
            else if(config.name == "criterion") {
                const criteria_name = 
                    data && 
                    data.criterion[config.index] && 
                    data.criterion[config.index].criteria_name;
                const operator = 
                    data && 
                    data.criterion[config.index] && 
                    data.criterion[config.index].operator;
                const values = 
                    data && 
                    data.criterion[config.index] && 
                    data.criterion[config.index].values;
                const validValueFormat = 
                    criteria_name && 
                    criteria_name !== 'charge_name' &&
                    operator && 
                    values && 
                    !this.validCriteriaValueFormat(criteria_name, operator, values);

                if(data.criterion.length) {
                    validations.criterion.splice(config.index, 1, 
                        { 
                            ...validations.criterion[config.index], 
                            criteria_name: !criteria_name.length ? true : false,
                            operator: !operator.length ? true : false,
                            values: !values.length ? true : false,
                            validValue: validValueFormat,
                        }
                    );
                };
            }
            else {
                if(!data[config.name].length) {
                    validations[config.name] = true;
                }
                else {
                    validations[config.name] = false;
                };
            }

        });
        return validations;
    }

    checkDuplicates = (state, type) => {
		let data = [...state];
		let duplicateArr = [];
		let duplicateList = {};
		
		for(let i = 0; i < data.length; i++) {
			duplicateArr.splice(i, 0, data[i][type])
		};
		for(let i = 0; i < duplicateArr.length; i++) {
			if(duplicateList.hasOwnProperty(duplicateArr[i])) {
				duplicateList[duplicateArr[i]].push(i);
			}
			else if (duplicateArr.lastIndexOf(duplicateArr[i]) !== i) {
				duplicateList[duplicateArr[i]] = [i];
			};
        };
        delete duplicateList[""];

		return duplicateList;
    }

    getTooltip = (id,text) => {
        return <Tooltip id={id} className='properties__tooltip'>{text}</Tooltip>
    }

    panelOnSelect = (activekey, e, customKey) => {
        if(e) e.preventDefault();
        
        this.setState({ 
            activeKey: customKey ? customKey : activekey,
            init: false,
        });
    }

    addFeeCriteria = () => {
        const criteriaData = [...this.state.data.criterion];
        const criteriaValidations = [...this.state.validations.criterion];

        criteriaData.push({
            criteria_name: "",
            operator: "",
            values: ""
        });
        criteriaValidations.push({ 
            index: (criteriaData.length - 1), 
            criteria_name: false, 
            operator: false,
            values: false,
            validValue: false,
        });

        this.setState({
            data: {
                ...this.state.data,
                criterion: criteriaData
            },
            validations: {
                ...this.state.validations,
                criterion: criteriaValidations
            }
        });
    }

    deleteFeeCriteria = (index) => {
        const criteriaData = [...this.state.data.criterion];
        const criteriaValidations = [...this.state.validations.criterion];

        // Delete Criteria Data.
        criteriaData.splice(index, 1);

        // Delete Criteria Validations.
        criteriaValidations.splice(index, 1);

        // Change index value in Criteria Validations to new index order.
        criteriaValidations.forEach((val, i) => {
            criteriaValidations.splice(i, 1, {...val, index: i})
        });

        this.setState({
            data: {
                ...this.state.data,
                criterion: criteriaData
            },
            validations: {
                ...this.state.validations,
                criterion: criteriaValidations
            }
        }, () => {
            const newValidation = this.handleValidations([{ name: "duplicateCriteria"}]);

            this.setState({
                validations: newValidation
            });

        });
    }

    handleCriteriaOnChange = (e, index) => {
        const name = e.target.name.replace(/[0-9]/g, '');
        const value = e.target.value;
        const criteriaData = [...this.state.data.criterion];

        if(typeof (criteriaData && criteriaData[index] && criteriaData[index].values) === 'object' || (name === 'criteria_name' && value === 'charge_name')) {
            criteriaData.splice(index, 1, {
                ...criteriaData[index], [name]: value,
                operator: 'in',
                values: (name === 'criteria_name' && value === 'charge_name') ? [] : ''
            });
        }
        else {
            criteriaData.splice(index, 1, {
                ...criteriaData[index], [name]: value
            });
        };

        this.setState({
            data: {
                ...this.state.data,
                criterion: criteriaData
            }
        }, () => {
            const { validations } = this.state;

            if(validations.criterion[index][name] == true || validations.duplicateCriteria == true) {
                const newValidation = this.handleValidations([{ name: "criterion", index: index }, { name: "duplicateCriteria"}]);
    
                this.setState({
                    validations: newValidation
                });
            };
        });
    }

    handleChargeNameOnChange = (value, index) => {
        const criteriaData = [...this.state.data.criterion];

        criteriaData.splice(index, 1, {
            ...criteriaData[index], values: [...value]
        });

        this.setState({
            data: {
                ...this.state.data,
                criterion: criteriaData
            }
        }, () => {
            const { validations } = this.state;

            if(validations.criterion[index].values == true || validations.duplicateCriteria == true) {

                const newValidation = this.handleValidations([{ name: "criterion", index: index }, { name: "duplicateCriteria"}]);
    
                this.setState({
                    validations: newValidation
                });
            };
        });
    }

    handleOnChange = (e) => {
        const name = e.target.name;
        let value = e.target.value;

        if(name == "value" && this.state.data.value_type === "percent") {
            if(value > 100) {
                value = "100";
            }
            else if(value < 0) {
                value = "1";
            };
        };
        if (name === "description"){
            this.setState({
                description : value
            });
        } else {
        this.setState({
            data: {
                ...this.state.data,
                [name]: value
            }
        }, () => {
            const { validations } = this.state;

            if(validations[name] == true) {
                const newValidation = this.handleValidations([{ name: name }]);
    
                this.setState({
                    validations: newValidation
                });
            };
        });
    }
    }

    handleDateOnChange = (date) => {
        const name = 
            this.state.focusedInput == "startDate" ?
            "effective_from_ts" :
            "effective_to_ts";
        const value = 
            this.state.focusedInput == "startDate" ?
            date.startDate :
            date.endDate
        
            this.setState({
                data: {
                    ...this.state.data,
                    [name]: value
                }
            }, () => {
                const { validations } = this.state;

                if(validations.effective_from_ts == true || validations.effective_to_ts == true) {
                    const newValidation = this.handleValidations([{ name: name }]);
        
                    this.setState({
                        validations: newValidation
                    });
                };
            });
    }

    valueTypeHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let criterionCopy = [...this.state.data.criterion];
        let criterionValidationCopy = [...this.state.validations.criterion]
        let criteriaName = [];

        // Creates charge_name criteria if value_type is percent.
        if(value === "percent") {
            criterionCopy.forEach((criteria) => {
                if(criteria.criteria_name) {
                    criteriaName.push(criteria.criteria_name)
                };
            });
            if(!criteriaName.includes("charge_name")) {
                criterionCopy.push({
                    criteria_name: "charge_name",
                    operator: "in",
                    values: ""
                });
                criterionValidationCopy.push({ 
                    index: criterionCopy.length ? (criterionCopy.length - 1) : 0, 
                    criteria_name: false, 
                    operator: false, 
                    values: false 
                });
            };
        };

        this.setState({
            data: {
                ...this.state.data,
                criterion: criterionCopy,
                [name]: value,
                value: (name === "value_type" && value === "percent" && this.state.data.value > 100) ? "" : this.state.data.value,
            },
            validations: {
                ...this.state.validations,
                criterion: criterionValidationCopy
            }
        }, () => {
            const { validations } = this.state;

            if(validations[name] == true) {
                const newValidation = this.handleValidations([{ name: name }]);
    
                this.setState({
                    validations: newValidation
                });
            };
        });
    }

    radioButtonHandler = (e) => {
        const { data } = this.state;
        const name = e.target.name;
        const value = e.target.value;
        let state = {
            ...data,
            [name]: value,
        };

        if(name == 'fee_type' && value == 'pet_fee') {
            state.required = false;
            state.value_type = 'amount';
        };

        this.setState({
            data: state
        }, () => {
            const { validations } = this.state;

            if(validations[name] == true) {
                const newValidation = this.handleValidations([{ name: name }]);
    
                this.setState({
                    validations: newValidation
                });
            };
        });
    }

    toggleStatus = (e) => {
        e.preventDefault();

        this.setState({
            data: {
                ...this.state.data,
                active: !this.state.data.active
            }
        });
    }
    toggleRequired = () => {
        this.setState({
            data: {
                ...this.state.data,
                required: !this.state.data.required
            }
        });
    }

    createRadioButton = (labels, state, func) => {
      let maxCol = Math.min(labels.length, 6);
      let transformLabels = (labels) =>{
        const result = [];
        for (let i = 0; i < labels.length; i += maxCol) {
          result.push(labels.slice(i, i + maxCol));
        }
        return result;
      
      }
      let rowLabels = transformLabels(labels);

      return rowLabels.map((colLabels, i) => (
        
        <Row key={i}>
            {
                colLabels.map((label, j) => {
                    const disabled = label.name == 'value_type' && _.get(this.state.data, 'fee_type') == 'pet_fee';
                    let style = {
                        whiteSpace: maxCol === 2? "nowrap":"normal",
                        color: disabled ? '#dedede' : 'inherit',
                        cursor: disabled ? 'not-allowed' : 'pointer'
                    };

                    return (
                        <Col key={i * maxCol + j} lg ={2} md={maxCol === 2 ? 3:2} sm={12} >
                            <label id={label.name} className="radio-wrap radio-input">
                                <input
                                    type="radio"
                                    className="form-radio-label radio-label"
                                    name={label.name}
                                    onChange={(e) => func(e)}
                                    checked={state == label.value ? label.value : ""}
                                    value={label.value}
                                    disabled={disabled}
                                />
                                <div className="radio-label" style={style}>{label.title}</div>
                            </label>
                        </Col>
                    )
                })
            }
        </Row>
      ));
    };

    feeAppliesPopover = () => {
        let message = "";
        let title = "";

        constants.FEE_APPLIES_TYPE.forEach((appliesType) => {
            if(this.state.data.applies == appliesType.value) {
                title = appliesType.label;
            };
        });

        switch (this.state.data.applies) {
            case "per_stay":
                message = "The Eligible Amount is included as-is.";
                break;
            case "per_stay_per_guest":
                message = "The amount included is calculated as a multiplyer for Eligible Amount and Eligible Guests.";
                break;
            case "per_stay_per_pet":
                message = "The amount included is calculated as a multiplyer for Eligible Amount and Eligible Pets.";
                break;
            case "per_night":
                message = "The amount included is calculated as a multiplyer for Eligible Amount and Eligible Nights.";
                break;
            case "per_night_per_guest":
                message = "The amount included is calculated as a multiplyer for Eligible Amount, Nights, and Guests.";
                break;
            case "per_night_per_pet":
                message = "The amount included is calculated as a multiplyer for Eligible Amount, Nights, and Pets.";
                break;
            default:
                message = "";
        };
        
        return (
            <OverlayTrigger 
                placement='top' 
                overlay={<Popover
                    id="popover-basic"
                    placement="top"
                    title={title}
                >
                    <p>{message}</p>
                </Popover>}
            >
                <span className="fs-criteria-question-mark-icon-cntr">
                    <i className="icon-question-mark"></i>
                </span>
            </OverlayTrigger>
        )
    }

    feeCriteriaPopover = (criteria) => {
        let title = "";
        let message = "";

        constants.CRITERIA_NAME.forEach((criteriaName) => {
            if(criteria.criteria_name == criteriaName.value) {
                title = criteriaName.label;
            };
        });

        switch (criteria.criteria_name) {
            case "charge_name":
                message = (
                    <div>
                        <p>Refers to the name to which the fee will be charged.</p>
                    </div>
                )
                break;
            case "los":
                message = (
                    <div>
                        <p>Refers to the days between check-in and check-out dates.</p>
                        <p>Example: Fee applies between the 7th and 14th of check-in and check-out date.</p>
                        <p><strong>(Length of Stay, between, '7, 14')</strong></p>
                    </div>
                )
                break;
            case "guests":
                message = (
                    <div>
                        <p>Refers to the number (count) of guests.</p>
                        <p>Example: Fee applies to 5 or more guests.</p>
                        <p><strong>(Number of Guests, Greater than or Equal to, '5')</strong></p>
                    </div>
                )
                break;
            case "pets":
                message = (
                    <div>
                        <p>Refers to the number (count) of pets.</p>
                        <p>Example: Fee applies to less than 2 pets.</p>
                        <p><strong>(Number of Pets, Less than, '2')</strong></p>
                    </div>
                )
                break;
            case "check_in_date":
                message = (
                    <div>
                        <p>Refers to check-in dates.</p>
                        <p>Example: Fee applies between 01/15/20 to 01/25/20 of check-in date.</p>
                        <p><strong>(Check in Date(s), Between, '2020-01-15, 2020-01-25')</strong></p>
                    </div>
                )
                break;
            case "nights_booked_in_advance":
                message = (
                    <div>
                        <p>Refers to the number of days between the booking date and check-in date.</p>
                        <p>Example: Fee applies 7 day before the check-in date.</p>
                        <p><strong>(Nights Booked In Advance, Less than or Equal to, '7')</strong></p>
                    </div>
                )
                break;
            case "booking_date":
                message = (
                    <div>
                        <p>Refers to the booking dates.</p>
                        <p>Example: Fee applies on the month of January.</p>
                        <p><strong>(Booking Date(s), Between, '2020-01-01, 2020-01-31')</strong></p>
                    </div>
                )
                break;
            case "stay_dow":
                message = (
                    <div>
                        <p>Refers to the days of week (Mon/Tues/Wed/…/Sun) of the stay.</p>
                        <p>Example: Fee applies on Friday & Saturday nights of stay.</p>
                        <p><strong>(Day(s) of Week of the Stay, In, 'Friday, Saturday')</strong></p>
                    </div>
                )
                break;
            case "stay_date":
                message = (
                    <div>
                        <p>Refers to the dates of the stay.</p>
                        <p>Example: Fee applies on the 3rd, 4th, & 5th of July, in any year.</p>
                        <p><strong>(Stay Date, In, '7/3, 7/4, 7/5')</strong></p>
                    </div>
                )
                break;
            case "stay_night_number":
                message = (
                    <div>
                        <p>Refers to the number of nights of the stay.</p>
                        <p>Example: Fee applies on the 4th, 5th, & 6th nights of stay.</p>
                        <p><strong>(Stay Night, In, '4, 5, 6')</strong></p>
                    </div>
                )
                break;
            case "guest_number":
                message = (
                    <div>
                        <p>Refers to guest first indexed number.</p>
                        <p>Example: Fee applies on the 3rd guest and on every additional guests.</p>
                        <p><strong>(Guest Number, Greater than or Equal to, '3')</strong></p>
                    </div>
                )
                break; 
            case "pet_number":
                message = (
                    <div>
                        <p>Refers to pets first indexed number.</p>
                        <p>Example: Fee applies on the 2nd pet and on every additional pets.</p>
                        <p><strong>(Pet Number,  Greater than or Equal to, '2')</strong></p>
                    </div>
                )
                break; 
            default:
                message = "";
        };

        return (
            <OverlayTrigger
                placement='top' 
                overlay={<Popover
                    id="popover-basic"
                    placement="top"
                    title={title}
                >
                    {message}
                </Popover>}
            >
                <span className={`fs-criteria-question-mark-icon-cntr ${criteria.criteria_name === 'charge_name' ? `charge-name` : ''}`}>
                    <i className="icon-question-mark"></i>
                </span>
            </OverlayTrigger>
        )

    }

    createCriteriaValuePlaceHolder = (criteriaName, operator) =>{
        if(!criteriaName || !operator) return "";

        let needsMultipleValues = operator === "in" || operator === "between";
        
        if (criteriaName === "stay_dow"){
            return `Example: ${needsMultipleValues? "Saturday, Sunday": "Saturday"}`;
        }else if (criteriaName === "booking_date" || criteriaName === "check_in_date"){
            return `Example: ${needsMultipleValues? "2020-01-01, 2020-01-31": "2020-01-01"}`;
        }else if(criteriaName === "stay_date"){
            return `Example ${needsMultipleValues? "7/3, 7/5": "7/3"}`;
        }
        return `Example: ${needsMultipleValues? "3,5": "3"}`;
    }

    createCriteriaConfig = () => {
        const { data, validations } = this.state;

        let criteriaName = [<option key="" value="" disabled></option>];
        constants.CRITERIA_NAME.map((applies, i) => {
            criteriaName.push(<option key={i} value={applies.value}>{applies.label}</option>);
        });

        return data.criterion.map((criteria, i) => {
            let hasDuplicates = false;
            let criteriaNameLabel = "";
            let operatorLabel = "";
            let criteriaValuePlaceHolder = this.createCriteriaValuePlaceHolder(criteria.criteria_name, criteria.operator);

            const validCriteriaName = 
                validations && 
                validations.criterion && 
                validations.criterion[i] && 
                validations.criterion[i].criteria_name;
            const validOperator = 
                validations && 
                validations.criterion && 
                validations.criterion[i] && 
                validations.criterion[i].operator;
            const validValues = 
                validations && 
                validations.criterion && 
                validations.criterion[i] && 
                validations.criterion[i].values;
            const validValueFormat = 
                validations && 
                validations.criterion && 
                validations.criterion[i] && 
                validations.criterion[i].validValue;

            let criteriaOperator = [<option key="" value="" disabled></option>];
            constants.CRITERIA_OPERATOR.map((applies, i) => {
                if(criteria.criteria_name == "charge_name") {
                    if(applies.value == "eq" || applies.value == "in")
                    criteriaOperator.push(<option key={i} value={applies.value}>{applies.label}</option>);
                }
                else {
                    criteriaOperator.push(<option key={i} value={applies.value}>{applies.label}</option>);
                };
            });

            Object.entries(this.duplicateCriteriaData).forEach((duplicate) => {
                if(duplicate[1].includes(i)) {
                    hasDuplicates = true;
                };
            });
            constants.CRITERIA_NAME.forEach((config) => {
                if(config.value == criteria.criteria_name) {
                    criteriaNameLabel = config.label;
                };
            });
            constants.CRITERIA_OPERATOR.forEach((config) => {
                if(config.value == criteria.operator) {
                    operatorLabel = config.label;
                };
            });

            return (
              <Fragment key={i}>
                <Row
                  className={`fs-criteria-cntr 
                                                ${
                                                  hasDuplicates ||
                                                  validCriteriaName ||
                                                  validOperator ||
                                                  validValues ||
                                                  validValueFormat
                                                    ? "form-validation"
                                                    : ""
                                                }`}
                >
                  <Col md={criteria.criteria_name !== "charge_name" ? 4 : 6}>
                    <InputLabel
                      className={`${
                        validCriteriaName ? "form-validation" : ""
                      }`}
                    >
                      Criteria Name
                    </InputLabel>
                    <InputField
                      type="select"
                      name={"criteria_name" + i}
                      value={criteria.criteria_name}
                      onChange={(e) => this.handleCriteriaOnChange(e, i)}
                      className={`${
                        validCriteriaName ? "form-validation" : ""
                      }`}
                    >
                      {criteriaName}
                    </InputField>
                    {criteria.criteria_name.length > 0 &&
                      this.feeCriteriaPopover(criteria)}
                  </Col>
                  {criteria.criteria_name !== "charge_name" && (
                    <Col md={4} sm={12}>
                      <InputLabel
                        className={`${validOperator ? "form-validation" : ""}`}
                      >
                        Operator
                      </InputLabel>
                      <InputField
                        type="select"
                        name={"operator" + i}
                        value={criteria.operator}
                        onChange={(e) => this.handleCriteriaOnChange(e, i)}
                        className={`${validOperator ? "form-validation" : ""}`}
                      >
                        {criteriaOperator}
                      </InputField>
                    </Col>
                  )}
                  <Col
                    md={criteria.criteria_name == "charge_name" ? 6 : 4}
                    sm={12}
                    xs={12}
                  >
                    <InputLabel
                      className={`${validOperator ? "form-validation" : ""}`}
                    >
                      Value
                    </InputLabel>
                    {criteria.criteria_name == "charge_name" ? (
                      <Picky
                        placeholder=""
                        labelKey="label"
                        valueKey="value"
                        options={this.CHARGE_NAME}
                        value={criteria.values}
                        multiple={true}
                        includeSelectAll={false}
                        keepOpen={false}
                        onChange={(value) =>
                          this.handleChargeNameOnChange(value, i)
                        }
                        dropdownHeight={600}
                        disabled={!this.isAdmin}
                      />
                    ) : (
                      <InputField
                        type="text"
                        name={"values" + i}
                        value={criteria.values}
                        placeholder={criteriaValuePlaceHolder}
                        onChange={(e) => this.handleCriteriaOnChange(e, i)}
                        className={`${
                          validValues || validValueFormat
                            ? "form-validation"
                            : ""
                        }`}
                      />
                    )}
                    {this.props.isInternalPropertyAdmin && (
                      <OverlayTrigger
                        placement="bottom"
                        overlay={this.getTooltip(
                          "delete",
                          "Delete Fee Criteria"
                        )}
                      >
                        <div
                          className="fs-criteria-del-icon-cntr pointer"
                          onClick={() => this.deleteFeeCriteria(i)}
                        >
                          <i className="icon icon-Delete"></i>
                        </div>
                      </OverlayTrigger>
                    )}
                  </Col>
                </Row>
                {hasDuplicates && (
                  <span className="required-error-text">
                    Cannot have duplicate {criteriaNameLabel} and{" "}
                    {operatorLabel}.
                  </span>
                )}
                {validCriteriaName && (
                  <span className="required-error-text">
                    Criteria Name is required.
                  </span>
                )}
                {validOperator && (
                  <span className="required-error-text">
                    Criteria Operator is required.
                  </span>
                )}
                {validValues && (
                  <span className="required-error-text">
                    Criteria input is required.
                  </span>
                )}
                {validValueFormat && (
                  <span className="required-error-text">
                    Invalid Criteria Value Format.
                  </span>
                )}
                <br />
              </Fragment>
            );
        })
    }

    handleContinueUrl = () => {
        const { data } = this.state;
        const pathname = {
            pathname: this.pathname.replace("/create", _.get(data, 'fee_schedule_id') ? `/${data.fee_schedule_id}` : `/${this.newFeeId}`),
        };

        return pathname;
    }

    render() {
        const { 
            init, 
            error, 
            isLoading, 
            data, 
            mode, 
            validations,
            activeKey, 
            focusedInput,
            activeModal,
            submitStatus,
            modalError,
            progressMessage,
            description,
        } = this.state;

        let appliesType = [<option key="" value="" disabled></option>];
        constants.FEE_APPLIES_TYPE.map((x, i) => {
            appliesType.push(<option key={i} value={x.value}>{x.label}</option>);
        });

        let recipientType = [];
        this.RECIPIENT_TYPE.map((x, i) => {
            recipientType.push(<option key={i} value={x.value}>{x.label}</option>);
        });

        return (
            <PageLayout isCentered={true}>

            {isLoading && <Loader />}

            {
                error && 
                <div className="container-fluid">
                    <Title 
                        title={                           
                            `${mode.charAt(0).toUpperCase() + mode.slice(1)} 
                            ${this.paramsLevel.charAt(0).toUpperCase() + this.paramsLevel.slice(1)}
                            ${(mode == "create" && this.paramsLevel == "global") ? (this.orgTitle ? this.orgTitle : this.org) : ""} 
                            Fee ${mode !== "create" ? this.paramsFeeId : ""}`
                        }
                        isCentered={true}
                        breadcrumbItems={this.breadcrumbItems}
                    >
                        <Link 
                            to={this.routeTo} 
                            className="primary-btn black-btn pull-left right-margin">
                           Back
                        </Link>
                    </Title>
                    <div id="content">
                        <Col>
                            <div className="no-collection">{`${this.paramsLevel.charAt(0).toUpperCase() + this.paramsLevel.slice(1)} Fee could not be found.`}</div>
                        </Col>
                    </div>
                </div>
            }
            {
                (!isLoading && !error) &&
                <div className="container-fluid">
                    <Title 
                        title={                           
                            `${mode.charAt(0).toUpperCase() + mode.slice(1)} 
                            ${this.paramsLevel.charAt(0).toUpperCase() + this.paramsLevel.slice(1)}
                            ${(mode == "create" && this.paramsLevel == "global") ? (this.orgTitle ? this.orgTitle : this.org) : ""} 
                            Fee ${mode !== "create" ? this.paramsFeeId : ""}`
                        }
                        isCentered={true}
                        breadcrumbItems={this.breadcrumbItems}
                    >
                    <div className="top-left-content">
                        <div className="property-status">
                            <span>Status</span>
                            <div
                                className={
                                    `primary-btn active-btn live-btn 
                                    ${!data.active ? "not-live" : ""}`
                                }
                                onClick={
                                    this.props.isInternalPropertyAdmin ? 
                                    e => this.toggleStatus(e) : 
                                    null
                                }
                            >
                                {
                                    data.active ? 
                                    "Live" : 
                                    "Not Live"
                                }
                                <i className="icon icon-selection_tip"></i>
                            </div>
                        </div>
                    </div>
                    {
                        false && // TODO: FIX CLONE MODE
                        <DropdownButton  
                            title={'Actions'}
                            className="primary-btn white-btn"
                            id="property-actions"
                        >
                            <MenuItem>
                                <span
                                    onClick={(e) => {
                                        e.stopPropagation(),
                                        this.props.history.push({ pathname: "/account-management/organizations/create", 
                                        state: { cloneData: this.state.organization, cloneMode: true } })}
                                    }
                                > 
                                    Clone Fee 
                                </span>
                            </MenuItem>
                        </DropdownButton>
                    }
                    </Title>
                    <div id="content">
                        <div className={"container-fluid property-item row" + (!this.isAdmin ? " readonly" : "")}>
                            <FieldWrap>
                                <Form horizontal>
                                    <Row>
                                        <Col md={12} xs={12} sm={12} lg={12}>
                                            <PanelGroup 
                                                accordion id="accordion-controlled-example" 
                                                activeKey={activeKey}
                                                onSelect={activeKey =>this.panelOnSelect(activeKey)}
                                            >
                                            <Panel eventKey='1'>
                                                <Panel.Heading>
                                                    <Panel.Title 
                                                        toggle
                                                        onClick={e => this.panelOnSelect(null, e, "1")}
                                                    > 
                                                        Reservation Fees
                                                            <span 
                                                                className={`pull-right 
                                                                    ${(activeKey === '1' || init) ?
                                                                    'glyphicon glyphicon-chevron-up' :
                                                                    'glyphicon glyphicon-chevron-down'}`}
                                                            >
                                                            </span>
                                                    </Panel.Title>
                                                </Panel.Heading>
                                                <Panel.Body collapsible>
                                                    {
                                                        this.props.isInternalPropertyAdmin &&
                                                        <Fragment>
                                                            <Row>
                                                                <Col md={6} sm={12} xs={12}>
                                                                    <InputLabel>
                                                                        Recipient
                                                                    </InputLabel>
                                                                    <InputField 
                                                                        type="select" 
                                                                        name="recipient"
                                                                        value={data.recipient}
                                                                        onChange={this.handleOnChange}
                                                                    >
                                                                        {recipientType}
                                                                    </InputField>
                                                                </Col>
                                                            </Row>
                                                            <br/>
                                                        </Fragment>
                                                    }
                                                    <Row>
                                                        <Col lg={12}>
                                                            <InputLabel
                                                                className={validations.fee_type && 'form-validation'}
                                                            >
                                                                Fee Type
                                                            </InputLabel>
                                                            {this.createRadioButton(this.FEE_TYPE, data.fee_type, this.radioButtonHandler)}
                                                            {validations.fee_type && <span className="required-error-text">Fee Type is required</span>}
                                                        </Col>
                                                    </Row>
                                                    <br/>
                                                    <Row>
                                                        <Col md={6} sm={12}>
                                                            <InputLabel
                                                                className={validations.fee_schedule_name && 'form-validation'}
                                                            >
                                                                Display Name
                                                            </InputLabel>
                                                            <InputField 
                                                                type="text"
                                                                name="fee_schedule_name"
                                                                placeholder="Fee Name Displayed to Guest"
                                                                className={validations.fee_schedule_name && 'form-validation'}
                                                                value={data.fee_schedule_name || ""}
                                                                onChange={this.handleOnChange}
                                                            />
                                                            {validations.fee_schedule_name && <span className="required-error-text">Fee Name is required</span>}
                                                        </Col>
                                                        <Col md={6} sm={12} xs={12}>
                                                            {
                                                                (this.state.data.applies.length > 0) && 
                                                                this.feeAppliesPopover()
                                                            }
                                                            <InputLabel
                                                                className={validations.applies && 'form-validation'}
                                                            >
                                                                Fee Applies
                                                            </InputLabel>
                                                            <InputField 
                                                                type="select" 
                                                                name="applies"
                                                                className={validations.applies && 'form-validation'}
                                                                value={data.applies || ""}
                                                                onChange={this.handleOnChange}
                                                            >
                                                                {appliesType}                                                              
                                                            </InputField>
                                                            {validations.applies && <span className="required-error-text">Fee Applies is required</span>}
                                                        </Col>
                                                    </Row>
                                                    <br/>
                                                    <Row>
                                                    <Col md={6} sm={12}>
                                                            <InputLabel 
                                                                className={validations.value && 'form-validation'}
                                                            >
                                                                Fee Value
                                                            </InputLabel>
                                                            <div className="placeholder-wrap">
                                                                <InputField 
                                                                    type="number"  
                                                                    name="value"
                                                                    className={validations.value && 'form-validation'}
                                                                    value={data.value || ""}
                                                                    onChange={this.handleOnChange}
                                                                    placeholder="Enter a dollar amount or percentage"
                                                                />
                                                                <span 
                                                                    className="placeholder"
                                                                >
                                                                    {
                                                                        data.value_type == "percent" ? 
                                                                        "%" : 
                                                                        (data.value_type == "amount" ? 
                                                                        "$" : 
                                                                        "") 
                                                                    }
                                                                </span>
                                                            </div>
                                                            {validations.value && <span className="required-error-text">Fee Value is required</span>}
                                                        </Col>
                                                        <Col md={6} sm={12}>
                                                            <InputLabel
                                                                className={validations.value_type && 'form-validation'}
                                                            >
                                                                Value Type
                                                            </InputLabel>
                                                            {this.createRadioButton(this.VALUE_TYPE, data.value_type, this.valueTypeHandler)}
                                                            {validations.value_type && <span className="required-error-text">Value Type is required</span>}
                                                        </Col>
                                                    </Row>
                                                    <br/>
                                                    <Row>
                                                        <Col md={12} sm={12}>
                                                            <InputField 
                                                                type="checkbox" 
                                                                name="required" 
                                                                disabled={_.get(data, 'fee_type') == 'pet_fee'} 
                                                                cbChange={this.toggleRequired} 
                                                                value={!this.state.data.required}
                                                            >
                                                                Check if this is an optional fee a guest can select
                                                            </InputField> 
                                                        </Col>
                                                    </Row>
                                                    <br/>
                                                    <br/>
                                                    <Row>
                                                    <Col xs={12}>
                                                    <InputLabel>
                                                                <i>Optional Setting   </i>
                                                    </InputLabel>
                                                            
                                                    </Col>
                                                    </Row>
                                                    <br/>
                                                    <Row>
                                                        <Col md={3}>
                                                            <InputLabel
                                                                className={validations.effective_from_ts && 'form-validation'}
                                                            >
                                                                Start
                                                            </InputLabel>
                                                        </Col>
                                                        <Col md={3}>
                                                            <InputLabel
                                                                className={validations.effective_to_ts && 'form-validation'}
                                                            >
                                                                End
                                                            </InputLabel>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={6} 
                                                            className={
                                                                `effective-dates-container 
                                                                ${validations.effective_from_ts && 'start-form-validation'} 
                                                                ${validations.effective_to_ts && 'end-form-validation'}`}
                                                            >
                                                            <DateRangePicker
                                                                startDate={data.effective_from_ts}
                                                                endDate={data.effective_to_ts}
                                                                startDateId='start'
                                                                endDateId='end'
                                                                focusedInput={focusedInput}
                                                                onFocusChange={focusedInput => this.setState({ focusedInput })}
                                                                onDatesChange={this.handleDateOnChange}
                                                                noBorder
                                                                startDatePlaceholderText="From"
                                                                endDatePlaceholderText="To"
                                                                disabled={this.props.isInternalPropertyAdmin ? false : true}
                                                            />
                                                        </Col>
                                                        {this.props.isInternalPropertyAdmin &&
                                                        <Col sm={12} md={2} 
                                                                className="pull-left"
                                                            >
                                                                <PrimaryButton 
                                                                    cssClass="blue-btn "
                                                                    fullWidth={true} 
                                                                    onClick={this.addFeeCriteria}
                                                                    disabled={data.criterion.length >= 5}
                                                                >
                                                                 Add Fee Criteria <i className={`icon icon-chevrons-${data.criterion.length > 0?'up':'down'}`}></i>
                                                                </PrimaryButton>
                                                            </Col>
                                                        }
                                                    </Row>
                                                    
                                                            {this.createCriteriaConfig()}
                                                    <br/>

                                                    <Row>
                                                        <Col lg={8}>
                                                        <InputLabel>
                                                            Description
                                                        </InputLabel>
                                                        <InputField
                                                                cssClass="text-area" 
                                                                type="textarea"
                                                                name="description"
                                                                placeholder="Enter description"
                                                                value={description}
                                                                onChange={this.handleOnChange}
                                                                style = {{height:"178px"}}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <br/>
                                                    <Row className='pull-right'>
                                                    <Link 
                                                        to={this.routeTo} 
                                                        className="primary-btn black-btn pull-left left-margin">
                                                        Cancel
                                                    </Link>
                                                    {
                                                        this.isAdmin && 
                                                        <PrimaryButton 
                                                            cssClass="pull-left left-margin" 
                                                            fullWidth={false} 
                                                            type="button" 
                                                            onClick={this.handleFormSubmit}
                                                            disabled={!this.compareState()}
                                                        >
                                                            Save
                                                        </PrimaryButton>
                                                    }
                                                    </Row>
                                                </Panel.Body>
                                            </Panel>
                                            </PanelGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </FieldWrap>
                        </div>
                    </div>
                    <NavigationPrompt 
                        when={this.compareState()}
                        onSave={this.handleFormSubmit}
                    />
                    <GlobalSubmitModal
                        showViewAll={true}
                        mode={mode}
                        activeModal={activeModal}
                        submitModalTotal={1}
                        submitStatus={submitStatus}
                        error={modalError}
                        progressMessage={progressMessage}
                        closeModal={this.closeModal}
                        continueUrl={this.handleContinueUrl()}
                        viewAllUrl={this.routeTo}
                        viewAllLabel={
                            this.paramsLevel === "global" ? 
                            "View All Fees" : 
                            "View Property Fees"
                        }
                    />
                </div>
            }
            </PageLayout>
        )  
    }
}

const mapStateToProps = state => {
    return {
        roleManager: state.roleManager,
        isInternalPropertyAdmin: state.roleManager.isInternalPropertyAdmin,
        unsavedData: state.updateUnsavedData.unsavedData
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateUnsavedData: (bool) => {
            dispatch(updateUnsavedData(bool));
        }
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(AddFeeSchedule));