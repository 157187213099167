import React from 'react';

import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

const PageLayout = ({ isCentered, plClass, children, }) => {
    if (isCentered) {
        return (
            <div className={plClass ? plClass : 'main-container'}>
                <Row>
                    <Col md={12}>
                        {children}
                    </Col>
                </Row>
            </div>
        )
    } else {
        return (
            <div 
                className={plClass ? plClass : 'main-container'}>
                {children}
            </div>
        )
    }
    
}

export default PageLayout