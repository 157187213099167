import React from 'react';
import Col from 'react-bootstrap/lib/Col';
import Modal from 'react-bootstrap/lib/Modal';
import moment from 'moment';

/**
 * Total available room modal to show how much room remain to book on particular date.
 * @param {*} props 
 */
export const AvailableRoomModal = (props) => {
    let period_start, period_end;
    if (props.unAvailableChildren.period) {
        ({period_start, period_end} = props.unAvailableChildren.period)
    };

    let available = props.children.filter((data, index) => { return props.unAvailableChildren.propertyId && props.unAvailableChildren.propertyId.indexOf(index) === -1 }).map((child, idx) =>
        <li style={{fontSize: '16px'}} key={idx}>
            {child.content.title} (ID: {child.property_id})
        </li>
    )
    let unavailable = props.unAvailableChildren.blockTypes && props.unAvailableChildren.blockTypes.length && props.unAvailableChildren.blockTypes.map((child, idx) => {
        let type = '';

        if (child.type === "PaymentBlock") {
            type = "Blocked by Payment"
        }
        else if (child.type === "Manual") {
            type = "Blocked Manually"
        }
        else {
            type = child.type.split(/(?=[A-Z])/).join(' ').replace('By', 'by')
        };

        return (
            <li key={idx} style={{fontSize: '16px'}}>{child.quantity} {type}</li>)
    })
return (
    < Modal show={props.modal.show} onHide={() => props.onHide()} className='available-room-modal'>
        <Modal.Header closeButton>
            <Modal.Title>{props.unAvailableChildren && props.unAvailableChildren.period && moment(props.unAvailableChildren.period.period_end).format('MMMM DD, YYYY')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h3>Available Rooms ({props.unAvailableChildren.roomsAvailable})</h3>
            {props.totalUnits <= 100 ?
                <ol>
                    {available}
                </ol> : ""
            }
            <hr/>
            <h3 
                className="unavailable-title"
            >
                Unavailable Rooms 
                ({props.totalUnits <= 100 ? (props.children.length - props.unAvailableChildren.roomsAvailable) : (props.totalUnits - props.unAvailableChildren.roomsAvailable)})
                &nbsp;
                <span
                    className='update-txt'
                    onClick={() => props.swapModals(moment(props.modal.period_start), moment(props.modal.period_end), props.children[0].repconfig.parent_id)}
                >
                    Update
                </span>
            </h3>
            <ol>
                {unavailable}
            </ol>
        </Modal.Body>
    </Modal >)
}

AvailableRoomModal.prototype.show;
AvailableRoomModal.prototype.onHide;
AvailableRoomModal.prototype.children;
AvailableRoomModal.prototype.unAvailableChildren;