import React, { Component, Fragment } from 'react';
import { API } from 'aws-amplify';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import Link from 'react-router-dom/Link';
import { CSVLink } from "react-csv";
import DateRangePicker from 'react-dates/lib/components/DateRangePicker';
import Picky from 'react-picky';
import 'react-picky/dist/picky.css';
import Modal from 'react-bootstrap/lib/Modal';
import { InputLabel, InputField, PrimaryButton } from '../global/forms/FormElements';

import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import PanelGroup from 'react-bootstrap/lib/PanelGroup';
import Panel from 'react-bootstrap/lib/Panel';

import { Title } from '../global/Title';
import PageLayout from '../global/PageLayout';
import constants from '../../js/constants';
import ReactTableComponent from '../global/ReactTableComponent';
import Loader from '../global/Loader';
import VideoModal from '../global/forms/VideoModal';

class Payments extends Component {
    constructor(props) {
        super(props);
        this.transactionsTable = null;
        this.org = this.props.org;
        this.tid = this.getSessionId();
        this.transactionsCSVReportsCopy = null;
        this.compareTransactionsDates = {};
        this.compareTransactionsFilter = "";
        this.breadcrumbItems = [{
			title: "Payments",
        }];
        this.transactionsCSVHeaders = [
            { label: 'Created', key: 'created'},
            { label: 'Description', key: 'description'},
            { label: 'Type', key: 'type'},
            { label: 'Amount', key: 'amount'},
        ];
        this.transactionsFilters = [
            { label: 'No Filter', key: "no_filter"},
            { label: 'Payout', key: 'payout'},
            { label: 'Payment', key: 'payment'},
            { label: 'Payment Refund', key: 'payment_refund'},
        ];
        this.COUNTRIES = [
            { label: 'Australia (AU)', value: 'AU'},
            { label: 'Austria (AT)', value: 'AT'},
            { label: 'Belgium (BE)', value: 'BE'},
            { label: 'Bulgaria (BG)', value: 'BG'},
            { label: 'Canada (CA)', value: 'CA'},
            { label: 'Cyprus (CY)', value: 'CY'},
            { label: 'Czech Republic (CZ)', value: 'CZ'},
            { label: 'Denmark (DK)', value: 'DK'},
            { label: 'Estonia (EE)', value: 'EE'},
            { label: 'Finland (FI)', value: 'FI'},
            { label: 'France (FR)', value: 'FR'},
            { label: 'Germany (DE)', value: 'DE'},
            { label: 'Greece (GR)', value: 'GR'},
            { label: 'Hong Kong (HK)', value: 'HK'},
            { label: 'Ireland (IE)', value: 'IE'},
            { label: 'Italy (IT)', value: 'IT'},
            { label: 'Japan (JP)', value: 'JP'},
            { label: 'Latvia (LV)', value: 'LV'},
            { label: 'Lithuania (LT)', value: 'LT'},
            { label: 'Luxembourg (LU)', value: 'LU'},
            { label: 'Malta (MT)', value: 'MT'},
            { label: 'Netherlands (NL)', value: 'NL'},
            { label: 'New Zealand (NZ)', value: 'NZ'},
            { label: 'Norway (NO)', value: 'NO'},
            { label: 'Poland (PL)', value: 'PL'},
            { label: 'Portugal (PT)', value: 'PT'},
            { label: 'Romania (RO)', value: 'RO'},
            { label: 'Singapore (SG)', value: 'SG'},
            { label: 'Slovakia (SK)', value: 'SK'},
            { label: 'Slovenia (SI)', value: 'SI'},
            { label: 'Spain (ES)', value: 'ES'},
            { label: 'Sweden (SE)', value: 'SE'},
            { label: 'Switzerland (CH)', value: 'CH'},
            { label: 'United Kingdom (GB)', value: 'GB'},
            { label: 'United States (US)', value: 'US'}
        ];
        this.state = {
            modal: "",
            isLoading: true,
            stripeLoading: false,
            transactionsIsLoading: false,
            organizationData: {},
            errMsg: "",
            stripeStatus: '',
            error: false,
            activeKey: "1",
            videoModal: '',
            transactionsPageSize: 10,
            balance: "",
            balanceCur:"$",
            country:this.COUNTRIES.filter((country) => _.get(this.props.user, 'contact_info.country') == country.value).length > 0 ? 
                this.COUNTRIES.filter((country) => _.get(this.props.user, 'contact_info.country') == country.value) : 
                [{ label: 'United States (US)', value: 'US' }],
            transactionsData: [],
            initTransactionsPagination: {},
            transactionsPagination: {},
            transactionsPaginationEnd: false,
            transactionsCSVReports: [],
            transactionDates: {
                startDate: moment().startOf('year'),
                endDate: moment(),
            },
            transactionsFilter: {},
            transactionsFilterValue: "No Filter",
            transactionsColumns: [
                {
                    Header: 'Created',
                    accessor: 'created',
                    'sortable': false,
                    'Cell': row=>{
                        let created = row && row.original && row.original.created;

                        return(
                            <div>
                                {moment(created * 1000).format('MM/DD/YYYY')}
                            </div>
                        )
                    }
                },
                {
                    Header: 'Description',
                    accessor: 'description',
                    'sortable': false,
                    'Cell': row=>{
                        let description = row && row.original && row.original.description;

                        return(
                            <div className="transaction-description">
                                {description}
                            </div>
                        )
                    }
                },
                {
                    Header: 'Type',
                    accessor: 'type',
                    'sortable': false,
                },
                {
                    Header: 'Amount',
                    accessor: 'amount',
                    'sortable': false,
                    'Cell': row=>{
                        let amount = row && row.original && row.original.amount;
                        let currency = row && row.original && row.original.currency && row.original.currency;

                        return(
                            <div className={Math.sign(amount) == -1 ? "negative-amount" : ""}>
                                <strong>{constants.CURRENCY[currency.toUpperCase()]} {constants.NUM_FORMAT(Math.abs(amount))}</strong>
                            </div>
                        )
                    }
                },
            ]
        };
    }

    async componentDidMount() {
        this.setState({
            isLoading: true
        });

        await this.getOrganization();
        await this.getBalanceData();
        await this.getTransactionsData();

        this.setState({
            isLoading: false
        });
    }

    getOrganization = async () => {
        let resp;

        try {
            resp = await API.get("accapi", `/organizations/${this.org}`, {headers: {"Cache-Control": "must-revalidate"}});

            if(_.isPlainObject(resp)) {
                this.setState({
                    organizationData: resp
                }, () => {
                    const { organizationData } = this.state;
                    const paymentAccountId = _.get(organizationData, 'property_manager_details.payment_account_id');
                    const preliminaryPaymentId = _.get(organizationData, 'property_manager_details.preliminary_payment_account_id');
                    let stripeStatus = '';

                    if(paymentAccountId == null && preliminaryPaymentId == null) {
                        stripeStatus = 'notConnected';
                    }
                    else if(preliminaryPaymentId) {
                        stripeStatus = 'pendingSetup';
                    }
                    else if(paymentAccountId) {
                        stripeStatus = 'connected';
                    };

                    this.setState({
                        stripeStatus: stripeStatus
                    });
                });
            };
        }
        catch(err) {
            this.setState({
                stripeStatus: 'error'
            });
        };
    }

    getBalanceData = async () => {
        try {
            let response = await API.get('rptapi',`/reports/acctg_balance?organization=${this.org}`, { response: true, isCognito: true });

            if(response && response.data && response.data.items) {
                const balance = response.data.items && response.data.items[0] && constants.NUM_FORMAT(response.data.items[0].amount);
                const balanceCur = response.data.items && response.data.items[0] && response.data.items[0].currency ? constants.CURRENCY[response.data.items[0].currency.toUpperCase()] : "$";

                this.setState({
                    balance: balance,
                    balanceCur: balanceCur,
                });
            };
        } 
        catch(e) {
            this.setState({
                error: true,
            });
        };
    }

    getTransactionsData = async () => {
        const { 
            transactionsPageSize,
            transactionDates,
            transactionsFilter
         } = this.state;
        const startDate = moment(transactionDates.startDate).format("YYYY-MM-DD");
        const endDate = moment(transactionDates.endDate).format("YYYY-MM-DD");
        const filterBy = transactionsFilter && transactionsFilter.key ? (transactionsFilter.key !== "no_filter" ? transactionsFilter.key : "") : "";

        this.setState({
            transactionsIsLoading: true
        });

        try {
            let response = await API.get('rptapi',`/reports/acctg_transactions?organization=${this.org}&limit=${encodeURIComponent(transactionsPageSize)}${filterBy ? `&type_filter=${filterBy}` : ""}&created_start_dt=${startDate}&created_end_dt=${endDate}`, { response: true, isCognito: true });

            if(response && response.data && response.data.items) {
                this.setState({
                    transactionsData: response.data.items,
                    initTransactionsPagination: response.data.pagination,
                    transactionsPagination: response.data.pagination,
                    transactionsPaginationEnd: false,
                    transactionsIsLoading: false
                });
            };
        } 
        catch(e) {
            this.setState({
                error: true,
                transactionsData: [],
                transactionsIsLoading: false
            });
        };
    }

    fetchTransactionsData = async (direction, changePageSize) => {
        const { 
            transactionsPagination, 
            transactionsPageSize,
            transactionDates,
            transactionsFilter
        } = this.state;
        let transaction_id = 
            direction === "next" ? 
            transactionsPagination && transactionsPagination.ending_transaction_id : 
            transactionsPagination && transactionsPagination.starting_transaction_id;
        const startDate = moment(transactionDates.startDate).format("YYYY-MM-DD");
        const endDate = moment(transactionDates.endDate).format("YYYY-MM-DD");
        const filterBy = transactionsFilter && transactionsFilter.key ? (transactionsFilter.key !== "no_filter" ? transactionsFilter.key : "") : "";

        this.setState({
            transactionsPaginationEnd: false,
            isLoading: true
        });

        try {
            let response = await API.get('rptapi',`/reports/acctg_transactions?organization=${this.org}&limit=${encodeURIComponent(transactionsPageSize)}${filterBy ? `&type_filter=${filterBy}` : ""}&created_start_dt=${startDate}&created_end_dt=${endDate}${!changePageSize && direction ? `&transaction_id=${encodeURIComponent(transaction_id)}${direction ? `&direction=${encodeURIComponent(direction)}` : ""}`: ""}`, { response: true, isCognito: true });

            if((response && response.data && response.data.pagination && response.data.pagination.ending_transaction_id == null) && (response && response.data && response.data.pagination && response.data.pagination.starting_transaction_id != null)) {
                this.setState({
                    transactionsPaginationEnd: true,
                    isLoading: false,
                });
            }
            else {
                this.setState({
                    transactionsData: response.data.items,
                    transactionsPagination: response.data.pagination,
                    isLoading: false,
                });
            };
        } 
        catch(e) {
            this.setState({
                error: true,
                isLoading: false
            });
        };
    }

    stripeConnectOnClick = () => {
        const {
            country,
            stripeStatus
        } = this.state;

        this.setState({
            stripeLoading: true,
            errMsg: "",
        });

        let data  = {
            return_url: `${window.location.origin}/accounts/payments`,
            country: _.get(country, 'value') || '',
        };

        API.post(constants.ACCAPI.NAME, `/organizations/${this.org}/payment_account`, { responseType: "text", body: data,  response: true }).then((res) => {
            if(res && res.data) {
                let url = res.data;

                this.setState({
                    modal: "",
                    stripeLoading: false,
                });
                
                if(stripeStatus == 'connected') {
                    window.open(url, '_blank');
                }
                else {
                    window.location.href = url;
                };   
            }
            else if(res && res.data == null) {
                this.setState({
                    stripeStatus: 'error',
                    stripeLoading: false,
                    modal: "",
                });
            };
        }).catch((err) => {
            this.setState({
                stripeLoading: false,
                modal: "",
                stripeStatus: 'error',
                errMsg: _.get(err, 'response.data.Message') || "",
            });
        });
    }


    getTransactionsCSV = async () => {
        const { 
            transactionsFilter,
            transactionDates
         } = this.state;
        const filterBy = transactionsFilter && transactionsFilter.key ? (transactionsFilter.key !== "no_filter" ? transactionsFilter.key : "") : "";
        const startDate = moment(transactionDates.startDate).format("YYYY-MM-DD");
        const endDate = moment(transactionDates.endDate).format("YYYY-MM-DD");
        let transaction_id = "";
        let response = [];
        let reports = [];
        let limit = 100;
        let count = 0;

        this.setState({
            isLoading: true,
            dropdownIsOpen: false
        });

        try {
            response = await API.get("rptapi", `/reports/acctg_transactions?organization=${this.org}&limit=${limit}&created_start_dt=${startDate}&created_end_dt=${endDate}${filterBy ? `&type_filter=${filterBy}` : ""}`, { response: true, isCognito: true });
            if(response && response.data && response.data.items) {
                
                response.data.items.forEach((x) => {
                    reports = [...reports, x];
                });

                count = response.data.items.length;

                transaction_id = response.data.pagination.ending_transaction_id;

                while(count == limit) {
                    try {
                        response = await API.get("rptapi", `/reports/acctg_transactions?organization=${this.org}&limit=${limit}&transaction_id=${encodeURIComponent(transaction_id)}&created_start_dt=${startDate}&created_end_dt=${endDate}&direction=next${filterBy ? `&type_filter=${filterBy}` : ""}`, { response: true, isCognito: true });

                        response.data.items.forEach((x) => {
                            reports = [...reports, x];
                        });
        
                        count = response.data.items.length;

                        transaction_id = response.data.pagination.ending_transaction_id;
                    }
                    catch(e) {
                        console.log(e)
                    };
                };
                if(count < limit) {
                    reports.forEach((report) => {
                        report.created = moment(report.created * 1000).format('MM/DD/YYYY');
                    });
                    
                    this.transactionsCSVReportsCopy = reports;

                    this.compareTransactionsDates = this.state.transactionDates;

                    this.compareTransactionsFilter = this.state.transactionsFilterValue;
                    
                    this.setState({
                        transactionsCSVReports: reports,
                        isLoading: false,
                    });
                }
            };
        }
        catch(e) {
            console.log(e)
            this.setState({
                isLoading: false,
            });
        }
    }

    downloadTransactionsCSV = async () => {
        const { 
            transactionsCSVReports,
            transactionDates,
            transactionsFilterValue
        } = this.state;

        if(transactionsCSVReports !== this.transactionsCSVReportsCopy || this.compareTransactionsDates !== transactionDates || this.compareTransactionsFilter !== transactionsFilterValue) {
            await this.getTransactionsCSV();

            const CSVcomponent = document.getElementById("download-transactions-csv");
            CSVcomponent.click();
        }
        else {
            const CSVcomponent = document.getElementById("download-transactions-csv");
            CSVcomponent.click();
        }
    }

    handleDatesChange = (newDates) => {
        this.setState({
            transactionDates: {
                ...newDates
            }
        }, () => {
            const { transactionDates} = this.state;

            if((transactionDates && transactionDates.startDate) && (transactionDates && transactionDates.endDate)) {

                this.getTransactionsData();
            }
            else if(!(transactionDates && transactionDates.startDate) && !(transactionDates && transactionDates.endDate)) {

                this.getTransactionsData();
            };
        });
    }

    transactionsFilterOnChange = (value) => {
        this.setState({ 
            transactionsFilter: value 
        }, async () => {
            this.setState({
                transactionsFilterValue: value.label,
            });

            this.getTransactionsData();
        });
    }

    isOutsideRange = () => {
        return false;
    }

    pageSizeOnChange = (newPageSize) => {
        this.setState({
            transactionsPageSize: parseInt(newPageSize),
        }, () => {
            this.fetchTransactionsData(undefined, true);
        });
    }

    handleSearchChange = (e) => {
        const name = e.target.name;
        const val = e.target.value;

        this.setState({
            [name] : val
        });
    }

    panelOnSelect = (activekey, e, customKey) => {
        if(e) e.preventDefault();
        
        this.setState({ 
            activeKey: customKey ? customKey : activekey,
        });
    }

    prevPagination = () => {
        const {
            initTransactionsPagination,
            transactionsPagination
        } = this.state;
        const init_starting_transaction_id = 
            initTransactionsPagination.starting_transaction_id;
        const starting_transaction_id = 
            transactionsPagination.starting_transaction_id;

        if(init_starting_transaction_id === starting_transaction_id) {
            return true;
        }
        else {
            return false;
        };
    }

    nextPagination = () => {
        const {
            transactionsData,
            transactionsPageSize,
            transactionsPaginationEnd
        } = this.state;

        if(transactionsData.length < transactionsPageSize || transactionsPaginationEnd) {
            return true;
        }
        else {
            return false;
        };
    }

    toggleDropdown = () => this.setState({ dropdownIsOpen: !this.state.dropdownIsOpen })
    
    getSessionId = () => {
        const maximum = 99999
        const minimum = 10000
        return (Math.floor(Math.random() * ((maximum - minimum) + 1)) + minimum)
    }

    getRef = (r, name) => {
        this[name]= r;
    }

    handleVideoModal = () => {   
        this.setState({
            videoModal: "",
        });
    }

    returnActionBtn = () => {
        const { 
            dropdownIsOpen,
            transactionsData,
            transactionsCSVReports
         } = this.state;

        return (
            <DropdownButton  
                title="Actions"
                className="primary-btn white-btn"
                id="property-actions"
                onToggle={this.toggleDropdown}
                open={dropdownIsOpen}
            >
                <MenuItem 
                    onClick={transactionsData.length ? this.downloadTransactionsCSV : null}
                    disabled={!transactionsData.length}
                >
                    Download CSV
                </MenuItem>
                <CSVLink
                    asyncOnClick={true}
                    headers={this.transactionsCSVHeaders}
                    data={transactionsCSVReports}
                    filename={`${this.org}-transactions-reports.csv`}
                    target="_blank"
                    id="download-transactions-csv"
                >
                </CSVLink>
            </DropdownButton>
        )
    }
    
    pickyOnChange = (value) => {
        this.setState({
            country: value
        });
    }

    handleModal = (e, name) => {
        e && e.preventDefault();
        
        this.setState({
            modal: name
        });
    }

    render() {
        const { 
            isLoading,
            stripeStatus,
            stripeLoading,
            balance,
            balanceCur,
            transactionsPageSize,
            transactionsColumns,
            transactionsData,
            transactionsIsLoading,
            activeKey,
            transactionDates,
            focusedInput,
            transactionsFilterValue,
            videoModal,
            errMsg,
            organizationData,
            country,
            modal,
        } = this.state;
        const paymentAccountId = _.get(organizationData, 'property_manager_details.payment_account_id');
        const preliminaryAccountId = _.get(organizationData, 'property_manager_details.preliminary_payment_account_id');
        const accountId = paymentAccountId ? paymentAccountId : (preliminaryAccountId ? preliminaryAccountId : '');
        let statusMsg = '';

        switch(stripeStatus) {
            case 'error':
                statusMsg = 'There was an issue connecting to Stripe. Please contact our customer support team for assistance'
                break;
            case 'pendingSetup': 
                statusMsg = 'Complete your Stripe setup to start accepting payments.'
                break;
            case 'connected': 
                statusMsg = 'Your account is connected.'
            break;
        };

        return (
            <PageLayout isCentered={true}>

                {(isLoading || transactionsIsLoading) && <Loader/>}

                <Title 
                    title='Payments' 
                    isCentered={true} 
                    breadcrumbItems={this.breadcrumbItems}
                >
                    {
                        (!isLoading && stripeStatus == "connected") &&
                        <div>
                            <span className='reports__title'>Your Balance:</span>
                            <span className="reports__price">{balanceCur} {balance || 0}</span> 
                        </div>
                    }
                </Title>
                <div id="content">
                    <div className="container-fluid property-item">
                        <div className={`payments ${this.props.mobileMode && 'mobile'} `}>
                            {
                                !isLoading &&
                                <Fragment>
                                    <Row>
                                        <Col md={12} xs={12} sm={12}>
                                            <div className="box-container stripe-connect">
                                                {
                                                    stripeStatus == 'notConnected' ?
                                                    <div className='center-text'>
                                                        <Col md={12} sm={12} className='top-margin'>
                                                            <div className='stripe-connect-logo'></div>
                                                            <h2>RedAwning utilizes Stripe Connect to securely store and remit payments to your bank.</h2>
                                                        </Col>
                                                        <Col md={12} sm={12}>
                                                            <hr/>
                                                            <Col md={12} sm={12} className="bottom-margin">
                                                                <PrimaryButton  
                                                                    onClick={(e) => this.handleModal(e, 'stripe-prompt-modal')}
                                                                    disabled={stripeLoading}
                                                                    fullWidth={false}
                                                                >
                                                                    Set Up Stripe Connect
                                                                </PrimaryButton>
                                                            </Col>
                                                            <Col md={12} sm={12}>
                                                                <div>Learn how to set up Stripe Connect by watching a <span className="clickable-text" onClick={() => this.setState({ videoModal: 'video-modal' })}>short video</span>.</div>
                                                            </Col>
                                                        </Col>
                                                    </div>
                                                    :
                                                    <Fragment>
                                                        <Col md={12} sm={12}>
                                                            <Col md={8} sm={12} className='stripe-connect-container'>
                                                                <div className='display-flex'>
                                                                    <div className='stripe-logo'></div>
                                                                    <div className='connect-logo'></div>
                                                                </div>
                                                                <h3>
                                                                    {statusMsg}
                                                                </h3>
                                                            </Col>
                                                            <Col md={4} sm={12}>
                                                                <div className='stripe-actions-container'>
                                                                    <div className='overlay-disbled-btn'>
                                                                        <button 
                                                                            disabled={stripeLoading} 
                                                                            className='primary-btn' 
                                                                            type='button' 
                                                                            onClick={this.stripeConnectOnClick}
                                                                        >
                                                                            Stripe Dashboard
                                                                            <span className={`glyphicon glyphicon-refresh ${stripeLoading ? 'spinning' : 'hide'}`}></span>
                                                                        </button>
                                                                    </div>
                                                                    {
                                                                        accountId &&
                                                                        <div className='muted'>{accountId}</div>
                                                                    }
                                                                </div>
                                                            </Col>
                                                        </Col>
                                                    </Fragment>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                    <br/>
                                </Fragment>
                            }
                            <Row>
                                <Col md={12} xs={12}>
                                    <PanelGroup 
                                        accordion id="accordion-controlled-example" 
                                        defaultActiveKey={activeKey}
                                    >
                                    <Panel eventKey='1'>
                                        <Panel.Heading>
                                            <Panel.Title> 
                                                Transactions
                                                <div className='fs-warning'>
                                                    To view all past & future transactions related to a reservation, please navigate to the &nbsp;
                                                        <Link 
                                                            className="clickable-text" 
                                                            to={`/reservations`}
                                                        >
                                                            Reservations
                                                        </Link>
                                                    &nbsp; report.
                                                </div>
                                                <div className='fs-warning'>For more details on <Link className="clickable-text" to={`/account/faq/getting_paid`}>Payout Timing</Link>.</div>
                                            </Panel.Title>
                                        </Panel.Heading>
                                        <Panel.Body collapsible>
                                            <Row className="transactions">
                                                {
                                                    this.props.mobileMode &&
                                                    <Col md={12} sm={12} className="bottom-margin content-right">
                                                        {this.returnActionBtn()}
                                                    </Col>
                                                }
                                                <Col md={12} sm={12}>
                                                    <div className="payments-csv-action">
                                                        <Picky
                                                            placeholder="filter by"
                                                            labelKey="label"
                                                            valueKey="value"
                                                            filterPlaceholder="Filter"
                                                            options={this.transactionsFilters}
                                                            value={transactionsFilterValue}
                                                            multiple={false}
                                                            includeSelectAll={false}
                                                            defaultFocusFilter={true}
                                                            keepOpen={false}
                                                            onChange={value => this.transactionsFilterOnChange(value)}
                                                            dropdownHeight={600}
                                                            render={({
                                                                style,
                                                                item,
                                                                isSelected,
                                                                selectValue,
                                                                labelKey,
                                                                valueKey,
                                                                }) => {
                                                                    return (
                                                                        <li
                                                                            style={{ ...style }} 
                                                                            className={isSelected ? "selected" : ""} 
                                                                            key={item[labelKey]}
                                                                            onClick={() => selectValue(item)}
                                                                        >
                                                                        <span
                                                                            style={{fontWeight: isSelected ? "bold" : "normal"}}
                                                                        >
                                                                            {item[labelKey]}
                                                                        </span>
                                                                        </li>
                                                                    );
                                                                }}
                                                        />
                                                        <DateRangePicker
                                                            orientation={this.props.mobileMode ? "vertical" : "horizontal"}
                                                            noBorder
                                                            showClearDates={true}
                                                            minimumNights={0}
                                                            startDateId='start'
                                                            endDateId='end'
                                                            isOutsideRange={this.isOutsideRange}
                                                            startDatePlaceholderText="MM/DD/YYY"
                                                            endDatePlaceholderText="MM/DD/YYY"
                                                            startDate={transactionDates && transactionDates.startDate}
                                                            endDate={transactionDates && transactionDates.endDate}
                                                            focusedInput={focusedInput}
                                                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                                                            onDatesChange={this.handleDatesChange}
                                                        />
                                                        {!this.props.mobileMode ? this.returnActionBtn() : ""}
                                                    </div>
                                                </Col>
                                                <Col md={12} sm={12}> 
                                                    <ReactTableComponent
                                                        getRef={this.getRef}
                                                        reactTable={this.transactionsTable}
                                                        reactTableName={"transactionsTable"}
                                                        apiBase={true}
                                                        minRows={0}
                                                        resizable={false}
                                                        showPagination={false}
                                                        className='-highlight'
                                                        noDataText={isLoading ? "Loading..." : "No Data"}
                                                        pageSize={transactionsPageSize}
                                                        data={transactionsData}
                                                        columns={transactionsColumns}
                                                    />
                                                </Col>
                                            </Row>
                                            {
                                                transactionsData.length > 0 && 
                                                <Row>
                                                    <Col 
                                                        md={12} sm={12} 
                                                        className="pagination-container top-margin-20"
                                                    >
                                                        <div className="page_size-input-container">
                                                            <div 
                                                                className='page-size' 
                                                            >
                                                                <InputField
                                                                    className="pageSize"
                                                                    type='number' 
                                                                    name='pageSize'
                                                                    autoComplete="off"
                                                                    defaultValue={transactionsPageSize}
                                                                    onKeyUp={(e) => {
                                                                        let value = e.target.value;

                                                                        if(value > 100) {
                                                                            value = 100;
                                                                        }
                                                                        else if(value < 0) {
                                                                            value = 1;
                                                                        };

                                                                        if(value.length && e.keyCode === 13 && value !== 0 && Number.isInteger(Number(value))) {
                                                                            this.pageSizeOnChange(value, "transactions")
                                                                        };
                                                                    }}
                                                                /> 
                                                                / page
                                                            </div>
                                                            <button
                                                                name="prev"
                                                                className="transaction-pagination-btn"
                                                                type="button"
                                                                onClick={() => this.fetchTransactionsData("prev", false)}
                                                                disabled={this.prevPagination()}
                                                            >
                                                                Prev
                                                            </button>
                                                            <button
                                                                name="next"
                                                                className="transaction-pagination-btn"
                                                                type="button"
                                                                onClick={() => this.fetchTransactionsData("next", false)}
                                                                disabled={this.nextPagination()}
                                                            >
                                                                Next
                                                            </button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }
                                        </Panel.Body>
                                    </Panel>
                                    </PanelGroup>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <VideoModal
                        modal={videoModal}
                        url={'https://www.youtube.com/embed?si=6tJFsbtBC1QrL03p&rel=0&playlist=pJKjtOg8IVE&loop=1'}
                        closeModal={this.handleVideoModal}
                    />
                    <Modal 
                        show={modal == 'stripe-prompt-modal'}
                        className='stripe-prompt-modal'
                        onHide={() => this.handleModal('')}
                        backdrop={stripeLoading ? "static" : true}
                    >
                        
                        {stripeLoading && <Loader/>}

                        <Modal.Header closeButton>
                            <Modal.Title>
                                Confirm your country of residence
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col md={12} sm={12} className="bottom-margin">
                                    <InputLabel>
                                        <strong>Country</strong>
                                    </InputLabel>
                                    <Picky
                                        labelKey="label"
                                        valueKey="value"
                                        options={this.COUNTRIES}
                                        value={country}
                                        multiple={false}
                                        includeSelectAll={false}
                                        defaultFocusFilter={true}
                                        keepOpen={false}
                                        onChange={value => this.pickyOnChange(value)}
                                        dropdownHeight={600}
                                        includeFilter={true} 
                                        render={({
                                            style,
                                            item,
                                            isSelected,
                                            selectValue,
                                            labelKey,
                                            valueKey,
                                            }) => {
                                                return (
                                                    <li
                                                        style={{ ...style }} 
                                                        className={isSelected ? "selected" : ""} 
                                                        key={item[labelKey]}
                                                        onClick={() => selectValue(item)}
                                                    >
                                                    <span
                                                        style={{fontWeight: isSelected ? "bold" : "normal"}}
                                                    >
                                                        {item[labelKey]}
                                                    </span>
                                                    </li>
                                                );
                                            }}
                                    />
                                </Col>
                                <Col md={12} sm={12} className="flex-row-reverse bottom-margin">
                                    <button 
                                        disabled={stripeLoading} 
                                        className='primary-btn' 
                                        type='button' 
                                        onClick={this.stripeConnectOnClick}
                                    >
                                        Confirm
                                    </button>
                                    <button 
                                        disabled={stripeLoading} 
                                        className='primary-btn white-btn right-margin' 
                                        type='button' 
                                        onClick={(e) => this.handleModal(e, '')}
                                    >
                                        Cancel
                                    </button>
                                </Col>
                                {
                                    errMsg && 
                                    <Col xs={12}>
                                        <div className='msg-container _error'>{errMsg}</div>
                                    </Col>
                                }
                            </Row>
                        </Modal.Body>
                    </Modal >
                 </div>
            </PageLayout>
        )
    }
}


const mapStateToProps = state => {
    return {
        user: state.authState.user,
        roleManager: state.roleManager.roles,
        org: state.roleManager.org,
        mobileMode: state.updateMobileState.mobileMode,
    }
}

export default withRouter(connect(
    mapStateToProps,
    null
)(Payments));
