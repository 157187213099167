import React from 'react';
import RichTextEditor from 'react-rte';
/**
 * @class For enter description of property and collection.
 */
export class RTE extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: RichTextEditor.createValueFromString(props.value,"html")
        }
        this.toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'HISTORY_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
                {label: 'Bold', style: 'BOLD',},
                {label: 'Italic', style: 'ITALIC'},
                {label: 'Underline', style: 'UNDERLINE'}
            ],
            BLOCK_TYPE_BUTTONS: [
                {label: 'UL', style: 'unordered-list-item'},
                {label: 'OL', style: 'ordered-list-item'}
            ],
            BLOCK_TYPE_DROPDOWN: [
                { label: 'Normal', style: 'unstyled' },
                { label: 'Heading 1', style: 'header-one' },
                { label: 'Heading 2', style: 'header-two' },
                { label: 'Heading 3', style: 'header-three' },
                { label: 'Heading 4', style: 'header-four' },
                { label: 'Heading 5', style: 'header-five' },
                { label: 'Heading 6', style: 'header-six' },
                { label: 'Blockquote', style: 'blockquote' },
            ]
        };
        if(props.customPage) {
            this.toolbarConfig.display = [
                ...this.toolbarConfig.display.slice(0, 1),
                ...['LINK_BUTTONS', 'IMAGE_BUTTON', 'BLOCK_TYPE_DROPDOWN'],
                ...this.toolbarConfig.display.slice(2, 3)
            ];
        };
    }

    /**
     * Invoke when change in description. 
     * @param {*} value 
     */
    onChange(value) {
        if (this.props.onChange) {
            this.props.onChange(value,this.props.name);
        }
    };

    componentWillReceiveProps(nextProps) {
        this.setState({
            value: typeof nextProps.value==="string"?RichTextEditor.createValueFromString(nextProps.value,"html"):nextProps.value
        })
    }

    render() {
        let editorOptions = {
            toolbarConfig: this.toolbarConfig,
            value: this.state.value,
            placeholder: this.props.placeholder,
            onChange: this.onChange.bind(this)
        }
        if(!this.props.isAdmin) {
            editorOptions.readOnly = true
        }
        return (<RichTextEditor {...editorOptions} />);
    }
}