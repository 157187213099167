import React, { Component } from 'react';
import Faq from "react-faq-component";

import Modal from 'react-bootstrap/lib/Modal';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import { faqContent } from '../../../helpers/FaqContent';

class FaqModal extends Component {
    faqData = faqContent(this.props.propFormState);
    
    render() {
        return (
            <div>
                <Modal
                    className="faq-modal"
                    show={this.props.show === "faq"}
                    onHide={this.props.closeModal}
                >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="faq-modal-title">FAQ</div>
                    </Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                        {
                            this.faqData.map((data, i) => {
                                return (
                                    <Row className="faq-container" key={i}>
                                        <Col sm={12} md={12}>
                                            <Faq 
                                                data={data}
                                            />
                                        </Col>
                                    </Row>
                                )
                            })
                        }
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

export default FaqModal;