import React, {
	useState,
	useCallback,
	useMemo,
	useEffect,
	Fragment
} from "react";
import PropTypes from "prop-types";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { API } from "aws-amplify";
import showdown from "showdown";
import { PrimaryButton } from "../../global/forms/FormElements";
import { Editor } from "@tinymce/tinymce-react";
import styles from "!!raw-loader!../../../styles/rte.css";
import Loader from "../../global/Loader";
import "../../../styles/rte.css";

showdown.setOption("omitExtraWLInCodeBlocks", true);

const AI_API_CONFIG = {
	endpoint: "gbapi",
	path: "/guestbooks"
};

const DOCUMENT_API_CONFIG = {
	endpoint: "gbapi",
	path: "/properties"
};

const returnEditorConfig = () => {
	const contentStyle = JSON.stringify(styles)
		.replace(/\\[nt]/g, "") // Remove newlines and tabs
		.replace(/}/g, "} \r") // Add carriage return after each closing brace
		.slice(1, -1); // Remove surrounding quotes

	return {
		height: 500,
		width: "auto",
		menubar: false,
		plugins: ["lists"],
		toolbar:
			"undo redo | formatselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist",
		block_formats:
			"Paragraph=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6;",
		resize: true,
		statusbar: false,
		image_title: true,
		file_picker_types: "image",
		toolbar_mode: "wrap",
		custom_colors: false,
		content_style: contentStyle
	};
};

const GuestBook = ({ propertyId }) => {
	const [guestbookContent, setGuestbookContent] = useState("");
	const [isEditing, setIsEditing] = useState(false);
	const [editedContent, setEditedContent] = useState("");
	const [isPolling, setIsPolling] = useState(false);
	const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);
	const [pdfError, setPdfError] = useState(false);
	const [isGenerating, setIsGenerating] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	const converter = useMemo(() => new showdown.Converter(), []);

	const loadGuestbook = useCallback(async () => {
		try {
			const response = await API.get(
				DOCUMENT_API_CONFIG.endpoint,
				`${DOCUMENT_API_CONFIG.path}/${propertyId}/commdata/guestbook_content`
			);

			if (response && response.content) {
				const html = converter.makeHtml(response.content);
				setGuestbookContent(html);
			}
		} catch (error) {
			console.error("Failed to load existing guestbook content:", error);
		}
	}, [propertyId, converter]);

	useEffect(() => {
		if (propertyId) {
			loadGuestbook();
		}
	}, [propertyId, loadGuestbook]);

	const getRecommendedContent = useCallback(async () => {
		setIsGenerating(true);

		try {
			const response = await API.post(
				AI_API_CONFIG.endpoint,
				AI_API_CONFIG.path,
				{
					body: {
						property_id: propertyId
					}
				}
			);

			if (response.status_check_url) {
				// Wait 1 second before starting to poll
				await new Promise(resolve => setTimeout(resolve, 1000));
				const startIndex =
					response.status_check_url.indexOf("papi-v1") + "papi-v1".length;
				const statusCheckUrl = response.status_check_url.substring(startIndex);

				setIsPolling(true);
				try {
					while (true) {
						const statusResponse = await API.get("rapapi", statusCheckUrl);
						if (
							statusResponse.status &&
							statusResponse.status === "completed"
						) {
							await loadGuestbook();
							break;
						} else if (
							statusResponse.status &&
							statusResponse.status === "error"
						) {
							throw new Error(statusResponse.error);
						} else {
							await new Promise(resolve => setTimeout(resolve, 1000));
						}
					}
				} finally {
					setIsPolling(false);
				}
			}
		} catch (error) {
			console.error("Error fetching content:", error);
		} finally {
			setIsGenerating(false);
		}
	}, [converter, propertyId, loadGuestbook]);

	const handleStartEditing = () => {
		setEditedContent(guestbookContent);
		setIsEditing(true);
	};

	const handleSaveContent = async () => {
		setIsSaving(true);
		try {
			await API.put(
				DOCUMENT_API_CONFIG.endpoint,
				`${DOCUMENT_API_CONFIG.path}/${propertyId}/commdata/guestbook_content`,
				{
					body: {
						content: converter.makeMarkdown(editedContent),
						mimetype: "text/markdown"
					}
				}
			);
			setGuestbookContent(editedContent);
			setIsEditing(false);
		} catch (error) {
			console.error("Error saving content:", error);
		} finally {
			setIsSaving(false);
		}
	};

	const handleCancelEdit = () => {
		setEditedContent("");
		setIsEditing(false);
	};

	const handleDownloadPDF = useCallback(async () => {
		try {
			setPdfError(false);
			setIsDownloadingPdf(true);
			const response = await API.get(
				DOCUMENT_API_CONFIG.endpoint,
				`${DOCUMENT_API_CONFIG.path}/${propertyId}/guestbook`,
				{
					headers: {
						Accept: "application/pdf"
					},
					responseType: "arraybuffer"
				}
			);

			if (!response) {
				setPdfError(true);
				throw new Error("Failed to download PDF");
			}

			const blob = new Blob([response], { type: "application/pdf" });
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement("a");
			a.href = url;
			a.download = `welcome-guide-${propertyId}.pdf`;
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(url);
			document.body.removeChild(a);
		} catch (error) {
			console.error("Error downloading PDF:", error);
			setPdfError(true);
		} finally {
			setIsDownloadingPdf(false);
		}
	}, [propertyId]);

	return (
		<div className="guestbook-container">
			<Row>
				<Col xs={12}>
					{!isEditing && (
						<Fragment>
							<div className="guestbook-content">
								{guestbookContent ? (
									<div
										className="guestbook-preview-content"
										dangerouslySetInnerHTML={{ __html: guestbookContent }}
									/>
								) : (
									<Fragment>
										<p>
											Our AI generator makes it easy to create a compelling
											Welcome Guide for your guests. Simply click the button
											below, and our AI will craft a personalized guide filled
											with local recommendations and activities. Guests will
											love the insider tips on dining, shopping, and exploring,
											making their stay unforgettable. With this tool, you can
											effortlessly create a guide that delights your guests and
											sets your property apart, ensuring a memorable experience
											from the start.
										</p>
										<PrimaryButton
											cssClass="top-margin bottom-margin"
											fullWidth={false}
											type="button"
											onClick={getRecommendedContent}
											disabled={isGenerating || isPolling}
										>
											{isGenerating
												? "Generating Your Welcome Guide..."
												: "Generate Content"}
										</PrimaryButton>
									</Fragment>
								)}
							</div>
							{isPolling && (
								<div className="text-center">
									<Loader />
								</div>
							)}
							{guestbookContent && (
								<Fragment>
									<br />
									<PrimaryButton
										cssClass="bottom-margin"
										fullWidth={false}
										type="button"
										onClick={handleStartEditing}
										disabled={isEditing}
									>
										Edit
									</PrimaryButton>
									<PrimaryButton
										cssClass="bottom-margin left-margin"
										fullWidth={false}
										type="button"
										onClick={handleDownloadPDF}
										disabled={isDownloadingPdf}
									>
										{isDownloadingPdf ? "Generating PDF..." : "Download PDF"}
									</PrimaryButton>
									{pdfError && (
										<span className="text-danger left-margin">
											We're sorry, we encountered an error generating your PDF
										</span>
									)}
								</Fragment>
							)}
						</Fragment>
					)}
					{isEditing && (
						<Fragment>
							<div className="guestbook-editor">
								<Editor
									init={returnEditorConfig()}
									value={editedContent || ""}
									onEditorChange={setEditedContent}
								/>
							</div>
							<div className="guestbook-buttons">
								<PrimaryButton
									fullWidth={false}
									type="button"
									onClick={handleSaveContent}
									disabled={isSaving}
								>
									{isSaving ? "Saving..." : "Save Changes"}
								</PrimaryButton>
								<PrimaryButton
									fullWidth={false}
									type="button"
									onClick={handleCancelEdit}
									disabled={isSaving}
								>
									Cancel
								</PrimaryButton>
							</div>
						</Fragment>
					)}
				</Col>
			</Row>
		</div>
	);
};

GuestBook.propTypes = {
	propertyId: PropTypes.string.isRequired
};

export default GuestBook;
