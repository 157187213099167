import React, { Component } from 'react';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Popover from 'react-bootstrap/lib/Popover';

/**
 * LIVE or NOT LIVE button on properties form, rooms, and add organizations page.
 */
export default class PropertyStatusButton extends Component {
    render() {
        let { 
            status, 
            mobileMode,
            doNotShowSpan,
            mode,
            admin,
            loading,
            integrationName,
            toggleStatus,
            level
         } = this.props

        let dynamicStyles = {
            pointerEvents: !admin ? 
            'none' : 
            'auto',
            border: mobileMode ? 
            'none' : 
            'default',
        };

        let cssStatus = '';
        let textStatus = '';
        if(level == 'org') {
            if(status !== "Distribute to RA and Channels" && status !== "Distribute to RA only") {
                cssStatus = ' not-live';
                textStatus = 'Not Live';
            }
            else {
                cssStatus = ' is-live';
                textStatus = 'Live';
            };
        }
        else {
            if(status && status.published != true) {
                cssStatus = ' not-live';

                if(mobileMode) {
                    return <div className="status not-published"/>
                }
                else {
                    textStatus = 'Not Live';
                };
            }
            else {
                cssStatus = ' is-live';
                textStatus = 'Live';
            };
        };

        return (
            <div className="property-status">
                {
                    doNotShowSpan ? null : <span>Status</span>
                }
                <OverlayTrigger
                    placement='bottom' 
                    overlay={
                        <Popover
                            id="popover-basic"
                            placement="bottom"
                            style={
                                level == 'property' ?
                                (integrationName == 'racalendar' ? {display: 'none'} : {}) :
                                {display: 'none'}
                            }
                        >
                            <p>Changing the status inside the portal could have unintended consequences.</p>
                        </Popover>
                    }
                >
                    <div
                    style={dynamicStyles}
                    className={"primary-btn active-btn live-btn" + 
                        ((mode === "create" || mode === "clone") ? " createMode" : "") + 
                        (cssStatus)}
                    onClick={(e) => 
                        (admin && !mode && !loading) || 
                        (admin && mode === "edit" && !loading) ? 
                        toggleStatus(e) : 
                        null}
                >
                    {textStatus}
                    <i className="icon icon-selection_tip"></i>
                </div>
                </OverlayTrigger>
            </div>
        )
    }
}