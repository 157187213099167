import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Title } from '../global/Title';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import Panel from 'react-bootstrap/lib/Panel';
import PanelGroup from 'react-bootstrap/lib/PanelGroup';
import Grid from 'react-bootstrap/lib/Grid';
import Modal from 'react-bootstrap/lib/Modal';
import Link from 'react-router-dom/Link';
import { InputField, InputLabel, PrimaryButton } from '../global/forms/FormElements';
import { updateOrganization, updateOrganizationTitle, updateOrganizationId, setRole } from '../../js/actions/index';
import PageLayout from '../global/PageLayout';
import apiHandler from '../../js/apiHandler';
import constants from '../../js/constants';
import { RTE } from '../global/forms/RTE';
import Loader from '../global/Loader';
import PhotoGallery from '../content/properties/PhotoGallery';
import ProgressBar from 'react-bootstrap/lib/ProgressBar';
import { Storage, API } from 'aws-amplify';

/**@class Component for edit and add collection. handled Edit and Add thing using this.collectionId variable. */
class AddCollection extends React.Component {

    constructor(props) {
        super(props);
        this.data = props.location.state && props.location.state.data || {};
        this.org = props.roleManager.org;
        this.collectionId = props.match.params.collectionId;
        this.tempCollection = undefined;
        this.state = {
            admin: false,
            isLoading: false,
            nameError: false,
            isNull: false,
            deletedPhotos: [],
            selectedPictures: [],
            photos: [],
            activeKey: 1,
            errorActiveKey: "",
            failedImage: 0,
            numberOfTask: 0,
            finishedTask: 0,
            imageUploadStatus: {
                all: 0,
                uploading: 0,
                status: false
            },
            imageWithError: false,
            modal: {
                show: false,
                text: '',
                errorMessage: '',
                customErrorMessage: '',
                state: 0
            },
            error: false,
            collection: {
                organization_name: this.org,
                name: this.data.name || '',
                description: this.data.description || '',
                url: this.data.url || '',
            }
        }
        this.roles = props.roleManager.roles;
        this.currentPhotos = [];
        this.tempCollection = undefined;
        this.init = this.init.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.createNewCollection = this.createNewCollection.bind(this);
        this.reorderImages = this.reorderImages.bind(this)
    }

    componentDidCatch(e) {
        console.log(e)
    }

    componentDidMount() {
        this.init();
    }

    componentWillReceiveProps(newProps) {
        let { roleManager, location } = newProps;
        if (location && location.state && location.state.org != this.org) {
            this.org = roleManager.org
            this.roles = roleManager.roles
            this.init();
            if (this.collectionId) {
                this.props.history.push('/collections')
            }
        }
        let modal = this.state.modal;
        modal.show = false
        this.setState({
            modal
        })
    }

    setPropertyOrg = (orgName) => {
        let organization = this.props.user.expanded_organization_roles.filter(org => {
            if(org.organization_name === orgName) {
                return true
            }
        });

        if(this.org !== orgName) {
            if(organization && organization.length) {
                this.props.updateOrganization(organization[0].organization_name);
                this.props.updateOrganizationTitle(organization[0].title);
                this.props.updateOrganizationId(organization[0].organization_id);
                this.props.setRole(organization[0].roles);
    
                sessionStorage.setItem('org', organization[0].organization_name);
                sessionStorage.setItem('orgTitle', organization[0].title);
                sessionStorage.setItem('orgId', organization[0].organization_id);

                sessionStorage.removeItem('reservations-listing');
                sessionStorage.removeItem('properties-listing');
            };
        };
    }

    /** Fetch collection picture by API call. */
    fetchPhotos() {
        this.setState({ isLoading: true })
        apiHandler.get(`/propertycollections/${this.collectionId}/photos`, {}).then((res) => {
            if (res.response) {
                let msg = res.response.data.Message;
                switch (res.response.status) {
                    case 404: {
                        this.setState({
                            isNull: true,
                            msg: msg.substring(msg.indexOf("'") + 1, msg.lastIndexOf("'")),
                            isLoading: false
                        })
                    }
                    case 403: {

                    }
                }
                return true;
            }
            this.currentPhotos = JSON.parse(JSON.stringify(res.data))
            this.setState({
                photos: res.data,
                isLoading: false,
            })
        })
    }
    /** Initialize page. */
    init() {
        let admin = false;
        if (this.roles.indexOf(constants.USER_TYPES.PROPERTY_MANAGER_ADMIN) > -1) {
            admin = true
        }
        this.setState({
            admin,
            modal: {
                show: false,
                text: '',
                state: 0,
                errorMessage: "",
                customErrorMessage: "",
            },
            numberOfTask: 0,
            finishedTask: 0,
            deletedPhotos: [],
            selectedPictures: [],
            imageWithError: false
        })
        if (this.collectionId && !this.data.name) {
            this.setState({
                isLoading: true
            })
            apiHandler.get(`/propertycollections/${this.collectionId}`, {}).then(async (res) => {
                if (res.response) {
                    let msg = res.response.data.Message;
                    switch (res.response.status) {
                        case 404: {
                            this.setState({
                                isNull: true,
                                msg: msg.substring(msg.indexOf("'") + 1, msg.lastIndexOf("'")),
                                isLoading: false
                            })
                        }
                        case 403: {

                        }
                    }
                    return true;
                }
                this.fetchPhotos();
                let collection = this.state.collection;
                collection.name = res.data.name;
                collection.description = res.data.description;
                collection.organization_name = res.data.organization_name;
                this.setPropertyOrg(res.data.organization_name);
                this.setState({
                    collection,
                    isNull: false
                })
            })
        } else if (this.collectionId && this.data.name) {
            this.fetchPhotos()
        }
    }
    /** Handle change in collection name. */
    handleChange(event) {
        let collection = this.state.collection;
        collection[event.target.name] = event.target.value;
        if (event.target.value === '') {
            this.setState({
                collection,
                nameError: true
            })
            return;
        }
        this.setState({
            collection,
            nameError: false
        })
    }
    /** Create new collection and edit new collection. */
    async createNewCollection() {
        let data = this.state.collection;
        data.description = data.description.toString("html");
        data['organization_name'] = this.org;
        if (data['name'] === '') {
            this.setState({
                nameError: true
            })
            return;
        }
        let modal = this.state.modal;
        modal.show = true;
        if (!this.collectionId) {
            modal.text = 'Creating Collection...';
            modal.state = 2;
            this.setState({
                modal,
                numberOfTask: 1 + this.state.photos.length
            })
            apiHandler.post(`/propertycollections`, { body: data }).then(async (data) => {
                if (data.error || data.response) {
                    modal.errorMessage = (data && data.response && data.response.data && data.response.data.Message) ? data.response.data.Message : (data && data.response && data.response.data);
                    modal.customErrorMessage = this.handleCustomErrorMessage(data && data.response && data.response.data && data.response.data.Message);
                    modal.text = 'Error';
                    modal.state = 0;
                    this.setState({
                        modal,
                        finishedTask: this.state.finishedTask + 1,
                        imageWithError: true
                    })
                    return;
                } else {
                    let imageUploadStatus = this.state.imageUploadStatus;
                    imageUploadStatus.all = this.state.photos.length;
                    modal.text = 'Uploading Images...';
                    modal.state = 2;
                    this.setState({
                        modal,
                        finishedTask: this.state.finishedTask + 1,
                        imageUploadStatus
                    })
                    await this.uploadPhotos(data.collection_id, this.state.photos)
                    modal.text = 'Collection Created';
                    modal.state = 1;
                    this.setState({
                        modal
                    })
                    this.tempCollection = data;
                    this.data = data;
                }
            }).catch(e => {
                modal.text = 'Error';
                modal.state = 0;
                modal.errorMessage = (data && data.response && data.response.data && data.response.data.Message) ? data.response.data.Message : (data && data.response && data.response.data);
                modal.customErrorMessage = this.handleCustomErrorMessage(data && data.response && data.response.data && data.response.data.Message);
                this.setState({
                    modal,
                    finishedTask: this.state.finishedTask + 1,
                    imageWithError: true
                })
            })
        } else {
            modal.text = 'Saving Collection...';
            modal.state = 2;
            let existingImages = this.state.photos.filter((img, index) => {
                let oldImage = this.currentPhotos.filter(photo => {
                    return photo.photo_id == img.photo_id
                })
                return oldImage[0] && JSON.stringify(oldImage[0]) != JSON.stringify(img)
            });
            this.setState({
                modal,
                numberOfTask: 1 + this.state.deletedPhotos.length + existingImages.length
            })
            apiHandler.put(`/propertycollections/${this.collectionId}`, { body: data }).then(async (data) => {
                if (data.error || data.response) {
                    modal.text = 'Error';
                    modal.state = 0;
                    modal.errorMessage = (data && data.response && data.response.data && data.response.data.Message) ? data.response.data.Message : (data && data.response && data.response.data);
                    modal.customErrorMessage = this.handleCustomErrorMessage(data && data.response && data.response.data && data.response.data.Message);
                    this.setState({
                        modal,
                        finishedTask: this.state.finishedTask + 1
                    })
                } else {
                    if (this.state.photos.length) {
                        modal.text = 'Saving Images';
                        modal.state = 2;
                        this.setState({
                            modal,
                            finishedTask: this.state.finishedTask + 1
                        })
                    }
                    let failedImages = await this.submitPhotos();
                    for (let i = 0; i < existingImages.length; i++) {
                        try {
                            await apiHandler.put(`/propertycollections/${this.collectionId}/photos/${existingImages[i].photo_id}`, {
                                body: existingImages[i]
                            })
                            this.setState({
                                finishedTask: this.state.finishedTask + 1
                            })
                        } catch (e) {
                            console.log(e)
                        }
                    }
                    if (failedImages === 0 || isNaN(failedImages)) {
                        modal.text = 'Collection Saved';
                        modal.state = 1;
                    } else {
                        modal.text = 'Error In Image Upload';
                        modal.state = 0;
                        modal.errorMessage = (data && data.response && data.response.data && data.response.data.Message) ? data.response.data.Message : (data && data.response && data.response.data);
                        modal.customErrorMessage = this.handleCustomErrorMessage(data && data.response && data.response.data && data.response.data.Message);
                    }
                    this.setState({
                        modal
                    })
                }
            }).catch(e => {
                console.log(e)
                modal.text = 'Error';
                modal.state = 0;
                modal.errorMessage = (data && data.response && data.response.data && data.response.data.Message) ? data.response.data.Message : (data && data.response && data.response.data);
                modal.customErrorMessage = this.handleCustomErrorMessage(data && data.response && data.response.data && data.response.data.Message);
                this.setState({
                    modal
                })
            })
        }
    }
    /** Handle RTE changes (description) */
    handleRTEChange(value, name) {
        let collection = this.state.collection;
        collection[name] = value;
        this.setState({
            collection
        })
    }

    handleCustomErrorMessage = (error) => {
        let errorMessage = "";

        if(error && error.includes('already exists')) {
            errorMessage = "Error Saving Collection. Collection with this name already exists.";
        }
        else {
            errorMessage = "Error Saving Collection. Please contact Customer Support.";
        }
        return errorMessage;
    }

    /**
     * Select image by it's index
     * @param {*} imgIndex image index
     */
    selectImage(imgIndex) {
        let selectedPictures = this.state.selectedPictures;
        let pictures = this.state.photos || [];

        if (selectedPictures.length === 0) {
            selectedPictures = [];
        }

        if (selectedPictures.indexOf(imgIndex) < 0) {
            selectedPictures.push(imgIndex);
        } else {
            selectedPictures.splice(selectedPictures.indexOf(imgIndex), 1);
        }
        this.setState({
            selectedPictures: selectedPictures,
            selectAllImages: selectedPictures.length === this.state.photos.length ? true : false
        });
    }
    /**
     * Invoke function when image swap between 2 consecutive images.
     * @param {*} source 
     * @param {*} target 
     */
    swapImages(source, target) {
        let photos = this.state.photos;
        let selectedPictures = this.state.selectedPictures;
        if (selectedPictures.indexOf(source) >= 0 && selectedPictures.indexOf(target) < 0) {
            selectedPictures[selectedPictures.indexOf(source)] = target;
        }
        let swappedPhotos = this.reorderImages(this.swapArray(photos, source, target))
        this.setState({
            photos: [...swappedPhotos],
            selectedPictures
        });
    }
    /**
     * Invoke function when image swap between nonconsecutive image. 
     * @param {*} arr 
     * @param {*} source 
     * @param {*} target 
     */
    swapArray(arr, source, target) {
        if (!arr || !arr.length) {
            return [];
        }
        try {
            arr.splice(target, 0, arr.splice(source, 1)[0]);
            return arr;
        } catch (e) {
            return arr;
        }
    }
    /** Reorder images after swap 
     * @param imageList list of images
    */
    reorderImages(imageList) {
        imageList = imageList.map((img, i) => {
            img.order = i;
            return img
        })
        return imageList
    }
    /** Upload photo caption
     * @param {string} caption text of caption
     * @param {number} index  index of picture
     */
    updatePhotoCaption(caption, index) {
        let photos = this.state.photos;
        if (!!photos[index]) {
            photos[index].title = caption;
            this.setState({
                photos
            })
        } else {
            console.log('unable to update caption. Invalid index: ' + index);
        }
    }
    /** Change photo 
     * @param {*} files list of file
    */
    async changePhoto(files) {
        const _this = this;
        let photos = Object.assign([], _this.state.photos),
            order = _this.state.photos.length;
        if (typeof this.props.onChange === "function") {
            this.props.onChange(files);
        }

        // Iterate over all uploaded files
        for (let i = 0, f; f = files[i]; i++) {
            await new Promise((resolve, reject) => {

                const reader = new FileReader();
                // Read the image via FileReader API and save image result in state.
                reader.onload = (function () {
                    return function (e) {
                        if (_this.state.photos.indexOf(e.target.result) === -1) {
                            // const newPictureArray = _this.state.photos.slice();
                            photos.push({
                                data: e.target.result.split(',')[1],
                                data_type: f.type.replace('image/', ''),
                                order: order++,
                                file: files[i]
                            });
                            resolve();
                        }
                    };
                })(f);
                reader.readAsDataURL(f)
            });
        }
        this.setState({ photos })
    }
    /** Delete selected image. */
    deleteSelectedImages() {
        let selectedPictures = this.state.selectedPictures;
        let pictures = Object.assign([], this.state.photos);
        let deletedPhotos = Object.assign([], this.state.deletedPhotos);
        pictures = this.reorderImages(pictures.filter((pic, i) => {
            if (selectedPictures.indexOf(i) >= 0) {
                if (pic.photo_id)
                    deletedPhotos.push(pic.photo_id)
                return false
            }
            return true
        }))
        this.setState({
            photos: [...pictures],
            selectedPictures: [],
            selectAllImages: false,
            deletedPhotos: deletedPhotos
        })
    }
    /** Deselect all images. */
    cancelSelectedImages() {
        this.setState({
            selectedPictures: [],
            selectAllImages: false
        });
    }
    /** Toggle select images. */
    toggleSelectAllImages(e) {
        let temp = [];
        let isChecked = false;
        let selectedPictures = this.state.selectedPictures;
        let pictures = this.state.photos;
        if (pictures.length > 0) {
            if (selectedPictures.length !== pictures.length) {
                for (let i = 0; i < pictures.length; i++) {
                    temp.push(i);
                }
                isChecked = true;
            }
            this.setState({
                selectedPictures: temp,
                selectAllImages: temp.length === pictures.length ? true : false
            })
        }

    }
    /** Click continue editing button of modal. */
    clickContinueEditing() {
        if (this.tempCollection) {
            this.props.history.push({
                pathname: `/collections/${this.tempCollection.collection_id}/edit`,
                state: this.tempCollection
            })
        } else {
            this.init()
        }
    }

    /**
     * Upload picture
     * @param {*} id id of collection
     * @param {*} photos list of pictures
     */
    async uploadPhotos(id, photos) {
        let picLen = photos.length;
        let tokensList = [];
        let failedImage = 0;
        let errorMsg = '';

        if (picLen) {
            for (let i = 0; i < picLen; i++) {
                try {
                    let token = await apiHandler.post(`/propertycollections/${id}/imagetokens`, { body: { title: photos[i].title || '' } });

                    let upload_url = token.upload_url.split("s3://")[1];
                    let bucket = upload_url.substr(0, upload_url.indexOf("/"));

                    Storage.configure({
                        bucket: bucket,
                        identityPoolId: constants.COGNITO.IdentityPoolId,
                        region: constants.COGNITO.Region
                    });

                    try {
                        await Storage.put(`${token.token_id}`, photos[i].file, {
                            contentType: `image/${photos[i].data_type}`,
                            customPrefix: {
                                public: `${id}/public/`,
                            }
                        });
    
                        tokensList.push(token.token_id);

                        for (let j = 0; j < tokensList.length; j++) {
                            let imageUploadStatus = this.state.imageUploadStatus;
                            imageUploadStatus.status = true;
                            imageUploadStatus.all = tokensList.length;
                            imageUploadStatus.uploading = j + 1;
                            this.setState({ imageUploadStatus })
                            let uploadComplete = false
                            while (!uploadComplete) {
                                try {
                                    let bucketInfo = await API.get("rapapi", `/imagetokens/${tokensList[j]}`);
                                    if (bucketInfo.status == "completed" || bucketInfo.status == "error") {
                                        uploadComplete = true;
                                        imageUploadStatus.status = imageUploadStatus.all === imageUploadStatus.uploading ? false : true;
                                        this.setState({ finishedTask: this.state.finishedTask + 1, imageUploadStatus});
                                    }
                                    if (bucketInfo.error) {
                                        this.setState({ imageWithError: true })
                                        throw new Error(bucketInfo.error);
                                    }
                                } catch (e) {
                                    console.log(e)
                                    e.errorType = 'image';
                                    failedImage += 1;
                                    if (e.message === `("Error 1062: Duplicate entry 'node-0-0-0-und' for key 'PRIMARY'", -1)`) {
                                        errorMsg = 'Duplicate Image Found'
                                    } else {
                                        errorMsg = 'Error in Image Upload'
                                    }
                                }
                                if (!uploadComplete) {
                                    // Randon delay of 500ms to 1500ms before checking the status again
                                    let offset = 500 + Math.floor(Math.random() * 1000)
                                    await delay(offset);
                                }
                            }
                        }
                    }
                    catch(e) {
                        const reader = new FileReader();

                        reader.addEventListener("loadend", () => {
                            let result = reader.result;
                            let errorMessage = result.includes("RequestTimeTooSkewed") ?
                                "Image upload failed. Your system clock is out of sync." :
                                result;
                            
                            if(result) {
                                this.setState({
                                    modal: {
                                        ...this.state.modal,
                                        show: true,
                                        errorMessage: errorMessage
                                    },
                                    finishedTask: this.state.finishedTask + 1
                                });
                            };
                        });
                        reader.readAsText(e && e.response && e.response.data);
                    };
                } 
                catch (e) {
                    e.errorType = 'image';
                };
            };
            let modal = this.state.modal;
            if (!failedImage) {
                modal.text = 'Collection Saved';
                modal.state = 1;
            } else {
                modal.text = 'Error';
                modal.state = 0;
                modal.errorMsg = errorMsg;
            }
            this.setState({
                modal,
                failedImage
            })
            return failedImage
        }
    }
    /** Get already uploaded photo id */
    getUploadedPhotos(photos) {
        if (!photos || photos.length < 0) {
            return [];
        }

        return photos.filter(function (photo) {
            return !photo.photo_id;
        });
    }

    /** Submit picture save or delete picture will done here. */
    async submitPhotos() {
        let addedPhotos = this.getUploadedPhotos(this.state.photos);
        if (addedPhotos.length) {
            this.setState({
                numberOfTask: this.state.numberOfTask + addedPhotos.length
            })
            if (this.state.deletedPhotos && this.state.deletedPhotos.length > 0) {
                let modal = this.state.modal;
                let imageUploadStatus = this.state.imageUploadStatus;
                imageUploadStatus.all = addedPhotos.length;
                modal.text = 'Saving Pictures..';
                modal.state = 2;
                this.setState({
                    modal,
                    imageUploadStatus
                })
            }
        }
        let resultInUpload = await this.uploadPhotos(this.collectionId, addedPhotos);
        if (this.state.deletedPhotos && this.state.deletedPhotos.length > 0) {
            let modal = this.state.modal;
            modal.text = 'Deleting Pictures...';
            modal.state = 2;
            this.setState({
                modal
            })
        }
        let resultInDelete = await this.deletePhotos(this.collectionId, this.state.deletedPhotos);
        return resultInDelete + resultInUpload
    }
    /** For delete pictures 
     * @param {string} id id of property
     * @param {*} delete picture ids. 
    */
    async deletePhotos(id, photoIds) {
        let failedImage = 0;
        if (photoIds && photoIds.length > 0) {
            for (let i = 0; i < photoIds.length; i++) {
                try {
                    await apiHandler.update('delete', '/propertycollections/' + id + '/photos/' + photoIds[i]);
                    this.setState({
                        finishedTask: this.state.finishedTask + 1
                    })
                } catch (e) {
                    this.setState({
                        finishedTask: this.state.finishedTask + 1
                    })
                    failedImage += 1;
                }
            }
        }
        return failedImage;
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />
        }

        let breadcrumbItems = [];

        if(this.state.collection){
            breadcrumbItems = [
                { title: "Collections", link: '/collections' },
                { title: this.props.match.params.collectionId ? `${this.state.collection.name}` : 'Create Collection' }
            ]
        }

        return (
            <PageLayout isCentered={true}>
                <Title title={(this.collectionId ? 'Edit' : 'Create') + ' Collection' + (this.collectionId ? ` ${this.collectionId}` : '')} isCentered={true} breadcrumbItems={breadcrumbItems}>
                    <Link className='primary-btn black-btn' to={'/collections'}>Cancel</Link>
                    {this.state.admin && !this.state.isNull && <button className='primary-btn left-margin' onClick={this.createNewCollection}>Save</button>}
                </Title>
                <div id='content'>
                    {this.state.isNull ? <div className='no-collection'>{this.state.msg}</div> : <Grid fluid={true} className={'property-item collection-panel' + (!this.state.admin ? " readonly" : "")}>
                        <PanelGroup accordion id="accordion-controlled-example" defaultActiveKey={1} activeKey={this.state.activeKey} onSelect={(activeKey) => { this.setState({ activeKey }) }}>
                            <Panel eventKey={1}>
                                <Panel.Heading>
                                    <Panel.Title toggle>Collection Details <span className={'pull-right '+(this.state.activeKey === 1 ? 'glyphicon glyphicon-chevron-up': 'glyphicon glyphicon-chevron-down')}></span></Panel.Title>
                                </Panel.Heading>
                                <Panel.Body collapsible>
                                    <Row>
                                        <Col xs={12} className="bottom">
                                            <InputLabel htmlFor="title">Collection Name</InputLabel>
                                            <InputField type="text" placeholder="Collection Name" name="name" value={this.state.collection.name} onChange={this.handleChange} />
                                            <span className='error' style={{ display: this.state.nameError ? 'block' : 'none' }} >Collection name required</span>
                                        </Col>
                                        <Col xs={12}>
                                            <InputLabel htmlFor="title">Collection Description</InputLabel>
                                            <RTE value={this.state.collection.description} name="description" isAdmin={this.state.admin} onChange={this.handleRTEChange.bind(this)} placeholder="Enter a description for the collection" />
                                        </Col>
                                    </Row>
                                </Panel.Body>
                            </Panel>
                            {!this.state.admin && this.state.photos.length === 0 ? "" : <Panel eventKey={2} className="image-upload-section">
                                <Panel.Heading>
                                    <Panel.Title toggle>
                                        {!this.state.admin ? 'Photos' : 'Upload Photos'}
									{this.state.photos.length > 0 &&
                                            (<h4 className="panel-subtext">{this.state.photos.length} Photo{this.state.photos.length > 1 && 's'} Uploaded</h4>)
                                        }
                                        <span className={'pull-right '+(this.state.activeKey === 2 ? 'glyphicon glyphicon-chevron-up': 'glyphicon glyphicon-chevron-down')}></span>
                                    </Panel.Title>
                                </Panel.Heading>
                                <Panel.Body collapsible>
                                    <Row>
                                        <Col md={12}>
                                            <Row className="top-margin">
                                                <Col md={12} sm={12} xs={12}>
                                                    <div className="bed-config-div image-group">
                                                        <PhotoGallery
                                                            isAdmin={this.state.admin}
                                                            photos={this.state.photos}
                                                            selectedPictures={this.state.selectedPictures}
                                                            selectImage={this.selectImage.bind(this)}
                                                            changePhoto={this.changePhoto.bind(this)}
                                                            swapImages={this.swapImages.bind(this)}
                                                            updateCaption={this.updatePhotoCaption.bind(this)} />
                                                    </div>
                                                </Col>
                                            </Row>
                                            {this.state.admin && <Fragment>
                                                <Row className="image-options">
                                                    {this.state.photos.length > 0 &&
                                                        <Col xs={12} sm={4}>
                                                            <InputField type="checkbox" value={this.state.selectAllImages} name="selectAllImages" cbChange={this.toggleSelectAllImages.bind(this)}>Select All</InputField>
                                                        </Col>
                                                    }
                                                    {this.state.selectedPictures.length > 0 &&
                                                        <Col xs={12} sm={8} className="right-end">
                                                            <div className="image-btns-wrap">
                                                                <PrimaryButton cssClass="white-btn pull-left left-margin" fullWidth={false} type="button" onClick={this.cancelSelectedImages.bind(this)}> Cancel </PrimaryButton>
                                                                <PrimaryButton cssClass="primary-btn pull-left left-margin" fullWidth={false} type="button" onClick={this.deleteSelectedImages.bind(this)}>Delete Selected Items ({this.state.selectedPictures.length})</PrimaryButton>
                                                            </div>
                                                        </Col>
                                                    }
                                                </Row>
                                                <Row>
                                                    <Col xs={12}>
                                                        <ul className="image-upload-bulletin">
                                                            <li>Pictures must be of GIF, PNG, JPEG, or JPG.</li>
                                                            <li>Drag and drop picture to change the sequence of images. This is the sequence that will be used on the listing.</li>
                                                            <li>Simply double-click on an image to add or edit photo captions.</li>
                                                            <li>Select the desired image and click "delete" button to remove images.</li>
                                                        </ul>
                                                    </Col>
                                                </Row>
                                            </Fragment>} 
                                        </Col>
                                    </Row>
                                </Panel.Body>
                            </Panel>}
                        </PanelGroup>
                    </Grid>}
                </div>
                <Modal
                    backdrop='static'
                    className="submit-modal" 
                    show={this.state.modal.show} disabled
                >
                    <Modal.Header>
                        <Modal.Title>
                            {
                                this.state.modal.state === 2 ? 
                                    <div className="submit-loader">
                                        <Loader />
                                    </div> : 
                                    null
                            }
                            {
                                this.state.modal.state === 0 ? 
                                    <span className='status-icon status-icon--error'></span> : 
                                    null
                            }
                            {
                                this.state.modal.state === 1 ? 
                                    <span className='status-icon status-icon--success'></span> : 
                                    null
                            }
                            <span 
                                className="submit-heading"
                            >
                                {this.state.modal.text}
                            </span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='progress-wrapper'>
                            <ProgressBar 
                                bsStyle={this.state.imageWithError ? 'danger' : 'success'} now={(this.state.finishedTask / this.state.numberOfTask) * 100} />
                            <h5 className='pull-left'>
                                <strong>
                                    {this.state.imageUploadStatus.status && `Uploading image ${this.state.imageUploadStatus.uploading} of ${this.state.imageUploadStatus.all}`}
                                </strong>
                            </h5>
                            <h5 className='progress-percent pull-right'><strong>{parseInt((this.state.finishedTask / this.state.numberOfTask) * 100)}%</strong></h5>
                        </div>
                        {
                            this.state.modal.state === 0 && 
                            <p className="progress-error">{this.state.modal.customErrorMessage}</p>
                        }
                        {
                            this.state.modal.errorMessage &&
                            <PanelGroup accordion id="errorMessage" onSelect={errorActiveKey => this.setState({ errorActiveKey })}>
                                <Panel eventKey="1">
                                    <Panel.Heading>
                                        <Panel.Title toggle>
                                            Error Message
                                            <span className={`pull-right ${this.state.errorActiveKey === '1' ? 'glyphicon glyphicon-chevron-up': 'glyphicon glyphicon-chevron-down'}`}></span>
                                        </Panel.Title>
                                        <Panel.Body collapsible>
                                            <pre>
                                                <code>
                                                    {this.state.modal.errorMessage ? 
                                                        JSON.stringify(this.state.modal.errorMessage) : 
                                                        ''
                                                    }
                                                </code>
                                            </pre>
                                        </Panel.Body>
                                    </Panel.Heading> 
                                </Panel>
                            </PanelGroup>
                        }
                        <Row>
                            <Col xs={12} className="top-margin">
                                {
                                    this.state.modal.state === 1 || 
                                    this.state.modal.state === 0 ? 
                                        <Link 
                                            className="primary-btn pull-left white-btn" 
                                            type="button" 
                                            to={'/collections'}
                                        >
                                            Go To Collections
                                        </Link> : 
                                    null
                                }
                                {
                                    this.state.modal.state === 1 ? 
                                        <PrimaryButton 
                                            cssClass="left-margin" 
                                            fullWidth={false} 
                                            type="button" 
                                            onClick={this.clickContinueEditing.bind(this)}
                                        >
                                            Continue Editing
                                        </PrimaryButton> : 
                                    null
                                }
                                {
                                    this.state.modal.state === 0 ? 
                                        <PrimaryButton 
                                            fullWidth={false} 
                                            cssClass="left-margin" 
                                            onClick={() => this.init()}
                                        >
                                            Close
                                        </PrimaryButton> : 
                                        null
                                }
                            </Col> 
                        </Row>
                    </Modal.Body>
                </Modal>
            </PageLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        roleManager: state.roleManager,
        user: state.authState.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setRole: (role) => {
            dispatch(setRole(role));
        },
        updateOrganization: (org) => {
            dispatch(updateOrganization(org))
        },
        updateOrganizationTitle: (org) => {
            dispatch(updateOrganizationTitle(org))
        },
        updateOrganizationId: (orgId) => {
            dispatch(updateOrganizationId(orgId))
        },
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(AddCollection));