import React, { Component, Fragment } from 'react';
import Link from 'react-router-dom/Link';
import { API, Storage } from 'aws-amplify';
import Picky from 'react-picky';
import 'react-picky/dist/picky.css';
import { updateOrganization, updateOrganizationTitle, updateOrganizationId } from '../../js/actions/index';
import { updateUnsavedData } from '../../js/actions';
import moment from 'moment';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Dropzone from 'react-dropzone';
import isEqual from "lodash/isEqual";
import Switch from "react-switch";
import ReactTable from 'react-table/react-table.min.js';
import Pagination from 'react-js-pagination';

import Form from 'react-bootstrap/lib/Form';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import PanelGroup from 'react-bootstrap/lib/PanelGroup';
import Panel from 'react-bootstrap/lib/Panel';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import Modal from 'react-bootstrap/lib/Modal'
import Popover from 'react-bootstrap/lib/Popover';
import ReactTableComponent from '../global/ReactTableComponent';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { Title } from '../global/Title';
import { delay } from '../../js/actions';
import { InputField, PrimaryButton, FieldWrap, InputLabel } from '../global/forms/FormElements';
import Loader from '../global/Loader';
import PageLayout from '../global/PageLayout';
import PropertyStatusButton from '../content/properties/PropertyStatusButton';
import GlobalSubmitModal from '../global/forms/GlobalSubmitModal';
import NavigationPrompt from "../global/NavigationPrompt";
import Constants from '../../js/constants';
import {
    getSessionId,
    infoPopover
} from '../../helpers/helpers';
import DateRangePicker from "react-dates/lib/components/DateRangePicker";

const Channels = [
    { label: "Redawning", value: "redawning" },
    { label: "Airbnb", value: "airbnb" },
    { label: "Booking.com", value: "bookingcom" },
    { label: "BookingPal", value: "bookingpal" },
    { label: "Expedia", value: "expedia" },
    { label: "VRBO", value: "homeaway" },
    { label: "AllTheRooms", value: "alltherooms" },
    { label: "B2Book", value: "b2book" },
    { label: "BeachGuide", value: "beachguide" },
    { label: "BridgeStreet", value: "bridgestreet" },
    { label: "CanadaStays", value: "canadastays" },
    { label: "CondoWorld", value: "condoworld" },
    { label: "CozyStays", value: "cozystays" },
    { label: "Ebay", value: "ebay" },
    { label: "Galveston", value: "galveston" },
    { label: "GlampingHub", value: "glampinghub" },
    { label: "Google Hotel Ads", value: "googlehotelads" },
    { label: "Holidu", value: "holidu" },
    { label: "Home2go", value: "home2go" },
    { label: "IBC", value: "ibc" },
    { label: "Kognitiv", value: "kognitiv" },
    { label: "MagicStays", value: "magicstays" },
    { label: "MakeMyTrip", value: "makemytrip" },
    { label: "MisterBnB", value: "misterbnb" },
    { label: "NextTrip", value: "nexttrip" },
    { label: "PlayInNewBraunfels", value: "playinnewbraunfels" },
    { label: "Quintess", value: "quintess" },
    { label: "SouthPadreCVB", value: "southpadrecvb" },
    { label: "TProCommission", value: "tprocommission" },
    { label: "TProNet", value: "tpronet" },
    { label: "TravelersHaven", value: "travelershaven" },
    { label: "TripAdvisor", value: "tripadvisor" },
    { label: "Tripping", value: "tripping" },
    { label: "Trivago", value: "trivago" },
    { label: "VRbyChoiceHotels", value: "vrbychoicehotels" },
]

class AddOrganization extends Component {
    initialize = true;
    turnoverBlockTooltip = "Number of nights blocked before and after a reservation."
    seasonalPoliciesTooltip = "Cancellation policies that are less strict often generate more reservations. To overwrite your default cancellation policy for a certain date range, you can do so here. Please note this isn't supported on all channels currently. Channels other than Booking.com, Expedia, VRBO and RedAwning will continue to use your default cancellation policy."
    checkinInstructionsTooltip = "Metadata will be inserted into the check-in instructions provided to guest. Editing the field below will set the default metadata for all of your properties.";
    bookingPalTooltip = "Open BookingPal wizard."
    defaultChannels = [
        {channel: "redawning", opt: "1", markup: "0", svc_fee: "12", percent_markup_fees_addl: "12", percent_markup_rent_addl: "0"},
        {channel: "airbnb", opt: "1", markup: "5", svc_fee: "0", percent_markup_fees_addl: "0", percent_markup_rent_addl: "0"},
        {channel: "bookingcom", opt: "1", markup: "5", svc_fee: "13", percent_markup_fees_addl: "13", percent_markup_rent_addl: "0"},
        {channel: "expedia", opt: "1", markup: "5", svc_fee: "13", percent_markup_fees_addl: "13", percent_markup_rent_addl: "0"},
        {channel: "homeaway", opt: "1", markup: "5", svc_fee: "4", percent_markup_fees_addl: "4", percent_markup_rent_addl: "0"},
        {channel: "tripadvisor", opt: "1", markup: "5", svc_fee: "0", percent_markup_fees_addl: "0", percent_markup_rent_addl: "0"},
        {channel: "bookingpal", opt: "1", markup: "5", svc_fee: "13", percent_markup_fees_addl: "13", percent_markup_rent_addl: "0"},
        {channel: "home2go", opt: "1", markup: "0", svc_fee: "0", percent_markup_fees_addl: "0", percent_markup_rent_addl: "0"}
    ];
    reviewsRatingsOptions = [
        {value: 5, label: '5 Star'},
        {value: 4, label: '4 Star'},
        {value: 3, label: '3 Star'},
        {value: 2, label: '2 Star'},
        {value: 1, label: '1 Star'},
    ];
    breadcrumbItems = [{
        title: "Organizations",
        link: "/account-management/organizations"
    },
    {
        title: _.get(this.props.match, "params.organizationName") ? this.props.match.params.organizationName : "Create Organization"
    }];
    parentOrgOptions = [];
    addOrgSmartSiteOptions = [];
    tid = getSessionId();
    initSubCategories = {};
    initPrimaryRoles = [];
    initCommHubEnabled = false;
    editOrgSmartsiteAddUids = false;
    createUpdateOrganizations = false;
    showChannelsAndMarkup = false;
    createChannelsAndMarkup = false;
    updateChannelsAndMarkup = false;
    state = {
        successClearCache: false,
        init: true,
        mode: "create",
        modal: '',
        isLoading: true,
        altLoading: false,
        error: false,
        activeKey: "1",
        organization: [],
        integrationNames: [],
        integrationCalendar: [],
        integrationPartner: [],
        integrationFees: [],
        filteredChannels: [],
        absentStaticChannels: false,
        channelOptInMarkupData: this.defaultChannels,
        pageSize: 30,
        activePage: 1,
        keyword: "",
        submitModalTotal: 0,
        submitStatus: 0,
        progressMessage: "",
        image: {},
        newImage: {},
        imageDimension: "",
        integrationStatus: "0",
        bookingPalUrl: "",
        editRentalPolicies: false,
        commHubData: {},
        commHubEnabled: false,
        commHubUsersOptions: [],
        commHubUsersOptionsValue: [],
        enableCommHubIsLoading: false,
        enableCommHubErrMsg: '',
        selectedTeamMember: {},
        teamMemberPropertiesErrorMsg: '',
        teamMemberPropertyOptionsValue: [],
        teamMemberPropertiesList: [],
        teamMemberPropertiesIsLoading: false,
        teamMemberMode: 'create',
        teamMembers: [],
        teamMembersIsLoading: false,
        teamMemberDeleteErrorMsg: '',
        teamMemberErrorMsg: '',
        sourceTeamMemberData: {},
        enablePeriodicPayouts: '',
        reviewsRatingsOptionsValue: [],
        addOrgSmartSiteOptionsValue: [],
        teamMemberData: {
            subscriberId: '',
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            role_type: '',
        },
        teamMemberValidations: {
            subscriberId: false,
            first_name: false,
            last_name: false,
            email: false,
            phone_number: false,
            role_type: false,
            emailFormat: false,
            notificationPref: false,
            subCategories: false,
        },
        subCategories: {},
        churnData: {
            churn_date: moment().format('YYYY-MM-DD'),
            churn_reason: "",
            churn_detail: "",
        },
        rentalPolicy: {
            commission: "10",
            creditCardFee: "3",
        },
        modalError: {
            show: false,
            message: "",
            detail: [],
        },
        sorted: {
            id: "channel",
            desc: false,
        },
        cancelPolicyDataPlaceholder: {
            value: '',
            status: '',
            message: ''
        },
        selectedOrganization: {},
        validEmailFormat: false,
        duplicateIntegrationFeesData: {},
        checkinInstructionsData: {
            door_code: {
                name: "door_code",
                value: ""
            }
        },
        seasonalPolicies: [],
        validations: {
            organization_name: false,
            title: false,
            parent: false,
            contact_email: false,
            integration_name: false,
            integration_client_code: false,
            calendar_format: false,
            damage_waiver: false,
            duplicateIntegrationFees: false,
            integrationFees: [],
            free_cancel_policy: false,
            free_cancel_info: false,
            seasonalPolicies: [],
        },
        data: {
            organization_name: "",
            manager_uid: "",
            title: "",
            contact_email: "",
            parent: "",
            ra_collect: "1",
            type: ["property_manager"],
            property_manager_details: {
                rental_policy: "10% | 3%",
                commission_rate: "0",
                credit_card_fee: "0",
                auto_publish: "1",
                share_contact_status: "",
                booking_lead_hours: "48",
                block_calendar: "540",
                turnover_block: "",
                rental_fees: "0",
                rental_flat_fee: "0",
                cleaning_fees: "0",
                cleaning_flat_fee: "0",
                tax_fees: "0",
                tax_flat_fee: "0",
                integration_partner: "",
                integration_name: "",
                integration_client_code: "",
                calendar_format: "",
                damage_waiver: "",
                has_cleaning_fee: "1",
                has_tax: "1",
                exclude_tax_from_integration: "0",
                exclude_cleanfee_livequote: "0",
                exclude_tax_livequote: "0",
                airbnb_tax: "0",
                threshold_minimum_stay: 1,
                default_minimum_stay: 1,
                account_status: "Delisted",
                misc_rentalfee: "",
                misc_cleanfee: "",
                misc_tax: "",
                misc_nightlytax: "",
                misc_secdeposit: "",
                free_cancel_policy: "no",
                free_cancel_info: "unreached",
                full_service: "0",
                guest_involvement: "local_partner",
                use_commissions_for_additional_fees: "0",
            },
        },
        initialStateModel: {
            data: {},
            image: {},
            newImage: {},
            integrationFees: [],
            integrationStatus: "0",
            selectedOrganization: {},
            checkinInstructionsData: {},
            seasonalPolicies: [],
            enablePeriodicPayouts: ""
        },
        columns:[
            {
                'Header':'Channel',
                'accessor': 'channel',
                'sortMethod': (a, b) => {
                    if (a == b) return 0;
                    if (a == 'redawning') return -1;
                    if (b == 'redawning') return 1;
                  
                    if (a < b)
                        return -1;
                    if (a > b)
                        return 1;
                    return 0;
                },
                'Cell': row=>{
                    const { init } = this.state;
                    const channel = Channels.filter((channel) => { return row.original.channel === channel.value });
                    const bookingPal = row.original.channel === 'bookingpal' || "";

                    return(
                    <div>
                        <OverlayTrigger 
                            placement='right' 
                            overlay={this.getTooltip('warning', 'Channel is not saved.')}
                        >
                            <span>
                                {row && row.original && row.original.status && <i className="icon-warning channel-markup-warning-icon"/>}
                            </span>
                        </OverlayTrigger>
                        <span
                            className={(!init && bookingPal && (this.createUpdateOrganizations && this.updateChannelsAndMarkup)) ? 'clickable-text' : ''}
                            onClick={(!init && bookingPal && (this.createUpdateOrganizations && this.updateChannelsAndMarkup)) ? (e) => this.handleBookingPalOnClick(e, 'bookingPal') : null}
                        >
                            <strong>
                                {(channel && channel[0] && channel[0].label) ? channel[0].label : row.original.channel} 
                            </strong>
                        </span>
                        &nbsp; {(!init && bookingPal) && this.checkinInstructionsPopover("", this.bookingPalTooltip)}
                    </div>
                )}
            },
            {
                'Header':'Opt In',
                'accessor': 'opt',
                'Cell': row=>{
                    const channelOpt = row.original.opt == "1" ? true : false;

                    return(
                    <div>
                       <Switch 
                            onColor={"#5AD888"} 
                            uncheckedIcon={false} 
                            checkedIcon={false}
                            height={20} 
                            width={45}
                            checked={channelOpt}
                            onChange={bool => this.channelOptInOnChange(bool, row.index)}
                            disabled={row.original.channel === 'redawning' ? true : false}
                        />
                    </div>
                )}
            },
            {
                'Header':'Base Markup',
                'accessor': 'markup',
                'Cell': row=>{
                    return(
                    <div className="placeholder-wrap">
                        <InputField 
                            type="text"  
                            name={row.original.channel}
                            value={(row.original.markup || row.original.markup == "0") ? row.original.markup : "N/A"}
                            disabled={true}
                        />
                        <span className="placeholder">%</span>
                    </div>
                )}
            },
            {
                Header: row=>{
                    return <div>Additional Rent Markup{this.popover("Markup percentage", null, "top", "icon-info")}</div>;
                },
                'accessor': 'percent_markup_rent_addl',
                'Cell': row=>{
                    return(
                        <div className="placeholder-wrap">
                            <InputField 
                                type="text"
                                name={row.original.channel}
                                value={(row.original.percent_markup_rent_addl || row.original.percent_markup_rent_addl == "0") ? row.original.percent_markup_rent_addl : "N/A"}
                                disabled={true}
                            />
                            <span className="placeholder">%</span>
                        </div>
                )}
            },
            {
                Header: row=>{
                    return <div>Additional Fee Markup{this.popover("Markup percentage", null, "top", "icon-info")}</div>;
                },
                'accessor': 'percent_markup_fees_addl',
                'Cell': row=>{

                    return(
                        <div className="placeholder-wrap">
                            <InputField 
                                type="text"
                                name={row.original.channel}
                                value={(row.original.percent_markup_fees_addl || row.original.percent_markup_fees_addl == "0") ? row.original.percent_markup_fees_addl : "N/A"}
                                disabled={true}
                            />
                            <span className="placeholder">%</span>
                        </div>
                )}
            },
            {
                'Header':'Service Fee',
                'accessor': 'svc_fee',
                'Cell': row=>{
                    return(
                        <div className="placeholder-wrap">
                            <InputField 
                                type="text"
                                name={row.original.channel}
                                value={(row.original.svc_fee || row.original.svc_fee == "0") ? row.original.svc_fee : "N/A"}
                                disabled={true}
                            />
                            <span className="placeholder">%</span>
                        </div>
                )}
            },
        ],
        teamMembersColumns: [
            {
                sortable: false,
                'Cell': row=>{
                    const opt_ins = row && row.original && row.original.opt_ins;
                    const subscriber = row && row.original && row.original.subscriber;
                    const display_name = subscriber && row.original.subscriber.display_name;
                    let role = opt_ins && row.original.opt_ins[0] && row.original.opt_ins[0].role_type;

                    if(role == 'guest') {
                        role = 'host';
                    };

                    return(
                        <div>
                            {
                                role ? <div className="team-member-tag">{role[0].charAt(0).toUpperCase() + role.slice(1)}</div> : ''
                            }
                            <div className='bold-text'>{display_name}</div>
                        </div>
                    )
                }
            },
            {
                sortable: false,
                'Cell': row=>{
                    const data =  row && row.original;
                    const message_type_data = data && row.original.subscriber && row.original.subscriber.message_type_data;
                    const email_enabled = message_type_data && row.original.subscriber.message_type_data.email && row.original.subscriber.message_type_data.email.length;
                    const sms_enabled = message_type_data && row.original.subscriber.message_type_data.sms && row.original.subscriber.message_type_data.sms.length;
                    const portal_enabled = message_type_data && row.original.subscriber.message_type_data.portal && row.original.subscriber.message_type_data.portal.length;

                    return(
                        <div>
                            <div className="team-member-tag">Notifications</div>
                            <div>
                                <div className='sbold-text'>
                                    {(email_enabled || sms_enabled || portal_enabled) ? 'Enabled' : 'Disabled'} {" "} | {" "}
                                    <div className="clickable-text" onClick={e => this.initTeamMemberData(e, data)}>Edit</div>
                                </div>
                            </div>
                        </div>
                    )
                }
            },
            {
                sortable: false,
                'Cell': row=>{
                    const data = row && row.original;
                    const opt_ins = data && row.original.opt_ins;
                    let propertyIds = [];

                    if(opt_ins && row.original.opt_ins.length) {
                        opt_ins.forEach((optin) => {
                            if(optin && optin.internal_id) {
                                propertyIds.push(optin.internal_id);
                            };
                        });
                    };

                    const newPropertyIds = [...new Set(propertyIds)];

                    return(
                        <div>
                            <div className="team-member-tag">Responsibilities</div>
                            <div>
                                <div className='sbold-text'>
                                    {!newPropertyIds.length || (this.propertyCount == newPropertyIds.length) ? 'All Properties' : 'Specific Properties'} {" "} | {" "}
                                    <div 
                                        className="clickable-text" 
                                        onClick={e => this.handleSelectedTeamMember(e, data, 'add-tm-property')}
                                    >
                                        Edit
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            },
            {
                sortable: false,
                minWidth: 100,
                'Cell': row=>{
                    const data =  row && row.original;

                    return(
                        <div 
                            className="clickable-text sbold-text" 
                            onClick={e => this.handleSelectedTeamMember(e, data, 'remove-tm')}
                        >
                            Remove
                        </div>
                    )
                }
            }
        ]
    }

    async componentDidMount() {
        const { 
            location, 
            match 
        } = this.props;

        this.setState({
            isLoading: true,
        });

        this.checkPermissions();

        this.addOrgSmartSiteOptions = this.addOrgSmartSiteOptions(_.get(this.props.organizationsTree, "[0]", []));

        this.props.organizations.forEach((org) => {
            let organizationList = {};
            
            organizationList['title'] = org.title ? org.title : org.organization_name;
            organizationList['organization_name'] = org.organization_name;

            if(org.organization_name !== this.state.data.organization_name) {
                this.parentOrgOptions.push(organizationList);
            };
        });

        await this.getIntegrations();

        //** Edit Organization */
        if(match && match.params && match.params.organizationName) {
            this.setState({ 
                init: false,
                mode: "edit",
                pageSize: 10,
            });

            await this.getOrganization();
            await this.getCommHubData();
            await this.getIntegrationStatus();
            await this.getChannelOptInMarkupData();
			await this.getCheckinInstructions();
			await this.getSeasonalPolicies();

            if(this.initCommHubEnabled) {
                await this.getTeamMembers();
                await this.getSubCategories();
                await this.getProperties();
            };

            this.setDataValue(this.state.organization);
        }
        //** Clone Organization */
        else if(location && location.state && location.state.cloneData) {
            this.setState({
                mode: "clone",
            });

            this.setDataValue(location && location.state && location.state.cloneData.organization);
            
            this.setState({
                integrationStatus: location && location.state && location.state.cloneData.integrationStatus,
                channelOptInMarkupData: location && location.state && location.state.cloneData.channelOptInMarkupData,
                checkinInstructionsData: location && location.state && location.state.cloneData.checkinInstructionsData,
            });
        };

        this.updateInitialStateModel();
        
        this.initialize = false;

        this.setState({ 
            isLoading: false
        });
    }
    
    getCommHubData = async () => {
        const { organization } = this.state;
        let resp;

        try {
            resp = await API.get('ramapi',`/commhub/organizations/${organization.organization_name}`);
            if(resp) {
                let usersOptions = [];
                let usersOptionsValue = [];

                if(resp && resp.users && resp.users.length) {
                    resp.users.forEach((user) => {
                        usersOptions.push({
                            label: `${user.display_name} (${user.email})`,
                            value: user.subscriber_id,
                            data: user
                        });
                    });

                    usersOptions.forEach((usersOption) => {
                        if(usersOption.data.selected_for_onboarding == true) {
                            usersOptionsValue.push(usersOption);
                        };
                    })
                };

                this.initCommHubEnabled = resp.commhub_enabled

                this.setState({
                    commHubEnabled: resp.commhub_enabled,
                    commHubData: resp,
                    commHubUsersOptions: usersOptions,
                });
            };
        } 
        catch(e) {
            console.log(e);
        };
    }

    submitEnableCommHub = async () => {
        const { 
            organization,
            commHubData,
            commHubEnabled,
            commHubUsersOptionsValue,
        } = this.state;
        let resp;
        let body = {
            ...commHubData,
            commhub_enabled: commHubEnabled,
            users: [],
        };
        let users = [];
        let userIds = [];

        this.setState({
            enableCommHubErrMsg: '',
            enableCommHubIsLoading: true,
        })

        if(commHubUsersOptionsValue.length) {
            commHubUsersOptionsValue.forEach((option) => {
                userIds.push(option.value);
            });
    
            commHubData.users.forEach((user) => {
                const selected = userIds.includes(user.subscriber_id) ? true : null;
    
                users.push({
                    ...user,
                    selected_for_onboarding: selected 
                });
            });
    
            body.users = users;
        };

        try {
            resp = await API.post('ramapi',`/commhub/organizations/${organization.organization_name}`, { body: body });
            if(resp) {

                await this.getTeamMembers();
                await this.getSubCategories();
                await this.getProperties();

                this.initCommHubEnabled = true;

                this.setState({
                    modal: '',
                    enableCommHubIsLoading: false
                });
            }
        } 
        catch(e) {
            console.log(e);
            this.setState({
                enableCommHubIsLoading: false,
                enableCommHubErrMsg: 'There is an issue enabling Communication Hub. Please contact customer support.'
            })
        };
    }

    getProperties = async () => {
        const { organization } = this.state;
        let resp;
        let limit = 200;
        let offset = 0;
        let newPropertyList = [];

        try {
            resp = await API.get("rapapi", `/promotion/properties?organizations=${organization.organization_name}&offset=${offset}&tid=${this.tid}&limit=${limit}`, { response: true });

            if(resp && resp.data && resp.data.list && resp.data.list.length) {

                this.propertyCount = resp.data.list.length;

                resp.data.list.forEach((property) => {
                    newPropertyList.push({
                        ...property,
                        label: `${property.property_id ? `${property.property_id} -` : ''} ${property.title ? property.title : ''}`
                    })
                });
            };

            this.setState({
                teamMemberPropertiesList: [
                    ...newPropertyList,
                ],
            });
        }
        catch(e) {
            console.log(e);
        };
    }

    getTeamMembers = async () => {
        const { organization } = this.state;
        let resp;

        try {
            resp = await API.get('ramapi',`/commhub/organizations/${organization.organization_name}/subscribers`, { response: true });

            this.setState({
                teamMembers: resp.data,
            });
        } 
        catch(e) {
            console.log(e);
        };
    }

    getSubCategories = async () => {
        let resp;

        try {
            resp = await API.get('ramapi',`/commhub/catsubcats`, { response: true, isCognito: true });

            if(resp && resp.data && resp.data.length) {
                let subCat = resp.data;
                let categories = {};
                let groupSubCatInPairs = {};

                subCat.forEach((x) => {
                    let defaultData = {
                        sms_host: false,
                        email_host: false,
                        portal_host: false,
                        sms_guest: false,
                        email_guest: false,
                        portal_guest: false,
                        sms_schedule: false,
                        email_schedule: false,
                        sms_host_availability: {
                            monday: true,
                            tuesday: true,
                            wednesday: true,
                            thursday: true,
                            friday: true,
                            saturday: true,
                            sunday: true,
                            start: '09:00:00Z',
                            end: '21:00:00Z',
                            timeZone: 'US/Pacific'
                        },
                        email_host_availability: {
                            monday: true,
                            tuesday: true,
                            wednesday: true,
                            thursday: true,
                            friday: true,
                            saturday: true,
                            sunday: true,
                            start: '09:00:00Z',
                            end: '21:00:00Z',
                            timeZone: 'US/Pacific'
                        },
                    };

                    if(x.subcategory !== '_default' && !x.category.includes('*') && !x.subcategory.includes('*')) {
                        if((x.role_type == 'guest')) {
                            if(categories['host'] === undefined) {
                                categories['host'] = [{...x, ...defaultData}];
                            } else {
                                categories['host'].push({...x, ...defaultData});
                            };
                        } else if (categories[x.role_type] === undefined) {
                            categories[x.role_type] = [{...x, ...defaultData}];
                        } else {
                            categories[x.role_type].push({...x, ...defaultData});
                        };
                    };
                });

                function groupObjectsInPairs(array) {
                    const resultArray = [];
                    
                    for (let i = 0; i < array.length; i += 2) {
                      const pair = [array[i], array[i + 1]].filter(Boolean);
                      resultArray.push(pair);
                    };
                  
                    return resultArray;
                };

                Object.entries(categories).forEach((cat) => {
                    let sortedCat = [];

                    sortedCat = cat[1].sort(function(a, b) {
                        const roleA = a.role_type.toLowerCase();
                        const roleB = b.role_type.toLowerCase();
                    
                        if (roleA < roleB) {
                            return -1;
                        } else if (roleA > roleB) {
                            return 1;
                        } else {
                            return 0;
                        };
                    });

                    groupSubCatInPairs[cat[0]] = groupObjectsInPairs(sortedCat);
                });

                let primaryRoles = [];
                Object.entries(categories).forEach((entry) => {
                    primaryRoles.push({
                        key: entry[0].charAt(0).toUpperCase() + entry[0].slice(1),
                        value: entry[0]
                    });
                });

                this.initSubCategories = groupSubCatInPairs;
                this.copySubCategories = groupSubCatInPairs;
                this.initPrimaryRoles = primaryRoles;

                this.setState({
                    subCategories: groupSubCatInPairs
                });
            };
        } 
        catch(e) {
            console.log(e)
            this.setState({
                subcategoryError: true
            });
        };
    }

    duplicateOptins = (originalArray, internalIds) => {
        const duplicatedArray = [];
      
        originalArray.forEach((originalObject) => {
            internalIds.forEach((internalId) => {
                let {subscriber_org_opt_in, organization_id, priority, role_type, thread_id, ...newObj} = originalObject;
                const duplicatedObject = { ...newObj, internal_id: internalId, internal_id_type: 'nid' };
                duplicatedArray.push(duplicatedObject);
            });
        });
      
        return duplicatedArray;
    }

    removeDuplicateOptin(array) {
        let uniqueOptin = [];
        let newOptin = [];
    
        array.forEach(optin => {
            const isDuplicate = uniqueOptin.some(unique_optin => {
                const unique_message_type_data_selectors = unique_optin && unique_optin.message_type_data_selectors && unique_optin.message_type_data_selectors[0];
                const unique_participant_context = unique_optin && unique_optin.context && unique_optin.context.participant_context && unique_optin.context.participant_context[0];
                const unique_category_subcategory_id = unique_optin && unique_optin.category_subcategory_id;
                
                const optin_message_type_data_selectors = optin && optin.message_type_data_selectors && optin.message_type_data_selectors[0];
                const optin_participant_context = optin && optin.context && optin.context.participant_context && optin.context.participant_context[0];
                const optin_category_subcategory_id = optin && optin.category_subcategory_id;

                return (
                    unique_message_type_data_selectors == optin_message_type_data_selectors &&
                    unique_participant_context == optin_participant_context &&
                    unique_category_subcategory_id == optin_category_subcategory_id
                );
            });
    
            if (!isDuplicate) {
                uniqueOptin.push(optin);
            };
        });

        uniqueOptin.forEach((optin) => {
            newOptin.push({ ...optin, internal_id: null, internal_id_type: null });
        });
    
        return newOptin;
    }

    submitTeamMemberProperties = async () => {
        const {
            teamMemberPropertiesList,
            selectedTeamMember,
            teamMemberPropertyOptionsValue,
            organization
        } = this.state;
        let data = {...selectedTeamMember}
        let propertyIds = [];
        let transformedArray = [];

        if(teamMemberPropertyOptionsValue.length !== teamMemberPropertiesList.length) {
            if(teamMemberPropertyOptionsValue.length) {
                teamMemberPropertyOptionsValue.forEach((property) => {
                    propertyIds.push(property.property_id);
                });
            };
        }
        else {
            propertyIds = [];
        };

        const newPropertyIds = [...new Set(propertyIds)];

        if(newPropertyIds.length) {
            const removeDuplicateOptin = this.removeDuplicateOptin(data.opt_ins);
            transformedArray = this.duplicateOptins(removeDuplicateOptin, newPropertyIds);
        }
        else {
            transformedArray = this.removeDuplicateOptin(data.opt_ins);
        };
        
        data.opt_ins = transformedArray;

        let resp;

        this.setState({
            teamMemberPropertiesIsLoading: true,
            teamMemberPropertiesErrorMsg: '',
        })
        
        try {
            resp = await API.put('ramapi',`/commhub/organizations/${organization.organization_name}/subscribers/${selectedTeamMember.subscriber.subscriber_id}`, { response: true, body: data });
            if(resp && resp.data) {
                await this.getTeamMembers();
            };

            this.resetTeamMember();

            this.setState({
                modal: '',
                selectedTeamMember: {},
                teamMemberPropertiesIsLoading: false
            })
        } 
        catch(e) {
            console.log(e);
            this.setState({
                teamMemberPropertiesErrorMsg: 'Oops! We encountered an error. Please contact customer support.',
                teamMemberPropertiesIsLoading: false
            });
        };
    }

    getOrganization = async (newOrganizationNameBool) => {
        try {
            let data = await API.get("accapi", `/organizations/${newOrganizationNameBool ? this.state.data.organization_name : this.props.match.params.organizationName}`, { headers: {"Cache-Control": "must-revalidate"}});

            if(Object.keys(data).length) {
                this.setState({ 
                    organization: data,
                });
            };
        }
        catch(e) {
            console.log(e, "Error GET Organization")
        }
    }

    getIntegrations = async () => {
        try {
            let respIntegrationNames = await API.get("accapi", `/integrations/names`, { response: true });
            let respIntegrationCalendar = await API.get("accapi", `/integrations/calendars`, { response: true });
            let respIntegrationPartner = await API.get("accapi", `/integrations/partners`, { response: true });

            this.setState({
                integrationNames: respIntegrationNames.data,
                integrationCalendar: respIntegrationCalendar.data,
                integrationPartner: respIntegrationPartner.data,
            });
        }
        catch(e) {
            console.log(e, "Error GET Integrations");
        };
    }

    getIntegrationStatus = async () => {
        try {
            let data = await API.get("accapi", `/integrations/organizations/${this.props.match.params.organizationName}/status`);

            if(data.length) {
                this.setState({
                    integrationStatus: data && data[0] && data[0].status && data[0].status.toString(),
                });
            };
        }
        catch(e) {
            console.log(e, "Error GET Status");
        }
    }

    getBookingPalToken = async () => {
        try {
            let data = await API.get("bpalapi", `/organizations/token`);
            if(data && data.iframe_url) {
                this.setState({
                    bookingPalUrl: data.iframe_url
                })
            }
        }
        catch(e) {
            if(e && e.response && e.response.data && e.response.data.Message) {
                let errMsg = e.response.data.Message;
                let newErrMsg = errMsg.substring(
                    errMsg.indexOf("'") + 1, 
                    errMsg.lastIndexOf("'")
                );
                this.setState({
                    bookingPalErrMsg: newErrMsg
                });
            }
            else {
                this.setState({
                    bookingPalErrMsg: "Error loading BookingPal Wizard. Please contact Customer Support."
                });
            }
        };
    }

    getCheckinInstructions = async () => {
        try {
            let data = await API.get("accapi", `/organizations/${this.props.match.params.organizationName}/checkinconfigs`);

            if(data && data.default_metadata.length) {
                
                const { checkinInstructionsData } = this.state;
                let newMetaData = {};

                if(data.default_metadata.length) {

                    data.default_metadata.forEach((metaData) => {

                        newMetaData[metaData.name] = metaData;

                    });
                };

                this.setState({
                    checkinInstructionsData: {
                        ...checkinInstructionsData,
                        ...newMetaData,
                    },
                });
            };
        }
        catch(e) {
            console.log(e, "Error GET Status");
            this.setState({
                checkinInstructionsData: {
                    door_code: {
                        name: "door_code",
                        value: ""
                    }
                }
            });
        }
    }

    getSeasonalPolicies = async () => {
        try {
            let data = await API.get("rapapi", `/organizations/${this.props.match.params.organizationName}/seasonalcancellationpolicy`);

            let seasonalPolicies = [];
            let seasonalPoliciesValidation = [];

            if (data && data.length) {
                data.forEach((policy) => {
                    seasonalPolicies.push({
                        start_date: moment(policy.start_date, 'YYYY-MM-DD'),
                        end_date: moment(policy.end_date, 'YYYY-MM-DD'),
                        policy: policy.policy,
                        active: policy.active,
                        focusedInput: null
                    });
                    seasonalPoliciesValidation.push({
                        dates: false, policy: false
                    });
                });
                this.setState({
                    seasonalPolicies: seasonalPolicies,
                    validations: {
                        ...this.state.validations,
                        seasonalPolicies: seasonalPoliciesValidation
                    }

                });
            }
        } catch (e) {
            console.log(e, "Error GET Seasonal Policy");
            this.setState({
                seasonalPolicies: []
            });
        }
    }

    submitCheckinInstructions = async (org) => {
        if(!isEqual(this.state.initialStateModel.checkinInstructionsData, this.state.checkinInstructionsData)) {
    
            const { organization } = this.state;
            let checkinInstructionsMetaData = [];

            Object.entries(this.state.checkinInstructionsData).forEach((ci) => {
                checkinInstructionsMetaData.push(ci[1]);
            });

            let checkinInstructionsBody = {
                mail_additional_info: null,
                default_metadata: checkinInstructionsMetaData
            };
            
            await API.post("accapi", `/organizations/${org ? org : organization.organization_name}/checkinconfigs`, { body: checkinInstructionsBody })
                .then(resp => {
                    this.setState({ 
                        submitStatus: this.state.submitStatus + 1 
                    });
                })
                .catch(err => {
                    this.setModalError(err && err.response && err.response.data);
                });
        };
    }

    submitSeasonalPolicies = async (org) => {
        if (!isEqual(this.state.initialStateModel.seasonalPolicies, this.state.seasonalPolicies)) {

            const {organization, seasonalPolicies} = this.state;
            let seasonalPoliciesBody = [];

            seasonalPolicies.forEach((policy) => {
                seasonalPoliciesBody.push({
                    start_date: moment(policy.start_date).format('YYYY-MM-DD'),
                    end_date: moment(policy.end_date).format('YYYY-MM-DD'),
                    policy: policy.policy,
                    active: policy.active
                })
            });

            await API.put("rapapi", `/organizations/${org ? org : organization.organization_name}/seasonalcancellationpolicy`, {body: seasonalPoliciesBody})
                .then(resp => {
                    this.setState({
                        submitStatus: this.state.submitStatus + 1
                    });
                })
                .catch(err => {
                    this.setModalError(err && err.response && err.response.data);
                });
        }
    }

    getChannelOptInMarkupData = async () => {
        try {
            let data = await API.get("accapi", `/organizations/${this.props.match.params.organizationName}/channels`);

            if(data.length) {
                const dataCopy = [...data];

                this.setState({
                    activePage: 1,
                    channelOptInMarkupData: dataCopy,
                });
            };
        }
        catch(e) {
            console.log(e, "Error GET Channels");
        }
    }

    clearCache = async (e) => {
        e.preventDefault();

        this.setState({
            altLoading: true
        });

        try {
            await API.del("accapi", `/organizations/${this.state.data.organization_name}/cache`);
            this.setState({
                successClearCache: true,
                activeModal: "clear-cache-modal",
                altLoading: false,
            });
        }catch(e) {
            this.setState({
                successClearCache: false,
                altLoading: false,
            });
        }
    }

    shutdownOrg = async (e) => {
        e.preventDefault();
        const { 
            organization,
            churnData
        } = this.state;

        this.closeActiveModal();

        this.setState({
            altLoading: true
        });

        let parentOrgName = this.props.organizations.filter((org) => {
            return this.state.data.parent === org.title;
        });

        let dataCopy = {
            ...this.state.data,
            id: organization.id,
            api_key: organization.api_key,
            parent_id: organization.parent_id,
            parent: 
                parentOrgName.length ? 
                parentOrgName[0].organization_name : 
                this.state.data.parent,
            property_manager_details: {
                ...this.state.data.property_manager_details,
                account_status: "Canceled",
                churn_date: churnData.churn_date,
                churn_reason: churnData.churn_reason,
                churn_detail: churnData.churn_detail,
            }
        };
        
        try {
            let resp = await API.patch("accapi", `/organizations/${organization.organization_name}`, { "body": dataCopy });

            this.setState({
                data: resp,
                altLoading: false,
            }, () => {
                this.showActiveModal('shutdown-org-successful');
                this.updateInitialStateModel();
            });
        }
        catch(e) {
            this.setState({
                altLoading: false,
            });
        };
    }

    setDataValue = (data) => {
        const organization = data;
        const propertyManagerDetails = organization && organization.property_manager_details;

        // Set organization parent value to use organization_title
        let parentOrgName = [];

        if(_.get(data, "parent.length")) {
            parentOrgName = this.props.organizations.filter((org) => {
                return data.parent === org.organization_name;
            });
        };

        // Integration Fees
        const propertyManagerDetail = data.property_manager_details ? Object.entries(data.property_manager_details) : [];
        const integrationFeesNames = [
            "misc_rentalfee",
            "misc_cleanfee",
            "misc_tax",
            "misc_nightlytax",
            "misc_secdeposit"
        ];
        let integrationFees = [];
        let count = 0;

        for(let i = 0; i < propertyManagerDetail.length; i++) {
            if(integrationFeesNames.includes(propertyManagerDetail[i][0]) && propertyManagerDetail[i][1]) {

                // Create custom object for each integration fees.
                integrationFees.push({ name: propertyManagerDetail[i][0], value: propertyManagerDetail[i][1]});

                // Create validation for each integration fees. (only runs when the component first loads)
                if (this.initialize) this.state.validations.integrationFees.push({ index: count, radio: false, text: false });

                // Count for integration fees index.
                count++
            };
        };

        // Split rental_policy.
        let commission = "0";
        let creditCardFee = "0";

        if(_.get(data, "property_manager_details.rental_policy")) {
            if(data.property_manager_details.rental_policy.split("|")[0]) {
                if(data.property_manager_details.rental_policy.split("|")[0].includes('%')) {
                    commission = data.property_manager_details.rental_policy.split("|")[0].trim().split('%')[0];
                };
            };

            if(data.property_manager_details.rental_policy.split("|")[1]) {
                if(data.property_manager_details.rental_policy.split("|")[1].includes('%')) {
                    creditCardFee = Math.round(data.property_manager_details.rental_policy.split("|")[1].trim().split('%')[0]);
                };
            };
        };
        let state = {
            data: {
                ...this.state.data,
                manager_uid: _.get(data, "manager_uid", ""),
                organization_name: 
                    (this.state.mode !== "clone") ?
                    (_.get(data, "organization_name", "")) : "",
                title: 
                    (this.state.mode !== "clone") ?
                    (_.get(data, "title", "")) : "",
                contact_email: _.get(data, "contact_email", ""),
                parent:
                    (parentOrgName &&
                    parentOrgName[0] && 
                    parentOrgName[0].title ? 
                        parentOrgName[0].title : 
                        (parentOrgName[0].organization_name) || ""),
                ra_collect: _.get(data, "ra_collect", ""),
                property_manager_details: {
                    ...this.state.data.property_manager_details,
                    rental_policy: "",
                    auto_publish: _.get(data, "property_manager_details.auto_publish", "") || "",
                    share_contact_status: _.get(data, "property_manager_details.share_contact_status", "") || "",
                    booking_lead_hours: _.get(data, "property_manager_details.booking_lead_hours", "") || "",
                    block_calendar: _.get(data, "property_manager_details.block_calendar", "") || "",
                    turnover_block: _.get(data, "property_manager_details.turnover_block", "") || "",
                    calendar_format: _.get(data, "property_manager_details.calendar_format", "") || "",
                    integration_partner: _.get(data, "property_manager_details.integration_partner", "") || "",
                    integration_name: _.get(data, "property_manager_details.integration_name", "") || "",
                    integration_client_code: _.get(data, "property_manager_details.integration_client_code", "") || "",
                    account_status: _.get(data, "property_manager_details.account_status", "Delisted") || "Delisted",
                    damage_waiver: _.get(data, "property_manager_details.damage_waiver", "").toString() || "",
                    has_cleaning_fee: _.get(data, "property_manager_details.has_cleaning_fee") == "true" ? "1" : "0",
                    has_tax: _.get(data, "property_manager_details.has_tax") == "true" ? "1" : "0",
                    misc_rentalfee: _.get(data, "property_manager_details.misc_rentalfee", "") || "",
                    misc_cleanfee: _.get(data, "property_manager_details.misc_cleanfee", "") || "",
                    misc_tax: _.get(data, "property_manager_details.misc_tax", "") || "",
                    misc_nightlytax: _.get(data, "property_manager_details.misc_nightlytax", "") || "",
                    misc_secdeposit: _.get(data, "property_manager_details.misc_secdeposit", "") || "",
                    phone_office: _.get(data, "property_manager_details.phone_office", "") || "",
                    free_cancel_info: _.get(data, "property_manager_details.free_cancel_info", "") || "",
                    free_cancel_policy: _.get(data, "property_manager_details.free_cancel_policy", "") || "",
                    full_service: _.get(data, "property_manager_details.full_service", "") || "",
                    guest_involvement: _.get(data, "property_manager_details.guest_involvement", "local_partner") || "local_partner",
                    use_commissions_for_additional_fees: _.get(data, "property_manager_details.use_commissions_for_additional_fees", "0") || "0",                       
                    rental_policy: _.get(data, "property_manager_details.rental_policy", ""),
                    show_channel_source:_.get(data, "property_manager_details.show_channel_source", "") || "0",
                    payout_period_details:  _.get(data, 'property_manager_details.payout_period_details')
                }
            },
            integrationFees: integrationFees,
            image: (this.state.mode !== "clone") ? _.get(data, "logo_image.url", {}) : {},
            newImage: {},
            intRentalPolicy: {
                commission: commission,
                creditCardFee: creditCardFee
            },
            editRentalPolicies: false,
            enablePeriodicPayouts: _.get(data, 'property_manager_details.payout_period_details.periodic_payouts') || "0",
            reviewsRatingsOptionsValue: [],
        };

        if(_.get(data, "property_manager_details.review_config")) {
            let levels = [];

            if(_.get(data, "property_manager_details.review_config.auto_reply_review_rating_levels.length")) {
                data.property_manager_details.review_config.auto_reply_review_rating_levels.forEach((level) => {
                    this.reviewsRatingsOptions.forEach((option) => {
                        if(level == option.value) {
                            levels.push(option);
                        };
                    });
                });

                state.reviewsRatingsOptionsValue = levels;
            };

            state.data.property_manager_details.review_config = {
                auto_reply_delay_mins: _.get(data, "property_manager_details.review_config.auto_reply_delay_mins", "") || "",
                auto_reply_review_rating_levels: _.get(data, "property_manager_details.review_config.auto_reply_review_rating_levels"),
                host_managed: _.get(data, "property_manager_details.review_config.host_managed", "0") || "0",
            };
        };

        if(_.get(data, "property_manager_details.smartsite_additional_uids")) {
            let options = [];

            this.addOrgSmartSiteOptions.forEach((option) => {
                if(data.property_manager_details.smartsite_additional_uids.includes(_.get(option, 'uid', '').toString())) {
                    options.push(option)
                }
            });

            state.addOrgSmartSiteOptionsValue = options;
        };

        this.setState(state);
    }

    updateInitialStateModel = () => {
        this.setState({
            initialStateModel: {
                ...this.state.initialStateModel,
                data: {...this.state.data},
                image: {...this.state.image},
                newImage: {...this.state.newImage},
                integrationFees: [...this.state.integrationFees],
                integrationStatus: this.state.integrationStatus,
                selectedOrganization: {...this.state.selectedOrganization},
                checkinInstructionsData: {...this.state.checkinInstructionsData},
                channelOptInMarkupData: [...this.state.channelOptInMarkupData],
                seasonalPolicies: [...this.state.seasonalPolicies],
                enablePeriodicPayouts: this.state.enablePeriodicPayouts,
            }
        });
    }

    compareState = () => {
        let unsavedData = false;
        const stateModel = {
            data: {...this.state.data},
            image: {...this.state.image},
            newImage: {...this.state.newImage},
            integrationFees: [...this.state.integrationFees],
            seasonalPolicies: [...this.state.seasonalPolicies],
            integrationStatus: this.state.integrationStatus,
            selectedOrganization: {...this.state.selectedOrganization},
            checkinInstructionsData: {...this.state.checkinInstructionsData},
            channelOptInMarkupData: [...this.state.channelOptInMarkupData],
            enablePeriodicPayouts: this.state.enablePeriodicPayouts,
        };

        unsavedData = !isEqual(this.state.initialStateModel, stateModel);

		this.props.updateUnsavedData(unsavedData);

        return unsavedData;
    }

    manipulateDataOnSubmit = () => {
        const { 
            mode,
            data,
            integrationFees,
            editRentalPolicies,
            enablePeriodicPayouts
         } = this.state;

        //** Organization Data */

        // Remove leading and trailing spaces.
        data.organization_name = data.organization_name.replace(/^\-+|[^\w-]|\-+$/g,'').toLowerCase().trim();
        data.contact_email = data.contact_email.trim();
        
        // Change value if integration_name is racalendar.
        if(data.property_manager_details.integration_name === "racalendar") {
            data.property_manager_details.integration_partner = "";
            data.property_manager_details.integration_client_code = "";
        };

        // Change num value to bool value.
        data.property_manager_details.has_cleaning_fee =
            data.property_manager_details.has_cleaning_fee == "1" ? true : 
            (data.property_manager_details.has_cleaning_fee == "0" ? false : "");
        data.property_manager_details.has_tax =
            data.property_manager_details.has_tax == "1" ? true : 
            (data.property_manager_details.has_tax == "0" ? false : "");

        // Change string value to num value.
        data.property_manager_details.share_contact_status = 
            parseInt(data.property_manager_details.share_contact_status);
        data.property_manager_details.booking_lead_hours = 
            parseInt(data.property_manager_details.booking_lead_hours);
        data.property_manager_details.block_calendar = 
            parseInt(data.property_manager_details.block_calendar);
        
        if(_.get(data, "property_manager_details.smartsite_additional_uids")) {
            data.property_manager_details.smartsite_additional_uids = data.property_manager_details.smartsite_additional_uids.map(Number);
        };

        if(data.property_manager_details.booking_lead_hours == "" || isNaN(data.property_manager_details.booking_lead_hours)) {
            data.property_manager_details.booking_lead_hours = "0";
        };

        //** Integration Fees */

        // Revert Integration Fees data back to original state.
        for(let i = 0; i < integrationFees.length; i ++) {
            data.property_manager_details[integrationFees[i].name] = integrationFees[i].value.trim().replace(/^,/,'').replace(/,$/,'');
        };

        // Set organization parent value to use organization_name
        let parentOrgName = [];

        if(data && data.parent.length) {
            parentOrgName = this.props.organizations.filter((org) => {
                return data.parent === org.title;
            });
            data.parent = parentOrgName.length ? parentOrgName[0].organization_name : data.parent;
        };

        // Rental Policy.
        if(mode !== 'create' && editRentalPolicies === false) {
            delete data.property_manager_details.rental_policy;
        };

        // Remove manager_uid when cloning.
        if(mode == 'clone') {
            delete data.manager_uid;
        };

        if(enablePeriodicPayouts == '1' || _.get(data, 'property_manager_details.payout_period_details')) {
            const periodicPayouts = enablePeriodicPayouts == '1' ? true : null;

            if(_.get(data, 'property_manager_details.payout_period_details')) {
                data.property_manager_details.payout_period_details = {
                    ...data.property_manager_details.payout_period_details,
                    periodic_payouts: periodicPayouts
                };
            }
            else {
                data.property_manager_details.payout_period_details = {
                    payout_day: 0,
                    payout_period_start: 0,
                    payout_period_end: 0,
                    allow_negative_balance_rollover: true,
                    invoice_due_days: null,
                    periodic_payouts: periodicPayouts,
                    periodic_credits: true,
                    host_periodic_payout_statement: {
                        suppress: false
                    }
                };
            };
        };
    }

    checkPermissions = () => {
        if(_.isArray(this.props.permissions)) {
            if(this.props.permissions.includes('edit_org_smartsite_add_uids')) {
                this.editOrgSmartsiteAddUids = true;
            };
            if(this.props.permissions.includes('create_organization')) {
                this.createUpdateOrganizations = true;
            };
            if(this.props.permissions.includes('get_organization_channels_and_markup')) {
                this.showChannelsAndMarkup = true;
            };
            if(this.props.permissions.includes('create_organization_channels_and_markup')) {
                this.createChannelsAndMarkup = true;
            };
            if(this.props.permissions.includes('update_organization_channels_and_markup')) {
                this.updateChannelsAndMarkup = true;
            };
        };
    }

    handleFormSubmit = async () => {
        const { 
            data,
            mode,
            newImage,
            organization, 
            validations,
            integrationStatus,
            channelOptInMarkupData,
            initialStateModel,
            integrationFees,
            selectedOrganization,
            checkinInstructionsData,
            seasonalPolicies,
            enablePeriodicPayouts
        } = this.state;

        //** Submit Total Count */

        // + 1 for Updating Data
        let submitModalTotal = 1;

        if(mode === "edit") {
            // Organization
            if(!isEqual(initialStateModel.data, data) || 
                !isEqual(initialStateModel.integrationFees, integrationFees || 
                !isEqual(initialStateModel.selectedOrganization, selectedOrganization)) ||
                !isEqual(initialStateModel.enablePeriodicPayouts, enablePeriodicPayouts)
            ) {
                submitModalTotal ++;
            };
            // Channels and Markup
            if(!isEqual(initialStateModel.channelOptInMarkupData, channelOptInMarkupData)) {
                submitModalTotal ++;
            };
            // Integration Status
            if(!isEqual(initialStateModel.integrationStatus, integrationStatus) && data.property_manager_details.integration_name !== "racalendar") {
                submitModalTotal ++;
            };
            // Image
            if(!isEqual(initialStateModel.newImage, newImage)) {
                submitModalTotal ++;
            };
            // Checkin Instructions
            if(!isEqual(initialStateModel.checkinInstructionsData, checkinInstructionsData)) {
                submitModalTotal ++;
            };
            // Seasonal Cancellation Policy
            if (!isEqual(initialStateModel.seasonalPolicies, seasonalPolicies)) {
                submitModalTotal++;
            }
        }
        else {
            // Organization
            submitModalTotal = 2;

            // Integration Status
            if(data.property_manager_details.integration_name !== "racalendar") {
                submitModalTotal ++;
            };
            // Image
            if(!isEqual(initialStateModel.newImage, newImage)) {
                submitModalTotal ++;
            };
            // Checkin Instructions
            if(!isEqual(initialStateModel.checkinInstructionsData, checkinInstructionsData)) {
                submitModalTotal ++;
            };
            // Seasonal Cancellation Policy
            if (!isEqual(initialStateModel.seasonalPolicies, seasonalPolicies)) {
                submitModalTotal++;
            }
        };

        //** Validations */

        const requiredValidations = [
            { name: "organization_name" },
            { name: "title" },
            { name: "parent" },
            { name: "contact_email" },
            { name: "integration_name" },
            { name: "calendar_format" },
            { name: "integration_client_code" },
            { name: "duplicateIntegrationFees" },
            { name: "damage_waiver" },
        ];

        // Add Integration Fees to required validations
        for(let i = 0; i < validations.integrationFees.length; i++) {
            requiredValidations.push(
                { name:"integrationFees", index: validations.integrationFees[i].index }
            );
        };

        // Add Seasonal Policy to required validations
        for (let i = 0; i < validations.seasonalPolicies.length; i++) {
            requiredValidations.push(
                {name: "seasonalPolicies", index: i}
            );
        }
        
        // Remove validations that is not required when editing an organization and if the status is delisted.
        if(mode === "edit" && data.property_manager_details.account_status === "Delisted") {
            const valName = [
                "organization_name",
                "title",
                "parent",
                "contact_email",
                "integration_name",
                "calendar_format",
                "integration_client_code",
                "damage_waiver"
            ];

            let requiredValLength = requiredValidations.length - 1;
            for(let i = requiredValLength; i > 0; i--) {
                if(valName.includes(requiredValidations[i].name)) {
                    requiredValidations.splice(i, 1);
                };
            };
        };

        const newValidation = this.validationHandler(requiredValidations);

        this.setState({
            validations: newValidation
        }, async () => {
            const { 
                validations,
                initialStateModel,
            } = this.state;
            const checkValidations = Object.entries(validations).filter((notValid) => notValid[1] === true);
            const integrationFeesValidations = validations.integrationFees.filter((val) => (val.radio === true || val.text === true));
            const seasonalPoliciesValidations = validations.seasonalPolicies.filter((val) => (val.dates === true || val.policy === true));
            const combinedValidations = [...checkValidations, ...integrationFeesValidations, ...seasonalPoliciesValidations];

            if(!combinedValidations.length) {
                this.setState({ 
                    submitModalTotal: submitModalTotal,
                    progressMessage: (this.state.mode === "edit") ? "Updating..." : "Saving..."
                });

                this.showModal("submit-modal");

                //** Edit Organization */ 

                let organizationError = false;

                if(this.state.mode === "edit") {
                    if(!isEqual(this.state.initialStateModel.data, this.state.data) || 
                    !isEqual(this.state.initialStateModel.integrationFees, this.state.integrationFees) || 
                    !isEqual(this.state.initialStateModel.selectedOrganization, this.state.selectedOrganization) || 
                    !isEqual(this.state.initialStateModel.enablePeriodicPayouts, this.state.enablePeriodicPayouts)) {
                        // Manipulate data to match API requirements.
                        data["id"] = organization.id;
                        data["api_key"] = organization.api_key;
                        data["parent_id"] = organization.parent_id;
                        this.manipulateDataOnSubmit();

                        await API.patch("accapi", `/organizations/${organization.organization_name}`, { "body": data })
                            .then(resp => {
                                this.setState({ 
                                    submitStatus: this.state.submitStatus + 1 
                                });
                            })
                            .catch(err => {
                                organizationError = true;
                                this.setModalError(err && err.response && err.response.data);
                            });
                    };
                    // Checkin Instructions 
                    if(!isEqual(initialStateModel.checkinInstructionsData, checkinInstructionsData)) {
                        await this.submitCheckinInstructions();
                    };

                    // Seasonal Cancellation Policy
                    if (!isEqual(initialStateModel.seasonalPolicies, seasonalPolicies)) {
                        await this.submitSeasonalPolicies();
                    }

                    // Integration Status
                    if(!isEqual(this.state.initialStateModel.integrationStatus, this.state.integrationStatus) && this.state.data.property_manager_details.integration_name !== "racalendar") {
                        await API.put("accapi", `/integrations/organizations/${organization.organization_name}/status`, { body: { status: parseInt(this.state.integrationStatus) } })
                            .then(resp => {
                                this.setState({ 
                                    submitStatus: this.state.submitStatus + 1 
                                });
                            })
                            .catch(err => {
                                this.setModalError(err && err.response && err.response.data);
                            });
                    };

                    // Channels & Markup
                    if(!isEqual(initialStateModel.channelOptInMarkupData, channelOptInMarkupData)) {
                        const channelslength = channelOptInMarkupData.length;

                        for(let i = 0; i < channelslength; i++ ) {
                            parseInt(channelOptInMarkupData[i].opt);
                            parseInt(channelOptInMarkupData[i].markup);
                        };
                        
                        await API.put("accapi", `/organizations/${organization.organization_name}/channels`, { body: channelOptInMarkupData })
                            .then(resp => {
                                this.setState({ 
                                    submitStatus: this.state.submitStatus + 1 
                                });
                            })
                            .catch(err => {
                                this.setModalError(err && err.response && err.response.data);
                            });
                    };
  
                    // Upload Image
                    if(!isEqual(this.state.initialStateModel.newImage, this.state.newImage)) {
                        await this.saveImage(organization.organization_name);
                    };

                    //** Update New Data */ 

                    if(!isEqual(this.state.initialStateModel.data, this.state.data) || 
                        !isEqual(this.state.initialStateModel.integrationFees, this.state.integrationFees) || 
                        !isEqual(this.state.initialStateModel.newImage, this.state.newImage) || 
                        !isEqual(this.state.initialStateModel.selectedOrganization, this.state.selectedOrganization)) {

                        // Organization_name change
                        if(!isEqual(this.state.initialStateModel.data.organization_name, this.state.data.organization_name) && !organizationError) {
                            await this.getOrganization(true);
                        }
                        else {
                            await this.getOrganization();
                        };
                        this.setDataValue(this.state.organization);
                    };

                    if(!isEqual(this.state.initialStateModel.integrationStatus, this.state.integrationStatus) && 
                        this.state.data.property_manager_details.integration_name !== "racalendar") {
                        await this.getIntegrationStatus();
                    };

                    if(!isEqual(initialStateModel.channelOptInMarkupData, channelOptInMarkupData)) {
                        await this.getChannelOptInMarkupData();
                    };

                    if(!isEqual(initialStateModel.checkinInstructionsData, checkinInstructionsData)) {
                        await this.getCheckinInstructions();
                    };

                    this.updateInitialStateModel();

                    this.setState({
                        submitStatus: this.state.submitStatus + 1,
                        progressMessage: "Organization Saved"
                    });
                }
                else {
                    //** Create Organization */

                    // Manipulate data to match API requirements.
                    this.manipulateDataOnSubmit();

                    await API.post("accapi", "/organizations", { "body": data })
                        .then(async orgResp =>  {
                            this.setState({ 
                                submitStatus: this.state.submitStatus + 1 
                            });
                            // Integration Status
                            if(this.state.data.property_manager_details.integration_name !== "racalendar") {
                                await API.put("accapi", `/integrations/organizations/${orgResp.organization_name}/status`, { body: { status: parseInt(this.state.integrationStatus) } })
                                    .then(resp => {
                                        this.setState({ 
                                            submitStatus: this.state.submitStatus + 1 
                                        });
                                    })
                                    .catch(err => {
                                        this.setModalError(err && err.response && err.response.data);
                                    });
                            };

                            // Checkin Instructions 
                            await this.submitCheckinInstructions(orgResp.organization_name);

                            // Seasonal Cancellation Policy
                            await this.submitSeasonalPolicies(orgResp.organization_name);

                            // Channels & Markup
                            const channelslength = channelOptInMarkupData.length;

                            for(let i = 0; i < channelslength; i++ ) {
                                parseInt(channelOptInMarkupData[i].opt);
                                parseInt(channelOptInMarkupData[i].markup);
                            };
                            
                            await API.put("accapi", `/organizations/${orgResp.organization_name}/channels`, { body: channelOptInMarkupData })
                                .then(resp => {
                                    this.setState({ 
                                        submitStatus: this.state.submitStatus + 1 
                                    });
                                })
                                .catch(err => {
                                    this.setModalError(err && err.response && err.response.data);
                                });

                            // Upload Image
                            if(!isEqual(this.state.initialStateModel.newImage, this.state.newImage)) {
                                await this.saveImage(orgResp.organization_name);
                            };

                            this.updateInitialStateModel();

                            this.setState({
                                progressMessage: "Organization Saved"
                            });
                        })
                        .catch(err => {
                            this.setModalError(err && err.response && err.response.data);
                        });
                };
            }
            else {
                if(combinedValidations.length) {
                    this.scrollToValidation();
                };  
            };
        });      
    }

    saveImage = async (orgName) => {
        const { newImage } = this.state;
        let tokenList = [];

        try {
            if(newImage) {
                let dataType = newImage.data_type;
                let token = await API.post('accapi',`/organizations/${orgName}/imagetokens`,{
                    body: { image_type : "logo"}
                });
                if(token && token.token_id) {
                    let upload_url = token.upload_url.split("s3://")[1];
                    let bucket = upload_url.substr(0, upload_url.indexOf("/"));
                    let id = upload_url.substring(upload_url.indexOf("/") + 1, upload_url.lastIndexOf("/"));

                    Storage.configure({
                        bucket: bucket,
                        identityPoolId: Constants.COGNITO.IdentityPoolId,
                        region: Constants.COGNITO.Region
                    });

                    try {
                        await Storage.put(`${token.token_id}`, newImage.file, {
                            contentType: dataType,
                            customPrefix: {
                                public: `${id}/public/`,
                            }
                        });

                        tokenList.push({ dataType, id: token.token_id });
                    }
                    catch(e) {
                        const reader = new FileReader();

                        reader.addEventListener("loadend", () => {
                            let result = reader.result;
                            let errorMessage = result.includes("RequestTimeTooSkewed") ?
                                "Image upload failed. The difference between the request time and the current time is too large." :
                                result;
                            let detail = [...this.state.modalError.detail, errorMessage];
                            
                            if(result) {
                                this.setState({
                                    submitStatus: this.state.submitStatus + 1,
                                    modalError: {
                                        ...this.state.modalError,
                                        show: true,
                                        detail: detail
                                    }
                                });
                            };
                        });
                        reader.readAsText(e && e.response && e.response.data);
                    };
                } 
                else {
                    throw new Error();
                }
            }
        }
        catch(e) {
            console.log(e, "Error Uploading Logo Image");
        }

        for(let i=0;i<tokenList.length;i++) {
            let uploadComplete = false;

            while(!uploadComplete) {
                try {
                    let bucketInfo = await API.get("accapi",`/organizations/${orgName}/imagetokens/${tokenList[i].id}`);
                    if(bucketInfo && bucketInfo[0] && bucketInfo[0].status == "completed" || bucketInfo && bucketInfo[0] && bucketInfo[0].status == "error") {
                        uploadComplete = true;
                        this.setState({
                            submitStatus: this.state.submitStatus + 1 
                        });
                    };
                } 
                catch(e) {
                    console.log(e);
                    uploadComplete = true;
                    this.setState({
                        submitStatus: this.state.submitStatus + 1 
                    });
                }
                if(!uploadComplete) {
                    await delay(2000);
                };
            };
        };
    }

    updateImage = async (file) => {
		if(!file.length) return;

        const { newImage } = this.state;

        const reader = new FileReader();
		reader.onload = ((e) => {
			this.setState({
                newImage: {
                    ...newImage,
                    data: e.target.result.split(',')[1],
                    data_type: file[0].type,
                    file: file[0]
                }
            });
		});
        reader.readAsDataURL(file[0]);
	}


    handleSubmitErrorMessage = (error) => {
        let errorMessage = "";

        if(error && error.includes('already exists')) {
            errorMessage = "Error Saving Organization. Organization with this name already exists.";
        }
        else {
            errorMessage = "Error Saving Organization. Please contact Customer Support.";
        }
        return errorMessage;
    }

    channelOptInOnChange = (bool, index) => {
        const {
            channelOptInMarkupData,
        } = this.state;
        let channelOptInMarkupDataCopy = [...channelOptInMarkupData];
        const boolToNum = bool == true ? "1" : "0";

        channelOptInMarkupDataCopy.splice(index, 1, {...channelOptInMarkupDataCopy[index], opt: boolToNum });

        this.setState({
            channelOptInMarkupData: channelOptInMarkupDataCopy
        });
    }

    handleCheckinInstructionsChange = (e) => {
        const { 
            checkinInstructionsData 
        } = this.state;
		const name = e.target.name;
        const value = e.target.value;

        this.setState({
            checkinInstructionsData: {
                ...checkinInstructionsData,
                [name]: {
                    ...checkinInstructionsData[name],
                    value: value
                }
            }
        });
    }
    
    handleDataChange = (e) => {
        const { data, validations } = this.state;
		const name = e.target.name;
        let value = e.target.value;
        const propertyManagerDetails = [
            "calendar_format",
            "integration_partner",
            "integration_name",
            "integration_client_code",
            "account_status",
            "damage_waiver",
            "has_cleaning_fee",
            "has_tax",
            "share_contact_status",
            "booking_lead_hours",
            "block_calendar",
            "auto_publish",
            "phone_office",
            "free_cancel_info",
            "free_cancel_policy",
            "rental_policy",
            "full_service",
            "guest_involvement",
            "turnover_block",
            "use_commissions_for_additional_fees",
            "show_channel_source",
        ];

        if(name === "booking_lead_hours") {
			if(value % 1 != 0 || value < 0) {
				value = '0'
			};
			if(value > 380) {
				value = '380'
			};
		};
        
        if ((this.state.mode !== "edit") && name === "title") {
            this.setState({ 
                data: 
                    {...this.state.data,
                        [name]: value,
                        organization_name: value.replace(/^\-+|[^\w-]|\-+$/g,'').toLowerCase().trim(),
                    }
            }, () => {
                if(validations[name] == true) {
                    const requiredValidation = [
                        { name: "title" },
                        { name: "organization_name" },
                    ];
                    const newValidation = this.validationHandler(requiredValidation);

                    this.setState({
                        validations: newValidation
                    });
                };
            });
        }
        else if(name === "organization_name") {
            this.setState({ 
                data: 
                    {...this.state.data,
                        [name]: value.replace(/^\-+|[^\w-]|\-+$/g,'').toLowerCase().trim(),
                    }
            }, () => {
                if(validations[name] == true) {
                    const newValidation = this.validationHandler([{ name: name }]);

                    this.setState({
                        validations: newValidation
                    });
                };
            });
        }
        else if (name === "contact_email") {
            this.setState({ 
                data: {...data, [name]: value.toLowerCase()}
            }, () => {
                if(validations[name] == true) {
                    const newValidation = this.validationHandler([{ name: name }]);

                    this.setState({
                        validations: newValidation
                    });
                };
            });
        }
        else if (propertyManagerDetails.includes(name)) {
            if(name === 'rental_policy') {
                this.setState({
                    editRentalPolicies: true,
                    rentalPolicy: {
                        ...this.state.rentalPolicy,
                        commission: value.split("|")[0].trim().split('%')[0],
                        creditCardFee: Math.round(value.split("|")[1].trim().split('%')[0]),
                    }
                });
            }

            this.setState({ 
                data: 
                    {...data, property_manager_details: 
                        {...this.state.data.property_manager_details, 
                            [name]: value}}
            },  () => {
                if(validations[name] == true && name !== "integration_partner") {
                    const newValidation = this.validationHandler([{ name: name }]);

                    this.setState({
                        validations: newValidation
                    });
                };
            });
        }
        else {
            this.setState({ 
                data: {...data, [name]: value}
            }, () => {
                if(validations[name] == true) {
                    const newValidation = this.validationHandler([{ name: name }]);

                    this.setState({
                        validations: newValidation
                    }); 
                };
            });
        }
    }

    handleStateChange = (e) => {
		const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        }, () => {
            if(this.state.validations[name] == true) {
                const newValidation = this.validationHandler([{ name: name }]);

                this.setState({
                    validations: newValidation
                });
            };
        });
    }

    handleReviewsConfig = (e) => {
        const { data } = this.state;
        const name = e.target.name;
        let value = e.target.value;
        let config = {};

        if(name == "host_managed") {
            value == '1' ? true : false;
        };

        if(_.get(data, "property_manager_details.review_config")) {
            config = {
                ...data.property_manager_details.review_config,
                [name]: value
            };
        }
        else {
            config = {
                auto_reply_delay_mins: name == "auto_reply_delay_mins" ? value : "",
                auto_reply_review_rating_levels: [],
                host_managed: name == "host_managed" ? value : false,
            };
        };

        this.setState({
            data: {
                ...data,
                property_manager_details: {
                    ...data.property_manager_details,
                    review_config: config
                }
            }
        })
    }

    handleOrgParentChange = (value) => {
        const { data } = this.state;
        
        this.setState({ 
            selectedOrganization: value, 
            data: {
                ...data, 
                parent: value.organization_name 
            }
        }, () => {
            const { data, mode, selectedOrganization } = this.state;
            const newValidation = this.validationHandler([{ name: "parent" }]);

            this.setState({
                validations: newValidation
            });
            
            if(mode === "create" && selectedOrganization.organization_name === "propertyowners") {
                this.setState({
                    data: {
                        ...data,
                        property_manager_details : {
                            ...data.property_manager_details,
                            share_contact_status: "1"
                        }
                    }
                });
            }
            else if(mode === "create" && selectedOrganization.organization_name === "propertymanagers") {
                this.setState({
                    data: {
                        ...data,
                        property_manager_details : {
                            ...data.property_manager_details,
                            share_contact_status: "0"
                        }
                    }
                });
            }
            else if(mode === "create" && (selectedOrganization.organization_name !== "propertyowners" || selectedOrganization.organization_name !== "propertymanagers")) {
                this.setState({
                    data: {
                        ...data,
                        property_manager_details : {
                            ...data.property_manager_details,
                            share_contact_status: ""
                        }
                    }
                });
            }
        });
    }

    handleIntegrationFees = (e, index) => {
        const value = e.target.value;
        const type = e.target.type;
        let data = [...this.state.integrationFees];

        if(type === "radio") {
            data.splice(index, 1, {...data[index], name: value });
        }
        else if(type === "textarea") {
            data.splice(index, 1, {...data[index], value: value });
        };
        
        this.setState({
            integrationFees: data
        }, () => {
            const { validations } = this.state;
            const requiredValidation = [
                { name: "duplicateIntegrationFees" }, 
                { name: "integrationFees", index: index }
            ];

            if(type === "textarea") {
                if(validations.integrationFees[index].text == true || validations.duplicateIntegrationFees == true) {
                    const newValidation = this.validationHandler(requiredValidation);
        
                    this.setState({
                        validations: newValidation
                    });
                };       
            }
            else {
                if(validations.integrationFees[index].radio == true || validations.duplicateIntegrationFees == true) {
                    const newValidation = this.validationHandler(requiredValidation);
    
                    this.setState({
                        validations: newValidation
                    });
                };
            };
        });
    }

    routeToUserOrProperties = (e, name) => {
        e.preventDefault();
        let organizationName = this.state.organization.organization_name;
        let organizationTitle = this.state.organization.title;
        let organizationId = this.state.organization.organization_id;

        this.props.updateOrganization(organizationName);
        this.props.updateOrganizationTitle(organizationTitle ? organizationTitle : organizationName);
        this.props.updateOrganizationId(organizationId);

        sessionStorage.setItem('org', organizationName);
        sessionStorage.setItem('orgTitle', organizationTitle ? organizationTitle : organizationName);
        sessionStorage.setItem('orgId', organizationId);

        if(name === "user") {
            this.props.history.push("/account/users");
        }
        else if (name === "properties") {
            this.props.history.push("/properties");
        }     
    }

    validationHandler = (configs) => {
        const { data, selectedOrganization, integrationFees , seasonalPolicies} = this.state;
        const dataField = [
            "organization_name",
            "title",
            "parent",
            "contact_email",
        ];
        const propertyManagerDetailField = [
            "integration_name",
            "integration_client_code",
            "calendar_format",
            "damage_waiver",
        ]
        const validations = JSON.parse(JSON.stringify(this.state.validations));

        configs.forEach((config) => {
            if(dataField.includes(config.name)) {
                if(config.name === "parent") {
                    if((data.parent.length === 0) && (Object.keys(selectedOrganization).length === 0)) {
                        validations[config.name] = true;
                    }
                    else {
                        validations[config.name] = false;
                    };
                }
                else {
                    if(!data[config.name].length) {
                        validations[config.name] = true;
                    }
                    else {
                        validations[config.name] = false;
                    };
                }
            }
            else if(propertyManagerDetailField.includes(config.name)) {
                if(config.name === "integration_name" || config.name === "integration_client_code") {
                    if(config.name === "integration_name" && data.property_manager_details.integration_name === "racalendar") {
                        validations.integration_name = false;
                        validations.integration_client_code = false;
                    }
                    else if(config.name === "integration_client_code" && data.property_manager_details.integration_name === "racalendar") { 
                        validations[config.name] = false;
                    }
                    else {
                        if(!data.property_manager_details[config.name].length) {
                            validations[config.name] = true;
                        }
                        else {
                            validations[config.name] = false;
                        };
                    };
                }
                else if(config.name === "damage_waiver") {
                    if(this.state.data.property_manager_details.account_status !== "Delisted") {
                        if(!_.get(data, `property_manager_details.${config.name}`)) {
                            validations[config.name] = true;
                        }
                        else {
                            validations[config.name] = false;
                        };
                    }
                    else {
                        validations[config.name] = false;
                    }
                }
                else {
                    if(!data.property_manager_details[config.name].length) {
                        validations[config.name] = true;
                    }
                    else {
                        validations[config.name] = false;
                    };
                };
            }
            else {
                if(config.name === "duplicateIntegrationFees") {
                    if(integrationFees.length) {
                        this.setState({
                            duplicateIntegrationFeesData: this.checkDuplicates(integrationFees, "name")
                        }, () => {
                            const { duplicateIntegrationFeesData } = this.state;

                            if(Object.keys(duplicateIntegrationFeesData).length) {
                                validations.duplicateIntegrationFees = true;
                            }
                            else {
                                validations.duplicateIntegrationFees = false;
                            };
                        });
                    };
                }
                else if(config.name === "integrationFees") {
                    const name = 
                        integrationFees && 
                        integrationFees[config.index] && 
                        integrationFees[config.index].name;
                    const value = 
                        integrationFees && 
                        integrationFees[config.index] && 
                        integrationFees[config.index].value;
  
                    if(integrationFees.length) {
                        if(!name.length && value.length) {
                            validations.integrationFees.splice(config.index, 1, { ...validations.integrationFees[config.index], text: false, radio: true });
                        }
                        else if(name.length && !value.length) {
                            validations.integrationFees.splice(config.index, 1, { ...validations.integrationFees[config.index], text: true, radio: false });
                        }
                        else if(name.length && value.length) {
                            validations.integrationFees.splice(config.index, 1, { ...validations.integrationFees[config.index], text: false, radio: false });
                        }
                        else {
                            validations.integrationFees.splice(config.index, 1, { ...validations.integrationFees[config.index], text: true, radio: true });
                        };
                    };
                }
                else if (config.name === "seasonalPolicies") {
                    if (seasonalPolicies.length) {
                        const startDate = seasonalPolicies && seasonalPolicies[config.index] && seasonalPolicies[config.index].start_date;
                        const endDate = seasonalPolicies && seasonalPolicies[config.index] && seasonalPolicies[config.index].end_date;
                        const policy = _.get(seasonalPolicies, `${[config.index]}.policy`) ? false : true;
                        let datesValidation = false;

                        const validateDateInput = (date) => {
                            if (!date) {
                                datesValidation = true;
                            } else {
                                datesValidation = false;
                            };
                        };

                        validateDateInput(startDate, 'startdate');
                        validateDateInput(endDate, 'enddate');

                        validations.seasonalPolicies.splice(config.index, 1, {
                            ...validations.seasonalPolicies[config.index],
                            dates: datesValidation,
                            policy: policy
                        });
                    };
                }
                else {
                    if(!this.state[config.name].length) {
                        validations[config.name] = true;
                    }
                    else {
                        validations[config.name] = false;
                    };
                };
            }
        });

        return validations;
    }

    toggleStatus = () => {
        let status = this.state.data.property_manager_details.account_status;

        if (status === "Delisted") {
            status = "Distribute to RA and Channels";
        }
        else if (status === "Distribute to RA and Channels" || status === "Distribute to RA only") {
            status = "Delisted";
        };

		this.setState({
            data: {
                ...this.state.data, property_manager_details: {
                    ...this.state.data.property_manager_details, 
                        account_status: status,
                    }
            }
        });
        
        if(this.state.mode === "edit") {
            this.setState({
                validations: {
                    ...this.state.validations,
                    organization_name: false,
                    title: false,
                    parent: false,
                    contact_email: false,
                    integration_name: false,
                    calendar_format: false,
                    integration_client_code: false,

                    damage_waiver: false,
                }
            });
        };
    }

    handleSelect = () =>{
		if (this.state.init) {
			this.setState({
				init: false,
			});
		};
    }

    closeModal = () => {
        this.setState({
            showModal: false,
            progressMessage: "",
        });
    }

    showModal = (modalName) => {
		this.setState({
            showModal: modalName,
            submitStatus: 0,
            modalError: {
                show: false,
                message: "",
                detail: [],
            },
		});
    }

    showActiveModal = (modal) => {
        this.setState({
            activeModal: modal
        });
    }

    closeActiveModal = () => {
        this.setState({
            activeModal: ""
        }, () => {
            this.setState({
                churnData: {
                    churn_date: moment().format('YYYY-MM-DD'),
                    churn_reason: "",
                    churn_detail: "",
                }
            });
        });
    }
    
    setModalError = (error) => {
        let detailError = [...this.state.modalError.detail];

        if(error && error.Message) {
            detailError.push(error.Message);
        };

        this.setState({ 
            submitStatus: this.state.submitStatus + 1,
            modalError: {
                show: true,
                message: this.handleSubmitErrorMessage(error && error.Message),
                detail: detailError,
            }
        });
    }

    deleteIntegrationFees = (e, integration, index) => {
        e.preventDefault();
        let integrationFeesValidations = this.state.validations.integrationFees;
        let integrationFees = [...this.state.integrationFees];
        let data = {...this.state.data};

        // Delete integration fee.
        integrationFees.splice(index, 1);

        // Delete integration validation.
        integrationFeesValidations.splice(index, 1);

        // Change index value in integration fees validations.
        integrationFeesValidations.forEach((val, i) => {
            integrationFeesValidations.splice(i, 1, {...val, index: i});
        });

        // Remove value from data.
        data.property_manager_details[integration.name] = "";       

        this.setState({
            data: data,
            integrationFees: integrationFees,
            validations: {
                ...this.state.validations,
                integrationFees: integrationFeesValidations
            }
        }, () => {
            const { validations } = this.state;
            if(validations.duplicateIntegrationFees == true) {
                let newValidation = this.validationHandler([{ name: "duplicateIntegrationFees" }]);

                this.setState({
                    validations: newValidation
                });
            };
        });
    }

    addIntegrationFees = () => {
        let integrationFeesValidations = this.state.validations.integrationFees;
        let integrationFees = [...this.state.integrationFees];

        // Add integration fee.
        integrationFees.push({ name: "", value: ""});

        // Add integration validation.
        integrationFeesValidations.push({ index: (integrationFees.length - 1), radio: false, text: false });

        this.setState({
            integrationFees: integrationFees,
            validations: {
                ...this.state.validations,
                integrationFees: integrationFeesValidations
            }
        });
    }

    panelOnSelect = (activekey, e, customKey) => {
        if(e) e.preventDefault();
        
        this.setState({ 
            activeKey: customKey ? customKey : activekey,
            init: false,
        });
    }

    toggleIntegrationStatus = () => {
        const { integrationStatus } = this.state;

        this.setState({
            integrationStatus: integrationStatus == "1" ? "0" : "1"
        });
    }

    getDimensions = (e) => {
		const target = e.target;
          
		this.setState({
			imageDimension: `${target.naturalWidth} x ${target.naturalHeight}`
		});
    }

    getTooltip = (id, text) => {
        return <Tooltip id={id}>{text}</Tooltip>
    }

    handleModal = (e, name) => {
        e && e.preventDefault();

        this.setState({
            modal: name
        });
    }

    pageSizeOnChange = (pageSize) => {
        this.setState({
            activePage: 1,
            pageSize: parseInt(pageSize),
        });
    }

    pageOnChange = (page) => {
        this.setState({
            activePage: parseInt(page)
        })
    }
    
    handleBookingPalOnClick = async (e) => {
        e.preventDefault();

        this.setState({
            altLoading: true
        });

        await this.getBookingPalToken();

        this.setState({
            altLoading: false,
            modal: 'bookingPal'
        });
    }

    filterChannels = (e) => {
        let filteredChannels = [...this.state.filteredChannels];
        const value = e.target.value;

        filteredChannels.splice(0, 1, 
            {...this.state.filteredChannels[0], id: "channel", value: value.toString().toLowerCase() });

        this.setState({
            filteredChannels: filteredChannels,
            keyword: value
        }, () => {
            const { init, mode, keyword, channelOptInMarkupData } = this.state;

            this.setState({
                activePage: 1,
                pageSize: (init || mode !== "edit") ? 
                    (keyword.length ? this.reactTable.state.sortedData.length : channelOptInMarkupData.length) : 
                    (keyword.length ? this.reactTable.state.sortedData.length : 10),
            })
        });
    }

    clearSearch = () => {
        const { init, mode, channelOptInMarkupData } = this.state;

        this.setState({
            activePage: 1,
            keyword: "",
            filteredChannels: [],
            pageSize: (init || mode !== "edit") ? channelOptInMarkupData.length : 10,
        });
    }

    checkDuplicates = (state, type) => {
		let data = [...state];
		let duplicateArr = [];
		let duplicateList = {};
		
		for(let i = 0; i < data.length; i++) {
			duplicateArr.splice(i, 0, data[i][type])
		};
		for(let i = 0; i < duplicateArr.length; i++) {
			if(duplicateList.hasOwnProperty(duplicateArr[i])) {
				duplicateList[duplicateArr[i]].push(i);
			}
			else if (duplicateArr.lastIndexOf(duplicateArr[i]) !== i) {
				duplicateList[duplicateArr[i]] = [i];
			};
        };
        
        // Remove empty string from duplicate list.
        delete duplicateList[""];

		return duplicateList;
    }
    
    handleDuplicateIntegrationFees = (integration, index) => {
        const duplicatesData = Object.entries(this.state.duplicateIntegrationFeesData);

        for(let i = 0; i < duplicatesData.length; i++) {
           if(duplicatesData[i][0] === integration.name && duplicatesData[i][1].includes(index)) {
               return true
           }
           else {
               return false
           };
        };
    }

    scrollToValidation = () => {
		this.setState({
			activeKey:"1", 
			init: true,
		}, async () => {
			setTimeout(() => {
				document.getElementsByClassName('form-validation').item(0).scrollIntoView({
					block: "center",
					behavior: "smooth"
				});
			}, 500);	
		});
	};

    popover = (message, title, placement, iconName) => {
        return (
            <span>
                <OverlayTrigger 
                    placement={placement}
                    overlay={<Popover
                        id="popover-basic"
                        placement={placement}
                        title={title ? title : null}
                    >
                        <p>{message}</p>
                    </Popover>}
                >
                    <div className="info-popover">
                        <i className={iconName}></i>
                    </div> 
                </OverlayTrigger>
            </span>
        )
    }
    
    createInlineRadioButton = (labels, state, onChangeFunc, index) => {
		return (
			labels.map((label, i) => (
            <span key={i}>
                <label className="radio-wrap radio-input">
                    <input
                        type="radio" 
                        className="form-radio-label radio-label"
                        name={index ? label["name"][index] : label["name"]}  
                        onChange={e => onChangeFunc(e, index)}
                        checked={state == label.value ? label.value : ""}
                        value={label['value']}
                    />
                    <div className="radio-label">
                        {label["label"]}
                    </div>
                </label>
            </span>
		))
    )}

    createDefaultRadioButton = (labels, state, onChangeFunc, index) => {
		return (
			labels.map((label, i) => (
			<Row key={i}>
                <Col md={6} >
                    <label
                        className="radio-wrap radio-input"
                    >
                        <input
                            type="radio" 
                            className="form-radio-label radio-label"
                            name={index ? label["name"][index] : label["name"]}  
                            onChange={e => onChangeFunc(e, index)}
                            checked={state == label.value ? label.value : ""}
                            value={label['value']}
                        />
                    <div
                        className="radio-label"
                    >
                        {label["label"]}
                    </div>
                    </label>
                </Col>
			</Row>
		))
    )}

    createIntegrationFeesConfig = () => {
        const integrationFees= [...this.state.integrationFees];
        const integrationFeesLabels = [
            {name: "integrationFees", value: "misc_rentalfee", label: "Miscellaneous Rental Fee"},
            {name: "integrationFees", value: "misc_cleanfee", label: "Miscellaneous Cleaning Fee"},
            {name: "integrationFees", value: "misc_tax", label: "Miscellaneous Tax"},
            {name: "integrationFees", value: "misc_nightlytax", label: "Miscellaneous Nightly Tax"},
            {name: "integrationFees", value: "misc_secdeposit", label: "Miscellaneous Security Deposit"},
        ];

        return integrationFees.map((integration, idx) => {
            const { validations } = this.state;
            const labels = integrationFeesLabels.filter((label) => label.value === integration.name);

            // Returns true or false value if intregration fee type is a duplicate.
            const hasDuplicates = this.handleDuplicateIntegrationFees(integration, idx);

            return (
                <Fragment key={idx}>
                    <Col md={8} sm={12}>
                        <Row>
                            <Col md={12} sm={12} xs={12}>
                                <div className={`integration-fee-container ${hasDuplicates || validations.integrationFees[idx].radio || validations.integrationFees[idx].text ? "form-validation" : ""}`}>
                                    <Row>
                                        <Col md={12} sm={12} xs={12}>
                                            {this.createDefaultRadioButton(integrationFeesLabels, integration.name, this.handleIntegrationFees, idx)}
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col md={12} sm={12} xs={12}>
                                            <InputField
                                                className={validations.integrationFees[idx].text ? "form-validation" : ""}
                                                cssClass="text-area" 
                                                type="textarea"
                                                name={`integration_fees${idx}`}
                                                value={integration.value}
                                                 
                                                onChange={e => this.handleIntegrationFees(e, idx)}
                                            />
                                        </Col>
                                    </Row>
                                    <OverlayTrigger 
                                        placement='bottom' 
                                        overlay={this.getTooltip("delete", "Delete Integration Fees")}
                                    >
                                        <div 
                                            className="integration-fee-icon-delete-container pointer"
                                            onClick={e => this.deleteIntegrationFees(e, integration, idx)}
                                        >
                                            <i className="icon icon-Delete"></i>
                                        </div>
                                    </OverlayTrigger>
                                </div>
                                {hasDuplicates && <span className="required-error-text">Cannot have duplicate {labels && labels[0] && labels[0].label}</span>}
                                {validations.integrationFees[idx].radio && <span className="required-error-text">Integration Type is required</span>}
                                {validations.integrationFees[idx].text && <span className="required-error-text">Integration input is required.</span>}
                            </Col>
                        </Row>
                        <br/>
                    </Col>
                </Fragment>
            );
        });
    }

    addSeasonalPolicy = (e) => {
        e.preventDefault();
        let freeCancel = {
            'no': 'yes_60',
            'yes_60': 'yes_42',
            'yes_42': 'yes_30',
            'yes_30': 'yes_14',
            'yes_14': 'yes_7',
            'yes_7': 'yes_3'
        }
        this.setState((prevState) => ({
            seasonalPolicies: [
                ...prevState.seasonalPolicies,
                {
                    start_date: null,
                    end_date: null,
                    policy: freeCancel[this.state.data.property_manager_details.free_cancel_policy],
                    active: true,
                    focusedInput: null
                },
            ],
            validations: {
                ...this.state.validations,
                seasonalPolicies: [
                    ...prevState.validations.seasonalPolicies,
                    {
                        dates: false,
                        policy: false
                    }
                ]
            }
        }));
    }

    handleSeasonalDatesChange = (index, {startDate, endDate}) => {
        this.setState((prevState) => ({
            seasonalPolicies: prevState.seasonalPolicies.map((policy, i) =>
                i === index ? {...policy, start_date: startDate, end_date: endDate} : policy
            ),
        }));
    };

    handleSeasonalDatesFocusChange = (index, focusedInput) => {
        this.setState((prevState) => ({
            seasonalPolicies: prevState.seasonalPolicies.map((policy, i) =>
                i === index ? {...policy, focusedInput} : policy
            ),
        }), () => {
            const startDateInput = document.getElementById(`sespol-startdate-${index}`);
            const endDateInput = document.getElementById(`sespol-enddate-${index}`);
            if (startDateInput) startDateInput.readOnly = true;
            if (endDateInput) endDateInput.readOnly = true;
        });
    };

    handleSeasonalActiveChange = (index) => {
        this.setState((prevState) => ({
            seasonalPolicies: prevState.seasonalPolicies.map((policy, i) =>
                i === index ? {...policy, active: !policy.active} : policy
            ),
        }));
    };

    handleSeasonalPolicyChange = (index, e) => {
        const newPolicy = e.target.value
        this.setState((prevState) => ({
            seasonalPolicies: prevState.seasonalPolicies.map((policy, i) =>
                i === index ? {...policy, policy: newPolicy} : policy
            ),
            validations: {
                ...this.state.validations,
                seasonalPolicies: this.state.validations.seasonalPolicies.map((validation, i) =>
                    i === index ? {...validation, policy: false} : validation
                )
            }
        }));
    };

    handleSeasonalDatesClose = (index, {startDate, endDate}) => {
        let datesValidation = false;
        const validateDateInput = (date, type) => {
            const dateInput = document.getElementById(`sespol-${type}-${index}`);
            if (!date) {
                dateInput.classList.add('form-validation');
                datesValidation = true;
            } else {
                dateInput.classList.remove('form-validation');
            }
        };
        validateDateInput(startDate, 'startdate');
        validateDateInput(endDate, 'enddate');
        this.setState((prevState) => ({
            validations: {
                ...this.state.validations,
                seasonalPolicies: this.state.validations.seasonalPolicies.map((validation, i) =>
                    i === index ? {...validation, dates: datesValidation} : validation
                )
            }
        }));
    };

    removeSeasonalPolicy = (e, index) => {
        e.preventDefault();

        let seasonalPolicies = [...this.state.seasonalPolicies];
        let seasonalPoliciesValidations = [...this.state.validations.seasonalPolicies];

        seasonalPolicies.splice(index, 1);
        seasonalPoliciesValidations.splice(index, 1);

        this.setState({
            seasonalPolicies: seasonalPolicies,
            validations: {
                ...this.state.validations,
                seasonalPolicies: seasonalPoliciesValidations
            }
        })
    }

    handleCancelPolicyPrompt = (e) => {
        const { 
            seasonalPolicies
        } = this.state;
        const value = e.target.value;
        let state = {
            cancelPolicyDataPlaceholder: {
                value: value,
                status: '',
                message: ''
            }
        };

        if(_.get(seasonalPolicies, 'length') && value !== 'no') {
            let removePolicy = [];

            if(value.match(/\d+/) == 3) {
                state.cancelPolicyDataPlaceholder.status = 1;
                state.cancelPolicyDataPlaceholder.message = 'Free Cancel 3 will remove ALL "Seasonal Cancellation Policies". Do you want to proceed?'
            }
            else {
                seasonalPolicies.forEach((policy) => {
                    if(Number(value.match(/\d+/)) <= Number(policy.policy.match(/\d+/))) {
                        removePolicy.push(policy);
                    };
                });
            };

            if(removePolicy.length) {
                const policyLabel = Constants.FREE_CANCEL_POLICY.find((policy) => value == policy.value);
                state.cancelPolicyDataPlaceholder.status = 2;
                state.cancelPolicyDataPlaceholder.message = `Seasonal free cancel policies that are not stricter than ${_.get(policyLabel, 'label')} will be removed and need to be reentered. Do you want to proceed?`
            };

            if(state.cancelPolicyDataPlaceholder.message) {
                state.activeModal = 'cancel-policy-prompt';
            };
        };

        this.setState(state, () => {
            if(!state.cancelPolicyDataPlaceholder.message) {
                this.handleCancelPolicy();
            };
        });
    }

    handleCancelPolicy = () => {
        const {
            data,
            seasonalPolicies,
            cancelPolicyDataPlaceholder,
            validations
        } = this.state;
        let state = {
            data: {
                ...data,
                property_manager_details: {
                    ...data.property_manager_details, 
                    free_cancel_policy: cancelPolicyDataPlaceholder.value
                }
            },
            activeModal: '',
            cancelPolicyDataPlaceholder: {
                value: '',
                status: '',
                message: ''
            }
        };

        if(cancelPolicyDataPlaceholder.status == 1) {
            state.seasonalPolicies = [];

            if(_.get(validations, 'seasonalPolicies.length')) {
                state.validations = {
                    ...validations,
                    seasonalPolicies: []
                };
            };
        } else if(cancelPolicyDataPlaceholder.status == 2) {
            let parsePolicies = [];
            seasonalPolicies.forEach((policy) => {
                if(Number(_.get(cancelPolicyDataPlaceholder, 'value').match(/\d+/)) <= Number(_.get(policy, 'policy', '').match(/\d+/))) {
                    parsePolicies.push({...policy, policy: ''})
                }
                else {
                    parsePolicies.push(policy)
                };
            });

            state.seasonalPolicies = parsePolicies;
        };

        this.setState(state);
    }

    createSeasonalPolicies = () => {
        const standardPolicy = Number(((_.get(this.state.data, 'property_manager_details.free_cancel_policy') || '').match(/\d+/) || [99])[0]);
        let freeCancelPolicy = [<option key={'key'} data-value={''} value={''}></option>];
        Constants.FREE_CANCEL_POLICY.map((policy, i) => {
            if (policy.value !== 'no') {
                const freeCancel = Number((policy.value.match(/\d+/) || [null])[0]);
                const exclude = standardPolicy && freeCancel >= standardPolicy;
                if(!exclude) {
                    freeCancelPolicy.push(<option key={i} data-value={policy.value} value={policy.value}>{policy.label}</option>)
                }; 
            };
        });

        return this.state.seasonalPolicies.map((policy, index) => {

            return (
                <Fragment key={index}>
                    <Row className="sespol bottom-margin">
                        <Col className="sespol-form" lg={10} md={10} xs={10} sm={10}>
                            <Col className="sespol-dtpc" lg={6} md={6} xs={12} sm={12}>
                                <Col md={6} xs={6} sm={6} lg={6}>
                                    <InputLabel
                                        htmlFor='seasonalstart'
                                    >
                                        Start Date
                                    </InputLabel>
                                </Col>
                                <Col md={6} xs={6} sm={6} lg={6}>
                                    <InputLabel
                                        htmlFor='seasonalend'
                                    >
                                        End Date
                                    </InputLabel>
                                </Col>
                                <Col md={12} xs={12} sm={12} lg={12}>
                                    <div className={this.state.validations.seasonalPolicies[index].dates ? 'form-validation' : ''}>
                                        <DateRangePicker
                                            noBorder
                                            key={index}
                                            startDate={policy.start_date}
                                            startDateId={`sespol-startdate-${index}`}
                                            startDatePlaceholderText="mm/dd/yyyy"
                                            endDate={policy.end_date}
                                            endDateId={`sespol-enddate-${index}`}
                                            endDatePlaceholderText="mm/dd/yyyy"
                                            onDatesChange={(dates) => this.handleSeasonalDatesChange(index, dates)}
                                            focusedInput={policy.focusedInput}
                                            onFocusChange={(focused) => this.handleSeasonalDatesFocusChange(index, focused)}
                                            onClose={(dates) => this.handleSeasonalDatesClose(index, dates)}
                                        />
                                    </div>
                                    {this.state.validations.seasonalPolicies[index].dates && <span className="required-error-text">Seasonal date range should be filled in.</span>}
                                </Col>
                            </Col>
                            <Col lg={3} md={3} xs={6} sm={6}>
                                <InputLabel
                                    htmlFor="seasonal_cancel_policy"
                                >
                                    Free Cancel Policy
                                </InputLabel>
                                <InputField
                                    type="select"
                                    name="seasonal_cancel_policy"
                                    value={policy.policy}
                                    onChange={(e) => this.handleSeasonalPolicyChange(index, e)}
                                    className={this.state.validations.seasonalPolicies[index].policy ? 'form-validation' : ''}
                                >
                                    {freeCancelPolicy}
                                </InputField>
                                {this.state.validations.seasonalPolicies[index].policy && <span className="required-error-text">Free Cancel Policy is required.</span>}
                            </Col>
                            <Col lg={3} md={3} xs={6} sm={6}>
                                <InputLabel
                                    htmlFor="seasonal_active"
                                >
                                    Active
                                </InputLabel>
                                <div className={"switch-active"}>
                                    <Switch
                                        onColor={"#5AD888"}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        height={30}
                                        width={45}
                                        checked={policy.active}
                                        onChange={() => this.handleSeasonalActiveChange(index)}
                                    />
                                </div>
                            </Col>
                        </Col>
                        <Col md={2} xs={2} sm={2} lg={2}>
                            <a
                                href="#"
                                className="left-margin"
                                onClick={(e) => this.removeSeasonalPolicy(e, index)}
                            >
                                <i
                                    className="icon icon-Delete"
                                />
                            </a>
                        </Col>
                    </Row>
                </Fragment>
            );
        });
    }


    checkinInstructionsPopover = (title, message) => {
        return (
            <OverlayTrigger 
                placement='top' 
                overlay={<Popover
                    id="popover-basic"
                    placement="top"
                    title={title}
                >
                    <p>{message}</p>
                </Popover>}
            >
                <span className="input-question-mark">
                    <i className="icon-question-mark"></i>
                </span>
            </OverlayTrigger>
        )
    }

    seasonalPoliciesPopover = (title, message) => {
        return (
            <OverlayTrigger
                placement='top'
                overlay={<Popover
                    id="popover-basic"
                    placement="top"
                    title={title}
                >
                    <p>{message}</p>
                </Popover>}
            >
                <span className="input-question-mark">
                    <i className="icon-question-mark"></i>
                </span>
            </OverlayTrigger>
        )
    }

    toggleEditRentalPolicies = () => {
        const { editRentalPolicies } = this.state;

        this.setState({
            editRentalPolicies: !editRentalPolicies
        }, () => {
            const {
                data,
                editRentalPolicies
            } = this.state;

            if(editRentalPolicies === true) {
                this.setState({
                    data: {
                        ...data,
                        property_manager_details: {
                            ...data.property_manager_details,
                            rental_policy: "10% | 3%"
                        }
                    }
                });
            }
            else {
                this.setState({
                    data: {
                        ...data,
                        property_manager_details: {
                            ...data.property_manager_details,
                            rental_policy: ""
                        }
                    }
                });
            };
        });
    }

    handleChurnInput = (e) => {
        const { churnData } = this.state;
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            churnData: {
                ...churnData,
                [name]: value
            }
        });
    }

    handleTeamMemberPropertyOptions = (value) => {
        this.setState({
            teamMemberPropertyOptionsValue: value
        });
    }

    removeTeamMember = async () => {
        const { 
            selectedTeamMember,
            organization
        } = this.state;

        this.setState({
            teamMemberErrorMsg: '',
            teamMemberDeleteErrorMsg: '',
            teamMembersIsLoading: true
        });

        this.handleModal('', '');

        try {
            await API.del('ramapi',`/commhub/organizations/${organization.organization_name}/subscribers/${selectedTeamMember.subscriber.subscriber_id}`);

            await this.getTeamMembers();

            this.handleCancelTeamMember();

            this.setState({
                selectedTeamMember: {},
                teamMembersIsLoading: false
            });
        } 
        catch(e) {
            let msg = 'Oops! We encountered an error while attempting to delete a team member. Please contact customer support.';
        
            if(e && e.response && e.response.data && e.response.data.Message && e.response.data.Message.length) {
                if(e.response.data.Message.match(/'(.*?)'/)) {
                    msg = e.response.data.Message.match(/'(.*?)'/)[1];
                };
            };

            this.setState({
                teamMemberDeleteErrorMsg: msg,
                teamMembersIsLoading: false
            });
        };
    }

    submitTeamMember = async () => {
        const {
            teamMemberMode,
            subCategories,
            teamMemberData,
            sourceTeamMemberData,
            organization
        } = this.state;
        let email_uuid = Constants.generateUUID();
        let sms_uuid = Constants.generateUUID();
        let portal_uuid = Constants.generateUUID();
        let role = teamMemberData.role_type;

        function convertToSeconds (timeString) {
            const momentTime = moment.utc(timeString, 'HH:mm:ssZ');
            const seconds = momentTime.diff(moment.utc().startOf('day'), 'seconds');
            return seconds;
        };

        let data = {
            subscriber: {
                display_name: '',
                message_type_data: {}
            },
            organization_name: organization.organization_name,
            opt_ins: []
        };

        if(role.length) {
            // Create Team Member
            if(teamMemberMode == 'create') {

                data.subscriber.display_name = `${teamMemberData.first_name}${teamMemberData.last_name ? ` ${teamMemberData.last_name}` : ''}`;

                if(teamMemberData.email) {
                    data.subscriber.message_type_data.email = [
                        {
                            _id: email_uuid,
                            addr: teamMemberData.email,
                            name: "my email"
                        }
                    ];
                };

                if(teamMemberData.phone_number) {
                    data.subscriber.message_type_data.sms = [
                        {
                            _id: sms_uuid,
                            phone_number: teamMemberData.phone_number,
                            name: "my phone"
                        }
                    ];
                };

                data.subscriber.message_type_data.portal = [
                    {
                        _id: portal_uuid,
                        name: "Portal"
                    }
                ];

                let subArr= [];
                subCategories[role].forEach((arr) => {
                    arr.forEach((sub) => {
                        if(sub.email_host || sub.email_guest || sub.sms_host || sub.sms_guest || sub.portal_host || sub.portal_guest) {
                            subArr.push(sub);
                        };
                    });
                });

                let newSubArr = [];
                if(subArr.length) {
                    subArr.forEach((sub) => {
                        let typeArr = [
                            'email_host',
                            'email_guest',
                            'sms_host',
                            'sms_guest',
                            'portal_host',
                            'portal_guest',
                        ];

                        typeArr.forEach((type) => {
                            let subData = {
                                organization_name: organization.organization_name,
                                opt_in_type: "external",
                                category: sub.category,
                                subcategory: sub.subcategory,
                                category_subcategory_id: sub.id,
                                message_type_data_selectors: [],
                                schedule: null,
                                context: {
                                    participant_context: []
                                }
                            };

                            if(sub[type] == true) {
                                let uuid = '';

                                if((type.includes('email') && !teamMemberData.email) || (type.includes('sms') && !teamMemberData.phone_number)) {
                                    return;
                                }
                                else {
                                    if(type.includes('email')) {
                                        uuid = email_uuid;
                                    }
                                    else if (type.includes('sms')) {
                                        uuid = sms_uuid;
                                    }
                                    else if (type.includes('portal')) {
                                        uuid = portal_uuid;
                                    };
    
                                    if(role == 'cleaner') {
                                        subData.context.participant_context.push('cleaner');
                                    }
                                    else if(type.includes('host')) {
                                        subData.context.participant_context.push('host');
                                    }
                                    else if(type.includes('guest')) {
                                        subData.context.participant_context.push('guest');
                                    };
    
                                    subData.message_type_data_selectors.push(uuid);
    
                                    if(type == 'email_host' || type == 'sms_host') {
                                        if(sub[`${type}_schedule`]) {
                                            subData.schedule = {
                                                time_start_secs: convertToSeconds(sub[`${type}_availability`].start),
                                                time_end_secs: convertToSeconds(sub[`${type}_availability`].end),
                                                timezone: sub[`${type}_availability`].timeZone,
                                                sunday: sub[`${type}_availability`].sunday,
                                                monday: sub[`${type}_availability`].monday,
                                                tuesday: sub[`${type}_availability`].tuesday,
                                                wednesday: sub[`${type}_availability`].wednesday,
                                                thursday: sub[`${type}_availability`].thursday,
                                                friday: sub[`${type}_availability`].friday,
                                                saturday: sub[`${type}_availability`].saturday
                                            };
                                        };
                                    };
    
                                    newSubArr.push(subData);
                                };
                            };
                        });
                    })
                }

                data.opt_ins = newSubArr;
            }
            else {
                // Update Team Member
                data.subscriber = {
                    ...sourceTeamMemberData.subscriber
                };

                data.subscriber.display_name = `${teamMemberData.first_name}${teamMemberData.last_name ? ` ${teamMemberData.last_name}` : ''}`;

                if(teamMemberData.email) {
                    if(_.get(sourceTeamMemberData, 'subscriber.message_type_data.email.length')) {
                        email_uuid = sourceTeamMemberData.subscriber.message_type_data.email[0]._id;

                        data.subscriber.message_type_data.email = [
                            {
                                ...sourceTeamMemberData.subscriber.message_type_data.email[0],
                                addr: teamMemberData.email,
                            }
                        ];
                    }
                    else {
                        if(!data.subscriber.message_type_data) {
                            data.subscriber.message_type_data = {};
                        };

                        if(!data.subscriber.message_type_data.email) {
                            data.subscriber.message_type_data.email = [];
                        };

                        data.subscriber.message_type_data.email = [
                            {
                                _id: email_uuid,
                                addr: teamMemberData.email,
                                name: "my email"
                            }
                        ];
                    };
                }
                else {
                    data.subscriber.message_type_data.email = [];
                };

                if(teamMemberData.phone_number) {
                    if(_.get(sourceTeamMemberData, 'subscriber.message_type_data.sms.length')) {
                        sms_uuid = sourceTeamMemberData.subscriber.message_type_data.sms[0]._id;

                        data.subscriber.message_type_data.sms = [
                            {
                                ...sourceTeamMemberData.subscriber.message_type_data.sms[0],
                                phone_number: teamMemberData.phone_number,
                            }
                        ];
                    }
                    else {
                        if(!data.subscriber.message_type_data) {
                            data.subscriber.message_type_data = {};
                        };
    
                        if(!data.subscriber.message_type_data.sms) {
                            data.subscriber.message_type_data.sms = [];
                        };

                        data.subscriber.message_type_data.sms = [
                            {
                                _id: sms_uuid,
                                phone_number: teamMemberData.phone_number,
                                name: "my phone"
                            }
                        ];
                    };
                }
                else {
                    data.subscriber.message_type_data.sms = [];
                };

                if(_.get(sourceTeamMemberData, 'subscriber.message_type_data.portal.length')) {
                    portal_uuid = sourceTeamMemberData.subscriber.message_type_data.portal[0]._id;
                }
                else {
                    if(!data.subscriber.message_type_data) {
                        data.subscriber.message_type_data = {};
                    };

                    if(!data.subscriber.message_type_data.portal) {
                        data.subscriber.message_type_data.portal = [];
                    };

                    data.subscriber.message_type_data.portal = [
                        {
                            _id: portal_uuid,
                            name: "Portal"
                        }
                    ];
                };

                let propertyIds = [];
                if(_.get(sourceTeamMemberData, 'opt_ins.length')) {
                    sourceTeamMemberData.opt_ins.forEach((optin) => {
                        if(optin && optin.internal_id) {
                            propertyIds.push(optin.internal_id);
                        };
                    });
                };
                const newPropertyIds = [...new Set(propertyIds)];

                let newSubArr = [];
                subCategories[role].forEach((arr) => {
                    arr.forEach((sub) => {
                        if(teamMemberData.email.length) {
                            // Host Email
                            if(sub.email_host) {
                                let subData = {
                                    organization_name: organization.organization_name,
                                    category: sub.category,
                                    subcategory: sub.subcategory,
                                    category_subcategory_id: sub.id,
                                    message_type_data_selectors: [],
                                    subscriber_id: sourceTeamMemberData.subscriber.subscriber_id,
                                    opt_in_type: "external",
                                    schedule: null,
                                    context: {
                                        participant_context: ['host']
                                    }
                                };

                                let emailOptins = sourceTeamMemberData.opt_ins.filter((optin) => (
                                    (optin.context.participant_context && optin.context.participant_context.includes('guest')) &&
                                    optin.category_subcategory_id == sub.id && 
                                    optin.message_type_data_selectors.includes(email_uuid)
                                ));

                                if(emailOptins.length) {
                                    emailOptins.forEach((optin) => {
                                        subData = {...optin};

                                        subData.context.participant_context = ['host'];

                                        if(sub.email_host_schedule) {
                                            subData.schedule = {
                                                time_start_secs: convertToSeconds(sub.email_host_availability.start),
                                                time_end_secs: convertToSeconds(sub.email_host_availability.end),
                                                timezone: sub.email_host_availability.timeZone,
                                                sunday: sub.email_host_availability.sunday,
                                                monday: sub.email_host_availability.monday,
                                                tuesday: sub.email_host_availability.tuesday,
                                                wednesday: sub.email_host_availability.wednesday,
                                                thursday: sub.email_host_availability.thursday,
                                                friday: sub.email_host_availability.friday,
                                                saturday: sub.email_host_availability.saturday
                                            };
                                        }
                                        else {
                                            subData.schedule = null;
                                        };

                                        newSubArr.push(subData)
                                    });
                                }
                                else {
                                    subData.message_type_data_selectors.push(email_uuid);

                                    if(sub.email_host_schedule) {
                                        subData.schedule = {
                                            time_start_secs: convertToSeconds(sub.email_host_availability.start),
                                            time_end_secs: convertToSeconds(sub.email_host_availability.end),
                                            timezone: sub.email_host_availability.timeZone,
                                            sunday: sub.email_host_availability.sunday,
                                            monday: sub.email_host_availability.monday,
                                            tuesday: sub.email_host_availability.tuesday,
                                            wednesday: sub.email_host_availability.wednesday,
                                            thursday: sub.email_host_availability.thursday,
                                            friday: sub.email_host_availability.friday,
                                            saturday: sub.email_host_availability.saturday
                                        };
                                    };

                                    if(newPropertyIds.length) {
                                        newPropertyIds.forEach((id) => {
                                            newSubArr.push({
                                                ...subData,
                                                internal_id: id,
                                                internal_id_type: 'nid'
                                            });
                                        });
                                    }
                                    else {
                                        newSubArr.push(subData);
                                    };
                                };
                            };

                            // Guest Email
                            if(sub.email_guest) {
                                let subData = {
                                    organization_name: organization.organization_name,
                                    category: sub.category,
                                    subcategory: sub.subcategory,
                                    category_subcategory_id: sub.id,
                                    message_type_data_selectors: [],
                                    subscriber_id: sourceTeamMemberData.subscriber.subscriber_id,
                                    opt_in_type: "external",
                                    schedule: null,
                                    context: {
                                        participant_context: []
                                    }
                                };

                                let emailOptins = sourceTeamMemberData.opt_ins.filter((optin) => (
                                    ((optin.context.participant_context && optin.context.participant_context.includes('cleaner')) ||
                                    (optin.context.participant_context && optin.context.participant_context.includes('guest'))) &&
                                    optin.category_subcategory_id == sub.id && 
                                    optin.message_type_data_selectors.includes(email_uuid)
                                ));

                                if(emailOptins.length) {
                                    emailOptins.forEach((optin) => {
                                        subData = {...optin};

                                        if(role == 'cleaner') {
                                            subData.context.participant_context = ['cleaner'];
                                        }
                                        else if(role == 'host') {
                                            subData.context.participant_context = ['guest'];
                                        };

                                        subData.schedule = null;

                                        newSubArr.push(subData);
                                    });
                                }
                                else {
                                    subData.message_type_data_selectors.push(email_uuid);

                                    if(role == 'cleaner') {
                                        subData.context.participant_context = ['cleaner'];
                                    }
                                    else if(role == 'host') {
                                        subData.context.participant_context = ['guest'];
                                    };

                                    if(newPropertyIds.length) {
                                        newPropertyIds.forEach((id) => {
                                            newSubArr.push({
                                                ...subData,
                                                internal_id: id,
                                                internal_id_type: 'nid'
                                            });
                                        });
                                    }
                                    else {
                                        newSubArr.push(subData);
                                    };
                                };
                            };
                        };
                        

                        if(_.get(teamMemberData, 'phone_number.length')) {
                            // Host SMS
                            if(sub.sms_host ) {
                                let subData = {
                                    organization_name: organization.organization_name,
                                    category: sub.category,
                                    subcategory: sub.subcategory,
                                    category_subcategory_id: sub.id,
                                    message_type_data_selectors: [],
                                    subscriber_id: sourceTeamMemberData.subscriber.subscriber_id,
                                    opt_in_type: "external",
                                    schedule: null,
                                    context: {
                                        participant_context: ['host']
                                    }
                                };

                                let smsOptins = sourceTeamMemberData.opt_ins.filter((optin) => (
                                    (optin.context.participant_context && optin.context.participant_context.includes('host')) &&
                                    optin.category_subcategory_id == sub.id && 
                                    optin.message_type_data_selectors.includes(sms_uuid)
                                ));

                                if(smsOptins.length) {
                                    smsOptins.forEach((optin) => {
                                        subData = {...optin};

                                        subData.context.participant_context = ['host'];

                                        if(sub.sms_host_schedule) {
                                            subData.schedule = {
                                                time_start_secs: convertToSeconds(sub.sms_host_availability.start),
                                                time_end_secs: convertToSeconds(sub.sms_host_availability.end),
                                                timezone: sub.sms_host_availability.timeZone,
                                                sunday: sub.sms_host_availability.sunday,
                                                monday: sub.sms_host_availability.monday,
                                                tuesday: sub.sms_host_availability.tuesday,
                                                wednesday: sub.sms_host_availability.wednesday,
                                                thursday: sub.sms_host_availability.thursday,
                                                friday: sub.sms_host_availability.friday,
                                                saturday: sub.sms_host_availability.saturday
                                            };
                                        }
                                        else {
                                            subData.schedule = null;
                                        };

                                        newSubArr.push(subData);
                                    });
                                }
                                else {
                                    subData.message_type_data_selectors.push(sms_uuid);

                                    if(sub.sms_host_schedule) {
                                        subData.schedule = {
                                            time_start_secs: convertToSeconds(sub.sms_host_availability.start),
                                            time_end_secs: convertToSeconds(sub.sms_host_availability.end),
                                            timezone: sub.sms_host_availability.timeZone,
                                            sunday: sub.sms_host_availability.sunday,
                                            monday: sub.sms_host_availability.monday,
                                            tuesday: sub.sms_host_availability.tuesday,
                                            wednesday: sub.sms_host_availability.wednesday,
                                            thursday: sub.sms_host_availability.thursday,
                                            friday: sub.sms_host_availability.friday,
                                            saturday: sub.sms_host_availability.saturday
                                        };
                                    };

                                    if(newPropertyIds.length) {
                                        newPropertyIds.forEach((id) => {
                                            newSubArr.push({
                                                ...subData,
                                                internal_id: id,
                                                internal_id_type: 'nid'
                                            });
                                        });
                                    }
                                    else {
                                        newSubArr.push(subData);
                                    };
                                };
                            }

                            // Guest SMS
                            if(sub.sms_guest) {
                                let subData = {
                                    organization_name: organization.organization_name,
                                    category: sub.category,
                                    subcategory: sub.subcategory,
                                    category_subcategory_id: sub.id,
                                    message_type_data_selectors: [],
                                    subscriber_id: sourceTeamMemberData.subscriber.subscriber_id,
                                    opt_in_type: "external",
                                    schedule: null,
                                    context: {
                                        participant_context: []
                                    }
                                };

                                let smsOptins = sourceTeamMemberData.opt_ins.filter((optin) => (
                                    ((optin.context.participant_context && optin.context.participant_context.includes('cleaner')) ||
                                    (optin.context.participant_context && optin.context.participant_context.includes('guest'))) &&
                                    optin.category_subcategory_id == sub.id && 
                                    optin.message_type_data_selectors.includes(sms_uuid)
                                ));

                                if(smsOptins.length) {
                                    smsOptins.forEach((optin) => {
                                        subData = {...optin};

                                        if(role == 'cleaner') {
                                            subData.context.participant_context = ['cleaner'];
                                        }
                                        else if(role == 'host') {
                                            subData.context.participant_context = ['guest'];
                                        };

                                        subData.schedule = null;

                                        newSubArr.push(subData);
                                    });
                                }
                                else {
                                    subData.message_type_data_selectors.push(sms_uuid);

                                    if(role == 'cleaner') {
                                        subData.context.participant_context = ['cleaner'];
                                    }
                                    else if(role == 'host') {
                                        subData.context.participant_context = ['guest'];
                                    };

                                    if(newPropertyIds.length) {
                                        newPropertyIds.forEach((id) => {
                                            newSubArr.push({
                                                ...subData,
                                                internal_id: id,
                                                internal_id_type: 'nid'
                                            });
                                        });
                                    }
                                    else {
                                        newSubArr.push(subData);
                                    };
                                };
                            }
                        }
                        

                        // Host Portal
                        if(sub.portal_host) {
                            let subData = {
                                organization_name: organization.organization_name,
                                category: sub.category,
                                subcategory: sub.subcategory,
                                category_subcategory_id: sub.id,
                                message_type_data_selectors: [],
                                subscriber_id: sourceTeamMemberData.subscriber.subscriber_id,
                                opt_in_type: "external",
                                schedule: null,
                                context: {
                                    participant_context: ['host']
                                }
                            };

                            let portalOptins = sourceTeamMemberData.opt_ins.filter((optin) => (
                                (optin.context.participant_context && optin.context.participant_context.includes('host')) &&
                                optin.category_subcategory_id == sub.id && 
                                optin.message_type_data_selectors.includes(portal_uuid)
                            ));

                            if(portalOptins.length) {
                                portalOptins.forEach((optin) => {
                                    subData = {...optin};

                                    subData.context.participant_context = ['host'];

                                    newSubArr.push(optin)
                                });
                            }
                            else {
                                subData.message_type_data_selectors.push(portal_uuid);

                                if(newPropertyIds.length) {
                                    newPropertyIds.forEach((id) => {
                                        newSubArr.push({
                                            ...subData,
                                            internal_id: id,
                                            internal_id_type: 'nid'
                                        });
                                    });
                                }
                                else {
                                    newSubArr.push(subData);
                                };
                            };
                        };

                        // Guest Portal
                        if(sub.portal_guest) {
                            let subData = {
                                organization_name: organization.organization_name,
                                category: sub.category,
                                subcategory: sub.subcategory,
                                category_subcategory_id: sub.id,
                                message_type_data_selectors: [],
                                subscriber_id: sourceTeamMemberData.subscriber.subscriber_id,
                                opt_in_type: "external",
                                schedule: null,
                                context: {
                                    participant_context: []
                                }
                            };

                            let portalOptins = sourceTeamMemberData.opt_ins.filter((optin) => (
                                ((optin.context.participant_context && optin.context.participant_context.includes('cleaner')) ||
                                (optin.context.participant_context && optin.context.participant_context.includes('guest'))) && 
                                optin.message_type_data_selectors.includes(portal_uuid) &&
                                optin.category_subcategory_id == sub.id
                            ));

                            if(portalOptins.length) {
                                portalOptins.forEach((optin) => {
                                    subData = {...optin};

                                    if(role == 'cleaner') {
                                        subData.context.participant_context = ['cleaner'];
                                    }
                                    else if(role == 'host') {
                                        subData.context.participant_context = ['guest'];
                                    };

                                    newSubArr.push(subData);
                                });
                            }
                            else {
                                subData.message_type_data_selectors.push(portal_uuid);

                                if(role == 'cleaner') {
                                    subData.context.participant_context = ['cleaner'];
                                }
                                else if(role == 'host') {
                                    subData.context.participant_context = ['guest'];
                                };

                                if(newPropertyIds.length) {
                                    newPropertyIds.forEach((id) => {
                                        newSubArr.push({
                                            ...subData,
                                            internal_id: id,
                                            internal_id_type: 'nid'
                                        });
                                    });
                                }
                                else {
                                    newSubArr.push(subData);
                                };
                            };
                        };
                    });
                });

                let subCatNotAssociated = sourceTeamMemberData.opt_ins.filter((optin) => optin.subcategory == '*' || optin.subcategory == '_default');

                data.opt_ins = [...subCatNotAssociated, ...newSubArr];
            };
        };

        this.setState({
            teamMemberValidations: this.handleTeamMemberValidations()
        }, async () => {
            const { teamMemberValidations } = this.state;
            let validations = [];
            
            Object.entries(teamMemberValidations).forEach((entry) => {
                if(entry[1] == true) {
                    validations.push(entry[1])
                };
            });

            if(!validations.length) {
                this.setState({
                    teamMembersIsLoading: true,
                    teamMemberErrorMsg: '',
                    teamMemberDeleteErrorMsg: '',
                });

                let resp;

                try {
                    resp = await API[teamMemberMode == 'edit' ? 'put' : 'post']('ramapi',`/commhub/organizations/${organization.organization_name}/subscribers${teamMemberMode == 'edit' ? `/${sourceTeamMemberData.subscriber.subscriber_id}` : ''}`, { response: true, body: data });
                    if(resp && resp.data) {
                        await this.getTeamMembers();
                        
                        this.handleCancelTeamMember();

                        this.setState({
                            teamMembersIsLoading: false,
                        });
                    };
                } 
                catch(e) {
                    let msg = `Oops! We encountered an error while attempting to ${teamMemberMode == 'create' ? 'add a new member' : 'update a team member'}. Please contact customer support.`;
        
                    if(e && e.response && e.response.status == '409') {
                        msg = 'This subscriber already exists';
                    }
                    else if(e && e.response && e.response.data && e.response.data.Message && e.response.data.Message.length) {
                        if(e.response.data.Message.match(/'(.*?)'/)) {
                            msg = e.response.data.Message.match(/'(.*?)'/)[1];
                        };
                    };
        
                    this.setState({
                        teamMemberErrorMsg: msg,
                        teamMembersIsLoading: false
                    });
                };
            };
        });
    }

    initTeamMemberData = (e, data) => {
        e.preventDefault();
        let firstName = '';
        let lastName = '';
        let email = '';
        let phoneNumber = '';
        let roleType = '';
        let subscriberId = '';

        function formatTime (time) {
            const duration = moment.duration(time, 'seconds');
            const formattedTime = moment.utc(duration.asMilliseconds()).format('HH:mm:ss[Z]');
            return formattedTime;
        };

        if(_.get(data, 'subscriber.subscriber_id.length', '')) {
            subscriberId = data.subscriber.subscriber_id;
        };
        
        if(_.get(data, 'subscriber.display_name.length', '')) {
            let nameSplit = data.subscriber.display_name.split(" ");

            firstName = nameSplit[0] ? nameSplit[0] : "";
            lastName = nameSplit[1] ? nameSplit[1] : "";
        };

        if(_.get(data, 'opt_ins.length', '')) {
            for (let i = 0; i < data.opt_ins.length; i++) {
                if(_.get(data.opt_ins[i], 'role_type', '') === 'host' || _.get(data.opt_ins[i], 'role_type', '') === 'guest') {
                    roleType = 'host';
                    break;
                }
                else if(_.get(data.opt_ins[i], 'role_type', '') === 'cleaner') {
                    roleType = 'cleaner';
                    break;
                };
            };
        };

        if(_.get(data, 'subscriber.message_type_data.email.length', '')) {
            email = data.subscriber.message_type_data.email[0].addr;
        };

        if(_.get(data, 'subscriber.message_type_data.sms.length', '')) {
            phoneNumber = data.subscriber.message_type_data.sms[0].phone_number;
        };

        let newSubCat = _.cloneDeep(this.initSubCategories);

        if(_.get(data, 'opt_ins.length', '') && roleType) {
            newSubCat[roleType].forEach((arr, index1) => {
                arr.forEach((sub, index2) => {
                    let optIns = data.opt_ins.filter((opt_in) => opt_in.category_subcategory_id == sub.id);
                    let sms_host = false;
                    let sms_guest = false;
                    let email_host = false;
                    let email_guest = false;
                    let portal_host = false;
                    let portal_guest = false;
                    let sms_host_schedule = false;
                    let email_host_schedule = false;
                    let sms_host_availability = {
                        monday: true,
                        tuesday: true,
                        wednesday: true,
                        thursday: true,
                        friday: true,
                        saturday: true,
                        sunday: true,
                        start: '09:00:00Z',
                        end: '21:00:00Z',
                        timeZone: 'US/Pacific'
                    };
                    let email_host_availability = {
                        monday: true,
                        tuesday: true,
                        wednesday: true,
                        thursday: true,
                        friday: true,
                        saturday: true,
                        sunday: true,
                        start: '09:00:00Z',
                        end: '21:00:00Z',
                        timeZone: 'US/Pacific'
                    };

                    if(optIns.length) {
                        optIns.forEach((opt_in) => {
                            if(_.get(opt_in, 'message_type_data_selectors.length', '')) {
                                opt_in.message_type_data_selectors.forEach((msg_type_id) => {
                                    if(msg_type_id == _.get(data, 'subscriber.message_type_data.sms[0]._id')) {
                                        if(_.get(opt_in, 'context.participant_context.length', '')) {
                                            if(opt_in.context.participant_context.includes('host')) {
                                                sms_host = true;
                                            };
                                            if(opt_in.context.participant_context.includes('guest') || opt_in.context.participant_context.includes('cleaner')) {
                                                sms_guest = true;
                                            };
                                        };
                                        
                                        if(sms_host && !_.isNil(opt_in.schedule) && !_.isEmpty(opt_in.schedule)) {
                                            sms_host_schedule = true;
                                            sms_host_availability.monday = opt_in.schedule.monday;
                                            sms_host_availability.tuesday = opt_in.schedule.tuesday;
                                            sms_host_availability.wednesday = opt_in.schedule.wednesday;
                                            sms_host_availability.thursday = opt_in.schedule.thursday;
                                            sms_host_availability.friday = opt_in.schedule.friday;
                                            sms_host_availability.saturday = opt_in.schedule.saturday;
                                            sms_host_availability.sunday = opt_in.schedule.sunday;
                                            sms_host_availability.start = formatTime(opt_in.schedule.time_start_secs);
                                            sms_host_availability.end = formatTime(opt_in.schedule.time_end_secs);
                                            sms_host_availability.timeZone = opt_in.schedule.timezone;
                                        };
                                    };

                                    if(msg_type_id == _.get(data, 'subscriber.message_type_data.email[0]._id')) {
                                        if(_.get(opt_in, 'context.participant_context.length', '')) {
                                            if(opt_in.context.participant_context.includes('host')) {
                                                email_host = true;
                                            };
                                            if(opt_in.context.participant_context.includes('guest') || opt_in.context.participant_context.includes('cleaner')) {
                                                email_guest = true;
                                            };
                                        };
                                        if(email_host && !_.isNil(opt_in.schedule) && !_.isEmpty(opt_in.schedule)) {
                                            email_host_schedule = true;
                                            email_host_availability.monday = opt_in.schedule.monday;
                                            email_host_availability.tuesday = opt_in.schedule.tuesday;
                                            email_host_availability.wednesday = opt_in.schedule.wednesday;
                                            email_host_availability.thursday = opt_in.schedule.thursday;
                                            email_host_availability.friday = opt_in.schedule.friday;
                                            email_host_availability.saturday = opt_in.schedule.saturday;
                                            email_host_availability.sunday = opt_in.schedule.sunday;
                                            email_host_availability.start = formatTime(opt_in.schedule.time_start_secs);
                                            email_host_availability.end = formatTime(opt_in.schedule.time_end_secs);
                                            email_host_availability.timeZone = opt_in.schedule.timezone;
                                        };
                                    };
                                    
                                    if(msg_type_id == _.get(data, 'subscriber.message_type_data.portal[0]._id')) {
                                        if(opt_in && _.get(opt_in, 'context.participant_context.length', '')) {
                                            if(opt_in.context.participant_context.includes('host')) {
                                                portal_host = true;
                                            };
                                            if(opt_in.context.participant_context.includes('guest') || opt_in.context.participant_context.includes('cleaner')) {
                                                portal_guest = true;
                                            };
                                        };
                                    };
                                })
                            }
                            
                        })
                    };

                    newSubCat[roleType][index1].splice(index2, 1, {
                        ...newSubCat[roleType][index1][index2],
                        sms_host: sms_host,
                        sms_guest: sms_guest,
                        email_host: email_host,
                        email_guest: email_guest,
                        portal_host: portal_host,
                        portal_guest: portal_guest,
                        sms_host_schedule: sms_host_schedule,
                        email_host_schedule: email_host_schedule,
                        sms_host_availability: sms_host_availability,
                        email_host_availability: email_host_availability
                    })
                })
            });
        }

        let teamMemberData = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            phone_number: phoneNumber,
            role_type: roleType,
            subscriberId: subscriberId
        };

        this.copyTeamMemberData = teamMemberData;
        this.copySubCategories = newSubCat;

        this.setState({
            teamMemberMode: 'edit',
            subCatActiveKey: '',
            subCategories: newSubCat,
            teamMemberErrorMsg: '',
            teamMemberDeleteErrorMsg: '',
            sourceTeamMemberData: data,
            teamMemberData: teamMemberData,
            teamMemberValidations: {
                subscriberId: false,
                first_name: false,
                last_name: false,
                email: false,
                phone_number: false,
                role_type: false,
                emailFormat: false,
                notificationPref: false,
            },
        }, () => {
            setTimeout(() => {
				document.getElementById('edit-team-member').scrollIntoView({
					block: "center",
					behavior: "smooth"
				});
			}, 500);
        })
    }

    renderSubCat = () => {
        const { 
            subCategories,
            teamMemberData,
            subCatActiveKey
        } = this.state;

        return Object.entries(subCategories).map((category) => {
            if(teamMemberData.role_type == category[0]) {
                return category[1].map((subCat, index1) => {
                    return (
                        <div key={index1} className='sub-cat-container'>
                            {
                                subCat.map((x, index2) => {
                                    const activeKey = x.id;
                                    let label = `${x && x.role_type}: ${x && x.category} ${x && x.subcategory}`;

                                    return (
                                        <Col key={index2} xs={6} className={`sub-cat-tab ${subCatActiveKey == activeKey ? 'open': ''}`}>
                                            <a 
                                                href="#"
                                                className='flex-align-items-center'
                                                onClick={e => this.subCatActiveKey(e, activeKey)}
                                            >
                                                <i className={`icon-gree_check ${(x.sms_host || x.sms_guest || x.email_host || x.email_guest || x.portal_host || x.portal_guest) ? 'checked' : ''}`}></i>
                                                <span className={`capitalize ${subCatActiveKey == activeKey ? 'bold-text' : ''}`}>{label}</span>
                                                <i className={subCatActiveKey == activeKey ? 'glyphicon glyphicon-chevron-down' : 'glyphicon glyphicon-chevron-up'}></i>
                                            </a>
                                        </Col>
                                    )
                                })
                            }
                            {
                                subCat.map((x, index2) => {
                                    const activeKey = x.id;

                                    return (
                                        <Col key={index2} xs={12} className={`no-padding sub-cat-body-container ${subCatActiveKey == activeKey ? 'open' : ''}`}>
                                            {
                                                (x && x.role_type != 'cleaner') ?
                                                <Fragment>
                                                    <Col md={12} className='sub-cat-title'>
                                                        {
                                                            (_.get(x, 'role_type') !== 'host') ?
                                                            <span>
                                                                Receive Guest Replies
                                                            </span>
                                                            :
                                                            <span>
                                                                Receive Message Sent to Guest
                                                            </span>
                                                        }
                                                    </Col>
                                                    <Col md={12} className='sub-cat-check-box-container'>
                                                        <div className='sub-cat-switch'>
                                                            {
                                                                this.renderSubCatSwitch('sms_host', `Receive via Text`, category[0], index1, index2, this.handleSubCat)
                                                            }
                                                        </div>
                                                        {
                                                            (_.get(x, 'role_type') !== 'host') &&
                                                            <div className='sub-cat-switch-schedule'>
                                                                {
                                                                    x.sms_host == true &&
                                                                    this.renderSubCatSwitch('sms_host_schedule', `Schedule`, category[0], index1, index2, this.handleSubCat)
                                                                }
                                                            </div>
                                                        }
                                                    </Col>
                                                        <div className='sub-cat-switch'>
                                                            {
                                                                x.sms_host_schedule == true &&
                                                                this.renderSubCatAvailability(x, 'sms_host_availability', category[0], index1, index2)
                                                            }
                                                        </div>
                                                    <Col md={12} className='sub-cat-check-box-container'>
                                                        <div className='sub-cat-switch'>
                                                            {
                                                                this.renderSubCatSwitch('email_host', `Receive via Email`, category[0], index1, index2, this.handleSubCat)
                                                            }
                                                        </div>
                                                        {
                                                            (_.get(x, 'role_type') !== 'host') &&
                                                            <div className='sub-cat-switch-schedule'>
                                                                {
                                                                    x.email_host == true &&
                                                                    this.renderSubCatSwitch('email_host_schedule', `Schedule`, category[0], index1, index2, this.handleSubCat)
                                                                }
                                                            </div>
                                                        }
                                                    </Col>
                                                        {
                                                            x.email_host_schedule == true &&
                                                            this.renderSubCatAvailability(x, 'email_host_availability', category[0], index1, index2)
                                                        }
                                                    <Col md={12} className='bottom-margin sub-cat-check-box-container'>
                                                        <div className='sub-cat-switch'>
                                                            {
                                                                this.renderSubCatSwitch('portal_host', `Receive Portal Message`, category[0], index1, index2, this.handleSubCat)
                                                            }
                                                        </div>
                                                    </Col>
                                                    {
                                                        (_.get(x, 'subcategory') !== 'inquiry' && _.get(x, 'role_type') !== 'host') &&
                                                        <Fragment>
                                                            <Col md={12} className='sub-cat-title'>
                                                                <span>
                                                                    Receive Message Sent to Guest
                                                                </span>
                                                            </Col>
                                                            <Col md={12} className='sub-cat-check-box-container'>
                                                                <div className='sub-cat-switch-custom'>
                                                                    <div className='switch'>
                                                                        <div className='right-margin'>Text</div>
                                                                        <Switch 
                                                                            onColor={"#69c"} 
                                                                            uncheckedIcon={false} 
                                                                            checkedIcon={false}
                                                                            height={25} 
                                                                            width={50}
                                                                            checked={x.sms_guest || false}
                                                                            onChange={bool => this.handleSubCat('sms_guest', category[0], index1, index2, 'switch', bool)}
                                                                        />
                                                                    </div>
                                                                    <div className='switch'>
                                                                        <div className='right-margin'>Email</div>
                                                                        <Switch 
                                                                            onColor={"#69c"} 
                                                                            uncheckedIcon={false} 
                                                                            checkedIcon={false}
                                                                            height={25} 
                                                                            width={50}
                                                                            checked={x.email_guest || false}
                                                                            onChange={bool => this.handleSubCat('email_guest', category[0], index1, index2, 'switch', bool)}
                                                                        />
                                                                    </div>
                                                                    <div className='switch'>
                                                                        <div className='right-margin'>Portal</div>
                                                                        <Switch 
                                                                            onColor={"#69c"} 
                                                                            uncheckedIcon={false} 
                                                                            checkedIcon={false}
                                                                            height={25} 
                                                                            width={50}
                                                                            checked={x.portal_guest || false}
                                                                            onChange={bool => this.handleSubCat('portal_guest', category[0], index1, index2, 'switch', bool)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Fragment>
                                                    }                                                   
                                                </Fragment>
                                                :
                                                <Fragment>
                                                    <Col md={6}>
                                                        <InputLabel className='capitalize top-margin-10'>
                                                            {`Receive ${x && x.category} ${x && x.subcategory}`}
                                                        </InputLabel>
                                                    </Col>
                                                    <Col md={6} className='sub-cat-check-box-container'>
                                                        <div className='sub-cat-switch-custom'>
                                                            <div className='switch'>
                                                                <div className='right-margin'>Text</div>
                                                                <Switch 
                                                                    onColor={"#69c"} 
                                                                    uncheckedIcon={false} 
                                                                    checkedIcon={false}
                                                                    height={25} 
                                                                    width={50}
                                                                    checked={x.sms_guest || false}
                                                                    onChange={bool => this.handleSubCat('sms_guest', category[0], index1, index2, 'switch', bool)}
                                                                />
                                                            </div>
                                                            <div className='switch'>
                                                                <div className='right-margin'>Email</div>
                                                                <Switch 
                                                                    onColor={"#69c"} 
                                                                    uncheckedIcon={false} 
                                                                    checkedIcon={false}
                                                                    height={25} 
                                                                    width={50}
                                                                    checked={x.email_guest || false}
                                                                    onChange={bool => this.handleSubCat('email_guest', category[0], index1, index2, 'switch', bool)}
                                                                />
                                                            </div>
                                                            <div className='switch'>
                                                                <div className='right-margin'>Portal</div>
                                                                <Switch 
                                                                    onColor={"#69c"} 
                                                                    uncheckedIcon={false} 
                                                                    checkedIcon={false}
                                                                    height={25} 
                                                                    width={50}
                                                                    checked={x.portal_guest || false}
                                                                    onChange={bool => this.handleSubCat('portal_guest', category[0], index1, index2, 'switch', bool)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Fragment>
                                            }
                                        </Col>
                                    )
                                })
                            }
                        </div>
                    )
                });
            }
        })
    }

    handleOnChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        this.setState({
            teamMemberErrorMsg: '',
            teamMemberData: {
                ...this.state.teamMemberData,
                [name]: value
            }
        }, () => {
            const { teamMemberValidations } = this.state;

            if(teamMemberValidations[name] || (name == 'email' && teamMemberValidations.emailFormat)) {
                this.setState({
                    teamMemberValidations: this.handleTeamMemberValidations()
                });
            };
        });
    }

    optInOnChange = (bool, name) => {
        this.setState({
            teamMemberErrorMsg: '',
            teamMemberData: {
                ...this.state.teamMemberData,
                [name]: bool
            }
        }, () => {
            const { teamMemberValidations } = this.state;

            if(teamMemberValidations.notificationPref || teamMemberValidations.email || teamMemberValidations.phone_number) {
                const teamMemberValidations = this.handleTeamMemberValidations();

                this.setState({
                    teamMemberValidations: {
                        ...notificationValidations,
                        ...teamMemberValidations
                    }
                });
            };
        });
    }

    phoneNumberOnChange = (value) => {
        const { teamMemberData } = this.state;

        this.setState({
            teamMemberData: {
                ...teamMemberData,
                phone_number: value
            }
        }, () => {
            const { teamMemberValidations } = this.state;

            if(teamMemberValidations.phone_number) {
                this.setState({
                    teamMemberValidations: this.handleTeamMemberValidations()
                });
            };
        });
    }

    handleTeamMemberValidations = () => {
        const { teamMemberData } = this.state;
        let validations = {};

        Object.keys(teamMemberData).forEach((key) => {
            if(key !== 'subscriberId') {
                if(key == 'email' || key == 'phone_number') {
                    if(key == 'email') {
                        if(!teamMemberData.phone_number && !teamMemberData.email) {
                            validations.email = true;
                        }
                        else if(teamMemberData.email.length && !this.validateEmail(teamMemberData.email)) {
                            validations['emailFormat'] = true;
                        }
                        else {
                            validations.email = false;
                            validations['emailFormat'] = false;
                        };
                    }
                    else if(key == 'phone_number') {
                        if(!teamMemberData.phone_number && !teamMemberData.email) {
                            validations.phone_number = true;
                        }
                        else {
                            validations.phone_number = false;
                            validations['emailFormat'] = false;
                        };
                    };
                }
                else {
                    if(!teamMemberData[key].length) {
                        validations[key] = true;
                    }
                    else {
                        validations[key] = false;
                    };
                };
            };
        });

        const subCatValidations = this.handleSubCatValidations()
        return {
            ...validations,
            ...subCatValidations
        };
    }

    renderSubCatSwitch = (name, label, role, index1, index2, func) => {
        const { subCategories } = this.state;

        return (
            <div className='switch'>
                <div className='right-margin'>{label}</div>
                <Switch 
                    onColor={"#69c"} 
                    uncheckedIcon={false} 
                    checkedIcon={false}
                    height={25} 
                    width={50}
                    checked={subCategories[role][index1][index2][name] || false}
                    onChange={bool => func(name, role, index1, index2, 'switch', bool)}
                />
            </div>
        );
    }

    subCatActiveKey = (e, key) => {
        e.preventDefault();
        const { subCatActiveKey } = this.state;
        const newKey = key !== subCatActiveKey ? key : '';

        this.setState({
            subCatActiveKey: newKey
        });
    }

    handleSubCat = (name, role, index1, index2, type, bool) => {
        const { subCategories } = this.state;
        let copyArr = _.cloneDeep(subCategories);
        let value = type == 'checkbox' ? !copyArr[role][index1][index2][name] : bool;

        let data = {
            ...copyArr[role][index1][index2],
            [name]: value
        };

        if(name.includes('email') || name.includes('sms')) {
            if(copyArr[role][index1][index2][name] == true) {
                data = {
                    ...data,
                    [`${name}_schedule`]: false
                };
            };
        };

        copyArr[role][index1].splice(index2, 1, data);

        this.setState({
            subCategories: {
                ...copyArr
            }
        }, () => {
            const { teamMemberValidations } = this.state;

            if(teamMemberValidations.subCategories) {
                let validations = this.handleSubCatValidations();

                this.setState({
                    teamMemberValidations: {
                        ...teamMemberValidations,
                        ...validations
                    }
                });
            };

        });
    }

    renderSubCatAvailability = (data, name, role, index1, index2) => {
        const SUB_WEEK = [
            { name: 'week', value: 'monday', label: 'M'},
            { name: 'week', value: 'tuesday', label: 'T'},
            { name: 'week', value: 'wednesday', label: 'W'},
            { name: 'week', value: 'thursday', label: 'T'},
            { name: 'week', value: 'friday', label: 'F'},
            { name: 'week', value: 'saturday', label: 'S'},
            { name: 'week', value: 'sunday', label: 'Su'},
        ];

        let subTime = [];
        Constants.TIME_15.forEach((x, i) => {
            subTime.push(<option key={i} value={x.value}>{x.label}</option>)
        });
        let subTimeZone = [];
        Constants.TIME_ZONE.forEach((x, i) => {
            subTimeZone.push(<option key={i} value={x.value}>{x.label}</option>)
        });

        return (
            <Col md={12} className='sub-cat-avail'>
                <Col md={12} className='small-text no-padding'>
                    Available to Reply to Guest Message: {" "}{infoPopover(this.subCatInfo, null, "top", "icon-question-mark")}
                </Col>
                <Col md={12} className='sub-cat-week no-padding'>
                    {
                        SUB_WEEK.map((x) => {
                            return this.renderWeeklyCheckbox(x, name, role, index1, index2)
                        })
                    }
                    <div className='sub-cat-time'>
                        <InputLabel>
                            Start
                        </InputLabel>
                        <InputField
                            name="start"
                            type="select" 
                            value={data[name].start} 
                            onChange={(e) => this.handleSubCatAvailability(e, 'start', name, role, index1, index2, 'select')}
                        >
                            {subTime}
                        </InputField>
                    </div>
                    <div className='sub-cat-time'>
                        <InputLabel>
                            End
                        </InputLabel>
                        <InputField
                            name="end"
                            type="select" 
                            value={data[name].end} 
                            onChange={(e) => this.handleSubCatAvailability(e, 'end', name, role, index1, index2, 'select')}
                        >
                            {subTime}
                        </InputField>
                    </div>
                    <div className='sub-cat-time tz'>
                        <InputLabel>
                            Time Zone
                        </InputLabel>
                        <InputField
                            name="timeZone"
                            type="select" 
                            value={data[name].timeZone} 
                            onChange={(e) => this.handleSubCatAvailability(e, 'timeZone', name, role, index1, index2, 'select')}
                        >
                            {subTimeZone}
                        </InputField>
                    </div>
                </Col>
            </Col>
        )
    }

    renderWeeklyCheckbox = (x, cat, role, index1, index2) => {
        const { subCategories } = this.state;
        return (
            <div key={x.value} className="filters-wrap">
                <label 
                    className='checkbox-wrap checkbox-input'
                >
                    <input 
                        type="checkbox" 
                        onChange={() =>this.handleSubCatAvailability('', x.value, cat, role, index1, index2, 'checkbox')}
                        checked={subCategories[role][index1][index2][cat][x.value] ? true : false}
                    />
                    <div className="checkbox-label">{x.label}</div>
                </label>
            </div>
        );
    }

    handleSubCatAvailability = (e, name, cat, role, index1, index2, type) => {
        const { subCategories } = this.state;
        let copyArr = _.cloneDeep(subCategories);
        let value = type == 'checkbox' ? !copyArr[role][index1][index2][cat][name] : e.target.value;

        copyArr[role][index1][index2][cat][name] = value;

        this.setState({
            subCategories: {
                ...copyArr
            }
        });
    }

    handleCancelTeamMember = (e) => {
        e && e.preventDefault();

        this.resetTeamMember();

        this.setState({ 
            teamMemberMode: 'create',
        });
    }

    handleSelectedTeamMember = (e, data, type) => {
        e.preventDefault();

        this.setState({
            teamMemberErrorMsg: '',
            teamMemberDeleteErrorMsg: '',
            selectedTeamMember: data
        }, () => {
            if(type == 'remove-tm') {
                this.handleModal('', 'remove-tm');
            }
            else if ('add-tm-property') {
                const { 
                    selectedTeamMember,
                    teamMemberPropertiesList,
                } = this.state;
                let teamMemberProperties = [];

                if(selectedTeamMember && selectedTeamMember.opt_ins && selectedTeamMember.opt_ins.length) {
                    selectedTeamMember.opt_ins.forEach((optin) => {
                        if(optin && optin.internal_id) {
                            teamMemberProperties.push(optin.internal_id);
                        };
                    });
                };

                let newTeamMemberProperties = [...new Set(teamMemberProperties)];

                let optionsValue = [];
                if(!newTeamMemberProperties.length) {
                    optionsValue = [...teamMemberPropertiesList];
                }
                else {
                    optionsValue = teamMemberPropertiesList.filter((prop) => newTeamMemberProperties.includes(prop.property_id.toString()));
                };

                this.copyTeamMemberPropertyOptionsValue = optionsValue;

                this.setState({
                    teamMemberPropertyOptionsValue: optionsValue
                }, () =>{
                    this.handleModal('', 'add-tm-property');
                })   
            };
        });
    }

    resetTeamMember = () => {
        this.copyTeamMemberData = '';

        this.setState({
            sourceTeamMemberData: {},
            subCatActiveKey: '',
            subCategories: this.initSubCategories,
            teamMemberData: {
                first_name: '',
                last_name: '',
                email: '',
                phone_number: '',
                role_type: '',
            },
            teamMemberValidations: {
                subscriberId: false,
                first_name: false,
                last_name: false,
                email: false,
                phone_number: false,
                role_type: false,
                emailFormat: false,
                notificationPref: false,
            },
            teamMemberErrorMsg: ''
        });
    }

    handleEnableCommHub = (bool) => {
        this.setState({
            commHubEnabled: bool
        }, () => {
            this.handleModal('', bool == true ? 'enable-commhub-modal' : '');
        });
    }

    handleCommHubUsersOptions = (value) => {
        this.setState({
            commHubUsersOptionsValue: value
        });
    }

    renderMsg = (msg) => {
        return (
            <Row>
                <Col xs={12} sm={12} className='top-margin'>
                    <div className='msg-container _error'>
                        {msg}
                    </div>
                </Col>
            </Row>
        )
    }

    validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        
        return emailRegex.test(email);
    }

    handleSubCatValidations = () => {
        const {
            subCategories,
            teamMemberData
        } = this.state;
        let subCat = [];

        if(teamMemberData.role_type) {
            subCategories[teamMemberData.role_type].forEach((arr) => {
                arr.forEach((sub) => {
                    if(sub.email_host || sub.email_guest || sub.sms_host || sub.sms_guest || sub.portal_host || sub.portal_guest) {
                        subCat.push(arr);
                    };
                });
            });
        }
        

        return {
            subCategories: subCat.length ? false : true
        };
    }

    renderStarsReview = (rating) => {
        return [1,2,3,4,5].map((star) => {
            return <i key={star} className={`icon-star-full ${star <= rating ? 'fill': ''}`}></i>
        })
    }

    handleFilterRatings = (values) => {
        this.setState({
            reviewsRatingsOptionsValue: values
        }, () => {
            const { data } = this.state;
            let config = {};
            let levels = [];

            if(_.get(values, 'length')) {
                values.forEach((value) => {
                    levels.push(value.value)
                });
            };

            if(_.get(data, "property_manager_details.review_config")) {
                config = {
                    ...data.property_manager_details.review_config,
                    auto_reply_review_rating_levels: levels
                };
            }
            else {
                config = {
                    auto_reply_delay_mins: "",
                    auto_reply_review_rating_levels: levels,
                    host_managed: "",
                };
            };
    
            this.setState({
                data: {
                    ...data,
                    property_manager_details: {
                        ...data.property_manager_details,
                        review_config: config
                    }
                }
            });
        });
    }

    addOrgSmartSiteOptions = (org) => {
        let options = [];

        if(_.get(org, 'manager_uid')) {
            options.push({
                label: org.organization_name,
                value: `${org.organization_name} (${org.manager_uid})`,
                uid: org.manager_uid
            });
        };
        
        if(_.get(org, 'child_organizations.length') > 0) {
            org.child_organizations.forEach(childOrg => {
                options = options.concat(this.addOrgSmartSiteOptions(childOrg));
            });
        };

        return options;
    }

    handleAddOrgSmartSite = (values) => {
        this.setState({
            addOrgSmartSiteOptionsValue: values
        }, () => {
            if(_.get(values, 'length')) {
                const { data } = this.state;
                let uids = [];

                values.forEach((value) => {
                    uids.push(value.uid)
                });

                this.setState({
                    data: {
                        ...data,
                        property_manager_details: {
                            ...data.property_manager_details,
                            smartsite_additional_uids: uids,
                        }
                    }
                })
            };
        })
    }

    render() {
        const {
            mode,
            data, 
            error,
            modal,
            isLoading, 
            altLoading,
            churnData,
            teamMembers,
            subCategories,
            teamMembersColumns,
            sourceTeamMemberData,
            teamMemberValidations,
            teamMemberPropertiesIsLoading,
            teamMemberPropertyOptionsValue,
            teamMemberPropertiesErrorMsg,
            teamMemberPropertiesList,
            teamMemberMode,
            integrationStatus,
            checkinInstructionsData,
            enablePeriodicPayouts,
            teamMemberData,
            selectedTeamMember,
            commHubEnabled,
            commHubUsersOptions,
            commHubUsersOptionsValue,
            organization,
            enableCommHubIsLoading,
            enableCommHubErrMsg,
            teamMembersIsLoading,
            teamMemberErrorMsg,
            teamMemberDeleteErrorMsg,
            reviewsRatingsOptionsValue,
            addOrgSmartSiteOptionsValue,
            cancelPolicyDataPlaceholder
        } = this.state;

        let breadcrumbItems;
        const imageFormats = Constants.IMAGE_FORMATS.toString();
        const guestContactInformation = [
            {name: "share_contact_status", value: "1", label: "Show Immediately for all Reservations"},
            {name: "share_contact_status", value: "0", label: "Hide until 30 Days Before Check-in Date"},
        ];

        const fullService = [
            {name: "full_service", value: "1", label: "Yes"},
            {name: "full_service", value: "0", label: "No"},
        ];

        const autoPublish = [
			{name: "auto_publish", value: "1", label: "Yes"},
			{name: "auto_publish", value: "0", label: "No"},
        ];

        const includeCleaningFee = [
            {name: "has_cleaning_fee", value: "1", label: "Yes"},
            {name: "has_cleaning_fee", value: "0", label: "No"},
        ];

        const propertyValidationIncludeTaxes = [
            {name: "has_tax", value: "1", label: "Yes"},
            {name: "has_tax", value: "0", label: "No"},
        ];
        
        const smartSite = [
            {name: "ra_collect", value: "1", label: "RedAwning"},
            {name: "ra_collect", value: "0", label: "Organization"},
        ];

        const useCommissionsForAdditionalFees = [
            {name: "use_commissions_for_additional_fees", value: '1', label: "Yes"},
            {name: "use_commissions_for_additional_fees", value: '0', label: "No"},
        ];

        const enablePeriodicPayoutsOptions = [
            {name: "enablePeriodicPayouts", value: '1', label: "Yes"},
            {name: "enablePeriodicPayouts", value: '0', label: "No"},
        ];

        const showChannelSourceOptions = [
            {name: "show_channel_source", value: '1', label: "Yes"},
            {name: "show_channel_source", value: '0', label: "No"},
        ];

        const hostManagedOptions = [
            {name: "host_managed", value: '1', label: "Yes"},
            {name: "host_managed", value: '0', label: "No"},
        ];

        // Guest Involvement
        let guestInvolvement = [];
        Constants.GUEST_INVOLVEMENT.map((guest, i) => {
            guestInvolvement.push(<option key={i + 1} value={guest.value}>{guest.label}</option>);
        });

        // Integration Names
        let integrationNames = [];
        this.state.integrationNames.sort().map((name, i) => {
            integrationNames.push(<option key={i} value={name}>{name}</option>);
        });

        // Integration Calendar
        let integrationCalendar = [];
        Object.keys(this.state.integrationCalendar).sort().map((calendar, i) => {
            integrationCalendar.push(<option key={i} value={calendar}>{calendar}</option>);
        });

        // Integration Partner
        let integrationPartner = [<option key={0} value={""}></option>];
        this.state.integrationPartner.map((partner, i) => {
            integrationPartner.push(<option key={i + 1} value={partner}>{partner}</option>);
        });

		// Block Future Calendar
		let blockFeatureCalendar = [];
		Constants.BLOCK_FUTURE_CALENDAR.map((days, i) => {
			blockFeatureCalendar.push(<option key={i} value={days.value}>{days.label}</option>)
        });

        // Turnover Block
		let turnoverBlock = [<option key={0} value={''}>{''}</option>];
		for (let i = 1; i <= 7; i++) {
			turnoverBlock.push(<option key={i} value={i}>{i}</option>);
		};
        
        // Free Cancel Policy
        let freeCancelPolicy = [];
		Constants.FREE_CANCEL_POLICY.map((policy, i) => {
			freeCancelPolicy.push(<option key={i} data-value={policy.value} value={policy.value}>{policy.label}</option>)
        });

        // Rental Policy
        let rentalPolicies = [];
        Constants.RENTAL_POLICIES.map((policy, i) => {
			rentalPolicies.push(<option key={i} value={policy.value}>{policy.label}</option>)
        });

        // Free Cancel Info
        let freeCancelInfo = [];
        Constants.FREE_CANCEL_INFO.map((info, i) => {
            freeCancelInfo.push(<option key={i} value={info.value}>{info.label}</option>)
        }) 

        // Churn Reason
        let churnReasons = [];
        Constants.CHURN_REASONS.map((reason, i) => {
            churnReasons.push(<option key={i} value={reason.value}>{reason.label}</option>)
        });

        let primaryRolesOptions = [<option key={0} value="" disabled></option>];

        this.initPrimaryRoles.forEach(function (type) {
            primaryRolesOptions.push(<option key={type.key} value={type.value}>{type.key}</option>);
        });

        return (
            <PageLayout isCentered={true}>

                {(isLoading || altLoading) && <Loader />}

                {
                    error && 
                    <div className="container-fluid">
                        <Title 
                            title="Edit Organization"
                            isCentered={true}
                            removeOrganizationDropdown={true}
                            addDefaultTitle="Account Management"
                            breadcrumbItems={this.breadcrumbItems}
                        />
                        <div id="content" style={{ height: '70vh' }}>
                            <Col>
                                <div className="no-collection">Organization not found.</div>
                            </Col>
                        </div>
                    </div>
                }

                {
                    !isLoading && 
                    !error && 
                    <div className="organizations container-fluid">
                    <Title 
                        title=
                            {
                            <Fragment>
                                {(this.state.mode !== "edit") && (this.state.mode !== "clone") ? 
                                    "Create Organization" : ""}
                                {(this.state.mode === "edit") || (this.state.mode === "clone") ?
                                    `${(this.state.mode === "clone") ? "Clone" : "Edit"} Organization ${data.manager_uid ? data.manager_uid : ""}` : ""}
                            </Fragment>
                            } 
                        isCentered={true}
                        removeOrganizationDropdown={true}
                        addDefaultTitle="Account Management"
                        breadcrumbItems={breadcrumbItems}
                    >
                        <div className="top-left-content">
                            {
                                this.state.data &&
                                this.state.data.property_manager_details &&
                                this.state.data.property_manager_details.account_status &&
                                this.state.data.property_manager_details.account_status.toLowerCase() !== "canceled" ?
                                <PropertyStatusButton 
                                    status={data.property_manager_details.account_status} 
                                    toggleStatus={this.toggleStatus} 
                                    admin={this.createUpdateOrganizations}
                                    loading={altLoading}
                                    level={'org'}
                                    mode={this.state.mode}
                                />
                            :
                                <div className="property-status">
                                    <span>Status</span>
                                    <OverlayTrigger 
                                        placement='right' 
                                        overlay={this.getTooltip('canceled', 'Organization is canceled. Please contact Customer Support.')}
                                    >
                                        <div
                                            className="primary-btn active-btn live-btn disabled"
                                        >
                                            Canceled
                                        </div>
                                    </OverlayTrigger>
                                </div> 
                            }
                        </div>
                        {
                            (this.createUpdateOrganizations &&
                            this.state.mode === "edit") && 
                            <DropdownButton  
                                title={'Actions'}
                                className="primary-btn white-btn"
                                id="property-actions"
                                disabled={
                                    this.state.data &&
                                    this.state.data.property_manager_details &&
                                    this.state.data.property_manager_details.account_status &&
                                    this.state.data.property_manager_details.account_status.toLowerCase() === "canceled" ||
                                    isLoading || 
                                    altLoading
                                }
					        >
                                <li role="presentation">
                                    <Link
                                        to={
                                            { pathname: "/account-management/organizations/create",
                                                state: { 
                                                    cloneData: { 
                                                        organization: this.state.organization, 
                                                        channelOptInMarkupData: this.state.channelOptInMarkupData,
                                                        integrationStatus: this.state.integrationStatus,
                                                        checkinInstructionsData: this.state.checkinInstructionsData,
                                                    } 
                                                }
                                            }
                                        }
                                    > 
                                        Clone Organization 
                                    </Link>
                                </li>
                                <MenuItem onClick={e => this.routeToUserOrProperties(e, "user")}>
                                    View Users
                                </MenuItem>
                                <MenuItem onClick={e => this.routeToUserOrProperties(e, "properties")}>
                                    View Properties
                                </MenuItem>
                                <MenuItem onClick={e => this.clearCache(e)}>
                                    Clear Cache
                                </MenuItem>
                                <MenuItem onClick={e => this.showActiveModal("shutdown-org-modal")}>
                                    Shutdown Organization
                                </MenuItem>
					        </DropdownButton>
                        }
                        <Link 
                            to={"/account-management/organizations"} 
                            className="primary-btn black-btn pull-left left-margin">
                            Cancel
                        </Link>
                        {
                            this.createUpdateOrganizations && 
                            <PrimaryButton 
                                cssClass="pull-left left-margin" 
                                fullWidth={false} 
                                type="button" 
                                onClick={this.handleFormSubmit}
                                disabled={
                                    (this.state.mode !== "clone" &&
                                    !this.compareState()) ||
                                    (this.state.data &&
                                    this.state.data.property_manager_details &&
                                    this.state.data.property_manager_details.account_status && 
                                    this.state.data.property_manager_details.account_status.toLowerCase() === "canceled") ||
                                    isLoading || 
                                    altLoading
                                }
                            >
                                Save
                            </PrimaryButton>
                        }
                    </Title>

                    <div id="content">
                        <div className={"property-item row" + (!this.createUpdateOrganizations ? " readonly" : "")}>
                            <div className="addOrganization">
                            <FieldWrap>
                                <Form horizontal>
                                    <Row>
                                        <Col md={12} xs={12} sm={12} lg={12}>
                                            <PanelGroup 
                                                accordion 
                                                id="accordion-controlled-example"
                                                activeKey={this.state.activeKey}
                                                onSelect={activeKey => this.panelOnSelect(activeKey)}
                                            >
                                                <Panel eventKey="1">
                                                    <Panel.Heading>
                                                        <Panel.Title 
                                                            toggle
                                                            onClick={e => this.panelOnSelect(null, e, "1")}
                                                        > 
                                                            Organization Details
                                                                <span 
                                                                    className={`pull-right ${this.state.activeKey === "1" || this.state.init ? "glyphicon glyphicon-chevron-up" : "glyphicon glyphicon-chevron-down" }`}>
                                                                </span>
                                                        </Panel.Title>
                                                    </Panel.Heading>
                                                    <Panel.Body collapsible>
                                                        <Row>
                                                            <Col md={6} className="prop-type-col">
                                                                <InputLabel
                                                                    htmlFor="title"
                                                                    className={this.state.validations.title ? 'form-validation' : ''}
                                                                >
                                                                    Organization Name
                                                                </InputLabel>
                                                                <InputField 
                                                                    type="text"
                                                                    className={this.state.validations.title ? 'form-validation' : ''}
                                                                    name="title" 
                                                                    value={data.title}
                                                                    onChange={this.handleDataChange}
                                                                />
                                                                {this.state.validations.title && <span className="required-error-text">Organization Name is required</span>}
                                                            </Col>
                                                        </Row>
                                                        <br/>
                                                        <Row>
                                                            <Col md={6} className="prop-type-col">
                                                                <InputLabel 
                                                                    htmlFor="organization_name"
                                                                    className={this.state.validations.organization_name ? 'form-validation' : ''}
                                                                >
                                                                    Organization ID
                                                                </InputLabel>
                                                                <InputField 
                                                                    type="text"
                                                                    className={this.state.validations.organization_name ? 'form-validation' : ''}
                                                                    name="organization_name"
                                                                    value={data.organization_name} 
                                                                    onChange={this.handleDataChange}
                                                                />
                                                                {this.state.validations.organization_name && <span className="required-error-text">Organization ID is required</span>}
                                                            </Col>
                                                        </Row>
                                                        <br />
                                                        <Row>
                                                            <Col md={6} className="prop-type-col">
                                                                <InputLabel 
                                                                    htmlFor="title"
                                                                    className={this.state.validations.parent ? 'form-validation' : ''}
                                                                >
                                                                    Organization Parent
                                                                </InputLabel>
                                                                <Picky
                                                                    className={this.state.validations.parent ? 'form-validation' : ''}
                                                                    placeholder=""
                                                                    labelKey="title"
                                                                    valueKey="organization_name"
                                                                    filterPlaceholder="Search by Organization Name"
                                                                    options={this.parentOrgOptions}
                                                                    value={Object.keys(this.state.selectedOrganization).length ? 
                                                                        (this.state.selectedOrganization.title ? this.state.selectedOrganization.title : this.state.selectedOrganization.organization_name) : 
                                                                        data.parent}
                                                                    multiple={false}
                                                                    includeSelectAll={false}
                                                                    defaultFocusFilter={true}
                                                                    includeFilter={true}
                                                                    keepOpen={false}
                                                                    onChange={value => this.handleOrgParentChange(value)}
                                                                    dropdownHeight={600}
                                                                    render={({
                                                                        style,
                                                                        item,
                                                                        isSelected,
                                                                        selectValue,
                                                                        labelKey,
                                                                        valueKey,
                                                                      }) => {
                                                                        return (
                                                                          <li
                                                                            style={{ ...style }} 
                                                                            className={isSelected ? "selected" : ""} 
                                                                            key={item[valueKey]}
                                                                            onClick={() => selectValue(item)}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontWeight: isSelected ? "bold" : "normal"
                                                                              }}
                                                                            >
                                                                              {item[labelKey] !== null && item[labelKey] !== "" ? item[labelKey] : item[valueKey]}
                                                                            </span>
                                                                          </li>
                                                                        );
                                                                    }}
                                                                />
                                                                {this.state.validations.parent && <span className="required-error-text">Organization Parent is required</span>}
                                                            </Col>
                                                        </Row>
                                                        <br/>
                                                        <Row>
                                                            <Col md={6} className="prop-type-col">
                                                                <InputLabel 
                                                                    htmlFor="contact_email"
                                                                    className={this.state.validations.contact_email ? 'form-validation' : ''}
                                                                >
                                                                    Organization Email Address
                                                                </InputLabel>
                                                                <InputField 
                                                                    type="text"
                                                                    className={this.state.validations.contact_email ? 'form-validation' : ''}
                                                                    name="contact_email" 
                                                                    value={data.contact_email} 
                                                                    onChange={this.handleDataChange}
                                                                />
                                                                {this.state.validations.contact_email && <span className="required-error-text">Organization Email Address is required</span>}
                                                                {this.state.validEmailFormat &&
                                                                 !this.state.validations.contact_email &&
                                                                    <span className="col-md-12 error-text">
                                                                        {Constants.EMAIL_INVALID_MESSAGE}
                                                                    </span>}
                                                            </Col>
                                                        </Row>
                                                        <br/>
                                                        <Row>
                                                            <Col md={6}>
                                                            <InputLabel 
                                                                    htmlFor="phone_office"
                                                                >
                                                                    Organization Primary Phone <em className="optional">(Optional)</em>
                                                                </InputLabel>
                                                                <InputField 
                                                                    type="text"
                                                                    name="phone_office" 
                                                                    value={data && data.property_manager_details && data.property_manager_details.phone_office || ""} 
                                                                    onChange={this.handleDataChange}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <br/>
                                                        <Row>
                                                            <Col xs={12} md={6} className="field-wrapper">
                                                                <InputLabel 
                                                                    htmlFor="organization_logo" 
                                                                    cssClass="custom-label"
                                                                >
                                                                    Organization Logo <em className="optional">(Optional)</em>
                                                                </InputLabel>
                                                                <div className="file-input-wrap">
                                                                    <Dropzone
                                                                        inputProps={{name: "organization_logo"}}
                                                                        style={{height: "32px"}}
                                                                        multiple={false}
                                                                        accept={imageFormats}
                                                                        onDrop={this.updateImage}>

                                                                        <div className="file-input">
                                                                            <span className="file-name">{(this.state.newImage && this.state.newImage.file && this.state.newImage.file.name) || ""}</span>
                                                                            <span className="browse-btn">Browse</span>
                                                                        </div>

                                                                    </Dropzone>
                                                                </div>
                                                                <div 
                                                                    className={`image-preview ${(this.state.newImage && this.state.newImage.file && this.state.newImage.file.preview) || _.get(this.state.image) ? "image-preview-border" : ""}`}
                                                                >
                                                                    {(this.state.newImage && this.state.newImage.file && this.state.newImage.file.preview) || (Object.keys(this.state.image)).length ? 
                                                                        <img
                                                                            onLoad={this.getDimensions}
                                                                            id="organization_logo" 
                                                                            src={(this.state.newImage && this.state.newImage.file && this.state.newImage.file.preview) || this.state.image} 
                                                                            alt="Error Loading Image"
                                                                        />
                                                                            : 
                                                                        <span><Glyphicon glyph="picture"/> Image Preview</span>}
																</div>
                                                                <div 
                                                                    className="image-dimensions"
                                                                >
                                                                    {(this.state.newImage && this.state.newImage.file && this.state.newImage.file.preview) ? this.state.imageDimension : ""}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <br/>
                                                        <Row>
                                                            <Col md={6}>
                                                            <InputLabel 
                                                                    htmlFor="guest_involvement"
                                                                >
                                                                   Guest Involvement
                                                                </InputLabel>
                                                                <InputField 
                                                                    type="select"
                                                                    name="guest_involvement" 
                                                                    value={data && data.property_manager_details && data.property_manager_details.guest_involvement || ""} 
                                                                    onChange={this.handleDataChange}
                                                                >
                                                                    {guestInvolvement}
                                                                </InputField>
                                                            </Col>
                                                        </Row>
                                                        <br/>
                                                        <Row>
                                                            <Col sm={6} xs={6}>
                                                                <InputLabel>
                                                                    Guest Contact Information
                                                                </InputLabel>
                                                                {this.createDefaultRadioButton(guestContactInformation, data.property_manager_details.share_contact_status, this.handleDataChange)}
                                                            </Col>
                                                        </Row>
                                                        <br/>
                                                        <Row>
                                                            <Col sm={6} xs={6}>
                                                                <InputLabel>
                                                                    Full Service
                                                                </InputLabel>
                                                                {this.createDefaultRadioButton(fullService, data.property_manager_details.full_service, this.handleDataChange)}
                                                            </Col>
                                                        </Row>
                                                    </Panel.Body>
                                                </Panel>
                                                <Panel eventKey={this.state.init ? '1' : '2'}>
                                                    <Panel.Heading>
                                                        <Panel.Title 
                                                            toggle
                                                            onClick={e => this.panelOnSelect(null, e, "2")}
                                                        > 
                                                            Integration
                                                                <span 
                                                                    className={`pull-right ${this.state.activeKey === "2" || this.state.init ? "glyphicon glyphicon-chevron-up" : "glyphicon glyphicon-chevron-down" }`}>
                                                                </span>
                                                        </Panel.Title>
                                                    </Panel.Heading>
                                                    <Panel.Body collapsible>
                                                        <Row>
                                                            <Col md={6} className="prop-type-col">
                                                                <InputLabel 
                                                                    htmlFor="integration_name"
                                                                    className={this.state.validations.integration_name ? 'form-validation' : ''}
                                                                >
                                                                    Integration Name
                                                                </InputLabel>
                                                                <InputField 
                                                                    type="select"
                                                                    className={this.state.validations.integration_name ? 'form-validation' : ''}
                                                                    name="integration_name" 
                                                                    value={data && data.property_manager_details && data.property_manager_details.integration_name} 
                                                                    onChange={this.handleDataChange}
                                                                >
                                                                    <option value="" hidden></option>
                                                                    {integrationNames}
                                                                </InputField>
                                                                {this.state.validations.integration_name && <span className="required-error-text">Integration Name is required</span>}
                                                            </Col>
                                                        </Row>
                                                        <br/>
                                                        {
                                                            (data && data.property_manager_details && data.property_manager_details.integration_name !== "racalendar") &&
                                                            <Fragment>
                                                            <Row>
                                                                <Col md={6} className="prop-type-col">
                                                                    <InputLabel 
                                                                        htmlFor="integrationStatus"
                                                                    >
                                                                        Integration Status
                                                                    </InputLabel>
                                                                    <div
                                                                        className="property-status" 
                                                                        style={{ cursor: 'pointer' }}
                                                                    >
                                                                    <div
                                                                        className={`primary-btn active-btn live-btn ${integrationStatus == "1" ? ' is-live' : ' not-live'}`} 
                                                                        onClick={() => this.toggleIntegrationStatus()}
                                                                    >
                                                                        {integrationStatus == "1" ? 
                                                                            'Live': 
                                                                            'Not Live'}
                                                                        <i className="icon icon-selection_tip"></i>
                                                                    </div>
                                                                </div>
                                                                </Col>
                                                            </Row>
                                                            <br/>
                                                            <Row>
                                                                <Col md={6} className="prop-type-col">
                                                                    <InputLabel 
                                                                        htmlFor="publish"
                                                                    >
                                                                        Auto-Publish Properties
                                                                    </InputLabel>
                                                                    {this.createDefaultRadioButton(autoPublish, data && data.property_manager_details && data.property_manager_details.auto_publish, this.handleDataChange)}
                                                                </Col>
                                                            </Row>
                                                            <br/>
                                                            </Fragment>
                                                        }
                                                        <Row>
                                                            <Col md={6} className="prop-type-col">
                                                                <InputLabel 
                                                                    htmlFor="calendar_format"
                                                                    className={this.state.validations.calendar_format ? 'form-validation' : ''}
                                                                >
                                                                    Integration Calendar
                                                                </InputLabel>
                                                                <InputField 
                                                                    type="select"
                                                                    className={this.state.validations.calendar_format ? 'form-validation' : ''}
                                                                    name="calendar_format" 
                                                                    value={data && data.property_manager_details && data.property_manager_details.calendar_format} 
                                                                    onChange={this.handleDataChange}
                                                                >
                                                                    <option value="" hidden></option>
                                                                    {integrationCalendar}
                                                                </InputField>
                                                                {this.state.validations.calendar_format && <span className="required-error-text">Integration Calendar is required</span>}
                                                            </Col>
                                                        </Row>
                                                        {data && data.property_manager_details &&
                                                            data.property_manager_details.integration_name !== "racalendar" &&
                                                            <Fragment>
                                                                <br/>
                                                                <Row>
                                                                    <Col md={6} className="prop-type-col">
                                                                        <InputLabel 
                                                                            htmlFor="integration_partner"
                                                                        >
                                                                            Integration Partner <em className="optional">(Optional)</em>
                                                                        </InputLabel>
                                                                        <InputField 
                                                                            type="select"
                                                                            name="integration_partner" 
                                                                            value={_.get(data, 'property_manager_details.integration_partner') || ''} 
                                                                            onChange={this.handleDataChange}
                                                                        >
                                                                            <option value="" hidden></option>
                                                                            {integrationPartner}
                                                                        </InputField>
                                                                    </Col>
                                                                </Row>
                                                                <br/>
                                                                <Row>
                                                                    <Col md={6} className="prop-type-col">
                                                                        <InputLabel 
                                                                            htmlFor="integration_client_code"
                                                                            className={this.state.validations.integration_client_code ? 'form-validation' : ''}
                                                                        >
                                                                            Integration Client Code
                                                                        </InputLabel>
                                                                        <InputField 
                                                                            type="text"
                                                                            className={this.state.validations.integration_client_code ? 'form-validation' : ''}
                                                                            name="integration_client_code" 
                                                                            value={data && data.property_manager_details && data.property_manager_details.integration_client_code} 
                                                                            onChange={this.handleDataChange}
                                                                        /> 
                                                                        {this.state.validations.integration_client_code && <span className="required-error-text">Integration Client Code is required</span>}                                                             
                                                                    </Col>
                                                                </Row>
                                                            </Fragment>}
                                                    </Panel.Body>
                                                </Panel>
                                                <Panel eventKey={this.state.init ? '1' : '3'}>
                                                    <Panel.Heading>
                                                        <Panel.Title 
                                                            toggle
                                                            onClick={e => this.panelOnSelect(null, e, "3")}
                                                        > 
                                                            Policies
                                                                <span 
                                                                    className={`pull-right ${this.state.activeKey === "3" || this.state.init ? "glyphicon glyphicon-chevron-up" : "glyphicon glyphicon-chevron-down" }`}>
                                                                </span>
                                                        </Panel.Title>
                                                    </Panel.Heading>
                                                    <Panel.Body collapsible>
                                                        <Row>
                                                            <Col md={4}>
                                                                <InputLabel>
                                                                    Commission & Fees
                                                                </InputLabel>
                                                                <InputField 
                                                                    type="select"
                                                                    name="rental_policy" 
                                                                    value={_.get(data, 'property_manager_details.rental_policy') || ''}
                                                                    onChange={this.handleDataChange}
                                                                >
                                                                    {rentalPolicies}
                                                                </InputField>
                                                            </Col>
                                                            <Col md={4}>
                                                                <InputLabel 
                                                                    htmlFor="free_cancel_policy"
                                                                >
                                                                    Cancellation Policy
                                                                </InputLabel>
                                                                <InputField 
                                                                    type="select"
                                                                    name="free_cancel_policy" 
                                                                    value={_.get(data, 'property_manager_details.free_cancel_policy') || ''}
                                                                    onChange={this.handleCancelPolicyPrompt}
                                                                >
                                                                    {freeCancelPolicy}
                                                                </InputField>
                                                            </Col>
                                                            <Col md={4}>
                                                                <InputLabel>
                                                                    Free Cancel Status
                                                                </InputLabel>
                                                                <InputField 
                                                                    type="select"
                                                                    name="free_cancel_info" 
                                                                    value={_.get(data, 'property_manager_details.free_cancel_info') || ''} 
                                                                    onChange={this.handleDataChange}
                                                                >
                                                                    {freeCancelInfo}
                                                                </InputField>
                                                            </Col>
                                                        </Row>
                                                        <div style={{marginTop: '2rem'}} />
                                                        <Row>
                                                            <Col md={12}>
                                                                <InputLabel>
                                                                    Organization Covers Commissions & Fees {this.checkinInstructionsPopover('', "Select 'Yes' if the commissions & fees should be deducted from the payment amount when funds are transferred to the Manager/Owner.  Select 'No' if the commissions & fees should be paid by the guest, as an additional processing fee, when booking a reservation.")}
                                                                </InputLabel>
                                                                <span style={{marginLeft: '2rem'}}/>
                                                                {this.createInlineRadioButton(useCommissionsForAdditionalFees, data && data.property_manager_details && data.property_manager_details.use_commissions_for_additional_fees, this.handleDataChange)}
                                                            </Col>
                                                        </Row>
                                                        <hr/>
                                                        <Row>
                                                            <Col md={2} className='bottom-margin top-margin'>
                                                                <InputLabel>
                                                                    Seasonal Cancellation Policy {this.seasonalPoliciesPopover('', this.seasonalPoliciesTooltip)}
                                                                </InputLabel>
                                                            </Col>
                                                            <Col md={3} className='bottom-margin top-margin'>
                                                                <PrimaryButton
                                                                    cssClass="blue-btn "
                                                                    fullWidth={true}
                                                                    onClick={(e) => this.addSeasonalPolicy(e)}
                                                                    disabled={this.state.data.property_manager_details.free_cancel_policy === 'yes_3'}
                                                                >
                                                                    Add Seasonal Policy
                                                                </PrimaryButton>
                                                            </Col>
                                                        </Row>
                                                        {this.createSeasonalPolicies()}
                                                        <hr/>
                                                        <Row>
                                                            <Col md={12} className='bottom-margin top-margin'>
                                                                <InputLabel>
                                                                    Enable Periodic Payouts
                                                                </InputLabel>
                                                                <span style={{marginLeft: '2rem'}}/>
                                                                {this.createInlineRadioButton(enablePeriodicPayoutsOptions, enablePeriodicPayouts, this.handleStateChange)}
                                                            </Col>
                                                            <Col md={12} className='bottom-margin top-margin'>
                                                                <InputLabel>
                                                                    Show Channel Source
                                                                </InputLabel>
                                                                <span style={{marginLeft: '2rem'}}/>
                                                                {this.createInlineRadioButton(showChannelSourceOptions, _.get(data, 'property_manager_details.show_channel_source'), this.handleDataChange)}
                                                            </Col>
                                                        </Row>
                                                        <hr/>
                                                        <Row>
                                                            <Col md={12} className='top-margin'>
                                                                <h2>Reviews Settings</h2>
                                                            </Col>
                                                            <Col md={6} className='bottom-margin top-margin'>
                                                                <InputLabel>
                                                                    Auto Response Delay (Minutes)
                                                                </InputLabel>
                                                                <InputField 
                                                                    type="text"
                                                                    name="auto_reply_delay_mins" 
                                                                    value={_.get(data, 'property_manager_details.review_config.auto_reply_delay_mins') || ""} 
                                                                    onChange={this.handleReviewsConfig}
                                                                >
                                                                </InputField>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={6} className='bottom-margin top-margin'>
                                                                <InputLabel>
                                                                    Auto Response Rating Levels
                                                                </InputLabel>
                                                                <Picky
                                                                    placeholder=""
                                                                    labelKey="label"
                                                                    valueKey="value"
                                                                    filterPlaceholder="Filter"
                                                                    options={this.reviewsRatingsOptions}
                                                                    value={reviewsRatingsOptionsValue}
                                                                    multiple={true}
                                                                    includeSelectAll={false}
                                                                    defaultFocusFilter={true}
                                                                    keepOpen={false}
                                                                    onChange={value => this.handleFilterRatings(value)}
                                                                    dropdownHeight={600}
                                                                    render={({
                                                                        style,
                                                                        item,
                                                                        isSelected,
                                                                        selectValue,
                                                                        labelKey,
                                                                        valueKey,
                                                                    }) => {
                                                                        return (
                                                                            <li
                                                                                style={{ ...style }} 
                                                                                className={isSelected ? "selected" : ""} 
                                                                                key={item[labelKey]}
                                                                                onClick={() => selectValue(item)}
                                                                            >
                                                                                <div className="org-dropdown flex-space-between">
                                                                                    <span
                                                                                        style={{fontWeight: isSelected ? "bold" : "normal"}}
                                                                                    >
                                                                                        {item[labelKey]}{" "}{item[valueKey] && this.renderStarsReview(item[valueKey])}
                                                                                    </span>
                                                                                    <InputField
                                                                                        cssClass="form-check-label checkbox-label"
                                                                                        type="checkbox"
                                                                                        value={isSelected}
                                                                                        cbChange={() => {}}
                                                                                    >
                                                                                    </InputField>
                                                                                </div>
                                                                            </li>
                                                                        );
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={12} className='bottom-margin top-margin'>
                                                                <InputLabel>
                                                                    Host Manage Reviews
                                                                </InputLabel>
                                                                <span style={{marginLeft: '2rem'}}/>
                                                                {this.createInlineRadioButton(hostManagedOptions, _.get(data, 'property_manager_details.review_config.host_managed') || '0', this.handleReviewsConfig)}
                                                            </Col>
                                                        </Row>
                                                        {
                                                            this.editOrgSmartsiteAddUids &&
                                                            <Fragment>
                                                                <hr/>
                                                                <Row>
                                                                    <Col md={6} className='bottom-margin top-margin'>
                                                                        <InputLabel>
                                                                            Add Additional Organizations to SmartSite 
                                                                        </InputLabel>
                                                                        <Picky
                                                                            placeholder=""
                                                                            labelKey="value"
                                                                            valueKey="label"
                                                                            options={this.addOrgSmartSiteOptions}
                                                                            value={addOrgSmartSiteOptionsValue}
                                                                            multiple={true}
                                                                            includeSelectAll={true}
                                                                            defaultFocusFilter={true}
                                                                            includeFilter={true}
                                                                            keepOpen={false}
                                                                            onChange={value => this.handleAddOrgSmartSite(value)}
                                                                            dropdownHeight={350}
                                                                            render={({
                                                                                style,
                                                                                item,
                                                                                isSelected,
                                                                                selectValue,
                                                                                labelKey,
                                                                                valueKey,
                                                                            }) => {
                                                                                return (
                                                                                    <li
                                                                                        style={{ ...style }} 
                                                                                        className={isSelected ? "selected" : ""} 
                                                                                        key={item[valueKey]}
                                                                                        onClick={() => selectValue(item)}
                                                                                    >
                                                                                        <div className="org-dropdown flex-space-between">
                                                                                            <span
                                                                                                style={{fontWeight: isSelected ? "bold" : "normal"}}
                                                                                            >
                                                                                                {_.get(item, 'label') ? item.label : ''} &nbsp; 
                                                                                                {_.get(item, 'uid') ? <strong>({item.uid})</strong> : ''}
                                                                                            </span>
                                                                                            <InputField
                                                                                                cssClass="form-check-label checkbox-label"
                                                                                                type="checkbox"
                                                                                                value={isSelected}
                                                                                                cbChange={() => {}}
                                                                                            >
                                                                                            </InputField>
                                                                                        </div>
                                                                                    </li>
                                                                                );
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </Fragment>
                                                        }
                                                    </Panel.Body>
                                                </Panel>
                                                <Panel eventKey={this.state.init ? '1' : '5'}>
                                                    <Panel.Heading>
                                                        <Panel.Title
                                                            onClick={e => this.panelOnSelect(null, e, "5")}
                                                            toggle
                                                        > 
                                                            Fees & Taxes
                                                                <span 
                                                                    className={`pull-right ${this.state.activeKey === "5" || this.state.init ? "glyphicon glyphicon-chevron-up" : "glyphicon glyphicon-chevron-down" }`}>
                                                                </span>
                                                        </Panel.Title>
                                                    </Panel.Heading>
                                                    <Panel.Body collapsible>
                                                        <Row>
                                                            <Col md={6} className="prop-type-col">
                                                                <InputLabel 
                                                                    htmlFor="damage_waiver"
                                                                    className={this.state.validations.damage_waiver ? 'form-validation' : ''}
                                                                >
                                                                    Damage Waiver ($)
                                                                </InputLabel>
                                                                <div className="placeholder-wrap">
                                                                    <InputField 
                                                                        type="text"
                                                                        className={this.state.validations.damage_waiver ? 'form-validation' : ''}
                                                                        name="damage_waiver" 
                                                                        onChange={this.handleDataChange}
                                                                        value={data && data.property_manager_details && data.property_manager_details.damage_waiver}
                                                                    />
                                                                    <span className="placeholder">$</span>
                                                                </div>
                                                                {this.state.validations.damage_waiver && <span className="required-error-text">Damage Waiver is required to set this Organization Live</span>}
                                                            </Col>
                                                        </Row>
                                                        <br/>
                                                        <Row>
                                                            <Col md={12}>
                                                                <InputLabel>
                                                                    Include Cleaning Fee
                                                                </InputLabel>
                                                                {this.createDefaultRadioButton(includeCleaningFee, data && data.property_manager_details && data.property_manager_details.has_cleaning_fee, this.handleDataChange)}
                                                            </Col>
                                                        </Row>
                                                        <br/>
                                                        <Row>
                                                            <Col md={12}>
                                                                <InputLabel>
                                                                    Include Taxes
                                                                </InputLabel>
                                                                {this.createDefaultRadioButton(propertyValidationIncludeTaxes, data && data.property_manager_details && data.property_manager_details.has_tax, this.handleDataChange)}
                                                            </Col>
                                                        </Row>
                                                        <br/>
                                                        {
                                                            data.property_manager_details.integration_name !== "racalendar" &&
                                                            <Fragment>
                                                                <Row>
                                                                    {this.createIntegrationFeesConfig()}
                                                                </Row>
                                                                <br />
                                                                <Row>
                                                                    <Col xs={12} sm={12} md={3} 
                                                                        className="pull-left"
                                                                    >
                                                                        <PrimaryButton 
                                                                            cssClass="blue-btn "
                                                                            fullWidth={true} 
                                                                            onClick={this.addIntegrationFees}
                                                                            disabled={this.state.integrationFees.length == 5}
                                                                        >
                                                                            Add Integration Fees
                                                                        </PrimaryButton>
                                                                    </Col>
                                                                </Row>
                                                            </Fragment>
                                                        }      
                                                    </Panel.Body>
                                                </Panel>
                                                {
                                                    (this.showChannelsAndMarkup ||
                                                    this.createChannelsAndMarkup ||
                                                    this.updateChannelsAndMarkup) &&
                                                    mode === "edit" &&
                                                    <Panel eventKey={this.state.init ? '1' : '6'}>
                                                        <Panel.Heading>
                                                            <Panel.Title
                                                                onClick={e => this.panelOnSelect(null, e, "6")}
                                                                toggle
                                                            > 
                                                                Channels & Markup
                                                                    <span 
                                                                        className={`pull-right ${this.state.activeKey === "6" || this.state.init ? "glyphicon glyphicon-chevron-up" : "glyphicon glyphicon-chevron-down" }`}>
                                                                    </span>
                                                            </Panel.Title>
                                                        </Panel.Heading>
                                                        <Panel.Body collapsible>
                                                            <div className={`add-organization-table-component-container ${!this.updateChannelsAndMarkup ? 'readonly' : ''}`}>
                                                                <Row>
                                                                    <Col md={12} className="channelMarkupHeader">
                                                                        <div className="search-wrapper">
                                                                            <div className='search-inp'>
                                                                                <InputField 
                                                                                    type='searchbox' 
                                                                                    placeholder='Search' 
                                                                                    name='keyword' 
                                                                                    value={this.state.keyword} 
                                                                                    onChange={this.filterChannels}
                                                                                    clearSearch={this.clearSearch}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                <br/>
                                                                </Row>
                                                                <ReactTable
                                                                    ref={(r) => this.reactTable = r}
                                                                    minRows={0}
                                                                    resizable={false}
                                                                    data={this.state.channelOptInMarkupData}
                                                                    columns={this.state.columns}
                                                                    pageSize={this.state.pageSize}
                                                                    page={parseInt(this.state.activePage - 1)}
                                                                    filtered={this.state.filteredChannels}
                                                                    defaultSorted={[this.state.sorted]}
                                                                    getTrProps = {(state, rowInfo, column) => {
                                                                        if (rowInfo === undefined) {
                                                                            return {}
                                                                        };
                                                                        return {
                                                                            'channels-markup': 
                                                                                rowInfo && 
                                                                                rowInfo.original && 
                                                                                rowInfo.original.status ? "1" : "0",
                                                                        };
                                                                    }}
                                                                    PaginationComponent={
                                                                        (props) => {
                                                                            return this.state.channelOptInMarkupData.length > 0 && (this.reactTable && this.reactTable.state && this.reactTable.state.sortedData.length > 0) ? (
                                                                                <div
                                                                                    className="pagination-container"
                                                                                >
                                                                                    <div className="units-info-container">
                                                                                        {
                                                                                            (this.reactTable && this.reactTable.state && this.reactTable.state.sortedData.length) ? 
                                                                                            this.reactTable.state.sortedData.length : 
                                                                                            this.state.channelOptInMarkupData.length
                                                                                        } 
                                                                                            &nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                                                                                    </div>
                                                                                    <div className="page_size-input-container">
                                                                                        <div 
                                                                                            className='page-size' 
                                                                                        >
                                                                                            <InputField
                                                                                                className="pageSize"
                                                                                                type='number' 
                                                                                                name='pageSize'
                                                                                                autoComplete="off"
                                                                                                defaultValue={this.state.pageSize}
                                                                                                onKeyUp={(e) => {
                                                                                                    if(e.target.value > 100) {
                                                                                                        e.target.value = 100;
                                                                                                    }
                                                                                                    else if(e.target.value < 0) {
                                                                                                        e.target.value = 1;
                                                                                                    };

                                                                                                    if(e.keyCode === 13 && e.target.value !== 0 && Number.isInteger(Number(e.target.value))) {
                                                                                                        this.pageSizeOnChange(e.target.value)
                                                                                                    };
                                                                                                }}
                                                                                            /> 
                                                                                            / page
                                                                                        </div>
                                                                                    </div>
                                                                                    <Pagination
                                                                                        activePage={this.state.activePage}
                                                                                        itemsCountPerPage={props.pageSize}
                                                                                        totalItemsCount={
                                                                                            (this.reactTable && this.reactTable.state && this.reactTable.state.sortedData.length) ? 
                                                                                            this.reactTable.state.sortedData.length : 
                                                                                            this.state.channelOptInMarkupData.length
                                                                                        }
                                                                                        onChange={this.pageOnChange}
                                                                                    />
                                                                                </div>
                                                                            ) :
                                                                            ""
                                                                        }
                                                                    }
                                                                />
                                                            </div>
                                                            <br/>
                                                            <Row>
                                                                <Col md={12}>
                                                                    <InputLabel>
                                                                        Smart Site Merchant of Record
                                                                    </InputLabel>
                                                                    {this.createDefaultRadioButton(smartSite, this.state.data.ra_collect, this.handleDataChange)}
                                                                </Col>
                                                            </Row>
                                                        </Panel.Body>
                                                    </Panel>
                                                }
                                                <Panel eventKey={this.state.init ? '1' : '7'}>
                                                <Panel.Heading>
                                                    <Panel.Title 
                                                        onClick={e => this.panelOnSelect(null, e, "7")} 
                                                        toggle 
                                                    > 
                                                        Calendar Settings
                                                            <span 
                                                                className={`pull-right ${this.state.activeKey === "7" || this.state.init ? "glyphicon glyphicon-chevron-up" : "glyphicon glyphicon-chevron-down" }`}
                                                            >
                                                            </span>
                                                    </Panel.Title>
													</Panel.Heading>
													<Panel.Body collapsible>
                                                        <Row>
                                                            <Col md={12}>
                                                                <InputLabel>
                                                                    Booking Window
                                                                </InputLabel>
                                                            </Col>
                                                        </Row>
                                                        <br/>
														<Row>															
															<Col md={6}>
																<InputLabel>
																	Start (hours before check-in date)
																</InputLabel>
																<InputField 
																	type="number"
																	value={data.property_manager_details.booking_lead_hours || ""} 
																	name="booking_lead_hours"
																	onChange={e => this.handleDataChange(e)}
																>
																</InputField>
															</Col>
														</Row>
														<br/>
														<Row>
															<Col md={6}>
																<InputLabel>
																	End
																</InputLabel>
																<InputField 
																	type="select"
																	value={data.property_manager_details.block_calendar || ""} 
																	name="block_calendar"
																	onChange={e => this.handleDataChange(e)}
																>
																	{blockFeatureCalendar}
																</InputField>
															</Col>
														</Row>
                                                        <br/>
                                                        <Row>
                                                            <Col md={6}>
																<InputLabel>
																	Turnover Block {this.checkinInstructionsPopover('', this.turnoverBlockTooltip)}
																</InputLabel>
																<InputField
																	type="select"
																	value={data.property_manager_details.turnover_block || ""}
																	name="turnover_block"
																	onChange={e => this.handleDataChange(e)}
																>
                                                                    {turnoverBlock}
																</InputField>
															</Col>
                                                        </Row>
													</Panel.Body>
                                                </Panel>
                                                <Panel eventKey={this.state.init ? '1' : '8'}>
                                                    <Panel.Heading>
                                                        <Panel.Title 
                                                            toggle
                                                            onClick={e => this.panelOnSelect(null, e, "8")}
                                                        > 
                                                            Checkin Instruction Settings
                                                                <span 
                                                                    className={`pull-right ${this.state.activeKey === "8" || this.state.init ? "glyphicon glyphicon-chevron-up" : "glyphicon glyphicon-chevron-down" }`}>
                                                                </span>
                                                        </Panel.Title>
                                                    </Panel.Heading>
                                                    <Panel.Body collapsible>
                                                        <Row>
                                                            <Col md={12}>
                                                                <InputLabel >
                                                                    Checkin Instruction Metadata {this.checkinInstructionsPopover("", this.checkinInstructionsTooltip)}
                                                                </InputLabel>

                                                            </Col>
                                                        </Row>
                                                        <br/>
                                                        <Row>
                                                            <Col md={6} className="prop-type-col">
                                                                <InputLabel 
                                                                    htmlFor="door_code"
                                                                >
                                                                    Door Code
                                                                </InputLabel>
                                                                <div className="placeholder-wrap">
                                                                    <InputField 
                                                                        type="text"
                                                                        name="door_code"
                                                                        placeholder="Not set"
                                                                        onChange={this.handleCheckinInstructionsChange}
                                                                        value={
                                                                            checkinInstructionsData &&
                                                                            checkinInstructionsData.door_code &&
                                                                            checkinInstructionsData.door_code.value
                                                                        }
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <br/>
                                                    </Panel.Body>
                                                </Panel>
                                                {
                                                    mode === "edit" &&
                                                    <Panel eventKey='9'>
                                                        <Panel.Heading>
                                                            <Panel.Title
                                                                onClick={e => this.panelOnSelect(null, e, "9")}
                                                                toggle
                                                            > 
                                                                Communication Hub  &nbsp; <span className='small-text'>{this.initCommHubEnabled ? '(Enabled)' : '(Disabled)'}</span>
                                                                <span 
                                                                    className={`pull-right ${this.state.activeKey === "9" ? "glyphicon glyphicon-chevron-up" : "glyphicon glyphicon-chevron-down" }`}>
                                                                </span>
                                                            </Panel.Title>
                                                        </Panel.Heading>
                                                        <Panel.Body collapsible>
                                                            <div className='dashboard org-team-members'>

                                                                {teamMembersIsLoading && <Loader/>}

                                                                <div className='comm-management'>
                                                                    {
                                                                        !this.initCommHubEnabled &&
                                                                        <Row>
                                                                            <Col md={12} className='bottom-margin'>
                                                                                <div className='switch'>
                                                                                    <div className='right-margin'>Enable Communication Hub</div>
                                                                                    <Switch 
                                                                                        onColor={"#69c"} 
                                                                                        uncheckedIcon={false} 
                                                                                        checkedIcon={false}
                                                                                        height={25} 
                                                                                        width={50}
                                                                                        checked={commHubEnabled || false}
                                                                                        onChange={bool => this.handleEnableCommHub(bool)}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    }
                                                                    {
                                                                        this.initCommHubEnabled &&
                                                                        <Fragment>
                                                                            {
                                                                                (teamMembers.length > 0) &&
                                                                                <Row className="bottom-margin">
                                                                                    <Col md={12}>
                                                                                        <ReactTableComponent
                                                                                            manual
                                                                                            className='-highlight'
                                                                                            noDataText="No Global Members found."
                                                                                            minRows={0}
                                                                                            data={teamMembers}
                                                                                            resizable={false}
                                                                                            activePage={1}
                                                                                            columns={teamMembersColumns}
                                                                                            showPagination={true}
                                                                                            pageSize={5}
                                                                                            totalCount={5}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>
                                                                            }
                                                                            <Col md={12} sm={12} className='no-padding'>
                                                                                <Row>
                                                                                    <Col md={12} sm={12} id='edit-team-member' className='flex-space-between'>
                                                                                        {
                                                                                            teamMemberMode == 'create' ?
                                                                                            <h4>Add Team Member</h4> :
                                                                                            <h4>Edit {sourceTeamMemberData && sourceTeamMemberData.subscriber && sourceTeamMemberData.subscriber.display_name ? `  -  ${sourceTeamMemberData.subscriber.display_name}` : ''}</h4>
                                                                                        }
                                                                                    </Col>
                                                                                    <Col md={12} sm={12} className="bottom-margin-10 no-padding">
                                                                                        <Col md={4} sm={12}>
                                                                                            <InputLabel
                                                                                                className={teamMemberValidations.first_name ? 'form-validation' : ''}
                                                                                            >
                                                                                                First Name
                                                                                            </InputLabel>
                                                                                            <InputField 
                                                                                                type="text"
                                                                                                value={teamMemberData.first_name} 
                                                                                                name="first_name"
                                                                                                onChange={e => this.handleOnChange(e)}
                                                                                                className={teamMemberValidations.first_name ? 'form-validation' : ''}
                                                                                            >
                                                                                            </InputField>
                                                                                            {teamMemberValidations.first_name && <span className="required-error-text">First Name is required</span>}
                                                                                        </Col>
                                                                                        <Col md={4} sm={12}>
                                                                                            <InputLabel
                                                                                                className={teamMemberValidations.last_name ? 'form-validation' : ''}
                                                                                            >
                                                                                                Last Name
                                                                                            </InputLabel>
                                                                                            <InputField 
                                                                                                type="text" 
                                                                                                value={teamMemberData.last_name} 
                                                                                                name="last_name"
                                                                                                onChange={e => this.handleOnChange(e)}
                                                                                                className={teamMemberValidations.last_name ? 'form-validation' : ''}
                                                                                            >
                                                                                            </InputField>
                                                                                            {teamMemberValidations.last_name && <span className="required-error-text">Last Name is required</span>}
                                                                                        </Col>
                                                                                        <Col md={4} sm={12}>
                                                                                            <InputLabel
                                                                                                className={teamMemberValidations.role_type ? 'form-validation' : ''}
                                                                                            >
                                                                                                Primary Role
                                                                                            </InputLabel>
                                                                                            <InputField 
                                                                                                type="select" 
                                                                                                value={teamMemberData.role_type} 
                                                                                                name="role_type"
                                                                                                onChange={e => this.handleOnChange(e)}
                                                                                                className={teamMemberValidations.role_type ? 'form-validation' : ''}
                                                                                            >
                                                                                                {primaryRolesOptions}
                                                                                            </InputField>
                                                                                            {teamMemberValidations.role_type && <span className="required-error-text">Primary Role is required</span>}
                                                                                        </Col>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col md={12} sm={12} className="bottom-margin-10 no-padding">
                                                                                        <Col md={4} sm={12}>
                                                                                            <InputLabel
                                                                                                className={(teamMemberValidations.email || teamMemberValidations.emailFormat) ? 'form-validation' : ''}
                                                                                            >
                                                                                                Email Address
                                                                                            </InputLabel>
                                                                                            <InputField 
                                                                                                type="text" 
                                                                                                value={teamMemberData.email} 
                                                                                                name="email"
                                                                                                onChange={e => this.handleOnChange(e)}
                                                                                                className={(teamMemberValidations.email || teamMemberValidations.emailFormat) ? 'form-validation' : ''}
                                                                                            >
                                                                                            </InputField>
                                                                                            {(teamMemberValidations.email || teamMemberValidations.emailFormat) && <span className="required-error-text">{teamMemberValidations.email ? 'Email is required' : "Please enter a valid Email"}</span>}
                                                                                        </Col>
                                                                                        <Col md={4} sm={12}>
                                                                                            <InputLabel
                                                                                                className={teamMemberValidations.phone_number ? 'form-validation' : ''}
                                                                                            >
                                                                                                Mobile Phone Number
                                                                                            </InputLabel>
                                                                                            <PhoneInput
                                                                                                country="us"
                                                                                                autoFormat={true}
                                                                                                enableSearch={true}
                                                                                                disableSearchIcon={true}
                                                                                                value={teamMemberData.phone_number}
                                                                                                name="phone_number"
                                                                                                onChange={this.phoneNumberOnChange}
                                                                                                className={teamMemberValidations.phone_number ? 'form-validation' : ''}
                                                                                            />
                                                                                            {teamMemberValidations.phone_number && <span className="required-error-text">Phone Number is required</span>}
                                                                                        </Col>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col md={8} sm={12} xs={12}>
                                                                                        {
                                                                                            (teamMemberData && teamMemberData.role_type && teamMemberData.role_type.length > 0) &&
                                                                                            <Fragment>
                                                                                                <div className={`sub-cat ${teamMemberValidations.subCategories ? 'form-validation' : ''}`}>
                                                                                                    {this.renderSubCat()}

                                                                                                </div>
                                                                                                {
                                                                                                    teamMemberValidations.subCategories && 
                                                                                                    <div className="required-error-text">
                                                                                                        Please select at least 1 message opt-in.
                                                                                                    </div>
                                                                                                }
                                                                                            </Fragment>
                                                                                        }
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col md={12} sm={12} xs={12} className="flex-align-right">
                                                                                        <PrimaryButton
                                                                                            cssClass="white-btn "
                                                                                            fullWidth={false} 
                                                                                            type="button"
                                                                                            onClick={(e) => this.handleCancelTeamMember(e)}
                                                                                        >
                                                                                            Cancel
                                                                                        </PrimaryButton>
                                                                                        <PrimaryButton
                                                                                            cssClass="left-margin"
                                                                                            fullWidth={false} 
                                                                                            type="button"
                                                                                            onClick={this.submitTeamMember}
                                                                                            disabled={
                                                                                                teamMemberMode == 'edit' && 
                                                                                                (isEqual(teamMemberData, this.copyTeamMemberData)) &&
                                                                                                (isEqual(subCategories, this.copySubCategories))
                                                                                            }
                                                                                        >
                                                                                            {teamMemberMode == 'edit' ? 'Update' : 'Add'}
                                                                                        </PrimaryButton>
                                                                                    </Col>
                                                                                </Row>
                                                                                {
                                                                                    (teamMemberErrorMsg.length > 0 || (teamMemberDeleteErrorMsg.length > 0)) && 
                                                                                    this.renderMsg(teamMemberErrorMsg || teamMemberDeleteErrorMsg)
                                                                                }
                                                                            </Col>
                                                                        </Fragment>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Panel.Body>
                                                    </Panel>
                                                }
                                            </PanelGroup>
                                        </Col>
                                    </Row>
                                    <NavigationPrompt 
                                        when={
                                            this.compareState() &&
                                            (this.state.data &&
                                            this.state.data.property_manager_details &&
                                            this.state.data.property_manager_details.account_status &&
                                            this.state.data.property_manager_details.account_status.toLowerCase() !== "canceled")
                                        }
                                        onSave={this.handleFormSubmit}
                                    />
                                    <GlobalSubmitModal
                                        showViewAll={true}
                                        mode={this.state.mode}
                                        submitModalTotal={this.state.submitModalTotal}
                                        activeModal={this.state.showModal}
                                        closeModal={this.closeModal}
                                        submitStatus={this.state.submitStatus}
                                        progressMessage={this.state.progressMessage}
                                        error={this.state.modalError}
                                        className={"addOrganizations"}
                                        continueUrl={`/account-management/organizations/${this.state.data.organization_name}/edit`}
                                        viewAllLabel={"View All Organizations"}
                                        viewAllUrl={"/account-management/organizations"}
								    />
                                    <Modal
                                        className="clear-cache-modal"
                                        show={this.state.activeModal === "clear-cache-modal"} 
                                        onHide={this.closeActiveModal}
                                    >
                                        <Modal.Body>
                                            <Row className="text-center bottom-margin-30">
                                                {
                                                    this.state.successClearCache ? 
                                                    <Col md={12}>
                                                        <h1>Successfully Clear Cache.</h1>
                                                    </Col> :
                                                    <Fragment>
                                                        <Col md={12}>
                                                            <h1>Error Clearing Cache.</h1>
                                                        </Col>
                                                        <Col md={12}>
                                                            <h2>Please try again.</h2>
                                                        </Col>   
                                                    </Fragment>
                                                }
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <button
                                                        onClick={this.closeActiveModal} 
                                                        className='primary-btn left-margin pull-right'>
                                                        Done
                                                    </button>
                                                </Col>
                                            </Row>
                                        </Modal.Body>
                                    </Modal>
                                    <Modal 
                                        show={this.state.activeModal == "shutdown-org-modal"}
                                        onHide={this.closeActiveModal}
                                        className="shutdown-org-modal"
								    >
                                        <Modal.Header closeButton>
                                            <h2>Shutdown Organization</h2>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Row>
                                                <Col md={12}>
                                                    <span><strong>WARNING:</strong> Proceeding will immediately deactivate all listings and close this organization down. Restoring will be time consuming. Proceed with caution.</span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <InputLabel 
                                                        htmlFor="churn_reason"
                                                    >
                                                        Churn Reason
                                                    </InputLabel>
                                                    <InputField 
                                                        type="select"
                                                        name="churn_reason" 
                                                        value={churnData.churn_reason} 
                                                        onChange={this.handleChurnInput}
                                                    >
                                                        <option value="" hidden></option>
                                                        {churnReasons}
                                                    </InputField>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <InputLabel 
                                                        htmlFor="churn_detail"
                                                    >
                                                        Churn Detail
                                                    </InputLabel>
                                                    <InputField 
                                                        type="textarea"
                                                        name="churn_detail" 
                                                        value={churnData.churn_detail} 
                                                        onChange={this.handleChurnInput}
                                                    >
                                                    </InputField>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12}>
                                                    <PrimaryButton
                                                        cssClass="pull-right"  
                                                        fullWidth={false}
                                                        onClick={this.closeActiveModal}
                                                    >
                                                        Cancel
                                                    </PrimaryButton>
                                                    <PrimaryButton 
                                                        cssClass='white-btn pull-right right-margin'
                                                        fullWidth={false}
                                                        onClick={(e) => this.shutdownOrg(e)}
                                                        disabled={!churnData.churn_reason.length || !churnData.churn_detail.length}
                                                    >
                                                        Proceed
                                                    </PrimaryButton>
                                                </Col>
                                            </Row>
                                        </Modal.Body>
								    </Modal>
                                    <Modal 
                                        show={this.state.activeModal == "cancel-policy-prompt"}
                                        onHide={this.closeActiveModal}
                                        className="prompt-modal"
								    >
                                        <Modal.Body>
                                            <Row className="text-center bottom-margin">
                                                <Col xs={12}>
                                                    <h2>{_.get(cancelPolicyDataPlaceholder, 'message')}</h2>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12}>
                                                    <PrimaryButton 
                                                        cssClass='pull-right'
                                                        fullWidth={false}
                                                        onClick={(e) => this.handleCancelPolicy(e)}
                                                    >
                                                        Proceed
                                                    </PrimaryButton>
                                                    <PrimaryButton
                                                        cssClass="white-btn pull-right right-margin" 
                                                        fullWidth={false}
                                                        onClick={this.closeActiveModal}
                                                    >
                                                        Cancel
                                                    </PrimaryButton>
                                                </Col>
                                            </Row>
                                        </Modal.Body>
								    </Modal>
                                    <Modal 
                                        show={this.state.activeModal == "shutdown-org-successful"}
                                        onHide={this.closeActiveModal}
                                        className="prompt-modal"
								    >
                                        <Modal.Body>
                                            <Row className="text-center bottom-margin">
                                                <Col xs={12}>
                                                    <h2>Successfully Shutdown Organization.</h2>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12}>
                                                    <PrimaryButton
                                                        cssClass="pull-right"  
                                                        fullWidth={false}
                                                        onClick={this.closeActiveModal}
                                                    >
                                                        Close
                                                    </PrimaryButton>
                                                </Col>
                                            </Row>
                                        </Modal.Body>
								    </Modal>
                                    <Modal 
                                        show={this.state.modal === 'bookingPal'}
                                        className={`bookinPal-modal ${!this.state.bookingPalUrl ? 'bookinPal-error' : ''}`}
                                        onHide={e => this.handleModal(e, "")}
								    >
                                        <Modal.Header closeButton>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Row>
                                                <Col xs={12}>
                                                    {
                                                        this.state.bookingPalUrl ?
                                                        <iframe
                                                            src={this.state.bookingPalUrl}
                                                            width="100%" 
                                                            height="760px"
                                                            frameBorder="0"
                                                            allowFullScreen
                                                        >
                                                        </iframe> 
                                                        :
                                                        <h1>
                                                            {this.state.bookingPalErrMsg}
                                                        </h1>
                                                    }
                                                </Col>
                                            </Row>
                                        </Modal.Body>
								    </Modal>
                                    <Modal 
                                        show={modal == 'add-tm-property'} 
                                        className='comm-properties-modal' 
                                        onHide={this.handleModal}
                                        backdrop='static'
                                    >

                                        {teamMemberPropertiesIsLoading && <Loader/>}

                                        <Modal.Header>
                                            {
                                                <h1>{selectedTeamMember && selectedTeamMember.display_name}</h1>
                                            }
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Picky
                                                placeholder={"Property Search"}
                                                labelKey="label"
                                                valueKey="property_id"
                                                options={teamMemberPropertiesList}
                                                value={teamMemberPropertyOptionsValue}
                                                multiple={true}
                                                includeSelectAll={true}
                                                includeFilter={true}
                                                onChange={prop => this.handleTeamMemberPropertyOptions(prop)}
                                                dropdownHeight={600} 
                                                filterDebounce={100}   
                                                keepOpen={true}
                                                selectAllText='All Properties'
                                                numberDisplayed={1}
                                                clearFilterOnClose={true}
                                                renderSelectAll={({
                                                    filtered,
                                                    tabIndex,
                                                    selected,
                                                    allSelected,
                                                    toggleSelectAll,
                                                    multiple,
                                                }) => {
                                                    if (multiple && !filtered) {
                                                        return (
                                                            <div
                                                                key={tabIndex}
                                                                tabIndex={tabIndex}
                                                                role="option"
                                                                className={allSelected ? 'option selected' : 'option'}
                                                                onClick={toggleSelectAll}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    checked={allSelected}
                                                                    readOnly
                                                                />
                                                                All Properties
                                                            </div>
                                                        );
                                                    }
                                                }}
                                            />
                                            <Row>
                                                <Col xs={12} className='top-margin'>
                                                    <PrimaryButton
                                                        cssClass="pull-right"
                                                        fullWidth={false} 
                                                        type="button"
                                                        onClick={this.submitTeamMemberProperties}
                                                        disabled={!teamMemberPropertyOptionsValue.length || isEqual(teamMemberPropertyOptionsValue, this.copyTeamMemberPropertyOptionsValue)}
                                                    >
                                                        Update
                                                    </PrimaryButton>
                                                    <PrimaryButton
                                                        cssClass='white-btn pull-right right-margin'
                                                        fullWidth={false} 
                                                        type="button"
                                                        onClick={(e) => this.handleModal(e, '')}
                                                    >
                                                        Cancel
                                                    </PrimaryButton>
                                                </Col>
                                            </Row>
                                            {
                                                (teamMemberPropertiesErrorMsg.length > 0) &&
                                                this.renderMsg(teamMemberPropertiesErrorMsg)
                                            }
                                        </Modal.Body>
                                    </Modal>
                                    <Modal 
                                        show={modal == 'remove-tm'} 
                                        onHide={(e) => this.handleModal(e, '')}
                                        backdrop='static'
                                    >
                                        <Modal.Header>
                                        </Modal.Header>
                                        <Modal.Body>
                                        <Row className="text-center bottom-margin">
                                                <Col xs={12}>
                                                    <h2>Removing{" "} 
                                                        <strong>{`"${selectedTeamMember && selectedTeamMember.subscriber && selectedTeamMember.subscriber.display_name}"`}</strong>{" "}
                                                        will cancel all future messages and notifications for this user.
                                                    </h2>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12}>
                                                    <PrimaryButton
                                                        cssClass="pull-right"
                                                        fullWidth={false} 
                                                        type="button"
                                                        onClick={this.removeTeamMember}
                                                    >
                                                        Remove
                                                    </PrimaryButton>
                                                    <PrimaryButton
                                                        cssClass='white-btn pull-right right-margin'
                                                        fullWidth={false} 
                                                        type="button"
                                                        onClick={(e) => this.handleModal(e, '')}
                                                    >
                                                        Cancel
                                                    </PrimaryButton>
                                                </Col>
                                            </Row>
                                        </Modal.Body>
                                    </Modal>
                                    <Modal 
                                        backdrop='static'
                                        show={modal == 'enable-commhub-modal'} 
                                        className='comm-properties-modal'
                                    >
                                        {enableCommHubIsLoading && <Loader/>}
                                        
                                        <Modal.Header>
                                            <h2>Enable Communication Hub <span className='grey-font'>{`(${organization.organization_name})`}</span></h2>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <InputLabel>
                                                Add Team Members(s):
                                            </InputLabel>
                                            <Picky
                                                placeholder='Add Team Members'
                                                labelKey="label"
                                                valueKey="value"
                                                options={commHubUsersOptions}
                                                value={commHubUsersOptionsValue}
                                                multiple={true}
                                                includeSelectAll={true}
                                                includeFilter={true}
                                                onChange={prop => this.handleCommHubUsersOptions(prop)}
                                                dropdownHeight={600} 
                                                filterDebounce={100}   
                                                keepOpen={true}
                                                selectAllText='All Users'
                                                numberDisplayed={1}
                                                clearFilterOnClose={true}
                                                renderSelectAll={({
                                                    filtered,
                                                    tabIndex,
                                                    selected,
                                                    allSelected,
                                                    toggleSelectAll,
                                                    multiple,
                                                }) => {
                                                    if (multiple && !filtered) {
                                                        return (
                                                            <div
                                                                key={tabIndex}
                                                                tabIndex={tabIndex}
                                                                role="option"
                                                                className={allSelected ? 'option selected' : 'option'}
                                                                onClick={toggleSelectAll}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    checked={allSelected}
                                                                    readOnly
                                                                />
                                                                All Users
                                                            </div>
                                                        );
                                                    }
                                                }}
                                            />    
                                            <Row>
                                                <Col xs={12} className='top-margin'>
                                                    <PrimaryButton
                                                        cssClass="pull-right"
                                                        fullWidth={false} 
                                                        type="button"
                                                        onClick={this.submitEnableCommHub}
                                                    >
                                                        Enable
                                                    </PrimaryButton>
                                                    <PrimaryButton
                                                        cssClass='white-btn pull-right right-margin'
                                                        fullWidth={false} 
                                                        type="button"
                                                        onClick={() => this.handleEnableCommHub(false)}
                                                    >
                                                        Cancel
                                                    </PrimaryButton>
                                                </Col>
                                            </Row>
                                            {
                                                enableCommHubErrMsg && this.renderMsg(enableCommHubErrMsg)
                                            }
                                        </Modal.Body>
                                    </Modal>
                                </Form>
                            </FieldWrap>
                        </div>
                    </div>
                </div>
            </div>}
            </PageLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        organizationsTree: state.authState.user.organization_role_tree,
        organizations: state.authState.user.expanded_organization_roles,
        parentOrg: state.authState.user.organization_roles[0].organization_name,
        unsavedData: state.updateUnsavedData.unsavedData,
        permissions: state.authState.permissions,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateOrganization: (org) => {
            dispatch(updateOrganization(org))
        },
        updateOrganizationTitle: (org) => {
            dispatch(updateOrganizationTitle(org))
        },
        updateOrganizationId: (org) => {
            dispatch(updateOrganizationId(org))
        },
        updateUnsavedData: (bool) => {
            dispatch(updateUnsavedData(bool));
        }
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(AddOrganization));