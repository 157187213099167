const updateMobileState = (state = {mobileMode: false}, action) => {
    switch (action.type) {
        case 'UPDATE_MOBILE_STATE':
            return Object.assign({}, state, {
                mobileMode: action.mobileMode,
            });           
        default:
            return state
    }
}
  
export default updateMobileState