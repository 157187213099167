import React, { Component } from 'react';
import Link from 'react-router-dom/Link';
import { connect } from 'react-redux';
import { Title } from '../global/Title';
import { API } from 'aws-amplify';
import queryString from 'query-string';
import { CSVLink } from "react-csv";

import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';

import { InputField } from '../global/forms/FormElements';
import PageLayout from '../global/PageLayout';
import Loader from '../global/Loader';
import ReactTableComponent from '../global/ReactTableComponent';
import {
    tableSort,
    getSessionId,
    getOrgData
} from '../../helpers/helpers';

class ReportsChannels extends Component {
    constructor(props) {
        super(props);
        this.reactTable = null;
        this.CSVLink = null;
        this.compareChannelsReports = {};
        this.org = this.props.org;
        this.tid = getSessionId();
        this.allOrganizations = [];
        this.breadcrumbItems = [
            { title: "Reports" },
            { title: "Channels" },
        ];
        this.CSVHeaders = [
            { label: 'Property ID', key: 'property_id'},
            { label: 'Reference ID', key: 'reference_id'},
            { label: 'Property Name', key: 'title'},
            { label: 'RedAwning', key: 'redawning'},
            { label: 'Booking.com', key: 'bookingcom'},
            { label: 'Airbnb', key: 'airbnb'},
            { label: 'Expedia', key: 'expedia'},
            { label: 'Vrbo', key: 'vrbo'},
        ];
        this.state = {
            isLoading: false,
            pageSize: 10,
            activePage: 1,
            channelsReports: [],
            initStateChannelsReports: [],
            CSVReports: [],
            totalCount: 0,
            keyword: "",
            dropdownIsOpen: false,
            showAllOrg: true,
            sorted: {
                id: "property_id",
                desc: true
            },
            columns: [],
        };
    }

    updateColumns = () => {
        const { 
            showAllOrg,
            channelsReports
        } = this.state;
        let columns = [];

        columns = [
            ...columns,
            {
                Header:() => tableSort("Property ID"),
                accessor: 'property_id',
                'width': 115,
                'Cell': row=>{
                    const propertyId = row && row.original && row.original.property_id;

                    return(
                        <div>
                            <Link 
                                className="clickable-text" 
                                to={`/properties/${propertyId}/edit`}
                            >
                                {propertyId}
                            </Link>
                        </div>
                    )
                }
            },
            {
                Header:() => tableSort("Reference ID"),
                accessor: 'reference_id',
                'minWidth': 115,
            },
            {
                Header:() => tableSort("Property Name"),
                accessor: 'title',
                'minWidth': 115,
            },
            {
                Header:() => tableSort("RedAwning"),
                'accessor': 'url',
                'minWidth': 115,
                'Cell': row=>{
                    let channels = row && row.original && row.original.channels;
                    let redawningChannel = [];

                    redawningChannel = channels.filter((channel) => {
                        return Object.keys(channel)[0] === "redawning";
                    });

                    return(
                        <div className="channels-link">
                            {
                                redawningChannel.length > 0 &&
                                this.renderChannelsUrl(redawningChannel, 'redawning')
                            }
                        </div>
                    )
                }
            },
            {
                Header:() => tableSort("Booking.com"),
                'accessor': 'url',
                'minWidth': 115,
                'Cell': row=>{
                    let channels = row && row.original && row.original.channels;
                    let bookingcomChannel = [];
                    
                    bookingcomChannel = channels.filter((channel) => {
                        return Object.keys(channel)[0] === "bookingcom";
                    });

                    return(
                        <div className="channels-link">
                            {
                                bookingcomChannel.length > 0 &&
                                this.renderChannelsUrl(bookingcomChannel, 'bookingcom')
                            }
                        </div>
                    )
                }
            },
            {
                Header:() => tableSort("Airbnb"),
                'accessor': 'url',
                'minWidth': 115,
                'Cell': row=>{
                    let channels = row && row.original && row.original.channels;
                    let airbnbChannel = [];
                    
                    airbnbChannel = channels.filter((channel) => {
                        return Object.keys(channel)[0] === "airbnb";
                    });

                    return(
                        <div className="channels-link">
                            {
                                airbnbChannel.length > 0 &&
                                this.renderChannelsUrl(airbnbChannel, 'airbnb')
                            }
                        </div>
                    )
                }
            },
            {
                Header:() => tableSort("Expedia"),
                'accessor': 'url',
                'minWidth': 115,
                'Cell': row=>{
                    let channels = row && row.original && row.original.channels;
                    let expediaChannel = [];

                    expediaChannel = channels.filter((channel) => {
                        return Object.keys(channel)[0] === "expedia";
                    });

                    return(
                        <div className="channels-link">
                            {
                                expediaChannel.length > 0 &&
                                this.renderChannelsUrl(expediaChannel, 'expedia')
                            }
                        </div>
                    )
                }
            },
            {
                Header:() => tableSort("Vrbo"),
                'accessor': 'url',
                'minWidth': 115,
                'Cell': row=>{
                    let channels = row && row.original && row.original.channels;
                    let homeawayChannel = [];
                    
                    homeawayChannel = channels.filter((channel) => {
                        return Object.keys(channel)[0] === "homeaway";
                    });

                    return(
                        <div className="channels-link">
                            {
                                homeawayChannel.length > 0 &&
                                this.renderChannelsUrl(homeawayChannel, 'homeaway')
                            }
                        </div>
                    )
                }
            },
            {
                Header:() => tableSort("Marriott"),
                'accessor': 'url',
                'minWidth': 115,
                'Cell': row=>{
                    let channels = row && row.original && row.original.channels;
                    let marriottChannel = [];
                    
                    marriottChannel = channels.filter((channel) => {
                        return Object.keys(channel)[0] === "marriott";
                    });

                    return(
                        <div className="channels-link">
                            {
                                marriottChannel.length > 0 &&
                                this.renderChannelsUrl(marriottChannel, 'marriott')
                            }
                        </div>
                    )
                }
            },
        ]

        if(showAllOrg && (channelsReports && channelsReports[0] && channelsReports[0].organization_name)) {
            let orgName = 
                {
                    Header:() => tableSort("Organization"),
                    'accessor': 'organization_name',
                    "minWidth": 100,
                    'sortable': false,
                    'Cell': row=>(
                        <div className='organization_name'>
                            <strong>{row.value}</strong>
                        </div>
                    )
                };

            columns.splice(2, 0, orgName);
        }

        return columns;
    }

    async componentWillMount() {
        const { user } = this.props;

        let queryStringActivePage = 
            this.props.location.search ? 
            parseInt(queryString.parse(this.props.location.search).page) : 
            1;

        this.setState({
            isLoading: true
        });

        let orgData = getOrgData(user, this.org);

        this.allOrganizations = orgData.allOrganizations;

        await this.getChannelsReports(queryStringActivePage);

        this.setState({
            isLoading: false
        });
    }

    async componentWillReceiveProps(newProps) {
        let queryStringActivePage = 
            this.props.location.search ? 
            parseInt(queryString.parse(this.props.location.search).page) : 
            1;
        let newQueryStringActivePage = 
            newProps.location.search ? 
            parseInt(queryString.parse(newProps.location.search).page) : 
            1;

        if(queryStringActivePage !== newQueryStringActivePage) {
            newQueryStringActivePage = 
                !((newQueryStringActivePage - 1) < 0) ? 
                newQueryStringActivePage - 1 : 
                0;

            await this.fetchChannelsReports(parseInt(newQueryStringActivePage), this.state.sorted);
        }
    }

    getChannelsReports = async (activePage) => {
        const { 
            showAllOrg,
            pageSize,
            sorted
        } = this.state;
        let offset = (activePage - 1) * pageSize;
        let sortDesc = sorted.desc ? "-" : "+";

        try {
            let response = await API.get('rptapi',`/reports/listings_channels?organization=${showAllOrg ? encodeURIComponent(this.allOrganizations.join()) : this.org}&limit=${encodeURIComponent(pageSize)}&offset=${encodeURIComponent(offset)}&tid=${encodeURIComponent(this.tid)}&sort=${encodeURIComponent(sorted.id)}${encodeURIComponent(sortDesc)}`, { response: true, isCognito: true });

            if(response && response.data && response.data.items && response.data.items.length) {
                this.setState({
                    activePage: activePage,
                    channelsReports: response.data.items,
                    initStateChannelsReports: response.data.items,
                    totalCount: response.headers["x-total-count"]
                }, () => {
                    this.setState({
                        columns: this.updateColumns(),
                    })
                }); 
            }
            else {
                if(this.props.location.search) {
                    this.props.history.push("/reports/channels");
                } 
                else {
                    this.fetchChannelsReports(0, sorted);
                };
            };
        } 
        catch(e) {
            this.setState({
                error: true
            });
        };
    }

    fetchChannelsReports = async (activePage, sorted, compare) => {
        const { 
            showAllOrg,
            pageSize,
            initStateChannelsReports,
            keyword
        } = this.state;

        this.setState({
            isLoading: true
        });

        let offset = pageSize * activePage;
        let sortDesc = sorted.desc ? "-" : "+";

        try {
            let response = await API.get('rptapi',`/reports/listings_channels?organization=${showAllOrg ? encodeURIComponent(this.allOrganizations.join()) : this.org}&limit=${encodeURIComponent(pageSize)}&offset=${encodeURIComponent(offset)}&tid=${encodeURIComponent(this.tid)}&sort=${encodeURIComponent(sorted.id)}${encodeURIComponent(sortDesc)}&keyword=${encodeURIComponent(keyword)}`, { response: true, isCognito: true });

            if(response && response.data && response.data.items) {
                this.setState({
                    activePage: activePage + 1,
                    channelsReports: response.data.items,
                    initStateChannelsReports: compare ? response.data.items : initStateChannelsReports,
                    pageSize: pageSize,
                    totalCount: response.headers["x-total-count"],
                    sorted: {
                        ...this.state.sorted,
                        id: sorted ? sorted.id : this.state.sorted.id,
                        desc: sorted ? sorted.desc : this.state.sorted.desc,
                    },
                    isLoading: false
                }, () => {
                    this.setState({
                        columns: this.updateColumns(),
                    })
                });
            };
        } 
        catch(e) {
            this.setState({
                error: true,
                isLoading: false
            });
        };
    }

    getChannelsReportsCSV = async () => {
        const { 
            keyword,
            showAllOrg
        } = this.state;
        const tid = getSessionId();
        let response = [];
        let reports = [];
        let offset = 0;
        let limit = 100;
        let count = 0;
        let totalCount = 0;

        this.setState({
            isLoading: true,
            dropdownIsOpen: false
        });

        try {
            response = await API.get("rptapi", `/reports/listings_channels?organization=${showAllOrg ? encodeURIComponent(this.allOrganizations.join()) : this.org}&limit=${limit}&offset=${offset}&tid=${tid}&keyword=${encodeURIComponent(keyword)}`, { response: true, isCognito: true });
            if(response && response.data && response.data.items) {
                
                response.data.items.forEach((x) => {
                    reports = [...reports, x];
                });

                count = count + response.data.items.length;

                totalCount = response.data.pagination.total_results;


                while(count < totalCount) {
                    try {
                        offset = offset + 100;

                        response = await API.get("rptapi", `/reports/listings_channels?organization=${showAllOrg ? encodeURIComponent(this.allOrganizations.join()) : this.org}&limit=${limit}&offset=${offset}&tid=${tid}&keyword=${encodeURIComponent(keyword)}`, { response: true, isCognito: true });

                        response.data.items.forEach((x) => {
                            reports = [...reports, x];
                        });
        
                        count = count + response.data.items.length;
                    }
                    catch(e) {
                        console.log(e)
                    };
                };
                if(count == totalCount) {
                    let newReports = [];

                    reports.forEach((report) => {
                        let redawning = report.channels.filter((channel) => {
                            return Object.keys(channel) == "redawning";
                        });
                        let bookingcom = report.channels.filter((channel) => {
                            return Object.keys(channel) == "bookingcom";
                        });
                        let airbnb = report.channels.filter((channel) => {
                            return Object.keys(channel) == "airbnb";
                        });
                        let expedia = report.channels.filter((channel) => {
                            return Object.keys(channel) == "expedia";
                        });
                        let homeaway = report.channels.filter((channel) => {
                            return Object.keys(channel) == "homeaway";
                        });

                        newReports.push({
                            property_id: report.property_id,
                            reference_id: report.reference_id,
                            title: report.title,
                            redawning: redawning.length ? redawning[0].redawning.url : "",
                            bookingcom: bookingcom.length ? bookingcom[0].bookingcom.url : "",
                            airbnb: airbnb.length ? airbnb[0].airbnb.url : "",
                            expedia: expedia.length ? expedia[0].expedia.url : "",
                            vrbo: homeaway.length ? homeaway[0].homeaway.url : "",
                        });
                    });
                    
                    this.CSVReportsCopy = newReports;
                    
                    this.setState({
                        CSVReports: newReports,
                        isLoading: false,
                    });
                }
            };
        }
        catch(e) {
            console.log(e)
            this.setState({
                isLoading: false,
            });
        }
    }

    downloadCSV = async () => {
        const { 
            CSVReports
        } = this.state;

        if(CSVReports !== this.CSVReportsCopy) {
            await this.getChannelsReportsCSV();

            const CSVcomponent = document.getElementById("download-csv");
            CSVcomponent.click();
        }
        else {
            const CSVcomponent = document.getElementById("download-csv");
            CSVcomponent.click();
        }
    }

    toggleDropdown = () => this.setState({ dropdownIsOpen: !this.state.dropdownIsOpen })

    pageOnChange = (page) => {
        this.props.history.push(`/reports/channels?page=${page}`);
    }

    pageSizeOnChange = (newPageSize) => {
        if(!isNaN(newPageSize) && newPageSize.trim() !== "" && newPageSize !== this.state.pageSize) {
            this.setState({
                pageSize: parseInt(newPageSize),
            }, () => {
                this.fetchChannelsReports(0, this.state.sorted); 
            });
        };
    }

    onSortedChange = (newSorted) => {
        this.fetchChannelsReports(this.state.activePage - 1, newSorted[0]);
    }
   
    handleSearch = (e) => {
        e.preventDefault();
        this.fetchChannelsReports(0, this.state.sorted);
    }

    handleSearchChange = (e) => {
        let name = e.target.name;
        let val = e.target.value;

        this.setState({
            [name] : val
        });
    }

    getTooltip = (id, text) => {
        return <Tooltip id={id}>{text}</Tooltip>
    }

    handleShowAllOrg = (name) => {
        this.setState({ 
            [name]: !this.state[name]
        }, () => {
            this.fetchChannelsReports(0, this.state.sorted)
        });
    }
    getRef = (r) => {
        this.reactTable = r;
    }

    renderChannelsUrl = (data, channel) => {
        return (
            <OverlayTrigger 
                placement='bottom' 
                overlay={this.getTooltip('redawning', data[0][channel].url)}>
                    <a 
                        target='_blank'
                        className="clickable-text" 
                        href={data[0][channel].url}
                    >
                        {data[0][channel].url}
                    </a>
            </OverlayTrigger>
        )
    }
    
    render() {
        const { 
            isLoading, 
            activePage, 
            pageSize,
            sorted, 
            columns,
            channelsReports,
            dropdownIsOpen,
            CSVReports,
            totalCount,
            showAllOrg
        } = this.state;

        return(
            <PageLayout isCentered={true}>

                {isLoading && <Loader />}

                <Title 
                    title="Channels" 
                    isCentered={true} 
                    breadcrumbItems={this.breadcrumbItems}
                >
                    {
                        this.allOrganizations.length > 1 &&
                        this.org !== '_global_' && 
                        this.org !== 'travelagencies' &&
                        <div className="filters-wrap">
                            <InputField 
                                type="checkbox" 
                                value={showAllOrg} 
                                cbChange={() => this.handleShowAllOrg('showAllOrg')}
                            >
                                All Organizations
                            </InputField>
                        </div>
                    }
                    <DropdownButton  
                        title="Actions"
                        className="primary-btn white-btn"
                        id="property-actions"
                        onToggle={this.toggleDropdown}
                        open={dropdownIsOpen}
                    >
                        <MenuItem 
                            onClick={channelsReports.length ? this.downloadCSV : null}
                            disabled={!channelsReports.length}
                        >
                            Download CSV
                        </MenuItem>
                        <CSVLink
                            asyncOnClick={true}
                            headers={this.CSVHeaders}
                            data={CSVReports}
                            filename={`${this.org}-channels-reports.csv`}
                            target="_blank"
                            id="download-csv"
                        >
                        </CSVLink>
                    </DropdownButton>
                    <form 
                        className='search-inp'
                        onSubmit={e => this.handleSearch(e)}
                    >
                        <InputField 
                            type='searchbox' 
                            placeholder='Search' 
                            name='keyword'
                            value={this.state.keyword}
                            onChange={e => this.handleSearchChange(e)}
                            clearSearch={()=> {
                                let compareChannelsData = this.state.channelsReports === this.state.initStateChannelsReports;
                                this.setState({
                                    keyword: ''
                                }, !compareChannelsData ? () => this.fetchChannelsReports(0, this.state.sorted, true) : null);
                            }} 
                        />
                    </form>
                </Title>
                {
                    <div id="content">
                        <div className="container-fluid">
                            <div className="reports_channels">
                                <ReactTableComponent
                                    apiBase={true}
                                    getRef={this.getRef}
                                    reactTable={this.reactTable}
                                    className='-highlight'
                                    noDataText={isLoading ? "Loading..." : "No Reports found. Please contact Customer Support."}
                                    minRows={0}
                                    showPagination={true}
                                    data={channelsReports}
                                    resizable={false}
                                    pageSize={pageSize}
                                    activePage={activePage}
                                    columns={columns}
                                    defaultSortDesc={true}
                                    defaultSorted={[sorted]}
                                    totalCount={Number(totalCount)}
                                    pageSizeOnChange={(pageSize) => this.pageSizeOnChange(pageSize)}
                                    pageOnChange={(page) => this.pageOnChange(page)}
                                    onSortedChange={this.onSortedChange}
                                />
                            </div>
                        </div>
                    </div>
                }
            </PageLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        org: state.roleManager.org,
        user: state.authState.user,
    }
}

export default connect(
    mapStateToProps,
    null
)(ReportsChannels);