import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Title } from '../global/Title';
import { API } from 'aws-amplify';
import { updateOrganization, updateOrganizationTitle, updateOrganizationId, setRole } from '../../js/actions/index';
import { STRING_CONSTANTS } from '../../js/constants';

import PageLayout from '../global/PageLayout';
import Loader from '../global/Loader';
import Col from 'react-bootstrap/lib/Col';
import PropertiesForm from '../content/properties/PropertiesForm';

class EditProperty extends Component {
    routeFromCalendar = false;
    routeFromFeeSchedule = false;
    org = this.props.org;
    state = {
        propertyData: {},
        integrationsDataByOrgName: {"integration_name": "racalendar"},
        isLoading: false,
        status: null,
    }

    async componentDidMount() {
        this.routeFromCalendar =
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.routeFromCalendar;
        this.routeFromFeeSchedule =
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.routeFromFeeSchedule;

        await this.getPropertyDetail();
    }

    getPropertyDetail = async () => {
        this.setState({ 
            isLoading: true,
            propertyData: {}, 
        });

        let propertyId = this.props.match.params.property_id || this.props.match.params.roomId;

        try {
            let propertyResponse = await API.get("rapapi", `/properties/${propertyId}?include_image_status`, {});
            let integrationsDataByOrgNameResponse = {"integration_name": "racalendar"};
            
            try {
                integrationsDataByOrgNameResponse = await API.get("rapapi", `/integrations/${propertyResponse.organization_name}`);
            } 
            catch (err)  {
                integrationsDataByOrgNameResponse = {"integration_name": "racalendar"};
            };
            
            this.redirectRoute(propertyResponse);
            this.setPropertyOrg(propertyResponse.organization_name);

            this.setState({
                propertyData: propertyResponse,
                integrationsDataByOrgName: (integrationsDataByOrgNameResponse && integrationsDataByOrgNameResponse.integration_name) ? integrationsDataByOrgNameResponse : {"integration_name": "racalendar"},
                isLoading: false,
            });          
        } catch (err) {
            this.setState({ 
                status: err.response && err.response.status,
                isLoading: false,
            });
        }
    }

    fetchPropertyDetail = async () => {
        let propertyId = this.props.match.params.property_id || this.props.match.params.roomId;

        try {
            let propertyResponse = await API.get("rapapi", `/properties/${propertyId}?include_image_status`, {});
            let integrationsDataByOrgNameResponse = {"integration_name": "racalendar"};
            
            try {
                integrationsDataByOrgNameResponse = await API.get("rapapi", `/integrations/${propertyResponse.organization_name}`);
            }
            catch (err)  {
                integrationsDataByOrgNameResponse = {"integration_name": "racalendar"};
            };
            
            this.setState({
                propertyData: propertyResponse,
                integrationsDataByOrgName: (integrationsDataByOrgNameResponse && integrationsDataByOrgNameResponse.integration_name) ? integrationsDataByOrgNameResponse : {"integration_name": "racalendar"},
            });
        } catch (err) {
            console.log(err)
        }
    }

    redirectRoute = (data) => {
        if(data && data.level === 'repchild') {
            this.props.history.push({
                pathname: 
                `/collections/${data && data.repconfig && data.repconfig.property_collection_id}/rooms/${data && data.property_id}/edit`
            })
        } else if (data && Object.keys(data.repconfig).length && data.repconfig.property_collection_id) {
            this.props.history.push({
                pathname: 
                `/collections/${data && data.repconfig && data.repconfig.property_collection_id}/rooms/${data && data.property_id}/edit`
            })
        } else {
            this.props.history.push({
                pathname: 
                `/properties/${data && data.property_id}/edit`
            })
        }
    }

    setPropertyOrg = (orgName) => {
        let organization = this.props.user.expanded_organization_roles.filter(org => {
            if(org.organization_name === orgName) {
                return true
            }
        });

        if(this.org !== orgName) {
            if(organization && organization.length) {
                this.props.updateOrganization(organization[0].organization_name);
                this.props.updateOrganizationTitle(organization[0].title);
                this.props.updateOrganizationId(organization[0].organization_id);
                this.props.setRole(organization[0].roles);
    
                sessionStorage.setItem('org', organization[0].organization_name);
                sessionStorage.setItem('orgTitle', organization[0].title);
                sessionStorage.setItem('orgId', organization[0].organization_id);

                sessionStorage.removeItem('reservations-listing');
                sessionStorage.removeItem('properties-listing');
            };
        };
    }

    render() {
        const { 
            propertyData, 
            integrationsDataByOrgName, 
            status, 
            isLoading 
        } = this.state;
        let breadcrumbs = [];

        if(Object.entries(propertyData).length !== 0) {
            breadcrumbs = [{
                title: "Properties",
                link:  "/properties"
            },{
                title: propertyData.content.title || propertyData.property_id
            }]
        };
            
            return (
                <PageLayout isCentered={true}>

                    {isLoading && <Loader />}

                    {
                        !isLoading && Object.entries(propertyData).length !== 0 && 
                        <Fragment>
                            <PropertiesForm 
                                breadcrumbItems={breadcrumbs}
                                propertyData={propertyData}
                                fetchPropertyDetail={this.fetchPropertyDetail}
                                routeFromCalendar={this.routeFromCalendar}
                                routeFromFeeSchedule={this.routeFromFeeSchedule}
                                integrationsDataByOrgName={integrationsDataByOrgName}
                                title={
                                    `Edit ${propertyData && propertyData.level === "rep" ? 
                                    "Room Type" : 
                                    "Property"} ${propertyData.property_id || this.props.match.params.property_id}`}
                            />
                        </Fragment>
                    }
                    {
                        status && 
                        <Fragment>
                            <Title
                                breadcrumbItems={breadcrumbs} 
                                isCentered={true}
                                title={
                                    `Edit ${propertyData && propertyData.level === "rep" ? 
                                    "Room Type" : 
                                    "Property"} ${propertyData.property_id || this.props.match.params.property_id}`} 
                            />
                            <div id="content" style={{height: '70vh'}}>
                                <Col xs={12}>
                                    {
                                        status && 
                                        <div className="no-collection">
                                            { 
                                                status==404 ? 
                                                STRING_CONSTANTS.PROPERTY_NOT_FOUND : 
                                                (status==403 ? 
                                                    STRING_CONSTANTS.NO_ACCESS_PROPERTY : 
                                                    <Loader/>)
                                            }
                                        </div>
                                    }
                                </Col>
                            </div>
                        </Fragment>
                    }
                </PageLayout>
            )  
        }
    }

const mapStateToProps = state => {
    return {
        org: state.roleManager.org,
        user: state.authState.user,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setRole: (role) => {
            dispatch(setRole(role));
        },
        updateOrganization: (org) => {
            dispatch(updateOrganization(org))
        },
        updateOrganizationTitle: (org) => {
            dispatch(updateOrganizationTitle(org))
        },
        updateOrganizationId: (orgId) => {
            dispatch(updateOrganizationId(orgId))
        },
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(EditProperty));