import React, { Component } from 'react';
import Link from 'react-router-dom/Link';
import { connect } from 'react-redux';
import { Title } from '../global/Title';
import { API } from 'aws-amplify';
import queryString from 'query-string';
import { CSVLink } from "react-csv";
import moment from 'moment';

import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import Popover from 'react-bootstrap/lib/Popover';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';

import { InputField } from '../global/forms/FormElements';
import { 
    getSessionId,
    getOrgData
} from '../../helpers/helpers';
import PageLayout from '../global/PageLayout';
import Loader from '../global/Loader';
import ReactTableComponent from '../global/ReactTableComponent';

class ReportsValidations extends Component {
    constructor(props) {
        super(props);
        this.reactTable = null;
        this.org = this.props.org;
        this.tid = getSessionId();
        this.allOrganizations = [];
        this.availabilityInfoMessage = "Percentage of nights available in the next 180 days";
        this.breadcrumbItems = [
            { title: "Reports" },
            { title: "Validations" },
        ];
        this.CSVHeaders = [
            { label: 'Timestamp', key: 'timestamp'},
            { label: 'Validation ID', key: 'vr_id'},
            { label: 'Property ID', key: 'nid'},
            { label: 'Reference ID', key: 'prop_id'},
            { label: 'Channels', key: 'channel'},
            { label: 'Category', key: 'category'},
            { label: 'Property Name', key: 'title'},
            { label: 'Description', key: 'description'},
            { label: 'Severity', key: 'severity'},
        ];
        this.state = {
            isLoading: false,
            pageSize: 10,
            activePage: 1,
            validationsData: [],
            initStateValidationsData: [],
            CSVReports: [],
            totalCount: 0,
            keyword: "",
            dropdownIsOpen: false,
            showAllOrg: true,
            columns: [],
        };
    }

    updateColumns = () => {
        const { 
            showAllOrg,
            validationsData
        } = this.state;
        let columns = [];

        columns = [
            ...columns,
            {
                Header: 'Property ID',
                accessor: 'nid',
                maxWidth: 150,
                sortable: false,
                'Cell': row=>{
                    const propertyId = row && row.original && row.original.nid;

                    return(
                        <div>
                            <Link 
                                className="clickable-text" 
                                to={`/properties/${propertyId}/edit`}
                            >
                                {propertyId}
                            </Link>
                        </div>
                    )
                }
            },
            {
                Header: 'Reference ID',
                accessor: 'prop_id',
                maxWidth: 150,
                sortable: false,
            },
            {
                Header: 'Channels',
                accessor: 'channel',
                maxWidth: 150,
                sortable: false,
            },
            {
                Header: 'Category',
                accessor: 'category',
                maxWidth: 150,
                sortable: false,
            },
            {
                Header: 'Property Name',
                accessor: 'title',
                sortable: false,
            },
            {
                Header: 'Description',
                accessor: 'description',
                sortable: false,
            },
        ];

        if(showAllOrg && (validationsData && validationsData[0] && validationsData[0].organization_name)) {
            let orgName = 
                {
                    'Header': "Organization",
                    'accessor': 'organization_name',
                    'maxWidth': 175,
                    'sortable': false,
                    'Cell': row=>(
                        <div className='organization_name'>
                            <strong>{row.value}</strong>
                        </div>
                    )
                };

            columns.splice(2, 0, orgName);
        }

        return columns;
    }
    
    async componentWillMount() {
        const { user } = this.props;

        this.setState({
            isLoading: true
        });

        let queryStringActivePage = this.props.location.search ? 
        parseInt(queryString.parse(this.props.location.search).page) : 
        1;

        let orgData = getOrgData(user, this.org);

        this.allOrganizations = orgData.allOrganizations;

        await this.getValidations(queryStringActivePage);

        this.setState({
            isLoading: false
        });
    }

    async componentWillReceiveProps(newProps) {
        let queryStringActivePage = 
            this.props.location.search ? 
            parseInt(queryString.parse(this.props.location.search).page) : 
            1;
        let newQueryStringActivePage = 
            newProps.location.search ? 
            parseInt(queryString.parse(newProps.location.search).page) : 
            1;

        if(queryStringActivePage !== newQueryStringActivePage) {
            newQueryStringActivePage = 
                !((newQueryStringActivePage - 1) < 0) ? 
                newQueryStringActivePage - 1 : 
                0;

            await this.fetchValidations(parseInt(newQueryStringActivePage));
        }
    }

    getValidations = async (activePage) => {
        const { 
            showAllOrg,
            pageSize
        } = this.state;
        let response = [],
            offset = (activePage - 1) * pageSize;

        try {
            response = await API.get("rptapi", `/reports/listings_validations?organization=${showAllOrg ? encodeURIComponent(this.allOrganizations.join()) : this.org}&limit=${pageSize}&offset=${offset}&tid=${this.tid}`, { response: true, isCognito: true });

            if(response && response.data && response.data.items) {
                this.setState({
                    activePage: activePage,
                    validationsData: response.data.items,
                    initStateValidationsData: response.data.items,
                    totalCount: response.data.pagination.total_results,
                }, () => {
                    this.setState({
                        columns: this.updateColumns(),
                    })
                });
            };
        } 
        catch(e) {
            console.log(e)
        };
    }

    fetchValidations = async (activePage, compare) => {
        const { 
            showAllOrg,
            pageSize,
            initStateValidationsData,
            keyword
        } = this.state;
        let response = [],
            offset = pageSize * activePage;

        this.setState({
            isLoading: true
        });

        try {
            response = await API.get("rptapi", `/reports/listings_validations?organization=${showAllOrg? encodeURIComponent(this.allOrganizations.join()) : this.org}&limit=${pageSize}&offset=${offset}&tid=${this.tid}&keyword=${encodeURIComponent(keyword)}`, { response: true, isCognito: true });

            if(response && response.data && response.data.items) {
                this.setState({
                    activePage: activePage + 1,
                    validationsData: response.data.items,
                    initStateValidationsData: compare ? response.data.items : initStateValidationsData,
                    pageSize: pageSize,
                    totalCount: response.data.pagination.total_results,
                    isLoading: false
                }, () => {
                    this.setState({
                        columns: this.updateColumns(),
                    })
                });
            };
        } 
        catch(e) {
            this.setState({
                isLoading: false
            });
        };
    }

    getValidationsReportsCSV = async () => {
        const {
            keyword,
            showAllOrg
        } = this.state;
        const tid = getSessionId();
        let response = [];
        let reports = [];
        let offset = 0;
        let limit = 100;
        let count = 0;
        let totalCount = 0;

        this.setState({
            isLoading: true,
            dropdownIsOpen: false
        });

        try {
            response = await API.get("rptapi", `/reports/listings_validations?organization=${showAllOrg? encodeURIComponent(this.allOrganizations.join()) : this.org}&limit=${limit}&offset=${offset}&tid=${tid}&keyword=${encodeURIComponent(keyword)}`, { response: true, isCognito: true });
            if(response && response.data && response.data.items) {
                
                response.data.items.forEach((x) => {
                    reports = [...reports, x];
                });

                count = count + response.data.items.length;

                totalCount = response.data.pagination.total_results;


                while(count < totalCount) {
                    try {
                        offset = offset + 100;

                        response = await API.get("rptapi", `/reports/listings_validations?organization=${showAllOrg? encodeURIComponent(this.allOrganizations.join()) : this.org}&limit=${limit}&offset=${offset}&tid=${tid}`, { response: true, isCognito: true });

                        response.data.items.forEach((x) => {
                            reports = [...reports, x];
                        });
        
                        count = count + response.data.items.length;
                    }
                    catch(e) {
                        console.log(e)
                    };
                };
                if(count >= totalCount) {
                    this.CSVReportsCopy = reports;
                    
                    reports.forEach((report) => {
                        if(report.timestamp) {
                            report.timestamp = moment.unix(report.timestamp).format("MM-DD-YYYY");
                        }
                    });
                    
                    this.setState({
                        CSVReports: reports,
                        isLoading: false,
                    });
                }
            };
        }
        catch(e) {
            console.log(e)
            this.setState({
                isLoading: false,
            });
        }
    }

    downloadCSV = async () => {
        const { 
            CSVReports
        } = this.state;

        if(CSVReports !== this.CSVReportsCopy) {
            await this.getValidationsReportsCSV();

            const CSVcomponent = document.getElementById("download-csv");
            CSVcomponent.click();
        }
        else {
            const CSVcomponent = document.getElementById("download-csv");
            CSVcomponent.click();
        }
    }

    popover = (message, title, placement, iconName) => {
        return (
            <span>
                <OverlayTrigger 
                    placement={placement}
                    overlay={<Popover
                        id="popover-basic"
                        placement={placement}
                        title={title ? title : null}
                    >
                        <p>{message}</p>
                    </Popover>}
                >
                    <div className="info-popover">
                        <i className={iconName}></i>
                    </div> 
                </OverlayTrigger>
            </span>
        )
    }

    pageOnChange = (page) => {
        this.props.history.push(`/reports/validations?page=${page}`);
    }

    toggleDropdown = () => this.setState({ dropdownIsOpen: !this.state.dropdownIsOpen })

    pageSizeOnChange = (newPageSize) => {
        if(!isNaN(newPageSize) && newPageSize.trim() !== "" && newPageSize !== this.state.pageSize) {
            this.setState({
                pageSize: parseInt(newPageSize),
            }, () => {
                this.fetchValidations(0); 
            });
        };
    }
   
    handleSearch = (e) => {
        e.preventDefault();
        this.fetchValidations(0);
    }

    handleSearchChange = (e) => {
        let name = e.target.name;
        let val = e.target.value;

        this.setState({
            [name] : val
        });
    }

    handleShowAllOrg = (name) => {
        this.setState({ 
            [name]: !this.state[name]
        }, () => {
            this.fetchValidations(0)
        });
    }

    getRef = (r) => {
        this.reactTable = r;
    }
    
    render() {
        const { 
            isLoading, 
            activePage, 
            pageSize,
            columns,
            validationsData,
            dropdownIsOpen,
            CSVReports,
            totalCount,
            showAllOrg
        } = this.state;

        return(
            <PageLayout isCentered={true}>

                {isLoading && <Loader />}

                <Title 
                    title="Validations" 
                    isCentered={true} 
                    breadcrumbItems={this.breadcrumbItems}
                >
                    {
                        this.allOrganizations.length > 1 &&
                        this.org !== '_global_' && 
                        this.org !== 'travelagencies' &&
                        <div className="filters-wrap">
                            <InputField 
                                type="checkbox" 
                                value={showAllOrg} 
                                cbChange={() => this.handleShowAllOrg('showAllOrg')}
                            >
                                All Organizations
                            </InputField>
                        </div>
                    }
                    <DropdownButton  
                        title="Actions"
                        className="primary-btn white-btn"
                        id="property-actions"
                        onToggle={this.toggleDropdown}
                        open={dropdownIsOpen}
                    >
                        <MenuItem 
                            onClick={validationsData.length ? this.downloadCSV : null}
                            disabled={!validationsData.length}
                        >
                            Download CSV
                        </MenuItem>
                        <CSVLink
                            asyncOnClick={true}
                            headers={this.CSVHeaders}
                            data={CSVReports}
                            filename={`${this.org}-availability-reports.csv`}
                            target="_blank"
                            id="download-csv"
                        >
                        </CSVLink>
                    </DropdownButton>
                    <form 
                        className='search-inp'
                        onSubmit={e => this.handleSearch(e)}
                    >
                        <InputField 
                            type='searchbox' 
                            placeholder='Search' 
                            name='keyword'
                            value={this.state.keyword}
                            onChange={e => this.handleSearchChange(e)}
                            clearSearch={()=> {
                                let compareAvailabilityData = this.state.validationsData === this.state.initStateValidationsData;
                                this.setState({
                                    keyword: ''
                                }, !compareAvailabilityData ? () => this.fetchValidations(0, true) : null);
                            }} 
                        />
                    </form>
                </Title>
                {
                    <div id="content">
                        <div className="container-fluid">
                            <div className="reports_channels">
                                <ReactTableComponent
                                    apiBase={true}
                                    getRef={this.getRef}
                                    reactTable={this.reactTable}
                                    className='-highlight'
                                    noDataText={isLoading ? "Loading..." : "No Reports found. Please contact Customer Support."}
                                    minRows={0}
                                    showPagination={true}
                                    data={validationsData}
                                    resizable={false}
                                    pageSize={pageSize}
                                    activePage={activePage}
                                    columns={columns}
                                    totalCount={Number(totalCount)}
                                    pageSizeOnChange={(pageSize) => this.pageSizeOnChange(pageSize)}
                                    pageOnChange={(page) => this.pageOnChange(page)}
                                />
                            </div>
                        </div>
                    </div>
                }
            </PageLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        org: state.roleManager.org,
        user: state.authState.user,
    }
}

export default connect(
    mapStateToProps,
    null
)(ReportsValidations);