import React, { Component } from 'react';

import Modal from 'react-bootstrap/lib/Modal';

class ExpandableImage extends Component {
    state = {
        showModal: false
    }
    
    openModal = (e) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({ showModal: true });
    };

    closeModal = () => {
        this.setState({ showModal: false });
    };

    render() {
        const { src, alt = 'Image' } = this.props;
        const { showModal } = this.state;

        return (
            <div className='expandable-image'>
                <img
                    src={src}
                    alt={alt}
                    onClick={e => this.openModal(e)}
                    className='expandable-image-preview'
                    style={{ cursor: 'zoom-in' }}
                />

                <Modal
                    show={showModal}
                    onHide={this.closeModal}
                    className='expandable-image-modal'
                >
                    <img
                        src={src}
                        alt={alt}
                        className='expandable-image-fullsize'
                    />
                </Modal>
            </div>
        );
    }
    
}

export default ExpandableImage;