import Constants from '../constants';

const authState = (state = {}, action) => {
    switch (action.type) {
        case 'UPDATE_LOGIN':
            return Object.assign({}, state, {
                isLoggedIn: action.isLoggedIn
            });

        case 'UPDATE_USER_PERMISSIONS':
            return Object.assign({}, state, {
                permissions: action.perms
            });

        case 'UPDATE_USER':
            return Object.assign({}, state, {
                user: action.user
            });
            
        default:
            return state
    }
 }
  
  export default authState