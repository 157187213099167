import React, { Fragment } from "react";
import FormGroup from "react-bootstrap/lib/FormGroup";
import ControlLabel from "react-bootstrap/lib/ControlLabel";
import FormControl from "react-bootstrap/lib/FormControl";
import { Button, InputGroup } from "react-bootstrap";

export class FieldWrap extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		let classes = this.props.cssClass || "";
		return (
			<FormGroup
				controlId={this.props.controlId}
				bsClass={"field-wrapper " + classes}
				validationState={this.props.validationState}
				style={this.props.style}
			>
				{this.props.children}
			</FormGroup>
		);
	}
}

/**
 * For title above input box..
 */
export class InputLabel extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		let newProps = Object.assign({}, this.props),
			classes = newProps.cssClass || "";
		delete newProps.cssClass;
		return (
			<ControlLabel
				{...newProps}
				htmlFor={this.props.htmlFor}
				srOnly={this.props.srOnly}
				bsClass={"form-label " + classes}
			>
				{this.props.children}
			</ControlLabel>
		);
	}
}

/**
 * For input box and select drop-down.
 */
export class InputField extends React.Component {
	render() {
		let newProps = Object.assign({}, this.props),
			classes = newProps.cssClass || "";
		delete newProps.cssClass;
		switch (this.props.type) {
			case "select":
				return (
					<div className="form-field-select">
						<FormControl
							{...newProps}
							componentClass="select"
							bsClass={"form-field " + classes}
						>
							{this.props.children}
						</FormControl>
						<span className="caret"></span>
					</div>
				);
			case "textarea":
				return (
					<FormControl
						{...newProps}
						componentClass="textarea"
						bsClass={"form-field " + classes}
					/>
				);
			case "checkbox":
				return (
					<label
						className={
							this.props.disabled
								? "checkbox-wrap checkbox-input checkbox-disabled"
								: "checkbox-wrap checkbox-input"
						}
					>
						<input
							name={this.props.name}
							type="checkbox"
							checked={this.props.value || ""}
							disabled={this.props.disabled || ""}
							onChange={this.props.cbChange.bind(this)}
						/>
						<div className="checkbox-label">{this.props.children}</div>
					</label>
				);
			case "radio":
				return (
					<label
						className={`radio-wrap radio-input ${
							this.props.disabled ? "radio-disabled" : ""
						}`}
						style={this.props.style}
					>
						<input
							name={this.props.name}
							disabled={this.props.disabled || ""}
							id={this.props.id}
							type="radio"
							checked={this.props.value || ""}
							onChange={this.props.cbChange.bind(this)}
						/>
						<div style={this.props.style2} className="radio-label">
							{this.props.children}
						</div>
					</label>
				);
			case "search":
				return (
					<div className="search-box-container">
						<FormControl
							bsClass={"form-field " + classes}
							placeholder={this.props.placeholder}
							type={this.props.type}
							name={this.props.name}
							value={this.props.value}
							onChange={this.props.onChange}
						/>
						{this.props.value && (
							<div className="search-box-btn">
								<Button onClick={this.props.handleClear}>
									<i className="icon-Cross"></i>
								</Button>
							</div>
						)}
					</div>
				);
			case "searchbox":
				let [search] = document.getElementsByClassName("search-inp");
				delete newProps.clearSearch;
				if (search) {
					if (newProps.value && newProps.value.length) {
						search.classList.add("dirty");
					} else {
						if (newProps.value !== undefined) {
							search.classList.remove("dirty");
						}
					}
				}
				return (
					<Fragment>
						{newProps.value && newProps.value.length && (
							<label
								className="x-button"
								onClick={() => {
									this.props.clearSearch();
									if (search.childNodes[1]) {
										search.childNodes[1].focus();
									}
								}}
							/>
						)}
						<FormControl {...newProps} bsClass={"form-field " + classes} />
					</Fragment>
				);
			default:
				return <FormControl {...newProps} bsClass={"form-field " + classes} />;
		}
	}
}
/**
 * For primary color button.
 */
export class PrimaryButton extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		let classes = this.props.cssClass || "";
		classes += this.props.fullWidth ? "full-width" : "";
		if (this.props.type == "link") {
			return (
				<a
					className={"primary-btn " + classes}
					href={this.props.href}
					target={this.props.target}
					onClick={this.props.onClick}
				>
					{this.props.children}
				</a>
			);
		} else {
			return (
				<button
					disabled={this.props.disabled}
					className={"primary-btn " + classes}
					style={this.props.style}
					type={this.props.type}
					onClick={this.props.onClick}
				>
					{this.props.children}
				</button>
			);
		}
	}
}

PrimaryButton.defaultProps = {
	type: "button",
	fullWidth: true
};
