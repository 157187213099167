import Constants from '../constants';

const userRoles = Constants.USER_ROLES;

let getRoleConfig = (roles) => {
    let config = {};
    let usersTypes = Object.keys(Constants.USER_TYPES).map(type=>{
        return Constants.USER_TYPES[type];
    });
    roles.sort((a, b)=>{
        return usersTypes.indexOf(a) - usersTypes.indexOf(b);
    });
    if(roles && roles.length) {
        roles.forEach(role => {
            config = Object.assign(config,userRoles[role])
        })
    }
    return config
}

const roleManager = (state = {}, action) => {
    switch (action.type) {
        case 'SET_ROLE':
            return Object.assign({}, state, {
                roles: action.role,
                config: getRoleConfig(action.role)
            });
        case 'UPDATE_ORGANIZATION':
            return Object.assign({}, state, {
                org: action.org
            });
        case 'UPDATE_ORGANIZATION_TITLE':
            return Object.assign({}, state, {
                orgTitle: action.orgTitle
            });        
        case 'UPDATE_ORGANIZATION_ID':
            return Object.assign({}, state, {
                orgId: action.orgId
            });
        case 'INTERNAL_PROPERTY_ADMIN':
            return Object.assign({}, state, {
                isInternalPropertyAdmin: action.boolean
            });
        default:
            return state
    }
}

export default roleManager