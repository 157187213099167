import React, { Component, Fragment } from 'react';
import { mobileAndTabletCheck } from '../../helpers/helpers';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import { withRouter } from 'react-router';
import TableComponent from '../global/TableComponent';
import queryString from 'query-string';
import Link from 'react-router-dom/Link';
import { Grid, Row, Col, Tooltip, OverlayTrigger, Modal } from 'react-bootstrap';
import { InputField, PrimaryButton, InputLabel } from '../global/forms/FormElements';
import apiHandler from '../../js/apiHandler';
import { KebabStyleDropdown } from '../hoc/KebabStyleDropdown';
import Loader from '../global/Loader';
import PropertyStatusButton from '../content/properties/PropertyStatusButton';
import { ValidationModal } from '../content/properties/ValidationModal';
import moment from 'moment';

class Rooms extends Component {
    constructor(props) {
        super(props);
        this.collectionId = props.match.params.collectionId;
        this.propertyId = props.match.params.roomId || props.match.params.property_id;
        this.isMobileDeviceOrTablet = mobileAndTabletCheck()
        this.amenities = {
            amenities: [],
            entertainment: []
        }
        this.state = {
            removeOrDeleteState: '',
            errorMessage: false,
            pageSize: 10,
            activePage: 0,
            admin: false,
            editableKey: undefined,
            property: (props.location.state && props.location.state.data) || undefined,
            isLoading: (props.location.state && props.location.state.data) ? false : true,
            deleteId: false,
            deleteName: undefined,
            modal: false,
            count: 1,
            add: false,
            children: [],
            saveLoad: false,
            roomLoading: false,
            inProgressRow: [],
            statusChangeInProgress: [],
            deleteProgressRow: [],
            isNull: false, 
            columns: this.updateColumns(this.props.mobileMode),
        }
        this.editNumberOfRooms = this.editNumberOfRooms.bind(this);
        this.hideModal = this.hideModal.bind(this)
        this.changeCount = this.changeCount.bind(this)
        this.handleRoomName = this.handleRoomName.bind(this)
    }
    /**
     * Get tooltip of icon
     * @param {*} id id of row 
     * @param {*} text text of tooltip
     */
    getTooltip(id, text) {
        return <Tooltip id={id} className='properties__tooltip'>{text}</Tooltip>
    }
    /**
     * For toggle status of child room LIVE or NOT LIVE.
     * @param {*} id id of child room.
     */
    async toggleStatus(id) {
        let children = this.state.children;
        let index = children.findIndex((data) => id === data.property_id)
        try {
            let statusChangeInProgress = this.state.statusChangeInProgress;
            statusChangeInProgress.push(id)
            this.setState({
                statusChangeInProgress
            })
            children[index].status.published = !children[index].status.published;       
            let newChildren = await apiHandler.put(`/properties/${id}/status`, { body: children[index].status });
            statusChangeInProgress.splice(statusChangeInProgress.indexOf(id), 1)
            if (newChildren.error || newChildren.response) {
                children[index].status.published = !children[index].status.published;
                this.setState({
                    children,
                    statusChangeInProgress
                })
            } else {
                this.setState({
                    children,
                    statusChangeInProgress
                })
            }
        } catch (e) {
            console.log('catch', e)
        }

    }

    updateColumns = mobileMode => {
        return [
            {
                'Header': 'ID',
                'accessor': 'property_id',
                'width': mobileMode ? 50 : 120,
                'sortable': true,
                'Cell': row => {
                    let roomName = mobileMode ? 
                        <div>
                            {row.value}
                        </div> : 
                        <div>
                            <strong>#{row.value}</strong>
                        </div>
                    return roomName
                }
            },
            {
                'Header': 'Room Name',
                'accessor': 'title',
                'minWidth': mobileMode ? 160 : 400,
                'Cell': row =>
                    this.state.admin ? <div key={row.index}>
                        {row.original.property_id === this.state.editableKey ? 
                            (<div className={`${this.isMobileDeviceOrTablet ? 'mobile-' : ''}input-room-name`}>
                                <InputField 
                                    value={this.state.children[row.index].title} 
                                    name="room_name"
                                    autoFocus={true} 
                                    onChange={(e) => this.handleRoomName(e, row.original.property_id)}
                                    onBlur={()=> {
                                        if (this.isMobileDeviceOrTablet) {
                                            this.saveRoomName(false, row.original.property_id)
                                        }
                                    }}
                                >
                                </InputField>
                                {!this.isMobileDeviceOrTablet && 
                                    <button 
                                        to='/properties' 
                                        className="primary-btn white-btn pull-left left-margin" 
                                        onClick={() => { this.saveRoomName(true, row.original.property_id)}}
                                    >
                                        Close
                                    </button>
                                }
                                {!this.isMobileDeviceOrTablet &&
                                    <PrimaryButton 
                                        cssClass="pull-left left-margin" 
                                        fullWidth={false} type="button" 
                                        onClick={() => this.saveRoomName(false, row.original.property_id)}
                                    >
                                        Save
                                    </PrimaryButton>
                                }
                            </div>) : 
                            <div 
                                className="room-editable"
                                onClick={() => { this.state.inProgressRow.indexOf(row.original.property_id) === -1 ? 
                                    this.toggleInputBox(row.original.property_id) : null }}
                            >
                            <span className="elipsis-wrapper">
                            {this.state.children[row.index] && this.state.children[row.index].title}</span>
                            {this.state.inProgressRow.indexOf(row.original.property_id) > -1 ? 
                                (this.state.deleteProgressRow.indexOf(row.original.property_id) > -1 ? 
                                    <span /> : <Loader />) : <i className='icon-edit edit' />}
                            </div>}
                            </div> : 
                            <div 
                                key={row.index}>
                            <div 
                                className="room-editable" style={{cursor: !this.state.admin && 'default'}}>
                                {this.state.children[row.index].title}
                            </div>
                        </div>
            },
            {
                'Header': 'Status',
                'accessor': 'status',
                'width': mobileMode ? 60 : 180,
                'Cell': row => { 
                    if (this.state.statusChangeInProgress.indexOf(row.original.property_id) === -1 ) {
                        return (<div className='table-status-btn'>
                            <PropertyStatusButton 
                                admin={this.state.admin} 
                                status={row.value} 
                                doNotShowSpan={true} 
                                mobileMode={mobileMode} 
                                toggleStatus={() => this.toggleStatus(row.original.property_id)} 
                            />
                        </div>)
                    } else {
                        return <div className='status-loader'><Loader /></div>
                    }
                
                }
            },
            {
                'Header': mobileMode ? '' : 'Actions',
                'accessor': 'property_id',
                'width': mobileMode ? 20 : 200,
                'sortable': false,
                'Cell': row => {

                    let actionsMenu

                    if (mobileMode) {
                        actionsMenu = (
                            <Fragment>
                                <div className="kebab-menu small-kebab-menu">
                                    {[1,2,3].map(el => (
                                            <div key={el} className="kebab-item" />
                                        )
                                    )}
                                </div>
                                <KebabStyleDropdown>
                                    <DropdownButton
                                        title='Actions'
                                        className="primary-btn secondary-btn"
                                        onClick={e => {e.stopPropagation()}}
                                        id="room-actions"
                                    >
                                        <MenuItem>
                                            <Link
                                                onClick={(e) => e.stopPropagation()} 
                                                to={`/calendar/${row.original.property_id}/edit`}>
                                                    <i className="icon icon-Calender"></i>
                                            </Link>
                                        </MenuItem>
                                        <MenuItem>
                                            <i className="icon icon-Cross" onClick={() => {
                                                this.setState({
                                                    removeOrDeleteState: 'remove',
                                                    modal: 'removeOrDeleteModal',
                                                    deleteId: row.value,
                                                    deleteName: row.original.title,
                                                })
                                            }} />
                                        </MenuItem>
                                        {(this.state.isSmartConciergeViewer || this.state.isSmartConciergeAdmin) &&
                                            <MenuItem>
                                                <Link 
                                                    onClick={(e)=> {e.stopPropagation();}} 
                                                    to={
                                                        `/smart-concierge/${this.state.originalChildren[row.index] && this.state.originalChildren[row.index].repconfig && this.state.originalChildren[row.index].repconfig.property_collection_id ? 
                                                        `collections/${this.state.originalChildren[row.index].repconfig.property_collection_id}/` : 
                                                        ''}rooms/${row.original.property_id}`}>
                                                    <i className="icon-concierge"/>
                                                </Link>
                                            </MenuItem>
                                        }
                                        <MenuItem>
                                            <i className="icon icon-Delete" onClick={() => {
                                                this.setState({
                                                    removeOrDeleteState: 'delete',
                                                    modal: 'removeOrDeleteModal',
                                                    deleteId: row.value,
                                                    deleteName: row.original.title,
                                                })
                                            }} />
                                        </MenuItem>
                                    </DropdownButton> 
                                </KebabStyleDropdown>
                            </Fragment>
                        )
                    }
                    else {
                        actionsMenu = (
                            <Fragment>
                                <OverlayTrigger
                                    placement='bottom' 
                                    overlay={this.getTooltip('edit', 'Edit Calendar')}
                                >
                                    <span className='properties__options' style={{cursor: 'pointer'}}>
                                        <Link
                                            onClick={(e) => e.stopPropagation()} 
                                            className='left-margin' 
                                            to={`/calendar/${row.original.property_id}/edit`}>
                                                <i className="icon icon-Calender"></i>
                                        </Link>
                                    </span>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement='bottom' 
                                    overlay={this.getTooltip('remove', 'Remove from Room Type')}
                                >
                                    <span className='properties__options' style={{cursor: 'pointer'}}>
                                        <i className="icon icon-Cross" onClick={() => {
                                            this.setState({
                                                removeOrDeleteState: 'remove',
                                                modal: 'removeOrDeleteModal',
                                                deleteId: row.value,
                                                deleteName: row.original.title,
                                            })
                                        }} />
                                    </span>
                                </OverlayTrigger>
                                {(this.state.isSmartConciergeViewer || this.state.isSmartConciergeAdmin) &&
                                    <OverlayTrigger placement='bottom' overlay={this.getTooltip('smart-concierge', 'Smart Concierge')}>
                                        <span className='properties__options' style={{cursor: 'pointer'}}>
                                            <Link 
                                                onClick={(e)=> {e.stopPropagation();}} 
                                                to={
                                                    `/smart-concierge/${this.state.originalChildren[row.index] && this.state.originalChildren[row.index].repconfig && this.state.originalChildren[row.index].repconfig.property_collection_id ? 
                                                    `collections/${this.state.originalChildren[row.index].repconfig.property_collection_id}/` : 
                                                    ''}rooms/${row.original.property_id}`}>
                                                <i className="icon-concierge"/>
                                            </Link>
                                        </span>
                                    </OverlayTrigger>
                                }
                                <OverlayTrigger
                                    placement='bottom' 
                                    overlay={this.getTooltip('delete', 'Delete Room')}
                                >
                                    <span className='properties__options' style={{cursor: 'pointer'}}>
                                        <i className="icon icon-Delete" onClick={() => {
                                            this.setState({
                                                removeOrDeleteState: 'delete',
                                                modal: 'removeOrDeleteModal',
                                                deleteId: row.value,
                                                deleteName: row.original.title,
                                            })
                                        }} />
                                    </span>
                                </OverlayTrigger>
                            </Fragment>
                        )
                    }
                    return this.state.admin ? (
                        <div className='delete-row-loader'>
                            {this.state.deleteProgressRow.indexOf(row.value) === -1 ? (
                                <div>
                                    {actionsMenu}
                                </div>
                            ) : (
                                <Loader />
                            )}
                        </div>
                    ) : (
                        <div />
                    ) 
                }
            },
        ]
    }

    handlePageChange = (page) => {
        this.props.history.push(`${this.props.history.location.pathname}?page=${page + 1}`)
    }

    /**
     * Save room name of child
     * @param {boolean} cancel click on close then not change
     * @param {string} id child property id
     */
    async saveRoomName(cancel, id) {
        let children = this.state.children;
        let index = children.findIndex((data) => id === data.property_id)
        let originalChildren = this.state.originalChildren;
        if (children[index].title === '' || cancel) {
            children[index].title = originalChildren[index].content.title;
            this.setState({
                children
            })
            this.toggleInputBox();
        } else {
            try {
                let inProgressRow = this.state.inProgressRow;
                inProgressRow.push(id)
                this.setState({
                    inProgressRow
                })
                this.toggleInputBox();
                let original = originalChildren[index].content.title;
                originalChildren[index].content.title = children[index].title;
                let newChildren = await apiHandler.put(`/properties/${id}/content`, { body: originalChildren[index].content });
                inProgressRow.splice(inProgressRow.indexOf(id), 1)
                if (newChildren.error) {
                    children[index].title = original;
                    originalChildren[index].content.title = original;
                    this.setState({
                        children,
                        inProgressRow
                    })
                } else {
                    this.setState({
                        originalChildren,
                        inProgressRow
                    })
                }
            } catch (e) {
                console.log('catch')
                children[index].title = originalChildren[index].content.title;
                this.setState({
                    children
                })
            }

        }
    }
    /** Remove room from room type. */
    async removeRoom(id) {
        let { deleteProgressRow, children, inProgressRow } = this.state
        deleteProgressRow.push(id);
        inProgressRow.push(id);
        this.setState({
            deleteProgressRow,
            modal: false,
            inProgressRow
        })
        try {
            let data = await apiHandler.update('delete', `/properties/${this.propertyId}/children`, { body: [id] })
            let status = 'processing';
            let responseError = false;

            var startTime = moment().valueOf();

            let reponseStatus = setInterval(async () => {
                if (moment().valueOf() - startTime > 20000 || responseError === true) {

                    clearInterval(reponseStatus)

                    inProgressRow.splice(inProgressRow.findIndex(data => 
                        data === id), 1)
                    deleteProgressRow.splice(deleteProgressRow.findIndex(data => 
                        data === id), 1)
                    this.setState({
                        deleteProgressRow: [...deleteProgressRow]
                    })
                    responseError = false
                } else if (status === 'completed') {

                    clearInterval(reponseStatus)

                    let element = document.getElementById('row' + id)
                    
                    setTimeout(async () => {
                        element.classList.add('animation-delete')
                        deleteProgressRow = this.state.deleteProgressRow;
                        children = this.state.children;
                        let originalChildren = this.state.originalChildren;
                        deleteProgressRow.splice(deleteProgressRow.findIndex(data => 
                            data === id), 1)
                        children.splice(children.findIndex(data => 
                            data.property_id === id), 1)
                        originalChildren.splice(originalChildren.findIndex(data => 
                            data.property_id === id), 1)

                        await this.props.fetchPropertyDetail();
                            element.classList.remove('animation-delete')
                            this.setState({
                                deleteProgressRow: [...deleteProgressRow],
                                children: [...children],
                                originalChildren: [...originalChildren],
                            })
                    }, 1000)
                } else {
                    try {
                        let response = await apiHandler.get(data.status_url);
                        status = response && response.data && response.data.status;
                    } catch(e) {
                        responseError = true;
                    }
                }
            },3000)
        } catch(e) {
            inProgressRow.splice(inProgressRow.findIndex(data => 
                data === id), 1)
            deleteProgressRow.splice(deleteProgressRow.findIndex(data => 
                data === id), 1)
            this.setState({
                deleteProgressRow: [...deleteProgressRow],
            })
        }
    }

    /** Delete room from room type. */
    async deleteRoom(id) {
        let { deleteProgressRow, children, inProgressRow } = this.state
        deleteProgressRow.push(id);
        inProgressRow.push(id);
        this.setState({
            deleteProgressRow,
            modal: false,
            inProgressRow,
        })
        try {
            let data = await apiHandler.update('delete', `/properties/${id}`)
            let element = document.getElementById('row' + id)
            setTimeout(async () => {
                element.classList.add('animation-delete')
                deleteProgressRow = this.state.deleteProgressRow;
                children = this.state.children;
                let originalChildren = this.state.originalChildren;
                deleteProgressRow.splice(deleteProgressRow.findIndex(data => 
                    data === id), 1)
                children.splice(children.findIndex(data => 
                    data.property_id === id), 1)
                originalChildren.splice(originalChildren.findIndex(data => 
                    data.property_id === id), 1)
                await this.props.fetchPropertyDetail();
                    element.classList.remove('animation-delete')
                    this.setState({
                        deleteProgressRow: [...deleteProgressRow],
                        children: [...children],
                        originalChildren: [...originalChildren],
                    })
            }, 1000)
        } catch(e) {
            inProgressRow.splice(inProgressRow.findIndex(data => 
                data === id), 1)
            deleteProgressRow.splice(deleteProgressRow.findIndex(data => 
                data === id), 1)
            this.setState({
                deleteProgressRow: [...deleteProgressRow],
            })
        }
    }

    /** Show validation modal of current room type */
    showValidationModal() {
        this.setState({
            isLoading: true
        })
        apiHandler.get(`/properties/${this.propertyId}/validations`, { iscognito: true }).then(validations => {
            if (validations.data) {
                this.setState({
                    showValidations: true,
                    validationData: validations.data,
                    isLoading: false
                })
            } else {
                this.setState({
                    isLoading: false
                })
            }
        })
    }
    /** Handle room name text box. 
     * @param {*} e
     * @param {string} id id of room
    */
    handleRoomName(e, id) {
        let children = this.state.children;
        let index = children.findIndex((data) => data.property_id === id);
        children[index].title = e.target.value;
        this.setState({
            children
        })
    }

    async componentWillReceiveProps(newProps) {
        if (this.props.mobileMode !== newProps.mobileMode) {
            this.setState({
                columns: this.updateColumns(newProps.mobileMode),
            })
        }
        let queryStringActivePage = 
            this.props.location.search ? 
            parseInt(queryString.parse(this.props.location.search).page) : 0;
        let newQueryStringActivePage = 
            newProps.location.search ? 
            parseInt(queryString.parse(newProps.location.search).page) : 0;

        if(queryStringActivePage !== newQueryStringActivePage) {

            this.setState({
                isLoading: true,
                activePage: newQueryStringActivePage
            })
            
            try {
                let offset = this.state.pageSize * (newQueryStringActivePage !== 0 ? newQueryStringActivePage - 1 : 0);
                let children = await apiHandler.get(`/properties/${this.propertyId}/children?status=all&offset=${offset}&limit=${this.state.pageSize}&tid=${this.state.tid}`)
                
                this.setState({
                    children: children.data.map((data) => { return { property_id: data.property_id, title: data.content.title, status: data.status } }),
                    isLoading: false
                })
            } catch (e) {
                this.setState({
                    isLoading: false
                })
                console.log(e)
            }
         }
    }

    componentWillMount() {
        let queryStringActivePage = 
            this.props.location.search ? 
            parseInt(queryString.parse(this.props.location.search).page) : 0;
        this.state.activePage = queryStringActivePage;
        this.state.tid = this.getSessionId();
    }

    async componentDidMount() {
        this.init();
    }
    /** Initialize page */
    async init() {
        let property = undefined;
        this.setState({
            admin: this.props.admin,
            isSmartConciergeAdmin: this.props.isSmartConciergeAdmin,
            isSmartConciergeViewer: this.props.isSmartConciergeViewer,
        });

        let isNull = false;
        if (!this.state.property) {
            property = await apiHandler.get(`/properties/${this.propertyId}`);
            if (property.response) {
                isNull = true;
                switch (property.response.status) {
                    case 404: {
                        this.setState({
                            isLoading: false,
                            isNull: true,
                            msg: 'Unable to find specified Room Type.'
                        })
                    }
                    case 403: {

                    }
                }
            }
            
            property = property.data;

            this.setState({
                property,
                isLoading: false,
            })
        }
        if (!isNull) {
            this.getChildren()
        }
    }
    /** Get children API call */
    async getChildren() {
        this.setState({
            roomLoading: true,
            children: [],
            originalChildren: [],
        })
        try {
            let offset = this.state.pageSize * (this.state.activePage);
            let children = await apiHandler.get(`/properties/${this.propertyId}/children?status=all&offset=${offset}&limit=${this.state.pageSize}&tid=${this.state.tid}`)
            this.setState({
                children: children.data.map((data) => { return { property_id: data.property_id, title: data.content.title, status: data.status } }),
                originalChildren: children.data,
                roomLoading: false
            })
        } catch (e) {
            this.setState({
                roomLoading: false
            })
            console.log(e)
        }
    }
    /**
     * Toggle input box and span text of edit room type.
     * @param {*} id 
     */
    toggleInputBox(id) {
        this.setState({
            editableKey: id || undefined
        })
    }
    editNumberOfRooms() {
        if (this.state.add) {
            return
        }
        this.setState({
            modal: 'Edit Rooms'
        })
    }
    /**
     * Hide delete modal and reinitialize delete id.
     */
    hideModal() {
        this.setState({
            modal: false,
            deleteId: false,
            deleteName: undefined
        })
    }
    /** Change count of add room */
    changeCount(event) {
        this.setState({
            count: event.target.value
        })
    }

    /** Create a session ID to use */
    getSessionId = () => {
        const maximum = 99999
        const minimum = 10000
        return (Math.floor(Math.random() * ((maximum - minimum) + 1)) + minimum)
    }

    /** Add rooms in room type API call */
    async addMoreRooms() {
        try {
            this.setState({
                add: true,
                modal: false,
                count: 1
            })
            let count = this.state.count;
            let data = await apiHandler.post(`/properties/${this.propertyId}/children/${count}`, { body: {} });
            let status = data.status;
            let responseError = false;

            var startTime = moment().valueOf();

            let reponseStatus = setInterval(async () => {
                if (moment().valueOf() - startTime > 120000 || responseError === true) {

                    clearInterval(reponseStatus)

                    this.setState({
                        add: false,
                    })
                    this.hideModal()
                    responseError = false;
                } else if (status === 'completed') {

                    clearInterval(reponseStatus)

                    let children = this.state.children;
                    let offset = this.state.pageSize * (this.state.activePage);
                    let allChildren = await apiHandler.get(`/properties/${this.propertyId}/children?status=all&offset=${offset}&limit=${this.state.pageSize}&tid=${this.state.tid}`)
                    allChildren = allChildren.data.filter((data) => 
                        children.findIndex((child) => 
                            child.property_id === data.property_id) === -1)
                    children = this.state.children.concat(allChildren.map((data) => 
                        { return { property_id: data.property_id, title: data.content.title, status: data.status } }))
                        await this.props.fetchPropertyDetail();
                        this.setState({
                            children,
                            originalChildren: this.state.originalChildren.concat(allChildren),
                            roomLoading: false
                        })
                        this.setState({
                            add: false,
                        })
                } else if (status === 'created' || status === 'processing') {
                    let response = await apiHandler.get(data.status_url);
                    status = response && response.data && response.data.status;
                } else {
                    responseError = true;
                    this.setState({ errorMessage: true})
                    setTimeout(() => {
                        this.setState({ errorMessage: false})
                    },3000)
                }
            },3000)
        } catch (e) {
            this.setState({
                add: false,
            })
            this.hideModal()
        }
    }
    
    /** For close validation modal */
    closeValidations() {
        this.setState({
            showValidations: false
        })
    }
    /** Handle back button click */
    handleBack() {
        this.props.history.goBack();
    }

    handlePageSizeChange = async (newPageSize) => {
        if(!isNaN(newPageSize) && newPageSize.trim() !== "" && newPageSize !== this.state.pageSize) {
            // change state page size to the new page size
            await this.setState({
                pageSize: newPageSize,
                isLoading: true,
                activePage: 1
            })
            
            try {
                let children = await apiHandler.get(`/properties/${this.propertyId}/children?status=all&offset=0&limit=${this.state.pageSize}&tid=${this.state.tid}`)
                
                this.setState({
                    children: children.data.map((data) => { return { property_id: data.property_id, title: data.content.title, status: data.status } }),
                    isLoading: false
                })
            } catch (e) {
                this.setState({
                    isLoading: false
                })
                console.log(e)
            }
        }
    }

    /** render delete modal when delete any room from room type */
    renderRemoveOrDeleteModal() {
        return <Modal 
            className={`room-delete ${this.props.mobileMode ? 'mobile-modal' : ''}`}
            show={this.state.modal === 'removeOrDeleteModal'} 
            onHide={this.hideModal}>
        <Modal.Body>    
            <Col md={12} lg={12} sm={12} xs={12} className='fix-height'>
                <h1>              
                    {`Are you sure you want to ${this.state.removeOrDeleteState === 'delete' ? 'delete' : 'remove'} ${this.state.deleteName ? this.state.deleteName : ''} from this Room Type?`}
                </h1>
            </Col>
            <Col md={6} className='pri-btn'>
                <PrimaryButton 
                    cssClass="pull-left white-btn col-md-12 " 
                    fullWidth={true} 
                    type="button" 
                    onClick={this.hideModal}
                >
                    Cancel
                </PrimaryButton>
            </Col>
            <Col md={6} className='pri-btn'>
                <PrimaryButton 
                    cssClass="pull-right col-md-12 " 
                    fullWidth={true} 
                    type="button" 
                    onClick=
                        {this.state.removeOrDeleteState === 'delete' ? 
                        this.deleteRoom.bind(this, this.state.deleteId) : 
                        this.removeRoom.bind(this, this.state.deleteId) }
                >
                    {this.state.removeOrDeleteState === 'delete' ? 
                        'Delete' : 
                        'Remove'}
                </PrimaryButton>
            </Col>
        </Modal.Body>
    </Modal>
    }

    render() {
        let property = this.state.property || {};
        if (this.state.isNull) {
            return <div id='content' className='rooms'>
                        <div className='no-collection'>{this.state.msg}</div>
                    </div>
        }
        return (
            <div>
                {this.state.isLoading && <Loader />}
                {<div id='content' className='rooms'>
                    <Grid fluid={true}>
                        <Row className="room-list-title">
                            <div className="title-container">
                            <span
                                style={{display: 'flex', justifyContent: 'center'}}
                                className={this.state.errorMessage ? 'error-msg':'hide'}>
                                    Unable to process your request. Please contact Customer Support.
                            </span>
                                {
                                    this.state.admin &&
                                    <button
                                        type="button"
                                        className='primary-btn pull-right black-btn' 
                                        onClick={this.editNumberOfRooms}>
                                        {this.state.add ? 
                                            <span className={'glyphicon glyphicon-refresh spinning'} /> : 
                                            <i className='icon-Plus create-icon' />} 
                                        {!this.state.add ? 'Add Rooms' : 'Adding Rooms...'}
                                    </button>
                                }
                            </div>
                            <TableComponent
                                className={this.props.mobileMode ? 
                                    'mobile-styled-table' : 
                                    ''
                                }
                                resizable={false} minRows={0}
                                pageSize={this.state.pageSize}
                                showPagination={Boolean(this.state.children.length)}
                                activePage={
                                    this.state.activePage !== 0 ? 
                                    this.state.activePage : 1}
                                handlePageChange={this.handlePageChange}
                                onPageSizeChange={this.handlePageSizeChange}
                                totalItemsCount={this.props.totalItemsCount}
                                itemType={this.props.totalItemsCount == 1 ? 'Room' : 'Rooms'}
                                defaultPageSize={-1}
                                mobileMode={this.props.mobileMode}
                                data={this.state.children}
                                columns={this.state.columns}
                                noDataText={this.state.roomLoading ? <Loader /> : "No Rooms added for this Property."}
                                getTheadTrProps={(props) => ({ className: 't-header' })}
                                getTrGroupProps={(props, rowInfo) => {
                                    return {
                                        id: 'row' + rowInfo.original.property_id
                                    }
                                }}
                            />
                        </Row>
                    </Grid>
                </div>
                }
                <Modal 
                    className="room-modal" 
                    show={this.state.modal === 'Edit Rooms'} 
                    onHide={this.hideModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Add Rooms
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col 
                                xs={12}
                            >
                                <InputLabel 
                                    htmlFor="cleaning_fee"
                                >
                                    Number of Rooms To Add
                                </InputLabel>
                                <InputField 
                                    type="number" 
                                    placeholder="# of Rooms" 
                                    name="count" 
                                    value={this.state.count} 
                                    onChange={this.changeCount} 
                                    min={1} 
                                />
                            </Col>
                            <Col 
                                md={12} 
                                className='pri-btn'
                            >
                                <PrimaryButton 
                                    cssClass="pull-left" 
                                    fullWidth={false} 
                                    type="button" 
                                    onClick={this.addMoreRooms.bind(this)}
                                >
                                    Add
                                </PrimaryButton>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
                <Modal className="room-status" show={this.state.modal === 'Room Status'} disabled>
                    <Modal.Body>
                        <Col md={12} lg={12} sm={12} xs={12}>
                            <Loader />
                            <h1>{this.state.text}</h1>
                        </Col>
                    </Modal.Body>
                </Modal>
                {
                    this.state.showValidations && 
                    <ValidationModal 
                        show={this.state.showValidations} 
                        property={this.state.property} 
                        onHide={this.closeValidations.bind(this)} 
                        validationData={this.state.validationData} 
                    />
                }
                {this.renderRemoveOrDeleteModal()}
            </div>
        )
    }
}


export default withRouter((Rooms));