import React, { Component, Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import { AddressCard } from './AddressCard';
import GoogleMap from '../../global/GoogleMap.js';
import { InputLabel, InputField } from '../../global/forms/FormElements';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';

export default class Detail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            propertyContentLocks: props.propertyContentLocks,
            integrationsDataByOrgName: (props.integrationsDataByOrgName && props.integrationsDataByOrgName.integration_name) ? props.integrationsDataByOrgName : {"integration_name": "racalendar"},
            isAdmin: props.isAdmin,
            shouldUpdate: true
        }
    }

    shouldComponentUpdate(nextProps) {
        let shouldUpdate = this.state.shouldUpdate
        return (

            shouldUpdate ||

            nextProps.formValidation.title !== this.props.formValidation.title ||
            nextProps.formValidation.address !== this.props.formValidation.address ||
            nextProps.formValidation.longLatRequired !== this.props.formValidation.longLatRequired ||
            nextProps.formValidation.street_address !== this.props.formValidation.street_address ||
            nextProps.formValidation.city !== this.props.formValidation.city ||
            nextProps.formValidation.province !== this.props.formValidation.province ||
            nextProps.formValidation.country !== this.props.formValidation.country ||

            nextProps.data.title !== this.props.data.title ||
            nextProps.data.address !== this.props.data.address ||
            nextProps.data.location.geo_code.lat !== this.props.data.location.geo_code.lat ||
            nextProps.data.location.address !== this.props.data.location.address ||
            
            nextProps.external_property_id !== this.props.external_property_id ||
            nextProps.isFetching !== this.props.isFetching ||
            nextProps.fetchError !== this.props.fetchError ||
            nextProps.mapOptions !== this.props.mapOptions ||   
            nextProps.editAddress !== this.props.editAddress    
        );
    }

    toggleLockState(lockName, lockValue) {
        let {propertyContentLocks} = this.state

        this.setState({
            shouldUpdate: false
        })

        if (propertyContentLocks[lockName] && (lockValue === 'LOCKED' || lockValue === 'UNLOCKED')) {
            propertyContentLocks[lockName] = lockValue

            this.setState({
                propertyContentLocks,
                shouldUpdate: true
            })

            this.props.updateLocks(lockName, lockValue)
        }
    }
    
    render() {
        const {  
            type, 
            data,
            isAdmin,
            formValidation,
            external_property_id,
            isFetching,
            fetchError,
            countries,
            mapOptions,
            mapEvents,
            handleDataChange, 
            handleInputStateChange,
            editAddress,
            addManually,
            isAddressPopulated,
            handleChangeAddress,
            showModal,
            integrationsDataByOrgName,
            mode,
        } = this.props;

        return (
            <div className="details">
                <Row>
                    <Col md={6} sm={12} xs={12}>
                        <InputLabel 
                            id="title"
                            htmlFor="title"
                            className={formValidation.title && 'form-validation'}
                        >		
                            {type} Title &nbsp; {this.props.checkinInstructionsPopover("", this.props.propertyTitleTooltip)}
                            {this.state.isAdmin && this.state.propertyContentLocks && this.state.propertyContentLocks.title && this.state.integrationsDataByOrgName && this.state.integrationsDataByOrgName.integration_name && this.state.integrationsDataByOrgName.integration_name !== 'racalendar' ?
                            <OverlayTrigger placement='top' overlay={<Tooltip id="tip-title" className='properties_title__tooltip'>																																							
                                    {(this.state.propertyContentLocks && this.state.propertyContentLocks.title === 'LOCKED') ? 
                                        "Unlock to enable PMS integration to overwrite title content." : 
                                        "Lock to prevent PMS integration from overwriting edited title content."}
                                </Tooltip>}>
                                <a href="#" onClick={(e) => {
                                        this.toggleLockState('title', (this.state.propertyContentLocks.title === 'LOCKED') ? 'UNLOCKED' : 'LOCKED')
                                    }}>
                                    <i className={(this.state.propertyContentLocks && this.state.propertyContentLocks.title === 'LOCKED') ? 
                                        "icon-lock" : 
                                        "icon-unlock"} />
                                </a>
                            </OverlayTrigger> : ''}
                        </InputLabel>
                        <InputField
                            type="text"  
                            name="title"
                            className={formValidation.title && 'form-validation'}
                            value={data && data.title} 
                            onChange={(e) => handleDataChange(e, "text")}
                            autoFocus
                        />
                        {formValidation.title && <span className="required-error-text">Property Name is required</span>}
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col md={6} sm={12} xs={12}>
                        <InputLabel 
                            htmlFor="external_property_id">
                            Reference ID <em className="optional">(Optional)</em>
                        </InputLabel>
                        <InputField 
                            type="text" 
                            name="external_property_id" 
                            value={external_property_id} 
                            onChange={handleInputStateChange}  
                            readOnly={integrationsDataByOrgName.integration_name !== "racalendar" && mode === "edit"}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className="address-section rows-section">
                        <AddressCard 
                            edit={editAddress} 
                            admin={isAdmin} 
                            addManually={addManually} 
                            isFetching={isFetching} 
                            fetchError={fetchError} 
                            type={type}
                            data={data}
                            countries={countries} 
                            isAddressPopulated={isAddressPopulated()} 
                            handleChangeAddress={handleChangeAddress}
                            formValidation={
                                {
                                    address: formValidation.address,
                                    longLatRequired: formValidation.longLatRequired,
                                    street_address: formValidation.street_address,
                                    city: formValidation.city,
                                    province: formValidation.province,
                                    country: formValidation.country,
                                }
                            }
                        />
                    </Col>
                    <Col md={6} className={(isAddressPopulated() || editAddress) ? "map-section": "hidden"}>
                        <InputLabel 
                            htmlFor="country"
                        >
                            Map
                        </InputLabel>
                        <div 
                            className="map-modal-trigger prevent-click" 
                            style={{cursor: !isAdmin && 'default'}} 
                            onClick={() => { 
                                if (!isAdmin) {
                                    return null
                                }
                                else {
                                    showModal('map')
                                }
                            }}
                        >
                            <GoogleMap 
                                key={'map-' + data.location.geo_code.lat} 
                                mapId="mini-map" 
                                address={data.location.address} 
                                autoCompleteInput="add-input" 
                                autoCompleteCity="city" 
                                autoCompleteProvince="province" 
                                mapOptions={Object.assign({ disableDefaultUI: true }, mapOptions)} 
                                mapEvents={mapEvents} 
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}