import React from 'react';
import Link from 'react-router-dom/Link';
import Modal from 'react-bootstrap/lib/Modal';
import apiHandler from '../../../js/apiHandler';
import Autosuggest from 'react-autosuggest';
import { InputField, PrimaryButton, FieldWrap, InputLabel } from '../../global/forms/FormElements';
import Loader from '../../global/Loader';

/**
 * Component modal for fetch collections as suggestions and add property into collection.
 * @class
 */
export class AddToCollection extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            suggestions: this.props.collectionSuggestionData ? this.props.collectionSuggestionData : [],
            value: '',
            importId: undefined,
            isLoading: false,
            error: false,
            process: false,
            renderSuggestion: false
        }
        this.suggestions = this.props.collectionSuggestionData ? this.props.collectionSuggestionData : [];
    }

    componentWillReceiveProps(nextprops) {
        if(nextprops.type === 'Delete From Collection' && !isNaN(Number(this.props.collectionId))) {
            this.setState({
                isLoading: true
            })
            apiHandler.update('delete' ,`/propertycollections/${this.props.collectionId}/properties/${this.props.propertyId}`).then((data) => {
                apiHandler.update('delete' ,`/properties/${this.props.propertyId}/repconfig`).then((data1) => {
                    this.setState({
                        isLoading: false
                    })
                }).catch((error) => {
                    this.setState({
                        isLoading: false
                    })
                    this.props.onHide({event: false, type: this.props.type});
                })
            }).catch((error) => {
                this.setState({
                    isLoading: false
                })
                this.props.onHide({event: false, type: this.props.type});
            })
            return;
        }else if(nextprops.type === 'Delete From Collection' && this.suggestions.length) {
            let collection = this.suggestions.find((data) => {
                return data.name === this.props.collectionName
            })
            this.setState({
                isLoading: true
            })
            apiHandler.update('delete' ,`/propertycollections/${collection? (collection.collection_id): ('1')}/properties/${this.props.propertyId}`).then((data) => {
                apiHandler.update('delete' ,`/properties/${this.props.propertyId}/repconfig`).then((data1) => {
                    this.setState({
                        isLoading: false
                    })
                }).catch((error) => {
                    this.setState({
                        isLoading: false
                    })
                    this.props.onHide({event: false, type: this.props.type});
                })
            }).catch((error) => {
                this.setState({
                    isLoading: false
                })
                this.props.onHide({event: false, type: this.props.type});
            })
            return;
        }
    }

    onSuggestionsClearRequested() {

    }

    /**
     * When select any suggestions this function will invoke
     * @param {number} index 
     */
    selectProperty(index) {
        this.setState({
            importId: this.state.suggestions[index],
        })
    }

    /**
     * Filter suggestions online
     * @param {string} event 
     */
    fetchPropertiesSuggestions(event) {
        if (typeof (event.value) === 'string') {
            let suggestions = this.suggestions.filter((data) => {
                return data.name.toLowerCase().match(RegExp(event.value.toLowerCase())) ? true : false
            })
            this.setState({
                suggestions
            })
        }
    }
    /**
     * Add property into collection use this function
     */
    async addToCollection() {
        this.setState({
            process: true,
            isLoading: true
        })
        try {
            let collectionData = await apiHandler.put(`/propertycollections/${this.state.importId.collection_id}/properties/${this.props.propertyId}`, {body: {}, response: true})
            if(collectionData.response) {
                this.setState({
                    isLoading: false,
                    error: true
                })
            }
            this.setState({
                isLoading: false
            })
        } catch(e) {
            this.setState({
                isLoading: false,
                error: true
            })
        }
    }

    /**
     * Hide collection modal will send prop for hide collection modal to parent component.
     */
    onHide(event) {
        if(this.state.isLoading) {
            return
        }
        this.props.onHide({event, type: this.props.type});
    }

    render() {
        const inputProps = {
            placeholder: 'Collection Name',
            value: this.state.value,
            className: 'form-field',
            id:'auto-com-collection',
            onChange: (event, { newValue }) => {
                if (typeof (newValue) !== 'string') {
                    newValue = newValue.name
                }
                this.setState({
                    value: newValue
                });
            },
            onFocus: event => {
                this.setState({
                    renderSuggestion: true
                })
            },
            onBlur: event => {
                this.setState({
                    renderSuggestion: false
                })
            }
        };

        if(this.props.type === 'Delete From Collection' && this.suggestions.length) {
            return (<Modal className="import-modal" show={this.props.open} onHide={this.onHide.bind(this)}>
                <Modal.Body className="importing col-add-modal bottom-margin">
                    <div className="importing-loader submit-modal">
                        {!this.state.isLoading ? (!this.state.error ? <span className='status-icon status-icon--success'></span> : <span className='status-icon status-icon--error' />) : <Loader />}
                        <div className='progress-wrapper upload-status'>
                            {!this.state.isLoading ? (!this.state.error ? <p>Deleted From Collection</p> : <p>Failed To Delete From Collection </p>) : <p>Deleting From Collection...</p>}
                        </div>
                        {!this.state.isLoading && <Link to='/collections' className='primary-btn white-btn pull-left col-md-6'>Go To Collection</Link>}
                        {!this.state.isLoading && <Link to='/properties' className='primary-btn pull-right col-md-6'>Go To Properties</Link>}
                    </div>
                </Modal.Body>
            </Modal>)
        }

        return (
            <Modal className="import-modal" show={this.props.open} onHide={this.onHide.bind(this)}>
                {this.state.process ?<Modal.Body className="importing col-add-modal bottom-margin">
                    <div className="importing-loader submit-modal">
                        {!this.state.isLoading ? (!this.state.error ? <span className='status-icon status-icon--success'></span> : <span className='status-icon status-icon--error' />) : <Loader />}
                        <div className='progress-wrapper upload-status'>
                            {!this.state.isLoading ? (!this.state.error ? <p>Added To Collection</p> : <p>Failed To Add In Collection </p>) : <p>Adding To Collection...</p>}
                        </div>
                        {!this.state.isLoading && <Link to='/collections' className='primary-btn white-btn pull-left col-md-6'>Go To Collection</Link>}
                        {!this.state.isLoading && <Link to={`/collections/${this.state.importId.collection_id}/rooms/${this.props.propertyId}/edit`} className='primary-btn pull-right'>Add Rooms</Link>}
                    </div>
                </Modal.Body>
                :<React.Fragment>
                    <Modal.Header closeButton>
                        <Modal.Title>Collection Name</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FieldWrap>
                            <Autosuggest
                                onSuggestionsFetchRequested={this.fetchPropertiesSuggestions.bind(this)}
                                suggestions={this.state.suggestions}
                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                getSuggestionValue={(event) => event}
                                renderSuggestion={(renderSuggestion) => <div>{renderSuggestion.name}</div>}
                                inputProps={inputProps}
                                alwaysRenderSuggestions={this.state.renderSuggestion}
                                focusInputOnSuggestionClick={false}
                                onSuggestionSelected={(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => { this.selectProperty(suggestionIndex); }}
                            />
                            {this.state.autoLoader ? <div className="auto-suggest-loader">
                                <Loader />
                            </div> : null}
                        </FieldWrap>
                        <PrimaryButton type="button" fullWidth={false} disabled={!(this.state.importId && this.state.importId.name === this.state.value)} onClick={this.addToCollection.bind(this)}>Add to Collection</PrimaryButton>
                    </Modal.Body>
                </React.Fragment>}
            </Modal>
        )
    }

}