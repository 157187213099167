import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropertiesForm from '../content/properties/PropertiesForm';
import { API } from 'aws-amplify';

import PageLayout from '../global/PageLayout';

/**@class Component for add new property use PropertyForm as child component */
class AddProperty extends Component {
	constructor(props) {
		super(props);
		this.cloneData = null;
		this.breadcrumbItems = [{
			title: "Properties",
			link: "/properties"
        },{
			title: "Create Property",
			link: "/properties/create"
		}];
		this.org = this.props.org;
		this.state = {
			isLoading: false,
			intergrationName: {"integration_name": "racalendar"},
		}
		if(props.location.state && props.location.state.cloneData) {
			let propState = props.location.state;
			this.cloneData = propState.cloneData;
		};
	}

	async componentWillMount() {
		this.setState({
			isLoading: true,
		});

		await this.getIntergrationNameData();

		this.setState({
			isLoading: false,
		});
	}

	getIntergrationNameData = async () => {
		try {
			const data = await API.get("rapapi", `/integrations/${this.org}`);
			if(data) {
				this.setState({
					intergrationName: data
				});
			};
		} 
		catch (err)  {
			console.log(err)
		};
	}

	render() {
		const { 
			isLoading, 
			intergrationName
		} = this.state;

		return (
			<PageLayout isCentered={true}>
				{
					!isLoading &&
					<PropertiesForm
						breadcrumbItems={this.breadcrumbItems}  
						title="Create Property" 
						cloneData={this.cloneData}
						integrationsDataByOrgName={intergrationName}
					/>
				}
			</PageLayout>	
		)		
	}
}

const mapStateToProps = state => {
    return {
        org: state.roleManager.org
    }
}

export default withRouter(connect(
    mapStateToProps,
    null
)(AddProperty));
